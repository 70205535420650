import React, { Component } from 'react';
import Select from 'react-select';

class OpenSelectField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			options: []
		};
	}

	componentDidMount() {
		const options = JSON.parse(JSON.stringify(this.props.options)) || [];
		const inOptions = options.find((option) => option.value === this.props.value);
		if (!inOptions && this.props.value != '') {
			options.push({ value: this.props.value, label: this.props.value });
		}
		this.setState({
			options: options
		});
	}

	componentWillUnmount() {
		this.setState({ options: [] });
	}

	handleOptionChange = (option) => {
		if (option.new) {
			if (this.props.optionValidator && this.props.optionValidator(option.value)) {
				this.props.onChange(option.value);
			} else if (!this.props.optionValidator) {
				this.props.onChange(option.value);
			} else {
				this.setState({ errorTrigger: true, errorMessage: 'Invalid Option' });
			}
		} else {
			this.props.onChange(option.value);
		}
	};

	handleNewOptionChange = (e) => {
		const newOption = {
			label: 'New Option: ' + e,
			value: e,
			color: '#00B8D9',
			new: true
		};
		if (e !== '') {
			this.setState({
				options: [ ...this.props.options, newOption ]
			});
		} else {
			this.setState({
				options: this.props.options
			});
		}
	};

	render() {
		const { options, errorTrigger, errorMessage } = this.state;
		const {
			onChange,
			label,
			actionOn,
			actionMessage,
			value,
			disabled,
			placeholder,
			style,
			name,
			onInputChange,
			noOptionsMessage,
			required,
			formatGroupLabel
		} = this.props;

		const selectedOption = options.find((option) => option.value === value);
		return (
			<div className="formFieldContainer" style={style}>
				<div className="formField">
					<div className="labelContainer">
						{label && (
							<div className="formFieldLabel">
								{label}
								{required ? '*' : ''}
							</div>
						)}
						{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'}</div>}
						{actionOn && <div className="formFieldLabel">{actionMessage}</div>}
					</div>
					<Select
						formatGroupLabel={formatGroupLabel}
						styles={{
							valueContainer: (e) => ({
								...e,
								height: 30
							}),
							singleValue: (e) => ({
								...e,
								height: 30
							}),
							indicatorSeparator: (e) => ({
								...e,
								display: 'none'
							}),
							dropdownIndicator: (e) => ({
								...e,
								height: 30,
								padding: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: 30
							}),
							input: (e) => ({
								...e,
								height: 30,
								lineHeight: 1.2
							}),
							option: (e) => ({
								...e,
								height: 30,
								lineHeight: 1.2
							})
						}}
						placeholder={placeholder}
						className={`editingSelectField  ${disabled ? 'disabled-link' : ''} ${errorTrigger
							? 'error'
							: ''}`}
						classNamePrefix="select"
						value={selectedOption}
						name={name}
						options={options}
						onChange={this.handleOptionChange}
						onInputChange={this.handleNewOptionChange}
						noOptionsMessage={noOptionsMessage}
					/>
				</div>
			</div>
		);
	}
}

export default OpenSelectField;
