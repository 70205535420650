import React, { useEffect, useState, useRef } from 'react';
import Prism from 'prismjs';

import { FixedSizeList as List } from 'react-window';

const CodeEditor = (props) => {
	const { content, setContent } = props;
	const [height, setHeight] = useState(0);
	let sidebarScroll = React.createRef();
	let contentScroll = React.createRef();
	let parentRef = useRef();
	let listRef = React.createRef();
	let scrollOffset = useRef(0);
	let _preventEvent = false;
	let contentLastScrollTop = 0;
	let contentLastScrollLeft = 0;

	const onSidebarScroll = (e) => {
		if (_preventEvent) {
			_preventEvent = false;
			return;
		}
		_preventEvent = true;
		contentScroll.current.scrollTop = e.target.scrollTop;
		contentScroll.current.scrollLeft = e.target.scrollLeft;


		if (listRef.current) {
			listRef.current.scrollTo(e.target.scrollTop);
			scrollOffset.current = e.target.scrollTop;
			document.querySelectorAll('code').forEach((block) => {
				Prism.highlightElement(block);
			});

		}
	};

	const onContentScroll = (e) => {
		if (_preventEvent) {
			_preventEvent = false;
			return;
		}

		if (e.target.scrollTop !== contentLastScrollTop) {
			_preventEvent = true;
			sidebarScroll.current.scrollTop = e.target.scrollTop;
			contentLastScrollTop = e.target.scrollTop;
		}
		if (e.target.scrollLeft !== contentLastScrollLeft) {
			_preventEvent = true;
			sidebarScroll.current.scrollLeft = e.target.scrollLeft;
			contentLastScrollLeft = e.target.scrollLeft;
		}
	};

	const handleKeyDown = async (evt) => {
		let value = content,
			selStartPos = evt.currentTarget.selectionStart;

		// handle 4-space indent on
		if (evt.key === 'Tab') {
			evt.persist();
			evt.preventDefault();
			value = value.substring(0, selStartPos) + '    ' + value.substring(selStartPos, value.length);
			await setContent(value);
			evt.target.selectionStart = selStartPos + 4;
			evt.target.selectionEnd = selStartPos + 4;
		}
	};

	useEffect(
		() => {
			const textAreaWidth = sidebarScroll.current.offsetWidth;
			listRef.current.offsetWidth = textAreaWidth;
			document.querySelectorAll('code').forEach((block) => {
				Prism.highlightElement(block);
			});


			if (parentRef.current) {
				setHeight(parentRef.current.offsetHeight);
			}
		}
	);



	const fixedEventCustomCSS = content ? content.split('\n') : [];

	return (
		<div ref={parentRef} className="code-edit-container" style={props.style} >
			<textarea
				ref={sidebarScroll}
				onScroll={onSidebarScroll}
				className="code-input"
				value={content}
				disabled={props.disabled}
				onChange={(evt) => setContent(evt.target.value.replace(/	/g, '    '))}
				onKeyDown={handleKeyDown}
				style={{ color: 'transparent', caretColor: 'black', height: height, paddingTop: 2, paddingLeft: 2 }}

			/>

			<pre style={{ padding: 0, height: height, border: '' }} className={`code-output language-${props.language}`} ref={contentScroll} onScroll={onContentScroll}>

				<List
					ref={listRef}
					height={height}
					itemCount={fixedEventCustomCSS.length}
					itemSize={21}
					width={window.innerWidth}
				>


					{({ index, key, style }) => (

						<code key={key} style={{ ...style, whiteSpace: 'pre' }} className={`language-${props.language}`} >{`${fixedEventCustomCSS[index]}`}</code>
					)}

				</List>
			</pre>

		</div>
	);
};

export default CodeEditor;
