import React from "react";
import FieldModal from "../../../../components/modals/fieldModal";
import InputField from "../../../../components/inputs/inputField";

class ShareReportModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            shareList: props.report.sharedWith ? props.report.sharedWith : [],
            error: ''
        }
    }

    validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    addShare = () => {
        const { email, shareList } = this.state;
        const isValidEmail = this.validateEmail(email);
        if (isValidEmail) {
            const existingEmail = shareList.find(e => e === email.toLowerCase());
            if (!existingEmail) {
                shareList.push(email.toLowerCase());
                this.setState({ shareList, email: '', error: '' }, () => {
                    this.props.shareReport(this.state.shareList);
                });
            } else {
                this.setState({ error: 'Email already exists' });
            }
        } else {
            this.setState({ error: 'Invalid email' });
        }
    }

    removeShare = (index) => {
        const { shareList } = this.state;
        shareList.splice(index, 1);
        this.setState({ shareList }, () => {
            this.props.shareReport(this.state.shareList);
        });
    }
    render() {
        const { orgId, eventId, reportId } = this.props;
        const { email, shareList, error } = this.state;
        return <FieldModal
            size="small"
            isOpen={this.props.isOpen}
            modalTitle={"Share Report"}
            bodyHeaderText={'Manage email addresses that can access this report.'}
            bodyContent={() => {
                return (
                    <div>
                        <a target="_blank" href={`https://planner.simple.events/external/${orgId}/events/${eventId}/reports/${reportId}`}><h3 className="mb-20">Report Link</h3></a>
                        <div className="flex jcc mb-20">

                            <InputField
                                label={'Email to share with'}
                                type={'email'}
                                value={email}
                                classes="mb-0"
                                onChange={(e) => this.setState({ email: e.target.value })}
                                placeholder={'Enter email'}
                                errorTrigger={error !== ''}
                                errorMessage={error}

                            />
                            <button className="mt-a mb-5 neu ml-10" onClick={() => this.addShare()}>
                                Add
                            </button>
                        </div>
                        <div className="flex column">
                            <h3 className="mb-10">Shared With:</h3>
                            {shareList.length > 0 ? shareList.map((user, index) => {
                                return <div className="flex aic jcsb mb-15"><h4 key={index}>{user}</h4><button onClick={() => this.removeShare(index)} className="neu p-7"><i className="las la-times"></i></button></div>
                            }) : <div className="flex aic jcc mb-15"><h4>No external emails</h4></div>}
                        </div>
                    </div>
                );
            }}
            toggle={this.props.toggle}
            //actionButtonLabel={'Update Share List'}
            //actionButton={() => this.props.shareReport(shareList, this.props.toggle)}
            actionButtonDisabled={this.props.submitting}
            cancelButtonLabel={'Done'}
            isDelete={false}
        />
    }
}

export default ShareReportModal;