import React, { Component } from 'react';
import { UncontrolledDropdown, Modal, DropdownMenu } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Send } from 'react-feather';
import API from '../../../utils/API';
import EventEmailDetails from './details';
import EventEmailSchedules from './schedules';
import EventEmailPreview from './preview';
import EventSentEmails from './sent';
import SendEmailModal from './modals/sendEmailModal';
import DeleteEmailModal from './modals/deleteEmailModal';
import ScheduleEmailModal from './modals/scheduleEmailModal';

import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';

import CodeEditor from '../../../components/codeEditor/codeEditor';
import CopyButton from '../../../components/inputs/copyButton';
import ToggleField from '../../../components/inputs/toggleField';

class EventEmail extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const email = props.emails.length > 0 ? props.emails.find((e) => e._id == params.emailId) : {};
		this.state = {
			orgSenderEmail: '',
			orgSenderName: '',
			email: email,
			selectedSection: 0,
			editedEmail: {},
			sendEmailModalOpen: false,
			editing: false,
			errorMessage: false,
			DeleteEmailModalOpen: false,
			loading: true,
			parameterOptionsOpen: false,
			requiredFields: {
				name: false,
				subject: false
			},
			codeExpanded: false,
			previewExpanded: false,
			scheduleEmailModalOpen: false
		};
	}

	//Fetch complete attendee information and append to current
	async componentDidMount() {
		const { match: { params } } = this.props;
		const email = this.state.email;
		const { eventId, orgId } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/emails/${params.emailId}`).then((res) => {
			if (res.data) {
				Object.assign(email, res.data);
				this.setState({
					orgSenderEmail: res.data.defaultSenderEmail,
					orgSenderName: res.data.defaultSenderName,
					email: email,
					editedEmail: email,
					schedules: res.data.schedules,
					loading: false
				});
			}
		});
	}

	saveChanges = async () => {
		const { eventId, orgId } = this.props;
		const { editedEmail, email } = this.state;

		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedEmail[key] || editedEmail[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			if (editedEmail.isPlainHtml) {
				this.setState({
					editedEmail: { ...editedEmail, template: 'email-simple-html' }
				});
			}
			delete editedEmail.sentEmails;

			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${email._id}`, editedEmail)
				.then((res) => {
					this.state.editedEmail.link = `/${orgId}/events/${eventId}/emails/${email._id}`;
					this.props.updateEmails(this.state.editedEmail);
					this.setState({
						email: this.state.editedEmail,
						editing: false,
						requiredFields: requiredFields
					});
				})
				.catch((e) => {
					console.log('Error', e);
				});
		} else {
			this.setState({
				requiredFields: requiredFields
			});
		}
	};

	updateEditedEmail = (data, callback) => {
		this.setState({ editing: true, editedEmail: data }, callback);
	};

	toggleCompose = () => {
		this.setState({ sendEmailModalOpen: !this.state.sendEmailModalOpen });
	};

	toggleScheduleEmail = () => {
		this.setState({ scheduleEmailModalOpen: !this.state.scheduleEmailModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	updateEmail = (email) => {
		this.setState({ email: email });
	};

	toggleDelete = () => {
		this.setState({ DeleteEmailModalOpen: !this.state.DeleteEmailModalOpen });
	};

	SectionItem = (title, sectionNo) => {
		const sectionTitle =
			sectionNo === this.state.selectedSection ? (
				<div className="filterTab selected">
					<div className="tabTitle">
						<div className="calibreBold fs-30">{title}</div>
					</div>
				</div>
			) : (
				<div
					className="filterTab"
					onClick={() => {
						this.setState({ selectedSection: sectionNo });
					}}
				>
					<div className="tabTitle">
						<div className="calibreBold fs-30">{title}</div>
					</div>
				</div>
			);
		return sectionTitle;
	};

	cancelEdit = () => {
		const email = { ctaUrl: '', subject: '', sender: '', bannerUrl: '', html: '', ...this.props.email };
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedEmail: this.state.email,
			editing: false,
			errorMessage: false,
			requiredFields: requiredFields
		});
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	renderMetadataOptions = () => {
		const { keyedMetadata, updateEditedEmail, keyedData } = this.props;
		const { parameterOptionsOpen, editedEmail } = this.state;

		return (
			<Modal
				isOpen={parameterOptionsOpen}
				toggle={() => this.setState({ parameterOptionsOpen: !parameterOptionsOpen })}
			>
				<div>
					<div className="paramsList ">
						<p className="calibreSemiBold">Shortcodes</p>
					</div>
				</div>

				<div>
					<div className="paramsList">
						{keyedMetadata.map((option) => {
							const split = option.split('.');
							const concatenatedOption = option.split('.').join(' ');
							const shortcode = option.split('.').join('');
							let label = '';
							switch (split.length) {
								case 2:
									label =
										split[0].charAt(0).toUpperCase() +
										split[0].slice(1) +
										' ' +
										keyedData[split[0]][split[1]];
								case 3:
									label = `
										${split[0].charAt(0).toUpperCase() + split[0].slice(1)} 
										
										${keyedData[split[0]][split[1]]._defaultLabel
											? keyedData[split[0]][split[1]]._defaultLabel +
												' ' +
												keyedData[split[0]][split[1]][split[2]]
											: keyedData[split[0]][split[1]]} 											
												`;
							}
							return (
								<div style={{ display: 'flex', height: 40 }}>
									<ToggleField
										label={label}
										checked={editedEmail.parameters.includes(option)}
										icons={false}
										style={{ width: 'unset', marginBottom: 'unset', margin: 'auto 0' }}
										onChange={() => {
											const parameters = editedEmail.parameters;
											if (parameters.includes(option)) {
												parameters.splice(parameters.indexOf(option), 1);
											} else {
												parameters.push(option);
											}
											this.updateEditedEmail({ ...editedEmail, parameters: parameters });
										}}
									/>

									{editedEmail.parameters.includes(option) && (
										<div style={{ display: 'flex', margin: 'auto 0', whiteSpace: 'nowrap' }}>
											<div
												style={{
													paddingLeft: 5,
													paddingRight: 10,
													fontFamily: 'Calibre-Regular',
													fontSize: 16,

													margin: 'auto 0'
												}}
											>
												- {`{{${shortcode}}}`}
											</div>
											<CopyButton
												style={{ background: 'unset' }}
												copyValue={`{{${shortcode}}}`}
												isButton={false}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</Modal>
		);
	};

	renderSection() {
		const { editedEmail, requiredFields, codeExpanded, previewExpanded } = this.state;
		switch (this.state.selectedSection) {
			case 0:
				return (
					<EventEmailDetails
						orgSenderEmail={this.state.orgSenderEmail}
						orgSenderName={this.state.orgSenderName}
						email={this.state.email}
						updateEditedEmail={this.updateEditedEmail}
						editedEmail={this.state.editedEmail}
						errorMessage={this.state.errorMessage}
						toggle={this.state.editing}
						toggleEditing={this.toggleEditing}
						keyedMetadata={this.props.keyedMetadata}
						requiredFields={requiredFields}
					/>
				);
			case 1:
				return (
					<div className="flex-sb">
						<div className={`${codeExpanded ? 'w-100' : previewExpanded ? 'hiddenBlock' : 'emailEditor '}`}>
							<div className="flex-sb">
								<div
									className="sectionSubtitle c-pointer"
									onClick={() => {
										this.setState({ codeExpanded: !codeExpanded, previewExpanded: false });
									}}
								>
									Code
								</div>
								<div
									className="calibreSemiBold se-blue c-pointer fs-20"
									onClick={() => {
										this.setState({ parameterOptionsOpen: true });
									}}
								>
									Insert Shortcodes
								</div>
							</div>
							<CodeEditor
								className="detailsInput detailsTextArea"
								language="markup"
								content={editedEmail.html}
								style={{ height: '70vh' }}
								setContent={(content) => {
									this.updateEditedEmail({ ...this.state.editedEmail, html: content });
								}}
							/>
						</div>
						<div className={`${previewExpanded ? 'w-100' : codeExpanded ? 'hiddenBlock' : 'emailPreview'}`}>
							<div className="flex-sb">
								<div
									className="sectionSubtitle c-pointer"
									onClick={() => {
										this.setState({ previewExpanded: !previewExpanded, codeExpanded: false });
									}}
								>
									Preview
								</div>
							</div>
							<div
								className="emailPreviewWindow"
								style={{ border: '1px solid #bbb', borderRadius: '5px' }}
								dangerouslySetInnerHTML={{ __html: editedEmail.html }}
							/>
						</div>
					</div>
				);
			case 2:
				return (
					<div>
						{/* <EventSentEmails email={this.state.email} />; */}
						<EventEmailSchedules
							orgId={this.props.orgId}
							orgSenderEmail={this.state.orgSenderEmail}
							orgSenderName={this.state.orgSenderName}
							email={this.state.email}
							updateEditedEmail={this.updateEditedEmail}
							cancelSchedules={this.cancelSchedules}
							resumeSchedules={this.resumeSchedules}
							editedEmail={this.state.editedEmail}
							errorMessage={this.state.errorMessage}
							toggle={this.state.editing}
							schedules={this.state.schedules}
							toggleEditing={this.toggleEditing}
							keyedMetadata={this.props.keyedMetadata}
							requiredFields={requiredFields}
						/>
					</div>
				);

			/* case 2:
				return (
					<EventEmailPreview email={this.state.email} eventId={this.props.eventId} orgId={this.props.orgId} />
				); */
			default:
		}
	}

	addSchedule = (schedule) => {
		this.state.schedules.push(schedule);
		this.setState({ schedules: this.state.schedules });
	};

	cancelSchedules = (scheduleIds) => {
		let schedules = this.state.schedules;

		schedules = schedules.map((s) => {
			if (scheduleIds.includes(s._id)) {
				s.cancelled = true;
			}
			return s;
		});
		this.setState({ schedules: schedules });
	};

	resumeSchedules = (scheduleIds) => {
		let schedules = this.state.schedules;

		//TODO: check is after current time
		schedules = schedules.map((s) => {
			const timeSchedule = new Date(`${s.sendDate} ${s.sendTime}`);
			const currentDate = new Date();
			if (scheduleIds.includes(s._id) && timeSchedule >= currentDate) {
				s.cancelled = false;
			}
			return s;
		});
		this.setState({ schedules: schedules });
	};

	render() {
		const { event, keyedMetadata } = this.props;
		const {
			email,
			sendEmailModalOpen,
			editing,
			DeleteEmailModalOpen,
			loading,
			selectedSection,
			parameterOptionsOpen,
			scheduleEmailModalOpen
		} = this.state;

		return (
			<div>
				{!loading && (
					<div className="sectionContainer">
						<BasicSectionHeader
							title={`${email.name}`}
							editing={editing}
							cancelEdit={this.cancelEdit}
							saveEdit={this.saveChanges}
							updatedAt={email.updatedAt}
							enableLastLink={true}
							breadcrumbs={[
								{
									name: 'Events',
									link: `/admin/events`
								},
								{
									name: event.name,
									link: `/admin/events/${event._id}/0`
								},
								{
									name: 'Emails',
									link: `/admin/events/${event._id}/8`
								}
							]}
							mainActions={[
								{ onClick: this.toggleCompose, label: 'Send Email' },
								{ onClick: this.toggleScheduleEmail, label: 'Schedule Email' }
							]}
							subActions={[ { onClick: this.toggleDelete, label: 'Delete Email' } ]}
						/>
						<TabsContainer
							tabs={[ { title: 'Details' }, { title: 'Editor' }, { title: 'Schedules' } ]}
							updateSelected={this.updateSelected}
							selectedIndex={selectedSection}
						/>
						<div className="sectionBody">{this.renderSection()}</div>
						{sendEmailModalOpen && (
							<SendEmailModal
								isOpen={sendEmailModalOpen}
								toggle={this.toggleCompose}
								attendeeCount={this.props.attendeeCount}
								attendees={this.props.event.attendees}
								sessions={this.props.event.sessions}
								groups={this.props.event.groups}
								loadMoreAttendees={this.props.loadMoreAttendees}
								email={email}
								submit={this.updateEmail}
								eventId={this.props.eventId}
								orgId={this.props.orgId}
							/>
						)}
						{scheduleEmailModalOpen && (
							<ScheduleEmailModal
								isOpen={scheduleEmailModalOpen}
								toggle={this.toggleScheduleEmail}
								addSchedule={this.addSchedule}
								attendeeCount={this.props.attendeeCount}
								attendees={this.props.event.attendees}
								sessions={this.props.event.sessions}
								groups={this.props.event.groups}
								loadMoreAttendees={this.props.loadMoreAttendees}
								email={email}
								submit={this.updateEmail}
								eventId={this.props.eventId}
								orgId={this.props.orgId}
								event={event}
							/>
						)}
						{DeleteEmailModalOpen && (
							<DeleteEmailModal
								isOpen={DeleteEmailModalOpen}
								toggle={this.toggleDelete}
								email={email}
								removeEmail={this.props.removeEmail}
							/>
						)}
						{parameterOptionsOpen && this.renderMetadataOptions()}
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(EventEmail);
