import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-toggle/style.css';
import API from '../../../utils/API';
import TabContainer from '../../../components/views/TabsContainer';
import InputField from '../../../components/inputs/inputField';

import FormSubmissions from './submissions';
import ToggleField from '../../../components/inputs/toggleField';
import SelectField from '../../../components/inputs/selectField';
import SectionHeader from '../../../components/views/SectionHeader';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import FormBuilderModal from './modals/formBuilder';
import SectionFooter from '../../../components/views/SectionFooter';

import DuplicateFormModal from './modals/duplicateFormModal'
import RichTextEditor from '../../../views/pages/components/richTextEditor';
import AddToPage from './modals/addToPage';
import CloseFormNowModal from './modals/closeFormNowModal';
import ScheduleCloseFormModal from './modals/scheduleCloseFormModal';
import RenameFormModal from './modals/renameFormModal';
import moment from 'moment';
import DeleteFormModal from './modals/deleteFormModal';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
const baseFieldList = [
	/* 	{
		_id: 'event',
		title: 'Event',
		fields: [
			{
				_id: '_startDate',
				fieldName: 'Start Date',
				fieldType: 'date',
				propertyType: 'event',
				required: false
			},
			{
				_id: '_endDate',
				fieldName: 'End Date',
				fieldType: 'date',
				propertyType: 'event',
				required: false
			},
			{
				_id: '_name',
				fieldName: 'Name',
				fieldType: 'text',
				propertyType: 'event',
				required: true
			}
		]
	}, */
	{
		_id: 'attendee',
		title: 'Attendee Basic Detail Fields',
		fields: [
			{
				_id: '_firstName',
				fieldName: 'First Name',
				fieldType: 'text',
				required: true,
				propertyType: 'attendee'
			},
			{
				_id: '_lastName',
				fieldName: 'Last Name',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_email',
				fieldName: 'Email',
				fieldType: 'email',
				required: true,
				propertyType: 'attendee'
			},
			{
				_id: '_phoneNumber',
				fieldName: 'Phone Number',
				fieldType: 'tel',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_title',
				fieldName: 'Title',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_company',
				fieldName: 'Company',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_textMessageOptIn',
				fieldName: 'Text Message Opt In',
				fieldType: 'checkbox',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_dietaryPreference',
				fieldName: 'Dietary Preference',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_foodAllergies',
				fieldName: 'Food Allergies',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_emergencyContactName',
				fieldName: 'Emergency Contact Name',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_emergencyContactPhoneNumber',
				fieldName: 'Emergency Contact Phone Number',
				fieldType: 'tel',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_address',
				fieldName: 'Address',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_city',
				fieldName: 'City',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_state',
				fieldName: 'State',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_zip',
				fieldName: 'Zip',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_country',
				fieldName: 'Country',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_profilePicture',
				fieldName: 'Profile Picture',
				fieldType: 'profileImage',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_NPINumber',
				fieldName: 'NPI Number',
				fieldType: 'NPINumber',
				required: false,
				propertyType: 'attendee'
			},

		]
	},
	{
		_id: 'rooming',
		title: 'Attendee Rooming Fields',
		fields: [
			{
				_id: '_roomingInformation-arrivalDate',
				fieldName: 'Arrival Date',
				fieldType: 'date',
				propertyType: 'attendee',
			}, {
				_id: '_roomingInformation-arrivalTime',
				fieldName: 'Arrival Time',
				fieldType: 'time',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-checkInDate',
				fieldName: 'Check In Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-departureDate',
				fieldName: 'Departure Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-departureTime',
				fieldName: 'Departure Time',
				fieldType: 'time',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-checkOutDate',
				fieldName: 'Check Out Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-numberOfDaysStay',
				fieldName: 'Number of Days Stay',
				fieldType: 'number',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-roomType',
				fieldName: 'Room Type',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-hotelConfirmationNumber',
				fieldName: 'Hotel Confirmation Number',
				fieldType: 'text',
				propertyType: 'attendee'
			}
		]
	},
	{
		_id: 'travel',
		title: 'Attendee Travel Fields',
		fields: [
			{
				_id: '_travelInformation-originCity',
				fieldName: 'Origin City',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-originState',
				fieldName: 'Origin State',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalCity',
				fieldName: 'Arrival City',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalState',
				fieldName: 'Arrival State',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalDate',
				fieldName: 'Arrival Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalTime',
				fieldName: 'Arrival Time',
				fieldType: 'time',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalCarrier',
				fieldName: 'Arrival Carrier',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalFlight',
				fieldName: 'Arrival Flight',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalTicketed',
				fieldName: 'Arrival Ticketed',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalClassOfService',
				fieldName: 'Arrival Class of Service',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalLocator',
				fieldName: 'Arrival Locator',
				fieldType: 'text',
				propertyType: 'attendee'
			}]
	},
	{
		_id: 'special',
		title: 'Special Function Fields',
		fields: [
			{
				_id: '_additionalAttendees',
				fieldName: 'Additional Attendees',
				fieldType: 'additionalAttendees',
				propertyType: 'base',
				moduleConfiguration: {
					label: 'Guest',
					maxAttendees: 2,
					associatedGroup: null,
					defaultStatus: 'registered',
					confirmationEmail: null,
					fields: [
						{
							_id: '_firstName',
							attendeeField: { propertyType: 'base', propertyName: 'firstName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeFirstName',
							label: 'First Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'text',
							static: true
						},
						{
							_id: '_lastName',
							attendeeField: { propertyType: 'base', propertyName: 'lastName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeLastName',
							label: 'Last Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: false,
							style: '',
							type: 'text',
							static: false
						},
						{
							_id: '_email',
							attendeeField: { propertyType: 'base', propertyName: 'email' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeEmail',
							label: 'Email',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'email',
							static: true
						}
					]
				}
			},
			{
				_id: '_notifierEmail',
				fieldName: 'Notifier Email',
				fieldType: 'notifierEmail',
				propertyType: 'base',
				moduleConfiguration: {
					notificationEmail: null,
				}
			},
			{
				_id: '_medProLookup',
				fieldName: 'MedPro Lookup',
				fieldType: 'medProLookup',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			},
			{
				_id: '_salesForceSearchContact',
				fieldName: 'Salesforce Search Contact',
				fieldType: 'salesForceSearchContact',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			},
			{
				_id: '_salesForceSearchAccount',
				fieldName: 'Salesforce Search Account',
				fieldType: 'salesForceSearchAccount',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			}
		]
	}
];
class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {},
			editedForm: {},
			formEditorModalOpen: false,
			eventId: props.eventId,
			editedFields: props.fields,
			customFields: this.props.fields.map((category) => {
				const title = category.title;
				const headers = category.fields.map((header) => {
					const name = header.fieldName;
					return {
						_id: header._id,
						category: title,
						categoryId: category._id,
						value: name,
						label: name
					};
				});
				return {
					_id: category._id,
					label: title,
					value: category._id,
					options: headers
				};
			}),

			fieldModal: false,
			adding: false,
			loading: true,
			editing: false,
			editingFormBuilder: false,
			removing: false,
			removedCategoryId: 0,
			removedFieldId: 0,
			customFieldsCollapsed: false,
			unattachedFieldsCollapsed: true,
			formButtonsCollapsed: true,
			fieldTypeTabSelected: 0,
			pageCount: 0,
			currentPage: 0,
			formEditorTabSelected: 0,
			selectedSection: 0,
			pardotCampaigns: [],
			pageEmbedOptions: [],
			selectedEmbedPage: 0,
			selectedEmbedPagePreview: {},
			toggleAddToPage: false,
			toggleDuplicateFormModal: false,
			pages: [],
			isV1: false,
			toggleRenameForm: false,
			editCount: 0,
			requiredFields: {
				campaignName: false,
			},
			isMissingFields: true,
			toggleScheduleClose: false,
			toggleCloseNow: false,
			toggleDeleteForm: false
		};
	}

	componentDidMount() {
		const { orgId, eventId } = this.props;
		const { match: { params }, keyedMetadata, keyedData } = this.props;
		API().get(`Organizations/${orgId}/events/${eventId}/forms/${params.formId}`).then((res) => {
			if (res.data) {
				const editForm = JSON.parse(JSON.stringify({ ...this.state.editedForm, ...res.data }));
				if (editForm.pages.length === 0) {
					editForm.pages = [
						{
							title: '',
							description: '',
							sections: [
								{
									_id: `^0`,
									title: '',
									page: 1,
									fields: [],
									buttons: []
								}
							]
						}
					];
				}
				let isV1 = false;
				if (editForm.pageEmbedOptions && editForm.pageEmbedOptions.length > 0) {
					isV1 = true;
				}
				let isMissingFields = true;
				if (editForm.pages && editForm.pages.length > 0) {
					for (let pageCount = 0; pageCount < editForm.pages.length; pageCount++) {
						let page = editForm.pages[pageCount];
						if (page.sections && page.sections.length > 0) {
							for (let sectionCount = 0; sectionCount < page.sections.length; sectionCount++) {
								let section = page.sections[sectionCount];
								if (section.fields && section.fields.length > 0) {
									isMissingFields = false;
									break;
								}
							}

						}
						if (isMissingFields == false) {
							break;

						}

					}

				}



				this.setState({
					isMissingFields: isMissingFields,
					form: { ...this.state.form, ...res.data },
					pardotIntegrationEnabled: res.data.pardotIntegrationEnabled,
					editedForm: editForm,
					loading: false,
					isV1: isV1,
					pageEmbedOptions: editForm.pageEmbedOptions.map((option) => {
						return {
							label: option.title,
							value: option._id,
							partials: option.partials,
							url: option.url

						};
					}),
					pageEmbedOptionsV2: editForm.pageEmbedOptionsV2.map((option) => {
						return {
							label: option.title,
							value: option._id,
							partials: option.partials,
							url: option.url
						};
					}),
					selectedEmbedPageV1: editForm.pageEmbedOptions.length > 0 ? editForm.pageEmbedOptions[0]._id : 0,
					selectedEmbedPage: editForm.pageEmbedOptionsV2.length > 0 ? editForm.pageEmbedOptionsV2[0]._id : 0
				}, () => {
					const selectedEmbedPagePreview = this.state.pageEmbedOptionsV2.find((option) => option.value == this.state.selectedEmbedPage);
					const selectedEmbedPagePreviewV1 = this.state.pageEmbedOptions.find((option) => option.value == this.state.selectedEmbedPageV1);
					this.setState({ selectedEmbedPagePreview: selectedEmbedPagePreview, selectedEmbedPagePreviewV1: selectedEmbedPagePreviewV1 });
				});
			}
		});
		API().get(`Organizations/${orgId}/events/${eventId}/formPages`).then((res) => {
			if (res.data) {
				let fixedPages = res.data.pages.map((page) => {
					return {
						label: page.title,
						value: page._id
					};
				});
				this.setState({ pages: fixedPages });
			}
		});


		const metadataOptions = [];
		keyedData &&
			Object.keys(keyedData).forEach((key) => {
				const layer1 = keyedData[key];
				if (typeof layer1 === 'object') {
					Object.keys(layer1).forEach((key2) => {
						const layer2 = layer1[key2];
						const k2 = key2.substring(0, 1) + key2.substring(1);
						if (typeof layer2 === 'object') {
							Object.keys(layer2).forEach((key3) => {
								const layer3 = layer2[key3];
								const k3 = key3.substring(0, 1) + key3.substring(1);
								if (
									key !== '_defaultLabel' &&
									key2 !== '_defaultLabel' &&
									key3 !== '_defaultLabel'
								) {
									//metadataOptions.push(`${key}.${key2}.${key3}`);
									metadataOptions.push({
										label: `${layer1._defaultLabel} ${layer2._defaultLabel} ${layer3}`,
										value: `{{${key}${k2}${k3}}}`,
										text: `${key}${k2}${k3}`
									});
								}
							});
						} else {
							if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
								//metadataOptions.push(`${key}.${key2}`);
								metadataOptions.push({
									label: `${layer1._defaultLabel} ${layer2}`,
									value: `{{${key}${k2}}}`,
									text: `${key}${k2}`
								});
							}
						}
					});
				} else {
					if (key !== '_defaultLabel') {
						metadataOptions.push({ label: layer1, value: key, text: `{{${key}}}` });
					}
				}
			});
		this.setState({ customFields: metadataOptions });
	}

	searchPardotCampaigns = (search) => {

	}

	saveForm = async () => {
		const { eventId, orgId } = this.props;
		const { form, editedForm } = this.state;

		if (editedForm.integrationSettings && editedForm.integrationSettings.pardot && editedForm.integrationSettings.pardot.enabled) {
			if (!editedForm.integrationSettings.pardot.campaignId) {
				this.setState({ requiredFields: { ...this.state.requiredFields, campaignName: true } });
				return;
			}
		}

		let editedFormTemp = JSON.parse(JSON.stringify(editedForm));
		if (editedForm.confirmationEmail == 'N/A' || editedForm.confirmationEmail == '') {
			delete editedFormTemp.confirmationEmail;
		}
		delete editedFormTemp.submissions;


		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/forms/${editedForm._id}`, {
				name: editedForm.name,
				confirmationEmailEnabled: editedForm.confirmationEmailEnabled,
				progressBarEnabled: editedForm.progressBarEnabled,
				confirmationEmail: editedForm.confirmationEmail,
				pages: editedForm.pages,
				integrationSettings: editedForm.integrationSettings,
				type: editedForm.type,
				allowAnonymous: editedForm.allowAnonymous,
				rsvpEnabled: editedForm.rsvpEnabled,
				rsvpLabel: editedForm.rsvpLabel,
				denialEnabled: editedForm.denialEnabled,
				declineLabel: editedForm.declineLabel,
				declineTitle: editedForm.declineTitle,
				declineSubtitle: editedForm.declineSubtitle,
				enableDeclineOptions: editedForm.enableDeclineOptions,
				declineOptions: editedForm.declineOptions,
				requireDeclineOptions: editedForm.requireDeclineOptions,
				enableDeclineComment: editedForm.enableDeclineComment,
				requireComment: editedForm.requireComment,
				submissionReviewTitle: editedForm.submissionReviewTitle,
				submissionReviewSubtitle: editedForm.submissionReviewSubtitle,
				enableGrouping: editedForm.enableGrouping,
				mappedGroup: editedForm.mappedGroup,
				enableWaitlist: editedForm.enableWaitlist,
				maxCapTitle: editedForm.maxCapTitle,
				maxCapacityMessage: editedForm.maxCapacityMessage,
				successTitle: editedForm.successTitle,
				successSubtitle: editedForm.successSubtitle,
				preFillAttendeeData: editedForm.preFillAttendeeData,
				enableSubmissionReview: editedForm.enableSubmissionReview,
				formClosedTitle: editedForm.formClosedTitle || '',
				formClosedMessage: editedForm.formClosedMessage || '',
			})
			.then((res) => {
				if (res.data) {
					const editForm = JSON.parse(JSON.stringify({ ...this.state.editedForm, ...res.data }));
					let isMissingFields = true;
					if (editForm.pages && editForm.pages.length > 0) {
						for (let pageCount = 0; pageCount < editForm.pages.length; pageCount++) {
							let page = editForm.pages[pageCount];
							if (page.sections && page.sections.length > 0) {
								for (let sectionCount = 0; sectionCount < page.sections.length; sectionCount++) {
									let section = page.sections[sectionCount];
									if (section.fields && section.fields.length > 0) {
										isMissingFields = false;
										break;
									}
								}

							}
							if (isMissingFields == false) {
								break;

							}

						}

					}

					this.setState({
						form: { ...this.state.editedForm, ...res.data },
						editedForm: editForm,
						editing: false,
						editingFormBuilder: false,
						formEditorTabSelected: 0,
						focusOnField: false,
						focusedFieldId: null,
						focusedSectionId: null,
						editCount: this.state.editCount + 1,
						isMissingFields: isMissingFields
					}, () => {
						this.props.updateForm(this.state.form);
					});
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	cancelEdit = () => {
		const revertedForm = JSON.parse(JSON.stringify(this.state.form));
		this.setState({
			editedForm: revertedForm,
			editing: false
		});
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	removePage = (index) => {
		const { editedForm, currentPage } = this.state;
		editedForm.pages.splice(index, 1);
		const cp = currentPage > editedForm.pages.length - 1 ? editedForm.pages.length - 1 : currentPage;
		this.setState({ editedForm: editedForm, currentPage: cp, editing: true });
	};

	updateForm = (editedForm) => {
		this.setState({
			editedForm: editedForm,
			editingFormBuilder: true
		});
	};

	createPardotCampaign = () => {
		const { editedForm } = this.state;
		if (editedForm.integrationSettings && editedForm.integrationSettings.pardot && editedForm.integrationSettings.pardot.campaignName) {
			const campaignName = editedForm.integrationSettings.pardot.campaignName;
			//make post request
			API().post(`/Organizations/${this.props.orgId}/integrations/pardot/campaigns`, { formId: this.state.form._id, request: "createCampaign", campaignName: campaignName }).then((res) => {
				if (res.data.success) {
					const integrationSettings = editedForm.integrationSettings || {};
					integrationSettings.pardot = integrationSettings.pardot || {};
					integrationSettings.pardot.campaignId = res.data.campaignId;
					this.setState({
						editedForm: { ...editedForm, integrationSettings },
						editing: true
					});
				}
			});
		}
	}

	handleChange = (changeObj) => {
		const { editedForm } = this.state;
		this.setState({ editedForm: { ...editedForm, ...changeObj }, editing: true });
	};

	toggleFormEditorModal = () => {
		this.setState({ formEditorModalOpen: !this.state.formEditorModalOpen });
	};

	updateAddToPage = (page, callback) => {
		let pageEmbedOptionsV2 = [...this.state.pageEmbedOptionsV2, {
			label: page.title,
			value: page._id,
			partials: page.partials,
			url: '',
			_id: page._id
		}];

		let selectedEmbedPage = pageEmbedOptionsV2.length > 0 ? pageEmbedOptionsV2[0]._id : 0;

		const selectedEmbedPagePreview = pageEmbedOptionsV2.find((option) => option.value == selectedEmbedPage);
		this.props.updatePathOptions(page);
		this.setState({ selectedEmbedPagePreview: selectedEmbedPagePreview, pageEmbedOptionsV2: pageEmbedOptionsV2, selectedEmbedPage: selectedEmbedPage },
			() => {
				callback && callback();
			});
	}

	updateFormName = (name, callback) => {
		const { editedForm, form } = this.state;

		this.setState({ editedForm: { ...editedForm, name: name }, form: { ...form, name: name } }, () => {
			this.props.updateForm(this.state.form);
			callback && callback();
		});
	}


	renderSection = () => {

		const { form, editing, editedForm, loading, selectedSection, pardotIntegrationEnabled, pageEmbedOptions, selectedEmbedPageV1, pageEmbedOptionsV2, selectedEmbedPage, isV1, isMissingFields } = this.state;

		const { orgId, eventId, event, keyedMetadata, keyedData } = this.props;

		const selectedEmbedPagePreview = isV1 ? pageEmbedOptions.find((option) => option.value == selectedEmbedPageV1) : pageEmbedOptionsV2.find((option) => option.value == selectedEmbedPage);
		let section;

		const formTypes = [
			{ value: 'registration', label: 'Registration' },
			{ value: 'custom', label: 'Custom' },
			{ value: 'general', label: 'General' }
		]

		return (
			<div className="sectionBody">
				{selectedSection !== 2 ?

					<div className='blocksContainer'>
						{selectedSection !== 1 ?
							<div className="sectionBlock mr-25 contained-h">
								<h2 className='blockHeader'>Basic Information</h2>
								<SelectField
									label={'Form Type'}
									type="select"
									required={true}
									value={formTypes.find((type) => type.value == editedForm.type)}
									onChange={(e) => {
										this.handleChange({ type: e.value });
									}}
									options={formTypes}
								/>
								<ToggleField
									label="Enable Attendance Confirmation"
									checked={editedForm.rsvpEnabled}
									icons={false}
									onChange={(e) => {
										this.handleChange({ rsvpEnabled: e.target.checked });
									}}
								/>

								{!editedForm.rsvpEnabled && editedForm.type == 'registration' && <ToggleField
									label="Allow Anonymous Submissions"
									checked={editedForm.allowAnonymous}
									icons={false}
									onChange={(e) => {
										this.handleChange({ allowAnonymous: e.target.checked });
									}}
								/>}
								{editedForm.rsvpEnabled &&

									<InputField
										label="Submit Button Label"
										required={false}
										placeholder="Register for Event"
										value={editedForm.rsvpLabel}
										onChange={(e) => {
											this.handleChange({ rsvpLabel: e.target.value });
										}}
									/>
								}
								<h2 className='blockHeader'>Submission</h2>
								<InputField
									label="Confirmation Page Title"
									required={false}
									placeholder="Form Submitted"
									value={editedForm.successTitle}
									onChange={(e) => {
										this.handleChange({ successTitle: e.target.value });
									}}
								/>
								<RichTextEditor
									id="Confirmation_Page_Description"
									label="Confirmation Page Description"
									placeholder="We have received your information"
									value={editedForm.successSubtitle}
									onChange={(e) => {
										this.handleChange({ successSubtitle: e });
									}}
									customFields={this.state.customFields}

								/>

								<ToggleField
									label="Send Confirmation Email"
									checked={editedForm.confirmationEmailEnabled}
									icons={false}
									onChange={(e) => {
										this.handleChange({ confirmationEmailEnabled: e.target.checked });
									}}
								/>
								{editedForm.confirmationEmailEnabled && (
									<SelectField
										label="Confirmation Email Template"
										options={this.props.availableEmails}
										value={this.props.availableEmails.find(
											(e) => e.value == editedForm.confirmationEmail
										)}
										onChange={(e) => {
											this.handleChange({ confirmationEmail: e.value });
										}}
									/>
								)}

							</div> :
							<div className="sectionBlock mr-25 contained-h">
								<h2 className='blockHeader'>Form Information</h2>
								<ToggleField
									label="Pre-fill form with attendee data"
									checked={editedForm.preFillAttendeeData}
									icons={false}
									onChange={(e) => {
										this.handleChange({ preFillAttendeeData: e.target.checked });
									}}
								/>



								<ToggleField
									label="Show Progress Bar"
									checked={editedForm.progressBarEnabled}
									icons={false}
									onChange={(e) => {
										this.handleChange({ progressBarEnabled: e.target.checked });
									}}
								/>


								<ToggleField
									label="Enable a review page before form submission"
									checked={editedForm.enableSubmissionReview}
									icons={false}
									onChange={(e) => {
										this.handleChange({ enableSubmissionReview: e.target.checked });
									}}
								/>

								{editedForm.enableSubmissionReview &&
									<div>
										<InputField
											label="Review Page Title"
											required={false}
											placeholder="Please Review"
											value={editedForm.submissionReviewTitle}
											onChange={(e) => {
												this.handleChange({ submissionReviewTitle: e.target.value });
											}}
										/>
										{/* Update */}
										<RichTextEditor
											id="Review_Page_Description"
											label="Review Page Description"
											placeholder="Look over your questions and go back if you need to edit."
											value={editedForm.submissionReviewSubtitle}
											onChange={(e) => {
												this.handleChange({ submissionReviewSubtitle: e });
											}}
											customFields={this.state.customFields}
										/>
									</div>
								}

								<h2 className='blockHeader'>Decline Attendance</h2>

								<ToggleField
									label="Allow attendees to decline attendance"
									checked={editedForm.denialEnabled}
									icons={false}
									onChange={(e) => {
										this.handleChange({ denialEnabled: e.target.checked });
									}}
								/>

								{editedForm.denialEnabled &&
									<div>
										<InputField
											label="Decline Button Label"
											required={false}
											placeholder="I am unable to attend"
											value={editedForm.declineLabel}
											onChange={(e) => {
												this.handleChange({ declineLabel: e.target.value });
											}}
										/>

										<InputField
											label="Decline Page Title"
											required={false}
											placeholder="Sorry we missed you"
											value={editedForm.declineTitle}
											onChange={(e) => {
												this.handleChange({ declineTitle: e.target.value });
											}}
										/>

										<RichTextEditor
											id="Decline_Page_Description"
											label="Decline Page Description"
											placeholder="Not attending"
											value={editedForm.declineSubtitle}
											onChange={(e) => {
												this.handleChange({ declineSubtitle: e });
											}}
											customFields={this.state.customFields}

										/>


										<ToggleField
											label="Enable Decline Options"
											checked={editedForm.enableDeclineOptions}
											icons={false}
											onChange={(e) => {
												this.handleChange({ enableDeclineOptions: e.target.checked });
											}}
										/>

										{editedForm.enableDeclineOptions && (
											<div className="pb-20">
												<div className="formFieldLabel">Decline Options</div>
												{editedForm.declineOptions.map((option, index) => {
													return (
														<div className='flex-ac'>
															<InputField
																label="Item"
																required={false}
																placeholder="Not attending"
																value={option}
																onChange={(e) => {
																	editedForm.declineOptions[index] = e.target.value;
																	this.handleChange({ declineOptions: editedForm.declineOptions });
																}}
															/>
															<i className='las la-times ml-10' onClick={() => {
																editedForm.declineOptions.splice(index, 1);
																this.handleChange({ declineOptions: editedForm.declineOptions });
															}}></i>
														</div>
													)
												})}

												<div className="c-pointer flex jcc" onClick={() => {
													editedForm.declineOptions.push('');
													this.handleChange({ declineOptions: editedForm.declineOptions });
												}}>+ Add Decline Options</div>
											</div>
										)

										}

										<ToggleField
											label="Require Decline Options"
											checked={editedForm.requireDeclineOptions}
											icons={false}
											onChange={(e) => {
												this.handleChange({ requireDeclineOptions: e.target.checked });
											}}
										/>

										<ToggleField
											label="Include Decline Comment"
											checked={editedForm.enableDeclineComment}
											icons={false}
											onChange={(e) => {
												this.handleChange({ enableDeclineComment: e.target.checked });
											}}
										/>

										{editedForm.enableDeclineComment &&
											<ToggleField
												label="Require Decline Comment"
												checked={editedForm.requireComment}
												icons={false}
												onChange={(e) => {
													this.handleChange({ requireComment: e.target.checked });
												}}
											/>
										}

									</div>

								}

								<h2 className='blockHeader'>Attendee</h2>

								<ToggleField
									label="Add attendees to a group"
									checked={editedForm.enableGrouping}
									icons={false}
									onChange={(e) => {
										this.handleChange({ enableGrouping: e.target.checked });
									}}
								/>
								{editedForm.enableGrouping &&
									<div>

										<SelectField
											label="Attendee Group"
											options={this.props.groups}
											value={this.props.groups.find(
												(e) => e.value == editedForm.mappedGroup
											)}
											onChange={(e) => {
												this.handleChange({ mappedGroup: e.value });
											}}
										/>

										<InputField
											label="Max Capacity Title"
											required={false}
											placeholder="Registration is at capacity"
											value={editedForm.maxCapTitle}
											onChange={(e) => {
												this.handleChange({ maxCapTitle: e.target.value });
											}}
										/>
										<InputField
											label="Max Capacity Message"
											required={false}
											placeholder="This registration has reached capacity"
											value={editedForm.maxCapacityMessage}
											onChange={(e) => {
												this.handleChange({ maxCapacityMessage: e.target.value });
											}}
										/>
									</div>

								}

								<h2 className='blockHeader'>Form Messages</h2>
								<InputField
									label="Form Closed Title"
									required={false}
									placeholder="Form unavailable"
									value={editedForm.formClosedTitle}
									onChange={(e) => {
										this.handleChange({ formClosedTitle: e.target.value });
									}}
								/>

								<InputField
									label="Form Closed Message"
									required={false}
									placeholder="This form is not currently available, check back at a later time or reach out to support."
									value={editedForm.formClosedMessage}
									onChange={(e) => {
										this.handleChange({ formClosedMessage: e.target.value });
									}}
								/>

							</div>}
						<div style={{ padding: 5, minWidth: 490 }} className='sectionBlock contained-h emailDesignPreview mt-20 '>
							<div
								style={{ width: 590, maxWidth: "calc(100% - 15px)", minWidth: "calc(100% - 700px)", }}
								className="cardDetailsContainerPages containerTitle"
							>

								<div className='w-100'>
									<h2 className='blockHeader mt-0'>Design</h2>
									<h5 className='formFieldLabel mt-10'>Form Preview </h5>

								</div>

								{!isMissingFields ? <button
									className="neu ml-a"
									onClick={this.toggleFormEditorModal}
								>
									Edit Form
								</button> : <button
									className="neu prim ml-a"
									onClick={this.toggleFormEditorModal}
								>Add Form Fields

								</button>}
							</div>
							<div style={{ width: 590, maxWidth: "calc(100% - 15px)", minWidth: "calc(100% - 700px)", height: 617 }}>
								<div
									className="emailPreview eFrame pages flex-ac preview"
								>
									<PagePreviewFrame
										ENV={ENV}
										key={this.state.editCount}
										renderURL={`${isV1 ? `Organizations/${orgId}/events/${eventId}/pages/render/${selectedEmbedPageV1}/` : `Organizations/${orgId}/events/${eventId}/pages/render/formRender/`}`}
										pageProperties={isV1 ? selectedEmbedPagePreview : {}}
										focusedComponent={""}
										simulatedUser={{ value: this.props.account._id }}
										simulatedSession={{ value: false }}
										renderOnly={true}
										formId={isV1 ? null : editedForm._id}
										preview={true}
									/>

								</div>
							</div>
						</div>
					</div>
					: <div className="sectionBody">
						<FormSubmissions event={event} editedForm={editedForm} form={form} />
					</div>}
			</div>
		);
	}

	toggleDuplicateFormModal = () => {
		const { toggleDuplicateFormModal } = this.state;
		this.setState({ toggleDuplicateFormModal: !toggleDuplicateFormModal })
	}

	toggleAddToPage = () => {
		const { toggleAddToPage } = this.state;
		this.setState({ toggleAddToPage: !toggleAddToPage })

	}
	toggleRenameForm = () => {
		const { toggleRenameForm } = this.state;
		this.setState({ toggleRenameForm: !toggleRenameForm })
	}
	toggleScheduleClose = () => {
		const { toggleScheduleClose } = this.state;
		this.setState({ toggleScheduleClose: !toggleScheduleClose })
	}
	toggleCloseNow = () => {
		const { toggleCloseNow } = this.state;
		this.setState({ toggleCloseNow: !toggleCloseNow })
	}

	updateCloseStatus = (properties, callback) => {
		const { form, editedForm } = this.state;
		this.setState({ form: { ...form, ...properties }, editedForm: { ...editedForm, ...properties }, editCount: this.state.editCount + 1 }, () => {

			callback && callback();

		});
	}

	toggleDeleteForm = () => {
		const { toggleDeleteForm } = this.state;
		this.setState({ toggleDeleteForm: !toggleDeleteForm })
	}


	render() {
		const { form,
			loading,
			selectedSection,
			editedForm,
			editing,
			editingFormBuilder,
			toggleDuplicateFormModal,
			selectedEmbedPagePreview,
			toggleAddToPage,
			isV1,
			toggleRenameForm,
			toggleScheduleClose,
			toggleCloseNow,
			toggleDeleteForm } = this.state;

		const { event, orgId, keyedMetadata, keyedData } = this.props;
		let selectedDate = new Date(form.closeAt) ? new Date(form.closeAt) : null;
		let currentTimeDate = new Date(moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a'));

		let isFormClosed = false;
		let formClosedMessage = '';
		if (form.closed) {
			isFormClosed = form.closed;
			formClosedMessage = 'This Form has been closed';
		}
		if (form.closeAt) {
			if (selectedDate <= currentTimeDate) {
				isFormClosed = true;
				formClosedMessage = 'This form is now closed as of ' + moment(selectedDate).tz(event.timezone).format('MM/DD/YYYY hh:mm a');
			} else {
				formClosedMessage = 'This form is scheduled to close on ' + moment(selectedDate).tz(event.timezone).format('MM/DD/YYYY hh:mm a');
			}
		}
		let subActions = form.closeAt && isFormClosed ? [
			{
				label: <span><i className='las la-pen mr-10'></i>Rename Form</span>,
				onClick: this.toggleRenameForm
			},
			{
				label: <span><i className='las la-copy mr-10'></i>Duplicate Form</span>,
				onClick: this.toggleDuplicateFormModal
			},
			{
				label: <span><i className='las la-lock-open mr-10'></i>Open Form Now</span>,
				onClick: this.toggleCloseNow
			},
			{
				label: <span><i className='las la-trash mr-10'></i>Delete Form</span>,
				onClick: this.toggleDeleteForm
			}

		] : isFormClosed ? [
			{
				label: <span><i className='las la-pen mr-10'></i>Rename Form</span>,
				onClick: this.toggleRenameForm
			},
			{
				label: <span><i className='las la-copy mr-10'></i>Duplicate Form</span>,
				onClick: this.toggleDuplicateFormModal
			},
			{
				label: <span><i className='las la-lock-open mr-10'></i>Open Form Now</span>,
				onClick: this.toggleCloseNow
			},
			{
				label: <span><i className='las la-trash mr-10'></i>Delete Form</span>,
				onClick: this.toggleDeleteForm
			}

		] : !isFormClosed ? [
			{
				label: <span><i className='las la-pen mr-10'></i>Rename Form</span>,
				onClick: this.toggleRenameForm
			},
			{
				label: <span><i className='las la-copy mr-10'></i>Duplicate Form</span>,
				onClick: this.toggleDuplicateFormModal
			},
			{
				label: <span><i className='las la-lock mr-10'></i>Close Form Now</span>,
				onClick: this.toggleCloseNow
			},
			{
				label: <span><i className={`${!form.closeAt ? 'las la-calendar-plus' : 'las la-calendar-minus'} mr-10`}></i>{form.closeAt ? 'Unschedule Close Form' : 'Schedule Close Form'}</span>,
				onClick: this.toggleScheduleClose
			},
			{
				label: <span><i className='las la-trash mr-10'></i>Delete Form</span>,
				onClick: this.toggleDeleteForm
			}
		] : []


		return (
			<div className="sectionContainer">
				<SectionHeader
					title={editedForm.name}
					description={`Insightful description**`}
					breadcrumbs={[
						{
							name: event.name
						},
						{
							name: 'Forms'
						}
					]}
					subActions={subActions}
				>
					<TabContainer
						tabs={[{ title: 'Settings' }, { title: 'Advanced' }, { title: 'Submissions' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/>
				</SectionHeader>

				{!loading && !isV1 &&
					<div className='flex aic'>

						<div className="mainActionContainer mt-20" style={{ marginLeft: 'unset' }}>
							<button className={` prim`}
								disabled={false}
								onClick={() => {
									const { organization } = this.props;
									if (!selectedEmbedPagePreview) {
										this.toggleAddToPage();
									}
									else {
										//window.open(`https://${organization.identifier}.simple.events/${event.nameSlug}/${editedPage.pathSlug}`, '_blank');
										this.props.history.push(`/${orgId}/events/${event._id}/pages/${selectedEmbedPagePreview.value}`);
									}
								}}>
								<div>{!selectedEmbedPagePreview ? "Add to Page" : "View Page"}</div>
							</button>

						</div>
						{formClosedMessage && <p className='ml-10 flex aic mt-20'>{formClosedMessage}</p>}
					</div>}
				{!loading && this.renderSection()}
				<SectionFooter updatedAt={form.updatedAt} saveEdit={this.saveForm} cancelEdit={this.cancelEdit} editing={editing} />
				<FormBuilderModal
					isOpen={this.state.formEditorModalOpen}
					editing={editingFormBuilder}
					toggle={this.toggleFormEditorModal}
					form={form}
					editedForm={editedForm}
					fields={this.props.fields}
					baseFieldList={baseFieldList}
					links={{ get: `` }}
					updateForm={this.updateForm}
					cancelEdit={this.cancelEdit}
					emails={this.props.availableEmails}
					orgId={this.props.orgId}
					eventId={this.props.eventId}
					addNewField={this.props.addNewField}
					saveForm={this.saveForm}
					customFields={this.state.customFields}
				/>

				{toggleRenameForm && <RenameFormModal
					toggle={this.toggleRenameForm}
					isOpen={toggleRenameForm}
					name={form.name}
					formId={form._id}
					form={editedForm}
					eventId={event._id}
					orgId={orgId}
					update={this.updateFormName}
				/>}

				{toggleAddToPage &&
					<AddToPage
						toggle={this.toggleAddToPage}
						isOpen={toggleAddToPage}
						pages={this.state.pages}
						updateAddToPage={this.updateAddToPage}
					/>}

				{toggleDuplicateFormModal && <DuplicateFormModal
					toggle={this.toggleDuplicateFormModal}
					isOpen={toggleDuplicateFormModal}
					name={form.name}
					formId={form._id}
					eventId={event._id}
					orgId={orgId}

				/>}

				{toggleCloseNow &&
					<CloseFormNowModal
						toggle={this.toggleCloseNow}
						isOpen={toggleCloseNow}
						formId={form._id}
						eventId={event._id}
						updateCloseStatus={this.updateCloseStatus}
						orgId={orgId}
						isFormClosed={isFormClosed}
					/>
				}
				{toggleScheduleClose &&
					<ScheduleCloseFormModal
						toggle={this.toggleScheduleClose}
						isOpen={toggleScheduleClose}
						formId={form._id}
						eventId={event._id}
						orgId={orgId}
						isScheduled={form.closeAt}
						event={event}
						updateCloseStatus={this.updateCloseStatus}
					/>
				}

				{toggleDeleteForm && (
					<DeleteFormModal
						isOpen={toggleDeleteForm}
						delete={this.deleteCheckedForms}
						form={form}
						toggle={this.toggleDeleteForm}
						count={1}
						isMultiple={false}
						removeForm={this.props.deleteSingleForm}
					/>
				)}
			</div>
		);
	}
}

export default withRouter(Form);
