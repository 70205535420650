import React, { Component, Fragment } from 'react';
import LoadingWizard from '../spinner/wizard';
import InputField from '../inputs/inputField';
import { Draggable, Droppable } from 'react-beautiful-dnd';



class EmailComponentLibrary extends Component {
    constructor(props) {
        super(props);

        const componentLists = [

            {
                key: 'navbar',
                title: 'Navbar',
                icon: ''
            },
            {
                key: 'image',
                icon: '',
                title: 'Image',
            },
            {
                key: 'hairline',
                icon: '',
                title: 'Hairline',
            },
            {
                key: 'contentblock',
                icon: '',
                title: 'Content Block',
            },
            {
                key: 'text',
                icon: '',
                title: 'Text Block',
            },
            {
                key: 'locationblock',
                icon: '',
                title: 'Location Block',
            },
            {
                key: 'footer',
                icon: '',
                title: 'Footer',
            },
            {
                key: 'cta',
                icon: '',
                title: 'CTA Button',
            },

            {
                key: 'columnlayout',
                icon: '',
                title: 'Column Layout',
            },
            {
                key: 'tablecolumns',
                icon: '',
                title: 'Table Columns',
            },
            {
                key: 'tablegrid',
                icon: '',
                title: 'Table Grid',
            },
            {
                key: 'sideimage',
                icon: '',
                title: 'Side Image',
            },
            {
                key: 'heading',
                icon: '',
                title: 'Heading',
            },
            {
                key: 'banner',
                icon: '',
                title: 'Banner',
            },
            /*    
 
           {
               key: 'agenda',
               icon: '',
               title: 'Agenda',
           },
 
           {
               key: 'sideimageblock',
               icon: '',
               title: 'Side Image Block',
           }, 
           */

        ]
        this.state = {
            componentLists: componentLists,
            searchInput: '',
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({ loading: false })
    }



    render() {
        const { togglePanel } = this.props;
        const { componentLists, searchInput, loading } = this.state;
        return (
            <Fragment>
                {/* <div className='flex jcsb aic mt-10'>
                    <h2>Add Components</h2>
                    <button onClick={togglePanel}><i className='las la-times'></i></button>
                </div>
                <p className=' mb-20'>Drag components to the components panel or directly into the canvas to add component</p> */}

                {!loading ? <div className='componentLibrary'>

                    <InputField
                        placeholder="Search components"
                        value={searchInput}
                        onChange={(e) => this.setState({ searchInput: e.target.value })}
                        inputIcon={searchInput.length > 0 ? <i className="las la-times-circle c-pointer"
                            onClick={() => this.setState({ searchInput: '' })} /> : <i className="las la-search" />}
                    />

                    <div >
                        <div className='mb-20' style={{ overflow: 'auto' }}>

                            <Droppable droppableId="droppable-components">
                                {(provided, snapshot) => (<div
                                    ref={provided.innerRef} className="fieldListContainer">
                                    {componentLists.map((component, index) => {
                                        const { title, key, icon } = component;
                                        return title.toLowerCase().includes(searchInput.toLowerCase()) && (<Draggable
                                            key={`${key}`}
                                            draggableId={`${key}`}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps} className={`fieldDraggable  ${true ? 'selected' : ''}`}
                                                >
                                                    <i className="las la-braille mr-5" />

                                                    <div>
                                                        <h4>{title}</h4>

                                                    </div>
                                                </div>)}
                                        </Draggable>)
                                    })}
                                </div>)}
                            </Droppable>
                        </div>
                    </div>

                </div> : <LoadingWizard loading={loading} text="fetching components" />}
            </Fragment>
        );
    }
}

export default EmailComponentLibrary;
