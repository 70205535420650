import React, { Component } from 'react';

class CheckboxInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			eyebrowEnable: false,

		};
	}
	onMouseEnter = () => {
		this.setState({ eyebrowEnable: true })
	}
	onMouseLeave = () => {
		this.setState({ eyebrowEnable: false })
	}
	render() {
		const { key, label, onClick, checked, classes, unsetHeight, eyebrow, left, style, disableHover, disabled } = this.props;
		const { eyebrowEnable } = this.state;
		return (
			<div
				style={style ? style : {}}
				onMouseLeave={eyebrow && this.onMouseLeave}
				onMouseEnter={eyebrow && this.onMouseEnter}
				key={`colToggle-${key}`}
				className={`checkboxInput ${disableHover ? 'disableHover' : ''} ${classes ? classes : ''} ${eyebrowEnable ? 'eyebrowEnabledContainer' : 'eyebrowDisabledContainer'}`}
				onClick={!disabled && onClick}
			>
				{left &&
					<div className={`checkBox mr-10 ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
						<i className="las la-check" />
					</div>}
				<div>
					{eyebrow && <p className={` ${eyebrowEnable ? ' small eyebrowEnabled ' : 'eyebrowDisabled'}`}
					>
						{eyebrowEnable && eyebrow}
					</p>}
					<p className='mb-0'>
						{label}
					</p>
				</div>
				{!left && <div className={`checkBox ml-a ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
					<i className="las la-check" />
				</div>}
			</div>
		);
	}
}

export default CheckboxInput;
