import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import API from '../../utils/API';
import FieldModal from "./fieldModal";
import InputField from "../inputs/inputField";
import LoadingWizard from "../spinner/wizard";

class GlobalSearchModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            searching: false,
            searchOrg: false,
            results: [],
            searchResultOptions: {
                organization: {
                    events: {
                        label: 'Events',
                        options: [],
                    },
                    programs: {
                        label: 'Programs',
                        options: [],
                    },
                    contacts: {
                        label: 'Contacts',
                        options: [],
                    },
                    pages: {
                        label: 'Pages',
                        options: [],
                    },
                    forms: {
                        label: 'Forms',
                        options: [],
                    },
                    media: {
                        label: 'Media',
                        options: [],
                    }
                },
                event: {
                    attendees: {
                        label: 'Attendees',
                        options: [],
                    },
                    sessions: {
                        label: 'Sessions',
                        options: [],
                    },
                    groups: {
                        label: 'Groups',
                        options: [],
                    },
                    inventory: {
                        label: 'Inventory',
                        options: [],
                    },
                    emails: {
                        label: 'Email Campaigns',
                        options: [],
                    },
                    texting: {
                        label: 'Text Campaigns',
                        options: [],
                    },
                    forms: {
                        label: 'Forms',
                        options: [],
                    },
                    pages: {
                        label: 'Pages',
                        options: [],
                    },
                    media: {
                        label: 'Media',
                        options: [],
                    }
                },
            }
        };
    }

    searchPlatform = async () => {
        const { search, searchOrg } = this.state;
        const { organization, event } = this.props;
        if (search.length > 1) {
            this.setState({ searching: true });
            if (event && !searchOrg) {
                const results = await API().post(`/platformActions/search/${organization._id}/${event._id}`, { search }).catch(err => console.log(err));
                const searchResultOptions = this.state.searchResultOptions;
                const eventResultOptions = [];
                results.data && results.data.event && Object.keys(results.data.event).forEach(key => {
                    eventResultOptions.push({
                        label: searchResultOptions.event[key].label, options: results.data.event[key].map(item => {
                            return {
                                value: key === "media" ? item.url : item._id,
                                label: key === 'attendees' ? `${item.firstName} ${item.lastName} [${item.email}]` : key === 'pages' ? item.title : item.name,
                                type: key,
                                isEvent: true
                            }
                        })
                    });
                });
                this.setState({ results: eventResultOptions, searching: false });
            } else {
                const results = await API().post(`/platformActions/search/${organization._id}/false`, { search });
                const searchResultOptions = this.state.searchResultOptions;
                const orgResultOptions = [];
                results.data && results.data.organization && Object.keys(results.data.organization).forEach(key => {
                    orgResultOptions.push({
                        label: searchResultOptions.organization[key].label, options: results.data.organization[key].map(item => {
                            return {
                                value: key === "media" ? item.url : item._id,
                                label: key === 'contacts' ? `${item.firstName} ${item.lastName} (${item.emailAddress})` : key === 'pages' ? item.title : item.name,
                                type: key,
                                isEvent: false
                            }
                        })
                    });
                }
                );
                this.setState({ results: orgResultOptions, searching: false });
            }
        } else if (search.length === 0) {
            this.setState({ results: [], searching: false });
        }
    }

    onResultSelected = (e) => {
        const { organization, event } = this.props;
        const { type, isEvent } = e;
        if (isEvent) {
            if (type === 'attendees') {
                this.props.history.push(`/${organization._id}/events/${event._id}/attendees/${e.value}`);
            } else if (type === 'sessions') {
                this.props.history.push(`/${organization._id}/events/${event._id}/session/${e.value}`);
            } else if (type === 'groups') {
                this.props.history.push(`/${organization._id}/events/${event._id}/groups/${e.value}`);
            } else if (type === 'inventory') {
                this.props.history.push(`/${organization._id}/events/${event._id}/inventory/${e.value}`);
            } else if (type === 'emails') {
                this.props.history.push(`/${organization._id}/events/${event._id}/emails/${e.value}`);
            } else if (type === 'texting') {
                this.props.history.push(`/${organization._id}/events/${event._id}/texting/${e.value}`);
            } else if (type === 'forms') {
                this.props.history.push(`/${organization._id}/events/${event._id}/forms/${e.value}`);
            } else if (type === 'pages') {
                this.props.history.push(`/${organization._id}/events/${event._id}/pages/${e.value}`);
            } else if (type === 'media') {
                window.open(e.value, '_blank');
            }

        } else {
            if (type === 'contacts') {
                this.props.history.push(`/${organization._id}/contacts/${e.value}`);
            } else if (type === 'events') {
                this.props.history.push(`/${organization._id}/events/${e.value}/overview`);
            } else if (type === 'programs') {
                this.props.history.push(`/${organization._id}/programs/${e.value}/details`);
            } else if (type === 'forms') {
                this.props.history.push(`/${organization._id}/forms/${e.value}`);
            } else if (type === 'pages') {
                this.props.history.push(`/${organization._id}/pages/${e.value}`);
            } else if (type === 'media') {
                window.open(e.value, '_blank');
            }
        }
        this.props.toggle();
    }

    render() {
        const { isOpen, toggle, event } = this.props;
        const { search, searching, results } = this.state;
        return (
            <FieldModal
                size="large" isOpen={isOpen} toggle={toggle} isFullWidthBody={true}
                bodyContent={() => {
                    return <div className="globalSearchContainer">
                        <InputField name="search" placeholder={event ? "Search Event..." : "Search Organization..."} value={search}
                            onChange={(e) => {
                                this.setState({ search: e.target.value }, this.searchPlatform);
                            }}
                            classes="searchModalInput"
                        />
                        <div className="searchList">
                            {searching && <div className=""><LoadingWizard text="Searching" loading={searching} /></div>}
                            {results.length > 0 && results.map((result, i) => {
                                const category = result.label;
                                return result.options.length > 0 && <div key={result.value}>
                                    <div className="flex aic mb-10">
                                        <h1 className="blockHeader">{category}</h1>
                                        <hr />
                                    </div>
                                    <div className="searchOptionlist">

                                        {result.options.map((option, j) => {
                                            return <button key={j} className="searchOption" onClick={() => this.onResultSelected(option)}>{option.label}</button>
                                        })}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                }}
            />
        );
    }
}

export default withRouter(GlobalSearchModal);