import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../../utils/API';

import ViewsContainer from '../../../components/views/ViewsContainer';
import InventoryItem from './inventoryItem';
import AddInventoryModal from './modals/addInventoryModal';
import DeleteInventoryItem from './modals/deleteInventoryModal'
import LoadingWizard from '../../../components/spinner/wizard';

let batchCallback;
const statusOptions = [
	{
		value: '',
		label: ''
	},
	{
		value: 'Estimate',
		label: 'Estimate'
	},
	{
		value: 'Ordered',
		label: 'Ordered'
	},
	{
		value: 'Shipped',
		label: 'Shipped'
	},
	{
		value: 'Delivered',
		label: 'Delivered'
	}
];
class EventInventory extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			category: 'Category',
			vendor: 'Vendor',
			status: 'Status',
			referenceNumber: 'Reference Number',
			quantity: 'Quantity',
			price: 'Price',
			total: 'Total',
			trackingNumber: 'Tracking Number',
			arrivalDate: 'Arrival Date',
			postEventPlan: 'Post Event Plan',
			updatedAt: 'Updated At'
		};
		const defaultFieldTypes = {
			name: 'text',
			category: 'text',
			vendor: 'text',
			status: 'text',
			referenceNumber: 'text',
			quantity: 'number',
			price: 'currency',
			total: 'currency',
			trackingNumber: 'text',
			arrivalDate: 'date',
			postEventPlan: 'text',
			updatedAt: 'timestamp'
		};

		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'category',
					'vendor',
					'status',
					'referenceNumber',
					'quantity',
					'price',
					'total',
					'trackingNumber',
					'arrivalDate',
					'postEventPlan',
					'updatedAt',
				]
			},
		};

		this.state = {
			categoryColumns: fieldCategories,
			selectedItem: 0,
			addInventoryModalOpen: false,
			inventoryViews: [],
			checkedInventory: {},
			loading: true,
			deleteInventoryModalOpen: false,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			inventory: [],
			toggleAddInventory: false,
			toggleDeleteInventory: false,
			sidePanelOpen: false,
			editCount: 0,
			activeRow: ''
		};
	}

	componentDidMount() {
		const { eventId, orgId } = this.props;
		API().get(`Organizations/${orgId}/events/${eventId}/inventory`).then((res) => {
			if (res.data) {
				const inventoryViews = res.data.inventoryViews;
				let inventory = res.data.inventory.map((item) => {
					item.link = `/${this.props.orgId}/events/${eventId}/inventory/${item._id}`;
					item.total = item.quantity * item.price;
					return item;
				});
				const metadataOptions = [];
				Object.keys(res.data.keyedData).forEach((key) => {
					const layer1 = res.data.keyedData[key];
					if (typeof layer1 === 'object') {
						Object.keys(layer1).forEach((key2) => {
							const layer2 = layer1[key2];
							if (typeof layer2 === 'object') {
								Object.keys(layer2).forEach((key3) => {
									metadataOptions.push(`${key}.${key2}.${key3}`);
								});
							} else {
								metadataOptions.push(`${key}.${key2}`);
							}
						});
					} else {
						metadataOptions.push(`${key}`);
					}
				});
				this.setState({
					inventory: inventory,
					loading: false,
					keyedMetadata: metadataOptions,
					inventoryViews: inventoryViews
				});
			}
		});
	}

	addInventory = async (item, callback) => {
		const { orgId } = this.props;
		const eventId = this.props.eventId;

		await API().post(`Organizations/${orgId}/events/${eventId}/inventory`, item).then((res) => {
			if (res.data) {
				const itemObj = res.data;
				itemObj.link = `/${this.props.orgId}/events/${eventId}/inventory/${itemObj._id}`;
				this.setState(
					{
						inventory: [...this.state.inventory, itemObj],
						addInventoryModalOpen: false,
						editCount: this.state.editCount + 1

					},
					() => {
						batchCallback && batchCallback('update')
						this.props.refreshSectionCounts()
						callback(itemObj._id);
					}
				);
			}
		});
	};

	deleteCheckedEmails = async (emails) => {
		const { eventId, orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/emails`, {
				emails: Object.keys(this.state.checkedEmails)
			})
			.then((res) => {
				if (res.data) {
					let tempChecked = JSON.parse(JSON.stringify(this.state.checkedEmails));

					batchCallback && batchCallback('delete');
					this.setState({
						addEmailModalOpen: false,
						deletedEmails: [...this.state.deletedEmails, ...Object.keys(tempChecked)],
						sortedEmails: this.state.sortedEmails.filter(
							(e) => !Object.keys(this.state.checkedEmails).includes(e.id)
						),
						deleteEmailsModalOpen: false,
						editCount: this.state.editCount + 1

					});
				}
			});

		this.setState({
			checkedEmails: {}
		});
	};

	removeEmail = async (emailId, callback) => {
		const { eventId, orgId } = this.props;
		await API().delete(`Organizations/${orgId}/events/${eventId}/email/${emailId}`).then((res) => {
			if (res.data) {
				this.state.deletedEmails.push(emailId);
				this.setState(
					{
						addEmailModalOpen: false,
						deletedEmails: this.state.deletedEmails,
						sortedEmails: this.state.sortedEmails.filter((e) => e.id !== emailId),
						deleteEmailsModalOpen: false,
						editCount: this.state.editCount + 1

					},
					() => {
						callback();

					}
				);
			}
		});

		this.setState({
			checkedEmails: {}
		});
	};

	deleteInventories = async () => {
		const { eventId, orgId } = this.props;
		const { checkedInventory, inventory } = this.state;
		await API().patch(`Organizations/${orgId}/events/${eventId}/deleteInventories`, { inventoryIds: Object.keys(checkedInventory) }).then((res) => {
			let result = inventory.filter(i => !Object.keys(checkedInventory).includes(i._id));
			this.setState({
				inventory: result,
				editCount: this.state.editCount + 1
			},
				() => {
					batchCallback && batchCallback('update');
					this.props.refreshSectionCounts()
					this.toggleDeleteInventory()
				})
		});
		this.setState({
			checkedEmails: {}
		});
	}
	deleteSingleItem = (itemId, callback) => {
		const { inventory } = this.state;
		let updatedItem = inventory.filter((item) => item._id !== itemId);
		this.setState({ inventory: updatedItem, toggleDeleteInventory: false, checkedInventory: {} }, () => {
			callback && callback()
		});
	}
	updateInventory = (updatedItem) => {
		const updatedInventory = this.state.inventory.map((item) => {
			if (item._id === updatedItem._id) {
				return updatedItem;
			}
			return item;
		});
		this.setState({
			inventory: updatedInventory,
			editCount: this.state.editCount + 1
		});
	};

	toggleDeleteInventory = (checkedInventory, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedInventory: checkedInventory || {},
			toggleDeleteInventory: !this.state.toggleDeleteInventory
		});
	};

	toggleAddInventory = (cb) => {
		const { toggleAddInventory } = this.state;
		batchCallback = cb;
		this.setState({ toggleAddInventory: !toggleAddInventory })
	}
	setActiveRow = (inventoryId) => {
		this.setState({ activeRow: inventoryId })
	}


	render() {
		const { loading, categoryColumns, inventory, inventoryViews, columnTypes, columns, toggleAddInventory, toggleDeleteInventory, sidePanelOpen,
			activeRow } = this.state;
		const { eventId, event, orgId } = this.props;
		return (
			<Switch>
				<Route path={`/:organization/events/:eventId/inventory`}>

					<Fragment>
						{!loading && <ViewsContainer
							activeRow={activeRow}
							//key={`inventory${editCount}`}
							defaultSort={'name'}
							categoryColumns={categoryColumns}
							orgId={orgId}
							columns={columns}
							columnTypes={columnTypes}
							views={inventoryViews}
							entries={this.state.inventory}
							title="Inventory"
							description="View and manage your event inventory. Track Vendors, quantity, cost, tracking numbers and delivery dates."
							readOnly={false}
							mainActions={[
								{ label: 'Add Inventory', onClick: this.toggleAddInventory, type: 'button' }
							]}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteInventory
								}
							]}
							breadcrumbs={[
								{ name: event.name }
							]}
							enableDownloadViews={true}
							type={'Inventory'}
							referenceCollection={'Events'}
							referenceId={eventId}
						/>}
						{toggleAddInventory &&
							<AddInventoryModal
								eventId={eventId}
								handleSubmit={this.addInventory}
								isOpen={true}
								toggle={this.toggleAddInventory}
							/>
						}
						{toggleDeleteInventory &&
							<DeleteInventoryItem isOpen={toggleDeleteInventory}
								toggle={this.toggleDeleteInventory}
								deleteInventories={this.deleteInventories}
							/>}
						<LoadingWizard text="Loading Inventory" loading={loading} />


						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/events/:eventId/inventory/:inventoryId`}
									render={(props) => {
										const inventoryId = props.match.params.inventoryId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={inventoryId}>

											{!loading && <InventoryItem
												event={this.props.event}
												eventId={eventId}
												orgId={orgId}
												inventory={inventory}
												updateInventory={this.updateInventory}
												removeEmail={this.removeEmail}
												statusOptions={statusOptions}
												setActiveRow={this.setActiveRow}
												deleteSingleItem={this.deleteSingleItem}
											/>}
											<LoadingWizard loading={loading} />
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/events/:eventId/inventory`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>

						<LoadingWizard loading={loading} text="Loading Inventory" />
					</Fragment>

				</Route>

			</Switch>
		);
	}
}

export default withRouter(EventInventory);
