import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../utils/API';
import SectionHeader from '../../components/views/SectionHeader';
import LoadingWizard from '../../components/spinner/wizard';
import InputField from '../../components/inputs/inputField';
import PagePreviewFrame from '../../views/pages/components/pagePreviewFrame';
import SelectField from '../../components/inputs/selectField';
import PageEditor from '../../components/modals/pageEditor';
import SectionFooter from '../../components/views/SectionFooter';
import DeletePagesModal from './modals/deleteAccountPagesModal';

import RenameAccountPageModal from './modals/renameAccountPageModal';

import UnpublishPageModal from './modals/unpublishAccountPageModal';

let batchCallback;


const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;

const pageTypeOptions = [
    { value: false, label: 'General Page' },
    { value: true, label: 'Session Template' },
]

const pageAvailabilityOptions = [
    { value: true, label: 'Public (Available to anyone)' },
    { value: false, label: 'Private (Only for logged in users)' }
]
class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorOpen: false,
            selectedPage: null,
            loading: false,
            addPageModalOpen: false,
            previewOpen: true,
            detailsOpen: true,
            page: {},
            editedPage: {},
            selectedSection: 0,
            loading: true,
            deletePageModalTrigger: false,
            checkedPages: [],
            editCount: 0,
            toggleUnpublishPage: false,
            toggleRenamePage: false,
            programOptions: [],
        };

    }

    componentWillMount() {
        const { orgId } = this.props;
        const { match: { params } } = this.props;
        this.setState({ loading: true });

        API()
            .get(`Organizations/${this.props.orgId}/programs`)
            .then((res) => {
                if (res.data) {
                    this.setState({
                        programOptions: res.data.programs.map(p => {
                            return {
                                value: p._id,
                                label: p.name
                            }
                        })
                    });
                }
            })
        API().get(`Organizations/${orgId}/accountPages/${params.page}`).then((res) => {
            if (res.data) {
                res.data.pathSlug = res.data.slug;
                this.setState({ page: { ...res.data }, editedPage: { ...res.data }, loading: false });
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { match: { params } } = this.props;
        if (params.page !== prevProps.match.params.page) {
            this.setState({ loading: true });
            API().get(`Organizations/${this.props.orgId}/accountPages/${params.page}`).then((res) => {
                if (res.data) {
                    res.data.pathSlug = res.data.slug;
                    this.setState({ page: { ...res.data }, editedPage: { ...res.data }, loading: false });
                }
            });
        }
    }

    refreshIframe = () => {
        this.setState({ previewUrl: '' }, () => this.setState({ previewUrl: this.props.page.url }));
    };

    savePage = async () => {
        const { page } = this.state;
        const { orgId } = this.props;
        await API()
            .patch(`Organizations/${orgId}/accountPages/${page._id}`, this.state.editedPage)
            .then((res) => {
                if (res.data) {
                    this.setState({ page: { ...res.data }, editedPage: { ...res.data }, loading: false, editing: false });
                }
            })
            .catch((e) => {
                this.setState({ loading: false, editing: false });
            });
    };

    updatePage = async (updatedPage) => {
        this.setState({ editedPage: updatedPage, page: { ...updatedPage }, editCount: this.state.editCount + 1 });
    };

    publishPage = (published, callback) => {
        const { orgId, eventId } = this.props;
        const { page } = this.state;
        API().post(`Organizations/${orgId}/pages/${page._id}/publish`, { published: published }).then((res) => {
            if (res.data) {
                this.setState({ editedPage: res.data, page: { ...res.data }, loading: false, editing: false }, () => {
                    callback && callback();
                });
            }
        });
    };

    togglePageBuilder = () => {
        this.setState({ editorOpen: !this.state.editorOpen });
    };

    cancelEdit = () => {
        this.setState({ editedPage: { ...this.state.page }, editing: false });
    };

    deletePages = async () => {
        const { page } = this.state;
        await API()
            .post(`Organizations/${this.props.orgId}/accountPages/delete`, {
                pageIds: [page._id]
            })
            .then((res) => {
                this.props.history.push(`/${this.props.orgId}/pages`);
            });
    };
    toggleSingleDelete = () => {
        this.setState({
            deletePageModalTrigger: !this.state.deletePageModalTrigger
        });
    };
    checkEntry = (entryId) => {
        const checked = this.state.checkedPages;

        checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
        this.setState({
            checkedPages: checked
        });

    };
    toggleRenamePageModal = () => {
        const { toggleRenamePage } = this.state;
        this.setState({ toggleRenamePage: !toggleRenamePage });
    };

    toggleUnpublishPageModal = () => {
        const { toggleUnpublishPage } = this.state;
        this.setState({ toggleUnpublishPage: !toggleUnpublishPage });
    }

    updateTitle = (title, callback) => {
        this.setState({ page: { ...this.state.page, title: title }, editedPage: { ...this.state.editedPage, title: title } }, () => {
            callback && callback();
        });
    }

    renderSection() {
        const {
            selectedSection,
            editedPage,
            editorOpen,
            programOptions
        } = this.state;
        const { organization, orgId } = this.props;
        switch (selectedSection) {
            case 0:
                return (
                    <div className='sectionBody'>

                        <div className='blocksContainer'>
                            <div className='sectionBlock mr-25 contained-h'>
                                <h2 className='blockHeader'>Basic Information</h2>
                                {/*  <InputField
                                    label="Title"
                                    required={true}
                                    placeholder="Appears as browser tab title"
                                    value={editedPage.title}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, title: e.target.value },
                                            editing: true
                                        });
                                    }}
                                /> */}
                                <InputField
                                    label="Description"
                                    required={true}
                                    placeholder="Displays in page previews when link is shared"
                                    value={editedPage.description}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, description: e.target.value },
                                            editing: true
                                        });
                                    }}
                                />

                                <SelectField
                                    label="Page Type"
                                    required={true}
                                    classes="w-100"
                                    placeholder="Page type"
                                    value={pageTypeOptions[0]}
                                />

                                <InputField
                                    //prefix={`https://${organization.identifier}.simple.events/0/`}
                                    prefix={`https://${organization.hostname ? organization.hostname : (organization.identifier + '.simple.events')}/0/`}
                                    label="Address"
                                    required={true}
                                    placeholder="Unique slug for this page"
                                    value={editedPage.slug}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, slug: e.target.value, pathSlug: e.target.value },
                                            editing: true
                                        });
                                    }}
                                />
                                <SelectField
                                    label="Availability"
                                    required={true}
                                    classes="w-100"
                                    placeholder="Page availability"
                                    value={editedPage.openToPublic ? pageAvailabilityOptions[0] : pageAvailabilityOptions[1]}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, openToPublic: e.value },
                                            editing: true
                                        });
                                    }}
                                    options={pageAvailabilityOptions}
                                />

                                <SelectField
                                    label="Program"
                                    required={false}
                                    classes="w-100"
                                    placeholder="No Program"
                                    value={editedPage.programId ? programOptions.find(p => p.value == editedPage.programId) : null}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, programId: e.value },
                                            editing: true
                                        });
                                    }}
                                    options={programOptions}
                                />
                                {/*   <h2 className='blockHeader'>
                                    Page Style
                                </h2>

                                <div className="editorFileInput">
                                    <FileChooserPreview
                                        label="Background Image"
                                        value={editedPage.backgroundImage}
                                        clearFile={() =>
                                            this.setState({
                                                editing: true,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage: ''
                                                },
                                                editCount: this.state.editCount + 1
                                            })}
                                        onClick={(e) =>
                                            this.setState({
                                                editCount: this.state.editCount + 1,
                                                editing: true,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage:
                                                        'choosing__' + editedPage.backgroundImage
                                                }
                                            })}
                                    />
                                    <FilesUploaderModal
                                        links={{
                                            getAssets: `Organizations/${orgId}/assets`,
                                            addAssets: `Organizations/${orgId}/assets`,
                                        }}
                                        isOpen={
                                            editedPage.backgroundImage &&
                                            editedPage.backgroundImage.includes('choosing__')
                                        }
                                        toggle={() => {
                                            this.setState({
                                                editing: true,
                                                editCount: this.state.editCount + 1,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage: editedPage.backgroundImage.replace(
                                                        'choosing__',
                                                        ''
                                                    )
                                                }
                                            });
                                        }}
                                        orgId={this.props.orgId}
                                        addFiles={(fileURL) => {
                                            this.setState({
                                                editCount: this.state.editCount + 1,
                                                editing: true,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage: fileURL
                                                }
                                            });
                                        }}
                                    />
                                </div>

                                <ColorField
                                    label="Background Overlay"
                                    value={editedPage.backgroundOverlay}
                                    onChange={(e) => {
                                        this.setState({
                                            editing: true,
                                            editedPage: {
                                                ...this.state.editedPage,
                                                backgroundOverlay: e
                                            },
                                            editCount: this.state.editCount + 1
                                        });
                                    }}
                                />
                                <div className="popoverButton">
                                    <div
                                        className="actionButton actionOutline w-100"
                                        onClick={() => this.setState({ customCSSModalOpen: true })}
                                    >
                                        <i className="las la-brush mr-5" />
                                        <div>
                                            {editedPage.customCSS &&
                                                editedPage.customCSS.length > 0 ? (
                                                'Edit'
                                            ) : (
                                                'Add'
                                            )}{' '}
                                            Custom Page CSS
                                        </div>
                                    </div>
                                    <div className="pageConfigPopover">
                                        <div className="pageConfigPopoverContent">
                                            <CodeEditor
                                                className={`displayedField2`}
                                                placeholder="Add your custom CSS here"
                                                content={editedPage.customCSS}
                                                language="css"
                                                style={{
                                                    marginLeft: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    minHeight: '100%'
                                                }}
                                                setContent={(c) =>
                                                    this.setState({
                                                        editing: true,
                                                        editCount: this.state.editCount + 1,
                                                        editedPage: {
                                                            ...this.state.editedPage,
                                                            customCSS: c
                                                        }
                                                    })}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='sectionBlock emailDesignPreview mt-20'>

                                <div
                                    className="cardDetailsContainerPages containerTitle"
                                >

                                    <div className='w-100'>
                                        <h2 className='blockHeader mt-0'>Design</h2>
                                        <h5 className='formFieldLabel mt-10'> Web Page Design </h5>
                                    </div>


                                    {editedPage.partials.length > 0 ? <button
                                        disabled={!editedPage.pathSlug}
                                        className={`neu min ml-a`}
                                        onClick={this.togglePageBuilder}
                                    >
                                        Edit Design
                                    </button> :

                                        <button
                                            disabled={!editedPage.pathSlug}
                                            className={`neu min ml-a prim`}
                                            onClick={this.togglePageBuilder}
                                        >
                                            Create Design
                                        </button>}
                                </div>

                                <div
                                    className="emailPreview eFrame flex-ac preview"
                                >
                                    {!editorOpen && <PagePreviewFrame
                                        ENV={ENV}
                                        renderURL={`Organizations/${orgId}/accountPages/render/${editedPage._id}/`}
                                        preview={true}
                                        pageProperties={editedPage}
                                        focusedComponent={""}
                                        simulatedUser={{ value: this.props.account._id }}
                                        simulatedSession={{ value: false }}
                                        renderOnly={true}
                                        editing={this.state.editCount}
                                        key={this.state.editCount}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className='sectionBody'>

                    </div>
                );
            default:
                return null;
        }
    }

    render() {

        const {
            loading,
            editing,
            page,
            editedPage,
            editorOpen,
            deletePageModalTrigger,
            toggleRenamePage,
            toggleUnpublishPage
        } = this.state;
        const { orgId } = this.props;
        let currentPage = {};
        currentPage[editedPage._id] = true;
        const isDraft = page.status == 'draft' ? true : false;
        return (
            <div className="sectionContainer  pageContainer">
                <SectionHeader
                    title={page.title}
                    description={page.status}
                    status={page.status}
                    breadcrumbs={[
                        {
                            name: 'Account Pages',
                        }
                    ]}
                    subActions={
                        page.status != "published" ?
                            (
                                [
                                    {
                                        onClick: this.toggleRenamePageModal,
                                        label: 'Rename Page',
                                    },
                                    {
                                        onClick: this.toggleSingleDelete,
                                        label: 'Delete Page',
                                    },

                                ]
                            ) :

                            (
                                [
                                    {
                                        onClick: this.toggleRenamePageModal,
                                        label: 'Rename Page',
                                    },
                                    {
                                        onClick: this.toggleSingleDelete,
                                        label: 'Delete Page',
                                    },
                                    {
                                        onClick: this.toggleUnpublishPageModal,
                                        label: 'Unpublish Page',
                                    },

                                ]
                            )
                    }
                >
                    <div className='tabsContainer unsetWidth'></div>

                </SectionHeader>

                {!loading && <div className="actionButtonContainer mt-20" style={{ marginLeft: 'unset' }}>
                    <div >
                        <button className={`mainAction ${editing ? 'disabled' : 'prim'}`}
                            disabled={editing}
                            onClick={() => {
                                const { organization, } = this.props;
                                if (isDraft) {
                                    this.publishPage(isDraft);
                                }
                                else {
                                    if (organization.hostname) {
                                        window.open(`https://${organization.hostname}/0/${editedPage.pathSlug}`, '_blank');
                                    } else {
                                        window.open(`https://${organization.identifier}.simple.events/0/${editedPage.pathSlug}`, '_blank');
                                    }
                                }
                            }}>
                            <div>{isDraft ? "Publish Page" : "View Page"}</div>
                        </button>
                    </div>
                </div>}
                {!loading && this.renderSection()}
                <SectionFooter updatedAt={page.updatedAt} saveEdit={this.savePage} cancelEdit={this.cancelEdit} editing={editing} />
                <LoadingWizard text="Loading Page" loading={loading} />
                {toggleRenamePage && (
                    <RenameAccountPageModal
                        isOpen={toggleRenamePage}
                        page={editedPage}
                        orgId={orgId}
                        toggle={this.toggleRenamePageModal}
                        updateTitle={this.updateTitle}
                    />
                )}
                {toggleUnpublishPage && (
                    <UnpublishPageModal
                        isOpen={toggleUnpublishPage}
                        page={editedPage}
                        orgId={orgId}
                        toggle={this.toggleUnpublishPageModal}
                        update={this.updatePage}
                        publishPage={this.publishPage}
                    />
                )}
                {deletePageModalTrigger && (
                    <DeletePagesModal
                        isOpen={deletePageModalTrigger}
                        toggle={() => this.toggleSingleDelete()}
                        orgId={this.props.orgId}
                        delete={this.deletePages}
                        count={Object.keys(currentPage).length}
                    />
                )}
                <PageEditor scope="account" isOpen={editorOpen} toggle={() => this.setState({ editorOpen: !editorOpen })} page={this.state.page} editedPage={this.state.editedPage} savePage={this.savePage} updatePage={this.updatePage} isAccountPage={true} />
            </div>
        );
    }
}

export default withRouter(AccountPage);
