import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import InputField from "../../components/inputs/inputField";
import BasicSectionHeader from "../../components/views/BasicSectionHeader";
import moment from "moment";
import cityTimezones from "city-timezones";
import API from "../../utils/API";
import TimeField from "../../components/inputs/timeField";
import SelectField from "../../components/inputs/selectField";
import SectionHeader from "../../components/views/SectionHeader";
import SectionFooter from "../../components/views/SectionFooter";

class RequestDetails extends Component {
  constructor(props) {
    super(props);

    const {
      match: { params },
      program,
    } = this.props;
    const request = props.requests.find((r) => r._id == params.requestId);
    const additionalAttendees = request.additionalAttendees
      ? JSON.parse(request.additionalAttendees)
      : [];
    this.props.setActiveRow(params.requestId)

    const requiredFields = {
      name: false,
      startDate: false,
      endDate: false,
      startTime: false,
      endTime: false,
      timezone: false,
    };
    const eventDefaultDetails = program.eventDefaults.details;
    program.eventCustomFields &&
      program.eventCustomFields.forEach((category) => {
        category.fields.map((field) => {
          if (
            eventDefaultDetails.requiredCustomFields &&
            eventDefaultDetails.requiredCustomFields.includes(field._id)
          ) {
            requiredFields[field._id] = false;
          }
        });
      });
    this.state = {
      editing: false,
      loading: false,
      request: request,
      submitting: false,
      cityLookup: "",
      editedRequest: { ...request },
      additionalAttendees: additionalAttendees,
      disableButton: false,
      requiredFields: requiredFields,
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;

    this.props.setActiveRow(params.requestId);
  }
  componentWillUnmount() {
    this.props.setActiveRow(null);
  }

  approveRequest = () => {
    const { editedRequest, editing } = this.state;
    const requiredFields = this.state.requiredFields;
    let valid = true;
    Object.keys(requiredFields).forEach((key) => {
      if (!editedRequest[key] || editedRequest[key] === "") {
        requiredFields[key] = true;
        valid = false;
      } else {
        requiredFields[key] = false;
      }
    });
    if (valid && !editing) {
      this.setState({ submitting: true });
      API()
        .post(
          `Organizations/${this.props.orgId}/programs/${this.props.program._id}/requests/${this.state.request._id}/approve`,
          editedRequest
        )
        .then((res) => {
          if (res.data.success) {
            window.open(
              `/${this.props.orgId}/events/${res.data.event._id}/overview`,
              "_self"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ requiredFields: requiredFields, disableButton: true });
    }
  };

  denyRequest = () => {
    const { request, editedRequest, editing } = this.state;
    if (!editing) {
      this.setState({ submitting: true });
      API()
        .post(
          `Organizations/${this.props.orgId}/programs/${this.props.program._id}/requests/${this.state.request._id}/deny`,
          editedRequest
        )
        .then((res) => {
          const r = { ...request };
          const r2 = { ...editedRequest };
          r.status = "denied";
          r2.status = "denied";

          this.props.saveRequest(r, () => {
            this.setState({
              request: r,
              editedRequest: r2,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  saveRequest = () => {
    const { editedRequest, requiredFields } = this.state;
    let valid = true;
    Object.keys(requiredFields).forEach((key) => {
      if (!editedRequest[key] || editedRequest[key] === "") {
        requiredFields[key] = true;
        valid = false;
      } else requiredFields[key] = false;
    });
    if (valid) {
      this.props.saveRequest(editedRequest, () => {
        this.setState({
          request: { ...editedRequest },
          disableButton: false,
          editing: false,
          requiredFields: requiredFields,
        });
      });
    } else {
      this.setState({ requiredFields: requiredFields });
    }
  };

  cancelEdit = () => {
    const request = JSON.parse(JSON.stringify(this.state.request));
    console.log(request);
    const requiredFields = this.state.requiredFields;
    Object.keys(requiredFields).forEach((key) => {
      requiredFields[key] = false;
    });
    this.setState({
      editedRequest: { ...request },
      requiredFields: requiredFields,
      editing: false,
    });
  };

  displaySalesforceAdditionalAttendees = () => {
    const { editedRequest } = this.state;

    let salesforceAdditionalAttendees = editedRequest.integrations && editedRequest.integrations.salesforce && editedRequest.integrations.salesforce.additionalAttendees ? editedRequest.integrations.salesforce.additionalAttendees : [];
    let salesforceSelectedAccountName = editedRequest.integrations && editedRequest.integrations.salesforce && editedRequest.integrations.salesforce.accountName ? editedRequest.integrations.salesforce.accountName : "";

    return (<>
      {salesforceSelectedAccountName.length > 0 ?
        <div className="sectionBlock">
          <h2 className="blockHeader">Salesforce Additional Attendees</h2>
          {salesforceAdditionalAttendees.map((attendee, i) => {
            console.log(attendee);
            let attendeeName = `${attendee.FirstName} ${attendee.LastName}`;
            return (
              <div className="addtionalAttendeeContainer w-100">
                <InputField
                  label={'Salesforce attendee name'}
                  type="text"
                  value={attendeeName}
                  disabled={true}
                  inputIcon={<i className="las la-lock lightGray" />}
                />
              </div>
            );
          })}
        </div >
        : null}
    </>)
  }

  render() {
    const {
      editing,
      request,
      editedRequest,
      requiredFields,
      cityLookup,
      submitting,
      disableButton,
    } = this.state;
    const { program } = this.props;
    const eventDefaultDetails = program.eventDefaults.details;

    const cityTimezoneOptions =
      cityLookup != ""
        ? cityTimezones.findFromCityStateProvince(cityLookup).map((tz) => {
          return { label: `${tz.city}, ${tz.province}`, value: tz.timezone };
        })
        : [];
    return (
      <div className="sectionContainer">
        <SectionHeader
          title={"Request Form"}
          description={`${disableButton
            ? "Required fields are missing, please fill out and save to approve"
            : request.status == "pending"
              ? "Request is pending, approve it to create event"
              : request.status == "denied"
                ? "Request was denied"
                : "Request was approved"
            }`}
          mainActions={
            request.status == "denied"
              ? [
                {
                  type: "button",
                  label: "Approve",
                  onClick: this.approveRequest,
                },
              ]
              : !request.eventId
                ? [
                  {
                    type: "button",
                    label: submitting ? "Approving" : "Approve",
                    disabled: submitting || disableButton,
                    onClick: this.approveRequest,
                  },
                  { type: "button", label: "Deny", onClick: this.denyRequest },
                ]
                : [
                  {
                    type: "link",
                    label: "View event",
                    target: `/${this.props.orgId}/events/${request.eventId}/overview`,
                  },
                ]
          }
          closeButton={true}
        /*  breadcrumbs={[
           {
             name: program.name,
           },
           {
             name: "Requests",
           },
           {
             name: request.name,
           },
         ]} */
        />
        <div className="sectionBody">
          <div className="sectionBlock">
            <h2 className="blockHeader">Request Details</h2>
            <InputField
              label="Submitter Name"
              value={editedRequest.submitterName}
              disabled={true}
              inputIcon={<i className="las la-lock" />}
            />
            <InputField
              label="Submitter Email"
              value={editedRequest.submitterEmail}
              disabled={true}
              inputIcon={<i className="las la-lock" />}
            />
            <InputField
              label="Requested Event Name"
              value={editedRequest.name}
              required={true}
              errorTrigger={requiredFields.name}
              disabled={!!request.eventId}
              onChange={(e) => {
                const r = { ...editedRequest };
                r.name = e.target.value;
                this.setState({
                  editedRequest: r,
                  editing: true,
                });
              }}
              inputIcon={
                !!request.eventId ? <i className="las la-lock" /> : false
              }
            />
            <div className="flex-sb w-100">
              <InputField
                label="Start Date"
                type="date"
                classes="mr-10"
                required={true}
                errorTrigger={requiredFields.startDate}
                value={editedRequest.startDate}
                disabled={!!request.eventId}
                onChange={(e) => {
                  const r = { ...editedRequest };
                  r.startDate = e.target.value;
                  this.setState({
                    editedRequest: r,
                    editing: true,
                  });
                }}
                inputIcon={
                  !!request.eventId ? (
                    <i className="las la-lock" />
                  ) : (
                    <i className="las la-calendar" />
                  )
                }
              />
              <InputField
                label="End Date"
                type="date"
                value={editedRequest.endDate}
                required={true}
                errorTrigger={requiredFields.endDate}
                disabled={!!request.eventId}
                classes="ml-10"
                onChange={(e) => {
                  const r = { ...editedRequest };
                  r.endDate = e.target.value;
                  this.setState({
                    editedRequest: r,
                    editing: true,
                  });
                }}
                inputIcon={
                  !!request.eventId ? (
                    <i className="las la-lock" />
                  ) : (
                    <i className="las la-calendar" />
                  )
                }
              />
            </div>
            <div className="flex-sb w-100">
              <TimeField
                label={"Start Time"}
                onChange={(time) => {
                  const r = { ...editedRequest };
                  r.startTime = time;
                  this.setState({
                    editedRequest: r,
                    editing: true,
                  });
                }}
                required={true}
                errorTrigger={requiredFields.startTime}
                disabled={!!request.eventId}
                value={editedRequest.startTime}
                classes="mr-10 w-100"
                iconClass={
                  !!request.eventId ? (
                    "las la-lock"
                  ) : (
                    "las la-calendar"
                  )
                }
              />
              <TimeField
                label={"End Time"}
                onChange={(time) => {
                  const r = { ...editedRequest };
                  r.endTime = time;
                  this.setState({
                    editedRequest: r,
                    editing: true,
                  });
                }}
                required={true}
                errorTrigger={requiredFields.endTime}
                disabled={!!request.eventId}
                value={editedRequest.endTime}
                classes="ml-10 w-100"
                iconClass={
                  !!request.eventId ? (
                    "las la-lock"
                  ) : (
                    "las la-calendar"
                  )
                }
              />
            </div>

            <SelectField
              label="Timezone"
              value={{
                value: editedRequest.timezone,
                label: editedRequest.timezone,
              }}
              name="registrationPageSlug"
              options={cityTimezoneOptions}
              required={true}
              errorTrigger={requiredFields.timezone}
              disabled={!!request.eventId}
              onInputChange={(text) => {
                this.setState({ cityLookup: text });
              }}
              noOptionsMessage={({ inputValue }) =>
                !inputValue
                  ? "Type city name to get options"
                  : "No cities match your search"
              }
              onChange={(timezone) => {
                this.setState({
                  editedRequest: { ...editedRequest, timezone: timezone.value },
                  editing: true,
                });
              }}
            />

            <InputField
              label="Requested Calendar"
              value={editedRequest.availabilityCalendar}
              disabled={true}
              inputIcon={<i className="las la-lock" />}
            />
            {eventDefaultDetails.requiredCustomFields.length > 0 && (
              <h2 className="blockHeader">Required Custom Fields</h2>
            )}
            {program.eventCustomFields &&
              program.eventCustomFields.map((category, i) => {
                let fields = category.fields.map((field, j) => {
                  if (
                    eventDefaultDetails.requiredCustomFields &&
                    eventDefaultDetails.requiredCustomFields.includes(field._id)
                  ) {
                    return (
                      <div
                        className="flex-ac flex-sb"
                        key={`customField-${i}-${j}`}
                      >
                        <InputField
                          label={`${field.fieldName}`}
                          type={field.fieldType}
                          required={
                            eventDefaultDetails.requiredCustomFields &&
                            eventDefaultDetails.requiredCustomFields.includes(
                              field._id
                            )
                          }
                          errorTrigger={requiredFields[field._id]}
                          value={
                            editedRequest[field._id]
                              ? editedRequest[field._id]
                              : field.fieldValue
                          }
                          disabled={!!request.eventId}
                          onChange={(e) => {
                            const r = { ...editedRequest };
                            r[field._id] = e.target.value;
                            this.setState({
                              editedRequest: r,
                              editing: true,
                            });
                          }}
                          inputIcon={
                            !!request.eventId && (
                              <i className="las la-lock lightGray" />
                            )
                          }
                        />
                      </div>
                    );
                  }
                });
                //check if fields is empty
                fields = fields.filter(function (el) {
                  return el;
                });

                if (fields.length > 0) {
                  return (
                    <div className="sectionBlock" key={`customField-${i}`}>
                      <h2 className="blockHeader">{category.title}</h2>
                      {fields}
                    </div>
                  );
                }
              })}
            {program.fieldLabels &&
              Object.keys(program.fieldLabels).map((field, i) => {
                if (!eventDefaultDetails.requiredCustomFields.includes(field)) {
                  return (
                    <InputField
                      label={program.fieldLabels[field]}
                      type="text"
                      value={editedRequest[field]}
                      disabled={!!request.eventId}
                      onChange={(e) => {
                        const r = { ...editedRequest };
                        r[field] = e.target.value;
                        this.setState({
                          editedRequest: r,
                          editing: true,
                        });
                      }}
                      inputIcon={
                        !!request.eventId && (
                          <i className="las la-lock lightGray" />
                        )
                      }
                    />
                  );
                }
              })}
          </div>
          {this.state.additionalAttendees.length > 0 && (
            <div className="sectionBlock">
              <h2 className="blockHeader">Additional Attendees</h2>
              {this.state.additionalAttendees.map((attendee, i) => {
                return (
                  <div className="addtionalAttendeeContainer w-100">
                    {program.attendeeFieldLabels &&
                      Object.keys(program.attendeeFieldLabels).map((field) => {
                        return (
                          <InputField
                            label={program.attendeeFieldLabels[field]}
                            type="text"
                            value={attendee[field]}
                            disabled={true}
                            inputIcon={<i className="las la-lock lightGray" />}
                          />
                        );
                      })}
                  </div>
                );
              })}
            </div>
          )}

          {editedRequest.integrations && editedRequest.integrations.salesforce && editedRequest.integrations.salesforce.accountName &&
            <div className="sectionBlock">
              <h2 className="blockHeader">Salesforce Account Name</h2>
              <InputField
                label="Salesforce Account Name"
                value={editedRequest.integrations && editedRequest.integrations.salesforce && editedRequest.integrations.salesforce.accountName ? editedRequest.integrations.salesforce.accountName : ""}
                disabled={true}
                inputIcon={<i className="las la-lock lightGray" />}
              />
            </div>
          }
          {this.displaySalesforceAdditionalAttendees()}
        </div>
        <SectionFooter
          editing={editing}
          cancelEdit={this.cancelEdit}
          saveEdit={this.saveRequest}
          updatedAt={program.updatedAt}
        />
      </div>
    );
  }
}

export default withRouter(RequestDetails);
