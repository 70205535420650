import React, { Component } from 'react';

import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
class Forms extends Component {
	constructor(props) {
		super(props);

		const fieldColumns = {
			name: 'Name',
			pageCount: 'Pages',
			submissions: 'Submissions',
			programId: 'Program',
			updatedAt: 'Last Updated'
		};

		const fieldTypes = {
			name: 'text',
			pageCount: 'number',
			submissions: 'number',
			programId: 'program',
			updatedAt: 'date'
		};
		this.state = {
			forms: [],
			formViews: [],
			loading: true,
			columns: fieldColumns,
			columnTypes: fieldTypes
		};
	}

	componentDidMount() {
		API()
			.get(`Organizations/${this.props.orgId}/forms`)
			.then((res) => {
				if (res.data) {
					this.setState({
						forms: res.data.forms,
						formViews: res.data.views || [],
						loading: false
					});
				} else {
					this.setState({
						loading: false
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	}

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};

	deleteForm = async (formId) => {
		await API().delete(`Organizations/${this.props.orgId}/forms/${formId}`).then((res) => {
			if (res.data) {
				const forms = this.state.forms.filter((e) => e._id !== formId);
				this.setState({ forms: forms });
				return true;
			} else {
				return false;
			}
		});
	};

	render() {
		const { loading, formViews, columns, forms, columnTypes } = this.state;

		return (
			<div className="pageContainer">
				{!loading && (
					<ViewsContainer
						views={formViews}
						entries={forms}
						columns={columns}
						columnTypes={columnTypes}
						title="Forms"
						subtitle={null}
						readOnly={false}
						mainActions={[]}
						batchActions={[]}
						enableDownloadViews={true}
						orgId={this.props.orgId}
						type={'Form'}
						referenceCollection={'Organizations'}
						referenceId={this.props.orgId}
					/>
				)}
				<LoadingWizard text="Loading Account Forms" loading={loading} />
			</div>
		);
	}
}

export default Forms;
