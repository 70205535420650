import React, { Component } from 'react';
import moment from 'moment';

class SectionFooter extends Component {

    render() {
        const { updatedAt, editing, cancelEdit, saveEdit, style } = this.props;
        return <div className={`sectionFooter ${editing ? 'editing' : ''}`} style={style}>
            <p className='small gray mr-25'>
                Last Edited On: {moment(updatedAt).format('MMMM Do YYYY, h:mm a')}
            </p>
            <div className='flex aic footerActionContainer'>
                <p className='mb-0 mr-25 gray'>|</p>
                <button className='neu mr-25 min' onClick={cancelEdit}>
                    Discard Changes
                </button>
                <button className='prim min mr-25' onClick={saveEdit} >
                    Save Updates
                </button>
            </div>
        </div>
    }
}

export default SectionFooter;