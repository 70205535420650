import React, { Component, Fragment } from 'react';

import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import API from '../../../utils/API';

import BasicSectionHeader from '../../../components/views/BasicSectionHeader.js';
import TabsContainer from '../../../components/views/TabsContainer';
import ViewsTable from '../../../components/tables/ViewsTable';

import AddBccModal from './modals/addBccModal';

import AddRecipientsModal from './modals/addRecipientsModal';
import SendTextNowModal from './modals/sendTextNowModal';
import ScheduleTextMessageModal from './modals/scheduleTextMessageModal';
import UnscheduleEmailModal from './modals/unscheduleEmailModal';

import SendTestTextModal from './modals/sendTestTextModal';

import RenameEmailModal from './modals/renameEmailModal';
import DeleteEmailModal from './modals/deleteEmailModal';
import DeleteTextsModal from './modals/deleteTextsModal';

import DuplicateCampaignModal from './modals/duplicateCampaignModal';

import TextAreaField from '../../../components/inputs/textAreaField';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import LoadingWizard from '../../../components/spinner/wizard';
import InputField from '../../../components/inputs/inputField';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';

import { UncontrolledTooltip } from 'reactstrap';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';


let interval;

class EventTextCampaign extends Component {
	constructor(props) {
		super(props);
		const columns = {
			attendeeFirstName: 'First Name',
			attendeeLastName: 'Last Name',
			attendeePhoneNumber: 'PhoneNumber',
			sentDate: 'Sent date'
		};
		const columnTypes = {
			attendeeFirstName: 'text',
			attendeeLastName: 'text',
			attendeePhoneNumber: 'text',
			sentDate: 'Date'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		const { match: { params } } = this.props;
		const textMsg = props.textMessages.length > 0 ? props.textMessages.find((e) => e._id == params.textId) : {};
		this.state = {
			textMessage: textMsg,
			selectedSection: 0,
			editedTextMessage: {},
			sendMsgModalOpen: false,
			editing: false,
			errorMessage: false,
			deleteMsgModalOpen: false,
			loading: true,
			parameterOptionsOpen: false,
			requiredFields: {
				name: false,
				message: false
			},
			codeExpanded: false,
			previewExpanded: false,
			scheduleMsgModalOpen: false,
			cancelEdit: false,
			toggleRecipients: false,
			toggleSendMsgNow: false,
			toggleSendTestMsg: false,
			selectedSection: 0,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			toggleRenameMsg: false,
			toggleDeleteMsgModal: false,
			toggleScheduleMsgModal: false,
			toggleCampaignEditorModal: false,
			toggleCreateTemplateFromCampaignModal: false,
			toggleBcc: false,
			editCount: 0,
			toggleDuplicateCampaign: false,
			sentCount: 0,
			totalTexts: 0,
			sendingCompleted: false,
			selectedColumnSort: null,
			sortDesc: false,
			searchTerm: '',
		};
	}

	//Fetch complete attendee information and append to current
	componentDidMount() {
		const { match: { params } } = this.props;
		const textMessage = this.state.textMessage;
		const { eventId, orgId } = this.props;

		API().get(`Organizations/${orgId}/events/${eventId}/textMessages/${params.textId}`).then((res) => {
			if (res.data) {
				Object.assign(textMessage, res.data);
				this.setState({
					textMessage: textMessage,
					editedTextMessage: JSON.parse(JSON.stringify(textMessage)),
					schedules: res.data.schedules,
					loading: false
				}, () => {
					this.runCheck();
					this.setState({ loading: false, })
				});
			}
		});
	}

	componentWillUnmount() {
		clearInterval(interval);
	}

	runCheck = () => {
		const { match: { params }, eventId, orgId } = this.props;
		const { editedTextMessage } = this.state;
		const { event } = this.props;
		//will check for the count of sentemails and make enpoint calls every second for updated sentemails
		let sentTexts = this.state.editedTextMessage.sentTexts;

		if (editedTextMessage.sent || editedTextMessage.scheduled) {

			if (this.isTextsSending() == false) {
				interval = setInterval(() => {
					//stop interval if all emails have been sent
					if (this.isTextsSending() == false) {

						API().get(`Organizations/${orgId}/events/${eventId}/textMessages/${params.textId}/status`)
							.then((res) => {
								this.setState({ editedTextMessage: { ...editedTextMessage, sentTexts: res.data.sentTexts, numbersToBeSent: res.data.numbersToBeSent } });
							});
					} else {
						this.setState({ sendingCompleted: true });
						clearInterval(interval);
					}
				}, 1000);
			}
		}
	}

	isTextsSending = () => {
		const { editedTextMessage } = this.state;
		const { event } = this.props;

		const msg = this.state.textMessage;

		let sentTexts = this.state.editedTextMessage.sentTexts;
		let sentTextsCount = sentTexts.length;
		let isCompleted = false;

		let recipientCount = 0;


		if (editedTextMessage.sent) {
			const recipientModel =
				msg.recipients.attendees.length !== 0
					? 'attendees'
					: msg.recipients.groups.length !== 0
						? 'groups'
						: msg.recipients.sessions.length !== 0 ? 'sessions' : '';

			switch (recipientModel) {
				case 'attendees':


					if (editedTextMessage.numbersToBeSent) {
						recipientCount = editedTextMessage.numbersToBeSent.length;

						if (sentTextsCount > 0 && (recipientCount == sentTextsCount || recipientCount < sentTextsCount)) {
							isCompleted = true;
						}
					} else {
						//legacy logic assuming that all sent status have in fact been sent
						recipientCount = editedTextMessage.sentTexts.length;
						isCompleted = true;

					}
					break;
				case 'groups':
					if (editedTextMessage.numbersToBeSent) {
						recipientCount = editedTextMessage.numbersToBeSent.length;

						if (sentTextsCount > 0 && (recipientCount == sentTextsCount || recipientCount < sentTextsCount)) {
							isCompleted = true;
						}
					} else {
						//legacy logic assuming that all sent status have in fact been sent

						recipientCount = editedTextMessage.sentTexts.length;
						isCompleted = true;

					}

					break;
				case 'sessions':

					if (editedTextMessage.numbersToBeSent) {
						recipientCount = editedTextMessage.numbersToBeSent.length;
						if ((sentTextsCount > 0 && recipientCount > 0) && (recipientCount == sentTextsCount || recipientCount < sentTextsCount)) {
							isCompleted = true;
						}

					} else {
						//legacy logic assuming that all sent status have in fact been sent
						recipientCount = editedTextMessage.sentTexts.length;
						isCompleted = true;
					}
					break;
			}
			let progressPercentage = sentTextsCount == 0 && recipientCount == 0 ? 0 : Math.round((sentTextsCount / recipientCount) * 100);


			this.setState({ sentCount: sentTextsCount, totalEmails: recipientCount, sendingCompleted: isCompleted, progressPercentage: progressPercentage > 100 ? 100 : progressPercentage });


			return isCompleted;

		}
	}

	updateCampaign = (textProperties, callback) => {
		const { textMessage, editedTextMessage } = this.state;

		this.setState(
			{
				textMessage: { ...textMessage, ...textProperties, updatedAt: moment().format() },
				editedTextMessage: { ...editedTextMessage, ...textProperties, updatedAt: moment().format() }
			},
			() => {
				this.props.updateText(this.state.textMessage);
				callback && callback();
			}
		);
	};

	toggleBccModal = () => {
		const { toggleBcc } = this.state;
		this.setState({ toggleBcc: !toggleBcc });
	};
	toggleRecipientsModal = () => {
		const { toggleRecipients } = this.state;
		this.setState({ toggleRecipients: !toggleRecipients });
	};
	toggleSendMsgNowModal = () => {
		const { toggleSendMsgNow } = this.state;
		this.setState({ toggleSendMsgNow: !toggleSendMsgNow });
	};

	toggleSendTestMsgModal = () => {
		const { toggleSendTestMsg } = this.state;
		this.setState({ toggleSendTestMsg: !toggleSendTestMsg });
	};
	toggleRenameMsgModal = () => {
		const { toggleRenameMsg } = this.state;
		this.setState({ toggleRenameMsg: !toggleRenameMsg });
	};
	toggleDuplicateCampaignModal = () => {
		const { toggleDuplicateCampaign } = this.state;
		this.setState({ toggleDuplicateCampaign: !toggleDuplicateCampaign });
	};
	toggleScheduleMsgModal = () => {
		const { toggleScheduleMsgModal } = this.state;
		this.setState({ toggleScheduleMsgModal: !toggleScheduleMsgModal });
	};
	toggleDeleteMsgModal = () => {
		const { toggleDeleteMsgModal } = this.state;
		this.setState({ toggleDeleteMsgModal: !toggleDeleteMsgModal });
	};

	toggleUnscheduleMsg = () => {
		const { toggleUnscheduleMsg } = this.state;
		this.setState({ toggleUnscheduleMsg: !toggleUnscheduleMsg });
	};
	toggleCampaignEditorModal = () => {
		const { toggleCampaignEditorModal } = this.state;
		this.setState({ toggleCampaignEditorModal: !toggleCampaignEditorModal });
	};

	toggleCreateTemplateFromCampaignModal = () => {
		const { toggleCreateTemplateFromCampaignModal } = this.state;
		this.setState({ toggleCreateTemplateFromCampaignModal: !toggleCreateTemplateFromCampaignModal });
	};
	updateSelected = (selectedIndex) => {
		this.setState({
			selectedSection: selectedIndex
		});
	};

	updateCampaignMessage = async () => {
		const { editedTextMessage } = this.state;
		const { orgId, eventId } = this.props;

		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/textMessage/${editedTextMessage._id}`, {
				name: editedTextMessage.name,
				message: editedTextMessage.message,
				parameters: editedTextMessage.parameters
			})
			.then((res) => {
				if (res.data) {
					this.updateCampaign({ message: editedTextMessage.message, parameters: editedTextMessage.parameters }, () => {
						this.setState({ textMessage: res.data, editedTextMessage: JSON.parse(JSON.stringify(res.data)), editing: false });
					});
				}
			});
	};

	/* Download */
	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};
	downloadReport = async () => {

		const { editedTextMessage, columns, columnTypes } = this.state;

		const entries = editedTextMessage.sentTexts; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData
		};

		const reportDetails =
			{ Title: editedTextMessage.name + " Report", Subject: 'Texts Recipient Report' };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${moment().format('LLL')}.xlsx`
		);
	};
	/* Search */
	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};
	/* sortData */
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns, selectedSection } = this.state;
		if (selectedSection !== 2) {
			let nc = normalizedColumns;
			nc = nc.map((col) => {
				if (col.key == column.key) {
					col.sortAsc = sortAsc;
					col.sortDesc = sortDesc;
				} else {
					col.sortAsc = false;
					col.sortDesc = false;
				}
				return col;
			});

			this.setState({
				normalizedColumns: nc,
				selectedColumnSort: column,
				sortDesc: sortDesc == false ? true : false
			});

		}
	}
	renderTab = () => {
		const { normalizedColumns, selectedSection, toggledColumns, editedTextMessage, selectedColumnSort, sortDesc, searchTerm, columns } = this.state;

		switch (selectedSection) {
			case 0:
				return this.renderDetails();
			case 1:
				let data = editedTextMessage.sentTexts;
				if (searchTerm.length > 0) {
					const searchTerms = searchTerm.toLowerCase().split(' ');
					searchTerms.forEach((term) => {
						if (term != '') {
							data = data.filter((entry) => {
								let found = false;
								Object.keys(columns).forEach((key) => {
									if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
										found = true;
									}
								});
								return found;
							});
						}
					});
				}
				if (selectedColumnSort) {
					data = data.sort((a, b) => {
						const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
						if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
							switch (columnType) {
								case 'string':
									return a[selectedColumnSort.key]
										.toLowerCase()
										.trim()
										.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
								case 'number':
									return a[selectedColumnSort.key] - b[selectedColumnSort.key];
								case 'date':
									return (
										new Date(a[selectedColumnSort.key]).getTime() -
										new Date(b[selectedColumnSort.key]).getTime()
									);
								case 'time':
									return (
										new Date(a[selectedColumnSort.key]).getTime() -
										new Date(b[selectedColumnSort.key]).getTime()
									);
								case 'boolean':
									const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
									const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
									return a1.localeCompare(b1);
								default:
									return a[selectedColumnSort.key]
										.toLowerCase()
										.trim()
										.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
							}
						} else if (a[selectedColumnSort.key]) {
							return 1;
						}
						return -1;
					});

					data = sortDesc ? data.reverse() : data;
				}
				return (
					<div className=' flex sectionContainer'>
						<div className='mb-10  mt-10 flex aic'>
							<InputField
								classes="m-0 viewSearch"
								placeholder="Search "
								clickIcon={true}
								required={true}
								value={searchTerm}
								onChange={(e) => {
									clearTimeout(this.searchTimeout);
									this.setState({ searchTerm: e.target.value },
										() => {
											this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
										});

								}}
								prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
								inputIcon={searchTerm != '' &&
									<i
										onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
										//onClick={() => this.setState({ searchTerm: '', searching: false })}
										className="las la-times-circle"
										style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
									/>}
							/>
							<div className="sectionOptionsContainer">
								<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
									<i className="las la-download" />
									<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
								</div>
							</div>
						</div>
						<div className='mb-10 flex sectionContainer'>
							<ViewsTable
								title="Texts"
								classes="h-100 mt-10"
								columns={normalizedColumns}
								toggledColumns={toggledColumns}
								data={data}
								mainColumnActive={false}
								checked={{}}
								checkRow={{}}
								mainActions={[]}
								checkAllRows={() => { }}
								sortData={() => { }}
								disableCheck={true}
							/>
						</div>
					</div>
				);
		}
	};

	renderDetails = () => {
		const { editedTextMessage, progressPercentage, sentCount, totalEmails } = this.state;
		const { event, groups, sessions } = this.props;
		let sentMessagesDates = editedTextMessage.sentTexts.map((s) => s.sentDate);

		const recipientNames = editedTextMessage.recipients.groups.length > 0
			? groups.filter(group => editedTextMessage.recipients.groups.includes(group._id)).map(group => group.name).join(", ")
			: sessions.filter(session => editedTextMessage.recipients.sessions.includes(session._id)).map(session => session.name).join(", ");

		let label = editedTextMessage && editedTextMessage.recipients
			? editedTextMessage.recipients.attendees.length > 0
				? editedTextMessage.recipients.attendees.length === 1 ? "Attendee" : "Attendees" // Singular or plural attendees
				: editedTextMessage.recipients.sessions.length > 0
					? editedTextMessage.recipients.sessions.length === 1 ? "Session" : "Sessions" // Singular or plural sessions
					: editedTextMessage.recipients.groups.length > 0
						? editedTextMessage.recipients.groups.length === 1 ? "Group" : "Groups" // Singular or plural groups
						: "Recipients" // Default label if no attendees, sessions, or groups
			: "Recipients"; // Default label if editedTextMessage or editedTextMessage.recipients is falsy
		return (
			<div className="sectionBlock" >
				<h2 className='blockHeader'>Summary</h2>

				<div className="mb-20" >
					<p className="small gray"> Status</p>
					<p >{editedTextMessage.sent ? progressPercentage >= 100 ? 'Sent' : 'Sending' : 'Not Sent'}</p>

					{editedTextMessage.scheduled && !editedTextMessage.sent &&
						<button
							className='neu mt-20 min'
							onClick={this.toggleUnscheduleMsg}
						>
							Pause
						</button>}
					{editedTextMessage.sent && <div className='userCounter mt-25'>

						<div className="flex-sb">
							<h4 >Recipients</h4>
							<h4 >
								{sentCount} <span>of</span> {totalEmails}
							</h4>
						</div>

						<div className="progressBar mt-5">
							<div
								className={`progressBarFill `}
								style={{ width: `${progressPercentage}%` }}
							/>
						</div>
					</div>}

				</div>

				<div className="mb-20">
					<p className="small gray"> Delivery Date & Time</p>
					<p>
						{`${editedTextMessage.sendDate} ${editedTextMessage.sendTime} ${event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}`}

					</p>
				</div>





				<div className="mb-20">
					<p className="small gray">{label}</p>
					<p>
						{editedTextMessage.recipients ? editedTextMessage.recipients.attendees.length > 0 ? (
							`${editedTextMessage.recipients.attendees.length} ${editedTextMessage.recipients.attendees.length === 1 ? 'Attendee' : 'Attendees'}`
						) : editedTextMessage.recipients.sessions.length > 0 ? (
							`${recipientNames}`
						) : editedTextMessage.recipients.groups.length > 0 ? (
							`${recipientNames}`
						) : (
							'ff'
						) : (
							'No Recipients'
						)}
					</p>
				</div>



			</div>
		);
	};

	render() {
		const { event, eventId, orgId, keyedMetadata, keyedData } = this.props;
		const {
			loading,
			editing,
			textMessage,
			editedTextMessage,
			toggleRecipients,
			toggleSendMsgNow,
			toggleSendTestMsg,
			selectedSection,
			toggleRenameMsg,
			toggleDeleteMsgModal,
			toggleScheduleMsgModal,
			toggleUnscheduleMsgModal,
			toggleUnscheduleMsg,
			toggleBcc,
			toggleDuplicateCampaign
		} = this.state;
		let recipients = editedTextMessage.recipients;

		let isRecipientsEmpty = recipients
			? recipients.attendees.length != 0 || recipients.groups.length != 0 || recipients.sessions.length != 0
				? false
				: true
			: true;


		let recipientModel = '';
		let uniqueAttendees = [];
		let recipientModelElementCount = 0;

		if (recipients) {

			if (recipients.attendees.length > 0) {
				recipientModel = 'Attendee';
				recipientModelElementCount = recipients.attendees.length;
			} else if (recipients.sessions.length > 0) {
				recipientModel = 'Session';
				recipientModelElementCount = recipients.sessions.length;
			} else if (recipients.groups.length > 0) {
				recipientModel = 'Group';
				recipientModelElementCount = recipients.groups.length;
			}

		}


		switch (recipientModel) {
			case 'Attendee':
				uniqueAttendees = [...recipients.attendees];
				break;
			case 'Group':
				let groupsToSend = [...event.groups.filter(g => recipients.groups.includes(g._id))];
				for (let gi = 0; gi < groupsToSend.length; gi++) {
					let group = groupsToSend[gi];
					uniqueAttendees = [...uniqueAttendees, ...group.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
			case 'Session':
				let sessionsToSend = [...event.sessions.filter(s => recipients.sessions.includes(s._id))];
				for (let si = 0; si < sessionsToSend.length; si++) {
					let session = sessionsToSend[si];
					if (session.isGeneral) {
						uniqueAttendees = [];
						let attendeeIds = event.attendees.map(a => a._id);
						uniqueAttendees = [...uniqueAttendees, ...attendeeIds];
						break;
					}
					uniqueAttendees = [...uniqueAttendees, ...session.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			default:
				break;
		}

		let recipientCount = uniqueAttendees.length;

		let sendNow = !isRecipientsEmpty && textMessage.message !== '';

		let testNow = textMessage.message !== '';
		let isScheduled = editedTextMessage.scheduled;

		return (
			<div className="sectionContainer">
				<SectionHeader
					editing={editing}
					cancelEdit={() => {
						this.cancelEdit();
					}}
					saveEdit={() => {
						this.saveInfo();
					}}
					status={editedTextMessage.sent ? 'Sent' : editedTextMessage.scheduled ? "Scheduled" : 'Draft'}
					updatedAt={editedTextMessage.updatedAt}
					title={editedTextMessage.name}
					enableLastLink={true}
					breadcrumbs={[
						{
							name: event.name
						},
						{
							name: 'Texts'
						}
					]}
					subActions={
						!editedTextMessage.sent ? (
							[
								{
									onClick: this.toggleRenameMsgModal,
									label: <span><i className='las la-pen mr-10'></i>Rename Campaign</span>
								},
								{
									onClick: this.toggleDuplicateCampaignModal,
									label: <span><i className='las la-copy mr-10'></i>Duplicate Campaign</span>
								},
								{
									onClick: this.toggleDeleteMsgModal,
									label: <span><i className='las la-trash mr-10'></i>Delete Campaign</span>
								}
							]
						) : (
							[
								{
									onClick: () => { },// this.toggleDuplicateCampaignModal,
									label: 'Duplicate Campaign'
								},
								{
									onClick: () => { }, //this.toggleDeleteMsgModal,
									label: 'Delete Campaign'
								}
							]
						)
					}
				>

					{editedTextMessage.sent &&
						< TabsContainer
							tabs={[
								{ title: 'Details' },
								{ title: 'Recipients' },
							]}
							updateSelected={this.updateSelected}
							selectedIndex={selectedSection}
						/>
					}
					<div className='tabsContainer unsetWidth mb-20 '></div>

				</SectionHeader>




				{editedTextMessage.status == 'Scheduled' ? (

					<div className='flex'>
						<div className='mb-20 flex aic'>
							<div className=' mainActionContainer mr-10' style={{}}>

								<button className={`prim`}
									onClick={this.toggleUnscheduleMsg}
								>
									Pause
								</button>
							</div>
							<p>Scheduled for {editedTextMessage.sendDate} {editedTextMessage.sendTime} {event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}</p>


						</div>
					</div>
				) : !editedTextMessage.sent && <Fragment>
					<div className='flex mb-20'>
						<div className=' flex aic'>
							<div className=' mainActionContainer mr-10' style={sendNow ? {} : { opacity: '0.5', pointerEvents: 'none' }}>

								<button className={` prim  `}
									onClick={this.toggleSendMsgNowModal}
								>Send Now
								</button>
								<UncontrolledDropdown inNavbar>
									<DropdownToggle className="columnToggle">
										<i className="las la-angle-down" />
									</DropdownToggle>
									<DropdownMenu left className="columnDropdown">
										<div className='ph-20'>
											<h5 className="mb-10">Options</h5>
											<DropdownItem
												onClick={() => this.toggleScheduleMsgModal()}
												key={`${'schedule'}-${0}`}
											>
												Schedule for Later
											</DropdownItem>
										</div>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						</div>
						<div style={testNow ? { marginTop: 'auto' } : { marginTop: 'auto', color: '#D5D5D5', pointerEvents: 'none' }} className={`ml-a ml-10 ${!testNow ? "" : "c-pointer"}`} onClick={!testNow ? () => { } : this.toggleSendTestMsgModal}>
							<i className="las la-envelope-open mr-5"></i>
							Send a Test
						</div>
					</div>
				</Fragment >}
				{
					!loading && <div className='sectionBody p-0'>
						{editedTextMessage.sent ? <>{this.renderTab()}</> : <>
							<div className='blocksContainer p-0'>
								<div className='sectionBlock mr-25'>
									<h2 className='blockHeader mt-0'>To</h2>
									<InputField
										label="Who are you sending this text message to?"
										required={true}

										value={`${recipientModelElementCount > 0 ? `${recipientModel == 'Attendee' ? `${recipientCount} Attendee${recipientCount > 1 ? 's' : ''}` : `${recipientCount} Attendee${recipientCount > 1 ? 's' : ''} in ${recipientModelElementCount} ${recipientModel}${recipientModelElementCount > 1 ? 's' : ''}`}` : ``}`}
										inputAction={this.toggleRecipientsModal}
										inputActionLabel={editedTextMessage.status !== 'Scheduled' ? recipientModelElementCount > 0 ? 'Edit Recipients' : 'Add Recipients' : null}
										inputIcon={editedTextMessage.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}

										disabled={true}
										onChange={(e) => { }} />

									<h2 className='blockHeader'>Compose</h2>

									<TextAreaField
										classes="w-100 m-0"
										rows="10"
										label="Message Text"

										value={editedTextMessage.message}
										onChange={(e) => {

											if (e.target.value.length <= 640) {

												this.setState({
													editedTextMessage: {
														...editedTextMessage,
														message: e.target.value
													},
													editing: true
												})
											}

										}}
										subText={`${editedTextMessage.message.length}/640`}
										disabled={editedTextMessage.status == 'Scheduled'}
										inputIcon={editedTextMessage.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}

									/>



								</div>
								<div className='sectionBlock'>
									<label className='formFieldLabelMobile'>Preview</label>
									<div className='essential'>
										<div className='mockPhoneBar'><i className="las la-battery-full"></i><i className="las la-wifi"></i></div>
										<div className='mockPhone'>
											<i className='las la-angle-left se-blue'></i>
											<div>+1 (949) 629-4950</div>
											<i className='las la-info-circle se-blue'></i>
										</div>
										<div className='mockScreen'><p className='mockText small mb-10'>{editedTextMessage.message}</p></div>

									</div>
								</div>
							</div></>}
					</div>
				}
				<SectionFooter
					editing={editing}
					cancelEdit={() => {

						this.setState({ editedTextMessage: { ...editedTextMessage, message: textMessage.message }, editing: false });
					}}
					saveEdit={this.updateCampaignMessage}
					updatedAt={textMessage.updatedAt} />
				<LoadingWizard text="Loading Text Campaign" loading={loading} />

				{
					toggleBcc && (
						<AddBccModal
							isOpen={toggleBcc}
							email={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							toggle={this.toggleBccModal}
							update={this.updateCampaign}
						/>
					)
				}
				{
					toggleRecipients && (
						<AddRecipientsModal
							isOpen={toggleRecipients}
							textMessage={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							toggle={this.toggleRecipientsModal}
							update={this.updateCampaign}
						/>
					)
				}
				{
					toggleSendMsgNow && (
						<SendTextNowModal
							isOpen={toggleSendMsgNow}
							textMessage={textMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							toggle={this.toggleSendMsgNowModal}
							update={this.updateCampaign}
							runCheck={this.runCheck}

						/>
					)
				}
				{
					toggleScheduleMsgModal && (
						<ScheduleTextMessageModal
							isOpen={toggleScheduleMsgModal}
							email={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							toggle={this.toggleScheduleMsgModal}
							update={this.updateCampaign}
						/>
					)
				}
				{
					toggleSendTestMsg && (
						<SendTestTextModal
							isOpen={toggleSendTestMsg}
							textMessage={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							toggle={this.toggleSendTestMsgModal}
							update={this.updateCampaign}
						/>
					)
				}
				{
					toggleRenameMsg && (
						<RenameEmailModal
							isOpen={toggleRenameMsg}
							textMessage={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							toggle={this.toggleRenameMsgModal}
							update={this.updateCampaign}
						/>
					)
				}
				{
					toggleDeleteMsgModal && (
						<DeleteTextsModal
							isOpen={toggleDeleteMsgModal}
							editedTextMessage={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							isSingleDelete={true}
							toggle={this.toggleDeleteMsgModal}
							update={this.updateCampaign}
							updateDeleteCampaign={this.props.updateDeleteCampaign}
						/>
					)
				}
				{
					toggleUnscheduleMsg && (
						<UnscheduleEmailModal
							isOpen={toggleUnscheduleMsg}
							email={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							event={event}
							toggle={this.toggleUnscheduleMsg}
							update={this.updateCampaign}
						/>
					)
				}
				{
					toggleDuplicateCampaign && (
						<DuplicateCampaignModal
							isOpen={toggleDuplicateCampaign}
							email={editedTextMessage}
							orgId={orgId}
							eventId={eventId}
							keyedMetadata={keyedMetadata}
							keyedData={keyedData}
							event={event}
							toggle={this.toggleDuplicateCampaignModal}
							update={this.updateCampaign}
						/>
					)
				}
			</div >
		);
	}
}

export default withRouter(EventTextCampaign);
