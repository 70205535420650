import React, { Fragment } from 'react';
import FieldModal from '../../../components/modals/fieldModal';



class DeleteContactsModal extends React.Component {
	state = {
		submitting: false
	};
	render() {
		const { isOpen, count, toggle } = this.props;
		const { submitting } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete ${count > 1 ? 'Contacts' : 'Contact'}`}
					bodyHeaderText={<span>You are about to delete {count} {count > 1 ? 'contacts' : 'contact'}, this is irreversible,
						would you like to continue?</span>}
					bodyContent={() => {
						return ("")
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.props.deleteContacts()}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default DeleteContactsModal;
