import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';
import fileIcon from '../../../../assets/img/icons/file.png';

let dropzoneRef2;
class FilesUploadModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
			folder: props.folder,
			uploading: false
		};
	}
	onDrop = (files) => {
		const existingFiles = this.state.files;
		files.forEach((f) => existingFiles.push(f));
		this.setState({
			files: existingFiles
		});
	};

	uploadFiles = () => {
		const { orgId, eventId } = this.props;
		const { files, folder } = this.state;
		if (files.length > 0) {
			this.setState({
				uploading: true
			});
			const data = new FormData();
			files.forEach((f) => data.append(`files`, f));

			API()
				.post(`Organizations/${orgId}/events/${eventId}/assets`, data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((res) => {
					if (res.data) {
						this.props.addFiles(res.data.assets);
						this.props.toggle();
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({ error: true, uploading: false });
				});
		}
	};

	removeFile = (file) => {
		const filteredFiles = this.state.files.filter((f) => f.name !== file.name);
		this.setState({ files: filteredFiles });
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { files, uploading } = this.state;
		return (
			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Upload files"}
					bodyHeaderText={``}
					bodyDescription={``}
					bodyContent={() => {

						return (
							<div>
								<Dropzone
									className="clickable dropzone"
									inputProps={{ style: { margin: 'auto' } }}
									ref={(node) => {
										dropzoneRef2 = node;
									}}
									onDrop={this.onDrop.bind(this)}
								>
									<div className="dropzoneDescriptionContainer">
										<p>
											<span style={{ color: '#2B83F1' }}>Browse</span>, or drop a file here or click to upload.
										</p>
									</div>
								</Dropzone>
								{files.length > 0 && (
									<div className="filePreviewList">
										{files.map((file) => (
											<div className="filePreview">
												<div className="removeFileBtn" style={{ width: 15, height: 15, }} onClick={() => this.removeFile(file)}>
													<i className="las la-times" />
												</div>
												<img
													style={{ width: 70, height: 70, borderRadius: 6, border: '1px solid #999999' }}
													src={file.type.includes('image') ? file.preview : fileIcon}
													className="filePreviewImage"
												/>
												<div className='filePreviewLable2'>
													{file.name}
												</div>
											</div>
										))}
									</div>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!uploading ? 'Upload' : 'Uploading...'}
					actionButtonDisabled={uploading}
					actionButton={() => this.uploadFiles()}
				></FieldModal>


			</div>

		);
	}
}

export default FilesUploadModal;
