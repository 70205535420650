import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import InputField from '../../components/inputs/inputField';
import SelectField from '../../components/inputs/selectField';
const permissionOptions = [{ label: 'Admin', value: 'admin' }, { label: 'Organizer', value: 'organizer' }];
class MemberInformation extends Component {
	render() {
		const { editedMember, requiredFields, permission, isInvite } = this.props;
		return (
			<div className="sectionBlock">
				<h2 className="blockHeader">Personal Information</h2>
				<InputField
					label="First Name"
					required={true}
					disabled={!permission || isInvite}
					errorTrigger={requiredFields.firstName}
					value={editedMember.firstName}
					type="text"
					onChange={isInvite ? null : (e) => this.props.editMemberInformation('firstName', e.target.value)}
					inputIcon={!isInvite ? null : <i className="las la-lock" />}

				/>
				<InputField
					label="Last Name"
					disabled={!permission || isInvite}
					value={editedMember.lastName}
					type="text"
					onChange={isInvite ? null : (e) => this.props.editMemberInformation('lastName', e.target.value)}
					inputIcon={!isInvite ? null : <i className="las la-lock" />}


				/>
				<InputField
					label="Email Address"
					disabled={!permission || isInvite}
					required={true}
					errorTrigger={requiredFields.emailAddress}
					value={editedMember.emailAddress}
					type="text"
					onChange={isInvite ? null : (e) => this.props.editMemberInformation('emailAddress', e.target.value)}
					inputIcon={!isInvite ? null : <i className="las la-lock" />}

				/>
				<InputField
					label="Phone Number"
					disabled={!permission || isInvite}
					value={editedMember.phoneNumber}
					type="text"
					onChange={isInvite ? null : (e) => this.props.editMemberInformation('phoneNumber', e.target.value)}
					inputIcon={!isInvite ? null : <i className="las la-lock" />}

				/>
				<SelectField
					label="Role"
					disabled={!permission}
					name="permissions"
					value={editedMember.permission === 'admin' ? permissionOptions[0] : permissionOptions[1]}
					options={permissionOptions}
					onChange={(e) => this.props.editMemberInformation('permission', e.value)}

				/>
				{/* <div className="formField">
					<label className="displayLabel">Profile Picture</label>
					<input
						className={`editingField`}
						value={editedMember.profilePicture}
						type="text"
						onChange={(e) => this.props.editMemberInformation('profilePicture', e.target.value)}
					/>
				</div> */}
			</div>
		);
	}
}

export default MemberInformation;
