import React, { Component } from 'react';
import SelectField from '../../components/inputs/selectField';

const themeOptions = [
	{ label: 'Default', value: 'default' },
	{ label: 'Green', value: 'green' },
	{ label: 'Orange', value: 'orange' },
	{ label: 'Pink', value: 'pink' },
	{ label: 'Purple', value: 'purple' },
	{ label: 'Red', value: 'red' },
	{ label: 'Teal', value: 'teal' },
	{ label: 'Yellow', value: 'yellow' }
];
class ProfileSettings extends Component {
	previewTheme = (e) => {
		const theme = e.value;
		const themePreview = document.getElementsByTagName('body')[0];
		if (theme == 'default') {
			themePreview.className = '';
		} else {
			const themeClass = `theme-${theme}`;
			themePreview.className = themeClass;
		}
		this.props.editMemberInformation('plannerTheme', e.value);
	};

	render() {
		const { editedMember } = this.props;
		return (
			<div style={{ padding: 5 }}>
				<h2 className="mb-15 mt-15">Planner personalization</h2>
				<SelectField
					label="Theme"
					value={themeOptions.find((option) => option.value === editedMember.plannerTheme)}
					options={themeOptions}
					onChange={this.previewTheme}
				/>
			</div>
		);
	}
}

export default ProfileSettings;
