import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import EventSession from './session';
import AddSessionModal from './modals/addSessionModal';
import DeleteSessionModal from './modals/deleteSessionsModal';
import GroupSessionsModal from './modals/groupSessionModal';
import ImportSessionsModal from './modals/importSessionsModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import LoadingWizard from '../../../components/spinner/wizard';
import SessionInsights from './modals/sessionInsights';
import FieldModal from '../../../components/modals/fieldModal';
import moment from 'moment';
import { pdf, Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

import SessionBatchUpdateModal from './modals/sessionBatchUpdateModal';
import CalendarView from '../../../components/views/CalendarView';
let bannedProperties = ['name', 'backupVirtualMeetingLinks', 'virtualMeetingLink', 'recordingLink', 'createdAt', 'updatedAt', 'eventGroups'];
let batchCallback;
class EventSessions extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			subtitle: 'Subtitle',
			description: 'Description',
			date: 'Date',
			startTime: 'Start Time',
			endTime: 'End Time',
			isAllDay: 'All Day',
			isGeneral: 'Is General',
			locationName: 'Location',
			setPeople: 'Set People',
			guaranteedPeople: 'Guaranteed People',
			roomSet: 'Room Set',
			foodAndBeverage: 'Food and Beverage',
			posted: 'Posted',
			hotelItemsNeeded: 'Hotel Items Needed',
			avOrder: 'AV Order',
			setTime: 'Set Time',
			strikeTime: 'Strike Time',
			notes: 'Notes',
			members: "Members",
			eventGroups: 'Groups',
			createdAt: 'Date Created',
			updatedAt: 'Last Updated',
			displayStartTime: 'Display Start Time',
			displayEndTime: 'Display End Time',
			displayTimezone: 'Display Timezone',
			template: 'Page Template',
			virtualMeetingType: 'Virtual Meeting Type',
			virtualStartOffset: 'Virtual Start Offset',
			virtualStopOffset: 'Virtual Stop Offset',
			virtualMeetingLink: 'Virtual Meeting Link',
			recordingLink: 'Recording Link',
			chatEnabled: 'Chat Enabled',
			reactionsEnabled: 'Reactions Enabled',
			pollsEnabled: 'Polls Enabled',
			enableReview: 'Review Enabled'
		};

		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'subtitle',
					'description',
					'date',
					'startTime',
					'endTime',
					'isAllDay',
					'isGeneral',
					'locationName',
					'setPeople',
					'guaranteedPeople',
					'roomSet',
					'foodAndBeverage',
					'posted',
					'hotelItemsNeeded',
					'avOrder',
					'setTime',
					'strikeTime',
					'notes',
					'members',
					'eventGroups',
					'createdAt',
					'updatedAt',
					'displayStartTime',
					'displayEndTime',
					'displayTimezone',
					'template',
					'virtualMeetingType',
					'virtualStartOffset',
					'virtualStopOffset',
					'virtualMeetingLink',
					'recordingLink',
					'chatEnabled',
					'reactionsEnabled',
					'pollsEnabled',
					'enableReview'
				]
			}
		};
		const defaultFieldTypes = {
			name: 'text',
			subtitle: 'text',
			description: 'text',
			date: 'date',
			startTime: 'time',
			endTime: 'time',
			isAllDay: 'boolean',
			isGeneral: 'boolean',
			locationName: 'text',
			setPeople: 'number',
			guaranteedPeople: 'number',
			roomSet: 'text',
			foodAndBeverage: 'text',
			posted: 'boolean',
			hotelItemsNeeded: 'text',
			avOrder: 'text',
			setTime: 'time',
			strikeTime: 'time',
			notes: 'text',
			members: 'text',
			eventGroups: 'text',
			createdAt: 'timestamp',
			updatedAt: 'timestamp',
			displayStartTime: 'text',
			displayEndTime: 'text',
			displayTimezone: 'text',
			template: 'text',
			virtualMeetingType: 'text',
			virtualStartOffset: 'number',
			virtualStopOffset: 'number',
			virtualMeetingLink: 'link',
			recordingLink: 'link',
			chatEnabled: 'boolean',
			reactionsEnabled: 'boolean',
			pollsEnabled: 'boolean',
			enableReview: 'boolean'
		};
		props.customFields.forEach((category) => {
			const fields = category.fields;
			fields.forEach((f) => {
				if (fieldCategories[category._id]) {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				} else {
					fieldCategories[category._id] = {
						title: category.title,
						columns: [`${category._id}*${f._id}`]
					};
				}
				customFieldColumns[category._id + '*' + f._id] = f.fieldName;
				defaultFieldTypes[category._id + '*' + f._id] = f.fieldType;
			});
		});

		const sortedSessions = props.sessions;

		this.state = {
			eventId: props.eventId,
			newSessions: [],
			groupSessions: [],
			checkedSessions: {},
			categoryColumns: fieldCategories,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			sortedSessions: sortedSessions,
			allChecked: false,
			sessionSelected: false,
			selectedSession: 0,
			searchSelected: false,
			searchTerm: '',
			searchField: '',
			sortByColumn: { name: true },
			addSessionModalOpen: false,
			deleteSessionsModalOpen: false,
			toggleGroupSessions: false,
			importSessionsModalOpen: false,
			newSession: {},
			sessionGroup: {},
			loading: false,
			upcomingCount: 0,
			completedCount: 0,
			deletedSessions: [],
			isDeleting: false,
			toggleAddSession: false,
			sessionInsightsModalOpen: false,
			upcomingSessions: [],
			toggleBatchUpdate: false,
			selectedViewIndex: 0,
			selectedView: null,
			entriesCount: 0,
			restrictBatchUpdate: false,
			calendarViewOn: false,
			sidePanelOpen: false,
			editCount: 0,
			sessionsInsightsModalOpen: false,
			setActiveRow: ''
		};
	}

	componentWillMount() {
		const { orgId, eventId, event, organization } = this.props;

		let members = organization.members
		let hashMembers = {};
		members.forEach((m) => {
			hashMembers[m._id] = `${m.firstName} ${m.lastName}`;
		})
		let pathOptions = {};
		event.pathOptions.forEach((p) => {
			pathOptions[p._id] = p.title;
		});

		this.setState({ loading: true }, () => this.props.fetchSessions(() => {
			const sortedSessions = this.props.sessions.map((s) => {

				s.eventGroups = event.groups
					.map((eg) => eg.sessions.includes(s._id) && eg.name)
					.filter((g) => !!g)
					.join(', ');
				s.id = s._id;
				s.link = `/${orgId}/events/${eventId}/schedule/${s._id}`;
				s.metadata.forEach((meta) => (s[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
				let location = event.venueDetails && event.venueDetails.locations && event.venueDetails.locations.find((l) => l._id === s.location) || {}
				s.locationName = location && location.name ? location.name : '';
				s.template = pathOptions[s.pageId] || '';
				let team = s.team ? s.team.map((t) => hashMembers[t]) : [];

				let concatMember = team.join(', ');
				s.members = concatMember;
				return s;
			});
			sortedSessions.sort((a, b) => {

				const aDateTime = moment(`${moment(a.date).format('YYYY-MM-DD')} ${moment(a.startTime, 'HH:mm a').format('HH:mm a')}`, 'YYYY-MM-DD HH:mm a');
				const bDateTime = moment(`${moment(b.date).format('YYYY-MM-DD')} ${moment(b.startTime, 'HH:mm a').format('HH:mm a')}`, 'YYYY-MM-DD HH:mm a');

				if (aDateTime.isBefore(bDateTime)) return -1;
				if (aDateTime.isAfter(bDateTime)) return 1;
				return 0;
			});

			const timeUntilNextMinute = 60 - moment().seconds();
			setTimeout(() => {
				setInterval(this.updateUpcoming, 1000 * 60);
			}, timeUntilNextMinute * 1000)

			this.setState({ loading: false, sortedSessions: sortedSessions });
			this.updateUpcoming();
		}));
	}

	updateUpcoming = () => {
		const { sortedSessions } = this.state;
		const upcomingSessions = sortedSessions.filter((s) => moment(`${s.date} ${s.startTime}`, "MM/DD/YYYY HH:mm a").isSameOrAfter(moment()));
		this.setState({ upcomingCount: upcomingSessions.length, completedCount: sortedSessions.length - upcomingSessions.length, upcomingSessions: upcomingSessions });
	}


	addSession = (session) => {
		this.props.addSession(session);
		session.id = session._id;

		session.link = `/${this.props.orgId}/events/${this.props.eventId}/schedule/${session._id}`;
		session.metadata.forEach((meta) => (session[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
		this.setState({
			newSessions: [...this.state.newSessions, session],
			loading: false,

			sortedSessions: [...this.state.sortedSessions, session]
		}, () => {
			batchCallback && batchCallback('update')
		});
	};

	toggleDeleteSessions = (checkedSessions, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedSessions: checkedSessions || {},
			deleteSessionsModalOpen: !this.state.deleteSessionsModalOpen
		});
	};

	deleteCheckedSessions = () => {
		this.setState({ isDeleting: true });
		this.props.deleteSessions(this.state.checkedSessions, () => {
			let updatedSessions = this.state.sortedSessions;
			let tempChecked = JSON.parse(JSON.stringify(this.state.checkedSessions));
			let checkedSessionIDs = updatedSessions.filter((sa) => Object.keys(this.state.checkedSessions).includes(sa.id) && this.state.checkedSessions[sa.id] === true).map((sa) => sa.id);
			updatedSessions = updatedSessions.filter((sa) => !Object.keys(this.state.checkedSessions).includes(sa.id));
			this.props.updateSessionDeletePathOP(checkedSessionIDs)
			this.setState({
				checkedSessions: {},
				sortedSessions: updatedSessions,
				deletedSessions: [...this.state.deletedSessions, ...Object.keys(tempChecked)],
				isDeleting: false
			});
			batchCallback && batchCallback('delete');
			this.toggleDeleteSessions();
		});
	};

	deleteSingleSession = (session, callback) => {
		this.props.deleteSessions(session, (error) => {
			this.setState({ sessionSelected: error ? true : false });
			callback(error);
		});
	};

	toggleGroupSessions = (checkedSessions, bcb) => {
		batchCallback = bcb;
		this.setState(
			{
				sessionGroup: {},
				checkedSessions: checkedSessions || {},
				toggleGroupSessions: !this.state.toggleGroupSessions
			},
			() => {
				batchCallback && batchCallback('update');
			}
		);
	};
	groupCheckedSessions = (group, callback) => {
		this.setState({ loading: true })
		let sorted = this.props.sessions.map((s) => {
			s.eventGroups = this.props.event.groups
				.map((eg) => eg.sessions.includes(s._id) && eg.name)
				.filter((g) => !!g)
				.join(', ');
			s.id = s._id;
			s.link = `/${this.props.orgId}/events/${this.props.eventId}/schedule/${s._id}`;
			s.metadata.forEach((meta) => (s[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
			return s;
		});
		this.props.groupSessions(group, this.state.checkedSessions, (added) => {
			this.setState({ sortedSessions: sorted, loading: false })
			callback(added);
		});
		this.setState({ groupSessions: [...this.state.groupSessions, group], loading: false });
	};

	updateSession = (sessionId, session) => {
		const event = this.props.event;
		const updatedSessions = this.state.sortedSessions.map((s) => {
			if (sessionId == s.id) {
				session.metadata.forEach((meta) => (session[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
				s = { ...s, ...session }
				let location = event.venueDetails && event.venueDetails.locations && event.venueDetails.locations.find((l) => l._id === s.location) || {}
				s.locationName = location && location.name ? location.name : '';
			}
			return s;
		});
		this.setState({ sortedSessions: updatedSessions, editCount: this.state.editCount + 1 }, () => this.props.updateSession(sessionId, session));
	};

	addField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (
				fieldCategories[category._id] &&
				!fieldCategories[category._id].columns.includes(`${category._id}*${f._id}`)
			) {
				fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
			} else {
				fieldCategories[category._id] = {
					title: category.title,
					columns: [`${category._id}*${f._id}`]
				};
			}
			fieldColumns[category._id + '*' + f._id] = f.fieldName;
			fieldTypes[category._id + '*' + f._id] = f.fieldType;
		});
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, () => {
			this.props.addCustomField('sessions', category);
			cb && cb();
		});
	};

	updateField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (fieldCategories[category._id]) {
				//fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				fieldColumns[category._id + '*' + f._id] = f.fieldName;
				fieldTypes[category._id + '*' + f._id] = f.fieldType;
			}
		});
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	removeField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		if (fields.length > 0) {
			if (fieldCategories[category._id]) {
				fieldCategories[category._id].columns = [];

				fields.forEach((f) => {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
					fieldColumns[category._id + '*' + f._id] = f.fieldName;
					fieldTypes[category._id + '*' + f._id] = f.fieldType;
				});
			}
		} else {
			delete fieldCategories[category._id];
		}
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	toggleAddSession = (cb) => {
		const { toggleAddSession } = this.state;
		batchCallback = cb

		this.setState({ toggleAddSession: !toggleAddSession })
	}

	toggleImportSessions = () => {
		const { importSessionsModalOpen } = this.state;
		this.setState({ importSessionsModalOpen: !importSessionsModalOpen })
	}

	toggleBatchUpdate = (checkedSessions, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedSessions: checkedSessions || {},
			//attendeeGroup: {},
			toggleBatchUpdate: !this.state.toggleBatchUpdate
		});
	};

	updateBatchSessions = (editedValues, callback) => {
		const { checkedSessions, sortedSessions } = this.state;
		const { event } = this.props;
		let sessionIds = Object.keys(checkedSessions);
		let updatedSessions = [];
		for (let i = 0; i < sortedSessions.length; i++) {
			let currentSession = sortedSessions[i];
			if (sessionIds.includes(currentSession._id)) {

				sortedSessions[i] = { ...currentSession, ...editedValues };

				let location = event.venueDetails && event.venueDetails.locations && event.venueDetails.locations.find((l) => l._id === sortedSessions[i].location) || {}
				sortedSessions[i].locationName = location && location.name ? location.name : '';

				if (Object.keys(editedValues).includes('pageId')) {
					let title = '';
					for (let i = 0; i < event.pathOptions.length; i++) {
						if (event.pathOptions[i]._id === editedValues.pageId) {
							title = event.pathOptions[i].title;
							break;
						}
					}
					sortedSessions[i].template = title
				}

				updatedSessions.push(sortedSessions[i]);
			}
		}

		this.setState({ sortedSessions, checkedSessions: {} }, () => {
			batchCallback && batchCallback('update');
			updatedSessions.forEach((s) => {
				this.props.updateSession(s._id, s);
			});
			callback && callback();
		});
	}

	updateSelectedViewIndex = (selectedViewIndex, views, entriesCount) => {
		let currentView = null;

		if ((selectedViewIndex == 0 && views[0] == null) || (selectedViewIndex == 1 && views[0] !== null)) {
			currentView = null;
		} else {
			if (selectedViewIndex == 0) {
				currentView = views[0];
			} else {
				if (views[0] == null && selectedViewIndex > 0) {
					currentView = views[selectedViewIndex];
				} else {
					currentView = views[selectedViewIndex - 1];
				}
			}
		}

		this.setState({ selectedViewIndex: selectedViewIndex, selectedView: currentView, entriesCount: entriesCount }, () => {

			if (this.state.selectedView) {
				let toggledColumns = JSON.parse(JSON.stringify(this.state.selectedView.toggledColumns))
				toggledColumns = toggledColumns.filter(c => !bannedProperties.includes(c));
				this.setState({ restrictBatchUpdate: toggledColumns.length == 0 ? true : false })
			} else {
				this.setState({ restrictBatchUpdate: false })

			}
		}
		)
	}

	updateTeam = (id, team) => {
		const { organization, } = this.props;


		let members = organization.members
		let hashMembers = {};
		members.forEach((m) => {
			hashMembers[m._id] = `${m.firstName} ${m.lastName}`;
		})

		const sortedSessions = this.state.sortedSessions.map((s) => {

			if (s._id === id) {
				s.team = team;
				let newteam = s.team.map((t) => hashMembers[t]);
				let concatMember = newteam.join(', ');
				s.members = concatMember;
			}

			return s;
		});

		this.setState({ sortedSessions }
		);

	}


	downloadPDFAgenda = (view, entries) => {
		const { event, organization } = this.props;

		Font.registerHyphenationCallback(word => [word]);

		const systemFonts = [
			{
				name: 'Calibre-Bold',
				url: "https://system-fonts.s3.amazonaws.com/Calibre/Calibre-Bold.otf"
			},
			{
				name: 'Calibre-SemiBold',
				url: "https://system-fonts.s3.amazonaws.com/Calibre/Calibre-Semibold.otf"
			},
			{
				name: 'Calibre-Regular',
				url: "https://system-fonts.s3.amazonaws.com/Calibre/Calibre-Regular.otf"
			},
			{
				name: "Inter regular",
				url: "https://system-fonts.s3.amazonaws.com/Inter/Inter-Regular.ttf"
			}
		]

		systemFonts.forEach((font) => {
			Font.register({
				family: font.name,
				src: font.url
			});
		});

		organization.branding.fonts.forEach((font) => {
			Font.register({
				family: font.name,
				src: font.url
			});
		});

		event.branding.fonts.forEach((font) => {
			Font.register({
				family: font.name,
				src: font.url
			});
		});

		const styles = StyleSheet.create({
			page: {
				borderLeft: `22px solid ${event.branding.accentColor}`,
				padding: '0 30px'
			},
			sideBar: {
				width: 22,
				height: '100%',
				backgroundColor: event.branding.accentColor,
			},
			logo: {
				width: 100,
				height: 17,
				marginLeft: 420,
				marginTop: 30
			},
			logoImage: {
				objectFit: 'scale-down'
			},
			heading: {
				fontSize: 26,
				maxWidth: 320,
				fontFamily: event.style.heading1FontFamily,
			},
			heading2: {
				fontSize: 18,
				fontFamily: event.style.heading2FontFamily,
			},
			heading3: {
				fontSize: 10,
				fontFamily: event.style.heading3FontFamily,
				borderBottom: '1px solid #ccc',
				paddingBottom: 5,
			},
			heading3Gray: {
				fontSize: 10,
				fontFamily: event.style.heading3FontFamily,
				color: '#999',
			},
			sectionBar: {
				width: 26,
				height: 3,
				backgroundColor: event.branding.accentColor,
				marginBottom: 10
			},
			subtitle: {
				fontSize: 12,
				fontFamily: event.style.heading4FontFamily,
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				borderBottom: '1px solid #ccc',
				fontSize: 8.5,
				fontFamily: event.style.bodyFontFamily,
				padding: '5px 0px'
			},
			column1: {
				width: 100

			},
			column2: {
				width: 290,
				paddingRight: 10
			},
			column3: {
				width: 100
			},
			blankPage: {
				height: '100%'
			},
			footer: {
				fontSize: 8,
				color: '#999',
				fontFamily: event.style.bodyFontFamily,
				marginTop: 'auto',
				marginBottom: 30,
				paddingTop: 20,
				flexDirection: 'row',
				justifyContent: 'space-between',
			}
		});


		const agenda = {};
		entries.forEach((e) => {
			const date = moment(e.date).format('dddd, MMMM D');
			if (!agenda[e.date]) {
				agenda[e.date] = [];
			}
			agenda[e.date].push({ ...e, formattedDate: date });
		});

		Object.keys(agenda).forEach((date) => {
			agenda[date].sort((a, b) => {
				if (a.isAllDay) return -1;

				const aTime = moment(a.startTime, 'HH:mm a').format('HH:mm a');
				const bTime = moment(b.startTime, 'HH:mm a').format('HH:mm a');

				if (aTime < bTime) return -1;
				if (aTime > bTime) return 1;
				return 0;
			});
		});

		let eventDateString = "";
		if (event.startDate && event.endDate && event.startDate !== event.endDate) {
			const startDate = moment(event.startDate).format('MMMM D');
			const endDate = moment(event.endDate).format('D');
			eventDateString = `${startDate}-${endDate}`;
		} else if (event.startDate) {
			eventDateString = moment(event.startDate).format('MMMM D');
		}

		let venueName = "";
		if (event.venueDetails && event.venueDetails.name) {
			venueName = event.venueDetails.name;
		}

		let venuePlace = "";
		if (event.venueDetails && event.venueDetails.address) {
			venuePlace = `${event.venueDetails.address.city}, ${event.venueDetails.address.state}`;
		}

		let venueLocationNames = {};
		if (event.venueDetails && event.venueDetails.locations && event.venueDetails.locations.length > 0) {
			event.venueDetails.locations.forEach((l) => {
				venueLocationNames[l._id] = l.name;
			});
		}

		const MyDocument = (
			<Document>
				<Page size="A4" style={styles.page}>
					{/* <View style={styles.page}> */}
					{/* <View style={styles.sideBar} fixed={true} /> */}
					<View>
						<View style={styles.logo} fixed={true} >
							<Image src={event.branding.logo} style={styles.logoImage} />
						</View>
						<View style={styles.section}>
							<Text style={[styles.heading, { marginBottom: 10 }]}>{event.name}</Text>
							<Text style={styles.subtitle}>{eventDateString} | {venueName} | {venuePlace}</Text>
						</View>
						<View style={[styles.section, { marginBottom: 20, marginTop: 25 }]} fixed={true}>
							<View style={styles.sectionBar} />
							<Text style={styles.heading2}>{view.name}</Text>
						</View>
						{Object.keys(agenda).map((date) => {
							const renderedTitles = {};
							return (
								<View style={[styles.section, { marginBottom: 20 }]}>
									<Text fixed style={styles.heading3} render={(e) => {
										if (!renderedTitles[date]) {
											renderedTitles[date] = !!e.totalPages;
											return `${agenda[date][0].formattedDate}`
										} else {
											return <View style={styles.heading3}>{agenda[date][0].formattedDate} <View style={styles.heading3Gray}>(cont.)</View></View>
										}
									}} />
									{agenda[date].map((session) => {
										let time = `${session.startTime} - ${session.endTime}`.toUpperCase();
										if (session.isAllDay) {
											time = 'All Day';
										}
										if (session.startTime == session.endTime) {
											time = session.startTime;
										}
										if (session.displayStartTime && session.displayEndTime) {
											time = `${session.displayStartTime} - ${session.displayEndTime}`;
										}
										return (
											<View style={styles.row}>
												<Text style={styles.column1}>{time}</Text>
												<Text style={styles.column2}>{session.name}</Text>
												<Text style={styles.column3}>{venueLocationNames[session.location]}</Text>
											</View>
										)
									})}
								</View>
							)
						})}
					</View>
					{/* </View> */}
					<View style={styles.footer} fixed={true}>
						<Text render={({ pageNumber }) => (
							`Page ${pageNumber}`
						)} />
						<Text>{event.name} | {venuePlace}</Text>
					</View>
				</Page>
			</Document>
		);

		const blob = pdf(MyDocument).toBlob();
		blob.then((blob) => {
			const url = URL.createObjectURL(blob);
			window.open(url, '_blank');
		});
	}
	setActiveRow = (sessionId) => {
		this.setState({ activeRow: sessionId })
	}

	render() {
		const {
			eventId,
			selectedSession,
			deleteSessionsModalOpen,
			deletedSessions,
			columns,
			isDeleting,
			categoryColumns,
			columnTypes,
			toggleAddSession,
			loading,
			sortedSessions,
			toggleGroupSessions,
			importSessionsModalOpen,
			sessionInsightsModalOpen,
			upcomingSessions,
			toggleBatchUpdate,
			restrictBatchUpdate,
			calendarViewOn,
			sidePanelOpen,
			editCount,
			activeRow,
			sessionsInsightsModalOpen
		} = this.state;
		const { event, sessions, readOnly, sessionsViews, orgId } = this.props;
		const { location } = this.props;
		const sessionsPages = event.pathOptions.map(page => {
			return page.isSessionPage ? { label: page.title, value: page._id } : null;
		}).filter(path => path !== null);
		return (
			<Switch>
				<Route path={`/:organization/events/:eventId/schedule`}>
					<Fragment>
						{!loading && <>{!calendarViewOn ? <ViewsContainer
							activeRow={activeRow}
							importAvailable={true}
							orgId={orgId}
							//key={`sessions${editCount}`}
							views={sessionsViews}
							entries={sortedSessions}
							deletedEntries={deletedSessions}
							categoryColumns={categoryColumns}
							columns={columns}
							columnTypes={columnTypes}
							title="Sessions"
							description="View and manage all sessions."
							readOnly={false}
							summaryPanel={<button className='summaryPanel' onClick={() => this.setState({ sessionInsightsModalOpen: true })}>
								<div className='flex aic jcsb mb-20 w-100'><h5><i className='las la-lightbulb mr-10 seBlue'></i>Session Insights</h5><i className='las la-angle-right'></i></div>
								<div className='flex column wrap jcfe'>
									{upcomingSessions.length > 0 ? upcomingSessions.map((e, i) => {
										if (i < 2) {
											const sessionStart = `${e.date} ${e.startTime}`;
											return (
												<div className='flex aic' style={{ marginBottom: i < 1 ? 5 : 0 }}>
													<p className='mr-5'>{e.name}</p>
													{/* use moment to set time until */}
													<h3>
														{moment(sessionStart).fromNow()}
													</h3>
												</div>);
										}
									}) : <p>No upcoming sessions</p>}
								</div>
								{/* 								<p className='small'>Last Update: <span className='seBlue'>William added attendee on {moment(event.lastUpdated).format("MM/DD/YYYY H:mm:ss a")}</span>  </p>
 */}

							</button>}
							mainActions={[
								{ label: 'Add session', onClick: this.toggleAddSession, type: 'button' },
								{ label: <span><i className='las la-file-import mr-5' /> Import sessions</span>, onClick: this.toggleImportSessions, type: 'button' }
							]}
							enablePDFDownload={this.downloadPDFAgenda}
							batchActions={restrictBatchUpdate ? [
								{
									label: 'Add to Group',
									iconClass: 'las la-user-plus',
									onClick: this.toggleGroupSessions
								},
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteSessions
								}
							] : [
								{
									label: 'Batch Update',
									iconClass: 'las la-redo-alt mr-5',
									class: '',
									onClick: this.toggleBatchUpdate
								},
								{
									label: 'Add to Group',
									iconClass: 'las la-user-plus',
									onClick: this.toggleGroupSessions
								},
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteSessions
								}
							]}
							breadcrumbs={[
								{ name: event.name }
							]}
							viewPanelActions={[
								{
									iconClass: "las la-history  fs-20 c-pointer",
									label: "Change Log",
									onClick: () => this.setState({ sessionsInsightsModalOpen: true }),
								},

							]}
							enableDownloadViews={true}
							updateSelectedViewIndex={this.updateSelectedViewIndex}
							type={'Session'}
							referenceCollection={'Events'}
							referenceId={eventId}
							eventId={eventId}
							section={'sessions'}
							fields={this.props.customFields}
							addField={this.addField}
							updateField={this.updateField}
							removeField={this.removeField}
						/> : <CalendarView
							orgId={orgId}
							views={sessionsViews}
							entries={sortedSessions}
							deletedEntries={deletedSessions}
							categoryColumns={categoryColumns}
							columns={columns}
							columnTypes={columnTypes}
							title="Sessions"
							description="View and manage all sessions."
							readOnly={false}
							summaryPanel={<button className='summaryPanel' onClick={() => this.setState({ sessionInsightsModalOpen: true })}>
								<div className='flex aic jcsb mb-20 w-100'><h5><i className='las la-lightbulb mr-10 seBlue'></i>Session Insights</h5><i className='las la-angle-right'></i></div>
								<div className='flex column wrap jcfe'>
									{upcomingSessions.length > 0 ? upcomingSessions.map((e, i) => {
										if (i < 2) {
											const sessionStart = `${e.date} ${e.startTime}`;
											return (
												<div className='flex aic' style={{ marginBottom: i < 1 ? 5 : 0 }}>
													<p className='mr-5'>{e.name}</p>
													{/* use moment to set time until */}
													<h3>
														{moment(sessionStart).fromNow()}
													</h3>
												</div>);
										}
									}) : <p>No upcoming sessions</p>}
								</div>
								{/* 								<p className='small'>Last Update: <span className='seBlue'>William added attendee on {moment(event.lastUpdated).format("MM/DD/YYYY H:mm:ss a")}</span>  </p>
 */}

							</button>}
							mainActions={[
								{ label: 'Add session', onClick: this.toggleAddSession, type: 'button' },
								{ label: <span><i className='las la-file-import mr-5' /> Import sessions</span>, onClick: this.toggleImportSessions, type: 'button' }
							]}
							breadcrumbs={[
								{ name: event.name }
							]}
							viewPanelActions={[
								{
									iconClass: 'las la-calendar mr-10 fs-20', onClick: () => {
										this.setState({ calendarViewOn: !this.state.calendarViewOn })
									},
									label: "Calendar View"
								},

							]}
							enableDownloadViews={true}
							updateSelectedViewIndex={this.updateSelectedViewIndex}
							type={'Session'}
							referenceCollection={'Events'}
							referenceId={eventId}
							eventId={eventId}
							section={'sessions'}
							fields={this.props.customFields}
							addField={this.addField}
							updateField={this.updateField}
							removeField={this.removeField} />}</>}
						<LoadingWizard loading={loading} text="Loading Sessions" />

						{sessionsInsightsModalOpen &&
							<SessionInsights
								isOpen={sessionsInsightsModalOpen}
								toggle={() => this.setState({ sessionsInsightsModalOpen: false })}
								customFields={this.state.columns}
								attendees={sortedSessions}
								orgId={orgId}
								eventId={eventId}
								event={event}
							/>
						}
						{deleteSessionsModalOpen && <DeleteSessionModal
							isOpen={deleteSessionsModalOpen}
							delete={this.deleteCheckedSessions}
							isDeleting={isDeleting}
							toggle={this.toggleDeleteSessions}
							count={Object.keys(this.state.checkedSessions).length}
						/>}
						{toggleBatchUpdate &&
							<SessionBatchUpdateModal
								isOpen={toggleBatchUpdate}
								toggle={this.toggleBatchUpdate}
								count={Object.keys(this.state.checkedSessions).length}
								attendeeIds={Object.keys(this.state.checkedSessions)}
								updateCheckedAttendeesStatus={this.updateCheckedAttendeesStatus}
								updateBatchSessions={this.updateBatchSessions}
								categoryColumns={categoryColumns}
								columns={columns}
								columnTypes={columnTypes}
								orgId={this.props.orgId}
								eventId={this.props.eventId}
								bannedProperties={bannedProperties}
								checkedEntreesData={sortedSessions.filter(a => Object.keys(this.state.checkedSessions).includes(a._id))}
								selectedView={this.state.selectedView}
								entriesCount={this.state.entriesCount}
								checkedEntries={Object.keys(this.state.checkedSessions).length}
								sessions={this.props.sessions}
								venues={this.props.venues}
								event={this.props.event}
								addNewLocation={this.props.addNewLocation}
								renameLocation={this.props.renameLocation}
								deleteLocation={this.props.deleteLocation}
								pathOptions={this.props.event.pathOptions.filter(p => p.isSessionPage)}


							/>
						}
						{toggleGroupSessions && <GroupSessionsModal
							isOpen={toggleGroupSessions}
							addGroup={this.groupCheckedSessions}
							toggle={this.toggleGroupSessions}
							groups={this.props.event.groups}
							count={Object.keys(this.state.checkedSessions).length}
						/>}
						{toggleAddSession &&
							<AddSessionModal
								isOpen={toggleAddSession}
								handleSubmit={this.addSession}
								toggle={this.toggleAddSession}
								eventId={eventId}
								orgId={this.props.orgId}
							/>
						}
						{importSessionsModalOpen && <ImportSessionsModal
							sessionsPages={sessionsPages}
							isOpen={importSessionsModalOpen}
							groups={this.props.event.groups}
							toggle={this.toggleImportSessions}
							customFields={this.props.event.customFields.sessions}
							addSession={this.addSession}
							eventId={eventId}
							event={this.props.event}
							orgId={this.props.orgId}
							groupImportedSessions={this.props.groupImportedSessions}
						/>}
						{sessionInsightsModalOpen && (
							<FieldModal size="large" isOpen={sessionInsightsModalOpen} toggle={() => this.setState({ sessionInsightsModalOpen: false })} modalTitle="Session Change Log" cancelButtonLabel="Close" isFullWidthBody={true} bodyContent={() => {
								return <SessionInsights customFields={this.state.columns} sessions={sortedSessions} orgId={orgId} eventId={eventId} />
							}} />)}
						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route exact path={`/:organization/events/:eventId/schedule/:sessionId`}
									render={(props) => {
										const sessionId = props.match.params.sessionId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={sessionId}>

											{!loading && <EventSession
												sortedSessions={sortedSessions}
												updateTeam={this.updateTeam}
												orgId={this.props.orgId}
												event={this.props.event}
												eventId={this.state.eventId}
												index={selectedSession}
												sessions={sessions}
												readOnly={readOnly}
												venues={this.props.venues}
												customFields={this.props.customFields}
												loadMoreAttendees={this.props.loadMoreAttendees}
												locations={this.props.locations}
												addSession={this.addSession}
												updateSession={this.updateSession}
												deleteSession={this.deleteSingleSession}
												eventEmails={this.props.eventEmails}
												addNewGroup={this.props.addNewGroup}
												organization={this.props.organization}
												excludeUpdateAttendees={this.props.excludeUpdateAttendees}
												addNewLocation={this.props.addNewLocation}
												renameLocation={this.props.renameLocation}
												deleteLocation={this.props.deleteLocation}
												account={this.props.account}
												setActiveRow={this.setActiveRow}
											/>}
											<LoadingWizard loading={loading} />
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/events/:eventId/schedule`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(EventSessions);
