import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class AddGroupModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			capacity: null,
			hasCapacity: false,
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	handleChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	addGroup = async (group) => {
		this.props.handleSubmit(group, this.props.toggle);
	};

	submit = () => {
		const { name } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		if (name === '') {
			requiredFields.name = true;
			valid = false;
		}

		if (valid) {
			this.setState({ submitting: true });
			this.addGroup({ name });
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, submitting, invalid, requiredFields } = this.state;
		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add Group"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Group Name"
								onChange={this.handleChange}
								value={name}
								required={true}
								errorTrigger={requiredFields.name}
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding Group...'}
				actionButton={() => this.submit()}
				actionButtonDisabled={submitting}


			></FieldModal>

		);
	}
}

export default AddGroupModal;
