import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteEmailModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			submitting: false
		}
	}

	deleteCampaign = async () => {
		const { orgId, eventId, email, toggle, update, updateDeleteCampaign, noRedirect } = this.props;
		this.setState({ submitting: true })
		try {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/emails/`, { emails: [email._id] })
				.then((res) => {
					update({ sent: true }, () => {
						updateDeleteCampaign(email._id, () => {

							if (!noRedirect) {
								this.props.history.push('./');
							}
						});
					});
				});
		} catch (e) {
			console.log(e);
		}
	};
	render() {
		const { isOpen, toggle, email } = this.props;
		const { submitting } = this.state;

		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Email"}
					bodyHeaderText={'You are about to delete this email from the event, this is irreversible, would you like to continue?'}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.deleteCampaign()}
					actionButtonDisabled={submitting}
					isDelete={true}

				></FieldModal>

			</div>

		);
	}
}

export default withRouter(DeleteEmailModal);
