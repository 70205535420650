import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

class ReportsGrid extends Component {
    constructor(props) {
        super(props);
        this.sidebarScroll = React.createRef();
        this.contentScroll = React.createRef();
        this._preventEvent = false;
        this.contentLastScrollTop = 0;
        this.contentLastScrollLeft = 0;
        this.state = {
            hoverIndex: null,
            lastCheckedIndex: null,
            hoverColumnActive: null,
            columnMetadata: [],
            interval: null
        };
    }

    componentDidMount() {
        const { columns, defaultSort, sortDirection } = this.props;
        this.dragImg = new Image(0, 0);
        this.dragImg.src =
            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

        if (defaultSort) {
            if (sortDirection && sortDirection === 'desc') {
                this.props.sortData(columns.find((c) => c.key === defaultSort), false, true);

            } else {
                this.props.sortData(columns.find((c) => c.key === defaultSort), true, false);
            }
        }
    }


    GridItem(meta, index) {
        const { rowSettings } = this.props;
        return (
            <div
                key={`row-${meta._id}-${index}`}
                className="flex"
                style={{ border: '1px solid #D5D5D5', width: 'clamp(250px, 23%, 350px)', borderRadius: 5, height: 80, marginBottom: 20, marginRight: 20 }}
            >
                <div className='flex aic jcc' style={{ fontSize: 40, background: '#F5F6FA', minWidth: 60 }}>

                    {meta.icon ? (<i className={`${meta.icon}`} />) : <i className="las la-file-alt" />}
                </div>
                <Link to={meta.link ? meta.link : ''} >
                    <div className='flex column jcc p-10 black h-100'>
                        <h3>{meta.name}</h3>
                        <p className='text-trunc'>{meta.description}</p>
                    </div>
                </Link>
                <div className='ml-a p-10 pl-0'>
                    <div className='flex jcc c-pointer mb-10' onClick={() => {
                        this.props.favoriteReport(meta._id, !meta.favorited);
                    }}>{meta.favorited ? <i className='las la-star' style={{ color: '#ffd400' }}></i> : <i className='lar la-star'></i>}</div>

                    {rowSettings && rowSettings.length > 0 &&
                        <div>
                            <div className='detailsContainer' >
                                <UncontrolledDropdown direction="bottom" inNavbar >
                                    <DropdownToggle className="columnToggle" style={{ width: 30, height: 30 }}>
                                        <div className='icon'>
                                            <i className="las la-ellipsis-v" />
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu className="columnDropdown" style={{ minWidth: 230 }}>
                                        <div className='ph-20'>
                                            {rowSettings.map((item, index) => {
                                                return (
                                                    <div>
                                                        <DropdownItem
                                                            className={`moreViewsPopoverItemMenuItem`}
                                                            style={{ textTransform: 'capitalize', padding: '7px 10px' }}
                                                            onClick={() => {
                                                                item.onClick && item.onClick(meta._id);
                                                            }}
                                                        >

                                                            {item.label}
                                                        </DropdownItem>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    render() {
        const {
            data,
        } = this.props;

        return (<div className='reportsGrid'>

            <div className="w-100 flex wrap">
                {data.map((d, i) => this.GridItem(d, i))}
            </div>
        </div>);
    }
}

export default ReportsGrid;
