import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Modal, ModalBody } from 'reactstrap';

import FieldsCodeEditor from '../codeEditor/FieldsCodeEditor';
import * as IFrame from 'react-iframe-form';
import ColorField from '../inputs/colorField';

import EmailComponentLibrary from './emailComponentLibrary'
import EmailEditorComponent from './emailEditorComponent';
import moment from 'moment';
import { CircularProgressbar } from 'react-circular-progressbar';


const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
let refreshTimeInterval = null;


const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

class TemplateEditorModal extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;

		const customFields = [
			{
				text: 'Organization Name',
				value: '{{orgName}}',
				label: 'Organization Name'
			}
		];
		this.state = {
			orgSenderEmail: '',
			orgSenderName: '',
			selectedSection: 0,
			editedEmailTemplate: {},
			sendEmailModalOpen: false,
			editing: false,
			errorMessage: false,
			DeleteEmailModalOpen: false,
			loading: true,
			codeExpanded: false,
			previewExpanded: false,
			scheduleEmailModalOpen: false,
			loading: true,
			editCount: 0,
			editedPostData: {},
			designerPanelOpen: false,
			focusedComponent: -1,
			detailsExpanded: false,
			customFields: customFields,
			isDragging: false,
			componentDetailsOpen: false,
			componentDetailsExpanded: false,
			bodyDetailsOpen: false,
			emailStylesOpen: false,
		};
	}

	componentDidMount() {
		const { emailTemplate, postData, keyedMetadata, keyedData } = this.props;
		const parameters = emailTemplate.parameters;
		keyedMetadata.forEach((option) => {
			!parameters.includes(option) && parameters.push(option);
		})
		const metadataOptions = [];
		keyedData &&
			Object.keys(keyedData).forEach((key) => {
				const layer1 = keyedData[key];
				if (typeof layer1 === 'object') {
					Object.keys(layer1).forEach((key2) => {
						const layer2 = layer1[key2];
						const k2 = key2.substring(0, 1) + key2.substring(1);
						if (typeof layer2 === 'object') {
							Object.keys(layer2).forEach((key3) => {
								const layer3 = layer2[key3];
								const k3 = key3.substring(0, 1) + key3.substring(1);
								if (
									key !== '_defaultLabel' &&
									key2 !== '_defaultLabel' &&
									key3 !== '_defaultLabel'
								) {
									//metadataOptions.push(`${key}.${key2}.${key3}`);
									metadataOptions.push({
										label: `${layer1._defaultLabel} ${layer2._defaultLabel} ${layer3}`,
										value: `{{${key}${k2}${k3}}}`,
										text: `${key}${k2}${k3}`
									});
								}
							});
						} else {
							if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
								//metadataOptions.push(`${key}.${key2}`);
								metadataOptions.push({
									label: `${layer1._defaultLabel} ${layer2}`,
									value: `{{${key}${k2}}}`,
									text: `${key}${k2}`
								});
							}
						}
					});
				} else {
					if (key !== '_defaultLabel') {
						metadataOptions.push({ label: layer1, value: key, text: `{{${key}}}` });
					}
				}
			});
		let codeOnly = (!emailTemplate.emailComponents) || (emailTemplate.emailComponents.length === 0 && emailTemplate.html !== '') || emailTemplate.codeOnly;
		this.setState({ editedEmailTemplate: { ...JSON.parse(JSON.stringify(emailTemplate)), parameters: parameters, codeOnly: codeOnly }, editedPostData: postData, loading: false, customFields: metadataOptions }, () => { !emailTemplate.codeOnly && this.updateFromComponents() });
		window.addEventListener('message', (ev) => {
			if (ev.data.type == 'component-clicked' && this.state.editedEmailTemplate.emailComponents) {
				const componentId = ev.data.componentId;
				if (componentId >= 0) {
					this.setState({
						focusedComponent: componentId,
						componentDetailsOpen: true,
					});
				}
			}
			if (typeof ev.data !== 'object') return;
			if (!ev.data.type) return;
			if (ev.data.type !== 'button-click') return;
			if (!ev.data.message) return;
		});
	}

	updateEditedEmailTemplate = (data, callback) => {
		this.setState({ editing: true, editedEmailTemplate: data }, callback);
	};

	addComponent = (component) => {
		const emailComponents = this.state.editedEmailTemplate && this.state.editedEmailTemplate.emailComponents ? this.state.editedEmailTemplate.emailComponents : [];

		switch (component) {
			case 'navbar':
				emailComponents.push({
					_id: `navbar_${emailComponents.length}`,
					type: 'Navbar',
					width: 700,
					backgroundColor: '#ffffff',
					paddingTop: 18,
					paddingRight: 0,
					paddingBottom: 15,
					paddingLeft: 0,
					src: this.props.branding && this.props.branding.logo !== '' ? this.props.branding.logo : 'https://placehold.co/135x22',
					alignment: 'center',
					maxLogoHeight: 22,
					logoSrc: '',
					altText: 'Image'

				});
				break;
			case 'image':
				emailComponents.push({
					_id: `image_${emailComponents.length}`,
					type: 'Image',
					alt: 'Image',
					width: 700,
					borderRadius: 0,
					borderColor: '',
					borderWidth: 0,
					paddingLeft: 0,
					paddingRight: 0,
					paddingBottom: 0,
					paddingTop: 0,
					link: '',
					src: 'https://placehold.co/700x400',
					backgroundColor: '#ffffff',
				});
				break;
			case 'hairline':
				emailComponents.push({
					_id: `hairline_${emailComponents.length}`,
					type: 'Hairline',
					emailWidth: 50,
					paddingTop: 50,
					paddingBottom: 50,
					paddingLeft: 0,
					paddingRight: 0,
					backgroundColor: '#ffffff',
					color: '#BBBBBB',
					thickness: 1,
					width: 620
				});
				break;
			case 'contentblock':
				emailComponents.push({
					_id: `contentblock_${emailComponents.length}`,
					type: 'Contentblock',
					backgroundColor: '#ffffff',
					paddingTop: 25,
					paddingBottom: 25,
					paddingLeft: 50,
					paddingRight: 50,

					headerAlignment: 'center',
					headerText: `<h1 style="text-align:center;"><span style="font-size: 50px;font-family: Arial;">Header</span></h1>`,

					subtitleAlignment: 'center',
					subtitleText: `<div style="text-align:center;"><span style="font-size: 20px;font-family: Arial;"><strong>Bold Subtitle</strong></span><span style="font-family: Arial;"><br></span><span style="font-size: 20px;font-family: Arial;">Subtitle Text</span></div>`,

					descriptionAlignment: 'center',
					descriptionText: `<div style="text-align:center;"><span style="font-size: 18px;font-family: Arial;">Description of the section goes here.</span></div>`,

					enableCTA: true,
					ctaAlignment: 'center',
					ctaType: 'Fill',
					ctaBackgroundColor: this.props.branding ? this.props.branding.accentColor : '',
					ctaOutlineColor: '',
					ctaText: `<div><span style="font-size: 18px;font-family: Arial;">Button Text</span></div>`,
					ctaIcon: '',
					ctaIconSize: 18,
					ctaIconColor: '',
					ctaIconPlacement: 'left',
					ctaUrl: '',
					ctaBorderRadius: 12,
					ctaWidth: 180,
					ctaHeight: 50,
					ctaPaddingTop: 11,
					ctaPaddingRight: 25,
					ctaPaddingBottom: 11,
					ctaPaddingLeft: 25,
					discalimerAlignment: '',
					disclaimerText: `<div style="text-align:center;"><span style="font-size: 12px;font-family: Arial;">*This email is for informational purposes only. Registration is required.</span></div>`,

				});
				break;
			case 'cta':
				emailComponents.push({
					_id: `cta_${emailComponents.length}`,
					type: 'Cta',
					backgroundColor: '#ffffff',
					paddingTop: 0,
					paddingBottom: 0,
					paddingLeft: 0,
					paddingRight: 0,
					ctaAlignment: 'center',
					ctaType: 'Fill',
					ctaBackgroundColor: this.props.branding ? this.props.branding.accentColor : '',
					ctaOutlineColor: '',
					ctaText: `<div><span style="font-size: 18px;font-family: Arial;">Button Text</span></div>`,
					ctaIcon: '',
					ctaIconPlacement: 'left',
					ctaIconSize: 18,
					ctaIconColor: '',
					ctaUrl: '',
					ctaBorderRadius: 12,
					ctaWidth: 180,
					ctaHeight: 50,
					ctaPaddingTop: 11,
					ctaPaddingRight: 25,
					ctaPaddingBottom: 11,
					ctaPaddingLeft: 25,

				});
				break;
			case 'text':
				emailComponents.push({
					_id: `text_${emailComponents.length}`,
					type: 'Text',
					paddingTop: 25,
					paddingBottom: 25,
					paddingLeft: 50,
					paddingRight: 50,
					backgroundColor: '#ffffff',
					text: `<div style="text-align:center;"><span style="font-size: 18px;font-family: Arial;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </span></div>`

				});
				break;
			case 'banner':
				emailComponents.push({
					_id: `banner_${emailComponents.length}`,
					type: 'Banner',
					paddingTop: 25,
					paddingBottom: 25,
					paddingLeft: 50,
					paddingRight: 50,
					backgroundColor: '#ffffff',
					backgroundImage: '',
					text: `<h1 style="text-align:center;"><span style="font-size: 50px;font-family: Arial;">Header</span></h1>`

				});
				break;
			case 'heading':
				emailComponents.push({
					_id: `heading_${emailComponents.length}`,
					type: 'Heading',
					paddingTop: 25,
					paddingBottom: 25,
					paddingLeft: 50,
					paddingRight: 50,
					backgroundColor: '#ffffff',
					text: `<h1 style="text-align:center;"><span style="font-size: 50px;font-family: Arial;">Header</span></h1>`

				});
				break;


			case 'footer':
				emailComponents.push({
					_id: `footer_${emailComponents.length}`,
					type: 'Footer',
					paddingTop: 13,
					paddingBottom: 15,
					paddingLeft: 25,
					paddingRight: 25,
					backgroundColor: '#000000',
					text: `<div style="text-align:center;"><span style="color: rgb(255,255,255);font-size: 7px;font-family: Arial;">© ${moment().format('YYYY')} Company Name | Powered by Simple.Events</span></div>`

				});
				break;

			case 'locationblock':
				emailComponents.push({
					_id: `footer_${emailComponents.length}`,
					type: 'Locationblock',
					backgroundColor: "#FFFFFF",
					whenText: '<div><span style="font-size: 18px;font-family: Arial;"><strong>WHEN</strong></span></div>',
					whenDescription: `<div><span style="font-size: 18px;font-family: Arial;">${moment().format('MMMM Do, YYYY')}</span></div>`,
					whereText: '<div><span style="font-size: 18px;font-family: Arial;"><strong>WHERE</strong></span></div>',
					whereDescription: '<div><span style="font-size: 18px;font-family: Arial;">Convention Center<br>3772 Elm Drive<br>Gardden City, NY 11530</span></div>',
					paddingLeft: 50,
					paddingRight: 50,
					paddingBottom: 25,
					paddingTop: 25,
					cta: true,
					ctaUrl: '',
					descriptionText: `<div><span style="font-size: 13px;font-family: Arial;">Attire is business casual. </span><span style="font-family: Arial;"> </span></div><div><span style="font-size: 13px;font-family: Arial;">Dinner and drinks to be provided on site. </span></div>`,
					imageUrl: `https://placehold.co/280x280`,
					imageTarget: '',
					altText: 'Image',
					alignment: 'left'


				});
				break;

			case 'columnlayout':
				emailComponents.push({
					_id: `columnlayout_${emailComponents.length}`,
					type: 'Columnlayout',
					columnOption: 2,
					paddingBottom: 50,
					paddingTop: 50,
					paddingRight: 50,
					paddingLeft: 50,
					title: `<h1 style="text-align:center;"><span style="font-size: 36px;font-family: Arial;">Featured speakers</span></h1>`,
					backgroundImage: '',
					backgroundColor: '#ffffff',
					description: '',
					gridItems: [{
						imageSrc: '',
						title: `<div style="text-align:center;"><span style="font-size: 16px;font-family: Arial;"><strong>Title</strong></span></div>`,
						description: `<div style="text-align:center;"><span style="font-size: 16px;font-family: Arial;">Subtitle</span></div>`,
						imageLink: '',
						target: '',
						altText: 'Image',
					}, {
						imageSrc: '',
						title: `<div style="text-align:center;"><span style="font-size: 16px;font-family: Arial;"><strong>Title</strong></span></div>`,
						description: `<div style="text-align:center;"><span style="font-size: 16px;font-family: Arial;">Subtitle</span></div>`,
						imageLink: '',
						target: '',
						altText: 'Image',
					}
					],
				});
				break;
			case 'tablegrid':
				emailComponents.push({
					_id: `tablegrid_${emailComponents.length}`,
					type: 'Tablegrid',
					paddingTop: 50,
					paddingBottom: 50,
					paddingLeft: 50,
					paddingRight: 50,
					backgroundColor: '#ffffff',
					title: `<div style="text-align:center;"><span style="color: rgba(0,0,0,1);font-size: 30px;font-family: Arial;"><strong>Registration Details</strong></span></div>`,
					items: [
						{
							label: `<div><span style="color: rgba(0,0,0,1);font-size: 18px;font-family: Arial;"><strong>First Name:</strong></span></div>`,
							value: `<div><span style="color: rgba(0,0,0,1);font-size: 18px;font-family: Arial;">[Fname]</span></div>`
						},
						{
							label: `<div><span style="color: rgba(0,0,0,1);font-size: 18px;font-family: Arial;"><strong>Last Name:</strong></span></div>`,
							value: `<div><span style="color: rgba(0,0,0,1);font-size: 18px;font-family: Arial;">[Lname]</span></div>`
						},
					]

				});

				break;
			case 'sideimage':
				emailComponents.push({
					_id: `sideimage_${emailComponents.length}`,
					type: 'Sideimage',
					backgroundColor: "#FFFFFF",
					sideText: '<div><span style="font-size: 18px;"><strong>WHEN</strong></span></div>',
					paddingLeft: 50,
					paddingRight: 50,
					paddingBottom: 25,
					paddingTop: 25,
					cta: true,
					ctaUrl: '',
					imageUrl: `https://placehold.co/280x280`,
					imageTarget: '',
					altText: 'Image',
					reverse: false
				});

				break;
			case 'tablecolumns':
				emailComponents.push({
					_id: `tablecolumns_${emailComponents.length}`,
					type: 'Tablecolumns',
					paddingTop: 50,
					paddingBottom: 50,
					paddingLeft: 50,
					paddingRight: 50,
					backgroundColor: '#ffffff',
					firstColumnWidth: 180,
					secondColumnWidth: 300,
					thirdColumnWidth: 120,
					title: `<div style="text-align:center;"><span style="font-size: 18px;font-family: Arial;"><strong>Friday, May 5​</strong></span></div>`,
					items: [
						{
							first: `<div><span style="color: rgba(0,0,0,1);font-size: 15px;font-family: Arial;"><strong>All Day</strong></span></div>`,
							second: `<div><span style="color: rgba(0,0,0,1);font-size: 15px;font-family: Arial;">Arrivals</span></div>`,
							third: `<div><span style="color: rgba(0,0,0,1);font-size: 15px;font-family: Arial;">Hotel Lobby</span></div>`

						},
						{
							first: `<div><span style="color: rgba(0,0,0,1);font-size: 15px;font-family: Arial;"><strong>All Day</strong></span></div>`,
							second: `<div><span style="color: rgba(0,0,0,1);font-size: 15px;font-family: Arial;">Arrivals</span></div>`,
							third: `<div><span style="color: rgba(0,0,0,1);font-size: 15px;font-family: Arial;">Hotel Lobby</span></div>`
						},
					]

				});

				break;

			default:
				break;
		}

		this.setState({
			editing: true,
			editedEmailTemplate: { ...this.state.editedEmailTemplate, emailComponents: emailComponents },
			//focusedComponent: emailComponents.length - 1,
		},
			this.updateFromComponents);
	};

	updateFromComponents = () => {


		if (this.state.editedEmailTemplate.codeOnly) return;
		const emailComponents = this.state.editedEmailTemplate && this.state.editedEmailTemplate.emailComponents ? this.state.editedEmailTemplate.emailComponents : [];
		const updatedHtml = this.renderComponents(emailComponents);

		this.setState({
			editedEmailTemplate: { ...this.state.editedEmailTemplate, emailComponents: emailComponents, html: updatedHtml },

		}, () => {
			this.refreshPage()
		});
	}

	updateEmailComponent = (emailComponents) => {
		this.setState({
			editedEmailTemplate: { ...this.state.editedEmailTemplate, emailComponents: [...emailComponents] },

			editing: true
		}, () => {

			this.focusComponent(this.state.focusedComponent)
			this.updateFromComponents()

		})

	}

	refreshPage = () => {

		if (this.state.editCount > 0) {
			this.setState({ refreshTimeRemaining: 1.5 });
			if (!refreshTimeInterval) {
				refreshTimeInterval = setInterval(() => {
					if (this.state.refreshTimeRemaining > 0) {
						this.setState({ refreshTimeRemaining: this.state.refreshTimeRemaining - 0.1 });
					} else {
						this.setState({ editCount: this.state.editCount + 1 }, () => { this.focusComponent(this.state.focusedComponent) });
						clearInterval(refreshTimeInterval);
						refreshTimeInterval = null;
					}
				}, 100);
			}

		} else {
			this.setState({ editCount: this.state.editCount + 1 }, () => { this.focusComponent(this.state.focusedComponent) });
		}


	};

	renderComponents = (emailComponents) => {

		let html = "";

		//render as table rows for use in email template
		emailComponents && emailComponents.forEach((component, index) => {
			switch (component.type) {
				case 'Navbar':
					html +=
						`
						<div id="component_${index}" class="main">
							<table id="${index}" bgcolor="${component.backgroundColor}" style="background:${component.backgroundColor}; padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px;  " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody>
									<tr>
										<td style="text-align:${component.alignment};">
										${component.logoSrc ? `
											<a href="${component.logoSrc}"  target="_blank" style="text-decoration:none;">
												<img src="${component.src}" alt="${component.altText}" height="${component.maxLogoHeight}" style="height:${component.maxLogoHeight}px;border:none;text-decoration:none;">
											</a>
										`: `
											<img src="${component.src}" alt="${component.altText}" height="${component.maxLogoHeight}" style="height:${component.maxLogoHeight}px;border:none;text-decoration:none;">
										`}
											
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					`;
					break;

				case 'Image':
					html += `
					<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody>
									<tr>
										<td style="text-align:center;">


											${component.link ? `
												<a href="${component.link}" target="_blank" style="text-decoration:none;">
													<img src="${component.src}" alt="${component.alt}" width="${component.width}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px; width:${component.width}px; ${component.borderWidth > 0 ? `border:${component.borderColor} solid ${component.borderWidth}px;border-radius:${component.borderRadius}px;` : ``} text-decoration:none;color:#ffffff;">
												</a>
											
											`: `
												<img src="${component.src}" alt="${component.alt}" width="${component.width}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px;width:${component.width}px; ${component.borderWidth > 0 ? `border:${component.borderColor} solid ${component.borderWidth}px;border-radius:${component.borderRadius}px;` : ``} text-decoration:none;color:#ffffff;">
											`}

										</td>
									</tr>
								</tbody>
							</table>
						</div>
					`;
					break;
				case 'Hairline':
					html += `
					<div id="component_${index}" class="main">

						<table id="${index}" bgcolor="${component.backgroundColor ? component.backgroundColor : '#ffffff'}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									<tr id="${index}">
											<td  style="text-align:center; ">
												<div style="margin:auto;width:${component.width}px;background:none; border:${component.thickness}px solid ${component.color}; border-width:${component.thickness}px 0 0 0; "></div>
											</td>

									</tr>
								<tbody>
						</table>
					</div>
					`;
					break;

				case 'Contentblock':
					html += `	
					<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									${component.headerText && `
										<tr>
											<td style="overflow-wrap:break-word;word-break:break-word;">
												${component.headerText}
											</td>
										</tr>
									`}
									

									${component.subtitleText && `
										<tr>
											<td style="padding: 0px 0px 0px 0px; overflow-wrap:break-word;word-break:break-word;">
												${component.subtitleText}
											</td>
										</tr>
									
									`}
									
									${component.descriptionText && `
										<tr>
											<td style="padding: 20px 0px 0px 0px; overflow-wrap:break-word;word-break:break-word;">
												${component.descriptionText}
											</td>
										</tr>
									`}

									${component.enableCTA ? `
									<tr>
										<td>
											<table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:700px;">
												<tr>
												<td align="center" style="overflow-wrap:break-word;word-break:break-word;padding:30px 0px 0px 0px; background-color: ${component.backgroundColor};" bgcolor="${component.backgroundColor}" >    

												<!--[if (gte mso 9)|(IE)]>                 
													<table align="${component.ctaAlignment}" border="0" cellspacing="0" cellpadding="0" width="700">
														<tr>
															<td align="${component.ctaAlignment}" valign="top" width="700">                 
												<![endif]-->

												<table align="${component.ctaAlignment}" border="0" cellpadding="0" cellspacing="0" width="100%"
													style="max-width:700px;">
													<tbody>
														<tr>
															<td  align="${component.ctaAlignment}" style="padding: 0">
																<table  cellpadding="0">
																	<tbody>
																		<tr>
																			<td align="center"
																				style="border-radius: ${component.ctaBorderRadius}px;"
																				bgcolor="${component.ctaType !== 'Fill' ? 'transparent' : component.ctaBackgroundColor}"> 
																				<a href="${component.ctaUrl}"
																					target="_blank"
																					style="text-decoration: none; border-radius: ${component.ctaBorderRadius}px; background-color: ${component.ctaType !== 'Fill' ? 'transparent' : component.ctaBackgroundColor}; border:${component.ctaType !== 'Fill' ? `2px solid ${component.ctaOutlineColor}` : component.ctaBackgroundColor}   ; display: block;">
																					<table bgcolor="transparent"  align="center" role="presentation" cellpadding="0" cellspacing="0" border="0">
																						<tbody class="main">
																							<tr>
																								${component.ctaIconPlacement == "left" ? `
																									<td style="padding: ${component.ctaPaddingTop}px 0px ${component.ctaPaddingBottom}px ${component.ctaPaddingLeft}px;">${component.ctaIcon != '' ? ('<img style="padding: 0px 10px 0px 0px;" src="' + ENV + 'API/icons/svg/' + component.ctaIcon + '/' + (component.ctaIconColor ? component.ctaIconColor.replace("#", "%23") : 'black') + '" height="' + component.ctaIconSize + '" width="' + component.ctaIconSize + '" />') : ''}</td>
																									<td style="padding: ${component.ctaPaddingTop}px ${component.ctaPaddingRight}px ${component.ctaPaddingBottom}px 0px;" valign="middle">${component.ctaText}</td>
																								`: `
																									<td style="padding: ${component.ctaPaddingTop}px 0px ${component.ctaPaddingBottom}px ${component.ctaPaddingLeft}px;" valign="middle">${component.ctaText}</td>
																									<td style="padding: ${component.ctaPaddingTop}px ${component.ctaPaddingRight}px ${component.ctaPaddingBottom}px 0px;"> ${component.ctaIcon != '' ? ('<img style="padding: 0px 0px 0px 10px " src="' + ENV + 'API/icons/svg/' + component.ctaIcon + '/' + (component.ctaIconColor ? component.ctaIconColor.replace("#", "%23") : 'black') + '" height="' + component.ctaIconSize + '" width="' + component.ctaIconSize + '" />') : ''}</td>
																								`}
																							</tr>
																						</tbody>
																					</table>
																				</a>
																					</td>
																		</tr>
																	</tbody>
																</table>
															</td>
														</tr>
													</tbody>
												</table>

												<!--[if (gte mso 9)|(IE)]>                 
															</td>                 
														</tr>                 
													</table>                 
												<![endif]-->
												</td>
												</tr>
											</table>
										</td>
									</tr>
									`: ``}
									

									${component.disclaimerText && `
										<tr>
											<td style="padding: ${component.enableCTA ? '40' : '30'}px 0px 0px 0px; overflow-wrap:break-word;word-break:break-word; align="${component.disclaimerAlignment}">
												${component.disclaimerText}
											</td>
										</tr>
									`}

								</tbody>
							</table>
						</div>
					`;
					break;
				case 'Text':
					html += `
					<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									<tr>
										<td>
											${component.text}
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					`;
					break;

				case 'Banner':
					html += `
					<div id="component_${index}" class="main">

							<table id="${index}" background="${component.backgroundImage}" bgcolor="${component.backgroundColor}" style="background-size: cover; background-repeat: no-repeat;padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									<tr>
										<td>
											${component.text}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					`;
					break;


				case 'Heading':
					html += `
					<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									<tr>
										<td>
											${component.text}
										</td>

									</tr>
								</tbody>
							</table>
						</div>
					`;
					break;


				case 'Cta':
					html += `
						<div id="component_${index}" class="main">
							<table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:700px;">
								<tr>
								<td align="center" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px; background-color: ${component.backgroundColor};" bgcolor="${component.backgroundColor}" >    

								<!--[if (gte mso 9)|(IE)]>                 
									<table align="center" border="0" cellspacing="0" cellpadding="0" width="700">
										<tr>
											<td align="center" valign="top" width="700">                 
								<![endif]-->

								<table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
									style="max-width:700px;">
									<tbody>
										<tr>
											<td  align="center" style="padding: 0">
												<table  cellpadding="0">
													<tbody>
														<tr>
															<td align="center"
																style="border-radius: ${component.ctaBorderRadius}px;"
																bgcolor="${component.ctaType !== 'Fill' ? 'transparent' : component.ctaBackgroundColor}"> 
																<a href="${component.ctaUrl}"
																	target="_blank"
																	style="text-decoration: none; border-radius: ${component.ctaBorderRadius}px; background-color: ${component.ctaType !== 'Fill' ? 'transparent' : component.ctaBackgroundColor}; border:${component.ctaType !== 'Fill' ? `2px solid ${component.ctaOutlineColor}` : component.ctaBackgroundColor}   ; display: block;">
																	<table bgcolor="transparent"  align="center" role="presentation" cellpadding="0" cellspacing="0" border="0">
																		<tbody class="main">
																			<tr>
																				${component.ctaIconPlacement == "left" ? `
																					<td style="padding: ${component.ctaPaddingTop}px 0px ${component.ctaPaddingBottom}px ${component.ctaPaddingLeft}px;">${component.ctaIcon != '' ? ('<img style="padding: 0px 10px 0px 0px;" src="' + ENV + 'API/icons/svg/' + component.ctaIcon + '/' + (component.ctaIconColor ? component.ctaIconColor.replace("#", "%23") : 'black') + '" height="' + component.ctaIconSize + '" width="' + component.ctaIconSize + '" />') : ''}</td>
																					<td style="padding: ${component.ctaPaddingTop}px ${component.ctaPaddingRight}px ${component.ctaPaddingBottom}px 0px;" valign="middle">${component.ctaText}</td>
																				`: `
																					<td style="padding: ${component.ctaPaddingTop}px 0px ${component.ctaPaddingBottom}px ${component.ctaPaddingLeft}px;" valign="middle">${component.ctaText}</td>
																					<td style="padding: ${component.ctaPaddingTop}px ${component.ctaPaddingRight}px ${component.ctaPaddingBottom}px 0px;"> ${component.ctaIcon != '' ? ('<img style="padding: 0px 0px 0px 10px " src="' + ENV + 'API/icons/svg/' + component.ctaIcon + '/' + (component.ctaIconColor ? component.ctaIconColor.replace("#", "%23") : 'black') + '" height="' + component.ctaIconSize + '" width="' + component.ctaIconSize + '" />') : ''}</td>
																				`}
																			</tr>
																		</tbody>
																	</table>
																</a>
																	</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
									</tbody>
								</table>

								<!--[if (gte mso 9)|(IE)]>                 
											</td>                 
										</tr>                 
									</table>                 
								<![endif]-->
								</td>
								</tr>
							</table>
					</div>
					`;
					break;

				case 'Locationblock':
					html +=
						`			
						<div id="component_${index}" class="main">
			
							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									<tr>
										<td valign="top" width="100%" style="width: 100%;">
											<table id="${index}-1" width="100%" style="width: 100%; padding: 0px 60px 0px 0px">
												<tbody>
													<tr>
														<td style="overflow-wrap:break-word;word-break:break-word;">
															${component.whenText}
														</td>
														
													</tr>
													<tr>
														<td style="overflow-wrap:break-word;word-break:break-word;">
															${component.whenDescription}

														</td>
													</tr>

													<tr>
														<td style="padding: 30px 0px 0px 0px;overflow-wrap:break-word;word-break:break-word;">
															${component.whereText}
														</td>
													</tr>
													<tr>
														<td style="overflow-wrap:break-word;word-break:break-word;">
															${component.whereDescription}

														</td>
													</tr>

													<tr>
														<td style="padding: 50px 0px 0px 0px;overflow-wrap:break-word;word-break:break-word;">
															${component.descriptionText}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
										<td valign="top">

										${component.imageTarget ? `
											<a href="${component.imageTarget}" target="_blank" style="text-decoration:none;">
												<img src="${component.imageUrl}" alt="${component.altText}" style="width:280px;max-width: 700px; margin: 0px ${component.marginRight}px 0px ${component.marginLeft}px; border:${component.borderColor} solid ${component.borderWidth}px;padding:0px 0px 0px 0px;border-radius:${component.borderRadius}px;height:${component.maxLogoHeight}px;text-decoration:none;color:#ffffff;">
											</a>
										`: `
											<img src="${component.imageUrl}" alt="${component.altText}" style="width:280px;max-width: 700px; margin: 0px ${component.marginRight}px 0px ${component.marginLeft}px; border:${component.borderColor} solid ${component.borderWidth}px;padding:0px 0px 0px 0px;border-radius:${component.borderRadius}px;height:${component.maxLogoHeight}px;text-decoration:none;color:#ffffff;">
										`}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					
						`;
					break;
				case 'Sideimage':
					html +=
						`		
						<div id="component_${index}" class="main">
			
							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">

								${component.reverse ? `
								
									<tr>
										<td valign="top">
										${component.imageTarget ? `
										
											<a href="${component.imageTarget}" target="_blank" style="text-decoration:none;">
												<img src="${component.imageUrl}" alt="${component.altText}" style="width:280px;max-width: 700px; border:${component.borderColor} solid ${component.borderWidth}px;padding:0px 0px 0px 0px;border-radius:${component.borderRadius}px;height:${component.maxLogoHeight}px;text-decoration:none;color:#ffffff;">
											</a>
										
										`: `
											<img src="${component.imageUrl}" alt="${component.altText}" style="width:280px;max-width: 700px; border:${component.borderColor} solid ${component.borderWidth}px;padding:0px 0px 0px 0px;border-radius:${component.borderRadius}px;height:${component.maxLogoHeight}px;text-decoration:none;color:#ffffff;">

										`}
											
										</td>
										<td valign="top" width="100%" style="width: 100%;">
											<table id="${index}-1" width="100%" style="width: 100%; padding: 0px 0px 0px 60px">
												<tbody>
													<tr>
														<td style="overflow-wrap:break-word;word-break:break-word;">
															${component.sideText}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								` : `
									<tr>
										<td valign="top" width="100%" style="width: 100%;">
											<table id="${index}-1" width="100%" style="width: 100%; padding: 0px 60px 0px 0px">
												<tbody>
													<tr>
														<td style="overflow-wrap:break-word;word-break:break-word;">
															${component.sideText}
														</td>
													</tr>
												</tbody>
											</table>
										</td>
										<td valign="top">
										${component.imageTarget ? `
										
											<a href="${component.imageTarget}" target="_blank" style="text-decoration:none;">
												<img src="${component.imageUrl}" alt="${component.altText}" style="width:280px;max-width: 700px; border:${component.borderColor} solid ${component.borderWidth}px;padding:0px 0px 0px 0px;border-radius:${component.borderRadius}px;text-decoration:none;color:#ffffff;">
											</a>
										
										`: `
											<img src="${component.imageUrl}" alt="${component.altText}" style="width:280px;max-width: 700px; border:${component.borderColor} solid ${component.borderWidth}px;padding:0px 0px 0px 0px;border-radius:${component.borderRadius}px;text-decoration:none;color:#ffffff;">

										`}
										</td>
									</tr>
								`}
									
								</tbody>
							</table>
						</div>
					
						`;
					break;
				case 'Columnlayout':
					const rowCount = (Math.ceil(component.gridItems.length / component.columnOption));
					let content = ``;

					for (let i = 0; i < rowCount; i++) {

						let contentItem = ``;
						let itemCount = (component.gridItems.length % component.columnOption) == 0 ? component.columnOption : (component.gridItems.length % component.columnOption);


						for (let ic = (i * component.columnOption); (ic < ((i + 1) * component.columnOption)) && (ic !== component.columnOption.length - 1); ic++) {

							let item = component.gridItems[ic];

							let tileWidth = component.columnOption == 1 ? '700' : component.columnOption == 2 ? '330' : component.columnOption == 3 ? '213' : '160';
							tileWidth = Math.ceil(parseInt(tileWidth) - ((parseInt(component.paddingLeft) + parseInt(component.paddingRight)) / component.columnOption));
							let paddingBetween = ((700 - (parseInt(component.paddingLeft) + parseInt(component.paddingRight)) - (tileWidth * component.columnOption)));
							let imageToUse = (item && item.imageSrc) ? item.imageSrc : `https://placehold.co/${tileWidth}x${tileWidth}`
							contentItem += `
								<td valign="top">
									${item ? `
										<table align="" role="presentation" width="${tileWidth}" cellpadding="0" cellspacing="0"  border="0">
											<tbody class="main">
												<tr>
													<td valign="top" align="center" height="" style="text-align:center;">
														${item.target ? `
															<a href="${item.target}" target="_blank" style="text-decoration:none;">
																<img src="${imageToUse}" alt="${item.altText}" width="${tileWidth}"  style="width:${tileWidth}px;  max-width: ${tileWidth}px; text-decoration:none;color:#ffffff;">
															</a>
														`: `

															<img src="${imageToUse}" alt="${item.altText}" width="${tileWidth}"  style="width:${tileWidth}px;  max-width: ${tileWidth}px; text-decoration:none;color:#ffffff;">

														`}
														

													</td>
												</tr>
												<tr>
													<td height="" width="${tileWidth}" style="width:${tileWidth}px;padding: 20px 0px ${component.columnOption == 1 ? '50' : '50'}px 0px">
														${item.title}
														${item.description}
													</td>
												</tr>
											</tbody> 
										</table>
		
									`: ''}

									
								</td>
								${component.columnOption !== 1 && (ic < ((i + 1) * component.columnOption) - 1) ? `<td width="${paddingBetween}" style="width:${paddingBetween}px;"></td>` : ''}
							`
						}

						content += `
							<tr  style="vertical-align:baseline; padding: 0px 0px 50px 0px">
								${contentItem}
							</tr>
						`;


					}


					html +=
						`
						<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px 0px ${component.paddingLeft}px" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">

									${component.title && `
									<tr>
										<td style="padding: 0px 0px 28px 0px">
											${component.title}
										</td>
									</tr>`}

									


									<tr>
										<table bgcolor="${component.backgroundColor}" style="vertical-align:baseline;padding: 0px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
											<tbody>
												${content}
											</tbody>
										</table>
									</tr>
							
								
								</tbody>
							</table>
						</div>
					`
					break;
				case 'Footer':
					html += `
					<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" border="0">
								<tbody class="main">
									<tr>
										<td>
											${component.text}
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
					`;
					break;

				case 'Tablegrid':


					let rows = ``;


					for (let rci = 0; rci < component.items.length; rci++) {

						let rowContent = component.items[rci];



						rows += `
							<tr>
								<td>
									<table bgcolor="${component.backgroundColor}" style="padding: 13px ${component.paddingRight}px ${rci == (parseInt(component.items.length) - 1) ? `0` : `10`}px ${component.paddingLeft}px; border:none;vertical-align:baseline;" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" >
										<tbody>
											<tr style=" border:none;padding: 10px ${component.paddingRight}px 0px ${component.paddingLeft}px ">
												<td width="350" align="right" style="overflow-wrap:break-word;word-break:break-word;padding: 0px 5px 0px 0px">${rowContent.label}</td>
												<td width="350" style="overflow-wrap:break-word;word-break:break-word;padding: 0px 0px 0px 5px">${rowContent.value}</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>

								${rci !== (parseInt(component.items.length) - 1) ?

								`
								<tr>
									<td>
										<table id="${rci}" bgcolor="${component.backgroundColor}" style="max-width:700px;padding: 0px ${component.paddingRight}px 0px ${component.paddingLeft}px" align="center" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
											<tbody class="main">
												<tr id="${rci}">
													<td height="1" style="height: 1px;">
														<div style="height: 1px;margin:auto;background:none; border:$1px solid #D9D8D6; border-width:1px 0 0 0; border-style:solid; border-width:1px; border-width:1px 0 0 0 ; border-color:#D9D8D6;"></div>
													</td>
												</tr>
											<tbody>
										</table>
									</td>
								</tr>
									

									`  : ''}
								
								`;




					}


					html += `
						<div id="component_${index}" class="main">

							<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px 0px ${component.paddingBottom}px 0px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" >
								<tbody>
									<tr>
										<td>
											<table id="${index}" bgcolor="${component.backgroundColor}" style="padding: 0px ${component.paddingRight}px 23px ${component.paddingLeft}px " align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" >
												<tbody class="main">
													${component.title && `
														<tr>
															<td style="overflow-wrap:break-word;word-break:break-word; align="${component.headerAlignment}">
																${component.title}
															</td>
														</tr>
													`}
												</tbody>
											</table>
										</td>
									</tr>
									<tr>
										<td>
											${rows}
										</td>
									</tr>
								</tbody>
							</table>

								
						
						</div>	`;





					break;
				case 'Tablecolumns':


					let rowsTotal = ``;


					for (let rci = 0; rci < component.items.length; rci++) {

						let rowContent = component.items[rci];



						rowsTotal += `
							<tr>
								<td>
									<table bgcolor="${component.backgroundColor}" style="padding: 13px 0px ${rci == (parseInt(component.items.length) - 1) ? `0` : `10`}px 0px; border:none;vertical-align:baseline;" align="center" role="presentation" cellpadding="0" cellspacing="0" width="100%" >
										<tbody>
											<tr style=" border:none;padding:0 ">
												<td width="${component.firstColumnWidth ? component.firstColumnWidth : 180}px" align="left" style="overflow-wrap:break-word;word-break:break-word;padding: 0px 40px 0px 0px">${rowContent.first}</td>
												<td width="${component.secondColumnWidth ? component.secondColumnWidth : 300}px" style="overflow-wrap:break-word;word-break:break-word;padding: 0px 40px 0px 5px">${rowContent.second}</td>
												<td width="${component.thirdColumnWidth ? component.thirdColumnWidth : 120}px" style="overflow-wrap:break-word;word-break:break-word;padding: 0px 0px 0px 5px">${rowContent.third}</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							
								
								`;
					}


					html += `
					<div id="component_${index}" class="main">

						<table bgcolor="${component.backgroundColor}" style="" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" >
							<tbody class="main">
								${component.title ? `
									<tr>
										<td>
											<table bgcolor="${component.backgroundColor}" style="padding: ${component.paddingTop}px ${component.paddingRight}px 10px ${component.paddingLeft}px;" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" >
												<tbody class="main">
														<tr>
															<td style="overflow-wrap:break-word;word-break:break-word; align="${component.headerAlignment}">
																	${component.title}
																</td>
														</tr>
												</tbody>
											</table>
										</td>
					
										
									</tr>
								`: ``}
						
								<tr>
									<td>
										<table bgcolor="${component.backgroundColor}" style="padding: 13px ${component.paddingRight}px ${component.paddingBottom}px ${component.paddingLeft}px; border:none;vertical-align:baseline;" align="center" role="presentation" cellpadding="0" cellspacing="0" width="700" >
											<tbody>
													${rowsTotal}
											</tbody>
										</table>
									
									</td>
									
								</tr>
							</tbody>
						</table>
					</div>
				`;
					break

				case 'HTML':
					html += `
					<tr id="${index}">
						<td>${component.html}</td>
					</tr>`;
					break;

				default:
					break;
			}

		});
		return html;
	}


	onDragEnd = (result) => {
		this.setState({ isDragging: false })

		if (!result.destination) {
			return;
		}

		switch (result.destination.droppableId) {
			case 'droppable-email-components':
				if (result.source.droppableId === 'droppable-email-components') {
					const { editedEmailTemplate } = this.state;
					const { emailComponents } = editedEmailTemplate;
					const components = JSON.parse(JSON.stringify(reorder(emailComponents, result.source.index, result.destination.index)));
					editedEmailTemplate.emailComponents = components;

					this.updateEmailComponent(editedEmailTemplate.emailComponents)

				} else if (result.source.droppableId === 'droppable-components') {
					const { editedEmailTemplate } = this.state;
					const { emailComponents } = editedEmailTemplate;

					this.addComponent(result.draggableId);

					const components = JSON.parse(JSON.stringify(reorder(emailComponents, emailComponents.length - 1, result.destination.index)));
					editedEmailTemplate.emailComponents = components;
					this.updateEmailComponent(editedEmailTemplate.emailComponents)
				}

				break;

			case 'droppable-page':
				if (result.source.droppableId === 'droppable-components') {
					this.addComponent(result.draggableId);
				}
				break;

			default:
				break;

		}

	}

	focusComponent = (componentIndex) => {

		const previewFrame = document.getElementsByName(
			'PreviewFrame'
		)[0];


		componentIndex > -1
			? previewFrame.contentWindow.postMessage(
				{
					type: 'focusComponent',
					componentIndex: componentIndex
				},
				'*'
			)
			: previewFrame.contentWindow.postMessage(
				{
					type: 'unfocus'
				},
				'*'
			);
		this.setState({ focusedComponent: componentIndex, componentDetailsOpen: true, designerPanelOpen: false, emailStylesOpen: false });
	}


	cancelEdit = () => {
		this.setState({
			editedEmailTemplate: this.props.emailTemplate,
			editing: false
		});
	}

	toggleSection = (section) => {
		const { componentDetailsOpen, designerPanelOpen, emailStylesOpen } = this.state;
		switch (section) {
			case 'editor':
				this.setState({ componentDetailsOpen: !componentDetailsOpen, designerPanelOpen: false, emailStylesOpen: false })
				break;
			case 'style':
				this.setState({ emailStylesOpen: !emailStylesOpen, designerPanelOpen: false, componentDetailsOpen: false })
				break;
			case 'library':
				this.setState({ designerPanelOpen: !designerPanelOpen, componentDetailsOpen: false, emailStylesOpen: false })

				break;
		}
	}


	render() {
		const { isOpen, toggle, update, url, method, eventId } = this.props;
		const {
			editedEmailTemplate,
			editCount,
			designerPanelOpen,
			focusedComponent,
			detailsExpanded,
			editing,
			isDragging,
			componentDetailsOpen,
			emailStylesOpen,
			componentDetailsExpanded,
			bodyDetailsOpen,
			loading,
			codeExpanded
		} = this.state;
		const emailComponents = editedEmailTemplate.emailComponents || [];
		const emailStyles = editedEmailTemplate.emailStyles || {};

		return (
			<Modal isOpen={isOpen} toggle={toggle} className="fullscreen" >
				<div className='modalHeader ph-20' style={{ borderBottom: '1px solid var(--background)' }}>
					<div className='headerContainer' style={{ maxWidth: 'unset', minHeight: 60, height: 60 }}>
						<div className='flex aic'>
							{/* <i className='las la-envelope mr-25' style={{ fontSize: 50 }}></i> */}
							<div>

								<h2>{this.props.event ? this.props.event.name : this.props.program ? this.props.program.name : ''}</h2>
								<div className='flex aic '>
									<h4>Email</h4> <div className='mr-10 ml-10' style={{ fontSize: 12, lineHeight: 1 }}>|</div>
									<h3>{editedEmailTemplate.name}</h3>
								</div>
							</div>
						</div>

						<div className='flex aic'>

							{this.state.refreshTimeRemaining > 0 && (
								<div className='flex aic'
									style={{ width: 20, height: 20, fontSize: 20, marginRight: 20 }}
								>
									<CircularProgressbar
										value={this.state.refreshTimeRemaining}
										maxValue={1.5}
										text={this.state.refreshTimeRemaining.toFixed(0)}
										strokeWidth={12}
										counterClockwise={true}
									/>
								</div>
							)}
							<button className="neu mr-25 " onClick={() => {
								if (editing) {
									if (window.confirm('Are you sure you want to discard your changes?')) {
										this.cancelEdit();
										this.props.toggle();
									}
								} else {
									this.props.toggle();
								}
							}
							}>{editing ? 'Close & Discard Changes' : 'Close'}</button>
							<button className={`neu  ${editing ? 'prim' : 'disabled'}`}
								onClick={() => {
									update(editedEmailTemplate);
									this.setState({ editing: false });
								}}>Save</button>
						</div>

					</div>
				</div>
				{!editedEmailTemplate.codeOnly ? <ModalBody className="modalBody">
					<DragDropContext className="editorWorkspace" onDragEnd={this.onDragEnd} onDragStart={(e, f) => {
						this.setState({ isDragging: true });
					}}>
						<div className='flex jcsb w-100 h-100'>

							<div className='designerPreview'>

								{/* <div className='headerHeight flex aic jcsb w-100'>
								<button className={` neu ${designerPanelOpen ? 'inset' : ''}`} onClick={() => this.setState({ designerPanelOpen: !this.state.designerPanelOpen })}>Toggle Overview</button>
								<button className={`neu ${componentDetailsOpen ? 'inset' : ''}`} onClick={() => this.setState({ componentDetailsOpen: !this.state.componentDetailsOpen })}>Page Components</button>

							</div> */}

								<Droppable droppableId="droppable-page">
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											className="editorFrame w-100"
											style={{
												pointerEvents: isDragging ? 'none' : 'auto'
											}}
										>
											<div className='pagePreviewContainer2'>

												<IFrame.Form
													id="builderPreviewFrame"
													key={`template-${editCount}`}
													name="PreviewFrame"
													config={{ url, method, data: { html: editedEmailTemplate.html, rendering: true, style: emailStyles, focusedComponent: this.state.focusedComponent, ...(this.props.eventId ? { eventId: this.props.eventId } : {}) } }}
												/>

											</div>

										</div>
									)}


								</Droppable>

								{emailComponents.length == 0 && <div className='startCreatingPrompt'>
									<h4>Drag over components from the library to build your email.</h4>
								</div>}
							</div>
							<div style={{ overflowX: 'hidden', position: 'relative' }} className={`designerPanel p-0 right ${emailStylesOpen ? '' : 'closed'}`}>

								<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
									<button onClick={() => this.toggleSection('style')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
									<h1 className='mb-15'>Style</h1>
									<ColorField useHex={true} label="Background Color" value={emailStyles.backgroundColor} onChange={
										(color) => {
											const editedEmailTemplate = this.state.editedEmailTemplate;
											const emailStyles = editedEmailTemplate.emailStyles || {};
											emailStyles.backgroundColor = typeof color == 'object' ? '' : color;
											editedEmailTemplate.emailStyles = emailStyles;
											this.setState({ editedEmailTemplate, editing: true, editCount: this.state.editCount + 1 }, this.updateFromComponents);
										}
									} />
								</div>
							</div>
							<div style={{ overflowX: 'hidden', position: 'relative' }} className={`designerPanel p-0 right ${componentDetailsOpen ? '' : 'closed'} ${componentDetailsExpanded ? 'expanded' : ''}`}>
								{/* <ColorField useHex={true} label="Background Color" value={emailStyles.backgroundColor} onChange={
									(color) => {
										const editedEmailTemplate = this.state.editedEmailTemplate;
										const emailStyles = editedEmailTemplate.emailStyles || {};
										emailStyles.backgroundColor = typeof color == 'object' ? '' : color;
										editedEmailTemplate.emailStyles = emailStyles;
										this.setState({ editedEmailTemplate, editing: true, editCount: this.state.editCount + 1 }, this.updateFromComponents);
									}
								} /> */}
								<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
									<button onClick={() => this.toggleSection('editor')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
									<h1 className='mb-15'>Editor</h1>
									{!loading && <EmailEditorComponent
										componentSelected={focusedComponent !== -1 ? true : false}
										editedEmailTemplate={editedEmailTemplate}
										focusedComponentIndex={focusedComponent}
										togglePanel={() => { this.setState({ componentDetailsOpen: !componentDetailsOpen }) }}
										expanded={componentDetailsExpanded}
										expandPanel={() => this.setState({ componentDetailsExpanded: !componentDetailsExpanded })}
										focusComponent={this.focusComponent}
										updateEmailComponent={this.updateEmailComponent}
										links={this.props.links}
										staticCustomFields={this.props.staticCustomFields}
										customFields={this.state.customFields}
									/>}
								</div>
							</div>
							<div style={{ overflow: 'hidden', position: 'relative' }} className={`designerPanel p-0 ${!designerPanelOpen ? 'closed' : ''}`}>

								<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
									<button onClick={() => this.toggleSection('library')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
									<h1 className='mb-15'>Library</h1>

									<EmailComponentLibrary
										togglePanel={() => { this.setState({ designerPanelOpen: !this.state.designerPanelOpen }) }}
									/>
								</div>

							</div>

							{<div className='designerPanel side p-0' style={{ overflow: 'hidden', position: 'relative', minWidth: 100, width: 100 }} >
								<div className='flex column jcc'>
									<div className={`flex column jcc aic c-pointer ${componentDetailsOpen ? 'selectedSection' : ''} `} style={{ height: 100 }} onClick={() => {
										this.toggleSection('editor')
									}}>
										<i className='las la-edit mb-10' style={{ fontSize: 30 }}></i>
										<h5>Editor</h5></div>
									{<div className={`flex column jcc aic c-pointer ${emailStylesOpen ? 'selectedSection' : ''} `} style={{ height: 100 }} onClick={() => {
										this.toggleSection('style')
									}}>
										<i className='las la-brush mb-10' style={{ fontSize: 30 }}></i>
										<h5>Style</h5></div>}
									<div className={`flex column jcc aic c-pointer ${designerPanelOpen ? 'selectedSection' : ''} `} style={{ height: 100 }} onClick={() => {
										this.toggleSection('library')
									}}>
										<i className='las la-book mb-10' style={{ fontSize: 30 }}></i>
										<h5>Library</h5></div>
									{/* <div className={`flex column jcc aic c-pointer ${bodyDetailsOpen ? 'seBlue' : ''} `} style={{ height: 100 }}>
										<i className='las la-pen-square mb-10' style={{ fontSize: 30 }}></i>
										<h5>Body</h5></div> */}
								</div>

								<div className='flex column jcc aic c-pointer mt-a' style={{ height: 100, marginBottom: 70 }} onClick={() => {
									if (window.confirm("Are you sure you want to move to code editor? You will no longer be able to edit using drag and drop tools for this campaign.")) {

										const editedEmailTemplate = this.state.editedEmailTemplate;
										editedEmailTemplate.codeOnly = true;

										this.setState({ editedEmailTemplate,  editCount: this.state.editCount + 1 });
									}
								}}>
									<i className='las la-code mb-10' style={{ fontSize: 30 }}></i>
									<h5>Code</h5></div>
							</div>}
						</div>
					</DragDropContext>
				</ModalBody> : <ModalBody className="modalBody ">
					<div className='flex jcsb w-100 h-100'>

						<div className='designerPreview'>
							<div className='pagePreviewContainer2'>
								<IFrame.Form
									id="builderPreviewFrame"
									key={`template-${editCount}`}
									name="PreviewFrame"
									config={{ url, method, data: { html: editedEmailTemplate.html, rendering: false, style: emailStyles, eventId: eventId } }}
								/></div>
						</div>
						<div className={`designerPanel right ${codeExpanded ? 'expanded' : 'closed'} posRel`} >
							<FieldsCodeEditor
								expand={() => this.setState({ codeEditorExpanded: !this.state.codeEditorExpanded })}
								style={{ height: '90%' }}
								content={editedEmailTemplate.html}
								customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
								language="html"
								setContent={(html, doNotEdit) => {
									const editedEmailTemplate = this.state.editedEmailTemplate;
									editedEmailTemplate.html = html;
									if(doNotEdit){
										this.setState({ editedEmailTemplate }, () => {
											this.refreshPage();
										});
									}else{
											this.setState({ editedEmailTemplate, editing:true }, () => {
										this.refreshPage();
									});
									}
								
								}}
							/>
						</div>
						{<div className='designerPanel side p-0' style={{ overflow: 'hidden', position: 'relative', minWidth: 100, width: 100 }} >
							<div className='flex column jcc h-100 w-100'>
								<div className={`flex column jcc aic c-pointer ${codeExpanded ? 'seBlue' : ''} `} style={{ height: 100 }} onClick={() => {
									this.setState({ codeExpanded: !codeExpanded })
								}}>
									<i className='las la-code mb-10' style={{ fontSize: 30 }}></i>
									<h5>Code</h5>
								</div>


								<div className='flex column jcc aic c-pointer mt-a' style={{ height: 100 }} onClick={() => {
									if (window.confirm("Are you sure you want to move to drag & drop editor? Note you will lose all code for this campaign.")) {

										const editedEmailTemplate = this.state.editedEmailTemplate;
										editedEmailTemplate.codeOnly = false;

										this.setState({ editedEmailTemplate, editing: true, editCount: this.state.editCount + 1 });
									}
								}}>
									<i className='las la-cubes mb-10' style={{ fontSize: 30 }}></i>
									<h5>Blocks</h5></div>
							</div>
						</div>}
					</div>
				</ModalBody>
				}
			</Modal>
		);
	}
}

export default withRouter(TemplateEditorModal);
