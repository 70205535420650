import React from 'react';
import { Table, Row, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import Venue from './venue';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import { Trash, ChevronRight, ChevronUp, ChevronDown } from 'react-feather';
import moment from 'moment';
import defaultImage from '../../assets/img/gallery/PlaceholderHeader-Restaurant.jpg';

class VenuesList extends React.Component {
	constructor(props) {
		super(props);
		this.sidebarScroll = React.createRef();
		this.contentScroll = React.createRef();
		this._preventEvent = false;
		this.contentLastScrollTop = 0;
		this.contentLastScrollLeft = 0;
		this.state = {
			deleteModalOpen: false,
			selectedVenueId: ''
		};
	}

	promptDeleteVenue = (venueId) => {
		this.setState({ deleteModalOpen: true, selectedVenued: venueId });
	};

	onSidebarScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;

			return;
		}

		this._preventEvent = true;
		this.contentScroll.current.scrollTop = e.target.scrollTop;
	};

	onContentScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}

		if (e.target.scrollTop !== this.contentLastScrollTop) {
			this._preventEvent = true;
			this.sidebarScroll.current.scrollTop = e.target.scrollTop;
			this.contentLastScrollTop = e.target.scrollTop;
		}
	};

	render() {
		const { venues, listType, onCriteriaChange, criteria } = this.props;
		const { deleteModalOpen } = this.state;
		let count = 0;
		return (
			<div className="sectionBody" style={{ paddingTop: 0 }}>
				<div className="sectionTable">
					<div className="tableMainColumn">
						<div className="tableColumnHeader">
							<div className="tableMainColumnCell">
								<div
									className="tableCellData"
									onClick={() =>
										criteria === 'NameAsc'
											? onCriteriaChange('NameDesc')
											: onCriteriaChange('NameAsc')}
								>
									<div className="tableColumnTitle">
										Name<span>
											{criteria === 'NameAsc' && <ChevronDown />}
											{criteria === 'NameDesc' && <ChevronUp />}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div
							className="tableColumnRows hideScrollbar"
							ref={this.sidebarScroll}
							onScroll={this.onSidebarScroll}
						>
							{venues.map((venue, index) => {
								return (
									<div
										className="tableMainColumnRowCell"
										key={`tmcrc-${index}`}
										style={{
											backgroundImage: `linear-gradient(#ffffffdd, #ffffffdd),url(${venue.image
												? venue.image
												: defaultImage})`,
											backgroundSize: 'cover',
											backgroundPosition: 'center',
											backgroundRepeat: 'no-repeat'
										}}
									>
										{/* venue.image ? (
											<img
												src={venue.image}
												alt="test"
												width="57"
												height="40"
												style={{
													marginRight: '10px',
													borderRadius: '10px 10px 10px 10px'
												}}
											/>
										) : (
											<img
												src={defaultImage}
												alt="test"
												width="57"
												height="40"
												style={{
													marginRight: '10px',
													borderRadius: '10px 10px 10px 10px'
												}}
											/>
										) */}
										<div
											style={{ marginTop: 'auto', marginBottom: 'auto' }}
											className=""
										>
											{venue.name}
										</div>
										<Link
											className="ml-a"
											to={`./venues/${venue._id}`}
											style={{ marginTop: 'auto', marginBottom: 'auto' }}
										>
											<ChevronRight size={18} style={{ color: 'black' }} />
										</Link>
									</div>
								);
							})}
						</div>
					</div>
					<div className="mainColumnShadow" style={{ height: 'unset' }} />
					<div className="tableSubColumns">
						<div className="tableColumnHeader">
							<div
								className="tableSubColumn"
								onClick={() =>
									criteria === 'WebsiteAsc'
										? onCriteriaChange('WebsiteDesc')
										: onCriteriaChange('WebsiteAsc')}
							>
								<div className="tableColumnTitle">
									Website<span>
										{criteria === 'WebsiteAsc' && <ChevronDown />}
										{criteria === 'WebsiteDesc' && <ChevronUp />}
									</span>
								</div>
							</div>

							<div
								className="tableSubColumn"
								onClick={() =>
									criteria === 'LocationsAsc'
										? onCriteriaChange('LocationsDesc')
										: onCriteriaChange('LocationsAsc')}
							>
								<div className="tableColumnTitle">
									Locations<span>
										{criteria === 'LocationsAsc' && <ChevronDown />}
										{criteria === 'LocationsDesc' && <ChevronUp />}
									</span>
								</div>
							</div>

							<div
								className="tableSubColumn"
								onClick={() =>
									criteria === 'CreatedAsc'
										? onCriteriaChange('CreatedDesc')
										: onCriteriaChange('CreatedAsc')}
							>
								<div className="tableColumnTitle">
									Created<span>
										{criteria === 'CreatedAsc' && <ChevronDown />}
										{criteria === 'CreatedDesc' && <ChevronUp />}
									</span>
								</div>
							</div>
						</div>

						<div className="tableColumnRows w-fc" ref={this.contentScroll} onScroll={this.onContentScroll}>
							{venues.map((venue, index) => {
								return (
									<div className="tableColumnRow" key={`tcr-${index}`}>
										<div className="tableColumnRowCell text-truncate calibreRegular fs-16">
											<a target="_blank" href={venue.website}>
												{venue.website}
											</a>
										</div>
										<div className="tableColumnRowCell text-truncate calibreRegular fs-16">
											{venue.locations.length}
										</div>
										<div className="tableColumnRowCell text-truncate calibreRegular fs-16">
											{moment(venue.createdAt).format('M/D/YYYY')}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<Modal isOpen={deleteModalOpen}>
					<ModalHeader>Delete Venue</ModalHeader>
					<ModalBody>
						You are about to permanently delete this venue and all its contents. This is irreversible, do
						you wish to continue?
					</ModalBody>
					<ModalFooter>
						<Button
							color="primary"
							onClick={() => {
								this.props.deleteVenue(this.state.selectedVenueId);
								this.setState({ deleteModalOpen: false });
							}}
						>
							Yes
						</Button>
						<Button color="secondary" onClick={() => this.setState({ deleteModalOpen: false })}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default VenuesList;
