import React, { Component } from 'react';

import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
import AddProgramModal from './modals/addProgramModal';
import DeleteProgramsModal from './modals/deleteProgramsModal';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import FieldModal from '../../components/modals/fieldModal';
import Requests from './requests';

let batchCallback;
class Programs extends Component {
	constructor(props) {
		super(props);

		const fieldColumns = {
			name: 'Name',
			eventCount: 'Event Count',
			requestCount: 'Request Count',
			requestFormActive: 'Request form active',
			requestFormLink: 'Request Form Link',
			eventType: 'Event Type',
			startTime: 'Start Time',
            endTime: 'End Time',
            timezone: 'Timezone',

		};

		const fieldTypes = {
			name: 'string',
			eventCount: 'number',
			requestCount: 'number',
			requestFormActive: 'string',
			requestFormLink: 'link',
			eventType: 'string',
			startTime: 'time',
            endTime: 'time',
            timezone: 'timezone',
		};
		this.state = {
			programs: [],
			programViews: [],
			loading: true,
			columns: fieldColumns,
			columnTypes: fieldTypes,
			addedPrograms: [],
			selectedPrograms: [],
			addModalOpen: false,
			deleteModalOpen: false,
			requests: [],
			latestRequests: [],
			requestSummaryOpen: false,
		};
	}

	componentDidMount() {
		API()
			.get(`Organizations/${this.props.orgId}/programs`)
			.then((res) => {
				if (res.data) {
					this.setState({
						programs: res.data.programs.map(p => {
							return {
								...p,
								link: `/${this.props.orgId}/programs/${p._id}/details`
							}
						}),
						programViews: res.data.views,
						loading: false
					});
				} else {
					this.setState({
						loading: false
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});

		API()
			.get(`Organizations/${this.props.orgId}/programRequests`)
			.then((res) => {
				if (res.data) {
					const sortedRequests = res.data.requests.map((request) => {
						Object.keys(request).forEach((key) => {
							if (key.includes('-')) {
								const idSplit = key.split('-');
								const id = idSplit[idSplit.length - 1];
								request[id] = request[key];
							}
						});
						return request;
					}).sort((a, b) => {
						return moment(b.submitDate).diff(moment(a.submitDate));
					});

					const latestRequests = [];
					sortedRequests.forEach((request) => {
						if (request.status == 'pending' && request.submitDate) {
							latestRequests.push(request);
						}
					});

					this.setState({
						requests: sortedRequests,
						latestRequests: latestRequests,
						requestViews: res.data.views,
						customFields: res.data.customFields,
						loading: false,
					});
				} else {
					this.setState({
						loading: false
					});
				}
			}
			);

	}

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};

	addProgram = async (program) => {
		await API()
			.post(`Organizations/${this.props.orgId}/programs`, program)
			.then((res) => {
				if (res.data) {
					const program = res.data;
					this.setState({ programs: [...this.state.programs, program], adding: false });
					//window.location.href = `./programs/${res.data._id}/0`;
					this.props.history.push(`./programs/${res.data._id}/details`);
					return true;
				} else {
					return false;
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	toggleDeletePrograms = (programIds, bcb) => {
		batchCallback = bcb;
		this.setState({
			selectedPrograms: programIds ? Object.keys(programIds) : [],
			deleteModalOpen: !this.state.deleteModalOpen
		});
	};

	deletePrograms = async () => {
		await API()
			.post(`Organizations/${this.props.orgId}/programs/delete`, { programIds: this.state.selectedPrograms })
			.then((res) => {
				if (res.data) {
					const programs = this.state.programs.filter((e) => !this.state.selectedPrograms.includes(e._id));
					this.setState({ programs: programs });
					batchCallback && batchCallback('delete');
					this.toggleDeletePrograms({});
				}
			});
	};

	render() {
		const {
			loading,
			programViews,
			columns,
			programs,
			columnTypes,
			addModalOpen,
			addedPrograms,
			deletedPrograms,
			deleteModalOpen,
			requests,
			latestRequests,
			requestSummaryOpen
		} = this.state;

		return (
			<div className="pageContainer">
				{!loading && (
					<ViewsContainer
						views={programViews}
						entries={programs}
						columns={columns}
						columnTypes={columnTypes}
						title="Programs"
						subtitle={null}
						readOnly={false}
						mainActions={[{ type: 'button', onClick: this.toggleAdd, label: 'Add new program' }]}
						batchActions={[
							{
								label: 'Delete',
								iconClass: 'las la-trash-alt',
								class: 'danger',
								onClick: this.toggleDeletePrograms
							}
						]}
						columnWidths={{
							eventCount: 200,
							requestCount: 200
						}}
						enableDownloadViews={true}
						orgId={this.props.orgId}
						type={'Program'}
						referenceCollection={'Organizations'}
						referenceId={this.props.orgId}
						addedEntries={addedPrograms}
						deletedEntries={deletedPrograms}
						description="Setup program templates to streamline event creation. Allow users to submit event requests."
						viewPanelActions={[
							{
								iconClass: 'las la-clipboard-list',
								label: 'Requests',
								onClick: () => this.setState({ requestSummaryOpen: true })
							}
						]}
						summaryPanel={<button className='summaryPanel' onClick={() => this.setState({ requestSummaryOpen: true })}>
							<div className='flex aic jcsb mb-10 w-100'><h5 className='mr-25'><i className='las la-exclamation-circle mr-10 seBlue'></i>{latestRequests.length} Pending Requests</h5><i className='las la-angle-right'></i></div>
							<div className='flex column wrap jcfe'>
								{/* A short summary of upcoming events */}
								{latestRequests.length > 0 ? latestRequests.map((e, i) => {
									if (i < 2) {
										return (
											<div className='flex aic' style={{ marginBottom: i < 1 ? 5 : 0 }}>
												<h4 className='mr-5'>{e.name} from {e.programName} pending since</h4>
												{/* use moment to set time until */}
												<h3>
													{moment(e.submitDate).fromNow()}
												</h3>
											</div>);
									}
								}) : <p>No currently active requests</p>}

							</div>
						</button>}
					/>
				)}
				<LoadingWizard text="Loading Event Programs" loading={loading} />
				{addModalOpen && (
					<AddProgramModal
						isOpen={addModalOpen}
						toggle={() => this.setState({ addModalOpen: false })}
						handleSubmit={this.addProgram}
					/>
				)}
				{deleteModalOpen && (
					<DeleteProgramsModal
						isOpen={deleteModalOpen}
						toggle={this.toggleDeletePrograms}
						delete={this.deletePrograms}
						count={this.state.selectedPrograms.length}
					/>
				)}

				<FieldModal size="large" cancelButtonLabel="Close" toggle={() => this.setState({ requestSummaryOpen: !this.state.requestSummaryOpen })} isFullWidthBody={true} isOpen={requestSummaryOpen} bodyContent={() => <div className='pageContainer' style={{ height: "100%", padding: 0, margin: 0, width: '100%' }}><Requests orgId={this.props.orgId} requests={this.state.requests} customFields={this.state.customFields} views={this.state.requestViews} /></div>} />
			</div>
		);
	}
}

export default withRouter(Programs);
