import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import LoadingWizard from '../../../components/spinner/wizard';
import EditFeedItem from './modals/editFeedItem';
import DeleteGalleryModal from './modals/deleteGallery';
import { withRouter } from 'react-router-dom'

import AddGalleryModal from './modals/addGalleryModal';

class EventGalleries extends Component {
    constructor(props) {
        super(props);

        const columns = {
            feedName: 'Name',
            feedItemsCount: 'Shared Pictures',
            updatedAt: 'Last Updated'
        };
        const columnTypes = {
            feedName: 'text',
            feedItemsCount: 'number',
            updatedAt: 'date'
        };

        const toggledColumns = {};
        const normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });

        delete toggledColumns[Object.keys(columnTypes)[0]];


        this.state = {
            columns: columns,
            columnTypes: columnTypes,
            normalizedColumns: normalizedColumns,
            toggledColumns: toggledColumns,
            checkedItems: {},
            feedItems: [],
            isGridLayout: true,
            loading: true,
            toggleEditFile: false,
            markedItem: null,
            toggleAddGallery: false,
            searchTerm: ''
        };
    }

    async componentDidMount() {
        const { orgId, eventId, galleries } = this.props;
        this.props.setSelectedTab(2);
        this.setState({
            loading: false,
        });


    }

    sortData = (column, sortAsc, sortDesc) => {
        const { normalizedColumns } = this.state;
        const updatedColumns = normalizedColumns.map((col) => {
            if (col.key == column.key) {
                col.sortAsc = sortAsc;
                col.sortDesc = sortDesc;
            } else {
                col.sortAsc = false;
                col.sortDesc = false;
            }
            return col;
        });

        this.setState({
            normalizedColumns: updatedColumns,
            selectedColumnSort: column,
            sortDesc: sortDesc ? true : false
        });
    };

    toggleLayout = (selected) => {
        this.setState({ isGridLayout: selected });
    };
    updatedCheckedItems = (checked) => {
        this.setState({ checkedItem: checked });
    };

    toggleEditFile = (fileId) => {
        this.setState({
            toggleEditFile: !this.state.toggleEditFile,
            markedItem: fileId ? fileId : null
        });
    }

    toggleAddGallery = () => {
        this.setState({ toggleAddGallery: !this.state.toggleAddGallery });
    }


    toggleDeleteGallery = () => {
        this.setState({ toggleDeleteGallery: !this.state.toggleDeleteGallery });
    }

    removeGallery = (feedIds, callback) => {
        this.props.removeGallery(feedIds, () => {
            this.setState({ checkedItems: {}, markedItem: null }, () => {
                callback && callback();
            });
        });
    }

    toggleSingleDeleteGallery = (galleryId) => {
        this.setState({ markedItem: galleryId, toggleDeleteGallery: true });
    }


    render() {
        const {
            normalizedColumns,
            toggledColumns,
            loading,
            toggleEditFile,
            markedItem,
            toggleAddGallery,
            searchTerm,
            checkedItems,
            toggleDeleteGallery
        } = this.state;
        const { galleries } = this.props;
        const sortedGalleries = galleries;
        let searchedEntries = sortedGalleries;

        if (searchTerm.length > 0) {
            searchedEntries = galleries.filter((entry) => {
                return entry.feedName.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }


        return (

            <div className="sectionContainer">
                {!loading ?
                    <div className="sectionContainer posRel p-0">
                        {<ActionButtonContainer
                            mainActions={[
                                {
                                    type: 'button',
                                    label: 'Add Gallery',
                                    onClick: () => this.toggleAddGallery(),
                                }
                            ]}
                            searchEnabled={true}
                            searchEntries={(term) => {
                                this.setState({ searchTerm: term })
                            }}

                        />}
                        <div className='flex h-100 hasHeader'>
                            <ViewsTable
                                title={'Galleries'}
                                columns={normalizedColumns}
                                toggledColumns={toggledColumns}
                                data={searchedEntries}
                                mainColumnActive={true}
                                checked={this.state.checkedItems}
                                checkRow={(entryId) => {
                                    const checked = this.state.checkedItems;
                                    checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
                                    this.updatedCheckedItems(checked);

                                }}
                                checkAllRows={(entryIds) => {
                                    let checked = this.state.checkedItems;
                                    entryIds.length > 0
                                        ? entryIds.forEach((entryId) => {
                                            checked[entryId] = true;
                                        })
                                        : (checked = {});
                                    this.updatedCheckedItems(checked);


                                }}
                                sortData={this.sortData}
                                defaultSort={'feedName'}
                                sortDirection={'desc'}
                                rowSettings={[
                                    {
                                        label: 'Delete Gallery',
                                        onClick: this.toggleSingleDeleteGallery,
                                    },
                                ]}
                            />
                        </div>

                        <ActionButtonContainer
                            batchActions={
                                [
                                    {
                                        type: 'button',
                                        label: 'Delete Gallery',
                                        onClick: this.toggleDeleteGallery,
                                        iconClass: 'las la-trash-alt mr-10',
                                        class: 'neu danger'
                                    }
                                ]}
                            checkedEntryCount={Object.keys(checkedItems).length}
                            entriesLength={checkedItems.length}
                            disableNueFooter={true}
                            uncheckEntries={() => {
                                this.setState({ checkedItems: {} });
                            }}
                        />
                    </div> :
                    <LoadingWizard
                        text="Loading Feed Items"
                        loading={loading}

                    />}

                {toggleEditFile &&
                    <EditFeedItem
                        isOpen={toggleEditFile}
                        toggle={this.toggleEditFile}
                        eventId={this.props.eventId}
                        orgId={this.props.orgId}
                        markedItem={searchedEntries.find((entry) => entry._id == markedItem)}

                    />
                }

                {toggleAddGallery && (
                    <AddGalleryModal
                        isOpen={toggleAddGallery}
                        toggle={this.toggleAddGallery}
                        eventId={this.props.eventId}
                        orgId={this.props.orgId}
                        addNewGallery={this.props.addNewGallery}
                    />
                )}

                {toggleDeleteGallery && (
                    <DeleteGalleryModal
                        isOpen={toggleDeleteGallery}
                        toggle={this.toggleDeleteGallery}
                        eventId={this.props.eventId}
                        orgId={this.props.orgId}
                        markedGallery={markedItem}
                        galleries={galleries}
                        checkedItems={Object.keys(checkedItems)}
                        removeGallery={this.removeGallery}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(EventGalleries);
