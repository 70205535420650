import React, { Component, Fragment } from 'react';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import ToggleField from '../../components/inputs/toggleField';
import FieldModal from '../../components/modals/fieldModal';

const editingPermissionOptions = [{ label: 'Viewer', value: true }, { label: 'Editor', value: false }];

const defaultPermissions = {
	readOnly: false,
	capabilities: ['overview', 'details', 'attendees', 'schedule', 'groups', 'inventory', 'emails', 'texting', 'forms', 'pages', 'engagement', 'media'],
	canApprove: false,
	canCreateFrom: false,
	canUpdateDetails: false,
	canUpdateRequestForm: false,
	canEditCalendars: false,
	permissions: {
		overview: {
			write: true,
			read: true
		},
		details: {
			write: true,
			read: true
		},
		attendees: {
			write: true,
			read: true
		},
		schedule: {
			write: true,
			read: true
		},
		groups: {
			write: true,
			read: true
		},
		inventory: {
			write: true,
			read: true
		},
		emails: {
			write: true,
			read: true
		},
		texting: {
			write: true,
			read: true
		},
		forms: {
			write: true,
			read: true
		},
		pages: {
			write: true,
			read: true
		},
		engagement: {
			write: true,
			read: true
		},
		media: {
			write: true,
			read: true
		},
		insights: {
			write: true,
			read: true
		}
	}
};

class ProgramPermissions extends Component {
	constructor(props) {
		super(props);

		let upcomingEvents = [];
		let previousEvents = [];
		let allEvents = props.programs;
		this.state = {
			selectedFilter: 0,
			upcomingEvents: upcomingEvents,
			previousEvents: previousEvents,
			allEvents: allEvents,
			sortCriteria: 'Name',
			criteriaOpen: false,
			visibilityOpen: false,
			selectedEvent: null,
			manageNavigation: [
				{
					adminOnly: false,
					name: 'Details',
					icon: <i className="las la-pen-alt" />,
					section: 'details'
				},
				{
					adminOnly: false,
					name: 'Attendees',
					icon: <i className="las la-user-friends" />,
					section: 'attendees',
				},
				{
					adminOnly: true,
					name: 'Schedule',
					icon: <i className="las la-calendar-week" />,
					section: 'schedule'
				},
				{
					adminOnly: false,
					name: 'Groups',
					icon: <i className="las la-users" />,
					section: 'groups',
				},
				{
					adminOnly: false,
					name: 'Inventory',
					icon: <i className="las la-clipboard-list" />,
					section: 'inventory',
					addon: 'inventoryEnabled',
					experiences: ['in-person', 'hybrid']
				}
			],
			promoteNavigation: [
				{
					name: 'Emails',
					icon: <i className="las la-envelope" />,
					section: 'emails',
				},
				{
					adminOnly: false,
					name: 'Texting',
					icon: <i className="las la-sms" />,
					section: 'texting',
				}, {
					adminOnly: false,
					name: 'Forms',
					icon: <i className="lab la-wpforms" />,
					section: 'forms',
				}
			],
			produceNavigation: [
				{
					name: 'Event Pages',
					icon: <i className="las la-laptop-code" />,
					section: 'pages',
				},
				{
					adminOnly: false,
					name: 'Engagement',
					icon: <i className="las la-trophy" />,
					section: 'engagement',
					experiences: ['hybrid']
				}, {
					adminOnly: false,
					name: 'Media Files',
					icon: <i className="las la-photo-video" />,
					section: 'media',
				}, {
					adminOnly: false,
					name: 'Insights',
					icon: <i className="las la-chart-pie" />,
					section: 'insights',
				}
			],
		};
	}

	selectFilter = (filterIndex) => {
		this.setState({ selectedFilter: filterIndex });
	};

	FilterTab = (title, sectionNo, count) => {
		return (
			<div
				className={`filterTab ${sectionNo === this.state.selectedFilter ? 'selected' : ''} `}
				onClick={() => this.selectFilter(sectionNo)}
			>
				<div className="tabTitle">
					<h3>{title}</h3>
				</div>
				<span className="tabTitleCount">({count})</span>
			</div>
		);
	};

	toggleEventPermission = (programId) => {
		const ep = this.props.editedMember.programPermissions;
		const defaultPermissionsCopy = JSON.parse(JSON.stringify(defaultPermissions));
		if (!ep[programId]) {
			ep[programId] = {
				programId: programId,
				...defaultPermissionsCopy
			};
		} else {
			delete ep[programId];
		}
		this.props.editProgramPermission(ep, true);
	};

	setPermittedEventEditPermission(readOnly) {
		const programPermissions = this.props.editedMember.programPermissions;
		programPermissions[this.state.selectedEvent._id].readOnly = readOnly;

		this.props.editProgramPermission(programPermissions);
	}

	toggleSectionPermission = (section, prop) => {
		const permittedEvent = this.props.editedMember.programPermissions[this.state.selectedEvent._id];
		permittedEvent.permissions = { ...defaultPermissions.permissions, ...permittedEvent.permissions }
		permittedEvent.permissions[section][prop] = !permittedEvent.permissions[section][prop];
		const programPermissions = this.props.editedMember.programPermissions;
		programPermissions[this.state.selectedEvent._id] = permittedEvent;
		this.props.editProgramPermission(programPermissions);
	};

	toggleProgramPermission = (prop) => {
		const permittedEvent = { ...defaultPermissions, ...this.props.editedMember.programPermissions[this.state.selectedEvent._id] };
		permittedEvent[prop] = !permittedEvent[prop];
		const programPermissions = this.props.editedMember.programPermissions;
		programPermissions[this.state.selectedEvent._id] = permittedEvent;
		this.props.editProgramPermission(programPermissions);
	};

	dismissVisibilityModal = () => {
		this.setState({ visibilityOpen: false, selectedEvent: null });
	};

	EventItem(event, index) {
		const editedPermissions = this.props.editedMember.programPermissions;
		const isAdmin =
			this.props.editedMember.permission === 'admin' || this.props.editedMember.permission === 'owner';
		const permittedEvent = isAdmin ? { programId: event._id, ...defaultPermissions, ...editedPermissions[event._id] } : editedPermissions[event._id];
		const notPermitted = !permittedEvent;
		let permissionCount = 0;
		let readCount = 0;
		let writeCount = 0;
		permittedEvent && Object.keys(defaultPermissions.permissions).forEach((key) => {
			if (permittedEvent.permissions[key]) {
				if (permittedEvent.permissions[key].read) {
					readCount++;
				}
				if (permittedEvent.permissions[key].write) {
					writeCount++;
				}
				permissionCount++;
			}
		});
		let role = "";
		if (Object.keys(defaultPermissions.permissions).length === permissionCount && readCount == permissionCount && writeCount == permissionCount) {
			role = 'Organizer';
		} else if (Object.keys(defaultPermissions.permissions).length === permissionCount && writeCount <= permissionCount && writeCount > 0 && readCount >= writeCount) {
			role = 'Staff';
		} else if (Object.keys(defaultPermissions.permissions).length === permissionCount && readCount <= permissionCount && readCount > 0) {
			role = 'Guest';
		} else {
			role = 'No Access';
		}
		return (
			<div key={event._id} className="tableColumnRow" style={{ opacity: notPermitted ? '.5' : '1' }}>
				<div className="tableColumnRowCell text-truncate" style={{ minWidth: 250, maxWidth: 250, width: 250 }}>
					<div className=" flex-ac">
						<Toggle
							className='mr-10'
							checked={!notPermitted}
							icons={false}
							disabled={isAdmin}
							onChange={() => this.toggleEventPermission(event._id)}
						/>
						<div className="mh-a">{event.name}</div>
					</div>
				</div>
				<div className="tableColumnRowCell">
					{role}
				</div>
				<div className="tableColumnRowCell">
					{!notPermitted ? (
						`${readCount}/${Object.keys(defaultPermissions.permissions).length}`
					) : (
						'N/A'
					)}
				</div>
				<div className="tableColumnRowCell">
					{!notPermitted ? (
						`${writeCount}/${Object.keys(defaultPermissions.permissions).length}`
					) : (
						'N/A'
					)}
				</div>
				<div className="tableColumnRowCell">
					{permittedEvent && permittedEvent.canApprove ? (
						<p>Yes</p>
					) : (
						<p>No</p>
					)}
				</div>
				<div className="tableColumnRowCell">
					{permittedEvent && permittedEvent.canCreateFrom ? (
						<p>Yes</p>
					) : (
						<p>No</p>
					)}
				</div>
				<div className="tableColumnRowCell">
					{permittedEvent && permittedEvent.canUpdateDetails ? (
						<p>Yes</p>
					) : (
						<p>No</p>
					)}
				</div>
				<div className="tableColumnRowCell">
					{permittedEvent && permittedEvent.canUpdateRequestForm ? (
						<p>Yes</p>
					) : (
						<p>No</p>
					)}
				</div>
				<div className="tableColumnRowCell">
					{permittedEvent && permittedEvent.canEditCalendars ? (
						<p>Yes</p>
					) : (
						<p>No</p>
					)}
				</div>


				<div className="tableColumnRowCell ml-a">
					{isAdmin ? (
						<button className="min">
							<i className="las la-lock mr-10" />
							Full Permissions
						</button>
					) : (
						<button
							className="min "
							onClick={() => this.setState({ selectedEvent: event, visibilityOpen: true })}
							style={{ pointerEvents: notPermitted ? 'none' : 'all' }}
						>
							<i className="las la-user-lock mr-10" />Edit Permissions
						</button>
					)}
				</div>
			</div>
		);
	}
	setCriteria = (criteria) => {
		this.setState({ sortCriteria: criteria, criteriaOpen: false });
	};

	sortEvents = () => {
		let filteredEvents = this.props.programs;
		switch (this.state.sortCriteria) {
			case 'DateAsc':
				return filteredEvents.sort((a, b) => {
					return new Date(a.startDate) < new Date(b.startDate) ? -1 : 1;
				});
			case 'DateDesc':
				return filteredEvents.sort((a, b) => {
					return new Date(a.startDate) > new Date(b.startDate) ? -1 : 1;
				});
			case 'NameAsc':
				return filteredEvents.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
			case 'NameDesc':
				return filteredEvents
					.sort((a, b) => {
						return a.name.localeCompare(b.name);
					})
					.reverse();
			default:
				return filteredEvents;
		}
	};

	render() {
		const { sortCriteria, visibilityOpen, selectedEvent, manageNavigation, promoteNavigation, produceNavigation } = this.state;
		const { programPermissions } = this.props.editedMember;
		const sortedEvents = this.sortEvents();
		return (
			<div className="sectionContainer">
				<div className='headerHeight flex aic'>
					<div>
						<h2>Program Permissions</h2>
						<h4>Toggle programs for access and automatic addition to events created from this program. Edit permissions for sections to set a role based on read and write configuration in new events.</h4>
					</div>
				</div>
				<div className="sectionTable neuCard" style={{ width: 'calc(100% - 10px)', margin: '0 5px', marginBottom: 5 }}>
					<div className="tableSubColumns fit">
						<div className="tableColumnHeader" style={{ boxShadow: 'none' }}>
							<div className="tableSubColumn"
								style={{ minWidth: 250, maxWidth: 250, width: 250 }}
								onClick={() =>
									sortCriteria === 'NameAsc'
										? this.setCriteria('NameDesc')
										: this.setCriteria('NameAsc')}
							>
								Name{sortCriteria === 'NameAsc' && <i className="las la-angle-down" />}
								{sortCriteria === 'NameDesc' && <i className="las la-angle-up" />}
							</div>
							<div className="tableSubColumn">Default Event Role</div>
							<div className="tableSubColumn">Read Sections</div>
							<div className="tableSubColumn">Write Sections</div>
							<div className="tableSubColumn">Authorize</div>
							<div className="tableSubColumn">Create From</div>
							<div className="tableSubColumn">Update Details</div>
							<div className="tableSubColumn">Update Request Form</div>
							<div className="tableSubColumn">Edit Calendars</div>
							<div className="tableSubColumn" />
						</div>
						<div className='tableColumnRows'>
							{sortedEvents.map((event, index) => this.EventItem(event, index))}
						</div>
					</div>
				</div>
				{selectedEvent && <FieldModal
					size="large"
					isOpen={visibilityOpen}
					toggle={() => {
						this.props.cancelProgramPermissionEdit();
						this.dismissVisibilityModal();
					}}
					actionButton={() => {
						this.props.updateProgramPermissions();
						this.dismissVisibilityModal();
					}}
					modalTitle="Program Permissions"
					bodyHeaderText={selectedEvent.name}
					bodyDescription={`Manage ${this.props.editedMember.firstName}'s role within this program`}
					actionButtonLabel="Save"
					bodyContent={() => {
						const permissions = { ...defaultPermissions, ...programPermissions[selectedEvent._id] };
						let permissionCount = 0;
						let readCount = 0;
						let writeCount = 0;
						Object.keys(defaultPermissions.permissions).forEach((key) => {
							if (permissions.permissions[key]) {
								if (permissions.permissions[key].read) {
									readCount++;
								}
								if (permissions.permissions[key].write) {
									writeCount++;
								}
								permissionCount++;
							}
						});
						let accessString = '';
						let role = "";
						if (Object.keys(defaultPermissions.permissions).length === permissionCount && readCount == permissionCount && writeCount == permissionCount) {
							accessString = 'Full Access';
							role = 'Organizer';
						} else if (Object.keys(defaultPermissions.permissions).length === permissionCount && writeCount <= permissionCount && writeCount > 0 && readCount >= writeCount) {
							accessString = `Limited Access to ${writeCount} sections`;
							role = 'Staff';
						} else if (Object.keys(defaultPermissions.permissions).length === permissionCount && readCount <= permissionCount && readCount > 0) {
							accessString = `Read Only Access to ${readCount} sections`;
							role = 'Guest';
						} else {
							accessString = 'Will not be added';
							role = 'No Access';
						}

						return <Fragment>
							<ToggleField label="Can approve or deny event requests" icons={false}
								checked={permissions.canApprove}
								onChange={() => this.toggleProgramPermission('canApprove')}
							/>
							<ToggleField label="Can create events from this program" icons={false}
								checked={permissions.canCreateFrom}
								onChange={() => this.toggleProgramPermission('canCreateFrom')}
							/>
							<ToggleField label="Can update default event details" icons={false}
								checked={permissions.canUpdateDetails}
								onChange={() => this.toggleProgramPermission('canUpdateDetails')}
							/>
							<ToggleField label="Can update event request form" icons={false}
								checked={permissions.canUpdateRequestForm}
								onChange={() => this.toggleProgramPermission('canUpdateRequestForm')}
							/>
							<ToggleField label="Can add and edit calendars" icons={false}
								checked={permissions.canEditCalendars}
								onChange={() => this.toggleProgramPermission('canEditCalendars')}
							/>
							<h2 className='blockHeader mt-10'>Default Event Access</h2>
							<div className='flex aic mb-20'><h3 className='mr-10'>{role}:</h3><h4>{accessString}</h4></div>
							<div className='flex aic jcsb mb-10'>
								<h4>Overview</h4>
								<div className='flex aic'>

									<ToggleField
										className='mr-10 mb-0'
										label="Read"
										checked={
											permissions &&
											permissions.permissions.overview && permissions.permissions.overview.read
										}
										icons={false}
										onChange={() => this.toggleSectionPermission('overview', 'read')}
									/>
									<ToggleField
										className='mb-0'
										label="Write"
										checked={
											permissions &&
											permissions.permissions.overview && permissions.permissions.overview.write
										}
										icons={false}
										onChange={() => this.toggleSectionPermission('overview', 'write')}
									/>

								</div>
							</div>
							<h5 className='mb-10'>Manage</h5>
							{manageNavigation.map((menuItem) => {
								return (<div className='flex aic jcsb'>
									<h4>{menuItem.name}</h4>
									<div className='flex aic'>

										<ToggleField
											className='mr-10 mb-0'
											label="Read"
											checked={
												permissions &&
												permissions.permissions[menuItem.section] && permissions.permissions[menuItem.section].read
											}
											icons={false}
											onChange={() => this.toggleSectionPermission(menuItem.section, 'read')}
										/>
										<ToggleField
											className='mb-0'
											label="Write"
											checked={
												permissions &&
												permissions.permissions[menuItem.section] && permissions.permissions[menuItem.section].write
											}
											icons={false}
											onChange={() => this.toggleSectionPermission(menuItem.section, 'write')}
										/>

									</div>
								</div>
								);
							})}
							<h5 className='mb-10 mt-10'>Promote</h5>
							{promoteNavigation.map((menuItem) => {
								return (<div className='flex aic jcsb'>
									<h4>{menuItem.name}</h4>
									<div className='flex aic'>

										<ToggleField
											className='mr-10 mb-0'
											label="Read"
											checked={
												permissions &&
												permissions.permissions[menuItem.section] && permissions.permissions[menuItem.section].read
											}
											icons={false}
											onChange={() => this.toggleSectionPermission(menuItem.section, 'read')}
										/>
										<ToggleField
											className='mb-0'
											label="Write"
											checked={
												permissions &&
												permissions.permissions[menuItem.section] && permissions.permissions[menuItem.section].write
											}
											icons={false}
											onChange={() => this.toggleSectionPermission(menuItem.section, 'write')}
										/>

									</div>
								</div>
								);
							})}
							<h5 className='mb-10 mt-10'>Produce</h5>
							{produceNavigation.map((menuItem) => {
								return (<div className='flex aic jcsb'>
									<h4>{menuItem.name}</h4>
									<div className='flex aic'>

										<ToggleField
											className='mr-10 mb-0'
											label="Read"
											checked={
												permissions &&
												permissions.permissions[menuItem.section] && permissions.permissions[menuItem.section].read
											}
											icons={false}
											onChange={() => this.toggleSectionPermission(menuItem.section, 'read')}
										/>
										<ToggleField
											className='mb-0'
											label="Write"
											checked={
												permissions &&
												permissions.permissions[menuItem.section] && permissions.permissions[menuItem.section].write
											}
											icons={false}
											onChange={() => this.toggleSectionPermission(menuItem.section, 'write')}
										/>

									</div>
								</div>
								);
							})}
						</Fragment>
					}} />}
			</div>
		);
	}
}

export default ProgramPermissions;
