import React, { Fragment } from 'react';

import FieldModal from '../../modals/fieldModal';
import API from '../../../utils/API';

import InputField from '../../../components/inputs/inputField';
import LocationLookup from '../../inputs/locationLookup';

class AddVenueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            address: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                postalCode: '',
                country: ''
            },
            website: '',
            phoneNumber: '',
            description: '',
            submitting: false,
            complete: false,
            selectedVenue: null,
            requiredFields: {
                name: false,
            }
        };
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    submit = async () => {
        const { name,
            address,
            website,
            phoneNumber
            
        } = this.state;
        const { addNewVenue } = this.props;
        let valid = true;
        let requiredFields = this.state.requiredFields;
        if (!name) {
            requiredFields.name = true;
            valid = false;
        } else {
            requiredFields = false;
        }

        if (valid) {

            this.setState({ submitting: true })
            let venueObj = {
                name: name,
                address: address,
                website: website,
                phoneNumber: phoneNumber,
                description: '',
                venueType: 'Physical'
            };


            await API().post(`Organizations/${this.props.orgId}/venue`, venueObj).then((res) => {
                if (res.data) {
                    addNewVenue(res.data)
                    this.props.toggle()
                }
            });


        } else {

            this.setState({ requiredFields: requiredFields })
        }


    }

    render() {
        const { isOpen, toggle } = this.props;
        const { name, submitting, requiredFields, complete } = this.state;
        return (

            <div>


                <FieldModal
                    size="medium"
                    isOpen={isOpen}
                    modalTitle={"Add Venue"}
                    bodyHeaderText={''}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <LocationLookup selectedPlace={this.state.selectedVenue} locations={[]} label="Location Finder" placeholder="Search for the venue where the event will take place" noOptionsMessage="No results" onSelect={(location) => {
                                        this.setState({ 
                                            selectedVenue: location,
                                            name: location.name,
                                            address: {
                                                address1: location.address.address1,
                                                address2: location.address.address2,
                                                city: location.address.city,
                                                state: location.address.state,
                                                postalCode: location.address.zip,
                                                country: location.address.country
                                            }
                                         });
									}} classes="w-100" />
                                <InputField
                                    label="Venue Name"
                                    required={true}
                                    disabled={complete}
                                    errorTrigger={requiredFields.name}
                                    value={name}
                                    type="name"
                                    onChange={this.handleNameChange}
                                />
                                <InputField
                                    label="Address1"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.address.address1}
                                    type="address"
                                    onChange={(e) => {
                                        let address = this.state.address;
                                        address.address1 = e.target.value;
                                        this.setState({ address: address });
                                    }}
                                />
                                <InputField
                                    label="Address2"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.address.address2}
                                    type="address"
                                    onChange={(e) => {
                                        let address = this.state.address;
                                        address.address2 = e.target.value;
                                        this.setState({ address: address });
                                    }}
                                />
                                <InputField
                                    label="City"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.address.city}
                                    type="address"
                                    onChange={(e) => {
                                        let address = this.state.address;
                                        address.city = e.target.value;
                                        this.setState({ address: address });
                                    }}
                                />
                                <div className='flex'>
                                <InputField
                                    label="State"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.address.state}
                                    type="address"
                                    onChange={(e) => {
                                        let address = this.state.address;
                                        address.state = e.target.value;
                                        this.setState({ address: address });
                                    }}
                                    classes='mr-10'
                                />
                                <InputField
                                    label="Postal Code"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.address.postalCode}
                                    type="address"
                                    onChange={(e) => {
                                        let address = this.state.address;
                                        address.postalCode = e.target.value;
                                        this.setState({ address: address });
                                    }}
                                    classes='ml-10'
                                />

                                </div>
                                <InputField
                                    label="Country"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.address.country}
                                    type="address"
                                    onChange={(e) => {
                                        let address = this.state.address;
                                        address.country = e.target.value;
                                        this.setState({ address: address });
                                    }}
                                />
                                <InputField
                                    label="Website"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.website}
                                    type="website"
                                    onChange={(e) => {
                                        this.setState({ website: e.target.value });
                                    }}
                                />
                                <InputField
                                    label="Phone Number"
                                    required={false}
                                    disabled={complete}
                                    value={this.state.phoneNumber}
                                    type="phone"
                                    onChange={(e) => {
                                        this.setState({ phoneNumber: e.target.value });
                                    }}
                                />

                            </div>)
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting && !complete ? 'Submit' : submitting && !complete ? 'Adding...' : complete ? 'Complete' : ''}
                    actionButton={() => this.submit()}
                    actionButtonDisabled={submitting || complete}
                    cancelButtonLabel={complete ? 'Close' : 'Cancel'}


                ></FieldModal>
            </div>

        );
    }
}

export default AddVenueModal;
