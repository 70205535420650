// import external modules
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { setCookie, removeCookie } from '../../utils/cookie';
import API from '../../utils/API';
import { LogIn, Edit, Edit2, Send, Mail, Phone, AlertTriangle, CheckCircle, X } from 'react-feather';
import { Row, Col, Input, Form, FormGroup, Button, Label, Card, CardBody, CardFooter } from 'reactstrap';

import logo from '../../assets/img/logos/simple events logo.png';

import InputField from '../../components/inputs/inputField';

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		const tokenIndex = window.location.href.indexOf('key=') + 4;
		let userToken = tokenIndex > -1 && window.location.href.substr(tokenIndex);
		if (!userToken) {
			window.location.replace('/');
		}
		this.state = {
			userToken: userToken,
			password: '',
			confirmPassword: '',
			error: '',
			disableSubmit: true,
			errorTrigger: false,
			formatErrorTrigger: false,
			matchErrorTrigger: false,
			isConfirmed: false
		};
	}

	submit = (e) => {
		const { password, confirmPassword, userToken } = this.state;
		const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

		if (!passwordRegEx.test(password)) {
			this.setState({ formatErrorTrigger: true });
			return;
		}
		this.setState({ formatErrorTrigger: false });

		if (password !== confirmPassword) {
			this.setState({ matchErrorTrigger: true });
			return;
		}
		this.setState({ matchErrorTrigger: false });

		e.preventDefault();
		e.persist();

		const data = {
			userToken: userToken,
			newPassword: password
		};
		API()
			.post(`/auth/resetPassword`, data)
			.then((res) => {
				this.setState({ isConfirmed: true });
			})
			.catch((err) => {
				this.setState({ error: err.response.data });
			});
	};

	render() {
		const {
			password,
			confirmPassword,
			error,
			disableSubmit,
			errorTrigger,
			formatErrorTrigger,
			matchErrorTrigger,
			isConfirmed
		} = this.state;
		return (
			<div className='w-100 h-100 flex column'>

				<div className='systemToolbar w-100' >
					<div className="flex h-100 ml-30" style={{ alignItems: 'end' }}>

						<div className="navLogo c-pointer" style={{ marginRight: 113 }} onClick={() => window.location.href = "/"}>
							<img src={logo} />
						</div>
					</div>
				</div>
				<div className='w-fc p-0 neuCard  h-100' style={{ background: 'white', border: 'none', width: 'calc(100% - 15px)', marginLeft: 15 }} >

					<div className="w-100 m-a mt-20 p-50" style={{ maxWidth: 560 }}>
						<div className='flex aic'>
							{!isConfirmed ? <div className='w-100 p-25'>
								<div className='text-c mb-40'>
									<h1 className='mb-10'>Create your password</h1>
									<p>
										Your password must be at least 8 characters long and contain at least <br />1 lowercase letter, 1 uppercase letter, and a number.
									</p>
								</div>
								<InputField
									label={'New Password'}
									type={'password'}
									onChange={(e) => {
										const val = e.currentTarget.value;
										val == '' || confirmPassword == ''
											? this.setState({ disableSubmit: true })
											: this.setState({ disableSubmit: false });
										this.setState({ password: val });
									}}
									value={this.state.password}
									errorTrigger={formatErrorTrigger}
									errorMessage={'Invalid format'}
								/>

								<InputField
									label={'Confirm New Password'}
									type={'password'}
									onChange={(e) => {
										const val = e.currentTarget.value;
										val == '' || password == ''
											? this.setState({ disableSubmit: true })
											: this.setState({ disableSubmit: false });
										this.setState({ confirmPassword: val });
									}}
									value={this.state.confirmPassword}
									errorTrigger={matchErrorTrigger}
									errorMessage={'Does not match'}
								/>

								<button
									onClick={this.submit}
									className={`prim ${disableSubmit ? 'inactive' : ''} w-100 mt-50`}
									disabled={disableSubmit}
								>
									Set new password
								</button>
							</div> : <div className='w-100 mw-400 p-25'>
								<div className='text-c mb-40'>
									<h1 className='mb-10'>Password Has Been Reset</h1>
									<p>Success! Your password has been set. Please head to the login page to access
										your account.</p>
								</div>
								<div className='w-100 flex jcc'>
									<Link to={'/'}>
										Back to login
									</Link>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
