import React, { Component, Fragment } from 'react';

import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
import AddPageModal from './modals/addAccountPageModal';
import DeletePagesModal from './modals/deleteAccountPagesModal';
import CopyAccountPageModal from './modals/copyAccountPageModal';
import { Route, Switch } from 'react-router-dom';
import TabsContainer from '../../components/views/TabsContainer';
import ActionButtonContainer from '../../components/views/ActionButtonContainer';
import ViewsTable from '../../components/tables/ViewsTable';
import SectionHeader from '../../components/views/SectionHeader';
import PageGrid from '../../components/tables/PageGrid';

let batchCallback;

class Pages extends Component {
	constructor(props) {
		super(props);
		/* const FieldColumns = {
			title: 'Title',
			description: 'Description',
			status: 'Status',
			openToPublic: 'Public',
			url: 'Link'
		};

		const FieldTypes = {
			title: 'text',
			description: 'text',
			status: 'text',
			openToPublic: 'bool',
			url: 'link'
		}; */
		const customFieldColumns = {
			title: 'Title',
			slug: 'Slug',
			description: 'Description',
			isSessionPage: 'Is Session Page',
			openToPublic: 'Is Open to Public',
			published: 'Auto Publish'
		};
		const defaultFieldTypes = {
			title: 'text',
			slug: 'text',
			description: 'text',
			isSessionPage: 'boolean',
			openToPublic: 'boolean',
			published: 'boolean'
		};

		/* New Update */
		const toggledColumns = {}; // Added 
		const normalizedColumns = Object.keys(customFieldColumns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: customFieldColumns[cfc],
				key: cfc,
				type: typeof customFieldColumns[cfc]
			};
		});
		/* End */
		delete toggledColumns[Object.keys(defaultFieldTypes)[0]];
		this.state = {
			pages: [],
			pageViews: [],
			loading: true,
			//columns: fieldColumns,
			//columnTypes: fieldTypes,
			deletePagesModalOpen: false,
			selectedPages: [],
			copyPagesModalOpen: false,
			checkedPages: {},
			/* New States */
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			selectedSection: 0,
			toggledColumns: toggledColumns,
			normalizedColumns: normalizedColumns,
			searchText: '',
			selectedRow: '',
			toggleRenamePage: false,
			isGridLayout: false
		};
	}
	componentDidMount() {
		this.setState({ loading: true });
		API()
			.get(`Organizations/${this.props.orgId}/accountPages`)
			.then((res) => {
				if (res.data) {
					this.setState({
						pages: res.data.pages,
						pageViews: res.data.views || [],
						loading: false
					});
				} else {
					this.setState({
						loading: false
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	}

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};

	addPage = () => {
		this.componentDidMount();
	};

	deletePages = async () => {
		await API()
			.post(`Organizations/${this.props.orgId}/accountPages/delete`, {
				pageIds: Object.keys(this.state.checkedPages)
			})
			.then((res) => {
				if (res.data) {
					const filteredPages = this.state.pages.filter(
						(p) => !Object.keys(this.state.checkedPages).includes(p._id)
					);
					this.setState({ pages: filteredPages, checkedPages: [], deletePagesModalOpen: false });

					batchCallback && batchCallback('delete');
				} else {
					return false;
				}
			});
	};

	toggleDelete = () => {
		this.setState({
			deletePagesModalOpen: !this.state.deletePagesModalOpen
		});
	};

	toggleCopyPages = () => {
		this.setState({
			copyPagesModalOpen: !this.state.copyPagesModalOpen
		});
	};

	/* New Functions */
	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};
	uncheckEntries = () => {
		this.setState({
			checkedPages: {}
		});
	}
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		let nc = normalizedColumns;
		nc = nc.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: nc,
			selectedColumnSort: column,
			sortDesc: sortDesc == false ? true : false
		});
	};

	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};
	updateSinglePage = (pUpdate, callback) => {

		let tempPages = this.props.pages;
		tempPages.map(object => {

			if (this.state.selectedRow == object._id) {
				Object.assign(object, pUpdate);
			}
			return object;
		})
		this.setState({ pages: tempPages }, () => {

			callback && callback();
		})
	}
	updateAddCopy = (newPage, callback) => {
		const { eventId, orgId } = this.props;
		const { pages, page } = this.state;
		let existingArray = this.state.pages;
		//pages.link = `/${this.props.orgId}/events/${eventId}/pages/${page.id}/0`;
		newPage.link = `/${orgId}/events/${eventId}/pages/${newPage._id}`;
		existingArray.push(newPage);
		this.setState({ pages: existingArray }, () => {
			this.props.refreshSectionCounts()
			callback && callback();
		})
	}
	checkEntry = (entryId) => {
		const checked = this.state.checkedPages;

		checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
		this.setState({
			checkedPages: checked
		});

	};
	toggleLayout = (selected) => {
		this.setState({ isGridLayout: selected });
	};
	/* End New Functions */
	render() {
		const {
			loading,
			pageViews,
			columns,
			pages,
			columnTypes,
			addModalOpen,
			copyPagesModalOpen,
			deletePagesModalOpen,
			checkedPages,
			/* New */
			selectedSection,
			toggledColumns,
			normalizedColumns,
			selectedColumnSort,
			sortDesc,
			searchText,
			toggleRenamePage,
			isGridLayout
			/* End new  */
		} = this.state;
		const { orgId } = this.props;
		/* New  */
		let mainAction = [{ type: 'button', onClick: this.toggleAdd, label: 'Add Page' },];
		let batchActions = [
			{
				label: 'Duplicate Page',
				iconClass: 'las la-copy',
				pluralLabel: 'Duplicate Pages',
				onClick: this.toggleCopyPages
			},
			{
				label: 'Delete',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleDelete
			}
		];
		let searchEnabled = true;
		let checkedEntryCount = Object.keys(this.state.checkedPages).length;
		let data = this.state.pages;
		let entriesLength = data.length;
		let checkedRows = this.state.checkedPages;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		switch (selectedSection) {
			case 0:
				break;
			case 1:
				data = data.filter((p) => p.status === 'published');
				break;
			case 2:
				data = data.filter((p) => p.status === 'draft');
				break;
			default:
				break;
		}
		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}
		/* New End */
		return (
			<Fragment>
				<Switch>
					<Route>
						<Fragment>
							<div className="pageContainer">
								<SectionHeader
									title="Account Pages"
									description="Create and manage your account website. Build pages for upcoming events, event requests, and more. "
									style={{ paddingBottom: 0 }}
									enableLastLink={true}
								>
								</SectionHeader>
								<TabsContainer
									tabs={[{ title: 'All Pages' }, { title: 'Published' }, { title: 'Drafts' }]}
									updateSelected={this.updateSelected}
									selectedIndex={selectedSection}
								/>
								{true &&
									<div className="sectionContainer sectionBody">
										<ActionButtonContainer
											mainActions={mainAction}
											searchEnabled={searchEnabled}
											searchEntries={this.searchEntries}
											enabledViewSelection={true}
											isGridLayout={isGridLayout}
											toggleLayout={this.toggleLayout}
										/>
										{!isGridLayout ? (<div className='flex h-100 hasHeader'>
											<ViewsTable
												columns={normalizedColumns}
												toggledColumns={toggledColumns}
												data={data}
												mainColumnActive={true}
												checked={checkedRows}
												checkRow={this.checkEntry}
												checkAllRows={this.checkAllEntries}
												sortData={this.sortData}
												defaultSort={this.props.defaultSort}
												sortDirection={this.props.sortDirection}
											/>
										</div>): (
											<PageGrid
												title={'Account Pages'}
												orgId={orgId}
												updateFavorite={this.updateFavorite}
												data={data}
												account={this.props.account}
												checkRow={this.checkEntry}
												checked={checkedRows}
												/>)}
										<ActionButtonContainer
											batchActions={batchActions}
											checkedEntryCount={checkedEntryCount}
											entriesLength={entriesLength}
											uncheckEntries={this.uncheckEntries}
										/>
									</div>
								}
								<LoadingWizard text="Loading Account Pages" loading={loading} />
								{copyPagesModalOpen && (
									<CopyAccountPageModal
										orgId={orgId}
										pages={pages}
										checkedPages={checkedPages}
										isOpen={copyPagesModalOpen}
										toggle={() => this.setState({ copyPagesModalOpen: !this.state.copyPagesModalOpen })}
										addPage={(page) => {
											const ps = this.state.pages;
											page.link = `/${orgId}/pages/${page._id}/0`;
											ps.push(page);
											this.setState({ pages: ps, copyPagesModalOpen: !copyPagesModalOpen }, () => {
												batchCallback && batchCallback('update');
											});
										}}
									/>
								)}

								{addModalOpen && (
									<AddPageModal
										isOpen={addModalOpen}
										toggle={() => this.toggleAdd()}
										orgId={this.props.orgId}
										addPage={this.addPage}
									/>
								)}
								{deletePagesModalOpen && (
									<DeletePagesModal
										isOpen={deletePagesModalOpen}
										toggle={this.toggleDelete}
										orgId={this.props.orgId}
										delete={this.deletePages}
										count={Object.keys(this.state.checkedPages).length}
									/>
								)}
							</div>

						</Fragment>
					</Route>
				</Switch>
			</Fragment>

		);
	}
}

export default Pages;
