import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import 'react-toggle/style.css';

class DraggableCustomField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false
		};
	}

	updateField = () => {
		this.setState({ field: this.props.field });
	};

	toggleExpand = () => {
		this.setState({ expanded: !this.state.expanded });
	};
	render() {
		const { expanded } = this.state;
		const { index, field } = this.props;
		const unattached = field._id.includes('unattached');
		return (
			<div key={field._id}>
				<div
					className="categoryHeader flex-sb c-pointer"
					onClick={() => this.setState({ expanded: !expanded })}
				>
					<h3 className="mr-10"> {field.title}</h3>
					{expanded ? <i className="las la-angle-up" /> : <i className="las la-angle-down" />}
				</div>
				{expanded && (
					<div className="fieldListContainer">
						{field.fields.map((f) => (
							<Draggable key={`f${f._id}`} draggableId={`${f._id}`} index={f}>
								{(provided, snapshot) => (
									<div
										className="fieldDraggable"
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										<i className="las la-braille mr-5" />
										<div>
											<p className='mb-0'>{f.fieldName}</p>
											{!unattached && <p className='mb-0 small'>{f.fieldType}</p>}
										</div>
										<span className="ml-a">{f.icon}</span>
									</div>
								)}
							</Draggable>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default DraggableCustomField;
