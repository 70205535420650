import React, { Fragment } from 'react';

import API from '../../../../utils/API';


import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import SelectField from '../../../../components/inputs/selectField';
import ToggleField from '../../../../components/inputs/toggleField';
import { withRouter } from 'react-router-dom';
const defaultPermissions = {
    readOnly: false,
    capabilities: ['overview', 'details', 'attendees', 'schedule', 'groups', 'inventory', 'emails', 'texting', 'forms', 'pages', 'engagement', 'media'],
    permissions: {
        /* overview: {
            write: true,
            read: true
        }, */
        details: {
            write: true,
            read: true
        },
        attendees: {
            write: true,
            read: true
        },
        schedule: {
            write: true,
            read: true
        },
        groups: {
            write: true,
            read: true
        },
        inventory: {
            write: true,
            read: true
        },
        emails: {
            write: true,
            read: true
        },
        texting: {
            write: true,
            read: true
        },
        forms: {
            write: true,
            read: true
        },
        pages: {
            write: true,
            read: true
        },
        engagement: {
            write: true,
            read: true
        },
        media: {
            write: true,
            read: true
        },
        insights: {
            write: true,
            read: true
        },
        reports: {
            write: true,
            read: true
        },
        notifications: {
            write: true,
            read: true
        }
    }
};
class AddTeamMemberModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            requiredFields: { name: false },
            users: [],
            selectedUser: '',
            mappedTeam: [],
            editedTeam: [],
            manageNavigation: [
                {
                    adminOnly: false,
                    name: 'Details',
                    icon: <i className="las la-pen-alt" />,
                    section: 'details'
                },
                {
                    adminOnly: false,
                    name: 'Attendees',
                    icon: <i className="las la-user-friends" />,
                    section: 'attendees',
                },
                {
                    adminOnly: false,
                    name: 'Schedule',
                    icon: <i className="las la-calendar-week" />,
                    section: 'schedule'
                },
                {
                    adminOnly: false,
                    name: 'Groups',
                    icon: <i className="las la-users" />,
                    section: 'groups',
                },
                {
                    adminOnly: false,
                    name: 'Inventory',
                    icon: <i className="las la-clipboard-list" />,
                    section: 'inventory',
                    addon: 'inventoryEnabled',
                    experiences: ['in-person', 'hybrid']
                },
                {
                    name: 'Reports',
                    icon: <i className="las la-chart-pie" />,
                    section: 'reports',
                    adminOnly: false
                }
            ],
            promoteNavigation: [
                {
                    name: 'Emails',
                    icon: <i className="las la-envelope" />,
                    section: 'emails',
                },
                {
                    adminOnly: false,
                    name: 'Texting',
                    icon: <i className="las la-sms" />,
                    section: 'texting',
                }, {
                    adminOnly: false,
                    name: 'Notifications',
                    icon: <i className="las la-bell" />,
                    section: 'notifications'
                }
            ],
            produceNavigation: [
                {
                    name: 'Event Pages',
                    icon: <i className="las la-laptop-code" />,
                    section: 'pages',
                }, {
                    adminOnly: false,
                    name: 'Forms',
                    icon: <i className="lab la-wpforms" />,
                    section: 'forms',
                },
                {
                    adminOnly: false,
                    name: 'Engagement',
                    icon: <i className="las la-trophy" />,
                    section: 'engagement',
                    experiences: ['hybrid']
                }, {
                    adminOnly: false,
                    name: 'Media Files',
                    icon: <i className="las la-photo-video" />,
                    section: 'media',
                }, {
                    adminOnly: false,
                    name: 'Insights',
                    icon: <i className="las la-chart-pie" />,
                    section: 'insights',
                }
            ],
            selectedUserPermissions: null
        };
    }

    componentDidMount() {
        let fixedUsers = [];
        let fixedAdminUsers = [];
        const { orgId, users, eventTeam } = this.props;
        let team = eventTeam || [];
        team = JSON.parse(JSON.stringify(team));
        users.map((user) => {
            let firstName = user.firstName ? user.firstName : '';
            let lastName = user.lastName ? user.lastName : '';
            let name = firstName + ' ' + lastName;
            if (!team.includes(user._id)) {
                if (user.permission !== 'admin') {
                    fixedUsers.push({ label: name, value: user._id })
                } else {

                    fixedAdminUsers.push({
                        _id: user._id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        emailAddress: user.email,
                        permission: user.permission,
                        profilePicture: user.profilePicture || '',
                    })
                }

            }
        })



        this.setState({ adminUsers: fixedAdminUsers, users: fixedUsers, editedTeam: team, team: team })
    }

    handleUserChange = (e) => {
        const { users, eventId } = this.props;
        const { editedTeam } = this.state;

        let selectedUser = users.find((user) => user._id === e.value);
        let newTeamMember = {
            _id: selectedUser._id,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            emailAddress: selectedUser.email,
            permission: selectedUser.permission,
            eventPermissions: {}
        };
        let permissions = defaultPermissions;
        permissions.eventId = eventId;
        newTeamMember.eventPermissions[eventId] = permissions;


        this.setState({ editedTeam: [...editedTeam, newTeamMember] }, () => { });
    };

    submit = async () => {
        const { editedTeam } = this.state;
        const { orgId, eventId } = this.props;

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/team`, {
                members: editedTeam,
            })
            .then((res) => {
                this.props.updateTeam(editedTeam, () => {
                    this.props.toggle();

                });

            })
            .catch((e) => {
                console.log(e);
            });
    }

    removeTeamMember = (memberId) => {
        const { editedTeam } = this.state;
        let newEditedTeam = editedTeam.filter((member) => member._id !== memberId);
        this.setState({ editedTeam: newEditedTeam })
    }

    render() {
        const { isOpen, toggle, eventId } = this.props;
        const { users, editedTeam, manageNavigation, promoteNavigation, produceNavigation, selectedUserPermissions, adminUsers } = this.state;

        let filteredUsers = users.filter((user) => !editedTeam.some(et => et._id === user.value));
        return (
            <div>

                <FieldModal
                    size="medium"
                    isOpen={isOpen}
                    modalTitle={"Team"}
                    bodyHeaderText={`All admins can see your event plus all organizers below. Add and mange organizers.`}
                    bodyContent={() => {
                        return (
                            <div style={{
                                maxHeight: 'calc(100vh - 610px)',
                                overflow: 'hidden'
                            }}>
                                <SelectField
                                    style={{ padding: '0px 2px' }}
                                    label="Available Organizers"
                                    options={filteredUsers}
                                    onChange={this.handleUserChange}
                                    value={''}
                                    placeholder="Enter Name"
                                />
                                <div className="pt-5 h-100"
                                    style={{ maxHeight: 'calc(100vh - 700px)', overflow: 'auto' }}
                                >
                                    <div className='flex pb-20'
                                        style={{ justifyContent: 'space-between' }}
                                    >

                                        <div style={{ width: '50%' }}>
                                            <h3>Admin</h3>
                                        </div>
                                        <div style={{ width: '50%' }}>

                                            <h3>Permissions</h3>
                                        </div>


                                    </div>
                                    {adminUsers && adminUsers.length > 0 && adminUsers.map((member, memberIndex) => {
                                        let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
                                        return (
                                            <div className="flex aic pb-20"
                                                style={{ justifyContent: 'space-between' }}
                                            >

                                                <div className='flex aic'
                                                    style={{ width: '50%' }}
                                                >
                                                    <div className="acronymCircle mr-5"
                                                    >
                                                        <p>
                                                            {emptyProfilePicture ? `${member.firstName.toUpperCase()[0]}${member.lastName.toUpperCase()[0]}` :
                                                                <img src={member.profilePicture} className="acronymCircle profilePicture" />
                                                            }
                                                        </p>
                                                    </div>
                                                    <span className=''>{member.firstName} {member.lastName}</span>
                                                </div>
                                                <div
                                                    className='flex aic '
                                                    style={{ width: '50%', justifyContent: 'space-between' }}>

                                                    <div>
                                                        Full Access
                                                    </div>


                                                    {/* <div
                                                        className="ml-1 grey c-pointer mr-15"
                                                        onClick={() => this.removeTeamMember(member._id)}
                                                    >
                                                        <i className="las la-times-circle"></i>

                                                    </div> */}
                                                </div>

                                            </div>
                                        )
                                    })}




                                    <div className='flex pb-20'
                                        style={{ justifyContent: 'space-between' }}
                                    >

                                        <div style={{ width: '50%' }}>
                                            <h3>Organizers</h3>
                                        </div>
                                        <div style={{ width: '50%' }}>

                                            <h3>Permissions</h3>
                                        </div>


                                    </div>

                                    {editedTeam.map((member, memberIndex) => {
                                        //capitalize first letter of permission
                                        let permission = member.permission;
                                        permission = permission.charAt(0).toUpperCase() + permission.slice(1);

                                        let permissionCount = 0;
                                        let readCount = 0;
                                        let writeCount = 0;
                                        Object.keys(defaultPermissions.permissions).forEach((key) => {
                                            if (member.eventPermissions[eventId].permissions[key].read) {
                                                readCount++;
                                            }
                                            if (member.eventPermissions[eventId].permissions[key].write) {
                                                writeCount++;
                                            }
                                            permissionCount++;
                                        });
                                        let accessString = '';
                                        let role = "";
                                        if (Object.keys(defaultPermissions.permissions).length === permissionCount && readCount == permissionCount && writeCount == permissionCount) {
                                            accessString = 'Full Access';
                                            role = 'Organizer';
                                        } else if (Object.keys(defaultPermissions.permissions).length === permissionCount && writeCount <= permissionCount && writeCount > 0 && readCount >= writeCount) {
                                            accessString = `Staff`;
                                            role = 'Staff';
                                        } else if (Object.keys(defaultPermissions.permissions).length === permissionCount && readCount <= permissionCount && readCount > 0) {
                                            accessString = `Guest`;
                                            role = 'Guest';
                                        } else {
                                            accessString = 'No Access';
                                            role = 'No Access';
                                        }
                                        let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
                                        return (
                                            <div className="flex "
                                                style={{ flexDirection: 'column', paddingBottom: '20px', }}
                                            >

                                                <div className="flex aic"
                                                    style={{ justifyContent: 'space-between' }}
                                                >

                                                    <div className={`flex aic ${selectedUserPermissions === member._id ? 'activeColor' : ''}`}
                                                        style={{ width: '50%' }}
                                                    >
                                                        <div className={`acronymCircle mr-5 ${selectedUserPermissions === member._id ? 'activeColor' : ''}`}
                                                        >
                                                            <p>
                                                                {emptyProfilePicture ? `${member.firstName.toUpperCase()[0]}${member.lastName.toUpperCase()[0]}`
                                                                    :
                                                                    <img src={member.profilePicture} className="acronymCircle profilePicture" />
                                                                }
                                                            </p>
                                                        </div>
                                                        <span className=''>{member.firstName} {member.lastName}</span>
                                                    </div>
                                                    <div
                                                        className={`flex aic c-pointer hoverText ${selectedUserPermissions === member._id ? 'activeColor' : ''}`}
                                                        style={{ width: '50%', justifyContent: 'space-between' }}>

                                                        <div
                                                            onClick={() => {
                                                                this.setState({ selectedUserPermissions: this.state.selectedUserPermissions == member._id ? null : member._id })
                                                            }
                                                            }>
                                                            {accessString} <span className='pl-15'>{selectedUserPermissions === member._id ? <i className="las la-angle-up"></i> : <i className="las la-angle-down"></i>}</span>
                                                        </div>


                                                        <div
                                                            className="ml-1 grey c-pointer mr-15"
                                                            onClick={() => this.removeTeamMember(member._id)}
                                                        >
                                                            <i className="las la-times-circle"></i>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className={`pt-10 ${selectedUserPermissions === member._id ? '' : 'hiddenBlock'}`}
                                                    style={{ padding: '0px 20px' }}
                                                >

                                                    <div >
                                                        <div style={{

                                                            borderBottom: '1px solid var(--border)',
                                                            padding: '10px 0 '
                                                        }}>
                                                            <h3>Plan</h3>
                                                        </div>


                                                        {manageNavigation.map((sec) => {
                                                            let adminOnly = sec.adminOnly;
                                                            let name = sec.name;
                                                            let icon = sec.icon;
                                                            let section = sec.section;

                                                            return (

                                                                <div className='flex aic'
                                                                    style={{ justifyContent: 'space-between', borderBottom: '1px solid var(--border)', padding: '5px 0 ' }}
                                                                >
                                                                    <div style={{ width: '50%%' }}> {name}</div>


                                                                    <div className='flex aic '
                                                                        style={{ justifyContent: 'space-between', width: '50%%', gap: '50px' }}
                                                                    >

                                                                        <ToggleField
                                                                            style={{ marginBottom: '0px' }}
                                                                            label="View"
                                                                            checked={member.eventPermissions[eventId].permissions[section].read}
                                                                            icons={false}
                                                                            onChange={(e) => {
                                                                                let editedMember = JSON.parse(JSON.stringify(member));

                                                                                let eventPermissions = editedMember.eventPermissions[eventId];
                                                                                let permissions = eventPermissions.permissions;
                                                                                permissions[section].read = e.target.checked;
                                                                                if (e.target.checked === false) {
                                                                                    permissions[section].write = false;

                                                                                }
                                                                                let newEditedTeam = editedTeam;
                                                                                newEditedTeam[memberIndex] = editedMember;

                                                                                this.setState({ editedTeam: newEditedTeam })
                                                                            }}
                                                                        />
                                                                        <ToggleField
                                                                            style={{ marginBottom: '0px' }}

                                                                            label="Edit"
                                                                            checked={member.eventPermissions[eventId].permissions[section].write}
                                                                            icons={false}
                                                                            onChange={(e) => {
                                                                                let editedMember = JSON.parse(JSON.stringify(member));

                                                                                let eventPermissions = editedMember.eventPermissions[eventId];
                                                                                let permissions = eventPermissions.permissions;
                                                                                permissions[section].write = e.target.checked;
                                                                                if (e.target.checked === true) {
                                                                                    permissions[section].read = true;
                                                                                }
                                                                                let newEditedTeam = editedTeam;
                                                                                newEditedTeam[memberIndex] = editedMember;

                                                                                this.setState({ editedTeam: newEditedTeam })
                                                                            }}
                                                                        />

                                                                    </div>


                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                    <div >
                                                        <div style={{

                                                            borderBottom: '1px solid var(--border)',
                                                            padding: '10px 0 '
                                                        }}>
                                                            <h3>Promote</h3>
                                                        </div>


                                                        {promoteNavigation.map((sec) => {
                                                            let adminOnly = sec.adminOnly;
                                                            let name = sec.name;
                                                            let icon = sec.icon;
                                                            let section = sec.section;

                                                            return (

                                                                <div className='flex aic'
                                                                    style={{ justifyContent: 'space-between', borderBottom: '1px solid var(--border)', padding: '5px 0 ' }}
                                                                >
                                                                    <div style={{ width: '50%%' }}> {name}</div>


                                                                    <div className='flex aic '
                                                                        style={{ justifyContent: 'space-between', width: '50%%', gap: '50px' }}
                                                                    >

                                                                        <ToggleField
                                                                            style={{ marginBottom: '0px' }}
                                                                            label="View"
                                                                            checked={member.eventPermissions[eventId].permissions[section].read}
                                                                            icons={false}
                                                                            onChange={(e) => {
                                                                                let editedMember = JSON.parse(JSON.stringify(member));

                                                                                let eventPermissions = editedMember.eventPermissions[eventId];
                                                                                let permissions = eventPermissions.permissions;
                                                                                permissions[section].read = e.target.checked;
                                                                                if (e.target.checked === false) {
                                                                                    permissions[section].write = false;

                                                                                }
                                                                                let newEditedTeam = editedTeam;
                                                                                newEditedTeam[memberIndex] = editedMember;

                                                                                this.setState({ editedTeam: newEditedTeam })
                                                                            }}
                                                                        />
                                                                        <ToggleField
                                                                            style={{ marginBottom: '0px' }}

                                                                            label="Edit"
                                                                            checked={member.eventPermissions[eventId].permissions[section].write}
                                                                            icons={false}
                                                                            onChange={(e) => {
                                                                                let editedMember = JSON.parse(JSON.stringify(member));

                                                                                let eventPermissions = editedMember.eventPermissions[eventId];
                                                                                let permissions = eventPermissions.permissions;
                                                                                permissions[section].write = e.target.checked;
                                                                                if (e.target.checked === true) {
                                                                                    permissions[section].read = true;
                                                                                }
                                                                                let newEditedTeam = editedTeam;
                                                                                newEditedTeam[memberIndex] = editedMember;

                                                                                this.setState({ editedTeam: newEditedTeam })
                                                                            }}
                                                                        />

                                                                    </div>


                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                    </div>

                                                    <div >
                                                        <div style={{

                                                            borderBottom: '1px solid var(--border)',
                                                            padding: '10px 0 '
                                                        }}>
                                                            <h3>Produce</h3>
                                                        </div>


                                                        {produceNavigation.map((sec) => {
                                                            let adminOnly = sec.adminOnly;
                                                            let name = sec.name;
                                                            let icon = sec.icon;
                                                            let section = sec.section;

                                                            return (

                                                                <div className='flex aic'
                                                                    style={{ justifyContent: 'space-between', borderBottom: '1px solid var(--border)', padding: '5px 0 ' }}
                                                                >
                                                                    <div style={{ width: '50%%' }}> {name}</div>


                                                                    <div className='flex aic '
                                                                        style={{ justifyContent: 'space-between', width: '50%%', gap: '50px' }}
                                                                    >

                                                                        <ToggleField
                                                                            style={{ marginBottom: '0px' }}
                                                                            label="View"
                                                                            checked={member.eventPermissions[eventId].permissions[section].read}
                                                                            icons={false}
                                                                            onChange={(e) => {
                                                                                let editedMember = JSON.parse(JSON.stringify(member));

                                                                                let eventPermissions = editedMember.eventPermissions[eventId];
                                                                                let permissions = eventPermissions.permissions;
                                                                                permissions[section].read = e.target.checked;
                                                                                if (e.target.checked === false) {
                                                                                    permissions[section].write = false;

                                                                                }
                                                                                let newEditedTeam = editedTeam;
                                                                                newEditedTeam[memberIndex] = editedMember;

                                                                                this.setState({ editedTeam: newEditedTeam })
                                                                            }}
                                                                        />
                                                                        <ToggleField
                                                                            style={{ marginBottom: '0px' }}

                                                                            label="Edit"
                                                                            checked={member.eventPermissions[eventId].permissions[section].write}
                                                                            icons={false}
                                                                            onChange={(e) => {
                                                                                let editedMember = JSON.parse(JSON.stringify(member));

                                                                                let eventPermissions = editedMember.eventPermissions[eventId];
                                                                                let permissions = eventPermissions.permissions;
                                                                                permissions[section].write = e.target.checked;
                                                                                if (e.target.checked === true) {
                                                                                    permissions[section].read = true;
                                                                                }
                                                                                let newEditedTeam = editedTeam;
                                                                                newEditedTeam[memberIndex] = editedMember;

                                                                                this.setState({ editedTeam: newEditedTeam })
                                                                            }}
                                                                        />

                                                                    </div>


                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </div>)
                    }
                    }
                    toggle={toggle}
                    cancelButtonLabel={'Close'}
                    cancelOnClick={toggle}
                    actionButtonLabel={'Submit'}
                    actionButton={() => {

                        this.submit();

                    }}
                ></FieldModal >
            </div >

        );
    }
}

export default withRouter(AddTeamMemberModal);
