import React, { Fragment } from 'react';
import API from '../../../../utils/API';
import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import { update } from 'lodash';
class RenamePageModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: this.props.page.title ? this.props.page.title : '',
			submitting: false,
			requiredFields: { title: false }
		};
      
	}

	handleNameChange = (p) => {
		this.setState({
			title: p.target.value
		});
	};
    
	updateTitle = () => {
        const { update, page,toggle } = this.props;
        const { title } = this.state;

        this.setState({ submitting: true })
        let renamePage = page;
        renamePage.title = title;
        update(renamePage, toggle);

	};


	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, title, requiredFields } = this.state;
      
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Rename Page"}
					bodyHeaderText={'Enter a new title for your Page.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="title"                                 
									value={title}                                    
									required={true}                                 
									errorTrigger={requiredFields.title}
									onChange={this.handleNameChange}
								/>
							</div>)
                            
					}}
                    
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateTitle()}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}

export default RenamePageModal;
