import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Input } from 'reactstrap';
import Select from 'react-select';

import API from '../../../../utils/API';
import SelectField from '../../../../components/inputs/selectField';
import InputField from '../../../../components/inputs/inputField';

import FieldModal from '../../../../components/modals/fieldModal';


class AddPointsModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			availablePoints: [],
			availablePointOptions: [],
			selectedValue: { label: '', color: '#00B8D9' },
			referenceId: '',
			submitting: false,
			invalid: false,
			success: false,
			error: '',
			enableSubmit: false,
			requiredFields: {
				selectedValue: false,
				referenceId: false
			}
		};
	}

	componentWillMount() {
		const { orgId, eventId } = this.props;
		this.setState({ loading: true });
		API().get(`Organizations/${orgId}/events/${eventId}/interactions`).then((res) => {
			if (res.data) {
				let surveyReference = [];
				let sessionsReference = this.props.sessions.map((s) => {
					if (s.surveyId) {
						surveyReference.push({ label: s.name, value: s.surveyId });

					}
					return { label: s.name, value: s._id };
				}
				);
				this.setState({
					availablePoints: res.data.points,
					availablePointOptions: res.data.points.map((p, i) => {
						return {
							label: `${p.customLabel ? p.customLabel : p.actionName} (${p.pointValue})`,
							value: i,
							actionName: p.actionName
						};
					}),
					loading: false,
					sessionsReference: sessionsReference,
					surveyReference: surveyReference
				});
			}
		});
	}

	handlePointsChosen = (e) => {
		const { availablePoints } = this.state;
		const selectedPoint = availablePoints.length > 0 && availablePoints[this.state.availablePointOptions[e.value].value];
		let notOncePerReference = selectedPoint && !selectedPoint.oncePerReference ? true : false;

		this.setState({
			selectedValue: this.state.availablePointOptions[e.value],
			enableSubmit: notOncePerReference
		});
	};
	handleSessionChosen = (e) => {
		this.setState({
			referenceId: e.value,
			enableSubmit: true
		});
	};


	submitInteractionPoints = () => {
		const { availablePoints, selectedValue } = this.state;
		const selectedPoint = availablePoints.length > 0 && availablePoints[selectedValue.value];
		const referenceId = this.state.referenceId;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		let error = '';
		const existingSimilarPoints = selectedPoint ? this.props.interactionPoints.filter((p) => p.name == selectedPoint.actionName) : [];

		if (
			selectedPoint &&
			((selectedPoint.oncePerReference && referenceId == ''))
		) {
			requiredFields.referenceId = false;
			valid = false;
			error = 'Reference ID is required';
		} else if (selectedPoint && ((existingSimilarPoints.length >= selectedPoint.maxPointsAllowed) || (selectedPoint.maxPointsAllowed == 0))) {
			valid = false;
			error = 'Max points allowed reached';
		} else {
			if (!selectedPoint) {
				requiredFields.selectedValue = true;
				valid = false;
				error = 'Point is required';
			} else {
				if (!((selectedPoint.oncePerReference && referenceId != '') || !selectedPoint.oncePerReference)) {
					requiredFields.referenceId = true;
					valid = false;
					error = 'Reference is required';
				} else {
					requiredFields.referenceId = false;
				}
				requiredFields.selectedValue = false;
			}
		}

		if (valid) {
			this.setState({ submitting: true, requiredFields: requiredFields, error: "" });
			this.props.add(selectedPoint, referenceId);
		} else {
			this.setState({ invalid: true, requiredFields: requiredFields, error: error });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			availablePointOptions,
			submitting,

			selectedValue,
			availablePoints,
			referenceId,
			requiredFields,
			enableSubmit, error
		} = this.state;
		const selectedPoint = availablePoints.length > 0 && availablePoints[selectedValue.value];

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add Interaction Points"}
					bodyHeaderText={`Points will be added to attendee total`}
					bodyContent={() => {
						return (
							<div>
								<SelectField
									classes={`w-100`}
									label="Available Point Options"
									value={selectedValue}
									name="availablePointOptions"
									options={availablePointOptions}
									required={true}
									errorTrigger={requiredFields.selectedValue}
									onChange={this.handlePointsChosen}
								/>

								{selectedPoint &&
									selectedPoint.oncePerReference && (
										<div>
											{this.props.showSessionReference(selectedPoint.actionName) ? (
												< SelectField
													classes={`w-100`}
													label="Sessions"
													options={this.state.sessionsReference}
													required={true}
													errorTrigger={requiredFields.referenceId}
													value={this.state.sessionsReference.find((s) => s.value == referenceId)}
													name="referenceId"
													onChange={this.handleSessionChosen}
												/>
											) : this.props.showSurveyReference(selectedPoint.actionName) ? (

												< SelectField
													classes={`w-100`}
													label="Sessions"
													options={this.state.surveyReference}
													required={true}
													errorTrigger={requiredFields.referenceId}
													value={this.state.surveyReference.find((s) => s.value == referenceId)}
													name="referenceId"
													onChange={this.handleSessionChosen}
												/>
											) : (
												<InputField
													label="Reference ID"
													onChange={(e) => this.setState({ referenceId: e.target.value, enableSubmit: e.target.value.length == 12 })}
													value={referenceId}
													errorTrigger={error}
													errorMessage={error}
													required={true}
												/>
											)}
										</div>
									)}
							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding Point...'}
					cancelButtonLabel={'Cancel'}

					actionButton={() => this.submitInteractionPoints()}
					submitting={submitting}
					actionButtonDisabled={submitting || !enableSubmit}

				></FieldModal>

			</div>

		);
	}
}

export default AddPointsModal;
