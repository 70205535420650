import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';

import BasicSectionHeader from '../../../../components/views/BasicSectionHeader.js';
import TabsContainer from '../../../../components/views/TabsContainer';
import ViewsTable from '../../../../components/tables/ViewsTable';

import AddFromModal from '../modals/addFromTemplateModal';
import AddSubjectModal from '../modals/addSubjectTemplateModal';

import TemplateRenameModal from '../modals/renameTemplateModal';
import DeleteTemplateModal from '../modals/deleteTemplateModal';

import SendTestTemplateModal from '../modals/sendTestTemplateModal';
import EmailEditor from '../../../../components/email/emailEditor';
import AddBccTemplateModal from '../modals/addBccTemplateModal';
import * as IFrame from 'react-iframe-form';
import LoadingWizard from '../../../../components/spinner/wizard';
import SectionFooter from '../../../../components/views/SectionFooter';
import SectionHeader from '../../../../components/views/SectionHeader';

import InputField from '../../../../components/inputs/inputField';
import AlertBar from '../../../../components/custom/alertBar';

import CreateCampaignFromTemplateModal from '../modals/createCampaignFromTemplate';
import DuplicateCampaignModal from '../modals/duplicateCampaignModal.js';

import DuplicateTemplateModal from '../modals/duplicateTemplateModal.js';
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}
const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
class EventTemplate extends Component {
	constructor(props) {
		super(props);
		const columns = {
			attendeeFirstName: 'First Name',
			attendeeLastName: 'Last Name',
			attendeeEmail: 'Email',
			status: 'Status',
			sentDate: 'Sent date'
		};
		const columnTypes = {
			attendeeFirstName: 'text',
			attendeeLastName: 'text',
			attendeeEmail: 'text',
			status: 'text',
			sentDate: 'Date'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		const { match: { params } } = this.props;

		this.state = {
			orgSenderEmail: '',
			orgSenderName: '',
			editedTemplate: {},
			template: {},
			selectedSection: 0,
			editedEmail: {},
			sendEmailModalOpen: false,
			editing: false,
			errorMessage: false,
			DeleteEmailModalOpen: false,
			loading: true,
			parameterOptionsOpen: false,
			requiredFields: {
				name: false,
				subject: false
			},
			codeExpanded: false,
			previewExpanded: false,
			scheduleEmailModalOpen: false,
			cancelEdit: false,
			toggleFrom: false,
			toggleSubject: false,
			toggleRecipients: false,
			toggleSendEmailNow: false,
			toggleSendTestEmail: false,
			selectedSection: 0,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			toggleRename: false,
			toggleDeleteTemplateModal: false,
			isDedicatedEmailServerEnabled: false,
			toggleSendTestEmail: false,
			editCount: 0,
			toggleBcc: false,
			orgSenderEmail: '',
			orgSenderName: '',
			validBcc: true,
			validEmail: true,
			toggleDuplicateTemplateModal: false
		};
	}

	//Fetch complete attendee information and append to current
	async componentDidMount() {
		const { match: { params } } = this.props;
		const { eventId, orgId } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/templates/${params.templateId}`).then((res) => {
			if (res.data) {
				res.data.template.bcc = res.data.template.bcc.toString().replace(/,/g, ', ')
				this.setState({
					orgSenderEmail: res.data.defaultSenderEmail,
					orgSenderName: res.data.defaultSenderName,
					template: res.data.template,
					editedTemplate: res.data.template,
					loading: false,
					isDedicatedEmailServerEnabled: res.data.template.dedicatedEmailServerEnabled
				});
			}
		});
	}
	updateEditedTemplate = (changesObj) => {
		const { editedTemplate } = this.state;
		this.setState({ editedTemplate: { ...editedTemplate, ...changesObj }, editing: true });

	}
	updateTemplate = (templateProperties, callback) => {
		const { editedTemplate } = this.state;

		this.setState(
			{
				editedTemplate: { ...editedTemplate, ...templateProperties }
			},
			() => {
				//this.props.updateTemplates(this.state.editedTemplate);
				callback();
			}
		);
	};
	toggleBccModal = () => {
		const { toggleBcc } = this.state;
		this.setState({ toggleBcc: !toggleBcc });
	};
	toggleFromModal = () => {
		const { toggleFrom } = this.state;
		this.setState({ toggleFrom: !toggleFrom });
	};
	toggleSubjectModal = () => {
		const { toggleSubject } = this.state;
		this.setState({ toggleSubject: !toggleSubject });
	};
	toggleRenameModal = () => {
		const { toggleRename } = this.state;
		this.setState({ toggleRename: !toggleRename });
	};

	toggleDeleteTemplateModal = () => {
		const { toggleDeleteTemplateModal } = this.state;
		this.setState({ toggleDeleteTemplateModal: !toggleDeleteTemplateModal });
	};

	toggleTemplateEditorModal = () => {
		const { toggleTemplateEditorModal } = this.state;
		this.setState({ toggleTemplateEditorModal: !toggleTemplateEditorModal });
	};
	toggleSendTestEmailModal = () => {
		const { toggleSendTestEmail } = this.state;
		this.setState({ toggleSendTestEmail: !toggleSendTestEmail });
	};

	toggleDuplicateTemplateModal = () => {
		const { toggleDuplicateTemplateModal } = this.state;
		this.setState({ toggleDuplicateTemplateModal: !toggleDuplicateTemplateModal });
	};


	updateSelected = (selectedIndex) => {
		const { selectedSection } = this.state;

		this.setState({
			selectedSection: selectedIndex
		});
	};
	updateTemplateHtml = async (emailTemplate) => {
		const { editedTemplate } = this.state;
		const { orgId, eventId } = this.props;

		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/template/${editedTemplate._id}`, {
				name: emailTemplate.name,
				html: emailTemplate.html,
				parameters: emailTemplate.parameters,
				emailStyles: emailTemplate.emailStyles,
				emailComponents: emailTemplate.emailComponents,
				codeOnly: emailTemplate.codeOnly,
			})
			.then((res) => {
				if (res.data) {
					this.updateTemplate({ html: emailTemplate.html, parameters: emailTemplate.parameters, emailComponents: emailTemplate.emailComponents }, () => {
						this.setState({ editCount: ++this.state.editCount });
					});
				}
			});
	};

	saveTemplate = async () => {
		const { editedTemplate } = this.state;
		const { orgId, eventId } = this.props;


		let senderEmail = editedTemplate.senderEmail
		let bccEmails = editedTemplate.bcc == '' ? [] : editedTemplate.bcc.replace(/ /g, '').split(',');
		let validbcc = true;



		bccEmails = bccEmails.map((e) => {
			if (!ValidateEmail(e) || '') {
				validbcc = false;
			}

			if (e !== '') return e;
		});
		let validEmail = editedTemplate.senderEmail && editedTemplate.senderEmail !== '' ? ValidateEmail(editedTemplate.senderEmail) : true;


		if (validbcc && validEmail) {

			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/template/${editedTemplate._id}`, {
					name: editedTemplate.name,
					bcc: bccEmails,
					senderName: editedTemplate.senderName,
					senderEmail: editedTemplate.senderEmail,
					subject: editedTemplate.subject,
					previewText: editedTemplate.previewText,

				})
				.then((res) => {
					this.updateTemplate({
						name: editedTemplate.name,
						bcc: editedTemplate.bcc,
						senderName: editedTemplate.senderName,
						senderEmail: editedTemplate.senderEmail,
						subject: editedTemplate.subject,
						previewText: editedTemplate.previewText,
					}, () => {
						this.setState({ editing: false, validBcc: true, validEmail: true });
					});

				})

		} else {

			this.setState({ validBcc: validbcc, validEmail: validEmail })

		}
	}

	renderTab = () => {
		const { normalizedColumns, selectedSection, toggledColumns, editedEmail } = this.state;

		switch (selectedSection) {
			case 0:
				break;
			case 1:
				let data = editedEmail.sentEmails;

				return (
					<ViewsTable
						style={{ margin: '25px', paddingTop: 10, paddingBottom: 25, height: 325 }}
						columns={normalizedColumns}
						toggledColumns={toggledColumns}
						data={data}
						mainColumnActive={false}
						checked={{}}
						checkRow={{}}
						mainActions={[]}
						checkAllRows={() => { }}
						sortData={() => { }}
						disableCheck={true}
					/>
				);
				break;
			case 2:
				return (
					<div style={{ margin: 25, border: '1px solid #999999', borderRadius: 6 }} className="emailPreview">
						<div className="emailPreviewWindow" dangerouslySetInnerHTML={{ __html: editedEmail.html }} />
					</div>
				);
				break;
		}
	};

	cancelEdit = () => {

		this.setState({ editing: false, editedTemplate: this.state.template });

	};
	saveInfo = () => { };

	updateEditedTemplate = (changesObj) => {
		const { editedTemplate } = this.state;
		this.setState({ editedTemplate: { ...editedTemplate, ...changesObj }, editing: true });

	}

	toggleCreateCampaign = () => {
		const { toggleCreateCampaign } = this.state;
		this.setState({ toggleCreateCampaign: !toggleCreateCampaign });
	};

	render() {
		const { event, keyedMetadata, keyedData, eventId, orgId } = this.props;
		const {

			loading,
			editing,
			editedEmail,
			toggleFrom,
			toggleSubject,
			toggleRecipients,
			toggleSendEmailNow,
			toggleSendTestEmail,
			selectedSection,
			editedTemplate,
			toggleRename,
			toggleDeleteTemplateModal,
			toggleTemplateEditorModal,
			isDedicatedEmailServerEnabled,
			editCount,
			toggleBcc,
			orgSenderEmail,
			orgSenderName,
			validBcc,
			validEmail,
			toggleCreateCampaign,
			template,
			toggleDuplicateTemplateModal
		} = this.state;
		let recipients = editedEmail.recipients;

		let isRecipientsEmpty = recipients
			? recipients.attendees.length != 0 || recipients.groups.length != 0 || recipients.sessions.length != 0
				? false
				: true
			: true;

		let recipientCount = recipients
			? recipients.attendees.length != 0
				? recipients.attendees.length
				: recipients.groups.length != 0
					? recipients.groups.length
					: recipients.sessions.length != 0 ? recipients.sessions.length : 0
			: null;
		//let sendNow = !isRecipientsEmpty && editedEmail.subject !== '' && editedEmail.previewText !== '';
		let sendNow = true;
		let testNow =
			!loading &&
			((isDedicatedEmailServerEnabled && editedTemplate.serviceUrl) ||
				(!isDedicatedEmailServerEnabled && editedTemplate.subject !== ''));

		let url = !loading ? `${ENV}Organizations/${orgId}/emailrenderer?preview=true` : '';
		let method = 'post';
		let data = !loading ? { html: editedTemplate.html, eventId: this.props.eventId } : { html: '', eventId: '' };

		const adminEmail = orgSenderEmail;
		const adminEmailName = orgSenderName;
		let emailPlaceholder = event.eventEmail || adminEmail;
		let emailNamePlaceholder = event.eventEmailName || adminEmailName;
		let fromName = editedTemplate.senderName && editedTemplate.senderName !== '' ? editedTemplate.senderName : '';
		let fromEmail = editedTemplate.senderEmail && editedTemplate.senderEmail !== '' ? editedTemplate.senderEmail : '';



		/* if (data == ' ') {
			console.log("no data")
		} else {
			console.log("data")
		} */
		console.log(editedTemplate.html == '')

		return (
			<div className="sectionContainer">
				<AlertBar message={"This email is a template. Changes made here will be applied to all future emails created from this template."} type="error" />
				<SectionHeader
					style={{ marginTop: 20 }}
					title={editedTemplate.name}
					enableLastLink={true}
					breadcrumbs={[
						{
							name: event.name,
						},
						{
							name: " Email Templates",
						}
					]}
					mainActions={[

					]}
					directLink={'../'}
					subActions={[
						{
							onClick: this.toggleCreateCampaign,
							label: <span><i className='las la-share mr-10'></i>Create Campaign</span>
						},
						{
							onClick: this.toggleRenameModal,
							label: <span><i className='las la-pen mr-10'></i>Rename Template</span>
						},
						{
							onClick: this.toggleDuplicateTemplateModal,
							label: <span><i className='las la-copy mr-10'></i>Duplicate Template</span>
						},
						{
							onClick: this.toggleDeleteTemplateModal,
							label: <span><i className='las la-trash mr-10'></i>Delete Template</span>
						}
					]}
				>
				</SectionHeader>
				<Fragment>
					{!loading && <div className='flex'>
						<div className='flex aic'>
							<div className=' mainActionContainerTemplate template mr-10' style={sendNow ? {} : { opacity: '0.5', pointerEvents: 'none' }}>
								<button className={` ${editedTemplate.html == '' ? 'disabled' : ` prim `}`}
									onClick={this.toggleCreateCampaign}
								>Create Campaign
								</button>
							</div>
						</div>
						<div style={testNow ? { margin: 'auto' } : { margin: 'auto', color: '#D5D5D5', pointerEvents: 'none' }} className={`ml-a ml-10 ${!testNow ? "" : "c-pointer"}`} onClick={!testNow ? () => { } : this.toggleSendTestEmailModal}>
							<i className="las la-envelope-open mr-5"></i>
							Send a Test
						</div>
					</div>}

				</Fragment >




				{!loading && <div className='sectionBody'>
					<div className="blocksContainer">
						<div className='sectionBlock mr-25 '>
							<h2 className='blockHeader' style={{ marginTop: 0 }}>To</h2>

							<InputField
								label="BCC"
								placeholder="Add emails separated by commas..."
								value={editedTemplate.bcc}
								errorTrigger={validBcc == false}
								errorMessage={'Invalid Email detected'}
								onChange={(e) => {

									this.updateEditedTemplate({
										bcc: e.target.value
									})
								}}
							/>
							<h2 className='blockHeader'>Subject</h2>
							<InputField
								label="Subject"
								placeholder="Write your subject line"
								value={editedTemplate.subject || ''}
								onChange={(e) => {
									this.updateEditedTemplate({
										subject: e.target.value
									})
								}}
							/>
							<InputField
								label="Preview"
								placeholder="Write a preview message"
								value={editedTemplate.previewText}
								onChange={(e) => {
									this.updateEditedTemplate({
										previewText: e.target.value
									})
								}}
							/>
							<h2 className='blockHeader'>From</h2>
							<InputField
								label="Name"
								placeholder={fromName == '' ? emailNamePlaceholder ? emailNamePlaceholder : 'Simple Events Support' : ''}
								value={fromName}
								onChange={(e) => {
									this.updateEditedTemplate({
										senderName: e.target.value
									})
								}}
							/>
							<InputField
								label="Email"
								placeholder={fromEmail == '' ? emailPlaceholder ? emailPlaceholder : 'support@simple.events' : ''}
								value={fromEmail}
								errorTrigger={validEmail == false}
								errorMessage={'Invalid Email'}
								onChange={(e) => {
									this.updateEditedTemplate({
										senderEmail: e.target.value
									})
								}}
							/>
						</div>
						<div style={{ maxWidth: 900 }} className='sectionBlock emailDesignPreview '>

							<div
								className="designPreviewDetailsContainer flex"
								style={
									!(editedTemplate.dedicatedEmailServerEnabled && editedTemplate.serviceUrl) ||
										!editedTemplate.html ? (
										{
											borderBottom: 'unset',
											borderRadius: 0
										}
									) : (
										{}
									)
								}
							>

								<div className="detailContainer">
									<h2 className='blockHeader mt-0'>Design</h2>
									{/* {isDedicatedEmailServerEnabled &&
										editedTemplate.serviceUrl && (
											<p>
												ServiceUrl: {editedTemplate.serviceUrl}
											</p>
										)}{' '}
									{testNow && (
										<div
											onClick={this.toggleSendTestEmailModal}
											style={{ color: '#2B83F1', cursor: 'pointer' }}
											className="detailDescription"
										>
											Send a test
										</div>
									)} */}
								</div>





















								{!isDedicatedEmailServerEnabled ? editedTemplate.html == '' ? (
									<button
										className='prim ml-a'
										onClick={this.toggleTemplateEditorModal}
									>
										Add Design
									</button>
								) : (
									<button
										className='neu ml-a'
										onClick={this.toggleTemplateEditorModal}
									>
										Edit Design
									</button>
								) : (
									<div className='ml-a' />
								)}
							</div>
							<div>
								<p style={{ color: '#999999', marginBottom: 5 }} className='small'>Email Template Design</p>
							</div>

							<div
								className="emailPreview  eFrame flex-ac preview"
								style={{
									backgroundColor: editedTemplate.emailStyles ? editedTemplate.emailStyles.backgroundColor : '',
								}}
							>
								{
									<IFrame.Form
										id="builderPreviewFrameTemplate"
										className="emailPreviewWindow Frame"
										key={`template-${editCount}`}
										style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
										name="PreviewFrameTemp"
										config={{ url, method, data }}
									/>
								}
							</div>



							{/* {isDedicatedEmailServerEnabled && editedTemplate.serviceUrl ? (
								<div
									style={{
										maxWidth: 700,
										overflow: 'auto',
										borderRadius: 0,
										maxHeight: 900
									}}
									className="emailPreview "
								>
									<iframe
										className="emailPreviewWindow Frame"
										scrolling="no"
										style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
										src={`${editedTemplate.serviceUrl
											? editedTemplate.serviceUrl.concat('?preview=true')
											: ''}`}
									/>
								</div>
							) : (
								!isDedicatedEmailServerEnabled &&
								editedTemplate.html && (
									<div
										className="emailPreview  eFrame flex-ac preview"
										style={{
											backgroundColor: editedTemplate.emailStyles ? editedTemplate.emailStyles.backgroundColor : '',
										}}
									>
										{
											<IFrame.Form
												id="builderPreviewFrameTemplate"
												className="emailPreviewWindow Frame"
												key={`template-${editCount}`}
												style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
												name="PreviewFrameTemp"
												config={{ url, method, data }}
											/>
										}
									</div>
								)
							)} */}
						</div>
					</div>
				</div>}

				{toggleCreateCampaign && <CreateCampaignFromTemplateModal
					isOpen={toggleCreateCampaign}
					addCampaign={(emailObj, cb) => { cb() }}
					template={editedTemplate}
					keyedMetadata={keyedMetadata}
					toggle={this.toggleCreateCampaign}
					eventId={eventId}
					orgId={orgId}
				/>}
				{toggleBcc && (
					<AddBccTemplateModal
						isOpen={toggleBcc}
						template={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						toggle={this.toggleBccModal}
						update={this.updateTemplate}
					/>
				)}
				{toggleFrom && (
					<AddFromModal
						isOpen={toggleFrom}
						template={editedTemplate}
						orgSenderEmail={orgSenderEmail}
						orgSenderName={orgSenderName}
						orgId={orgId}
						eventId={eventId}
						toggle={this.toggleFromModal}
						update={this.updateTemplate}
					/>
				)}
				{toggleSubject && (
					<AddSubjectModal
						isOpen={toggleSubject}
						template={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						toggle={this.toggleSubjectModal}
						update={this.updateTemplate}
					/>
				)}
				{toggleSendTestEmail && (
					<SendTestTemplateModal
						isOpen={toggleSendTestEmail}
						template={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.toggleSendTestEmailModal}
					/>
				)}

				{toggleDuplicateTemplateModal && (

					<DuplicateTemplateModal
						isOpen={toggleDuplicateTemplateModal}
						template={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.toggleDuplicateTemplateModal}
					/>
				)}





				{toggleRename && (
					<TemplateRenameModal
						isOpen={toggleRename}
						template={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						toggle={this.toggleRenameModal}
						update={this.updateTemplate}
					/>
				)}



				{toggleDeleteTemplateModal && (
					<DeleteTemplateModal
						isOpen={toggleDeleteTemplateModal}
						template={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.toggleDeleteTemplateModal}
						update={this.updateTemplate}
						updateDeleteTemplate={this.props.updateDeleteTemplate}
					/>
				)}
				{toggleTemplateEditorModal && (
					<EmailEditor
						isOpen={toggleTemplateEditorModal}
						emailTemplate={editedTemplate}
						orgId={orgId}
						eventId={eventId}
						keyedMetadata={keyedMetadata}
						keyedData={keyedData}
						event={event}
						branding={event.branding}
						url={`${ENV}Organizations/${orgId}/emailrenderer`}
						method={method}
						postData={data}
						toggle={this.toggleTemplateEditorModal}
						update={this.updateTemplateHtml}
						cancelEdit={this.cancelEdit}
						links={{
							getAssets: `Organizations/${orgId}/events/${eventId}/assets`,
							addAssets: `Organizations/${orgId}/events/${eventId}/assets`,
						}}
					/>
				)}

				<SectionFooter updatedAt={template.updatedAt} cancelEdit={this.cancelEdit} saveEdit={this.saveTemplate} editing={editing} />
				<LoadingWizard text="Loading Email Template" loading={loading} />
			</div>
		);
	}
}

export default withRouter(EventTemplate);
