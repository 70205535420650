import React, { Component, Fragment } from 'react';
import * as IFrame from 'react-iframe-form';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';

import { withRouter } from 'react-router-dom';
import moment from 'moment';

import API from '../../../../utils/API';

import SectionHeader from '../../../../components/views/SectionHeader.js';
import TabsContainer from '../../../../components/views/TabsContainer';
import ViewsTable from '../../../../components/tables/ViewsTable';

import AddFromModal from '../modals/addFromModal';
import AddBccModal from '../modals/addBccModal';

import AddSubjectModal from '../modals/addSubjectModal';
import AddRecipientsModal from '../modals/addRecipientsModal';
import SendEmailNowModal from '../modals/sendEmailNowModal';
import ScheduleCampaignModal from '../modals/scheduleCampaignModal';
import UnscheduleEmailModal from '../modals/unscheduleEmailModal';

import SendTestEmailModal from '../modals/sendTestEmailModal';

import RenameEmailModal from '../modals/renameEmailModal';
import DeleteEmailModal from '../modals/deleteEmailModal';

import CreateTemplateFromCampaignModal from '../modals/createTemplateFromCampaignModal';
import DuplicateCampaignModal from '../modals/duplicateCampaignModal';
import CodeEditor from '../../../../components/codeEditor/codeEditor';

import EmailEditor from '../../../../components/email/emailEditor';
import LoadingWizard from '../../../../components/spinner/wizard';
import SectionFooter from '../../../../components/views/SectionFooter';

import InputField from '../../../../components/inputs/inputField';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import ToggleField from '../../../../components/inputs/toggleField.js';


const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
let interval;
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}
class EventCampaign extends Component {
	constructor(props) {
		super(props);
		const columns = {
			recipientName: 'Name',
			recipientEmail: 'Email',
			sentAt: 'Sent date',
			delivered: 'Delivered',
			deliveredAt: 'Delivered date',
			open: 'Opened',
			openAt: 'Opened date',
			click: 'Clicked',
			clickAt: 'Clicked date',
			bounce: 'Bounced',
			bounceAt: 'Bounced date',
		};
		const columnTypes = {
			recipientName: 'text',
			recipientEmail: 'text',
			sentAt: 'date',
			delivered: 'boolean',
			deliveredAt: 'date',
			open: 'boolean',
			openAt: 'date',
			click: 'boolean',
			clickAt: 'date',
			bounce: 'boolean',
			bounceAt: 'date',
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		const { match: { params } } = this.props;
		const email = props.emails.length > 0 ? props.emails.find((e) => e._id == params.emailId) : {};
		this.state = {
			orgSenderEmail: '',
			orgSenderName: '',
			email: email || {},
			selectedSection: 0,
			editedEmail: {},
			sendEmailModalOpen: false,
			editing: false,
			errorMessage: false,
			DeleteEmailModalOpen: false,
			loading: true,
			parameterOptionsOpen: false,
			requiredFields: {
				name: false,
			},
			codeExpanded: false,
			previewExpanded: false,
			scheduleEmailModalOpen: false,
			cancelEdit: false,
			toggleFrom: false,
			toggleSubject: false,
			toggleRecipients: false,
			toggleSendEmailNow: false,
			toggleSendTestEmail: false,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			toggleRenameEmail: false,
			toggleDeleteEmailModal: false,
			toggleScheduleEmailModal: false,
			toggleCampaignEditorModal: false,
			isDedicatedEmailServerEnabled: false,
			toggleCreateTemplateFromCampaignModal: false,
			toggleBcc: false,
			editCount: 0,
			toggleCampaignCodeEditorModal: false,
			toggleDuplicateCampaign: false,
			sentCount: 0,
			totalEmails: 0,
			sendingCompleted: false,
			validBcc: true,
			validEmail: true,
			toggleTemplateCenter: false,
			searchTerm: '',
			userName: '',
			domainName: '',
			userNamePlaceholder: '',
		};
	}

	//Fetch complete attendee information and append to current
	async componentDidMount() {
		const { match: { params }, event } = this.props;
		const email = this.state.email;
		const { eventId, orgId, keyedMetadata } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/emails/${params.emailId}`).then((res) => {
			if (res.data) {
				const parameters = keyedMetadata;
				res.data.bcc = res.data.bcc.toString().replace(/,/g, ', ')
				Object.assign(email, res.data);
				email.parameters = parameters;

				let authorizedEmailDomains = res.data.authorizedEmailDomains.map((ae) => {
					return { value: ae, label: ae }

				});



				const adminEmail = email.defaultSenderEmail;
				const adminEmailName = email.defaultSenderName;
				let emailPlaceholder = event.eventEmail || adminEmail;
				let emailNamePlaceholder = event.eventEmailName || adminEmailName;
				let fromName = email.senderName && email.senderName !== '' ? email.senderName : '';
				let fromEmail = email.senderEmail && email.senderEmail !== '' ? email.senderEmail : '';

				let userName = fromEmail ? fromEmail.split('@')[0] : '';
				let userNamePlaceholder = emailPlaceholder ? emailPlaceholder.split('@')[0] : 'support';
				let domainName = fromEmail ? fromEmail.split('@')[1] : emailPlaceholder ? emailPlaceholder.split('@')[1] : 'simple.events';




				this.setState({
					orgSenderEmail: res.data.defaultSenderEmail,
					orgSenderName: res.data.defaultSenderName,
					email: email,
					editedEmail: email,
					schedules: res.data.schedules,
					platformTemplates: this.props.platformTemplates,
					templates: this.props.templates,
					isDedicatedEmailServerEnabled: email.dedicatedEmailServerEnabled,
					userName: userName,
					domainName: domainName,
					userNamePlaceholder: userNamePlaceholder,
					authorizedEmailDomains: authorizedEmailDomains,
					emailNamePlaceholder: emailNamePlaceholder,
					fromName: fromName,

				}, () => {
					this.runCheck();
					this.setState({ loading: false, })
				});
			}
		});
	}

	componentWillUnmount() {
		clearInterval(interval);
	}


	runCheck = () => {
		const { match: { params }, eventId, orgId, keyedMetadata } = this.props;
		const { editedEmail } = this.state;
		const { event } = this.props;
		//will check for the count of sentemails and make enpoint calls every second for updated sentemails
		let sentEmails = this.state.editedEmail.sentEmails;

		if (editedEmail.sent) {

			if (this.isEmailSending() == false) {
				interval = setInterval(() => {
					//stop interval if all emails have been sent
					if (this.isEmailSending() == false) {

						API().get(`Organizations/${orgId}/events/${eventId}/emails/${params.emailId}/status`)
							.then((res) => {
								this.setState({ editedEmail: { ...editedEmail, sentEmails: sentEmails, emailsToBeSent: res.data.emailsToBeSent }, sentCount: res.data.sentEmails.length, totalEmails: res.data.emailsToBeSent.length });
							});
					} else {
						this.setState({ sendingCompleted: true, editedEmail: { ...editedEmail, status: 'Sent' } });
						clearInterval(interval);
					}
				}, 1000);
			}
		}
	}

	isEmailSending = () => {
		const { editedEmail } = this.state;
		const { event } = this.props;

		const email = this.state.email;

		let sentEmails = this.state.editedEmail.sentEmails;
		let sentEmailsCount = this.state.sentCount;
		let isCompleted = false;

		let recipientCount = 0;


		if (editedEmail.sent) {
			const recipientModel =
				email.recipients.attendees.length !== 0
					? 'attendees'
					: email.recipients.groups.length !== 0
						? 'groups'
						: email.recipients.sessions.length !== 0 ? 'sessions' : '';

			switch (recipientModel) {
				case 'attendees':


					if (editedEmail.emailsToBeSent) {
						recipientCount = editedEmail.emailsToBeSent.length;

						if (sentEmailsCount > 0 && (recipientCount == sentEmailsCount || recipientCount < sentEmailsCount)) {
							isCompleted = true;
						}
					} else {
						//legacy logic assuming that all sent status have in fact been sent
						recipientCount = editedEmail.sentEmails.length;
						isCompleted = true;


					}
					break;
				case 'groups':
					if (editedEmail.emailsToBeSent) {
						recipientCount = editedEmail.emailsToBeSent.length;

						if (sentEmailsCount > 0 && (recipientCount == sentEmailsCount || recipientCount < sentEmailsCount)) {
							isCompleted = true;
						}
					} else {
						//legacy logic assuming that all sent status have in fact been sent

						recipientCount = editedEmail.sentEmails.length;
						isCompleted = true;


					}

					break;
				case 'sessions':

					if (editedEmail.emailsToBeSent) {
						recipientCount = editedEmail.emailsToBeSent.length;


						if ((sentEmailsCount > 0 && recipientCount > 0) && (recipientCount == sentEmailsCount || recipientCount < sentEmailsCount)) {
							isCompleted = true;
						}

					} else {
						//legacy logic assuming that all sent status have in fact been sent
						recipientCount = editedEmail.sentEmails.length;
						isCompleted = true;
					}
					break;
				default:
			}
			let progressPercentage = sentEmailsCount == 0 && recipientCount == 0 ? 0 : Math.round((sentEmailsCount / recipientCount) * 100);


			this.setState({ totalEmails: recipientCount, sendingCompleted: isCompleted, progressPercentage: progressPercentage > 100 ? 100 : progressPercentage });

			return isCompleted;

		}
	}

	updateCampaign = (emailProperties, callback) => {
		const { email, editedEmail } = this.state;

		this.setState(
			{
				email: { ...email, ...emailProperties, updatedAt: moment().format() },
				editedEmail: { ...editedEmail, ...emailProperties, updatedAt: moment().format() }
			},
			() => {
				const { email } = this.state;
				this.props.updateEmail(email);
				callback && callback();
			}
		);
	};

	toggleFromModal = () => {
		const { toggleFrom } = this.state;
		this.setState({ toggleFrom: !toggleFrom });
	};
	toggleBccModal = () => {
		const { toggleBcc } = this.state;
		this.setState({ toggleBcc: !toggleBcc });
	};
	toggleSubjectModal = () => {
		const { toggleSubject } = this.state;
		this.setState({ toggleSubject: !toggleSubject });
	};
	toggleRecipientsModal = () => {
		const { toggleRecipients } = this.state;
		this.setState({ toggleRecipients: !toggleRecipients });
	};
	toggleSendEmailNowModal = () => {
		const { toggleSendEmailNow } = this.state;
		this.setState({ toggleSendEmailNow: !toggleSendEmailNow });
	};

	toggleSendTestEmailModal = () => {
		const { toggleSendTestEmail } = this.state;
		this.setState({ toggleSendTestEmail: !toggleSendTestEmail });
	};
	toggleRenameEmailModal = () => {
		const { toggleRenameEmail } = this.state;
		this.setState({ toggleRenameEmail: !toggleRenameEmail });
	};
	toggleDuplicateCampaignModal = () => {
		const { toggleDuplicateCampaign } = this.state;
		this.setState({ toggleDuplicateCampaign: !toggleDuplicateCampaign });
	};
	toggleScheduleEmailModal = () => {
		const { toggleScheduleEmailModal } = this.state;
		this.setState({ toggleScheduleEmailModal: !toggleScheduleEmailModal });
	};
	toggleDeleteEmailModal = () => {
		const { toggleDeleteEmailModal } = this.state;
		this.setState({ toggleDeleteEmailModal: !toggleDeleteEmailModal });
	};
	toggleTemplateCenter = () => {
		const { toggleTemplateCenter } = this.state;
		this.setState({ toggleTemplateCenter: !toggleTemplateCenter });
	};
	toggleUnscheduleEmail = () => {
		const { toggleUnscheduleEmailModal } = this.state;
		this.setState({ toggleUnscheduleEmailModal: !toggleUnscheduleEmailModal });
	};
	toggleCampaignEditorModal = () => {
		const { toggleCampaignEditorModal } = this.state;
		this.setState({ toggleCampaignEditorModal: !toggleCampaignEditorModal });
	};

	toggleCreateTemplateFromCampaignModal = () => {
		const { toggleCreateTemplateFromCampaignModal } = this.state;
		this.setState({ toggleCreateTemplateFromCampaignModal: !toggleCreateTemplateFromCampaignModal });
	};
	updateSelected = (selectedIndex) => {
		const { selectedSection } = this.state;

		this.setState({
			selectedSection: selectedIndex
		});
	};

	updateCampaignHtml = async (emailCampaign) => {
		const { orgId, eventId } = this.props;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/email/${emailCampaign._id}`, {
				name: emailCampaign.name,
				html: emailCampaign.html,
				emailStyles: emailCampaign.emailStyles,
				emailComponents: emailCampaign.emailComponents,
				parameters: emailCampaign.parameters,
				codeOnly: emailCampaign.codeOnly,
			})
			.then((res) => {
				if (res.data) {
					this.updateCampaign({ html: emailCampaign.html, parameters: emailCampaign.parameters, emailComponents: emailCampaign.emailComponents }, () => {
						this.setState({ editCount: ++this.state.editCount });
					});
				}
			});
	};
	/* Download */
	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};
	downloadReport = async () => {

		const { editedEmail, columns, columnTypes } = this.state;

		const entries = editedEmail.sentEmails; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData
		};

		const reportDetails =
			{ Title: editedEmail.name + " Report", Subject: 'Email Recipient Report' };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${moment().format('LLL')}.xlsx`
		);
	};

	/* sortData */
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns, selectedSection } = this.state;
		if (selectedSection !== 2) {
			let nc = normalizedColumns;
			nc = nc.map((col) => {
				if (col.key == column.key) {
					col.sortAsc = sortAsc;
					col.sortDesc = sortDesc;
				} else {
					col.sortAsc = false;
					col.sortDesc = false;
				}
				return col;
			});

			this.setState({
				normalizedColumns: nc,
				selectedColumnSort: column,
				sortDesc: sortDesc == false ? true : false
			});

		}
	}



	renderTab = () => {
		const { normalizedColumns, selectedColumnSort, selectedSection, sortDesc, toggledColumns, editCount, editedEmail, loading, searchTerm, columns, columnTypes, } = this.state;
		const { orgId } = this.props;
		switch (selectedSection) {
			case 0:
				return this.renderDetails();
			case 1:
				let data = editedEmail.sentEmails;
				if (searchTerm.length > 0) {
					const searchTerms = searchTerm.toLowerCase().split(' ');
					searchTerms.forEach((term) => {
						if (term != '') {
							data = data.filter((entry) => {
								let found = false;
								Object.keys(columns).forEach((key) => {
									if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
										found = true;
									}
								});
								return found;
							});
						}
					});
				}
				if (selectedColumnSort) {
					data = data.sort((a, b) => {
						const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
						if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
							switch (columnType) {
								case 'string':
									return a[selectedColumnSort.key]
										.toLowerCase()
										.trim()
										.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
								case 'number':
									return a[selectedColumnSort.key] - b[selectedColumnSort.key];
								case 'date':
									return (
										new Date(a[selectedColumnSort.key]).getTime() -
										new Date(b[selectedColumnSort.key]).getTime()
									);
								case 'time':
									return (
										new Date(a[selectedColumnSort.key]).getTime() -
										new Date(b[selectedColumnSort.key]).getTime()
									);
								case 'boolean':
									const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
									const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
									return a1.localeCompare(b1);
								default:
									return a[selectedColumnSort.key]
										.toLowerCase()
										.trim()
										.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
							}
						} else if (a[selectedColumnSort.key]) {
							return 1;
						}
						return -1;
					});

					data = sortDesc ? data.reverse() : data;
				}

				return (
					<div className=' flex sectionContainer'>
						<div className='mb-10  mt-10 flex aic'>
							<InputField
								classes="m-0 viewSearch"
								placeholder="Search "
								clickIcon={true}
								required={true}
								value={searchTerm}
								onChange={(e) => {
									clearTimeout(this.searchTimeout);
									this.setState({ searchTerm: e.target.value },
										() => {
											this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
										});

								}}
								prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
								inputIcon={searchTerm != '' &&
									<i
										onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
										//onClick={() => this.setState({ searchTerm: '', searching: false })}
										className="las la-times-circle"
										style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
									/>}
							/>
							<div className="sectionOptionsContainer">
								<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
									<i className="las la-download" />
									<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
								</div>
							</div>
						</div>
						<div className='mb-10 flex sectionContainer'>
							<ViewsTable
								title={'Email'}
								classes="h-100"
								columns={normalizedColumns}
								toggledColumns={toggledColumns}
								data={data}
								mainColumnActive={false}
								checked={{}}
								checkRow={{}}
								mainActions={[]}
								checkAllRows={() => { }}
								sortData={this.sortData}
								defaultSort={'recipientName'}
								sortDirection={'desc'}
								disableCheck={true}
							/>
						</div>
					</div>
				);
			case 2:
				let url = `${ENV}Organizations/${orgId}/emailrenderer?preview=true`;
				let method = 'post';
				let html = { html: editedEmail.html };


				return (
					<div
						className="emailPreview eFrame flex-ac preview detailSummary"
						style={{
							backgroundColor: editedEmail.emailStyles ? editedEmail.emailStyles.backgroundColor : '',
						}}
					>
						<IFrame.Form
							id="builderPreviewFrameTemplate"
							className="emailPreviewWindow Frame"
							key={`campaign-${'2'}`}
							style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
							name="PreviewFrameCamp"
							config={{ url, method, data: html }}
						/>
					</div>
				)
			case 3:
				return (
					<CodeEditor
						content={editedEmail.html}
						language='html'
						style={{ minHeight: '100%' }}
						disabled={true}
						setContent={(html) => {
						}}
					/>
				)
			default:
		}
	};

	renderDetails = () => {
		const { editedEmail, totalEmails, sentCount, progressPercentage } = this.state;
		const { event, groups, sessions } = this.props;

		let sentEmailsDates = editedEmail.sentEmails.map((s) => s.sentDate);

		const recipientNames = editedEmail.recipients.groups.length > 0
			? groups.filter(group => editedEmail.recipients.groups.includes(group._id)).map(group => group.name).join(", ")
			: sessions.filter(session => editedEmail.recipients.sessions.includes(session._id)).map(session => session.name).join(", ");

		let label = editedEmail && editedEmail.recipients
			? editedEmail.recipients.attendees.length > 0
				? editedEmail.recipients.attendees.length === 1 ? "Attendee" : "Attendees" // Singular or plural attendees
				: editedEmail.recipients.sessions.length > 0
					? editedEmail.recipients.sessions.length === 1 ? "Session" : "Sessions" // Singular or plural sessions
					: editedEmail.recipients.groups.length > 0
						? editedEmail.recipients.groups.length === 1 ? "Group" : "Groups" // Singular or plural groups
						: "Recipients" // Default label if no attendees, sessions, or groups
			: "Recipients"; // Default label if editedEmail or editedEmail.recipients is falsy
		return (
			<div className='sectionBlock'>
				<h2 className='blockHeader'>Campaign Summary</h2>
				<div className="mb-20" >
					<p className="small gray"> Status</p>
					<p >{editedEmail.sent ? progressPercentage >= 100 ? 'Sent' : 'Sending' : 'Not Sent'}</p>

					<div className='userCounter mt-25'>

						<div className="flex-sb">
							<h4>Recipients</h4>
							<h4 >
								{sentCount} <span>of</span> {totalEmails}
							</h4>
						</div>

						<div className="progressBar mt-5">
							<div
								className={`progressBarFill `}
								style={{ width: `${progressPercentage}%` }}
							/>
						</div>
					</div>

				</div>
				<div className="mb-20">
					<p className="small gray"> Delivery Date & Time</p>
					<p>
						{`${editedEmail.sendDate} ${editedEmail.sendTime} ${event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}`}
					</p>
				</div>
				<div className="mb-20">
					<p className="small gray"> From Name</p>
					<p >
						{editedEmail.senderName ? editedEmail.senderName : event.eventEmailName ? event.eventEmailName : editedEmail.defaultSenderName ? editedEmail.defaultSenderName : 'Simple Events Support'}
					</p>
				</div>
				<div className="mb-20">
					<p className="small gray"> From Email</p>
					<p>
						{editedEmail.senderEmail ? editedEmail.senderEmail : event.eventEmail ? event.eventEmail : editedEmail.defaultSenderEmail ? editedEmail.defaultSenderEmail : 'support@simple.events'}
					</p>
				</div>
				<div className="mb-20">
					<p className="small gray">Subject Line</p>
					<p>{editedEmail.subject}</p>
				</div>
				<div className="mb-20">
					<p className="small gray">Preview Text</p>
					<p>{editedEmail.previewText ? editedEmail.previewText : '-'}</p>
				</div>
				<div className="mb-20">
					<p className="small gray">{label}</p>
					<p>
						{editedEmail.recipients ? editedEmail.recipients.attendees.length > 0 ? (
							`${editedEmail.recipients.attendees.length} ${editedEmail.recipients.attendees.length === 1 ? 'Attendee' : 'Attendees'}`
						) : editedEmail.recipients.sessions.length > 0 ? (
							`${recipientNames}`
						) : editedEmail.recipients.groups.length > 0 ? (
							`${recipientNames}`
						) : (
							'ff'
						) : (
							'No Recipients'
						)}
					</p>
				</div>

			</div>
		);
	};
	cancelEdit = () => {
		this.setState({ editing: false, editedEmail: { ...this.state.email } });

	};

	updateEditedEmail = (changesObj) => {
		const { editedEmail } = this.state;
		this.setState({ editedEmail: { ...editedEmail, ...changesObj }, editing: true });

	}


	saveInfo = async () => {
		const { orgId, eventId } = this.props;
		const { editedEmail, userName, domainName } = this.state;
		let concatEmail = `${userName}@${domainName}`;
		if (userName !== '' && !ValidateEmail(concatEmail)) {
			this.setState({ validEmail: false })
			return
		} else {
			if (userName == '') {
				editedEmail.senderEmail = '';
			} else {
				editedEmail.senderEmail = concatEmail;
			}
		}
		let fromEmail = editedEmail.senderEmail;
		let fromName = editedEmail.senderName;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (editedEmail.name == '') {

			requiredFields.name = false;
			valid = false

		} else {
			requiredFields.name = true

		}
		let bccEmails = editedEmail.bcc == '' ? [] : editedEmail.bcc.replace(/ /g, '').split(',');
		let validbcc = true;

		bccEmails = bccEmails.map((e) => {
			if (!ValidateEmail(e) || '') {
				validbcc = false;
			}

			if (e !== '') return e;
		});

		let validEmail = fromEmail && fromEmail !== '' ? ValidateEmail(fromEmail) : true;


		if (valid && validbcc && validEmail) {
			this.setState({ submitting: true, requiredFields: requiredFields, validBcc: validbcc, validEmail: validEmail });

			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${editedEmail._id}`, {
					name: editedEmail.name,
					senderName: fromName,
					senderEmail: fromEmail,
					subject: editedEmail.subject,
					previewText: editedEmail.previewText,
					bcc: bccEmails,
					unsubscribeEmail: editedEmail.unsubscribeEmail
				})
				.then((res) => {
					const emailObj = res.data;

					if (res.data) {
						this.updateCampaign(
							{
								name: editedEmail.name,
								senderName: fromName,
								senderEmail: fromEmail,
								subject: editedEmail.subject,
								previewText: editedEmail.previewText,
								bcc: bccEmails
							},
							() => {
								this.setState({ editing: false, submitting: false });
							}
						);
					}
				});
		} else {
			this.setState({ requiredFields: requiredFields, validBcc: validbcc, validEmail: validEmail })
		}




	};

	render() {
		const { event, eventId, orgId, keyedMetadata, keyedData } = this.props;
		const {
			email,
			loading,
			editing,
			editedEmail,
			toggleFrom,
			toggleSubject,
			toggleRecipients,
			toggleSendEmailNow,
			toggleSendTestEmail,
			selectedSection,
			toggleRenameEmail,
			toggleDeleteEmailModal,
			toggleScheduleEmailModal,
			toggleUnscheduleEmailModal,
			toggleCampaignEditorModal,
			isDedicatedEmailServerEnabled,
			toggleCreateTemplateFromCampaignModal,
			toggleBcc,
			editCount,
			toggleDuplicateCampaign,
			orgSenderEmail,
			orgSenderName,
			validBcc,
			userName,
			domainName,
			userNamePlaceholder,
			authorizedEmailDomains
		} = this.state;
		let recipients = editedEmail.recipients;

		let isRecipientsEmpty = recipients
			? recipients.attendees.length != 0 || recipients.groups.length != 0 || recipients.sessions.length != 0
				? false
				: true
			: true;


		let sendNow =
			(isDedicatedEmailServerEnabled && email.serviceUrl && !isRecipientsEmpty) ||
			(!isDedicatedEmailServerEnabled && !isRecipientsEmpty && email.subject && email.subject !== '');

		let testNow =
			(isDedicatedEmailServerEnabled && email.serviceUrl) ||
			(!isDedicatedEmailServerEnabled && (email.subject && email.subject !== ''));
		let isScheduled = editedEmail.scheduled;

		let url = !loading ? `${ENV}Organizations/${orgId}/emailrenderer?preview=true` : '';
		let method = 'post';
		let data = !loading ? { html: editedEmail.html, eventId: this.props.eventId } : { html: '', eventId: '' };


		let recipientModel = '';
		let uniqueAttendees = [];
		let recipientModelElementCount = 0;

		if (recipients) {

			if (recipients.attendees.length > 0) {
				recipientModel = 'Attendee';
				recipientModelElementCount = recipients.attendees.length;
			} else if (recipients.sessions.length > 0) {
				recipientModel = 'Session';
				recipientModelElementCount = recipients.sessions.length;
			} else if (recipients.groups.length > 0) {
				recipientModel = 'Group';
				recipientModelElementCount = recipients.groups.length;
			}

		}



		switch (recipientModel) {
			case 'Attendee':
				uniqueAttendees = [...recipients.attendees];
				break;
			case 'Group':
				let groupsToSend = [...event.groups.filter(g => recipients.groups.includes(g._id))];
				for (let gi = 0; gi < groupsToSend.length; gi++) {
					let group = groupsToSend[gi];
					uniqueAttendees = [...uniqueAttendees, ...group.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
			case 'Session':
				let sessionsToSend = [...event.sessions.filter(s => recipients.sessions.includes(s._id))];
				for (let si = 0; si < sessionsToSend.length; si++) {
					let session = sessionsToSend[si];
					if (session.isGeneral) {
						uniqueAttendees = [];
						let attendeeIds = event.attendees.map(a => a._id);
						uniqueAttendees = [...uniqueAttendees, ...attendeeIds];
						break;
					}
					uniqueAttendees = [...uniqueAttendees, ...session.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			default:
				break;
		}

		let recipientCount = uniqueAttendees.length;


		const adminEmailName = editedEmail.defaultSenderName;
		let emailNamePlaceholder = event.eventEmailName || adminEmailName;
		let fromName = editedEmail.senderName && editedEmail.senderName !== '' ? editedEmail.senderName : '';



		return (


			<div className="sectionContainer">
				{' '}
				<SectionHeader
					title={editedEmail.name}
					status={`${editedEmail.status}`}
					enableLastLink={true}
					breadcrumbs={[
						{
							name: event.name
						},
						{
							name: 'Emails'
						}
					]}
					subActions={
						!editedEmail.sent ? (
							[
								{
									onClick: this.toggleRenameEmailModal,
									label: <span><i className='las la-pen mr-10'></i>Rename Campaign</span>
								},
								{
									onClick: this.toggleDuplicateCampaignModal,
									label: <span><i className='las la-copy mr-10'></i>Duplicate Campaign</span>
								},
								{
									onClick: this.toggleCreateTemplateFromCampaignModal,
									label: <span><i className='las la-save mr-10'></i>Save as Template</span>
								},
								{
									onClick: this.toggleDeleteEmailModal,
									label: <span><i className='las la-trash mr-10'></i>Delete Campaign</span>
								}
							]
						) : (
							[
								{
									onClick: this.toggleDuplicateCampaignModal,
									label: <span><i className='las la-copy mr-10'></i>Duplicate Campaign</span>
								},
								{
									onClick: this.toggleCreateTemplateFromCampaignModal,
									label: <span><i className='las la-save mr-10'></i>Save as Template</span>
								},
								{
									onClick: this.toggleDeleteEmailModal,
									label: <span><i className='las la-trash mr-10'></i>Delete Campaign</span>
								}
							]
						)
					}
				>

					{editedEmail.sent && <TabsContainer

						tabs={[
							{ title: <span>Details</span> },
							{ title: <span>Recipients</span> },
							{ title: <span>Preview</span> },
							{ title: <span>Code</span> },

						]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/>}
					<div className='tabsContainer  mb-5 '></div>
				</SectionHeader>
				{/* <ActionButtonContainer
					mainActions={[{ onClick: this.toggleSendEmailNowModal, label: 'Send Now' }, { onClick: this.toggleScheduleEmailModal, label: 'Schedule for Later' }]}
					batchActions={[]}
					checkedEntryCount={''}
					entriesLength={''}
					uncheckEntries={() => {

					}}
				/> */}


				{editedEmail.status == 'Scheduled' ? (

					<div className='flex'>
						<div className='mb-20 flex aic'>
							<div className=' mainActionContainer mr-10' style={{}}>

								<button className={`prim`}
									onClick={this.toggleUnscheduleEmail}
								>
									Pause
								</button>
							</div>
							<p>Scheduled for {editedEmail.sendDate} {editedEmail.sendTime} {event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}</p>


						</div>
					</div>
				) : !editedEmail.sent && <Fragment>
					<div className='flex mb-20'>
						<div className='flex aic'>
							<div className=' mainActionContainer mr-10' style={sendNow ? {} : { opacity: '0.5', pointerEvents: 'none' }}>

								<button className={` prim  `}
									onClick={this.toggleSendEmailNowModal}
								>Send Now
								</button>
								<UncontrolledDropdown inNavbar>
									<DropdownToggle className="columnToggle">
										<i className="las la-angle-down" />
									</DropdownToggle>
									<DropdownMenu left className="columnDropdown">
										<div className='ph-20'>
											<h5 className="mb-10">Options</h5>
											<DropdownItem
												onClick={() => this.toggleScheduleEmailModal()}
												key={`${'schedule'}-${0}`}
											>
												Schedule for Later
											</DropdownItem>
										</div>
									</DropdownMenu>
								</UncontrolledDropdown>

							</div>


						</div>
						<div style={testNow ? { margin: 'auto' } : { margin: 'auto', color: '#D5D5D5', pointerEvents: 'none' }} className={`ml-a ml-10 ${!testNow ? "" : "c-pointer"}`} onClick={!testNow ? () => { } : this.toggleSendTestEmailModal}>
							<i className="las la-envelope-open mr-5"></i>
							Send a Test
						</div>

					</div>



				</Fragment >



				}

				{/* <div style={{ display: 'flex' }}>
					<button className={` prim  `}
						onClick={this.toggleSendEmailNowModal}
						style={sendNow ? {} : { opacity: '0.5', pointerEvents: 'none' }}
					>Send Now
					</button>
					<UncontrolledDropdown inNavbar>
						<DropdownToggle disabled={sendNow ? false : true} className="columnToggle">
							<i className="las la-angle-down" />
						</DropdownToggle>
						<DropdownMenu left className="columnDropdown">
							<div className='ph-20'>
								<h5 className="mb-10">Options</h5>
								<DropdownItem
									onClick={() => this.toggleScheduleEmailModal()}
									key={`${'schedule'}-${0}`}
								>
									Schedule for Later
								</DropdownItem>
							</div>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div> */}
				{
					!loading && <div className='sectionBody' style={{ padding: 0 }}>
						{!editedEmail.sent ? (
							<div className='blocksContainer' style={{ padding: 0 }}>
								<div className='sectionBlock mr-25 '>
									<h2 className='blockHeader' style={{ marginTop: 0 }}>To</h2>
									<InputField
										label="Who are you sending this email to?"
										required={true}
										value={`${recipientModelElementCount > 0 ? `${recipientModel == 'Attendee' ? `${recipientCount} Attendee${recipientCount > 1 ? 's' : ''}` : `${recipientCount} Attendee${recipientCount > 1 ? 's' : ''} in ${recipientModelElementCount} ${recipientModel}${recipientModelElementCount > 1 ? 's' : ''}`}` : ``}`}
										inputAction={this.toggleRecipientsModal}
										inputActionLabel={editedEmail.status !== 'Scheduled' ? recipientModelElementCount > 0 ? 'Edit Recipients' : 'Add Recipients' : null}
										disabled={true}
										inputIcon={editedEmail.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}
										onChange={(e) => {

										}}
									/>
									<InputField
										label="BCC"
										required={false}
										placeholder="Add emails separated by commas..."
										disabled={editedEmail.status == 'Scheduled' ? true : false}
										value={editedEmail.bcc}
										errorTrigger={validBcc == false}
										errorMessage={'Invalid Email detected'}
										inputIcon={editedEmail.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}
										onChange={(e) => {

											this.updateEditedEmail({
												bcc: e.target.value
											})
										}}
									/>
									<h2 className='blockHeader'>Subject</h2>
									<InputField
										label="Subject"
										required={true}
										placeholder="Write your subject line"
										disabled={editedEmail.status == 'Scheduled' ? true : false}
										value={editedEmail.subject}
										inputIcon={editedEmail.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}
										onChange={(e) => {
											this.updateEditedEmail({
												subject: e.target.value
											})
										}}
									/>
									<InputField
										label="Preview"
										required={false}
										placeholder="Write a preview message"
										disabled={editedEmail.status == 'Scheduled' ? true : false}
										value={editedEmail.previewText}
										inputIcon={editedEmail.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}
										onChange={(e) => {
											this.updateEditedEmail({
												previewText: e.target.value
											})
										}}
									/>
									<h2 className='blockHeader'>From</h2>
									<InputField
										label="Name"
										required={true}
										placeholder={fromName == '' ? emailNamePlaceholder ? emailNamePlaceholder : 'Simple Events Support' : ''}
										disabled={editedEmail.status == 'Scheduled' ? true : false}
										value={fromName}
										inputIcon={editedEmail.status == 'Scheduled' ? <i className="las la-lock lightGray" /> : ''}
										onChange={(e) => {
											this.updateEditedEmail({
												senderName: e.target.value
											})
										}}
									/>


									<div className='flex aic jcc'>
										<div className='formFieldContainer'>
											<div className="formField">
												<div className="labelContainer">
													<div className="formFieldLabel">
														Email
													</div>
													{(userName !== '' ? !ValidateEmail(`${userName}@${domainName}`) : false) && <div className="formFieldLabel error">Invalid Email</div>}
													{!authorizedEmailDomains.some(ae => ae.value == domainName) && <div className="formFieldLabel error">Domain is not registered</div>}

												</div>
												<div className='flex'>
													<div
														className={`formFieldInputContainer ${false ? 'error' : ''} `}
														style={{ borderRadius: '6px 0 0 6px' }}
													>
														<input
															key={`${'email'}`}
															value={userName}
															className="formFieldInput text-truncate"
															placeholder={userNamePlaceholder}
															disabled={editedEmail.status == 'Scheduled' ? true : false}
															onChange={(e) => {
																this.setState({ userName: e.target.value, editing: true });
															}} />
													</div>
													<div className='flex aic jcc' style={{ background: 'var(--input)', padding: '0px 5px' }}>@</div>
													<Select
														styles={{
															valueContainer: (e) => ({
																...e,
																height: 30
															}),
															singleValue: (e) => ({
																...e,
																height: 30
															}),
															indicatorSeparator: (e) => ({
																...e,
																display: 'none'
															}),
															dropdownIndicator: (e) => ({
																...e,
																height: 30,
																padding: 0,
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																width: 30
															}),
															input: (e) => ({
																...e,
																height: 30,
																lineHeight: 1.2
															}),
															option: (e) => ({
																...e,
																height: 30,
																lineHeight: 1.2
															})
														}}
														className={`editingSelectField mergedField ${false
															? 'error'
															: ''}`}
														classNamePrefix="select"
														value={{ label: domainName, value: domainName }}
														options={authorizedEmailDomains}
														onChange={(e) => {
															this.setState({ domainName: e.value, editing: true });
														}}
													/>
												</div>
											</div>
										</div>
									</div>
									{/* Unsubscribe */}
									<ToggleField
										label="Enable Unsubscribe link"
										checked={editedEmail.unsubscribeEmail}
										icons={false}
										onChange={(e) => {
											this.updateEditedEmail({ unsubscribeEmail: e.target.checked })
										}}
									/>
								</div>

								{/* 	<div className='sectionBlock mr-25'>
								<div className="emailDetailsBorder">
									<div className="cardDetailsContainer mt-20">
										<i className={`las la-check-circle ${!isRecipientsEmpty ? '#2B83F1' : ''}`} />
										<div className="detailContainer">
											<h2>To</h2>
											<h3>
												{isRecipientsEmpty ? (
													'Who are you sending this campaign to?'
												) : (
													`${recipientCount} Recipients`
												)}
											</h3>

											{editedEmail.bcc.length > 0 ? (
												<h3>
													bcc enabled {' '}
													{!isScheduled && (
														<span
															style={{ color: '#2B83F1', cursor: 'pointer' }}
															onClick={this.toggleBccModal}
														>
															- Edit
														</span>
													)}{' '}
												</h3>
											) : (
												<div>
													{!isScheduled && (
														<p>
															Add{' '}
															<span
																style={{ color: '#2B83F1', cursor: 'pointer' }}
																onClick={this.toggleBccModal}
															>
																{' '}
																bcc
															</span>{' '}
															to this email?{' '}
														</p>
													)}
												</div>
											)}
										</div>
										{isRecipientsEmpty ? (
											<button
												className='ml-a prim min'
												onClick={this.toggleRecipientsModal}
											>Add Recipients
											</button>
										) : (
											<button
												className={`ml-a neu  min ${isScheduled ? 'disabled' : ''}`}
												onClick={!isScheduled && this.toggleRecipientsModal}
											>Edit Recipients
											</button>
										)}
									</div>

									{!isDedicatedEmailServerEnabled && (
										<div className="cardDetailsContainer mt-20">
											<div>
												<i style={{ color: '#2B83F1' }} className="las la-check-circle" />
											</div>
											<div className="detailContainer">
												<h2>From</h2>
												<div >
													{' '}
													<h3>{editedEmail.senderName ? (
														editedEmail.senderName
													) : event.eventEmailName ? event.eventEmailName : orgSenderName ? (
														orgSenderName
													) : (
														'Simple Events Support'
													)}</h3>{' '}
													<h4>
														{editedEmail.senderEmail ? (
															editedEmail.senderEmail
														) : event.eventEmail ? event.eventEmail : orgSenderEmail ? (
															orgSenderEmail
														) : (
															'support@simple.events'
														)}</h4>
												</div>
											</div>
											<button
												className={`neu  min ml-a ${isScheduled ? 'disabled' : ''}`}
												onClick={!isScheduled && this.toggleFromModal}
											>Edit From
											</button>
										</div>
									)}

									{!isDedicatedEmailServerEnabled && (
										<div className="cardDetailsContainer mt-20">
											<div>
												<i
													style={{
														color: editedEmail.subject && '#2B83F1'
													}}
													className="las la-check-circle"
												/>
											</div>
											<div className="detailContainer">
												<h2>Subject</h2>
												<div className="detailDescription">
													{!editedEmail.subject && !editedEmail.previewText ? (
														<h3>Add your Subject and Preview lines.</h3>
													) : (
														<h4>
															Subject: {editedEmail.subject} <br />
															<span>Preview: {editedEmail.previewText}</span>
														</h4>
													)}
												</div>
											</div>
											{!editedEmail.subject ? (
												<button
													className={`prim min ml-a`}
													onClick={this.toggleSubjectModal}
												>Add Subject
												</button>
											) : (
												<button
													className={`ml-a neu  min ${isScheduled ? 'disabled' : ''}`}
													onClick={!isScheduled && this.toggleSubjectModal}
												>Edit Subject
												</button>
											)}
										</div>
									)}
									<div className="cardDetailsContainer mt-20" >
										<div>
											<i style={{ color: sendNow && '#2B83F1' }} className="las la-check-circle" />
										</div>
										<div className="detailContainer">
											{!isScheduled ? (
												<div>
													<h2 >Send or Schedule</h2>
													<h3 >
														{' '}
														{!isDedicatedEmailServerEnabled ? (
															'How would you like to send this campaign?'
														) : (
															'This is a dedicated server email'
														)}
														{isDedicatedEmailServerEnabled &&
															!editedEmail.serviceUrl && <div>Service Url is missing</div>}
													</h3>
													{testNow && (
														<p
															onClick={this.toggleSendTestEmailModal}
															style={{ color: '#2B83F1', cursor: 'pointer' }}
														>
															Send a test
														</p>
													)}
												</div>
											) : (
												<div>
													<h2 className=" detailHeader">Scheduled</h2>
													<h3 className="detailDescription">
														Scheduled for:{' '}
														{`${editedEmail.sendDate} at ${editedEmail.sendTime} ${moment()
															.tz(editedEmail.timezone)
															.zoneAbbr()}`}
													</h3>
												</div>
											)}
										</div>

										{!isScheduled ? (
											<div style={{ marginLeft: 'auto', opacity: sendNow ? '1' : '.5' }}>
												<button
													style={{ marginBottom: !isDedicatedEmailServerEnabled && 15 }}

													className={`prim min`}
													onClick={() => {
														sendNow && this.toggleSendEmailNowModal();
													}}
												>Review & Send
												</button>{' '}
												{!isDedicatedEmailServerEnabled && (
													<button
														className={`neu min`}
														onClick={() => {
															sendNow && this.toggleScheduleEmailModal();
														}}
													>Schedule for Later
													</button>
												)}
											</div>
										) : (
											<button
												className={`prim min ml-a`}
												onClick={() => {
													sendNow && this.toggleUnscheduleEmail();
												}}
											>Pause
											</button>
										)}
									</div>
								</div>
							</div>*/}

								<div className='sectionBlock emailDesignPreview'>
									<div
										className="designPreviewDetailsContainer flex"
									>
										<div className="detailContainer">
											<h2 className='blockHeader mt-0'>Design</h2>
											{isDedicatedEmailServerEnabled &&
												editedEmail.serviceUrl && (
													<p>
														ServiceUrl: {editedEmail.serviceUrl}
													</p>
												)}
										</div>

										{!isDedicatedEmailServerEnabled ? editedEmail.html == '' ? (
											<button
												className='neu  ml-a'
												onClick={this.toggleCampaignEditorModal}
											>
												Create Design
											</button>
										) : (
											<button
												className={`neu  ml-a ${isScheduled ? 'disabled' : ''}`}
												onClick={!isScheduled && this.toggleCampaignEditorModal}
											>
												Edit Design
											</button>
										) : (
											<div style={{ marginRight: 'auto' }} />
										)}
									</div>


									<div>
										<p style={{ color: '#999999', marginBottom: 5 }} className='small'>Email Campaign Design</p>
									</div>
									{isDedicatedEmailServerEnabled && editedEmail.serviceUrl ? (
										<div
											style={{
												maxWidth: 700,
												overflow: 'auto',
												borderRadius: 6,
												maxHeight: 900
											}}
											className="emailPreview detailSummary"
										>
											<iframe
												className="emailPreviewWindow Frame"
												scrolling="no"
												style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
												src={`${editedEmail.serviceUrl
													? editedEmail.serviceUrl.concat('?preview=true')
													: ''}`}
											/>
										</div>
									) : (
										!isDedicatedEmailServerEnabled && (
											<div
												className="emailPreview  detailSummary eFrame flex-ac preview"
												style={{
													backgroundColor: editedEmail.emailStyles ? editedEmail.emailStyles.backgroundColor : '',
												}}
											>
												<IFrame.Form
													id="builderPreviewFrameTemplate"
													className="emailPreviewWindow Frame"
													key={`campaign-${editCount}`}
													style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
													name="PreviewFrameCamp"
													config={{ url, method, data }}
												/>
											</div>
										)
									)}
								</div>
							</div>


						) : this.renderTab()}
						{toggleFrom && (
							<AddFromModal
								isOpen={toggleFrom}
								event={event}
								email={editedEmail}
								orgSenderEmail={orgSenderEmail}
								orgSenderName={orgSenderName}
								orgId={orgId}
								eventId={eventId}
								toggle={this.toggleFromModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleBcc && (
							<AddBccModal
								isOpen={toggleBcc}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								toggle={this.toggleBccModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleSubject && (
							<AddSubjectModal
								isOpen={toggleSubject}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								toggle={this.toggleSubjectModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleRecipients && (
							<AddRecipientsModal
								isOpen={toggleRecipients}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleRecipientsModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleSendEmailNow && (
							<SendEmailNowModal
								isOpen={toggleSendEmailNow}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleSendEmailNowModal}
								update={this.updateCampaign}
								runCheck={this.runCheck}
							/>
						)}
						{toggleScheduleEmailModal && (
							<ScheduleCampaignModal
								isOpen={toggleScheduleEmailModal}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleScheduleEmailModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleSendTestEmail && (
							<SendTestEmailModal
								isOpen={toggleSendTestEmail}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleSendTestEmailModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleRenameEmail && (
							<RenameEmailModal
								isOpen={toggleRenameEmail}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleRenameEmailModal}
								update={this.updateCampaign}
							/>
						)}
						{toggleDeleteEmailModal && (
							<DeleteEmailModal
								isOpen={toggleDeleteEmailModal}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleDeleteEmailModal}
								update={this.updateCampaign}
								updateDeleteCampaign={this.props.updateDeleteCampaign}
							/>
						)}
						{toggleUnscheduleEmailModal && (
							<UnscheduleEmailModal
								isOpen={toggleUnscheduleEmailModal}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								event={event}
								toggle={this.toggleUnscheduleEmail}
								update={this.updateCampaign}
							/>
						)}









						{toggleCampaignEditorModal && (
							<EmailEditor
								isOpen={toggleCampaignEditorModal}
								emailTemplate={editedEmail}
								orgId={orgId}
								eventId={eventId}
								keyedMetadata={keyedMetadata}
								keyedData={keyedData}
								event={event}
								branding={event.branding}
								url={`${ENV}Organizations/${orgId}/emailrenderer`}
								method={method}
								postData={data}
								toggle={this.toggleCampaignEditorModal}
								update={this.updateCampaignHtml}
								cancelEdit={this.cancelEdit}
								links={{
									getAssets: `Organizations/${orgId}/events/${eventId}/assets`,
									addAssets: `Organizations/${orgId}/events/${eventId}/assets`,
								}}
							/>
						)}
						{toggleCreateTemplateFromCampaignModal && (
							<CreateTemplateFromCampaignModal
								isOpen={toggleCreateTemplateFromCampaignModal}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								keyedMetadata={keyedMetadata}
								keyedData={keyedData}
								event={event}
								toggle={this.toggleCreateTemplateFromCampaignModal}
								update={this.updateCampaign}
								addTemplate={this.props.addTemplate}
							/>
						)}
						{toggleDuplicateCampaign && (
							<DuplicateCampaignModal
								isOpen={toggleDuplicateCampaign}
								email={editedEmail}
								orgId={orgId}
								eventId={eventId}
								keyedMetadata={keyedMetadata}
								keyedData={keyedData}
								event={event}
								toggle={this.toggleDuplicateCampaignModal}
								update={this.updateCampaign}
							/>
						)}
					</div>
				}
				<SectionFooter updatedAt={email.updatedAt} cancelEdit={this.cancelEdit} saveEdit={this.saveInfo} editing={editing} />
				<LoadingWizard text="Loading Email Campaign" loading={loading} />

			</div >

		);
	}
}

export default withRouter(EventCampaign);
