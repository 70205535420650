import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import InputField from '../inputs/inputField';

class ActionButtonContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchTerm: ''
		};
	}
	render() {
		const {
			mainActions,
			batchActions,
			checkedEntryCount,
			entriesLength,
			uncheckEntries,
			searchEnabled,
			enabledViewSelection,
			isGridLayout,
			toggleLayout,
			disableNueFooter,
			secondaryActions,
			viewPanelActions,
			downloadFunction
		} = this.props;
		const { searchTerm } = this.state;
		return (<Fragment>
			{((mainActions &&
				mainActions.length > 0) || searchEnabled || enabledViewSelection) && <div className="headerHeight flex aic">
					{mainActions &&
						mainActions.length > 0 && (
							<div className="mainActionContainer mr-10">
								{mainActions[0].type == 'link' ? (
									<Link to={mainActions[0].target} className="mainAction">
										{mainActions[0].label}
									</Link>
								) : (
									<div className="mainAction" onClick={mainActions[0].onClick}>
										{mainActions[0].label}
									</div>
								)}
								{mainActions.length > 1 && (
									<UncontrolledDropdown inNavbar>
										<DropdownToggle className="columnToggle">
											<i className="las la-angle-down" />
										</DropdownToggle>
										<DropdownMenu left className="columnDropdown">
											<div className='ph-20'>
												<h5 className="mb-10">Options</h5>
												{mainActions.map((action, index) => {
													if (index > 0) {
														return action.type == 'link' ? (
															<DropdownItem key={`${action.type}-${index}`}>

																<Link to={action.target} className="supAction2">
																	{action.label}
																</Link>
															</DropdownItem>
														) : (
															<DropdownItem
																onClick={() => action.onClick()}
																key={`${action.type}-${index}`}
															>
																{action.label}
															</DropdownItem>
														);
													}
												})}
											</div>
										</DropdownMenu>
									</UncontrolledDropdown>
								)}

							</div>
						)}
					{secondaryActions && secondaryActions.length > 0 && secondaryActions.map((action, index) => {
						return action;
					})}
					{downloadFunction && (<div id="downloadTable" className='ml-a c-pointer fs-20' onClick={downloadFunction}>
						<i className="las la-download" />
						<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
					</div>)}

					{/* searchEnabled && (
						<InputField
							style={{ maxWidth: '300px', margin: '0 5px' }}
							placeholder="Search table entries"
							value={searchTerm}
							onChange={(e) => {
								clearTimeout(this.searchTimeout);
								this.setState({ searchTerm: e.target.value });
								this.searchTimeout = setTimeout(() => this.props.searchEntries(searchTerm), 1000);
							}}
							inputIcon={searchTerm == '' ?
								<i className="las la-search" /> :
								<i
									onClick={() => this.setState({ searchTerm: '', searching: false })}
									className="las la-times-circle"
									style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
								/>}
						/>
					) */}



					{searchEnabled && (<InputField
						classes="m-0 viewSearch"
						placeholder="Search "
						clickIcon={true}
						required={true}
						value={searchTerm}
						onChange={(e) => {
							clearTimeout(this.searchTimeout);
							this.setState({ searchTerm: e.target.value },
								() => {
									this.searchTimeout = setTimeout(() => this.props.searchEntries(this.state.searchTerm), 1000);
								});

						}}
						prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
						inputIcon={searchTerm != '' &&
							<i
								onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.props.searchEntries(''))}
								//onClick={() => this.setState({ searchTerm: '', searching: false })}
								className="las la-times-circle"
								style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
							/>}
					/>)}
					{enabledViewSelection && (
						<div className="sectionOptionsContainer">

							{viewPanelActions && viewPanelActions.length > 0 &&
								viewPanelActions.map((sa, index) => {
									return sa.iconClass && <><button className='p-7 mr-10' onClick={sa.onClick}><i id={`viewAction-${index}`} className={` ${sa.iconClass}`} />
									</button>
										<UncontrolledTooltip target={`viewAction-${index}`} placement="left">{sa.label}</UncontrolledTooltip></>
								})
							}
							{!isGridLayout ? (
								<div id="gridLayout" className="c-pointer fs-20">
									<button className="p-7" onClick={() => toggleLayout(true)}>
										<i className={`las la-border-all`} />
									</button>
									<UncontrolledTooltip target="gridLayout" placement="left">Grid</UncontrolledTooltip>
								</div>
							) : (
								<div id="gridLayout" className="c-pointer fs-20">
									<button className="p-7" onClick={() => toggleLayout(false)}>
										<i className={`las la-th-list`} />
									</button>
									<UncontrolledTooltip target="gridLayout" placement="left">List</UncontrolledTooltip>
								</div>
							)}
						</div>
					)}
				</div>}


			{batchActions &&
				batchActions.length > 0 && (
					<div className={`sectionFooter ${disableNueFooter ? 'noNue' : ''}  ph-20 ${batchActions &&
						batchActions.length > 0 &&
						checkedEntryCount > 0 ? 'editing' : ''}`} >
						<div className='flex aic jcsb'><p style={{ width: 200 }} className='small gray mb-0 mr-25'>{checkedEntryCount} selected out of {entriesLength} record{entriesLength == 1 ? '' : 's'}</p>
							<button onClick={uncheckEntries} className={`p-7 mr-25 ${checkedEntryCount > 0 ? '' : 'invisible'}`}><i className="las la-times" style={{ margin: 0 }} /></button>
						</div>
						<div className="flex-ac footerActionContainer">
							<p className='mb-0 mr-25'>|</p>
							{batchActions.map((action) => (
								<button
									className={` neu ${action.class} mr-25`}
									onClick={(e) => {
										action.onClick();
										e.currentTarget.blur();
									}
									}
								>
									{action.iconClass &&
										action.iconClass != '' && <i className={`${action.iconClass} mr-10`} />}
									{action.pluralLabel ? checkedEntryCount > 1 ? (
										action.pluralLabel
									) : (
										action.label
									) : (
										action.label
									)}
								</button>
							))}
						</div>
					</div>
				)}
		</Fragment>);
	}
}

export default ActionButtonContainer;
