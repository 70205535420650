import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';

class BasicSectionHeader extends Component {
	render() {
		const {
			subtitle,
			title,
			breadcrumbs,
			description,
			editing,
			cancelEdit,
			saveEdit,
			updatedAt,
			mainActions,
			batchActions,
			additionalActions,
			enableLastLink,
			titleIcon,
			checkedEntryCount,
			entriesLength,
			uncheckEntries,
			subActions,
			classes,
			style
		} = this.props;

		return (
			<div style={style} className={`sectionHeader ${classes ? classes : ''}`}>
				<div className="sectionTitleBar">
					<div className="sectionTitle">
						{((breadcrumbs && breadcrumbs.length > 0) || subtitle) && (
							<div className="subtitle">
								{breadcrumbs &&
									breadcrumbs.length > 0 && (
										<div className="breadCrumbContainer">
											{breadcrumbs.map((b, i) => (
												<div key={i} className="breadCrumb flex-ac">
													{i > 0 && (
														<div className="breadCrumbSeparator flex-ac">
															<span style={{ fontSize: 10 }} >/</span>
														</div>
													)}
													{i < breadcrumbs.length - (!enableLastLink ? 1 : 0) && (
														<Link to={b.link} className="breadCrumbText calibreRegular fs-21">
															{b.name}
														</Link>
													)}
													{i == breadcrumbs.length - 1 &&
														!enableLastLink && (
															<div className="breadCrumbText  calibreSemiBold fs-21">
																{b.name}
															</div>
														)}
												</div>
											))}
										</div>
									)}
								{subtitle}
							</div>
						)}
						<div className="flex-ac">
							{titleIcon && <div className="profileIconContainer mr-10">{titleIcon}</div>}
							{title && <h1 className="sectionName">{title}</h1>}
						</div>
						{description && <div className="sectionDescription">{description}</div>}
					</div>
					<div className="ml-a">
						{editing ? (
							<div className="actionButtonContainer">
								<div onClick={cancelEdit} className="actionButton actionOutlineGray red mr-5">
									<div >Discard changes</div>
								</div>{' '}
								<div onClick={saveEdit} className="actionButton actionSave">
									<div className="">Save changes</div>
								</div>{' '}
							</div>
						) : (
							updatedAt && (
								<div className="lastSaveText calibreRegular fs-16">
									Last saved: {moment(updatedAt).format('MM/DD/YYYY hh:mm:ssa')}
								</div>
							)
						)}
						{subActions && (
							<UncontrolledDropdown className="ml-a" style={{ float: 'right', marginTop: 20 }}>
								<DropdownToggle outline className="las la-ellipsis-h c-pointer">
									<i className="las la-ellipsis-h" />
								</DropdownToggle>
								<DropdownMenu right className="calibreSemiBold">
									{subActions.map((sa, index) => {
										return sa.type == 'link' ? (
											<DropdownItem>
												<Link to={sa.target} className="">
													<div className="">
														{sa.iconClass && <i className={`mr-10 ${sa.iconClass}`} />}
														{sa.label}
													</div>
												</Link>
											</DropdownItem>
										) : (
											<DropdownItem>
												<div className="" onClick={sa.onClick}>
													<div className="">
														{sa.iconClass && <i className={`mr-10 ${sa.iconClass}`} />}
														{sa.label}
													</div>
												</div>
											</DropdownItem>
										);
									})}
								</DropdownMenu>
							</UncontrolledDropdown>
						)}
					</div>
				</div>

				{(mainActions || batchActions || additionalActions) && (
					<div className="actionButtonContainer mt-10">
						{mainActions &&
							mainActions.length > 0 && (
								<div
									style={{ opacity: !mainActions[0].disabled ? '1' : '.5' }}
									className="mainActionContainer mr-10"
								>
									{mainActions[0].type == 'link' ? (
										<Link to={mainActions[0].target} className="mainAction">
											<div >{mainActions[0].label}</div>
										</Link>
									) : (
										<div
											className="mainAction"
											onClick={!mainActions[0].disabled && mainActions[0].onClick}
										>
											<div >{mainActions[0].label}</div>
										</div>
									)}
									{mainActions.length > 1 && (
										<UncontrolledDropdown inNavbar>
											<DropdownToggle className="columnToggle">
												<i className="las la-angle-down" />
											</DropdownToggle>
											<DropdownMenu right className="columnDropdown p-10">
												{mainActions.map((action, index) => {
													if (index > 0) {
														return action.type == 'link' ? (
															<Link to={action.target} className="supAction">
																<div className="calibreRegular fs-16">{action.label}</div>
															</Link>
														) : (
															<div className="supAction" onClick={action.onClick}>
																<div className="calibreRegular fs-16">{action.label}</div>
															</div>
														);
													}
												})}
											</DropdownMenu>
										</UncontrolledDropdown>
									)}
								</div>
							)}

						{batchActions &&
							batchActions.length > 0 && (
								<div className="selectedContainer m-0">
									<div className=" mr-10">
										{checkedEntryCount} selected out of {entriesLength} record{entriesLength > 1 ? 's' : ''}
									</div>
									{checkedEntryCount > 0 && (
										<div
											className="actionButton actionFade"
											style={{ width: 30, height: 30, padding: 0 }}
											onClick={uncheckEntries}
										>
											<i className="las la-times" style={{ margin: 0 }} />
										</div>
									)}
									{checkedEntryCount > 0 && (
										<div className="flex-ac">
											<div className="breakBar">|</div>
											{batchActions.map((action) => (
												<div
													className={`actionButton ${action.class} mr-10`}
													onClick={action.onClick}
												>
													{action.iconClass &&
														action.iconClass != '' && <i className={action.iconClass} />}
													<div>{action.label}</div>
												</div>
											))}
										</div>
									)}
								</div>
							)}

						{additionalActions &&
							additionalActions.length > 0 && (
								<div className="additionalActions">{additionalActions.map((action) => action)}</div>
							)}
					</div>
				)}
			</div>
		);
	}
}

export default BasicSectionHeader;
