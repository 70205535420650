import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import API from '../../../utils/API';
import QRCode from 'qrcode.react';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import ToggleField from '../../../components/inputs/toggleField';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import FieldModal from '../../../components/modals/fieldModal';
import ImportScannablesModal from './modals/importScannables';
import DeleteScannablesModal from './modals/deleteScannables';
import XLSX from 'xlsx';
import moment from 'moment';
import { saveAs } from 'file-saver';

const contentTypeOptions = [
	{
		label: 'Link',
		value: 'link'
	}
];
class EventScannables extends Component {
	constructor(props) {
		super(props);

		const columns = {
			name: 'Name',
			link: 'QR Link',
			url: 'Destination URL',
			description: 'Associated Points',
			active: 'Active'
		};
		const columnTypes = {
			name: 'text',
			link: 'text',
			url: 'text',
			description: 'text',
			active: 'boolean'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			points: [],
			scans: [],
			createScannableOpen: false,
			showQRModal: false,
			showRemoveScanModal: false,
			markedScan: null,
			activeScan: null,
			submitting: false,
			requiredFields: {
				name: false,
				contentType: false,
				url: false,
			},
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedScans: [],
			toggleDeleteScannables: false,
			toggleImportScannables: false
		};
	}

	componentDidMount() {
		const { orgId, eventId } = this.props;
		this.props.setSelectedTab(0);

		this.setState({ loading: true });
		API().get(`Organizations/${orgId}/events/${eventId}/interactions`).then((res) => {
			if (res.data) {
				this.setState({ points: res.data.points, scans: res.data.scannables, loading: false });
			}
		});
	}

	toggleShowQR = (scan) => {
		this.setState({
			showQRModal: !this.state.showQRModal,
			markedScan: scan ? scan._id : null,
			activeScan: scan ? { ...scan } : null,
			requiredFields: {
				name: false,
				contentType: false,
				url: false,
			}
		});
	};
	toggleRemoveScan = (scan) => {
		this.setState({
			showRemoveScanModal: !this.state.showRemoveScanModal,
			markedScan: scan ? scan._id : null,
			activeScan: scan ? scan : null
		});
	};

	removeScan = (scanId) => {
		const scans = this.state.scans.filter((s) => s._id !== scanId);
		this.setState({ scans: scans });
	};

	toggleCreateScannable = () => {
		this.setState({
			createScannableOpen: !this.state.createScannableOpen, activeScan: {
				name: '',
				contentType: '',
				url: '',
				pointCategory: '',
				successMessage: '',
				errorMessage: '',
				active: true
			},
			requiredFields: {
				name: false,
				contentType: false,
				url: false,
			}
		});
	}


	updateScannable = () => {
		const { orgId, eventId } = this.props;
		const { activeScan } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!activeScan[key] || activeScan[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			API().patch(`Organizations/${orgId}/events/${eventId}/scannables/${activeScan._id}`, activeScan).then((res) => {
				if (res.data) {
					const scans = this.state.scans;
					const index = scans.findIndex(s => s._id == activeScan._id);
					scans[index] = { ...activeScan };
					this.setState({ scans: scans }, this.toggleShowQR);
				}
			});
		} else {

			this.setState({ submitting: false, requiredFields: requiredFields });
		}

	}

	deleteScannables = (scannables, callback) => {

		let scans = this.state.scans.filter((scan) => !scannables.includes(scan._id));
		this.setState({ scans: scans, checkedScans: {} }, () => {

			callback && callback();

		});
	}

	createScannable = (callback) => {

		const { orgId, eventId } = this.props;
		const { activeScan } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!activeScan[key] || activeScan[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			API().post(`Organizations/${orgId}/events/${eventId}/scannable`, activeScan).then((res) => {
				if (res.data) {
					const scans = this.state.scans;
					scans.push(res.data);
					this.setState({ scans: scans }, this.toggleCreateScannable);
				}
			});
		} else {

			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	}

	updateScannables = (scannables, callback) => {
		const { scans } = this.state;
		scans.push(...scannables);
		this.setState({ scans: scans }, () => {
			callback && callback();
		});


	}


	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	sortScans = () => {
		const {
			sortDesc,
			selectedColumnSort, scans
		} = this.state;
		let scansData = scans;
		if (selectedColumnSort) {
			scansData = scansData.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			scansData = sortDesc ? scansData.reverse() : scansData;
		}

		scansData = scansData.map((scan) => {

			const point = this.state.points.find((p) => p._id == scan.pointCategory);
			const url = this.props.organization.hostname ? `https://${this.props.organization.hostname}/scan/${scan._id}` : `https://${this.props.organization.identifier}.simple.events/scan/${scan._id}`;
			return {
				...scan,
				description: point ? point.description : '',
				link: url
			};
		});


		return scansData;
	}

	toggleDeleteScannables = () => {
		this.setState({ toggleDeleteScannables: !this.state.toggleDeleteScannables });
	};

	toggleImportScannables = () => {
		this.setState({ toggleImportScannables: !this.state.toggleImportScannables });
	};



	updatedCheckedScans = (checked) => {
		this.setState({ checkedScans: checked });
	};

	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};

	downloadReport = async () => {
		const { activeView, customViews, mainView, organizedEntries, allEntries } = this.state;

		let columns = this.state.columns;
		const entries = this.sortScans();
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (this.state.columnTypes[hc] === 'boolean' || this.state.columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});

		const data = {
			headerRow: headers,
			dataRows: cleanData
		};
		const reportDetails = { Title: 'QR Codes Report', Subject: 'QR Codes Report' };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${moment().format('LLL')}.xlsx`
		);
	};

	render() {
		const { scans, showQRModal, createScannableOpen, activeScan, markedScan, submitting, requiredFields, checkedScans, normalizedColumns, toggledColumns, toggleDeleteScannables, toggleImportScannables } = this.state;
		const pointOptions = this.state.points.map(p => ({ label: p.actionName, value: p._id }));
		const sortedScans = this.sortScans();
		const url = markedScan && (this.props.organization.hostname ? `https://${this.props.organization.hostname}/scan/${markedScan._id}` : `https://${this.props.organization.identifier}.simple.events/scan/${markedScan._id}`);
		return (
			<div className="sectionContainer">
				<div className="sectionBody posRel p-0">
					<ActionButtonContainer
						downloadFunction={this.downloadReport}
						mainActions={[
							{
								type: 'button',
								label: 'Add QR Code',
								onClick: this.toggleCreateScannable
							},
							{
								type: 'button',
								label: 'Import QR Codes',
								onClick: this.toggleImportScannables
							}
						]} />
					<ViewsTable
						title={this.props.title}
						data={sortedScans}
						columns={normalizedColumns}
						toggledColumns={toggledColumns}
						checked={checkedScans}
						classes="nested"
						checkRow={(entryId) => {
							const checked = this.state.checkedScans;
							checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
							this.updatedCheckedScans(checked);
							this.setState({
								checkedScans: checked
							});
						}}

						//mainActions={mainActions}
						checkAllRows={(entryIds) => {
							let checked = this.state.checkedScans;
							entryIds.length > 0
								? entryIds.forEach((entryId) => {
									checked[entryId] = true;
								})
								: (checked = {});
							this.updatedCheckedScans(checked);

							this.setState({
								checkedScans: checked
							});
						}}
						mainColumnActive={false}

						onClick={(entryId) => {
							const markedScan = this.state.scans.find((scan) => scan._id == entryId);
							this.toggleShowQR(markedScan);
						}}

					/>
					<ActionButtonContainer
						batchActions={
							[
								{
									type: 'button',
									label: 'Delete QR Code',
									onClick: this.toggleDeleteScannables,
									iconClass: 'las la-trash-alt',
									class: 'neu danger'
								}
							]}
						checkedEntryCount={Object.keys(checkedScans).length}
						entriesLength={scans.length}
						disableNueFooter={true}
						uncheckEntries={() => {
							this.setState({ checkedScans: {} });
						}}
					/>
				</div>

				{toggleDeleteScannables &&
					<DeleteScannablesModal
						isOpen={toggleDeleteScannables}
						toggle={this.toggleDeleteScannables}
						checkedScannables={checkedScans}
						deleteScannables={this.deleteScannables}
						eventId={this.props.eventId}
						orgId={this.props.orgId}

					/>}
				{createScannableOpen &&
					<div>
						<FieldModal
							size="small"
							isOpen={createScannableOpen}
							modalTitle={"New QR Code"}
							bodyHeaderText={''}
							bodyDescription={''}
							bodyContent={() => {
								return (
									<div className='w-100'>
										<InputField label="Name" value={activeScan.name} onChange={(e) => {
											activeScan.name = e.target.value;
											this.setState({ activeScan: activeScan })

										}} required={true}
											errorTrigger={requiredFields.name} />
										<SelectField label="Content Type" options={contentTypeOptions}
											value={contentTypeOptions.find(p => p.value == activeScan.contentType)}
											onChange={(e) => {
												activeScan.contentType = e.value;
												this.setState({ activeScan: activeScan })
											}}
											required={true}
											errorTrigger={requiredFields.contentType}
										/>
										<InputField label="Destination URL" value={activeScan.url}
											onChange={(e) => {
												activeScan.url = e.target.value;
												this.setState({ activeScan: activeScan })
											}}
											required={true}
											errorTrigger={requiredFields.url}
										/>
										<SelectField label="Associated Points"
											options={pointOptions}
											value={pointOptions.find(p => p.value == activeScan.pointCategory)}
											onChange={(e) => {
												activeScan.pointCategory = e.value;
												this.setState({ activeScan: activeScan })
											}}

										/>
										<InputField label="Points Redeemed Success Message" value={activeScan.successMessage}
											onChange={(e) => {
												activeScan.successMessage = e.target.value;
												this.setState({ activeScan: activeScan })
											}}
										/>
										<InputField label="Points Already Redeemed Message" value={activeScan.errorMessage}
											onChange={(e) => {
												activeScan.errorMessage = e.target.value;
												this.setState({ activeScan: activeScan })
											}}
										/>

										<ToggleField label="Active" checked={activeScan.active}
											icons={false}
											onChange={(e) => {
												activeScan.active = e.target.checked;
												this.setState({ activeScan: activeScan })
											}} />

									</div>)
							}}
							toggle={this.toggleCreateScannable}
							actionButtonLabel={!submitting ? 'Submit' : 'Creating...'}
							actionButton={() => this.createScannable()}
							actionButtonDisabled={submitting}
						></FieldModal>

					</div>
				}

				{toggleImportScannables &&
					<ImportScannablesModal
						isOpen={toggleImportScannables}
						toggle={this.toggleImportScannables}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
						updateScannables={this.updateScannables}
					/>
				}
				{showQRModal &&
					<div>
						<FieldModal
							size='medium'
							isOpen={showQRModal}
							modalTitle={"Edit QR Code"}
							overflowHidden={true}
							bodyHeaderText={''}
							bodyDescription={''}
							bodyContent={() => {
								return (
									<div style={{
										overflowX: 'hidden',
										height: "calc(100vh - 350px)"
									}}>
										<div className='w-100'>

											<InputField label="Name" value={activeScan.name} onChange={(e) => {
												activeScan.name = e.target.value;
												this.setState({ activeScan: activeScan })
											}}
												required={true}
												errorTrigger={requiredFields.name}
											/>
											<SelectField label="Content Type" options={contentTypeOptions}
												value={contentTypeOptions.find(p => p.value == activeScan.contentType)}
												onChange={(e) => {
													activeScan.contentType = e.value;
													this.setState({ activeScan: activeScan })
												}}
												required={true}
												errorTrigger={requiredFields.contentType}
											/>
											<InputField label="Destination URL" value={activeScan.url}
												onChange={(e) => {
													activeScan.url = e.target.value;
													this.setState({ activeScan: activeScan })
												}}
												required={true}
												errorTrigger={requiredFields.url}
											/>
											<SelectField label="Associated Points"
												options={pointOptions}
												value={pointOptions.find(p => p.value == activeScan.pointCategory)}
												onChange={(e) => {
													activeScan.pointCategory = e.value;
													this.setState({ activeScan: activeScan })
												}}

											/>
											<InputField label="Points Redeemed Success Message" value={activeScan.successMessage}
												onChange={(e) => {
													activeScan.successMessage = e.target.value;
													this.setState({ activeScan: activeScan })
												}}
											/>
											<InputField label="Points Already Redeemed Message" value={activeScan.errorMessage}
												onChange={(e) => {
													activeScan.errorMessage = e.target.value;
													this.setState({ activeScan: activeScan })
												}}
											/>
											<ToggleField label="Active" checked={activeScan.active}
												icons={false}
												onChange={(e) => {
													activeScan.active = e.target.checked;
													this.setState({ activeScan: activeScan })
												}} />
										</div>
										<div className='mb-20'>

											<div className="labelContainer" >
												<div className="formFieldLabel">
													QR Code
												</div>
											</div>
											<QRCode
												width={"100%"}
												renderAs="svg"
												size={512}
												value={
													markedScan &&
													url
												}
											/>
										</div>
									</div>)
							}}
							toggle={this.toggleShowQR}
							actionButtonLabel={!submitting ? 'Submit' : 'Updating...'}
							actionButton={() => this.updateScannable()}
							actionButtonDisabled={submitting}
						></FieldModal>


					</div>
				}
			</div>
		);
	}
}

export default EventScannables;
