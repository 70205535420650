import React, { Fragment } from 'react';

// Importing utility functions and components
import API from '../../../../utils/API';
import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import { withRouter } from 'react-router-dom';

class DuplicateEmailModal extends React.Component {
    constructor(props) {
        super(props);
        // Initial state of the component
        this.state = {
            name: '',
            submitting: false,
            requiredFields: { name: false }
        };
    }

    // Handler to update the state when the name input field changes
    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    // Function to handle the email duplication process
    duplicateEmail = async () => {
        this.setState({ submitting: true });
        const { name } = this.state;
        const { eventId, orgId, email, update, toggle, emailId, programId } = this.props;
        const requiredFields = this.state.requiredFields;
        let valid = true;

        // Check if the name field is filled
        if (!name) {
            requiredFields.name = true;
            valid = false;
        }

        // If the email is valid, proceed with duplication
        if (valid) {
            this.props.duplicateEmail(emailId, name, () => {
                // Redirect to the emails page after duplication
                this.props.history.push(`/${orgId}/programs/${programId}/emails`);
                toggle();
            });
        } else {
            this.setState({ submitting: false, requiredFields: requiredFields });
        }
    };

    render() {
        const { isOpen, toggle } = this.props;
        const { submitting, name, requiredFields } = this.state;
        return (
            <div>
                <FieldModal
                    size="medium"
                    isOpen={isOpen}
                    modalTitle={"Duplicate Email"}
                    bodyHeaderText={'Please enter name for your duplicated email.'}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <InputField
                                    label="Name"
                                    value={name}
                                    required={true}
                                    errorTrigger={requiredFields.name}
                                    onChange={this.handleNameChange}
                                />
                            </div>)
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Duplicate' : 'Duplicating...'}
                    actionButton={() => this.duplicateEmail()}
                    actionButtonDisabled={submitting}
                />
            </div>
        );
    }
}

export default withRouter(DuplicateEmailModal);
