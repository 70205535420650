import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class AddFromTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fromName:
				this.props.template.senderName && this.props.template.senderName !== ''
					? this.props.template.senderName
					: '',
			fromEmail:
				this.props.template.senderEmail && this.props.template.senderEmail !== ''
					? this.props.template.senderEmail
					: '',

			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {}
		};
	}

	handleFromNameChange = (e) => {
		this.setState({
			fromName: e.target.value
		});
	};
	handleFromEmailChange = (e) => {
		this.setState({
			fromEmail: e.target.value
		});
	};

	updateFrom = async () => {
		this.setState({ submitting: true });
		const { fromName, fromEmail } = this.state;
		const { eventId, orgId, template, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (valid) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/template/${template._id}`, {
					name: template.name,
					senderName: fromName,
					senderEmail: fromEmail
				})
				.then((res) => {
					const emailObj = res.data;

					if (res.data) {
						update(
							{
								name: template.name,
								senderName: fromName,
								senderEmail: fromEmail
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle, orgSenderEmail, orgSenderName } = this.props;
		const { fromEmail, fromName, submitting } = this.state;

		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add From"}
				bodyHeaderText={'Who is sending this campaign?'}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="From Name"
								required={false}
								placeholder={fromName == '' ? orgSenderName ? orgSenderEmail : 'Simple Events Support' : ''}
								value={fromName}
								onChange={this.handleFromNameChange}
							/>
							<InputField
								label="From Email"
								required={false}
								placeholder={fromName == '' ? 'support@simple.events' : ''}
								value={fromEmail}
								onChange={this.handleFromEmailChange}
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
				actionButton={() => this.updateFrom()}
				actionButtonDisabled={submitting}


			></FieldModal>

		);
	}
}

export default AddFromTemplateModal;
