import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import FormBuilder from "../../../components/forms/formBuilder";

class FormBuilderModal extends Component {
    render() {
        const { isOpen, toggle, editedForm, saveForm, editing, cancelEdit } = this.props;
        return <Modal isOpen={isOpen} toggle={toggle}>
            <div className='modalHeader'>
                <div className="headerContainer">

                    <button className="neu min" onClick={() => {
                        if (editing) {
                            if (window.confirm('Are you sure you want to discard your changes?')) {
                                cancelEdit();
                                toggle();
                            }
                        } else {
                            toggle();
                        }
                    }}>{editing ? "Discard & Close" : "Close"}</button>
                    <div style={{ textAlign: 'center' }}>
                        <h2>Form Builder</h2>
                        <h3>{editedForm.name}</h3>
                    </div>
                    <button className={`neu min ${!editing ? 'disabled' : 'prim'}`}
                        onClick={saveForm}>Save</button>
                </div>
            </div>
            <ModalBody className="modalBody">
                <FormBuilder {...this.props} />
            </ModalBody>
        </Modal>
    }
}

export default FormBuilderModal;