import React, { Component, Fragment } from 'react';
import API from '../../utils/API';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import LoadingWizard from '../spinner/wizard';
import InputField from '../inputs/inputField';
import { Draggable, Droppable } from 'react-beautiful-dnd';



class ComponentLibrary extends Component {
	constructor(props) {
		super(props);
		const componentLists = {
			platform: {
				title: 'Platform',
				description: 'These components are built into the platform and are available to all users.',
				components: [{}]
			},
			custom: {
				title: 'Custom',
				description: 'These components are built by you and are available to all users.',
				components: [{}]
			},
			global: {
				title: 'Global',
				description: 'These components are global, their values are synced accross all pages where they are utilized.',
				components: [{}]
			}
		}
		this.state = {
			componentLists: componentLists,
			searchInput: '',
			partials: [],
			customPartials: [],
			globalPartials: [],
			loading: true,
			selectedPartials: [],
			tabSelected: 'platform',
			isCustom: false,
			isGlobal: false
		};
	}

	componentDidMount() {
		const { orgId, eventId, event, isAccountPage } = this.props;
		API().get(`Organizations/${orgId}/partialsLibrary/${eventId ? eventId : isAccountPage ? 'account' : 'false'}`).then((res) => {
			if (res.data) {
				const updatedComponentLists = {
					platform: {
						...this.state.componentLists.platform,
						components: [{
							category: 'General',
							blocks: res.data.library.map((partial) => {
								if (partial.file == 'v1/banner') {
									partial.parameters = partial.parameters.map((param) => {
										if (param.key == 'sectionBackground') {
											param.value = (event && event.branding && event.branding.headerImage) || '';
										}
										return param;
									})

								}
								return partial;
							})
						}]
					},
					custom: {
						...this.state.componentLists.custom,
						components: [{
							category: 'General',
							blocks: res.data.customLibrary
						}]
					},
					global: {
						...this.state.componentLists.global,
						components: [{
							category: 'General',
							blocks: res.data.globalLibrary
						}]
					}
				};
				this.setState({
					componentLists: updatedComponentLists,
					partials: res.data.library,
					customPartials: res.data.customLibrary,
					globalPartials: res.data.globalLibrary,
					loading: false
				});
			}
		});
	}

	addComponents = () => {
		/* this.props.updatePageComponents(this.state.selectedPartials, this.state.isCustom, this.state.isGlobal);
		this.setState({ selectedPartials: [] }); */
		const { selectedPartials, isCustom, isGlobal } = this.state;
		selectedPartials.forEach((partial) => {
			this.props.addPartial(partial);
		});
		this.setState({ selectedPartials: [] });
	};

	togglePartialSelect = (partial, tab) => {
		let partialSelect = this.state.selectedPartials;
		const index = partialSelect.findIndex((p) => p.id == partial._id);
		if (index >= 0) {
			partialSelect.splice(index, 1);
		} else {
			const newPartial = JSON.parse(JSON.stringify(partial));
			newPartial._id = `_${partial._id}`;
			newPartial.id = partial._id;
			if (tab === 2) newPartial.isGlobal = true;
			//newPartial.id = partial._id;
			partialSelect.push(newPartial);
		}
		this.setState({ selectedPartials: partialSelect });
	};

	render() {
		const { backToComponents, disableTabs, togglePanel } = this.props;
		const { componentLists, partials, selectedPartials, tabSelected, customPartials, globalPartials, searchInput, loading } = this.state;
		return (
			<Fragment>
				{/* <div className='flex jcsb aic mt-10'>
					<h2>Add Components</h2>
					<button onClick={togglePanel}><i className='las la-times'></i></button>
				</div> */}
				{/* <p className=' mb-20'>Drag components to the components panel or directly into the canvas to add component</p> */}

				{!loading ? <div className='componentLibrary '>
					{!disableTabs && (
						<div className="componentTabs mb-20">
							{Object.keys(componentLists).map((key, index) => {
								const { title } = componentLists[key];
								return (<h4 className={`componentTab ${tabSelected === key ? 'selected' : ''}`}
									onClick={() =>
										this.setState({
											tabSelected: key,
											searchInput: ''
										})}
								>
									{title}
								</h4>);
							})}
						</div>
					)}
					<p className='mb-10'>{componentLists[tabSelected].description}</p>
					<InputField
						placeholder="Search components"
						clickIcon={true}
						value={searchInput}
						onChange={(e) => this.setState({ searchInput: e.target.value })}
						inputIcon={searchInput.length > 0 ? <i className="las la-times-circle c-pointer"
							onClick={() => this.setState({ searchInput: '' })} /> : <i className="las la-search" />}
					/>
					{componentLists[tabSelected].components.map((component) => {
						const { category, blocks } = component
						return <div>
							<h5 className='mb-20'>{category}</h5>
							<Droppable droppableId="droppable-components">
								{(provided, snapshot) => (<div
									ref={provided.innerRef} className="fieldListContainer">
									{blocks && blocks.map((partial, partialIndex) => {
										const partialSelected = selectedPartials.find((p) => p.id == partial._id);
										return partial.title.toLowerCase().includes(searchInput.toLowerCase()) && (<Draggable
											key={`#${partial.file}_${partial._id}`}
											draggableId={`#${partial.file}_${partial._id}_${partialIndex}`}
											index={partial}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps} className={`fieldDraggable  ${partialSelected ? 'selected' : ''}`}
													onClick={() => this.togglePartialSelect(partial)}>
													<i className="las la-braille mr-5" />
													{partial.thumbnail && <div className="partialThumbnail">
														<img src={partial.thumbnail} />
													</div>}
													<div>
														<h4>{partial.title}</h4>
														<p className="small">{partial.file}</p>
													</div>
												</div>)}
										</Draggable>)
									})}
								</div>)}
							</Droppable>
						</div>
					})}
				</div> : <LoadingWizard loading={loading} text="fetching components" />}
			</Fragment>
		);
	}
}

export default ComponentLibrary;
