import React, { Fragment } from 'react';

import 'react-datetime/css/react-datetime.css';
import 'react-toggle/style.css';

import InputField from '../../../components/inputs/inputField';
import FieldModal from '../../../components/modals/fieldModal';

class AddProgramModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			useTheme: false,
			theme: null,
			submitting: false,
			invalid: false
		};
	}

	handleChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	submit = () => {
		const { handleSubmit } = this.props;
		const { name, useTheme } = this.state;

		let valid = true;

		if (name === '') {
			valid = false;
		}

		if (valid) {
			this.setState({ submitting: true });

			handleSubmit({
				name,
				theme: useTheme ? this.state.theme : undefined
			});
			this.setState({ submitting: false });
		} else {
			this.setState({ invalid: true, submitting: false });
		}
	};

	render() {
		const { isOpen, handleSubmit, toggle } = this.props;
		const { name, submitting, invalid } = this.state;
		return (
			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Program"}
					bodyHeaderText={'Please enter the information below.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Program Title"
									value={name}
									onChange={this.handleChange}
									required={true}
									errorTrigger={invalid}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Create Program' : 'Creating Program...'}
					actionButton={() => this.submit()}
					actionButtonDisabled={submitting}


				></FieldModal>







			</div>

		);
	}
}

export default AddProgramModal;
