import React from 'react';

import { Modal, ModalBody } from 'reactstrap';


class BaseModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            isOpen,
            toggle,
            bodyContent,
            className,
            size
        } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle} className={`${className ? className : ''} ${size ? size : ''}`}>
                <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7, zIndex: 100 }}><i className='las la-times'></i></button>
                {bodyContent && bodyContent()}
            </Modal>
        );
    }
}

export default BaseModal;
