import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

class DeleteMemberModal extends Component {
	state = {
		submitting: false
	};
	render() {
		const { isOpen, toggle, memberName, isUser } = this.props;
		const { submitting } = this.state;
		return (
			<Modal isOpen={isOpen} toggle={this.props.toggle} >
				<ModalHeader className="modalHeader">
					{' '}
					{!isUser ? (
						<div className="fs-30 calibreBold">Delete Member</div>
					) : (
						<div className="fs-30 calibreBold">Leave Organization?</div>
					)}
				</ModalHeader>
				<ModalBody className="modalBody">
					{!isUser ? (
						<p className="calibreRegular">
							You are about to delete <b>{memberName}</b>, this is irreversible, would you like to
							continue?
						</p>
					) : (
						<p className="calibreRegular">
							You are about to leave this organization, losing all access. This is irreversible by you and
							would require account a new invitation to rejoin, would you like to continue?
						</p>
					)}
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionDelete m-0"
						disabled={submitting}
						onClick={this.props.delete}
					>
						<i className="las la-trash" />
						<div className="calibreBold fs-16">Yes, {!isUser ? 'Delete' : 'Leave'}</div>{' '}
					</Button>
					<Button
						outline
						onClick={this.props.toggle}
						className="modalButton actionButton actionOutline mb-0 ml-a"
					>
						<i className="las la-times" />
						<div className="calibreBold fs-16">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default DeleteMemberModal;
