import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import SelectField from '../../../../components/inputs/selectField';
import FieldModal from '../../../../components/modals/fieldModal';

const statusOptions = [
	{ label: 'Imported', value: 'imported' },
	{ label: 'Invited', value: 'invited' },
	{ label: 'Registered', value: 'registered' },
	{ label: 'Not Attending', value: 'not attending' },
	{ label: 'Checked In', value: 'checked in' },
	{ label: 'Incomplete', value: 'incomplete' },
	{ label: 'Entered through SSO', value: 'entered through SSO' },
	{ label: 'Removed', value: 'removed' },
	{ label: 'Did not attend', value: 'did not attend' },
	{ label: 'Cancelled', value: 'cancelled' },
];

class UpdateStatusModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedStatus: statusOptions[0],
			updateResults: null,
			submitting: false,
			invalid: false,
			success: false,
			selectedValue: {},
			requiredFields: {
				selectedValue: false
			}
		};
	}

	handleStatusChosen = (e) => {
		this.setState({ selectedValue: e });
	};

	submit = () => {
		const { selectedValue } = this.state;
		const { updateCheckedAttendeesStatus } = this.props;

		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (!selectedValue.value || selectedValue.value === '') {
			requiredFields.selectedValue = true;
			valid = false;
		} else {
			requiredFields.selectedValue = false;
		}
		if (valid) {
			this.setState({ submitting: true });
			updateCheckedAttendeesStatus(selectedValue.value, (added) => {
				this.setState({ success: true, updateResults: added });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, count, updateCheckedAttendeesStatus, toggle } = this.props;
		const { submitting, invalid, success, selectedValue, updateResults, requiredFields } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Update Status"}
					bodyHeaderText={!success && `Select a status to change for the selected ${count} attendees.`}
					bodyContent={() => {
						return (
							<div>
								{!success ?
									<SelectField
										classes={'w-100'}
										label="Attendee Status"
										value={selectedValue}
										name="attendeeStatus"
										errorTrigger={requiredFields.selectedValue}
										options={statusOptions}
										required
										onChange={this.handleStatusChosen}
									/>
									:
									<div>
										<p className="mb-10">
											{`The status of `}
											<b>{`${updateResults.nModified} ${updateResults.nModified > 1 ||
												updateResults.nModified === 0
												? 'attendees'
												: 'attendee'}`}</b>{' '}
											{`was successfully updated to ${selectedValue.label}.`}
										</p>
									</div>
								}
							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!success ? !submitting ? 'Submit' : 'Updating...' : ''}
					cancelButtonLabel={success && 'Close'}

					actionButton={() => this.submit()}
					submitting={submitting}
					actionButtonDisabled={submitting || success}

				></FieldModal>


			</div>
		)

	}
}

export default UpdateStatusModal;
