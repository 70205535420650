import React, { Component } from 'react';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import API from '../../utils/API';
import MemberInformation from '../../sections/team/information';
import EventPermissions from '../../sections/team/eventPermissions';

import LoadingWizard from '../../components/spinner/wizard';
import TabsContainer from '../../components/views/TabsContainer';
import BasicSectionHeader from '../../components/views/BasicSectionHeader';
import DeleteMemberModal from './modals/deleteMemberModal';
import DeleteTeamModal from './modals/deleteTeamMembersModal';
import { removeCookie } from '../../utils/cookie';
import { withRouter } from 'react-router-dom';
import SectionHeader from '../../components/views/SectionHeader';
import SectionFooter from '../../components/views/SectionFooter';
import ProgramPermissions from '../../sections/team/programPermissions';

import ImageCropper from '../../components/modals/imageCropper';

class TeamMember extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member: {},
			editedMember: {},
			events: [],
			selectedSection: 0,
			DeleteMemberModalOpen: false,
			loading: true,
			editing: false,
			requiredFields: {
				firstName: false,
				emailAddress: false
			},
			selectedFile: null,
			toggleImageCropper: false
		};
	}

	async componentDidMount() {
		const { match: { params }, orgId } = this.props;
		const eventData = await API().get(`Organizations/${orgId}/eventNames`);
		const programData = await API().get(`Organizations/${orgId}/programNames`);
		await API().get(`Organizations/${orgId}/members/${params.teamMember}`).then((res) => {
			this.setState({
				member: JSON.parse(JSON.stringify(res.data)),
				editedMember: JSON.parse(JSON.stringify(res.data)),
				events: eventData.data.events,
				programs: programData.data.programs,
				loading: false
			});
		});
	}

	toggleDelete = () => {
		this.setState({ DeleteMemberModalOpen: !this.state.DeleteMemberModalOpen });
	};

	SectionItem = (title, sectionNo) => {
		return (
			<div
				className={`sectionTab ${sectionNo === this.state.selectedSection ? 'selected' : ''}`}
				onClick={() => {
					this.setState({ selectedSection: sectionNo });
				}}
			>
				<div className="calibreBold fs-30">{title}</div>
			</div>
		);
	};

	saveImage = async (data, callback) => {
		const { match: { params }, orgId } = this.props;

		API().patch(`Organizations/${orgId}/members/${params.teamMember}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			if (res.data) {
				this.setState({
					member: JSON.parse(JSON.stringify(res.data)),
					editedMember: JSON.parse(JSON.stringify(res.data)),
					editing: false,
				}, () => {

					callback && callback();
				});
			}
		});
	}
	handleFileChange = (e) => {
		const reader = new FileReader();
		const selectedFile = e.target.files[0];
		reader.readAsDataURL(selectedFile);
		reader.onload = () => {

			this.setState({ selectedFile: reader.result, toggleImageCropper: true });
		}
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	toggleImageCropper = () => {
		this.setState({ toggleImageCropper: false });
	};

	updateMember = () => {
		const { editedMember } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedMember[key] || editedMember[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			const { match: { params }, orgId } = this.props;
			API().patch(`Organizations/${orgId}/members/${params.teamMember}`, editedMember).then((res) => {
				if (res.data) {
					this.setState({
						member: JSON.parse(JSON.stringify(res.data)),
						editedMember: JSON.parse(JSON.stringify(res.data)),
						editing: false,
						requiredFields: requiredFields
					});
				}
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	removeMember = () => {
		const { match: { params }, orgId } = this.props;
		API().delete(`Organizations/${orgId}/members/${params.teamMember}`).then((res) => {
			if (this.state.member.isUser) {
				localStorage.clear();
				removeCookie('authToken');
				window.location.replace('/login');
			} else {
				this.props.removeTeamMembers([params.teamMember]);
				this.toggleDelete();
				this.props.history.push(`/${orgId}/team`);
			}
		});
	};

	editEventPermission = (editedPermissions, inlineEdit) => {
		const { editedMember } = this.state;
		editedMember.eventPermissions = editedPermissions;
		inlineEdit ? this.setState({ editedMember: editedMember, editing: true }) : this.setState({ editedMember: editedMember });
	};

	editProgramPermission = (editedPermissions, inlineEdit) => {
		const { editedMember } = this.state;
		editedMember.programPermissions = editedPermissions;
		inlineEdit ? this.setState({ editedMember: editedMember, editing: true }) : this.setState({ editedMember: editedMember });
	};

	updateProgramPermissions = () => {
		const { member, editedMember } = this.state;
		const { match: { params }, orgId } = this.props;
		API().patch(`Organizations/${orgId}/members/${params.teamMember}`, { ...member, programPermissions: editedMember.programPermissions }).then((res) => {
			if (res.data) {
				this.setState({
					member: { ...member, programPermissions: editedMember.programPermissions },
					editedMember: editedMember
				});
			}
		});
	};

	updateEventPermissions = () => {
		const { member, editedMember } = this.state;
		const { match: { params }, orgId } = this.props;
		API().patch(`Organizations/${orgId}/members/${params.teamMember}`, { ...member, eventPermissions: editedMember.eventPermissions }).then((res) => {
			if (res.data) {
				this.setState({
					member: { ...member, eventPermissions: editedMember.eventPermissions },
					editedMember: editedMember
				});
			}
		});
	};

	cancelProgramPermissionEdit = () => {
		const { member, editedMember } = this.state;
		editedMember.programPermissions = JSON.parse(JSON.stringify(member.programPermissions));
		this.setState({ editedMember: editedMember });
	};

	cancelEventPermissionEdit = () => {
		const { member, editedMember } = this.state;
		editedMember.eventPermissions = JSON.parse(JSON.stringify(member.eventPermissions));
		this.setState({ editedMember: editedMember });
	};

	editMemberInformation = (property, value) => {
		const { editedMember } = this.state;
		editedMember[property] = value;
		this.setState({ editedMember: editedMember, editing: true });
	};
	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};
	renderSection() {
		const { selectedSection, editedMember, events, requiredFields, programs } = this.state;
		let permission = this.props.permission;
		switch (selectedSection) {
			case 0:
				return (
					<MemberInformation
						requiredFields={requiredFields}
						editedMember={editedMember}
						permission={permission}
						editMemberInformation={this.editMemberInformation}
					/>
				);
			case 1:
				return (
					<EventPermissions
						events={events}
						editedMember={editedMember}
						editEventPermission={this.editEventPermission}
						cancelEventPermissionEdit={this.cancelEventPermissionEdit}
						updateEventPermissions={this.updateEventPermissions}
					/>
				);
			case 2: {
				return <ProgramPermissions
					programs={programs}
					editedMember={editedMember}
					editProgramPermission={this.editProgramPermission}
					cancelProgramPermissionEdit={this.cancelProgramPermissionEdit}
					updateProgramPermissions={this.updateProgramPermissions}

				/>
			}
			default:
		}
	}

	cancelEdit = () => {
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editing: false,
			editedMember: JSON.parse(JSON.stringify(this.state.member)),
			requiredFields: requiredFields
		});
	};

	render() {
		const { member, DeleteMemberModalOpen, selectedSection, loading, editing, toggleImageCropper, editedMember } = this.state;
		let permission = this.props.permission;
		return <div className={`pageContainer `}>
			{!loading && <div className={`sectionContainer ${editing ? 'editing' : ''}`}>

				<SectionHeader
					updatedAt={member.updatedAt}
					title={member.firstName.concat(' ').concat(member.lastName)}
					enableProfileIcon={true}
					acronym={editedMember.firstName.charAt(0).concat(editedMember.lastName.charAt(0))}
					profilePicture={editedMember.profilePicture || null}
					disabledProfileEvents={true}
					profileIconOnClick={null}
					handleProfileFileChange={null}
					handleProfileImageDelete={null}
					description={`Last Active: ${moment(member.lastActive).format('MMMM Do YYYY')}`}
					titleIcon={
						member.profilePicture ? (
							<img src={member.profilePicture} style={{ maxWidth: '100%', overflow: 'clip' }} />
						) : (
							<div className='profileIconContainer d40'>
								{member.firstName.toUpperCase().charAt(0)}
								{member.lastName.toUpperCase().charAt(0)}
							</div>
						)
					}
					enableLastLink={true}
					breadcrumbs={[
						{
							name: 'Team',
						},
						{
							name: 'Member',
						}
					]}
					subActions={!permission ? false : [{ onClick: this.toggleDelete, label: 'Delete Member' }]}
				>
					<TabsContainer
						tabs={[{ title: 'Information', icon: <i className='las la-info-circle mr-10'></i> }, { title: 'Events', icon: <i className='las la-calendar mr-10'></i> }, { title: 'Programs', icon: <i className='las la-stream mr-10'></i> }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/></SectionHeader>
				<div className="sectionBody">{this.renderSection()}</div>
				<SectionFooter cancelEdit={this.cancelEdit} saveEdit={this.updateMember} updatedAt={member.updatedAt} editing={editing} />
				{/* 	<DeleteMemberModal
					isOpen={DeleteMemberModalOpen}
					permission={permission}
					toggle={this.toggleDelete}
					memberName={member.firstName.concat(' ').concat(member.lastName)}
					isUser={member.isUser}
					delete={this.removeMember}
				/> */}
				<DeleteTeamModal
					isOpen={DeleteMemberModalOpen}
					toggle={this.toggleDelete}
					memberName={member.firstName.concat(' ').concat(member.lastName)}
					isUser={member.isUser}
					delete={this.removeMember}
				/>
				{toggleImageCropper && <ImageCropper
					isOpen={toggleImageCropper}
					toggle={this.toggleImageCropper}
					selectedFile={this.state.selectedFile}
					saveImage={this.saveImage}
				/>}

			</div>}
			<LoadingWizard text="LOADING MEMBER" loading={loading} />
		</div>
	}
}

export default withRouter(TeamMember);
