import React, { Component, Fragment } from 'react';
import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

class SessionRemoveFromGroupModal extends React.Component {
    state = {
        submitting: false
    };

    removeGroup = () => {
        const { eventId, orgId, session, checkedGroups } = this.props;
        this.setState({ submitting: true })
        API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
                groupIds: checkedGroups,
                request: 'removeGroups'
            })
            .then((res) => {
                if (res.data) {
                    //this is for one element only
                    this.props.removeGroup({ _id: checkedGroups[0] }, () => {

                        this.props.toggle();
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    invalid: true,
                    submitting: false
                });
            });

        this.setState({ submitting: false });
    };

    render() {
        const { isOpen, checkedGroups, toggle } = this.props;
        const { submitting } = this.state;

        let checkedGroupLength = checkedGroups.length;
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete From Session"}
                    bodyHeaderText={<span>You are about to delete{' '}
                        <b>
                            {checkedGroupLength} group{checkedGroupLength != 1 ? 's' : ''}
                        </b>{' '}
                        from this session, this will remove <b>ALL</b> group associated
                        attendees from this session as well. Would you like to continue?</span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
                    actionButton={() => this.removeGroup()}
                    isDelete={true}
                    actionButtonDisabled={submitting}
                ></FieldModal>
            </div>

        );
    }
}
export default SessionRemoveFromGroupModal;

