import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TabsContainer from '../../components/views/TabsContainer';
import InputField from '../../components/inputs/inputField';
import ToggleField from '../../components/inputs/toggleField';
import SectionHeader from '../../components/views/SectionHeader';
import SectionFooter from '../../components/views/SectionFooter';
import SelectField from '../../components/inputs/selectField';
import EmailEditor from '../../components/email/emailEditor';
import * as IFrame from 'react-iframe-form';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
class ProgramDetails extends Component {
	constructor(props) {
		super(props);
		const fieldOptions = [];
		let capturingEmail = false;
		let capturingName = false;
		let capturingRequiredEventFields = false;
		const requestForm = this.props.program.requestForm;
		const requiredFields = this.props.program.eventDefaults.details.requiredCustomFields;
		requestForm && requestForm.pages && requestForm.pages.forEach((page) => {
			page.sections.forEach((section) => {
				section.fields.forEach((field) => {
					let key = field._id.toString();
					if (field.type === 'nestedSection') {
						field.nestedSections.forEach((ns) => {
							key += `-${ns._id.toString()}`;
							ns.questions.forEach((q) => {
								key += `-${q._id.toString()}`;
								fieldOptions.push({ value: key, label: q.label });
							});
						});
					} else if (field.objectClass !== 'baseInput') {
						fieldOptions.push({ value: key, label: field.label });
					} else if (field.id === 'email') {
						capturingEmail = true;
					} else if (field.id === 'firstName') {
						capturingName = true;
					}
					if (requiredFields.includes(field.id) && field.required) {
						const index = requiredFields.indexOf(field.id);
						if (index > -1) {
							requiredFields.splice(index, 1);
						}
					}
				});
			});
		});

		if (requiredFields.length === 0) {
			capturingRequiredEventFields = true;
		}

		this.state = {
			editedProgram: { ...props.program },
			selectedSection: 0,
			editing: false,
			requiredFields: {
				name: false
			},
			fieldOptions: fieldOptions,
			editCount: 0,
			toggleRequestEmailBuilder: false,
			toggleApprovalemailBuilder: false,
			toggleDeniedEmailBuilder: false,
			canBePublic: capturingEmail && capturingName,
			canAutoApprove: capturingRequiredEventFields
		};
	}

	saveDetails = () => {
		const { editedProgram } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedProgram[key] || editedProgram[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			this.props.saveDetails(editedProgram, () => {
				this.setState({ editing: false, requiredFields: requiredFields });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	editProgramField = (property, value) => {
		const editedProgram = this.state.editedProgram;
		editedProgram[property] = value;
		this.setState({ editedProgram: editedProgram, editing: true, editCount: this.state.editCount + 1 });
	};

	cancelEdit = () => {
		const program = this.props.program;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({ editedProgram: { ...program }, requiredFields: requiredFields, editing: false, editCount: this.state.editCount + 1 });
	};

	updateRequestEmail = async (email) => {
		this.props.saveRequestEmail(email, () => {
			this.setState({
				editedProgram: { ...this.state.editedProgram, requestEmailCode: email.html, requestEmailComponents: email.emailComponents ? email.emailComponents : [], requestEmailCodeOnly: email.codeOnly ? email.codeOnly : false, requestEmailStyles: email.emailStyles },
				editCount: this.state.editCount + 1
			});
		});
	};


	updateApprovalEmail = async (email) => {
		this.props.saveApprovalEmail(email, () => {

			this.setState({
				editedProgram: { ...this.state.editedProgram, approvalEmailCode: email.html, approvalEmailComponents: email.emailComponents ? email.emailComponents : [], approvalEmailCodeOnly: email.codeOnly ? email.codeOnly : false, approvalEmailStyles: email.emailStyles },
				editCount: this.state.editCount + 1
			});
		});
	};

	updateDeniedEmail = async (email) => {
		this.props.saveDeniedEmail(email, () => {
			this.setState({
				editedProgram: { ...this.state.editedProgram, deniedEmailCode: email.html, deniedEmailComponents: email.emailComponents ? email.emailComponents : [], deniedEmailCodeOnly: email.codeOnly ? email.codeOnly : false, deniedEmailStyles: email.emailStyles },
				editCount: this.state.editCount + 1
			});
		});
	}

	renderSection = () => {
		const { selectedSection, editedProgram, requiredFields, editCount, toggleRequestEmailBuilder, toggleApprovalEmailBuilder, toggleDeniedEmailBuilder } = this.state;
		const { program, orgId } = this.props;
		let url = `${ENV}Organizations/${orgId}/emailrenderer?preview=true`;
		let method = 'post';
		let staticFields = [
			{
				label: "Organization Name",
				text: "orgName",
				value: "{{orgName}}",
			},
			{
				label: "Program Name",
				text: "programName",
				value: "{{programName}}",
			},
			{
				label: "Submitter Name",
				text: "submitterName",
				value: "{{submitterName}}",
			},
			{
				label: "Submitter Email",
				text: "submitterEmail",
				value: "{{submitterEmail}}",
			},
			{
				label: "Submission Date",
				text: "submissionDate",
				value: "{{submissionDate}}",
			},
			{
				label: "Denied Date",
				text: "deniedDate",
				value: "{{deniedDate}}",
			},

			{
				label: "Administrator Name",
				text: "adminName",
				value: "{{adminName}}",
			},

			{
				label: "Administrator Email",
				text: "adminEmail",
				value: "{{adminEmail}}",
			},

			{
				label: "Event Name",
				text: "eventName",
				value: "{{eventName}}",
			},

			{
				label: "Event Slug",
				text: "eventSlug",
				value: "{{eventSlug}}",
			},

			{
				label: "Event Start Date",
				text: "eventStartDate",
				value: "{{eventStartDate}}",
			},

			{
				label: "Event End Date",
				text: "eventEndDate",
				value: "{{eventEndDate}}",
			},

			{
				label: "Event Start Time",
				text: "eventStartTime",
				value: "{{eventStartTime}}",
			},

			{
				label: "Event End Time",
				text: "eventEndTime",
				value: "{{eventEndTime}}",
			},

			{
				label: "Event Timezone",
				text: "eventTimezone",
				value: "{{eventTimezone}}",
			},
			{
				label: "Request Calendar",
				text: "requestCalendar",
				value: "{{requestCalendar}}",
			},
			{
				label: "Salesforce Account Name",
				text: "salesforceAccountName",
				value: "{{salesforceAccountName}}",
			}
		];
		if (selectedSection === 0) {
			return (
				<div className="sectionBlock">
					<h2 className='blockHeader'>Program details</h2>
					<InputField
						label="Name"
						required={true}
						errorTrigger={requiredFields.name}
						value={editedProgram.name}
						type="text"
						onChange={(e) => this.editProgramField('name', e.target.value)}
					/>

					<ToggleField
						label="Open to public"
						checked={editedProgram.openToPublic && this.state.canBePublic}
						icons={false}
						errorTrigger={requiredFields.openToPublic}
						errorMessage="Email and Name must be captured in the request form to enable this feature."
						onChange={(e) => {
							if (this.state.canBePublic) {
								this.editProgramField('openToPublic', !editedProgram.openToPublic)
							} else {
								this.setState({ requiredFields: { ...requiredFields, openToPublic: true } });
							}
						}}
					/>

					<ToggleField
						label="Auto Approve"
						checked={editedProgram.autoApprove && this.state.canAutoApprove}
						icons={false}
						errorTrigger={requiredFields.autoApprove}
						errorMessage="All required custom fields must exist and be required in request form to enable this feature."
						onChange={(e) => {
							if (this.state.canAutoApprove) {
								this.editProgramField('autoApprove', !editedProgram.autoApprove)
							} else {
								this.setState({ requiredFields: { ...requiredFields, autoApprove: true } });
							}
						}}
					/>

					<h2 className='blockHeader'>Program Administrator</h2>
					<InputField
						label="Name"
						value={`${this.props.program.creator.firstName} ${this.props.program.creator.lastName}`}
						disabled={true}
						inputIcon={<i className="las la-lock" />}
					/>

					<InputField
						label="Email"
						value={this.props.program.creator.emailAddress}
						disabled={true}
						inputIcon={<i className="las la-lock" />}
					/>
				</div>
			);
		} else if (selectedSection === 1) {

			let data = { html: editedProgram.requestEmailCode }
			let style = { ...editedProgram.requestEmailStyles }
			let fixedEditedHtml = { html: editedProgram.requestEmailCode, emailComponents: editedProgram.requestEmailComponents ? editedProgram.requestEmailComponents : [], codeOnly: editedProgram.requestEmailCodeOnly ? editedProgram.requestEmailCodeOnly : false, emailStyles: editedProgram.requestEmailStyles };


			let customFieldsFixed = [];
			if (program.eventCustomFields && program.eventCustomFields.length > 0) {
				program.eventCustomFields.forEach((category) => {
					category.fields.forEach((field) => {


						const categoryShortCode = category.title.replace(/\s/g, '');
						const fieldShortCode = field.fieldName.replace(/\s/g, '');
						let label = field.fieldName;
						let text = `${categoryShortCode} + ${fieldShortCode}`;
						let value = `{ {${categoryShortCode} + ${fieldShortCode} } }`;

						customFieldsFixed.push({ label, text, value });
					});
				})
			}

			let customFieldsFixedFinal = [...staticFields, ...customFieldsFixed];
			return (
				<div className='blocksContainer' key={'programRequestEmail'}>

					<div className="sectionBlock mr-25">
						<h2 className='blockHeader'>Request Email</h2>
						<InputField
							label="Subject Line"
							required={true}
							errorTrigger={requiredFields.requestEmailSubject}
							value={editedProgram.requestEmailSubject}
							type="text"
							onChange={(e) => this.editProgramField('requestEmailSubject', e.target.value)}
						/>

						<ToggleField
							label="Send Confirmation Based on Form Field Value"
							checked={editedProgram.conditionalRequestConfirmationEnabled}
							icons={false}
							onChange={(e) =>
								this.editProgramField('conditionalRequestConfirmationEnabled', !editedProgram.conditionalRequestConfirmationEnabled)}
						/>
						{editedProgram.conditionalRequestConfirmationEnabled ? <div className='mb-20'>
							{editedProgram.requestConfirmationEmailFieldConditionals && editedProgram.requestConfirmationEmailFieldConditionals.map((conditional, index) => {
								const selectedField = this.state.fieldOptions.find(f => f.value === conditional.fieldId);
								return <div className='flex-ac'>
									<SelectField classes="mr-10" label="Form Field" value={selectedField} options={this.state.fieldOptions} onChange={(e) => {
										const editedProgram = this.state.editedProgram;
										editedProgram.requestConfirmationEmailFieldConditionals[index].fieldId = e.value;
										this.setState({ editedProgram: editedProgram, editing: true });
									}} />
									<InputField classes="mr-10" label="Value" value={conditional.value} type="text" onChange={(e) => {
										const editedProgram = this.state.editedProgram;
										editedProgram.requestConfirmationEmailFieldConditionals[index].value = e.target.value;
										this.setState({ editedProgram: editedProgram, editing: true });
									}} />
									<InputField label="Recipients" value={conditional.recipients} type="text" onChange={(e) => {
										const editedProgram = this.state.editedProgram;
										editedProgram.requestConfirmationEmailFieldConditionals[index].recipients = e.target.value;
										this.setState({ editedProgram: editedProgram, editing: true });
									}} />
								</div>

							})}
							<div
								className="actionButton actionOutline"
								onClick={() => {
									const editedProgram = this.state.editedProgram;
									editedProgram.requestConfirmationEmailFieldConditionals ? (editedProgram.requestConfirmationEmailFieldConditionals.push({ fieldId: '', value: '', recipients: '' })) : (editedProgram.requestConfirmationEmailFieldConditionals = [{ fieldId: '', value: '', recipients: '' }]);
									this.setState({ editedProgram: editedProgram, editing: true });
								}}
							>
								<i className="las la-plus" />
								<div className="">Add Conditional</div>
							</div>
						</div> : <InputField
							label="Recipients"
							value={editedProgram.requestEmailRecipients}
							type="text"
							onChange={(e) => this.editProgramField('requestEmailRecipients', e.target.value)}
						/>}

						<ToggleField
							label="Platform Template Enabled"
							checked={editedProgram.requestTemplateEnabled}
							icons={false}
							onChange={(e) =>
								this.editProgramField('requestTemplateEnabled', !editedProgram.requestTemplateEnabled)}
						/>
					</div>

					{!editedProgram.requestTemplateEnabled && (
						<div className='sectionBlock emailDesignPreview mt-20'>
							<div
								className="cardDetailsContainerSetup containerTitle"
								style={{ marginBottom: 5 }}
							>

								<div className='w-100'>
									<h2 className='blockHeader mt-0'> Design</h2>
									<h5 className='formFieldLabel mt-10'>Request Email Preview</h5>
								</div>

								<button
									className="neu min ml-a"
									onClick={() => { this.toggleRequestEmailBuilder() }}
								>
									Edit Email
								</button>
							</div>
							<div
								className="emailPreview eFrame flex-ac preview"
							>

								<IFrame.Form
									id="builderPreviewFrameTemplate"
									className="emailPreviewWindow Frame"
									key={`approvalEmail-${editCount}`}
									style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
									name="PreviewFrameCamp"
									config={{ url, method, data, style }}
								/>
							</div>
						</div>



					)}
					{toggleRequestEmailBuilder && (
						<EmailEditor
							isOpen={toggleRequestEmailBuilder}
							emailTemplate={fixedEditedHtml}
							orgId={orgId}
							eventId={''}
							keyedMetadata={[]}
							keyedData={[]}
							event={''}
							branding={program.eventDefaults.details.branding}
							staticCustomFields={customFieldsFixedFinal}
							url={`${ENV}Organizations/${orgId}/emailrenderer`}
							method={method}
							postData={data}
							toggle={this.toggleRequestEmailBuilder}
							update={this.updateRequestEmail}
							cancelEdit={this.cancelEdit}
							links={{
								getAssets: `Organizations/${orgId}/assets`,
								addAssets: `Organizations/${orgId}/assets`,
							}}
						/>
					)}
				</div>
			);
		} else if (selectedSection === 2) {

			let approvalData = { html: editedProgram.approvalEmailCode };
			let approvalStyle = {
				style: { ...editedProgram.approvalEmailStyles }
			}
			let approvalFixedEditedHtml = { html: editedProgram.approvalEmailCode, emailComponents: editedProgram.approvalEmailComponents ? editedProgram.approvalEmailComponents : [], codeOnly: editedProgram.approvalEmailCodeOnly ? editedProgram.approvalEmailCodeOnly : false, emailStyles: editedProgram.approvalEmailStyles };


			let customFieldsFixed = [];
			if (program.eventCustomFields && program.eventCustomFields.length > 0) {
				program.eventCustomFields.forEach((category) => {
					category.fields.forEach((field) => {


						const categoryShortCode = category.title.replace(/\s/g, '');
						const fieldShortCode = field.fieldName.replace(/\s/g, '');
						let label = field.fieldName;
						let text = `${categoryShortCode} + ${fieldShortCode}`;
						let value = `{ {${categoryShortCode} + ${fieldShortCode} } }`;

						customFieldsFixed.push({ label, text, value });
					});
				})
			}

			let customFieldsFixedFinal = [...staticFields, ...customFieldsFixed];
			return (


				<div className='blocksContainer ' key={'programRequestEmail'}>

					<div className="sectionBlock mr-25">
						<h2 className='blockHeader'>Approval Email</h2>
						<InputField
							label="Subject Line"
							required={true}
							errorTrigger={requiredFields.approvalEmailSubject}
							value={editedProgram.approvalEmailSubject}
							type="text"
							onChange={(e) => this.editProgramField('approvalEmailSubject', e.target.value)}
						/>

						<InputField
							label="Recipients"
							value={editedProgram.approvalEmailRecipients}
							type="text"
							onChange={(e) => this.editProgramField('approvalEmailRecipients', e.target.value)}
						/>
						<ToggleField
							label="Platform Template Enabled"
							checked={editedProgram.approvalTemplateEnabled}
							icons={false}
							onChange={(e) =>
								this.editProgramField('approvalTemplateEnabled', !editedProgram.approvalTemplateEnabled)}
						/>
					</div>

					{!editedProgram.approvalTemplateEnabled && (
						<div className='sectionBlock emailDesignPreview mt-20'>
							<div
								className="cardDetailsContainerSetup containerTitle"
								style={{ marginBottom: 5 }}
							>

								<div className='w-100'>
									<h2 className='blockHeader mt-0'> Design</h2>
									<h5 className='formFieldLabel mt-10'>Approval Email Preview</h5>
								</div>

								<button
									className="neu min ml-a"
									onClick={() => { this.toggleApprovalEmailBuilder() }}
								>
									Edit Email
								</button>
							</div>
							<div
								className="emailPreview eFrame flex-ac preview"
							>

								<IFrame.Form
									id="builderPreviewFrameTemplate"
									className="emailPreviewWindow Frame"
									key={`approvalEmail-${editCount}`}
									style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
									name="PreviewFrameCamp"
									config={{ url, method, data: approvalData, style: approvalStyle }}
								/>

							</div>


							{toggleApprovalEmailBuilder && (
								<EmailEditor
									isOpen={toggleApprovalEmailBuilder}
									emailTemplate={approvalFixedEditedHtml}
									orgId={orgId}
									eventId={''}
									keyedMetadata={[]}
									keyedData={[]}
									branding={program.eventDefaults.details.branding}
									staticCustomFields={customFieldsFixedFinal}
									event={''}
									url={`${ENV}Organizations/${orgId}/emailrenderer`}
									method={method}
									postData={approvalData}
									toggle={this.toggleApprovalEmailBuilder}
									update={this.updateApprovalEmail}
									cancelEdit={this.cancelEdit}
									links={{
										getAssets: `Organizations/${orgId}/assets`,
										addAssets: `Organizations/${orgId}/assets`,
									}}
								/>
							)}
						</div>
					)}
				</div>
			);
		} else if (selectedSection === 3) {
			let deniedData = { html: editedProgram.deniedEmailCode };
			let deniedStyle = {
				style: { ...editedProgram.deniedEmailStyles }
			}
			let deniedFixedEditedHtml = { html: editedProgram.deniedEmailCode, emailComponents: editedProgram.deniedEmailComponents ? editedProgram.deniedEmailComponents : [], codeOnly: editedProgram.deniedEmailCodeOnly ? editedProgram.deniedEmailCodeOnly : false, emailStyles: editedProgram.deniedEmailStyles };

			let customFieldsFixed = [];
			if (program.eventCustomFields && program.eventCustomFields.length > 0) {
				program.eventCustomFields.forEach((category) => {
					category.fields.forEach((field) => {


						const categoryShortCode = category.title.replace(/\s/g, '');
						const fieldShortCode = field.fieldName.replace(/\s/g, '');
						let label = field.fieldName;
						let text = `${categoryShortCode} + ${fieldShortCode}`;
						let value = `{ {${categoryShortCode} + ${fieldShortCode} } }`;

						customFieldsFixed.push({ label, text, value });
					});
				})
			}

			let customFieldsFixedFinal = [...staticFields, ...customFieldsFixed];
			return (
				<div className='blocksContainer' key={'programDeniedEmail'}>

					<div className="sectionBlock mr-25">
						<h2 className='blockHeader'>Denied Email</h2>
						<InputField
							label="Subject Line"
							required={true}
							errorTrigger={requiredFields.deniedEmailSubject}
							value={editedProgram.deniedEmailSubject}
							type="text"
							onChange={(e) => this.editProgramField('deniedEmailSubject', e.target.value)}
						/>

						<InputField
							label="Recipients"
							value={editedProgram.deniedEmailRecipients}
							type="text"
							onChange={(e) => this.editProgramField('deniedEmailRecipients', e.target.value)}
						/>

						<ToggleField
							label="Platform Template Enabled"
							checked={editedProgram.deniedTemplateEnabled}
							icons={false}
							onChange={(e) =>
								this.editProgramField('deniedTemplateEnabled', !editedProgram.deniedTemplateEnabled)}
						/>
					</div>

					{!editedProgram.deniedTemplateEnabled && (
						<div className='sectionBlock emailDesignPreview mt-20'>
							<div
								className="cardDetailsContainerSetup containerTitle"
								style={{ marginBottom: 5 }}
							>

								<div className='w-100'>
									<h2 className='blockHeader mt-0'> Design</h2>
									<h5 className='formFieldLabel mt-10'>Denied Email Preview</h5>
								</div>

								<button
									className="neu min  ml-a"
									onClick={() => { this.toggleDeniedEmailBuilder() }}
								>
									Edit Email
								</button>
							</div>
							<div
								className="emailPreview eFrame flex-ac preview"
							>

								<IFrame.Form
									id="builderPreviewFrameTemplate"
									className="emailPreviewWindow Frame"
									key={`deniedEmail-${editCount}`}
									style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
									name="PreviewFrameCamp"
									config={{ url, method, data: deniedData, style: deniedStyle }}
								/>
							</div>
						</div>



					)}
					{toggleDeniedEmailBuilder && (
						<EmailEditor
							isOpen={toggleDeniedEmailBuilder}
							emailTemplate={deniedFixedEditedHtml}
							orgId={orgId}
							eventId={''}
							keyedMetadata={[]}
							keyedData={[]}
							event={''}
							branding={program.eventDefaults.details.branding}
							staticCustomFields={customFieldsFixedFinal}
							url={`${ENV}Organizations/${orgId}/emailrenderer`}
							method={method}
							postData={deniedData}
							toggle={this.toggleDeniedEmailBuilder}
							update={this.updateDeniedEmail}
							cancelEdit={this.cancelEdit}
							links={{
								getAssets: `Organizations/${orgId}/assets`,
								addAssets: `Organizations/${orgId}/assets`,
							}}
						/>
					)}
				</div>
			);
		}

	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex, editCount: this.state.editCount + 1 });
	};

	toggleRequestEmailBuilder = () => {
		const { toggleRequestEmailBuilder } = this.state;

		this.setState({ toggleRequestEmailBuilder: !toggleRequestEmailBuilder })

	}
	toggleApprovalEmailBuilder = () => {
		const { toggleApprovalEmailBuilder } = this.state;
		this.setState({ toggleApprovalEmailBuilder: !toggleApprovalEmailBuilder })
	}

	toggleDeniedEmailBuilder = () => {
		const { toggleDeniedEmailBuilder } = this.state;
		this.setState({ toggleDeniedEmailBuilder: !toggleDeniedEmailBuilder })
	}

	render() {
		const { editing, selectedSection } = this.state;
		const { program } = this.props;
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={`Event Program Setup`}
					description="Set up an event program to let organizers create and request events from a customized event configuration."
					breadcrumbs={[
						{
							name: program.name,
						}
					]}
				>
					<TabsContainer
						tabs={[{ title: 'Details' }, { title: 'Requests' }, { title: 'Approvals' }, { title: 'Denials' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/></SectionHeader>
				<div className="sectionBody">{this.renderSection()}</div>
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveDetails}
					updatedAt={program.updatedAt} />
			</div>
		);
	}
}

export default withRouter(ProgramDetails);
