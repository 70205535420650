import React from 'react';
import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteSessionModal extends React.Component {
    state = {
        submitting: false
    };

    delete = () => {
        const { submitting } = this.state;
        const { toggle, deleteInventories } = this.props;
        this.setState({ submitting: true })
        deleteInventories();



    };
    render() {
        const { isOpen, toggle, } = this.props;
        const { submitting } = this.state;
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Item"}
                    bodyHeaderText={<span>You are about to delete <b>{''}</b> inventory items from the event, this is irreversible,
                        would you like to continue?</span>}
                    bodyContent={() => {
                        return (
                            ''
                        )
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                    actionButton={() => this.delete()}
                    actionButtonDisabled={submitting}
                    isDelete={true}
                ></FieldModal>
            </div>

        );
    }
}

export default withRouter(DeleteSessionModal);
