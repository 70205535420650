import React, { Component, Fragment } from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, Button } from 'reactstrap';
import { Trash2, ChevronDown, ChevronUp } from 'react-feather';
import API from '../../../utils/API';
import EventGroup from './group';
import AddGroupModal from './modals/addGroupModal';
import DeleteGroupModal from './modals/deleteGroupsModal';

import SectionTable from '../../../components/tables/SectionTable';
import ViewsContainer from '../../../components/views/ViewsContainer';
import LoadingWizard from '../../../components/spinner/wizard';

let batchCallback;
class EventGroups extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			capacity: 'Capacity',
			attendees: 'Attendees',
			sessions: 'Sessions',
			createdAt: 'Created At',
			updatedAt: 'Updated At'
		};
		const defaultFieldTypes = {
			name: 'text',
			capacity: 'number',
			attendees: 'number',
			sessions: 'number',
			updatedAt: 'date',
			createdAt: 'date'
		};
		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'capacity',
					'attendees',
					'sessions',
					'createdAt',
					'updatedAt'
				]
			},
		};
		const normalizedColumns = Object.keys(customFieldColumns).map((cfc) => {
			return {
				label: customFieldColumns[cfc],
				key: cfc,
				type: typeof customFieldColumns[cfc]
			};
		});

		this.state = {
			categoryColumns: fieldCategories,
			eventId: props.eventId,
			newGroups: [],
			checkedGroups: [],
			groupSelected: false,
			searchTerm: '',
			searchField: '',
			addGroupModalOpen: false,
			deleteGroupsModalOpen: false,
			newGroup: {},
			toggledColumns: { name: false, capacity: true, attendees: true, sessions: true },
			sortByColumn: { name: true },
			loading: true,
			normalizedColumns: normalizedColumns,
			deletedGroups: [],
			isDeleting: false,
			customFieldColumns: customFieldColumns,
			sortedGroups: this.props.groups
				.map((group) => {
					return {
						name: group.name,
						capacity: group.maxCapacityEnabled ? group.maxCapacity.toString() : 'N/A',
						attendees: group.attendees.length.toString(),
						sessions: group.sessions.length.toString(),
						_id: group._id,
						link: `/${props.orgId}/events/${props.eventId}/groups/${group._id}`,
						updatedAt: group.updatedAt,
						createdAt: group.createdAt
					};
				})
				.sort((a, b) => {
					const key = Object.keys({ name: true })[0];
					if (key === 'name') {
						return a[key].toLowerCase().trim().localeCompare(b[key].toLowerCase().trim());
					}
				}),
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			toggleAddGroup: false,
			sidePanelOpen: false,
			editCount: 0,
			activeRow: ''
		};
	}

	componentDidMount = () => {
		const { orgId, eventId, } = this.props;
		this.setState({ loading: true }, () => this.props.fetchGroups(() => {

			const sortedGroups = this.props.groups
				.map((group) => {
					return {
						name: group.name,
						capacity: group.maxCapacityEnabled ? group.maxCapacity.toString() : 'N/A',
						attendees: group.attendees.length.toString(),
						sessions: group.sessions.length.toString(),
						_id: group._id,
						link: `/${orgId}/events/${eventId}/groups/${group._id}`,
						updatedAt: group.updatedAt,
						createdAt: group.createdAt
					};
				})
				.sort((a, b) => {
					const key = Object.keys({ name: true })[0];
					if (key === 'name') {
						return a[key].toLowerCase().trim().localeCompare(b[key].toLowerCase().trim());
					}
				});

			this.setState({ loading: false, sortedGroups: sortedGroups });
		}));
	}


	addGroup = (group) => {
		this.props.addGroup(group);
		this.setState({
			sortedGroups: [
				...this.state.sortedGroups,
				{
					...group,
					name: group.name,
					capacity: group.maxCapacityEnabled ? group.maxCapacity.toString() : 'N/A',
					attendees: group.attendees.length.toString(),
					sessions: group.sessions.length.toString(),
					id: group._id,
					link: `/${this.props.orgId}/events/${this.props.eventId}/groups/${group._id}`,
					updatedAt: group.updatedAt,
					createdAt: group.createdAt
				}
			],
			newGroups: [...this.state.newGroups, group],
			loading: false,
			editCount: this.state.editCount + 1

		}, () => {
			batchCallback && batchCallback('update');
		});
	};

	toggleDeleteGroups = (checkedGroups, bcb) => {
		batchCallback = bcb;

		this.setState({
			checkedGroups: checkedGroups || {},
			deleteGroupsModalOpen: !this.state.deleteGroupsModalOpen
		});
	};
	deleteCheckedGroups = () => {
		this.setState({ isDeleting: true });
		this.props.deleteGroups(this.state.checkedGroups, () => {
			this.setState({
				checkedGroups: {},
				sortedGroups: this.state.sortedGroups.filter(sg => !Object.keys(this.state.checkedGroups).includes(sg._id)),
				deletedGroups: [...this.state.deletedGroups, ...Object.keys(this.state.checkedGroups)],
				isDeleting: false,
				editCount: this.state.editCount + 1

			}, () => {
				batchCallback && batchCallback('update')
				this.toggleDeleteGroups();
			});

		});
	};

	deleteSingleGroup = (group, callback) => {
		this.setState({ loading: true })
		this.props.deleteGroups(group, (error) => {
			this.setState({
				groupSelected: error ? true : false,
				sortedGroups: this.state.sortedGroups.filter((g) => Object.keys(group)[0] !== g._id),
				deletedGroups: [...this.state.deletedGroups, Object.keys(group)[0]],
				loading: false,
				editCount: this.state.editCount + 1

			});
			callback(error);
		});
	};

	updateGroup = (id, group) => {
		const tempGroups = this.state.sortedGroups.map((g) => {
			if (id == g._id) {
				return {
					...group,
					name: group.name,
					capacity: group.maxCapacityEnabled ? group.maxCapacity.toString() : 'N/A',
					attendees: group.attendees.length.toString(),
					sessions: group.sessions.length.toString(),
					id: group._id,
					link: `/${this.props.orgId}/events/${this.props.eventId}/groups/${group._id}`,
					updatedAt: group.updatedAt,
					createdAt: group.createdAt,

				};
			}
			return g;
		});

		this.props.updateGroup(id, group);
		this.setState({
			sortedGroups: tempGroups,
			editCount: this.state.editCount + 1
		}, () => {

			batchCallback && batchCallback('update');
		});
	};

	toggleAddGroup = (bcb) => {
		const { toggleAddGroup } = this.state;
		batchCallback = bcb;

		this.setState({ toggleAddGroup: !toggleAddGroup })
	}
	setActiveRow = (groupId) => {
		this.setState({ activeRow: groupId })
	}
	render() {
		const {
			eventId,
			deleteGroupsModalOpen,
			deletedGroups,
			sortedGroups,
			columns,
			categoryColumns,
			isDeleting,
			columnTypes,
			newGroups,
			toggleAddGroup,
			loading,
			sidePanelOpen,
			activeRow,
			editCount
		} = this.state;
		const { event, orgId, groupsViews } = this.props;
		return (
			<Switch>

				<Route path={`/:organization/events/:eventId/groups`}>
					<Fragment>
						{!loading &&
							<ViewsContainer
								//key={`groups${editCount}`}
								activeRow={activeRow}
								orgId={orgId}
								views={groupsViews}
								columns={columns}
								categoryColumns={categoryColumns}
								columnTypes={columnTypes}
								entries={sortedGroups}
								addedEntries={newGroups}
								deletedEntries={deletedGroups}
								title="Groups"
								description="Add attendees and session to groups to create custom tracks. "
								readOnly={false}
								mainActions={[
									{ label: 'Add Group', onClick: this.toggleAddGroup, type: "button" },

								]}
								batchActions={[
									{
										label: 'Delete',
										iconClass: 'las la-trash-alt',
										class: 'danger',
										onClick: this.toggleDeleteGroups
									}
								]}
								breadcrumbs={[
									{ name: event.name }
								]}
								enableDownloadViews={true}
								type={'Group'}
								referenceCollection={'Events'}
								referenceId={eventId}
							/>}

						<LoadingWizard loading={loading} text="Loading Groups" />

						{deleteGroupsModalOpen &&
							<DeleteGroupModal
								isOpen={deleteGroupsModalOpen}
								delete={this.deleteCheckedGroups}
								isDeleting={isDeleting}
								toggle={this.toggleDeleteGroups}
								count={Object.keys(this.state.checkedGroups).length}
							/>}
						{toggleAddGroup &&
							<AddGroupModal
								isOpen={toggleAddGroup}
								handleSubmit={this.addGroup}
								toggle={this.toggleAddGroup}
								eventId={eventId}
								orgId={this.props.orgId}
							/>}



						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/events/:eventId/groups/:groupId`}
									render={(props) => {
										const groupId = props.match.params.groupId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={groupId}>

											{!loading && <EventGroup
												event={this.props.event}
												eventId={eventId}
												orgId={this.props.orgId}
												groups={sortedGroups}
												loadMoreAttendees={this.props.loadMoreAttendees}
												loadMoreSessions={this.props.loadMoreSessions}
												updateGroup={this.updateGroup}
												deleteGroup={this.deleteSingleGroup}
												addNewAttendee={this.props.addNewAttendee}
												addNewSession={this.props.addNewSession}
												setActiveRow={this.setActiveRow}
											/>}
											<LoadingWizard loading={loading} />
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/events/:eventId/groups`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route>

			</Switch>
		);
	}
}

export default withRouter(EventGroups);
