import React from 'react';

import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';
import FieldModal from '../../../../components/modals/fieldModal';
import axios from 'axios';

import InputField from '../../../../components/inputs/inputField';
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}

class SendTestEmailModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
			invalid: false,
			attendees: [],
			sessions: [],
			groups: [],
			error: '',
			requiredFields: {},
			sentCount: 0,
			completed: false,
			totalEmails: 0,
			eventSessions: [],
			eventGroups: [],
			eventAttendees: [],
			testEmails: '',
			invalidError: false
		};
	}

	componentDidMount() {
		const { email, orgId, eventId, event } = this.props;
		const { setCount } = this.state;
		let recipients = email.recipients ? email.recipients : {};

		let attendees = recipients.attendees ? recipients.attendees : [];
		let sessions = recipients.sessions ? recipients.sessions : [];

		let groups = recipients.groups ? recipients.groups : [];

		this.setState({
			attendees: attendees,
			sessions: sessions,
			groups: groups
		});
	}

	handleEmailsChange = (e) => {
		this.setState({
			testEmails: e.target.value
		});
	};

	sendTestEmails = async () => {
		const { testEmails } = this.state;
		const { orgId, eventId, email } = this.props;
		let emails = testEmails.replace(/ /g, '').split(',');
		axios.defaults.headers.post['Content-Type'] = 'application/json';

		let valid = true;
		const request = {};

		emails = emails.map((e) => {
			if (!ValidateEmail(e) || '') {
				valid = false;
			}

			if (e !== '') return e;
		});

		if (valid || emails.length == 0) {
			this.setState({ sending: true, invalidError: false, emailsTotal: emails.length });

			if (email.dedicatedEmailServerEnabled) {
				for (let emailCount = 0; emailCount < emails.length; emailCount++) {
					let currentEmail = emails[emailCount];
					//request['id'] = `${this.props.email._id}-${res1.data._id}`;
					request['email'] = `${currentEmail}`;
					await axios
						.post(email.serviceUrl, request)
						.then((res2) => {
							this.setState({ sentCount: this.state.sentCount + 1 });
						})
						.catch((e) => {
							console.log(e);
						});
				}
			} else {
				for (let emailCount = 0; emailCount < emails.length; emailCount++) {
					try {
						const res = await Scheduler()
							.post(`Organizations/${orgId}/events/${eventId}/emails/${email._id}/send`, {
								isTest: true,
								testEmail: emails[emailCount]
							})
							.then((res) => {
								this.setState({ sentCount: this.state.sentCount + 1 });
							});
					} catch (err) {
						console.log(err);
					}
				}
			}

			this.setState({ completed: true });
		} else {
			this.setState({ invalidError: true });
		}
	};
	render() {
		const { isOpen, toggle, email } = this.props;
		const { sending, completed, testEmails, invalidError, emailsTotal, sentCount } = this.state;

		return (
			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Send a Test Email"}
					bodyHeaderText={!sending ? (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							Who would you like to send a test email to?
						</div>
					) : (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							{`Sending ${sentCount} of ${emailsTotal}`}
						</div>
					)}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								{!sending && (
									<InputField
										label="Emails"
										required={true}
										value={testEmails}
										errorTrigger={invalidError}
										errorMessage={'Invalid Email Detected'}
										onChange={this.handleEmailsChange}
										subText={'Use commas to separate multiple emails'}
									/>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Send Test' : completed ? '' : 'Sending...'}
					actionButton={() => this.sendTestEmails()}
					actionButtonDisabled={sending || completed}
					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}



				></FieldModal>
			</div>

		);
	}
}

export default SendTestEmailModal;
