import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import 'react-toggle/style.css';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DraggableField from './draggableField';
import DraggableCustomField from './draggableCustomField';

import RichTextEditor from '../../views/pages/components/richTextEditor';
import InputField from '../inputs/inputField';
import SelectField from '../inputs/selectField';
import ToggleField from '../inputs/toggleField';
import AddCustomFieldModal from '../../sections/event/customFields/addCustomFieldModal';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

const fieldTypes = [
	{ value: 'text', label: 'Text', color: '#00B8D9' },
	{ value: 'email', label: 'Email', color: '#00B8D9' },
	{ value: 'phone', label: 'Phone', color: '#00B8D9' },
	{ value: 'number', label: 'Number', color: '#00B8D9' },
	{ value: 'rating', label: 'Rating', color: '#00B8D9' },
	{ value: 'multipleCheck', label: 'Multiple Check', color: '#00B8D9' },
	{ value: 'textarea', label: 'Text Area', color: '#00B8D9' },
	{ value: 'checkbox', label: 'Checkbox', color: '#00B8D9' },
	{ value: 'select', label: 'Select', color: '#00B8D9' },
	{ value: 'list', label: 'List', color: '#00B8D9' },
	{ value: 'nestedSections', label: 'Nested Sections', color: '#00B8D9' },
	{ value: 'inventory', label: 'Inventory Item', color: '#00B8D9' },
	{ value: 'color', label: 'Color', color: '#00B8D9' },
	{ value: 'date', label: 'Date', color: '#00B8D9' },
	{ value: 'datetime-local', label: 'Date Time Local', color: '#00B8D9' },
	{ value: 'file', label: 'File', color: '#00B8D9' },
	{ value: 'hidden', label: 'Hidden', color: '#00B8D9' },
	{ value: 'month', label: 'Month', color: '#00B8D9' },
	{ value: 'password', label: 'Password', color: '#00B8D9' },
	{ value: 'radio', label: 'Radio', color: '#00B8D9' },
	{ value: 'range', label: 'Range', color: '#00B8D9' },
	{ value: 'search', label: 'Search', color: '#00B8D9' },
	{ value: 'submit', label: 'Submit', color: '#00B8D9' },
	{ value: 'time', label: 'Time', color: '#00B8D9' },
	{ value: 'url', label: 'URL', color: '#00B8D9' },
	{ value: 'week', label: 'Week', color: '#00B8D9' },
	{ value: 'image', label: 'Image', color: '#00B8D9' },
	{ value: 'medProLookup', label: 'MedPro Look up', color: '#00B8D9' },
	{ value: 'salesForceSearchContact', label: 'Salesforce Search Contact', color: '#00B8D9' },
	{ value: 'salesForceSearchAccount', label: 'Salesforce Search Account', color: '#00B8D9' },

];

const unattachedFieldList = [
	{
		_id: 'unattached',
		title: 'Text Components',
		fields: [
			{
				_id: '*text',
				fieldName: 'Text',
				fieldType: 'text',
				required: false,
				propertyType: 'custom',
				icon: <i className="las la-i-cursor" />
			},
			{
				_id: '*email',
				fieldName: 'Email',
				fieldType: 'email',
				required: false,
				propertyType: 'custom',
				icon: <i className="las la-at" />
			},
			{
				_id: '*phone',
				fieldName: 'Phone Number',
				fieldType: 'tel',
				required: false,
				propertyType: 'custom',
				icon: <i className="las la-phone" />
			},
			{
				_id: '*number',
				fieldType: 'number',
				fieldName: 'Number',
				required: false,
				icon: <i className="las la-list-ol" />
			},
			{ _id: '*textarea', fieldType: 'textarea', fieldName: 'Text Area', required: false }
		]
	},
	{
		_id: 'unattached2',
		title: 'Date & Time Components',
		fields: [
			{ _id: '*date', fieldType: 'date', fieldName: 'Date', required: false },
			{ _id: '*datetime-local', fieldType: 'datetime-local', fieldName: 'Date Time Local', required: false },
			{ _id: '*month', fieldType: 'month', fieldName: 'Month', required: false },
			{ _id: '*time', fieldType: 'time', fieldName: 'Time', required: false },
			{ _id: '*week', fieldType: 'week', fieldName: 'Week', required: false }
		]
	},
	{
		_id: 'unattached3',
		title: 'List Components',
		fields: [
			{ _id: '*select', fieldType: 'select', fieldName: 'Select', required: false },
			{ _id: '*color', fieldType: 'color', fieldName: 'Color', required: false },
			{ _id: '*radio', fieldType: 'radio', fieldName: 'Radio', required: false },
			{ _id: '*checkbox', fieldType: 'checkbox', fieldName: 'Checkbox', required: false },
			{ _id: '*multipleCheck', fieldType: 'multipleCheck', fieldName: 'Multiple Check', required: false },
			{ _id: '*nestedSections', fieldType: 'nestedSections', fieldName: 'Nested Sections', required: false }
		]
	},
	{
		_id: 'unattached4',
		title: 'Additional Components',
		fields: [
			{ _id: '*file', fieldType: 'file', fieldName: 'File', required: false },
			{ _id: '*hidden', fieldType: 'hidden', fieldName: 'Hidden', required: false },
			{ _id: '*password', fieldType: 'password', fieldName: 'Password', required: false },
			{ _id: '*range', fieldType: 'range', fieldName: 'Range', required: false },
			{ _id: '*search', fieldType: 'search', fieldName: 'Search', required: false },
			{ _id: '*url', fieldType: 'url', fieldName: 'URL', required: false },
			{ _id: '*image', fieldType: 'image', fieldName: 'Image', required: false },
		]
	}
];

const columnSizeOptions = [
	{ value: 'col-12', label: 'Full Width' },
	{ value: 'col-6', label: 'Half Width' },
	{ value: 'col-4', label: 'Quarter Width' },
	{ value: 'col-3', label: 'Third Width' },
	{ value: 'col-2', label: 'One-Third Width' }
];

class FormBuilder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editedFields: props.fields,
			customFields: this.props.fields.map((category) => {
				const title = category.title;
				const headers = category.fields.map((header) => {
					const name = header.fieldName;
					return {
						_id: header._id,
						category: title,
						categoryId: category._id,
						value: name,
						label: name
					};
				});
				return {
					_id: category._id,
					label: title,
					value: category._id,
					options: headers
				};
			}),
			fieldModal: false,
			adding: false,
			loading: true,
			editing: false,
			removing: false,
			removedCategoryId: 0,
			removedFieldId: 0,
			customFieldsCollapsed: false,
			unattachedFieldsCollapsed: true,
			formButtonsCollapsed: true,
			fieldTypeTabSelected: 0,
			currentPage: 0,
			formEditorTabSelected: 0,
			selectedSection: 0,
			addCustomFieldModalOpen: false,
			detailsExpanded: false,
			detailsOpen: true,
			componentLibraryIsOpen: false,
			focusedFieldIndex: null,
			focusedFieldId: null,
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	selectField = (index) => {
		this.setState({ selectedField: index, fieldSelected: true });
	};

	onDragEnd(result) {
		// dropped outside the list
		const { focusedFieldId } = this.state;
		if (!result.destination) {
			return;
		}
		const { currentPage } = this.state;

		if (result.type == 'field') {
			const updatedForm = this.props.editedForm;
			const sections = updatedForm.pages[currentPage].sections;

			const droppedInSectionIndex = sections.findIndex((sec) => sec._id == result.destination.droppableId);
			if (droppedInSectionIndex > -1) {
				const draggedField = result.source.index;
				if (result.destination.droppableId == result.source.droppableId) {
					sections[droppedInSectionIndex].fields = reorder(
						sections[droppedInSectionIndex].fields,
						draggedField,
						result.destination.index
					);
				} else if (
					result.source.droppableId == 'drop-custom-fields' ||
					result.source.droppableId == 'drop-attendee-fields'
				) {
					const nextIndex = sections[droppedInSectionIndex].fields.length;
					if (draggedField.propertyType == 'base') {
						draggedField._id = `${draggedField._id}`;
					}
					sections[droppedInSectionIndex].fields.push({
						columnClass: 'col-12',
						description: '',
						disabled: false,
						id: draggedField._id,
						label: draggedField.fieldName,
						nestedSections: [],
						objectClass: draggedField._id.startsWith('_')
							? 'baseInput'
							: draggedField._id.startsWith('*') ? 'customInput' : 'mappedInput',
						attendeeField: draggedField._id.startsWith('_')
							? { propertyType: 'base', propertyName: draggedField._id.substring(1) }
							: draggedField._id.startsWith('*')
								? { propertyType: 'unattached' }
								: { propertyType: 'custom', customField: draggedField._id },
						options: draggedField.options || [],
						placeholder: draggedField.placeholder,
						required: draggedField.required,
						type: draggedField.fieldType,
						_id: draggedField._id.startsWith('*')
							? `${draggedField._id}_${droppedInSectionIndex}_${sections[
								droppedInSectionIndex
							].fields.filter((ff) => ff._id.includes(draggedField._id)).length}`
							: draggedField._id,
						moduleConfiguration: draggedField.moduleConfiguration
					});
					sections[droppedInSectionIndex].fields = reorder(
						sections[droppedInSectionIndex].fields,
						nextIndex,
						result.destination.index
					);
				} else if (result.source.droppableId == 'drop-reg-buttons') {
					const nextIndex = sections[droppedInSectionIndex].buttons.length;
					sections[droppedInSectionIndex].buttons.push(result.source.index);
					sections[droppedInSectionIndex].buttons = reorder(
						sections[droppedInSectionIndex].buttons,
						nextIndex,
						result.destination.index
					);
				} else if (result.source.droppableId != result.destination.droppableId) {
					const removeFromSectionIndex = sections.findIndex((sec) => sec._id == result.source.droppableId);
					if (removeFromSectionIndex > -1) {
						const nextIndex = sections[droppedInSectionIndex].fields.length;
						const removedField = sections[removeFromSectionIndex].fields[result.source.index];
						sections[removeFromSectionIndex].fields.splice(result.source.index, 1);
						sections[droppedInSectionIndex].fields.push(removedField);
						sections[droppedInSectionIndex].fields = reorder(
							sections[droppedInSectionIndex].fields,
							nextIndex,
							result.destination.index
						);
					}
				}
				this.props.updateForm(updatedForm);
			}
		} else if (result.type == 'section') {
			const updatedForm = this.props.editedForm;
			const sections = updatedForm.pages[currentPage].sections;
			const reorderedSections = reorder(sections, result.source.index, result.destination.index);
			updatedForm.pages[currentPage].sections = reorderedSections;
			this.props.updateForm(updatedForm);
		}
		if (focusedFieldId) {
			this.setState({
				focusedFieldIndex: result.destination.index
			});
		}
	}

	closeFieldModal = () => {
		this.setState({
			adding: false,
			fieldSelected: false
		});
	};

	cancelEdit = () => {
		this.props.cancelEdit();
		this.setState({
			fieldSelected: false,
			focusOnField: false,
			focusedFieldId: null,
			focusedFieldIndex: null,
			focusedSectionId: null
		});
	};

	updateFormField = (sectionId, fieldId, propertyName, value) => {
		//console.log(sectionId, fieldId, propertyName, value);
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedFieldIndex = sections[editedSectionIndex].fields.findIndex((f) => f._id == fieldId);
			if (updatedFieldIndex > -1) {
				const updatedField = sections[editedSectionIndex].fields[updatedFieldIndex];
				updatedField[propertyName] = value;
				sections[editedSectionIndex].fields[updatedFieldIndex] = updatedField;

				this.props.updateForm(updatedForm);
			}
		}
	};

	updateNestedFormField = (sectionId, fieldId, nestedSectionId, nestedFieldId, propertyName, value) => {
		const updatedForm = this.props.editedForm;
		const specialFields = ['salesForceSearchContact', 'salesForceSearchAccount'];
		const baseFieldList = this.props.baseFieldList;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedFieldIndex = sections[editedSectionIndex].fields.findIndex((f) => f._id == fieldId);
			if (updatedFieldIndex > -1) {
				const updatedField = sections[editedSectionIndex].fields[updatedFieldIndex];
				const nestedSectionIndex = updatedField.nestedSections.findIndex((ns) => ns._id == nestedSectionId);
				if (nestedSectionIndex > -1) {
					if (!nestedFieldId) {
						updatedField.nestedSections[nestedSectionIndex][propertyName] = value;
					} else {
						const nestedFieldIndex = updatedField.nestedSections[nestedSectionIndex].questions.findIndex(
							(nsq) => nsq._id == nestedFieldId
						);
						if (nestedFieldIndex > -1) {
							updatedField.nestedSections[nestedSectionIndex].questions[nestedFieldIndex][
								propertyName
							] = value;
							if (propertyName === 'type' && specialFields.includes(value)) {
								//find the value in the baseFieldList
								let baseField = baseFieldList.find((f) => f._id === 'special');
								let fieldType = baseField.fields.find((f) => f.fieldType === value);
								//assign the moduleConfiguration if it doesn't exist
								if (!updatedField.nestedSections[nestedSectionIndex].questions[nestedFieldIndex].moduleConfiguration) {
									updatedField.nestedSections[nestedSectionIndex].questions[nestedFieldIndex].moduleConfiguration = fieldType.moduleConfiguration;
								}
							}
						}
					}
				}

				//updatedField[propertyName] = value;
				sections[editedSectionIndex].fields[updatedFieldIndex] = updatedField;
				this.props.updateForm(updatedForm);
			}
		}
	};

	updateFormFieldConfig = (sectionId, fieldId, propertyName, value) => {
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedFieldIndex = sections[editedSectionIndex].fields.findIndex((f) => f._id == fieldId);
			if (updatedFieldIndex > -1) {
				const updatedField = sections[editedSectionIndex].fields[updatedFieldIndex];
				updatedField.moduleConfiguration[propertyName] = value;
				sections[editedSectionIndex].fields[updatedFieldIndex] = updatedField;
				this.props.updateForm(updatedForm);
			}
		}
	};

	updateFormFieldConfigField = (sectionId, fieldId, configFieldId, propertyName, value) => {
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedFieldIndex = sections[editedSectionIndex].fields.findIndex((f) => f._id == fieldId);
			if (updatedFieldIndex > -1) {
				const updatedField = sections[editedSectionIndex].fields[updatedFieldIndex];
				const configFieldIndex = updatedField.moduleConfiguration.fields.findIndex(
					(cf) => cf._id == configFieldId
				);
				if (configFieldIndex > -1) {
					updatedField.moduleConfiguration.fields[configFieldIndex][propertyName] = value;
				}
				sections[editedSectionIndex].fields[updatedFieldIndex] = updatedField;
				this.props.updateForm(updatedForm);
			}
		}
	};

	updateFormButton = (sectionId, buttonId, propertyName, value) => {
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedButtonIndex = sections[editedSectionIndex].buttons.findIndex((b) => b._id == buttonId);
			if (updatedButtonIndex > -1) {
				const updatedButton = sections[editedSectionIndex].buttons[updatedButtonIndex];
				updatedButton[propertyName] = value;
				sections[editedSectionIndex].buttons[updatedButtonIndex] = updatedButton;
				this.props.updateForm(updatedForm);
			}
		}
	};

	addNewSection = () => {
		const updatedForm = this.props.editedForm;
		const { currentPage } = this.state;
		console.log(currentPage);
		updatedForm.pages[currentPage].sections.push({
			_id: `^${currentPage}-${updatedForm.pages[currentPage].sections.length}`,
			title: '',
			fields: [],
			buttons: []
		});
		this.props.updateForm(updatedForm);
	};

	removeSection = (sectionId) => {
		const updatedForm = this.props.editedForm;
		const updatedSections = updatedForm.pages[this.state.currentPage].sections.filter((s) => s._id != sectionId);
		updatedForm.pages[this.state.currentPage].sections = updatedSections;
		this.props.updateForm(updatedForm);
	};

	moveSection = (direction, sectionId) => {
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const sectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (sectionIndex > -1) {
			const section = sections[sectionIndex];
			sections.splice(sectionIndex, 1);
			if (direction == 'sectionUp') {
				if (sectionIndex == 0 && this.state.currentPage > 0) {
					updatedForm.pages[this.state.currentPage - 1].sections.push(section);
				} else if (sectionIndex > 0) {
					sections.splice(sectionIndex - 1, 0, section);
				}
			} else if (direction == 'sectionDown') {
				if (sectionIndex == sections.length && this.state.currentPage < updatedForm.pages.length - 1) {
					updatedForm.pages[this.state.currentPage + 1].sections.unshift(section);
				} else if (sectionIndex < sections.length) {
					sections.splice(sectionIndex + 1, 0, section);
				}
			}
			updatedForm.pages[this.state.currentPage].sections = sections;
			this.props.updateForm(updatedForm);
		}
	};

	removeFormField = (sectionId, fieldId) => {
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedFieldIndex = sections[editedSectionIndex].fields.findIndex((f) => f._id == fieldId);
			if (updatedFieldIndex > -1) {
				sections[editedSectionIndex].fields.splice(updatedFieldIndex, 1);
				this.props.updateForm(updatedForm);
				this.setState({
					fieldSelected: false,
					focusedFieldId: null,
					focusedFieldIndex: null,
					focusedSection: null
				});
			}
		}
	};

	removeFormButton = (sectionId, buttonId) => {
		const updatedForm = this.props.editedForm;
		const sections = updatedForm.pages[this.state.currentPage].sections;
		const editedSectionIndex = sections.findIndex((s) => s._id == sectionId);
		if (editedSectionIndex > -1) {
			const updatedButtonIndex = sections[editedSectionIndex].buttons.findIndex((b) => b._id == buttonId);
			if (updatedButtonIndex > -1) {
				sections[editedSectionIndex].buttons.splice(updatedButtonIndex, 1);
				this.props.updateForm(updatedForm);
			}
		}
	};

	editFormField = (property, value) => {
		const updatedForm = this.props.editedForm;
		updatedForm[property] = value;
		this.props.updateForm(updatedForm);
	};

	addField = (data, cb) => {
		const { focusedField, customFields } = this.state;
		const { editedForm } = this.props;
		let existingCategory = false;
		const updatedCustomFields = customFields.map((cf) => {
			if (cf._id == data._id) {
				existingCategory = true;
				cf.options = data.fields.map((f) => {
					const name = f.fieldName;
					return {
						_id: f._id,
						category: f.title,
						categoryId: f._id,
						value: name,
						label: name
					};
				});
			}
			return cf;
		});
		if (!existingCategory) {
			updatedCustomFields.push({
				_id: data._id,
				category: data.title,
				label: data.title,
				value: data._id,
				options: data.fields.map((f) => {
					const name = f.fieldName;
					return {
						_id: f._id,
						category: data.title,
						categoryId: data._id,
						value: name,
						label: name
					};
				})
			});

		}
		const updatedFormPages = editedForm.pages.map(page => {
			page.sections = page.sections.map((section) => {
				if (section._id == focusedField.section) {
					if (focusedField.nested) {
						const focusedFieldIndex = section.fields.findIndex((field) => {
							return field._id == focusedField.fieldId;
						});
						if (focusedFieldIndex > -1) {
							const focusedNestedSectionIndex = section.fields[focusedFieldIndex].nestedSections.findIndex(
								(ns) => ns._id == focusedField.nestedSectionId
							);
							if (focusedNestedSectionIndex > -1) {
								const focusedNestedFieldIndex = section.fields[focusedFieldIndex].nestedSections[
									focusedNestedSectionIndex
								].questions.findIndex((nsq) => nsq._id == focusedField.id);
								if (focusedNestedFieldIndex > -1) {
									section.fields[focusedFieldIndex].nestedSections[focusedNestedSectionIndex].questions[
										focusedNestedFieldIndex
									].id =
										data.fields[data.fields.length - 1]._id;
									section.fields[focusedFieldIndex].nestedSections[focusedNestedSectionIndex].questions[
										focusedNestedFieldIndex
									].attendeeField.propertyType =
										'custom';
									section.fields[focusedFieldIndex].nestedSections[focusedNestedSectionIndex].questions[
										focusedNestedFieldIndex
									].objectClass =
										'mappedInput';
								}
							}
						}
					} else {
						const focusedFieldIndex = section.fields.findIndex((field) => {
							return field._id == focusedField.id;
						});
						if (focusedFieldIndex > -1) {
							section.fields[focusedFieldIndex].id = data.fields[data.fields.length - 1]._id;
							section.fields[focusedFieldIndex].attendeeField.propertyType = 'custom';
							section.fields[focusedFieldIndex].objectClass = 'mappedInput';
						}
					}
				}
				return section;
			});
			return page;
		});
		const updatedForm = { ...editedForm, pages: updatedFormPages };
		this.props.updateForm(updatedForm);
		this.setState({
			customFields: updatedCustomFields
		});

		this.props.addNewField && this.props.addNewField('attendees', data, cb);
		//cb && cb();
	};

	renderNestedFieldConfig = (section, field, nestedSection, nestedField) => {
		let configElement;

		switch (nestedField.type) {
			case 'list':
				configElement = (
					<div>
						<div className="labelContainer">
							<div className="formFieldLabel">Options</div>
							<div
								className="se-blue c-pointer"
								onClick={() => {
									const updatedOptions = nestedField.options;
									updatedOptions.push('');
									this.updateNestedFormField(
										section._id,
										field._id,
										nestedSection._id,
										nestedField._id,
										'options',
										updatedOptions
									);
								}}
							>
								+ Add option
							</div>
						</div>
						<div className="formEditlistOptions">
							{nestedField.options.map((opt, oi) => {
								return (
									<div className="flex-ac mb-10">
										<InputField
											value={opt}
											classes="m-0"
											onChange={(e) => {
												const updatedOptions = nestedField.options;
												updatedOptions[oi] = e.target.value;
												this.updateNestedFormField(
													section._id,
													field._id,
													nestedSection._id,
													nestedField._id,
													'options',
													updatedOptions
												);
											}}
										/>
										<i
											className="las la-times ml-10 c-pointer"
											onClick={() => {
												const updatedOptions = nestedField.options.filter((op) => op !== opt);
												this.updateNestedFormField(
													section._id,
													field._id,
													nestedSection._id,
													nestedField._id,
													'options',
													updatedOptions
												);
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				);
				break;
			case 'email':
				configElement = <div />;
				break;
			case 'medProLookup':
				const updatedModuleConfigurations = nestedField.moduleConfiguration ? nestedField.moduleConfiguration : {};

				configElement = <div >
					<ToggleField
						label="Capture Data"
						checked={updatedModuleConfigurations.captureData}
						icons={false}
						onChange={(e) => {
							this.updateNestedFormField(section._id, field._id, nestedSection._id,
								nestedField._id, 'moduleConfiguration', { ...updatedModuleConfigurations, captureData: e.target.checked })
						}}
					/></div>;
				break;
			case 'salesForceSearchContact':
				const updatedModuleConfigurationsSFC = nestedField.moduleConfiguration ? nestedField.moduleConfiguration : {};

				configElement = <div >
					<ToggleField
						label="Do not add to campaign"
						checked={updatedModuleConfigurationsSFC.doNotAddToCampaign}
						icons={false}
						onChange={(e) => {
							this.updateNestedFormField(section._id, field._id, nestedSection._id,
								nestedField._id, 'moduleConfiguration', { ...updatedModuleConfigurationsSFC, doNotAddToCampaign: e.target.checked })
						}}
					/></div>;
				break;
			case 'salesForceSearchAccount':
				/* const updatedModuleConfigurationsSFA = nestedField.moduleConfiguration ? nestedField.moduleConfiguration : {};

				configElement = <div >
					<ToggleField
						label="Capture Data"
						checked={updatedModuleConfigurationsSFA.captureData}
						icons={false}
						onChange={(e) => {
							this.updateNestedFormField(section._id, field._id, nestedSection._id,
								nestedField._id, 'moduleConfiguration', { ...updatedModuleConfigurationsSFA, captureData: e.target.checked })
						}}
					/></div>; */
				break;
			case 'multipleCheck':
				configElement = (
					<div>
						<div className="labelContainer">
							<div className="formFieldLabel">Options</div>

							<div
								className="se-blue c-pointer "
								onClick={() => {
									const updatedOptions = nestedField.options;
									updatedOptions.push('');
									this.updateNestedFormField(
										section._id,
										field._id,
										nestedSection._id,
										nestedField._id,
										'options',
										updatedOptions
									);
								}}
							>
								+ Add option
							</div>
						</div>
						<div className="formEditlistOptions">
							{nestedField.options.map((opt, oi) => {
								return (
									<div className="flex-ac mb-10">
										<InputField
											value={opt}
											classes="m-0"
											onChange={(e) => {
												const updatedOptions = nestedField.options;
												updatedOptions[oi] = e.target.value;
												this.updateNestedFormField(
													section._id,
													field._id,
													nestedSection._id,
													nestedField._id,
													'options',
													updatedOptions
												);
											}}
										/>
										<i
											className="las la-times ml-10 c-pointer"
											onClick={() => {
												const updatedOptions = nestedField.options.filter((op) => op !== opt);
												this.updateNestedFormField(
													section._id,
													field._id,
													nestedSection._id,
													nestedField._id,
													'options',
													updatedOptions
												);
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				);
				break;
			case 'select':
				configElement = (
					<div className="formEditlistOptions">
						<div className="labelContainer">
							<div className="formFieldLabel">Options</div>

							<div
								className="se-blue c-pointer "
								onClick={() => {
									const updatedOptions = nestedField.options;
									updatedOptions.push('');
									this.updateNestedFormField(
										section._id,
										field._id,
										nestedSection._id,
										nestedField._id,
										'options',
										updatedOptions
									);
								}}
							>
								+ Add option
							</div>
						</div>
						<div>
							{nestedField.options &&
								nestedField.options.map((opt, oi) => {
									return (
										<div className="flex-ac mb-10">
											<InputField
												value={opt}
												classes="m-0"
												onChange={(e) => {
													const updatedOptions = nestedField.options;
													updatedOptions[oi] = e.target.value;
													this.updateNestedFormField(
														section._id,
														field._id,
														nestedSection._id,
														nestedField._id,
														'options',
														updatedOptions
													);
													this.updateNestedFormField(
														section._id,
														field._id,
														nestedSection._id,
														nestedField._id,
														'defaultValue',
														''
													);
												}}
											/>
											<i
												className="las la-times ml-10 c-pointer"
												onClick={() => {
													const updatedOptions = nestedField.options.filter(
														(op) => op !== opt
													);
													this.updateNestedFormField(
														section._id,
														field._id,
														nestedSection._id,
														nestedField._id,
														'options',
														updatedOptions
													);
												}}
											/>
										</div>
									);
								})}
						</div>

						<ToggleField
							label="Enable 'Other' Option"
							checked={nestedField.enableOtherOption}
							icons={false}
							onChange={(e) => {
								const r = e.target.checked;
								this.updateNestedFormField(
									section._id,
									field._id,
									nestedSection._id,
									nestedField._id,
									'enableOtherOption',
									r
								);
							}}
						/>
					</div>
				);
				break;
			case 'rating':
				configElement = <div />;
				break;
			default:
				break;
		}
		return configElement ? (
			<div className="fieldTypeConfig mt-10">
				<label className="calibreSemiBold">Config</label>
				{configElement}
			</div>
		) : (
			''
		);
	};

	renderFocusedFieldConfig = (section, field) => {
		const { detailsExpanded } = this.state;
		let configElement;
		let isModuleField = false;
		if (field) {
			const attendeeFieldType = field && field.attendeeField ? field.attendeeField.propertyType : 'unattached';
			let currentCustomField = {};
			this.state.customFields.forEach((c) =>
				c.options.forEach((o) => {
					if (o._id == field.id) {
						currentCustomField = o;
					}
				})
			);

			switch (field.type) {
				case 'nestedSections':
					const formattedOptions = field.options
						? field.options.map((opt) => {
							return { label: opt, value: opt.replace(/ /g, '') };
						})
						: [];
					configElement = (
						<div>
							<div className="formFieldContainer">
								<div className="labelContainer">
									<div className="formFieldLabel">Options</div>
									<div
										className="se-blue c-pointer "
										onClick={() => {
											const updatedOptions = field.options;
											updatedOptions.push('');
											this.updateFormField(section._id, field._id, 'options', updatedOptions);
										}}
									>
										+ Add option
									</div>
								</div>
								<div className="formEditlistOptions">
									{field.options.map((opt, oi) => {
										return (
											<div className="flex-ac mb-10">
												<InputField
													value={opt}
													classes="m-0"
													onChange={(e) => {
														const updatedOptions = field.options;
														updatedOptions[oi] = e.target.value;
														this.updateFormField(
															section._id,
															field._id,
															'options',
															updatedOptions
														);
													}}
												/>
												<i
													className="las la-times ml-10 c-pointer"
													onClick={() => {
														const updatedOptions = field.options.filter((op) => op !== opt);
														this.updateFormField(
															section._id,
															field._id,
															'options',
															updatedOptions
														);
													}}
												/>
											</div>
										);
									})}
								</div>
							</div>
							<div className="formFieldContainer">
								<div className="labelContainer mb-10">
									<div className="">Sections</div>
									<div
										className="se-blue c-pointer "
										onClick={() => {
											const updatedNestedSections = field.nestedSections || [];
											updatedNestedSections.push({
												_id: `_ns_${updatedNestedSections.length}`,
												trigger: '',
												sectionTitle: '',
												questions: []
											});
											this.updateFormField(
												section._id,
												field._id,
												'nestedSections',
												updatedNestedSections
											);
										}}
									>
										+ Add nested section
									</div>
								</div>
								<div className="nestedSectionFormEditor ">
									{field.nestedSections.map((ns, nsi) => (
										<div className="formFieldContainer" key={
											`nestedSection-${nsi}`}>
											<div className="labelContainer mb-10">
												<div className="">Section {nsi + 1}</div>

												<div className="flex-ac">
													{nsi > 0 && (
														<div
															className=" mr-10 c-pointer"
															onClick={() => {
																//move section up
																const updatedNestedSections = field.nestedSections;
																const temp = updatedNestedSections[nsi - 1];
																updatedNestedSections[nsi - 1] = updatedNestedSections[nsi];
																updatedNestedSections[nsi] = temp;
																this.updateFormField(
																	section._id,
																	field._id,
																	'nestedSections',
																	updatedNestedSections
																);
															}}
														>
															<i className="las la-caret-square-up" /> Move up
														</div>
													)}
													{nsi < field.nestedSections.length - 1 && (
														<div
															className=" mr-10 c-pointer"
															onClick={() => {
																//move section down
																const updatedNestedSections = field.nestedSections;
																const temp = updatedNestedSections[nsi + 1];
																updatedNestedSections[nsi + 1] = updatedNestedSections[nsi];
																updatedNestedSections[nsi] = temp;
																this.updateFormField(
																	section._id,
																	field._id,
																	'nestedSections',
																	updatedNestedSections
																);
															}}
														>
															<i className="las la-caret-square-down" /> Move Down
														</div>
													)}
													<div
														onClick={() => {
															//remove section
															const updatedNestedSections = field.nestedSections;
															updatedNestedSections.splice(nsi, 1);
															this.updateFormField(
																section._id,
																field._id,
																'nestedSections',
																updatedNestedSections
															);
														}}
														className="  c-pointer red"
													>
														<i className="las la-trash" /> Remove
													</div>
												</div>
											</div>
											<SelectField

												classes="w-100"
												label="Trigger"
												value={formattedOptions.find((t) => t.value == ns.trigger)}
												options={formattedOptions}
												onChange={(e) => {
													this.updateNestedFormField(
														section._id,
														field._id,
														ns._id,
														false,
														'trigger',
														e.value
													);
												}}
											/>
											<div className="labelContainer">
												<div className="formFieldLabel">Section Title</div>
											</div>
											<RichTextEditor
												id={`field-${field._id}-nestedSectionTitle${nsi}`}
												value={ns.sectionTitle}
												onChange={(e) =>
													this.updateNestedFormField(
														section._id,
														field._id,
														ns._id,
														false,
														'sectionTitle',
														e
													)}
												options={['image']}
												fontFamilies={[
													"'Museo Sans 300'",
													"'Museo Sans 500'",
													"'Museo Sans 700'",
													"'Proxima Nova Regular'",
													"'Proxima Nova Bold'"
												]}
												customFields={this.props.customFields}
											/>
											<div className="labelContainer mt-25 mb-10">
												<div className="">Section Questions</div>
												<div
													className="ml-a se-blue c-pointer "
													onClick={() => {
														const updatedQuestions = ns.questions || [];
														updatedQuestions.push({
															attendeeField: { propertyType: 'unattached' },
															columnClass: 'col-12',
															description: '',
															disabled: false,
															id: '',
															label: '',
															objectClass: 'customInput',
															options: [],
															placeholder: '',
															required: false,
															style: '',
															type: 'custom',
															_id: `*newquestion${ns.questions.length}`
														});
														this.updateNestedFormField(
															section._id,
															field._id,
															ns._id,
															false,
															'questions',
															updatedQuestions
														);
													}}
												>
													+ Add question
												</div>
											</div>
											<div className="w-100">
												{ns.questions.map((nsq, qi) => {
													const attendeeNSFieldType =
														ns && nsq.attendeeField
															? nsq.attendeeField.propertyType
															: 'unattached';
													let currentNSCustomField = {};
													this.state.customFields.forEach((c) =>
														c.options.forEach((o) => {
															if (o._id == nsq.id) {
																currentNSCustomField = o;
															}
														})
													);
													return (
														<div className="w-100 mb-20 nestedSectionQuestionsFormEditor">
															<div className="labelContainer mb-10">
																<div className="">
																	Question {qi + 1}
																</div>
																<div className="flex-ac">
																	{qi > 0 && (
																		<div
																			className=" mr-10 c-pointer"
																			onClick={() => {
																				//move question up
																				const updatedQuestions = ns.questions;
																				const temp = updatedQuestions[qi - 1];
																				updatedQuestions[qi - 1] =
																					updatedQuestions[qi];
																				updatedQuestions[qi] = temp;
																				this.updateNestedFormField(
																					section._id,
																					field._id,
																					ns._id,
																					false,
																					'questions',
																					updatedQuestions
																				);
																			}}
																		>
																			<i className="las la-caret-square-up" /> Move up
																		</div>
																	)}
																	{qi < ns.questions.length - 1 && (
																		<div
																			className=" mr-10 c-pointer"
																			onClick={() => {
																				const updatedQuestions = ns.questions.slice(
																					0
																				);
																				updatedQuestions.splice(
																					qi,
																					0,
																					updatedQuestions.splice(qi + 1, 1)[0]
																				);
																				this.updateNestedFormField(
																					section._id,
																					field._id,
																					ns._id,
																					false,
																					'questions',
																					updatedQuestions
																				);
																			}}
																		>
																			<i className="las la-caret-square-down" /> Move
																			Down
																		</div>
																	)}
																	<div
																		onClick={() => {
																			const updatedQuestions = ns.questions.filter(
																				(qs) => qs._id !== nsq._id
																			);
																			this.updateNestedFormField(
																				section._id,
																				field._id,
																				ns._id,
																				false,
																				'questions',
																				updatedQuestions
																			);
																		}}
																		className="  c-pointer red"
																	>
																		<i className="las la-trash" /> Remove
																	</div>
																</div>
															</div>

															<SelectField
																label="Attendee Field"

																classes="w-100"
																value={currentNSCustomField}
																options={this.state.customFields}
																actionMessage={
																	<div
																		className="se-blue c-pointer"
																		onClick={() =>
																			this.setState({
																				addCustomFieldModalOpen: true,
																				focusedField: {
																					section: section._id,
																					nested: true,
																					id: nsq._id,
																					label: field.label
																				}
																			})}
																	>
																		Create custom field
																	</div>
																}
																actionOn={attendeeNSFieldType == 'unattached'}
																formatGroupLabel={(data) => (
																	<div style={groupStyles}>
																		<span>{data.label}</span>
																		<span style={groupBadgeStyles}>
																			{data.options.length}
																		</span>
																	</div>
																)}
																onChange={(data) => {
																	this.updateNestedFormField(
																		section._id,
																		field._id,
																		ns._id,
																		nsq._id,
																		'id',
																		data._id
																	);
																	this.updateNestedFormField(
																		section._id,
																		field._id,
																		ns._id,
																		nsq._id,
																		'attendeeField',
																		{
																			...field.attendeeField,
																			propertyName: data._id,
																			propertyType: 'custom'
																		}
																	);
																	this.updateNestedFormField(
																		section._id,
																		field._id,
																		ns._id,
																		nsq._id,
																		'objectClass',
																		'mappedInput'
																	);
																}}
															/>
															<InputField
																label="Label"
																value={nsq.label}
																onChange={(e) => {
																	this.updateNestedFormField(
																		section._id,
																		field._id,
																		ns._id,
																		nsq._id,
																		'label',
																		e.target.value
																	);
																}}
															/>
															<div className="flex-ac">
																<SelectField
																	label="Field Type"

																	classes="w-100"
																	value={fieldTypes.find((t) => t.value == nsq.type)}
																	name="fieldType"
																	style={{ marginRight: 10 }}
																	options={fieldTypes}
																	required
																	onChange={(e) => {
																		this.updateNestedFormField(
																			section._id,
																			field._id,
																			ns._id,
																			nsq._id,
																			'type',
																			e.value
																		);
																	}}
																/>
																<SelectField
																	label="Column Size"

																	classes="w-100"
																	value={columnSizeOptions.find(
																		(cso) => cso.value == nsq.columnClass
																	)}
																	options={columnSizeOptions}
																	onChange={(e) => {
																		this.updateNestedFormField(
																			section._id,
																			field._id,
																			ns._id,
																			nsq._id,
																			'columnClass',
																			e.value
																		);
																	}}
																/>
															</div>

															<div className="flex-ac">
																{nsq.type === 'select' ?
																	<SelectField
																		classes="mr-10"
																		label="Default Value"
																		value={nsq.defaultValue ? { value: nsq.defaultValue, label: nsq.defaultValue } : ''}
																		options={[{ label: '', value: '' }, ...nsq.options.map((o) => ({ label: o, value: o }))]}
																		onChange={(e) => this.updateNestedFormField(
																			section._id,
																			field._id,
																			ns._id,
																			nsq._id,
																			'defaultValue',
																			e.value
																		)}
																	/> :
																	<InputField
																		label="Default Value"
																		value={nsq.defaultValue}
																		type={nsq.type == 'date' ? 'date' : ''}
																		inputIcon={field.type == 'date' && <i className="lar la-calendar" />}
																		onChange={(e) => {
																			this.updateNestedFormField(
																				section._id,
																				field._id,
																				ns._id,
																				nsq._id,
																				'defaultValue',
																				e.target.value
																			);
																		}}
																		classes="mr-10"
																	/>}
																<InputField
																	label="PlaceHolder"
																	type={nsq.type == 'date' ? 'date' : ''}
																	inputIcon={field.type == 'date' && <i className="lar la-calendar" />}
																	value={nsq.placeholder}
																	onChange={(e) =>
																		this.updateNestedFormField(
																			section._id,
																			field._id,
																			ns._id,
																			nsq._id,
																			'placeholder',
																			e.target.value
																		)}
																/>
															</div>
															<ToggleField
																label="Required"
																checked={nsq.required}
																icons={false}
																onChange={(e) => {
																	const r = e.target.checked;
																	this.updateNestedFormField(
																		section._id,
																		field._id,
																		ns._id,
																		nsq._id,
																		'required',
																		r
																	);
																}}
															/>
															{this.renderNestedFieldConfig(section, field, ns, nsq)}
														</div>
													);
												})}
											</div>
											<hr />
										</div>
									))}
								</div>
							</div>
						</div>
					);
					break;
				case 'email':
					configElement = <div />;
					break;
				case 'multipleCheck':
				case 'select':
				case 'list':
					configElement = (
						<div className="formFieldContainer">
							<div className="labelContainer">
								<div className="formFieldLabel">Options</div>
								<div
									className="se-blue c-pointer "
									onClick={() => {
										const updatedOptions = field.options;
										updatedOptions.push('');
										this.updateFormField(section._id, field._id, 'options', updatedOptions);
									}}
								>
									+ Add option
								</div>
							</div>
							<div className="formEditlistOptions">
								{field.options.map((opt, oi) => {
									return (
										<div className="flex-ac mb-10">
											<InputField
												value={opt}
												classes="m-0"
												onChange={(e) => {
													const updatedOptions = field.options;
													updatedOptions[oi] = e.target.value;
													this.updateFormField(section._id, field._id, 'options', updatedOptions);
													this.updateFormField(
														section._id,
														field._id,
														'defaultValue',
														''
													);
												}}
											/>
											<i
												className="las la-times ml-10 c-pointer"
												onClick={() => {
													const updatedOptions = field.options.filter((op) => op !== opt);
													this.updateFormField(section._id, field._id, 'options', updatedOptions);
													this.updateFormField(
														section._id,
														field._id,
														'defaultValue',
														''
													);
												}}
											/>
										</div>
									);
								})}
							</div>
							<ToggleField
								label="Enable 'Other' Option"
								checked={field.enableOtherOption}
								icons={false}
								onChange={(e) => {
									const r = e.target.checked;
									this.updateFormField(section._id, field._id, 'enableOtherOption', r);
								}}
							/>
						</div>
					);
					break;
				case 'radio':
					configElement = (
						<div className="formFieldContainer">
							<div className="labelContainer">
								<div className="formFieldLabel">Options</div>
								<div
									className="se-blue c-pointer "
									onClick={() => {
										const updatedOptions = field.options ? field.options : [];
										updatedOptions.push({ label: '', value: '' });
										this.updateFormField(section._id, field._id, 'options', updatedOptions);
									}}
								>
									+ Add option
								</div>
							</div>
							<div className="formEditlistOptions">
								{field.options && field.options.map((option, ri) => {
									return (
										<div className="flex-ac mb-10">
											<InputField
												label="Label"
												value={option}
												onChange={(e) => {
													const updatedOptions = field.options;
													updatedOptions[ri] = e.target.value;
													this.updateFormField(section._id, field._id, 'options', updatedOptions);
												}}
											/>
											
											<i
												className="las la-times ml-10 c-pointer"
												onClick={() => {
													const updatedOptions = field.options.filter((r, rfi) => ri !== rfi);
													this.updateFormField(section._id, field._id, 'options', updatedOptions);
												}}
											/>
										</div>
									);
								})}
							</div>
						</div>
					);
					break;
				case 'rating':
					configElement = (
						<div className="formFieldContainer">
							<div className="labelContainer">
								<div className="formFieldLabel">Ratings</div>
								<div
									className="se-blue c-pointer "
									onClick={() => {
										const updatedRatings = field.ratingScale ? field.ratingScale : [];
										updatedRatings.push({ label: '', value: '' });
										this.updateFormField(section._id, field._id, 'ratingScale', updatedRatings);
									}}
								>
									+ Add option
								</div>
							</div>
							<div className="formEditlistOptions">
								{field.ratingScale && field.ratingScale.map((rating, ri) => {
									return (
										<div className="flex-ac mb-10">
											<InputField
												label="Label"
												value={rating.label}
												onChange={(e) => {
													const updatedRatings = field.ratingScale;
													updatedRatings[ri].label = e.target.value;
													this.updateFormField(section._id, field._id, 'ratingScale', updatedRatings);
												}}
											/>
											<InputField
												value={rating.value}
												label="Value"
												classes="ml-10"
												onChange={(e) => {
													const updatedRatings = field.ratingScale;
													updatedRatings[ri].value = e.target.value;
													this.updateFormField(section._id, field._id, 'ratingScale', updatedRatings);
												}}
											/>
											<i
												className="las la-times ml-10 c-pointer"
												onClick={() => {
													const updatedRatings = field.ratingScale.filter((r, rfi) => ri !== rfi);
													this.updateFormField(section._id, field._id, 'ratingScale', updatedRatings);
												}}
											/>
										</div>
									);
								})}
							</div>
						</div>
					);
					break;
				case 'inventory':
					const inventoryOptions = this.props.program.eventDefaults.inventory.map((opt) => {
						return {
							label: opt.name,
							value: opt._id
						};
					});
					configElement = (
						<div className="formEditlistOptions">
							<SelectField
								label="Inventory Item"

								classes="w-100"
								value={inventoryOptions.find((t) => t.value == field.id)}
								name="Inventory Item"
								options={inventoryOptions}
								required
								onChange={(e) => {
									this.updateFormField(section._id, field._id, 'id', e.value);
								}}
							/>
						</div>
					);
					break;
				case 'additionalAttendees':
					isModuleField = true;
					const statusOptions = [
						{ label: 'Registered', value: 'registered' },
						{
							label: 'Invited',
							value: 'invited'
						}
					];
					const attendeeFields = field.moduleConfiguration.fields || [];
					configElement = (
						<div className="formFieldContainer">
							<div className=" w-100 flex-sb flex-ac mt-10">
								<h4>{field.label}</h4>
							</div>
							<InputField
								label="Attendee Label"
								value={field.moduleConfiguration.label}
								onChange={(e) =>
									this.updateFormFieldConfig(section._id, field._id, 'label', e.target.value)}
							/>
							<InputField
								type="number"
								label="Max Additional Attendees"
								value={field.moduleConfiguration.maxAttendees}
								onChange={(e) =>
									this.updateFormFieldConfig(section._id, field._id, 'maxAttendees', e.target.value)}
							/>
							{this.props.groups && this.props.groups.length > 0 && <SelectField label="Associated Group"
								classes="w-100" />}
							<SelectField
								label="Default Status"

								classes="w-100"
								value={statusOptions.find((f) => f.value == field.moduleConfiguration.defaultStatus)}
								options={statusOptions}
								onChange={(e) =>
									this.updateFormFieldConfig(section._id, field._id, 'defaultStatus', e.value)}
							/>
							{this.props.emails &&
								this.props.emails.length > 0 && (
									<SelectField
										label="Confirmation Email"

										classes="w-100"
										options={this.props.emails}
										value={this.props.emails.find(
											(e) => e.value == field.moduleConfiguration.confirmationEmail
										)}
										onChange={(e) =>
											this.updateFormFieldConfig(section._id, field._id, 'confirmationEmail', e.value)}
									/>
								)}
							<div className="labelContainer mt-25 mb-10">
								<div className="">Attendee Fields</div>
								<div
									className="ml-a se-blue c-pointer "
									onClick={() => {
										const updatedQuestions = attendeeFields || [];
										updatedQuestions.push({
											attendeeField: { propertyType: 'unattached' },
											columnClass: 'col-12',
											description: '',
											disabled: false,
											id: `*newquestion${attendeeFields.length}`,
											label: '',
											objectClass: 'xcustomInput',
											options: [],
											placeholder: '',
											required: false,
											style: '',
											type: 'custom',
											_id: `*newquestion${attendeeFields.length}`
										});
										this.updateFormFieldConfig(section._id, field._id, 'fields', updatedQuestions);
									}}
								>
									+ Add Field
								</div>
							</div>
							<div className="w-100">
								{attendeeFields.map((nsq, qi) => {
									const attendeeNSFieldType = nsq.attendeeField
										? nsq.attendeeField.propertyType
										: 'unattached';
									let currentNSCustomField = {};
									this.state.customFields.forEach((c) =>
										c.options.forEach((o) => {
											if (o._id == nsq.id) {
												currentNSCustomField = o;
											}
										})
									);
									let isBaseField = false;
									isBaseField = nsq.objectClass === 'xbaseInput';
									return (
										<div className="w-100 mb-20 nestedSectionQuestionsFormEditor">
											<div className="labelContainer mb-10">
												<div className="">Question {qi + 1}</div>
												{!nsq.static && (
													<div className="flex-ac">
														<div
															onClick={() => {
																const updatedQuestions = attendeeFields.filter(
																	(qs) => qs._id !== nsq._id
																);
																this.updateFormFieldConfig(
																	section._id,
																	field._id,
																	'fields',
																	updatedQuestions
																);
															}}
															className="  c-pointer red"
														>
															<i className="las la-trash" /> Remove
														</div>
													</div>
												)}
											</div>

											{nsq.attendeeField.propertyType !== 'base' && (
												<SelectField
													label="Attendee Field"

													classes="w-100"
													value={currentNSCustomField}
													options={this.state.customFields}
													actionMessage={
														<div
															className="se-blue c-pointer"
															onClick={() =>
																this.setState({
																	addCustomFieldModalOpen: true,
																	focusedField: {
																		section: section._id,
																		nested: true,
																		id: nsq._id,
																		label: field.label
																	}
																})}
														>
															Create custom field
														</div>
													}
													actionOn={attendeeNSFieldType == 'unattached'}
													formatGroupLabel={(data) => (
														<div style={groupStyles}>
															<span>{data.label}</span>
															<span style={groupBadgeStyles}>{data.options.length}</span>
														</div>
													)}
													onChange={(data) => {
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'customField',
															data._id
														);
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'attendeeField',
															{
																...field.attendeeField,
																propertyName: data._id,
																propertyType: 'custom'
															}
														);
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'objectClass',
															'mappedInput'
														);
													}}
												/>
											)}
											<InputField
												label="Label"
												value={nsq.label}
												onChange={(e) => {
													this.updateFormFieldConfigField(
														section._id,
														field._id,
														nsq._id,
														'label',
														e.target.value
													);
												}}
											/>
											<div className="flex-ac">
												{!isBaseField && <SelectField
													label="Field Type"

													classes="w-100"
													value={fieldTypes.find((t) => t.value == nsq.type)}
													name="fieldType"
													style={{ marginRight: 10 }}
													options={fieldTypes}
													required
													onChange={(e) => {
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'type',
															e.value
														);
													}}
												/>}
												<SelectField
													label="Column Size"

													classes="w-100"
													value={columnSizeOptions.find((cso) => cso.value == nsq.columnClass)}
													options={columnSizeOptions}
													onChange={(e) => {
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'columnClass',
															e.value
														);
													}}
												/>
											</div>

											<div className="flex-ac">
												<InputField
													label="Default Value"
													value={nsq.defaultValue}
													onChange={(e) => {
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'defaultValue',
															e.target.value
														);
													}}
													classes="mr-10"
												/>
												<InputField
													label="PlaceHolder"
													value={nsq.placeholder}
													type={nsq.type == 'date' ? 'date' : ''}
													inputIcon={field.type == 'date' && <i className="lar la-calendar" />}
													onChange={(e) =>
														this.updateFormFieldConfigField(
															section._id,
															field._id,
															nsq._id,
															'placeholder',
															e.target.value
														)}
												/>
											</div>
											<ToggleField
												label="Required"
												checked={nsq.required}
												type={nsq.type == 'date' ? 'date' : ''}
												inputIcon={field.type == 'date' && <i className="lar la-calendar" />}
												icons={false}
												onChange={(e) => {
													const r = e.target.checked;
													this.updateFormFieldConfigField(
														section._id,
														field._id,
														nsq._id,
														'required',
														r
													);
												}}
											/>
											{/* this.renderNestedFieldConfig(section, field, ns, nsq) */}
											{this.renderFormFieldConfig(section, field, nsq)}
										</div>
									);
								})}
							</div>
						</div>
					);
					break;
				case 'calendarBooking':
					isModuleField = true;
					break;
				case 'notifierEmail':
					isModuleField = true;
					const notificationEmails = this.props.emails ? this.props.emails : [];
					configElement = (<div>

						<div className=" w-100 flex mb-10">
							<InputField
								label="Label"
								value={field.label}
								type="text"
								onChange={(e) => this.updateFormField(section._id, field._id, 'label', e.target.value)}
							/>
						</div>
						<SelectField
							label="Notification Email"

							classes="w-100"
							options={notificationEmails}
							value={notificationEmails.find(
								(e) => e.value == field.moduleConfiguration.notificationEmail
							)}
							onChange={(e) =>
								this.updateFormFieldConfig(section._id, field._id, 'notificationEmail', e.value)}
						/>

						<ToggleField
							label="Required"
							checked={field.required}
							icons={false}
							onChange={(e) => {
								const r = e.target.checked;
								this.updateFormField(section._id, field._id, 'required', r);
							}}
						/>
					</div>);
					break;
				case 'medProLookup':
					isModuleField = true;

					configElement = (
						<div className="formFieldContainer">
							<div className=" w-100 flex-sb flex-ac mt-10">
								<InputField
									label="Label"
									value={field.label}
									type="text"
									onChange={(e) => this.updateFormField(section._id, field._id, 'label', e.target.value)}
								/>
							</div>

							<ToggleField
								label="Capture Data"
								checked={field.moduleConfiguration.captureData}
								icons={false}
								onChange={(e) =>
									this.updateFormFieldConfig(section._id, field._id, 'captureData', e.target.checked)}
							/>
						</div>
					);
					break;
				case 'salesForceSearchContact':
					isModuleField = true;

					configElement = (
						<div className="formFieldContainer">
							<div className=" w-100 flex-sb flex-ac mt-10">
								<InputField
									label="Label"
									value={field.label}
									type="text"
									onChange={(e) => this.updateFormField(section._id, field._id, 'label', e.target.value)}
								/>
							</div>

							<ToggleField
								label="Do not add to campaign"
								checked={field.moduleConfiguration.doNotAddToCampaign}
								icons={false}
								onChange={(e) =>
									this.updateFormFieldConfig(section._id, field._id, 'doNotAddToCampaign', e.target.checked)}
							/>
						</div>
					);
					break;
				case 'salesForceSearchAccount':
					isModuleField = true;

					configElement = (
						<div className="formFieldContainer">
							<div className=" w-100 flex-sb flex-ac mt-10">
								<InputField
									label="Label"
									value={field.label}
									type="text"
									onChange={(e) => this.updateFormField(section._id, field._id, 'label', e.target.value)}
								/>
							</div>
						</div>
					);
					break;
				default:
					break;
			}
			let isBaseField = false;
			isBaseField = field.objectClass === 'baseInput';
			return (
				<Fragment key={`editing-${section._id}-${field._id}`
				}>

					<div className="flex aic jcsb mb-20  w-100">
						<h1>{field.label}</h1>
					</div>
					{
						!isModuleField && (
							<div className="formEditField">
								<InputField
									label="Label"
									value={field.label}
									type="text"
									onChange={(e) => this.updateFormField(section._id, field._id, 'label', e.target.value)}
								/>
								{attendeeFieldType != 'base' && (
									<SelectField
										label="Mapped Field"
										value={currentCustomField}
										options={this.state.customFields}
										classes="w-100"
										actionMessage={
											Object.keys(currentCustomField).length == 0 ?
												<div
													className="se-blue c-pointer"
													onClick={() =>
														this.setState({
															addCustomFieldModalOpen: true,
															focusedField: {
																section: section._id,
																nested: false,
																id: field._id,
																label: field.label
															}
														})}
												>
													Create new field
												</div> : <div
													className="se-red c-pointer"
													onClick={() => {

														this.setState({
															focusedField: {
																section: section._id,
																nested: false,
																id: field._id,
																label: field.label
															}
														});

														this.updateFormField(section._id, field._id, 'id', '');
														this.updateFormField(section._id, field._id, 'attendeeField', {
															...field.attendeeField,
															propertyName: '',
															propertyType: 'custom'
														});
														this.updateFormField(section._id, field._id, 'objectClass', 'mappedInput');
													}
													}

												>
													Unmap field
												</div>
										}
										actionOn={true/* attendeeFieldType == 'unattached' */}
										formatGroupLabel={(data) => (
											<div style={groupStyles}>
												<span>{data.label}</span>
												<span style={groupBadgeStyles}>{data.options.length}</span>
											</div>
										)}
										onChange={(data) => {
											this.updateFormField(section._id, field._id, 'id', data._id);
											this.updateFormField(section._id, field._id, 'attendeeField', {
												...field.attendeeField,
												propertyName: data._id,
												propertyType: 'custom'
											});
											this.updateFormField(section._id, field._id, 'objectClass', 'mappedInput');
										}}
									/>
								)}
								<RichTextEditor
									id={`field-${field._id.replace('*', '')}-description`}
									label="Description"
									value={field.description}
									options={['image']}
									onChange={(e) => {
										this.updateFormField(section._id, field._id, 'description', e)
									}}
									placeholder="Description"
									customFields={this.props.customFields}
								/>
								<div className="flex-ac">
									{!isBaseField && <SelectField
										label="Field Type"
										classes="w-100"
										value={fieldTypes.find((t) => t.value == field.type)}
										options={fieldTypes}
										onChange={(e) => {
											this.updateFormField(section._id, field._id, 'type', e.value);
										}}
										style={{ marginRight: 10 }}
									/>}
									<SelectField
										classes="w-100"
										label="Column Size"
										value={columnSizeOptions.find((o) => o.value == field.columnClass)}
										options={columnSizeOptions}
										onChange={(e) => this.updateFormField(section._id, field._id, 'columnClass', e.value)}
									/>
								</div>
								<div className="flex-ac">

									{field.type === 'select' ?
										<SelectField
											classes="mr-10"
											label="Default Value"
											value={field.defaultValue ? { value: field.defaultValue, label: field.defaultValue } : ''}
											options={[{ label: '', value: '' }, ...field.options.map((o) => ({ label: o, value: o }))]}
											onChange={(e) => this.updateFormField(section._id, field._id, 'defaultValue', e.value)}
										/> :

										<InputField
											label="Default Value"
											value={field.defaultValue}
											type={field.type == 'date' ? 'date' : 'text'}
											inputIcon={field.type == 'date' && <i className="lar la-calendar" />}

											onChange={(e) => {
												this.updateFormField(section._id, field._id, 'defaultValue', e.target.value)
											}
											}
											classes="mr-10"
										/>}

									<InputField
										label="PlaceHolder"
										type={field.type == 'date' ? 'date' : 'text'}
										inputIcon={field.type == 'date' && <i className="lar la-calendar" />}
										value={field.placeholder}
										onChange={(e) =>
											this.updateFormField(section._id, field._id, 'placeholder', e.target.value)}
									/>
								</div>
								<ToggleField
									label="Required"
									checked={field.required}
									icons={false}
									onChange={(e) => {
										const r = e.target.checked;
										this.updateFormField(section._id, field._id, 'required', r);
									}}
								/>
							</div>
						)
					}
					{configElement}

					<button className='mt-a neu w-100 mb-20 danger'
						onClick={() => {
							this.removeFormField(section._id, field._id);
						}}>Remove Field</button>
				</Fragment >
			);
		}
	};

	renderFormFieldConfig = (section, field, configField) => {
		const { fieldTypes } = this.state;
		let configElement;

		switch (configField.type) {
			case 'list':
				configElement = (
					<div>
						<div className="labelContainer">
							<div className="formFieldLabel">Options</div>
							<div
								className="se-blue c-pointer "
								onClick={() => {
									const updatedOptions = configField.options;
									updatedOptions.push('');
									this.updateFormFieldConfigField(
										section._id,
										field._id,
										configField._id,
										'options',
										updatedOptions
									);
								}}
							>
								+ Add option
							</div>
						</div>
						<div className="formEditlistOptions">
							{configField.options.map((opt, oi) => {
								return (
									<div className="flex-ac mb-10">
										<InputField
											value={opt}
											classes="m-0"
											onChange={(e) => {
												const updatedOptions = configField.options;
												updatedOptions[oi] = e.target.value;
												this.updateFormFieldConfigField(
													section._id,
													field._id,
													configField._id,
													'options',
													updatedOptions
												);
											}}
										/>
										<i
											className="las la-times ml-10 c-pointer"
											onClick={() => {
												const updatedOptions = configField.options.filter((op) => op !== opt);
												this.updateFormFieldConfigField(
													section._id,
													field._id,
													configField._id,
													'options',
													updatedOptions
												);
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				);
				break;
			case 'email':
				configElement = <div />;
				break;
			case 'multipleCheck':
				configElement = (
					<div>
						<div className="labelContainer">
							<div className="formFieldLabel">Options</div>

							<div
								className="se-blue c-pointer "
								onClick={() => {
									const updatedOptions = configField.options;
									updatedOptions.push('');
									this.updateFormFieldConfigField(
										section._id,
										field._id,
										configField._id,
										'options',
										updatedOptions
									);
								}}
							>
								+ Add option
							</div>
						</div>
						<div className="formEditlistOptions">
							{configField.options.map((opt, oi) => {
								return (
									<div className="flex-ac mb-10">
										<InputField
											value={opt}
											classes="m-0"
											onChange={(e) => {
												const updatedOptions = configField.options;
												updatedOptions[oi] = e.target.value;
												this.updateFormFieldConfigField(
													section._id,
													field._id,
													configField._id,
													'options',
													updatedOptions
												);
											}}
										/>
										<i
											className="las la-times ml-10 c-pointer"
											onClick={() => {
												const updatedOptions = configField.options.filter((op) => op !== opt);
												this.updateFormFieldConfigField(
													section._id,
													field._id,
													configField._id,
													'options',
													updatedOptions
												);
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				);
				break;
			case 'select':
				configElement = (
					<div className="formEditlistOptions">
						<div className="labelContainer">
							<div className="formFieldLabel">Options</div>

							<div
								className="se-blue c-pointer "
								onClick={() => {
									const updatedOptions = configField.options;
									updatedOptions.push('');
									this.updateFormFieldConfigField(
										section._id,
										field._id,
										configField._id,
										'options',
										updatedOptions
									);
								}}
							>
								+ Add option
							</div>
						</div>
						<div>
							{configField.options &&
								configField.options.map((opt, oi) => {
									return (
										<div className="flex-ac mb-10">
											<InputField
												value={opt}
												classes="m-0"
												onChange={(e) => {
													const updatedOptions = configField.options;
													updatedOptions[oi] = e.target.value;
													this.updateFormFieldConfigField(
														section._id,
														field._id,
														configField._id,
														'options',
														updatedOptions
													);
													this.updateFormField(
														section._id,
														field._id,
														'defaultValue',
														''
													);
												}}
											/>
											<i
												className="las la-times ml-10 c-pointer"
												onClick={() => {
													const updatedOptions = configField.options.filter(
														(op) => op !== opt
													);
													this.updateFormFieldConfigField(
														section._id,
														field._id,
														configField._id,
														'options',
														updatedOptions
													);
													this.updateFormField(
														section._id,
														field._id,
														'defaultValue',
														''
													);
												}}
											/>
										</div>
									);
								})}
						</div>
					</div>
				);
				break;
			case 'rating':
				configElement = <div />;
				break;
			default:
				break;
		}
		return configElement ? (
			<div className="fieldTypeConfig mt-10">
				<label className="calibreSemiBold">Config</label>
				{configElement}
			</div>
		) : (
			''
		);
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	removePage = (index) => {
		const { currentPage } = this.state;
		const { editedForm } = this.props;
		editedForm.pages.splice(index, 1);
		const cp = currentPage > editedForm.pages.length - 1 ? editedForm.pages.length - 1 : currentPage;

		this.props.updateForm(editedForm);
		this.setState({ currentPage: cp });
	};

	addPage = () => {
		const { editedForm } = this.props;
		editedForm.pages.push({
			_id: `page_${new Date().getTime()}`,
			sections: []
		});
		this.props.updateForm(editedForm);
		this.setState({ currentPage: editedForm.pages.length - 1 });
	}

	nextPage = () => {
		const { currentPage } = this.state;
		const { editedForm } = this.props;
		if (currentPage < editedForm.pages.length - 1) {
			this.setState({ currentPage: currentPage + 1 });
		}
	};

	prevPage = () => {
		const { currentPage } = this.state;
		if (currentPage > 0) {
			this.setState({ currentPage: currentPage - 1 });
		}
	};

	toggleDetailsOpen = () => {
		const { detailsOpen } = this.state;
		this.setState({ detailsOpen: !detailsOpen, detailsExpanded: false });
	};

	toggleDetailsExpanded = () => {
		const { detailsExpanded } = this.state;
		this.setState({ detailsExpanded: !detailsExpanded });
	};

	toggleSection = (section) => {
		switch (section) {
			case 'editor':
				this.setState({ detailsOpen: !this.state.detailsOpen, componentLibraryIsOpen: false });
				break;
			case 'library':
				this.setState({ componentLibraryIsOpen: !this.state.componentLibraryIsOpen, detailsOpen: false });
				break;
			default:
				break;

		}
	}
	render() {
		const {
			currentPage,
			formEditorTabSelected,
			focusedFieldId,
			focusedButtonId,
			focusedSectionId,
			fieldSelected,
			addCustomFieldModalOpen,
			detailsExpanded,
			detailsOpen,
			componentLibraryIsOpen,
			focusedFieldIndex,
		} = this.state;
		const { editedForm, baseFieldList } = this.props;

		let focusedField, focusedSection;
		if (focusedSectionId) {
			focusedSection = editedForm.pages[currentPage].sections.find((s) => s._id == focusedSectionId);
			if (focusedSection) {
				if (focusedFieldId) {
					focusedField = focusedSection.fields.find((f) => f._id == focusedFieldId);
					if (!focusedField) {
						focusedField = focusedSection.fields[focusedFieldIndex];
					}
				} else if (focusedButtonId) {
					focusedField = focusedSection.buttons.find((b) => b._id == focusedButtonId);
				}
				if (focusedFieldId && focusedFieldId.startsWith('^')) {
					focusedFieldId = focusedFieldId.substring(1);
				}
			}
		}
		const cp = editedForm.pages ? editedForm.pages[currentPage] : {};

		return (
			<Fragment>
				<DragDropContext onDragEnd={this.onDragEnd}>

					<div className='flex jcsb w-100 h-100'>
						<div className='w-100 flex column aic'>
							<div className='formPageNav mt-20 mb-10'>
								<button className={`neu p-7 ${currentPage == 0 ? 'disabled' : ''}`} onClick={this.prevPage}><i className='las la-angle-left'></i></button>
								<div>
									Page {currentPage + 1} of {editedForm.pages.length}

								</div>
								{currentPage == editedForm.pages.length - 1 || editedForm.pages.length == 0 ? <button className={`prim`} onClick={this.addPage}><i className='las la-plus mr-10'></i>New Page</button> : <button className={`neu p-7`} onClick={this.nextPage}><i className='las la-angle-right'></i></button>}
							</div>
							{cp && (<div className='formCanvas'>
								<div className="formContainer" key={`currentPage-${currentPage}`}>
									<div className='c-pointer' onClick={() => {
										this.setState({ focusedSectionId: null, focusedFieldId: null, focusedFieldIndex: null, focusedButtonId: null, detailsOpen: true, componentLibraryIsOpen: false })
									}}>

										<h2 className='mb-10'>{cp.title && cp.title != '' ? cp.title : <span className='lightBody'>Click to Edit Title</span>}</h2>
										<div className='formTitleBreakBar'></div>
										{cp.subtitle && cp.subtitle != '' ? <p className='mb-20 mt-20' dangerouslySetInnerHTML={{ __html: cp.subtitle }}></p> : <p className='mb-20 mt-20 lightBody'>Click to Edit Subtitle</p>}

									</div>
									<div className="labelContainer">
										<div className="formFieldLabel">Sections</div>
									</div>
									<Droppable
										droppableId={`${cp._id}`}
										key={`drop-${cp._id}`}
										type="section"
									>
										{(provided3, snapshot) => (
											<div
												className="formEditorPageContainer"
												ref={provided3.innerRef}
												key={`drop-${cp._id}`} >
												{cp.sections.map((section, si) => {
													return (
														<Draggable key={section._id} draggableId={`drag-${section._id}`} index={si}>
															{(provided2, snapshot2) => (
																<div

																	className={`formEditorSectionContainer ${focusedSectionId == section._id ? 'highlight' : ''} `}
																	ref={provided2.innerRef}
																	{...provided2.draggableProps}
																	{...provided2.dragHandleProps}
																>
																	<div className='c-pointer' onClick={() => this.setState({ focusedSectionId: section._id, focusedFieldId: null, focusedFieldIndex: null, focusedButtonId: null, detailsOpen: true, componentLibraryIsOpen: false })}
																		key={`section-${section._id}`}>

																		<div className='flex aic jcc w-100 g-pointer' style={{ marginTop: -10 }}>
																			<i className='las la-braille' style={{ transform: 'rotate(90deg)' }}></i>
																		</div>

																		{section.title && section.title != '' ? <h4 className='mb-10' dangerouslySetInnerHTML={{ __html: section.title }}></h4> : <h4 className='mb-10 lightBody'>Click to Edit Section Title</h4>}
																		<div className="labelContainer">
																			<div className="formFieldLabel">Fields</div>
																		</div>
																	</div>
																	<Droppable
																		droppableId={`${section._id}`}
																		key={`drop-${section._id}`}
																		type="field"
																	>
																		{(provided, snapshot) => (
																			<div
																				className='formEditorFieldContainer'
																				ref={provided.innerRef}>

																				{section.fields && (
																					<div className="Flex m-0">
																						{section.fields.map((field, index) => (
																							<DraggableField
																								focusOnField={(
																									fField,
																									fSection
																								) => {
																									this.setState({
																										formEditorTabSelected: 1,
																										fieldSelected: true,
																										detailsOpen: true,
																										componentLibraryIsOpen: false,
																										focusedFieldId: fField._id,
																										focusedFieldIndex: index,
																										focusedSectionId:
																											fSection._id
																									});
																								}}
																								customFields={this.props.fields.map(
																									(category) => {
																										const title =
																											category.title;
																										const headers = category.fields.map(
																											(header) => {
																												const name =
																													header.fieldName;
																												return {
																													_id: header._id,
																													category: title,
																													categoryId:
																														category._id,
																													value: name,
																													label: name
																												};
																											}
																										);
																										return {
																											label: title,
																											value: category._id,
																											options: headers
																										};
																									}
																								)}
																								key={field._id}
																								field={field}
																								focused={focusedFieldId == field._id}
																								section={section}
																								index={index}
																								updateFormField={
																									this.updateFormField
																								}
																								updateNestedFormField={
																									this.updateNestedFormField
																								}
																								removeFormField={
																									this.removeFormField
																								}
																							/>
																						))}
																					</div>
																				)}
																				{section.fields.length == 0 && (<div style={{ padding: 15, textAlign: 'center', color: '#999' }}>Add fields by dragging over from Library</div>)}
																			</div>
																		)}
																	</Droppable>
																</div>
															)}
														</Draggable>
													);
												})}
												{cp.sections.length == 0 && (<div style={{ padding: 20, textAlign: 'center', color: '#999' }}>Create a section to add fields</div>)}
											</div>)}
									</Droppable>
									<button
										className="prim w-100 mt-20"
										onClick={this.addNewSection}
									>
										<i className="las la-plus mr-10" />
										New Section
									</button>
								</div>
							</div>)}
						</div>
						<div className={`designerPanel p-0 right ${!componentLibraryIsOpen ? 'closed' : ''} `} style={{ overflowX: 'hidden', position: 'relative' }}>
							<div style={{ padding: 25 }}>

								<button onClick={() => this.toggleSection('library')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>

								<div className='mb-20'>
									<h1>Form Fields</h1>
								</div>
								<div className={`formInputsSection selected`}>
									<Droppable droppableId={`drop-attendee-fields`} type="field">
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												className="formFieldsContainer"
											>
												<p className='gray small mb-10'>Attendee Fields</p>
												{baseFieldList.map((field, index) => (<><DraggableCustomField key={field._id} field={field} index={index} /><hr /></>

												))}
												<p className='gray small mt-50 mb-10'>All Fields Type</p>
												{unattachedFieldList.map((field, index) => (<><DraggableCustomField key={field._id} field={field} index={index} /><hr /></>

												))}
											</div>
										)}
									</Droppable>
								</div>
							</div>
						</div>

						<div className={`designerPanel p-0 right ${!detailsOpen ? 'closed' : ''} `} style={{ overflow: 'hidden', position: 'relative' }} >
							<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
								<button onClick={() => this.toggleSection('editor')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
								{fieldSelected && focusedFieldId && focusedSection ? (
									<Fragment>
										{this.renderFocusedFieldConfig(focusedSection, focusedField)}
									</Fragment>
								) : focusedSection ? (
									<Fragment>

										<div className="flex aic mb-20  w-100">
											<h1>Section</h1>
										</div>

										<RichTextEditor
											id={`section-${currentPage}-${focusedSectionId.includes('^') ? focusedSectionId.replace('^', '') : focusedSectionId}`}
											value={focusedSection.title}
											options={['image']}
											onChange={(e) => {
												const eForm = { ...this.props.editedForm };
												eForm.pages[currentPage].sections[
													editedForm.pages[currentPage].sections.findIndex(
														(section) =>
															section._id == focusedSectionId
													)
												].title = e;
												this.props.updateForm(eForm);

											}}
											placeholder="Section Title"
											label="Section Title"
											key={`section-${currentPage}-${focusedSectionId.includes('^') ? focusedSectionId.replace('^', '') : focusedSectionId}`}
											customFields={this.props.customFields}

										/>
										<div className='mt-a'>
											<div className='flex aic jcsb mb-10'>
												<button
													className={`min ${currentPage == 0 ? 'disabled' : ''}`}
													onClick={() =>
														this.moveSection(
															'sectionUp',
															focusedSection._id
														)}
												>
													Move to Previous Page
												</button>

												<button
													className={`min ${currentPage != editedForm.pages.length - 1 ? '' : 'disabled'}`}
													onClick={() =>
														this.moveSection(
															'sectionDown',
															focusedSection._id
														)}
												>
													Move to Next Page
												</button></div>
											<button
												className='neu w-100 danger'
												onClick={() =>
													this.removeSection(
														focusedSection._id
													)}
											>
												Delete Section
											</button>
										</div>
									</Fragment>
								) : (
									<Fragment>
										<div className="flex aic  mb-20 w-100">
											<h1>Page {currentPage + 1}</h1>
										</div>
										<InputField
											value={cp ? cp.title : ''}
											label="Page Title"
											placeholder="Page Title"
											key={`title-${currentPage}`}
											onChange={(e) => {
												const eForm = { ...this.props.editedForm };
												eForm.pages[currentPage].title = e.target.value;
												this.props.updateForm(eForm);
											}}
										/>
										<RichTextEditor
											id={`section-${currentPage}-${'pageSubtitle'}`}
											key={`section-${currentPage}-${'pageSubtitle'}`}
											options={['image']}

											label="Page Subtitle"
											value={cp ? cp.subtitle : ''}
											onChange={(e) => {
												const eForm = { ...this.props.editedForm };
												eForm.pages[currentPage].subtitle = e;
												this.props.updateForm(eForm);
											}}
											placeholder="Page Subtitle"
											customFields={this.props.customFields}

										/>

										<div className='mt-a w-100'>
											<button
												className='neu w-100 danger'
												onClick={() =>
													this.removePage(currentPage)}
											>
												Delete Page
											</button>
										</div>
									</Fragment>
								)}
							</div>
						</div>
						<div className='designerPanel side p-0' style={{ overflow: 'hidden', position: 'relative', minWidth: 100, width: 100 }} >
							<div className='flex column jcc w-100'>
								<div className={`flex column jcc aic c-pointer ${detailsOpen ? 'selectedSection' : ''} `} style={{ height: 100 }} onClick={() => {
									this.toggleSection('editor');
								}}>
									<i className='las la-edit mb-10' style={{ fontSize: 30 }}></i>
									<h5>Editor</h5></div>
								<div className={`flex column jcc aic c-pointer ${componentLibraryIsOpen ? 'selectedSection' : ''} `} style={{ height: 100 }} onClick={() => {
									this.toggleSection('library');
								}}>
									<i className='las la-book mb-10' style={{ fontSize: 30 }}></i>
									<h5>Library</h5></div>
							</div>
						</div>
					</div>
				</DragDropContext>
				{
					addCustomFieldModalOpen && (
						<AddCustomFieldModal
							adding={true}
							toggle={() => this.setState({ addCustomFieldModalOpen: false, focusedField: null })}
							fields={this.props.fields}
							section="attendees"
							addField={this.addField}
							eventId={this.props.eventId}
							fieldName={this.state.focusedField.label}
							orgId={this.props.orgId}
						/>
					)
				}


				{/* <div className={`designerPanel righ`}>

				</div>
				<div className={`designerPanel`}>
				</div> */}
			</Fragment >
		);
	}
}

export default FormBuilder;

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
};
const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center'
};
