import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';

import TextAreaField from '../../../../components/inputs/textAreaField';
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}
class AddBccModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			invalid: false,
			error: '',
			ccEmails: this.props.email.bcc.toString().replace(/,/g, ', '),
			requiredFields: {},
			invalidBcc: false
		};
	}

	handleCCEmailsChange = (e) => {
		this.setState({
			ccEmails: e.target.value,
			editing: true
		});
	};

	updateFrom = async () => {
		this.setState({ submitting: true });
		const { fromName, fromEmail, ccEmails } = this.state;
		const { eventId, orgId, email, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		let bccEmails = ccEmails == '' ? [] : ccEmails.replace(/ /g, '').split(',');

		let validbcc = true;

		bccEmails = bccEmails.map((e) => {
			if (!ValidateEmail(e) || '') {
				validbcc = false;
			}

			if (e !== '') return e;
		});

		if (validbcc || bccEmails.toString() == '') {
			this.setState({ invalidBcc: false });
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${email._id}`, {
					name: email.name,
					bcc: [...bccEmails]
				})
				.then((res) => {
					const emailObj = res.data;

					if (res.data) {
						update(
							{
								name: email.name,
								senderName: fromName,
								senderEmail: fromEmail,
								bcc: [...bccEmails]
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields, invalidBcc: true });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { fromEmail, fromName, submitting, ccEmails, invalidBcc } = this.state;

		return (
			<Modal isOpen={isOpen} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="fs-30 calibreBold">Add Bcc</div>
					{/* 		<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
						Who is sending this campaign?{' '}
					</div> */}
				</ModalHeader>
				<ModalBody className="modalBody">
					<TextAreaField
						placeholder={'Add emails separated by commas...'}
						value={ccEmails}
						label="bcc"
						errorTrigger={invalidBcc}
						errorMessage={'Invalid Email detected'}
						type="text"
						onChange={(e) => this.handleCCEmailsChange(e)}
					/>
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						disabled={submitting}
						className="modalButton actionButton actionSave m-0"
						onClick={() => {
							this.updateFrom();
						}}
					>
						<div className="modalButtonText">{!submitting ? 'Save' : 'Saving...'}</div>
					</Button>
					<Button onClick={toggle} outline className="modalButton actionButton modalCancel mb-0 ml-a">
						<div className="modalCancelText">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default AddBccModal;
