import React, { Component } from 'react';
import { EditorState, ContentState, convertToRaw, Modifier, Entity, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SelectField from '../../../components/inputs/selectField';
import ColorPic from '../../../components/inputs/colorToolbar';

class CustomFieldMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedField: null
		};
	}

	addField = (customField) => {
		const { editorState, onChange } = this.props;
		editorState.getCurrentContent().createEntity('MENTION', 'IMMUTABLE', { value: customField.value });
		const contentState = Modifier.replaceText(
			editorState.getCurrentContent(),
			editorState.getSelection(),
			customField.value,
			editorState.getCurrentInlineStyle(),
			editorState.getCurrentContent().getLastCreatedEntityKey()
		);
		onChange(EditorState.push(editorState, contentState, 'insert-characters'));
	};

	render() {
		const { customFields } = this.props;
		return (
			<SelectField
				placeholder="Custom Fields"
				value={this.state.selectedField}
				relative={true}
				options={customFields}
				style={{ width: 200, marginBottom: 5 }}
				onChange={(e) => this.addField(e)}
			/>
		);
	}
}

export default class RichTextEditor extends Component {
	constructor(props) {
		super(props);

		const blocksFromHtml = htmlToDraft(props.value && props.value !== '' ? props.value : props.defaultElement ? props.defaultElement : '');
		const { contentBlocks, entityMap } = blocksFromHtml;
		this.state = {
			editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap)),
			focused: false,
			codeViewEnabled: false,
			textareaheight: 1
		};

		document.addEventListener('mousedown', this.handleClickOutside);
		//console.log(props);
	}

	onEditorStateChange = (editorState) => {
		let text = '';
		convertToRaw(editorState.getCurrentContent()).blocks.forEach((b) => (text += b.text + '\n'));
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()), null, null, (entity, text) => {
			if (entity.type === 'MENTION') {
				const wrapper = text.replace(
					entity.data.value,
					`<a className="wysiwyg-mention" data-mention data-value="${entity.data.value}">${entity.data.value}</a>`
				);
				return wrapper;
			}
		});
		let processedHtml = text && text.trim() != '' ? html.replace(/<p/g, '<div').replace(/<\/p>/g, '</div>') : '';
		this.props.onChange(processedHtml);
		this.setState({
			editorState
			//bodyText: text,
			//bodyHtml: draftToHtml(convertToRaw(editorState.getCurrentContent()))
		});
	};

	handleClickOutside = (event) => {
		if (!event.target.closest(`#${this.props.id}`)) {
			this.setState({ focused: false });
		}
	};

	handleChange = (event) => {
		const { textareaheight } = this.state;
		const height = event.target.scrollHeight;
		const rowHeight = 20;
		const trows = Math.ceil(height / rowHeight) - 1;
		console.log(height, rowHeight, trows);
		if (trows !== textareaheight) {
			this.setState({ textareaheight: trows });
		}
		this.props.onChange(event.target.value);
	};

	toggleCodeView = () => {
		if (this.state.codeViewEnabled) {
			const blocksFromHtml = htmlToDraft(this.props.value ? this.props.value : this.props.defaultElement ? this.props.defaultElement : '');
			const { contentBlocks, entityMap } = blocksFromHtml;
			this.setState({
				codeViewEnabled: false,
				editorState: EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks, entityMap))
			});
		} else {
			this.setState({ codeViewEnabled: true });
		}
	};

	render() {
		const { editorState, codeViewEnabled, textareaheight } = this.state;
		const { id, label, placeholder, className, options, blockTypeOptions } = this.props;
		//return <div />;
		const additonalOptions = options || [];

		return (
			<div
				className="posRel mb-25 w-100"
				id={id}>

				<div className="labelContainer">
					<div className="formFieldLabel">{label}</div>
				</div>
				{!codeViewEnabled ? (
					<Editor
						toolbarCustomButtons={[<CustomFieldMap customFields={this.props.customFields} />]}
						placeholder={this.props.placeholder}
						toolbar={{
							options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'link', ...additonalOptions],
							inline: {
								options: ['bold', 'italic']
							},
							blockType: {
								inDropdown: false,
								options: blockTypeOptions ? blockTypeOptions : undefined,
								className: undefined,
								component: undefined,
								dropdownClassName: undefined
							},
							fontSize: {
								options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
							},
							fontFamily: {
								options: this.props.fontFamilies
							},
							colorPicker: { component: ColorPic }
						}}
						mention={true}
						toolbarHidden={!this.state.focused}
						editorState={editorState}
						wrapperClassName="parameter-wrapper"
						editorClassName="rich-editor"
						onEditorStateChange={this.onEditorStateChange}
						handleReturn={(e, editorState) => {
							if (e.key === 'Enter') {
								this.onEditorStateChange(RichUtils.insertSoftNewline(editorState));
								return 'handled';
							}
							return 'not-handled';
						}}
						onFocus={() => {
							this.setState({ focused: true });
						}}
						onBlur={() => {
							//this.setState({ focused: false });
						}}
					/>
				) : (
					<DynamicTextarea
						className="formFieldTextArea"
						value={this.props.value}
						rows={textareaheight}
						onChange={this.handleChange}
						updateRowHeight={(rh) => this.setState({ textareaheight: rh })}
					/>
				)}
				<div className="codeToggleIcon" onClick={this.toggleCodeView}>
					{codeViewEnabled ? <i className="las la-paragraph" /> : <i className="las la-code" />}
				</div>
			</div>
		);
	}
}

class DynamicTextarea extends Component {
	constructor(props) {
		super(props);
		this.ta = React.createRef();
	}

	componentDidMount() {
		const height = this.ta.current.scrollHeight;
		const rowHeight = 20;
		const trows = Math.ceil(height / rowHeight) - 1;
		this.props.updateRowHeight(trows);
	}

	render() {
		return (
			<div className="formFieldInputContainer">
				<textarea
					ref={this.ta}
					className="formFieldTextArea"
					value={this.props.value}
					rows={this.props.rows}
					onChange={this.props.onChange}
				/>
			</div>
		);
	}
}
