import React, { Component } from 'react';
import ColorField from '../../components/inputs/colorField';
import InputField from '../../components/inputs/inputField';
import TextAreaField from '../../components/inputs/textAreaField';
import FileChooserPreview from '../../components/inputs/fileChooserPreview';
import FilesUploaderModal from '../../components/files/filesUploaderModal';
class Branding extends Component {
	render() {
		const { branding } = this.props.accountData;
		const { orgId } = this.props;
		return (
			<div className="sectionBlock">
				<h2 className="mb-15 mt-10">Logos</h2>

				<FileChooserPreview
					label="Company Logo"
					value={branding.logo}
					onClick={() => {
						this.props.editAccountData({
							...this.props.accountData,
							branding: {
								...this.props.accountData.branding,
								logo: 'choosing__' + branding.logo
							}
						});
					}}
					clearFile={() => {

						this.props.editAccountData({
							...this.props.accountData,
							branding: {
								...this.props.accountData.branding,
								logo: ''
							}
						});
					}}
				/>


				{branding.logo.includes('choosing__') &&
					<FilesUploaderModal
						isOpen={branding.logo.includes('choosing__')}
						toggle={() => {
							this.props.editAccountData({
								...this.props.accountData,
								branding: {
									...this.props.accountData.branding,
									logo: branding.logo.replace('choosing__', '')
								}
							});
						}}
						//eventId={eventId}
						links={{
							getAssets: `Organizations/${orgId}/assets`,
							addAssets: `Organizations/${orgId}/assets`,
						}}
						orgId={orgId}
						addFiles={(fileURL) => {

							this.props.editAccountData({
								...this.props.accountData,
								branding: {
									...this.props.accountData.branding,
									logo: fileURL
								}
							});
						}}
					/>}

				<FileChooserPreview
					label="Website Icon"
					value={branding.favicon}
					onClick={() => {
						this.props.editAccountData({
							...this.props.accountData,
							branding: {
								...this.props.accountData.branding,
								favicon: 'choosing__' + branding.favicon
							}
						});
					}}
					clearFile={() => {

						this.props.editAccountData({
							...this.props.accountData,
							branding: {
								...this.props.accountData.branding,
								favicon: ''
							}
						});
					}}
				/>


				{branding.favicon.includes('choosing__') &&
					<FilesUploaderModal
						isOpen={branding.favicon.includes('choosing__')}
						toggle={() => {
							this.props.editAccountData({
								...this.props.accountData,
								branding: {
									...this.props.accountData.branding,
									favicon: branding.favicon.replace('choosing__', '')
								}
							});
						}}
						//eventId={eventId}
						links={{
							getAssets: `Organizations/${orgId}/assets`,
							addAssets: `Organizations/${orgId}/assets`,
						}}
						orgId={orgId}
						addFiles={(fileURL) => {

							this.props.editAccountData({
								...this.props.accountData,
								branding: {
									...this.props.accountData.branding,
									favicon: fileURL
								}
							});
						}}
					/>}

				<h2 className="mb-15 mt-10">Colors</h2>

				<ColorField
					label="Primary color"
					type="color"
					value={branding.accentColor}
					useHex={true}
					onChange={(e) => {
						this.props.editAccountData({
							...this.props.accountData,
							branding: {
								...this.props.accountData.branding,
								accentColor: e
							}
						});
					}}
				/>
				<ColorField
					label="Primary Text Color"
					type="color"
					value={branding.fontColor}
					useHex={true}
					onChange={(e) => {
						this.props.editAccountData({
							...this.props.accountData,
							branding: {
								...this.props.accountData.branding,
								fontColor: e
							}
						});
					}}
				/>
			</div>
		);
	}
}

export default Branding;
