import React, { Fragment } from 'react';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteSessionsModal extends React.Component {
	render() {
		const { isOpen, count, isDeleting, toggle } = this.props;
	
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete ${count > 1 ? 'Sessions' : 'Session'}`}
					bodyHeaderText={<span>You are about to delete{' '}
						<b>
							{count} {count > 1 ? 'sessions' : 'session'}
						</b>, this is irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!isDeleting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.props.delete()}
					isDelete={true}
					actionButtonDisabled={isDeleting}


				></FieldModal>
			</div>

		);
	}
}

export default DeleteSessionsModal;
