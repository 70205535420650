import React from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../../../utils/API';
import { Modal } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import TabsContainer from '../../../../components/views/TabsContainer';
import TextAreaField from '../../../../components/inputs/textAreaField';
class ReportsCenterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            submitting: false,
            invalid: false,
            error: '',
            selectedSection: 0,
            templateSelected: null,
            selectedSection: 0,
            countingKey: 0,
            isConfirmation: false,
            campaignName: '',
            reportDescription: '',
        };
    }

    selectSection = (section) => {
        const { countingKey, campaignName } = this.state;
        this.setState({ selectedSection: section, isConfirmation: false, templateSelected: null, countingKey: countingKey + 1, campaignName: '' });
    };

    selectTemplate = (templateId) => {
        this.setState({ templateSelected: templateId })
    }

    setConfirmation = (isConfirmation) => {
        this.setState({ isConfirmation: isConfirmation, countingKey: this.state.countingKey + 1 })
    }

    onCancel = () => {
        const { isConfirmation } = this.state;


        if (isConfirmation) {
            this.setConfirmation(false);

        } else {

            this.props.toggle();
        }

    }


    setCampaignName = (campaignName) => {
        this.setState({ campaignName: campaignName })
    }

    addCampaign = async (callback) => {
        this.setState({ submitting: true });
        const { templateSelected, selectedSection, campaignName } = this.state;
        const { eventId, orgId, addReport } = this.props;

        let valid = campaignName && campaignName !== '' ? true : false;
        let payload = {};

        switch (selectedSection) {
            case 0:
                payload.name = campaignName;
                payload.description = this.state.reportDescription;
                payload.platformReportTemplateId = templateSelected;
                break;
            case 1:
                payload.name = campaignName;
                payload.description = this.state.reportDescription;
                payload.reportTemplateId = templateSelected;
                break;
            case 2:
                payload.name = campaignName;
                payload.description = this.state.reportDescription;
                break;
        }


        if (valid) {
            await API().post(`Organizations/${orgId}/events/${eventId}/viewReports`, payload).then((res) => {
                const reportObj = res.data;

                if (res.data) {
                    const newReport = {
                        ...reportObj,
                        link: `/${orgId}/events/${eventId}/reports/${reportObj._id}`,
                    };

                    addReport(newReport, () => {
                        this.props.toggle();
                        this.props.history.push(`/${orgId}/events/${eventId}/reports/${reportObj._id}`)
                    });
                } else {
                }
            });
        } else {
            this.setState({ submitting: false });
        }
    };

    handleActionButtonLogic = () => {
        const { isConfirmation, templateSelected, selectedSection } = this.state;
        switch (selectedSection) {
            case 0:
                if (templateSelected && !isConfirmation) {
                    this.setConfirmation(true);
                } else if (templateSelected && isConfirmation) {
                    this.addCampaign();
                }
                break;
            case 1:
                if (templateSelected && !isConfirmation) {
                    this.setConfirmation(true);
                } else if (templateSelected && isConfirmation) {
                    this.addCampaign();
                }
                break;
            case 2:
                this.addCampaign();
                break;
            case 3:
                this.addCampaign();
                break;
        }
    }


    render() {
        const { isOpen, toggle, templates, platformTemplates } = this.props;
        const { selectedSection, templateSelected, isConfirmation } = this.state;

        let data = [];

        if (selectedSection == 1) {
            data = templates ? templates : [];
        } else {
            data = platformTemplates ? platformTemplates : [];
        }
        let selectedTemplate;
        if (templateSelected && isConfirmation) {
            selectedTemplate = data.find(template => template._id == templateSelected);
        }

        return (
            <div>
                <Modal isOpen={isOpen} style={{}} toggle={toggle} className={`large templateCenterModal`}>
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7, zIndex: 100 }}><i className='las la-times'></i></button>

                    <div className={`templateCenterContainer`} style={{}}>
                        <div className="sideNav">
                            <h1>Template Center</h1>
                            <div className="explorePanel">
                                <p>EXPLORE</p>
                                <button type="button" tabindex="0" className={`sideNav  ${selectedSection == 0 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(0)}>
                                    All Templates
                                </button>
                                <button type="button" tabindex="1" className={`sideNav  ${selectedSection == 1 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(1)}>
                                    Existing Reports
                                </button>
                                <button type="button" tabindex="2" className={`sideNav  ${selectedSection == 2 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(2)}>
                                    Start From Scratch
                                </button>
                            </div>
                        </div>
                        <div style={{ height: "calc(100vh - 80px)" }} className="templatesContainer">
                            {isConfirmation ? (
                                <div>
                                    <div style={{ alignContent: 'baseline', height: 'calc(100vh - 233px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>
                                        <div style={{ width: '50%' }}>
                                            <InputField
                                                label={'Report Name'}
                                                value={this.state.campaignName}
                                                errorTrigger={this.state.campaignName == ''}
                                                inputStyle={{ fontSize: 28, lineHeight: '34px', fontWeight: 600, margin: 0, paddingLeft: 0, background: '#ffffffa0' }}
                                                onChange={(e) => {
                                                    this.setCampaignName(e.target.value);
                                                }}
                                            />
                                            <InputField
                                                label={'Report Description'}
                                                value={this.state.reportDescription}
                                                errorTrigger={this.state.reportDescription == ''}
                                                inputStyle={{ margin: 0, paddingLeft: 0, background: '#ffffffa0' }}
                                                onChange={(e) => {
                                                    this.setState({ reportDescription: e.target.value });
                                                }}

                                            />
                                            <div className='sectionBlock'>
                                                <h2 className='blockHeader mb-20'>Details</h2>


                                                <div className="mb-20">
                                                    <p className="small gray"> Views</p>
                                                    <TabsContainer tabs={selectedTemplate.views.map(v => { return { title: v.name } })} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : selectedSection == 0 || selectedSection == 1 ? (
                                <div>
                                    <div style={{ alignContent: 'baseline', height: 'calc(100vh - 233px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>
                                        {data.map((template, index) => {
                                            return (<div
                                                key={`row-${template._id}-${index}`}
                                                className="flex c-pointer"
                                                onClick={() => {
                                                    this.setCampaignName(this.state.templateSelected == template._id ? '' : template.name);
                                                    this.selectTemplate(this.state.templateSelected == template._id ? null : template._id);
                                                    this.setState({ reportDescription: template.description })
                                                }}
                                                style={{ border: template._id == this.state.templateSelected ? '1px solid #2A83F1' : '1px solid #D5D5D5', width: '30%', borderRadius: 5, overflow: 'hidden', height: 80, marginBottom: 20, marginRight: 20, opacity: template._id == this.state.templateSelected ? "0.7" : '1' }}
                                            >
                                                <div className='flex aic jcc' style={{ fontSize: 40, background: '#F5F6FA', minWidth: 60 }}>

                                                    {template.icon ? (<i className={`${template.icon}`} />) : <i className="las la-file-alt" />}
                                                </div>

                                                <div className='flex column jcc p-20 w-100' style={{ background: 'white' }}>
                                                    <h3>{template.name}</h3>
                                                    <p>{template.description}</p>
                                                </div>
                                            </div>)

                                        })}
                                    </div>
                                </div>
                            ) : selectedSection == 2 ? (

                                <div style={{ height: 'calc(100vh - 234px)' }}>
                                    <div className="inputCard m-a flex column jcc aic">
                                        <h1 className='mb-10'>Start From Scratch</h1>
                                        <p className="mb-20">Enter the report details below.</p>
                                        <InputField
                                            label={'Report Name'}
                                            required={true}
                                            value={this.state.campaignName}
                                            onChange={(e) => {
                                                this.setCampaignName(e.target.value);
                                            }}
                                        />
                                        <TextAreaField
                                            label={'Report Description'}
                                            value={this.state.reportDescription}
                                            onChange={(e) => {
                                                this.setState({ reportDescription: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : ''}

                            <div className='modalFooter mt-20'>
                                <div className='flex'>
                                    <button className="neu ml-a" onClick={this.onCancel}>{isConfirmation ? 'Back' : 'Cancel'}</button>
                                    <button className={`ml-25 ${!templateSelected && !isConfirmation && selectedSection !== 2 && selectedSection !== 3 ? "disabled" : (this.state.campaignName == '') ? 'disabled' : 'prim'}`} onClick={() => {
                                        this.handleActionButtonLogic();
                                    }}>{(selectedSection == 0 || selectedSection == 1) && templateSelected && !isConfirmation ? "Next" : "Create Report"}</button>
                                </div>

                            </div>
                        </div>

                    </div>

                </Modal >

            </div >

        );
    }
}

export default withRouter(ReportsCenterModal);
