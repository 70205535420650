import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

class ColorPic extends Component {
	static propTypes = {
		expanded: PropTypes.bool,
		onExpandEvent: PropTypes.func,
		onChange: PropTypes.func,
		currentState: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			displayColorPicker: false
		};

		document.addEventListener('mousedown', this.handleClickOutside);
	}

	stopPropagation = (event) => {
		event.stopPropagation();
	};

	onChange = (color) => {
		const { onChange } = this.props;
		onChange('color', `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`);
	};

	renderModal = () => {
		const { color } = this.props.currentState;
		return (
			<div onClick={this.stopPropagation} style={{ position: 'absolute', zIndex: 10, right: 0 }}>
				<ChromePicker color={color} onChangeComplete={this.onChange} />
			</div>
		);
	};

	toggleColorPicker = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker });
	};

	handleClickOutside = (event) => {
		if (!event.target.closest(`.toolbarColorPicker`)) {
			this.setState({ focused: false });
		}
	};

	render() {
		const { displayColorPicker } = this.state;

		return (
			<div
				className="toolbarColorPicker"
				aria-haspopup="true"
				aria-expanded={displayColorPicker}
				aria-label="rdw-color-picker"
			>
				<div className="c-pointer flex-ac mt-5" onClick={this.toggleColorPicker}>
					<i style={{ fontSize: 18 }} className="las la-eye-dropper" />
				</div>
				{displayColorPicker ? this.renderModal() : undefined}
			</div>
		);
	}
}

export default ColorPic;
