import React, { Fragment } from 'react';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';

function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}
class AddFromTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fromName:
				this.props.emailTemplate.senderName && this.props.emailTemplate.senderName !== ''
					? this.props.emailTemplate.senderName
					: '',
			fromEmail:
				this.props.emailTemplate.senderEmail && this.props.emailTemplate.senderEmail !== ''
					? this.props.emailTemplate.senderEmail
					: '',

			submitting: false,
			invalid: false,
			requiredFields: { fromEmail: false },
		};
	}

	handleFromNameChange = (e) => {
		this.setState({
			fromName: e.target.value
		});
	};
	handleFromEmailChange = (e) => {
		this.setState({
			fromEmail: e.target.value
		});
	};

	updateFrom = async () => {
		const { fromName, fromEmail } = this.state;
		const { toggle, emailTemplate, saveChanges } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		let editedEmailTemplate = emailTemplate;
		requiredFields.fromEmail = false;
		this.setState({ requiredFields: requiredFields })
		if (ValidateEmail(fromEmail) || fromEmail == '') {
			this.setState({ submitting: true });
			editedEmailTemplate.senderName = fromName;
			editedEmailTemplate.senderEmail = fromEmail;
			saveChanges(editedEmailTemplate, () => {
				toggle();
			});
		} else {
			requiredFields.fromEmail = true;

			this.setState({ submitting: false, requiredFields: requiredFields });

		}


	};

	render() {
		const { isOpen, toggle, emailTemplate } = this.props;
		const { fromEmail, fromName, submitting, requiredFields } = this.state;

		return (
			<div>


				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add From"}
					bodyHeaderText={'Who is sending this campaign?'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="From Name"
									required={false}
									placeholder={fromName == '' ? 'Simple Events Support' : ''}
									value={fromName}
									onChange={this.handleFromNameChange}
								/>
								<InputField
									label="From Email"
									required={false}
									placeholder={fromName == '' ? 'support@simple.events' : ''}
									errorTrigger={requiredFields.fromEmail}
									errorMessage={'Invalid email format'}

									value={fromEmail}
									onChange={this.handleFromEmailChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateFrom()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default AddFromTemplateModal;
