import React, { Fragment } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';

class NewEmailModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			emailName: '',
			subject: '',
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	handleEmailNameChange = (val) => {
		this.setState({
			emailName: val
		});
	};

	handleSubjectChange = (val) => {
		this.setState({
			subject: val
		});
	};

	addEmail = async (email) => {
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!email[key] || email[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.setState({ submitting: true, invalid: false });

			await this.props.addNewEmail(email, () => {

				this.props.toggle();
			});
		} else {
			this.setState({ invalid: true, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { emailName, submitting, invalid, subject, requiredFields } = this.state;
		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add New Email"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Email Name"
								required={true}
								errorTrigger={requiredFields.name}
								value={emailName}
								onChange={(e) => {
									this.handleEmailNameChange(e.target.value);
								}}
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
				actionButton={() => this.addEmail({ name: emailName, codeOnly: false })}
				actionButtonDisabled={submitting}


			></FieldModal>

		);
	}
}

export default NewEmailModal;
