import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import SelectField from '../../../../components/inputs/selectField';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';
import TimeField from '../../../../components/inputs/timeField';
import FilesUploaderModal from '../../../../components/files/filesUploaderModal';
import FileChooserPreview from '../../../../components/inputs/fileChooserPreview';
import TabsContainer from '../../../../components/views/TabsContainer';
import CheckboxInput from '../../../../components/inputs/checkboxInput';
import API from '../../../../utils/API';
import VenuesLocationField from '../../../../components/venues/venuesLocationField';
const statusOptions = [
    { label: 'imported', value: 'imported' },
    { label: 'invited', value: 'invited' },
    { label: 'registered', value: 'registered' },
    { label: 'not attending', value: 'not attending' },
    { label: 'checked in', value: 'checked in' },
    { label: 'incomplete', value: 'incomplete' },
    { label: 'entered through SSO', value: 'entered through SSO' },
    { label: 'removed', value: 'removed' },
    { label: 'Did not attend', value: 'did not attend' },
	{ label: 'Cancelled', value: 'cancelled' },
];

const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
};

const virtualMeetingTypes = [
    { label: 'Stream', value: 'stream' },
    { label: 'Meeting', value: 'meeting' },
    { label: 'In-Person', value: 'in-person' },
    { label: 'Link', value: 'link' },
    { label: 'Break', value: 'break' }
];

class SessionBatchUpdateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: statusOptions[0],
            updateResults: null,
            submitting: false,
            invalid: false,
            success: false,
            selectedValue: {},
            requiredFields: {
                selectedValue: false
            },
            editedValues: {},
            columnSearchTerm: '',
            selectedColumns: {},
            activeCategories: [],
            loading: true,
            selectedSection: 0,
            isConfirmation: false,
            removedProperties: {},
            beginningValues: {},
            editedProperties: []
        };
    }

    componentDidMount() {
        const { categoryColumns, selectedView, bannedProperties } = this.props;
        let activeCategories = {};
        let editedValues = {};
        // console.log('this.props.checkedEntreesData', this.props.checkedEntreesData);
        Object.keys(categoryColumns).map((c, i) => {
            let currentCategory = categoryColumns[c];
            let categoryColumnsList = currentCategory.columns;
            categoryColumnsList.map((cc, i) => {
                let currentColumn = cc;
                //console.log(cc);

                let isBanned = false;
                isBanned = bannedProperties.includes(currentColumn);
                if (!isBanned &&
                    (selectedView && selectedView.toggledColumns.includes(currentColumn)) || !selectedView) {
                    if (currentColumn === 'template') {
                        //must change to pageId
                        currentColumn = 'pageId';
                    }

                    for (let i = 0; i < this.props.checkedEntreesData.length; i++) {
                        let currentEntry = this.props.checkedEntreesData[i];

                        //save all the first entree value to later compare with the rest
                        if (i == 0) {
                            if (currentColumn === 'locationName') {
                                editedValues['location'] = currentEntry['location'] ? currentEntry['location'] : '';
                            } else if (currentColumn === 'pageId') {
                                editedValues['pageId'] = currentEntry['pageId'] ? currentEntry['pageId'] : '';
                            } else {
                                editedValues[currentColumn] = currentEntry[currentColumn] ? currentEntry[currentColumn] : '';
                            }

                        } else {
                            if (currentColumn === 'locationName') {
                                if (editedValues['location'] !== currentEntry['location']) {
                                    editedValues['location'] = '';
                                }
                            } else if (currentColumn === 'pageId') {
                                if (editedValues['pageId'] !== currentEntry['pageId']) {
                                    editedValues['pageId'] = '';
                                }
                            } else {
                                if (editedValues[currentColumn] !== currentEntry[currentColumn]) {
                                    editedValues[currentColumn] = '';
                                }
                            }
                        }
                    }
                    activeCategories[currentCategory.title] = true;
                }

            })
        })
        this.setState({ loading: false, activeCategories: Object.keys(activeCategories), editedValues: editedValues, beginningValues: editedValues })
    }

    processPage = (previousEditedValues) => {
        const { categoryColumns, selectedView, bannedProperties } = this.props;
        let activeCategories = {};
        let editedValues = editedValues ? editedValues : {};
        Object.keys(categoryColumns).map((c, i) => {
            let currentCategory = categoryColumns[c];
            let categoryColumnsList = currentCategory.columns;
            categoryColumnsList.map((cc, i) => {
                let currentColumn = cc;
                let isBanned = false;

                isBanned = bannedProperties.includes(currentColumn);
                if (!isBanned &&
                    (selectedView && selectedView.toggledColumns.includes(currentColumn)) || !selectedView) {

                    for (let i = 0; i < this.props.checkedEntreesData.length; i++) {
                        let currentEntry = this.props.checkedEntreesData[i];

                        //save all the first entree value to later compare with the rest
                        if (i == 0) {
                            editedValues[currentColumn] = currentEntry[currentColumn] ? currentEntry[currentColumn] : '';

                        } else {
                            if (editedValues[currentColumn] !== currentEntry[currentColumn]) {
                                editedValues[currentColumn] = '';
                            }
                        }
                    }
                    activeCategories[currentCategory.title] = true;
                }

            })
        })
        Object.assign(editedValues, previousEditedValues);
        this.setState({ loading: false, activeCategories: Object.keys(activeCategories), editedValues: editedValues })

    }

    handleStatusChosen = (e) => {
        this.setState({ selectedValue: e });
    };

    updatedEditedValue = (key, value) => {
        this.setState({ editedValues: { ...this.state.editedValues, [key]: value } });
    }

    submit = async () => {
        const { orgId, eventId, attendeeIds, toggle } = this.props;
        const { editedValues, isConfirmation, removedProperties } = this.state;

        //remove all empty values
        Object.keys(editedValues).forEach((key) => (editedValues[key] == null || editedValues[key] == '') && delete editedValues[key]);
        //compare the beginning values with the current values
        let editedProperties = [];
        Object.keys(editedValues).forEach((key) => {
            if (editedValues[key] !== this.state.beginningValues[key]) {
                editedProperties.push(key);
            }
        });

        if (!isConfirmation) {
            this.setState({ isConfirmation: true, editedProperties })
        } else {

            this.setState({ submitting: true, })
            const removedPropertiesKeys = Object.keys(removedProperties);

            for (let i = 0; i < removedPropertiesKeys.length; i++) {
                let currentKey = removedPropertiesKeys[i];
                delete editedValues[currentKey]
            }

            Object.keys(editedValues).forEach((key) => {
                if (!editedProperties.includes(key)) {
                    delete editedValues[key];
                }
            });
            await API().patch(`/Organizations/${orgId}/events/${eventId}/sessions/batchUpdate`, { sessionIds: attendeeIds, propertyValues: editedValues }).then((res) => {


                this.props.updateBatchSessions(editedValues, () => {
                    toggle();

                });
            });
        }
    };



    renderField = (key) => {


        const { columns, columnTypes, pathOptions } = this.props;
        const { editedValues } = this.state;

        let keyType = columnTypes[key];
        let element = null;

        switch (keyType) {

            case 'boolean':
                element = <SelectField
                    label={columns[key]}
                    value={editedValues[key] == true ? { label: 'Yes', value: true } : { label: "No", value: false }}
                    options={[{ label: 'Yes', value: true }, { label: "No", value: false }]}
                    onChange={(e) => this.updatedEditedValue(key, e.value)}


                />;
                break;

            case 'text':

                if (key == 'virtualMeetingType') {
                    element =
                        element = <SelectField
                            label={columns[key]}
                            value={virtualMeetingTypes.find((vmt) => editedValues[key].virtualMeetingType ? vmt.value == editedValues[key].virtualMeetingType : false)}
                            options={virtualMeetingTypes}
                            onChange={(e) => this.updatedEditedValue(key, e.value)
                            }
                        />;

                } else if (key === 'template' || key === 'pageId') {

                    let mappedPathOptions = pathOptions.map((p) => {
                        return { label: p.title, value: p._id }
                    });

                    element = <SelectField
                        label={columns[key]}
                        value={mappedPathOptions.find((vmt) => editedValues['pageId'] ? vmt.value == editedValues['pageId'] : false)}
                        options={mappedPathOptions}
                        onChange={(e) => this.updatedEditedValue('pageId', e.value)
                        }
                    />;

                } else if (key === 'locationName') {
                    element = <VenuesLocationField
                        selectedVenue={this.props.venues.find(v => v._id == this.props.event.venue) || ''}
                        orgId={this.props.orgId}
                        venueOptions={this.props.venues}
                        sessionLocationId={editedValues.location}
                        isLocation={true}
                        venuePicked={this.state.venue}
                        selectLocation={(location) => {
                            this.updatedEditedValue('location', location._id);
                        }}
                        addNewLocation={this.props.addNewLocation}
                        renameLocation={this.props.renameLocation}
                        deleteLocation={this.props.deleteLocation}
                    ></VenuesLocationField>;

                } else {

                    element =
                        <InputField
                            label={columns[key]}
                            value={editedValues[key]}
                            disabled={false}
                            type={'text'}
                            onChange={(e) => {
                                this.updatedEditedValue(key, e.target.value);
                            }}
                        />;
                }

                break;
            case 'date':
                element = <InputField
                    label={columns[key]}
                    value={editedValues[key]}
                    inputIcon={<i className="lar la-calendar" />}
                    disabled={false}
                    type={'date'}
                    onChange={(e) => this.updatedEditedValue(key, e.target.value)}
                />;
                break;
            case 'time':
                element = <TimeField
                    label={columns[key]}
                    disabled={false}
                    value={editedValues[key]}
                    required={false}
                    onChange={(e) => this.updatedEditedValue(key, e)}
                />;
                break;
            case 's3file':
                editedValues[key] = editedValues[key] ? editedValues[key] : '';
                element = <div className="editorFileInput" >
                    <FileChooserPreview
                        label={columns[key]}
                        value={editedValues[key]}
                        onClick={() => {
                            this.updatedEditedValue(key, 'choosing__' + editedValues[key])

                        }}
                        clearFile={() => {
                            this.updatedEditedValue(key, '')


                        }}
                    />
                    {
                        editedValues[key] && editedValues[key].includes('choosing__') &&
                        <FilesUploaderModal
                            isOpen={editedValues[key].includes('choosing__')}
                            toggle={() => {

                                this.updatedEditedValue(key, editedValues[key].replace('choosing__'))

                            }}
                            eventId={this.props.eventId}
                            links={{
                                addAssets: `Organizations/${this.props.orgId}/events/${this.props.eventId}/assets`,
                                getAssets: `Organizations/${this.props.orgId}/events/${this.props.eventId}/assets`
                            }}
                            orgId={this.props.orgId}
                            addFiles={(fileURL) => {

                                getMeta(fileURL, (err, img) => {
                                    this.updatedEditedValue(key, fileURL)


                                });
                            }}
                        />
                    }
                </div>;
                break;
            default:
                element = <InputField
                    label={columns[key]}
                    value={editedValues[key]}
                    disabled={false}
                    type={''}
                    onChange={(e) => {
                        this.updatedEditedValue(key, e.target.value);
                    }}
                />;
        }

        return (
            element
        );


    }

    updateSelectedSection = (index) => {
        this.setState({ selectedSection: index });
    };

    render() {
        const { isOpen, count, toggle, columns, bannedProperties, categoryColumns, selectedView, entriesCount, checkedEntries, checkedEntreesData } = this.props;
        const { submitting, success, columnSearchTerm, activeCategories, loading, editedValues, selectedSection, isConfirmation, editedProperties } = this.state;
        return (
            <div>
                {!loading && <FieldModal
                    size="medium"
                    style={{ maxWidth: 1000 }}
                    isFullWidthBody={true}
                    isOpen={isOpen}
                    modalTitle={"Batch Update"}
                    bodyHeaderText={
                        !isConfirmation ?
                            <span><span><i className="las la-check-square" /> {checkedEntries} Sessions selected</span></span> :
                            <span><span><i className="las la-check-square" /> {editedProperties.length - Object.keys(this.state.removedProperties).length} updates for {checkedEntries} Sessions</span></span>

                    }
                    bodyExtraText={isConfirmation ? <p style={{ color: '#999999', marginTop: 0 }}>Please review and confirm your edits.</p> : <p className='pb-20' style={{ color: '#999999', marginTop: 0 }}>Edits you make to the fields below will be applied to all selected sessions.</p>}
                    bodyContent={() => {
                        return (
                            <div style={{ padding: '0px 10px' }}>
                                {
                                    isConfirmation ?
                                        <div>
                                            <div style={{ maxHeight: 350, overflowX: 'hidden' }}>
                                                {Object.keys(categoryColumns).map((c, i) => {
                                                    let currentCategory = categoryColumns[c];
                                                    let categoryColumnsList = currentCategory.columns;
                                                    let editedKeys = Object.keys(editedValues).filter((k) => editedProperties.includes(k));
                                                    let isWithinCategory = categoryColumnsList.some((cc) => cc === 'template' ? editedKeys.includes('pageId') : editedKeys.includes(cc));
                                                    return (
                                                        <div>
                                                            {isWithinCategory &&
                                                                <div>
                                                                    {activeCategories.includes(currentCategory.title) && <h2 style={{ margin: '10px 0', marginTop: i == 0 ? 0 : 20 }} className="blockHeader">{currentCategory.title}</h2>}

                                                                    <div>
                                                                        {categoryColumnsList.map((cc, i) => {
                                                                            let currentColumn = cc;
                                                                            let isEditedKey = false;

                                                                            if (currentColumn === 'locationName' || currentColumn === 'location') {
                                                                                isEditedKey = editedKeys.includes('location');
                                                                            } else if (currentColumn === 'pageId' || currentColumn === 'template') {
                                                                                isEditedKey = editedKeys.includes('pageId');
                                                                            } else {
                                                                                isEditedKey = editedKeys.includes(currentColumn);
                                                                            }
                                                                            let value = editedValues[currentColumn];
                                                                            if (currentColumn === 'locationName') {
                                                                                let venue = this.props.venues.find(v => v._id == this.props.event.venue);
                                                                                let locations = venue ? venue.locations : [];
                                                                                value = locations.find(l => l._id == editedValues['location']) ? locations.find(l => l._id == editedValues['location']).name : '';
                                                                            } else if (currentColumn === 'template') {
                                                                                let path = this.props.pathOptions.find(p => p._id == editedValues['pageId']);
                                                                                value = path ? path.title : '';
                                                                            }
                                                                            return (

                                                                                isEditedKey ?
                                                                                    <div style={{ display: 'flex' }}>
                                                                                        <div
                                                                                            style={{ marginBottom: 15, marginTop: 30, marginRight: 20 }}
                                                                                            className={`checkBoxContainer checkBox ${!Object.keys(this.state.removedProperties).includes(currentColumn === 'template' ? 'pageId' : currentColumn) ? 'checked' : ''}`}
                                                                                            onClick={() => {
                                                                                                let removedProperties = this.state.removedProperties;
                                                                                                let localCurrentColumn = currentColumn;
                                                                                                if (localCurrentColumn === 'template') {
                                                                                                    localCurrentColumn = 'pageId';
                                                                                                }
                                                                                                if (removedProperties[localCurrentColumn]) {
                                                                                                    delete removedProperties[localCurrentColumn];
                                                                                                } else {
                                                                                                    removedProperties[localCurrentColumn] = true;
                                                                                                }
                                                                                                this.setState({ removedProperties: removedProperties })
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            <i className="las la-check" />
                                                                                        </div>

                                                                                        <InputField
                                                                                            label={columns[currentColumn]}
                                                                                            value={value}
                                                                                            disabled={true}
                                                                                            errorTrigger={Object.keys(this.state.removedProperties).includes(currentColumn)}
                                                                                            errorMessage={'This field will not be updated'}
                                                                                            type={'text'}
                                                                                            onChange={(e) => {
                                                                                                this.updatedEditedValue(currentColumn, e.target.value);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    : ''
                                                                            )
                                                                        })
                                                                        }

                                                                    </div></div>}

                                                        </div>
                                                    )

                                                })}
                                            </div>

                                        </div> : <div>

                                            <div style={{ maxHeight: 550, overflowX: 'hidden' }}>
                                                {Object.keys(categoryColumns).map((c, i) => {
                                                    let currentCategory = categoryColumns[c];
                                                    let categoryColumnsList = currentCategory.columns;

                                                    let isCurrentTab = selectedSection == 1 && currentCategory.title == 'Travel' || selectedSection == 2 && currentCategory.title == 'Rooming' || (selectedSection == 0 && currentCategory.title !== 'Travel' && selectedSection == 0 && currentCategory.title !== 'Rooming');
                                                    return (
                                                        <div>
                                                            {isCurrentTab && <div> {activeCategories.includes(currentCategory.title) && <h2 style={{ margin: '10px 0', marginTop: i == 0 ? 0 : 20 }} className="blockHeader">{currentCategory.title}</h2>}

                                                                <div>
                                                                    {categoryColumnsList.map((cc, i) => {
                                                                        let currentColumn = cc;
                                                                        let isBanned = false;

                                                                        isBanned = bannedProperties.includes(currentColumn);
                                                                        return (
                                                                            !isBanned &&
                                                                                (selectedView && selectedView.toggledColumns.includes(currentColumn)) || !selectedView ? !isBanned && this.renderField(currentColumn) : ''
                                                                        )
                                                                    })
                                                                    }

                                                                </div></div>}

                                                        </div>
                                                    )

                                                })}
                                            </div>
                                        </div>
                                }
                            </div>
                        )
                    }
                    }
                    toggle={toggle}
                    actionButtonLabel={!submitting && !isConfirmation ? 'Next' : isConfirmation && !submitting ? 'Save' : 'Saving...'}
                    cancelButtonLabel={success ? 'Close' : isConfirmation ? 'Back' : false}
                    cancelOnClick={isConfirmation ? () => {
                        this.setState({ isConfirmation: false, removedProperties: {} }, this.processPage(editedValues))
                    } : false}
                    actionButton={() => this.submit()}
                    submitting={submitting}
                    actionButtonDisabled={submitting || success}
                ></FieldModal >}
            </div >
        )

    }
}

export default SessionBatchUpdateModal;
