import React from 'react';

import API from '../../../../utils/API';
import SelectField from '../../../../components/inputs/selectField';
import ViewsTable from '../../../../components/tables/ViewsTable';

import FieldModal from '../../../../components/modals/fieldModal';
import InputField from '../../../../components/inputs/inputField';

class GroupAndAttendeesModal extends React.Component {
    constructor(props) {
        super(props);
        const columns = {
            sendDate: 'Date',
            sendTime: 'Time',
            timezone: 'Timezone',
            sent: 'Sent',
            recipientModel: 'Type',
            cancelled: 'Cancelled'
        };
        const columnTypes = {
            sendDate: 'date',
            sendTime: 'time',
            timezone: 'text',
            sent: 'bool',
            recipientModel: 'text',
            cancelled: 'bool'
        };

        const toggledColumns = {};
        const normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });

        this.state = {
            name: null,
            submitting: false,
            invalid: false,
            error: '',
            attendees: [],
            sessions: [],
            groups: [],
            selectedSection: 0,
            normalizedColumns: normalizedColumns,
            columns: columns,
            columnTypes: columnTypes,
            toggledColumns: toggledColumns,
            checkedAttendees: {},
            checkedGroups: {},
            checkedSessions: {},
            selectedColumnSort: null,
            sortDesc: false,
            selectedRecipientsCount: 0,
            searching: false,
            searchTerm: '',

        };
    }
    componentDidMount() {
        const { session, event } = this.props;

        let checkedAttendees = {};
        let sessionAttendees = session.selectedAttendees || []; //checkedAttendees

        for (let i = 0; i < sessionAttendees.length; i++) {
            checkedAttendees[sessionAttendees[i]._id] = true;
        }

        let checkedGroups = {};
        let sessionGroups = session.groups || []; //checkedGroups
        for (let i = 0; i < sessionGroups.length; i++) {
            checkedGroups[sessionGroups[i]._id] = true;
        }



        this.setState({
            attendees: event.attendees,
            groups: event.groups,
            checkedAttendees: checkedAttendees,
            checkedGroups: checkedGroups,
        });


    }



    searchEntries = (searchText) => {
        this.setState({
            searching: searchText != '' ? true : false,
            searchText: searchText ? searchText : '',
        });
    };

    updateSelected = (selectedIndex) => {
        const { checkedAttendees, checkedSessions, checkedGroups } = this.state;

        this.setState({
            selectedSection: selectedIndex,
            selectedColumnSort: null,
        });
    };

    submit = async () => {
        const { name, checkedAttendees, checkedGroups, checkedSessions } = this.state;
        const { session, toggle, orgId, eventId } = this.props;

        let selectedAttendees = Object.keys(checkedAttendees);
        let selectedGroups = Object.keys(checkedGroups);

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
                request: 'updateAttendeesAndGroups',
                attendees: selectedAttendees,
                groupIds: selectedGroups
            })
            .then((res) => {
                if (res.data) {
                    let attendees = this.props.event.attendees.filter(att => res.data.attendees.includes(att._id));
                    let groups = this.props.event.groups.filter(grp => selectedGroups.includes(grp._id)).map(g => {
                        return { attendees: g.attendees, name: g.name, _id: g._id }
                    });
                    this.props.updateAttendees(attendees);
                    this.props.updateSessionGroups(groups);
                    this.props.toggle();

                }
            })
            .catch((e) => {
                console.log(e);

            });

    }



    renderSelectedTabContent = () => {
        const { selectedSection, selectedColumnSort, sortDesc, searchTerm } = this.state;
        const toggledColumns = { ...this.state.toggledColumns }
        let columns = {};
        let columnTypes = {};
        let normalizedColumns = null;
        let checkRow = () => { };
        let checkAllRows = () => { };
        let checkedRows = [];
        let data = [];
        let sortData = () => { };
        switch (selectedSection) {
            case 0:
                columns = {
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    email: 'Email',
                    checkedIn: 'Checked In',
                    checkedInTime: 'Checked In Time',
                    status: 'Status'
                };
                columnTypes = {
                    firstName: 'text',
                    lastName: 'text',
                    email: 'text',
                    checkedIn: 'text',
                    checkedInTime: 'timestamp',
                    status: 'text'
                };
                checkRow = (entryId) => {
                    const checked = this.state.checkedAttendees;
                    checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
                    this.setState({
                        checkedAttendees: checked,
                        selectedRecipientsCount: Object.keys(checked).length
                    });
                };
                checkAllRows = (entryIds) => {
                    let checked = this.state.checkedAttendees;
                    entryIds.length > 0
                        ? entryIds.forEach((entryId) => {
                            checked[entryId] = true;
                        })
                        : (checked = {});

                    this.setState({
                        checkedAttendees: checked,
                        selectedRecipientsCount: Object.keys(checked).length
                    });
                };
                checkedRows = this.state.checkedAttendees;
                data = this.state.attendees;
                normalizedColumns =
                    !normalizedColumns &&
                    Object.keys(columns).map((cfc) => {
                        toggledColumns[cfc] = true;
                        return {
                            label: columns[cfc],
                            key: cfc,
                            value: cfc,
                            type: columnTypes[cfc],
                            sortAsc: false,
                            sortDesc: false
                        };
                    });

                sortData = (column, sortAsc, sortDesc) => {
                    normalizedColumns = normalizedColumns.map((col) => {
                        if (col.key == column.key) {
                            col.sortAsc = sortAsc;
                            col.sortDesc = sortDesc;
                        } else {
                            col.sortAsc = false;
                            col.sortDesc = false;
                        }
                        return col;
                    });

                    this.setState({
                        selectedColumnSort: column,
                        sortDesc: sortDesc == false ? true : false
                    });
                };

                break;
            case 1:
                columns = {
                    name: 'Name'
                };
                columnTypes = {
                    name: 'text'
                };
                checkRow = (entryId) => {
                    const checked = this.state.checkedGroups;
                    checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
                    this.setState({
                        checkedGroups: checked,
                        selectedRecipientsCount: Object.keys(checked).length
                    });
                };
                checkAllRows = (entryIds) => {
                    let checked = this.state.checkedGroups;
                    entryIds.length > 0
                        ? entryIds.forEach((entryId) => {
                            checked[entryId] = true;
                        })
                        : (checked = {});

                    this.setState({
                        checkedGroups: checked,
                        selectedRecipientsCount: Object.keys(checked).length
                    });
                };
                checkedRows = this.state.checkedGroups;
                data = this.state.groups;
                normalizedColumns = Object.keys(columns).map((cfc) => {
                    toggledColumns[cfc] = true;
                    return {
                        label: columns[cfc],
                        key: cfc,
                        value: cfc,
                        type: columnTypes[cfc],
                        sortAsc: false,
                        sortDesc: false
                    };
                });
                break;
            default:
        }
        if (selectedColumnSort) {
            data = data.sort((a, b) => {
                const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
                if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
                    switch (columnType) {
                        case 'string':
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                        case 'number':
                            return a[selectedColumnSort.key] - b[selectedColumnSort.key];
                        case 'date':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'time':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'boolean':
                            const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
                            const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
                            return a1.localeCompare(b1);
                        default:
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                    }
                } else if (a[selectedColumnSort.key]) {
                    return 1;
                }
                return -1;
            });

            data = sortDesc ? data.reverse() : data;
        }

        if (searchTerm.length > 0) {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term != '') {
                    data = data.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }

        return (
            <div className='flex '
                style={{ height: "calc(100vh - 330px)" }}
            >
                <ViewsTable
                    style={{ margin: 0, paddingTop: 0, paddingBottom: 25, }}
                    columns={normalizedColumns}
                    toggledColumns={toggledColumns}
                    data={data}
                    mainColumnActive={false}
                    checked={checkedRows}
                    checkRow={checkRow}
                    mainActions={[]}
                    checkAllRows={checkAllRows}
                    sortData={sortData}
                />
            </div>
        )
    };

    render() {
        const { isOpen, toggle, email } = this.props;
        const { submitting, selectedSection, selectedRecipientsCount, searchTerm } = this.state;

        let options = [{ label: 'Attendees', value: 0 }, { label: 'Groups', value: 1 },];

        return (
            <div

            >


                <FieldModal
                    size="large"
                    isOpen={isOpen}
                    modalTitle={"Attendees"}
                    bodyHeaderText={'Choose from attendees list and groups.'}
                    bodyDescription={''}
                    overflowHidden={true}
                    bodyContent={() => {
                        return (
                            <div className=''

                            >

                                <div style={{}} className='mb-10 flex'>
                                    <div style={{ width: 158, marginLeft: 1 }}>
                                        <SelectField
                                            label={'Select From'}
                                            value={options[selectedSection]}
                                            options={options}
                                            onChange={(val) => {
                                                this.updateSelected(val.value)
                                            }}
                                        />
                                    </div>
                                    <div className=' ml-25 flex aic'>
                                        <InputField
                                            classes="m-0 viewSearch"
                                            placeholder="Search "
                                            clickIcon={true}
                                            required={true}
                                            value={searchTerm}
                                            onChange={(e) => {
                                                clearTimeout(this.searchTimeout);
                                                this.setState({ searchTerm: e.target.value },
                                                    () => {
                                                        this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
                                                    });

                                            }}
                                            prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                            inputIcon={searchTerm != '' &&
                                                <i
                                                    onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
                                                    //onClick={() => this.setState({ searchTerm: '', searching: false })}
                                                    className="las la-times-circle"
                                                    style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                                />}
                                        />
                                    </div>

                                </div>
                                {this.renderSelectedTabContent()}
                            </div>)
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? (
                        `Update Attendee List ${selectedRecipientsCount == 0 ? '' : `(${selectedRecipientsCount})`}`
                    ) : (
                        'Updating...'
                    )}
                    actionButton={() => this.submit()}
                    actionButtonDisabled={submitting}
                    isFullWidthBody={true}


                ></FieldModal>
            </div >

        );
    }
}

export default GroupAndAttendeesModal;
