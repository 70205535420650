import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../../utils/API';
import EventNotificationCampaign from './campaign';
import LoadingWizard from '../../../components/spinner/wizard';
import AddCampaignModal from './modals/newCampaignModal';
import BasicViewContainer from '../../../components/views/BasicViewContainer';
import RenameNotificationModal from './modals/renameNotificationModal';

import DuplicateCampaignModal from './modals/duplicateCampaignModal';
import DeleteNotificationsModal from './modals/deleteNotificationsModal';
let batchCallback;
let mainActionCB;

class EventNotificationCampaigns extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			status: 'Status',
			createdAt: 'Created At',
			updatedAt: 'Updated At',
		};
		const defaultFieldTypes = {
			name: 'text',
			status: 'Status',
			createdAt: 'date',
			updatedAt: 'date',
		};

		this.state = {
			editing: false,
			templates: [],
			campaignsHidden: false,
			allTemplates: false,
			addCampaignToggle: false,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			notificationViews: [],
			notifications: [],
			sortedNotifications: [],
			loading: true,
			toggleCreateFromTemplate: false,
			selectedTemplate: {},
			checkedCampaigns: {},
			deletedCampaigns: [],
			hoverOn: '',
			selectedSection: 0,
			campaignDrafts: [],
			campaignScheduled: [],
			campaignSent: [],
			selectedCampaign: null,
			toggleRenameSingle: false,
			toggleSingleDeleteCampaign: false,
			toggleDuplicateSingleCampaign: false,
			editCount: 0,
		};
	}

	async componentDidMount() {
		const { eventId, orgId } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/notifications`).then((res) => {
			if (res.data) {
				const notificationViews = res.data.notificationViews;
				let sortedNotifications = res.data.notifications.map((msg) => {
					return {
						name: msg.name,
						status: msg.sent ? 'Sent' : msg.scheduled ? 'Scheduled' : 'Draft',
						_id: msg._id,
						link: `/${this.props.orgId}/events/${eventId}/notifications/${msg._id}`,
						createdAt: msg.createdAt,
						updatedAt: msg.updatedAt
					};
				});
				const metadataOptions = [];
				{
					Object.keys(res.data.keyedData).forEach((key) => {
						const layer1 = res.data.keyedData[key];
						if (typeof layer1 === 'object') {
							Object.keys(layer1).forEach((key2) => {
								const layer2 = layer1[key2];
								if (typeof layer2 === 'object') {
									Object.keys(layer2).forEach((key3) => {
										if (
											key !== '_defaultLabel' &&
											key2 !== '_defaultLabel' &&
											key3 !== '_defaultLabel'
										) {
											metadataOptions.push(`${key}.${key2}.${key3}`);
										}
									});
								} else {
									if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
										metadataOptions.push(`${key}.${key2}`);
									}
								}
							});
						} else {
							if (key !== '_defaultLabel') {
								metadataOptions.push(`${key}`);
							}
						}
					});
				}

				const drafts = sortedNotifications.filter((e) => e.status == 'Draft');
				const scheduled = sortedNotifications.filter((e) => e.status == 'Scheduled');
				const sent = sortedNotifications.filter((e) => e.status == 'Sent');

				this.setState({
					sortedNotifications: sortedNotifications,
					notifications: sortedNotifications,
					campaignDrafts: drafts,
					campaignScheduled: scheduled,
					campaignSent: sent,
					loading: false,
					keyedMetadata: metadataOptions,
					keyedData: res.data.keyedData,
					notificationViews: notificationViews,
					loading: false
				});
			}
		});
	}

	hideCampaigns = () => {
		const { campaignsHidden } = this.state;
		this.setState({ campaignsHidden: !campaignsHidden });
	};

	toggleAddCampaign = (cb) => {
		const { addCampaignToggle } = this.state;
		mainActionCB = cb;
		this.setState({ addCampaignToggle: !addCampaignToggle });
	};

	createFromTemplateToggle = () => {
		const { toggleCreateFromTemplate } = this.state;
		this.setState({ toggleCreateFromTemplate: !toggleCreateFromTemplate });
	};

	addCampaign = (campaign, callback) => {
		const { campaignDrafts, notifications } = this.state;

		this.setState({ campaignDrafts: [...campaignDrafts, campaign], notifications: [...notifications, campaign] }, () => {
			this.props.refreshSectionCounts();
			mainActionCB && mainActionCB('update');
			callback && callback()
		});
	};
	toggleSingleDeleteCampaign = (rowId) => {
		const { toggleDeleteCampaigns } = this.state;

		this.setState({
			toggleDeleteCampaigns: !toggleDeleteCampaigns,
			checkedCampaigns: { [rowId]: true },
		});
	};

	toggleDuplicateSingleCampaign = (rowId) => {
		const { toggleDuplicateSingleCampaign } = this.state;

		this.setState({
			toggleDuplicateSingleCampaign: !toggleDuplicateSingleCampaign,
			selectedRow: rowId
		});
	};


	toggleDeleteCampaigns = (checkedCampaigns, bcb) => {
		const { toggleDeleteCampaigns } = this.state;
		batchCallback = bcb;

		this.setState({
			checkedCampaigns: checkedCampaigns || {},
			toggleDeleteCampaigns: !toggleDeleteCampaigns
		});
	};

	deleteCheckedCampaigns = (callback) => {
		const { checkedCampaigns, deletedCampaigns, notifications, editCount } = this.state;

		let updatedNotifications = notifications.filter((e) => !Object.keys(checkedCampaigns).includes(e._id));
		const campaignDrafts = updatedNotifications.filter((e) => e.status == 'Draft');
		const campaignScheduled = updatedNotifications.filter((e) => e.status == 'Scheduled');
		const campaignSent = updatedNotifications.filter((e) => e.status == 'Sent');
		this.setState(
			{
				editCount: editCount + 1,
				notifications: updatedNotifications,
				sortedNotifications: updatedNotifications,
				deletedCampaigns: [...deletedCampaigns, ...Object.keys(checkedCampaigns)],
				checkedCampaigns: {},
				campaignDrafts,
				campaignScheduled,
				campaignSent,

			},

			() => {
				this.props.refreshSectionCounts()
				callback();
			}
		);
	};

	updateDeleteCampaign = (campaignId, callback) => {
		const { notifications, editCount } = this.state;
		let updatedNotifications = notifications.filter((e) => e._id !== campaignId);
		const campaignDrafts = updatedNotifications.filter((e) => e.status == 'Draft');
		const campaignScheduled = updatedNotifications.filter((e) => e.status == 'Scheduled');
		const campaignSent = updatedNotifications.filter((e) => e.status == 'Sent');
		this.setState(
			{
				editCount: editCount + 1,
				notifications: updatedNotifications,
				sortedNotifications: updatedNotifications,
				deletedCampaigns: [...this.state.deletedCampaigns, campaignId],
				campaignDrafts,
				campaignScheduled,
				campaignSent,

			},
			() => {

				callback && callback();
				batchCallback && batchCallback('delete');
				this.props.refreshSectionCounts()

			}
		);
	};

	updateNotification = (updatedNotification) => {
		const { notifications, campaignDrafts, campaignScheduled, campaignSent } = this.state;
		let tempNotifications = notifications;
		const { _id } = updatedNotification;
		for (var i = 0; i < tempNotifications.length; i++) {
			if (tempNotifications[i]._id === _id) {
				Object.assign(tempNotifications[i], updatedNotification);
			}
		}
		const updatedDrafts = tempNotifications.filter((e) => e.status == 'Draft');
		const updatedScheduled = tempNotifications.filter((e) => e.status == 'Scheduled');
		const updatedSent = tempNotifications.filter((e) => e.status == 'Sent');
		this.setState({
			notifications: tempNotifications,
			campaignDrafts: updatedDrafts,
			campaignScheduled: updatedScheduled,
			campaignSent: updatedSent
		});
	}
	updateSingleText = (tUpdate, callback) => {
		let tempText = this.state.notifications
		const { selectedRow } = this.state;
		for (var i = 0; i < tempText.length; i++) {
			if (selectedRow == tempText[i]._id) {

				Object.assign(tempText[i], tUpdate);
			}
		}
		this.setState({ textMessage: tempText }, () => {
			callback && callback()
		})

	}

	toggleRenameSingle = (id) => {
		const { toggleRenameSingle } = this.state;
		this.setState({ toggleRenameSingle: !toggleRenameSingle, selectedRow: id });
	}

	render() {
		const {
			addCampaignToggle,
			columnTypes,
			columns,
			notificationViews,
			notifications,
			loading,
			toggleDeleteCampaigns,
			checkedCampaigns,
			deletedCampaigns,
			keyedData,
			keyedMetadata,
			selectedSection,
			campaignDrafts,
			campaignScheduled,
			campaignSent,
			toggleRenameSingle,
			selectedRow,
			toggleDuplicateSingleCampaign
		} = this.state;
		const { eventId, event, orgId, editing } = this.props;
		const campaignList = selectedSection == 0 ? this.state.campaignDrafts : selectedSection == 1 ? this.state.campaignScheduled : this.state.campaignSent
		return (
			<Switch>
				<Route path={`/:organization/events/:eventId/notifications/:notificationId`}>
					<EventNotificationCampaign
						groups={this.props.event.groups}
						sessions={this.props.event.sessions}
						event={this.props.event}
						eventId={eventId}
						orgId={orgId}
						notifications={notifications}
						keyedMetadata={keyedMetadata}
						keyedData={keyedData}
						updateDeleteCampaign={this.updateDeleteCampaign}
						updateNotification={this.updateNotification}
						organization={this.props.organization}

					/>
				</Route>
				<Route path={`/:organization/events/:eventId/notifications`}>
					<Fragment>

						{!loading && (
							<div className="sectionContainer">

								<div className="sectionContainer sectionBody">

									<BasicViewContainer
										editing={editing}
										key={`Notifications${this.state.editCount}`}
										cancelEdit={() => {
											this.cancelEdit();
										}}
										rowSettings={[
											{
												label: <span><i className='las la-pen mr-10'></i>Rename Campaign</span>,
												onClick: this.toggleRenameSingle
											},
											{
												label: <span><i className='las la-copy mr-10'></i>Duplicate Campaign</span>,
												onClick: this.toggleDuplicateSingleCampaign
											},
											{
												label: <span><i className='las la-trash mr-10'></i>Delete Campaign</span>,
												onClick: this.toggleSingleDeleteCampaign
											}
										]}
										saveEdit={() => {
											this.saveInfo();
										}}
										title={'Notifications'}
										enableLastLink={true}
										breadcrumbs={[
											{
												name: event.name
											}
										]}
										orgId={orgId}
										tabs={[{
											title: 'Drafts',
											count: campaignList.length
										}, {
											title: 'Scheduled',
											count: campaignList.length
										}, {
											title: 'Sent',
											count: campaignList.length

										}]}
										onTabsChange={(i) => {
											if (i === 0) {
												return campaignDrafts
											} else if (i === 1) {
												return campaignScheduled
											} else {
												return campaignSent
											}
										}}

										columns={columns}
										columnTypes={columnTypes}
										views={notificationViews}
										entries={campaignList}
										deletedEntries={deletedCampaigns}
										readOnly={false}
										mainActions={[{ onClick: this.toggleAddCampaign, label: 'Create New Notification' }]}
										enableDownloadViews={true}
										type={'Notifications'}
										referenceCollection={'Events'}
										defaultSort={'name'}
										batchActions={[
											{
												label: 'Delete',
												iconClass: 'las la-trash-alt',
												class: 'danger',
												onClick: this.toggleDeleteCampaigns
											}
										]}
										referenceId={eventId}
									/>

								</div>

								{addCampaignToggle && (
									<AddCampaignModal
										isOpen={addCampaignToggle}
										addCampaign={this.addCampaign}
										toggle={this.toggleAddCampaign}
										eventId={eventId}
										orgId={this.props.orgId}
									/>
								)}
								{toggleDuplicateSingleCampaign &&
									<DuplicateCampaignModal
										isOpen={toggleDuplicateSingleCampaign}
										notification={notifications.find((e) => e._id === selectedRow)}
										orgId={orgId}
										eventId={eventId}
										event={event}
										toggle={this.toggleDuplicateSingleCampaign}
									/>}
								{toggleDeleteCampaigns && (
									<DeleteNotificationsModal
										isOpen={toggleDeleteCampaigns}
										checkedCampaigns={checkedCampaigns}
										toggle={this.toggleDeleteCampaigns}
										deleteCheckedCampaigns={this.deleteCheckedCampaigns}
										eventId={eventId}
										orgId={this.props.orgId}
										update={this.updateDeleteCampaign}
									/>
								)}
								{toggleRenameSingle && (
									<RenameNotificationModal
										isOpen={toggleRenameSingle}
										notification={notifications.find((e) => e._id === selectedRow)}
										orgId={this.props.orgId}
										eventId={eventId}
										toggle={this.toggleRenameSingle}
										update={this.updateSingleText}
									/>
								)}
							</div>
						)}
						<LoadingWizard text="Loading Notification Campaigns" loading={loading} />
					</Fragment>

				</Route>
			</Switch >
		);
	}
}

export default withRouter(EventNotificationCampaigns);
