import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../../../utils/API';
import LoadingWizard from '../../../../components/spinner/wizard';
import TabsContainer from '../../../../components/views/TabsContainer';
import ActionButtonContainer from '../../../../components/views/ActionButtonContainer';
import AddTemplateModal from '../modals/addTemplateModal';
import DeleteTemplatesModal from '../modals/deleteTemplatesModal';
import GridLayoutTable from '../../../../components/tables/GridLayoutTable';
class EventTemplates extends Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name',
			createdByName: 'Created By'
		};
		const columnTypes = {
			name: 'text',
			createdByName: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = cfc !== 'name' ? true : false;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		const gridNormalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			editing: false,
			templates: [
				{ title: 'Save the Date' },
				{ title: 'Invitation' },
				{ title: 'Registration Confirmation' },
				{ title: 'New Event Excitement' },
				{ title: 'Event Pass' },
				{ title: 'Join Now' },
				{ title: 'Thanks for Attending' }
			],
			campaignsHidden: false,
			allTemplates: false,
			addCampaignToggle: false,
			emailsViews: [],
			emails: [],
			deletedEmails: [],
			loading: true,
			selectedSection: 0,
			searching: false,
			searchText: '',
			isGridLayout: false,
			normalizedColumns: normalizedColumns,
			gridNormalizedColumns: gridNormalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			searchText: '',
			selectedColumnSort: null,
			sortDesc: false,
			checkedTemplates: {},
			toggleDeleteTemplates: false,
			singleSelection: false,
			allChecked: false
		};
	}

	async componentDidMount() {
		const { eventId, orgId } = this.props;

		await API().get(`Organizations/${orgId}/events/${eventId}/templates`).then((res) => {
			//map links
			let templates = res.data.templates.map((t) => {
				t.link = `/${orgId}/events/${eventId}/emails/templates/${t._id}`;
				return t;
			});
			const metadataOptions = [];
			{
				Object.keys(res.data.keyedData).forEach((key) => {
					const layer1 = res.data.keyedData[key];
					if (typeof layer1 === 'object') {
						Object.keys(layer1).forEach((key2) => {
							const layer2 = layer1[key2];
							if (typeof layer2 === 'object') {
								Object.keys(layer2).forEach((key3) => {
									if (
										key !== '_defaultLabel' &&
										key2 !== '_defaultLabel' &&
										key3 !== '_defaultLabel'
									) {
										metadataOptions.push(`${key}.${key2}.${key3}`);
									}
								});
							} else {
								if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
									metadataOptions.push(`${key}.${key2}`);
								}
							}
						});
					} else {
						if (key !== '_defaultLabel') {
							metadataOptions.push(`${key}`);
						}
					}
				});
			}
			let platformTemplates = res.data.platformTemplates;
			if (res.data) {
				this.setState({
					templates: templates,
					keyedData: res.data.keyedData,
					keyedMetadata: metadataOptions,
					loading: false
				});
			}
		});
	}
	cancelEdit = () => { };
	saveInfo = () => { };

	hideCampaigns = () => {
		const { campaignsHidden } = this.state;
		this.setState({ campaignsHidden: !campaignsHidden });
	};

	toggleAddCampaign = () => {
		const { addCampaignToggle } = this.state;
		this.setState({ addCampaignToggle: !addCampaignToggle });
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	toggleAddTemplate = () => {
		this.setState({ addTemplateModalOpen: !this.state.addTemplateModalOpen });
	};

	toggleDeleteTemplates = () => {
		this.setState({ toggleDeleteTemplates: !this.state.toggleDeleteTemplates });
	};

	searchEntries = (searchText) => {
		this.setState({ searching: searchText != '' ? true : false, searchText: searchText ? searchText : '' });
	};
	toggleLayout = (selected) => {
		this.setState({ isGridLayout: selected });
	};
	updateTemplates = (template) => {
		const { templates } = this.state;
		let finalTemplates = templates.map((t) => {
			if (t._id == template._id) {
				t = template;
			}
			return t;
		});

		this.setState({ templates: finalTemplates });
	};
	updateCheckedTemplates = (checked) => {
		this.setState({ checkedTemplates: checked });
	};
	deleteCheckedTemplates = (callback) => {
		const { checkedTemplates, deletedTemplates, templates } = this.state;

		let updatedTemplates = templates.filter((t) => !Object.keys(checkedTemplates).includes(t._id));

		this.setState(
			{
				templates: updatedTemplates,

				checkedTemplates: {}
			},
			() => {
				callback();
			}
		);
	};
	addTemplate = (template, callback) => {
		const { templates } = this.state;
		this.setState({ templates: [...templates, template] }, callback);
	};

	updateDeleteTemplate = (templateId, callback) => {
		const { templates } = this.state;

		let updatedTemplates = templates.filter((e) => e._id !== templateId);

		this.setState(
			{
				templates: updatedTemplates
			},
			() => {
				callback();
			}
		);
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const {
			templates,
			columns,
			loading,
			selectedSection,
			addTemplateModalOpen,
			isGridLayout,
			normalizedColumns,
			toggledColumns,
			searchText,
			selectedColumnSort,
			sortDesc,
			searching,
			keyedData,
			keyedMetadata,
			toggleDeleteTemplates,
			checkedTemplates,
			singleSelection,
			allChecked,
			gridNormalizedColumns
		} = this.state;
		const { eventId, event, orgId, editing } = this.props;
		let searchedTemplate = templates;
		//Searching by text from input
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					searchedTemplate = searchedTemplate.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		if (selectedSection == 1) {
			searchedTemplate = searchedTemplate.filter((template) => template.uploadedByYou);
		}
		if (selectedSection == 2) {
			searchedTemplate = searchedTemplate.filter((template) => template.uploadedByYou);
		}
		if (selectedSection == 3) {
			searchedTemplate = searchedTemplate.filter((template) => template.createdByYou);
		}

		if (selectedColumnSort) {
			searchedTemplate = searchedTemplate.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			searchedTemplate = sortDesc ? searchedTemplate.reverse() : searchedTemplate;
		}

		let mainAction = [];
		mainAction = [{ onClick: this.toggleAddTemplate, label: 'Add Template' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = true;

		batchActions = [
			{
				type: 'button',
				label: 'Delete Templates',
				onClick: this.toggleDeleteTemplates,
				iconClass: 'las la-trash-alt',
				class: 'danger'
			}
		];

		checkedEntryCount = Object.keys(this.state.checkedTemplates).length;
		entriesLength = searching || selectedSection == 1 ? searchedTemplate.length : templates.length;
		uncheckEntries = () => {
			this.setState({ checkedTemplates: [] });
		};
		searchEnabled = true;
		return (
			<Fragment>
				<div className="sectionBody ">
					<div className="sectionBody posRel">
						{!loading && <div style={{ margin: '0 20px' }}>
							<TabsContainer
								tabs={[
									{ title: 'All' },
									{ title: 'Recently Used' },
									{ title: 'Created By You' }
								]}
								updateSelected={this.updateSelected}
								selectedIndex={selectedSection}
							/>

							<ActionButtonContainer
								mainActions={[]}
								searchEnabled={searchEnabled}
								searchEntries={this.searchEntries}
								enabledViewSelection={true}
								isGridLayout={isGridLayout}
								toggleLayout={this.toggleLayout}
							/>

							<GridLayoutTable
								classes="nested"

								columns={normalizedColumns}
								toggledColumns={toggledColumns}
								isGridLayout={isGridLayout}
								data={searchedTemplate}
								eventId={eventId}
								event={event}
								orgId={orgId}
								isRemoveEllipses={true}
								mainColumnActive={false}
								checked={this.state.checkedTemplates}
								isiFrame={true}
								checkRow={(entryId) => {
									const checked = this.state.checkedTemplates;
									checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
									this.updateCheckedTemplates(checked);
									this.setState({
										checkedTemplates: checked
									});
								}}
								mainActions={mainAction}
								checkAllRows={(entryIds) => {
									let checked = this.state.checkedTemplates;
									entryIds.length > 0
										? entryIds.forEach((entryId) => {
											checked[entryId] = true;
										})
										: (checked = {});
									this.updateCheckedTemplates(checked);

									this.setState({
										checkedTemplates: checked
									});
								}}
								sortData={this.sortData}
							/>
							<ActionButtonContainer

								batchActions={batchActions}
								checkedEntryCount={checkedEntryCount}
								entriesLength={entriesLength}
								uncheckEntries={uncheckEntries}
							/>
						</div>}
					</div>
					{addTemplateModalOpen && (
						<AddTemplateModal
							isOpen={addTemplateModalOpen}
							addTemplate={this.addTemplate}
							toggle={this.toggleAddTemplate}
							eventId={eventId}
							orgId={this.props.orgId}
						/>
					)}
					{toggleDeleteTemplates && (
						<DeleteTemplatesModal
							isOpen={toggleDeleteTemplates}
							checkedTemplates={this.state.checkedTemplates}
							toggle={this.toggleDeleteTemplates}
							deleteCheckedTemplates={this.deleteCheckedTemplates}
							eventId={eventId}
							orgId={this.props.orgId}
						/>
					)}

				</div>
				<LoadingWizard text="RETRIEVING TEMPLATES" loading={loading} />
			</Fragment>
		);
	}
}

export default withRouter(EventTemplates);
