import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import InputField from '../../components/inputs/inputField';
import SelectField from '../../components/inputs/selectField';
import DeleteContactModal from './modals/deleteContactModal';
import API from '../../utils/API';
import LoadingWizard from '../../components/spinner/wizard';
import TabsContainer from '../../components/views/TabsContainer';
import ViewsTable from '../../components/tables/ViewsTable';
import { withRouter } from 'react-router-dom';
import SectionHeader from '../../components/views/SectionHeader';
import SectionFooter from '../../components/views/SectionFooter';
import ToggleField from '../../components/inputs/toggleField';
import LinkCopy from '../../components/inputs/linkCopy';

const permissionOptions = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'user', label: 'User' },
	{ value: 'guest', label: 'Guest' }
];

class ContactDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			contact: {},
			editedContact: {},
			invalid: false,
			saving: false,
			selectedSection: 0,
			deleteModalOpen: false,
			loading: true,
			requiredFields: {
				firstName: false,
				emailAddress: false
			},
			errorPhoneNumber: false,
			errorPhoneMessage: '',
			errorContactPhoneNumber: false,
			errorContactPhoneMessage: '',


		};
	}
	componentDidMount() {
		const contactId = this.props.match.params.contact;
		const { orgId } = this.props;
		API()
			.get(`Organizations/${orgId}/contacts/${contactId}`)
			.then((res) => {
				if (res.data) {
					this.setState({ contact: res.data, editedContact: { ...res.data }, loading: false });
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.contact !== prevProps.match.params.contact) {
			const contactId = this.props.match.params.contact;
			const { orgId } = this.props;
			API()
				.get(`Organizations/${orgId}/contacts/${contactId}`)
				.then((res) => {
					if (res.data) {
						this.setState({ contact: res.data, editedContact: { ...res.data }, loading: false });
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({ loading: false });
				});
		}
	}

	saveBasics = async (editedContact, callback) => {
		const { orgId } = this.props;

		API()
			.patch(`Organizations/${orgId}/contacts/${editedContact._id}`, { request: 'update', ...editedContact })
			.then((res) => {
				if (res.data) {
					this.setState({ contact: { ...editedContact, ...res.data } });
					callback && callback();
				}
			});
	};

	saveDetails = (e) => {
		const { contact, editedContact, requiredFields } = this.state;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedContact[key] || editedContact[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.setState({ saving: true });
			this.saveBasics({ ...contact, ...editedContact }, () => {
				this.setState({ editing: false, saving: false, error: '', requiredFields: requiredFields });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	cancelEdit = () => {
		const contact = this.state.contact;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedContact: { ...contact },
			requiredFields: requiredFields,
			editing: false
		});
	};

	deleteContact = async () => {
		const { editedContact } = this.state;
		await API()
			.patch(`Organizations/${this.props.orgId}/contacts`, {
				request: 'delete',
				userIds: [editedContact._id]
			})
			.then((res) => {
				this.toggleDeleteModal();
				window.open('../', '_self');
			});
	};

	toggleDeleteModal = () => {
		this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	verifyPhone(value, isContact) {
		const phoneNumber = value
		if (phoneNumber.length >= 8) {
			const data = JSON.stringify({ phone: phoneNumber });
			fetch("https://intl-tel-input-7366.twil.io/lookup", {
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: data,
			})
				.then(response => {
					return response.json();
				})
				.then((json) => {
					if (json.success) {
						isContact ? this.setState({ errorContactPhoneNumber: false }) :
							this.setState({ errorPhoneNumber: false });
					} else {
						if (isContact) {
							this.setState({ errorContactPhoneMessage: json.error });
							this.setState({ errorContactPhoneNumber: true });
						} else {
							this.setState({ errorPhoneMessage: json.error });
							this.setState({ errorPhoneNumber: true });
						}
					}
				})
				.catch((error) => {
					console.error("Error with fetch operation:", error);
				});
		} else {
			isContact ? this.setState({ errorContactPhoneNumber: false }) :
				this.setState({ errorPhoneNumber: false, errorPhoneMessage: null });
		}
	}
	renderSection() {
		const { selectedSection, contact, editedContact, requiredFields } = this.state;

		const { readOnly, organization } = this.props;
		const perm = permissionOptions.find((p) => p.value === editedContact.permission);
		switch (selectedSection) {
			case 0:
				return (
					<div className="sectionBlock">
						<h2 className="blockHeader">Account Details</h2>
						<InputField
							label="First Name"
							required={true}
							errorTrigger={requiredFields.firstName}
							value={editedContact.firstName}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, firstName: e.target.value },
									editing: true
								});
							}}
						/>
						<InputField
							label="Last Name"
							value={editedContact.lastName}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, lastName: e.target.value },
									editing: true
								});
							}}
						/>
						<InputField
							label="Email Address"
							required={true}
							errorTrigger={requiredFields.emailAddress}
							value={editedContact.emailAddress}
							type="email"
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, emailAddress: e.target.value },
									editing: true
								});
							}}
						/>
						<SelectField
							label="Permission"
							value={perm}
							name="permission"
							options={permissionOptions}
							onChange={(permission) => {
								this.setState({
									editedContact: { ...editedContact, permission: permission.value },
									editing: true
								});
							}}
						/>
						{perm.value !== "guest" && <>
							<ToggleField label="Enable Authentication Bypass Link"
								checked={editedContact.bypassLinkEnabled}
								icons={false}
								onChange={(e) => {
									this.setState({
										editedContact: { ...editedContact, bypassLinkEnabled: e.target.checked },
										editing: true
									});
								}}
							/>

							{editedContact.bypassLinkEnabled && contact.bypassToken && (<LinkCopy link={`https://${organization.hostname ? organization.hostname : (organization.identifier + ".simple.events")}/?aud=${contact.bypassToken}&email=${contact.emailAddress}`} />)}
						</>}
						<h2 className="blockHeader">Contact Information</h2>
						<InputField
							label="Phone Number"
							value={editedContact.phoneNumber}
							disabled={readOnly}
							type="tel"
							errorTrigger={this.state.errorPhoneNumber}
							errorMessage={this.state.errorPhoneMessage}
							onChange={(e) => {
								let cleanValue = e.target.value.replace(/[^0-9+]/g, '');
								cleanValue = !cleanValue.startsWith("+")
									? cleanValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
									: cleanValue.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
								this.verifyPhone(cleanValue);

								this.setState({
									editedContact: { ...editedContact, phoneNumber: cleanValue },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Company"
							value={editedContact.company}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, company: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Title"
							value={editedContact.title}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, title: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Dietary Preferences"
							value={editedContact.dietaryPreference}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, dietaryPreference: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Food Allergies"
							value={editedContact.foodAllergies}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, foodAllergies: e.target.value },
									editing: true
								});
							}
							}
						/>

						<InputField
							label="Emergency Contact Name"
							value={editedContact.emergencyContactName}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, emergencyContactName: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Emergency Contact Phone"
							value={editedContact.emergencyContactPhoneNumber}
							disabled={readOnly}
							errorTrigger={this.state.errorContactPhoneNumber}
							errorMessage={this.state.errorContactPhoneMessage}
							onChange={(e) => {
								let cleanValue = e.target.value.replace(/[^0-9+]/g, '');
								cleanValue = !cleanValue.startsWith("+")
									? cleanValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
									: cleanValue.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
								this.verifyPhone(cleanValue, true);
								this.setState({
									editedContact: { ...editedContact, emergencyContactPhoneNumber: cleanValue },
									editing: true
								});
							}
							}
						/>

						<InputField
							label="Address"
							value={editedContact.address}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, address: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="City"
							value={editedContact.city}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, city: e.target.value },
									editing: true
								});
							}

							}
						/>
						<InputField
							label="State"
							value={editedContact.state}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, state: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Zip"
							value={editedContact.zip}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, zip: e.target.value },
									editing: true
								});
							}
							}
						/>
						<InputField
							label="Country"
							value={editedContact.country}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedContact: { ...editedContact, country: e.target.value },
									editing: true
								});
							}
							}
						/>


					</div>
				);
			case 1:
				return (
					<div className='sectionBlock'>
						<h2 className="blockHeader">MedPro</h2>

						<InputField
							label="MedPro ID"
							value={editedContact.medProId}
							type="text"
							inputIcon={<i className="las la-lock" />}
							disabled={true}
						/>

						<InputField
							label="NPI Number"
							value={editedContact.NPINumber}
							type="text"
							inputIcon={<i className="las la-lock" />}
							disabled={true}
						/>

						<InputField
							label="State License Number"
							value={editedContact.stateLicenseNumber}
							type="text"
							inputIcon={<i className="las la-lock" />}
							disabled={true}
						/>
						<InputField
							label="State License State"
							value={editedContact.licenseState}
							type="text"
							inputIcon={<i className="las la-lock" />}
							disabled={true}
						/>

						{editedContact.integrations && editedContact.integrations.salesforce && <div >
							<h2 className="blockHeader">Salesforce</h2>
							<ToggleField label={`Is Lead`} disabled={true} checked={editedContact.integrations.salesforce.leadId && editedContact.integrations.salesforce.leadId !== ''} icons={false} />
							<ToggleField label="Is Contact" disabled={true} checked={editedContact.integrations.salesforce.contactId && editedContact.integrations.salesforce.contactId !== ''} icons={false} />
							<InputField
								label="Account Name"
								value={editedContact.integrations.salesforce.accountName}
								disabled={true}
								type="text"
								inputIcon={<i className="las la-lock" />}
							/>
						</div>}
					</div>
				)

			case 2:
				const columns = {
					eventName: 'Event Name',
					eventStartDate: 'Start Date',
					eventEndDate: 'End Date',
					firstName: 'First Name',
					lastName: 'Last Name',
					email: 'Email',
					updatedAt: 'Updated At',
					createdAt: 'Created At'
				};
				const columnTypes = {
					eventName: 'string',
					eventStartDate: 'date',
					eventEndDate: 'date',
					firstName: 'string',
					lastName: 'string',
					email: 'string',
					updatedAt: 'timestamp',
					createdAt: 'timestamp'
				};
				const toggledColumns = {};
				const normalizedColumns = Object.keys(columns).map((cfc) => {
					toggledColumns[cfc] = true;
					return {
						label: columns[cfc],
						key: cfc,
						value: cfc,
						type: columnTypes[cfc],
						sortAsc: false,
						sortDesc: false
					};
				});
				return (
					<ViewsTable
						style={{ margin: 0 }}
						columns={normalizedColumns}
						toggledColumns={toggledColumns}
						data={contact.attendeeRecords}
						mainColumnActive={false}
						readOnly={true}
						checked={[]}
					/>
				);
			default:
		}
	}

	render() {
		const {
			loading,
			contact,
			editing,
			editedContact,
			deleteModalOpen,
			selectedSection
		} = this.state;
		return <div className='pageContainer' key={`contact-${contact._id}`}>
			{!loading && (
				<div className="sectionContainer">
					<SectionHeader
						title={`${contact.firstName} ${contact.lastName}`}
						titleIcon={
							contact.profilePicture ? (
								<img alt="profilePic" src={contact.profilePicture} style={{ maxWidth: '100%', overflow: 'clip' }} />
							) : (
								<div className="calibreBold fs-30">
									{contact.firstName && contact.firstName.toUpperCase().charAt(0)}
									{contact.lastName && contact.lastName.toUpperCase().charAt(0)}
								</div>
							)
						}
						breadcrumbs={[
							{
								name: 'Contacts',
							},
						]}
						subActions={[{ onClick: this.toggleDeleteModal, label: 'Delete Attendee' }]}
					>
						<TabsContainer
							tabs={[{ title: 'Information' }, { title: 'Integrations' }, { title: 'Events' }]}
							updateSelected={this.updateSelected}
							selectedIndex={selectedSection}
						/></SectionHeader>

					<div className="sectionBody ">{this.renderSection()}</div>
					<SectionFooter
						editing={editing}
						cancelEdit={this.cancelEdit}
						saveEdit={this.saveDetails}
						updatedAt={contact.updatedAt} />
					{deleteModalOpen && (
						<DeleteContactModal
							isOpen={deleteModalOpen}
							name={editedContact.firstName.concat(' ').concat(editedContact.lastName)}
							toggle={this.toggleDeleteModal}
							deleteContact={this.deleteContact}
						/>
					)}
				</div>)}
			<LoadingWizard text="LOADING CONTACT" loading={loading} />
		</div>
	}
}
export default withRouter(ContactDetails);
