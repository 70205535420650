import React, { Component } from 'react';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
class AddPageModal extends Component {
	state = {
		title: '',
		description: '',
		template: '',
		submitting: false,
		requiredFields: {
			title: false
		}
	};

	addPage = async () => {
		const { title } = this.state;

		const page = {
			title: this.state.title,
			description: this.state.description,
			template: this.state.template,
			pathSlug: this.state.title.replace(/\s/g, '').replace(/\?/g, ''),
			useLegacyStyle: false
		};
		let valid = true;
		const requiredFields = this.state.requiredFields;

		if (title === '') {
			valid = false;
			requiredFields.title = true;
		}

		if (valid) {
			this.setState({ submitting: true })
			this.props.handleSubmit(page, this.props.toggle);
		} else {
			this.setState({ requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { title, description, template, submitting, requiredFields } = this.state;
		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add Page"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Title"
								required={true}
								value={title}
								errorTrigger={requiredFields.title}
								onChange={(e) => this.setState({ title: e.target.value })}
							/>

							<InputField
								label="Description"
								value={description}
								onChange={(e) => this.setState({ description: e.target.value })}
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding Page...'}
				actionButton={() => this.addPage()}
				actionButtonDisabled={submitting}


			></FieldModal>

		);
	}
}

export default AddPageModal;
