import React, { Component, Fragment } from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
import BasicSectionHeader from '../../components/views/BasicSectionHeader';
import Request from './request';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import InputField from '../../components/inputs/inputField';
import NewCalendarModal from './modals/newCalendarModal';
import Calendar from './calendar';
import DeleteCalendarsModal from './modals/deleteCalendars';

let batchActionCB;
class ProgramCalendars extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;

		let fieldColumns = {
			name: 'Calendar Name',
			active: 'Active',
			bookings: 'Bookings',
			availability: 'Availability'
		};

		let fieldTypes = {
			name: 'text',
			active: 'boolean',
			bookings: 'number',
			availability: 'string'
		};
		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'active',
					'bookings',
					'availability',
				]
			},
		};

		/* fieldColumns = { ...fieldColumns, ...props.program.fieldLabels };
		fieldTypes = { ...fieldTypes, ...props.program.fieldTypes }; */

		const formattedFieldColumns = {};
		const formattedFieldTypes = {};
		Object.keys(fieldColumns).forEach((key) => {
			if (key.includes('-')) {
				const shortKeys = key.split('-');
				const shortKey = shortKeys[shortKeys.length - 1];
				formattedFieldColumns[shortKey] = fieldColumns[key];
				formattedFieldTypes[shortKey] = fieldTypes[key];
			} else {
				formattedFieldColumns[key] = fieldColumns[key];
				formattedFieldTypes[key] = fieldTypes[key];
			}
		});

		this.state = {
			categoryColumns: fieldCategories,
			programId: props.program._id,
			calendars: props.calendars.map((c) => {
				const availability = this.formatAvailability(c.schedule);
				return {
					...c,
					link: `/${props.orgId}/programs/${props.program._id}/calendars/${c._id}`,
					availability: availability,
				};
			}),
			requestViews: props.calendarViews,
			loading: false,
			columns: formattedFieldColumns,
			columnTypes: formattedFieldTypes,
			addModalOpen: false,
			deleteCalendarsModalOpen: false,
			checkedCalendars: {},
			sidePanelOpen: false,
			activeRow: ''
		};
	}

	formatAvailability = (schedule) => {
		const availability = [];
		if (schedule.monday && schedule.monday.available) {
			availability.push('Monday');
		}
		if (schedule.tuesday && schedule.tuesday.available) {
			availability.push('Tuesday');
		}
		if (schedule.wednesday && schedule.wednesday.available) {
			availability.push('Wednesday');
		}
		if (schedule.thursday && schedule.thursday.available) {
			availability.push('Thursday');
		}
		if (schedule.friday && schedule.friday.available) {
			availability.push('Friday');
		}
		if (schedule.saturday && schedule.saturday.available) {
			availability.push('Saturday');
		}
		if (schedule.sunday && schedule.sunday.available) {
			availability.push('Sunday');
		}
		return availability.join(', ');
	};

	addCalendar = (calendar, cb) => {
		this.props.addCalendar(calendar, (c) => {
			const { calendars } = this.state;
			const formattedCalendar = {
				...c,
				availability: this.formatAvailability(c.schedule),
			};
			calendars.push(formattedCalendar);
			this.setState({ calendars: calendars });
			cb && cb()
		});
	}

	toggleAddModal = () => {
		this.setState({
			addModalOpen: !this.state.addModalOpen
		});
	}

	updateCalendars = (calendar) => {
		let calendars = this.state.calendars
		const updateCalendars = calendars.map(cal => 
			cal._id === calendar._id ? { ...calendar, availability: this.formatAvailability(calendar.schedule) } : cal );
		this.setState({ calendars: updateCalendars });
	}

	toggleDeleteCalendars = (checkedCalendars, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedCalendars: checkedCalendars || this.state.checkedCalendars,
			deleteCalendarsModalOpen: !this.state.deleteCalendarsModalOpen,
			calendars: this.props.calendars
		});
	};


	deleteCheckedCalendar = () => {
		this.props.deleteCalendar(Object.keys(this.state.checkedCalendars), () => {
			this.setState({
			}, () => {
				batchActionCB && batchActionCB('delete');
				this.toggleDeleteCalendars();
			});
		});
	};

	setActiveRow = (attendeeId) => {
		this.setState({ activeRow: attendeeId })
	}

	render() {
		const { program, orgId, } = this.props;
		const { requestViews, categoryColumns, columns, columnTypes, addModalOpen, deleteCalendarsModalOpen, checkedCalendars, calendars, sidePanelOpen, activeRow } = this.state;
		return (
			<Switch>
				<Route path={`/${orgId}/programs/${program._id}/calendars`}>
					<Fragment>
						<ViewsContainer
							activeRow={activeRow}
							categoryColumns={categoryColumns}
							views={requestViews}
							entries={calendars}
							columns={columns}
							columnTypes={columnTypes}
							title="Calendars"
							subtitle={null}
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddModal, label: 'Add Calendar' },
							]}
							batchActions={[
								{
									type: 'button',
									onClick: this.toggleDeleteCalendars,
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger'
								}
							]}
							enableDownloadViews={true}
							orgId={this.props.orgId}
							type={'ProgramCalendar'}
							referenceCollection={'Program'}
							referenceId={program._id}
							addedEntries={[]}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>
						{addModalOpen && <NewCalendarModal
							addCalendar={this.addCalendar}
							isOpen={addModalOpen}
							toggle={this.toggleAddModal}
						/>}
						{deleteCalendarsModalOpen && (
							<DeleteCalendarsModal
								isOpen={deleteCalendarsModalOpen}
								toggle={this.toggleDeleteCalendars}
								submit={() =>
									this.deleteCheckedCalendar(Object.keys(checkedCalendars), () => {
										this.setState({ checkedCalendars: {}, deleteCalendarsModalOpen: false });
									})}
								count={Object.keys(checkedCalendars).length}
							/>
						)}
						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>

								<Route path={`/:organization/programs/:program/calendars/:calendarId`}
									render={(props) => {
										const calendarId = props.match.params.calendarId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={calendarId}>
											<Calendar
												program={program}
												calendars={calendars}
												orgId={this.props.orgId}
												updateCalendars={this.updateCalendars}
												saveCalendar={this.props.saveCalendar}
												setActiveRow={this.setActiveRow}
											/>
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organzation/programs/:programId/calendars`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(ProgramCalendars);
