import React, { Component } from 'react';
import moment from 'moment';

import AddEventModal from './addEventModal';
import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
import DeleteEventsModal from './deleteEventsModal';
import AddEventFromProgramModal from './addEventFromProgramModal';
import DuplicateEventModal from './duplicateEventModal';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import FieldModal from '../../components/modals/fieldModal';
import OnboardingModal from './onboardingModal';

const localizer = momentLocalizer(moment)

let batchCallback;
class Events extends Component {
	constructor(props) {
		super(props);

		const fieldColumns = {
			name: 'Name',
			startDate: 'Start Date',
			startTime: 'Start Time',
			endDate: 'End Date',
			endTime: 'End Time',
			eventType: 'Event Type',
			registeredCount: 'Registered',
			spotsLeft: 'Spots Left',
			registrationCapacity: 'Registration Goal',
			location: 'Venue',
			timezone: 'Timezone',
			attendeeCount: 'Attendee Count',
			sessionCount: 'Session Count',
			updatedAt: 'Last Updated',
			nameSlug: 'Event Path',
			defaultPublicPath: 'Public Page',
			defaultUserPath: 'Home Page',
			pwaEnabled: 'App Enabled',
			programName: 'Program'
		};

		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'startDate',
					'startTime',
					'endDate',
					'endTime',
					'eventType',
					'registeredCount',
					'spotsLeft',
					'registrationCapacity',
					'location',
					'timezone',
					'attendeeCount',
					'sessionCount',
					'updatedAt',
					'nameSlug',
					'defaultPublicPath',
					'defaultUserPath',
					'pwaEnabled',
					'programName'
				]
			}
		};

		const fieldTypes = {
			name: 'string',
			startDate: 'date',
			startTime: 'time',
			endDate: 'date',
			endTime: 'time',
			eventType: 'eventType',
			registeredCount: 'number',
			spotsLeft: 'number',
			registrationCapacity: 'string',
			location: 'venue',
			timezone: 'timezone',
			attendeeCount: 'number',
			sessionCount: 'number',
			updatedAt: 'timestamp',
			nameSlug: 'string',
			defaultPublicPath: 'page',
			defaultUserPath: 'page',
			pwaEnabled: 'boolean',
			programName: 'string'
		};
		this.state = {
			events: [],
			organizedEvents: [],
			venueOptions: [],
			addModalOpen: false,
			addFromProgramModalOpen: false,
			deleteEventsModalOpen: false,
			loading: true,
			adding: false,
			categoryColumns: fieldCategories,
			columns: fieldColumns,
			columnTypes: fieldTypes,
			checkedEvents: {},
			customFields: [],
			programs: [],
			calendarOpen: false,
			selectedCalendarMonthDate: moment(),
			sortedEvents: [],
			toggleDuplicateEvent: false,
			onboardingModalOpen: !props.organization.onboarded,
		};
	}

	async componentDidMount() {
		await API().get(`Organizations/${this.props.orgId}/venues`).then((res) => {
			if (res.data) {
				const venues = res.data.map((venue) => {
					return {
						value: venue._id,
						label: venue.name,
						name: venue.name,
						timezone: venue.timezone || 'America/Los_Angeles',
						address: venue.address,
						platform: true
					};
				});
				this.setState({ venueOptions: venues });
			}
		});
		await API().get(`Organizations/${this.props.orgId}/events`).then((res) => {
			if (res.data) {
				const fieldCategories = { ...this.state.categoryColumns };
				const fieldColumns = { ...this.state.columns };
				const fieldTypes = { ...this.state.columnTypes };
				res.data.customFields.forEach((category) => {
					const fields = category.fields;
					fields.forEach((f) => {
						if (fieldCategories[category._id]) {
							fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
						} else {
							fieldCategories[category._id] = {
								title: category.title,
								columns: [`${category._id}*${f._id}`]
							};
						}
						fieldColumns[category._id + '*' + f._id] = f.fieldName;
						fieldTypes[category._id + '*' + f._id] = f.fieldType;
					});
				});
				const mappedEvents = res.data.events.map((event) => {
					event.programName = event.programId ? event.programId.name : '';
					if (event.metadata) {
						event.metadata.forEach(
							(meta) => (event[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue)
						);
					}
					event.link = `/${this.props.orgId}/events/${event._id}/overview`;
					return event;
				});
				const upcomingEvents = mappedEvents.filter((event) => {
					const startString = event.startDate + ' ' + event.startTime;
					return moment(startString).isAfter(moment());
				});
				const sortedEvents = upcomingEvents.sort((a, b) => {
					const aStartString = a.startDate + ' ' + a.startTime;
					const bStartString = b.startDate + ' ' + b.startTime;
					return moment(aStartString).isBefore(moment(bStartString)) ? -1 : 1;
				});
				this.setState({
					events: mappedEvents,
					organizedEvents: res.data.events,
					eventViews: res.data.views,
					customFields: res.data.customFields,
					categoryColumns: fieldCategories,
					columns: fieldColumns,
					columnTypes: fieldTypes,
					loading: false,
					programs: res.data.programs,
					sortedEvents
				});

			}
		});

		this.props.socket.on('details_updated', (resData) => {
			API().get(`Organizations/${this.props.orgId}/events`).then((res) => {
				if (res.data) {
					this.setState({ events: res.data, loading: false });
				}
			});
		});
	}

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};

	toggleAddFromProgram = () => {
		this.setState({ addFromProgramModalOpen: !this.state.addFromProgramModalOpen });
	};


	toggleDuplicateEvent = () => {
		this.setState({ toggleDuplicateEvent: !this.state.toggleDuplicateEvent });
	};



	addEvent = async (event, newVenue, fromProgram, fileUploads) => {
		const { orgId } = this.props;
		let venueId = '';

		if (newVenue) {
			// Replace null with an empty string for timezone in newVenue
			if (!newVenue.timezone) {
				delete newVenue.timezone;
			}

			if(newVenue.address && newVenue.address.address1 === 'undefined undefined'){
				newVenue.address.address1 = '';
			}

			await API().post(`Organizations/${orgId}/venue`, newVenue).then((res) => {
				if (res.data) {
					venueId = res.data._id;
				}
			});
		}

		this.setState({ adding: true });
		if (venueId !== '') {
			event = { ...event, venue: venueId };
		}


		await API()
			.post(fromProgram ? `Organizations/${orgId}/eventFromProgram` : `Organizations/${orgId}/event`, event)
			.then(async (res) => {
				if (res.data) {
					const event = res.data;
					if (fileUploads && fileUploads.length > 0) {
						const uploadedFiles = {};
						for (let fu = 0; fu < fileUploads.length; fu++) {
							const file = fileUploads[fu];
							const formData = new FormData();

							formData.append('files', file.file);
							await API()
								.post(`Organizations/${orgId}/events/${event._id}/assets`, formData, {
									headers: {
										'Content-Type': 'multipart/form-data'
									}
								})
								.then((res) => {
									if (res.data && res.data.assets && res.data.assets.length > 0 && res.data.assets[0] !== null && res.data.assets[0].url) {
										uploadedFiles[file.identifier] = res.data.assets[0].url;
									}
								})
								.catch((e) => {
									console.log(e);
									this.setState({ error: true, uploading: false });
								});
						}
						let eventUpdated = false;

						if (uploadedFiles.headerImage) {
							event.branding.headerImage = uploadedFiles.headerImage;
							eventUpdated = true;
						}

						if (uploadedFiles.logo) {
							event.branding.logo = uploadedFiles.logo;
							eventUpdated = true;
						}

						if (eventUpdated) {
							await API().patch(`Organizations/${orgId}/events/${event._id}`, {
								branding: JSON.stringify(event.branding)
							});
						}
					}
					window.location.href = `/${this.props.orgId}/events/${res.data._id}/overview`;
					return true;
				} else {
					return false;
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	duplicateEvent = async (event, newVenue, fromEvent, fileUploads) => {
		const { orgId } = this.props;
		let venueId = '';
		if (newVenue) {
			// Replace null with an empty string for timezone in newVenue
			if (newVenue.timezone === null) {
				newVenue.timezone = '';
			}
			await API().post(`Organizations/${orgId}/venue`, newVenue).then((res) => {
				if (res.data) {
					venueId = res.data._id;
				}
			});
		}

		this.setState({ adding: true });
		if (venueId !== '') {
			event = { ...event, venue: venueId };
		}

		await API()
			.post(`Organizations/${orgId}/duplicateEvent`, event)
			.then(async (res) => {
				if (res.data) {
					const event = res.data;
					if (fileUploads && fileUploads.length > 0) {
						const uploadedFiles = {};
						for (let fu = 0; fu < fileUploads.length; fu++) {
							const file = fileUploads[fu];
							const formData = new FormData();

							formData.append('files', file.file);
							await API()
								.post(`Organizations/${orgId}/events/${event._id}/assets`, formData, {
									headers: {
										'Content-Type': 'multipart/form-data'
									}
								})
								.then((res) => {
									if (res.data && res.data.assets && res.data.assets.length > 0 && res.data.assets[0] !== null && res.data.assets[0].url) {
										uploadedFiles[file.identifier] = res.data.assets[0].url;
									}
								})
								.catch((e) => {
									console.log(e);
									this.setState({ error: true, uploading: false });
								});
						}
						let eventUpdated = false;

						if (uploadedFiles.headerImage) {
							event.branding.headerImage = uploadedFiles.headerImage;
							eventUpdated = true;
						}

						if (uploadedFiles.logo) {
							event.branding.logo = uploadedFiles.logo;
							eventUpdated = true;
						}

						if (eventUpdated) {
							await API().patch(`Organizations/${orgId}/events/${event._id}`, {
								branding: JSON.stringify(event.branding)
							});
						}
					}
					window.location.href = `/${this.props.orgId}/events/${res.data._id}/overview`;
					return true;
				} else {
					return false;
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	deleteEvent = async (eventId) => {
		await API().delete(`Organizations/${this.props.orgId}/events/${eventId}`).then((res) => {
			if (res.data) {
				const events = this.state.events.filter((e) => e._id !== eventId);
				this.setState({ events: events });
				return true;
			} else {
				return false;
			}
		});
	};

	deleteEvents = async () => {
		const eventIds = Object.keys(this.state.checkedEvents);
		this.setState({ deletedEvents: 0, deletingEvents: true });
		for (let i = 0; i < eventIds.length; i++) {
			await API()
				.delete(`Organizations/${this.props.orgId}/events/${eventIds[i]}`)
				.then((res) => {
					if (res) {
						this.setState({ deletedEvents: this.state.deletedEvents + 1 });
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					if (i === eventIds.length - 1) {
						const events = this.state.events.filter((e) => !eventIds.includes(e._id));
						this.setState({ deletingEvents: false, checkedEvents: {}, events: events });
						batchCallback && batchCallback('delete');
					}
				});
		}
	};

	toggleDeleteEvents = (checkedEvents, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedEvents: checkedEvents || {},
			deleteEventsModalOpen: !this.state.deleteEventsModalOpen
		});
	};

	toggleCalendar = () => {
		this.setState({ calendarOpen: !this.state.calendarOpen });
	};

	addField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (
				fieldCategories[category._id] &&
				!fieldCategories[category._id].columns.includes(`${category._id}*${f._id}`)
			) {
				fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
			} else {
				fieldCategories[category._id] = {
					title: category.title,
					columns: [`${category._id}*${f._id}`]
				};
			}
			fieldColumns[category._id + '*' + f._id] = f.fieldName;
			fieldTypes[category._id + '*' + f._id] = f.fieldType;
		});
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	updateField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (fieldCategories[category._id]) {
				//fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				fieldColumns[category._id + '*' + f._id] = f.fieldName;
				fieldTypes[category._id + '*' + f._id] = f.fieldType;
			}
		});
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	removeField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		if (fields.length > 0) {
			if (fieldCategories[category._id]) {
				fieldCategories[category._id].columns = [];

				fields.forEach((f) => {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
					fieldColumns[category._id + '*' + f._id] = f.fieldName;
					fieldTypes[category._id + '*' + f._id] = f.fieldType;
				});
			}
		} else {
			// If removing the last field, also clean up fieldColumns and fieldTypes
			if (fieldCategories[category._id].columns) {
				fieldCategories[category._id].columns.forEach(columnId => {
					delete fieldColumns[columnId];
					delete fieldTypes[columnId];
				});
			}
			// Finally, remove the category itself
			delete fieldCategories[category._id];
		}
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	onboardingCompleteToggle = () => {
		this.setState({ onboardingModalOpen: false }, this.toggleAdd);
	}

	render() {
		const {
			loading,
			addModalOpen,
			venueOptions,
			eventViews,
			categoryColumns,
			columns,
			events,
			columnTypes,
			deleteEventsModalOpen,
			checkedEvents,
			customFields,
			addFromProgramModalOpen,
			selectedCalendarMonthDate,
			calendarOpen,
			sortedEvents,
			toggleDuplicateEvent,
			onboardingModalOpen
		} = this.state;

		const calendarFormat = []
		events.forEach((e) => {
			try {

				const eventStart = new Date(`${e.startDate} ${e.startTime}`);
				const eventEnd = new Date(`${e.endDate} ${e.endTime}`);
				calendarFormat.push({
					title: e.name,
					start: eventStart,
					end: eventEnd,
					resource: {
						_id: e._id,
					}
				});
			} catch (err) {
				console.log(err, e)
			}
		});
		const selectedMonth = selectedCalendarMonthDate.format('MMMM YYYY');

		const mainActions = [
			{ type: 'button', onClick: this.toggleAdd, label: 'Add New Event' }];
		if (events.length > 0) {
			mainActions.push(
				{
					type: 'button',
					onClick: this.toggleDuplicateEvent,
					label: (
						<span>
							<i className="las la-copy mr-10" />Duplicate Event
						</span>
					)
				})
		}

		if (this.state.programs.length > 0) {
			mainActions.push({
				type: 'button',
				onClick: this.toggleAddFromProgram,
				label: (
					<span>
						<i className="las la-stream mr-10" />Create From Program
					</span>
				)

			})
		}

		return (
			<div className='pageContainer'>
				{!loading && (
					<ViewsContainer
						views={eventViews}
						entries={events}
						categoryColumns={categoryColumns}
						columns={columns}
						columnTypes={columnTypes}
						title="Events"
						description="View and manage all events. Create a new event from scratch or use an existing template or program."
						subtitle={null}
						readOnly={false}
						mainActions={mainActions}
						batchActions={[
							{
								type: 'button',
								onClick: this.toggleDeleteEvents,
								label: 'Delete',
								iconClass: 'las la-trash-alt',
								class: 'danger'
							}
						]}
						summaryPanel={<button className='summaryPanel' onClick={() => this.setState({ calendarOpen: true })}>
							<div className='flex aic jcsb mb-10 w-100'><h5 className='mr-25'><i className='las la-calendar mr-10 seBlue'></i>Events Calendar</h5><i className='las la-angle-right'></i></div>
							<div className='flex column wrap jcfe'>
								{/* A short summary of upcoming events */}
								{sortedEvents.length > 0 ? sortedEvents.map((e, i) => {
									if (i < 2) {
										const eventStart = `${e.startDate} ${e.startTime}`;
										return (
											<div className='flex aic' style={{ marginBottom: i < 1 ? 5 : 0 }}>
												<p className='mr-5'>{e.name}</p>
												{/* use moment to set time until */}
												<h3>
													{moment(eventStart).fromNow()}
												</h3>
											</div>);
									}
								}) : <p>No upcoming events</p>}

							</div>
						</button>}

						enableDownloadViews={true}
						orgId={this.props.orgId}
						type={'Event'}
						referenceCollection={'Organizations'}
						referenceId={this.props.orgId}
						fields={customFields}
						defaultSort={'startDate'}
						sortDirection={"desc"}
						addField={this.addField}
						updateField={this.updateField}
						removeField={this.removeField}
					>
					</ViewsContainer>
				)}
				<LoadingWizard text="Loading Events" loading={loading} />

				{onboardingModalOpen && <OnboardingModal
					isOpen={onboardingModalOpen}
					toggle={() => this.setState({ onboardingModalOpen: false }, this.props.pingServer)}
					organization={this.props.organization}
					account={this.props.account}
					orgId={this.props.orgId}
					locations={venueOptions}
					pingServer={this.props.pingServer}
					onboardingCompleteToggle={this.onboardingCompleteToggle}
				/>}

				{addModalOpen && (
					<AddEventModal
						account={this.props.account}
						organization={this.props.organization}
						isOpen={addModalOpen}
						toggle={this.toggleAdd}
						handleSubmit={this.addEvent}
						locations={venueOptions}
					/>
				)}

				{toggleDuplicateEvent && (
					<DuplicateEventModal
						account={this.props.account}
						organization={this.props.organization}
						isOpen={toggleDuplicateEvent}
						toggle={this.toggleDuplicateEvent}
						events={this.state.events}
						customFields={this.state.customFields}
						handleSubmit={this.duplicateEvent}
						locations={venueOptions} />
				)}

				{addFromProgramModalOpen && (
					<AddEventFromProgramModal
						account={this.props.account}
						organization={this.props.organization}
						isOpen={addFromProgramModalOpen}
						toggle={this.toggleAddFromProgram}
						programs={this.state.programs}
						customFields={this.state.customFields}
						handleSubmit={this.addEvent}
						locations={venueOptions} />
				)}

				{deleteEventsModalOpen && (
					<DeleteEventsModal
						isOpen={deleteEventsModalOpen}
						toggle={this.toggleDeleteEvents}
						delete={this.deleteEvents}
						deleting={this.state.deletingEvents}
						deleteCount={this.state.deletedEvents}
						count={Object.keys(checkedEvents).length}
					/>
				)}

				{calendarOpen && (
					<FieldModal
						size="large"
						isOpen={calendarOpen}
						toggle={() => this.setState({ calendarOpen: !calendarOpen })}
						isFullWidthBody={true}
						modalTitle="Events Calendar"
						cancelButtonLabel="Close"
						bodyContent={() => <Calendar
							localizer={localizer}
							events={calendarFormat} />}
					/>)}
			</div>
		);
	}
}

export default Events;
