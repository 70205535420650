import React from 'react';

import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class AddSubjectEmailTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subject: this.props.emailTemplate.subject ? this.props.emailTemplate.subject : '',
			previewText: this.props.emailTemplate.previewText ? this.props.emailTemplate.previewText : '',

			submitting: false,
			invalid: false,
			requiredFields: {}
		};
	}

	handleSubjectChange = (e) => {
		const { requiredFields, subject } = this.state;
		let subCount = subject.length;
		let required = requiredFields;

		if (subCount > 140) {
			required.subject = true;
		} else {
			required.subject = false;
		}

		this.setState({
			subject: e.target.value,
			requiredFields: required
		});
	};
	handlePreviewTextChange = (e) => {
		this.setState({
			previewText: e.target.value
		});
	};

	updateSubject = async () => {
		this.setState({ submitting: true });
		const { subject, previewText } = this.state;
		const { emailTemplate, toggle, saveChanges } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		let editedEmailTemplate = emailTemplate;

		if (valid) {
			editedEmailTemplate.subject = subject;
			editedEmailTemplate.previewText = previewText;

			saveChanges(editedEmailTemplate, () => {
				toggle();
			});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, subject, previewText, requiredFields } = this.state;

		let subCount = subject.length;

		return (
			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Subject"}
					bodyHeaderText={'Add your Subject and Preview lines below.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Subject"
									required={false}
									errorTrigger={requiredFields.subject}
									subText={`${subCount} / 140`}
									errorMessage={'Reduce Characters'}
									value={subject}
									onChange={this.handleSubjectChange}
								/>
								<InputField
									label="Preview"
									required={false}
									value={previewText}
									subText={'Suggested Character Count: 40 - 140'}
									onChange={this.handlePreviewTextChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateSubject()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default AddSubjectEmailTemplateModal;
