// import external modules
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../assets/img/logos/simple events logo.png';
import TopLevelNavLink2 from '../components/nav/topLevelNavLink2';
import Event from '../views/events/event';
import SystemToolbar from '../components/nav/systemToolbar';
import LoadingWizard from '../components/spinner/wizard';
import API from '../utils/API';
import moment from 'moment';

class EventLevelLayout extends PureComponent {
    constructor(props) {
        super(props);
        const premiumLicense = props.subscriptionType == 'Enterprise Premium';
        let budgetEnabled = premiumLicense;
        let inventoryEnabled = premiumLicense;
        let gamificationEnabled = premiumLicense;
        let scanningEnabled = premiumLicense;
        let capacityExtensionEnabled = premiumLicense;
        let customEmailsEnabled = premiumLicense;
        let ssoEnabled = premiumLicense;
        let textingEnabled = props.organization.integrations.find((integration) => integration.identifier == 'twilio');
        if (!premiumLicense) {
            props.subscriptionAddons.forEach((addon) => {
                if (addon.identifier == 'budget') {
                    budgetEnabled = true;
                } else if (addon.identifier == 'inventory') {
                    inventoryEnabled = true;
                } else if (addon.identifier == 'gamification') {
                    gamificationEnabled = true;
                } else if (addon.identifier == 'event-pass') {
                    scanningEnabled = true;
                } else if (addon.identifier == 'space-plus') {
                    capacityExtensionEnabled = true;
                } else if (addon.identifier == 'html-emails') {
                    customEmailsEnabled = true;
                } else if (addon.identifier == 'sso') {
                    ssoEnabled = true;
                }
            });
        }


        let manageNavigation = [
            {
                adminOnly: false,
                name: 'Setup',
                icon: <i className="las la-info-circle" />,
                section: 'details'
            },
            {
                adminOnly: false,
                name: 'Attendees',
                icon: <i className="las la-user-friends" />,
                section: 'attendees',
            },
            {
                adminOnly: true,
                name: 'Sessions',
                icon: <i className="las la-calendar-week" />,
                section: 'schedule'
            },
            {
                adminOnly: false,
                name: 'Groups',
                icon: <i className="las la-users" />,
                section: 'groups',
            },
            {
                adminOnly: false,
                name: 'Inventory',
                icon: <i className="las la-list-alt" />,
                section: 'inventory',
                addon: 'inventoryEnabled',
                experiences: ['in-person', 'hybrid']
            }, {
                adminOnly: false,
                name: 'Reports',
                icon: <i className="las la-chart-pie" />,
                section: 'reports',
            }
        ]
        let promoteNavigation = [
            {
                name: 'Emails',
                icon: <i className="las la-envelope" />,
                section: 'emails',
            },
            {
                adminOnly: false,
                name: 'Texts',
                icon: <i className="las la-sms" />,
                section: 'texting',
            }, {
                adminOnly: false,
                name: 'Notifications',
                icon: <i className="las la-bell" />,
                section: 'notifications'
            }
        ];

        if (!textingEnabled) {
            promoteNavigation.splice(1, 1);
        }

        let produceNavigation = [
            {
                name: 'Pages',
                icon: <i className="las la-laptop-code" />,
                section: 'pages',
            }, {
                adminOnly: false,
                name: 'Forms',
                icon: <i className="lab la-wpforms" />,
                section: 'forms',
            }, {
                adminOnly: false,
                name: 'Engagement',
                icon: <i className="las la-trophy" />,
                section: 'engagement',
                experiences: ['hybrid']
            }, {
                adminOnly: false,
                name: 'Files',
                icon: <i className="las la-photo-video" />,
                section: 'media',
            }
        ];

        let permittedEvent =
            props.permission ||
            props.eventPermissions.find(
                (ep) => ep.eventId.toString() == this.props.match.params.event
            );
        if (!props.permission && permittedEvent) {
            manageNavigation = manageNavigation.filter((nav) => {
                return permittedEvent.permissions[nav.section] && permittedEvent.permissions[nav.section].read;
            });
            promoteNavigation = promoteNavigation.filter((nav) => {
                return permittedEvent.permissions[nav.section] && permittedEvent.permissions[nav.section].read;
            });
            produceNavigation = produceNavigation.filter((nav) => {
                return permittedEvent.permissions[nav.section] && permittedEvent.permissions[nav.section].read;
            });

            if (!permittedEvent.permissions.overview.read) {

                //find next available section
                let nextSection = manageNavigation.length > 0 ? manageNavigation[0].section : promoteNavigation.length > 0 ? promoteNavigation[0].section : produceNavigation.length > 0 ? produceNavigation[0].section : 'details';
                this.props.history.push(`./${nextSection}`);

            }
        } else if (props.permission) {
            permittedEvent = {
                eventId: this.props.match.params.event,
                permissions: {
                    overview: {
                        read: true,
                        write: true
                    },
                    details: {
                        read: true,
                        write: true
                    },
                    attendees: {
                        read: true,
                        write: true
                    },
                    schedule: {
                        read: true,
                        write: true
                    },
                    groups: {
                        read: true,
                        write: true
                    },
                    inventory: {
                        read: true,
                        write: true
                    },
                    emails: {
                        read: true,
                        write: true
                    },
                    texting: {
                        read: true,
                        write: true
                    },
                    forms: {
                        read: true,
                        write: true
                    },
                    pages: {
                        read: true,
                        write: true
                    },
                    engagement: {
                        read: true,
                        write: true
                    },
                    media: {
                        read: true,
                        write: true
                    },
                    insights: {
                        read: true,
                        write: true
                    }
                }
            }
        }
        this.state = {
            orgId: this.props.match.params.organization,
            eventId: this.props.match.params.event,
            permittedEvent: permittedEvent,
            permittedAddons: {
                budgetEnabled: budgetEnabled,
                inventoryEnabled: inventoryEnabled,
                gamificationEnabled: gamificationEnabled,
                scanningEnabled: scanningEnabled,
                capacityExtensionEnabled: capacityExtensionEnabled,
                customEmailsEnabled: customEmailsEnabled,
                ssoEnabled: ssoEnabled,
                textingEnabled: textingEnabled

            },
            manageNavigation: manageNavigation,
            promoteNavigation: promoteNavigation,
            produceNavigation: produceNavigation,
            event: {},
            loading: true,
        };
    }

    async componentDidMount() {
        const sectionCounts = await this.props.getSectionCounts(this.state.eventId);
        const eventSectionCounts = sectionCounts.event;
        const { manageNavigation, promoteNavigation, produceNavigation } = this.state;
        manageNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
        });
        promoteNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
        });
        produceNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
        });
        const eventId = this.props.match.params.event;
        const { orgId } = this.props;
        try {
            const event = await API().get(`Organizations/${orgId}/events/${eventId}`).then();
            this.setState({ produceNavigation, promoteNavigation, manageNavigation, loading: false, event: event.data })

        } catch (e) {
            console.log(e);
        }
    }

    refreshSectionCounts = async () => {

        const sectionCounts = await this.props.getSectionCounts(this.state.eventId);
        const eventSectionCounts = sectionCounts.event;
        let { manageNavigation, promoteNavigation, produceNavigation } = this.state;
        manageNavigation = manageNavigation.map((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
            return navItem;
        });
        promoteNavigation = promoteNavigation.map((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
            return navItem;
        });
        produceNavigation = produceNavigation.map((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
            return navItem;
        });
        this.setState({ produceNavigation, promoteNavigation, manageNavigation })
    }


    getEventPermissions = () => {
        const { manageNavigation } = this.state;
        let updatedEventMenuItems = [];
        const wantedSection = this.props.match.params.section;
        let wantedSectionAvailable = false;
        manageNavigation.forEach((mi) => {
            if (
                (!this.props.permittedEvent.capabilities ||
                    (this.props.permittedEvent.capabilities &&
                        this.props.permittedEvent.capabilities.includes(mi.section))) &&
                ((mi.addon && this.state.permittedAddons[mi.addon]) || !mi.addon)
            ) {
                if (wantedSection == mi.section) {
                    wantedSectionAvailable = true;
                }
                updatedEventMenuItems.push(mi);
            }
        });

        if (updatedEventMenuItems.length === 0) {
            window.location.replace('/');
        } else if (!wantedSectionAvailable) {
            window.location.replace(
                `/${this.props.orgId}/events/${this.props.match.params.event}/${updatedEventMenuItems[0].section}`
            );
        }
        return updatedEventMenuItems;
    };

    render() {
        const { organizations, permission, subscriptionAddons, subscriptionType, organization, socket, account } = this.props;
        const { orgId, eventId, loading, manageNavigation, produceNavigation, promoteNavigation, event, permittedEvent } = this.state;

        const { startDate, endDate, startTime, endTime, experienceType, venueDetails } = event;
        /* const { name  } = this.props.venueDetails; */

        const start = moment(startDate);
        const end = moment(endDate);
        const startHour = startTime ? moment(startTime, 'HH:mm:ss a').format('hh:mm A') : '';
        const endHour = endTime ? moment(endTime, 'HH:mm:ss a').format('hh:mm A') : '';

        //format date range as Friday, March 3, 2023 8:00AM - Sunday, March 5, 2023 5:00PM, account for same day events and proper time formatting and timezone
        let dateTimeRangeString = '';
        /*  if (start.isSame(end, 'day')) {
             dateTimeRangeString = `${start.format('ddd, MMM Do')} ${startHour} - ${endHour}`;
         } else {
             dateTimeRangeString = `${start.format('ddd, MMM D')} - ${end.format('ddd, MMM D')}`;
         } */
        if (start.isSame(end, 'day')) {
            dateTimeRangeString = `${start.format(' MMMM Do YYYY')}`;
        }
        else {
            dateTimeRangeString = `${start.format((' MMMM Do YYYY'))}`;
        }
        //append timezone abbreviation from moment with event timezone string
        //   dateTimeRangeString += ` ${start.tz(event.timezone)?.format('z')}`;
        // <h3 className='sideEventName'>{event.name ? event.name : "..."}</h3>
        let eName = '';
        if (event.experienceType == 'in-person') {
            eName = "In-Person";
        } else if (event.experienceType == 'virtual') {
            eName = "Virtual";
        } else if (event.experienceType == 'hybrid') {
            eName = "Hybrid";
        }
        return (
            <div className='application'>
                <div className="topLevelPages ">
                    <div className={`systemContentContainer`}>
                        <SystemToolbar
                            socket={socket}
                            markNotificationAsRead={this.props.markNotificationAsRead}
                            platformNotifications={this.props.platformNotifications}
                            permission={this.props.permission}
                            account={this.props.account}
                            organization={organization}
                            organizations={organizations}
                            logout={this.props.logout}
                            event={this.state.event}
                            programsAvailable={this.props.programsAvailable}
                            subscriptionAddons={this.props.subscriptionAddons}
                            subscriptionType={this.props.subscriptionType}
                            displayLogo={true}
                            taskCount={this.props.taskCount}
                            updateTaskCount={this.props.updateTaskCount}
                            pingServer={this.props.pingServer} />
                        <div className='flex' style={{ height: 'calc(100% - 55px)' }}>
                            <div className={`sideNavigation ${loading ? 'closed' : ''}`}>
                                {/* <div className="navLogo">
                                    <img src={logo} />
                                </div> */}
                                <div className='sideMenuContainer'>
                                    <Link to={`/${orgId}/events/${eventId}/overview`} className='menutItemLink' >
                                        <div className='p-30 pt-15 pb-0 pr-20 pb-20 '>
                                            <h3 className='sideEventName'>{event.venueDetails && event.venueDetails.name ? event.venueDetails.name : eName}</h3>
                                            {/* <h3 className='sideEventName'>{event.name ? event.name : "..."}</h3> */}
                                            <p className='small'>{dateTimeRangeString}</p>

                                        </div>
                                        <i className='las la-angle-right'></i>
                                    </Link>
                                    <hr className='m-20 mt-0 mr-0 ml-0' />
                                    <div className='scrollContainer'>
                                        {/*  {permittedEvent.permissions.overview.read && <TopLevelNavLink2
                                            orgId={orgId}
                                            menuItem={{
                                                name: 'Overview',
                                                icon: <i className="las la-home" />,
                                                section: 'overview',
                                            }}
                                            prefix={`/${orgId}/events/${eventId}`}
                                            permission={permission}
                                            key={`event-overview-nav`}
                                            className="mb-25"
                                        />} */}
                                        {manageNavigation.length > 0 && <h5 className='mb-10 sideNaveColor'>PLAN</h5>}
                                        {manageNavigation.map((menuItem, index) => {
                                            return (!menuItem.experiences || (menuItem.experiences && menuItem.experiences.includes(event.experienceType)))/*  && (permission || !menuItem.adminOnly) */ &&
                                                ((subscriptionType != 'Enterprise Premium' &&
                                                    menuItem.addon &&
                                                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                                                    !menuItem.addon ||
                                                    subscriptionType == 'Enterprise Premium') ? (
                                                <TopLevelNavLink2
                                                    isLast={index == manageNavigation.length - 1}
                                                    orgId={orgId}
                                                    menuItem={menuItem}
                                                    prefix={`/${orgId}/events/${eventId}`}
                                                    index={index}
                                                    permission={permission}
                                                    loading={loading}
                                                    key={`event-manage-nav-${index}`}
                                                />
                                            ) : null;
                                        })}
                                        {promoteNavigation.length > 0 && <h5 className='mb-10 mt-20 sideNaveColor'>PROMOTE</h5>}
                                        {promoteNavigation.map((menuItem, index) => {
                                            return (!menuItem.experiences || (menuItem.experiences && menuItem.experiences.includes(event.experienceType))) /* && (permission || !menuItem.adminOnly) */ &&
                                                ((subscriptionType != 'Enterprise Premium' &&
                                                    menuItem.addon &&
                                                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                                                    !menuItem.addon ||
                                                    subscriptionType == 'Enterprise Premium') ? (
                                                <TopLevelNavLink2
                                                    isLast={index == promoteNavigation.length - 1}
                                                    menuItem={menuItem}
                                                    index={index}
                                                    loading={loading}
                                                    prefix={`/${orgId}/events/${eventId}`}
                                                    permission={permission}
                                                    key={`event-promote-nav-${index}`}
                                                />
                                            ) : null;
                                        })}
                                        {produceNavigation.length > 0 && <h5 className='mb-10 mt-20 sideNaveColor'>PRODUCE</h5>}
                                        {produceNavigation.map((menuItem, index) => {
                                            return (!menuItem.experiences || (menuItem.experiences && menuItem.experiences.includes(event.experienceType))) /* && (permission || !menuItem.adminOnly) */ &&
                                                ((subscriptionType != 'Enterprise Premium' &&
                                                    menuItem.addon &&
                                                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                                                    !menuItem.addon ||
                                                    subscriptionType == 'Enterprise Premium') ? (
                                                <TopLevelNavLink2
                                                    isLast={index == produceNavigation.length - 1}
                                                    menuItem={menuItem}
                                                    index={index}
                                                    loading={loading}
                                                    prefix={`/${orgId}/events/${eventId}`}
                                                    permission={permission}
                                                    key={`event-produce-nav-${index}`}
                                                />
                                            ) : null;
                                        })}

                                    </div>
                                </div>
                            </div>
                            {!loading && <Event {...this.props} event={this.state.event} permittedAddons={this.state.permittedAddons} permittedEvent={this.state.permittedEvent} refreshSectionCounts={this.refreshSectionCounts} />}
                            {loading && <div className='pageContainer'><LoadingWizard text="LOADING EVENT" loading={loading} /></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EventLevelLayout);
