import React, { Component } from 'react';
import API from '../../utils/API';
import MemberInformation from '../../sections/profile/information';
import BasicSectionHeader from '../../components/views/BasicSectionHeader';

import TabsContainer from '../../components/views/TabsContainer';
import ProfileSettings from '../../sections/profile/settings';
import SectionHeader from '../../components/views/SectionHeader';
import ActionButtonContainer from '../../components/views/ActionButtonContainer';
import SectionFooter from '../../components/views/SectionFooter';
import ImageCropper from '../../components/modals/imageCropper';

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member: {},
			editedMember: {},
			selectedSection: 0,
			DeleteMemberModalOpen: false,
			loading: true,
			editing: false,
			tabs: [],
			requiredFields: {
				firstName: false
			},
			selectedFile: null,
			toggleImageCropper: false
		};
	}

	componentDidMount() {
		const { orgId } = this.props;
		let searchParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')));
		API().get(`Organizations/${orgId}/members/myProfile`).then((res) => {
			this.setState({
				member: JSON.parse(JSON.stringify(res.data)),
				editedMember: res.data,
				loading: false,
				selectedSection: searchParams.get('section') ? parseInt(searchParams.get('section')) : 0
			});
		});
	}

	toggleDelete = () => {
		this.setState({ DeleteMemberModalOpen: !this.state.DeleteMemberModalOpen });
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	updateMember = () => {
		const { editedMember } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedMember[key] || editedMember[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			const { orgId } = this.props;
			API().patch(`Organizations/${orgId}/members/${editedMember._id}`, editedMember).then((res) => {
				if (res.data) {
					this.setState({
						member: JSON.parse(JSON.stringify(res.data)),
						editing: false,
						requiredFields: requiredFields
					});
					this.props.pingServer && this.props.pingServer();
				}
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	editMemberInformation = (property, value) => {
		const { editedMember } = this.state;
		editedMember[property] = value;
		this.setState({ editedMember: editedMember, editing: true });
	};

	renderSection() {
		const { selectedSection, editedMember, requiredFields } = this.state;
		switch (selectedSection) {
			case 0:
				return (
					<MemberInformation
						requiredFields={requiredFields}
						editedMember={editedMember}
						editMemberInformation={this.editMemberInformation}
					/>
				);
			case 1:
				return (
					<ProfileSettings editedMember={editedMember} editMemberInformation={this.editMemberInformation} />
				);
			default:
		}
	}

	cancelEdit = () => {
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editing: false,
			editedMember: { ...JSON.parse(JSON.stringify(this.state.member)), phoneNumber: this.state.member.phoneNumber ? this.state.member.phoneNumber : '' },
			requiredFields: requiredFields
		});
	};


	saveImage = async (data, callback) => {
		const { orgId } = this.props;
		const { editedMember } = this.state;
		API().patch(`Organizations/${orgId}/members/${editedMember._id}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			if (res.data) {
				this.setState({
					member: JSON.parse(JSON.stringify(res.data)),
					editedMember: JSON.parse(JSON.stringify(res.data)),
					editing: false,
				}, () => {
					this.props.pingServer && this.props.pingServer()
					callback && callback();
				});
			}
		});
	}
	handleFileChange = (e) => {
		const reader = new FileReader();
		const selectedFile = e.target.files[0];
		reader.readAsDataURL(selectedFile);
		reader.onload = () => {

			this.setState({ selectedFile: reader.result, toggleImageCropper: true });
		}
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	toggleImageCropper = () => {
		this.setState({ toggleImageCropper: false });
	};

	render() {
		const { member, loading, editing, selectedSection, editedMember, toggleImageCropper } = this.state;
		return !loading ? (
			<div className="">
				<SectionHeader
					editing={editing}
					enableProfileIcon={true}
					updatedAt={member.updatedAt}
					acronym={editedMember.firstName.charAt(0).concat(editedMember.lastName.charAt(0))}
					title={`${member.firstName} ${member.lastName}`}
					profilePicture={editedMember.profilePicture || null}
					profileIconOnClick={() => {
						this.openFileSelector();
					}}
					handleProfileFileChange={this.handleFileChange}
					handleProfileImageDelete={() => {
						this.editMemberInformation('profilePicture', '');
					}}
					/* breadcrumbs={[
						{
							name: 'Profile',
						}
					]} */
					//subtitle="Profile"
					titleIcon={
						member.profilePicture ? (
							<img src={member.profilePicture} style={{ maxWidth: '100%', overflow: 'clip' }} />
						) : (
							<div>
								{member.firstName.toUpperCase().charAt(0)}
								{member.lastName.toUpperCase().charAt(0)}
							</div>
						)
					}
				>
					{/* <TabsContainer
						tabs={[{ title: 'Information' }, { title: 'Settings' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/> */}</SectionHeader>
				<div >{this.renderSection()}</div>
				<SectionFooter
					style={{ marginBottom: 0 }}
					updatedAt={member.updatedAt}
					editing={editing}
					cancelEdit={() => {
						this.cancelEdit();
					}}
					saveEdit={() => {
						this.updateMember();
					}} />
				{toggleImageCropper && <ImageCropper
					isOpen={toggleImageCropper}
					toggle={this.toggleImageCropper}
					selectedFile={this.state.selectedFile}
					saveImage={this.saveImage}
				/>}
			</div>
		) : (
			<div>Loading</div>
		);
	}
}

export default Profile;
