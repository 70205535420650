import React from 'react';
import AddSpeakersModal from './modals/addSpeakersModal';
import RemoveSpeakerModal from './modals/removeSpeakerModal';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';

class EventSessionSpeakers extends React.Component {
	constructor(props) {
		super(props);

		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email'
		};
		const columnTypes = {
			firstName: 'text',
			lastName: 'text',
			email: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			addSpeakersModalOpen: false,
			removeSpeakerModalOpen: false,
			markedSpeaker: null,
			toggledColumns: toggledColumns,
			deleteSpeakerToggle: false,
			searchText: '',
			searching: false,
			checkedSpeaker: [],
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			checkedSpeakers: [],
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	toggleAddSpeakers = () => {
		this.setState({ addSpeakersModalOpen: !this.state.addSpeakersModalOpen });
	};

	toggleRemoveSpeaker = (speaker) => {
		this.setState({
			removeSpeakerModalOpen: !this.state.removeSpeakerModalOpen,
			markedSpeaker: speaker || null
		});
	};

	removeSpeaker = (speaker) => {
		this.setState({ speakers: this.state.speakers.filter((s) => s._id != speaker._id) });
	};

	deleteSpeakersToggle = () => {
		this.setState({ deleteSpeakersToggle: !this.state.deleteSpeakersToggle });
	};

	searchEntries = (searchText) => {
		this.setState({ searching: searchText != '' ? true : false, searchText: searchText ? searchText : '' });
	};

	updatedCheckedModerators = (checked) => {
		this.setState({ checkedSpeakers: checked });
	};

	updatedCheckedSpeakers = (checked) => {
		this.setState({ checkedSpeakers: checked });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const {
			addSpeakersModalOpen,
			markedSpeaker,

			deleteSpeakersToggle,
			searchText,
			searching,
			toggledColumns,
			normalizedColumns,
			selectedColumnSort,
			sortDesc,
			columns
		} = this.state;
		const { speakers } = this.props.editedSession;

		let searchedEntries = speakers;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		searchedEntries = searchedEntries.map((att) => {
			return {
				_id: att._id,
				firstName: att.firstName,
				lastName: att.lastName,
				email: att.email
			};
		});

		if (selectedColumnSort) {
			searchedEntries = searchedEntries.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
		}

		let mainActions = [];
		mainActions = [{ onClick: this.toggleAddSpeakers, label: 'Add Speakers' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Delete Speakers',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.deleteSpeakersToggle
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedSpeakers).length;
		entriesLength = searching ? searchedEntries.length : speakers.length;
		uncheckEntries = () => {
			this.setState({ checkedSpeakers: [] });
		};
		searchEnabled = true;

		return (
			< div className='sectionBody posRel mb-0 p-0' >
				<ActionButtonContainer
					mainActions={mainActions}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>

				<ViewsTable
					title="Speakers"	
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					classes="nested"
					data={searchedEntries}
					mainColumnActive={false}
					checked={this.state.checkedSpeakers}
					checkRow={(entryId) => {
						const checked = this.state.checkedSpeakers;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedSpeakers(checked);
						this.setState({
							checkedSpeakers: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedSpeakers;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedSpeakers(checked);

						this.setState({
							checkedSpeakers: checked
						});
					}}
					sortData={this.sortData}
				/>

				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
				/>

				{addSpeakersModalOpen && (
					<AddSpeakersModal
						isOpen={addSpeakersModalOpen}
						toggle={this.toggleAddSpeakers}
						orgId={this.props.orgId}
						updateSpeakers={this.props.updateSpeakers}
						loadMoreAttendees={this.props.loadMoreAttendees}
						eventId={this.props.event._id}
						existingSpeakers={speakers}
						attendees={this.props.event.attendees}
						session={this.props.editedSession}
					/>
				)}

				{deleteSpeakersToggle && (
					<RemoveSpeakerModal
						isOpen={deleteSpeakersToggle}
						toggle={this.deleteSpeakersToggle}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						markedSpeaker={markedSpeaker}
						attendees={this.props.event.attendees}
						handleSubmit={this.props.updateSession}
						updateSpeakers={this.props.updateSpeakers}
						session={this.props.editedSession}
						uncheckEntries={uncheckEntries}
						checkedSpeakers={this.state.checkedSpeakers}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionSpeakers;
