import React, { Component } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import OverflowPopoverItem from './OverflowPopoverItem';

class MoreViewsTab extends Component {

	render() {
		const { overflowViews, onClick, activeCustomViewsCount, activeView, overflowActive } = this.props;
		return (
			<UncontrolledDropdown className="moreViewsButton">
				<DropdownToggle className="columnToggle">
					More <i className="ml-10 las la-angle-down" />
				</DropdownToggle>
				<DropdownMenu className="columnDropdown">
					<div className='ph-20'>
						<h5 className='mb-10'>More Views</h5>

						{overflowViews.map((view, index) => {
							const viewIndex = overflowActive
								? activeCustomViewsCount - 1 + index
								: activeCustomViewsCount + index;
							return (
								<OverflowPopoverItem
									makeMainView={this.props.makeMainView}
									renameView={this.props.renameView}
									duplicateView={this.props.duplicateView}
									deleteView={this.props.deleteView}
									key={viewIndex}
									onClick={() => {
										onClick(viewIndex);
									}}
									view={view}
									activeView={activeView}
									viewIndex={viewIndex}
								/>
							);
						})}
					</div>
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}
}

export default MoreViewsTab;
