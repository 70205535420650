import React, { Component } from 'react';
class FileChooserPreview extends Component {
	render() {
		const {
			onChange,
			label,
			errorTrigger,
			errorMessage,
			value,
			placeholder,
			style,
			inputStyle,
			required,
			classes,
			onClick,
			clearFile,
			isVideo
		} = this.props;
		return (
			<div key={label} className={`formFieldContainer color ${classes ? classes : ''}`} style={style}>
				<div className="formField">
					<div className="labelContainer">
						<div className="formFieldLabel">
							{label}
							{required ? '*' : ''}
						</div>
						{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
					</div>
					<div className={`formFieldInputContainer ${errorTrigger ? 'error' : ''}`}>
						{isVideo ? <video className="filePickerPreview" src={value} /> : <img className="filePickerPreview" src={value} /> }
						<input
							style={inputStyle}
							value={value}
							className="formFieldInput"
							type="text"
							placeholder={placeholder}
							disabled={true}
							onChange={onChange}
						/>

						{value == '' ? (
							<div className="filePickerButton" onClick={onClick}>
								<i className="las la-upload mr-5" /> Choose File
							</div>
						) : (
							<div className="filePickerButton red" onClick={clearFile}>
								<i className="las la-times mr-5" /> Clear File
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default FileChooserPreview;
