import React from 'react';

import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';
import { withRouter } from 'react-router-dom';

class CloseFormNowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    closeFormNow = async () => {
        const { match: { params }, isFormClosed } = this.props;
        this.setState({ submitting: true });

        await API().patch(`Organizations/${params.organization}/events/${params.eventId}/forms/${params.formId}/close`, { closed: !isFormClosed })
            .then((res) => {
                this.setState({ submitting: false });
                this.props.updateCloseStatus({ closed: !isFormClosed, closeAt: null }, this.props.toggle());
            }
            ).catch((error) => {
                this.setState({ submitting: false });
            });

    }



    render() {
        const { submitting } = this.state;
        const { toggle, isOpen, isFormClosed } = this.props;

        return (
            <FieldModal
                size="small"
                isOpen={isOpen}
                modalTitle={isFormClosed ? "Open Form Now" : "Close Form Now"}
                bodyHeaderText={isFormClosed ? "Are you sure you want to open this form now?" : 'Are you sure you want to close this form now?'}
                bodyDescription={``}
                bodyContent={() => {
                    return (
                        <div>

                        </div>
                    )
                }}
                toggle={toggle}
                actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
                cancelButtonLabel={'Cancel'}
                actionButton={this.closeFormNow}
                submitting={submitting}
                actionButtonDisabled={submitting}

            ></FieldModal>
        );
    }
}

export default withRouter(CloseFormNowModal);
