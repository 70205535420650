// import external modules
import React, { Component, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import FullPageLayout from '../layouts/routes/fullpageRoutes';
import ErrorLayoutRoute from '../layouts/routes/errorRoutes';
import { getCookie } from '../utils/cookie';
import LoadingWizard from '../components/spinner/wizard';
// Full Layout
import LazyForgotPassword from '../views/pages/forgotPassword';
import LazyLogin from '../views/pages/login';
import LazyRegister from '../views/pages/register';
import LazyResetPassword from '../views/pages/resetPassword';

import ApplicationLayout from '../layouts/applicationLayout';
import ForgotPassword from '../views/pages/forgotPassword';
import EventSharedReport from '../sections/event/reports/eventSharedReport';
import Welcome from '../views/pages/welcome';
class Router extends Component {

	constructor(props) {
		super(props);
		const authToken = getCookie('authToken');
		const authenticated = authToken !== undefined;
		this.state = {
			authenticated: authenticated
		};

		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.getRegistrations().then(function (rs) { for (let reg of rs) { reg.unregister(); } });
		}
		//get url params
		/*const urlParams = new URLSearchParams(window.location.search);
		 const version = urlParams.get('v');
		if (version) {
			const setVersion = localStorage.getItem('version', version);
			if (setVersion !== version) {
				localStorage.setItem('version', version);
				window.location.reload(true);
			} else {
				window.location.href = `${window.location.origin}${window.location.pathname}`
			} 
		}*/
	}

	render() {
		const {
			authenticated
		} = this.state;
		return (
			<BrowserRouter basename="/">
				{!authenticated ? <Switch>
					<Route exact path="/">
						<LazyLogin />
					</Route>
					<Route exact path="/forgotPassword">
						<ForgotPassword />
					</Route>
					<Route exact path="/welcome">
						<Welcome />
					</Route>
					<Route exact path="/resetPassword">
						<LazyResetPassword />
					</Route>

					<Route exact path="/external/:organization/events/:event/reports/:report" render={(matchprops) => {
						return <EventSharedReport {...matchprops} />
					}} />
					<ErrorLayoutRoute
						render={() => (
							<Redirect
								to={{
									pathname: '/'
								}}
							/>
						)}
					/>

				</Switch> : <Switch>

					<Route exact path="/external/:organization/events/:event/reports/:report" render={(matchprops) => {
						return <EventSharedReport {...matchprops} />
					}} />
					<Route path="/:organization" render={
						(matchprops) => {
							return <ApplicationLayout {...matchprops} />
						}
					}>
					</Route>

					<Route path="/" render={
						(matchprops) => {
							return <ApplicationLayout {...matchprops} />
						}
					}>
					</Route>

				</Switch>}

			</BrowserRouter>
		);
	}
}

export default Router;
