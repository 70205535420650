import React, { Component } from 'react';

import FieldModal from '../../../components/modals/fieldModal';

class DeletePagesModal extends Component {
	state = {
		submitting: false
	};
	render() {
		const { isOpen, count, toggle } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete ${count > 1 ? 'Pages' : 'Page'}`}
					bodyHeaderText={`You are about to delete ${count} ${count > 1 ? 'pages' : 'page'}, this is irreversible, would you
						like to continue?`}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.props.delete()}
					actionButtonDisabled={submitting}
					isDelete={true}


				></FieldModal>
			</div>

		);
	}
}

export default DeletePagesModal;
