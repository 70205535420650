import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';
import TextAreaField from '../../../../components/inputs/textAreaField';
import SelectField from '../../../../components/inputs/selectField';
import Dropzone from 'react-dropzone';
import Croppie from 'croppie/croppie';

const croppieOptions = {
    showZoomer: true,
    enableOrientation: true,
    maxZoom: .5,
    viewport: {
        width: 200,
        height: 200,
        type: 'square',

    },
    boundary: {
        width: 300,
        height: 300
    }
};

let dropzoneRef;

class AddFeedItemModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: { label: '', color: '#00B8D9' },
            isCustomPoint: false,
            customLabel: '',
            description: '',
            pointSuffix: '',
            pointValue: 0,
            maxPointsAllowed: 0,
            oncePerReference: false,
            active: false,
            callToActionLabel: '',
            callToActionUrl: '',
            submitting: false,
            invalid: false,
            success: false,
            allowPoints: false,
            galleryName: '',
            currentStep: 0,
            selectedFile: null,
            caption: '',
            selectedAttendee: null,
            isSelectedAttendeeDefined: true,
        };
    }

    componentDidMount() {
        const { attendees } = this.props;

        let mappedAttendees = attendees.map((attendee) => {
            return {
                label: `${attendee.firstName} ${attendee.lastName}`,
                value: attendee._id
            }
        }
        );
        this.setState({ availableAttendees: mappedAttendees });
    }

    onDrop = (files) => {

        const reader = new FileReader();
        const selectedFile = files[0];
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {


            this.setState({ selectedFile: reader.result, currentStep: 1 }, () => {
                const croppie = document.getElementById("croppie");
                const c = new Croppie(croppie, croppieOptions);
                c.bind({ url: this.state.selectedFile });
                this.setState({ c: c });

            });
        }
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };



    handleFileChange = (e) => {
        const reader = new FileReader();
        const selectedFile = e.target.files[0];
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {

            this.setState({ selectedFile: reader.result, toggleImageCropper: true });
        }
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };



    addFeedItem = async () => {
        const { orgId, eventId, toggle, selectedGallery } = this.props;
        const { selectedFile, caption, selectedAttendee, blob } = this.state;

        if (selectedAttendee && selectedAttendee.value) {

            if (blob) {
                this.setState({ submitting: true, isSelectedAttendeeDefined: true });
                var formData = new FormData();
                formData.append('feedIdentifier', selectedGallery.feedIdentifier);
                formData.append('caption', caption);
                formData.append('attendeeId', selectedAttendee.value);
                formData.append('files', blob);
                await API().post(`Organizations/${orgId}/events/${eventId}/feedItem`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((res) => {
                    if (res.data) {
                        let feedItem = res.data.feedItem;
                        feedItem.name = `${feedItem.attendee.firstName || ''} ${feedItem.attendee.lastName || ''}`;
                        this.props.addFeedItem(feedItem, toggle);
                    }
                }).catch((err) => {
                    console.log(err);
                    this.setState({ submitting: false });
                })
            }

        } else {

            this.setState({ isSelectedAttendeeDefined: false })
        }


    };

    actionButtonLogic = () => {

        const { currentStep, c } = this.state;
        if (currentStep == 0) {
            this.setState({ currentStep: 1 });
        } else if (currentStep == 1) {
            c.result({
                type: "blob",
                size: { width: 1000, height: 1000 },
                format: "jpg",
                quality: 1,
                circle: false
            }).then(async (blob) => {
                if (blob) {
                    this.setState({ currentStep: 2, blob: blob });
                }
            });




        } else if (currentStep == 2) {
            this.addFeedItem();
        }

    }

    render() {
        const { isOpen, toggle } = this.props;
        const {
            submitting,
            currentStep,
            caption,
            availableAttendees,
            selectedAttendee,
            isSelectedAttendeeDefined
        } = this.state;

        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Share A Picture"}
                    bodyHeaderText={'Image will be made available on pages with selected feed'}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                {currentStep == 0 ?
                                    <div>
                                        <Dropzone
                                            accept="image/*"
                                            className="clickable dropzone-details banner mb-10 "
                                            style={{ height: 335, width: 335, margin: 'auto' }}
                                            ref={(node) => {
                                                dropzoneRef = node;
                                            }}
                                            onDrop={this.onDrop.bind(this)}
                                        >
                                            <div>
                                                <div className="addIcon">
                                                    <i className="las la-plus"></i>
                                                </div>
                                                <p className='text-c grey'>
                                                    Add Image
                                                </p>
                                            </div>
                                        </Dropzone>

                                    </div> :
                                    currentStep == 1 ?
                                        <div>
                                            <div id="croppie" >
                                            </div>

                                        </div>
                                        : currentStep == 2 ?
                                            <div>
                                                <TextAreaField
                                                    label='Image Caption'
                                                    placeholder='Add a caption...'
                                                    value={caption}
                                                    rows={5}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 300) {
                                                            this.setState({ caption: e.target.value });
                                                        }
                                                    }
                                                    }
                                                    subText={`${caption.length}/300`}
                                                />

                                                <SelectField
                                                    label={'Event Attendee'}
                                                    options={availableAttendees}
                                                    value={selectedAttendee}
                                                    required={true}
                                                    errorTrigger={!isSelectedAttendeeDefined}
                                                    onChange={(e) => {
                                                        this.setState({ selectedAttendee: e });
                                                    }}
                                                />

                                            </div>
                                            : null
                                }
                            </div>
                        )
                    }
                    }
                    toggle={toggle}
                    actionButtonLabel={
                        currentStep == 0 ? 'Next' :
                            currentStep == 1 ? 'Next' :
                                currentStep == 2 ? 'Upload' : ''
                    }
                    actionButton={this.actionButtonLogic}
                    actionButtonDisabled={currentStep == 0 || submitting}
                    cancelButtonDisabled={currentStep !== 2}

                ></FieldModal >
            </div >

        );
    }
}

export default AddFeedItemModal;
