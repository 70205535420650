import React, { Fragment } from 'react';


import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
class AddAttendeeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			submitting: false,
			invalid: false,
			error: 'All fields are required',
			requiredFields: {
				email: false,
				firstName: false
			}
		};
	}

	handleFNChange = (e) => {
		this.setState({
			firstName: e.target.value
		});
	};

	handleLNChange = (e) => {
		this.setState({
			lastName: e.target.value
		});
	};

	handleEAChange = (e) => {
		const email = e.target.value;
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email regex pattern

		this.setState({
			email,
			requiredFields: {
				...this.state.requiredFields,
				email: !emailPattern.test(email) // Set to true if email is invalid
			}
		});
	};

	addAttendee = (attendee) => {
		let requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (attendee[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
			// Ensure email is valid
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailPattern.test(attendee.email)) {
				requiredFields.email = true;
				valid = false;
			}

		if (valid) {
			this.setState({ submitting: true });
			
			this.props.handleSubmit(attendee, this.props.toggle);
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { firstName, lastName, email, submitting, invalid, error, requiredFields } = this.state;
		return (
			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Attendee"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Email Address"
									value={email}
									required={true}
									errorTrigger={requiredFields.email}
									onChange={this.handleEAChange}
								/>

								<InputField
									label="First Name"
									value={firstName}
									required={true}
									errorTrigger={requiredFields.firstName}
									onChange={this.handleFNChange}
								/>

								<InputField label="Last Name" onChange={this.handleLNChange} value={lastName} />
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding Attendee...'}
					actionButton={() => this.addAttendee({ firstName, lastName, email })}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default AddAttendeeModal;
