import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import TopLevelNavLink2 from "./topLevelNavLink2";
import seFavIcon from '../../assets/img/icons/s2.png';
import logo from '../../assets/img/logos/simple events logo.png';
import FieldModal from "../modals/fieldModal";
import Teams from "../../views/teams/teams";
import Profile from "../../views/profile/profile";
import Account from "../../views/account/account";
import BaseModal from "../modals/baseModal";
import InboxModal from "../modals/inboxModal";
import moment from "moment";
import GlobalSearchModal from "../modals/globalSearchModal";

class SystemToolbar extends Component {

    constructor(props) {
        super(props);
        const { permission, subscriptionAddons, subscriptionType } = props;
        let manageNavigation = [
            {
                adminOnly: false,
                name: 'Events',
                section: 'events'
            },
            {
                adminOnly: false,
                name: 'Programs',
                section: 'programs',
                addon: 'requests'
            },
            {
                adminOnly: false,
                name: 'Contacts',
                section: 'contacts'
            }
        ];

        let accountNavigation = [{
            adminOnly: true,
            name: 'Account Pages',
            icon: "las la-laptop-code",
            section: 'pages',
            addon: 'account-pages',
        },
        {
            adminOnly: true,
            name: 'Account Files',
            icon: "las la-photo-video",
            section: 'media',
            addon: 'account-pages',
        }]


        accountNavigation = accountNavigation.filter((menuItem) => {
            return (permission || !menuItem.adminOnly) &&
                ((subscriptionType != 'Enterprise Premium' &&
                    menuItem.addon &&
                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                    !menuItem.addon ||
                    subscriptionType == 'Enterprise Premium')
        });

        manageNavigation = manageNavigation.filter((menuItem) => {
            if (menuItem.section == 'programs' && !this.props.programsAvailable) {
                return false;
            }
            return (permission || !menuItem.adminOnly) &&
                ((subscriptionType != 'Enterprise Premium' &&
                    menuItem.addon &&
                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                    !menuItem.addon ||
                    subscriptionType == 'Enterprise Premium')
        });
        let searchParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')));
        const accountModalOpen = searchParams.get('section') != null;

        this.state = {
            loading: true,
            manageNavigation,
            accountNavigation,
            profileModalOpen: false,
            teamModalOpen: false,
            accountModalOpen: accountModalOpen,
            inboxModalOpen: false,
            supportModalOpen: true,
            searchModalOpen: false
        };
    }



    render() {
        const { account, organization, organizations, logout, event, permission, displayLogo, orgId, taskCount, platformNotifications } = this.props;
        const { firstName, lastName, email, profilePicture } = account;
        const { manageNavigation, accountNavigation, profileModalOpen, teamModalOpen, accountModalOpen, inboxModalOpen, searchModalOpen } = this.state;
        let emptyProfilePicture = !profilePicture;
        let notificationCount = platformNotifications.filter((notification) => notification.isRead == false).length || 0;
        return <div className='systemToolbar'>
            <div className="flex h-100 ml-30" style={{ alignItems: 'end' }}>

                {displayLogo &&
                    <div className="navLogo c-pointer" style={{ marginRight: 103 }} onClick={() => window.location.href = "/"}>
                        <img src={logo} alt="logo" />
                    </div>}
                {manageNavigation.map((menuItem, index) => {
                    return <TopLevelNavLink2
                        className="mr-30"
                        isLast={index == manageNavigation.length - 1}
                        orgId={organization._id}
                        menuItem={menuItem}
                        index={index}
                        permission={permission}
                        key={`manage-nav-${index}`}
                        loading={false}
                    />;
                })}
            </div>
            <div className='systemOptions mr-30'>
                <button className="inboxContainer round c-pointer mr-10"
                    onClick={() => {
                        this.setState({
                            searchModalOpen: true,
                            profileModalOpen: false,
                            teamModalOpen: false,
                            accountModalOpen: false,
                        })
                    }
                    }
                >
                    <div className="iconContainer">
                        <i className="las la-search" />
                    </div>
                </button>
                <UncontrolledDropdown className="accountDropdown mr-10" >
                    <DropdownToggle className="p-0 inboxContainer round c-pointer">
                        <div style={{ position: 'relative' }}>
                            <div className="iconContainer">
                                <i class="las la-bell"></i>
                            </div>
                            {notificationCount > 0 &&
                                <div>
                                    <div
                                        className="notificationCount"
                                    >
                                        <p className="small">{notificationCount}</p>
                                    </div>

                                </div>}
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="columnDropdown pt-0 platformNotificationDropdown" right style={{ minWidth: 300, width: '100%', maxWidth: 350 }}>

                        <div>
                            <div className='notificationHeader'>
                                <h2 >Notifications</h2>
                            </div>

                            <div className='p-10 ' style={{ maxHeight: 450, overflow: 'auto' }}>
                                {platformNotifications && platformNotifications.length > 0 ? platformNotifications.map((notification) => {
                                    const title = notification.title;
                                    const message = notification.message;
                                    const type = notification.type;
                                    let createdDate = moment(notification.createdAt);
                                    let daysAgo = createdDate.fromNow();
                                    return (
                                        <DropdownItem
                                            className="leftAlign notificationItem"
                                            style={{ wordWrap: 'break-word' }}

                                            onClick={() => {
                                                this.props.markNotificationAsRead(notification._id, () => {
                                                    const origin = window.location.origin;
                                                    window.location.href = origin + notification.actionLink;

                                                });
                                            }}
                                        >
                                            <div style={{
                                            }}>


                                                <h3 className='header'>
                                                    {title}
                                                </h3>
                                                <div className='message'>
                                                    {message}
                                                </div>
                                                <div className='details'>
                                                    {daysAgo} - {type}
                                                </div>

                                            </div>

                                        </DropdownItem>
                                    )
                                }
                                ) :
                                    <div className="flex jcc aic pt-20">
                                        No Notifications
                                    </div>
                                }

                            </div>

                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>


                <button className="inboxContainer round c-pointer"
                    onClick={() => {

                        this.setState({
                            inboxModalOpen: true,
                            profileModalOpen: false,
                            teamModalOpen: false,
                            accountModalOpen: false,
                        })

                    }}
                >
                    {taskCount > 0 && <div
                        className="notificationBubble"
                    >
                        <p className="small">{taskCount}</p>
                    </div>}
                    <div className="iconContainer">
                        <i className="las la-inbox" />
                    </div>
                </button>

                {/* <UncontrolledDropdown className="accountDropdown ml-15" >
                    <DropdownToggle className="contained p-0">
                        <div className="iconContainer">
                            <i className="las la-question-circle" />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className="columnDropdown pt-0" right style={{ minWidth: 250, width: '100%', maxWidth: 350 }}>
                    </DropdownMenu>
                </UncontrolledDropdown> */}
                <UncontrolledDropdown className="accountDropdown ml-15" >
                    <DropdownToggle className="contained p-0">
                        <div className="flex" style={{ padding: 3, width: 42, marginRight: -15, background: 'white', borderRadius: 3, border: '1px solid var(--border)' }}>
                            <img className="orgFavicon" src={organization.branding.favicon ? organization.branding.favicon : seFavIcon} />
                        </div>
                        {
                            // Check if the profile picture URL is empty or not
                            emptyProfilePicture ?
                                (
                                    // If the profile picture URL is empty, render a div that contains the user's initials
                                    <div className="profileIconContainer d30 mr-10">
                                        {firstName.toUpperCase().charAt(0)}
                                        {lastName.toUpperCase().charAt(0)}
                                    </div>
                                ) :
                                (
                                    // If there is a profile picture URL, render an <img> element to display the profile picture
                                    <img src={profilePicture}
                                        alt="Profile"
                                        className="acronymCircle profilePicture d30 mr-10" />
                                )
                        }
                    </DropdownToggle>
                    <DropdownMenu className="columnDropdown pt-0" right style={{ minWidth: 250, width: '100%', maxWidth: 350 }}>
                        <div className="p-20 pb-0">
                            <h3 className="mb-0" >
                                {organization.name}
                            </h3>
                            <p className="mb-0 small gray" style={{ whiteSpace: 'nowrap' }}>
                                {organization.subscriptionType}
                            </p>
                        </div>
                        {organizations.length > 1 && (<div style={{ padding: '15px 5px', paddingBottom: 0 }}>

                            <UncontrolledDropdown direction="left" >
                                <DropdownToggle className="w-100" >
                                    <i className="las la-sync mr-10" />
                                    Switch Accounts
                                    <i className="las la-angle-right ml-a"></i>
                                </DropdownToggle>
                                <DropdownMenu className="columnDropdown hideX p-20" style={{ minWidth: 100, maxHeight: 200 }}>
                                    {organizations.map((org) => {
                                        if (org.name == organization.name) {
                                            return (
                                                <div className="leftAlign selectedAccount" >
                                                    {org.name}
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <DropdownItem className="leftAlign"
                                                    onClick={() => {
                                                        localStorage.setItem('defaultOrganization', org._id);
                                                        window.location.href = `/${org._id}/events`
                                                    }}>
                                                    {org.name}
                                                </DropdownItem>
                                            )
                                        }

                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </div>)}
                        <hr className="mt-15" />
                        <div style={{ padding: 5 }}>

                            <DropdownItem className="leftAlign " onClick={() => this.setState({
                                profileModalOpen: true,
                                teamModalOpen: false,
                                accountModalOpen: false,
                            })} >
                                <i className="las la-user mr-15" />My Profile
                            </DropdownItem>

                            <Link to={`/${organization._id}/team`}>
                                <DropdownItem className="leftAlign ">
                                    <i className="las la-user-friends mr-15" />
                                    Team
                                </DropdownItem>
                            </Link>
                            <DropdownItem className="leftAlign " onClick={() => this.setState({
                                profileModalOpen: false,
                                teamModalOpen: false,
                                accountModalOpen: true,
                            })} >
                                <i className="las la-book mr-15" />Manage Account
                            </DropdownItem>

                            {/* <Link to={`/${organization._id}/profile`}>
                            </Link> */}
                            {accountNavigation.map((nav) => {
                                return (
                                    <Link to={`/${organization._id}/${nav.section}`}>
                                        <DropdownItem className="leftAlign " >
                                            <i className={`${nav.icon} mr-15`} />{nav.name}
                                        </DropdownItem>
                                    </Link>
                                )
                            })}

                            <DropdownItem className="leftAlign" onClick={() => {
                                window.open("https://wkf.ms/3O3tHzS", "_blank")
                            }}>
                                <i className="lab la-gratipay mr-15" />Send Feedback
                            </DropdownItem>
                            <button
                                onClick={logout}
                                className="leftAlign dropdown-item"
                            >
                                <i
                                    className="las la-sign-out-alt mr-15 danger"
                                />
                                Logout
                            </button>
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            {profileModalOpen && <FieldModal cancelButtonDisabled={true} size="medium" isOpen={profileModalOpen} toggle={() => this.setState({ profileModalOpen: !profileModalOpen })} bodyContent={() => {
                return <Profile
                    permission={this.props.adminPermission}
                    orgId={organization._id}
                    socket={this.props.socket}
                    pingServer={this.props.pingServer}
                ></Profile>
            }} />}
            {teamModalOpen && <FieldModal size="large" isOpen={teamModalOpen} toggle={() => this.setState({ teamModalOpen: !teamModalOpen })} isFullWidthBody={true} bodyContent={() => {
                return <Teams orgId={organization._id} />
            }} />}
            {accountModalOpen && <BaseModal cancelButtonDisabled={true} className="noPad" size="large" isOpen={accountModalOpen} toggle={() => this.setState({ accountModalOpen: !accountModalOpen })} isFullWidthBody={true} bodyContent={() => {
                return <Account
                    permission={this.props.adminPermission}
                    orgId={organization._id}
                    socket={this.props.socket} />
            }} />}
            {inboxModalOpen && <InboxModal
                isOpen={inboxModalOpen}
                orgId={organization._id}
                toggle={() => this.setState({ inboxModalOpen: !inboxModalOpen })}
                updateTaskCount={this.props.updateTaskCount}
            />}

            {searchModalOpen && <GlobalSearchModal
                isOpen={true}
                toggle={() => this.setState({ searchModalOpen: !searchModalOpen })}
                organization={organization}
                event={event}
            />}

        </div>
    }
}

export default SystemToolbar;