import React from 'react';

import FieldModal from '../../../components/modals/fieldModal';


class DeleteRequestsModal extends React.Component {
    state = {
        submitting: false,
        deleteConfirmed: ''
    };
    render() {
        const { isOpen, toggle, count, deleting } = this.props;
        const { submitting, complete } = this.state;
        return (

            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={`Delete Event${count == 1 ? '' : 's'}`}
                    bodyHeaderText={<div>{!submitting ? `You are about to delete ${count} request${count == 1 ? '' : 's'}, this is irreversible.` : `${count} request${count == 1 ? '' : 's'} deleted.`}<br/><span>{!complete ? 'Are you sure you want to continue?' : ''}</span></div>}
                    bodyContent={() => {
                        return (
                            '')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : complete ? 'Complete' : 'Deleting...'}
                    actionButton={() => {
                        this.setState({ submitting: true });
                        this.props.delete(() => {
                            this.setState({ complete: true })
                        });
                    }}
                    actionButtonDisabled={submitting}
                    cancelButtonLabel={complete ? 'Close' : 'Cancel'}
                    isDelete={true}


                ></FieldModal>

            </div>

        );
    }
}

export default DeleteRequestsModal;
