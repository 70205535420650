import React, { Component } from 'react';
import moment from 'moment';

class SubscriptionAndBilling extends Component {
	render() {
		const {
			billing,
			subscriptionType,
			userCount,
			subscriptionDetails,
			subscriptionAddons,
			adminCount,
			registrationCount
		} = this.props.accountData;
		let userCountPercentage = userCount / subscriptionDetails.allotedUsers * 100;
		let overCount = false;
		if (userCountPercentage > 100) {
			userCountPercentage = 100;
			overCount = true;
		}

		let adminCountPercentage = adminCount / subscriptionDetails.allotedAdministrators * 100;
		let overAdminCount = false;
		if (adminCountPercentage > 100) {
			adminCountPercentage = 100;
			overAdminCount = true;
		}

		let registrationCountPercentage = registrationCount / subscriptionDetails.allotedRegistrations * 100;
		let overRegistrationCount = false;
		if (registrationCountPercentage > 100) {
			registrationCountPercentage = 100;
			overRegistrationCount = true;
		}


		const enableAll = subscriptionType == 'Enterprise Premium';
		//format amount due with commas
		const amountDue = billing.amountDue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		//if billing date has passed
		let nextBillDate = moment(billing.startDate);
		const billingYear = parseInt(moment(billing.startDate).format('YYYY'));
		if (moment(billing.startDate).isBefore(moment())) {
			const currentYear = parseInt(moment().format('YYYY'));
			if (billingYear <= currentYear) {
				nextBillDate.year(currentYear + 1);
			}
		}

		return (
			<div className="sectionBlock" style={{ maxWidth: 500 }}>
				<h2 className="mb-15 mt-10">Your Plan</h2>
				<div className="subscriptionBox">
					<div className="subscriptionTitle">
						<h3>{subscriptionType}</h3>

						<div className="mt-5">
							{subscriptionType === 'Enterprise' ? (
								'Maximize your event experience and brand.'
							) : subscriptionType == 'Enterprise Premium' ? (
								"All of Simple.Events's the bells and whistles."
							) : (
								''
							)}
						</div>
					</div>
					<div className="subscriptionPrice">
						<h3>Payment</h3>
						<div className="mt-5">
							Your next bill is for <strong>${amountDue}</strong> on{' '}
							<strong>{nextBillDate.format('MMMM Do, YYYY')}</strong>
						</div>
					</div>
				</div>
				<div className="userCounter mt-25">
					<div className="flex-sb">
						<h2>Administrators</h2>
						<h4 >
							{adminCount} <span>of</span> {subscriptionDetails.allotedAdministrators}
						</h4>
					</div>
					{/* progress bar of user count */}
					<div className="progressBar mt-5">
						<div
							className={`progressBarFill ${overAdminCount ? 'overCount' : ''}`}
							style={{ width: `${adminCountPercentage}%` }}
						/>
					</div>
					{overAdminCount && (<div className="calibreRegular" style={{ marginTop: 15 }}>

						<div>
							You have exceeded your user count by{' '}
							<strong>{adminCount - subscriptionDetails.allotedAdministrators}</strong>
							<br />
							<br />
							Please contact your account director directly, or contact our{' '}
							<a href="mailto:support@simple.events" className="se-blue" target="_blank">
								support team
							</a>{' '}
							to upgrade.
						</div>

					</div>)}
				</div>
				<div className="userCounter mt-25">
					<div className="flex-sb">
						<h2>Users</h2>
						<h4 >
							{userCount} <span>of</span> {subscriptionDetails.allotedUsers}
						</h4>
					</div>
					{/* progress bar of user count */}
					<div className="progressBar mt-5">
						<div
							className={`progressBarFill ${overCount ? 'overCount' : ''}`}
							style={{ width: `${userCountPercentage}%` }}
						/>
					</div>

					{overCount > 0 && (<div className="calibreRegular" style={{ marginTop: 15 }}>
						<div>
							You have exceeded your registration count by{' '}
							<strong>{overCount - subscriptionDetails.allotedUsers}</strong>
							<br />
							<br />
							Please contact your account director directly, or contact our{' '}
							<a href="mailto:" className="se-blue" target="_blank">
								support team to upgrade.</a>
						</div>
					</div>)}
				</div>
				<div className="userCounter mt-25">
					<div className="flex-sb">
						<h2>Registrations</h2>
						<h4 >
							{registrationCount} <span>of</span> {subscriptionDetails.allotedRegistrations}
						</h4>
					</div>
					{/* progress bar of user count */}
					<div className="progressBar mt-5">
						<div
							className={`progressBarFill ${overRegistrationCount ? 'overCount' : ''}`}
							style={{ width: `${registrationCountPercentage}%` }}
						/>
					</div>
					{overRegistrationCount && (<div className="calibreRegular" style={{ marginTop: 15 }}>

						<div>
							You have exceeded your user count by{' '}
							<strong>{registrationCount - subscriptionDetails.allotedRegistrations}</strong>
							<br />
							<br />
							Please contact your account director directly, or contact our{' '}
							<a href="mailto:support@simple.events" className="se-blue" target="_blank">
								support team
							</a>{' '}
							to upgrade.
						</div>
					</div>)}
				</div>

				<h2 className="mb-15 mt-20" >
					Add-Ons
				</h2>
				<div className="addOnList">
					{/* <div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('budgetManagement')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Budget Management</div>
					</div> */}
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('budget')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Event budget management</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('inventory')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Custom equipment / inventory management</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('requests')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Event request module</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('html-emails')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Custom HTML emails</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('sso')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>SSO integration</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('gamification')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Application gamification module</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('event-pass')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>QR code scanner / pass management module</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('space-plus')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>File manager capacity extension (20GB)</h4>
					</div>
					<div className="addOnItem">
						<div
							className={`addOnPill ${enableAll || subscriptionAddons.includes('account-pages')
								? 'enabled'
								: 'disabled'}`}
						>
							<h5 className="enableText">enabled</h5>
							<h5 className="disableText">disabled</h5>
						</div>{' '}
						<h4>Custom account level web pages</h4>
					</div>
				</div>
			</div>
		);
	}
}
export default SubscriptionAndBilling;
