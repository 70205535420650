import React from 'react';

import API from '../../../../utils/API';
import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';

class AddTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {
				name: false
			}
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	addTemplate = async () => {
		this.setState({ submitting: true });
		const { name } = this.state;
		const { eventId, orgId, addTemplate } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!name || name === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			await API().post(`Organizations/${orgId}/events/${eventId}/template`, { name: name }).then((res) => {
				const templateObj = res.data;

				if (res.data) {
					addTemplate(templateObj, () => {
						window.open(`/${orgId}/events/${eventId}/emails/templates/${templateObj._id}`, '_self');
					});
				} else {
				}
			}).catch((err) => {
				console.log(err);
			});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, submitting, requiredFields } = this.state;
		return (
			<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={"Create New Email Template"}
				bodyHeaderText={''}
				//bodyDescription={`Please enter a unique name for your new email campaign.`}
				isFullWidthBody={true}
				bodyContent={() => {
					return (
						<div>
							<div className='sectionBlock m-a mb-20'>
								<h2 className='blockHeader mb-10 '>Enter a unique name for your new email campaign</h2>
								<InputField
									label="Template Name"
									required={true}
									errorTrigger={requiredFields.name}
									value={name}
									onChange={this.handleNameChange}
								/>
							</div>
						</div>

					)
				}
				}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Creating Template...'}
				cancelButtonLabel={'Cancel'}

				actionButton={() => this.addTemplate()}
				submitting={submitting}
				actionButtonDisabled={submitting}

			></FieldModal >
		);
	}
}

export default AddTemplateModal;
