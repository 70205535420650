import React from 'react';
import { Modal, ModalBody } from 'reactstrap';


class FieldModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        // Create a ref for the modal
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        const { isDelete } = this.props;
        if (!isDelete) {
            this.modalRef.current && this.modalRef.current.addEventListener('keydown', this.handleEnterKey);
        }
    }

    componentWillUnmount() {

        this.modalRef.current && this.modalRef.current.removeEventListener('keydown', this.handleEnterKey);
    }

    handleEnterKey = (e) => {
        const { actionButton, } = this.props;
        if (e.target.tagName.toLowerCase() == "textarea" || e.target.tagName.toLowerCase() == "div") return;
        if (e.key === 'Enter' && !e.altKey && !e.ctrlKey && !e.shiftKey && !e.metaKey) {
            const isInputWithListAttribute = e.target.tagName.toLowerCase() === 'input' && e.target.getAttribute('aria-autocomplete') === 'list';
            if (!isInputWithListAttribute) {
                // Execute the actionButton code
                actionButton();
            } else {
                // to unfocus target due to the select 
                e.target.blur();
            }

        }
    };
    render() {
        const {
            isOpen,
            toggle,
            modalTitle,
            style,
            bodyHeaderText,
            bodyContent,
            actionButton,
            isDelete,
            actionButtonLabel,
            actionButtonDisabled,
            cancelButtonLabel,
            className,
            size,
            isFullWidthBody,
            cancelButtonDisabled,
            overflowOff,
            cancelOnClick,
            bodyExtraText,
            overflowHidden
        } = this.props;

        return (
            <Modal innerRef={this.modalRef} style={style} isOpen={isOpen} toggle={toggle} className={`${className ? className : ''} ${size ? size : ''}`} >
                <div className='modalHeader'   >
                    <div>

                        <h1>{modalTitle}</h1>
                        {bodyHeaderText && <p className='mb-10'>{bodyHeaderText}</p>}
                        {bodyExtraText && bodyExtraText}
                    </div>
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
                </div>
                <ModalBody className={`modalBody mt-10 ${overflowOff ? 'overflowOff' : ""} ${overflowHidden ? 'overflowHidden' : ''}`}>
                    <div className={`sectionBody w-100  anim left`}>
                        <div className='flex flex-wrap jcc w-100 h-100'>
                            <div className={`sectionBlock h-100 ${isFullWidthBody ? 'unset-w' : ''}`} >
                                {bodyContent()}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <div className='modalFooter mt-20'>
                    <div className='flex'>
                        {!cancelButtonDisabled && <button className="neu ml-a " onClick={cancelOnClick ? cancelOnClick : toggle}>{cancelButtonLabel ? cancelButtonLabel : 'Cancel'}</button>}
                        {actionButtonLabel && <button className={`${cancelButtonDisabled ? 'ml-a' : `ml-25`}  ${actionButtonDisabled && 'disabled'} ${!actionButtonDisabled ? isDelete ? 'danger' : 'prim' : ''} `}
                            onClick={actionButton}>{actionButtonLabel}
                        </button>}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default FieldModal;
