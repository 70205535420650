// import external modules
import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { removeCookie } from '../utils/cookie';
import API from '../utils/API';
import Logo from '../assets/img/logos/simple events logo.png';

// Main Layout
import LazyEvents from '../views/events/events';
import LazyPrograms from '../views/programs/programs';
import LazyContacts from '../views/contacts/contacts';
import LazyPages from '../views/application/accountPages';
import LazyForms from '../views/forms/forms';
import TopLevelLayout from '../layouts/topLevelLayout';
import EventLevelLayout from './eventLevelLayout';
import SpinnerComponent from '../components/spinner/spinner';
import ProgramLevelLayout from './programLevelLayout';
import ContactDetails from '../views/contacts/contact';
import Teams from '../views/teams/teams';

import TeamMember from '../views/teams/team';
import Invite from '../views/teams/invite';
import AccountFiles from '../views/application/accountFiles';
import Page from '../views/application/page';
import Form from '../views/application/form';
import io from '../utils/Socket';
let socket = io();

const AUTHENTICATION_TIME = 2000;
let authenticationInterval = null;

class ApplicationLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            authenticated: false,
            loadingTimeElapsed: 0,
            authorized: false,
            account: {
                firstName: '',
                lastName: '',
                email: '',
                profilePicture: '',
                permission: ''
            },
            selectedOrganization: null,
            permission: '',
            eventPermissions: [],
            singleUserSectionInUse: false,
            subscriptionType: 'Enterprise',
            subscriptionAddons: [],
            platformNotifications: [],
        };
    }

    loadApplication = () => {
        const { authenticated, loadingTimeElapsed } = this.state;
        if (authenticated && loadingTimeElapsed >= AUTHENTICATION_TIME) {
            clearInterval(authenticationInterval);
            this.setState({ loading: false });
        } else {
            this.setState({ loadingTimeElapsed: loadingTimeElapsed + 100 });
        }
    }


    componentDidMount() {
        this.pingServer();
        authenticationInterval = setInterval(this.loadApplication, 100);
        this.unlisten = this.props.history.listen((location, action) => {
            this.pingServer();
        });


        socket.on('user_notification', (resData) => {
            if (resData.platformNotification) {
                let platformNotifications = this.state.platformNotifications;
                let newNotifications = resData.platformNotification;
                platformNotifications.push(newNotifications);
                this.setState({ platformNotifications: platformNotifications });
            }

        })
    }

    logout = () => {
        localStorage.clear();
        removeCookie('authToken');
        window.location.replace('/login');
    }

    pingServer = () => {
        const defaultOrg = localStorage.getItem('defaultOrganization');
        const orgId = this.props.match && this.props.match.params.organization;
        if (orgId === 'integrate') {
            let searchParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')));
            const code = searchParams.get('code');
            window.open(`/${defaultOrg}/events?section=1&code=${code}&integration=salesforce`, '_self');
        } else if (orgId && orgId !== 'undefined' && orgId !== 'admin' && orgId !== 'login' && orgId !== 'welcome') {
            socket.post(
                `/Organizations/${orgId}/adminConnect`,
                {
                    location: `${window.location.pathname}${window.location.search}`
                }
                , (data) => {
                    const resData = data;
                    if (resData.authorized) {
                        if (
                            resData.permission !== this.state.permission ||
                            resData.eventPermissions.toString() !== this.state.eventPermissions.toString()
                        ) {
                            this.setState({
                                permission: resData.permission,
                                account: {
                                    _id: resData._id,
                                    firstName: resData.firstName,
                                    lastName: resData.lastName,
                                    email: resData.email,
                                    profilePicture: resData.profilePicture,
                                },
                                eventPermissions: resData.eventPermissions,
                                authenticated: true,
                                singleUserSectionInUse: resData.singleUserSectionInUse,
                                subscriptionType: resData.subscriptionType,
                                subscriptionAddons: resData.subscriptionAddons || [],
                                selectedOrganization: resData.organization,
                                organizations: resData.organizations,
                                taskCount: resData.taskCount
                            });
                        } else if (resData.organization && resData.organization.onboarded !== this.state.selectedOrganization.onboarded) {
                            this.setState({
                                selectedOrganization: resData.organization,
                                organizations: resData.organizations,
                                taskCount: resData.taskCount
                            });
                        } else {
                            this.setState({
                                account: {
                                    _id: resData._id,
                                    firstName: resData.firstName,
                                    lastName: resData.lastName,
                                    email: resData.email,
                                    profilePicture: resData.profilePicture,
                                }
                            })
                        }
                        if (
                            resData.singleUserSectionInUse &&
                            resData.singleUserSectionInUse.toString() !== this.state.singleUserSectionInUse.toString()
                        ) {
                            this.setState({ singleUserSectionInUse: resData.singleUserSectionInUse });
                        }
                        if (resData.platformNotifications) {
                            this.setState({ platformNotifications: resData.platformNotifications });

                        }
                    } else {
                        this.setState({ authorized: false, authenticated: true });
                    }
                });

        } else if (defaultOrg && defaultOrg != orgId && orgId !== 'undefined' && orgId !== 'admin' && orgId !== 'welcome') {

            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then(function (rs) {
                    if (rs.length > 0) {

                        for (let reg of rs) {
                            reg.unregister().then(() => {
                                window.open(`/${defaultOrg}/events`, '_self');
                            })
                        }
                    } else {
                        window.open(`/${defaultOrg}/events`, '_self');
                    }

                }).catch((err) => {
                    window.open(`/${defaultOrg}/events`, '_self');
                });
            } else {
                window.open(`/${defaultOrg}/events`, '_self');
            }
            // window.open(`/${defaultOrg}/events`, '_self');
        } else {
            this.logout();
        }
    };

    getSectionCounts = async (eventId) => {
        const orgId = this.props.match && this.props.match.params.organization;
        if (eventId) {
            const sectionCounts = await API().get(
                `Organizations/${orgId}/events/${eventId}/sectionCounts`
            ).then();
            return sectionCounts.data;
        } else {

            const sectionCounts = await API().get(
                `Organizations/${orgId}/sectionCounts`
            ).then();
            return sectionCounts.data;
        }
    }

    getSectionProgramCounts = async (programId) => {
        const orgId = this.props.match && this.props.match.params.organization;
        if (programId) {
            const sectionCounts = await API().get(
                `Organizations/${orgId}/programs/${programId}/sectionCounts`
            ).then();
            return sectionCounts.data;
        } else {

            const sectionCounts = await API().get(
                `Organizations/${orgId}/sectionCounts`
            ).then();
            return sectionCounts.data;
        }
    }

    updateTaskCount = (increment, callback) => {
        this.setState({ taskCount: increment === true ? this.state.taskCount + 1 : this.state.taskCount - 1 }, callback);
    }

    removeTeamMembers = (memberIds) => {
        const { selectedOrganization } = this.state;
        let updatedMembers = selectedOrganization.members;
        updatedMembers = updatedMembers.filter(
            (sa) => !memberIds.includes(sa._id.toString())
        );
        selectedOrganization.members = updatedMembers;
        this.setState({ selectedOrganization: selectedOrganization });
    }

    markNotificationAsRead = async (notificationId, callback) => {
        const { platformNotifications } = this.state;
        const orgId = this.props.match && this.props.match.params.organization;

        await API().patch(`Organizations/${orgId}/platformnotifications/${notificationId}`, { isRead: true })
            .then((resData) => {

                if (resData) {
                    let updatedNotifications = platformNotifications;
                    updatedNotifications = updatedNotifications.map((n) => {
                        if (n._id === notificationId) {
                            n.isRead = true;
                        }
                        return n;
                    });
                    this.setState({ platformNotifications: updatedNotifications }, () => {

                        callback && callback();
                    });
                }


            })
            .catch((err) => {
                console.log(err);
            });

    }

    render() {
        const orgId = this.props.match && this.props.match.params.organization;
        const {
            account,
            selectedOrganization,
            organizations,
            permission,
            loading,
            subscriptionAddons,
            subscriptionType
        } = this.state;
        const adminPermission = permission === 'admin' || permission === 'owner';
        let programsAvailable = subscriptionType === 'Enterprise Premium' || false;
        let accountPagesAvailable = subscriptionType === 'Enterprise Premium' || false;
        if (!programsAvailable || !accountPagesAvailable) {
            subscriptionAddons.forEach((sa) => {
                if (sa.identifier === 'requests') {
                    programsAvailable = true;
                } else if (sa.identifier === 'account-pages') {
                    accountPagesAvailable = true;
                }
            });
        }

        return (<Fragment>

            {!loading && <Switch>

                <Route path="/:organization/events/:event" render={(matchprops) => (
                    <EventLevelLayout
                        {...matchprops}
                        getSectionCounts={this.getSectionCounts}
                        account={account}
                        orgId={orgId}
                        organization={selectedOrganization}
                        organizations={organizations}
                        subscriptionType={subscriptionType}
                        subscriptionAddons={subscriptionAddons}
                        pingServer={this.pingServer}
                        permission={adminPermission}
                        eventPermissions={this.state.eventPermissions}
                        topLevel={true}
                        logout={this.logout}
                        programsAvailable={programsAvailable}
                        socket={socket}
                        taskCount={this.state.taskCount}
                        updateTaskCount={this.updateTaskCount}
                        singleUserSectionInUse={this.state.singleUserSectionInUse}
                        platformNotifications={this.state.platformNotifications}
                        markNotificationAsRead={this.markNotificationAsRead}
                    />
                )}
                />

                {programsAvailable && <Route path="/:organization/programs/:program" render={(matchprops) => (
                    <ProgramLevelLayout
                        {...matchprops}
                        getSectionCounts={this.getSectionProgramCounts}
                        account={account}
                        orgId={orgId}
                        organization={selectedOrganization}
                        organizations={organizations}
                        subscriptionType={subscriptionType}
                        subscriptionAddons={subscriptionAddons}
                        pingServer={this.pingServer}
                        permission={adminPermission}
                        topLevel={true}
                        logout={this.logout}
                        programsAvailable={programsAvailable}
                        socket={socket}
                        taskCount={this.state.taskCount}
                        updateTaskCount={this.updateTaskCount}
                        platformNotifications={this.state.platformNotifications}
                        markNotificationAsRead={this.markNotificationAsRead}

                    />
                )}
                />}

                <Route path="/:organization/:module" render={(matchprops) => (
                    <TopLevelLayout
                        {...matchprops}
                        account={account}
                        orgId={orgId}
                        getSectionCounts={this.getSectionCounts}
                        organization={selectedOrganization}
                        organizations={organizations}
                        subscriptionType={subscriptionType}
                        subscriptionAddons={subscriptionAddons}
                        pingServer={this.pingServer}
                        permission={adminPermission}
                        topLevel={true}
                        logout={this.logout}
                        programsAvailable={programsAvailable}
                        socket={socket}
                        updateTaskCount={this.updateTaskCount}
                        taskCount={this.state.taskCount}
                        platformNotifications={this.state.platformNotifications}
                        markNotificationAsRead={this.markNotificationAsRead}
                    >
                        <Switch>
                            <Route path="/:organization/events" render={(matchprops) => (
                                <LazyEvents
                                    {...matchprops}
                                    account={account}
                                    organization={selectedOrganization}
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                    pingServer={this.pingServer}
                                />
                            )}>

                            </Route>
                            {programsAvailable && <Route path="/:organization/programs">
                                <LazyPrograms
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>}
                            <Route path="/:organization/contacts/:contact">
                                <ContactDetails
                                    permission={adminPermission}
                                    organization={selectedOrganization}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>
                            <Route path="/:organization/contacts">
                                <LazyContacts
                                    permission={adminPermission}
                                    organization={selectedOrganization}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>


                            <Route path="/:organization/pages/:page">
                                <Page
                                    organization={selectedOrganization}
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                    account={account}
                                />
                            </Route>
                            <Route path="/:organization/pages">
                                <LazyPages
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                    account={account}
                                />
                            </Route>
                            <Route exact path="/:organization/forms">
                                <LazyForms
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>


                            <Route path="/:organization/forms/:formId">
                                <Form
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>


                            <Route path="/:organization/media">
                                <AccountFiles
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>

                            <Route path="/:organization/team/:teamMember">
                                <TeamMember
                                    orgId={orgId}
                                    permission={adminPermission}
                                    removeTeamMembers={this.removeTeamMembers}
                                />
                            </Route>

                            <Route path="/:organization/invites/:invite">
                                <Invite
                                    orgId={orgId}
                                    permission={adminPermission}
                                    removeTeamMembers={this.removeTeamMembers}
                                />
                            </Route>
                            <Route path="/:organization/team">
                                <Teams
                                    orgId={orgId}
                                    permission={adminPermission}
                                    removeTeamMembers={this.removeTeamMembers}
                                />
                            </Route>

                            {/* <Route path="/:organization/account">
                                <LazyAccount
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}
                                />
                            </Route>

                            <Route path="/:organization/profile">
                                <Profile
                                    permission={adminPermission}
                                    orgId={orgId}
                                    socket={socket}></Profile>
                            </Route> */}


                            <Route
                                render={() => (
                                    <Redirect
                                        to={{
                                            pathname: `/${orgId}/events`
                                        }}
                                    />
                                )}
                            />
                        </Switch >
                        {/* <TawkMessengerReact
                            propertyId="63f4f49a4247f20fefe1d9bb"
                            widgetId="1gpqdmv3h" /> */}
                    </TopLevelLayout >
                )
                } />

                < Route
                    render={() => (
                        <Redirect
                            to={{
                                pathname: `/${orgId}/events`
                            }}
                        />
                    )
                    }
                />
            </Switch >
            }
            <SpinnerComponent text="Version 4.15" loading={loading} image={Logo} />
        </Fragment >
        );
    }
}

export default withRouter(ApplicationLayout);
