import React, { Fragment } from 'react';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import { withRouter } from 'react-router-dom';
import SelectField from '../../../../components/inputs/selectField';
import ToggleField from '../../../../components/inputs/toggleField';

class CreateFormModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			formType: '',
			confirmationEmailEnabled: false,
			confirmationEmail: '',
			createPage: false,
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false,
				formType: false,
			}
		};
	}

	addForm = () => {
		this.setState({ submitting: true });
		const { eventId, orgId, toggle } = this.props;
		const { name, formType, confirmationEmailEnabled, createPage, confirmationEmail } = this.state;

		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (name === '') {
			valid = false;
			requiredFields.name = true;
		}else{

			requiredFields.name = false;
		}
		if (formType === '') {
			valid = false;
			requiredFields.formType = true;
		}else{
			
			requiredFields.formType = false;
		}
		if (valid) {
			API()
				.post(`Organizations/${orgId}/events/${eventId}/form`, {
					name: name,
					formType: formType.value,
					confirmationEmailEnabled: confirmationEmailEnabled,
					confirmationEmail: confirmationEmail
				})
				.then((res) => {
					if (res.data) {
						this.props.addForm(res.data);
						this.props.history.push(`/${orgId}/events/${eventId}/forms/${res.data._id}`);
						toggle();
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false,
						error: e.request.response._id ? 'Error creating form' : e.request.response
					});
				});
		} else {
			this.setState({
				invalid: true,
				submitting: false,
				error: 'Name is required'
			});
		}
	};

	render() {
		const { name, formType, confirmationEmailEnabled, confirmationEmail, createPage, submitting, invalid, requiredFields } = this.state;
		const { toggle, isOpen } = this.props;
		return (
			<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={"Create Form"}
				bodyHeaderText={''}
				bodyDescription={``}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Name"
								onChange={(e) => this.setState({ name: e.target.value })}
								value={name}
								required={true}
								errorTrigger={requiredFields.name}
							/>
							<SelectField
								label="Form Type"
								required={true}
								errorTrigger={requiredFields.formType}
								options={[
									{ value: 'registration', label: 'Registration' },
									{ value: 'general', label: 'General' },
									{ value: 'custom', label: 'Custom' }
								]}
								onChange={(e) => {
									this.setState({
										formType: e
									});
								}}
								value={formType}

							/>
							<ToggleField
								label="Enable Confirmation Email"
								checked={confirmationEmailEnabled}
								icons={false}
								onChange={(e) => {
									this.setState({
										confirmationEmailEnabled: e.target.checked
									});
								}}
							/>
							{confirmationEmailEnabled && (
								<SelectField
									label="Confirmation Email"
									options={this.props.availableEmails}
									value={this.props.availableEmails.find(
										(e) => e.value == confirmationEmail
									)}
									onChange={(e) => {
										this.setState({
											confirmationEmail: e.value
										});
									}}
								/>
							)}
						</div>

					)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
				cancelButtonLabel={'Cancel'}
				actionButton={this.addForm}
				submitting={submitting}
				actionButtonDisabled={submitting}

			></FieldModal>
		);
	}
}

export default withRouter(CreateFormModal);
