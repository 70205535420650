import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Row, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Edit, Trash2, PlusCircle, Download, Users, Share, User, ChevronRight, ChevronLeft } from 'react-feather';
import EventGroupInfo from './info';
import EventGroupAttendees from './attendees';
import EventGroupSessions from './sessions';
import DeleteGroupModal from './modals/deleteGroupModal';
import API from '../../../utils/API';

import LoadingWizard from '../../../components/spinner/wizard';

import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';

class EventGroup extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = props;
		const group = props.groups.find((g) => g._id == params.groupId);
		this.state = {
			group: group,
			editedGroup: {},
			selectedSection: 0,
			deleteGroupModalOpen: false,
			loading: true,
			editing: false,
			addAttendeeToggle: false,
			importAttendeesToggle: false,
			addSessionToggle: false,
			importSessionsToggle: false,
			deleteAttendeesModalOpen: false,
			checkedAttendees: [],
			deleteSessionsModalOpen: false,
			checkedSessions: [],
			requiredFields: {
				name: false
			}
		};
	}

	//Fetch complete group information and append to current
	componentDidMount() {
		const { group } = this.state;
		this.setState({ loading: true });
		const { eventId, orgId } = this.props;
		this.props.setActiveRow(group._id)
		API().get(`Organizations/${orgId}/events/${eventId}/groups/${group._id}`).then((res) => {
			if (res.data) {
				this.setState({
					group: { ...group, ...res.data },
					editedGroup: { ...group, ...res.data },
					loading: false
				});
			}
		});
	}
	componentWillUnmount() {
		this.props.setActiveRow(null);
	}

	refreshGroup = () => {
		const { group } = this.state;
		const { eventId, orgId } = this.props;

		API().get(`Organizations/${orgId}/events/${eventId}/groups/${group._id}`).then((res) => {
			if (res.data) {
				this.setState({
					group: { ...group, ...res.data },
					editedGroup: { ...group, ...res.data },
					loading: false
				});
			}
		});
	};

	toggleDelete = () => {
		this.setState({ deleteGroupModalOpen: !this.state.deleteGroupModalOpen });
	};

	SectionItem = (title, sectionNo) => {
		return (
			<div
				className={`sectionTab ${sectionNo === this.state.selectedSection ? 'selected' : ''}`}
				onClick={() => {
					this.setState({ selectedSection: sectionNo });
				}}
			>
				<div className="calibreBold fs-30">{title}</div>
			</div>
		);
	};

	updateGroupInfo = (property, value) => {
		const { editedGroup } = this.state;
		editedGroup[property] = value;
		this.setState({ editedGroup: editedGroup, editing: true });
	};

	cancelEdit = () => {
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});

		const g = JSON.parse(JSON.stringify(this.state.group));
		this.setState({
			editedGroup: g,
			editing: false,
			requiredFields: requiredFields
		});
	};

	addAttendees = (attendees) => {
		const { editedGroup, group } = this.state;
		const updatedAttendees = [...editedGroup.attendees, ...attendees];
		editedGroup.attendees = updatedAttendees;
		group.attendees = updatedAttendees;
		this.setState({ group: group, editedGroup: editedGroup });
		this.props.updateGroup(group._id, group);
	};

	addAttendee = (attendee, isNew) => {
		const { editedGroup, group } = this.state;
		const editedAttendees = editedGroup.attendees;
		if (!editedAttendees.find((a) => a._id == attendee._id)) editedAttendees.push(attendee);
		group.attendees = editedAttendees;
		editedGroup.attendees = editedAttendees;
		if (isNew) this.props.addNewAttendee(attendee);
		this.setState({ group: group, editedGroup: editedGroup });
		this.props.updateGroup(group._id, group);
	};

	removeAttendees = (attendees) => {
		const { editedGroup, group } = this.state;
		const updatedAttendees = editedGroup.attendees.filter((a) => !attendees.includes(a._id));
		editedGroup.attendees = updatedAttendees;
		group.attendees = updatedAttendees;
		this.updatedCheckedAttendees([]);
		this.setState({ group: group, editedGroup: editedGroup, checkedAttendees: [] });
		this.props.updateGroup(group._id, group);
	};

	removeSessions = (sessions) => {
		const { group, editedGroup } = this.state;
		const editedSessions = editedGroup.sessions.filter((s) => !sessions.includes(s._id));
		group.sessions = editedSessions;
		editedGroup.sessions = editedSessions;
		this.updatedCheckedSessions([]);
		this.setState({ group: group, editedGroup: editedGroup, checkedSessions: [] });
		this.props.updateGroup(group._id, group);
	};

	addSession = (session, isNew) => {
		const { group, editedGroup } = this.state;
		const editedSessions = editedGroup.sessions;
		if (!editedSessions.find((s) => s._id == session._id)) editedSessions.push(session);
		group.sessions = editedSessions;
		editedGroup.sessions = editedSessions;
		if (isNew) this.props.addNewSession(session);
		this.setState({ group: group, editedGroup: editedGroup });
		this.props.updateGroup(group._id, group);
	};

	addSessions = (sessions) => {
		const { editedGroup, group } = this.state;
		const updatedSessions = [...editedGroup.sessions, ...sessions];
		editedGroup.sessions = updatedSessions;
		group.sessions = updatedSessions;
		this.setState({ group: group, editedGroup: editedGroup });
		this.props.updateGroup(group._id, group);
	};

	saveInfo = () => {
		const { editedGroup } = this.state;

		const { orgId, eventId } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedGroup[key] || editedGroup[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			API()
				.patch(`Organizations/${orgId}/events/${eventId}/groups/${editedGroup._id}`, {
					name: editedGroup.name,
					maxCapacityEnabled: editedGroup.maxCapacityEnabled,
					maxCapacity: editedGroup.maxCapacity
				})
				.then((res) => {
					if (res.data) {
						this.setState({ editing: false, group: { ...res.data } });
						this.props.updateGroup(editedGroup._id, editedGroup);
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({
						invalid: true,
						editing: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};
	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	renderSection() {
		const {
			addAttendeeToggle,
			importAttendeesToggle,
			addSessionToggle,
			importSessionsToggle,
			selectedSection,
			checkedAttendees,
			deleteAttendeesModalOpen,
			checkedSessions,
			deleteSessionsModalOpen
		} = this.state;

		let mainAction = [];

		switch (selectedSection) {
			case 1:
				mainAction = [
					{ onClick: this.toggleAddAttendees, label: 'Add Attendee' },
					{ onClick: this.toggleImportAttendees, label: 'Import Attendees' }
				];
				break;
			case 2:
				mainAction = [
					{ onClick: this.toggleAddSessions, label: 'Add Session' },
					{ onClick: this.toggleImportSessions, label: 'Import Sessions' }
				];
				break;

			default:
				mainAction = [];
		}

		let batchActions = [];

		switch (selectedSection) {
			case 1:
				batchActions = [
					{
						type: 'button',
						label: 'Delete Attendees',
						onClick: this.toggleDeleteAttendees,
						iconClass: 'las la-trash-alt',
						class: 'danger'
					}
				];
				break;
			case 1:
				batchActions = [
					{
						type: 'button',
						label: 'Delete Sessions',
						onClick: this.toggleDeleteSessions,
						iconClass: 'las la-trash-alt',
						class: 'danger'
					}
				];
				break;
			default:
				batchActions = [];
		}

		switch (this.state.selectedSection) {
			case 0:
				return (
					<EventGroupInfo
						editedGroup={this.state.editedGroup}
						updateGroupInfo={this.updateGroupInfo}
						readOnly={this.props.readOnly}
						requiredFields={this.state.requiredFields}
					/>
				);
			case 1:
				return (
					<EventGroupAttendees
						editedGroup={this.state.editedGroup}
						event={this.props.event}
						index={this.props.index}
						orgId={this.props.orgId}
						loadMoreAttendees={this.props.loadMoreAttendees}
						addAttendees={this.addAttendees}
						removeAttendees={this.removeAttendees}
						updateGroup={this.refreshGroup}
						readOnly={this.props.readOnly}
						addAttendee={this.addAttendee}
						addAttendeeToggle={addAttendeeToggle}
						importAttendeesToggle={importAttendeesToggle}
						toggleAddAttendees={this.toggleAddAttendees}
						toggleImportAttendees={this.toggleImportAttendees}
						toggleRemoveAttendees={this.toggleDeleteAttendees}
						batchActions={batchActions}
						updateChecked={this.updatedCheckedAttendees}
						checkedAttendees={checkedAttendees}
						deleteAttendeesModalOpen={deleteAttendeesModalOpen}
					/>
				);
			case 2:
				return (
					<EventGroupSessions
						editedGroup={this.state.editedGroup}
						event={this.props.event}
						index={this.props.index}
						orgId={this.props.orgId}
						loadMoreSessions={this.props.loadMoreSessions}
						addSessions={this.addSessions}
						removeSessions={this.removeSessions}
						readOnly={this.props.readOnly}
						addSessionToggle={addSessionToggle}
						importSessionsToggle={importSessionsToggle}
						toggleAddSessions={this.toggleAddSessions}
						toggleImportSessions={this.toggleImportSessions}
						toggleRemoveSessions={this.toggleDeleteSessions}
						batchActions={batchActions}
						updateChecked={this.updatedCheckedSessions}
						checkedSessions={checkedSessions}
						deleteSessionsModalOpen={deleteSessionsModalOpen}
					/>
				);
			default:
		}
	}

	toggleAddAttendees = () => {
		this.setState({ addAttendeeToggle: !this.state.addAttendeeToggle });
	};
	toggleImportAttendees = () => {
		this.setState({ importAttendeesToggle: !this.state.importAttendeesToggle });
	};
	toggleDeleteAttendees = () => {
		this.setState({
			deleteAttendeesModalOpen: !this.state.deleteAttendeesModalOpen
		});
	};

	toggleDeleteSessions = () => {
		this.setState({
			deleteSessionsModalOpen: !this.state.deleteSessionsModalOpen
		});
	};

	toggleAddSessions = () => {
		this.setState({ addSessionToggle: !this.state.addSessionToggle });
	};
	toggleImportSessions = () => {
		this.setState({ importSessionsToggle: !this.state.importSessionsToggle });
	};

	updatedCheckedAttendees = (checked) => {
		this.setState({ checkedAttendees: checked });
	};

	updatedCheckedSessions = (checked) => {
		this.setState({ checkedSessions: checked });
	};

	render() {
		const { group, deleteGroupModalOpen, loading, editing, selectedSection, deleteAttendeesModalOpen } = this.state;
		const { eventId, event } = this.props;

		let mainAction = [];

		switch (selectedSection) {
			case 1:
				mainAction = [
					{ onClick: this.toggleAddAttendees, label: 'Add Attendee' },
					{ onClick: this.toggleImportAttendees, label: 'Import Attendees' }
				];
				break;
			case 2:
				mainAction = [
					{ onClick: this.toggleAddSessions, label: 'Add Session' },
					{ onClick: this.toggleImportSessions, label: 'Import Sessions' }
				];
				break;

			default:
				mainAction = [];
		}

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;

		switch (selectedSection) {
			case 1:
				batchActions = [
					{
						type: 'button',
						label: 'Delete Attendees',
						onClick: this.toggleDeleteAttendees,
						iconClass: 'las la-trash-alt',
						class: 'danger'
					}
				];
				checkedEntryCount = Object.keys(this.state.checkedAttendees).length;
				entriesLength = group.attendees.length;
				uncheckEntries = () => {
					this.setState({ checkedAttendees: [] });
				};
				break;
			case 2:
				batchActions = [
					{
						type: 'button',
						label: 'Delete Sessions',
						onClick: this.toggleDeleteSessions,
						iconClass: 'las la-trash-alt',
						class: 'danger'
					}
				];
				checkedEntryCount = Object.keys(this.state.checkedSessions).length;
				entriesLength = group.sessions.length;
				uncheckEntries = () => {
					this.setState({ checkedSessions: [] });
				};
				break;
			default:
				batchActions = [];
		}
		return (
			<div className="sectionContainer ">
				<SectionHeader
					editing={editing}
					title={group.name}
					description={"Insightful information about the group**"}
					enableLastLink={true}
					/* breadcrumbs={[
						{
							name: event.name
						},
						{
							name: 'Groups'
						}
					]} */
					subActions={[{ onClick: this.toggleDelete, label: <span><i className='las la-trash mr-10'></i>Delete Group</span> }]}
					closeButton={true}
				>
					<TabsContainer
						tabs={[{ title: 'Information' }, { title: 'Attendees' }, { title: 'Sessions' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/></SectionHeader>
				<div className="sectionBody">
					{!loading && this.renderSection()}
				</div>
				<LoadingWizard text="LOADING GROUP" loading={loading} />
				<SectionFooter saveEdit={this.saveInfo} cancelEdit={this.cancelEdit} updatedAt={group.updatedAt} editing={editing} />
				{deleteGroupModalOpen && <DeleteGroupModal
					isOpen={deleteGroupModalOpen}
					toggle={this.toggleDelete}
					group={group}
					submit={this.props.deleteGroup}
				/>}
			</div>
		);
	}
}

export default withRouter(EventGroup);
