import React, { Component } from 'react';
import SelectField from './selectField';
import zipcode_to_timezone from 'zipcode-to-timezone'

var service;

class LocationLookup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            searching: false,
            options: []
        }
        service = new window.google.maps.places.PlacesService(document.createElement('div'));
    }

    searchPlaces = () => {
        const { search } = this.state;
        var request = {
            query: search,
            fields: ['name', 'formatted_address', 'place_id'],
        };


        this.setState({ searching: true });
        service && service.findPlaceFromQuery(request, (results, status) => {
            if (results) {
                const options = results.map(r => {
                    return {
                        value: r.place_id,
                        label: `${r.name} - ${r.formatted_address}`,
                    }
                });
                const googleOptions = {
                    label: 'Google Results',
                    options: options
                }

                const existingLocationOptions = {
                    label: 'Existing Locations',
                    options: this.props.locations
                }
                this.setState({ options: [googleOptions, existingLocationOptions], searching: false });
            } else {
                this.setState({ options: [], searching: false });
            }
        });
    }


    onChange = async (selectedPlace) => {
        this.setState({ search: "" });
        if (!selectedPlace.platform) {
            const fields = ['name', 'address_components', 'utc_offset_minutes'];
            service && service.getDetails({ placeId: selectedPlace.value, fields: fields }, (place, status) => {
                if (place) {
                    const addressObject = place.address_components.reduce((obj, component) => {
                        obj[component.types[0]] = component.long_name;
                        return obj;
                    }, {});
                    const zipcode = addressObject.postal_code;
                    const timezone = zipcode_to_timezone.lookup(zipcode);
                    const formattedAddressObject = {
                        address1: addressObject.street_number + ' ' + addressObject.route,
                        address2: addressObject.subpremise,
                        city: addressObject.locality,
                        state: addressObject.administrative_area_level_1,
                        zip: addressObject.postal_code,
                        country: addressObject.country,
                        timezone: timezone
                    }
                    this.props.onSelect({ ...selectedPlace, name: place.name, address: formattedAddressObject, timezone: timezone });
                }
            });
        } else {
            this.props.onSelect(selectedPlace);
        }
    }

    render() {
        const { search, options, searching } = this.state;
        const {
            selectedPlace,
            label,
            placeholder,
            style,
            classes,
            name,
            required,
            errorTrigger,
            noOptionsMessage
        } = this.props;
        return (
            <div className={`${classes}`} style={style}>
                <SelectField
                    label={label}
                    value={selectedPlace && search == "" ? selectedPlace : { value: search, label: search != '' ? search : placeholder }}
                    placeholder={placeholder}
                    name={name}
                    classes={classes}
                    options={options}
                    required={required}
                    errorTrigger={errorTrigger}
                    onInputChange={(text) => {
                        this.setState({ search: text }, this.searchPlaces);
                    }}
                    noOptionsMessage={() =>
                        !searching ? noOptionsMessage : 'Searching...'}
                    onChange={this.onChange}
                    formatGroupLabel={data => (
                        <h5 className='mb-10'>
                            {data.label}
                        </h5>
                    )}
                    disableFilter={true}
                />
            </div>
        );
    }
}

export default LocationLookup;

