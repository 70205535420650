import React from 'react';
import API from '../../utils/API';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import FieldModal from './fieldModal';

class ConvertCustomFieldModal extends React.Component {
	constructor(props) {
		super(props);
		const fieldIds = this.props.field.includes('*') ? this.props.field.split('*') : this.props.field;
		console.log(fieldIds);
		this.state = {
			submitting: false,
			fieldName: props.fieldName,
			categoryId: fieldIds[0],
			fieldId: fieldIds[1],
			requiredFields: {
				fieldName: false
			}
		};
	}

	convertCustomField = async () => {
		this.setState({ submitting: true });
		const { orgId } = this.props;
		const { categoryId, fieldId } = this.state;
		this.setState({ submitting: true });

		const endpoint = this.props.eventId
			? `Organizations/${orgId}/events/${this.props.eventId}/customFields`
			: this.props.programId ? `Organizations/${orgId}/programs/${this.props.programId}/customFields`
				: `Organizations/${orgId}/customFields`;
		await API()
			.patch(endpoint, {
				section: this.props.section,
				categoryId: categoryId,
				fieldId: fieldId,
				fieldType: 'text'
			})
			.then((res) => {
				if (res.data) {
					this.props.convertField(res.data, this.props.toggle);
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting } = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Convert Column"}
					bodyHeaderText={<span>You are converting this field into a text field, <b>this is irreversible</b>. Are you sure you
						want to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Convert' : 'Converting'}
					actionButton={() => (this.convertCustomField())}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>
		);
	}
}

export default ConvertCustomFieldModal;
