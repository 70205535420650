import React, { Fragment } from 'react';

import FieldModal from '../../modals/fieldModal';
import API from '../../../utils/API';


class DeleteLocationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            complete: false,
            venue: props.venue || '',
            location: props.location || ''

        };
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    submit = async () => {
        const { deleteLocation, venue, location } = this.props;
        this.setState({ submitting: true })

        await API().delete(`Organizations/${this.props.orgId}/venues/${venue._id}/locations/${location._id}`).then((res) => {

            deleteLocation(venue._id, location._id);
            this.setState({ submitting: false, complete: true })

        }).catch((e) => {
            console.log('error', e);

        });



    }

    render() {
        const { isOpen, toggle, location } = this.props;
        const { name, submitting, requiredFields, complete, venue } = this.state;
        let venueName = venue ? venue.name : '';
        let locationName = location ? location.name : '';
        return (


            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Location"}
                    bodyHeaderText={<span>You are about to delete <b>{locationName}</b> from <b>{venueName}</b> venue from the
                        organization. This is irreversible. This will location association from sessions </span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting && !complete ? 'Delete' : submitting && !complete ? 'Deleting...' : complete ? '' : ''}
                    actionButton={() => this.submit()}
                    actionButtonDisabled={submitting || complete}
                    cancelButtonLabel={complete ? 'Close' : 'Cancel'}
                    isDelete={true}


                ></FieldModal>
            </div>

        );
    }
}

export default DeleteLocationModal;
