import React, { Component, Fragment } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

class EventSentEmails extends Component {
	EmailItem(email, index) {
		return (
			<div className="tableColumnRow">
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">
					{email.attendeeFirstName}
				</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">
					{email.attendeeLastName}
				</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">{email.attendeeEmail}</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">{email.status}</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">{email.sentDate}</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">
					{moment(email.updatedAt).format('MM/DD/YY hh:mm:ss a')}
				</div>
			</div>
		);
	}

	render() {
		const { email } = this.props;
		return (
			<div className="sectionBody">
				<div className="sectionTable">
					<div className="tableSubColumns">
						<div className="tableColumnHeader">
							<div className="tableSubColumnNoSort main">
								<div className="tableCellData">
									<div className="tableColumnTitle">First Name</div>
								</div>
							</div>

							<div className="tableSubColumnNoSort main">
								<div className="tableCellData">
									<div className="tableColumnTitle">Last Name</div>
								</div>
							</div>

							<div className="tableSubColumnNoSort main">
								<div className="tableCellData">
									<div className="tableColumnTitle">Email Address</div>
								</div>
							</div>

							<div className="tableSubColumnNoSort main">
								<div className="tableCellData">
									<div className="tableColumnTitle">Status</div>
								</div>
							</div>

							<div className="tableSubColumnNoSort main">
								<div className="tableCellData">
									<div className="tableColumnTitle">Sent Date</div>
								</div>
							</div>
							<div className="tableSubColumnNoSort main">
								<div className="tableCellData">
									<div className="tableColumnTitle">Last Updated</div>
								</div>
							</div>
						</div>

						<div className="tableColumnRows w-fc">
							{email.sentEmails.map((email, index) => this.EmailItem(email, index))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EventSentEmails;
