import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
let countdownInterval = null;

const experienceTypeMap = {
	'in-person': 'In-Person',
	'virtual': 'Virtual',
	'hybrid': 'Hybrid'
}
class Overview extends Component {
	state = {
		attendeeActivity: [],
		timeRemaining: {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		}
	};
	componentWillMount() {
		countdownInterval = setInterval(this.getEventCountdown, 500);
	}

	componentWillUnmount() {
		clearInterval(countdownInterval);
	}

	getEventCountdown = () => {
		const { event } = this.props;
		const startDateString = `${event.startDate} ${event.startTime}`;
		const eventDateTime = new Date(startDateString).getTime();
		const currentDateTime = new Date().getTime();
		const timeRemaining = eventDateTime > currentDateTime ? eventDateTime - currentDateTime : 0;
		this.setState({
			timeRemaining: {
				days: Math.floor(timeRemaining / 1000 / 60 / 60 / 24) % 365,
				hours: Math.floor(timeRemaining / 1000 / 60 / 60) % 24,
				minutes: Math.floor(timeRemaining / 1000 / 60) % 60,
				seconds: Math.floor(timeRemaining / 1000) % 60
			}
		});
	};

	render() {
		const { event, sectionName, organization, venue, permittedEvent } = this.props;
		const { timeRemaining, attendeeActivity } = this.state;
		const detailsComplete =
			event.name !== '' &&
			event.nameSlug &&
			event.nameSlug !== '' &&
			event.startDate !== '' &&
			event.endDate !== '' &&
			event.startTime !== '' &&
			event.endTime !== '' &&
			event.timezone &&
			event.timezone !== '' &&
			event.venue &&
			event.venue !== '';

		const accessComplete =
			event.enableMeetingPassword ||
			event.enablePassthroughPassword ||
			event.enableSSOLogin ||
			event.allowPublicRegistrations;

		const eventStarted =
			timeRemaining.days === 0 &&
			timeRemaining.hours === 0 &&
			timeRemaining.minutes === 0 &&
			timeRemaining.seconds === 0;

		const { name, startDate, endDate, startTime, endTime } = event;
		const start = moment(startDate);
		const end = moment(endDate);
		const startHour = startTime ? moment(startTime, 'HH:mm:ss a').format('hh:mm A') : '';
		const endHour = endTime ? moment(endTime, 'HH:mm:ss a').format('hh:mm A') : '';

		//format date range as Friday, March 3, 2023 8:00AM - Sunday, March 5, 2023 5:00PM, account for same day events and proper time formatting and timezone
		let dateTimeRangeString = '';
		if (start.isSame(end, 'day')) {
			dateTimeRangeString = `${start.format('dddd, MMMM Do, YYYY')} ${startHour} - ${endHour}`;
		} else {
			dateTimeRangeString = `${start.format('dddd, MMMM Do, YYYY')} ${startHour} - ${end.format('dddd, MMMM Do, YYYY')} ${endHour}`;
		}
		//append timezone abbreviation from moment with event timezone string
		dateTimeRangeString += ` ${start.tz(event.timezone)?.format('z')}`;



		const branding = {};
		Object.keys(organization.branding).forEach((key) => {
			branding[key] = event.branding[key] && event.branding[key] !== '' ? event.branding[key] : organization.branding[key];
		});

		const experienceType = experienceTypeMap[event.experienceType] || 'In-Person';

		return (
			<div className="sectionContainer" style={{
				marginTop: -20, marginLeft: -30, width: 'calc(100% + 60px)',
				height: 'calc(100% + 20px)'
			}}>
				<div className="sectionBody p-0 noFooter">
					{/* <div className='w-100 flex aic jcsb mb-20'>
						<div className='flex aic'>
							<div className='mr-25'>
								<img className='w-100 h-100' style={{ maxHeight: 35, maxWidth: 200 }} src={branding.logo} />
								<div className='flex aic' style={{ marginTop: 5, marginBottom: -5 }}>
									<div className='bar neu' style={{ backgroundColor: branding.accentColor, marginRight: 2 }}></div>
									<div className='bar neu' style={{ backgroundColor: branding.fontColor, marginRight: 2 }}></div>
									<div className='bar neu' style={{ backgroundColor: branding.backgroundColor }}></div>
								</div>
							</div>
							{permittedEvent.permissions.details.read && <Link to="./details?tab=branding">
								<button className='neu min mr-25'>Update Branding</button>
							</Link>}
						</div>
						<div className='flex aic'>

							{permittedEvent.permissions.pages.read && <Link to="./pages">
								<button className='neu min'>Manage Event Pages</button>
							</Link>}
							{permittedEvent.permissions.forms.read && <Link to="./forms">
								<button className='prim min ml-25'>Manage Registration</button>
							</Link>}
						</div>
					</div> */}
					<div className='pageBanner posRel white' style={{ backgroundImage: `linear-gradient(135deg, rgb(0 0 0 / 20%) , rgb(0 0 0 / 20%)), url('${branding.headerImage}')` }}>
						<h1>{event.name}</h1>
						<h4 className='mb-20'>{dateTimeRangeString}</h4>
						{experienceType != 'virtual' && venue && venue.address && <h4>
							{venue.address.address1}
							{venue.address.address2 && <Fragment> {venue.address.address2}</Fragment>}
							{venue.address.city && <Fragment>, {venue.address.city}</Fragment>}
							{venue.address.state && <Fragment>, {venue.address.state}</Fragment>}
						</h4>}
						<h3>{experienceType} Experience{venue && <Fragment><span className='mr-10 ml-10'>|</span>{venue.name}</Fragment>}</h3>
						<p className='mb-20'>{event.eventType}</p>
						{!eventStarted && (
							<Fragment>
								<h4>Starting in</h4>
								<div className="flex aic w-fc mb-20">
									<div className="countdownTime mr-25 text-c">
										<h4 style={{ fontSize: '200%' }}>{timeRemaining.days}</h4>
										<h5>DAY{timeRemaining.days == 1 ? '' : 's'}</h5>
									</div>
									<div className="countdownTime mr-25 text-c">
										<h4 style={{ fontSize: '200%' }}>{timeRemaining.hours}</h4>
										<h5>HOUR{timeRemaining.hours == 1 ? '' : 's'}</h5>
									</div>
									<div className="countdownTime mr-25 text-c">
										<h4 style={{ fontSize: '200%' }}>{timeRemaining.minutes}</h4>
										<h5>MINUTE{timeRemaining.minutes == 1 ? '' : 's'}</h5>
									</div>
									<div className="countdownTime text-c">
										<h4 style={{ fontSize: '200%' }}>{timeRemaining.seconds}</h4>
										<h5>SECOND{timeRemaining.seconds == 1 ? '' : 's'}</h5>
									</div>

								</div>
							</Fragment>)}
						{/* <div className='flex aic wrap'>
							{permittedEvent.permissions.details.read && <Link to="./details">
								<button className='neu min mr-25 mb-10'>Edit Details</button>
							</Link>}
							{event.eventType == 'public' ? <Fragment>
								{!event.registrationSetup ? (permittedEvent.permissions.attendees.read && <Link to="./attendees">
									<button className='prim min mr-25 mb-10'>Set up Registration</button>
								</Link>) : !event.inviteSetup ? (permittedEvent.permissions.attendees.read && <Link to="./attendees">
									<button className='prim min mr-25 mb-10'>Create Invitation Email</button>
								</Link>) : (permittedEvent.permissions.attendees.read && <Link to="./attendees">
									<button className='prim min mr-25 mb-10'>Invite Attendees</button>
								</Link>)}
							</Fragment> : <Fragment>
								{permittedEvent.permissions.attendees.read && <Link to="./attendees">
									<button className='prim min mr-25 mb-10'>Manage Attendees</button>
								</Link>}
							</Fragment>}

						</div> */}
					</div>
					<div className='mt-50 mb-50 text-c'>
						<h1>Progress Overview</h1>
						<p>Keep the amazing experience running at full speed.</p>
					</div>
					<div className='flex jcc wrap mb-20'>
						{permittedEvent.permissions.attendees.write && event.attendees.length == 1 && <div className='neuCard centered mr-25 p-20 w-250 text-c mb-20'>
							<h2 style={{ fontSize: '200%' }} className="mb-10">1</h2>
							<h3>Attendee</h3>
							<p className='mb-20'>Add attendees by importing from file or past event</p>
							<Link className=' mt-a w-100' to="./attendees">
								<button className='prim w-100'>Add Attendees</button>
							</Link>
						</div>}
						{permittedEvent.permissions.emails.write && event.attendees.length == 1 && <div className='neuCard centered mr-25 p-20 w-250 text-c mb-20'>
							<h2 style={{ fontSize: '200%' }} className="mb-10">0</h2>
							<h3 >Attendees Invited</h3>
							<p className='mb-20'>Create an email campaign to send invitations</p>
							<Link className=' mt-a w-100' to="./emails">
								<button className='prim w-100'>Compose Email</button>
							</Link>
						</div>}
						{permittedEvent.permissions.schedule.write && event.sessions.length == 0 && <div className='neuCard centered mr-25 p-20 w-250 text-c mb-20'>
							<h2 style={{ fontSize: '200%' }} className="mb-10">0</h2>
							<h3>Sessions Planned</h3>
							<p className='mb-20'>Plan out the event to the minute</p>
							<Link className=' mt-a w-100' to="./schedule">
								<button className='prim w-100'>Setup Schedule</button>
							</Link>
						</div>}
						{permittedEvent.permissions.groups.write && <div className='neuCard centered p-20  mr-25 w-250 text-c mb-20'>
							<h2 style={{ fontSize: '200%' }} className="mb-10">{event.groups.length}</h2>
							<h3 >Group{event.groups.length == 1 ? '' : 's'} Created</h3>
							<p className='mb-20'>
								Group attendees and sessions together to organize group schedules
							</p>
							<Link className=' mt-a w-100' to="./groups">
								<button className='prim w-100'>{event.groups.length == 0 ? 'Create' : 'Manage'} Group{event.groups.length == 0 ? '' : 's'}</button>
							</Link>
						</div>}
					</div>
				</div>
			</div>
		);
	}
}

export default Overview;
