import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import FieldModal from '../../../../components/modals/fieldModal';
import InputField from '../../../../components/inputs/inputField';

class DuplicateCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.notification.name ? this.props.notification.name.concat(' Copy') : '',
			submitting: false,
			requiredFields: { name: false }
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	duplicateCampaign = async () => {
		this.setState({ submitting: true });
		const { name } = this.state;
		const { eventId, orgId, notification } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		if (!name) {
			requiredFields.name = true;
			valid = false;
		}

		if (valid) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/notification`, { name: name, duplicateId: notification._id })
				.then((res) => {
					const notificationObj = res.data;

					if (res.data) {
						window.open(`/${orgId}/events/${eventId}/notifications/${notificationObj._id}`, '_self');
					} else {
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, requiredFields } = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Duplicate Campaign"}
					bodyHeaderText={'Enter a new name for your campaign.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Name"
									value={name}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Updating...'}
					actionButton={() => this.duplicateCampaign()}
					actionButtonDisabled={submitting}
				></FieldModal>


			</div>

		);
	}
}

export default DuplicateCampaignModal;
