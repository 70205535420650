import React, { Component } from 'react';
import { Trash2 } from 'react-feather';
import DeletePointsModal from './modals/deletePointsModal';
import AddPointsModal from './modals/AddPointsModal';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';

class EventAttendeePoints extends Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name',
			value: 'Value',
			referenceName: 'Reference Name',
			referenceLink: 'Reference'
		};
		const columnTypes = {
			name: 'text',
			value: 'text',
			referenceName: 'text',
			referenceLink: 'link'
		};
		const columnWidths = {
			name: 200,
			value: 100,
			referenceName: 250,
			referenceLink: 150
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			showAddPointsModal: false,
			showRemovePointsModal: false,
			markedPoint: null,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedPoints: [],
			columnWidths: columnWidths
		};
	}

	componentDidMount() {
		this.setState({ showAddPointsModal: this.props.addPointsToggle });
	}

	toggleRemovePoint = (point) => {
		this.setState({
			showRemovePointsModal: !this.state.showRemovePointsModal
		});
	};

	showSessionReference = (actionName) => {

		switch (actionName) {
			case 'arrived20to3MinsEarly':
			case 'attendedSession':
			case 'sessionCheckin':
			case 'submittedReview':
			case 'askedSessionQuestion':
				return true;
				break;
			default:
				return false;
				break;
		}

	}

	showSurveyReference = (actionName) => {

		/* uploadToFeed: //event.eventFeed._id
		chatParticipation:
		likeFeed: //AttendeeFeed._id
		scanAttendee: //attendee._id
		downloadVCard: //attendee._id
		formSubmit: //form._id
		eventCheckin: //event._id
		chatSent:	//chatRoom._id
		submitCorrectPollAnswer: //poll._id
		installApp: //attendee._id */


		switch (actionName) {
			case 'surveyCorrect100':
			case 'surveyCorrect90':
			case 'surveyCorrect80':
			case 'surveyCorrect70':
			case 'surveyCorrect60':
			case 'surveyCorrect50':
			case 'surveyCorrect40':
			case 'surveyCorrect30':
			case 'surveyCorrect20':
			case 'surveyCorrect10':
			case 'completedSurvey':
				return true;
				break;
			default:
				return false;
				break;
		}

	}

	PointItem(point, index) {
		const { readOnly } = this.props;
		return (
			<tr key={index}>
				<td className="text-truncate">{point.name}</td>
				<td className="text-truncate">{point.value}</td>
				<td style={{ width: '200px', textAlign: 'right' }}>
					<Trash2
						size={14}
						color="red"
						onClick={() => this.toggleRemovePoint(point, index)}
						className={`clickable ml-a ${readOnly ? 'disabled-link' : ''}`}
					/>
				</td>
			</tr>
		);
	}

	toggleAddPoints = () => {
		this.setState(
			{
				showAddPointsModal: true
			},
			() => {
				this.props.toggle();
			}
		);
	};
	addPointsToggle = () => {
		this.setState({
			showAddPointsModal: !this.state.showAddPointsModal
		});
	};

	updatedCheckedPoints = (checked) => {
		this.setState({ checkedPoints: checked });
	};

	render() {
		const { showRemovePointsModal, showAddPointsModal, normalizedColumns, toggledColumns } = this.state;
		const { points, sessions, orgId, eventId } = this.props;
		let pointsData = points.map((point, index) => {
			if (this.showSessionReference(point.name)) {
				const session = sessions.find((s) => s._id == point.referenceId);
				//if session is undefined, it means the session has been deleted
				const link = session ? `/${orgId}/events/${eventId}/schedule/${session._id}` : null;
				return {
					...point,
					referenceLink: link,
					referenceName: session ? session.name : '',
					deletedSession: session ? session.deleted : true
				};

			} else if (this.showSurveyReference(point.name)) {
				const session = sessions.find((s) => s.surveyId == point.referenceId);
				const link = session ? `/${orgId}/events/${eventId}/schedule/${session._id}` : null;

				return {
					...point,
					referenceLink: link,
					referenceName: session ? session.name : '',
					deletedSession: session ? session.deleted : true

				};

			}
			return {
				...point,
				referenceLink: null,
				deletedSession: false
			};
		}
		).filter((point) => !point.deletedSession);
		return (
			<div className='sectionBody posRel mb-0 p-0'>
				<ActionButtonContainer
					mainActions={[{ onClick: this.addPointsToggle, label: 'Add Points' }]}
					batchActions={[]}
					checkedEntryCount={''}
					entriesLength={''}
					uncheckEntries={() => {

					}}
				/>
				<ViewsTable
					title={"Virtual Engagement"}
					style={{ margin: 0 }}
					classes="nested"
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={pointsData}
					mainColumnActive={false}
					checked={this.state.checkedPoints}
					columnWidths={this.state.columnWidths}
					checkRow={(entryId) => {
						const checked = this.state.checkedPoints;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedPoints(checked);
						this.setState({
							checkedPoints: checked
						});
					}}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedPoints;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedPoints(checked);

						this.setState({
							checkedPoints: checked
						});
					}}
					sortData={(column, sortAsc, sortDesc) => {
						/* const sortedData = this.sortData(
							column,
							sortAsc,
							sortDesc,
							editedGroup.sessions,
							normalizedColumns
						);
						this.setState({ editedGroup: { ...editedGroup, sessions: sortedData.organizedEntries } }); */
					}}
				/>
				<ActionButtonContainer
					mainActions={[]}
					batchActions={[
						{
							type: 'button',
							label: 'Delete Points',
							onClick: this.toggleRemovePoint,
							iconClass: 'las la-trash-alt',
							class: 'danger'
						}
					]}
					checkedEntryCount={Object.keys(this.state.checkedPoints).length}
					entriesLength={points.length}
					uncheckEntries={() => {
						this.setState({ checkedPoints: [] });
					}}
				/>
				{showRemovePointsModal && (
					<DeletePointsModal
						isOpen={showRemovePointsModal}
						toggle={this.toggleRemovePoint}
						points={points}
						delete={(pointIds, callback) => {
							this.props.deletePoints(pointIds, () => {
								callback();
							});
						}}
						checkedPoints={Object.keys(this.state.checkedPoints)}
						uncheckEntries={() => {
							this.setState({ checkedPoints: [] });
						}}
					/>
				)}

				{showAddPointsModal && (
					<AddPointsModal
						toggle={this.addPointsToggle}
						isOpen={showAddPointsModal}
						orgId={this.props.orgId}
						eventId={this.props.eventId}
						interactionPoints={points}
						showSurveyReference={this.showSurveyReference}
						showSessionReference={this.showSessionReference}
						add={(point, referenceId) => {
							this.props.addPoints(point, referenceId, (success) =>
								success && this.setState({ showAddPointsModal: false })
							);
						}}
						sessions={sessions}
					/>
				)}
			</div>
		);
	}
}

export default EventAttendeePoints;
