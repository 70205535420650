import React, { Component } from 'react';

import FieldModal from '../../../components/modals/fieldModal';
import API from '../../../utils/API';

class CopyPageModal extends Component {
	state = {
		title: '',
		description: '',
		copyToOtherEvent: false,
		otherEvent: null,
		submitting: false,
		filteredPages: [],
		processing: false,
		count: 1,
		completed: false
	};

	componentDidMount = async () => {
		const { pages, checkedPages } = this.props;
		let filteredPages = pages.filter((p) => Object.keys(checkedPages).includes(p._id));
		this.setState({ filteredPages: filteredPages });
	};

	addPage = () => {
		this.setState({ submitting: true });
		const { orgId } = this.props;
		const { filteredPages } = this.state;

		filteredPages.forEach((page) => {
			this.setState({ count: this.state.count++, processing: true });

			API()
				.post(`Organizations/${orgId}/accountPages`, {
					title: page.title,
					description: page.description,
					pageId: page._id,
					isTopLevel: true
				})
				.then((res) => {
					if (res.data) {
						this.props.addPage(res.data);
					}
				});
		});

		this.setState({ completed: true });
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, processing, count, filteredPages, completed } = this.state;
		const { checkedPages } = this.props;
		let checkedPagesCount = Object.keys(checkedPages).length;

		return (
			<div>



				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Duplicate Page"}
					bodyHeaderText={`You are about to copy ${checkedPagesCount} pages. Do you want to continue?`}
					bodyDescription={`${(processing || completed) ? `${count} of ${filteredPages.length} processed` : ''}`}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={completed ? 'Completed' : !submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.addPage()}
					actionButtonDisabled={completed || submitting}


				></FieldModal>




			</div >

		);
	}
}

export default CopyPageModal;
