import React, { Component } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import moment from "moment";
import {
	VariableSizeGrid,
	VariableSizeList,
} from "react-window";

const fieldTypeWidths = {
	text: 300,
	number: 100,
	boolean: 70,
	date: 200,
	time: 100,
	currency: 150,
	s3file: 100,
};

const isTextTruncated = (element, dragMetadata) => {
	if (element) {
		const spanElement = element.querySelector("span");
		const sortElement = element.querySelector("i");
		let overflowWidth = 0;
		if (sortElement) {
			//considers the width of the sorting icon if present
			overflowWidth = sortElement.clientWidth + spanElement.offsetWidth;
		} else {
			overflowWidth = spanElement.offsetWidth;
		}
		let elementWidth = dragMetadata ? dragMetadata.width : element.clientWidth;
		return elementWidth <= overflowWidth;
	} else {
		return false;
	}
};
class ViewsTable extends Component {
	constructor(props) {
		super(props);
		this.sidebarScroll = React.createRef();
		this.contentScroll = React.createRef();
		this.tableHeader = React.createRef();
		this.tableContentContainer = React.createRef();
		this.dragTime = React.createRef(0);
		this.columnRefs = {};
		this._preventEvent = false;
		this.contentLastScrollTop = 0;
		this.contentLastScrollLeft = 0;
		props.columns.forEach((c, i) => {
			this.columnRefs[c.key] = React.createRef();
		});
		const { columnWidths, columns, toggledColumns, mainColumnActive } = props;
		const mainColumn = columns[0] || {};
		const activeColumns = columns.filter(
			(c) =>
				toggledColumns[c.key] && ((mainColumnActive && mainColumn.key !== c.key) || !mainColumnActive)
		);

		this.columnMetadata = {};
		if (columnWidths) {
			Object.keys(columnWidths).forEach((key) => {
				this.columnMetadata[key] = {
					width: columnWidths[key],
				};
			});
		}

		this.state = {
			hoverIndex: null,
			lastCheckedIndex: null,
			hoverColumnActive: null,
			contentWidth: 500,
			contentHeight: 100,
			loading: true,
			activeColumns
		};
	}

	componentDidMount() {
		const { columns, defaultSort, sortDirection } = this.props;
		this.dragImg = new Image(0, 0);
		this.dragImg.src =
			"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

		if (defaultSort) {
			if (sortDirection && sortDirection === "desc") {
				this.props.sortData(
					columns.find((c) => c.key === defaultSort),
					false,
					true
				);
			} else {
				this.props.sortData(
					columns.find((c) => c.key === defaultSort),
					true,
					false
				);
			}
		}

		let contentWidth = this.tableContentContainer.current
			? this.tableContentContainer.current.clientWidth - 1
			: 500;
		let contentHeight = this.tableContentContainer.current
			? this.tableContentContainer.current.clientHeight - 1
			: 100;

		window.addEventListener("resize", this.onWindowResize);
		this.setState({ contentWidth, contentHeight, loading: false }, () => {
			this.tableHeader.current.resetAfterIndex(0);
			this.contentScroll.current.resetAfterColumnIndex(0);
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.toggledColumns !== this.props.toggledColumns) {
			const { columns, toggledColumns, mainColumnActive } = this.props;
			const mainColumn = columns[0] || {};
			const activeColumns = columns.filter(
				(c) =>
					toggledColumns[c.key] && ((mainColumnActive && mainColumn.key !== c.key) || !mainColumnActive)
			);
			this.setState({ activeColumns }, () => {
				this.tableHeader.current.resetAfterIndex(0);
				this.contentScroll.current.resetAfterColumnIndex(0);
			});
		}
		if (prevProps.checked !== this.props.checked) {
			this.tableHeader.current.resetAfterIndex(0);
		}
	}

	onWindowResize = () => {
		let contentWidth = this.tableContentContainer.current
			? this.tableContentContainer.current.clientWidth - 1
			: 500;
		let contentHeight = this.tableContentContainer.current
			? this.tableContentContainer.current.clientHeight - 1
			: 100;
		this.setState(
			{
				contentWidth,
				contentHeight,
			},
			() => {
				this.tableHeader.current.resetAfterIndex(0);
				this.contentScroll.current.resetAfterColumnIndex(0);
			}
		);
	};

	onSidebarScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}

		this._preventEvent = true;
		if (this.contentScroll && this.contentScroll.current) {
			this.contentScroll.current._outerRef.scrollTop = e.scrollOffset;
		}
	};

	onContentScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}
		if (e.scrollLeft !== this.contentLastScrollLeft) {
			this._preventEvent = true;
			if (this.tableHeader && this.tableHeader.current) {
				this.tableHeader.current._outerRef.scrollLeft = e.scrollLeft;
			}
			this.contentLastScrollLeft = e.scrollLeft;
		}

		if (e.scrollTop !== this.contentLastScrollTop) {
			this._preventEvent = true;
			if (this.sidebarScroll && this.sidebarScroll.current) {
				this.sidebarScroll.current._outerRef.scrollTop = e.scrollTop;
			}
			this.contentLastScrollTop = e.scrollTop;
		}
	};

	onHeaderScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}
		this._preventEvent = true;
		if (this.contentScroll && this.contentScroll.current) {
			this.contentScroll.current._outerRef.scrollLeft = e.scrollOffset;
		}
	};

	shiftCheck = (id, index) => {
		const { data, checked, checkRow } = this.props;
		const keys = Object.keys(checked);
		var checkedLength = keys.length;

		if (checkedLength === 0) {
			for (var i = 0; i <= index; i++) {
				checkRow(data[i]._id);
			}
			this.setState({ lastCheckedIndex: index });
		} else {
			let from =
				this.state.lastCheckedIndex > index
					? index
					: this.state.lastCheckedIndex;
			let to =
				this.state.lastCheckedIndex < index
					? index
					: this.state.lastCheckedIndex;

			if (keys.includes(id)) {
				for (var d = from; d <= to; d++) {
					checked[data[d]._id] && checkRow(data[d]._id);
				}
			} else {
				for (var c = from; c <= to; c++) {
					!checked[data[c]._id] && checkRow(data[c]._id);
				}
			}
			this.setState({ lastCheckedIndex: index });
		}
	};

	componentWillUnmount() {
		this.dragImg = null;
		window.removeEventListener("resize", this.onWindowResize);
	}

	ColumnItem = ({ index, style }) => {
		const { sortData, mainColumnActive,
			readOnly,
			disableCheck,
			checked,
			singleCheck,
			checkAllRows,
			data
		} = this.props;
		const { activeColumns } = this.state;
		const colIndex = index;
		if (colIndex < activeColumns.length) {
			const c = activeColumns[colIndex];
			const allChecked = data.length === Object.keys(checked).length &&
				Object.keys(checked).length > 0
			return (
				<div
					style={{ maxWidth: style.width, ...style }}
					className="headerRowCell text-truncate"
				>
					{!mainColumnActive && index === 0 && !readOnly && !disableCheck && (

						!singleCheck ? (<div
							className={`checkBox mr-10 ${allChecked ? "checked" : ""} `}
							onClick={(e) => {
								e.stopPropagation();
								checkAllRows(allChecked ? [] : data.map((sd) => sd._id));
								this.tableHeader.current.resetAfterIndex(0);
							}}
						>
							<i className="las la-check" />
						</div>) : <div className="mr-25"></div>
					)}
					<div className="tableColumnTitle2 flex-ac cPointer"
						onClick={() => {
							if (sortData) {
								if (c.sortAsc) {
									sortData(c, false, true);
								} else {
									sortData(c, true, false);
								}
							}
						}}>
						<div
							ref={this.columnRefs[c.key]}
							style={{
								width: style.width - 30,
								minWidth: style.width - 30,
								maxWidth: style.width - 30,
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
							id={`header${colIndex}`}
						>
							<span>{c.label}</span>
							{sortData && (c.sortAsc ? (
								<i className="las la-angle-down" />
							) : c.sortDesc ? (
								<i className="las la-angle-up" />
							) : (
								""
							))}
						</div>
						{isTextTruncated(
							this.columnRefs[c.key]?.current,
							this.columnMetadata[c.key] || null
						) && (
								<UncontrolledTooltip
									className="tooltip"
									placement="right"
									target={`header${colIndex}`}
								>
									{c.label}
								</UncontrolledTooltip>
							)}
					</div>

					<div
						className={"ml-a dragHandle"}
						draggable
						onDragStart={(evt) => {
							evt.stopPropagation();
							evt.dataTransfer.setDragImage(this.dragImg, 0, 0);
							evt.target.classList.add("dragging");
							let editedColumnMetadata = this.columnMetadata;
							const initialWidth = this.getHeaderWidth(index);
							if (editedColumnMetadata[c.key]) {
								editedColumnMetadata[c.key].initialPoint = evt.clientX;
								editedColumnMetadata[c.key].initialWidth = initialWidth;
								editedColumnMetadata[c.key].width = initialWidth;
							} else {
								editedColumnMetadata[c.key] = {
									initialPoint: evt.clientX,
									initialWidth: initialWidth,
									width: initialWidth,
								};
							}
							this.dragTime.current = new Date().getTime();
							this.columnMetadata = editedColumnMetadata;
							this.tableHeader.current.resetAfterIndex(index);
							this.contentScroll.current.resetAfterColumnIndex(index);
						}}
						onDrag={(evt) => {
							evt.stopPropagation();
							const currentTime = new Date().getTime();
							if (
								evt.clientX > 0 &&
								currentTime - this.dragTime.current > 250
							) {
								let editedColumnMetadata = this.columnMetadata;
								let newWidth =
									editedColumnMetadata[c.key].initialWidth +
									(evt.clientX - editedColumnMetadata[c.key].initialPoint);
								if (newWidth < 50) {
									newWidth = 50;
								}
								editedColumnMetadata[c.key].width = newWidth;
								this.columnMetadata = editedColumnMetadata;
								this.tableHeader.current.resetAfterIndex(index);
								this.contentScroll.current.resetAfterColumnIndex(index);
							}
						}}
						onDragEnd={(evt) => {
							evt.stopPropagation();
							evt.target.classList.remove("dragging");
						}}
					>
						<div className="dragBar"></div>
					</div>
				</div>
			);
		} else {
			return <div style={style}></div>;
		}
	};

	RowItem = ({ columnIndex, rowIndex, style }) => {
		const { rowSettings, rowDetails, detailsTitle,
			readOnly,
			disableCheck,
			onClick,
			mainColumnActive,
			checked,
			activeRow,
			hoverIndex,
			checkRow } =
			this.props;
		const { activeColumns } = this.state;
		const colIndex = columnIndex;
		if (colIndex < activeColumns.length) {
			const meta = this.props.data[rowIndex];
			const c = activeColumns[colIndex];

			let value = "";
			if (typeof meta[c.key] != "undefined" && meta[c.key] != null) {
				if (typeof meta[c.key] == "boolean") {
					value = meta[c.key] ? "Yes" : "No";
				} else if (c.type === "icon") {
					value = <i className={`${meta[c.key]}`} />;
				} else if (c.type === "date") {
					const dateStamp = meta[c.key];
					value =
						dateStamp !== "" ? moment(meta[c.key]).format("MM/DD/YYYY") : "";
				} else if (c.type === "timestamp") {
					const timeStamp = meta[c.key];
					value =
						timeStamp && timeStamp !== ""
							? moment(timeStamp).format("MM/DD/YYYY h:mm a")
							: "";
				} else if (c.type === "time") {
					const today = moment().format("YYYY-MM-DD");
					const valueTime = meta[c.key];
					value =
						valueTime && valueTime !== ""
							? moment(`${today} ${valueTime}`).format("h:mm a")
							: "";
				} else if (c.type === "link") {
					value = (
						<a className="link" href={meta[c.key]} target="_blank">
							{meta[c.key]}
						</a>
					);
				} else if (c.type === "bool") {
					value = meta[c.key] ? "Yes" : "No";
				} else if (c.type === "currency") {
					const currency = parseFloat(meta[c.key]);

					value = !isNaN(currency) ? `$${currency.toFixed(2)}` : `$${0}`;
				} else if (c.type === "status") {
					value = meta[c.key].charAt(0).toUpperCase() + meta[c.key].slice(1);
				} else if (c.type === "array") {
					value = meta[c.key].join(", ");
				} else {
					value = meta[c.key];
				}
			}
			return (
				<div
					className={`columnRowCell ${hoverIndex === rowIndex ? "hover" : ""} ${!activeRow ? "" : activeRow === meta._id || checked[meta._id] ? "checked" : ""} ${onClick ? "c-pointer" : ""} ${meta.isStrikeThrough ? "strikeThrough" : ""}`}
					key={`cell-${c.key}-${rowIndex}-${colIndex}`}
					style={style}

					onClick={() => {
						onClick && onClick(meta._id);
					}}
				>
					{!mainColumnActive && !readOnly && !disableCheck && columnIndex === 0 && (
						<div
							className={`checkBox mr-10 ${checked[meta._id] ? "checked" : ""
								}`}
							onClick={(e) => {
								e.stopPropagation();
								if (e.shiftKey) {
									this.shiftCheck(meta._id, rowIndex);
								} else {
									this.setState({ lastCheckedIndex: rowIndex }, () => {
										this.tableHeader.current.resetAfterIndex(0);
									});
									checkRow(meta._id);
								}
							}}
						>
							<i className="las la-check" />
						</div>
					)}
					<div className="text-truncate rowText">{value}</div>
				</div>
			);
		} else {
			const meta = this.props.data[rowIndex];
			return (
				<div className="columnRowCell p-0" style={style}>
					{rowSettings && rowSettings.length > 0 && (
						<div className="ml-a mr-10">
							<div className="detailsContainer">
								<UncontrolledDropdown direction="left" inNavbar>
									<DropdownToggle
										className="light"
										style={{ width: 30, height: 30 }}
									>
										<div className="icon">
											<i className="las la-ellipsis-v" />
										</div>
									</DropdownToggle>
									<DropdownMenu
										className="columnDropdown"
										style={{ minWidth: 230 }}
									>
										<div className="ph-20">
											{rowSettings.map((item, index) => {
												let displayCondition = item.displayCondition
													? item.displayCondition(meta)
													: true;
												return (
													<div>
														{displayCondition && (
															<DropdownItem
																className={`moreViewsPopoverItemMenuItem`}
																style={{
																	textTransform: "capitalize",
																	padding: "7px 10px",
																}}
																onClick={() => {
																	item.onClick && item.onClick(meta._id);
																}}
															>
																{item.label}
															</DropdownItem>
														)}
													</div>
												);
											})}
										</div>
										{rowDetails && (
											<div>
												<hr />

												<div className="ph-20">
													<div className="mb-10" style={{ fontWeight: 600 }}>
														{detailsTitle}
													</div>
													{Object.keys(rowDetails).map((key, index) => {
														return (
															<div>
																<div className="mb-10">
																	<div
																		style={{ fontSize: 12, color: "#999999" }}
																	>
																		{rowDetails[key]}:
																	</div>
																	<div>{meta[key]}</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										)}
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						</div>
					)}
				</div>
			);
		}
	};

	getHeaderWidth = (index) => {
		const colIndex = index;
		const { activeColumns } = this.state;
		if (colIndex < activeColumns.length) {
			let headerWidth = this.columnMetadata[activeColumns[colIndex].key]
				? this.columnMetadata[activeColumns[colIndex].key].width
				: fieldTypeWidths[activeColumns[colIndex].type]
					? fieldTypeWidths[activeColumns[colIndex].type]
					: 180;
			return headerWidth;
		} else {
			//caculate remaining width
			let totalWidth = 0;
			activeColumns.forEach((c, i) => {
				totalWidth += this.columnMetadata[c.key]
					? this.columnMetadata[c.key].width
					: fieldTypeWidths[c.type]
						? fieldTypeWidths[c.type]
						: 180;
			});
			if (totalWidth < this.state.contentWidth) {
				return (this.state.contentWidth - totalWidth) - 5;
			} else {
				return 50;
			}
		}
	};

	MainColumnItem = ({ index, style }) => {
		const {
			columns,
			data,
			readOnly,
			disableCheck,
			disableLink,
			checked,
			activeRow,
			checkRow,
		} = this.props;
		const mainColumn = columns[0] || {};
		const a = data[index];
		return (
			<div
				className={`tableMainColumnRowCell ${activeRow === a._id || checked[a._id] ? "checked" : ""
					}`}
				key={a._id}
				style={style}
			>
				{!readOnly && !disableCheck && (
					<div
						className={`checkBox mr-10 ${checked[a._id] ? "checked" : ""}`}
						onClick={(e) => {
							if (e.shiftKey) {
								this.shiftCheck(a._id, index);
							} else {
								this.setState({ lastCheckedIndex: index });
								checkRow(a._id);
							}
						}}
					>
						<i className="las la-check" />
					</div>
				)}
				{this.props.favorite && <div className='mr-10 c-pointer' onClick={() => {
					this.props.favorite(a._id, !a.favorited);
				}}>{a.favorited ? <i className='las la-star' style={{ color: '#ffd400' }}></i> : <i className='lar la-star'></i>}</div>}
				{!disableLink && a.link ? (
					<Link to={a.link} className="tableCellData">
						<div id={`mainColumn${index}`} className="tableRowText">
							{a[mainColumn.key]}
							{a[mainColumn.key].length >= 30 && (
								<UncontrolledTooltip
									innerClassName="custom-tooltip"
                  /*  delay={{ "show": '1000', "hide": '0' }} */ placement="top"
									target={`mainColumn${index}`}
								>
									{a[mainColumn.key]}
								</UncontrolledTooltip>
							)}
						</div>
						<i className="las la-angle-right ml-a" />
					</Link>
				) : (
					<div className="tableCellData">
						<div className="tableRowText">{a[mainColumn.key]} </div>
					</div>
				)}
			</div>
		);
	};

	BlankTableRow = ({ index, style }) => {
		return <div className="columnRowCell noBorder" style={style}></div>;
	};

	render() {
		const {
			readOnly,
			mainColumnActive,
			checked,
			checkAllRows,
			data,
			sortData,
			columns,
			disableCheck,
			classes,
			noDescription,
		} = this.props;
		const { contentHeight, contentWidth, activeColumns } =
			this.state;
		const mainColumn = columns[0] || {};
		const allChecked =
			data.length === Object.keys(checked).length &&
			Object.keys(checked).length > 0;
		let title = this.props.title;

		return mainColumnActive ? (
			<div
				className={`sectionTable ${classes ? classes : ""}`}
				style={this.props.style}
			>
				<div className="tableMainColumn noselect">
					<div className="tableColumnHeader">
						<div className="tableMainColumnCell" style={{ padding: "0 15px" }}>
							{!readOnly && !disableCheck && (
								<div
									className={`checkBox mr-10 ${allChecked ? "checked" : ""} `}
									onClick={(e) => {
										e.stopPropagation();
										checkAllRows(allChecked ? [] : data.map((sd) => sd._id));
									}}
								>
									<i className="las la-check" />
								</div>
							)}
							<div
								className="tableCellData"
								onClick={() => {
									if (mainColumn.sortAsc) {
										sortData(mainColumn, false, true);
									} else {
										sortData(mainColumn, true, false);
									}
								}}
							>
								<div className="tableColumnTitle2">
									{mainColumn.label}

									{mainColumn.sortAsc ? (
										<i className="las la-angle-down" />
									) : mainColumn.sortDesc ? (
										<i className="las la-angle-up" />
									) : (
										""
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="tableColumnRows hideScrollbar">
						<VariableSizeList
							ref={this.sidebarScroll}
							onScroll={this.onSidebarScroll}
							height={contentHeight}
							width={300}
							itemCount={data.length}
							itemSize={() => 40}
						>
							{this.MainColumnItem}
						</VariableSizeList>
					</div>
				</div>
				<div className="tableSubColumns neu">
					<div className="tableColumnHeader hideScrollbar">
						<VariableSizeList
							ref={this.tableHeader}
							onScroll={this.onHeaderScroll}
							height={40}
							width={contentWidth}
							itemCount={activeColumns.length + 1}
							itemSize={this.getHeaderWidth}
							layout="horizontal"
						>
							{this.ColumnItem}
						</VariableSizeList>
					</div>
					<div className="tableColumnRows" ref={this.tableContentContainer}>
						{data.length > 0 ? (
							<VariableSizeGrid
								ref={this.contentScroll}
								onScroll={this.onContentScroll}
								height={contentHeight}
								width={contentWidth}
								columnCount={activeColumns.length + 1}
								rowCount={data.length}
								columnWidth={this.getHeaderWidth}
								rowHeight={() => 40}
							>
								{this.RowItem}
							</VariableSizeGrid>
						) : (
							<VariableSizeGrid
								ref={this.contentScroll}
								onScroll={this.onContentScroll}
								height={contentHeight}
								width={contentWidth}
								columnCount={activeColumns.length + 1}
								rowCount={1}
								columnWidth={this.getHeaderWidth}
								rowHeight={() => 40}
							>
								{this.BlankTableRow}
							</VariableSizeGrid>
						)}
						{data.length === 0 && (
							<div className="BlankTableMessage">
								<h2 className="mb-15"> No {title} Yet</h2>
								{!noDescription && (
									<p className="tableLabel">
										{" "}
										Add <span className="lower"> {title} </span>
										{this.props.importAvailable && (
											<span>
												{" "}
												or import <span className="lower"> {title} </span> from
												a spreadsheet
											</span>
										)}{" "}
										to view and manage.
									</p>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		) : (
			<div className={`sectionTable ${classes}`} style={this.props.style}>
				<div className="tableSubColumns fit">

					<div className="tableColumnHeader hideScrollbar">
						<VariableSizeList
							ref={this.tableHeader}
							onScroll={this.onHeaderScroll}
							height={40}
							width={contentWidth}
							itemCount={activeColumns.length + 1}
							itemSize={this.getHeaderWidth}
							layout="horizontal"
						>
							{this.ColumnItem}
						</VariableSizeList>
					</div>
					<div className="tableColumnRows" ref={this.tableContentContainer}>
						{data.length > 0 ? (
							<VariableSizeGrid
								key={"singleTable"}
								ref={this.contentScroll}
								onScroll={this.onContentScroll}
								height={contentHeight}
								width={contentWidth}
								columnCount={activeColumns.length + 1}
								rowCount={data.length}
								columnWidth={this.getHeaderWidth}
								rowHeight={(index) => {
									const dataRow = data[index];
									if (dataRow.rowHeight) {
										return dataRow.rowHeight > 2 ? 25 * dataRow.rowHeight : 40;

									} else {
										return 40;
									}
								}}
							>
								{this.RowItem}
							</VariableSizeGrid>
						) : (
							<VariableSizeGrid
								key={"blankSingleTable"}
								ref={this.contentScroll}
								onScroll={this.onContentScroll}
								height={contentHeight}
								width={contentWidth}
								columnCount={activeColumns.length + 1}
								rowCount={1}
								columnWidth={this.getHeaderWidth}
								rowHeight={() => 40}
							>
								{this.BlankTableRow}
							</VariableSizeGrid>
						)}
						{data.length === 0 && (
							<div className="BlankTableMessage">
								<h2 className="mb-15"> No {title} Yet</h2>
								{!noDescription && (
									<p className="tableLabel">
										{" "}
										Add <span className="lower"> {title} </span>
										{this.props.importAvailable && (
											<span>
												{" "}
												or import <span className="lower"> {title} </span> from
												a spreadsheet
											</span>
										)}{" "}
										to view and manage.
									</p>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default ViewsTable;
