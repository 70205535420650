import React from 'react';

import FieldModal from '../../components/modals/fieldModal';
import InputField from '../../components/inputs/inputField';

class DeleteEventsModal extends React.Component {
	state = {
		submitting: false,
		deleteConfirmed: ''
	};
	render() {
		const { isOpen, toggle, count, deleteCount, deleting } = this.props;
		const { submitting, deleteConfirmed } = this.state;
		return (

			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={`Delete Event${count == 1 ? '' : 's'}`}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								{!submitting ? (
									<div>
										<p >
											<span ><b>
												You are about to delete {count} event{count == 1 ? '' : 's'}, this is irreversible.</b>
											</span>
											<br />
											This action will remove access to any pages associated with the event{count == 1 ? (
												''
											) : (
												's'
											)}.<br />
											<br />
											Are you sure you want to continue?
										</p>
										<br />
										<InputField
											errorTrigger={deleteConfirmed != 'DELETE' && deleteConfirmed != ''}
											label="Type DELETE to confirm"
											value={deleteConfirmed}
											onChange={(e) => this.setState({ deleteConfirmed: e.target.value })}
										/>
									</div>
								) : (
									<p className="calibreRegular mb-5">
										{deleteCount} event{deleteCount == 1 ? '' : 's'} deleted.
									</p>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : ''}
					actionButton={() => {



						this.setState({ submitting: true });
						this.props.delete();
					}}
					actionButtonDisabled={submitting || deleteConfirmed != 'DELETE'}
					cancelButtonLabel={!submitting ? 'Cancel' : deleting ? 'Deleting...' : 'Done'}
					isDelete={true}


				></FieldModal>

			</div>

		);
	}
}

export default DeleteEventsModal;
