import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import FieldModal from '../../../../components/modals/fieldModal';

class DeletePointsModal extends React.Component {
	state = {
		submitting: false
	};

	delete = () => {
		const { checkedPoints } = this.props;
		this.setState({ submitting: true });
		this.props.delete(checkedPoints, () => {
			this.props.uncheckEntries();
			this.props.toggle();
		});
	};

	render() {
		const { isOpen, toggle, points } = this.props;
		const { submitting } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Point"}
					bodyHeaderText={""}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.delete()}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>

				{/* <Modal isOpen={isOpen} toggle={this.resetAdd} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="fs-30 calibreBold">Delete Points</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<p className="calibreRegular">
						You are about to delete points from this attendee, this is irreversible, would you like to
						continue?
					</p>
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionDelete m-0"
						disabled={submitting}
						onClick={() => {
							this.delete();
						}}
					>
						<div className="modalButtonText">{submitting ? 'Deleting' : 'Delete'}</div>
					</Button>
					<Button
						outline
						onClick={this.props.toggle}
						className="modalButton actionButton modalCancel mb-0 ml-a"
					>
						<div className="modalCancelText">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal> */}
			</div>
		);
	}
}

export default DeletePointsModal;
