import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-toggle/style.css';
import API from '../../utils/API';
import SectionHeader from '../../components/views/SectionHeader';
import PagePreviewFrame from '../../views/pages/components/pagePreviewFrame';
import InputField from '../../components/inputs/inputField';
import FormBuilderModal from './modals/formBuilder';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

const baseFieldList = [
	{
		_id: 'event',
		title: 'Event Defaults',
		fields: [
			{
				_id: '_name',
				id: 'name',
				fieldName: 'Name',
				fieldType: 'text',
				propertyType: 'base',
				required: true,
				unique: true
			},
			{
				_id: '_startDate',
				id: 'startDate',
				fieldName: 'Start Date',
				fieldType: 'date',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_endDate',
				id: 'endDate',
				fieldName: 'End Date',
				fieldType: 'date',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_startTime',
				id: 'startTime',
				fieldName: 'Start Time',
				fieldType: 'time',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_endTime',
				id: 'endTime',
				fieldName: 'End Time',
				fieldType: 'time',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_inventory',
				fieldName: 'Inventory',
				fieldType: 'inventory',
				propertyType: 'custom',
			}
		]
	},
	{
		_id: 'special',
		title: 'Special',
		fields: [
			{
				_id: '_additionalAttendees',
				fieldName: 'Additional Attendees',
				fieldType: 'additionalAttendees',
				propertyType: 'base',
				moduleConfiguration: {
					label: 'Guest',
					maxAttendees: 2,
					associatedGroup: null,
					defaultStatus: 'registered',
					confirmationEmail: null,
					fields: [
						{
							_id: '_firstName',
							attendeeField: { propertyType: 'base', propertyName: 'firstName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeFirstName',
							label: 'First Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'text',
							static: true
						},
						{
							_id: '_lastName',
							attendeeField: { propertyType: 'base', propertyName: 'lastName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeLastName',
							label: 'Last Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: false,
							style: '',
							type: 'text',
							static: false
						},
						{
							_id: '_email',
							attendeeField: { propertyType: 'base', propertyName: 'email' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeEmail',
							label: 'Email',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'email',
							static: true
						}
					]
				}
			},
			{
				_id: '_availabilityCalendar',
				fieldName: 'Availability Calendar',
				fieldType: 'availabilityCalendar',
				propertyType: 'base',
				moduleConfiguration: {}
			}
		]
	}
];

class AccountForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: {},
			editedForm: {},
			editedFields: [],
			fieldModal: false,
			adding: false,
			loading: false,
			editing: false,
			removing: false,
			removedCategoryId: 0,
			removedFieldId: 0,
			customFieldsCollapsed: false,
			unattachedFieldsCollapsed: true,
			formButtonsCollapsed: true,
			fieldTypeTabSelected: 0,
			currentPage: 1,
			formEditorTabSelected: 0,
			formBuilderModalOpen: false,

		};
	}

	async componentDidMount() {
		const { match: { params } } = this.props;
		const { orgId, formId } = params;
		/* const selectedEmbedPagePreviewResponse = await API().get(`Organizations/${orgId}/accountPages/${requestFormPageId}`);
		this.setState({
			selectedEmbedPagePreview: selectedEmbedPagePreviewResponse.data
		}); */
	}

	saveForm = async () => {
		const { orgId } = this.props;
		const { editedForm } = this.state;
		let editedFormTemp = JSON.parse(JSON.stringify(editedForm));
		if (editedForm.confirmationEmail == 'N/A' || editedForm.confirmationEmail == '') {
			delete editedFormTemp.confirmationEmail;
		}
		await API()
			.patch(`Organizations/${orgId}/accountForms/${editedForm._id}`, editedFormTemp)
			.then((res) => {
				if (res.data) {
					this.setState({
						form: { ...editedForm, ...res.data },
						editing: false
					});
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	updateForm = (editedForm) => {
		this.setState({
			editedForm: editedForm,
			editing: true
		});
	};

	cancelEdit = () => {
		const revertedForm = JSON.parse(JSON.stringify(this.state.form));
		this.setState({
			editedForm: revertedForm,
			editing: false
		});
	};

	setupPage = () => {
		this.setState({ loading: true });
		API()
			.post(`Organizations/${this.props.orgId}/programs/${this.props.programId}/createRequestFormPage`)
			.then((res) => {
				if (res.data) {
					this.props.updateProgramRequestForm(res.data, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	};

	toggleFormEditorModal = () => {
		this.setState({ formEditorModalOpen: !this.state.formEditorModalOpen });
	};
	render() {
		const { form, editing, editedForm, loading, formBuilderModalOpen, selectedEmbedPagePreview } = this.state;
		const { program } = this.props;
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={'Request Form'}
					description="Build a request form to allow users to request event based on this program."
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveForm}
					updatedAt={form.updatedAt}
					breadcrumbs={[
						{
							name: "Account Forms"
						}
					]}
				/>

				{!loading && (
					<div className="sectionBody">

						<div className='blocksContainer'>
							<div className="sectionBlock mr-25">
								<h2 className='blockHeader'>Basic Information</h2>
								<InputField
									label="Name"
									required={true}
									placeholder="Form name"
									value={editedForm.name}
									onChange={(e) => {
										this.setState({
											editedForm: { ...editedForm, name: e.target.value },
											editing: true
										});
									}}
								/>
								<div className='mb-20'>
									<h2 className='mb-10'>Form Status</h2>
									<h4>Number of Pages</h4>
									<h3 className='mb-10'>{form.pages ? form.pages.length : 0}</h3>
									<h4>Number of Submissions</h4>
									<h3>{form.submissions ? form.submissions.length : 0}</h3>
								</div>
							</div>
							<div className='sectionBlock emailDesignPreview mt-20'>
								<div
									className="cardDetailsContainer containerTitle"
								>

									<div className='w-100'>
										<h2>Page Preview</h2>
										{!selectedEmbedPagePreview ? <h3>Not Linked</h3> : <h3>{selectedEmbedPagePreview.label}</h3>}
									</div>

									<button
										className="neu min prim ml-a"
										onClick={this.toggleFormEditorModal}
									>
										Edit Form
									</button>

									{selectedEmbedPagePreview && <button
										className="neu min ml-25"
									>
										Open Page
									</button>}
								</div>
								<div
									className="emailPreview eFrame flex-ac preview"
								>
									{selectedEmbedPagePreview && <PagePreviewFrame
										ENV={ENV}
										renderURL={`Organizations/${this.props.orgId}/accountPages/render/${program.requestFormPageId}/`}
										preview={true}
										pageProperties={selectedEmbedPagePreview}
										focusedComponent={""}
										simulatedUser={{ value: this.props.account._id }}
										simulatedSession={{ value: false }}
										renderOnly={true}
									/>}
								</div>
							</div>
						</div>
					</div>
				)}


				<FormBuilderModal
					isOpen={this.state.formEditorModalOpen}
					editing={editing}
					toggle={this.toggleFormEditorModal}
					form={form}
					editedForm={editedForm}
					fields={[]}
					baseFieldList={baseFieldList}
					links={{ get: `` }}
					updateForm={this.updateForm}
					cancelEdit={this.cancelEdit}
					emails={this.props.availableEmails}
					orgId={this.props.orgId}
					eventId={this.props.eventId}
					addNewField={this.props.addNewField}
					saveForm={this.saveForm} />
			</div>
		);
	}
}

export default withRouter(AccountForm);
