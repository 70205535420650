import React from 'react';
import AddModeratorModal from './modals/addModeratorModal';
import RemoveModeratorModal from './modals/removeModeratorModal';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';

import InputField from '../../../components/inputs/inputField';
class EventSessionModerators extends React.Component {
	constructor(props) {
		super(props);

		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email'
		};
		const columnTypes = {
			firstName: 'text',
			lastName: 'text',
			email: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			addModeratorModalOpen: false,
			removeModeratorModalOpen: false,
			markedModerator: null,
			toggledColumns: toggledColumns,
			deleteModeratorsToggle: false,
			searchText: '',
			searching: false,
			checkedModerators: [],
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	ModeratorItem(moderator, index) {
		return (
			<tr key={index}>
				<td className="text-truncate">{moderator.firstName}</td>
				<td className="text-truncate">{moderator.lastName}</td>
				<td className="text-truncate">{moderator.email}</td>
				<td style={{ width: '100px' }}>
					<a style={{ color: 'red' }} onClick={() => this.toggleRemoveModerator(moderator)}>
						Remove
					</a>
				</td>
			</tr>
		);
	}

	toggleAddModerators = () => {
		this.setState({ addModeratorModalOpen: !this.state.addModeratorModalOpen });
	};

	toggleRemoveModerator = (moderator) => {
		this.setState({
			removeModeratorModalOpen: !this.state.removeModeratorModalOpen,
			markedModerator: moderator || null
		});
	};

	deleteModeratorsToggle = () => {
		this.setState({ deleteModeratorsToggle: !this.state.deleteModeratorsToggle });
	};

	removeModerator = (moderator) => {
		this.setState({ moderator: this.state.moderator.filter((m) => m._id != moderator._id) });
	};

	searchEntries = (searchText) => {
		this.setState({ searching: searchText != '' ? true : false, searchText: searchText ? searchText : '' });
	};

	updatedCheckedModerators = (checked) => {
		this.setState({ checkedModerators: checked });
	};
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;

		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};
	render() {
		const {
			addModeratorModalOpen,
			markedModerator,
			deleteModeratorsToggle,
			searchText,
			searching,
			toggledColumns,
			normalizedColumns,
			columns,
			selectedColumnSort,
			sortDesc
		} = this.state;
		const { moderators } = this.props.editedSession;

		let searchedEntries = moderators;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		searchedEntries = searchedEntries.map((att) => {
			return {
				_id: att._id,
				firstName: att.firstName,
				lastName: att.lastName,
				email: att.email
			};
		});

		if (selectedColumnSort) {
			searchedEntries = searchedEntries.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'currency':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						case 'bool':
							const c1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const d1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return c1.localeCompare(d1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});
			searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
		}

		let mainActions = [];
		mainActions = [{ onClick: this.toggleAddModerators, label: 'Add Moderators' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Delete Moderator',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.deleteModeratorsToggle
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedModerators).length;
		entriesLength = searching ? searchedEntries.length : moderators.length;
		uncheckEntries = () => {
			this.setState({ checkedModerators: [] });
		};
		searchEnabled = true;

		return (
			< div className='sectionBody posRel mb-0 p-0' >
				<ActionButtonContainer
					mainActions={mainActions}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>

				<ViewsTable
					title="Moderators"
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={searchedEntries}
					classes="nested"
					mainColumnActive={false}
					checked={this.state.checkedModerators}
					checkRow={(entryId) => {
						const checked = this.state.checkedModerators;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedModerators(checked);
						this.setState({
							checkedModerators: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedModerators;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedModerators(checked);

						this.setState({
							checkedModerators: checked
						});
					}}
					sortData={(column, sortAsc, sortDesc) => {
						this.sortData(column, sortAsc, sortDesc);
					}}
				/>
				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
				/>

				{addModeratorModalOpen && (
					<AddModeratorModal
						isOpen={addModeratorModalOpen}
						toggle={this.toggleAddModerators}
						orgId={this.props.orgId}
						updateModerators={this.props.updateModerators}
						loadMoreAttendees={this.props.loadMoreAttendees}
						eventId={this.props.event._id}
						existingSpeakers={moderators}
						attendees={this.props.event.attendees}
						session={this.props.editedSession}
						uncheckEntries={uncheckEntries}
					/>
				)}
				{deleteModeratorsToggle && (
					<RemoveModeratorModal
						isOpen={deleteModeratorsToggle}
						toggle={this.deleteModeratorsToggle}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						markedModerator={markedModerator}
						attendees={this.props.event.attendees}
						handleSubmit={this.props.updateSession}
						updateModerators={this.props.updateModerators}
						session={this.props.editedSession}
						uncheckEntries={uncheckEntries}
						checkedModerators={Object.keys(this.state.checkedModerators)}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionModerators;
