import React, { Component } from 'react';
import * as IFrame from 'react-iframe-form';

class PagePreviewFrame extends Component {

	render() {
		const {
			editing,
			ENV,
			renderURL,
			simulatedUser,
			simulatedSession,
			pageProperties,
			focusedComponent,
			eventStyling,
			customCSS,
			branding,
			preview
		} = this.props;
		const renderSrc = simulatedSession.value
			? `${ENV}${renderURL}${simulatedUser.value || ''}/${simulatedSession.value}${focusedComponent}`
			: `${ENV}${renderURL}${simulatedUser.value || ''}${focusedComponent}`;

		const url = renderSrc + (preview ? '?preview=true' : '');
		const method = 'post';
		const data = {
			pageProperties: {
				customCSS: pageProperties.customCSS,
				backgroundImage: pageProperties.backgroundImage,
				backgroundOverlay: pageProperties.backgroundOverlay
			},
			partials: pageProperties.partials,
			focusedComponent: focusedComponent,
			renderOnly: this.props.renderOnly || false,
			eventStyling: eventStyling,
			customCSS: customCSS,
			branding: branding,
			formId: this.props.formId,
			platformPageId: this.props.platformPageId,
		};

		return (
			<IFrame.Form id="builderPreviewFrame" key={editing} name="PreviewFrame" config={{ url, method, data }} />
		);
	}
}

export default PagePreviewFrame;
