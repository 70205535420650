import React from 'react';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteFeedItemsModal extends React.Component {
    state = {
        submitting: false,
    };



    removePoint = async () => {
        const { eventId, orgId, checkedItems, toggle, markedItem, selectedGallery } = this.props;
        this.setState({ submitting: true })
        let feedItemIds = markedItem ? [markedItem] : checkedItems;
        await API().patch(`Organizations/${orgId}/events/${eventId}/feedItems/`, {
            feedItemIds: feedItemIds,
        })
            .then((res) => {
                this.props.removeFeedItems(selectedGallery._id, feedItemIds, toggle);

            }
            ).catch((err) => {
                console.log(err);
                this.setState({ submitting: false });
            });


    };


    render() {
        const { isOpen, toggle, checkedItems, markedItem } = this.props;
        const { submitting, isGeneralGallery } = this.state;

        let itemCount = markedItem ? 1 : checkedItems.length;

        return (

            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Gallery"}
                    bodyHeaderText={<span>You are about to delete{' '}
                        <b>{itemCount}</b> {itemCount > 1 ? 'feed items' : 'feed item'} from
                        this event, would you like to continue?</span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                    actionButton={() => this.removePoint()}
                    actionButtonDisabled={submitting || isGeneralGallery}
                    isDelete={true}
                ></FieldModal>
            </div>

        );
    }
}

export default DeleteFeedItemsModal;
