import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

class DeleteScannablesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
        };

    }

    deleteScannables = async () => {
        const { checkedScannables, eventId, orgId } = this.props;

        await API().patch(`Organizations/${orgId}/events/${eventId}/scannables/`, {
            scannables: Object.keys(checkedScannables),
            isDelete: true
        }).then((res) => {

            this.props.deleteScannables(Object.keys(checkedScannables), () => {
                this.props.toggle();
            });

        });

    }



    render() {
        const { isOpen, toggle, checkedScannables } = this.props;
        const { submitting } = this.state;

        let scannablesCount = Object.keys(checkedScannables).length;

        return (

            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={`Delete QR Code${scannablesCount > 1 ? 's' : ''}`}
                    bodyHeaderText={<span>You are about to delete <b>{scannablesCount}</b> QR code{scannablesCount > 1 ? 's' : ''} from the
                        event, this is irreversible, would you like to continue?</span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting'}
                    actionButton={() => (this.deleteScannables())}
                    actionButtonDisabled={submitting}
                    isDelete={true}
                ></FieldModal>
            </div>

        );
    }
}

export default withRouter(DeleteScannablesModal);
