import React, { Component } from 'react';

class TextAreaField extends Component {
	render() {
		const {
			onChange,
			label,
			errorTrigger,
			errorMessage,
			type,
			value,
			disabled,
			placeholder,
			inputIcon,
			inputMode,
			pattern,
			autoComplete,
			maxLength,
			onKeyUp,
			style,
			inputStyle,
			required,
			classes,
			rows,
			subText
		} = this.props;
		return (
			<div className={`formFieldContainer textArea ${classes ? classes : ''}`} style={style} >
				<div className="formField">
					{label && (
						<div className="labelContainer">
							<div className="formFieldLabel">
								{label}
								{required ? '*' : ''}
							</div>
							{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
						</div>
					)}
					<div
						className={`formFieldInputContainer ${errorTrigger ? 'error' : ''}  ${inputIcon
							? 'hasIcon'
							: ''}`}
					>
						<textarea
							style={inputStyle}
							value={value}
							className="formFieldTextArea"
							type={type}
							placeholder={placeholder}
							disabled={disabled}
							onChange={onChange}
							inputMode={inputMode}
							pattern={pattern}
							autoComplete={autoComplete}
							maxLength={maxLength}
							onKeyUp={onKeyUp}
							rows={rows || 5}
						/>
						{inputIcon && <div className="inputIcon">{inputIcon}</div>}
					</div>
					{subText && <div className="subText"> {subText}</div>}

				</div>
			</div>
		);
	}
}

export default TextAreaField;
