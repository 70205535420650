import React, { Component, Fragment } from 'react';
import 'react-datetime/css/react-datetime.css';

import ViewsTable from '../../../components/tables/ViewsTable';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import API from '../../../utils/API';
import Scheduler from '../../../utils/Scheduler';

class EventEmailSchedules extends Component {
	constructor(props) {
		super(props);
		const email = props.email;
		const columns = {
			sendDate: 'Date',
			sendTime: 'Time',
			timezone: 'Timezone',
			sent: 'Sent',
			recipientModel: 'Type',
			cancelled: 'Cancelled'
		};
		const columnTypes = {
			sendDate: 'date',
			sendTime: 'time',
			timezone: 'text',
			sent: 'bool',
			recipientModel: 'text',
			cancelled: 'bool'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			editing: false,
			editedEmail: email,
			isHTML: email.isPlainHtml,
			parameterOptionsOpen: false,
			checkedSchedules: {},
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			cancelScheduleToggle: false
		};
	}

	renderMetadataOptions = () => {
		const { keyedMetadata, updateEditedEmail, editedEmail } = this.props;

		return (
			<div className="paramsList">
				{keyedMetadata.map((option) => {
					const concatenatedOption = option.split('.').join('');
					return (
						<div
							key={option}
							onClick={() => {
								const parameters = editedEmail.parameters;
								if (parameters.includes(option)) {
									parameters.splice(parameters.indexOf(option), 1);
								} else {
									parameters.push(option);
								}
								updateEditedEmail({ ...editedEmail, parameters: parameters });
							}}
							className={`emailParam ${editedEmail.parameters.includes(option) ? 'selected' : ''}`}
						>
							{option}
						</div>
					);
				})}
			</div>
		);
	};

	updatedCheckedSchedules = (checked) => {
		this.setState({ checkedSchedules: checked });
	};
	resumeScheduleToggle = async () => {
		const { eventId, orgId } = this.props;
		this.props.resumeSchedules(Object.keys(this.state.checkedSchedules));

		await Scheduler()
			.patch(`Organizations/${orgId}/schedules`, {
				request: 'resume',
				schedules: Object.keys(this.state.checkedSchedules)
			})
			.then((res) => {
				this.props.resumeSchedules(Object.keys(this.state.checkedSchedules));
				this.setState({ checkedSchedules: [] });
			});
	};

	cancelScheduleToggle = async () => {
		const { eventId, orgId } = this.props;
		console.log('cancelScheduleToggle', eventId, orgId);
		await Scheduler()
			.patch(`Organizations/${orgId}/schedules`, {
				request: 'cancel',
				schedules: Object.keys(this.state.checkedSchedules)
			})
			.then((res) => {
				this.props.cancelSchedules(Object.keys(this.state.checkedSchedules));
				this.setState({ checkedSchedules: [] });
			});
	};

	render() {
		const { schedules } = this.props;
		const { normalizedColumns, toggledColumns, cancelScheduleToggle } = this.state;

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Resume Schedules',
				iconClass: 'las la-trash-alt',
				onClick: this.resumeScheduleToggle
			},
			{
				type: 'button',
				label: 'Cancel Schedules',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.cancelScheduleToggle
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedSchedules).length;
		entriesLength = schedules.length;
		uncheckEntries = () => {
			this.setState({ checkedSchedules: [] });
		};
		searchEnabled = false;
		return (
			<div>
				<ActionButtonContainer
					mainActions={[]}
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>
				<ViewsTable
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={schedules}
					mainColumnActive={false}
					checked={this.state.checkedSchedules}
					checkRow={(entryId) => {
						const checked = this.state.checkedSchedules;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedSchedules(checked);
						this.setState({
							checkedSchedules: checked
						});
					}}
					mainActions={[]}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedSchedules;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedSchedules(checked);

						this.setState({
							checkedSchedules: checked
						});
					}}
					sortData={(column, sortAsc, sortDesc) => {
						/* const sortedData = this.sortData(
							column,
							sortAsc,
							sortDesc,
							editedGroup.sessions,
							normalizedColumns
						);
						this.setState({ editedGroup: { ...editedGroup, sessions: sortedData.organizedEntries } }); */
					}}
				/>
			</div>
		);
	}
}
export default EventEmailSchedules;
