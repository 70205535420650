import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import API from '../../utils/API';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ViewsTable from '../tables/ViewsTable';
import moment from 'moment';
import CheckboxInput from '../inputs/checkboxInput';
import InputField from '../inputs/inputField';
import TabsContainer from './TabsContainer';

class BasicViewContainer extends Component {
    constructor(props) {
        super(props);
        const toggleColumns = {};
        const normalizedColumns = Object.keys(props.columns).map((cfc) => {
            toggleColumns[cfc] = true;
            return {
                label: props.columns[cfc],
                key: cfc,
                value: cfc,
                type: (props.columnTypes && props.columnTypes[cfc]) || typeof props.columns[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });

        let mainView = null;
        let customViews = [];
        props.views.forEach((cv) => {
            if (!cv.isMain) {
                if (!cv.filterLayers) {
                    cv.filterLayers = [
                        {
                            column: '',
                            condition: '',
                            value: ''
                        }
                    ];
                }
                customViews.push(cv);
            } else {
                mainView = cv;
            }
        });
        delete toggleColumns[Object.keys(props.columns)[0]];
        this.state = {
            newEntries: [],
            checkedEntries: {},
            searchedEntries: [],
            allEntries: props.entries,
            organizedEntries: props.entries,
            normalizedColumns: normalizedColumns,
            toggledColumns: toggleColumns,
            allChecked: false,
            windowWidth: window.innerWidth,
            activeView: 0,
            focusedView: 0,
            searching: false,
            searchTerm: '',
            searchFocused: false,
            searchedEntries: [],
            columnSearchTerm: '',
            defaultViewFilters: [
                {
                    column: '',
                    condition: '',
                    value: ''
                }
            ],
            customViews: customViews,
            mainView: mainView,
            addViewModalOpen: false,
            duplicateViewModalOpen: false,
            deleteViewModalOpen: false,
            renameViewModalOpen: false,
            addCustomFieldModalOpen: false,
            renameCustomFieldModalOpen: false,
            deleteCustomFieldModalOpen: false,
            convertCustomFieldModalOpen: false,
            focusedCustomField: null,
            morePosition: null,
            deletedCustomField: {},
            selectedTab: 0,
        };
    }

    //search all entries properties for search term
    searchEntries = () => {
        const { organizedEntries, searchTerm, mainView, activeView, customViews } = this.state;

        const selectedCustomView =
            mainView && activeView == 0
                ? mainView
                : (!mainView && activeView == 0) || (mainView && activeView == 1)
                    ? null
                    : customViews[activeView - (mainView ? 2 : 1)];
        let columns = this.props.columns;
        if (selectedCustomView) {
            const customColumns = {};
            selectedCustomView.toggledColumns.forEach((col) => {
                customColumns[col] = columns[col];
            });
            const mainColumnKey = Object.keys(columns)[0];
            const mainColumn = columns[mainColumnKey];
            columns = customColumns;
            columns[mainColumnKey] = mainColumn;
        }

        let searchedEntries = organizedEntries;

        if (searchTerm.length > 0) {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term != '') {
                    searchedEntries = searchedEntries.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }
        this.setState({ searching: searchTerm != '', searchedEntries });
    };

    componentDidMount() {
        window.onresize = () => {
            this.setState({ windowWidth: window.innerWidth });
        };

        this.props.views.forEach((av) => {
            this.countFilteredEntries(av);
        });
        this.filterView();
        this.selectView(0);
    }

    componentDidUpdate(prevProps, prevState) {
        
        if (prevProps.entries != this.props.entries) {
            this.setState({ allEntries: this.props.entries, organizedEntries: this.props.entries }, this.filterView);
        }
    }


    setOrderOfColumns = (orderArray) => {
        const { normalizedColumns } = this.state;
        let processedColumns = [];
        let newCol = [];
        if (orderArray) {
            let sortedNormalizedColumns = [];
            let deletedColumns = [];
            let addedColumns = [];
            orderArray.map((col, i) => {
                let tempC = normalizedColumns.find(nc => nc.key == col);
                processedColumns.push(col);

                if (!tempC) {
                    deletedColumns.push(col);
                } else {
                    sortedNormalizedColumns.push(tempC);
                }

            });

            normalizedColumns.map((nc) => {
                if (!processedColumns.includes(nc.key)) {
                    sortedNormalizedColumns.push(nc);
                }
            })

            sortedNormalizedColumns.filter(c => !Object.keys(this.state.deletedCustomField).includes(c.key))

            this.setState({ normalizedColumns: sortedNormalizedColumns });
        } else {
            let sortedNormalizedColumns = Object.keys(this.props.columns).map((cfc) => {
                return {
                    label: this.props.columns[cfc],
                    key: cfc,
                    value: cfc,
                    type: (this.props.columnTypes && this.props.columnTypes[cfc]) || typeof this.props.columns[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            }).filter(c => !Object.keys(this.state.deletedCustomField).includes(c.key));
            this.setState({ normalizedColumns: sortedNormalizedColumns });
        }
    }

    makeMainView = (av) => {
        const { customViews, mainView } = this.state;
        let updatedViews = customViews;
        updatedViews = customViews.filter((a) => a._id != av._id);
        if (mainView) {
            mainView.isMain = false;
            updatedViews.push(mainView);
        }
        API()
            .patch(`/Organizations/${this.props.orgId}/views/${av._id}`, {
                isMainUpdate: true,
                isMain: true
            })
            .then((res) => {
                //this.props.setViewAsMain(av._id);
                av.isMain = true;
                this.setState({ activeView: 0, customViews: updatedViews, mainView: res.data }, this.filterView);
            });
    };

    resetDefaultView = () => {
        const { customViews, mainView } = this.state;
        let updatedViews = customViews;
        if (mainView) {
            mainView.isMain = false;
            updatedViews.push(mainView);
        }
        API()
            .patch(`/Organizations/${this.props.orgId}/views/${mainView._id}`, {
                isMainUpdate: true,
                isMain: false
            })
            .then((res) => {
                //this.props.resetMainView();
                this.setState({ activeView: 0, customViews: updatedViews, mainView: null }, this.filterView);
            });
    };

    renderToggleColumnItem = (key, toggledColumns, viewId, defaultChecked) => {
        const { columns, columnTypes, categoryColumns } = this.props;

        let categoryTitle = 'Basic';
        if (key.includes('*')) {
            let splitKey = key.split("*");
            let category = splitKey[0];
            let value = splitKey[1];
            categoryTitle = categoryColumns[category].title;
        } else if (key.includes('_')) {
            let splitKey = key.split('_');
            let category = splitKey[0];
            let value = splitKey[1];
            categoryTitle = categoryColumns[category].title;

        }
        return (
            <div className={`flex-sb flex-ac ${!key.includes('*') ? 'mr-25' : ''}`} >
                <i className="las la-braille mr-5"></i>
                <CheckboxInput
                    label={columns[key]}
                    eyebrow={categoryTitle}
                    checked={toggledColumns[key] == true || defaultChecked}
                    unsetHeight={true}
                    onClick={() => {
                        if (viewId) {
                            const mainView = this.state.mainView;
                            if (mainView && viewId == mainView._id) {
                                if (mainView.toggledColumns.includes(key)) {
                                    mainView.toggledColumns = mainView.toggledColumns.filter((col) => col !== key);
                                } else {
                                    mainView.toggledColumns.push(key);
                                }
                                mainView.unsavedColumns = true;
                                this.setState({ mainView }, this.filterView);
                            } else {
                                const customViews = this.state.customViews;
                                const vi = customViews.findIndex((view) => view._id == viewId);
                                if (vi >= 0) {
                                    const cView = this.state.customViews[vi];
                                    if (cView.toggledColumns.includes(key)) {
                                        cView.toggledColumns = cView.toggledColumns.filter((col) => col !== key);
                                    } else {
                                        cView.toggledColumns.push(key);
                                    }
                                    cView.unsavedColumns = true;
                                    customViews[vi] = cView;
                                }
                                this.setState({ customViews }, this.filterView);
                            }
                        } else {
                            const tc = this.state.toggledColumns;
                            tc[key] = !tc[key];
                            this.setState({ toggledColumns: tc });
                        }
                    }}
                />
                {key.includes('*') && (
                    <div className="mr-10">
                        <UncontrolledDropdown direction="up" inNavbar className="flex-ac fs-16">
                            <DropdownToggle className="columnToggle">
                                <i className="las la-ellipsis-h" />
                            </DropdownToggle>
                            <DropdownMenu right className="columnDropdown">
                                <DropdownItem
                                    className="min"
                                    onClick={() => {
                                        this.setState({ editCustomFieldModalOpen: true, focusedCustomField: key });
                                    }}
                                >
                                    <i className="las la-pen mr-10" /> Rename
                                </DropdownItem>

                                {!(columnTypes[key] == 'text' || columnTypes[key] == 'string') && (
                                    <DropdownItem
                                        className="min"
                                        onClick={() =>
                                            this.setState({
                                                convertCustomFieldModalOpen: true,
                                                focusedCustomField: key
                                            })}
                                    >
                                        <i className="las la-exchange-alt mr-10" /> Convert to text
                                    </DropdownItem>
                                )}
                                <DropdownItem
                                    className="min red"
                                    onClick={() => {
                                        this.setState({ deleteCustomFieldModalOpen: true, focusedCustomField: key });
                                    }}
                                >
                                    <i className="las la-trash mr-10" /> Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                )}
            </div>
        );
    };

    checkEntry = (entryId) => {
        const checked = this.state.checkedEntries;
        checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
        this.setState({
            checkedEntries: checked
        });
    };
    checkEntries = (entryIds) => {
        let checked = this.state.checkedEntries;
        entryIds.length > 0
            ? entryIds.forEach((entryId) => {
                checked[entryId] = true;
            })
            : (checked = {});
        this.setState({
            checkedEntries: checked
        });
    };

    filterView = (filterEdit) => {
        const { allEntries, activeView, customViews, mainView, defaultViewFilters } = this.state;
        let filterLayers = defaultViewFilters;

        const activeViewFilter = mainView ? activeView - 2 : activeView - 1;

        if (mainView && mainView.filterLayers && activeView == 0) {
            filterLayers = mainView.filterLayers;
        } else if (activeView > (mainView ? 1 : 0) && customViews[activeViewFilter].filterLayers) {
            filterLayers = customViews[activeViewFilter].filterLayers;
            (customViews[activeViewFilter].columnsOrder && customViews[activeViewFilter].columnsOrder.length > 0) ? this.setOrderOfColumns(customViews[activeViewFilter].columnsOrder, !customViews[activeViewFilter].unsavedColumns) : this.setOrderOfColumns();
        }
        const filteredView =
            filterLayers.length > 0 && filterLayers[0].column != ''
                ? allEntries.filter((a) => {
                    let valid = true;
                    filterLayers.forEach((layer, index) => {
                        if (a[layer.column] || a[layer.column] == false) {
                            switch (layer.condition) {
                                case 'is':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                    break;
                                case 'isnot':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                    break;
                                case 'contains':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'doesnotcontain':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'isempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == '')
                                            : valid || (!a[layer.column] || a[layer.column] == '');
                                    break;
                                case 'isnotempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] && a[layer.column] != '')
                                            : valid || (a[layer.column] && a[layer.column] != '');
                                    break;
                                case 'isdatelaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                            : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                    break;
                                case 'isdateearlierthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                            : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                    break;
                                case 'isdateequalto':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                            : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                    break;
                                case 'isupcoming':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment())
                                            : valid || moment(a[layer.column]).isAfter(moment());
                                    break;
                                case 'ispast':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment())
                                            : valid || moment(a[layer.column]).isBefore(moment());
                                    break;

                                case 'istoday':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY')
                                            : valid ||
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY');
                                    break;
                                case 'isyes':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] || a[layer.column] == true)
                                            : valid || (a[layer.column] || a[layer.column] == true);
                                    break;
                                case 'isno':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == false)
                                            : valid || (!a[layer.column] || a[layer.column] == false);
                                    break;
                                case 'isnumbergreaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) > layer.value
                                            : valid || parseInt(a[layer.column]) > layer.value;
                                    break;
                                case 'isnumberlessthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) < layer.value
                                            : valid || parseInt(a[layer.column]) < layer.value;
                                    break;
                                default:
                            }
                        } else {
                            valid = false;
                        }
                    });
                    return valid;
                })
                : allEntries;
        if (mainView && activeView == 0) {
            mainView.count = filteredView.length;
            if (filterEdit) {
                mainView.unsavedFilter = true;
            }
            this.setState({
                organizedEntries: filteredView,
                mainView: mainView
            });
        } else if (activeView > (mainView ? 1 : 0)) {
            customViews[activeViewFilter].count = filteredView.length;
            if (filterEdit) {
                customViews[activeViewFilter].unsavedFilter = true;
            }
            this.setState({
                organizedEntries: filteredView,
                customViews: customViews
            });
        } else {
            this.setState({ organizedEntries: filteredView });
        }
    };

    countFilteredEntries = async (view) => {
        const { allEntries, mainView } = this.state;
        const filteredView =
            view.filterLayers.length > 0 && view.filterLayers[0].column != ''
                ? allEntries.filter((a) => {
                    let valid = true;
                    view.filterLayers.forEach((layer, index) => {
                        if (a[layer.column] || a[layer.column] == false) {
                            switch (layer.condition) {
                                case 'is':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                    break;
                                case 'isnot':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                    break;
                                case 'contains':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'doesnotcontain':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'isempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == '')
                                            : valid || (!a[layer.column] || a[layer.column] == '');
                                    break;
                                case 'isnotempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] && a[layer.column] != '')
                                            : valid || (a[layer.column] && a[layer.column] != '');
                                    break;
                                case 'isdatelaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                            : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                    break;
                                case 'isdateearlierthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                            : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                    break;
                                case 'isdateequalto':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                            : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                    break;
                                case 'isupcoming':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment())
                                            : valid || moment(a[layer.column]).isAfter(moment());
                                    break;
                                case 'ispast':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment())
                                            : valid || moment(a[layer.column]).isBefore(moment());
                                    break;
                                case 'istoday':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY')
                                            : valid ||
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY');
                                    break;
                                case 'isyes':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] || a[layer.column] == true)
                                            : valid || (a[layer.column] || a[layer.column] == true);
                                    break;
                                case 'isno':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == false)
                                            : valid || (!a[layer.column] || a[layer.column] == false);
                                    break;
                                case 'isnumbergreaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) > layer.value
                                            : valid || parseInt(a[layer.column]) > layer.value;
                                    break;
                                case 'isnumberlessthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) < layer.value
                                            : valid || parseInt(a[layer.column]) < layer.value;
                                    break;
                                default:
                            }
                        } else {
                            valid = false;
                        }
                    });
                    return valid;
                })
                : allEntries;
        if (mainView && mainView._id == view._id) {
            mainView.count = filteredView.length;
            this.setState({ mainView: mainView });
        } else {
            const customViews = this.state.customViews;
            const viewIndex = customViews.findIndex((a) => a._id == view._id);
            customViews[viewIndex] && (customViews[viewIndex].count = filteredView.length);
            this.setState({ customViews: customViews });
        }
    };

    selectView = (viewIndex) => {
        this.setState({ activeView: viewIndex, searchTerm: '', searching: false, checkedEntries: {} }, () => {
            let viewsList = [];
            viewsList.push(this.state.mainView);
            this.props.updateSelectedViewIndex && this.props.updateSelectedViewIndex(viewIndex, [...viewsList, ...this.state.customViews], this.props.entries.length);
            this.filterView();
        });
    };

    addView = (view) => {
        const { customViews } = this.state;
        customViews.push(view);
        this.setState({ customViews }, () => {
            this.filterView();
            this.countFilteredEntries(view);
        });
        //this.props.addView(view);
    };

    updateFocusedView = (updates) => {
        const { focusedView, customViews, mainView } = this.state;
        let fView = mainView && focusedView == 0 ? mainView : customViews[focusedView - 1];
        fView = { ...fView, ...updates };
        if (mainView && focusedView == 0) {
            this.setState({ mainView: fView }, this.filterView);
        } else {
            const customViewIndex = customViews.findIndex((a) => a._id == fView._id);
            customViews[customViewIndex] = fView;
            this.setState({ customViews }, this.filterView);
        }
        //this.props.updateView(fView);
    };

    removeFocusedView = (viewId) => {
        const { customViews, mainView } = this.state;
        if (mainView && mainView._id == viewId) {
            this.setState({ mainView: null, activeView: 0 }, this.filterView);
        } else {
            const customViewIndex = customViews.findIndex((a) => a._id == viewId);
            customViews.splice(customViewIndex, 1);
            this.setState({ customViews, activeView: 0 }, this.filterView);
        }
        //this.props.removeView(viewId);
    };

    saveCurrentView = async (filters, columns) => {
        const { activeView, customViews, mainView, normalizedColumns } = this.state;
        const view = mainView && activeView == 0 ? mainView : customViews[activeView - (mainView ? 2 : 1)];
        const payload = {};
        let sortedColumns = [];
        normalizedColumns.map((nc) => {
            sortedColumns.push(nc.key);
        })

        if (
            filters &&
            view.filterLayers.length > 0 &&
            view.filterLayers[0].condition &&
            view.filterLayers[0].condition != ''
        ) {
            payload.filterLayers = view.filterLayers;
        } else if (filters && view.filterLayers.length > 0) {
            payload.filterLayers = [];
        }
        if (columns) {
            payload.toggledColumns = view.toggledColumns;
        }
        payload.columnsOrder = sortedColumns;

        await API()
            .patch(`/Organizations/${this.props.orgId}/views/${view._id}`, payload)
            .then((res) => {
                //this.setState({ submitting: false });
                //this.props.updateView(res.data);
                //find view and remove unsaved property
                if (mainView && activeView == 0) {
                    if (filters) mainView.unsavedFilter = false;
                    if (columns) mainView.unsavedColumns = false;
                    this.setState({ mainView });
                } else {
                    if (filters) customViews[activeView - (mainView ? 2 : 1)].unsavedFilter = false;
                    if (columns) customViews[activeView - (mainView ? 2 : 1)].unsavedColumns = false;

                    this.setState({ customViews });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    sortData = (column, sortAsc, sortDesc) => {
        const { organizedEntries, normalizedColumns } = this.state;
        const updatedColumns = normalizedColumns.map((col) => {
            if (col.key == column.key) {
                col.sortAsc = sortAsc;
                col.sortDesc = sortDesc;
            } else {
                col.sortAsc = false;
                col.sortDesc = false;
            }
            return col;
        });

        let reorganizedData = organizedEntries.sort((a, b) => {
            const columnType = column.type || typeof a[column.key];
            if (a[column.key] && b[column.key]) {
                switch (columnType) {
                    case 'string':
                        return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
                    case 'number':
                        return a[column.key] - b[column.key];
                    case 'currency':
                        return a[column.key] - b[column.key];
                    case 'date':
                    case 'time':
                    case 'timestamp':
                        return new Date(a[column.key]).getTime() - new Date(b[column.key]).getTime();
                    case 'boolean':
                        const a1 = a[column.key] ? 'yes' : 'no';
                        const b1 = b[column.key] ? 'yes' : 'no';
                        return a1.localeCompare(b1);
                    case 'bool':
                        const c1 = a[column.key] ? 'yes' : 'no';
                        const d1 = b[column.key] ? 'yes' : 'no';
                        return c1.localeCompare(d1);
                    default:
                        return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
                }
            } else if (a[column.key]) {
                return 1;
            }
            return -1;
        });
        reorganizedData = sortDesc ? reorganizedData.reverse() : reorganizedData;
        this.setState({ organizedEntries: reorganizedData, normalizedColumns: updatedColumns }, this.searchEntries);
    };

    s2ab = (s) => {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
    };

    downloadReport = async () => {
        const { activeView, customViews, mainView, organizedEntries, allEntries } = this.state;
        const selectedCustomView =
            mainView && activeView == 0
                ? mainView
                : (!mainView && activeView == 0) || (mainView && activeView == 1)
                    ? mainView
                    : customViews[activeView - (mainView ? 2 : 1)];
        let columns = this.props.columns;
        if (selectedCustomView) {
            const customColumns = {};
            if (selectedCustomView.columnsOrder && selectedCustomView.columnsOrder.length > 0) {
                let columnsOrder = selectedCustomView.columnsOrder;
                let toggledC = selectedCustomView.toggledColumns;

                columnsOrder.forEach(co => {
                    if (toggledC.includes(co)) {
                        if (columns[co]) {
                            customColumns[co] = columns[co];
                        }
                    }
                })
                columns = {};
                const mainColumn = Object.keys(this.props.columns)[0];
                columns[mainColumn] = this.props.columns[mainColumn];
                columns = { ...columns, ...customColumns };
            } else {
                //legacy
                selectedCustomView.toggledColumns.forEach((col) => {
                    if (columns[col]) {
                        customColumns[col] = columns[col];
                    }
                });
                columns = {};
                const mainColumn = Object.keys(this.props.columns)[0];
                columns[mainColumn] = this.props.columns[mainColumn];
                columns = { ...columns, ...customColumns };
            }

        } else {
            const customColumns = {};
            Object.keys(this.state.toggledColumns).forEach((col) => {
                customColumns[col] = columns[col];
            });
            columns = {};
            const mainColumn = Object.keys(this.props.columns)[0];
            columns[mainColumn] = this.props.columns[mainColumn];
            columns = { ...columns, ...customColumns };
        }
        columns = { ...columns };
        const entries = organizedEntries; //selectedCustomView ? organizedEntries : allEntries;
        const dataRows = [];
        entries.forEach((entry) => {
            const dataRow = {};
            Object.keys(columns).forEach((hc) => {
                if (entry[hc] != null) {
                    if (this.props.columnTypes[hc] === 'boolean' || this.props.columnTypes[hc] === 'bool') {
                        dataRow[hc] = entry[hc] ? 'Yes' : 'No';
                    } else {
                        dataRow[hc] = entry[hc];
                    }
                }
            });
            dataRows.push(dataRow);
        });
        const cleanData = [];
        const headers = Object.keys(columns).map((header, hIndex) => {
            dataRows.forEach((data, dIndex) => {
                if (!cleanData[dIndex]) cleanData[dIndex] = [];
                cleanData[dIndex][hIndex] = data[header] || '';
            });
            return columns[header];
        });
        const data = {
            headerRow: headers,
            dataRows: cleanData
        };

        const reportDetails = selectedCustomView
            ? { Title: selectedCustomView.name, Subject: selectedCustomView.name }
            : { Title: 'View Report', Subject: 'View Report' };
        this.downloadRegular(reportDetails, data);
    };

    downloadRegular = (report, data) => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: report.Title,
            Subject: report.Subject,
            Author: 'Simple Events',
            CreatedDate: new Date()
        };
        wb.SheetNames.push('Data');
        /* make worksheet */
        var ws_data2 = [data.headerRow, ...data.dataRows];
        var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

        /* Add the worksheet to the workbook */
        wb.Sheets['Data'] = ws2;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        saveAs(
            new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
            `${report.Title}_${moment().format('LLL')}.xlsx`
        );
    };

    addField = (field, cb) => {
        this.props.addField(field, () => {
            const newField = field.fields[field.fields.length - 1];
            const toggleColumns = this.state.toggledColumns;
            const normalizedColumns = this.state.normalizedColumns;
            const cfc = field._id + '*' + newField._id;
            toggleColumns[cfc] = true;
            normalizedColumns.push({
                label: this.props.columns[cfc],
                key: cfc,
                value: cfc,
                type: (this.props.columnTypes && this.props.columnTypes[cfc]) || typeof this.props.columns[cfc],
                sortAsc: false,
                sortDesc: false
            });
            this.setState({ toggledColumns: toggleColumns, normalizedColumns: normalizedColumns }, cb);
        });
    };

    deleteField = (field, cb) => {
        this.props.deleteField(field, () => {
            const toggleColumns = this.state.toggledColumns;
            const normalizedColumns = this.state.normalizedColumns;
            const cfc = field._id + '*' + field.fields[0]._id;
            delete toggleColumns[cfc];
            normalizedColumns.splice(normalizedColumns.findIndex((col) => col.key === cfc), 1);
            this.setState({ toggledColumns: toggleColumns, normalizedColumns: normalizedColumns }, cb);
        });
    };

    renameField = (field, cb) => {
        this.props.updateField(field, () => {
            let normalizedColumns = this.state.normalizedColumns;
            field.fields.forEach((f) => {
                const cfc = field._id + '*' + f._id;
                normalizedColumns = normalizedColumns.map((col) => {
                    if (col.key === cfc) {
                        col.label = this.props.columns[cfc];
                    }
                    return col;
                });
            });
            this.setState({ normalizedColumns: normalizedColumns }, cb);
        });
    };

    convertField = (field, cb) => {
        this.props.updateField(field, () => {
            let normalizedColumns = this.state.normalizedColumns;
            field.fields.forEach((f) => {
                const cfc = field._id + '*' + f._id;
                normalizedColumns = normalizedColumns.map((col) => {
                    if (col.key === cfc) {
                        col.type = 'text';
                    }
                    return col;
                });
            });
            this.setState({ normalizedColumns: normalizedColumns }, cb);
        });
    };

    removeField = (field, cb) => {
        this.props.removeField(field, () => {
            let normalizedColumns = this.state.normalizedColumns;
            field.fields.forEach((f) => {
                normalizedColumns = normalizedColumns.filter((col) => col.key !== this.state.focusedCustomField);
            });

            let tempDeletedCustomField = this.state.deletedCustomField;
            tempDeletedCustomField[this.state.focusedCustomField] = true;

            this.setState({ normalizedColumns: normalizedColumns, deletedCustomField: tempDeletedCustomField }, cb);
        });
    };

    onTabSelect = (tabIndex) => {
        const { onTabsChange } = this.props;
        const updatedEntries = onTabsChange(tabIndex);
        this.setState({
            selectedTab: tabIndex,
            organizedEntries: updatedEntries,
            allEntries: updatedEntries,
        },);
    }

    render() {
        const {
            checkedEntries,
            normalizedColumns,
            organizedEntries,
            searching,
            searchedEntries,
            searchTerm,
            selectedTab
        } = this.state;
        const {
            subtitle,
            title,
            mainActions,
            batchActions,
            enableDownloadViews,
            breadcrumbs,
            style,
            viewPanelActions,
            summaryPanel,
            summaryPanelEnabled,
            tabs
        } = this.props;
        /* let deletedEntries = this.props.deletedEntries || [];
        deletedEntries.forEach((id) => delete checkedEntries[id]); */
        let checkedEntryCount = Object.keys(checkedEntries).length;

        let toggledColumns = this.state.toggledColumns;
        let entryTable = searching ? searchedEntries : organizedEntries;

        let mainActionCallback = (cb) => {
            const updatedEntries = this.props.entries;

            switch (cb) {
                case 'delete':
                    this.setState(
                        {
                            organizedEntries: updatedEntries,
                            allEntries: updatedEntries,
                            checkedEntries: {}
                        },
                        () => {
                            this.filterView();
                            this.searchEntries();
                            this.props.views.forEach((av) => {
                                this.countFilteredEntries(av);
                            });
                        }
                    );
                    break;
                case 'update':
                    this.setState(
                        {
                            organizedEntries: updatedEntries,
                            allEntries: updatedEntries,
                            checkedEntries: {}
                        },
                        () => {
                            this.filterView();
                            this.searchEntries();
                            this.props.views.forEach((av) => {
                                this.countFilteredEntries(av);
                            });
                        }
                    );
                    break;
                default:
                    this.checkEntries([]);




            }
        }

        return (
            <div className={`sectionContainer`}>
                <div className="sectionHeader ns mb-10" style={style}>
                    <div className="sectionTitleBar">
                        <div className="sectionTitle">
                            {((breadcrumbs && breadcrumbs.length > 0) || subtitle) && (
                                <div>
                                    {breadcrumbs &&
                                        breadcrumbs.length > 0 && (
                                            <Link to={`${breadcrumbs.length > 1 ? './' : window.location.href.endsWith('/') ? '../../' : '../'}`} className={` breadCrumbContainer ${breadcrumbs.length > 1 ? 'internal' : ''}`} >
                                                {breadcrumbs.map((b, i) => (
                                                    <div key={i} className="breadCrumb flex-ac">
                                                        {i > 0 && (
                                                            <div className="breadCrumbSeparator">
                                                                /
                                                            </div>
                                                        )}
                                                        <p className="small">
                                                            {b.name}
                                                        </p>
                                                    </div>
                                                ))}
                                            </Link>
                                        )}
                                    {subtitle && <p className="small">{subtitle}</p>}
                                </div>
                            )}
                            {title && <h1 className="sectionName">{title}</h1>}
                            {/* description && <p className="sectionDescription">{description}</p> */}

                        </div>
                        <div className='ml-a posRel'>
                            {summaryPanelEnabled && <div style={{ position: 'absolute', right: 0, minWidth: 'max-content' }}>{summaryPanel && summaryPanel}</div>}
                        </div>
                    </div>
                </div>
                <div className='sectionsContainer'>

                    <div className={`sectionContainer ${checkedEntryCount > 0 ? ' editing' : ''}`}>

                        {tabs && tabs.length > 0 && <div className="sectionHeader">

                            <TabsContainer
                                tabs={tabs}
                                updateSelected={this.onTabSelect}
                                selectedIndex={selectedTab}
                            />

                        </div>}

                        <div className="sectionBody isTable p-0">
                            <div className="actionButtonContainer headerHeight  flex-ac">

                                {mainActions.length > 0 && (<div className="flex-ac mr-10">
                                    <div className="mainActionContainer">
                                        {mainActions[0].type == 'link' ? (
                                            <Link to={mainActions[0].target} className="mainAction">
                                                {mainActions[0].label}
                                            </Link>
                                        ) : (
                                            <div className="mainAction" onClick={() => mainActions[0].onClick(mainActionCallback)}>
                                                {mainActions[0].label}
                                            </div>
                                        )}
                                        {mainActions.length > 1 && (
                                            <UncontrolledDropdown inNavbar>
                                                <DropdownToggle className="columnToggle">
                                                    <i className="las la-angle-down" />
                                                </DropdownToggle>
                                                <DropdownMenu className="columnDropdown">
                                                    <div className='ph-20'>
                                                        <h5 className="mb-10">Options</h5>
                                                        {mainActions.map((action, index) => {
                                                            if (index > 0) {
                                                                return action.type == 'link' ? (
                                                                    <DropdownItem key={`${action.type}-${index}`}>
                                                                        <Link
                                                                            to={action.target}
                                                                            className="black"
                                                                        >
                                                                            {action.label}
                                                                        </Link>
                                                                    </DropdownItem>
                                                                ) : (
                                                                    <DropdownItem
                                                                        onClick={() => action.onClick(mainActionCallback)}
                                                                        key={`${action.type}-${index}`}
                                                                    >
                                                                        {action.label}
                                                                    </DropdownItem>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        )}
                                    </div>
                                </div>
                                )}
                                <InputField
                                    classes="m-0 viewSearch"
                                    placeholder="Search "
                                    clickIcon={true}
                                    required={true}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        clearTimeout(this.searchTimeout);
                                        this.setState({ searchTerm: e.target.value });
                                        this.searchTimeout = setTimeout(() => this.searchEntries(), 1000);
                                    }}
                                    prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                    inputIcon={searchTerm != '' &&
                                        <i
                                            onMouseDown={() => this.setState({ searchTerm: '', searching: false })}
                                            //onClick={() => this.setState({ searchTerm: '', searching: false })}
                                            className="las la-times-circle"
                                            style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                        />}
                                />

                                <div className="sectionOptionsContainer">
                                    {viewPanelActions && viewPanelActions.length > 0 &&
                                        viewPanelActions.map((sa, index) => {
                                            return sa.iconClass && <><i id={`viewAction-${index}`} onClick={sa.onClick} className={` ${sa.iconClass}`} />
                                                <UncontrolledTooltip target={`viewAction-${index}`} placement="left">{sa.label}</UncontrolledTooltip></>
                                        })
                                    }
                                    {enableDownloadViews && (<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
                                        <i className="las la-download" />
                                        <UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex h-100 hasHeader'>
                                <ViewsTable
                                    title={title}
                                    rowSettings={this.props.rowSettings}
                                    columns={normalizedColumns}
                                    toggledColumns={toggledColumns}
                                    data={entryTable}
                                    mainColumnActive={true}
                                    checked={checkedEntries}
                                    checkRow={this.checkEntry}
                                    checkAllRows={this.checkEntries}
                                    deletedRows={this.props.deletedEntries}
                                    sortData={this.sortData}
                                    defaultSort={this.props.defaultSort}
                                    sortDirection={this.props.sortDirection}
                                />
                                {this.props.viewPanel}
                            </div>
                        </div>

                        <div className={`sectionFooter ph-20 ${batchActions &&
                            batchActions.length > 0 &&
                            checkedEntryCount > 0 ? 'editing' : ''}`} >
                            <div className='flex aic jcsb'><p style={{ width: 200 }} className='small gray mb-0 mr-25'>{checkedEntryCount} selected out of {entryTable.length} record{entryTable.length == 1 ? '' : 's'}</p>
                                <button style={{ padding: 7 }} onClick={() => {
                                    this.setState({ checkedEntries: {} })
                                }} className={`mr-25 neu ${checkedEntryCount > 0 ? '' : 'invisible'}`}><i className="las la-times" style={{ margin: 0 }} /></button>
                            </div>
                            <div className="flex-ac footerActionContainer">
                                <p className='mb-0 mr-25 lightGray'>|</p>
                                {batchActions.map((action) => (
                                    <button
                                        className={` neu ${action.class} mr-15`}
                                        onClick={() =>
                                            action.onClick(checkedEntries, (action) => {
                                                const updatedEntries = this.props.entries;
                                                switch (action) {
                                                    case 'delete':
                                                        this.setState(
                                                            {
                                                                organizedEntries: updatedEntries,
                                                                allEntries: updatedEntries,
                                                                checkedEntries: {}
                                                            },
                                                            () => {
                                                                this.filterView();
                                                                this.searchEntries();
                                                                this.props.views.forEach((av) => {
                                                                    this.countFilteredEntries(av);
                                                                });
                                                            }
                                                        );
                                                        break;
                                                    case 'update':
                                                        this.setState(
                                                            {
                                                                organizedEntries: updatedEntries,
                                                                allEntries: updatedEntries,
                                                                checkedEntries: {}
                                                            },
                                                            () => {
                                                                this.filterView();
                                                                this.searchEntries();
                                                                this.props.views.forEach((av) => {
                                                                    this.countFilteredEntries(av);
                                                                });
                                                            }
                                                        );
                                                        break;
                                                    default:
                                                        this.checkEntries([]);
                                                }
                                            })}
                                    >
                                        {action.iconClass &&
                                            action.iconClass != '' && <i className={`${action.iconClass} mr-10`} />}
                                        {action.pluralLabel ? checkedEntryCount > 1 ? (
                                            action.pluralLabel
                                        ) : (
                                            action.label
                                        ) : (
                                            action.label
                                        )}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div >
        );
    }
}

export default BasicViewContainer;
