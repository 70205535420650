import React, { Fragment, Component } from 'react';
import { CircleLoader } from 'react-spinners';
import API from '../../../../utils/API';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Input,
	Button,
	Row,
	FormGroup,
	Label,
	Table,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';
import { CheckCircle, X, ChevronDown } from 'react-feather';
import 'react-toggle/style.css';
import axios from 'axios';
import moment from 'moment';
class SendEmailModal extends Component {
	constructor(props) {
		super(props);
		const attendees = props.attendees.map((a) => {
			let latestDate = '-';
			const recievedCount = props.email.sentEmails.filter((e) => {
				if (e.attendeeId == a._id) {
					if (latestDate === '-') {
						latestDate = e.sentDate;
					} else if (new Date(e.sentDate).getTime() > new Date(latestDate).getTime()) {
						latestDate = e.sentDate;
					}
					return true;
				} else {
					return false;
				}
			}).length;
			return {
				...a,
				recievedCount: recievedCount,
				latestDate: latestDate
			};
		});
		this.state = {
			orgId: props.orgId,
			eventId: props.eventId,
			attendeeCount: props.attendeeCount,
			checkedAttendees: {},
			allChecked: false,
			sentEmails: [],
			attendeeGroups: props.groups.filter((g) => g.attendees.length > 0),
			groupSelected: {},
			groupAttendees: [],
			attendees: attendees,
			sessionSelected: {},
			loading: false,
			sent: false,
			sending: false,
			startsWithName: false,
			sendTestEmail: false
		};
	}

	fetchMoreAttendees = async (callback) => {
		const { orgId, eventId } = this.props;
		this.setState({ loading: true });
		let queryUrl = `Organizations/${orgId}/events/${eventId}/attendees?a=${this.props.attendees.length}`;
		await API().get(queryUrl).then((res) => {
			if (res.data) {
				this.props.loadMoreAttendees(res.data);
				const attendees = this.props.attendees.map((a) => {
					let latestDate = '-';
					const recievedCount = this.props.email.sentEmails.filter((e) => {
						if (e.attendeeId == a._id) {
							if (latestDate === '-') {
								latestDate = e.sentDate;
							} else if (new Date(e.sentDate).getTime() > new Date(latestDate).getTime()) {
								latestDate = e.sentDate;
							}
							return true;
						} else {
							return false;
						}
					}).length;
					return {
						...a,
						recievedCount: recievedCount,
						latestDate: latestDate
					};
				});
				this.setState({ loading: false, attendees: attendees }, callback);
			}
		});
	};

	filterAttendees = (attendees) => {
		const { selectedGroups } = this.state;
		return attendees.filter(
			(a) => this.props.groups.filter((g) => g.attendees.includes(a._id) && selectedGroups[g._id]).length > 0
		);
	};

	selectGroup = async (group) => {
		const groupAttendees = this.state.attendees.filter((attendee) => group.attendees.includes(attendee._id));
		this.setState({ groupSelected: group, groupAttendees: groupAttendees });
	};

	deselectGroup = () => {
		this.setState({ groupSelected: {}, groupAttendees: [] });
	};
	deselectSession = () => {
		this.setState({ sessionSelected: {} });
	};

	selectSession = async (session) => {
		this.setState({ sessionSelected: session });
	};

	/* 
	searchAttendees = async (term) => {
		const searchedAttendees = this.state.groupSelected._id ? this.state.groupAttendees.filter(attendee => attendee.firstName.includes(term) || attendee.lastName.includes(term)) : this.state.attendees.filter(attendee => attendee.firstName.includes(term) || attendee.lastName.includes(term));
		if()
	} */

	AttendeeItem(attendee, index) {
		const attendeeEmails = this.props.email.sentEmails
			.filter((se) => se.attendeeId._id == attendee._id)
			.sort((a, b) => b.sentDate - a.sentDate);
		return (
			<div key={index} className="tableColumnRow">
				<div
					className={`checkBox mr-10 ${this.state.checkedAttendees[attendee._id] || false ? 'checked' : ''}`}
					onClick={() => {
						const checked = this.state.checkedAttendees;
						checked[attendee._id] ? delete checked[attendee._id] : (checked[attendee._id] = attendee);
						this.setState({
							checkedAttendees: checked,
							allChecked: Object.keys(checked).length === this.props.attendeeCount
						});
					}}
				>
					<i className="las la-check" />
				</div>

				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">
					{`${attendee.firstName} ${attendee.lastName}`}
				</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">{attendee.email}</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">
					{attendeeEmails.length > 0 ? `Yes (${attendeeEmails.length})` : 'No'}
				</div>
				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">
					{attendeeEmails && attendeeEmails.length > 0 ? attendeeEmails[0].sentDate : ''}
				</div>
			</div>
		);
	}
	checkAllAttendees = async () => {
		if (!this.state.allChecked && !this.state.groupSelected._id) {
			const ids = {};
			this.state.attendees.forEach((a) => (ids[a._id] = a));
			this.setState({ checkedAttendees: ids, allChecked: true });
		} else if (!this.state.allChecked && this.state.groupSelected._id) {
			const ids = {};
			this.state.groupAttendees.forEach(
				(a) => (ids[a._id] = this.state.attendees.find((at) => at._id.toString() === a._id.toString()))
			);
			this.setState({ checkedAttendees: ids, allChecked: true });
		} else {
			this.setState({ checkedAttendees: {}, allChecked: false });
		}
	};

	resetAdd = () => {
		const selectedGroups = {};
		this.props.groups.forEach((g) => {
			selectedGroups[g._id] = true;
		});

		const attendees = this.props.attendees.map((a) => {
			let latestDate = '-';
			const recievedCount = this.props.email.sentEmails.filter((e) => {
				if (e.attendeeId == a._id) {
					if (latestDate === '-') {
						latestDate = e.sentDate;
					} else if (new Date(e.sentDate).getTime() > new Date(latestDate).getTime()) {
						latestDate = e.sentDate;
					}
					return true;
				} else {
					return false;
				}
			}).length;
			return {
				...a,
				recievedCount: recievedCount,
				latestDate: latestDate
			};
		});
		this.state = {
			checkedAttendees: {},
			allChecked: false,
			sentEmails: [],
			selectedGroups: selectedGroups,
			attendees: attendees,
			loading: false,
			sent: false,
			sending: false,
			startsWithName: false,
			sendFromDedicatedServer: false
		};
		this.props.toggle();
	};

	/* 
	sendOutTestEmail = async () => {
		this.setState({sending});
		await this.sendEmail(testEmail)
	}
 */
	sendDedicatedEmail = async (attendee) => {
		const { email, orgId, eventId } = this.props;
		axios.defaults.headers.post['Content-Type'] = 'application/json';
		const request = {};
		email.parameters.forEach((param) => {
			if (param.includes('metadata')) {
				const breakdown = param.split('.');
				const categoryId = breakdown[1];
				const fieldId = breakdown[2];
				const varName = breakdown[3];
				const data = attendee.metadata.find((m) => m.categoryId == categoryId && m.fieldId == fieldId);
				if (data) {
					request[varName] = data.fieldValue;
				} else {
					return;
				}
			} else {
				request[param] = attendee[param];
			}
		});
		const sentDate = moment().format('MM/DD/YYYY hh:mm a');
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/emails/${this.props.email._id}/sendDedicated`, {
				attendeeId: attendee._id,
				sentDate,
				status: 'sent'
			})
			.then(async (res1) => {
				if (res1.data) {
					this.setState({ sentEmails: [ ...this.state.sentEmails, res1.data ] });
					request['id'] = `${this.props.email._id}-${res1.data._id}`;
					request['email'] = `${attendee.email}`;
					await axios
						.post(email.serviceUrl, request)
						.then((res2) => {
							if (res2.data !== 1) {
								API().post(`tracking/emails/${this.props.email._id}/update/${res1.data._id}`, {
									status: 'bounced'
								});
							}
						})
						.catch((e) => {
							API().post(`tracking/emails/${this.props.email._id}/update/${res1.data._id}`, {
								status: 'bounced'
							});
						});
				}
			});
	};

	sendEmail = async (attendee) => {
		const { email, orgId, eventId } = this.props;
		await API().post(`Organizations/${orgId}/events/${eventId}/emails/${email._id}/send`, {
			attendeeId: attendee._id
		});
	};

	sendOutEmails = async (attendeeIds) => {
		const { sessionSelected, startsWithName, sendFromDedicatedServer, sendTestEmail } = this.state;
		const { email, orgId, eventId } = this.props;
		if (
			(sessionSelected._id && email.parameters.find((p) => p.includes('session'))) ||
			(!sessionSelected._id && !email.parameters.find((p) => p.includes('session')))
		) {
			this.setState({ sending: true });
			for (let ai = 0; ai < attendeeIds.length; ai++) {
				const attendeeId = attendeeIds[ai];
				const attendee = this.state.checkedAttendees[attendeeId];
				if (sendFromDedicatedServer) {
					try {
						await this.sendDedicatedEmail(attendee);
					} catch (err) {
						console.log(err);
					}
				} else {
					try {
						const res = await API().post(
							`Organizations/${orgId}/events/${eventId}/emails/${email._id}/send`,
							sessionSelected._id
								? {
										attendeeId: attendeeId,
										sessionId: sessionSelected._id,
										startsWithName: startsWithName,
										isTest: sendTestEmail
									}
								: {
										attendeeId: attendeeId,
										startsWithName: startsWithName,
										isTest: sendTestEmail
									}
						);
						this.setState({ sentEmails: [ ...this.state.sentEmails, res.data ] });
					} catch (err) {
						console.log(err);
					}
				}
			}
			email.sentEmails = [ ...email.sentEmails, ...this.state.sentEmails ];
			this.props.submit(email);
			this.setState({ sent: true });
		}
	};

	render() {
		const {
			loading,
			sending,
			checkedAttendees,
			attendees,
			sent,
			sentEmails,
			attendeeGroups,
			groupSelected,
			sessionSelected,
			groupAttendees,
			startsWithName,
			sendFromDedicatedServer,
			sendTestEmail
		} = this.state;
		const { email, isOpen, toggle, sessions } = this.props;

		const availableAttendees = groupSelected._id ? groupAttendees : attendees; //this.filterAttendees(attendees);

		return (
			<Modal id="inviteModal" isOpen={isOpen} toggle={this.reset} style={{ marginTop: '15vh' }}>
				<ModalHeader className="modalHeader">
					{' '}
					<div className="calibreBold fs-30">Send {email.name} to Attendees</div>
				</ModalHeader>
				{sending ? (
					<div style={{ width: '100%', textAlign: 'center', paddingTop: 50, paddingBottom: 50 }}>
						{!sent || sentEmails.length !== Object.keys(checkedAttendees).length ? (
							<div style={{ margin: 'auto' }}>
								<CircleLoader
									className="clip-loader"
									sizeUnit={'px'}
									size={90}
									color={'blue'}
									loading={true}
								/>
								<p style={{ marginTop: 20 }}>{sentEmails.length} Sent</p>
							</div>
						) : (
							<div style={{ margin: 'auto' }}>
								<CheckCircle sizeUnit={'px'} size={90} color={'green'} />
								<p className="calibreRegular fs-20" style={{ marginTop: 20 }}>
									{sentEmails.length}/ {Object.keys(checkedAttendees).length} Emails sent
								</p>
							</div>
						)}{' '}
					</div>
				) : (
					<ModalBody className="modalBody">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={{ textAlign: 'left', marginBottom: 10 }}>
								{/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
									<label className="displayLabel">Send personalized subject:</label>

									<div
										style={{
											margin: 0,
											position: 'relative',
											marginLeft: 25,
											display: 'list-item'
										}}
									>
										<Input
											type="checkbox"
											value={startsWithName}
											onChange={() =>
												this.setState({ startsWithName: !this.state.startsWithName })}
											className="pr-1"
										/>
									</div>
								</div> */}
								{email.dedicatedEmailServerEnabled && (
									<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
										<label className="displayLabel">Send from dedicated server: </label>
										<div
											style={{
												margin: 0,
												position: 'relative',
												marginLeft: 25,
												display: 'list-item'
											}}
										>
											<Input
												type="checkbox"
												style={{ background: 'black' }}
												value={sendFromDedicatedServer}
												onChange={() =>
													this.setState({
														sendFromDedicatedServer: !this.state.sendFromDedicatedServer
													})}
												className="pr-1"
											/>
										</div>
									</div>
								)}
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
									<label className="displayLabel">Send Test Email: </label>
									<div
										style={{
											margin: 0,
											position: 'relative',
											marginLeft: 25,
											display: 'list-item'
										}}
									>
										<Input
											type="checkbox"
											style={{ background: 'black' }}
											value={sendTestEmail}
											onChange={() =>
												this.setState({
													sendTestEmail: !this.state.sendTestEmail
												})}
											className="pr-1"
										/>
									</div>
								</div>
							</div>

							<div style={{ textAlign: 'right', marginBottom: 10 }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
									<UncontrolledDropdown inNavbar className="pr-1" style={{ height: '35px' }}>
										<DropdownToggle
											outline
											style={{
												color: sessionSelected._id ? 'white' : '#2b83f1',
												backgroundColor: sessionSelected._id ? '#2b83f1' : 'white',
												border: '1px solid #2b83f1'
											}}
										>
											<div style={{ display: 'flex' }}>
												<div
													style={{
														fontWeight: 'bold',
														fontSize: 14
													}}
												>
													{sessionSelected._id && (
														<CheckCircle
															size={16}
															color="white"
															style={{ margin: 'auto' }}
														/>
													)}{' '}
													Session
													<ChevronDown size={12} style={{ margin: 'auto' }} />
												</div>
											</div>
										</DropdownToggle>
										<DropdownMenu
											right
											className="notification-dropdown"
											style={{
												width: 'fit-content',
												width: 200,
												maxHeight: 500,
												overflowY: 'scroll'
											}}
										>
											{sessions.map((session) => {
												return (
													<div
														style={{
															color:
																session._id == sessionSelected._id
																	? '#00000033'
																	: '#000000',
															padding: '8px 12px'
														}}
														className="calibreRegular"
														onClick={() => {
															session._id == sessionSelected._id
																? this.deselectSession()
																: this.selectSession(session);
														}}
													>
														<div className="fs-16">{session.name}</div>
													</div>
												);
											})}
										</DropdownMenu>
									</UncontrolledDropdown>

									<UncontrolledDropdown inNavbar className="pr-1" style={{ height: '35px' }}>
										<DropdownToggle
											outline
											style={{
												color: groupSelected._id ? 'white' : '#2b83f1 ',
												backgroundColor: groupSelected._id ? '#2b83f1 ' : 'white',
												border: '1px solid #2b83f1'
											}}
										>
											<div style={{ display: 'flex' }}>
												<div
													style={{
														fontWeight: 'bold',
														fontSize: 14
													}}
												>
													{groupSelected._id && (
														<CheckCircle
															size={16}
															color="white"
															style={{ margin: 'auto' }}
														/>
													)}{' '}
													Groups
													<ChevronDown size={12} style={{ margin: 'auto' }} />
												</div>
											</div>
										</DropdownToggle>
										<DropdownMenu
											right
											className="notification-dropdown"
											style={{
												width: 'fit-content',
												width: 200,
												maxHeight: 500,
												overflowY: 'scroll'
											}}
										>
											{attendeeGroups.map((group) => {
												return (
													<div
														style={{
															color:
																group._id == groupSelected._id
																	? '#00000033'
																	: '#000000',
															padding: '8px 12px',
															whiteSpace: 'wrap'
														}}
														className="calibreRegular"
														onClick={() => {
															group._id == groupSelected._id
																? this.deselectGroup()
																: this.selectGroup(group);
														}}
													>
														<div className="fs-16">{group.name}</div>
													</div>
												);
											})}
										</DropdownMenu>
									</UncontrolledDropdown>
								</div>
							</div>
						</div>

						<div className="sectionBody" style={{ padding: 0 }}>
							<div className="sectionTable modalSectionTable">
								<div className="tableSubColumns">
									<div className="tableColumnHeader">
										<div
											className={`checkBox mr-10 ${this.state.allChecked ? 'checked' : ''}`}
											onClick={() => {
												this.checkAllAttendees();
											}}
										>
											<i className="las la-check" />
										</div>

										<div className="tableSubColumnNoSort main">
											<div className="tableCellData">
												<div className="tableColumnTitle">Name</div>
											</div>
										</div>
										<div className="tableSubColumnNoSort main">
											<div className="tableCellData">
												<div className="tableColumnTitle">Email</div>
											</div>
										</div>
										<div className="tableSubColumnNoSort main">
											<div className="tableCellData">
												<div className="tableColumnTitle">Sent</div>
											</div>
										</div>
										<div className="tableSubColumnNoSort main">
											<div className="tableCellData">
												<div className="tableColumnTitle">Last Sent</div>
											</div>
										</div>
									</div>
									<div
										className="tableColumnRows w-fc"
										style={{ maxHeight: '400px', overflow: 'auto' }}
										onScroll={(e) =>
											e.target.scrollHeight - e.target.clientHeight === e.target.scrollTop &&
											availableAttendees.length < this.state.attendeeCount &&
											this.fetchMoreAttendees()}
									>
										{availableAttendees.map((attendee, index) =>
											this.AttendeeItem(attendee, index)
										)}
									</div>
								</div>
							</div>
						</div>

						{/*availableAttendees.length*/}
						{loading && <p>Loading...</p>}
					</ModalBody>
				)}
				<ModalFooter className="modalFooter">
					{!sent && (
						<Button
							className="modalButton actionButton actionSave m-0"
							disabled={sending}
							style={{ width: 'unset' }}
							onClick={() => {
								this.setState({ submitting: true });
								if (Object.keys(checkedAttendees).length > 0) {
									this.sendOutEmails(Object.keys(checkedAttendees));

									//this.addAttendees(Object.keys(checkedAttendees));
								} else {
									this.setState({ invalid: true, submitting: false });
								}
							}}
						>
							<i className="las la-check-circle" />{' '}
							<div className="calibreBold fs-16">
								{!sending ? (
									`Send out email (${Object.keys(checkedAttendees).length})`
								) : (
									`Sending emails... (${sentEmails.length}/${Object.keys(checkedAttendees).length})`
								)}
							</div>
						</Button>
					)}
					{sending &&
					sent && (
						<Button outline onClick={this.resetAdd} className="modalButton actionButton actionSave m-0">
							<i className="las la-check-circle" />
							<div className="calibreBold fs-16">Done</div>
						</Button>
					)}

					{!sending && (
						<Button
							outline
							onClick={this.resetAdd}
							className="modalButton actionButton actionOutline mb-0 ml-a"
						>
							<i className="las la-times" />
							<div className="calibreBold fs-16">Cancel</div>
						</Button>
					)}
				</ModalFooter>
			</Modal>
		);
	}
}

export default SendEmailModal;
