import React from 'react';

import API from '../../../../utils/API';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import InputField from '../../../../components/inputs/inputField';
import * as IFrame from 'react-iframe-form';
import PagePreviewFrame from '../../../../views/pages/components/pagePreviewFrame';


const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;
class PagesTemplateCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name ? this.props.name : '',
            submitting: false,
            requiredFields: { name: false },
            selectedSection: 0,
            isConfirmation: false,
            templateSelected: null,
            countingKey: 0,
            isConfirmation: false,
            templateSelected: null,
            title: '',
            description: '',
            platformTemplates: [],
            requiredFields: {
                title: false,

            }


        };
    }

    async componentDidMount() {

        const { orgId } = this.props;
        await API().get(`Organizations/${orgId}/getTemplates`).then((res) => {
            let platformTemplates = [];

            if (res.data) {
                platformTemplates = res.data || [];
            }

            this.setState({ platformTemplates });
        });
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    updateName = async () => {
        this.setState({ submitting: true });
        const { name } = this.state;
        const { eventId, orgId, formId, update, toggle, form } = this.props;
        const requiredFields = this.state.requiredFields;
        let valid = true;
        if (!name) {
            requiredFields.name = true;
            valid = false;
        }

        if (valid) {
            await API()
                .patch(`Organizations/${orgId}/events/${eventId}/forms/${formId}`, {
                    name: name,
                    pages: form.pages
                })
                .then((res) => {
                    if (res.data) {
                        update(
                            name
                            ,
                            () => {
                                toggle();
                            }
                        );
                    }
                });
        } else {
            this.setState({ submitting: false, requiredFields: requiredFields });
        }
    };

    selectSection = (section) => {
        const { countingKey, title } = this.state;


        this.setState({ selectedSection: section, isConfirmation: false, templateSelected: null, countingKey: countingKey + 1, formName: '' });
    };
    setPageTitle = (title) => {
        this.setState({ title: title })
    }
    selectTemplate = (templateId) => {
        this.setState({ templateSelected: templateId })
    }

    handleActionButtonLogic = () => {
        const { isConfirmation, templateSelected, selectedSection } = this.state;
        switch (selectedSection) {
            case 0:
                if (templateSelected && !isConfirmation) {
                    this.setConfirmation(true);
                } else if (templateSelected && isConfirmation) {
                    this.createPage();
                }
                break;
            case 1:
                /*  if (templateSelected && !isConfirmation) {
                     this.setConfirmation(true);
                 } else if (templateSelected && isConfirmation) {
                     this.addCampaign();
                 } */
                break;
            case 2:
                this.createPage();
                break;

        }
    }

    setConfirmation = (isConfirmation) => {
        this.setState({ isConfirmation: isConfirmation, countingKey: this.state.countingKey + 1 })
    }

    createPage = async (callback) => {
        const { orgId, eventId, toggle, addForm } = this.props;
        const { templateSelected, title, description, selectedSection, confirmationEmail, confirmationEmailEnabled, formType } = this.state;
        let payload = {};
        let valid = true;
        let isPlatformTemplate = false;
        const requiredFields = this.state.requiredFields;

        switch (selectedSection) {
            case 0:
                isPlatformTemplate = false;

                if (title === '') {
                    valid = false;
                    requiredFields.title = true;
                }

                if (this.state.platformTemplates.find(template => template._id == templateSelected)) {
                    isPlatformTemplate = true;
                }
                payload = {
                    templateId: templateSelected,
                    title: title,
                    isPlatformTemplate: isPlatformTemplate
                };

                break;
            case 1:
                isPlatformTemplate = false;

                if (title === '') {
                    valid = false;
                    requiredFields.title = true;
                }

                payload = {
                    templateId: templateSelected,
                    title: title,
                    isPlatformTemplate: isPlatformTemplate
                };

                break;
            case 2:

                if (title === '') {
                    valid = false;
                    requiredFields.title = true;
                } else {
                    requiredFields.title = false;
                }
                payload = {
                    title: title,
                    description: description,
                };
                break;

        }

        if (valid) {
            this.setState({ submitting: true });
            await API()
                .post(`Organizations/${orgId}/events/${eventId}/page`, payload)
                .then((res) => {
                    if (res.data) {
                        this.props.addPage(res.data);
                        this.props.toggle();
                        this.props.history.push(`/${orgId}/events/${eventId}/pages/${res.data._id}`);
                    }
                });
        } else {
            this.setState({ requiredFields: requiredFields });
        }
    }


    onCancel = () => {
        const { isConfirmation } = this.state;


        if (isConfirmation) {
            this.setConfirmation(false);

        } else {

            this.props.toggle();
        }

    }


    render() {
        const { isOpen, toggle, orgId, eventId } = this.props;
        const { submitting, title, description, requiredFields, selectedSection, isConfirmation, templateSelected, countingKey, confirmationEmailEnabled, confirmationEmail, formType, platformTemplates } = this.state;
        //let localTemplates = this.props.templates ? this.props.templates : [];
        //let localPlatformTemplates = this.props.platformTemplates ? this.props.platformTemplates : [];
        let data = platformTemplates;
        if (selectedSection == 1) {
            data = data.filter(template => {
                if (template.isSessionPage) {
                    return template;
                }
            });
        }

        if (templateSelected && isConfirmation) {
            data = data.filter(template => template._id == templateSelected);
        }

        return (
            <div>
                <Modal isOpen={isOpen} style={{}} toggle={toggle} className={`large templateCenterModal`}>
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7, zIndex: 100 }}><i className='las la-times'></i></button>
                    <div className={`templateCenterContainer`} style={{}}>
                        <div className="sideNav">
                            <h1>Template Center</h1>
                            <div className="explorePanel">
                                <p>EXPLORE</p>
                                <button type="button" tabindex="0" className={`sideNav  ${selectedSection == 0 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(0)}>
                                    All Templates
                                </button>
                                {/* <button type="button" tabindex="1" className={`sideNav  ${selectedSection == 1 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(1)}>
                                    Session Templates
                                </button> */}
                                <button type="button" tabindex="2" className={`sideNav  ${selectedSection == 2 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(2)}>
                                    Start From Scratch
                                </button>

                            </div>
                        </div>

                        <div style={{ height: "calc(100vh - 80px)" }} className="templatesContainer">
                            {isConfirmation ? (
                                < div >

                                    <div style={{ alignContent: 'baseline', height: 'calc(100vh - 233px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>
                                        <div>
                                            {data.map((template, index) => {
                                                return (
                                                    <div>
                                                        <div style={{ opacity: '1', width: 455, height: 630, background: '#FFF', margin: '10px 50px 40px 0px' }}>
                                                            <div className="noselect emailPreviewWindow Frame templateCenter formFocus" style={{ width: '100%' }}>
                                                                <PagePreviewFrame
                                                                    ENV={ENV}
                                                                    key={countingKey}
                                                                    renderURL={`Organizations/${orgId}/events/${eventId}/pages/render/platformPageRender/`}
                                                                    pageProperties={{}}
                                                                    focusedComponent={""}
                                                                    simulatedUser={{ value: this.props.account._id }}
                                                                    simulatedSession={{ value: false }}
                                                                    renderOnly={true}
                                                                    platformPageId={template._id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                        <div className='mt-10' style={{ width: '50%' }}>
                                            <InputField
                                                label={'Page Title'}
                                                value={this.state.title}
                                                errorTrigger={this.state.title == ''}
                                                inputStyle={{ fontSize: 28, lineHeight: '34px', fontWeight: 600, margin: 0, padding: 0 }}
                                                onChange={(e) => {
                                                    this.setPageTitle(e.target.value);
                                                }}
                                            />

                                            <div className='sectionBlock'>
                                                <h2 className='blockHeader mb-20'>Details</h2>


                                                <div className="mb-20">
                                                    <p className="small gray">Description</p>
                                                    <p >
                                                        {data[0].description ? data[0].description : 'N/A'}
                                                    </p>
                                                </div>

                                                <div className="mb-20">
                                                    <p className="small gray">Page type</p>
                                                    <p >
                                                        {data[0].isSessionPage ? "Session Template" : 'General Page'}
                                                    </p>
                                                </div>

                                                <div className="mb-20">
                                                    <p className="small gray">Availability</p>
                                                    <p >
                                                        {data[0].openToPublic ? "Public (Available to anyone)" : 'Private (Only for logged in users)'}
                                                    </p>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {selectedSection == 2 ? (
                                        <div className='formSection'>
                                            <div className="formSectionContent">
                                                <div className=" formSectionCard m-a flex jcc aic">
                                                    <div className="w-100 h-100 flex jcc aic" style={{ flexDirection: 'column' }}>
                                                        <h1>Start From Scratch</h1>
                                                        <p className="mb-20">Please complete the form below.</p>

                                                        <InputField
                                                            label="Title"
                                                            onChange={(e) => this.setState({ title: e.target.value })}
                                                            value={title}
                                                            required={true}
                                                            errorTrigger={requiredFields.title}
                                                        />
                                                        <InputField
                                                            label="Description"
                                                            onChange={(e) => this.setState({ description: e.target.value })}
                                                            value={description}
                                                            required={false}
                                                        />
                                                    </div>


                                                </div>

                                            </div>



                                        </div>
                                    ) : (
                                        <div style={{ alignContent: 'baseline', height: 'calc(100vh - 233px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>

                                            {data.map((template, index) => {
                                                let url = `Organizations/${orgId}/events/${eventId}/pages/render/platformPageRender/`;
                                                let simulatedSession = { value: false };
                                                const renderSrc = simulatedSession.value
                                                    ? `${ENV}${url}${this.props.account._id || ''}/${simulatedSession.value}${''}`
                                                    : `${ENV}${url}${this.props.account._id || ''}${''}`;
                                                let method = 'post';
                                                let data = {
                                                    html: template.html,
                                                    pageProperties: {},
                                                    focusedComponent: "",
                                                    renderOnly: true,
                                                    platformPageId: template._id,
                                                };

                                                return (
                                                    <div>
                                                        <div className="templateCard mt-10">
                                                            <div className="templateCardHeader">
                                                                <div className="templateCardHeaderLeft" style={{ fontWeight: '600', fontSize: 14 }}>
                                                                    <p style={{ fontWeight: '600', fontSize: 14 }}>{template.title}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`previewCardContainer ${template._id == this.state.templateSelected ? 'selected' : ''}`}
                                                            onClick={() => {
                                                                this.setPageTitle(this.state.templateSelected == template._id ? '' : template.title);
                                                                this.selectTemplate(this.state.templateSelected == template._id ? null : template._id)
                                                            }}>
                                                            <div style={{ opacity: template._id == this.state.templateSelected ? "0.4" : '1' }}>

                                                                <div className="noselect emailPreviewWindow Frame templateCenter form" style={{ width: '100%' }}>
                                                                    <IFrame.Form
                                                                        id={`builderPreviewFrameTemplate-${template._id}`}
                                                                        className="noselect emailPreviewWindow Frame"
                                                                        style={{
                                                                            ponterEvent: 'none',
                                                                            transform: 'scale(0.99)'
                                                                        }}
                                                                        key={`p-${index}-${countingKey}`}
                                                                        scrolling="no"
                                                                        name={`PreviewFrameTemp-${template._id}`}
                                                                        config={{ url: renderSrc, method, data }}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                    )
                                    }
                                </div>
                            )}
                            <div className='modalFooter mt-20'>
                                <div className='flex'>
                                    <button className="neu ml-a" onClick={this.onCancel}>{isConfirmation ? 'Back' : 'Cancel'}</button>
                                    <button className={`ml-25 ${!templateSelected && !isConfirmation && selectedSection !== 2 && selectedSection !== 3 ? "disabled" : (this.state.title == '') ? 'disabled' : 'prim'}`} onClick={() => {

                                        this.handleActionButtonLogic();



                                    }}>{(selectedSection == 0 || selectedSection == 1) && templateSelected && !isConfirmation ? "Next" : "Create Page"}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal>

            </div>

        );
    }
}

export default withRouter(PagesTemplateCenter);
