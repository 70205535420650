import React, { Component } from 'react';

import API from '../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';

import InputField from '../../inputs/inputField.js';
import ToggleField from '../../inputs/toggleField.js';
import FieldModal from '../../modals/fieldModal';
import SelectField from '../../inputs/selectField';

const dataOptions = [
	{ value: 'Attendee', label: 'Attendees' },
	{ value: 'Session', label: 'Sessions' },
	{ value: 'Group', label: 'Groups' },
	{ value: 'Email', label: 'Emails' },
	/* { value: 'inventory', label: 'Inventory' },
	{ value: 'textMessages', label: 'Text Messages' },
	{ value: 'forms', label: 'Forms' },
	{ value: 'pages', label: 'Pages' },
	{ value: 'engagements', label: 'Engagement' }, */
];

class AddReportViewModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			selectedDataOption: "",
			name: '',
			useCurrentViewSettings: true,
			requiredFields: {
				name: false,
				type: false
			}
		};
	}

	saveNewView = () => {
		const { name, useCurrentViewSettings, requiredFields } = this.state;
		const { orgId, type, referenceCollection, referenceId, normalizedColumns } = this.props;
		const view = {
			name,
			type: this.state.selectedDataOption.value,
			referenceCollection: referenceCollection,
			referenceId: referenceId
		};
		let valid = true;
		let sortedColumns = [];
		normalizedColumns.map((nc) => {
			sortedColumns.push(nc.key);
		})

		Object.keys(requiredFields).forEach((key) => {
			if (!view[key] || view[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {

			view.filterLayers = [];
			view.columnsOrder = [];
			view.toggledColumns = [];

			if (name != '') {
				this.setState({ submitting: true });
				API()
					.post(`/Organizations/${orgId}/views`, { ...view, reportId: this.props.reportId })
					.then((res) => {
						this.setState({ submitting: false, requiredFields: requiredFields });
						this.props.addView(res.data);
						this.props.toggle();
					})
					.catch((err) => {
						this.setState({ submitting: false });
						console.log(err);
					});
			}
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, useCurrentViewSettings, requiredFields, selectedDataOption } = this.state;
		return (
			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add View To Report"}
					bodyHeaderText={'Please enter the information below to add new view.'}
					bodyDescription={''}
					overflowOff={true}
					bodyContent={() => {
						return (
							<>
								<SelectField label="Data Set"
									value={selectedDataOption}
									options={dataOptions}
									onChange={(e) => this.setState({ selectedDataOption: e })}
								/>
								<InputField
									value={name}
									label={'View Name'}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={(e) => this.setState({ name: e.target.value })}
								/>
								{/* <ToggleField
									label="Use current view settings"
									checked={useCurrentViewSettings}
									icons={false}
									onChange={(e) => this.setState({ useCurrentViewSettings: !useCurrentViewSettings })}
								/> */}
							</>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.saveNewView()}
					actionButtonDisabled={submitting}

				></FieldModal>

			</div>

		);
	}
}

export default AddReportViewModal;
