import React, { Component } from 'react';
import FieldModal from '../../../components/modals/fieldModal';

import API from '../../../utils/API';

import InputField from '../../../components/inputs/inputField';

class AddPageModal extends Component {
	state = {
		title: '',
		description: '',
		submitting: false,
		requiredFields: { title: false }
	};

	addPage = async () => {
		this.setState({ submitting: true });
		const { orgId } = this.props;
		const { title, description } = this.state;

		const requiredFields = this.state.requiredFields;
		let valid = true;
		if (title === '') {
			valid = false;
			requiredFields.title = true;
		}
		if (valid) {
			await API()
				.post(`Organizations/${orgId}/accountPages`, {
					title: title,
					description: description
				})
				.then((res) => {
					if (res.data) {
						this.props.addPage(res.data);
						this.props.toggle();
					}
				});
		} else {
			this.setState({ requiredFields, submitting: false });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { title, description, submitting, requiredFields } = this.state;
		return (
			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Page"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Title"
									required={true}
									errorTrigger={requiredFields.title}
									value={title}
									onChange={(e) => this.setState({ title: e.target.value })}
								/>

								<InputField
									label="Description"
									value={description}
									onChange={(e) => this.setState({ description: e.target.value })}
								/>
							</div>
						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.addPage()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default AddPageModal;
