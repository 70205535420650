import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';
import moment from 'moment';

import FieldModal from '../../../../components/modals/fieldModal';

class UnscheduleEmailModal extends React.Component {
	constructor(props) {


		super(props);
		this.state = {

			submitting: false
		}
	}
	pauseCampaign = async () => {
		const { orgId, eventId, toggle, email, update } = this.props;

		this.setState({ submitting: true })
		try {
			await Scheduler()
				.patch(`Organizations/${orgId}/schedules`, { emails: [email._id], request: 'unschedule' })
				.then((res) => {
					update(
						{
							scheduled: false,
							sendDate: '',
							sendTime: '',
							status: 'Draft'
						},
						() => {
							toggle();
						}
					);
				});
		} catch (e) {
			console.log(e);
		}
	};

	render() {
		const { email, isOpen, toggle, event, } = this.props;
		const { submitting } = this.state;
		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Pause Campaign"}
					bodyHeaderText={'Are you sure you want to pause this campaign?'}
					bodyContent={() => {
						return (<p>Scheduled for {email.sendDate} {email.sendTime} {event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}</p>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Pause' : 'Pausing...'}
					actionButton={() => this.pauseCampaign()}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}

export default withRouter(UnscheduleEmailModal);
