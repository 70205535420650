import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import InputField from '../../components/inputs/inputField';
import BasicSectionHeader from '../../components/views/BasicSectionHeader';
import moment from 'moment';
import cityTimezones from 'city-timezones';
import API from '../../utils/API';
import TimeField from '../../components/inputs/timeField';
import SelectField from '../../components/inputs/selectField';
import ToggleField from '../../components/inputs/toggleField';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import SectionFooter from '../../components/views/SectionFooter';
import SectionHeader from '../../components/views/SectionHeader';

const dateRangeTypes = [
	{ value: 'fixed', label: 'Days into the future' },
	{ value: 'dateRange', label: 'Within Date Range' },
	{ value: 'indefinite', label: 'Indefinitely into the future' }
];

const durationUnits = [{ value: 'minutes', label: 'Minutes' }, { value: 'hours', label: 'Hours' }];
class ProgramCalendar extends Component {
	constructor(props) {
		super(props);

		const { match: { params } } = this.props;
		this.props.setActiveRow(params.calendarId)

		const calendar = props.calendars.find((r) => r._id == params.calendarId);
		this.state = {
			editing: false,
			loading: false,
			submitting: false,
			defaultCalendar: { ...JSON.parse(JSON.stringify(calendar)) },
			calendar: JSON.parse(JSON.stringify(calendar)),
			cityLookup: '',
			editedCalendar: {
				...JSON.parse(JSON.stringify(calendar))
			},
			requiredFields: {
				calendarName: true
			}
		};
	}
	componentDidMount() {
		const { match: { params } } = this.props;
		this.props.setActiveRow(params.calendarId);
	}

	componentWillUnmount() {
		this.props.setActiveRow(null);
	}
	cancelEdit = () => {
		this.setState({
			editedCalendar: this.state.defaultCalendar,
			editing: false
		});
	}

	renderDayOptions = (day) => {
		const { editedCalendar } = this.state;
		return (
			<div className="flex-ac">
				<ToggleField
					label={day.toUpperCase()}
					icons={false}
					style={{ margin: 0, maxWidth: 125, height: 80, display: 'flex', marginBottom: 'auto' }}
					checked={editedCalendar.schedule[day] && editedCalendar.schedule[day].available}
					onChange={(e) => {
						let schedule = editedCalendar.schedule;
						if (schedule[day]) {
							schedule[day].available = e.target.checked;
						} else {
							schedule[day] = {
								available: e.target.checked,
								availableTimeRanges: [
									{
										startTime: '12:00 AM',
										endTime: '11:59 PM'
									}
								]
							};
						}
						this.setState({
							editing: true,
							editedCalendar: { ...editedCalendar, schedule: schedule }
						});
					}}
				/>
				<div className="w-100 mr-10">
					{editedCalendar.schedule[day] && editedCalendar.schedule[day].available ? (
						editedCalendar.schedule[day].availableTimeRanges.map((s, i) => (
							<div className="flex-ac">
								<TimeField
									label="Start"
									value={s.startTime}
									classes="mr-10 ml-10"
									//timeStep={editedCalendar.duration}
									//timeStepUnit={editedCalendar.durationUnit}
									onChange={(e) => {
										let schedule = editedCalendar.schedule;
										schedule[day].availableTimeRanges[i].startTime = e;
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, schedule: schedule }
										});
									}}
								/>
								<TimeField
									label="End"
									classes="mr-10"
									value={s.endTime}
									timeStep={editedCalendar.duration}
									timeStepUnit={editedCalendar.durationUnit}
									startTime={s.startTime}
									onChange={(e) => {
										let schedule = editedCalendar.schedule;
										schedule[day].availableTimeRanges[i].endTime = e;

										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, schedule: schedule }
										});
									}}
								/>

								{i > 0 && (
									<div
										className="calibreRegular fs-16 red c-pointer"
										onClick={() => {
											let schedule = editedCalendar.schedule;
											schedule[day].availableTimeRanges.splice(i, 1);
											this.setState({
												editing: true,
												editedCalendar: { ...editedCalendar, schedule: schedule }
											});
										}}
									>
										<i className="las la-trash mr-5" />
									</div>
								)}
							</div>
						))
					) : (
						<div className="ml-10 flex-ac calibreSemiBold" style={{ height: 60 }}>
							Unavailable
						</div>
					)}
				</div>
				<div className="flex-ac" style={{ margin: 0, height: 80, marginBottom: 'auto' }}>
					{editedCalendar.schedule[day] &&
						editedCalendar.schedule[day].available && (
							<div
								className={`c-pointer`}
								onClick={() => {
									let schedule = editedCalendar.schedule;
									schedule[day].availableTimeRanges.push({
										startTime: '12:00 AM',
										endTime: '11:59 PM'
									});
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, schedule: schedule }
									});
								}}
							>
								<div className="calibreRegular fs-16">
									<i className="las la-plus-circle mr-5" />
								</div>
							</div>
						)}
				</div>
			</div>
		);
	};

	renderCustomDayOptions = (date, index) => {
		const { editedCalendar } = this.state;
		return (
			<div className="flex-ac">
				<InputField
					label="Date"
					type="date"
					style={{ maxWidth: 125, marginBottom: 'auto' }}
					value={date.date}
					onChange={(e) => {
						let schedule = editedCalendar.schedule;
						schedule.customDates[index].date = e.target.value;
						this.setState({
							editing: true,
							editedCalendar: { ...editedCalendar, schedule: schedule }
						});
					}}
					inputIcon={<i className="lar la-calendar" />}
				/>
				<div className="w-100 mr-10">
					{date.availableTimeRanges.map((s, i) => (
						<div className="flex-ac">
							<TimeField
								label="Start"
								value={s.startTime}
								classes="mr-10 ml-10"
								//timeStep={editedCalendar.duration}
								//timeStepUnit={editedCalendar.durationUnit}
								onChange={(e) => {
									let schedule = editedCalendar.schedule;
									schedule.customDates[index].availableTimeRanges[i].startTime = e;
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, schedule: schedule }
									});
								}}
							/>
							<TimeField
								label="End"
								classes="mr-10"
								value={s.endTime}
								timeStep={editedCalendar.duration}
								timeStepUnit={editedCalendar.durationUnit}
								startTime={s.startTime}
								onChange={(e) => {
									let schedule = editedCalendar.schedule;
									schedule.customDates[index].availableTimeRanges[i].endTime = e;
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, schedule: schedule }
									});
								}}
							/>

							{i > 0 && (
								<div
									className="calibreRegular fs-16 red c-pointer"
									onClick={() => {
										let schedule = editedCalendar.schedule;
										schedule.customDates[index].availableTimeRanges.splice(i, 1);
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, schedule: schedule }
										});
									}}
								>
									<i className="las la-trash mr-5" />
								</div>
							)}
						</div>
					))}
				</div>
				<div className="flex-ac" style={{ margin: 0, height: 80, marginBottom: 'auto' }}>
					<div
						className={`c-pointer`}
						onClick={() => {
							let schedule = JSON.parse(JSON.stringify(editedCalendar.schedule));
							schedule.customDates[index].availableTimeRanges.push({
								startTime: '12:00 AM',
								endTime: '11:59 PM'
							});
							this.setState({
								editing: true,
								editedCalendar: { ...editedCalendar, schedule: schedule }
							});
						}}
					>
						<div className="calibreRegular fs-16">
							<i className="las la-plus-circle mr-5" />
						</div>
					</div>
					<div
						className="calibreRegular fs-16 red c-pointer"
						onClick={() => {

							let schedule = JSON.parse(JSON.stringify(editedCalendar.schedule));
							if (schedule.customDates[index].availableTimeRanges.length == 1) {
								schedule.customDates.splice(index, 1);

							} else {
								schedule.customDates[index].availableTimeRanges.splice(0, 1);
							}

							this.setState({
								editing: true,
								editedCalendar: { ...editedCalendar, schedule: schedule }
							});
						}}
					>
						<i className="las la-trash mr-5" />
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { editing, calendar, editedCalendar, requiredFields, cityLookup } = this.state;
		const { program, saveCalendar } = this.props;
		const leadUnitOptions = [...durationUnits, { value: 'days', label: 'Days' }];
		const calendarTitle = this.props.calendars.find((r) => r._id == editedCalendar._id);
		const cityTimezoneOptions =
			cityLookup != ''
				? cityTimezones.findFromCityStateProvince(cityLookup).map((tz) => {
					return { label: `${tz.city}, ${tz.province}`, value: tz.timezone };
				})
				: [];

		return (
			<div className="sectionContainer">
				<SectionHeader
					title={calendarTitle.name}
					description={`Organize your program's date and time availability.`}
					updatedAt={calendar.updatedAt}
					closeButton={true}

				/*breadcrumbs={[
					{
						name: program.name,
					},
					{
						name: 'Calendars',
					}
				]} */
				/>
				<div className="sectionBody pt-0">
					<div className="sectionBlock">
						<h2 className="blockHeader">Calendar Details</h2>
						<InputField
							label="Calendar Name"
							value={editedCalendar.name}
							onChange={(e) =>
								this.setState({
									editing: true,
									editedCalendar: { ...editedCalendar, name: e.target.value }
								})}
							required={requiredFields.name}
						/>
						<h2 className="blockHeader">Date Range</h2>
						<SelectField
							label="Invitees can schedule..."
							value={dateRangeTypes.find((drt) => drt.value == editedCalendar.dateRangeType)}
							options={dateRangeTypes}
							onChange={(e) => {
								this.setState({
									editing: true,
									editedCalendar: { ...editedCalendar, dateRangeType: e.value }
								});
							}}
						/>
						{editedCalendar.dateRangeType === 'fixed' && (
							<InputField
								label="Days into the future"
								value={editedCalendar.futureDays}
								onChange={(e) => {
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, futureDays: e.target.value }
									});
								}}
							/>
						)}
						{editedCalendar.dateRangeType === 'dateRange' && (
							<div className="flex-ac flex-sb">
								<InputField
									label="Start Date"
									value={editedCalendar.startDate}
									type="date"
									classes="mr-10"
									inputIcon={<i className="lar la-calendar" />}
									onChange={(e) => {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, startDate: e.target.value }
										});
									}}
								/>
								<InputField
									label="End Date"
									value={editedCalendar.endDate}
									type="date"
									classes="ml-10"
									inputIcon={<i className="lar la-calendar" />}
									onChange={(e) => {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, endDate: e.target.value }
										});
									}}
								/>
							</div>
						)}
						<div className="flex-ac flex-sb">
							<InputField
								label="Duration Amount"
								classes="mr-10"
								type="number"
								min="0"
								value={editedCalendar.duration === 0 ? '' : editedCalendar.duration}
								onChange={(e) => {
									const value = parseFloat(e.target.value);
									if (!isNaN(value) && value >= 0) {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, duration: value }
										});
									} else if (value === 0) {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, duration: '' }
										});
									}
								}}
							/>
							<SelectField
								label="Unit"
								style={{ marginLeft: 10 }}
								value={durationUnits.find((dt) => dt.value == editedCalendar.durationUnit)}
								options={durationUnits}
								onChange={(e) => {
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, durationUnit: e.value }
									});
								}}
							/>
						</div>
						<h2 className="blockHeader">Availability</h2>

						<div className="flex-ac flex-sb">
							<SelectField
								label={'Timezone'}
								value={{
									value: editedCalendar.timezone,
									label: editedCalendar.timezone
								}}
								name="timezones"
								options={cityTimezoneOptions}
								required={true}
								errorTrigger={requiredFields.timezone}
								onInputChange={(text) => {
									this.setState({ editing: true, cityLookup: text });
								}}
								noOptionsMessage={({ inputValue }) =>
									!inputValue ? 'Type city name to get options' : 'No cities match your search'}
								onChange={(e) => {
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, timezone: e.value }
									});
								}}
							/>
							<InputField
								label="Lead Time Needed (days)"
								classes="ml-10"
								type="number"
								value={editedCalendar.leadTime === 0 ? '' : editedCalendar.leadTime}
								onChange={(e) => {
									const value = parseFloat(e.target.value);
									if (!isNaN(value) && value >= 0) {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, leadTime: value }
										});
									} else if (value === 0) {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, leadTime: '' }
										});
									}
								}}
							/>
						</div>
						<div className="flex-ac flex-sb mb-20">
							<ToggleField
								style={{ margin: 0 }}
								label="Limit Spots per Time Slot"
								icons={false}
								checked={editedCalendar.limitSpots}
								onChange={(e) => {
									this.setState({
										editing: true,
										editedCalendar: { ...editedCalendar, limitSpots: e.target.checked }
									});
								}}
							/>
							{editedCalendar.limitSpots && (
								<InputField
									label="Spots Available"
									classes="ml-10"
									type="number"
									value={editedCalendar.maxSpots}
									onChange={(e) => {
										this.setState({
											editing: true,
											editedCalendar: { ...editedCalendar, maxSpots: e.target.value }
										});
									}}
								/>
							)}
						</div>
						<h2 className="blockHeader"> Weekly Hours</h2>

						{this.renderDayOptions('sunday')}
						<hr />
						{this.renderDayOptions('monday')}
						<hr />
						{this.renderDayOptions('tuesday')}
						<hr />
						{this.renderDayOptions('wednesday')}
						<hr />
						{this.renderDayOptions('thursday')}
						<hr />
						{this.renderDayOptions('friday')}
						<hr />
						{this.renderDayOptions('saturday')}
						<hr />
						<h2 className="blockHeader">Date Overrides</h2>
						{editedCalendar.schedule.customDates.map((date, index) =>
							this.renderCustomDayOptions(date, index)
						)}

						<div
							className="actionButton actionOutline"
							onClick={() => {
								this.setState({
									editing: true,
									editedCalendar: {
										...editedCalendar,
										schedule: {
											...editedCalendar.schedule,
											customDates: [
												...editedCalendar.schedule.customDates,
												{
													date: '',
													availableTimeRanges: [
														{
															startTime: '',
															endTime: ''
														}
													]
												}
											]
										}
									}
								});
							}}
						>
							<i className="las la-plus-circle mr-5" />
							<div className="">Add Date Override</div>
						</div>
					</div>
				</div>
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={() => {
						saveCalendar(editedCalendar, () => {
							this.setState({ editing: false });
						});
						this.props.updateCalendars(editedCalendar);
					}} />
			</div>
		);
	}
}

export default withRouter(ProgramCalendar);
