import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import SelectField from '../../../../components/inputs/selectField';

import FieldModal from '../../../../components/modals/fieldModal';


class AddPageModal extends Component {
	state = {
		title: '',
		description: '',
		template: '',
		submitting: false,
		requiredFields: {
			title: false
		},
		templateOptions: [{ value: '', label: '' }]
	};

	componentDidMount() {
		const { eventId, orgId } = this.props;

		API().get(`Organizations/${orgId}/getTemplates`).then((res) => {
			let templateOptions = this.state.templateOptions;
			if (res.data) {
				res.data.map((template) => {
					templateOptions.push({
						value: template._id,
						label: template.title
					});
				});
			}

			this.setState({ templateOptions });
		});
	}

	addPage = async () => {
		const { eventId, orgId } = this.props;
		const { title, description, template } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		const obj = { title, description };
		Object.keys(requiredFields).forEach((key) => {
			if (!obj[key] || obj[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			this.setState({ submitting: true });
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/page`, {
					title: title,
					description: description,
					template: template.label,
					templateId: template.value
				})
				.then((res) => {
					if (res.data) {
						this.props.addPage(res.data);
						this.props.toggle();
					}
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { title, description, submitting, requiredFields, templateOptions } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Create Page"}
					bodyHeaderText={`Page will not be visible to the public until it's published.`}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Title"
									required={true}
									errorTrigger={requiredFields.title}
									value={title}
									onChange={(e) => this.setState({ title: e.target.value })}
								/>
								<InputField
									label="Description"
									errorTrigger={requiredFields.description}
									value={description}
									onChange={(e) => this.setState({ description: e.target.value })}
								/>
								<SelectField
									classes={'w-100'}
									label="Template"
									value={this.state.template}
									options={templateOptions}
									onChange={(e) => {
										this.setState({ template: e });
									}}
									style={{ marginRight: 10 }}
								/>{' '}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.addPage()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default AddPageModal;
