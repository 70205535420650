import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import moment from 'moment';
import FieldModal from '../../../components/modals/fieldModal';
import InputField from '../../../components/inputs/inputField';
import ViewsTable from '../../../components/tables/ViewsTable';

import LoadingWizard from '../../../components/spinner/wizard';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

class FormSubmissions extends Component {
	constructor(props) {
		let columns = {
			email: 'Email',
			firstName: 'First Name',
			lastName: 'Last Name',
			submittedAt: 'Submitted'
		};

		let columnTypes = {
			email: 'text',
			firstName: 'text',
			lastName: 'text',
			submittedAt: 'timestamp'
		};

		const toggledColumns = {};
		let normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});


		const toggledFieldLabels = { ...toggledColumns };
		const normalizedFieldLabels = [...normalizedColumns];
		props.form.pages.forEach((page) => {
			page.sections.forEach((section) => {
				section.fields.forEach((field) => {
					toggledFieldLabels[field.id] = true;
					normalizedFieldLabels.push({
						key: field.id,
						label: field.label,
						type: field.type,
						value: field.id,
						sortAsc: false,
						sortDesc: false
					});

					if (field.type == 'nestedSections') {
						field.nestedSections.forEach((nestedSection) => {
							nestedSection.questions.forEach((question) => {
								toggledFieldLabels[question.id] = true;
								normalizedFieldLabels.push({
									key: question.id,
									label: question.label,
									type: question.type,
									value: question.id,
									sortAsc: false,
									sortDesc: false
								});
							});
						});
					}
				});
			});
		});

		props.event.customFields.attendees.map(cfcategory => {
			let catFields = cfcategory.fields.map(field => {
				columns[field._id] = field.fieldName;
				columnTypes[field._id] = field.fieldType;
				toggledColumns[field._id] = true;

				let attendeeCustomField = {
					label: field.fieldName,
					key: field._id,
					value: field._id,
					type: field.fieldType,
					sortAsc: false,
					sortDesc: false

				}
				normalizedColumns.push(attendeeCustomField);
			})

		});

		super(props);
		this.state = {
			loading: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			normalizedFieldLabels: normalizedFieldLabels,
			toggledFieldLabels: toggledFieldLabels,
			checkedSubmission: {},
			submissionDetailsModalTrigger: false,
			selectedColumnSort: null,
			sortDesc: false,
			searchTerm: '',
			searching: false
		};
	}

	updatedCheckedSubmissions = (checked) => {
		this.setState({ checkedSubmission: checked });
	};

	toggle = (id) => {
		this.setState({
			submissionDetailsModalTrigger: !this.state.submissionDetailsModalTrigger,
			selectedSubmissionId: id ? id : ''
		});
	};
	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};

	download = (attList) => {
		const { event, editedForm } = this.props;
		let headers = ['First Name', 'Last Name', 'Email', 'Submitted On'];
		const columns = this.state.normalizedFieldLabels.reduce((acc, cur) => {
			acc[cur.key] = cur.label;
			return acc;
		}, {});
		let rows = [];
		Object.keys(columns).forEach((key) => {
			headers.push(columns[key]);
		});

		attList.forEach((sub) => {
			let fixedSub = [];
			const firstName = sub.firstName;
			const lastName = sub.lastName;
			const email = sub.email;
			const submittedAt = moment(sub.submittedAt).format('MM/DD/YYYY h:mm a');
			fixedSub = [firstName, lastName, email, submittedAt];

			Object.keys(columns).forEach((key) => {
				fixedSub.push(sub[key] || sub[key] == '' ? sub[key] : '');
			});

			rows.push(fixedSub);
		});

		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: editedForm.name + ' Submission Report',
			Subject: 'Submissions',
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [headers, ...rows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${event.name} - ${editedForm.name} .xlsx`
		);
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};
	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};

	render() {
		const {
			loading,
			normalizedColumns,
			toggledColumns,
			normalizedFieldLabels,
			toggledFieldLabels,
			submissionDetailsModalTrigger,
			selectedSubmissionId,
			selectedColumnSort,
			sortDesc,
			selectedSection,
			searchTerm,
			columns,
		} = this.state;
		const { editedForm, event } = this.props;
		let customFieldColumns = [];
		let customFieldColumnTypes = [];
		event.customFields.attendees.forEach((category) => {
			category.fields.forEach((f) => {
				customFieldColumns[f._id] = f.fieldName;
				customFieldColumnTypes[f._id] = f.fieldType;
			});
		});

		let att = editedForm.submissions.map((a) => {
			let extraFields = typeof a.formData.extraFields == 'string' ? JSON.parse(a.formData.extraFields) : a.formData.extraFields;
			return {
				_id: a._id,
				firstName: a.attendee.firstName == '-' ? a.formData.firstName || '-' : a.attendee.firstName,
				lastName: a.attendee.lastName == '-' ? a.formData.lastName || '-' : a.attendee.lastName,
				email: a.attendee.email == '-' ? a.formData.email || '-' : a.attendee.email,
				submittedAt: a.submittedAt, 
				...extraFields,
				...a.formData
			};
		});

		if (selectedColumnSort) {

			att = att.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			att = sortDesc ? att.reverse() : att;
		}
		/* Search Function */
		let data = att;
		if (searchTerm.length > 0) {
			const searchTerms = searchTerm.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		return (
			<div className="sectionContainer">
				{!loading ? (
					<div style={{ height: '100%' }}>
						<div className="actionButtonContainer" style={{ marginBottom: 15 }}>
							{/* Search filed */}

							<div className='mb-10  mt-10 flex aic'>
								<InputField
									classes="m-0 viewSearch"
									placeholder="Search "
									clickIcon={true}
									required={true}
									value={searchTerm}
									onChange={(e) => {
										clearTimeout(this.searchTimeout);
										this.setState({ searchTerm: e.target.value },
											() => {
												this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
											});

									}}
									prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
									inputIcon={searchTerm != '' &&
										<i
											onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
											//onClick={() => this.setState({ searchTerm: '', searching: false })}
											className="las la-times-circle"
											style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
										/>}
								/>
							</div>
							<div className="sectionOptionsContainer">
								<div
									onClick={() => {
										this.download(att);
									}}
									className="actionButton p-7"
								>
									<div >
										<i className="las la-download" />
									</div>
								</div>
							</div>
						</div>

						<ViewsTable
							title={"Submissions"}
							style={{ margin: 0 }}
							noDescription={true}
							columns={normalizedFieldLabels}
							toggledColumns={toggledFieldLabels}
							data={data}
							mainColumnActive={false}
							checked={{}}
							disableCheck={true}
							onClick={this.toggle}
							checkRow={(entryId) => {
								const checked = this.state.checkedSubmission;
								checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
								this.updatedCheckedSubmissions(checked);
								this.setState({
									checkedSubmission: checked
								});
							}}
							checkAllRows={(entryIds) => {
								let checked = this.state.checkedGcheckedSubmissionroups;
								entryIds.length > 0
									? entryIds.forEach((entryId) => {
										checked[entryId] = true;
									})
									: (checked = {});
								this.updatedCheckedSubmissions(checked);

								this.setState({
									checkedSubmission: checked
								});
							}}
							sortData={this.sortData}
						/>
						{submissionDetailsModalTrigger && (
							<SubmissionDetailsModal
								event={event}
								isOpen={submissionDetailsModalTrigger}
								submission={editedForm.submissions.find((s) => s._id == selectedSubmissionId)}
								columns={customFieldColumns}
								columnTypes={customFieldColumnTypes}
								toggle={this.toggle}
								form={this.props.form}
							/>
						)}
					</div>
				) : (
					<LoadingWizard text="LOADING ATTENDEE" />
				)}
			</div>
		);
	}
}

export default withRouter(FormSubmissions);

class SubmissionDetailsModal extends React.Component {
	constructor(props) {
		const columns = props.columns;

		const columnTypes = props.columnTypes;
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		super(props);
		this.state = {
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			submitting: false
		};
	}

	render() {
		const { isOpen, submission, form } = this.props;
		const { normalizedColumns, searchTerm } = this.state;
		const entries = typeof submission.formData.extraFields == 'string' ? JSON.parse(submission.formData.extraFields) : submission.formData.extraFields;
		if (submission.formData.roomingInformation && typeof submission.formData.roomingInformation == 'string') {
			submission.formData.roomingInformation = JSON.parse(submission.formData.roomingInformation);
		}
		if (submission.formData.travelInformation && typeof submission.formData.travelInformation == 'string') {
			submission.formData.travelInformation = JSON.parse(submission.formData.travelInformation);
		}
		return (

			<div>


				<FieldModal

					size="medium"
					isOpen={isOpen}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<div className="modalBodySubmission">
									<div className="sectionBlock">

										{
											form.pages.map((page, pi) => {
												return <div>
													<label className='mb-0'>Page {pi + 1}</label>
													<h1 className='mb-10'>{page.title}</h1>
													{
														page.sections.map((section) => {
															return <div>
																<h2 className='mb-10' dangerouslySetInnerHTML={{ __html: section.title }}></h2>
																{section.fields.map((field) => {
																	let value = '';
																	if (field.objectClass == 'baseInput') {
																		if (field.id.includes('-')) {
																			let split = field.id.split('-');
																			const v1 = submission.formData[split[0]];
																			value = v1 ? v1[split[1]] : '';
																		} else {
																			value = submission.formData[field.id];
																		}
																	} else if (field.objectClass == 'mappedInput') {
																		value = entries[field.id];
																	} else {
																		value = entries[field._id];
																	}
																	return <div>
																		<div dangerouslySetInnerHTML={{ __html: field.description }}></div>
																		<div>{field.label} {normalizedColumns[field._id] && <span>- {normalizedColumns[field._id]}</span>}</div>
																		<InputField value={value} disabled={true}>{entries[field._id]}</InputField>
																		{field.type == "nestedSections" && <div>
																			{field.nestedSections.map((section) => {
																				return <div className='nestedSectionBlock'>
																					<h2 className='mb-10' dangerouslySetInnerHTML={{ __html: section.title }}></h2>
																					{section.questions.map((field) => {
																						let value = '';
																						if (field.objectClass == 'baseInput') {
																							if (field.id.includes('-')) {
																								let split = field.id.split('-');
																								const v1 = submission.formData[split[0]];
																								value = v1 ? v1[split[1]] : '';
																							} else {
																								value = submission.formData[field.id];
																							}
																						} else if (field.objectClass == 'mappedInput') {
																							value = entries[field.id];
																						} else {
																							value = entries[field._id];
																						}
																						return <div>
																							<div dangerouslySetInnerHTML={{ __html: field.description }}></div>
																							<div>{field.label} {normalizedColumns[field._id] && <span>- {normalizedColumns[field._id]}</span>}</div>
																							<InputField value={value} disabled={true}>{entries[field._id]}</InputField>
																						</div>
																					})}
																				</div>
																			})}
																		</div>}
																	</div>
																})}
															</div>
														})
													}

												</div>;
											})
										}
									</div >
								</div >
							</div>)
					}
					}
					toggle={this.props.toggle}
					cancelButtonLabel={'Close'}
				></FieldModal >
			</div >

		);
	}
}
