import React, { Component, Fragment } from 'react';

import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
import BasicSectionHeader from '../../components/views/BasicSectionHeader';
import Request from './request';

import DeleteRequestsModal from './modals/deleteRequestsModal';

let batchCallback;
class ProgramRequests extends Component {
	constructor(props) {
		super(props);

		let fieldColumns = {
			name: 'Request Name',
			status: 'Status',
			submitterEmail: 'Submitter Email',
			submitterName: 'Submitter Name',
			submitDate: 'Submit Date',
			approvedDate: 'Approved Date',
			startDate: 'Start Date',
			startTime: 'Start Time',
			endDate: 'End Date',
			endTime: 'End Time',
			availabilityCalendar: 'Requested Calendar'
		};

		let fieldTypes = {
			endDate: 'date',
			endTime: 'time',
			name: 'text',
			startDate: 'date',
			startTime: 'time',
			status: 'status',
			submitterEmail: 'text',
			submitterName: 'text',
			submitDate: 'timestamp',
			approvedDate: 'timestamp',
			availabilityCalendar: 'calendar'
		};
		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'status',
					'submitterEmail',
					'submitterName',
					'submitDate',
					'approvedDate',
					'startDate',
					'startTime',
					'endDate',
					'endTime',
					'availabilityCalendar'
				]
			},
		};

		fieldColumns = { ...fieldColumns, ...props.program.fieldLabels };
		fieldTypes = { ...fieldTypes, ...props.program.fieldTypes };

		const formattedFieldColumns = {};
		const formattedFieldTypes = {};
		Object.keys(fieldColumns).forEach((key) => {
			if (key.includes('-')) {
				const shortKeys = key.split('-');
				const shortKey = shortKeys[shortKeys.length - 1];
				formattedFieldColumns[shortKey] = fieldColumns[key];
				formattedFieldTypes[shortKey] = fieldTypes[key];
			} else {
				formattedFieldColumns[key] = fieldColumns[key];
				formattedFieldTypes[key] = fieldTypes[key];
			}
		});

		const formattedRequest = props.program.requests.map((request) => {
			const formattedRequest = {};
			Object.keys(request).forEach((key) => {
				if (key.includes('-')) {
					const shortKeys = key.split('-');
					const shortKey = shortKeys[shortKeys.length - 1];
					formattedRequest[shortKey] = request[key];
				}
				formattedRequest[key] = request[key];
			});
			return formattedRequest;
		});

		this.state = {
			categoryColumns: fieldCategories,
			programId: props.program._id,
			requests: formattedRequest,
			requestViews: props.program.requestViews,
			loading: false,
			columns: formattedFieldColumns,
			columnTypes: formattedFieldTypes,
			deletedRequests: [],
			addModalOpen: false,
			toggleDeleteRequests: false,
			checkedRequests: {},
			sidePanelOpen: false,
			activeRow: ''


		};
	}

	deleteRequest = async (programId) => {
		await API().delete(`Organizations/${this.props.orgId}/programs/${programId}`).then((res) => {
			if (res.data) {
				const programs = this.state.programs.filter((e) => e._id !== programId);
				this.setState({ programs: programs });
				return true;
			} else {
				return false;
			}
		});
	};

	saveRequest = async (request, cb) => {
		const { programId } = this.state;
		await API()
			.patch(`Organizations/${this.props.orgId}/programs/${programId}/requests/${request._id}`, {
				updatedRequest: request
			})
			.then((res) => {
				if (res.data) {
					const requests = this.state.requests.map((e) => {
						if (e._id === request._id) {
							return { ...e, ...res.data };
						} else {
							return e;
						}
					});
					this.setState({ requests: requests });
					cb && cb();
				} else {
					return false;
				}
			});
	};

	deleteRequests = async (callback) => {
		const { programId } = this.state;

		const requestIds = Object.keys(this.state.checkedRequests);

		await API()
			.patch(`Organizations/${this.props.orgId}/programs/${programId}/requests`, { requestIds })
			.then((res) => {
				if (res) {
					this.setState({
						deletedRequests: [...this.state.deletedRequests, ...requestIds],
						requests: this.state.requests.filter((r) => !requestIds.includes(r._id))
					}, () => {
						batchCallback && batchCallback('delete');
						callback();
					});
				}
			})
			.catch((e) => {
				console.log(e);
			})


	};

	toggleDeleteRequests = (checkedRequests, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedRequests: checkedRequests || {},
			toggleDeleteRequests: !this.state.toggleDeleteRequests
		});
	};
	setActiveRow = (requestId) => {
		this.setState({ activeRow: requestId })
	}

	render() {
		const { program, orgId } = this.props;
		const { loading, requestViews, categoryColumns, columns, requests, columnTypes, deletedRequests, toggleDeleteRequests, checkedRequests, sidePanelOpen, activeRow } = this.state;
		return (
			<Switch>
				<Route path={`/${orgId}/programs/${program._id}/requests`}>
					<Fragment>
						<ViewsContainer
							activeRow={activeRow}
							categoryColumns={categoryColumns}
							views={requestViews}
							entries={requests}
							columns={columns}
							columnTypes={columnTypes}
							title="Program Requests"
							subtitle={null}
							readOnly={false}
							mainActions={[]}
							batchActions={[
								{
									type: 'button',
									onClick: this.toggleDeleteRequests,
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger'
								}
							]} enableDownloadViews={true}
							orgId={this.props.orgId}
							type={'ProgramRequests'}
							referenceCollection={'Program'}
							referenceId={program._id}
							addedEntries={[]}
							deletedEntries={deletedRequests}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>
						{toggleDeleteRequests && (
							<DeleteRequestsModal
								isOpen={toggleDeleteRequests}
								toggle={this.toggleDeleteRequests}
								delete={this.deleteRequests}
								deleting={this.state.deletingEvents}
								deleteCount={this.state.deletedEvents}
								count={Object.keys(checkedRequests).length}
							/>
						)}

						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/programs/:programId/requests/:requestId`}
									render={(props) => {
										const requestId = props.match.params.requestId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={requestId}>
											<Request
												program={program}
												requests={requests}
												orgId={this.props.orgId}
												saveRequest={this.saveRequest}
												fieldLabels={columns}
												setActiveRow={this.setActiveRow}
											/>
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organzation/programs/:programId/requests`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route >
			</Switch>
		);
	}
}

export default withRouter(ProgramRequests);
