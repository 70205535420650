import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'react-toggle/style.css';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import fileIcon from '../../assets/img/icons/file.png';
import InputField from '../inputs/inputField';
import SelectField from '../inputs/selectField';
import ToggleField from '../inputs/toggleField';
import TextAreaField from '../inputs/textAreaField';
import ColorField from '../inputs/colorField';
import FileChooserPreview from '../inputs/fileChooserPreview';
import RichTextEditor from '../../views/pages/components/richTextEditor';
import FilesUploaderModal from '../files/filesUploaderModal';
import OpenSelectField from '../inputs/openSelectField';
import CheckboxInput from '../inputs/checkboxInput';
import CodeEditor from '../codeEditor/codeEditor';
import IconChooser from '../inputs/iconChooser';
import icons from './icons'

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#2b83f166' : 'transparent'
    //overflowX: 'hidden'
});

const getComponentListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#2b83f166' : 'transparent',
    padding: '0 25px',
    margin: '0 -25px',
    marginBottom: '5px'
});

const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    //background: isDragging ? 'lightgrey' : 'white',
    /* borderBottom: '1px solid grey',
    padding: '15px 0', */
    // styles we need to apply on draggables
    ...draggableStyle
});

class EmailEditorComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedComponentTab: 'General',
            componentSelected: false,
            selectedPartialIndex: null,
            parameterSelected: false,
            parameterPath: '',
            color: {},
            displayColorPickers: {},
            // optional, reset this if you dont want to preserve selectscted component tab for nested compmonents
            selectedNestedComponentTab: {},
            customFields: this.props.customFields,
            nestedIndexSelected: -1
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = reorder(this.props.editedPage.partials, result.source.index, result.destination.index);
        this.props.updatePartialOrder(items);
    }

    focusComponent = (partialIndex) => {

        this.props.focusComponent(partialIndex);

    };

    focusNestedComponent = (nestedIndex) => {
        this.setState({ nestedIndexSelected: nestedIndex })
    };

    unfocusComponent = () => {
        this.props.focusComponent(false, 0);
        this.props.setParameterSelected(false, '');
    };

    renderParameters = (pIndex, param, onChange, propertyOptions) => {
        const { editedPage } = this.props;
        const { componentSelected, selectedPartialIndex } = this.props;
        const selectedPartial = componentSelected && editedPage.partials[selectedPartialIndex];
        let paramComponent = null;
        const paramType = param.type || typeof param.value;

        return (
            <div className={`componentParameter ${paramType}`} key={`${param.key}-${pIndex}`}>
                {/* Array.isArray(param.value) ? (
					<label className="paramLabel array calibreRegular collapsed">{param.label || param.key}</label>
				) : !hideLabel ? (
					<label className="paramLabel calibreRegular" style={{ margin: 0 }}>
						{param.label || param.key}{' '}
					</label>
				) : (
					''
				) */}
                {paramComponent}
            </div>
        );
    };

    setToValue(obj, value, path) {
        var i;
        path = path.split('.');
        for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

        obj[path[i]] = value;
    }


    renderNestedComponent = () => {
        const { editedEmailTemplate, focusedComponentIndex, expanded, expandPanel, togglePanel, updateEmailComponent } = this.props;
        const { emailComponents } = editedEmailTemplate;
        const component = emailComponents[focusedComponentIndex];

        const { nestedIndexSelected } = this.state;

        let item = {};
        let componentConfig = null;

        switch (component.type) {

            case 'Columnlayout':
                item = component.gridItems[nestedIndexSelected];

                componentConfig = (
                    <div>
                        <div class="editorFileInput">


                            <FileChooserPreview
                                label="Image File"
                                value={item.imageSrc}
                                onClick={() => {
                                    item.imageSrc = 'choosing__' + item.imageSrc;
                                    updateEmailComponent(emailComponents);
                                }}
                                clearFile={() => {
                                    item.imageSrc = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />
                            {item.imageSrc.includes('choosing__') &&
                                <FilesUploaderModal
                                    isOpen={item.imageSrc.includes('choosing__')}
                                    toggle={() => {
                                        item.imageSrc = item.imageSrc.replace('choosing__', '');
                                        this.setState({ emailComponents, editing: true, editCount: this.state.editCount + 1 }, this.updateFromComponents);
                                    }}
                                    eventId={this.props.eventId}
                                    links={this.props.links}
                                    orgId={this.props.orgId}
                                    addFiles={(fileURL) => {

                                        getMeta(fileURL, (err, img) => {
                                            console.log(img.naturalWidth, img.naturalHeight);
                                            //limit to 700px wide
                                            if (img.naturalWidth > 700) {
                                                item.height = img.naturalHeight * (700 / img.naturalWidth);
                                                item.width = 700;
                                            } else {
                                                item.height = img.naturalHeight;
                                                item.width = img.naturalWidth;
                                            }
                                            item.imageSrc = fileURL;
                                            updateEmailComponent(emailComponents);
                                        });
                                    }}
                                />}

                        </div>
                        <InputField label="Alt Text" type="text" value={item.altText} onChange={(e) => {
                            item.altText = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />
                        <InputField label="Image Target URL" type="text" value={item.target} onChange={(e) => {
                            item.target = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Title</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.title} onChange={(value) => {
                                item.title = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Description</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.description} onChange={(value) => {
                                item.description = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>
                    </div>);





                break;
            case 'Tablegrid':
                item = component.items[nestedIndexSelected];

                componentConfig = (



                    <div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Label</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.label} onChange={(value) => {
                                item.label = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Value</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.value} onChange={(value) => {
                                item.value = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>
                    </div>

                );
                break;
            case 'Tablecolumns':
                item = component.items[nestedIndexSelected];

                componentConfig = (



                    <div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">First</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.first} onChange={(value) => {
                                item.first = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Second</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.second} onChange={(value) => {
                                item.second = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>
                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Third</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={item.third} onChange={(value) => {
                                item.third = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>
                    </div>

                );
                break;
        }

        return <Fragment>
            <div key={`pd_${component.type}_${focusedComponentIndex}`}>

                {/* <div className="flex-ac flex-sb mb-10 mt-10">
                    <button onClick={
                        expandPanel

                    } >{expanded ? <i className='las la-file-export'></i> : <i className='las la-file-import'></i>}</button>
                    <h4>{component.type}</h4>
                    <button onClick={togglePanel}><i className="las la-times"></i></button>
                </div> */}

                <div className="flex-ac mb-10">
                    <div onClick={() => { this.focusNestedComponent(-1) }} className="c-pointer">
                        <i className="las la-angle-left mr-5" />Back

                    </div>
                </div>
                <div className="flex-ac flex-sb mb-10">
                    <div className="w-100 mr-15 flex-ac">

                        <InputField
                            label="Component Name"
                            classes="mb-0"
                            value={item.itemTitle ? item.itemTitle : ''}
                            placeholder={'Item'}
                            inputIcon={<i className="las la-pen" />}
                            onChange={(e) => {
                                item.itemTitle = e.target.value;
                                updateEmailComponent(emailComponents);
                            }}
                        />
                    </div>
                    <UncontrolledDropdown inNavbar className="flex-ac shrink">
                        <DropdownToggle className="columnToggle">
                            <i className="las la-ellipsis-h" />
                        </DropdownToggle>
                        <DropdownMenu right className="columnDropdown">
                            <div className='ph-20'>
                                <h5 className='mb-10'>Component Options</h5>


                                <DropdownItem

                                    onClick={() => {

                                        if (component.type == 'Tablecolumns' || component.type == 'Tablegrid') {

                                            component.items.push(JSON.parse(JSON.stringify(item)));

                                        } else {

                                            component.gridItems.push(JSON.parse(JSON.stringify(item)));
                                        }

                                        this.props.updateEmailComponent(emailComponents);

                                        this.focusNestedComponent(-1);
                                    }}
                                >

                                    <i className="las la-copy mr-25" />Duplicate

                                </DropdownItem>
                            </div>
                            <hr />
                            <div className='ph-20'>
                                <DropdownItem
                                    onClick={() => {

                                        if (component.type == 'Tablecolumns' || component.type == 'Tablegrid') {
                                            component.items.splice(nestedIndexSelected, 1);

                                        } else {
                                            component.gridItems.splice(nestedIndexSelected, 1);
                                        }
                                        updateEmailComponent(emailComponents);

                                        this.focusNestedComponent(-1)
                                    }}
                                >
                                    <i className="las la-trash mr-25 danger" />Remove
                                </DropdownItem>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                {componentConfig}
            </div>
        </Fragment>;
    }



    renderPadding = (emailComponents, component, disableLeftRight) => {
        const { updateEmailComponent } = this.props;



        return (
            <div>
                <h2 class="blockHeader">Padding</h2>
                <div className='flex-ac flex-sb mb-10 mt-10'>

                    <InputField label="Top" classes={`${disableLeftRight ? 'w-50' : 'w-25'}`} type="number" min="0" value={component.paddingTop} onChange={(e) => {
                        component.paddingTop = e.target.value;
                        updateEmailComponent(emailComponents);
                    }} />
                    {!disableLeftRight && <InputField label="Right" classes={'w-25'} type="number" min="0" value={component.paddingRight} onChange={(e) => {
                        component.paddingRight = e.target.value;
                        updateEmailComponent(emailComponents);
                    }} />}
                    <InputField label="Bottom" classes={`${disableLeftRight ? 'w-50' : 'w-25'}`} type="number" min="0" value={component.paddingBottom} onChange={(e) => {
                        component.paddingBottom = e.target.value;
                        updateEmailComponent(emailComponents);
                    }} />
                    {!disableLeftRight && <InputField label="Left" classes={'w-25'} type="number" min="0" value={component.paddingLeft} onChange={(e) => {
                        component.paddingLeft = e.target.value;
                        updateEmailComponent(emailComponents);
                    }} />}
                </div>
            </div>
        )
    }

    renderAlignment = (emailComponents, component) => {
        const { updateEmailComponent } = this.props;


        return (<div className=''>
            <h4 class="mb-10 mt-10">Alignment Options</h4>
            <div className='flex-ac flex-sb mb-10 mt-10'>

                <CheckboxInput

                    label={'Left'}
                    onClick={() => {
                        component.alignment = 'left';
                        updateEmailComponent(emailComponents);
                    }}
                    checked={component.alignment == 'left'}
                />
                <CheckboxInput

                    label={'Center'}
                    onClick={() => {
                        component.alignment = 'center';
                        updateEmailComponent(emailComponents);
                    }}
                    checked={component.alignment == 'center'}
                />
                <CheckboxInput

                    label={'Right'}
                    onClick={() => {
                        component.alignment = 'right';
                        updateEmailComponent(emailComponents);
                    }}
                    checked={component.alignment == 'right'}
                />
            </div>
        </div>)
    }

    renderComponentDetails = () => {
        const { editedEmailTemplate, focusedComponentIndex, expanded, expandPanel, togglePanel, updateEmailComponent } = this.props;
        const { emailComponents } = editedEmailTemplate;


        if (focusedComponentIndex === -1 || emailComponents.length === 0) {
            return null;
        }

        const component = emailComponents[focusedComponentIndex];

        let componentConfig = null;

        switch (component.type) {
            case 'Navbar':
                componentConfig = (
                    <div className="componentDetails" key={`navbar-${component._id}`}>

                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />

                        {this.renderPadding(emailComponents, component)}

                        {this.renderAlignment(emailComponents, component)}

                        <h2 className='blockHeader'>Logo Image</h2>
                        <div class="editorFileInput">
                            <FileChooserPreview
                                label="Image File"
                                value={component.src}
                                onClick={() => {
                                    component.src = 'choosing__' + component.src;
                                    updateEmailComponent(emailComponents);
                                }}
                                clearFile={() => {
                                    component.src = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />
                            {component.src.includes('choosing__') &&
                                <FilesUploaderModal
                                    isOpen={component.src.includes('choosing__')}
                                    toggle={() => {
                                        component.src = component.src.replace('choosing__', '');
                                        updateEmailComponent(emailComponents);
                                    }}
                                    eventId={this.props.eventId}
                                    links={this.props.links}
                                    orgId={this.props.orgId}
                                    addFiles={(fileURL) => {

                                        getMeta(fileURL, (err, img) => {
                                            console.log(img.naturalWidth, img.naturalHeight);
                                            //limit to 700px wide
                                            if (img.naturalWidth > 700) {
                                                component.height = img.naturalHeight * (700 / img.naturalWidth);
                                                component.width = 700;
                                            } else {
                                                component.height = img.naturalHeight;
                                                component.width = img.naturalWidth;
                                            }
                                            component.src = fileURL;
                                            updateEmailComponent(emailComponents);
                                        });
                                    }}
                                />}
                        </div>
                        <InputField label="Alt Text" type="text" value={component.altText} onChange={(e) => {
                            component.altText = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <InputField label="Logo Height" type="number" value={component.maxLogoHeight} onChange={(e) => {
                            component.maxLogoHeight = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <InputField label="Logo Target" type="text" value={component.logoSrc} onChange={(e) => {
                            component.logoSrc = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />
                    </div>
                );
                break;
            case 'Image':
                componentConfig = (
                    <div className="componentDetails" key={`image-${component._id}`}>
                        <div class="editorFileInput">
                            <FileChooserPreview
                                label="Image File"
                                value={component.src}
                                onClick={() => {
                                    component.src = 'choosing__' + component.src;
                                    updateEmailComponent(emailComponents);
                                }}
                                clearFile={() => {
                                    component.src = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />
                            {component.src.includes('choosing__') &&
                                <FilesUploaderModal
                                    isOpen={component.src.includes('choosing__')}
                                    toggle={() => {
                                        component.src = component.src.replace('choosing__', '');
                                        updateEmailComponent(emailComponents);
                                    }}
                                    eventId={this.props.eventId}
                                    links={this.props.links}
                                    orgId={this.props.orgId}
                                    addFiles={(fileURL) => {

                                        getMeta(fileURL, (err, img) => {
                                            console.log(img.naturalWidth, img.naturalHeight);
                                            //limit to 700px wide
                                            if (img.naturalWidth > 700) {
                                                component.height = img.naturalHeight * (700 / img.naturalWidth);
                                                component.width = 700;
                                            } else {
                                                component.height = img.naturalHeight;
                                                component.width = img.naturalWidth;
                                            }
                                            component.src = fileURL;
                                            updateEmailComponent(emailComponents);
                                        });
                                    }}
                                />}
                        </div>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = typeof value == 'object' ? '' : value;
                            updateEmailComponent(emailComponents);
                        }} />

                        {this.renderPadding(emailComponents, component)}

                        <InputField label="Width" type="number" max={700} value={component.width} onChange={(e) => {
                            component.width = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <InputField label="Alt Text" type="text" value={component.alt} onChange={(e) => {
                            component.alt = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <ColorField useHex={true} label="Border Color" value={component.borderColor} onChange={(value) => {
                            component.borderColor = typeof value == 'object' ? '' : value;
                            updateEmailComponent(emailComponents);
                        }} />
                        <InputField label="Border Width" type="number" value={component.borderWidth} onChange={(e) => {
                            component.borderWidth = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <InputField label="Rounded Border" type="number" value={component.borderRadius} onChange={(e) => {
                            component.borderRadius = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <InputField label="Link URL" type="text" value={component.link} onChange={(e) => {
                            component.link = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />
                    </div>);
                break;
            case 'Hairline':
                componentConfig = (
                    <div className="componentDetails" key={`hairline-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />
                        <InputField label=" Width" type="number" value={component.width} onChange={(e) => {
                            component.width = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />


                        {this.renderPadding(emailComponents, component, true)}

                        <ColorField useHex={true} label="Color" value={component.color} onChange={(value) => {
                            component.color = value;
                            updateEmailComponent(emailComponents);
                        }} />
                        <InputField label="Thickness" type="number" value={component.thickness} onChange={(e) => {
                            component.thickness = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />
                    </div>
                )
                break;
            case 'Locationblock':
                componentConfig = (
                    <div className="componentDetails" key={`locationblock-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = typeof value == 'object' ? '' : value;

                            updateEmailComponent(emailComponents);
                        }} />
                        <div class="editorFileInput">
                            <FileChooserPreview
                                label="Image File"
                                value={component.imageUrl}
                                onClick={() => {
                                    component.imageUrl = 'choosing__' + component.imageUrl;
                                    updateEmailComponent(emailComponents);
                                }}
                                clearFile={() => {
                                    component.imageUrl = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />
                            {component.imageUrl.includes('choosing__') &&
                                <FilesUploaderModal
                                    isOpen={component.imageUrl.includes('choosing__')}
                                    toggle={() => {
                                        component.imageUrl = component.imageUrl.replace('choosing__', '');
                                        updateEmailComponent(emailComponents);
                                    }}
                                    eventId={this.props.eventId}
                                    links={this.props.links}
                                    orgId={this.props.orgId}
                                    addFiles={(fileURL) => {

                                        getMeta(fileURL, (err, img) => {
                                            console.log(img.naturalWidth, img.naturalHeight);
                                            //limit to 700px wide
                                            if (img.naturalWidth > 700) {
                                                component.height = img.naturalHeight * (700 / img.naturalWidth);
                                                component.width = 700;
                                            } else {
                                                component.height = img.naturalHeight;
                                                component.width = img.naturalWidth;
                                            }
                                            component.imageUrl = fileURL;
                                            updateEmailComponent(emailComponents);
                                        });
                                    }}
                                />}
                        </div>
                        <InputField label="Alt Text" type="text" value={component.altText} onChange={(e) => {
                            component.altText = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <InputField label="Image Target" type="text" value={component.imageTarget} onChange={(e) => {
                            component.imageTarget = e.target.value;
                            updateEmailComponent(emailComponents);

                        }} />


                        {this.renderPadding(emailComponents, component)}



                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">When Label</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.whenText} onChange={(value) => {
                                component.whenText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">When Content</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.whenDescription} onChange={(value) => {
                                component.whenDescription = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Where Label</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.whereText} onChange={(value) => {
                                component.whereText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Where Content</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.whereDescription} onChange={(value) => {
                                component.whereDescription = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.descriptionText} onChange={(value) => {
                                component.descriptionText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                    </div>);
                break;
            case 'Sideimage':
                componentConfig = (
                    <div className="componentDetails" key={`sideimage-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = typeof value == 'object' ? '' : value;

                            updateEmailComponent(emailComponents);
                        }} />
                        <div class="editorFileInput">
                            <FileChooserPreview
                                label="Image File"
                                value={component.imageUrl}
                                onClick={() => {
                                    component.imageUrl = 'choosing__' + component.imageUrl;
                                    updateEmailComponent(emailComponents);
                                }}
                                clearFile={() => {
                                    component.imageUrl = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />
                            {component.imageUrl.includes('choosing__') &&
                                <FilesUploaderModal
                                    isOpen={component.imageUrl.includes('choosing__')}
                                    toggle={() => {
                                        component.imageUrl = component.imageUrl.replace('choosing__', '');
                                        updateEmailComponent(emailComponents);
                                    }}
                                    eventId={this.props.eventId}
                                    links={this.props.links}
                                    orgId={this.props.orgId}
                                    addFiles={(fileURL) => {

                                        getMeta(fileURL, (err, img) => {
                                            console.log(img.naturalWidth, img.naturalHeight);
                                            //limit to 700px wide
                                            if (img.naturalWidth > 700) {
                                                component.height = img.naturalHeight * (700 / img.naturalWidth);
                                                component.width = 700;
                                            } else {
                                                component.height = img.naturalHeight;
                                                component.width = img.naturalWidth;
                                            }
                                            component.imageUrl = fileURL;
                                            updateEmailComponent(emailComponents);
                                        });
                                    }}
                                />}
                        </div>

                        <InputField label="Alt Text" type="text" value={component.altText} onChange={(e) => {
                            component.altText = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />



                        <InputField label="Target URL" type="text" value={component.imageTarget} onChange={(e) => {
                            component.imageTarget = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />








                        {this.renderPadding(emailComponents, component)}



                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Side Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.sideText} onChange={(value) => {
                                component.sideText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                        <ToggleField
                            checked={component.reverse}
                            label="Reverse"
                            icons={false}
                            onChange={(e) => {
                                component.reverse = e.target.checked;
                                updateEmailComponent(emailComponents);
                            }}
                        />






                    </div>);
                break;

            case 'Columnlayout':

                componentConfig = (

                    <div className="componentDetails" key={`image-${component._id}`}>

                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <div className=''>
                            <h4 class="mb-10 mt-10">Column Option</h4>
                            <div className='flex-ac flex-sb mb-10 mt-10'>
                                <CheckboxInput

                                    label={'1'}
                                    onClick={() => {
                                        component.columnOption = 1;
                                        updateEmailComponent(emailComponents);
                                    }}
                                    checked={component.columnOption == 1}
                                />
                                <CheckboxInput

                                    label={'2'}
                                    onClick={() => {
                                        component.columnOption = 2;
                                        updateEmailComponent(emailComponents);
                                    }}
                                    checked={component.columnOption == 2}
                                />
                                <CheckboxInput

                                    label={'3'}
                                    onClick={() => {
                                        component.columnOption = 3;
                                        updateEmailComponent(emailComponents);
                                    }}
                                    checked={component.columnOption == 3}
                                />
                                <CheckboxInput

                                    label={'4'}
                                    onClick={() => {
                                        component.columnOption = 4;
                                        updateEmailComponent(emailComponents);
                                    }}
                                    checked={component.columnOption == 4}
                                />
                            </div>
                        </div>

                        <h2 class="blockHeader">Padding</h2>
                        <div className='flex-ac flex-sb mb-10 mt-10'>

                            <InputField label="Top" classes={'w-25'} type="number" value={component.paddingTop} onChange={(e) => {
                                component.paddingTop = e.target.value;
                                updateEmailComponent(emailComponents);
                            }} />
                            <InputField label="Right" classes={'w-25'} type="number" value={component.paddingRight} onChange={(e) => {
                                component.paddingRight = e.target.value;
                                updateEmailComponent(emailComponents);
                            }} />
                            <InputField label="Bottom" classes={'w-25'} type="number" value={component.paddingBottom} onChange={(e) => {
                                component.paddingBottom = e.target.value;
                                updateEmailComponent(emailComponents);
                            }} />
                            <InputField label="Left" classes={'w-25'} type="number" value={component.paddingLeft} onChange={(e) => {
                                component.paddingLeft = e.target.value;
                                updateEmailComponent(emailComponents);
                            }} />
                        </div>




                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Title</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.title} onChange={(value) => {
                                component.title = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                        <h2 class="blockHeader mb-10">Grid Items</h2>


                        <DragDropContext
                            onDragEnd={(result) => {
                                if (!result.destination) {
                                    return;
                                }
                                const items = reorder(
                                    component.gridItems,
                                    result.source.index,
                                    result.destination.index
                                );
                                component.gridItems = items;
                                updateEmailComponent(emailComponents);
                            }}
                            className="paramArrayElements"
                        >


                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        {component.gridItems.map((item, i) => {
                                            return (

                                                <Draggable
                                                    key={`#${item.type}_${i}`}
                                                    draggableId={`#${item.type}_${i}`}
                                                    index={i}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >


                                                            <div className="paramArrayItem">
                                                                <div className="flex-ac w-100" style={{ height: 50 }}>
                                                                    <i className="las la-braille mr-5" />
                                                                    <div
                                                                        className="w-100 flex-ac c-pointer"
                                                                        onClick={() => {
                                                                            this.focusNestedComponent(i);
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <h4>
                                                                                {item.itemTitle ? item.itemTitle : 'Item'}
                                                                            </h4>

                                                                        </div>
                                                                    </div>
                                                                    {true && (
                                                                        <UncontrolledDropdown inNavbar>
                                                                            <DropdownToggle className="columnToggle flex-ac">
                                                                                <i className="las la-ellipsis-h" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu
                                                                                right
                                                                                className="columnDropdown  p-20"
                                                                            >
                                                                                <h5 className='mb-10'>Options</h5>
                                                                                <DropdownItem
                                                                                    className="dropdownItem"
                                                                                    onClick={() => {


                                                                                        component.gridItems.push(JSON.parse(JSON.stringify(component.gridItems[i])));
                                                                                        updateEmailComponent(emailComponents);
                                                                                    }}

                                                                                >
                                                                                    <i className="las la-copy mr-10" />Duplicate

                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    className="dropdownItem"
                                                                                    onClick={() => {
                                                                                        component.gridItems.splice(i, 1);
                                                                                        updateEmailComponent(emailComponents);
                                                                                    }}
                                                                                >
                                                                                    <i className="las la-trash mr-10" />Remove
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>



                        <div
                            className="paramArrayAdd actionButton actionOutline"
                            onClick={() => {
                                component.gridItems.push({
                                    imageSrc: '',
                                    title: `<div style="text-align:center;"><span style="font-size: 16px;font-family: Arial;"><strong>Title</strong></span></div>`,
                                    description: `<div style="text-align:center;"><span style="font-size: 16px;font-family: Arial;">Subtitle</span></div>`,
                                    imageLink: ''
                                })
                                updateEmailComponent(emailComponents);
                            }}
                        >
                            <i class="las la-plus"></i><div>Add Item</div>
                        </div>



                    </div >);
                break;

            case 'Contentblock':
                componentConfig = (
                    <div className="componentDetails" key={`contentblock-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />



                        {this.renderPadding(emailComponents, component)}

                        <h2 class="blockHeader">Header Options</h2>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.headerText} onChange={(value) => {
                                component.headerText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign']}
                                blockTypeOptions={['H1']}
                                defaultElement={`<h1><span style="margin:0;font-size: 50px;text-align:center;font-family: Arial;">&nbsp</span></h1>`}
                            />
                        </div>

                        <h2 class="blockHeader">Subtitle Options</h2>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.subtitleText} onChange={(value) => {
                                component.subtitleText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                        <h2 class="blockHeader">Description Options</h2>



                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.descriptionText} onChange={(value) => {
                                component.descriptionText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                        <ToggleField
                            checked={component.enableCTA}
                            label="Enable CTA"
                            icons={false}
                            onChange={(e) => {
                                component.enableCTA = e.target.checked;
                                updateEmailComponent(emailComponents);
                            }}
                        />

                        {component.enableCTA &&
                            <div>
                                <h2 class="blockHeader">CTA Options</h2>
                                <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                                    <div className="labelContainer">
                                        <div className="formFieldLabel">Text</div>
                                    </div>
                                    <RichTextEditor id={`textcomp-${component._id}`} value={component.ctaText} onChange={(value) => {
                                        component.ctaText = value;
                                        updateEmailComponent(emailComponents);
                                    }}
                                        customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                        options={['textAlign']}
                                    />
                                </div>

                                <div>
                                    <h2 class="blockHeader">Padding</h2>
                                    <div className='flex-ac flex-sb mb-10 mt-10'>

                                        <InputField label="Top" classes={`w-25`} type="number" value={component.ctaPaddingTop} onChange={(e) => {
                                            component.ctaPaddingTop = e.target.value;
                                            updateEmailComponent(emailComponents);
                                        }} />
                                        <InputField label="Right" classes={'w-25'} type="number" value={component.ctaPaddingRight} onChange={(e) => {
                                            component.ctaPaddingRight = e.target.value;
                                            updateEmailComponent(emailComponents);
                                        }} />
                                        <InputField label="Bottom" classes={`w-25`} type="number" value={component.ctaPaddingBottom} onChange={(e) => {
                                            component.ctaPaddingBottom = e.target.value;
                                            updateEmailComponent(emailComponents);
                                        }} />
                                        <InputField label="Left" classes={'w-25'} type="number" value={component.ctaPaddingLeft} onChange={(e) => {
                                            component.ctaPaddingLeft = e.target.value;
                                            updateEmailComponent(emailComponents);
                                        }} />
                                    </div>
                                </div>



                                <div className=''>
                                    <h4 class="mb-10 mt-10">Alignment</h4>
                                    <div className='flex-ac flex-sb mb-10 mt-10'>
                                        <CheckboxInput
                                            label={'Left'}
                                            onClick={() => {
                                                component.ctaAlignment = 'left';
                                                updateEmailComponent(emailComponents);
                                            }}
                                            checked={component.ctaAlignment == 'left'}
                                        />
                                        <CheckboxInput
                                            label={'Center'}
                                            onClick={() => {
                                                component.ctaAlignment = 'center';
                                                updateEmailComponent(emailComponents);
                                            }}
                                            checked={component.ctaAlignment == 'center'}
                                        />
                                        <CheckboxInput

                                            label={'Right'}
                                            onClick={() => {
                                                component.ctaAlignment = 'right';
                                                updateEmailComponent(emailComponents);
                                            }}
                                            checked={component.ctaAlignment == 'right'}
                                        />
                                    </div>
                                </div>


                                <SelectField
                                    label={'Button Type'}
                                    options={[{ value: 'Fill', label: 'Fill' }, { value: 'Outline', label: 'Outline' }]}
                                    onChange={(e) => {

                                        component.ctaType = e.value;
                                        updateEmailComponent(emailComponents);
                                    }}
                                    value={{ value: component.ctaType, label: component.ctaType }}

                                ></SelectField>



                                {component.ctaType == "Fill" ? (

                                    <ColorField useHex={true} label="Background Color" value={component.ctaBackgroundColor} onChange={(value) => {
                                        component.ctaBackgroundColor = value;
                                        updateEmailComponent(emailComponents);
                                    }} />

                                ) : (
                                    <ColorField useHex={true} label="Outline Color" value={component.ctaOutlineColor} onChange={(value) => {
                                        component.ctaOutlineColor = value;
                                        updateEmailComponent(emailComponents);
                                    }} />
                                )
                                }




                                <div className="labelContainer">
                                    <div className="formFieldLabel">CTA Icon</div>
                                </div>
                                <IconChooser
                                    label="Icon"
                                    value={component.ctaIcon}
                                    onClick={(value) => {
                                        component.ctaIcon = value
                                        updateEmailComponent(emailComponents);
                                    }}
                                    clearIcon={(value) => {
                                        component.ctaIcon = '';
                                        updateEmailComponent(emailComponents);
                                    }}
                                />


                                <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">

                                    <div className='flex aic'>
                                        <InputField label="Icon Size" min={"0"} type="number" value={component.ctaIconSize} onChange={(e) => {
                                            component.ctaIconSize = e.target.value;
                                            updateEmailComponent(emailComponents);
                                        }} />
                                        <ColorField useHex={true} label="Icon Color" value={component.ctaIconColor} onChange={(value) => {
                                            component.ctaIconColor = value;
                                            updateEmailComponent(emailComponents);
                                        }} clickIcon={true} />

                                    </div>
                                </div>

                                <div className=''>
                                    <h4 class="mb-10 mt-10">CTA Icon Placement</h4>
                                    <div className='flex-ac flex-sb mb-10 mt-10'>
                                        <CheckboxInput
                                            label={'Left'}
                                            onClick={() => {
                                                component.ctaIconPlacement = 'left';
                                                updateEmailComponent(emailComponents);
                                            }}
                                            checked={component.ctaIconPlacement == 'left'}
                                        />
                                        <CheckboxInput

                                            label={'Right'}
                                            onClick={() => {
                                                component.ctaIconPlacement = 'right';
                                                updateEmailComponent(emailComponents);
                                            }}
                                            checked={component.ctaIconPlacement == 'right'}
                                        />
                                    </div>
                                </div>

                                <InputField label="CTA Border Radius" type="number" value={component.ctaBorderRadius} onChange={(e) => {
                                    component.ctaBorderRadius = e.target.value;
                                    updateEmailComponent(emailComponents);
                                }} />

                                <InputField label="CTA Target" type="text" value={component.ctaUrl} onChange={(e) => {
                                    component.ctaUrl = e.target.value;
                                    updateEmailComponent(emailComponents);
                                }} />
                            </div>
                        }



                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Disclaimer</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.disclaimerText} onChange={(value) => {
                                component.disclaimerText = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign']}
                            />
                        </div>

                        {/* <InputField label="Disclaimer Text" type="text" value={component.disclaimerText} onChange={(e) => {
							component.disclaimerText = e.target.value;
							this.setState({ emailComponents, editing: true, editCount: this.state.editCount + 1 }, this.updateFromComponents);
						}} /> */}

                    </div >
                );
                break;

            case 'Tablecolumns':
                componentConfig = (
                    <div className="componentDetails" key={`tablecolumns-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />



                        {this.renderPadding(emailComponents, component)}

                        <h2 class="blockHeader">Header Options</h2>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Title</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.title} onChange={(value) => {
                                component.title = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>


                        <InputField label="First Column Width" type="number" value={component.firstColumnWidth} onChange={(e) => {
                            component.firstColumnWidth = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />
                        <InputField label="Second Column Width" type="number" value={component.secondColumnWidth} onChange={(e) => {
                            component.secondColumnWidth = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />
                        <InputField label="Third Column Width" type="number" value={component.thirdColumnWidth} onChange={(e) => {
                            component.thirdColumnWidth = e.target.value;
                            updateEmailComponent(emailComponents);
                        }} />

                        <DragDropContext
                            onDragEnd={(result) => {
                                if (!result.destination) {
                                    return;
                                }
                                const items = reorder(
                                    component.items,
                                    result.source.index,
                                    result.destination.index
                                );
                                component.items = items;
                                updateEmailComponent(emailComponents);
                            }}
                            className="paramArrayElements"
                        >


                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        {component.items.map((item, i) => {
                                            return (

                                                <Draggable
                                                    key={`#${item.type}_${i}`}
                                                    draggableId={`#${item.type}_${i}`}
                                                    index={i}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >


                                                            <div className="paramArrayItem">
                                                                <div className="flex-ac w-100" style={{ height: 50 }}>
                                                                    <i className="las la-braille mr-5" />
                                                                    <div
                                                                        className="w-100 flex-ac c-pointer"
                                                                        onClick={() => {
                                                                            this.focusNestedComponent(i);
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <h4>
                                                                                {item.itemTitle ? item.itemTitle : 'Row'}
                                                                            </h4>

                                                                        </div>
                                                                    </div>
                                                                    {true && (
                                                                        <UncontrolledDropdown inNavbar>
                                                                            <DropdownToggle className="columnToggle flex-ac">
                                                                                <i className="las la-ellipsis-h" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu
                                                                                right
                                                                                className="columnDropdown  p-20"
                                                                            >
                                                                                <h5 className='mb-10'>Options</h5>
                                                                                <DropdownItem
                                                                                    className="dropdownItem"
                                                                                    onClick={() => {
                                                                                        component.items.push(JSON.parse(JSON.stringify(component.items[i])));
                                                                                        updateEmailComponent(emailComponents);
                                                                                    }}

                                                                                >
                                                                                    <i className="las la-copy mr-10" />Duplicate

                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    className="dropdownItem"
                                                                                    onClick={() => {
                                                                                        component.items.splice(i, 1);
                                                                                        updateEmailComponent(emailComponents);


                                                                                    }}
                                                                                >
                                                                                    <i className="las la-trash mr-10" />Remove
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>



                        <div
                            className="paramArrayAdd actionButton actionOutline"
                            onClick={() => {
                                component.items.push(
                                    {
                                        first: `<div><span style="color: rgb(66,66,66);font-size: 15px;font-family: Inter, sans-serif;"><strong>All Day</strong></span></div>`,
                                        second: `<div><span style="color: rgb(66,66,66);font-size: 15px;font-family: Inter, sans-serif;">Arrivals</span></div>`,
                                        third: `<div><span style="color: rgb(66,66,66);font-size: 15px;font-family: Inter, sans-serif;">Hotel Lobby</span></div>`
                                    }
                                )
                                updateEmailComponent(emailComponents);
                            }}
                        >
                            <i class="las la-plus"></i><div>Add Row</div>
                        </div>

                    </div >
                );
                break;

            case 'Tablegrid':
                componentConfig = (
                    <div className="componentDetails" key={`contentblock-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />



                        {this.renderPadding(emailComponents, component)}

                        <h2 class="blockHeader">Header Options</h2>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Title</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.title} onChange={(value) => {
                                component.title = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>







                        <DragDropContext
                            onDragEnd={(result) => {
                                if (!result.destination) {
                                    return;
                                }
                                const items = reorder(
                                    component.items,
                                    result.source.index,
                                    result.destination.index
                                );
                                component.items = items;
                                updateEmailComponent(emailComponents);
                            }}
                            className="paramArrayElements"
                        >


                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        {component.items.map((item, i) => {
                                            return (

                                                <Draggable
                                                    key={`#${item.type}_${i}`}
                                                    draggableId={`#${item.type}_${i}`}
                                                    index={i}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >


                                                            <div className="paramArrayItem">
                                                                <div className="flex-ac w-100" style={{ height: 50 }}>
                                                                    <i className="las la-braille mr-5" />
                                                                    <div
                                                                        className="w-100 flex-ac c-pointer"
                                                                        onClick={() => {
                                                                            this.focusNestedComponent(i);
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <h4>
                                                                                {item.itemTitle ? item.itemTitle : 'Row'}
                                                                            </h4>

                                                                        </div>
                                                                    </div>
                                                                    {true && (
                                                                        <UncontrolledDropdown inNavbar>
                                                                            <DropdownToggle className="columnToggle flex-ac">
                                                                                <i className="las la-ellipsis-h" />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu
                                                                                right
                                                                                className="columnDropdown  p-20"
                                                                            >
                                                                                <h5 className='mb-10'>Options</h5>
                                                                                <DropdownItem
                                                                                    className="dropdownItem"
                                                                                    onClick={() => {

                                                                                        component.items.push(JSON.parse(JSON.stringify(component.items[i])));
                                                                                        updateEmailComponent(emailComponents);
                                                                                    }}

                                                                                >
                                                                                    <i className="las la-copy mr-10" />Duplicate

                                                                                </DropdownItem>
                                                                                <DropdownItem
                                                                                    className="dropdownItem"
                                                                                    onClick={() => {
                                                                                        component.items.splice(i, 1);
                                                                                        updateEmailComponent(emailComponents);
                                                                                    }}
                                                                                >
                                                                                    <i className="las la-trash mr-10" />Remove
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>



                        <div
                            className="paramArrayAdd actionButton actionOutline"
                            onClick={() => {
                                component.items.push(
                                    {
                                        label: `<div><span style="color: rgba(0,0,0,1);font-size: 18px;font-family: Arial;"><strong>Label:</strong></span></div>`,
                                        value: `<div><span style="color: rgba(0,0,0,1);font-size: 18px;font-family: Arial;">Value</span></div>`
                                    },
                                )
                                updateEmailComponent(emailComponents);
                            }}
                        >
                            <i class="las la-plus"></i><div>Add Row</div>
                        </div>

                    </div >
                );
                break;








            case 'Heading':
                componentConfig = (
                    <div className="componentDetails" key={`heading-${component._id}`}>



                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />

                        {this.renderPadding(emailComponents, component)}



                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.text} onChange={(value) => {
                                component.text = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                                blockTypeOptions={['H1']}
                                defaultElement={`<h1><span style="margin:0;font-size: 50px;text-align:center;font-family: Arial;">&nbsp</span></h1>`}
                            />
                        </div>
                    </div>
                )
                break;
            case 'Banner':
                componentConfig = (
                    <div className="componentDetails" key={`text-${component._id}`}>

                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />

                        {this.renderPadding(emailComponents, component)}

                        <div class="editorFileInput">
                            <FileChooserPreview
                                label="Image File"
                                value={component.backgroundImage}
                                onClick={() => {
                                    component.backgroundImage = 'choosing__' + component.backgroundImage;
                                    updateEmailComponent(emailComponents);
                                }}
                                clearFile={() => {
                                    component.backgroundImage = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />
                            {component.backgroundImage.includes('choosing__') &&
                                <FilesUploaderModal
                                    isOpen={component.backgroundImage.includes('choosing__')}
                                    toggle={() => {
                                        component.backgroundImage = component.backgroundImage.replace('choosing__', '');
                                        updateEmailComponent(emailComponents);
                                    }}
                                    eventId={this.props.eventId}
                                    links={this.props.links}
                                    orgId={this.props.orgId}
                                    addFiles={(fileURL) => {

                                        getMeta(fileURL, (err, img) => {
                                            console.log(img.naturalWidth, img.naturalHeight);
                                            //limit to 700px wide
                                            if (img.naturalWidth > 700) {
                                                component.height = img.naturalHeight * (700 / img.naturalWidth);
                                                component.width = 700;
                                            } else {
                                                component.height = img.naturalHeight;
                                                component.width = img.naturalWidth;
                                            }
                                            component.backgroundImage = fileURL;
                                            updateEmailComponent(emailComponents);
                                        });
                                    }}
                                />}
                        </div>

                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.text} onChange={(value) => {
                                component.text = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                    </div>
                );
                break;

            case 'Text':



                componentConfig = (
                    <div className="componentDetails" key={`text-${component._id}`}>

                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />

                        {this.renderPadding(emailComponents, component)}


                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.text} onChange={(value) => {
                                component.text = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['blockType', 'textAlign', 'emoji']}
                            />
                        </div>

                    </div>
                );
                break;



            case 'Cta':
                componentConfig = (
                    <div className="componentDetails" key={`cta-${component._id}`}>

                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />
                        {this.renderPadding(emailComponents, component)}

                        <div>
                            <h2 class="blockHeader">CTA Options</h2>
                            <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                                <div className="labelContainer">
                                    <div className="formFieldLabel">Text</div>
                                </div>
                                <RichTextEditor id={`textcomp-${component._id}`} value={component.ctaText} onChange={(value) => {
                                    component.ctaText = value;
                                    updateEmailComponent(emailComponents);
                                }}
                                    customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                    options={['textAlign']}
                                />
                            </div>

                            <div>
                                <h2 class="blockHeader">Padding</h2>
                                <div className='flex-ac flex-sb mb-10 mt-10'>

                                    <InputField label="Top" classes={`w-25`} type="number" value={component.ctaPaddingTop} onChange={(e) => {
                                        component.ctaPaddingTop = e.target.value;
                                        updateEmailComponent(emailComponents);
                                    }} />
                                    <InputField label="Right" classes={'w-25'} type="number" value={component.ctaPaddingRight} onChange={(e) => {
                                        component.ctaPaddingRight = e.target.value;
                                        updateEmailComponent(emailComponents);
                                    }} />
                                    <InputField label="Bottom" classes={`w-25`} type="number" value={component.ctaPaddingBottom} onChange={(e) => {
                                        component.ctaPaddingBottom = e.target.value;
                                        updateEmailComponent(emailComponents);
                                    }} />
                                    <InputField label="Left" classes={'w-25'} type="number" value={component.ctaPaddingLeft} onChange={(e) => {
                                        component.ctaPaddingLeft = e.target.value;
                                        updateEmailComponent(emailComponents);
                                    }} />
                                </div>
                            </div>



                            <div className=''>
                                <h4 class="mb-10 mt-10">Alignment</h4>
                                <div className='flex-ac flex-sb mb-10 mt-10'>
                                    <CheckboxInput
                                        label={'Left'}
                                        onClick={() => {
                                            component.ctaAlignment = 'left';
                                            updateEmailComponent(emailComponents);
                                        }}
                                        checked={component.ctaAlignment == 'left'}
                                    />
                                    <CheckboxInput
                                        label={'Center'}
                                        onClick={() => {
                                            component.ctaAlignment = 'center';
                                            updateEmailComponent(emailComponents);
                                        }}
                                        checked={component.ctaAlignment == 'center'}
                                    />
                                    <CheckboxInput

                                        label={'Right'}
                                        onClick={() => {
                                            component.ctaAlignment = 'right';
                                            updateEmailComponent(emailComponents);
                                        }}
                                        checked={component.ctaAlignment == 'right'}
                                    />
                                </div>
                            </div>


                            <SelectField
                                label={'Button Type'}
                                options={[{ value: 'Fill', label: 'Fill' }, { value: 'Outline', label: 'Outline' }]}
                                onChange={(e) => {

                                    component.ctaType = e.value;
                                    updateEmailComponent(emailComponents);
                                }}
                                value={{ value: component.ctaType, label: component.ctaType }}

                            ></SelectField>



                            {component.ctaType == "Fill" ? (

                                <ColorField useHex={true} label="Background Color" value={component.ctaBackgroundColor} onChange={(value) => {
                                    component.ctaBackgroundColor = value;
                                    updateEmailComponent(emailComponents);
                                }} />

                            ) : (
                                <ColorField useHex={true} label="Outline Color" value={component.ctaOutlineColor} onChange={(value) => {
                                    component.ctaOutlineColor = value;
                                    updateEmailComponent(emailComponents);
                                }} />
                            )
                            }




                            <div className="labelContainer">
                                <div className="formFieldLabel">CTA Icon</div>
                            </div>
                            <IconChooser
                                label="Icon"
                                value={component.ctaIcon}
                                onClick={(value) => {
                                    component.ctaIcon = value
                                    updateEmailComponent(emailComponents);
                                }}
                                clearIcon={(value) => {
                                    component.ctaIcon = '';
                                    updateEmailComponent(emailComponents);
                                }}
                            />


                            <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">

                                <div className='flex aic'>
                                    <InputField label="Icon Size" min={"0"} type="number" value={component.ctaIconSize} onChange={(e) => {
                                        component.ctaIconSize = e.target.value;
                                        updateEmailComponent(emailComponents);
                                    }} />



                                    <ColorField useHex={true} label="Icon Color" value={component.ctaIconColor} onChange={(value) => {
                                        component.ctaIconColor = value;
                                        updateEmailComponent(emailComponents);
                                    }} clickIcon={true} />

                                </div>
                            </div>

                            <div className=''>
                                <h4 class="mb-10 mt-10">CTA Icon Placement</h4>
                                <div className='flex-ac flex-sb mb-10 mt-10'>
                                    <CheckboxInput
                                        label={'Left'}
                                        onClick={() => {
                                            component.ctaIconPlacement = 'left';
                                            updateEmailComponent(emailComponents);
                                        }}
                                        checked={component.ctaIconPlacement == 'left'}
                                    />
                                    <CheckboxInput

                                        label={'Right'}
                                        onClick={() => {
                                            component.ctaIconPlacement = 'right';
                                            updateEmailComponent(emailComponents);
                                        }}
                                        checked={component.ctaIconPlacement == 'right'}
                                    />
                                </div>
                            </div>

                            <InputField label="CTA Border Radius" type="number" value={component.ctaBorderRadius} onChange={(e) => {
                                component.ctaBorderRadius = e.target.value;
                                updateEmailComponent(emailComponents);
                            }} />

                            <InputField label="CTA Target" type="text" value={component.ctaUrl} onChange={(e) => {
                                component.ctaUrl = e.target.value;
                                updateEmailComponent(emailComponents);
                            }} />
                        </div>


                    </div>);
                break;
            case 'Footer':
                componentConfig = (
                    <div className="componentDetails" key={`footer-${component._id}`}>

                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }} />

                        {this.renderPadding(emailComponents, component)}


                        <div id={`textcomp-${component._id}`} className="w-100 mb-25 posRel">
                            <div className="labelContainer">
                                <div className="formFieldLabel">Text</div>
                            </div>
                            <RichTextEditor id={`textcomp-${component._id}`} value={component.text} onChange={(value) => {
                                component.text = value;
                                updateEmailComponent(emailComponents);
                            }}
                                customFields={this.props.staticCustomFields ? this.props.staticCustomFields : this.state.customFields}
                                options={['textAlign', 'emoji']}
                            />
                        </div>

                    </div>
                );
                break;


            case 'HTML':
                componentConfig = (
                    <div className="componentDetails" key={`html-${component._id}`}>
                        <ColorField useHex={true} label="Background Color" value={component.backgroundColor} onChange={(value) => {
                            component.backgroundColor = value;
                            updateEmailComponent(emailComponents);
                        }
                        } />
                        <InputField label="Width" type="number" value={component.width} onChange={(e) => {

                            component.width = e.target.value;
                            updateEmailComponent(emailComponents);
                        }
                        } />

                        <InputField label="Height" type="number" value={component.height} onChange={(e) => {

                            component.height = e.target.value;
                            updateEmailComponent(emailComponents);
                        }
                        } />

                        <CodeEditor
                            label="HTML"
                            language='html'
                            content={component.html}
                            setContent={(value) => {
                                component.html = value;
                                updateEmailComponent(emailComponents);
                            }}

                        />
                    </div>);
                break;

        }

        return <Fragment>
            <div key={`pd_${component.type}_${focusedComponentIndex}`}>

                {/* <div className="flex-ac flex-sb mb-10 mt-10">
                    <button onClick={
                        expandPanel

                    } >{expanded ? <i className='las la-file-export'></i> : <i className='las la-file-import'></i>}</button>
                    <h4>{component.type}</h4>
                    <button onClick={togglePanel}><i className="las la-times"></i></button>
                </div> */}

                <div className="flex-ac mb-10">
                    <div onClick={() => { this.focusComponent(-1) }} className="c-pointer">

                        <i className="las la-angle-left mr-5" />Back
                    </div>
                </div>
                <div className="flex-ac flex-sb mb-10">
                    <div className="w-100 mr-15 flex-ac">

                        <InputField
                            label="Component Name"
                            classes="mb-0"
                            value={component.componentTitle ? component.componentTitle : ''}
                            placeholder={component.type}
                            inputIcon={<i className="las la-pen" />}
                            onChange={(e) => {
                                component.componentTitle = e.target.value;
                                updateEmailComponent(emailComponents);
                            }}
                        />
                    </div>
                    <UncontrolledDropdown inNavbar className="flex-ac shrink">
                        <DropdownToggle className="columnToggle">
                            <i className="las la-ellipsis-h" />
                        </DropdownToggle>
                        <DropdownMenu right className="columnDropdown">
                            <div className='ph-20'>
                                <h5 className='mb-10'>Component Options</h5>



                                <DropdownItem

                                    onClick={() => {
                                        const componentToCopy = JSON.parse(JSON.stringify(emailComponents[focusedComponentIndex]));
                                        emailComponents.push(componentToCopy);
                                        this.props.updateEmailComponent(emailComponents);
                                        this.focusComponent(-1);
                                    }}
                                >

                                    <i className="las la-copy mr-25" />Duplicate

                                </DropdownItem>
                            </div>
                            <hr />
                            <div className='ph-20'>
                                <DropdownItem
                                    onClick={() => {

                                        emailComponents.splice(focusedComponentIndex, 1);
                                        updateEmailComponent(emailComponents);

                                        this.focusComponent(-1)
                                    }}
                                >
                                    <i className="las la-trash mr-25 danger" />Remove
                                </DropdownItem>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>



                {componentConfig}

            </div>
        </Fragment>

    }

    render() {
        const {
            editedEmailTemplate,
            focusedComponent,
            openComponentLibrary,
            addAsGlobalComponent,

            componentSelected,
            focusedComponentIndex,
            parameterSelected,
            togglePanel,
            expandPanel,
            expanded
        } = this.props;
        const { nestedIndexSelected } = this.state;
        //const selectedComponent = componentSelected && editedEmailTemplate.partials[focusedComponentIndex];

        return (
            <Fragment>
                {!componentSelected ? (
                    <div className='h-100'>
                        {/* <div className='flex-sb flex-ac mb-20 mt-10'>
                            <button onClick={expandPanel}>{expanded ? <i className='las la-arrow-alt-circle-right'></i> : <i className='las la-arrow-alt-circle-left'></i>}</button>
                            <h2 className="blockHeader">Components</h2>
                            <button onClick={togglePanel}><i className='las la-times'></i></button>
                        </div> */}
                        <Droppable droppableId="droppable-email-components">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getComponentListStyle(snapshot.isDraggingOver)}
                                    className="fieldListContainer h-100"
                                >
                                    {editedEmailTemplate.emailComponents && editedEmailTemplate.emailComponents.map((component, index) => {

                                        return (
                                            <Draggable
                                                key={`#${component.type}_${index}`}
                                                draggableId={`#${component.type}_${index}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                        className={`fieldDraggable ${focusedComponent ===
                                                            `#${component.type}_${index}`
                                                            ? 'focused'
                                                            : ''}`}
                                                    >
                                                        <div className="flex-ac w-100 " style={{ height: 50 }}>

                                                            <i className="las la-braille mr-5" />
                                                            <div
                                                                className="w-100 h-100 flex-ac c-pointer"
                                                                onClick={() => {
                                                                    this.focusComponent(index);
                                                                }}

                                                            >

                                                                {false && (
                                                                    <i className="las la-globe mr-5" />
                                                                )}
                                                                <h4 className="mb-0">
                                                                    {component.componentTitle ? component.componentTitle : component.type}
                                                                </h4>
                                                            </div>

                                                            <div className="flex-ac">
                                                                <UncontrolledDropdown inNavbar>
                                                                    <DropdownToggle className="columnToggle flex-ac">
                                                                        <i className="las la-ellipsis-h" />
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                        right
                                                                        className="columnDropdown p-0"
                                                                    >
                                                                        <DropdownItem
                                                                            className={`dropdownItem c-pointer`}
                                                                            onClick={() => {
                                                                                const { editedEmailTemplate } = this.props;
                                                                                const { emailComponents } = editedEmailTemplate;
                                                                                const componentToCopy = JSON.parse(JSON.stringify(emailComponents[index]));
                                                                                emailComponents.push(componentToCopy);
                                                                                this.props.updateEmailComponent(emailComponents);
                                                                            }

                                                                            }
                                                                        >
                                                                            <div className="">
                                                                                <i className="las la-copy mr-5" />Duplicate
                                                                            </div>
                                                                        </DropdownItem>


                                                                        <DropdownItem
                                                                            className={`dropdownItem c-pointer`}
                                                                            onClick={() => {
                                                                                const { editedEmailTemplate } = this.props;
                                                                                const { emailComponents } = editedEmailTemplate;
                                                                                emailComponents.splice(index, 1);
                                                                                this.props.updateEmailComponent(emailComponents);
                                                                            }}

                                                                        >
                                                                            <div className="red">
                                                                                <i className="las la-trash mr-5" />Remove
                                                                            </div>
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div >
                ) : nestedIndexSelected !== -1 ? (
                    this.renderNestedComponent()
                ) : (
                    this.renderComponentDetails()
                )
                }
            </Fragment>
        );
    }
}

export default EmailEditorComponent;
