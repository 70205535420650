const icons = [
{value:"500px"},
{value:"accessible-icon"},
{value:"accusoft"},
{value:"acquisitions-incorporated"},
{value:"ad-solid"},
{value:"address-book-solid"},
{value:"address-book"},
{value:"address-card-solid"},
{value:"address-card"},
{value:"adjust-solid"},
{value:"adn"},
{value:"adobe"},
{value:"adversal"},
{value:"affiliatetheme"},
{value:"air-freshener-solid"},
{value:"airbnb"},
{value:"algolia"},
{value:"align-center-solid"},
{value:"align-justify-solid"},
{value:"align-left-solid"},
{value:"align-right-solid"},
{value:"alipay"},
{value:"allergies-solid"},
{value:"amazon-pay"},
{value:"amazon"},
{value:"ambulance-solid"},
{value:"american-sign-language-interpreting-solid"},
{value:"amilia"},
{value:"anchor-solid"},
{value:"android"},
{value:"angellist"},
{value:"angle-double-down-solid"},
{value:"angle-double-left-solid"},
{value:"angle-double-right-solid"},
{value:"angle-double-up-solid"},
{value:"angle-down-solid"},
{value:"angle-left-solid"},
{value:"angle-right-solid"},
{value:"angle-up-solid"},
{value:"angry-solid"},
{value:"angry"},
{value:"angrycreative"},
{value:"angular"},
{value:"ankh-solid"},
{value:"app-store-ios"},
{value:"app-store"},
{value:"apper"},
{value:"apple-alt-solid"},
{value:"apple-pay"},
{value:"apple"},
{value:"archive-solid"},
{value:"archway-solid"},
{value:"arrow-alt-circle-down-solid"},
{value:"arrow-alt-circle-down"},
{value:"arrow-alt-circle-left-solid"},
{value:"arrow-alt-circle-left"},
{value:"arrow-alt-circle-right-solid"},
{value:"arrow-alt-circle-right"},
{value:"arrow-alt-circle-up-solid"},
{value:"arrow-alt-circle-up"},
{value:"arrow-circle-down-solid"},
{value:"arrow-circle-left-solid"},
{value:"arrow-circle-right-solid"},
{value:"arrow-circle-up-solid"},
{value:"arrow-down-solid"},
{value:"arrow-left-solid"},
{value:"arrow-right-solid"},
{value:"arrow-up-solid"},
{value:"arrows-alt-h-solid"},
{value:"arrows-alt-solid"},
{value:"arrows-alt-v-solid"},
{value:"artstation"},
{value:"assistive-listening-systems-solid"},
{value:"asterisk-solid"},
{value:"asymmetrik"},
{value:"at-solid"},
{value:"atlas-solid"},
{value:"atlassian"},
{value:"atom-solid"},
{value:"audible"},
{value:"audio-description-solid"},
{value:"autoprefixer"},
{value:"avianex"},
{value:"aviato"},
{value:"award-solid"},
{value:"aws"},
{value:"baby-carriage-solid"},
{value:"baby-solid"},
{value:"backspace-solid"},
{value:"backward-solid"},
{value:"bacon-solid"},
{value:"balance-scale-left-solid"},
{value:"balance-scale-right-solid"},
{value:"balance-scale-solid"},
{value:"ban-solid"},
{value:"band-aid-solid"},
{value:"bandcamp"},
{value:"barcode-solid"},
{value:"bars-solid"},
{value:"baseball-ball-solid"},
{value:"basketball-ball-solid"},
{value:"bath-solid"},
{value:"battery-empty-solid"},
{value:"battery-full-solid"},
{value:"battery-half-solid"},
{value:"battery-quarter-solid"},
{value:"battery-three-quarters-solid"},
{value:"battle-net"},
{value:"bed-solid"},
{value:"beer-solid"},
{value:"behance-square"},
{value:"behance"},
{value:"bell-slash-solid"},
{value:"bell-slash"},
{value:"bell-solid"},
{value:"bell"},
{value:"bezier-curve-solid"},
{value:"bible-solid"},
{value:"bicycle-solid"},
{value:"biking-solid"},
{value:"bimobject"},
{value:"binoculars-solid"},
{value:"biohazard-solid"},
{value:"birthday-cake-solid"},
{value:"bitbucket"},
{value:"bitcoin"},
{value:"bity"},
{value:"black-tie"},
{value:"blackberry"},
{value:"blender-phone-solid"},
{value:"blender-solid"},
{value:"blind-solid"},
{value:"blog-solid"},
{value:"blogger-b"},
{value:"blogger"},
{value:"bluetooth-b"},
{value:"bluetooth"},
{value:"bold-solid"},
{value:"bolt-solid"},
{value:"bomb-solid"},
{value:"bone-solid"},
{value:"bong-solid"},
{value:"book-dead-solid"},
{value:"book-medical-solid"},
{value:"book-open-solid"},
{value:"book-reader-solid"},
{value:"book-solid"},
{value:"bookmark-solid"},
{value:"bookmark"},
{value:"bootstrap"},
{value:"border-all-solid"},
{value:"border-none-solid"},
{value:"border-style-solid"},
{value:"bowling-ball-solid"},
{value:"box-open-solid"},
{value:"box-solid"},
{value:"boxes-solid"},
{value:"braille-solid"},
{value:"brain-solid"},
{value:"bread-slice-solid"},
{value:"briefcase-medical-solid"},
{value:"briefcase-solid"},
{value:"broadcast-tower-solid"},
{value:"broom-solid"},
{value:"brush-solid"},
{value:"btc"},
{value:"buffer"},
{value:"bug-solid"},
{value:"building-solid"},
{value:"building"},
{value:"bullhorn-solid"},
{value:"bullseye-solid"},
{value:"burn-solid"},
{value:"buromobelexperte"},
{value:"bus-alt-solid"},
{value:"bus-solid"},
{value:"business-time-solid"},
{value:"buy-n-large"},
{value:"buysellads"},
{value:"calculator-solid"},
{value:"calendar-alt-solid"},
{value:"calendar-alt"},
{value:"calendar-check-solid"},
{value:"calendar-check"},
{value:"calendar-day-solid"},
{value:"calendar-minus-solid"},
{value:"calendar-minus"},
{value:"calendar-plus-solid"},
{value:"calendar-plus"},
{value:"calendar-solid"},
{value:"calendar-times-solid"},
{value:"calendar-times"},
{value:"calendar-week-solid"},
{value:"calendar"},
{value:"camera-retro-solid"},
{value:"camera-solid"},
{value:"campground-solid"},
{value:"canadian-maple-leaf"},
{value:"candy-cane-solid"},
{value:"cannabis-solid"},
{value:"capsules-solid"},
{value:"car-alt-solid"},
{value:"car-battery-solid"},
{value:"car-crash-solid"},
{value:"car-side-solid"},
{value:"car-solid"},
{value:"caret-down-solid"},
{value:"caret-left-solid"},
{value:"caret-right-solid"},
{value:"caret-square-down-solid"},
{value:"caret-square-down"},
{value:"caret-square-left-solid"},
{value:"caret-square-left"},
{value:"caret-square-right-solid"},
{value:"caret-square-right"},
{value:"caret-square-up-solid"},
{value:"caret-square-up"},
{value:"caret-up-solid"},
{value:"carrot-solid"},
{value:"cart-arrow-down-solid"},
{value:"cart-plus-solid"},
{value:"cash-register-solid"},
{value:"cat-solid"},
{value:"cc-amazon-pay"},
{value:"cc-amex"},
{value:"cc-apple-pay"},
{value:"cc-diners-club"},
{value:"cc-discover"},
{value:"cc-jcb"},
{value:"cc-mastercard"},
{value:"cc-paypal"},
{value:"cc-stripe"},
{value:"cc-visa"},
{value:"centercode"},
{value:"centos"},
{value:"certificate-solid"},
{value:"chair-solid"},
{value:"chalkboard-solid"},
{value:"chalkboard-teacher-solid"},
{value:"charging-station-solid"},
{value:"chart-area-solid"},
{value:"chart-bar-solid"},
{value:"chart-bar"},
{value:"chart-line-solid"},
{value:"chart-pie-solid"},
{value:"check-circle-solid"},
{value:"check-circle"},
{value:"check-double-solid"},
{value:"check-solid"},
{value:"check-square-solid"},
{value:"check-square"},
{value:"cheese-solid"},
{value:"chess-bishop-solid"},
{value:"chess-board-solid"},
{value:"chess-king-solid"},
{value:"chess-knight-solid"},
{value:"chess-pawn-solid"},
{value:"chess-queen-solid"},
{value:"chess-rook-solid"},
{value:"chess-solid"},
{value:"chevron-circle-down-solid"},
{value:"chevron-circle-left-solid"},
{value:"chevron-circle-right-solid"},
{value:"chevron-circle-up-solid"},
{value:"chevron-down-solid"},
{value:"chevron-left-solid"},
{value:"chevron-right-solid"},
{value:"chevron-up-solid"},
{value:"child-solid"},
{value:"chrome"},
{value:"chromecast"},
{value:"church-solid"},
{value:"circle-notch-solid"},
{value:"circle-solid"},
{value:"circle"},
{value:"city-solid"},
{value:"clinic-medical-solid"},
{value:"clipboard-check-solid"},
{value:"clipboard-list-solid"},
{value:"clipboard-solid"},
{value:"clipboard"},
{value:"clock-solid"},
{value:"clock"},
{value:"clone-solid"},
{value:"clone"},
{value:"closed-captioning-solid"},
{value:"closed-captioning"},
{value:"cloud-download-alt-solid"},
{value:"cloud-meatball-solid"},
{value:"cloud-moon-rain-solid"},
{value:"cloud-moon-solid"},
{value:"cloud-rain-solid"},
{value:"cloud-showers-heavy-solid"},
{value:"cloud-solid"},
{value:"cloud-sun-rain-solid"},
{value:"cloud-sun-solid"},
{value:"cloud-upload-alt-solid"},
{value:"cloudscale"},
{value:"cloudsmith"},
{value:"cloudversify"},
{value:"cocktail-solid"},
{value:"code-branch-solid"},
{value:"code-solid"},
{value:"codepen"},
{value:"codiepie"},
{value:"coffee-solid"},
{value:"cog-solid"},
{value:"cogs-solid"},
{value:"coins-solid"},
{value:"columns-solid"},
{value:"comment-alt-solid"},
{value:"comment-alt"},
{value:"comment-dollar-solid"},
{value:"comment-dots-solid"},
{value:"comment-dots"},
{value:"comment-medical-solid"},
{value:"comment-slash-solid"},
{value:"comment-solid"},
{value:"comment"},
{value:"comments-dollar-solid"},
{value:"comments-solid"},
{value:"comments"},
{value:"compact-disc-solid"},
{value:"compass-solid"},
{value:"compass"},
{value:"compress-arrows-alt-solid"},
{value:"compress-solid"},
{value:"concierge-bell-solid"},
{value:"confluence"},
{value:"connectdevelop"},
{value:"contao"},
{value:"cookie-bite-solid"},
{value:"cookie-solid"},
{value:"copy-solid"},
{value:"copy"},
{value:"copyright-solid"},
{value:"copyright"},
{value:"cotton-bureau"},
{value:"couch-solid"},
{value:"cpanel"},
{value:"creative-commons-by"},
{value:"creative-commons-nc-eu"},
{value:"creative-commons-nc-jp"},
{value:"creative-commons-nc"},
{value:"creative-commons-nd"},
{value:"creative-commons-pd-alt"},
{value:"creative-commons-pd"},
{value:"creative-commons-remix"},
{value:"creative-commons-sa"},
{value:"creative-commons-sampling-plus"},
{value:"creative-commons-sampling"},
{value:"creative-commons-share"},
{value:"creative-commons-zero"},
{value:"creative-commons"},
{value:"credit-card-solid"},
{value:"credit-card"},
{value:"critical-role"},
{value:"crop-alt-solid"},
{value:"crop-solid"},
{value:"cross-solid"},
{value:"crosshairs-solid"},
{value:"crow-solid"},
{value:"crown-solid"},
{value:"crutch-solid"},
{value:"css3-alt"},
{value:"css3"},
{value:"cube-solid"},
{value:"cubes-solid"},
{value:"cut-solid"},
{value:"cuttlefish"},
{value:"d-and-d-beyond"},
{value:"d-and-d"},
{value:"dashcube"},
{value:"database-solid"},
{value:"deaf-solid"},
{value:"delicious"},
{value:"democrat-solid"},
{value:"deploydog"},
{value:"deskpro"},
{value:"desktop-solid"},
{value:"dev"},
{value:"deviantart"},
{value:"dharmachakra-solid"},
{value:"dhl"},
{value:"diagnoses-solid"},
{value:"diaspora"},
{value:"dice-d6-solid"},
{value:"dice-d20-solid"},
{value:"dice-five-solid"},
{value:"dice-four-solid"},
{value:"dice-one-solid"},
{value:"dice-six-solid"},
{value:"dice-solid"},
{value:"dice-three-solid"},
{value:"dice-two-solid"},
{value:"digg"},
{value:"digital-ocean"},
{value:"digital-tachograph-solid"},
{value:"directions-solid"},
{value:"discord"},
{value:"discourse"},
{value:"divide-solid"},
{value:"dizzy-solid"},
{value:"dizzy"},
{value:"dna-solid"},
{value:"dochub"},
{value:"docker"},
{value:"dog-solid"},
{value:"dollar-sign-solid"},
{value:"dolly-flatbed-solid"},
{value:"dolly-solid"},
{value:"donate-solid"},
{value:"door-closed-solid"},
{value:"door-open-solid"},
{value:"dot-circle-solid"},
{value:"dot-circle"},
{value:"dove-solid"},
{value:"download-solid"},
{value:"draft2digital"},
{value:"drafting-compass-solid"},
{value:"dragon-solid"},
{value:"draw-polygon-solid"},
{value:"dribbble-square"},
{value:"dribbble"},
{value:"dropbox"},
{value:"drum-solid"},
{value:"drum-steelpan-solid"},
{value:"drumstick-bite-solid"},
{value:"drupal"},
{value:"dumbbell-solid"},
{value:"dumpster-fire-solid"},
{value:"dumpster-solid"},
{value:"dungeon-solid"},
{value:"dyalog"},
{value:"earlybirds"},
{value:"ebay"},
{value:"edge"},
{value:"edit-solid"},
{value:"edit"},
{value:"egg-solid"},
{value:"eject-solid"},
{value:"elementor"},
{value:"ellipsis-h-solid"},
{value:"ellipsis-v-solid"},
{value:"ello"},
{value:"ember"},
{value:"empire"},
{value:"envelope-open-solid"},
{value:"envelope-open-text-solid"},
{value:"envelope-open"},
{value:"envelope-solid"},
{value:"envelope-square-solid"},
{value:"envelope"},
{value:"envira"},
{value:"equals-solid"},
{value:"eraser-solid"},
{value:"erlang"},
{value:"ethereum"},
{value:"ethernet-solid"},
{value:"etsy"},
{value:"euro-sign-solid"},
{value:"evernote"},
{value:"exchange-alt-solid"},
{value:"exclamation-circle-solid"},
{value:"exclamation-solid"},
{value:"exclamation-triangle-solid"},
{value:"expand-arrows-alt-solid"},
{value:"expand-solid"},
{value:"expeditedssl"},
{value:"external-link-alt-solid"},
{value:"external-link-square-alt-solid"},
{value:"eye-dropper-solid"},
{value:"eye-slash-solid"},
{value:"eye-slash"},
{value:"eye-solid"},
{value:"eye"},
{value:"facebook-f"},
{value:"facebook-messenger"},
{value:"facebook-square"},
{value:"facebook"},
{value:"fan-solid"},
{value:"fantasy-flight-games"},
{value:"fast-backward-solid"},
{value:"fast-forward-solid"},
{value:"fax-solid"},
{value:"feather-alt-solid"},
{value:"feather-solid"},
{value:"fedex"},
{value:"fedora"},
{value:"female-solid"},
{value:"fighter-jet-solid"},
{value:"figma"},
{value:"file-alt-solid"},
{value:"file-alt"},
{value:"file-archive-solid"},
{value:"file-archive"},
{value:"file-audio-solid"},
{value:"file-audio"},
{value:"file-code-solid"},
{value:"file-code"},
{value:"file-contract-solid"},
{value:"file-csv-solid"},
{value:"file-download-solid"},
{value:"file-excel-solid"},
{value:"file-excel"},
{value:"file-export-solid"},
{value:"file-image-solid"},
{value:"file-image"},
{value:"file-import-solid"},
{value:"file-invoice-dollar-solid"},
{value:"file-invoice-solid"},
{value:"file-medical-alt-solid"},
{value:"file-medical-solid"},
{value:"file-pdf-solid"},
{value:"file-pdf"},
{value:"file-powerpoint-solid"},
{value:"file-powerpoint"},
{value:"file-prescription-solid"},
{value:"file-signature-solid"},
{value:"file-solid"},
{value:"file-upload-solid"},
{value:"file-video-solid"},
{value:"file-video"},
{value:"file-word-solid"},
{value:"file-word"},
{value:"file"},
{value:"fill-drip-solid"},
{value:"fill-solid"},
{value:"film-solid"},
{value:"filter-solid"},
{value:"fingerprint-solid"},
{value:"fire-alt-solid"},
{value:"fire-extinguisher-solid"},
{value:"fire-solid"},
{value:"firefox"},
{value:"first-aid-solid"},
{value:"first-order-alt"},
{value:"first-order"},
{value:"firstdraft"},
{value:"fish-solid"},
{value:"fist-raised-solid"},
{value:"flag-checkered-solid"},
{value:"flag-solid"},
{value:"flag-usa-solid"},
{value:"flag"},
{value:"flask-solid"},
{value:"flickr"},
{value:"flipboard"},
{value:"flushed-solid"},
{value:"flushed"},
{value:"fly"},
{value:"folder-minus-solid"},
{value:"folder-open-solid"},
{value:"folder-open"},
{value:"folder-plus-solid"},
{value:"folder-solid"},
{value:"folder"},
{value:"font-awesome-alt"},
{value:"font-awesome-flag"},
{value:"font-awesome"},
{value:"font-solid"},
{value:"fonticons-fi"},
{value:"fonticons"},
{value:"football-ball-solid"},
{value:"fort-awesome-alt"},
{value:"fort-awesome"},
{value:"forumbee"},
{value:"forward-solid"},
{value:"foursquare"},
{value:"free-code-camp"},
{value:"freebsd"},
{value:"frog-solid"},
{value:"frown-open-solid"},
{value:"frown-open"},
{value:"frown-solid"},
{value:"frown"},
{value:"fulcrum"},
{value:"funnel-dollar-solid"},
{value:"futbol-solid"},
{value:"futbol"},
{value:"galactic-republic"},
{value:"galactic-senate"},
{value:"gamepad-solid"},
{value:"gas-pump-solid"},
{value:"gavel-solid"},
{value:"gem-solid"},
{value:"gem"},
{value:"genderless-solid"},
{value:"get-pocket"},
{value:"gg-circle"},
{value:"gg"},
{value:"ghost-solid"},
{value:"gift-solid"},
{value:"gifts-solid"},
{value:"git-alt"},
{value:"git-square"},
{value:"git"},
{value:"github-alt"},
{value:"github-square"},
{value:"github"},
{value:"gitkraken"},
{value:"gitlab"},
{value:"gitter"},
{value:"glass-cheers-solid"},
{value:"glass-martini-alt-solid"},
{value:"glass-martini-solid"},
{value:"glass-whiskey-solid"},
{value:"glasses-solid"},
{value:"glide-g"},
{value:"glide"},
{value:"globe-africa-solid"},
{value:"globe-americas-solid"},
{value:"globe-asia-solid"},
{value:"globe-europe-solid"},
{value:"globe-solid"},
{value:"gofore"},
{value:"golf-ball-solid"},
{value:"goodreads-g"},
{value:"goodreads"},
{value:"google-drive"},
{value:"google-play"},
{value:"google-plus-g"},
{value:"google-plus-square"},
{value:"google-plus"},
{value:"google-wallet"},
{value:"google"},
{value:"gopuram-solid"},
{value:"graduation-cap-solid"},
{value:"gratipay"},
{value:"grav"},
{value:"greater-than-equal-solid"},
{value:"greater-than-solid"},
{value:"grimace-solid"},
{value:"grimace"},
{value:"grin-alt-solid"},
{value:"grin-alt"},
{value:"grin-beam-solid"},
{value:"grin-beam-sweat-solid"},
{value:"grin-beam-sweat"},
{value:"grin-beam"},
{value:"grin-hearts-solid"},
{value:"grin-hearts"},
{value:"grin-solid"},
{value:"grin-squint-solid"},
{value:"grin-squint-tears-solid"},
{value:"grin-squint-tears"},
{value:"grin-squint"},
{value:"grin-stars-solid"},
{value:"grin-stars"},
{value:"grin-tears-solid"},
{value:"grin-tears"},
{value:"grin-tongue-solid"},
{value:"grin-tongue-squint-solid"},
{value:"grin-tongue-squint"},
{value:"grin-tongue-wink-solid"},
{value:"grin-tongue-wink"},
{value:"grin-tongue"},
{value:"grin-wink-solid"},
{value:"grin-wink"},
{value:"grin"},
{value:"grip-horizontal-solid"},
{value:"grip-lines-solid"},
{value:"grip-lines-vertical-solid"},
{value:"grip-vertical-solid"},
{value:"gripfire"},
{value:"grunt"},
{value:"guitar-solid"},
{value:"gulp"},
{value:"h-square-solid"},
{value:"hacker-news-square"},
{value:"hacker-news"},
{value:"hackerrank"},
{value:"hamburger-solid"},
{value:"hammer-solid"},
{value:"hamsa-solid"},
{value:"hand-holding-heart-solid"},
{value:"hand-holding-solid"},
{value:"hand-holding-usd-solid"},
{value:"hand-lizard-solid"},
{value:"hand-lizard"},
{value:"hand-middle-finger-solid"},
{value:"hand-paper-solid"},
{value:"hand-paper"},
{value:"hand-peace-solid"},
{value:"hand-peace"},
{value:"hand-point-down-solid"},
{value:"hand-point-down"},
{value:"hand-point-left-solid"},
{value:"hand-point-left"},
{value:"hand-point-right-solid"},
{value:"hand-point-right"},
{value:"hand-point-up-solid"},
{value:"hand-point-up"},
{value:"hand-pointer-solid"},
{value:"hand-pointer"},
{value:"hand-rock-solid"},
{value:"hand-rock"},
{value:"hand-scissors-solid"},
{value:"hand-scissors"},
{value:"hand-spock-solid"},
{value:"hand-spock"},
{value:"hands-helping-solid"},
{value:"hands-solid"},
{value:"handshake-solid"},
{value:"handshake"},
{value:"hanukiah-solid"},
{value:"hard-hat-solid"},
{value:"hashtag-solid"},
{value:"hat-cowboy-side-solid"},
{value:"hat-cowboy-solid"},
{value:"hat-wizard-solid"},
{value:"haykal-solid"},
{value:"hdd-solid"},
{value:"hdd"},
{value:"heading-solid"},
{value:"headphones-alt-solid"},
{value:"headphones-solid"},
{value:"headset-solid"},
{value:"heart-broken-solid"},
{value:"heart-solid"},
{value:"heart"},
{value:"heartbeat-solid"},
{value:"helicopter-solid"},
{value:"highlighter-solid"},
{value:"hiking-solid"},
{value:"hippo-solid"},
{value:"hips"},
{value:"hire-a-helper"},
{value:"history-solid"},
{value:"hockey-puck-solid"},
{value:"holly-berry-solid"},
{value:"home-solid"},
{value:"hooli"},
{value:"hornbill"},
{value:"horse-head-solid"},
{value:"horse-solid"},
{value:"hospital-alt-solid"},
{value:"hospital-solid"},
{value:"hospital-symbol-solid"},
{value:"hospital"},
{value:"hot-tub-solid"},
{value:"hotdog-solid"},
{value:"hotel-solid"},
{value:"hotjar"},
{value:"hourglass-end-solid"},
{value:"hourglass-half-solid"},
{value:"hourglass-solid"},
{value:"hourglass-start-solid"},
{value:"hourglass"},
{value:"house-damage-solid"},
{value:"houzz"},
{value:"hryvnia-solid"},
{value:"html5"},
{value:"hubspot"},
{value:"i-cursor-solid"},
{value:"ice-cream-solid"},
{value:"icicles-solid"},
{value:"icons-solid"},
{value:"id-badge-solid"},
{value:"id-badge"},
{value:"id-card-alt-solid"},
{value:"id-card-solid"},
{value:"id-card"},
{value:"igloo-solid"},
{value:"image-solid"},
{value:"image"},
{value:"images-solid"},
{value:"images"},
{value:"imdb"},
{value:"inbox-solid"},
{value:"indent-solid"},
{value:"industry-solid"},
{value:"infinity-solid"},
{value:"info-circle-solid"},
{value:"info-solid"},
{value:"instagram"},
{value:"intercom"},
{value:"internet-explorer"},
{value:"invision"},
{value:"ioxhost"},
{value:"italic-solid"},
{value:"itch-io"},
{value:"itunes-note"},
{value:"itunes"},
{value:"java"},
{value:"jedi-order"},
{value:"jedi-solid"},
{value:"jenkins"},
{value:"jira"},
{value:"joget"},
{value:"joint-solid"},
{value:"joomla"},
{value:"journal-whills-solid"},
{value:"js-square"},
{value:"js"},
{value:"jsfiddle"},
{value:"kaaba-solid"},
{value:"kaggle"},
{value:"key-solid"},
{value:"keybase"},
{value:"keyboard-solid"},
{value:"keyboard"},
{value:"keycdn"},
{value:"khanda-solid"},
{value:"kickstarter-k"},
{value:"kickstarter"},
{value:"kiss-beam-solid"},
{value:"kiss-beam"},
{value:"kiss-solid"},
{value:"kiss-wink-heart-solid"},
{value:"kiss-wink-heart"},
{value:"kiss"},
{value:"kiwi-bird-solid"},
{value:"korvue"},
{value:"landmark-solid"},
{value:"language-solid"},
{value:"laptop-code-solid"},
{value:"laptop-medical-solid"},
{value:"laptop-solid"},
{value:"laravel"},
{value:"lastfm-square"},
{value:"lastfm"},
{value:"laugh-beam-solid"},
{value:"laugh-beam"},
{value:"laugh-solid"},
{value:"laugh-squint-solid"},
{value:"laugh-squint"},
{value:"laugh-wink-solid"},
{value:"laugh-wink"},
{value:"laugh"},
{value:"layer-group-solid"},
{value:"leaf-solid"},
{value:"leanpub"},
{value:"lemon-solid"},
{value:"lemon"},
{value:"less-than-equal-solid"},
{value:"less-than-solid"},
{value:"less"},
{value:"level-down-alt-solid"},
{value:"level-up-alt-solid"},
{value:"life-ring-solid"},
{value:"life-ring"},
{value:"lightbulb-solid"},
{value:"lightbulb"},
{value:"line"},
{value:"link-solid"},
{value:"linkedin-in"},
{value:"linkedin"},
{value:"linode"},
{value:"linux"},
{value:"lira-sign-solid"},
{value:"list-alt-solid"},
{value:"list-alt"},
{value:"list-ol-solid"},
{value:"list-solid"},
{value:"list-ul-solid"},
{value:"location-arrow-solid"},
{value:"lock-open-solid"},
{value:"lock-solid"},
{value:"long-arrow-alt-down-solid"},
{value:"long-arrow-alt-left-solid"},
{value:"long-arrow-alt-right-solid"},
{value:"long-arrow-alt-up-solid"},
{value:"low-vision-solid"},
{value:"luggage-cart-solid"},
{value:"lyft"},
{value:"magento"},
{value:"magic-solid"},
{value:"magnet-solid"},
{value:"mail-bulk-solid"},
{value:"mailchimp"},
{value:"male-solid"},
{value:"mandalorian"},
{value:"map-marked-alt-solid"},
{value:"map-marked-solid"},
{value:"map-marker-alt-solid"},
{value:"map-marker-solid"},
{value:"map-pin-solid"},
{value:"map-signs-solid"},
{value:"map-solid"},
{value:"map"},
{value:"markdown"},
{value:"marker-solid"},
{value:"mars-double-solid"},
{value:"mars-solid"},
{value:"mars-stroke-h-solid"},
{value:"mars-stroke-solid"},
{value:"mars-stroke-v-solid"},
{value:"mask-solid"},
{value:"mastodon"},
{value:"maxcdn"},
{value:"mdb"},
{value:"medal-solid"},
{value:"medapps"},
{value:"medium-m"},
{value:"medium"},
{value:"medkit-solid"},
{value:"medrt"},
{value:"meetup"},
{value:"megaport"},
{value:"meh-blank-solid"},
{value:"meh-blank"},
{value:"meh-rolling-eyes-solid"},
{value:"meh-rolling-eyes"},
{value:"meh-solid"},
{value:"meh"},
{value:"memory-solid"},
{value:"mendeley"},
{value:"menorah-solid"},
{value:"mercury-solid"},
{value:"meteor-solid"},
{value:"microchip-solid"},
{value:"microphone-alt-slash-solid"},
{value:"microphone-alt-solid"},
{value:"microphone-slash-solid"},
{value:"microphone-solid"},
{value:"microscope-solid"},
{value:"microsoft"},
{value:"minus-circle-solid"},
{value:"minus-solid"},
{value:"minus-square-solid"},
{value:"minus-square"},
{value:"mitten-solid"},
{value:"mix"},
{value:"mixcloud"},
{value:"mizuni"},
{value:"mobile-alt-solid"},
{value:"mobile-solid"},
{value:"modx"},
{value:"monero"},
{value:"money-bill-alt-solid"},
{value:"money-bill-alt"},
{value:"money-bill-solid"},
{value:"money-bill-wave-alt-solid"},
{value:"money-bill-wave-solid"},
{value:"money-check-alt-solid"},
{value:"money-check-solid"},
{value:"monument-solid"},
{value:"moon-solid"},
{value:"moon"},
{value:"mortar-pestle-solid"},
{value:"mosque-solid"},
{value:"motorcycle-solid"},
{value:"mountain-solid"},
{value:"mouse-pointer-solid"},
{value:"mouse-solid"},
{value:"mug-hot-solid"},
{value:"music-solid"},
{value:"napster"},
{value:"neos"},
{value:"network-wired-solid"},
{value:"neuter-solid"},
{value:"newspaper-solid"},
{value:"newspaper"},
{value:"nimblr"},
{value:"node-js"},
{value:"node"},
{value:"not-equal-solid"},
{value:"notes-medical-solid"},
{value:"npm"},
{value:"ns8"},
{value:"nutritionix"},
{value:"object-group-solid"},
{value:"object-group"},
{value:"object-ungroup-solid"},
{value:"object-ungroup"},
{value:"odnoklassniki-square"},
{value:"odnoklassniki"},
{value:"oil-can-solid"},
{value:"old-republic"},
{value:"om-solid"},
{value:"opencart"},
{value:"openid"},
{value:"opera"},
{value:"optin-monster"},
{value:"orcid"},
{value:"osi"},
{value:"otter-solid"},
{value:"outdent-solid"},
{value:"page4"},
{value:"pagelines"},
{value:"pager-solid"},
{value:"paint-brush-solid"},
{value:"paint-roller-solid"},
{value:"palette-solid"},
{value:"palfed"},
{value:"pallet-solid"},
{value:"paper-plane-solid"},
{value:"paper-plane"},
{value:"paperclip-solid"},
{value:"parachute-box-solid"},
{value:"paragraph-solid"},
{value:"parking-solid"},
{value:"passport-solid"},
{value:"pastafarianism-solid"},
{value:"paste-solid"},
{value:"patreon"},
{value:"pause-circle-solid"},
{value:"pause-circle"},
{value:"pause-solid"},
{value:"paw-solid"},
{value:"paypal"},
{value:"peace-solid"},
{value:"pen-alt-solid"},
{value:"pen-fancy-solid"},
{value:"pen-nib-solid"},
{value:"pen-solid"},
{value:"pen-square-solid"},
{value:"pencil-alt-solid"},
{value:"pencil-ruler-solid"},
{value:"penny-arcade"},
{value:"people-carry-solid"},
{value:"pepper-hot-solid"},
{value:"percent-solid"},
{value:"percentage-solid"},
{value:"periscope"},
{value:"person-booth-solid"},
{value:"phabricator"},
{value:"phoenix-framework"},
{value:"phoenix-squadron"},
{value:"phone-alt-solid"},
{value:"phone-slash-solid"},
{value:"phone-solid"},
{value:"phone-square-alt-solid"},
{value:"phone-square-solid"},
{value:"phone-volume-solid"},
{value:"photo-video-solid"},
{value:"php"},
{value:"pied-piper-alt"},
{value:"pied-piper-hat"},
{value:"pied-piper-pp"},
{value:"pied-piper"},
{value:"piggy-bank-solid"},
{value:"pills-solid"},
{value:"pinterest-p"},
{value:"pinterest-square"},
{value:"pinterest"},
{value:"pizza-slice-solid"},
{value:"place-of-worship-solid"},
{value:"plane-arrival-solid"},
{value:"plane-departure-solid"},
{value:"plane-solid"},
{value:"play-circle-solid"},
{value:"play-circle"},
{value:"play-solid"},
{value:"playstation"},
{value:"plug-solid"},
{value:"plus-circle-solid"},
{value:"plus-solid"},
{value:"plus-square-solid"},
{value:"plus-square"},
{value:"podcast-solid"},
{value:"poll-h-solid"},
{value:"poll-solid"},
{value:"poo-solid"},
{value:"poo-storm-solid"},
{value:"poop-solid"},
{value:"portrait-solid"},
{value:"pound-sign-solid"},
{value:"power-off-solid"},
{value:"pray-solid"},
{value:"praying-hands-solid"},
{value:"prescription-bottle-alt-solid"},
{value:"prescription-bottle-solid"},
{value:"prescription-solid"},
{value:"print-solid"},
{value:"procedures-solid"},
{value:"product-hunt"},
{value:"project-diagram-solid"},
{value:"pushed"},
{value:"puzzle-piece-solid"},
{value:"python"},
{value:"qq"},
{value:"qrcode-solid"},
{value:"question-circle-solid"},
{value:"question-circle"},
{value:"question-solid"},
{value:"quidditch-solid"},
{value:"quinscape"},
{value:"quora"},
{value:"quote-left-solid"},
{value:"quote-right-solid"},
{value:"quran-solid"},
{value:"r-project"},
{value:"radiation-alt-solid"},
{value:"radiation-solid"},
{value:"rainbow-solid"},
{value:"random-solid"},
{value:"raspberry-pi"},
{value:"ravelry"},
{value:"react"},
{value:"reacteurope"},
{value:"readme"},
{value:"rebel"},
{value:"receipt-solid"},
{value:"record-vinyl-solid"},
{value:"recycle-solid"},
{value:"red-river"},
{value:"reddit-alien"},
{value:"reddit-square"},
{value:"reddit"},
{value:"redhat"},
{value:"redo-alt-solid"},
{value:"redo-solid"},
{value:"registered-solid"},
{value:"registered"},
{value:"remove-format-solid"},
{value:"renren"},
{value:"reply-all-solid"},
{value:"reply-solid"},
{value:"replyd"},
{value:"republican-solid"},
{value:"researchgate"},
{value:"resolving"},
{value:"restroom-solid"},
{value:"retweet-solid"},
{value:"rev"},
{value:"ribbon-solid"},
{value:"ring-solid"},
{value:"road-solid"},
{value:"robot-solid"},
{value:"rocket-solid"},
{value:"rocketchat"},
{value:"rockrms"},
{value:"route-solid"},
{value:"rss-solid"},
{value:"rss-square-solid"},
{value:"ruble-sign-solid"},
{value:"ruler-combined-solid"},
{value:"ruler-horizontal-solid"},
{value:"ruler-solid"},
{value:"ruler-vertical-solid"},
{value:"running-solid"},
{value:"rupee-sign-solid"},
{value:"sad-cry-solid"},
{value:"sad-cry"},
{value:"sad-tear-solid"},
{value:"sad-tear"},
{value:"safari"},
{value:"salesforce"},
{value:"sass"},
{value:"satellite-dish-solid"},
{value:"satellite-solid"},
{value:"save-solid"},
{value:"save"},
{value:"schlix"},
{value:"school-solid"},
{value:"screwdriver-solid"},
{value:"scribd"},
{value:"scroll-solid"},
{value:"sd-card-solid"},
{value:"search-dollar-solid"},
{value:"search-location-solid"},
{value:"search-minus-solid"},
{value:"search-plus-solid"},
{value:"search-solid"},
{value:"searchengin"},
{value:"seedling-solid"},
{value:"sellcast"},
{value:"sellsy"},
{value:"server-solid"},
{value:"servicestack"},
{value:"shapes-solid"},
{value:"share-alt-solid"},
{value:"share-alt-square-solid"},
{value:"share-solid"},
{value:"share-square-solid"},
{value:"share-square"},
{value:"shekel-sign-solid"},
{value:"shield-alt-solid"},
{value:"ship-solid"},
{value:"shipping-fast-solid"},
{value:"shirtsinbulk"},
{value:"shoe-prints-solid"},
{value:"shopping-bag-solid"},
{value:"shopping-basket-solid"},
{value:"shopping-cart-solid"},
{value:"shopware"},
{value:"shower-solid"},
{value:"shuttle-van-solid"},
{value:"sign-in-alt-solid"},
{value:"sign-language-solid"},
{value:"sign-out-alt-solid"},
{value:"sign-solid"},
{value:"signal-solid"},
{value:"signature-solid"},
{value:"sim-card-solid"},
{value:"simplybuilt"},
{value:"sistrix"},
{value:"sitemap-solid"},
{value:"sith"},
{value:"skating-solid"},
{value:"sketch"},
{value:"skiing-nordic-solid"},
{value:"skiing-solid"},
{value:"skull-crossbones-solid"},
{value:"skull-solid"},
{value:"skyatlas"},
{value:"skype"},
{value:"slack-hash"},
{value:"slack"},
{value:"slash-solid"},
{value:"sleigh-solid"},
{value:"sliders-h-solid"},
{value:"slideshare"},
{value:"smile-beam-solid"},
{value:"smile-beam"},
{value:"smile-solid"},
{value:"smile-wink-solid"},
{value:"smile-wink"},
{value:"smile"},
{value:"smog-solid"},
{value:"smoking-ban-solid"},
{value:"smoking-solid"},
{value:"sms-solid"},
{value:"snapchat-ghost"},
{value:"snapchat-square"},
{value:"snapchat"},
{value:"snowboarding-solid"},
{value:"snowflake-solid"},
{value:"snowflake"},
{value:"snowman-solid"},
{value:"snowplow-solid"},
{value:"socks-solid"},
{value:"solar-panel-solid"},
{value:"sort-alpha-down-alt-solid"},
{value:"sort-alpha-down-solid"},
{value:"sort-alpha-up-alt-solid"},
{value:"sort-alpha-up-solid"},
{value:"sort-amount-down-alt-solid"},
{value:"sort-amount-down-solid"},
{value:"sort-amount-up-alt-solid"},
{value:"sort-amount-up-solid"},
{value:"sort-down-solid"},
{value:"sort-numeric-down-alt-solid"},
{value:"sort-numeric-down-solid"},
{value:"sort-numeric-up-alt-solid"},
{value:"sort-numeric-up-solid"},
{value:"sort-solid"},
{value:"sort-up-solid"},
{value:"soundcloud"},
{value:"sourcetree"},
{value:"spa-solid"},
{value:"space-shuttle-solid"},
{value:"speakap"},
{value:"speaker-deck"},
{value:"spell-check-solid"},
{value:"spider-solid"},
{value:"spinner-solid"},
{value:"splotch-solid"},
{value:"spotify"},
{value:"spray-can-solid"},
{value:"square-full-solid"},
{value:"square-root-alt-solid"},
{value:"square-solid"},
{value:"square"},
{value:"squarespace"},
{value:"stack-exchange"},
{value:"stack-overflow"},
{value:"stackpath"},
{value:"stamp-solid"},
{value:"star-and-crescent-solid"},
{value:"star-half-alt-solid"},
{value:"star-half-solid"},
{value:"star-half"},
{value:"star-of-david-solid"},
{value:"star-of-life-solid"},
{value:"star-solid"},
{value:"star"},
{value:"staylinked"},
{value:"steam-square"},
{value:"steam-symbol"},
{value:"steam"},
{value:"step-backward-solid"},
{value:"step-forward-solid"},
{value:"stethoscope-solid"},
{value:"sticker-mule"},
{value:"sticky-note-solid"},
{value:"sticky-note"},
{value:"stop-circle-solid"},
{value:"stop-circle"},
{value:"stop-solid"},
{value:"stopwatch-solid"},
{value:"store-alt-solid"},
{value:"store-solid"},
{value:"strava"},
{value:"stream-solid"},
{value:"street-view-solid"},
{value:"strikethrough-solid"},
{value:"stripe-s"},
{value:"stripe"},
{value:"stroopwafel-solid"},
{value:"studiovinari"},
{value:"stumbleupon-circle"},
{value:"stumbleupon"},
{value:"subscript-solid"},
{value:"subway-solid"},
{value:"suitcase-rolling-solid"},
{value:"suitcase-solid"},
{value:"sun-solid"},
{value:"sun"},
{value:"superpowers"},
{value:"superscript-solid"},
{value:"supple"},
{value:"surprise-solid"},
{value:"surprise"},
{value:"suse"},
{value:"swatchbook-solid"},
{value:"swift"},
{value:"swimmer-solid"},
{value:"swimming-pool-solid"},
{value:"symfony"},
{value:"synagogue-solid"},
{value:"sync-alt-solid"},
{value:"sync-solid"},
{value:"syringe-solid"},
{value:"table-solid"},
{value:"table-tennis-solid"},
{value:"tablet-alt-solid"},
{value:"tablet-solid"},
{value:"tablets-solid"},
{value:"tachometer-alt-solid"},
{value:"tag-solid"},
{value:"tags-solid"},
{value:"tape-solid"},
{value:"tasks-solid"},
{value:"taxi-solid"},
{value:"teamspeak"},
{value:"teeth-open-solid"},
{value:"teeth-solid"},
{value:"telegram-plane"},
{value:"telegram"},
{value:"temperature-high-solid"},
{value:"temperature-low-solid"},
{value:"tencent-weibo"},
{value:"tenge-solid"},
{value:"terminal-solid"},
{value:"text-height-solid"},
{value:"text-width-solid"},
{value:"th-large-solid"},
{value:"th-list-solid"},
{value:"th-solid"},
{value:"the-red-yeti"},
{value:"theater-masks-solid"},
{value:"themeco"},
{value:"themeisle"},
{value:"thermometer-empty-solid"},
{value:"thermometer-full-solid"},
{value:"thermometer-half-solid"},
{value:"thermometer-quarter-solid"},
{value:"thermometer-solid"},
{value:"thermometer-three-quarters-solid"},
{value:"think-peaks"},
{value:"thumbs-down-solid"},
{value:"thumbs-down"},
{value:"thumbs-up-solid"},
{value:"thumbs-up"},
{value:"thumbtack-solid"},
{value:"ticket-alt-solid"},
{value:"times-circle-solid"},
{value:"times-circle"},
{value:"times-solid"},
{value:"tint-slash-solid"},
{value:"tint-solid"},
{value:"tired-solid"},
{value:"tired"},
{value:"toggle-off-solid"},
{value:"toggle-on-solid"},
{value:"toilet-paper-solid"},
{value:"toilet-solid"},
{value:"toolbox-solid"},
{value:"tools-solid"},
{value:"tooth-solid"},
{value:"torah-solid"},
{value:"torii-gate-solid"},
{value:"tractor-solid"},
{value:"trade-federation"},
{value:"trademark-solid"},
{value:"traffic-light-solid"},
{value:"train-solid"},
{value:"tram-solid"},
{value:"transgender-alt-solid"},
{value:"transgender-solid"},
{value:"trash-alt-solid"},
{value:"trash-alt"},
{value:"trash-restore-alt-solid"},
{value:"trash-restore-solid"},
{value:"trash-solid"},
{value:"tree-solid"},
{value:"trello"},
{value:"tripadvisor"},
{value:"trophy-solid"},
{value:"truck-loading-solid"},
{value:"truck-monster-solid"},
{value:"truck-moving-solid"},
{value:"truck-pickup-solid"},
{value:"truck-solid"},
{value:"tshirt-solid"},
{value:"tty-solid"},
{value:"tumblr-square"},
{value:"tumblr"},
{value:"tv-solid"},
{value:"twitch"},
{value:"twitter-square"},
{value:"twitter"},
{value:"typo3"},
{value:"uber"},
{value:"ubuntu"},
{value:"uikit"},
{value:"umbraco"},
{value:"umbrella-beach-solid"},
{value:"umbrella-solid"},
{value:"underline-solid"},
{value:"undo-alt-solid"},
{value:"undo-solid"},
{value:"uniregistry"},
{value:"universal-access-solid"},
{value:"university-solid"},
{value:"unlink-solid"},
{value:"unlock-alt-solid"},
{value:"unlock-solid"},
{value:"untappd"},
{value:"upload-solid"},
{value:"ups"},
{value:"usb"},
{value:"user-alt-slash-solid"},
{value:"user-alt-solid"},
{value:"user-astronaut-solid"},
{value:"user-check-solid"},
{value:"user-circle-solid"},
{value:"user-circle"},
{value:"user-clock-solid"},
{value:"user-cog-solid"},
{value:"user-edit-solid"},
{value:"user-friends-solid"},
{value:"user-graduate-solid"},
{value:"user-injured-solid"},
{value:"user-lock-solid"},
{value:"user-md-solid"},
{value:"user-minus-solid"},
{value:"user-ninja-solid"},
{value:"user-nurse-solid"},
{value:"user-plus-solid"},
{value:"user-secret-solid"},
{value:"user-shield-solid"},
{value:"user-slash-solid"},
{value:"user-solid"},
{value:"user-tag-solid"},
{value:"user-tie-solid"},
{value:"user-times-solid"},
{value:"user"},
{value:"users-cog-solid"},
{value:"users-solid"},
{value:"usps"},
{value:"ussunnah"},
{value:"utensil-spoon-solid"},
{value:"utensils-solid"},
{value:"vaadin"},
{value:"vector-square-solid"},
{value:"venus-double-solid"},
{value:"venus-mars-solid"},
{value:"venus-solid"},
{value:"viacoin"},
{value:"viadeo-square"},
{value:"viadeo"},
{value:"vial-solid"},
{value:"vials-solid"},
{value:"viber"},
{value:"video-slash-solid"},
{value:"video-solid"},
{value:"vihara-solid"},
{value:"vimeo-square"},
{value:"vimeo-v"},
{value:"vimeo"},
{value:"vine"},
{value:"vk"},
{value:"vnv"},
{value:"voicemail-solid"},
{value:"volleyball-ball-solid"},
{value:"volume-down-solid"},
{value:"volume-mute-solid"},
{value:"volume-off-solid"},
{value:"volume-up-solid"},
{value:"vote-yea-solid"},
{value:"vr-cardboard-solid"},
{value:"vuejs"},
{value:"walking-solid"},
{value:"wallet-solid"},
{value:"warehouse-solid"},
{value:"water-solid"},
{value:"wave-square-solid"},
{value:"waze"},
{value:"weebly"},
{value:"weibo"},
{value:"weight-hanging-solid"},
{value:"weight-solid"},
{value:"weixin"},
{value:"whatsapp-square"},
{value:"whatsapp"},
{value:"wheelchair-solid"},
{value:"whmcs"},
{value:"wifi-solid"},
{value:"wikipedia-w"},
{value:"wind-solid"},
{value:"window-close-solid"},
{value:"window-close"},
{value:"window-maximize-solid"},
{value:"window-maximize"},
{value:"window-minimize-solid"},
{value:"window-minimize"},
{value:"window-restore-solid"},
{value:"window-restore"},
{value:"windows"},
{value:"wine-bottle-solid"},
{value:"wine-glass-alt-solid"},
{value:"wine-glass-solid"},
{value:"wix"},
{value:"wizards-of-the-coast"},
{value:"wolf-pack-battalion"},
{value:"won-sign-solid"},
{value:"wordpress-simple"},
{value:"wordpress"},
{value:"wpbeginner"},
{value:"wpexplorer"},
{value:"wpforms"},
{value:"wpressr"},
{value:"wrench-solid"},
{value:"x-ray-solid"},
{value:"xbox"},
{value:"xing-square"},
{value:"xing"},
{value:"y-combinator"},
{value:"yahoo"},
{value:"yammer"},
{value:"yandex-international"},
{value:"yandex"},
{value:"yarn"},
{value:"yelp"},
{value:"yen-sign-solid"},
{value:"yin-yang-solid"},
{value:"yoast"},
{value:"youtube-square"},
{value:"youtube"},
{value:"zhihu"}
];
export default icons;