import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import SectionHeader from '../../../components/views/SectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import LoadingWizard from '../../../components/spinner/wizard';
import InputField from '../../../components/inputs/inputField';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import SelectField from '../../../components/inputs/selectField';
import PageBuilderModal from './pageEditor'
import SectionFooter from '../../../components/views/SectionFooter';
import ToggleField from '../../../components/inputs/toggleField';
import FileChooserPreview from '../../../components/inputs/fileChooserPreview';
import FilesUploaderModal from '../../../components/files/filesUploaderModal';
import ColorField from '../../../components/inputs/colorField';
import CodeEditor from '../../../components/codeEditor/codeEditor';
import DeleteSinglePageModal from './modals/deleteSinglePageModal';
import RenamePageModal from './modals/renamePageModal';

import DeletePageModal from './modals/deleteDefaultModal';
const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;

const pageTypeOptions = [
    { value: false, label: 'General Page' },
    { value: true, label: 'Session Template' },
]

const pageAvailabilityOptions = [
    { value: true, label: 'Public (Available to anyone)' },
    { value: false, label: 'Private (Only for logged in users)' }
]
class PageTemplate extends React.Component {
    constructor(props) {
        super(props);
        const page = props.pages.find((p) => p._id === props.match.params.pageId);
        this.state = {
            editorOpen: false,
            selectedPage: null,
            loading: false,
            addPageModalOpen: false,
            previewOpen: true,
            detailsOpen: true,
            page: { ...page },
            editedPage: { ...page },
            selectedSection: 0,
            editing: false,
            editCount: 0,
            deletePageModalTrigger: false,
            toggleRenamePage: false,

        };

    }
    refreshIframe = () => {
        this.setState({ previewUrl: '' }, () => this.setState({ previewUrl: this.props.page.url }));
    };


    cancelEdit = () => {
        this.setState({ editing: false, editedPage: { ...this.state.page } });
    };

    updatePage = async (updatedPage, callback) => {
        this.setState({ editedPage: updatedPage, page: { ...updatedPage }, page: { ...this.state.page, ...updatedPage } }, () => {
            this.props.updatePage(this.state.page);
        });
        callback && callback();
    };

    togglePageBuilder = () => {
        this.setState({ editorOpen: !this.state.editorOpen });
    };

    updateSelected = (selected) => {
        this.setState({ selectedSection: selected });
    };

    savePage = async () => {
        const { editedPage, page } = this.state;
        const { updatePage } = this.props;


        try {
            updatePage(editedPage, () => {
                this.setState({ page: { ...page, ...editedPage }, editing: false, loading: false });
            });
        } catch (e) {
            this.setState({ loading: false });
        }
    }

    publishP = (publishP) => {
        const { editedPage, editing, } = this.state;
        if (publishP == true) {
            this.setState({ editedPage: { ...editedPage, status: 'published', published: true }, editing: true })

        } else {
            this.setState({ editedPage: { ...editedPage, status: 'draft', published: false }, editing: true })
        }

    }

    renderSection() {
        const {
            selectedSection,
            //requiredFields,
            editedPage,
            editorOpen
        } = this.state;
        const { readOnly, organization, event, programId, orgId } = this.props;
        const isDraft = editedPage.status == 'draft' ? true : false;
        switch (selectedSection) {
            case 0:
                return (
                    <div className='sectionBody'>

                        <div className='blocksContainer'>
                            <div className='sectionBlock mr-25 contained-h'>
                                <h2 className='blockHeader'>Basic Information</h2>
                                {/* <InputField
                                    label="Title"
                                    required={true}
                                    placeholder="Appears as browser tab title"
                                    value={editedPage.title}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, title: e.target.value },
                                            editing: true
                                        });
                                    }}
                                /> */}
                                <InputField
                                    label="Description"
                                    placeholder="Displays in page previews when link is shared"
                                    value={editedPage.description}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, description: e.target.value },
                                            editing: true
                                        });
                                    }}
                                />

                                <SelectField
                                    label="Page Type"
                                    required={true}
                                    classes="w-100"
                                    placeholder="Page type"
                                    value={editedPage.isSessionPage ? pageTypeOptions[1] : pageTypeOptions[0]}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, isSessionPage: e.value },
                                            editing: true
                                        });
                                    }}
                                    options={pageTypeOptions}
                                />

                                <InputField
                                    subText={`https://${organization.identifier}.simple.events/eventName/${editedPage.pathSlug}`}
                                    label="Address"
                                    required={true}
                                    placeholder="Unique slug for this page"
                                    value={editedPage.pathSlug}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, pathSlug: e.target.value },
                                            editing: true
                                        });
                                    }}
                                />
                                <SelectField
                                    label="Availability"
                                    required={true}
                                    classes="w-100"
                                    placeholder="Page availability"
                                    value={editedPage.openToPublic ? pageAvailabilityOptions[0] : pageAvailabilityOptions[1]}
                                    onChange={(e) => {
                                        this.setState({
                                            editedPage: { ...editedPage, openToPublic: e.value },
                                            editing: true
                                        });
                                    }}
                                    options={pageAvailabilityOptions}
                                />

                                <ToggleField
                                    checked={isDraft}
                                    icons={false}
                                    label={"Publish on create"}
                                    onChange={() => {
                                        this.publishP(isDraft);
                                    }}

                                />
                                {/*<h2 className='blockHeader'>
                                    Page Style
                                </h2>

                                <div className="editorFileInput">
                                    <FileChooserPreview
                                        label="Background Image"
                                        value={editedPage.backgroundImage}
                                        clearFile={() =>
                                            this.setState({
                                                editing: true,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage: ''
                                                },
                                                editCount: this.state.editCount + 1
                                            })}
                                        onClick={(e) =>
                                            this.setState({
                                                editCount: this.state.editCount + 1,
                                                editing: true,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage:
                                                        'choosing__' + editedPage.backgroundImage
                                                }
                                            })}
                                    />
                                    <FilesUploaderModal
                                        links={{
                                            getAssets: `Organizations/${orgId}/assets`,
                                            addAssets: `Organizations/${orgId}/assets`,
                                        }}
                                        isOpen={
                                            editedPage.backgroundImage &&
                                            editedPage.backgroundImage.includes('choosing__')
                                        }
                                        toggle={() => {
                                            this.setState({
                                                editing: true,
                                                editCount: this.state.editCount + 1,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage: editedPage.backgroundImage.replace(
                                                        'choosing__',
                                                        ''
                                                    )
                                                }
                                            });
                                        }}
                                        orgId={this.props.orgId}
                                        addFiles={(fileURL) => {
                                            this.setState({
                                                editCount: this.state.editCount + 1,
                                                editing: true,
                                                editedPage: {
                                                    ...this.state.editedPage,
                                                    backgroundImage: fileURL
                                                }
                                            });
                                        }}
                                    />
                                </div> */}

                                {/*  <ColorField
                                    label="Background Overlay"
                                    value={editedPage.backgroundOverlay}
                                    onChange={(e) => {
                                        this.setState({
                                            editing: true,
                                            editedPage: {
                                                ...this.state.editedPage,
                                                backgroundOverlay: e
                                            },
                                            editCount: this.state.editCount + 1
                                        });
                                    }}
                                />
                               <div className="popoverButton">
                                    <div
                                        className="actionButton actionOutline w-100"
                                        onClick={() => this.setState({ customCSSModalOpen: true })}
                                    >
                                        <i className="las la-brush mr-5" />
                                        <div>
                                            {editedPage.customCSS &&
                                                editedPage.customCSS.length > 0 ? (
                                                'Edit'
                                            ) : (
                                                'Add'
                                            )}{' '}
                                            Custom Page CSS
                                        </div>
                                    </div>
                                    <div className="pageConfigPopover">
                                        <div className="pageConfigPopoverContent">
                                <CodeEditor
                                    className={`displayedField2`}
                                    placeholder="Add your custom CSS here"
                                    content={editedPage.customCSS}
                                    language="css"
                                    style={{
                                        marginLeft: 0,
                                        width: '100%',
                                        height: '100%',
                                        minHeight: '100%'
                                    }}
                                    setContent={(c) =>
                                        this.setState({
                                            editing: true,
                                            editCount: this.state.editCount + 1,
                                            editedPage: {
                                                ...this.state.editedPage,
                                                customCSS: c
                                            }
                                        })}
                                    /> 
                                    </div>
                                </div>
                            </div>*/}

                            </div>
                            <div className='sectionBlock emailDesignPreview mt-20'>

                                <div
                                    className="cardDetailsContainerPages containerTitle"
                                >

                                    <div className='w-100'>
                                        <h2 className='blockHeader mt-0'>Design</h2>
                                        <h5 className='formFieldLabel mt-10'> Web Page Design </h5>
                                    </div>

                                    <button
                                        className={`neu min ml-a`}
                                        onClick={this.togglePageBuilder}
                                    >
                                        Edit Design
                                    </button>
                                </div>
                                <div
                                    className="emailPreview eFrame flex-ac preview"
                                >
                                    {!editorOpen && <PagePreviewFrame
                                        ENV={ENV}
                                        renderURL={`Organizations/${orgId}/programs/${programId}/render/${editedPage._id}/`}
                                        pageProperties={this.state.editedPage}
                                        focusedComponent={""}
                                        simulatedUser={{ value: false }}
                                        simulatedSession={{ value: false }}
                                        renderOnly={true}
                                        editing={this.state.editCount}
                                        preview={true}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div className='sectionBody'>

                    </div>
                );
        }
    }

    deletePageModalTrigger = () => {
        const { deletePageModalTrigger } = this.state;
        this.setState({ deletePageModalTrigger: !deletePageModalTrigger })
    }
    toggleRenamePageModal = () => {
        const { toggleRenamePage } = this.state;
        this.setState({ toggleRenamePage: !toggleRenamePage });
    };

    render() {
        const {
            loading,
            page,
            editedPage,
            editorOpen,
            editing,
            selectedSection,
            deletePageModalTrigger,
            toggleRenamePage,
        } = this.state;
        const { event, programId, orgId, program } = this.props;
        /*  const publishActions = page.status == 'draft' ? [
             {
                 label: 'Publish Page',
                 onClick: () => {
                     this.setState({
                         editedPage: { ...editedPage, status: 'published', published: true },
                         editing: true
                     });
                 }, disabled: editing
 
             }] : [
             {
                 label: 'Set Unpublished',
                 onClick: () => {
                     this.setState({
                         editedPage: { ...editedPage, status: 'draft', published: false },
                         editing: true
                     });
                 },
                 disabled: editing
 
             }
         ] */
        return (
            <div className="sectionContainer">
                <SectionHeader
                    enableLastLink={true}
                    title={editedPage.title}
                    //description={page.status}
                    //status={editedPage.status}
                    breadcrumbs={[
                        {
                            name: program.name
                        },
                        {
                            name: 'Pages',
                        }
                    ]}
                    //mainActions={publishActions}
                    subActions=
                    {
                        [
                            {
                                label: <span><i className='las la-pen mr-10'></i>Rename Template</span>,
                                onClick: this.toggleRenamePageModal,
                            },
                            {
                                label: <span><i className='las la-trash mr-10'></i>Delete Template</span>,
                                onClick: this.deletePageModalTrigger,
                            }
                        ]
                    }
                >
                    <div className='tabsContainer unsetWidth'></div>
                </SectionHeader>

                {/* {!loading && <div className="actionButtonContainer mt-20" style={{ marginLeft: 'unset' }}>
					<div >
						<button className={`mainAction ${editing? 'disabled' : 'prim'}`}
						disabled={editing}
						onClick={()=>{
								this.publishP(isDraft); 
							}}>
							<div>{isDraft ? "Publish Page" : "Unpublished Page"}</div>
						</button>
					</div>
				</div>} */}

                {!loading && this.renderSection()}
                <SectionFooter updatedAt={program.updatedAt} saveEdit={this.savePage} cancelEdit={this.cancelEdit} editing={editing} orgId={this.props.orgId}
                    programId={programId} />
                <LoadingWizard text="Loading Page" loading={loading} />
                {editorOpen && <PageBuilderModal
                    scope="program"
                    isOpen={editorOpen}
                    toggle={() => this.setState({ editorOpen: !editorOpen })}
                    page={this.state.page}
                    editedPage={this.state.editedPage}
                    updatePage={this.updatePage}
                    cancelEdit={this.cancelEdit}
                    event={event}
                    programId={programId}
                    orgId={this.props.orgId} />}
                {toggleRenamePage && (
                    <RenamePageModal
                        isOpen={toggleRenamePage}
                        page={this.state.page}
                        orgId={this.props.orgId}
                        programId={programId}
                        event={event}
                        toggle={this.toggleRenamePageModal}
                        update={this.updatePage}
                    />
                )}
                {deletePageModalTrigger &&
                    <DeletePageModal
                        isOpen={deletePageModalTrigger}
                        toggle={() => this.deletePageModalTrigger()}
                        title={`Delete ${1 > 1 ? 'Pages' : 'Page'}`}
                        message={`You are about to delete ${1} ${1 > 1
                            ? 'pages'
                            : 'page'}, this is irreversible,
                would you like to continue?`}
                        submit={() =>
                            this.props.deleteSinglePage(this.state.page._id, () => {
                                this.props.history.push(`/${orgId}/programs/${programId}/pages`)
                            })}
                    />
                }
                {/*  {deletePageModalTrigger && (
                    <DeleteSinglePageModal
                        delete={this.deletePages}
                        event={event} 
                        page={editedPage} 
                        programId={programId} 
                        isOpen={deletePageModalTrigger} 
                        orgId={this.props.orgId} 
                        toggle={this.deletePageModalTrigger}
                        deleteSinglePage={this.props.deleteSinglePage}
                    />
                )} */}
            </div>
        );
    }
}

export default withRouter(PageTemplate);
