import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			selectedTemplates: [],
			restrictDelete: false,

		};
	}
	componentDidMount() {
		const { templates, isBatchDelete, template } = this.props;
		let selectedTemplates = [];
		if (isBatchDelete) {
			selectedTemplates = templates.filter((template) => isBatchDelete.includes(template._id));
		} else {
			selectedTemplates.push(template);
		}

		let restrictDelete = selectedTemplates.some((template) => template.forms && template.forms.length > 0);

		this.setState({ selectedTemplates, restrictDelete });
	}

	deleteTemplate = async () => {
		const { orgId, eventId, template, toggle, update, updateDeleteTemplate, isBatchDelete, noRedirect, updateMultipleDeleteTemplate } = this.props;
		this.setState({ submitting: true });

		try {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/templates/`, { templates: isBatchDelete ? isBatchDelete : [template._id] })
				.then((res) => {



					if (updateMultipleDeleteTemplate) {
						updateMultipleDeleteTemplate()

					} else {

						update({ sent: true }, () => {
							updateDeleteTemplate(template._id, () => {
								!noRedirect ? window.open('./', '_self') : toggle();
							});
						});
					}

				});
		} catch (e) {
			console.log(e);
		}
	};

	renderRestrictDelete = () => {
		const { selectedTemplates } = this.state;

		let filteredSelectedTemplates = selectedTemplates.filter((template) => template.forms && template.forms.length > 0);
		return (
			<div className="restrict-delete">
				<p>
					You cannot delete the following templates because they are currently being used in the following forms:
				</p>
				<div className='sectionBlock pt-20'
					style={{
						overflowY: 'auto',
						maxHeight: '300px',
					}}
				>
					{filteredSelectedTemplates.map((template, index) => {
						return (
							<div key={index}>
								<h3 className=' pb-10'>{template.name}</h3>
								<div className="pb-10">
									{template.forms.map((form, index) => {
										return (
											<div>
												<a href={`/${this.props.orgId}/events/${this.props.eventId}/forms/${form._id}`
												} target='_blank'>{form.name}</a>
											</div>
										);
									})}
								</div>
								<hr />

							</div>
						);
					}
					)}
				</div>
			</div >
		);

	}


	render() {
		const { isOpen, toggle, templates, submitting, isBatchDelete } = this.props;
		const { selectedTemplates, restrictDelete } = this.state;

		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Template"}
					bodyHeaderText={restrictDelete ? '' : `You are about to delete ${isBatchDelete ? `${isBatchDelete.length}` : 'this'} template from the event, this is irreversible, would you like to continue?`}
					bodyContent={() => {
						return (
							<div>{
								restrictDelete ? this.renderRestrictDelete() : ''
							}</div>
						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.deleteTemplate()}
					actionButtonDisabled={submitting || restrictDelete}
					isDelete={true}

				></FieldModal>
			</div>

		);
	}
}

export default withRouter(DeleteTemplateModal);
