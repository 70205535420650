import React, { Component } from 'react';

import InputField from '../../../components/inputs/inputField.js';
import ToggleField from '../../../components/inputs/toggleField.js';
import API from '../../../utils/API.js';


class EventAttendeeIntegrations extends Component {
	constructor(props) {
		super(props);
		const sfIntegration = props.org.integrations.find(s => {
			return s.identifier === "salesforce" && s.enabled
		})
		this.state = {
			files: [],
			accepted: [],
			rejected: [],
			genExpanded: true,
			sf: sfIntegration ? sfIntegration : null
		};
	}
	render() {
		const { editedAttendee } = this.props;
		return (
			<div className='sectionBlock'>
				{editedAttendee.integrationData.medpro && <div>

					<h2 className="blockHeader">MedPro</h2>

					<InputField
						label="MedPro ID"
						value={editedAttendee.integrationData.medpro.medProId}
						disabled={true}
						type="text"
						inputIcon={<i className="las la-lock" />}
					/>

					<InputField
						label="NPI Number"
						value={editedAttendee.integrationData.medpro.NPINumber}
						disabled={true}
						type="text"
						inputIcon={<i className="las la-lock" />}
					/>

					<InputField
						label="State License Number"
						value={editedAttendee.integrationData.medpro.stateLicenseNumber}
						disabled={true}
						type="text"
						inputIcon={<i className="las la-lock" />}
					/>
					<InputField
						label="State License State"
						value={editedAttendee.integrationData.medpro.licenseState}
						disabled={true}
						type="text"
						inputIcon={<i className="las la-lock" />}
					/>
				</div>}
				{editedAttendee.integrations && editedAttendee.integrations.salesforce && <div >
					<div className='flex jcsb'>
						<h2 className="blockHeader">Salesforce</h2>
						{editedAttendee.integrations.salesforce && this.state.sf && <h2 className='blockHeader'>
							<i className={`lab la-salesforce sfIcon ${editedAttendee.integrations.salesforce.campaignMemberId && editedAttendee.integrations.salesforce.campaignMemberId !== '' ? 'on' : ''}`} onClick={() => {
								if (editedAttendee.integrations.salesforce.campaignMemberId && editedAttendee.integrations.salesforce.campaignMemberId !== '') {
									window.open(`${this.state.sf.credentials.instance_url}/lightning/r/CampaignMember/${editedAttendee.integrations.salesforce.campaignMemberId}/view`, '_blank');
								} else {
									API().post(`/Organizations/${this.props.orgId}/events/${this.props.eventId}/${editedAttendee._id}/connectSalesforceCampaignMember`).then(res => {	console.log("RES",res);
										editedAttendee.integrations.salesforce.campaignMemberId = res.data.campaignMemberId;
										this.props.updateAttendee(editedAttendee._id, editedAttendee);
									}).catch(err => {
										console.log("ERR",err);
									})
								}
							}} />
							</h2>}
					</div>

					<ToggleField label={`Is Lead`} disabled={true} checked={editedAttendee.integrations.salesforce.leadId && editedAttendee.integrations.salesforce.leadId != ''} icons={false} />
					<ToggleField label="Is Contact" disabled={true} checked={editedAttendee.integrations.salesforce.contactId && editedAttendee.integrations.salesforce.contactId != ''} icons={false} />
					<InputField
						label="Account Name"
						value={editedAttendee.integrations.salesforce.accountName}
						disabled={true}
						type="text"
						inputIcon={<i className="las la-lock" />}
					/>
				</div>}
			</div>
		);
	}
}

export default EventAttendeeIntegrations;
