import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';

import AddContactModal from './modals/addContactModal.js';
import DeleteContactsModal from './modals/deleteContactsModal';
import UpdatePermissionsModal from './modals/updatePermissions';

let batchCallback;
const permissionOptions = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'user', label: 'User' },
	{ value: 'guest', label: 'Guest' }
];
class Contacts extends Component {
	constructor(props) {
		super(props);

		let fieldColumns = {
			emailAddress: 'Email',
			firstName: 'First Name',
			lastName: 'Last Name',
			phoneNumber: 'Phone Number',
			title: 'Title',
			company: 'Company',
			dietaryPreference: 'Dietary Preference',
			foodAllergies: 'Food Allergies',
			emergencyContactName: 'Emergency Contact',
			emergencyContactPhone: 'Emergency Contact Phone',
			address: 'Address',
			city: 'City',
			state: 'State',
			zip: 'Zip',
			country: 'Country',

			permission: 'Permission',
			updatedAt: 'Updated At'
		};

		let fieldTypes = {
			emailAddress: 'text',
			firstName: 'text',
			lastName: 'text',
			phoneNumber: 'text',
			permission: 'status',
			title: 'text',
			company: 'text',
			dietaryPreference: 'text',
			foodAllergies: 'text',
			emergencyContactName: 'text',
			emergencyContactPhone: 'text',
			address: 'text',
			city: 'text',
			state: 'text',
			zip: 'text',
			country: 'text',
			updatedAt: 'timestamp'
		};
		if (props.organization.integrations.find(s => {
			return s.identifier === "medpro" && s.enabled
		})) {
			fieldColumns = {
				...fieldColumns,
				medProId: "MedPro ID",
				NPINumber: "NPI Number",
				stateLicenseNumber: "State License Number",
				licenseState: "License State",
			}
			fieldTypes = {
				...fieldTypes,
				medProId: "text",
				NPINumber: "text",
				stateLicenseNumber: "text",
				licenseState: "text",
			}
		}
		if (props.organization.integrations.find(s => {
			return s.identifier === "salesforce" && s.enabled
		})) {
			fieldColumns = {
				...fieldColumns,
				isSFLead: "Is Lead",
				isSFContact: "Is Contact",
				accountName: "Account Name",
			}
			fieldTypes = {
				...fieldTypes,
				isSFLead: "bool",
				isSFContact: "bool",
				accountName: "text",
			}
		}

		this.state = {
			contacts: [],
			contactViews: [],
			loading: true,
			columns: fieldColumns,
			columnTypes: fieldTypes,
			addModalOpen: false,
			deleteModalOpen: false,
			checkedContacts: [],
			permissionModalOpen: false,
			requiredFields: {
				firstName: false,
				email: false
			}
		};
	}

	componentDidMount() {
		API()
			.get(`Organizations/${this.props.orgId}/contacts`)
			.then((res) => {
				if (res.data) {
					this.setState({
						contacts: res.data.contacts,
						contactViews: res.data.views || [],
						loading: false
					});
				} else {
					this.setState({
						loading: false
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	}

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};
	toggleDelete = (checkedContacts, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedContacts: checkedContacts || {},
			deleteModalOpen: !this.state.deleteModalOpen
		});
	};
	togglePermission = (checkedContacts, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedContacts: checkedContacts || {},
			permissionModalOpen: !this.state.permissionModalOpen
		});

	}

	deleteContacts = async () => {
		const { checkedContacts } = this.state;
		await API()
			.patch(`Organizations/${this.props.orgId}/contacts`, {
				request: 'delete',
				userIds: Object.keys(checkedContacts)
			})
			.then((res) => {
				const contacts = this.state.contacts.filter((e) => !Object.keys(checkedContacts).includes(e._id));
				this.setState({ contacts: contacts });
				batchCallback && batchCallback('delete');
				this.toggleDelete();
			});
	};

	updatePermissions = async (permission) => {
		const { checkedContacts } = this.state;
		await API()
			.patch(`Organizations/${this.props.orgId}/contacts`, {
				request: 'updatePermissions',
				permission: permission,
				userIds: Object.keys(checkedContacts)
			})
			.then((res) => {
				const contacts = this.state.contacts.map((c) => {
					if (Object.keys(checkedContacts).includes(c._id)) {

						c.permission = permission;
					}

					return c
				});
				this.setState({ contacts: contacts, checkedContacts: {} });
				batchCallback && batchCallback('update');
				this.togglePermission();
			});

	}


	createContact = async (contact) => {
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!contact[key] || contact[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			await API().post(`Organizations/${this.props.orgId}/contacts`, contact).then((res) => {
				if (res.data) {
					res.data.link = `/${this.props.orgId}/contacts/${res.data._id}/0`;

					this.setState({
						contacts: this.state.contacts.push(res.data),
						addModalOpen: false
					});
				} else {
					return false;
				}
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const {
			loading,
			contactViews,
			columns,
			requiredFields,
			contacts,
			addModalOpen,
			columnTypes,
			deleteModalOpen,
			checkedContacts,
			permissionModalOpen
		} = this.state;
		return (
			<div className="pageContainer">
				{!loading && (
					<ViewsContainer
						views={contactViews}
						entries={contacts}
						columns={columns}
						columnTypes={columnTypes}
						title="Contacts"
						description="View and manage your contacts. All Users can access your Account Website. Admins have additional event features"
						readOnly={false}
						mainActions={[{ onClick: this.toggleAdd, label: 'Add Contact' }]}
						batchActions={[
							{
								label: 'Update Permissions',
								onClick: this.togglePermission
							},
							{
								label: 'Delete',
								iconClass: 'las la-trash-alt',
								class: 'danger',
								onClick: this.toggleDelete
							}
						]}
						enableDownloadViews={true}
						orgId={this.props.orgId}
						type={'Contact'}
						referenceCollection={'Organizations'}
						referenceId={this.props.orgId}
					/>)
				}

				{addModalOpen && (
					<AddContactModal
						requiredFields={requiredFields}
						isOpen={addModalOpen}
						toggle={this.toggleAdd}
						createContact={this.createContact}
					/>
				)}
				{permissionModalOpen && (
					<UpdatePermissionsModal
						requiredFields={requiredFields}
						isOpen={permissionModalOpen}
						toggle={this.togglePermission}
						updatePermissions={this.updatePermissions}
					/>
				)}

				{deleteModalOpen && (
					<DeleteContactsModal
						isOpen={deleteModalOpen}
						count={Object.keys(checkedContacts).length}
						toggle={this.toggleDelete}
						deleteContacts={this.deleteContacts}
					/>
				)}
				<LoadingWizard text="Loading Organization Contacts" loading={loading} />

			</div>
		);
	}
}

export default withRouter(Contacts);
