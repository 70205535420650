import React, { Component } from 'react';
import ViewTab from '../tabs/ViewTab';
import MoreViewsTab from '../tabs/MoreViewsTab';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ViewsTable from '../tables/ViewsTable';
import moment from 'moment';
import InputField from '../inputs/inputField';

class SharedReportsContainer extends Component {
    constructor(props) {
        super(props);
        let customViews = props.report.views.map((view) => {
            const columns = view.customFieldColumns;
            const columnTypes = view.defaultFieldTypes;
            const toggledColumns = {};
            const normalizedColumns = columns ? Object.keys(columns).map((cfc) => {
                toggledColumns[cfc] = view.toggledColumns.includes(cfc);
                return {
                    label: columns[cfc],
                    key: cfc,
                    value: cfc,
                    type: (columnTypes && columnTypes[cfc]) || typeof columns[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            }) : [];
            if (columns && Object.keys(columns).length > 0) {
                delete toggledColumns[Object.keys(columns)[0]];
            }
            return {
                ...view,
                toggledColumns: toggledColumns,
                normalizedColumns: normalizedColumns
            }
        });
        this.state = {
            newEntries: [],
            checkedEntries: {},
            allEntries: JSON.parse(JSON.stringify(props.entries)),
            organizedEntries: props.entries,
            allChecked: false,
            windowWidth: window.innerWidth,
            activeView: 0,
            focusedView: 0,
            searching: false,
            searchTerm: '',
            searchFocused: false,
            searchedEntries: [],
            columnSearchTerm: '',
            defaultViewFilters: [
                {
                    column: '',
                    condition: '',
                    value: ''
                }
            ],
            customViews: customViews,
            focusedCustomField: null,
            morePosition: null,
            deletedCustomField: {}
        };
    }

    //search all entries properties for search term
    searchEntries = () => {
        const { organizedEntries, searchTerm, activeView, customViews } = this.state;

        const selectedCustomView = customViews[activeView];
        let columns = selectedCustomView.customFieldColumns;
        if (selectedCustomView) {
            const customColumns = {};
            Object.keys(selectedCustomView.toggledColumns).forEach((col) => {
                customColumns[col] = columns[col];
            });
            const mainColumnKey = Object.keys(columns)[0];
            const mainColumn = columns[mainColumnKey];
            columns = customColumns;
            columns[mainColumnKey] = mainColumn;
        }

        let searchedEntries = organizedEntries[selectedCustomView.type];

        if (searchTerm.length > 0) {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term != '') {
                    searchedEntries = searchedEntries.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }
        const sc = this.state.searchedEntries;
        sc[selectedCustomView.type] = searchedEntries;
        this.setState({ searching: searchTerm != '', searchedEntries: sc });
    };

    componentDidMount() {
        window.onresize = () => {
            this.setState({ windowWidth: window.innerWidth });
        };
        this.props.report.views.forEach((av, vi) => {
            this.countFilteredEntries(av);
            this.filterView(vi);
        });
        this.selectView(0);
    }

    setOrderOfColumns = (viewIndex, orderArray) => {
        const { customViews } = this.state;
        const activeCustomView = customViews[viewIndex];
        const columns = activeCustomView.customFieldColumns;
        const columnTypes = activeCustomView.defaultFieldTypes;
        const toggledColumns = {};
        const normalizedColumns = columns ? Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: (columnTypes && columnTypes[cfc]) || typeof columns[cfc],
                sortAsc: false,
                sortDesc: false
            };
        }) : [];
        let processedColumns = [];
        let newCol = [];
        if (orderArray) {
            let sortedNormalizedColumns = [];
            let deletedColumns = [];
            let addedColumns = [];
            orderArray.map((col, i) => {
                let tempC = normalizedColumns.find(nc => nc.key == col);
                processedColumns.push(col);

                if (!tempC) {
                    deletedColumns.push(col);
                } else {
                    sortedNormalizedColumns.push(tempC);
                }

            });

            normalizedColumns.map((nc) => {
                if (!processedColumns.includes(nc.key)) {
                    sortedNormalizedColumns.push(nc);
                }
            })

            sortedNormalizedColumns.filter(c => !Object.keys(this.state.deletedCustomField).includes(c.key))

            this.setState({ normalizedColumns: sortedNormalizedColumns });
        } else {
            let sortedNormalizedColumns = Object.keys(activeCustomView.customFieldColumns).map((cfc) => {
                return {
                    label: activeCustomView.customFieldColumns[cfc],
                    key: cfc,
                    value: cfc,
                    type: (activeCustomView.defaultFieldTypes && activeCustomView.defaultFieldTypes[cfc]) || typeof activeCustomView.customFieldColumns[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            }).filter(c => !Object.keys(this.state.deletedCustomField).includes(c.key));
            this.setState({ normalizedColumns: sortedNormalizedColumns });
        }
    }

    filterView = (viewIndex) => {
        const { allEntries, customViews, defaultViewFilters, organizedEntries } = this.state;
        let filterLayers = defaultViewFilters;
        const selectedCustomView = customViews[viewIndex];
        const aEntries = selectedCustomView ? allEntries[selectedCustomView.type] : [];
        const activeViewFilter = viewIndex;

        if (customViews[activeViewFilter] && customViews[activeViewFilter].filterLayers) {
            filterLayers = customViews[activeViewFilter].filterLayers; if (customViews[activeViewFilter].columnsOrder && customViews[activeViewFilter].columnsOrder.length > 0) {

            }
            /* (customViews[activeViewFilter].columnsOrder && customViews[activeViewFilter].columnsOrder.length > 0) ? this.setOrderOfColumns(viewIndex, customViews[activeViewFilter].columnsOrder, !customViews[activeViewFilter].unsavedColumns) : this.setOrderOfColumns(viewIndex); */
        }
        const filteredView =
            filterLayers.length > 0 && filterLayers[0].column != ''
                ? aEntries.filter((a) => {
                    let valid = true;
                    filterLayers.forEach((layer, index) => {
                        if (a[layer.column] || a[layer.column] == false) {
                            switch (layer.condition) {
                                case 'is':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                    break;
                                case 'isnot':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                    break;
                                case 'contains':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'doesnotcontain':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'isempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == '')
                                            : valid || (!a[layer.column] || a[layer.column] == '');
                                    break;
                                case 'isnotempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] && a[layer.column] != '')
                                            : valid || (a[layer.column] && a[layer.column] != '');
                                    break;
                                case 'isdatelaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                            : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                    break;
                                case 'isdateearlierthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                            : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                    break;
                                case 'isdateequalto':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                            : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                    break;
                                case 'isupcoming':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment())
                                            : valid || moment(a[layer.column]).isAfter(moment());
                                    break;
                                case 'ispast':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment())
                                            : valid || moment(a[layer.column]).isBefore(moment());
                                    break;

                                case 'istoday':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY')
                                            : valid ||
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY');
                                    break;
                                case 'isyes':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] || a[layer.column] == true)
                                            : valid || (a[layer.column] || a[layer.column] == true);
                                    break;
                                case 'isno':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == false)
                                            : valid || (!a[layer.column] || a[layer.column] == false);
                                    break;
                                case 'isnumbergreaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) > layer.value
                                            : valid || parseInt(a[layer.column]) > layer.value;
                                    break;
                                case 'isnumberlessthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) < layer.value
                                            : valid || parseInt(a[layer.column]) < layer.value;
                                    break;
                                default:
                            }
                        } else {
                            valid = false;
                        }
                    });
                    return valid;
                })
                : aEntries;
        if (customViews[activeViewFilter])
            customViews[activeViewFilter].count = filteredView.length;
        //customViews[activeViewFilter].unsavedFilter = true;
        const oEntries = organizedEntries;
        if (selectedCustomView) oEntries[selectedCustomView.type] = filteredView;
        this.setState({
            organizedEntries: oEntries,
            customViews: customViews
        });
    };

    countFilteredEntries = async (view) => {
        const { allEntries, customViews, activeView } = this.state;
        const selectedCustomView = customViews[activeView];
        const aEntries = allEntries[selectedCustomView.type];
        const filteredView =
            view.filterLayers.length > 0 && view.filterLayers[0].column != ''
                ? aEntries.filter((a) => {
                    let valid = true;
                    view.filterLayers.forEach((layer, index) => {
                        if (a[layer.column] || a[layer.column] == false) {
                            switch (layer.condition) {
                                case 'is':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                    break;
                                case 'isnot':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                    break;
                                case 'contains':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'doesnotcontain':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'isempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == '')
                                            : valid || (!a[layer.column] || a[layer.column] == '');
                                    break;
                                case 'isnotempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] && a[layer.column] != '')
                                            : valid || (a[layer.column] && a[layer.column] != '');
                                    break;
                                case 'isdatelaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                            : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                    break;
                                case 'isdateearlierthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                            : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                    break;
                                case 'isdateequalto':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                            : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                    break;
                                case 'isupcoming':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment())
                                            : valid || moment(a[layer.column]).isAfter(moment());
                                    break;
                                case 'ispast':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment())
                                            : valid || moment(a[layer.column]).isBefore(moment());
                                    break;
                                case 'istoday':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY')
                                            : valid ||
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY');
                                    break;
                                case 'isyes':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] || a[layer.column] == true)
                                            : valid || (a[layer.column] || a[layer.column] == true);
                                    break;
                                case 'isno':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == false)
                                            : valid || (!a[layer.column] || a[layer.column] == false);
                                    break;
                                case 'isnumbergreaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) > layer.value
                                            : valid || parseInt(a[layer.column]) > layer.value;
                                    break;
                                case 'isnumberlessthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) < layer.value
                                            : valid || parseInt(a[layer.column]) < layer.value;
                                    break;
                                default:
                            }
                        } else {
                            valid = false;
                        }
                    });
                    return valid;
                })
                : aEntries;
        const viewIndex = customViews.findIndex((a) => a._id == view._id);
        customViews[viewIndex] && (customViews[viewIndex].count = filteredView.length);
        this.setState({ customViews: customViews });

    };

    selectView = (viewIndex) => {
        this.setState({ activeView: viewIndex, searchTerm: '', searching: false, checkedEntries: {} }, () => {
            this.filterView(viewIndex);
            const { customViews } = this.state;
            const view = customViews[viewIndex];
            const normalizedColumns = view.normalizedColumns;
            let sortColumn = normalizedColumns[0];
            this.sortData(sortColumn, sortColumn.sortAsc, sortColumn.sortDesc);
        });
    };

    sortData = (column, sortAsc, sortDesc) => {
        const { organizedEntries } = this.state;
        const activeCustomView = this.state.customViews[this.state.activeView];
        const normalizedColumns = activeCustomView.normalizedColumns;
        const updatedColumns = normalizedColumns.map((col) => {
            if (col.key == column.key) {
                col.sortAsc = sortAsc;
                col.sortDesc = sortDesc;
            } else {
                col.sortAsc = false;
                col.sortDesc = false;
            }
            return col;
        });

        let reorganizedData = {};
        reorganizedData[activeCustomView.type] = organizedEntries[activeCustomView.type].sort((a, b) => {
            const columnType = column.type || typeof a[column.key];
            if (a[column.key] && b[column.key]) {
                switch (columnType) {
                    case 'string':
                        return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
                    case 'number':
                        return a[column.key] - b[column.key];
                    case 'currency':
                        return a[column.key] - b[column.key];
                    case 'date':
                    case 'time':
                    case 'timestamp':
                        return new Date(a[column.key]).getTime() - new Date(b[column.key]).getTime();
                    case 'boolean':
                        const a1 = a[column.key] ? 'yes' : 'no';
                        const b1 = b[column.key] ? 'yes' : 'no';
                        return a1.localeCompare(b1);
                    case 'bool':
                        const c1 = a[column.key] ? 'yes' : 'no';
                        const d1 = b[column.key] ? 'yes' : 'no';
                        return c1.localeCompare(d1);
                    default:
                        return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
                }
            } else if (a[column.key]) {
                return 1;
            }
            return -1;
        });
        reorganizedData[activeCustomView.type] = sortDesc ? reorganizedData[activeCustomView.type].reverse() : reorganizedData[activeCustomView.type];
        this.setState({ organizedEntries: reorganizedData, normalizedColumns: updatedColumns });
    };

    s2ab = (s) => {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
    };

    downloadReport = async () => {
        const { activeView, customViews, organizedEntries, allEntries } = this.state;
        const selectedCustomView = customViews[activeView - 1];
        let columns = selectedCustomView.customFieldColumns;
        let defaultFieldTypes = selectedCustomView.defaultFieldTypes;
        if (selectedCustomView) {
            const customColumns = {};
            if (selectedCustomView.columnsOrder && selectedCustomView.columnsOrder.length > 0) {
                let columnsOrder = selectedCustomView.columnsOrder;
                let toggledC = selectedCustomView.toggledColumns;

                columnsOrder.forEach(co => {
                    if (toggledC.includes(co)) {
                        if (columns[co]) {
                            customColumns[co] = columns[co];
                        }
                    }
                })
                columns = {};
                const mainColumn = Object.keys(columns)[0];
                columns[mainColumn] = columns[mainColumn];
                columns = { ...columns, ...customColumns };
            } else {
                //legacy
                selectedCustomView.toggledColumns.forEach((col) => {
                    if (columns[col]) {
                        customColumns[col] = columns[col];
                    }
                });
                columns = {};
                const mainColumn = Object.keys(columns)[0];
                columns[mainColumn] = columns[mainColumn];
                columns = { ...columns, ...customColumns };
            }

        } else {
            const customColumns = {};
            Object.keys(this.state.toggledColumns).forEach((col) => {
                customColumns[col] = columns[col];
            });
            columns = {};
            const mainColumn = Object.keys(columns)[0];
            columns[mainColumn] = columns[mainColumn];
            columns = { ...columns, ...customColumns };
        }
        columns = { ...columns };
        const entries = organizedEntries; //selectedCustomView ? organizedEntries : allEntries;
        const dataRows = [];
        entries.forEach((entry) => {
            const dataRow = {};
            Object.keys(columns).forEach((hc) => {
                if (entry[hc] != null) {
                    if (defaultFieldTypes[hc] === 'boolean' || defaultFieldTypes[hc] === 'bool') {
                        dataRow[hc] = entry[hc] ? 'Yes' : 'No';
                    } else {
                        dataRow[hc] = entry[hc];
                    }
                }
            });
            dataRows.push(dataRow);
        });
        const cleanData = [];
        const headers = Object.keys(columns).map((header, hIndex) => {
            dataRows.forEach((data, dIndex) => {
                if (!cleanData[dIndex]) cleanData[dIndex] = [];
                cleanData[dIndex][hIndex] = data[header] || '';
            });
            return columns[header];
        });
        const data = {
            headerRow: headers,
            dataRows: cleanData
        };

        const reportDetails = selectedCustomView
            ? { Title: selectedCustomView.name, Subject: selectedCustomView.name }
            : { Title: 'View Report', Subject: 'View Report' };
        this.downloadRegular(reportDetails, data);
    };

    downloadRegular = (report, data) => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: report.Title,
            Subject: report.Subject,
            Author: 'Simple Events',
            CreatedDate: new Date()
        };
        wb.SheetNames.push('Data');
        /* make worksheet */
        var ws_data2 = [data.headerRow, ...data.dataRows];
        var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

        /* Add the worksheet to the workbook */
        wb.Sheets['Data'] = ws2;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        saveAs(
            new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
            `${report.Title}_${moment().format('LLL')}.xlsx`
        );
    };

    //every view is a tab on the excel sheet
    downloadFullReport = async () => {
        const { report } = this.props;
        const { customViews, allEntries } = this.state;
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: report.name,
            Subject: report.description,
            Author: 'Simple Events',
            CreatedDate: new Date()
        };
        const data = {};
        customViews.forEach((view) => {
            const columns = view.customFieldColumns;
            const toggledColumns = view.toggledColumns;
            const defaultFieldTypes = view.defaultFieldTypes;
            const aEntries = allEntries[view.type];
            const dataRows = [];
            const filterLayers = view.filterLayers;
            const filteredView =
                filterLayers.length > 0 && filterLayers[0].column != ''
                    ? aEntries.filter((a) => {
                        let valid = true;
                        filterLayers.forEach((layer, index) => {
                            if (a[layer.column] || a[layer.column] == false) {
                                switch (layer.condition) {
                                    case 'is':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                                : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                        break;
                                    case 'isnot':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                                : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                        break;
                                    case 'contains':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid &&
                                                a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                                : valid ||
                                                a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                        break;
                                    case 'doesnotcontain':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid &&
                                                !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                                : valid ||
                                                !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                        break;
                                    case 'isempty':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (!a[layer.column] || a[layer.column] == '')
                                                : valid || (!a[layer.column] || a[layer.column] == '');
                                        break;
                                    case 'isnotempty':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (a[layer.column] && a[layer.column] != '')
                                                : valid || (a[layer.column] && a[layer.column] != '');
                                        break;
                                    case 'isdatelaterthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                                : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                        break;
                                    case 'isdateearlierthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                                : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                        break;
                                    case 'isdateequalto':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                                : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                        break;
                                    case 'isupcoming':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isAfter(moment())
                                                : valid || moment(a[layer.column]).isAfter(moment());
                                        break;
                                    case 'ispast':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isBefore(moment())
                                                : valid || moment(a[layer.column]).isBefore(moment());
                                        break;

                                    case 'istoday':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid &&
                                                moment(a[layer.column]).format('MM/DD/YYYY') ==
                                                moment().format('MM/DD/YYYY')
                                                : valid ||
                                                moment(a[layer.column]).format('MM/DD/YYYY') ==
                                                moment().format('MM/DD/YYYY');
                                        break;
                                    case 'isyes':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (a[layer.column] || a[layer.column] == true)
                                                : valid || (a[layer.column] || a[layer.column] == true);
                                        break;
                                    case 'isno':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (!a[layer.column] || a[layer.column] == false)
                                                : valid || (!a[layer.column] || a[layer.column] == false);
                                        break;
                                    case 'isnumbergreaterthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && parseInt(a[layer.column]) > layer.value
                                                : valid || parseInt(a[layer.column]) > layer.value;
                                        break;
                                    case 'isnumberlessthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && parseInt(a[layer.column]) < layer.value
                                                : valid || parseInt(a[layer.column]) < layer.value;
                                        break;
                                    default:
                                }
                            } else {
                                valid = false;
                            }
                        });
                        return valid;
                    })
                    : aEntries;

            filteredView.forEach((entry) => {
                const dataRow = {};
                Object.keys(columns).forEach((hc, i) => {
                    if (!toggledColumns[hc] && i != 0) return;
                    if (entry[hc] != null) {
                        if (defaultFieldTypes[hc] === 'boolean' || defaultFieldTypes[hc] === 'bool') {
                            dataRow[hc] = entry[hc] ? 'Yes' : 'No';
                        } else if (defaultFieldTypes[hc] === 'timestamp') {
                            dataRow[hc] = moment(entry[hc]).format('MM/DD/YYYY hh:mm A');
                        } else {
                            dataRow[hc] = entry[hc];
                        }
                    }
                });

                dataRows.push(dataRow);
            });

            let headers = Object.keys(columns).map((header, hIndex) => {
                if (!toggledColumns[header] && hIndex != 0) return;
                return columns[header];
            }).filter(h => h);

            let cleanColumns = {}
            Object.keys(columns).forEach((header, hIndex) => {
                if (!toggledColumns[header] && hIndex != 0) return;
                cleanColumns[header] = columns[header];
            })

            let cleanData = [];
            Object.keys(cleanColumns).forEach((header, hIndex) => {
                dataRows.forEach((data, dIndex) => {
                    if (!cleanData[dIndex]) cleanData[dIndex] = [];
                    cleanData[dIndex][hIndex] = data[header] || '';
                });
            })

            data[view._id] = {
                view: view,
                headerRow: headers,
                dataRows: cleanData
            };
        });

        Object.keys(data).forEach((viewId) => {
            const view = data[viewId].view;
            const ws_data2 = [data[viewId].headerRow, ...data[viewId].dataRows];
            const ws2 = XLSX.utils.aoa_to_sheet(ws_data2);
            let tabName = `(${data[viewId].dataRows.length}) ${view.name}`;
            //replace Sheet name cannot contain : \ / ? * [ ]
            const regex = /[\[\]\\\/\?\*\:]/g;
            tabName = tabName.replace(regex, '');
            wb.SheetNames.push(tabName);
            wb.Sheets[tabName] = ws2;
        });

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        saveAs(
            new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
            `${report.name}_${moment().format('LLL')}.xlsx`
        );


    }

    openShare = () => {
        this.setState({ shareModalOpen: true });
    }

    shareReport = (emails) => {

    }
    render() {
        const {
            organizedEntries,
            windowWidth,
            activeView,
            searching,
            searchedEntries,
            searchTerm,
            customViews,
        } = this.state;
        const {
            title,
            report,
        } = this.props;

        const selectedCustomView = customViews[activeView] || {};
        const normalizedColumns = selectedCustomView.normalizedColumns || [];
        const entries = organizedEntries[selectedCustomView.type] || [];
        const sEntries = searchedEntries[selectedCustomView.type] || [];
        const columns = selectedCustomView.customFieldColumns || {};
        const categoryColumns = selectedCustomView.fieldCategories;
        let toggledColumns = { ...selectedCustomView.toggledColumns };
        let cutoff = Math.floor((windowWidth - 270) / 270);
        let views = customViews;
        const overflowViews = views.slice(cutoff);
        const activeCustomViews = views.slice(0, cutoff);
        const activeViewCount = activeCustomViews.length;
        let overflowActive = false;
        if (cutoff > -1 && activeView > cutoff) {
            const activeCustomView = overflowViews[activeView - cutoff];
            overflowActive = true;
            activeCustomViews.push(activeCustomView);
        }
        let entryTable = searching ? sEntries : entries;


        let columnCount = normalizedColumns.length;

        let selectedColumnCount = 0;
        categoryColumns ? Object.keys(categoryColumns).forEach((col, ic) => {
            categoryColumns[col].columns.forEach((k, ik) => {
                const firstField = ic == 0 && ik == 0;
                if (toggledColumns[k] || firstField) {
                    selectedColumnCount++;
                }
                if (columns[k]) {

                    //columnCount++;
                }
            });
        }) : Object.keys(columns).forEach((k) => {
            const firstField = columnCount == 0;
            if (toggledColumns[k] || firstField) {
                selectedColumnCount++;
            }
            if (columns[k]) {
                //columnCount++;
            }
        });
        const viewPins = views.filter(v => report.pins.includes(v._id));
        return (
            <div className={`sectionContainer pb-50`}>
                <div className="sectionHeader ns mb-10">
                    <div className="sectionTitleBar">
                        <div className="sectionTitle w-100">
                            <div className='flex aic'>
                                <h1 className="sectionName">{title}</h1>
                                <button className='ml-15 p-7 fs-16 c-pointer' id="downloadReport" onClick={this.downloadFullReport}>
                                    <i className='las la-download'></i>
                                </button>

                            </div>
                            {report.description && <p className="sectionDescription">{report.description}</p>}
                            {viewPins && viewPins.length > 0 && <div className='flex mt-20'>{viewPins.map((summary, index) => {
                                switch (summary.type) {
                                    case "Attendee":
                                        summary.icon = "las la-user";
                                        break;
                                    case "Session":
                                        summary.icon = "las la-calendar";
                                        break;
                                    case "Group":
                                        summary.icon = "las la-users";
                                        break;
                                    case "Inventory":
                                        summary.icon = "las la-list-alt";
                                        break;
                                    case "Email":
                                        summary.icon = "las la-envelope";
                                        break;
                                    case "Text":
                                        summary.icon = "las la-sms";
                                        break;
                                    case "Form":
                                        summary.icon = "lab la-wpforms";
                                        break;
                                    case "Pages":
                                        summary.icon = "las la-laptop-code";
                                        break;
                                    default:
                                        summary.icon = null;
                                }
                                return <div className='summaryBox' key={`pins-${index}`}>
                                    <div className='number'>{summary.count || 0}</div>
                                    <div className="label">{summary.icon && <i className={`${summary.icon} mr-10`}></i>}{summary.name}</div>
                                </div>
                            })}</div>}
                        </div>
                    </div>
                </div>
                <div className='sectionsContainer'>

                    <div className={`sectionContainer`}>

                        <div className="sectionHeader">
                            <div className="viewTabs">
                                {activeCustomViews &&
                                    activeCustomViews.map((view, index) => {
                                        const viewIndex = index;
                                        view.isPinned = report.pins.includes(view._id);
                                        switch (view.type) {
                                            case "Attendee":
                                                view.icon = "las la-user";
                                                break;
                                            case "Session":
                                                view.icon = "las la-calendar";
                                                break;
                                            case "Group":
                                                view.icon = "las la-users";
                                                break;
                                            case "Inventory":
                                                view.icon = "las la-list-alt";
                                                break;
                                            case "Email":
                                                view.icon = "las la-envelope";
                                                break;
                                            case "Text":
                                                view.icon = "las la-sms";
                                                break;
                                            case "Form":
                                                view.icon = "lab la-wpforms";
                                                break;
                                            case "Pages":
                                                view.icon = "las la-laptop-code";
                                                break;
                                            default:
                                                view.icon = null;
                                        }
                                        return (
                                            <ViewTab
                                                viewIndex={viewIndex}
                                                onClick={() => this.selectView(viewIndex)}
                                                key={`view${view.name}${viewIndex}`}
                                                view={view}
                                                disableOptions={true}
                                                selected={
                                                    activeView > cutoff && viewIndex == activeCustomViews.length ? (
                                                        true
                                                    ) : activeView == viewIndex ? (
                                                        true
                                                    ) : (
                                                        false
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                {overflowViews.length > 0 &&
                                    (!(activeView > activeViewCount && overflowViews.length - 1 == 0) ||
                                        (activeView < activeViewCount && overflowViews.length > 0)) && (
                                        <MoreViewsTab
                                            overflowActive={overflowActive}
                                            overflowViews={overflowViews}
                                            activeView={activeView}
                                            onClick={(index) => this.selectView(index)}
                                            activeCustomViewsCount={activeCustomViews.length}
                                        />
                                    )}
                            </div>

                        </div>

                        <div className="sectionBody isTable p-0">
                            <div className="actionButtonContainer headerHeight flex-ac">

                                <InputField
                                    classes="m-0 viewSearch"
                                    placeholder="Search "
                                    clickIcon={true}
                                    required={true}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        clearTimeout(this.searchTimeout);
                                        this.setState({ searchTerm: e.target.value });
                                        this.searchTimeout = setTimeout(() => this.searchEntries(), 1000);
                                    }}
                                    prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                    inputIcon={searchTerm != '' &&
                                        <i
                                            onMouseDown={() => this.setState({ searchTerm: '', searching: false })}
                                            //onClick={() => this.setState({ searchTerm: '', searching: false })}
                                            className="las la-times-circle"
                                            style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                        />}
                                />
                            </div>
                            <div className='flex h-100 hasHeader'>
                                <ViewsTable
                                    disableCheck={true}
                                    disableLink={true}
                                    columns={normalizedColumns}
                                    toggledColumns={toggledColumns}
                                    data={entryTable}
                                    title={"Data Available"}
                                    noDescription={true}
                                    mainColumnActive={true}
                                    checked={[]}
                                    sortData={this.sortData}
                                    defaultSort={this.props.defaultSort}
                                    sortDirection={this.props.sortDirection}
                                />
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div >
        );
    }
}

export default SharedReportsContainer;
