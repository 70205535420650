import React from 'react';
import API from '../../utils/API';

import InputField from '../inputs/inputField';
import SelectField from '../inputs/selectField';
import FieldModal from './fieldModal';

const fieldTypes = [
	{ label: 'Text', value: 'text' },
	{ label: 'Number', value: 'number' },
	{ label: 'Yes/No', value: 'boolean' },
	{ label: 'Date', value: 'date' },
	{ label: 'Time', value: 'time' },
	{ label: 'Currency', value: 'currency' },
	{ label: 'File', value: 's3file' }
];

class AddCustomFieldModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categorySelects: props.fields.map((field) => {
				return { value: field._id, label: field.title, color: '#00B8D9' };
			}),
			defaultCategorySelects: props.fields.map((field) => {
				return { value: field._id, label: field.title, color: '#00B8D9' };
			}),
			selectedCategory: { label: '', color: '#00B8D9' },
			submitting: false,
			fieldName: this.props.fieldName ? this.props.fieldName : '',
			fieldType: 'text',
			requiredFields: {
				categoryName: false,
				fieldName: false,
				fieldType: false
			},
			fieldExists: false
		};
	}

	handleNameChange = (e) => {
		const newCategory = {
			label: 'New category: ' + e,
			color: '#00B8D9',
			new: true
		};
		this.setState({
			categorySelects: [...this.state.defaultCategorySelects, newCategory]
		});
	};

	handleCategoryChosen = (e) => {
		if (e.new) {
			this.setState({ selectedCategory: { ...e, label: e.label.split('New category: ')[1] } });
		} else {
			this.setState({ selectedCategory: e });
		}
	};

	addCustomField = async (newField) => {
		this.setState({ submitting: true });
		const { orgId, section } = this.props;
		const customField = {
			section: section,
			categoryName: newField.fieldCategory.label || '',
			categoryId: newField.fieldCategory.value || '',
			fieldName: newField.fieldName,
			fieldType: newField.fieldType
		};

		if (this.state.salesforceFieldId && this.state.salesforceFieldId !== '') {
			customField.salesforceField = this.state.salesforceFieldId;
		}
		const requiredFields = this.state.requiredFields;

		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!customField[key] || customField[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		let fields = this.props.fields;
		let category = fields.find(f => f._id == customField.categoryId);
		let fieldExists = false
		if (category) {
			fieldExists = category.fields.some(f => f.fieldName == newField.fieldName);
		}

		if (!fieldExists && valid) {
			this.setState({ submitting: true });
			const endpoint = this.props.eventId
				? `Organizations/${orgId}/events/${this.props.eventId}/customField`
				: this.props.programId ? `Organizations/${orgId}/programs/${this.props.programId}/customField`
					: `Organizations/${orgId}/customFields`;
			await API()
				.post(endpoint, { ...customField, isNotEventField: !this.props.eventId, section: this.props.section })
				.then((res) => {
					if (res.data) {
						this.props.addField(res.data, this.props.toggle);
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields, submitting: false, fieldExists: fieldExists });
		}
	};

	render() {
		const { isOpen, toggle, enableSalesforce } = this.props;
		const { selectedCategory, categorySelects, submitting, fieldName, fieldType, requiredFields, fieldExists } = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add Column"}
					bodyHeaderText={``}
					bodyDescription={``}
					bodyContent={() => {
						return (
							<div>
								<SelectField
									classes={"w-100"}
									label="Category"
									value={selectedCategory}
									style={{ padding: 0 }}
									name="categoryName"
									options={categorySelects}
									required={true}
									errorTrigger={requiredFields.categoryName}
									onChange={this.handleCategoryChosen}
									onInputChange={this.handleNameChange}
								/>
								<InputField
									label="Name"
									value={fieldName}
									required={true}
									errorTrigger={requiredFields.fieldName || fieldExists}
									onChange={(e) => this.setState({ fieldName: e.target.value })}
									errorMessage={fieldExists ? "Field already exists" : requiredFields.fieldName ? 'This field is required' : ""}
								/>

								<SelectField
									classes={"w-100"}
									label="Type"
									type="select"
									value={fieldTypes.find((e) => e.value == fieldType)}
									className="modalInput"
									options={fieldTypes}
									required={true}
									errorTrigger={requiredFields.fieldType}
									onChange={(e) => {
										this.setState({ fieldType: e.value });
									}}
								/>
								{enableSalesforce && (<InputField
									label="Salesforce Field"
									value={this.state.salesforceFieldId}
									onChange={(e) => this.setState({ salesforceFieldId: e.target.value })}
								/>)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.addCustomField({
						fieldCategory: selectedCategory,
						fieldName,
						fieldType
					})}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}

export default AddCustomFieldModal;
