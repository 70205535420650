import React from 'react';

import FieldModal from '../../../components/modals/fieldModal';


class DeleteCalendarsModal extends React.Component {
    state = {
        submitting: false,
        deleteConfirmed: ''
    };
    render() {
        const { isOpen, toggle, count, submit } = this.props;
        const { submitting } = this.state;
        return (

            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={`Delete Event${count == 1 ? '' : 's'}`}
                    bodyHeaderText={<div>{!submitting ? `You are about to delete ${count} request${count == 1 ? '' : 's'}, this is irreversible.` : `${count} request${count == 1 ? '' : 's'} deleted.`}</div>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                    actionButton={() => submit()}
                    actionButtonDisabled={submitting}
                    isDelete={true}
                ></FieldModal>
            </div>
        );
    }
}

export default DeleteCalendarsModal;
