import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DeleteDefaultModal from './modals/deleteDefaultModal';
import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import InputField from '../../../components/inputs/inputField';
import SectionFooter from '../../../components/views/SectionFooter';
import SectionHeader from '../../../components/views/SectionHeader';
class InventoryItem extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const item = props.inventory.find((i) => i._id == params.inventoryId);
		this.props.setActiveRow(params.inventoryId)
		this.state = {
			item: item,
			editedItem: { ...item },
			customFields: {},
			selectedSection: 0,
			DeleteItemModalOpen: false,
			loading: true,
			editing: false,
			requiredFields: {
				name: false
			}
		};
	}
	componentWillUnmount() {
		this.props.setActiveRow(null);
	}

	saveInfo = () => {
		const { editedItem } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedItem[key] || editedItem[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.props.saveItem(editedItem, () => {
				this.setState({ item: { ...editedItem }, editing: false });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	toggleDelete = () => {
		this.setState({ DeleteItemModalOpen: !this.state.DeleteItemModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	editItemField = (property, value) => {
		const editedItem = this.state.editedItem;
		editedItem[property] = value;
		this.setState({ editedItem: editedItem, editing: true });
	};

	cancelEdit = () => {
		const item = this.state.item;
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			requiredFields: requiredFields,
			editedItem: { ...item },
			editing: false
		});
	};

	render() {
		const { DeleteItemModalOpen, loading, editing, item, editedItem, requiredFields } = this.state;
		const { program } = this.props;
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={`${item.name}`}
					titleIcon={<div className="calibreBold fs-30">{item.name.toUpperCase().charAt(0)}</div>}
					enableLastLink={true}
					/* breadcrumbs={[
						{
							name: program.name
						},
						{
							name: 'Event Inventory',
						}
					]} */
					subActions={[{ onClick: this.toggleDelete, label: <span><i className='las la-trash mr-10'></i>Delete Item</span> }]}
					closeButton={true}
				/>
				<div className="sectionBody">
					<div className="sectionBlock">
						<h2 className="blockHeader">Inventory Item Details</h2>
						<InputField
							label="Name"
							errorTrigger={requiredFields.name}
							value={editedItem.name}
							type="text"
							required={true}
							onChange={(e) => this.editItemField('name', e.target.value)}
						/>
						<InputField
							label="Category"
							value={editedItem.category}
							type="text"
							onChange={(e) => this.editItemField('category', e.target.value)}
						/>
						<InputField
							label="Reference Number"
							value={editedItem.referenceNumber}
							type="text"
							onChange={(e) => this.editItemField('referenceNumber', e.target.value)}
						/>
						<InputField
							label="Vendor"
							value={editedItem.vendor}
							type="text"
							onChange={(e) => this.editItemField('vendor', e.target.value)}
						/>
						<InputField
							label="Quantity"
							value={editedItem.quantity}
							type="number"
							onChange={(e) => this.editItemField('quantity', e.target.value)}
						/>
						<InputField
							label="Price"
							value={editedItem.price}
							type="currency"
							onChange={(e) => this.editItemField('price', e.target.value)}
						/>
					</div>
				</div>
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveInfo}
					updatedAt={item.updatedAt} />
				{DeleteItemModalOpen && (
					<DeleteDefaultModal
						isOpen={DeleteItemModalOpen}
						toggle={this.toggleDelete}
						title={`Delete Inventory Item`}
						message={`You are about to delete, this is irreversible,
					would you like to continue?`}
						item={item}
						submit={() => {
							const items = {};
							items[item._id] = true;
							this.props.deleteSingleItem(
								items,
								this.props.history.push('./'),

							);
						}}
					/>
				)}
			</div>
		);
	}
}

export default withRouter(InventoryItem);
