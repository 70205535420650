import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import 'react-toggle/style.css';
class DraggableField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			nestedQuestionsExpanded: false,
			addCustomFieldModalOpen: false
		};
	}

	render() {
		const { index, field, section, focusOnField, focused } = this.props;
		const attendeeFieldType = field.attendeeField ? field.attendeeField.propertyType : 'unattached';

		return (
			<Draggable key={field._id} draggableId={`drag-${field._id}`} index={index}>
				{(provided, snapshot) => (
					<div
						className={`${field.columnClass} fieldDraggable ${focused ? 'focused' : ''}`}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						key={`f${field._id}`}
					>
						<div className="actionBar mr-5">
							<i className="las la-braille" />
						</div>
						<div className="w-100 c-pointer flex-ac flex-sb" onClick={() => focusOnField(field, section)}>
							<div style={{ textAlign: 'left' }}>
								<p className='mb-0'>
									{field.label}
								</p>
								<p className='small m-0'>{field.type && field.type !== 'hidden' ? field.type : 'text'} {field.required ? ' - required' : ''}</p>
							</div>
							<div>

								{attendeeFieldType == 'base' ? (
									<i className="las la-user" />
								) : attendeeFieldType == 'custom' ? (
									<i className="las la-user-cog" />
								) : attendeeFieldType == 'unattached' ? (
									<i className="las la-user-minus" />
								) : (
									''
								)}{' '}
							</div>
						</div>
					</div>
				)}
			</Draggable>
		);
	}
}

export default DraggableField;
