import React, { Fragment } from 'react';

import FieldModal from '../../modals/fieldModal';
import API from '../../../utils/API';


class RenameVenueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            complete: false,
            venue: props.venue || ''

        };
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    submit = async () => {
        const { deleteVenue, venue } = this.props;
        this.setState({ submitting: true })

        await API().delete(`Organizations/${this.props.orgId}/venues/${venue._id}`).then((res) => {

            deleteVenue(venue._id);
            this.setState({ submitting: false, complete: true })

        }).catch((e) => {
            console.log('e', e);

        });



    }

    render() {
        const { isOpen, toggle, } = this.props;
        const { name, submitting, requiredFields, complete, venue } = this.state;
        let venueName = venue ? venue.name : ''
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Venue"}
                    bodyHeaderText={<span>You are about to delete <b>{venueName}</b> venue from the organization. This is irreversible.</span>}
                    bodyDescription={''}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting && !complete ? 'Delete' : submitting && !complete ? 'Deleting...' : complete ? '' : ''}
                    cancelButtonLabel={complete ? 'Close' : 'Cancel'}
                    actionButton={() => this.submit()}
                    actionButtonDisabled={submitting || complete}
                    isDelete={true}

                ></FieldModal>
            </div>

        );
    }
}

export default RenameVenueModal;
