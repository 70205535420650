// import external modules
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
// import internal(own) modules
//import registerServiceWorker from './registerServiceWorker';
import unregister from './registerServiceWorker';
import swConfig from './swConfig';
import 'font-awesome/css/font-awesome.min.css';

import './index.scss';

import LazyApp from './app/app';

Sentry.init({
	dsn: "https://365a71b9ad9e4c4ca32c72f1847099fc@o124651.ingest.sentry.io/4505190786072576",
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	// Performance Monitoring
	tracesSampleRate: .1, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


//<Provider store={store}>
ReactDOM.render(<LazyApp />, document.getElementById('root'));
//registerServiceWorker(swConfig);
unregister();
