import React, { Component } from 'react';

import API from '../../../utils/API';
import FieldModal from '../../modals/fieldModal';

class DeleteViewModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false
		};
	}

	deleteView = () => {
		const { eventId, orgId, reportId } = this.props;
		this.setState({ submitting: true });
		let url = `/Organizations/${orgId}/views/${this.props.view._id}`;
		if (reportId) {
			url = `/Organizations/${orgId}/events/${eventId}/viewReports/${reportId}/${this.props.view._id}`
		}
		API()
			.delete(url)
			.then((res) => {
				this.setState({ submitting: false });
				if (res.data.success) {
					this.props.removeView(this.props.view._id);
					this.props.toggle();
				}
			})
			.catch((err) => {
				this.setState({ submitting: false });
				console.log(err);
			});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete view"}
					bodyHeaderText={'Are you sure you want to delete this view? This can not be undone.'}
					bodyDescription={''}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.deleteView()}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default DeleteViewModal;
