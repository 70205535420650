import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../inputs/inputField';
import SelectField from '../inputs/selectField';

const conditions = {
	date: [
		{
			label: 'Is later than',
			value: 'isdatelaterthan'
		},
		{
			label: 'Is earlier than',
			value: 'isdateearlierthan'
		},
		{
			label: 'Is specific date',
			value: 'isdateequalto'
		},
		{
			label: 'Is upcoming',
			value: 'isupcoming'
		},
		{
			label: 'Is past',
			value: 'ispast'
		},
		{
			label: 'Is today',
			value: 'istoday'
		}
	],
	bool: [
		{
			label: 'Is Yes',
			value: 'isyes'
		},
		{
			label: 'Is No',
			value: 'isno'
		}
	],
	boolean: [
		{
			label: 'Is Yes',
			value: 'isyes'
		},
		{
			label: 'Is No',
			value: 'isno'
		}
	],

	time: [
		{
			label: 'Is later than',
			value: 'isdatelaterthan'
		},
		{
			label: 'Is earlier than',
			value: 'isdateearlierthan'
		},
		{
			label: 'Is specific date',
			value: 'isdateequalto'
		},
		{
			label: 'Is upcoming',
			value: 'isupcoming'
		},
		{
			label: 'Is past',
			value: 'ispast'
		}
	],

	number: [
		{
			label: 'Is',
			value: 'is'
		},
		{
			label: 'Is not',
			value: 'isnot'
		},

		{
			label: 'Is empty',
			value: 'isempty'
		},
		{
			label: 'Is not empty',
			value: 'isnotempty'
		},
		{
			label: 'Is less than',
			value: 'isnumberthan'
		},
		{
			label: 'Is greater than',
			value: 'isnumbergreaterthan'
		}
	],
	currency: [
		{
			label: 'Is',
			value: 'is'
		},
		{
			label: 'Is not',
			value: 'isnot'
		},

		{
			label: 'Is empty',
			value: 'isempty'
		},
		{
			label: 'Is not empty',
			value: 'isnotempty'
		},
		{
			label: 'Is less than',
			value: 'isnumberthan'
		},
		{
			label: 'Is greater than',
			value: 'isnumbergreaterthan'
		}
	],
	timestamp: [
		{
			label: 'Is later than',
			value: 'isdatelaterthan'
		},
		{
			label: 'Is earlier than',
			value: 'isdateearlierthan'
		},
		{
			label: 'Is specific date',
			value: 'isdateequalto'
		},
		{
			label: 'Is upcoming',
			value: 'isupcoming'
		},
		{
			label: 'Is past',
			value: 'ispast'
		}
	],

	default: [
		{
			label: 'Is',
			value: 'is'
		},
		{
			label: 'Is not',
			value: 'isnot'
		},
		{
			label: 'Contains',
			value: 'contains'
		},
		{
			label: 'Does not contain',
			value: 'doesnotcontain'
		},
		{
			label: 'Is empty',
			value: 'isempty'
		},
		{
			label: 'Is not empty',
			value: 'isnotempty'
		}
	]
};

const layerConcatOptions = [
	{
		label: 'And',
		value: 'and'
	},
	{
		label: 'Or',
		value: 'or'
	}
];

const eventTypeOptions = [{ label: 'Public', value: 'Public' }, { label: 'Invite Only', value: 'Invite Only' }];
//set column dropdown from props, conditions dropdown and value textbox
class ColumnFilterRow extends Component {
	constructor(props) {
		super(props);
		const selectedColumn = props.selectedColumn && props.columns.find(
			(col) => col.value.toLowerCase() == props.selectedColumn.toLowerCase()
		) || { type: 'text' };

		const conditionType = conditions[selectedColumn.type] ? selectedColumn.type : 'default'; //handles data as text
		this.state = {
			selectedColumn: (props.selectedColumn && typeof props.selectedColumn == 'string' && selectedColumn) || '',
			selectedCondition:
				(props.selectedCondition &&
					typeof props.selectedCondition == 'string' &&
					conditions[conditionType].find(
						(cond) => cond.value.toLowerCase() == props.selectedCondition.toLowerCase()
					)) ||
				'',
			filterValue: props.filterValue || '',
			selectedConcatOption:
				(props.selectedConcatOption &&
					layerConcatOptions.find(
						(concat) => concat.value.toLowerCase() == props.selectedConcatOption.toLowerCase()
					)) ||
				layerConcatOptions[0]
		};
	}

	updateFilterValue(value) {
		this.setState(
			{
				filterValue: value
			},
			() => {
				this.props.updateFilter({
					concatOperator: this.state.selectedConcatOption.value,
					column: this.state.selectedColumn.value,
					condition: this.state.selectedCondition.value,
					value: value
				});
			}
		);
	}

	updateFilterCondition(condition) {
		this.setState(
			{
				selectedCondition: condition,
				filterValue: ''
			},
			() => {
				if (
					this.state.filterValue != '' ||
					this.state.selectedCondition.value == 'isempty' ||
					this.state.selectedCondition.value == 'isnotempty' ||
					this.state.selectedCondition.value == 'isupcoming' ||
					this.state.selectedCondition.value == 'ispast' ||
					this.state.selectedCondition.value == 'istoday' ||
					this.state.selectedCondition.value == 'isyes' ||
					this.state.selectedCondition.value == 'isno'
				) {
					this.props.updateFilter({
						concatOperator: this.state.selectedConcatOption.value,
						column: this.state.selectedColumn.value,
						condition: condition.value,
						value: this.state.filterValue
					});
				}
			}
		);
	}

	updateFilterColumn(column) {
		this.setState(
			{
				selectedColumn: column,
				selectedCondition: '',
				filterValue: ''
			},
			() => {
				if (
					this.state.filterValue != '' ||
					this.state.selectedCondition.value == 'isempty' ||
					this.state.selectedCondition.value == 'isnotempty'
				) {
					this.props.updateFilter({
						concatOperator: this.state.selectedConcatOption.value,
						column: column.value,
						condition: this.state.selectedCondition,
						value: this.state.filterValue
					});
				}
			}
		);
	}

	updateFilterConcatOperator = (operator) => {
		this.setState(
			{
				selectedConcatOption: operator
			},
			() => {
				if (
					this.state.filterValue != '' ||
					this.state.selectedCondition.value == 'isempty' ||
					this.state.selectedCondition.value == 'isnotempty'
				) {
					this.props.updateFilter({
						concatOperator: operator.value,
						column: this.state.selectedColumn.value,
						condition: this.state.selectedCondition.value,
						value: this.state.filterValue
					});
				}
			}
		);
	};

	clearFilter = () => {
		this.props.removeFilter();
		/* if (firstFilter && filterCount == 1) {
			this.setState(
				{
					selectedColumn: '',
					selectedCondition: '',
					filterValue: '',
					concatOperator: 'and'
				},
				() => {
					this.props.updateFilter({
						concatOperator: this.state.selectedConcatOption.value,
						column: this.state.selectedColumn,
						condition: this.state.selectedCondition,
						value: this.state.filterValue
					});
				}
			);
		} else {
			this.props.removeFilter();
		} */
	};

	render() {
		const { selectedConcatOption, selectedColumn, selectedCondition, filterValue } = this.state;
		const { columns, firstFilter } = this.props;
		let valueOptions = [];
		let valueType = 'string';
		if (selectedColumn && selectedColumn.value) {
			valueType = selectedColumn.type == 'hidden' ? 'string' : selectedColumn.type == 'timestamp' ? 'date' : selectedColumn.type;
			if (selectedColumn.value == 'eventType') {
				valueOptions = eventTypeOptions;
				valueType = 'select';
			}
		}
		const conditionType = conditions[selectedColumn.type] ? selectedColumn.type : 'default'; //handles data as text

		return (
			<div className="filterLayer">
				{firstFilter ? (
					<p className="whereText">Where</p>
				) : (
					<SelectField
						value={selectedConcatOption}
						classes="neu mr-10 mb-0"
						style={{ maxWidth: 70 }}
						relative={true}
						classNamePrefix="select"
						name="concatCondition"
						options={layerConcatOptions}
						onChange={(selectedConcatOption) => {
							this.updateFilterConcatOperator(selectedConcatOption);
						}}
					/>
				)}
				<div className="filterLayerContent">
					<SelectField
						classNamePrefix="select"
						relative={true}
						classes="neu mr-10 w-100 mb-0"
						name="column"
						value={selectedColumn}
						options={columns}
						onChange={(selectedColumn) => {
							this.updateFilterColumn(selectedColumn);
						}}
						placeholder="Field"
					/>

					<SelectField
						classNamePrefix="select"
						name="condition"
						relative={true}
						classes="neu mr-10 w-100 mb-0"
						value={selectedCondition}
						options={conditions[conditionType]}
						onChange={(selectedCondition) => {
							this.updateFilterCondition(selectedCondition);
						}}
						isDisabled={selectedColumn == ''}
						placeholder="Condition"
					/>

					{selectedColumn.type == 'bool' ||
						selectedColumn.type == 'boolean' ||
						(selectedCondition.value != 'isempty' &&
							selectedCondition.value != 'isnotempty' &&
							selectedCondition.value != 'isupcoming' &&
							selectedCondition.value != 'ispast' &&
							selectedCondition.value != 'istoday' && (
								<InputField
									classes={`mb-0 ${selectedCondition.value == 'isempty' ||
										selectedCondition.value == 'isnotempty'
										? 'invisible'
										: ''}`}
									placeholder="Value"
									value={filterValue}
									type={valueType}
									style={{ pointerEvents: selectedCondition != '' ? 'auto' : 'none' }}
									autoFocus={true}
									onChange={(e) => this.updateFilterValue(e.target.value)}
									inputIcon={valueType == 'date' ? <i className="lar la-calendar" /> : null}
								/>
							))}

					{/* Times button  */}
					<button className='ml-5 p-7' onClick={this.clearFilter}><i
						className={`las la-times`}

					/>
					</button>
				</div>
			</div>
		);
	}
}

export default ColumnFilterRow;
