import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';
import { withRouter } from 'react-router-dom';

class CreateTemplateFromCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.email.name ? this.props.email.name : '',
			submitting: false,
			requiredFields: { name: false }
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	submit = async () => {
		this.setState({ submitting: true });
		const { name } = this.state;
		const { eventId, orgId, email, noRedirect, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		if (!name) {
			requiredFields.name = true;
			valid = false;
		}

		if (valid) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/template`, { name: name, emailId: email._id })
				.then((res) => {
					if (res.data) {
						this.props.addTemplate(res.data);
						!noRedirect ? this.props.history.push(`/${orgId}/events/${eventId}/emails`) : toggle();
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, requiredFields } = this.state;

		return (
			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Create Template from Campaign"}
					bodyHeaderText={'Enter a new name for your template.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Name"
									value={name}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Submitting...'}
					actionButton={() => this.submit()}
					actionButtonDisabled={submitting}


				></FieldModal>






			</div>

		);
	}
}

export default withRouter(CreateTemplateFromCampaignModal);
