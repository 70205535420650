import React, { Component, createRef } from 'react';
import { Input } from 'reactstrap';

class ColumnFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			popupOpen: false,
			columnAsc: false,
			columnDesc: false,
			searchText: '',
			optionsAvailable: [],
			selectedOptions: []
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ popupOpen: false });
		}
	}

	handleColumnAsc = () => {
		this.props.sortData(this.props.column, !this.props.column.sortAsc, false);
	};

	handleColumnDesc = () => {
		this.props.sortData(this.props.column, false, !this.props.column.sortDesc);
	};

	handleColumnSearch = (e) => {
		this.props.searchData(this.props.column, e.target.value);
	};

	render() {
		const { optionsAvailable, selectedOptions, popupOpen } = this.state;
		const { search, sortAsc, sortDesc } = this.props.column;
		return (
			<div className="columnFilter" style={style.columnFilter} ref={this.setWrapperRef}>
				<div
					className="columnFilterIcon"
					style={style.columnFilterIcon}
					onClick={() => this.setState({ popupOpen: !popupOpen })}
				>
					{search != '' && <i className="las la-filter" />}
					{sortAsc != '' && <i className="las la-sort-alpha-down" />}
					{sortDesc != '' && <i className="las la-sort-alpha-down-alt" />}
					{popupOpen ? (
						<i className="las la-angle-up" style={style.icon} />
					) : (
						<i className="las la-angle-down" style={style.icon} />
					)}
				</div>
				{popupOpen && (
					<div className="columnFilterPopout" style={style.columnFilterPopout}>
						<div style={style.actionLabel}>Filter</div>
						<div className="w-100 flex-ac mb-10">
							<input
								value={search}
								placeholder="search"
								style={style.optionSearch}
								onChange={this.handleColumnSearch}
							/>
							{search.length > 0 && (
								<div
									style={style.clearLabel}
									onClick={() => this.props.searchData(this.props.column, '')}
								>
									<i className="las la-times-circle" />
								</div>
							)}
						</div>
						{/* <div style={style.actionLabel}>Sort</div> */}
						<div className="flex-ac" style={style.sortContainer}>
							<div
								style={sortAsc ? style.sortButtonSelected : style.sortButton}
								onClick={this.handleColumnAsc}
							>
								Sort A - Z
							</div>
							<div
								style={sortDesc ? style.sortButtonSelected : style.sortButton}
								onClick={this.handleColumnDesc}
							>
								Sort Z - A
							</div>
						</div>
						<div className="columnFilterOptions">
							{optionsAvailable.map((oa) => {
								return <div>{oa}</div>;
							})}
						</div>
					</div>
				)}
			</div>
		);
	}
}

const style = {
	columnFilter: {
		display: 'flex',
		marginLeft: 'auto',
		zIndex: 100
	},
	columnFilterIcon: {
		background: ' #e6e6e6',
		padding: 3,
		borderRadius: 5,
		height: 20,
		fontSize: 12,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		lineHeight: 1,
		cursor: 'pointer'
	},
	columnFilterPopout: {
		position: 'absolute',
		background: 'white',
		marginTop: 30,
		padding: 10,
		boxShadow: '0 0 10px #bbb',
		width: 200,
		marginLeft: -140,
		cursor: 'auto'
	},
	optionSearch: {
		width: '100%',
		background: 'white',
		border: '1px solid #bbb',
		fontSize: 14,
		paddingRight: 20
	},
	icon: {
		pointerEvents: 'none'
	},
	actionLabel: {
		fontSize: 12,
		fontWeight: 700
	},
	clearLabel: {
		fontSize: 14,
		fontWeight: 700,
		color: '#2a82f0',
		cursor: 'pointer',
		textAlign: 'center',
		position: 'absolute',
		right: 15
	},
	sortContainer: {
		justifyContent: 'space-between'
	},
	sortButton: {
		flexWeight: 1,
		border: '1px solid #bbb',
		color: '#bbb',
		padding: '2px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#fff',
		fontSize: 14,
		userSelect: 'none',
		cursor: 'pointer',
		width: '48%',
		borderRadius: 5
	},
	sortButtonSelected: {
		flexWeight: 1,
		border: '1px solid #2a82f0',
		padding: '2px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 14,
		color: '#2a82f0',
		userSelect: 'none',
		cursor: 'pointer',
		width: '48%',
		borderRadius: 5
	}
};

export default ColumnFilter;
