import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';

class SessionRemoveSpeakerModal extends React.Component {
	state = {
		submitting: false
	};

	removeModerator = async () => {
		const { eventId, orgId, session, checkedModerators } = this.props;
		this.setState({ submitting: true })
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				moderators: checkedModerators,
				request: 'removeModerator'
			})
			.then((res) => {
				if (res.data) {
					const moderators = res.data.moderators.map((m) => this.props.attendees.find((at) => at._id == m));
					this.props.updateModerators(moderators);
					this.props.uncheckEntries();
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, checkedModerators, toggle } = this.props;
		const { submitting } = this.state;

		let checkedModeratorsLength = checkedModerators.length;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete Moderator${checkedModeratorsLength != 1 ? 's' : ''}`}
					bodyHeaderText={<span>You are about to delete{' '}
						<b>
							{checkedModeratorsLength} moderator
							{checkedModeratorsLength != 1 ? 's' : ''}
						</b>{' '}
						from this session, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.removeModerator()}
					isDelete={true}
					actionButtonDisabled={submitting}

				></FieldModal>
			</div>
		);
	}
}

export default SessionRemoveSpeakerModal;
