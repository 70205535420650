// resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

import cookie from 'js-cookie';

const getCookieFromBrowser = (key) => cookie.get(key);

const getCookieFromServer = (key, req) => {
	if (!req.headers.cookie) {
		return undefined;
	}
	const rawCookie = req.headers.cookie.split(';').find((c) => c.trim().startsWith(`${key}=`));

	if (!rawCookie) {
		return undefined;
	}
	return rawCookie.split('=')[1];
};

export const setCookie = (key, value) => {
	cookie.set(key, value, {
		expires: 7,
		path: '/',
		domain: window.location.hostname
	});
};

export const removeCookie = (key) => {
	console.log('Removing cookie:', key);
	cookie.remove(key, {
		expires: 7,
		path: '/',
		domain: window.location.hostname
	});
	// cookie.remove(key);
};

export const getCookie = (key, req) => getCookieFromBrowser(key);
