import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import Form from './form';
import CreateFormModal from './modals/createForm';
import DeleteFormModal from './modals/deleteFormModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import LoadingWizard from '../../../components/spinner/wizard';
import SectionHeader from '../../../components/views/SectionHeader';
import Iframe from 'react-iframe';

import FormsTemplateCenter from './modals/FormsTemplateCenter';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
let batchCallback;
class EventForms extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			type: 'Type',
			submissions: 'Submissions',
			enableSubmissionReview: 'Confirmation Required',
			confirmationEmail: 'Confirmation Email',
			pageCount: 'Page Count',
			createdAt: 'Created At',
			updatedAt: 'Updated At'
		};

		const defaultFieldTypes = {
			name: 'text',
			type: 'text',
			description: 'text',
			status: 'text',
			submissions: 'number',
			enableSubmissionReview: 'boolean',
			confirmationEmail: 'text',
			pageCount: 'number',
			createdAt: 'date',
			updatedAt: 'date'
		};
		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'type',
					'description',
					'status',
					'submissions',
					'enableSubmissionReview',
					'confirmationEmail',
					'pageCount',
					'createdAt',
					'updatedAt',
				]
			},
		};



		this.state = {
			categoryColumns: fieldCategories,
			loading: true,
			checkedForms: [],
			addFormModalOpen: false,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			deletedForms: [],
			formsViews: [],
			forms: [],
			availableEmails: [],
			deleteFormsModalOpen: false,
			isDeleting: false,
			toggleAddForm: false,
			templates: [],
			templatesHidden: true,
			hoverOn: null,
			toggleFormTemplateCenter: false,

		};
	}

	async componentDidMount() {
		const { orgId, eventId, event } = this.props;

		await API().get(`Organizations/${orgId}/events/${eventId}/templates`).then((res) => {
			if (res.data) {
				const metadataOptions = [];
				{
					Object.keys(res.data.keyedData).forEach((key) => {
						const layer1 = res.data.keyedData[key];
						if (typeof layer1 === 'object') {
							Object.keys(layer1).forEach((key2) => {
								const layer2 = layer1[key2];
								if (typeof layer2 === 'object') {
									Object.keys(layer2).forEach((key3) => {
										if (
											key !== '_defaultLabel' &&
											key2 !== '_defaultLabel' &&
											key3 !== '_defaultLabel'
										) {
											metadataOptions.push(`${key}.${key2}.${key3}`);
										}
									});
								} else {
									if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
										metadataOptions.push(`${key}.${key2}`);
									}
								}
							});
						} else {
							if (key !== '_defaultLabel') {
								metadataOptions.push(`${key}`);
							}
						}
					});
				}
				this.setState({
					keyedMetadata: metadataOptions,
					keyedData: res.data.keyedData,
					availableEmails: [
						{ label: 'No Email', value: '' },
						...res.data.templates.map((e) => {
							return {
								value: e._id,
								label: e.name
							};
						})
					],

				});
			}
		});


		API().get(`Organizations/${orgId}/events/${eventId}/forms`).then((res) => {
			if (res.data) {
				this.setState({
					forms: res.data.forms.map((f) => {
						if (f.confirmationEmail && f.confirmationEmail !== '') {
							let email = this.state.availableEmails.find(e => {
								let campaignId = e.value;
								let formConfirmationEmail = f.confirmationEmail;
								if (campaignId === formConfirmationEmail) {
									return e;
								}
							});

							if (email) {
								f.confirmationEmail = email.label;

							} else {

								f.confirmationEmail = 'N/A';
							}


						} else {
							f.confirmationEmail = 'N/A';

						}
						f.link = `/${orgId}/events/${eventId}/forms/${f._id}`;
						return f;
					}),
					formsViews: res.data.formsViews,
					loading: false,
					formTemplates: res.data.formTemplates,

				});
			}
		});
	}



	deleteCheckedForms = async (forms) => {
		this.setState({ isDeleting: true });
		const { eventId, orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/forms/`, {
				forms: Object.keys(this.state.checkedForms),
				isDeleting: false
			})
			.then((res) => {
				if (res.data) {
					const filteredForms = this.state.forms.filter((f) => !this.state.checkedForms[f._id]);
					this.setState({
						forms: filteredForms
					}, () => {
						batchCallback && batchCallback('delete');
						this.props.refreshSectionCounts();
						this.toggleDeleteForms();
					});

				}
			});

		this.setState({
			checkedForms: {}
		});
	};
	deleteSingleForm = async (formId, callback) => {
		const { eventId, orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/forms/`, {
				forms: [formId],
				isDeleting: false
			})
			.then((res) => {
				if (res.data) {
					const filteredForms = this.state.forms.filter((f) => f._id !== formId);
					this.setState({
						forms: filteredForms
					}, () => {
						this.props.refreshSectionCounts();
						callback && callback();

					});

				}
			});

		this.setState({
			checkedForms: {}
		});
	};

	toggleDeleteForms = (checkedForms, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedForms: checkedForms || {},
			deleteFormsModalOpen: !this.state.deleteFormsModalOpen
		});
	};
	toggleAddForm = () => {

		const { toggleAddForm } = this.state;
		this.setState({ toggleAddForm: !toggleAddForm })

	}

	hidetemplates = () => {
		this.setState({ templatesHidden: !this.state.templatesHidden });
	};

	addForm = (form, callback) => {
		const { eventId, orgId } = this.props;
		const { forms } = this.state;
		let fixedForm = form;
		fixedForm.link = `/${orgId}/events/${eventId}/forms/${form._id}`;
		fixedForm.submissions = fixedForm.submissions.length;
		if (!fixedForm.confirmationEmail) {
			fixedForm.confirmationEmail = 'N/A';
		} else {

			let email = this.state.availableEmails.find(e => {
				let campaignId = e.value;
				let formConfirmationEmail = fixedForm.confirmationEmail;
				if (campaignId === formConfirmationEmail) {
					return e;
				}
			});

			if (email) {
				fixedForm.confirmationEmail = email.label;

			} else {

				fixedForm.confirmationEmail = 'N/A';
			}
		}


		this.setState({ forms: [...forms, fixedForm] }, () => {

			this.props.refreshSectionCounts();
			callback && callback();
		})


	}
	updateForm = (form) => {
		const { eventId, orgId } = this.props;
		const { forms } = this.state;
		let fixedForm = form;
		fixedForm.link = `/${orgId}/events/${eventId}/forms/${form._id}`;
		fixedForm.submissions = fixedForm.submissions.length;
		fixedForm.pageCount = fixedForm.pages.length;
		if (!fixedForm.confirmationEmail) {
			fixedForm.confirmationEmail = 'N/A';
		} else {
			let email = this.state.availableEmails.find(e => {
				let campaignId = e.value;
				let formConfirmationEmail = fixedForm.confirmationEmail;
				if (campaignId === formConfirmationEmail) {
					return e;
				}
			});

			if (email) {
				fixedForm.confirmationEmail = email.label;

			} else {

				fixedForm.confirmationEmail = 'N/A';
			}

		}


		let updatedForms = forms.map(f => {
			if (f._id === form._id) {
				return fixedForm;
			} else {
				return f;
			}
		})
		this.setState({ forms: updatedForms }, () => {

			this.props.refreshSectionCounts();
		})
	}

	toggleFormTemplateCenter = () => {
		this.setState({ toggleFormTemplateCenter: !this.state.toggleFormTemplateCenter });
	}


	render() {
		const { event, orgId, eventId, readOnly } = this.props;
		const { forms, categoryColumns, deletedForms, loading, columns, isDeleting, columnTypes, formsViews, deleteFormsModalOpen, toggleAddForm, keyedMetadata, keyedData, toggleFormTemplateCenter, formTemplates } = this.state;
		return <Fragment>
			<Switch>
				<Route path={`/:organization/events/:eventId/forms/:formId`}>
					<Fragment>

						{!loading && (<Form
							event={this.props.event}
							account={this.props.account}
							eventId={eventId}
							readOnly={readOnly}
							orgId={this.props.orgId}
							keyedMetadata={keyedMetadata}
							keyedData={keyedData}
							forms={forms}
							fields={this.props.fields}
							addNewField={this.props.addNewField}
							availableEmails={this.state.availableEmails}
							groups={this.props.groups.map(g => {
								return { value: g._id, label: g.name, }
							})}
							updateForm={this.updateForm}
							updatePathOptions={this.props.updatePathOptions}
							deleteSingleForm={this.deleteSingleForm}
						/>)}
						<LoadingWizard text={'Loading Form'} loading={loading} />
					</Fragment>
				</Route>
				<Route path={`/:organization/events/:eventId/forms`}>

					<Fragment>
						{!loading && (<Fragment>

							<ViewsContainer
								categoryColumns={categoryColumns}
								title="Forms"
								description="Create and manage registration and check-in forms. Add forms to pages to create a custom registration website."
								breadcrumbs={[
									{
										name: event.name
									}]}
								orgId={orgId}
								columns={columns}
								columnTypes={columnTypes}
								views={formsViews}
								entries={forms}
								deletedEntries={deletedForms}
								readOnly={false}
								defaultSort={'name'}
								mainActions={[
									{ label: 'Add Form', onClick: this.toggleFormTemplateCenter }
								]}
								batchActions={[
									{
										label: 'Delete',
										iconClass: 'las la-trash-alt',
										class: 'danger',
										onClick: this.toggleDeleteForms
									}
								]}
								enableDownloadViews={true}
								type={'Form'}
								referenceCollection={'Events'}
								referenceId={eventId}
							/></Fragment>)}
						<LoadingWizard text="Loading Forms" loading={loading} />
						{toggleFormTemplateCenter && (
							<FormsTemplateCenter
								isOpen={toggleFormTemplateCenter}
								orgId={orgId}
								templates={[]}//localTemplates
								platformTemplates={this.state.formTemplates}
								event={event}
								keyedMetadata={keyedMetadata}
								toggle={this.toggleFormTemplateCenter}
								addForm={this.addForm}
								account={this.props.account}
								eventId={eventId}
								availableEmails={this.state.availableEmails}
							/>
						)}
						{toggleAddForm &&
							<CreateFormModal
								event={this.props.event}
								eventId={eventId}
								readOnly={readOnly}
								orgId={this.props.orgId}
								refreshSectionCounts={this.props.refreshSectionCounts}
								toggle={this.toggleAddForm}
							/>
						}
						{deleteFormsModalOpen && (
							<DeleteFormModal
								isOpen={deleteFormsModalOpen}
								delete={this.deleteCheckedForms}
								isDeleting={isDeleting}
								toggle={this.toggleDeleteForms}
								count={Object.keys(this.state.checkedForms).length}
								isMultiple={true}
							/>
						)}
						{toggleAddForm &&
							<CreateFormModal
								isOpen={toggleAddForm}
								event={this.props.event}
								availableEmails={this.state.availableEmails}
								eventId={eventId}
								readOnly={readOnly}
								orgId={this.props.orgId}
								addForm={this.addForm}
								toggle={this.toggleAddForm}
							/>
						}
					</Fragment>

				</Route>

			</Switch>
		</Fragment>;
	}
}

export default withRouter(EventForms);
