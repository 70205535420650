import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import CodeEditor from '../../../components/codeEditor/codeEditor';

class CustomStyling extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { updateStyling, eventCustomCSS, readOnly, style, language="css" } = this.props;
		return <CodeEditor style={style} content={eventCustomCSS} language={language} disabled={readOnly} setContent={updateStyling} />;
	}
}
export default CustomStyling;
