import React, { useState, useEffect, useRef, Component } from 'react';
import Prism from 'prismjs';
import { EditorState, ContentState, convertToRaw, Modifier, Entity, RichUtils } from 'draft-js';

import { Editor } from 'react-draft-wysiwyg';
import SelectField from '../inputs/selectField';
import draftToHtml from 'draftjs-to-html';



class CustomFieldMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedField: null
        };
    }

    render() {
        const { customFields, label } = this.props;
        return (
            <SelectField
                label={label}
                placeholder="Custom Fields"
                value={this.state.selectedField}
                options={customFields}
                onChange={(val) => {

                    this.props.onChange(val);
                }}
            />
        );
    }
}

const FieldsCodeEditor = (props) => {
    const { content, setContent } = props;
    const [lastIndex, setLastIndex] = useState(0);
    const mounted = useRef(false);


    let sidebarScroll = React.createRef();
    let contentScroll = React.createRef();
    let _preventEvent = false;
    let contentLastScrollTop = 0;
    let contentLastScrollLeft = 0;

    const onSidebarScroll = (e) => {
        if (_preventEvent) {
            _preventEvent = false;
            return;
        }

        _preventEvent = true;
        contentScroll.current.scrollTop = e.target.scrollTop;
        contentScroll.current.scrollLeft = e.target.scrollLeft;
    };

    const onContentScroll = (e) => {
        if (_preventEvent) {
            _preventEvent = false;
            return;
        }

        if (e.target.scrollTop !== contentLastScrollTop) {
            _preventEvent = true;
            sidebarScroll.current.scrollTop = e.target.scrollTop;
            contentLastScrollTop = e.target.scrollTop;
        }
        if (e.target.scrollLeft !== contentLastScrollLeft) {
            _preventEvent = true;
            sidebarScroll.current.scrollLeft = e.target.scrollLeft;
            contentLastScrollLeft = e.target.scrollLeft;
        }
    };

    const handleKeyDown = async (evt) => {
        let value = content,
            selStartPos = evt.currentTarget.selectionStart;
        // handle 4-space indent on
        if (evt.key === 'Tab') {
            evt.persist();
            evt.preventDefault();
            value = value.substring(0, selStartPos) + '    ' + value.substring(selStartPos, value.length);
            await setContent(value);
            evt.target.selectionStart = selStartPos + 4;
            evt.target.selectionEnd = selStartPos + 4;
        }
    };

    const insertShortCode = (shortCodeObj) => {
        let fixedContent = content;
        fixedContent = fixedContent.slice(0, lastIndex) + shortCodeObj.value + fixedContent.slice(lastIndex);
        setContent(fixedContent)
    }

    useEffect(
        () => {
            Prism.highlightAll();
            setContent(content.replace(/	/g, '    '), true )

        },
        [props.language, content]
    );

    return (
        <div className='h-100'>
            <div className='mb-10 mt-20'>
                <CustomFieldMap
                    label="Select a custom field to insert into the code at current cursor position"
                    customFields={props.customFields} onChange={(val) => {
                        insertShortCode(val)
                    }} />
            </div>
            <div className="code-edit-container" style={props.style}>
                <textarea
                    ref={sidebarScroll}
                    onScroll={onSidebarScroll}
                    className="code-input"
                    value={content}
                    disabled={props.disabled}
                    onChange={(evt) => {
                        setContent(evt.target.value.replace(/\t/g, '    '));
                    }}
                    onKeyDown={handleKeyDown}
                    onKeyUp={(event) => {
                        setLastIndex(event.target.selectionStart)
                    }}
                    onFocus={(evt) => {
                    }}
                    onBlur={() => {
                    }}
                    onMouseDown={(evt) => {
                    }}
                    onMouseUp={(evt) => {
                        setLastIndex(evt.target.selectionStart)
                    }

                    }
                />

                <pre className="code-output" ref={contentScroll} onScroll={onContentScroll}>
                    <code className={`language-${props.language}`}>{content}</code>
                </pre>
            </div>

        </div>

    );
};

export default FieldsCodeEditor;
