import React, { Fragment } from 'react';
import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

class PublishPageModal extends React.Component {
	constructor(props){
		super(props);
		this.state={
			submitting: false,
		};
	}
	
	render() {
		const { isOpen, toggle, publishPage } = this.props;
		
		const { submitting,} = this.state;
	
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Error"}
					bodyHeaderText={' Please complete basic information and create a design before a page can be published.'}
					bodyDescription={''}
					bodyContent={() => {
                        return ('')
                    }}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'OK' : 'OK...'}
					actionButton={() => publishPage(false, toggle)}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}

export default PublishPageModal;
