import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import TextAreaField from '../../../../components/inputs/textAreaField';
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}
class AddBccModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			invalid: false,
			error: '',
			ccEmails: this.props.email.bcc.toString().replace(/,/g, ', '),
			requiredFields: {},
			invalidBcc: false
		};
	}

	handleCCEmailsChange = (e) => {
		this.setState({
			ccEmails: e.target.value,
			editing: true
		});
	};

	updateFrom = async () => {
		this.setState({ submitting: true });
		const { fromName, fromEmail, ccEmails } = this.state;
		const { eventId, orgId, email, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		let bccEmails = ccEmails == '' ? [] : ccEmails.replace(/ /g, '').split(',');

		let validbcc = true;

		bccEmails = bccEmails.map((e) => {
			if (!ValidateEmail(e) || '') {
				validbcc = false;
			}

			if (e !== '') return e;
		});

		if (validbcc || bccEmails.toString() == '') {
			this.setState({ invalidBcc: false });
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${email._id}`, {
					name: email.name,
					bcc: [...bccEmails]
				})
				.then((res) => {
					const emailObj = res.data;

					if (res.data) {
						update(
							{
								name: email.name,
								senderName: fromName,
								senderEmail: fromEmail,
								bcc: [...bccEmails]
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields, invalidBcc: true });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { fromEmail, fromName, submitting, ccEmails, invalidBcc } = this.state;

		return (
			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Bcc"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<TextAreaField
									placeholder={'Add emails separated by commas...'}
									value={ccEmails}
									label="bcc"
									errorTrigger={invalidBcc}
									errorMessage={'Invalid Email detected'}
									type="text"
									onChange={(e) => this.handleCCEmailsChange(e)}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateFrom()}
					actionButtonDisabled={submitting}


				></FieldModal>



			</div>

		);
	}
}

export default AddBccModal;
