import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import AddPageModal from './modals/addPageModal';
import DeletePageModal from './modals/deleteDefaultModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import PageTemplate from './page';
import TabsContainer from '../../../components/views/TabsContainer';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import SectionHeader from '../../../components/views/SectionHeader';
import PageGrid from '../../../components/tables/PageGrid';

let batchActionCB;
let batchCallback;
class EventPages extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			title: 'Title',
			pathSlug: 'Address',
			description: 'Description',
			isSessionPage: 'Is Session Page',
			openToPublic: 'Is Open to Public',
			published: 'Auto Publish'
		};
		const defaultFieldTypes = {
			title: 'text',
			description: 'text',
			isSessionPage: 'boolean',
			openToPublic: 'boolean',
			pathSlug: 'text',
			published: 'boolean'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(customFieldColumns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: customFieldColumns[cfc],
				key: cfc,
				value: cfc,
				type: (defaultFieldTypes && defaultFieldTypes[cfc]) || typeof defaultFieldTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		delete toggledColumns[Object.keys(defaultFieldTypes)[0]];
		this.state = {
			loading: true,
			checkedPages: [],
			addPageModalOpen: false,
			isDeleting: false,
			isDeletingFocused:false,
			pageViews: [],
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			deletePagesModalOpen: false,
			privateHomePageSlug: '',
			publicHomePageSlug: '',
			registrationPagePathSlug: '',
			copyPagesModalOpen: false,
			deletedPages: [],
			selectedSection:0,
			toggledColumns: toggledColumns, 
			normalizedColumns: normalizedColumns, 
			searchText: '',	
			selectedRow: '',
			mobileAppConfigOpen: false,
			isGridLayout: false
		};
	}
/* original */
	deleteCheckedPages = () => {
		this.props.deletePages(Object.keys(this.state.checkedPages), () => {
			this.setState({checkedPages: {}})
			this.toggleDeletePages();
		});
	};

	deleteSinglePage = (pageId, callback) => {
		this.props.deletePages([pageId], ()=>{
			callback && callback();
		})


	}
	addPage = (page, cb) => {
		this.props.addPage(page, cb);
	};

	toggleDeletePages = () => {
		this.setState({
			deletePagesModalOpen: !this.state.deletePagesModalOpen
		});
	};


	toggleAddPage = () => {
		this.setState({ addPageModalOpen: !this.state.addPageModalOpen });
	};
	

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};
	uncheckEntries = () => {
		this.setState({
			checkedPages: {}
		});

	}
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		let nc = normalizedColumns;
		nc = nc.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: nc,
			selectedColumnSort: column,
			sortDesc: sortDesc == false ? true : false
		});
	};

	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};
	updateSinglePage = (pUpdate, callback) =>{
		
		let tempPages = this.props.pages; 
		tempPages.map(object => { 
			
			if (this.state.selectedRow == object._id) {
				Object.assign(object, pUpdate);
			}
			return object;
		})		
		this.setState({pages:tempPages},()=>{

			callback && callback();
		})
	}
	updateAddCopy = (newPage, callback) =>{
		const { eventId,orgId  } = this.props;
		const { pages,page } = this.state;
		let existingArray = this.state.pages; 
		//pages.link = `/${this.props.orgId}/events/${eventId}/pages/${page.id}/0`;
		newPage.link = `/${orgId}/events/${eventId}/pages/${newPage._id}`;
		existingArray.push(newPage); 
		this.setState({pages: existingArray},()=>{
			this.props.refreshSectionCounts() 
			callback && callback(); 
		})
	}
	checkEntry = (entryId) => {
		const checked = this.state.checkedPages;
		
		checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
		this.setState({
			checkedPages: checked
		});
	};
	checkAllEntries = (pageIds) => {
		let checked = this.state.checkedPages;
		pageIds.length > 0
			? pageIds.forEach((pageId) => {
				checked[pageId] = true;
			})
			: (checked = {});

		this.setState({
			checkedPages: checked,
		});
	};

	toggleLayout = (selected) => {
		this.setState({ isGridLayout: selected });
	};
/* End New Functions */
	render() {
		const { pages, program, orgId, programId } = this.props;
		const {
			addPageModalOpen,
			deletedPages,
			deletePagesModalOpen,
			pageViews,
			columns,
			checkedPages,
			columnTypes,
			/* New */
			selectedSection,
			toggledColumns,
			normalizedColumns,
			selectedColumnSort,
			sortDesc,
			searchText,
			loading,
			isGridLayout,
			/* End new  */
		} = this.state;
		const checkedCount = Object.keys(checkedPages).length;

	
		let mainAction=[{ type: 'button', onClick: this.toggleAddPage, label: 'Add Page' },];
		let batchActions=[
			{
				label: 'Delete',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleDeletePages
			}
		];
		let searchEnabled = true;
		let checkedEntryCount = Object.keys(this.state.checkedPages).length;
		let data = this.props.pages;
		let entriesLength = data.length;
		let checkedRows = this.state.checkedPages;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		
		switch (selectedSection) {
			case 0:
				break;
			case 1:
				data = data.filter((p) => p.status === 'published');
				break;
			case 2:
				data = data.filter((p) => p.status === 'draft');
				break;
			case 3:
				data = data.filter((p) => p.isSessionPage );
				break;
			default:
				break;
		}
		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}
		return (
		<Fragment>
			<Switch>
				<Route exact path={`/${orgId}/programs/${programId}/pages`}>
					<Fragment>
					<SectionHeader
						title="Default Pages"
						description="Pages listed here will be added to all approved events. Build pages for registration, check-in, customized agendas and more."
						breadcrumbs={[
							{
								name: `${program.name}`,
							}
						]}
						style={{ paddingBottom: 0 }}
						enableLastLink={true}
						>
					</SectionHeader>
					<TabsContainer	
						tabs={[{ title: 'All Event Pages' }, { title: 'Published' }, { title: 'Drafts' }, { title: 'Session Templates' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/>
					{true &&
					<div className="sectionContainer sectionBody">
					<ActionButtonContainer
						mainActions={mainAction}
						searchEnabled={searchEnabled}
						searchEntries={this.searchEntries}
						enabledViewSelection={true}
						isGridLayout={isGridLayout}
						toggleLayout={this.toggleLayout}
					/>
					
					{!isGridLayout ? (<div className='flex h-100 hasHeader'>
						<ViewsTable
							title={'Default Pages'}
							columns={normalizedColumns}
							toggledColumns={toggledColumns}
							data={data}
							mainColumnActive={true}
							checked={checkedRows}
							checkRow={this.checkEntry}
							checkAllRows={this.checkAllEntries}
							sortData={this.sortData}
							defaultSort={this.props.defaultSort}
							sortDirection={this.props.sortDirection}
						/>
					</div>) : (
						<PageGrid
							title={'Default Pages'}
							orgId={orgId}
							programId={programId}
							updateFavorite={this.updateFavorite}
							data={data}
							account={this.props.account}
							checkRow={this.checkEntry}
							checked={checkedRows}
						/>)}
					
					<ActionButtonContainer
						batchActions={batchActions}
						checkedEntryCount={checkedEntryCount}
						entriesLength={entriesLength}
						uncheckEntries={this.uncheckEntries}
					/>
					</div>
					}

						{/* Old Container */}
						{/* 
						{!addPageModalOpen && <ViewsContainer
							programId={programId}
							orgId={orgId}
							views={pageViews}
							entries={pages}
							deletedEntries={deletedPages}
							columns={columns}
							columnTypes={columnTypes}
							title="Default Pages"
							description="Pages listed here will be added to all approved events. Build pages for registration, check-in, customized agendas and more."
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddPage, label: 'Add Page' },

							]}
							
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeletePages
								}
							]}
							enableDownloadViews={true}
							type={'Page'}
							referenceCollection={'Programs'}
							referenceId={programId}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>}
							*/}
						<DeletePageModal
							isOpen={deletePagesModalOpen}
							toggle={() => this.toggleDeletePages()}
							title={`Delete ${checkedCount > 1 ? 'Pages' : 'Page'}`}
							message={`You are about to delete ${checkedCount} ${checkedCount > 1
								? 'pages'
								: 'page'}, this is irreversible,
						would you like to continue?`}
							submit={() =>
								this.deleteCheckedPages(Object.keys(checkedPages), () => {
									this.setState({ checkedPages: {}, deletePagesModalOpen: false });
								})}
						/> 
						{addPageModalOpen && <AddPageModal
							isOpen={addPageModalOpen}
							handleSubmit={this.addPage}
							toggle={this.toggleAddPage}
							programId={programId}
							orgId={this.props.orgId}
						/>}
					</Fragment>
				</Route>
				<Route path={`/${orgId}/programs/${programId}/pages/:pageId`}>
					<PageTemplate pages={pages} event={program.eventDefaults.details} orgId={orgId} programId={programId} account={this.props.account} organization={this.props.organization} updatePage={this.props.updatePage} deleteSinglePage={this.deleteSinglePage} program={program}/>
				</Route> 
			</Switch>
		</Fragment>
		);
	}
}

export default withRouter(EventPages);
