import React, { Fragment } from 'react';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import { withRouter } from 'react-router-dom';
import SelectField from '../../../../components/inputs/selectField';
import ToggleField from '../../../../components/inputs/toggleField';

class AddToPageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            submitting: false,
            isNewPage: false,
            pageName: '',
            page: { label: '', value: '' },
            requiredFields: {
                pageName: false,
                page: false,
            }
        };
    }


    addToPage = () => {
        this.setState({ submitting: true });

        const { match: { params } } = this.props;

        const { page, pageName, isNewPage } = this.state;

        let isValid = true;
        if (isNewPage) {


            if (pageName == '') {
                isValid = false;
                this.setState({ requiredFields: { ...this.state.requiredFields, pageName: true } });
            } else {
                this.setState({ requiredFields: { ...this.state.requiredFields, pageName: false } });
            }
        } else {
            if (page.label === '') {
                isValid = false;
                this.setState({ requiredFields: { ...this.state.requiredFields, page: true } });
            } else {
                this.setState({ requiredFields: { ...this.state.requiredFields, page: false } });
            }
        }

        if (isValid) {
            API()
                .post(`Organizations/${params.organization}/events/${params.eventId}/addFormToPages`, isNewPage ? {
                    formId: params.formId,
                    title: pageName
                } : { pageId: page.value, formId: params.formId })
                .then((res) => {
                    if (res.data) {
                        this.props.updateAddToPage(res.data, () => {
                            this.props.toggle();
                        });
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        } else {
            this.setState({ submitting: false });
        }
    }

    render() {
        const { submitting, requiredFields, isNewPage, pageName, page } = this.state;
        const { toggle, isOpen } = this.props;

        return (
            <FieldModal
                size="small"
                isOpen={isOpen}
                modalTitle={"Add to Page"}
                bodyHeaderText={'Please create a new page or select an existing page with a form.'}
                bodyDescription={``}
                bodyContent={() => {
                    return (
                        <div>
                            <ToggleField
                                label="Create new page"
                                checked={isNewPage}
                                icons={false}
                                onChange={(e) => {
                                    this.setState({ isNewPage: e.target.checked });
                                }}
                            />

                            {isNewPage ? (
                                <InputField
                                    label="Page Name"
                                    onChange={(e) => this.setState({ pageName: e.target.value })}
                                    value={pageName}
                                    required={true}
                                    errorTrigger={requiredFields.pageName}
                                    errorText={'Page name is required'}
                                />
                            ) : (
                                <SelectField
                                    label="Page"
                                    classes={'w-100'}
                                    value={this.props.pages.find(p => p.title == page.label)}
                                    errorTrigger={requiredFields.page}
                                    name="pages"
                                    options={this.props.pages}
                                    required={true}
                                    onChange={(e) => {
                                        this.setState({
                                            page: e
                                        });
                                    }}
                                />
                            )}
                        </div>
                    )
                }}
                toggle={toggle}
                actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
                cancelButtonLabel={'Cancel'}
                actionButton={this.addToPage}
                submitting={submitting}
                actionButtonDisabled={submitting}

            ></FieldModal>
        );
    }
}

export default withRouter(AddToPageModal);
