import React from 'react';
import API from '../../utils/API';

import InputField from '../inputs/inputField';
import FieldModal from './fieldModal';
class RenameCustomFieldModal extends React.Component {
	constructor(props) {
		super(props);
		const fieldIds = this.props.field.includes('*') ? this.props.field.split('*') : this.props.field;
		let field = null;
		props.fields.forEach((category) => {
			category.fields.forEach((f) => {
				if (f._id == fieldIds[1]) {
					field = f;
				}
			});
		});

		this.state = {
			submitting: false,
			fieldName: field.fieldName,
			salesforceField: field.salesforceField,
			categoryId: fieldIds[0],
			fieldId: fieldIds[1],
			requiredFields: {
				fieldName: false
			},
			fieldExists: false

		};
	}

	renameCustomField = async () => {
		this.setState({ submitting: true });
		const { orgId } = this.props;
		const { fieldName, categoryId, fieldId } = this.state;

		const requiredFields = this.state.requiredFields;

		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!this.state[key] || this.state[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});


		let fields = this.props.fields;
		let category = fields.find(f => f._id == categoryId);
		let fieldExists = false
		if (category) {
			fieldExists = category.fields.some(f => f.fieldName == fieldName && f._id != fieldId);
		}

		if (!fieldExists && valid) {
			this.setState({ submitting: true });

			const endpoint = this.props.eventId
				? `Organizations/${orgId}/events/${this.props.eventId}/customFields`
				: this.props.programId ? `Organizations/${orgId}/programs/${this.props.programId}/customFields`
					: `Organizations/${orgId}/customFields`;
			const data = {
				section: this.props.section,
				categoryId: categoryId,
				fieldId: fieldId,
				fieldName: fieldName
			}
			if (this.state.salesforceField) {
				data.salesforceField = this.state.salesforceField;
			}

			await API()
				.patch(endpoint, data)
				.then((res) => {
					if (res.data) {
						this.props.renameField(res.data, this.props.toggle);
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields, submitting: false, fieldExists: fieldExists });
		}
	};

	render() {
		const { isOpen, toggle, enableSalesforce } = this.props;
		const { submitting, fieldName, requiredFields, fieldExists } = this.state;

		return (

			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Edit Column"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Name"
									value={fieldName}
									required={true}
									errorTrigger={requiredFields.fieldName || fieldExists}
									onChange={(e) => this.setState({ fieldName: e.target.value })}
									errorMessage={fieldExists ? "Field already exists" : requiredFields.fieldName ? 'This field is required' : ""}

								/>
								{enableSalesforce && (
									<InputField
										label="Salesforce Field"
										value={this.state.salesforceField}
										onChange={(e) => this.setState({ salesforceField: e.target.value })}
									/>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Renaming...'}
					actionButton={() => this.renameCustomField()}
					actionButtonDisabled={submitting}
				></FieldModal>



			</div>

		);
	}
}

export default RenameCustomFieldModal;
