import React from 'react';
import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteAttendeeModal extends React.Component {
	state = {
		submitting: false
	};

	delete = () => {
		const attendee = {};
		this.setState({ submitting: true })
		attendee[this.props.attendee._id] = true;
		this.props.submit(
			attendee,
			(error) => (error ? this.setState({ error: 'Error deleting attendee' }) : this.props.history.push('./attendees'))
		);
	};

	render() {
		const { isOpen, toggle, attendee, submit } = this.props;
		const { submitting } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Attendee"}
					bodyHeaderText={<span>You are about to delete{' '}
						<b>
							{attendee.firstName} {attendee.lastName}
						</b>{' '}
						from the event, this is irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.delete()}
					actionButtonDisabled={submitting}
					isDelete={true}


				></FieldModal>
			</div>

		);
	}
}

export default withRouter(DeleteAttendeeModal);
