import axios from 'axios';
import { getCookie } from './cookie';

export default (req) => {
	const authToken = getCookie('authToken', req);

	const instance = axios.create({
		baseURL:
			process.env.NODE_ENV === 'production'
				? window.location.href.includes('planner-beta') ? 'https://beta-api.simple.events/' : `https://scheduler-api.simple.events/`
				: `http://${window.location.hostname}:5015/`,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'json'
	});
	if (authToken) {
		instance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
	}

	return instance;
};
