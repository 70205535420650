import React, { Fragment } from 'react';
import FieldModal from '../../../components/modals/fieldModal';



class DeleteContactsModal extends React.Component {
	state = {
		submitting: false
	};
	render() {
		const { isOpen, toggle, name } = this.props;
		const { submitting } = this.state;
		return (


			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Contact"}
					bodyHeaderText={<span>You are about to delete {name}, this is irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.props.deleteContact()}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>

			</div>

		);
	}
}

export default DeleteContactsModal;
