import React, { Component } from 'react';
import * as IFrame from 'react-iframe-form';
import { withRouter } from 'react-router-dom';
const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;
class PageGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countingKey: 0,
            lastCheckedIndex: null
        };
    }
    shiftCheck = (id, index) => {
        const { data, checked, checkRow } = this.props;
        const keys = Object.keys(checked);
        var checkedLength = keys.length;

        if (checkedLength === 0) {
            for (var i = 0; i <= index; i++) {
                checkRow(data[i]._id);
            }
            this.setState({ lastCheckedIndex: index });
        } else {
            let from =
                this.state.lastCheckedIndex > index
                    ? index
                    : this.state.lastCheckedIndex;
            let to =
                this.state.lastCheckedIndex < index
                    ? index
                    : this.state.lastCheckedIndex;

            if (keys.includes(id)) {
                for (var d = from; d <= to; d++) {
                    checked[data[d]._id] && checkRow(data[d]._id);
                }
            } else {
                for (var c = from; c <= to; c++) {
                    !checked[data[c]._id] && checkRow(data[c]._id);
                }
            }
            this.setState({ lastCheckedIndex: index });
        }
    };
    render() {
        const {
            data,
            orgId,
            eventId,
            allChecked,
            programId,
            checked,
            checkRow,
        } = this.props;
        return (
            <div className='templateCenterContainer'>
                <div className="templatesContainer" style={{ padding: 0, background: 'unset' }} >
                    <div style={{ alignContent: 'baseline', height: 'calc(100vh - 325px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>

                        {data.map((d, i) => {
                            let url = eventId
                                ? `Organizations/${orgId}/events/${eventId}/pages/render/${d._id}/`
                                : programId
                                    ? `Organizations/${orgId}/programs/${programId}/render/${d._id}/`
                                    : `Organizations/${orgId}/accountPages/render/${d._id}/`;
                            let simulatedSession = { value: false };
                            let renderSrc = '';
                            if (this.props.programId) {
                                renderSrc = `${ENV}${url}`;
                            } else {
                                renderSrc = simulatedSession.value
                                    ? `${ENV}${url}${this.props.account._id || ''}/${simulatedSession.value}${''}`
                                    : `${ENV}${url}${this.props.account._id || ''}${''}`;
                            }
                            renderSrc = renderSrc + '?preview=true';
                            let method = 'post';
                            let data = {
                                html: d.html,
                                pageProperties: {
                                    customCSS: d.customCSS,
                                    backgroundImage: d.backgroundImage,
                                    backgroundOverlay: d.backgroundOverlay
                                },
                                focusedComponent: "",
                                renderOnly: true,
                                partials: d.partials,
                            };
                            return (
                                <div>
                                    <div className="templateCard mt-10">
                                        <div className="templateCardHeader">
                                            <div className="flex ">
                                                <div
                                                    className={`checkBox mr-10 ${checked[d._id] ? "checked" : ""} `}
                                                    onClick={(e) => {
                                                        // e.stopPropagation();
                                                        if (e.shiftKey) {
                                                            this.shiftCheck(d._id, i);
                                                        } else {
                                                            this.setState({ lastCheckedIndex: i }, () => {
                                                                //this.tableHeader.current.resetAfterIndex(0);
                                                            });
                                                            checkRow(d._id);
                                                        }
                                                    }}
                                                >
                                                    <i class="las la-check" />
                                                </div>
                                                <div className="templateCardHeaderLeft" style={{ fontWeight: '600', fontSize: 14 }}>
                                                    <p style={{ fontWeight: '600', fontSize: 14 }}>{d.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`previewCardContainer`}
                                        onClick={() => {
                                            this.props.history.push(eventId
                                                ? `/${orgId}/events/${eventId}/pages/${d._id}`
                                                : programId
                                                    ? `/${orgId}/programs/${programId}/pages/${d._id}`
                                                    : `/${orgId}/pages/${d._id}`);
                                        }}>
                                        <div
                                            className="noselect emailPreviewWindow Frame templateCenter form"
                                            style={{ width: '100%' }}
                                        >
                                            <IFrame.Form
                                                id={`builderPreviewFrameTemplate-${d._id}`}
                                                className="noselect emailPreviewWindow Frame"
                                                style={{
                                                    ponterEvent: 'none',
                                                    transform: 'scale(0.99)'
                                                }}
                                                key={`p-${i}`}
                                                scrolling="no"
                                                name={`PreviewFrameTemp-${d._id}`}
                                                config={{ url: renderSrc, method, data }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );

                        })}

                        {data.length === 0 ?
                            <div className='BlankTable'>
                                <h2 className='mb-15'> No {this.props.title} Yet</h2>

                                <p className='tableLabel'> Add <span className='lower' > {this.props.title} </span></p>
                            </div>
                            : false
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PageGrid);
