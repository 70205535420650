import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import EventDetails from '../../sections/event/setup/details';
import EventAttendees from '../../sections/event/attendee/attendees';
import API from '../../utils/API';
import EventSessions from '../../sections/event/session/sessions';
import EventGroups from '../../sections/event/group/groups';
import EventReports from '../../sections/event/reports/reports';
import EventEmails from '../../sections/event/emails/emails';
import EventInventory from '../../sections/event/planning/inventory';
import EventPages from '../../sections/event/pages/pages';
import Overview from '../../sections/event/overview/overview';
import ApplicationSetup from '../../sections/event/pages/setup';
import EventInteractionPoints from '../../sections/event/interactions/points';
import EventScannables from '../../sections/event/interactions/scannables';
import EventFiles from '../../sections/event/files/files';
import MobileApp from '../../sections/event/pages/mobile';
import EventForms from '../../sections/event/forms/forms';

import EventCampaigns from '../../sections/event/emails/campaigns/campaigns';
import EventTemplates from '../../sections/event/emails/templates/templates';
import EventTextCampaigns from '../../sections/event/texting/campaigns';
import EventNotificationCampaigns from '../../sections/event/notifications/campaigns';
import LoadingWizard from '../../components/spinner/wizard';
import FieldModal from '../../components/modals/fieldModal';

class Event extends Component {
	constructor(props) {
		super(props);

		this.state = {
			event: props.event,
			venues: [],
			loading: true,
			expanded: true,
			section: 0,
			sectionIndex: 0,
			sectionName: 'Overview',
			sectionTakeoverRequested: false,
			sectionTakeoverUserName: '',
			sectionTakeoverDenied: false,
			sectionTakeoverGranted: false,
			eventVenue: {},
			attendeesLoaded: false,
			groupsLoaded: false,
			sessionsLoaded: false,
			allLoaded: false,
		};
	}

	async componentDidMount() {
		const { orgId, socket } = this.props;
		this.getVenues(orgId);
		this.fetchGroups();
		this.fetchAttendees();
		this.fetchSessions();
		socket.on('section_takeover', (data) => {
			console.log('section_takeover', data);
			this.setState({
				sectionControlRequested: true,
				sectionTakeoverUserName: data.name,
				sectionTakeoverUserId: data.id
			});
		});

		socket.on('forced_section_takeover', (data) => {
			console.log('forced_section_takeover', data);
			this.setState({
				sectionControlRequested: false,
			});
			this.props.history.push(`../attendees`);
		});
	}

	getVenues = (orgId) => {
		let oId = orgId ? orgId : this.props.orgId;
		API().get(`Organizations/${oId}/venues`).then((res) => {
			if (res.data) {
				const venues = res.data;
				const eventVenue = venues.find(v => v._id == this.state.event.venue);
				this.setState({ venues: venues, eventVenue: eventVenue });
			}
		});
	};


	addNewVenue = (venue, cb) => {
		const { venues } = this.state;
		this.setState({ venues: [...venues, venue] });
		cb && cb(venue);
	}

	addNewLocation = (location) => {
		const { venues, event } = this.state;
		let updatedVenues = venues.map(v => {

			if (v._id == event.venue) {
				v.locations.push(location);
				return v;
			}

			return v;
		})
		let venueDetails = event.venueDetails;
		venueDetails.locations.push(location);
		this.setState({ venues: [...updatedVenues], event: { ...event, venueDetails: venueDetails } });

	}
	renameLocation = (location) => {
		const { venues, event } = this.state;
		let updatedVenues = venues.map(v => {

			if (v._id == event.venue) {
				let updatedLocations = v.locations;

				v.locations = updatedLocations.map(l => {

					if (l._id == location._id) {
						return location;
					}
					return l;
				});
			}

			return v;
		})
		this.setState({ venues: updatedVenues })

	}

	renameVenue = (updatedVenue) => {
		const { venues } = this.state;

		const updatedVenues = venues.map(v => {
			if (v._id == updatedVenue._id) {
				return updatedVenue;
			}
			return v;
		})
		this.setState({ venues: updatedVenues });

	}
	deleteVenue = (id) => {
		const { venues } = this.state;

		const updatedVenues = venues.filter(v => v._id !== id);
		this.setState({ venues: updatedVenues });

	}
	deleteLocation = (venueId, locationId) => {

		const { venues } = this.state;
		const updatedVenues = venues.map(v => {

			if (v._id == venueId) {

				let updatedLocations = v.locations.filter(l => l._id !== locationId);
				v.locations = updatedLocations;
			}
			return v;
		})
	}

	saveBasics = async (editedEvent, newVenue, callback) => {

		const { orgId } = this.props;
		const { venues } = this.state;


		if (newVenue) {
			let venueObj = {
				name: newVenue.label,
				address: {
					address1: '',
					address2: '',
					city: '',
					state: '',
					postalCode: '',
					country: ''
				},
				website: '',
				phoneNumber: '',
				description: '',
				venueType: 'Physical'
			};
			await API().post(`Organizations/${this.props.orgId}/venue`, venueObj).then((res) => {
				if (res.data) {
					editedEvent.venue = res.data._id;
					this.setState({ venues: [...venues, res.data] })
				}
			});
		}
		const data = new FormData();
		data.append('name', editedEvent.name);
		data.append('nameSlug', editedEvent.nameSlug);
		data.append('startDateTime', editedEvent.startDateTime);
		data.append('endDateTime', editedEvent.endDateTime);
		data.append('startTime', editedEvent.startTime);
		data.append('endTime', editedEvent.endTime);
		data.append('startDate', editedEvent.startDate);
		data.append('endDate', editedEvent.endDate);
		data.append('timezone', editedEvent.timezone);
		data.append('experienceType', editedEvent.experienceType);
		data.append('registrationCapacity', editedEvent.registrationCapacity);
		data.append('venue', editedEvent.venue);
		data.append('venueAddress', JSON.stringify(editedEvent.venueAddress));


		data.append('eventType', editedEvent.eventType);
		data.append('enableRegistrationPage', editedEvent.enableRegistrationPage);
		data.append('allowPublicRegistrations', editedEvent.allowPublicRegistrations);
		data.append('enableMeetingPassword', editedEvent.enableMeetingPassword);
		data.append('meetingPassword', editedEvent.meetingPassword);
		data.append('enableSSOLogin', editedEvent.enableSSOLogin);
		data.append('SSOLoginUrl', editedEvent.SSOLoginUrl);
		data.append('enablePassthroughPassword', editedEvent.enablePassthroughPassword);
		data.append('registrationPageSlug', editedEvent.registrationPageSlug);
		data.append('requestAccessEnabled', editedEvent.requestAccessEnabled);
		data.append('autoLoginFromRegistration', editedEvent.autoLoginFromRegistration);
		editedEvent.defaultSessionPage && data.append('defaultSessionPage', editedEvent.defaultSessionPage)


		data.append('metadata', JSON.stringify(editedEvent.metadata));

		//defaultPublicPath, defaultUserPath, eventSupportEmailName, eventSupportEmail, eventEmail, eventEmailName
		data.append('meetingLoginPath', editedEvent.meetingLoginPath);
		data.append('defaultPublicPath', editedEvent.defaultPublicPath);
		data.append('defaultUserPath', editedEvent.defaultUserPath);
		data.append('eventSupportEmailName', editedEvent.eventSupportEmailName);
		data.append('eventSupportEmail', editedEvent.eventSupportEmail);
		data.append('eventEmail', editedEvent.eventEmail);
		data.append('eventEmailName', editedEvent.eventEmailName);

		data.append('branding', JSON.stringify(editedEvent.branding));
		data.append('style', JSON.stringify(editedEvent.style));

		data.append('pwaEnabled', editedEvent.pwaEnabled);
		data.append('pwaDetails', JSON.stringify(editedEvent.pwaDetails));

		if (editedEvent.files && editedEvent.files.length > 0) {
			data.append('includesImage', true);
			data.append('files', editedEvent.files[0]);
		}
		await API()
			.patch(`Organizations/${orgId}/events/${editedEvent._id}`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then((res) => {
				if (res.data) {
					this.setState({ event: { ...editedEvent, ...res.data } });
					callback && callback({ ...editedEvent, ...res.data });
				}
			}).catch((e) => {


				console.log(e);
			})
	};

	updateEventIntegration = (integrations, cb) => {
		const { event } = this.state;
		event.integrations = integrations;
		this.setState({ event: event }, cb);
	}

	saveEventAccess = (editedEvent, callback) => {
		const { orgId } = this.props;
		API()
			.patch(`Organizations/${orgId}/events/${editedEvent._id}`, {
				eventType: editedEvent.eventType,
				enableRegistrationPage: editedEvent.enableRegistrationPage,
				allowPublicRegistrations: editedEvent.allowPublicRegistrations,
				enableMeetingPassword: editedEvent.enableMeetingPassword,
				meetingPassword: editedEvent.meetingPassword,
				enableSSOLogin: editedEvent.enableSSOLogin,
				SSOLoginUrl: editedEvent.SSOLoginUrl,
				enablePassthroughPassword: editedEvent.enablePassthroughPassword,
				meetingLoginPath: editedEvent.meetingLoginPath,
				defaultPublicPath: editedEvent.defaultPublicPath,
				defaultUserPath: editedEvent.defaultUserPath,
				registrationPageSlug: editedEvent.registrationPageSlug,
				requestAccessEnabled: editedEvent.requestAccessEnabled,
				autoLoginFromRegistration: editedEvent.autoLoginFromRegistration,
			})
			.then((res) => {
				if (res.data) {
					this.setState({ event: { ...editedEvent, ...res.data }, loading: false });
					callback && callback();
				}
			});
	};


	saveEventStyling = (editedEvent, callback) => {
		const { orgId } = this.props;
		API()
			.patch(`Organizations/${orgId}/events/${editedEvent._id}`, {
				style: editedEvent.style,
				customCSS: editedEvent.customCSS,
				headTagScripts: editedEvent.headTagScripts,
				bodyTagScripts: editedEvent.bodyTagScripts
			})
			.then((res) => {
				if (res.data) {
					this.setState({ event: { ...editedEvent, ...res.data }, loading: false });
					callback && callback();
				}
			});
	};


	fetchAttendees = async (cb) => {
		const { orgId } = this.props;
		const { event } = this.state;
		const eventId = event._id;
		await API().get(`Organizations/${orgId}/events/${eventId}/attendees`).then((res) => {
			if (res.data) {
				const sfIntegration = this.props.organization.integrations.find(s => {
					return s.identifier == "salesforce" && s.enabled
				})
				let attendees = res.data;
				attendees = attendees.map((a) => {
					let invited = false,
						registered = false,
						attending = false,
						checkedIn = false,
						declined = false,
						removed = false;

					if (a.statusLog) {
						a.statusLog.forEach((s) => {
							if (s.status === 'invited') {
								invited = true;
								removed = false;
							} else if (s.status === 'registered') {
								registered = true;
								attending = true;
								declined = false;
							} else if (s.status === 'incomplete' || s.status === 'registered') {
								attending = true;
								declined = false;
							} else if (s.status === 'not attending' || s.status === 'did not attend' || s.status === 'cancelled') {
								declined = true;
								attending = false;
							} else if (s.status === 'removed') {
								invited = false;
								attending = false;
								registered = false;
								checkedIn = false;
								removed = true;
							} else if (s.status === 'checked in') {
								checkedIn = true;
							}
						});
					} else {
						if (a.status === 'invited') {
							invited = true;
							removed = false;
						} else if (a.status === 'registered') {
							registered = true;
							attending = true;
							declined = false;
						} else if (a.status === 'incomplete' || a.status === 'registered') {
							attending = true;
							declined = false;
						} else if (a.status === 'not attending' || a.status === 'did not attend' || a.status === 'cancelled') {
							declined = true;
							attending = false;
						} else if (a.status === 'removed') {
							invited = false;
							attending = false;
							registered = false;
							checkedIn = false;
							removed = true;
						} else if (a.status === 'checked in') {
							checkedIn = true;
						}
					}
					a.invited = invited;
					a.registered = registered;
					a.attendance = (declined || removed) ? 'Not Attending' : checkedIn ? 'Attended' : attending ? 'Attending' : 'Not Indicated';
					a.checkedIn = checkedIn;
					a.id = a._id;
					a.link = `/${orgId}/events/${eventId}/attendees/${a._id}`;
					a.metadata.forEach((meta) => (a[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
					a.eventGroups = event.groups
						.map((eg) => eg.attendees.includes(a._id) && eg.name)
						.filter((g) => !!g)
						.join(', ');
					if (a.roomingInformation)
						a.roomingInformation = Object.keys(a.roomingInformation).forEach((ri) => {
							a[`rooming_${ri}`] = a.roomingInformation[ri];
						});
					if (a.travelInformation)
						a.travelInformation = Object.keys(a.travelInformation).forEach((ti) => {
							a[`travel_${ti}`] = a.travelInformation[ti];
						});

					if (sfIntegration && a.integrations && a.integrations.salesforce) {
						a.isSFLead = a.integrations.salesforce && a.integrations.salesforce.leadId && a.integrations.salesforce.leadId != '';
						a.isSFContact = a.integrations.salesforce && a.integrations.salesforce.contactId && a.integrations.salesforce.contactId != '';
						a.accountName = a.integrations.salesforce && a.integrations.salesforce.accountName ? a.integrations.salesforce.accountName : '';
						a.sfMemberLink = a.integrations.salesforce && a.integrations.salesforce.campaignMemberId ? `${sfIntegration.credentials.instance_url}/lightning/r/CampaignMember/${a.integrations.salesforce.campaignMemberId}/view` : '';
					} else {
						a.isSFLead = false;
						a.isSFContact = false;
						a.accountName = '';
						a.sfMemberLink = '';
					}

					return a;
				});
				this.setState({ event: { ...this.state.event, attendees: attendees }, attendeesLoaded: true, allLoaded: this.state.sessionsLoaded && this.state.groupsLoaded });
				cb && cb();
			}
		});
	};

	loadMoreAttendees = (moreAttendees) => {
		this.setState({
			event: { ...this.state.event, attendees: [...this.state.event.attendees, ...moreAttendees] }
		});
	};

	addAttendee = (attendee) => {
		this.setState({
			event: {
				...this.state.event,
				attendees: [attendee, ...this.state.event.attendees],
				attendeeCount: this.state.event.attendeeCount + 1
			}
		}/* , this.props.refreshSectionCounts */);
	};

	updateAttendee = (id, attendee) => {
		const attendees = this.state.event.attendees.map((a) => {
			if (a._id == id) {
				return attendee;
			}
			return a;
		});
		this.setState({ event: { ...this.state.event, attendees: attendees } });
	};

	importAttendees = (attendees, cb) => {
		const eventAttendees = this.state.event.attendees;
		// merge attendees
		attendees.forEach((a) => {
			if (!a.success) return;
			const existingAttendeeIndex = eventAttendees.findIndex((ea) => ea._id == a._id);
			if (existingAttendeeIndex >= 0) {
				eventAttendees[existingAttendeeIndex] = { ...eventAttendees[existingAttendeeIndex], ...a };
			} else {
				eventAttendees.push(a);
			}
		});
		this.setState({
			event: {
				...this.state.event,
				attendees: eventAttendees,
				attendeeCount: this.state.event.attendeeCount + attendees.length
			}
		}, () => {
			cb && cb();
			this.props.refreshSectionCounts();
		});
	};

	groupAttendees = async (group, attendees, callback) => {
		const { orgId } = this.props;
		const attendeeIds = Object.keys(attendees);
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/group`, {
				name: group.label,
				id: group.value || '',
				attendees: attendeeIds
			})
			.then((res) => {
				if (res.data) {
					let added = 0;
					if (!group.value) {
						const g = res.data;
						this.setState({
							event: {
								...this.state.event,
								groups: [...this.state.event.groups, g],
								groupCount: this.state.event.groups.length + 1
							}
						});
						added = g.attendees.length;
					} else {
						added = res.data.attendeesAdded;
					}
					callback(added);
				}
			});
	};

	groupImportedAttendees = async (group, attendees, callback) => {
		const { orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/group`, {
				name: group.label,
				id: group.value || '',
				attendees: attendees
			})
			.then((res) => {
				if (res.data) {
					let added = 0;
					const g = res.data;
					if (!group.value || group.value == '') {
						this.setState({
							event: {
								...this.state.event,
								groups: [...this.state.event.groups, g],
								groupCount: this.state.event.groups.length + 1
							}
						});
						added = g;
					} else {
						const existingGroups = this.state.event.groups;
						const existingGroupIndex = existingGroups.findIndex((eg) => {
							return eg._id == group.value;
						});
						if (existingGroupIndex >= 0) {
							existingGroups[existingGroupIndex].attendees = [
								...existingGroups[existingGroupIndex].attendees,
								...attendees
							];
						}
						this.setState({
							event: {
								...this.state.event,
								groups: existingGroups,
								groupCount: this.state.event.groups.length + 1
							}
						});
						added = res.data.attendeesAdded;
					}
					callback(added);
				}
			});
	};

	groupImportedSessions = async (group, sessions, callback) => {
		const { orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/group`, {
				name: group.label,
				id: group.value || '',
				sessions: sessions
			})
			.then((res) => {
				if (res.data) {
					let added = 0;
					if (!group.value || group.value == '') {
						const g = res.data;
						this.setState({
							event: {
								...this.state.event,
								groups: [...this.state.event.groups, g],
								groupCount: this.state.event.groups.length + 1
							}
						});
					} else {
						const existingGroups = this.state.event.groups;
						const existingGroupIndex = existingGroups.findIndex((eg) => {
							return eg._id == group.value;
						});
						if (existingGroupIndex >= 0) {
							existingGroups[existingGroupIndex].sessions = [
								...existingGroups[existingGroupIndex].sessions,
								...sessions
							];
						}
						this.setState({
							event: {
								...this.state.event,
								groups: existingGroups,
								groupCount: this.state.event.groups.length + 1
							}
						});
					}
					added = res.data;
					callback(added);
				}
			});
	};

	batchUpdateAttendeeStatus = async (status, attendees, callback) => {
		const { orgId } = this.props;
		const attendeeIds = Object.keys(attendees);
		const res = await API().patch(`Organizations/${orgId}/events/${this.state.event._id}/attendees/updateStatus`, {
			status: status,
			attendees: attendeeIds
		});

		await API().get(`Organizations/${orgId}/events/${this.state.event._id}`).then((res) => {
			if (res.data) {
				this.setState({ event: res.data, loading: false });
			}
		});
		callback(res.data);
	};

	batchAddAttendeePoint = async (point, attendees, callback) => {
		const { orgId } = this.props;
		const attendeeIds = Object.keys(attendees);
		const payload = {
			interactionPointId: point.id,
			attendees: attendeeIds
		};
		if (point.referenceId) {
			payload.referenceId = point.referenceId;
		}
		const res = await API().patch(
			`Organizations/${orgId}/events/${this.state.event._id}/attendees/addPoint`,
			payload
		);

		await API().get(`Organizations/${orgId}/events/${this.state.event._id}`).then((res) => {
			if (res.data) {
				this.setState({ event: res.data, loading: false });
			}
		});
		callback(res.data);
	};

	inviteAttendees = async (attendees, callback) => {
		const { orgId } = this.props;
		const attendeeIds = Object.keys(attendees);
		await API().post(`Organizations/${orgId}/events/${this.state.event._id}/inviteAttendees`).then((res) => {
			const ids = [];
			res.data.map((id) => (ids[id] = true));
			this.setState({});
		});
	};

	exportAttendees = () => { };

	updateDeleteAttendee = (attendeeId, callback) => {

		let updatedAttendees = this.state.event.attendees.filter((a) => a._id !== attendeeId);
		this.setState({
			event: {
				...this.state.event,
				attendees: updatedAttendees,
				attendeeCount: this.state.event.attendeeCount - 1
			}
		});
		//this.props.refreshSectionCounts
		callback();
	}

	deleteAttendees = async (attendees, callback) => {
		const { orgId } = this.props;
		const attendeeIds = Object.keys(attendees);
		//here
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/attendees`, {
				attendees: attendeeIds
			})
			.then((res) => {
				if (res.data) {
					const atts = this.state.event.attendees.filter((at) => !attendees[at._id]); //removed attendees from attendees state

					this.setState({
						event: {
							...this.state.event,
							attendees: atts,
							attendeeCount: this.state.event.attendeeCount - attendeeIds.length
						}
					}, this.props.refreshSectionCounts);
					callback(false);
				}
			})
			.catch((e) => {
				console.log(e);
				callback(true);
			});
	};

	fetchSessions = async (cb) => {
		const { orgId } = this.props;
		const { event } = this.state;
		const eventId = event._id;
		await API().get(`Organizations/${orgId}/events/${eventId}/sessions`).then((res) => {
			if (res.data) {
				let sessions = res.data;
				sessions = sessions.map((s) => {
					s.eventGroups = event.groups
						.map((eg) => eg.sessions.includes(s._id) && eg.name)
						.filter((g) => !!g)
						.join(', ');
					s.id = s._id;
					s.link = `/${orgId}/events/${eventId}/schedule/${s._id}`;
					s.metadata && s.metadata.forEach((meta) => (s[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
					return s;
				});
				this.setState({ event: { ...this.state.event, sessions: res.data }, sessionsLoaded: true, allLoaded: this.state.attendeesLoaded && this.state.groupsLoaded });
				cb && cb();
			}
		});
	};

	loadMoreSessions = (moreSessions) => {
		this.setState({
			event: { ...this.state.event, sessions: [...this.state.event.sessions, ...moreSessions] }
		});
	};

	addSession = (session) => {
		this.setState({
			event: {
				...this.state.event,
				sessions: [session, ...this.state.event.sessions],
				sessionCount: this.state.event.sessionCount + 1,
				pathOptions: [...this.state.event.pathOptions, { _id: session._id, title: `Session: ${session.name}`, openToPublic: session.openToPublic, pathSlug: `session/${session.slug}` }]
			}
		}, this.props.refreshSectionCounts);
	};

	updatePathOptions = (page) => {
		this.setState({
			event: {
				...this.state.event,
				pathOptions: [...this.state.event.pathOptions, { _id: page._id, title: page.title, openToPublic: page.openToPublic, pathSlug: page.pathSlug }]
			}
		}, this.props.refreshSectionCounts);
	}

	updatePathOptionsPage = (page) => {
		this.setState({
			event: {
				...this.state.event,
				pathOptions: [...this.state.event.pathOptions, { _id: page._id, title: page.title, openToPublic: page.openToPublic, pathSlug: page.slug }]
			}
		}, this.props.refreshSectionCounts);
	}
	updateSession = (id, updatedSession) => {
		const { sessions } = this.state.event;
		const updatedSessions = sessions.map((s) => {
			if (s._id == id) {
				return updatedSession;
			} else {
				return s;
			}
		});
		this.setState({ event: { ...this.state.event, sessions: updatedSessions } });
	};

	importSessions = () => { };

	groupSessions = async (group, sessions, callback) => {
		const { orgId } = this.props;
		const sessionIds = Object.keys(sessions);
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/group`, {
				name: group.label,
				id: group.value || '',
				sessions: sessionIds
			})
			.then((res) => {
				if (res.data) {
					let added = 0;
					if (!group.value) {
						const g = res.data;
						this.setState({
							event: {
								...this.state.event,
								groups: [...this.state.event.groups, g],
								groupCount: this.state.event.groups.length + 1
							}
						});
						added = g.sessions.length;
					} else {
						added = res.data.sessionsAdded;
					}
					callback(added);
				}
			});
	};

	deleteSessions = async (sessions, callback) => {
		const { orgId } = this.props;
		const sessionIds = Object.keys(sessions);
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/sessions`, {
				sessions: sessionIds
			})
			.then((res) => {
				if (res.data) {
					const seshs = this.state.event.sessions.filter((at) => !sessions[at._id]);

					this.setState({
						event: {
							...this.state.event,
							sessions: seshs,
							sessionCount: this.state.event.sessionCount - sessionIds.length
						}
					}, this.props.refreshSectionCounts);
					callback(false);
				}
			});
	};

	fetchGroups = async (cb) => {
		const { orgId } = this.props;
		await API().get(`Organizations/${orgId}/events/${this.state.event._id}/groups`).then((res) => {
			if (res.data) {
				this.setState({
					event: { ...this.state.event, groups: res.data },
					groupsLoaded: true,
					allLoaded: this.state.attendeesLoaded && this.state.sessionsLoaded
				});
				cb && cb();
			}
		});
	};

	loadMoreGroups = (moreGroups) => {
		this.setState({
			event: { ...this.state.event, groups: [...this.state.event.groups, ...moreGroups] }
		});
	};

	addGroup = (group) => {
		this.setState({
			event: {
				...this.state.event,
				groups: [group, ...this.state.event.groups],
				groupCount: this.state.groupCount + 1
			}
		}, this.props.refreshSectionCounts);
	};

	updatePathOp = (page, callback) => {
		let tempPathOptions = this.state.event.pathOptions; //pathOptions = Path Option elements 
		tempPathOptions.map(object => {

			if (page._id == object._id) {
				Object.assign(object, page);
			}
			return object;
		})
		this.setState({ page: tempPathOptions }, () => {
			callback && callback();
		})
	}
	updatePagesPathOp = (pages) => {
		this.setState({
			event: {
				...this.state.event,
				pathOptions: this.state.event.pathOptions.filter(pathOption =>
					pages.some(page => page._id === pathOption._id))
			}
		});
	}

	updateSessionDeletePathOP = (checkedSessionIDs) => {
		const newPathOptions = this.state.event.pathOptions.filter(object =>
			!checkedSessionIDs.includes(object._id)
		);
		// Update the state with the new path options
		this.setState({
			event: {
				...this.state.event,
				pathOptions: newPathOptions
			}
		});
	}
	deleteGroups = async (groups, callback) => {
		const { orgId } = this.props;
		const groupIds = Object.keys(groups);
		await API()
			.post(`Organizations/${orgId}/events/${this.state.event._id}/groups`, {
				groups: groupIds
			})
			.then((res) => {
				if (res.data) {
					const seshs = this.state.event.groups.filter((at) => !groups[at._id]);

					this.setState({
						event: {
							...this.state.event,
							groups: seshs,
							sessionCount: this.state.event.groupCount - groupIds.length
						}
					}, this.props.refreshSectionCounts);
					callback();
				}
			});
	};

	updateGroup = (id, group) => {
		const { groups } = this.state.event;
		const groupIndex = groups.findIndex((g) => g._id == id);
		Object.assign(groups[groupIndex], { ...group });
		//groups[index] = { ...groups[index], ...group };
		this.setState({ event: { ...this.state.event, groups: groups } });
	};

	addNewField = (section, field, callback) => {
		let updatedSection = this.state.event.customFields[section];
		if (updatedSection.find((cat) => cat._id == field._id)) {
			updatedSection = updatedSection.map((cat) => (cat._id === field._id ? field : cat));
		} else {
			updatedSection.push(field);
		}
		this.setState(
			{
				event: {
					...this.state.event,
					customFields: { ...this.state.event.customFields, [section]: updatedSection }
				}
			},
			() => {
				callback(this.state.event.customFields[section]);
			}
		);
	};

	updateSectionCustomFields = (sectionName, section, callback) => {
		this.setState(
			{
				event: {
					...this.state.event,
					customFields: { ...this.state.event.customFields, [sectionName]: section }
				}
			},
			() => {
				callback(this.state.event.customFields[sectionName]);
			}
		);
	};

	removeField = (section, fields, callback) => {
		this.setState(
			{ event: { ...this.state.event, customFields: { ...this.state.event.customFields, [section]: fields } } },
			() => {
				callback(this.state.event.customFields[section]);
			}
		);
	};

	addNewAppMenu = (menu, callback) => {
		callback([...this.state.event.app.menus, menu]);
		this.setState({
			event: {
				...this.state.event,
				app: { ...this.state.event.app, menus: [...this.state.app.menus, menu] }
			}
		});
	};

	requestTakeOver = () => {
		const { singleUserSectionInUse } = this.props;
		this.props.socket.post(
			`/Organizations/${this.props.orgId}/sectionOverlap`,
			{
				request: 'takeover',
				userId: singleUserSectionInUse.id,
				location: `${window.location.pathname}${window.location.search}`
			},
			(response) => {
				if (response.success) {
					this.setState({
						sectionTakeoverRequested: true,
						sectionTakeoverDenied: false
					});
				} else if (response.sectionFree) {
					window.location.reload();
				}
			}
		);
		this.props.socket.on('takeover_response', (resData) => {
			if (resData.allowed) {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverGranted: true });
			} else {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverDenied: true });
			}
		});
	};

	forceTakeOver = () => {
		const { singleUserSectionInUse } = this.props;
		this.props.socket.post(
			`/Organizations/${this.props.orgId}/sectionOverlap`,
			{
				request: 'force_takeover',
				userId: singleUserSectionInUse.id,
				location: `${window.location.pathname}${window.location.search}`
			},
			(response) => {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverGranted: true });
			}
		);
		this.props.socket.on('takeover_response', (resData) => {
			if (resData.allowed) {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverGranted: true });
			} else {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverDenied: true });
			}
		});
	};

	//if response is true access was given else it was denied
	respondToTakeover = (response) => {
		const { match: { params } } = this.props;
		const section = parseInt(params.section);
		this.props.socket.post(
			`/Organizations/${this.props.orgId}/sectionOverlap`,
			{
				request: 'takeover_response',
				response: response,
				userId: this.state.sectionTakeoverUserId,
				location: `${window.location.pathname}${window.location.search}`
			},
			(res) => {
				if (res.success) {
					if (response) {
						this.props.history.push('../attendees')
					}
					this.setState({ sectionControlRequested: false });
				}
			}
		);
	};
	savePwaDetails = (pwaDetails, callback) => {
		const { orgId } = this.props;
		API().patch(`Organizations/${orgId}/events/${this.state.event._id}`, pwaDetails).then((res) => {
			if (res.data) {
				this.setState({ event: { ...this.state.event, ...res.data }, loading: false });
				callback && callback();
			}
		});
	};

	addAttendeesView = (newView) => {
		this.setState({
			event: {
				...this.state.event,
				attendeesViews: [...this.state.event.attendeesViews, newView]
			}
		});
	};

	updateAttendeesView = (updatedView) => {
		this.setState({
			event: {
				...this.state.event,
				attendeesViews: this.state.event.attendeesViews.map((view) => {
					if (view._id === updatedView._id) {
						return updatedView;
					}
					return view;
				})
			}
		});
	};

	removeAttendeesView = (viewId) => {
		this.setState({
			event: {
				...this.state.event,
				attendeesViews: this.state.event.attendeesViews.filter((view) => view._id !== viewId)
			}
		});
	};

	setAttendeesViewAsMain = (viewId) => {
		const { event } = this.state;
		const attendeesViews = event.attendeesViews.map((view) => {
			if (view._id === viewId) {
				return { ...view, isMain: true };
			} else {
				return { ...view, isMain: false };
			}
		});
		this.setState({
			event: {
				...event,
				attendeesViews: attendeesViews
			}
		});
	};

	resetAttendeesViewAsMain = () => {
		const { event } = this.state;
		const attendeesViews = event.attendeesViews.map((view) => {
			view.isMain = false;
			return view;
		});
		this.setState({
			event: {
				...event,
				attendeesViews: attendeesViews
			}
		});
	};

	/**attendees is an array of ids */
	excludeUpdateAttendees = (attendees, sessionId, callback) => {
		const { event } = this.state;
		let tempEvent = event;

		tempEvent.attendees.map((att) => {
			if (attendees.includes(att._id)) {
				var index = att.excludedSessions.indexOf(sessionId);
				if (index !== -1) {
					att.excludedSessions.splice(index, 1);
				}
				return att;
			} else {
				return att;
			}
		});
		this.setState({ event: tempEvent }, () => {

			callback && callback();
		});
	};
	updateSetup = (obj) => {
		const eventObj = this.state.event;
		this.setState({ event: { ...eventObj, ...obj } });
	};


	addCustomField = (type, category) => {
		const customFieldsObj = this.state.event.customFields[type];
		const cat = customFieldsObj.find((c) => category._id === c._id);
		if (cat) {
			cat.fields = category.fields;
		} else {
			customFieldsObj.push(category);
		}
		this.setState({
			event: {
				...this.state.event,
				customFields: {
					...this.state.event.customFields,
					[type]: customFieldsObj
				}
			}
		});
	}

	render() {
		const {
			event,
			venues,
			sectionTakeoverRequested,
			sectionTakeoverDenied,
			sectionControlRequested,
			sectionTakeoverGranted,
			eventId,
			customFields,
			eventVenue,
			allLoaded
		} = this.state;
		const { singleUserSectionInUse, permittedEvent } = this.props;
		const v = this.state.venues.find((v) => v._id === this.state.event.venue);
		const locations = {};
		const locationOptions = [];
		if (v) {
			v.locations.forEach((l) => {
				locationOptions.push({
					value: l._id,
					label: l.name,
					color: '#000'
				});
				locations[l._id] = l.name;
			});
		}

		return (
			<div className={`pageContainer ${permittedEvent.readOnly ? 'readOnlyPermission' : ''}`}>

				{allLoaded && <Switch>
					<Route path={`/:organization/events/:event/overview`}>
						<Overview
							permittedEvent={permittedEvent}
							event={event}
							organization={this.props.organization}
							venue={eventVenue}
						/>
					</Route>
					<Route path={`/:organization/events/:event/details`}>
						<EventDetails
							orgId={this.props.orgId}
							readOnly={permittedEvent.readOnly}
							event={event}
							venues={venues}
							customFields={this.state.event.customFields.events}
							saveDetails={this.saveBasics}
							addNewVenue={this.addNewVenue}
							renameVenue={this.renameVenue}
							deleteVenue={this.deleteVenue}
							organization={this.props.organization}
							saveEventStyling={this.saveEventStyling}
							ssoAvailable={this.props.permittedAddons.ssoEnabled}
							updateEventIntegration={this.updateEventIntegration}
							savePwaDetails={this.savePwaDetails}
							sessions={this.state.event.sessions}
						/>
					</Route>
					<Route path={`/:organization/events/:event/attendees`}>
						<EventAttendees
							fetchAttendees={this.fetchAttendees}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							attendees={this.state.event.attendees}
							attendeeCount={this.state.event.attendeeCount}
							registeredCount={this.state.event.registeredCount}
							loadMoreAttendees={this.loadMoreAttendees}
							loadMoreSessions={this.loadMoreSessions}
							addAttendee={this.addAttendee}
							updateAttendee={this.updateAttendee}
							importAttendees={this.importAttendees}
							groupAttendees={this.groupAttendees}
							deleteAttendees={this.deleteAttendees}
							updateDeleteAttendee={this.updateDeleteAttendee}
							customFields={this.state.event.customFields['attendees']}
							addCustomField={this.addCustomField}
							locations={locations}
							batchUpdateAttendeeStatus={this.batchUpdateAttendeeStatus}
							batchAddAttendeePoint={this.batchAddAttendeePoint}
							addNewGroup={this.addGroup}
							groupImportedAttendees={this.groupImportedAttendees}
							addAttendeesView={this.addAttendeesView}
							updateView={this.updateAttendeesView}
							removeView={this.removeAttendeesView}
							attendeeViews={this.state.event.attendeesViews}
							setViewAsMain={this.setAttendeesViewAsMain}
							resetMainView={this.resetAttendeesViewAsMain}
							subscriptionAddons={this.props.subscriptionAddons}
							organization={this.props.organization}
						/>
					</Route>
					<Route path={`/:organization/events/:event/groups`}>
						<EventGroups
							fetchGroups={this.fetchGroups}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={event._id}
							orgId={this.props.orgId}
							groups={this.state.event.groups}
							groupCount={this.state.event.groupCount}
							addGroup={this.addGroup}
							loadMoreGroups={this.loadMoreGroups}
							loadMoreAttendees={this.loadMoreAttendees}
							loadMoreSessions={this.loadMoreSessions}
							deleteGroups={this.deleteGroups}
							updateGroup={this.updateGroup}
							addNewAttendee={this.addAttendee}
							addNewSession={this.addSession}
							groupsViews={this.state.event.groupsViews}
						/>
					</Route>
					<Route path={`/:organization/events/:event/schedule`}>
						<EventSessions
							updateSessionDeletePathOP={this.updateSessionDeletePathOP}
							fetchSessions={this.fetchSessions}
							account={this.props.account}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							venueOptions={venues.map((venue) => {
								return {
									value: venue._id,
									label: venue.name,
									color: '#000'
								};
							})}
							venues={venues}
							sessions={this.state.event.sessions}
							sessionCount={this.state.event.sessionCount}
							loadMoreAttendees={this.loadMoreAttendees}
							loadMoreSessions={this.loadMoreSessions}
							addSession={this.addSession}
							importSessions={this.importSessions}
							groupSessions={this.groupSessions}
							deleteSessions={this.deleteSessions}
							updateSession={this.updateSession}
							customFields={this.state.event.customFields['sessions']}
							locations={locationOptions}
							eventEmails={this.state.event.emails}
							getVenues={this.getVenues}
							addNewGroup={this.addGroup}
							organization={this.props.organization}
							groupImportedSessions={this.groupImportedSessions}
							sessionsViews={this.state.event.sessionsViews}
							excludeUpdateAttendees={this.excludeUpdateAttendees}
							addCustomField={this.addCustomField}
							addNewLocation={this.addNewLocation}
							renameLocation={this.renameLocation}
							deleteLocation={this.deleteLocation}
						/>
					</Route>
					<Route path={`/:organization/events/:event/12`}>
						<EventEmails
							groups={this.state.event.groups}
							sessions={this.state.event.sessions}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							attendeeCount={this.state.event.attendeeCount}
							loadMoreAttendees={this.loadMoreAttendees}
						/>
					</Route>
					<Route path={`/:organization/events/:event/media`}>
						<EventFiles
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							refreshSectionCounts={this.props.refreshSectionCounts}
						/>
					</Route>
					<Route path={`/:organization/events/:event/reports`}>
						<EventReports
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							permittedEvent={permittedEvent}
							locations={locations}
							subscriptionAddons={this.props.subscriptionAddons}
							organization={this.props.organization}
						/>
					</Route>
					<Route path={`/:organization/events/:event/pages`}>
						<EventPages
							organization={this.props.organization}
							updatePathOp={this.updatePathOp} // UpdatePathOp
							updatePagesPathOp={this.updatePagesPathOp}
							updatePathOptionsPage={this.updatePathOptionsPage}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							account={this.props.account}
							refreshSectionCounts={this.props.refreshSectionCounts}
						/>
					</Route>
					<Route exact path={`/:organization/events/:event/12`}>
						<ApplicationSetup
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							updateSetup={this.updateSetup}
						/>
					</Route>

					<Route path={`/:organization/events/:event/engagement`}>
						<MobileApp
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							save={this.savePwaDetails}
							organization={this.props.organization}
							attendees={this.state.event.attendees}
						/>
					</Route>

					<Route exact path={`/:organization/events/:event/14`}>
						<EventScannables
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
						/>
					</Route>
					<Route exact path={`/:organization/events/:event/15`}>
						<EventInteractionPoints
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
						/>
					</Route>

					<Route path={`/:organization/events/:event/forms`}>
						<EventForms
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							fields={this.state.event.customFields.attendees}
							addNewField={this.addNewField}
							account={this.props.account}
							refreshSectionCounts={this.props.refreshSectionCounts}
							groups={this.state.event.groups}
							updatePathOptions={this.updatePathOptions}
						/>
					</Route>

					<Route path={`/:organization/events/:event/inventory`}>
						<EventInventory
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							refreshSectionCounts={this.props.refreshSectionCounts}
						/>
					</Route>
					<Route path={`/:organization/events/:event/emails`}>
						<EventCampaigns
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							refreshSectionCounts={this.props.refreshSectionCounts}
						/>
					</Route>
					<Route path={`/:organization/events/:event/22`}>
						<EventTemplates
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
						/>
					</Route>
					<Route path={`/:organization/events/:event/texting`}>
						<EventTextCampaigns
							groups={this.state.event.groups}
							sessions={this.state.event.sessions}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							refreshSectionCounts={this.props.refreshSectionCounts}
						/>
					</Route>

					<Route path={`/:organization/events/:event/notifications`}>
						<EventNotificationCampaigns
							groups={this.state.event.groups}
							sessions={this.state.event.sessions}
							event={event}
							readOnly={permittedEvent.readOnly}
							eventId={this.state.event._id}
							orgId={this.props.orgId}
							refreshSectionCounts={this.props.refreshSectionCounts}
							organization={this.props.organization}
						/>
					</Route>
				</Switch>}
				<LoadingWizard loading={!allLoaded} text="Loading event" />


				{singleUserSectionInUse && !sectionTakeoverGranted && <FieldModal isOpen={singleUserSectionInUse && !sectionTakeoverGranted}
					modalTitle="Section unavailable"
					size="small"
					bodyContent={() => {
						return (
							<div>
								{sectionTakeoverDenied ? (
									'Access denied'
								) : sectionTakeoverRequested ? (
									'Waiting for access response...'
								) : (
									`Section currently in use by ${singleUserSectionInUse.name}`
								)}
							</div>
						);
					}}
					toggle={() => window.location.assign(`../attendees`)}
					actionButton={() => {
						sectionTakeoverRequested ? this.forceTakeOver() :
							this.requestTakeOver();
					}}
					actionButtonLabel={sectionTakeoverRequested ? "Force Take Over" : "Request Take Over"}
				/>}
				{sectionControlRequested && <FieldModal isOpen={sectionControlRequested}
					size="small"
					modalTitle="Access Requested"
					bodyContent={() => {
						return (
							<div>
								Section access and control is being requested by {this.state.sectionTakeoverUserName}
							</div>
						);
					}}
					toggle={() => this.respondToTakeover(false)}
					actionButton={() => {
						this.respondToTakeover(true);
					}}
					actionButtonLabel="Allow"
					cancelOnClick={() => this.respondToTakeover(false)}
					cancelButtonLabel="Deny"
				/>}
			</div>
		);
	}
}

export default Event;
