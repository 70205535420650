import React, { Component } from 'react';

class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { classes, message, style } = this.props;
        return (
            <div style={style?style:{}}className={`alertBoxContainer ${classes?classes:''}`}>
                {message}
            </div>
        );
    }
}

export default InputField;
