import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Toggle from 'react-toggle';
import API from '../../../utils/API';

import InputField from '../../../components/inputs/inputField';
import ToggleField from '../../../components/inputs/toggleField';

class EventGroupInfo extends Component {
	render() {
		const { editedGroup, updateGroupInfo, readOnly, requiredFields } = this.props;

		return (
			<div className='sectionBlock'>
				<h2 className='blockHeader'>Basic Details</h2>
				<InputField
					label={'Name'}
					value={editedGroup.name}
					type="text"
					errorTrigger={requiredFields.name}
					required={true}
					onChange={(e) => updateGroupInfo('name', e.target.value)}
				/>

				<ToggleField
					checked={editedGroup.maxCapacityEnabled}
					label={'Enable Max Capacity'}
					icons={false}
					disabled={readOnly}
					onChange={() => updateGroupInfo('maxCapacityEnabled', !editedGroup.maxCapacityEnabled)}
				/>

				{editedGroup.maxCapacityEnabled && (
					<InputField
						label={'Max Capacity'}
						value={editedGroup.maxCapacity || ''}
						type="text"
						onChange={(e) => updateGroupInfo('maxCapacity', e.target.value)}
					/>
				)}

			</div>
		);
	}
}

export default EventGroupInfo;
