import React from 'react';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';
class SessionRemoveAttendeeModal extends React.Component {
	state = {
		submitting: false
	};

	removeAttendee = async () => {
		const { eventId, orgId, markedAttendee, session, checkedAttendees } = this.props;

		this.setState({ submitting: true })
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				attendees: checkedAttendees,
				request: 'removeAttendees'
			})
			.then((res) => {
				if (res.data) {
					const attendees = res.data.attendees.map((a) => this.props.attendees.find((at) => at._id == a));
					const moderators = res.data.moderators.map((m) => this.props.attendees.find((at) => at._id == m));
					const speakers = res.data.speakers.map((s) => this.props.attendees.find((at) => at._id == s));
					this.props.session.moderators = moderators;
					this.props.session.speakers = speakers;
					this.props.updateAttendees(attendees);
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});

		this.props.uncheckEntries();
		this.props.toggle();
	};

	render() {
		const { isOpen, markedAttendee, toggle, checkedAttendees } = this.props;
		const { submitting } = this.state;
		let count = checkedAttendees.length;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"New Attendee"}
					bodyHeaderText={<span>You are about to delete <b>{count > 1 ? `${count} attendees` : `${count} attendee`}</b> from
						this session, would you like to continue?</span>}
					bodyContent={() => {
						return ("")
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.removeAttendee()}
					isDelete={true}
				></FieldModal>
			</div>
		);
	}
}

export default SessionRemoveAttendeeModal;
