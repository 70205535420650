import React from 'react';
import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import { Modal } from 'reactstrap';
import TextAreaField from '../../../../components/inputs/textAreaField';

class EditFeedItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availablePointOptions: [],
            selectedValue: { label: '', color: '#00B8D9' },
            isCustomPoint: false,
            customLabel: '',
            description: '',
            pointSuffix: '',
            pointValue: 0,
            maxPointsAllowed: 0,
            oncePerReference: false,
            active: false,
            callToActionLabel: '',
            callToActionUrl: '',
            submitting: false,
            invalid: false,
            success: false,
            caption: props.markedItem.caption || '',
        };
    }

    updateFeedItem = async () => {

        const { eventId, orgId, markedItem, toggle, selectedGallery } = this.props;
        const { caption } = this.state;
        this.setState({ submitting: true });
        await API().patch(`Organizations/${orgId}/events/${eventId}/feedItem/${markedItem._id}`, {
            caption
        }).then((res) => {
            if (res.data) {
                let feedItem = res.data.feedItem;
                this.props.updateFeedItem(selectedGallery._id, { _id: feedItem._id, caption: feedItem.caption, updatedAt: feedItem.updatedAt }, toggle);
            }
        }).catch((err) => {
            console.log(err);
            this.setState({ submitting: false });
        });
    }

    render() {
        const { isOpen, toggle, markedItem } = this.props;
        const {
            caption
        } = this.state;


        let name = `${markedItem.attendee.firstName} ${markedItem.attendee.lastName || ''}`;

        return (
            <div>
                <Modal isOpen={isOpen} toggle={toggle} className={`gallery galleryModalFeedItem`}>
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7, zIndex: 100 }}><i className='las la-times'></i></button>
                    <div className='galleryModalContainer'>
                        <div className='main'>
                            <div className='mainContainer'>
                                {/*  <div className='nameContainer'>
                                    <div className='acr'><b>{`${markedItem.attendee.firstName[0]}${markedItem.attendee.lastName[0]}`}</b></div>
                                    <div className='name'><b>{`${markedItem.attendee.firstName} ${markedItem.attendee.lastName}`}</b></div>
                                    <div className='timeFrom'>{markedItem.timeFrom}</div>
                                </div> */}
                                <div className='galleryImage'>
                                    <div className='imageContainer'>
                                        <img src={markedItem.imageUrl} alt={''} />
                                        {caption && <div className='caption'>
                                            {caption}
                                        </div>}

                                    </div>

                                </div>
                                {/*  <div className='galleryLikeContainer'>
                                    <i className="las la-thumbs-up"></i>
                                    <div className='likes'>{`${markedItem.likes.length} likes`}</div>
                                </div> */}
                            </div>

                        </div>
                        <div className='side'>

                            <h1>Edit Image</h1>
                            <p>Edits made here will reflect on the linked attendee feed page.</p>

                            <div className="pt-30">
                                <InputField
                                    label='Attendee'
                                    value={name}
                                    disabled={true}
                                    inputIcon={<i className='las la-lock'></i>}
                                />
                            </div>


                            <div className="">
                                <TextAreaField
                                    label='Image Caption'
                                    placeholder='Add a caption...'
                                    value={caption}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 300) {
                                            this.setState({ caption: e.target.value });
                                        }
                                    }}
                                    subText={`${caption.length}/300`}
                                    rows={10}
                                    inputStyle={{ resize: 'none' }} // Add this line

                                />
                            </div>



                            <div className='modalFooter mt-50'>
                                <div className='flex'>
                                    <button className="neu ml-a" onClick={toggle}>{'Cancel'}</button>
                                    <button className={`ml-20 prim`} onClick={() => {
                                        this.updateFeedItem();
                                    }}>Submit</button>
                                </div>

                            </div>
                        </div>


                    </div>


                </Modal >

            </div >

        );
    }
}

export default EditFeedItem;
