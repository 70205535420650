import React, { Component } from 'react';
import FieldModal from '../../../components/modals/fieldModal';

class DeleteInviteModal  extends React.Component {
	state = {
		submitting: false
	};
	render() {
		const { isOpen, memberName,memberCount, selfSelected, toggle, permission, inviteCount } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={!selfSelected ? (
						`Delete Invite`
					) : (
						`You have selected yourself`
					)}
					bodyHeaderText={!selfSelected ? (
						<span>
							You are about to delete {`${memberName}`}
							<b>
								{memberCount > 0 &&
									`${memberCount} team ${memberCount > 1 ? 'members' : 'member'}`
								}
								{
									inviteCount > 0 ? `${memberCount == 0 ? '' : ' and '}${inviteCount} invite${inviteCount > 1 ? 's' : ''}` : ''
								}
							</b>, this is irreversible, would you like to continue?
						</span>
					) : (

						`If you want to leave the organization, please do so from the detail page.`

					)}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Delete...'}
					actionButton={() => {
						this.setState({ submitting: true })
						this.props.delete()
					}
					}
					actionButtonDisabled={submitting || selfSelected || (permission == false)}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default DeleteInviteModal;
