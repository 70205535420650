import React, { Fragment } from 'react';
import FieldModal from '../../../components/modals/fieldModal';

class UnpublishAccountPageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    render() {
        const { isOpen, toggle, publishPage } = this.props;

        const { submitting, } = this.state;

        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Unpublish Page"}
                    bodyHeaderText={
                        <span>
                            Are you sure you want to unpublish this page? &nbsp;
                            Unpublishing will make it unreachable from the web.
                        </span>

                    }
                    bodyDescription={' '}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Confirm' : 'Confirm...'}
                    actionButton={() => publishPage(false, toggle)}
                    actionButtonDisabled={submitting}
                ></FieldModal>
            </div>

        );
    }
}

export default UnpublishAccountPageModal;
