import React from 'react';
import API from '../../../../utils/API';
import attendees from '../../attendee/attendees';
import FieldModal from '../../../../components/modals/fieldModal';

class IncludeAttendeesModal extends React.Component {
	state = {
		submitting: false
	};

	includeAttendees = async () => {
		const { eventId, orgId, markedAttendee, session, checkedAttendees } = this.props;
		this.setState({ submitting: true })

		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/exempt`, {
				attendees: checkedAttendees,
				request: 'include'
			})
			.then((res) => {
				this.props.updateAttendees(checkedAttendees);

			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, attendees, toggle, checkedAttendees } = this.props;
		const { submitting } = this.state;

		return (


			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Include Attendee"}
					bodyHeaderText={<span>You are about to include <b>{checkedAttendees.length == 1 ? `${checkedAttendees.length} attendee` : `${checkedAttendees.length} attendees`} </b> from this session, would you
						like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Include' : 'Including...'}
					actionButton={() => this.includeAttendees()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default IncludeAttendeesModal;
