import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';


class DeleteSinglePageModal extends Component {
    state = {
        title: '',
        description: '',
        template: '',
        submitting: false,
        privateHomePageSlug: '',
        publicHomePageSlug: '',
        registrationPagePathSlug: '',
        isPrivateSlug: false,
        isPublicSlug: false,
        isRegistrationSlug: false,
        filteredSelected: [],
        processing: true
    };
    componentDidMount() {
        const { pages, checkedPages, page } = this.props;

        let isPrivateHomePageSlug = this.props.event.defaultUserPath === page.slug;
        let isPublicHomePageSlug = this.props.event.defaultPublicPath === page.slug;
        let isregistrationPagePathSlug = this.props.event.registrationPageSlug === page.slug;

        if (isPrivateHomePageSlug) {
            this.setState({ isPrivateSlug: isPrivateHomePageSlug });
        }
        if (isPublicHomePageSlug) {
            this.setState({ isPublicSlug: isPublicHomePageSlug });
        }
        if (isregistrationPagePathSlug) {
            this.setState({ isRegistrationSlug: isregistrationPagePathSlug });
        }


        this.setState({ processing: false });
    }

    deletePage = async () => {

        const { isPrivateSlug, isPublicSlug, isRegistrationSlug } = this.state;
        const { orgId, eventId, page, toggle } = this.props;

        if (!isPrivateSlug && !isPublicSlug && !isRegistrationSlug) {

            await API()
                .post(`Organizations/${orgId}/events/${eventId}/pages/delete`, { pages: [page._id] })
                .then((res) => {
                    this.props.deleteSinglePage(page._id);
                    this.props.history.push(`../pages`);
                })
                .catch((e) => { });
        }
    };

    render() {
        const { isOpen, toggle, isDeleting, count, page } = this.props;
        const { isRegistrationSlug, processing, isPublicSlug, isPrivateSlug } = this.state;

        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Page"}
                    bodyHeaderText={isRegistrationSlug ? <span>The page selected is being used in{' '}
                        <b>Setup -> Event Access -> Registration Page Path</b>. Please update to a different
                        page to delete this page!</span> : isPublicSlug ? <span>The page selected is being used in{' '}
                            <b>Applications -> Setup -> Public Home Page</b>. Please update to a different page to
                            delete this page!</span> : isPrivateSlug ? <span>The page selected is being used in{' '}
                                <b>Applications -> Setup -> Private Home Page</b>. Please update to a different page to
                                delete this page!</span> : <span>You are about to delete{' '}
                        <b>
                            {page.title}
                        </b>, this is irreversible, would you like to continue?</span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={'Delete'}
                    actionButton={() => this.deletePage()}
                    actionButtonDisabled={isDeleting || isPrivateSlug || isPublicSlug || isRegistrationSlug}
                    isDelete={true}

                ></FieldModal>

            </div>

        );
    }
}

export default withRouter(DeleteSinglePageModal);
