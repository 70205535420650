import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import API from '../../../utils/API';

import moment from 'moment';
import { Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import InputField from '../../../components/inputs/inputField.js';
import TextAreaField from '../../../components/inputs/textAreaField';
import TimeField from '../../../components/inputs/timeField';
class EventAttendeeTravel extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.state = {
			editing: false,
			editedAttendee: props.editedAttendee,
			genExpanded: true,
			popoverOpen: false
		};
	}

	toggleEditing = () => {
		this.setState({ editedAttendee: this.props.attendee, editing: true });
	};
	toggle = () => {
		this.setState({
			popoverOpen: true
		});
	}
	toggleOff = () => {
		this.setState({
			popoverOpen: false
		});
	}

	saveInfo = async () => {
		const { eventId, orgId } = this.props;
		const { editedAttendee } = this.state;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${editedAttendee._id}`, {
				...editedAttendee
			})
			.then((res) => {
				if (res.data) {
					this.props.updateAttendee(editedAttendee._id, res.data);
					this.setState({ editing: false, editedAttendee: res.data });
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	//'PATCH /Organizations/:organization/events/:event/attendees/:attendee'

	cancelEdit = () => {
		this.setState({ editedAttendee: this.props.attendee, editing: false });
	};

	render() {
		const { editing, genExpanded } = this.state;
		const { readOnly, editedAttendee } = this.props;
		const arrivalDate = editedAttendee.travelInformation.arrivalDate
			? moment(new Date(editedAttendee.travelInformation.arrivalDate)).format('YYYY-MM-DD')
			: '';
		const arrivalTime = editedAttendee.travelInformation.arrivalTime;
		const departureDate = editedAttendee.travelInformation.departureDate
			? moment(new Date(editedAttendee.travelInformation.departureDate)).format('YYYY-MM-DD')
			: '';
		const departureTime = editedAttendee.travelInformation.departureTime;

		//convert to MM/DD/YYYY and create variables
		const flightAririval = editedAttendee.travelInformation.arrivalDate
			? moment(new Date(editedAttendee.travelInformation.arrivalDate)).format('MM/DD/YYYY')
			: '';
		const hotelCheckin = editedAttendee.roomingInformation.checkInDate
			? moment(new Date(editedAttendee.roomingInformation.checkInDate)).format('MM/DD/YYYY')
			: '';
		const eventArrival = editedAttendee.roomingInformation.arrivalDate
			? moment(new Date(editedAttendee.roomingInformation.arrivalDate)).format('MM/DD/YYYY')
			: '';
		// Check if empty 
		const Flag1 = flightAririval == 'Invalid date' ? true : false
		const Flag2 = eventArrival == 'Invalid date' ? true : false
		const Flag3 = hotelCheckin == 'Invalid date' ? true : false
		const Flag4 = hotelCheckin == 'Invalid date' && eventArrival == 'Invalid date';
		//Check if Dates are equal
		const Flag5 = hotelCheckin == flightAririval && flightAririval == eventArrival && flightAririval == hotelCheckin;
		//If empty show No Date else show date
		const FlightArrival = Flag1 ? "No Date Set - Flight Arrival" : flightAririval + " - Flight Arrival";
		const EventArrival = Flag2 ? "No Date Set - Event Arrival" : eventArrival + " - Event Arrival";
		const HotelCheckin = Flag3 ? "No Date Set - Hotel Check In" : hotelCheckin + " - Hotel Check In";
		// Set Warning Flag
		const ShowWarning = Flag1 || Flag5;


		//Icon logic hover
		let icon = ShowWarning ? false : <span className='c-pointer p-7' onMouseEnter={this.toggle} onMouseLeave={this.toggleOff} ><i id="Popover1" onClick={this.toggleOff} class="las la-lg la-exclamation-triangle red" />
		</span>;

		return (
			<>
				<h2 className='blockHeader '>Arrival Information</h2>

				<div className='blocksContainer'>
					<div className='sectionBlock mr-25'>
						<div style={{ paddingTop: 5 }}>
							<InputField
								label={'Origin City'}
								value={editedAttendee.travelInformation.originCity}
								className="detailsInput"
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											originCity: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Origin State'}
								value={editedAttendee.travelInformation.originState}
								className="detailsInput"
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											originState: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Arrival City'}
								value={editedAttendee.travelInformation.arrivalCity}
								className="detailsInput"
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalCity: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Arrival State'}
								value={editedAttendee.travelInformation.arrivalState}
								className="detailsInput"
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalState: e.target.value
										}
									});
								}}
							/>

							{!ShowWarning && <Popover placement="left" className="popoverWarrning" style={{ width: 229 }} isOpen={this.state.popoverOpen} target="Popover1" toggle={this.toggle} >
								<PopoverBody style={{ paddingBottom: 15 }}> Notice: Date mismatch </PopoverBody>
								<PopoverBody className="popoverWarrningBody">
									<div>{FlightArrival} </div>
									<div>{EventArrival} </div>
									<div>{HotelCheckin} </div>
								</PopoverBody>
							</Popover>}
							<InputField
								label={'Arrival Date'}
								warningIcon={icon}
								className={`detailsInput`}
								timeFormat={false}
								type={'date'}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalDate: moment(e.target.value).format('MM/DD/YYYY')
										}
									});
								}}
								value={arrivalDate}
							/>

							{/* <InputField
								label={'Arrival Time'}
								value={arrivalTime}
								type={'time'}
								inputIcon={<i className="las la-clock" />}
								onChange={(e) => {
									console.log(e.target.value);

									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalTime: moment(
												` ${moment().format('MM/DD/YYYY')} ${e.target.value}`
											).format('hh:mm a')
										}
									});
								}}
							/> */}
							<TimeField
								label={'Arrival Time'}
								value={arrivalTime}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalTime: e
										}
									});
								}}
							/>

							<InputField
								label={'Arrival Carrier'}
								value={editedAttendee.travelInformation.arrivalCarrier}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalCarrier: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Arrival Flight'}
								value={editedAttendee.travelInformation.arrivalFlight}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalFlight: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Arrival Ticketed'}
								value={editedAttendee.travelInformation.arrivalTicketed}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalTicketed: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Arrival Class of Service'}
								value={editedAttendee.travelInformation.arrivalClassOfService}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalClassOfService: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Arrival Locator'}
								value={editedAttendee.travelInformation.arrivalLocator}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											arrivalLocator: e.target.value
										}
									});
								}}
							/>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div className="sectionSubtitle">Departure Information</div>
							</div>

							<InputField
								label={'Departure City'}
								value={editedAttendee.travelInformation.departureCity}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureCity: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Departure State'}
								value={editedAttendee.travelInformation.departureState}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureState: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Destination City'}
								value={editedAttendee.travelInformation.destinationCity}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											destinationCity: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Destination State'}
								value={editedAttendee.travelInformation.destinationState}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											destinationState: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Departure Date'}
								type={'date'}
								value={departureDate}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureDate: moment(e.target.value).format('MM/DD/YYYY')
										}
									});
								}}
							/>

							{/* 	<InputField
								label={'Departure Time'}
								value={departureTime}
								type={'time'}
								inputIcon={<i className="las la-clock" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureTime: moment(
												` ${moment().format('MM/DD/YYYY')} ${e.target.value}`
											).format('hh:mm a')
										}
									});
								}}
							/> */}
							<TimeField
								label={'Departure Time'}
								value={departureTime}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureTime: e
										}
									});
								}}
							/>



							<InputField
								label={'Departure Carrier'}
								value={editedAttendee.travelInformation.departureCarrier}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureCarrier: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Departure Flight'}
								value={editedAttendee.travelInformation.departureFlight}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureFlight: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Departure Ticketed'}
								value={editedAttendee.travelInformation.departureTicketed}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureTicketed: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Departure Class of Service'}
								value={editedAttendee.travelInformation.departureClassOfService}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureClassOfService: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Departure Locator'}
								value={editedAttendee.travelInformation.departureLocator}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.travelInformation,
											departureLocator: e.target.value
										}
									});
								}}
							/>
							<TextAreaField
								placeholder={''}
								value={editedAttendee.travelInformation.notes}
								label="Notes"
								type="text"
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editTravelFields({
										...this.props.editedAttendee,
										travelInformation: {
											...this.props.editedAttendee.roomingInformation,
											notes: e.target.value
										}
									});
								}}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default EventAttendeeTravel;
