import React, { } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import InputField from '../inputs/inputField';
import TextEditor from '../../views/pages/components/textEditor';
import { Link } from 'react-router-dom';


class TaskCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchText: '',
            searching: false,
            message: props.message,
            editedMessage: props.message,
            toggleEditComment: false,
            editCount: 0

        };
    }

    searchEntries = (searchText) => {
        this.setState({
            searching: searchText != '' ? true : false,
            searchText: searchText ? searchText : '',
        });
    };


    updateEditComment = (value) => {
        this.setState({ editedMessage: value, toggleEditComment: true });
    }

    toggleEditComment = () => {
        this.setState({ toggleEditComment: !this.state.toggleEditComment });
    }

    cancelEditComment = () => {
        this.setState({ toggleEditComment: false, editedMessage: this.state.message, editCount: this.state.editCount + 1 });
    }

    saveEdit = () => {
        const { id, updateMessage } = this.props;

        updateMessage && updateMessage(id, this.state.editedMessage, () => {

            this.setState({ toggleEditComment: false, message: this.state.editedMessage, editCount: this.state.editCount + 1 });
        });
    }


    render() {
        const {
            //message,
            author,
            authorAcronym,
            assignedTo,
            users,
            id,
            completed,
            myTaskView,
            link,
            description,
            settings,
            key,
            enableEditComment,
            authorImage
        } = this.props;
        const { searchText,
            toggleEditComment,
            editedMessage,
            editCount
        } = this.state;
        let filteredUsers = [];
        if (users) {
            filteredUsers = users;
            //Searching by text from input
            if (searchText.length > 0) {
                const searchTerms = searchText.toLowerCase().split(' ');
                searchTerms.forEach((term) => {
                    if (term != '') {
                        filteredUsers = filteredUsers.filter((entry) => {
                            let found = false;
                            Object.keys(entry).forEach((key) => {
                                if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                    found = true;
                                }
                            });
                            return found;
                        });
                    }
                });
            }
        }
        let emptyProfilePictureA = !assignedTo || (assignedTo && (!assignedTo.profilePicture || assignedTo.profilePicture === ''))
        return (
            <div>
                <div className="commentCard mb-25">

                    <div className="commentCardHeader flex">
                        <div className='flex'>
                            <div className='abbreviation'>
                                <p className="small "><strong>{authorAcronym}</strong></p>
                            </div>
                            <div className='ml-5 flex aic name '>
                                <p>{author}</p>
                            </div>
                        </div>

                        <div className='flex ml-a jcc aic '>
                            {!myTaskView ?
                                !assignedTo ?
                                    <UncontrolledDropdown direction="left" className='flex jcc aic' inNavbar style={{ marginRight: 22 }}>
                                        <DropdownToggle className="columnToggle " style={{ width: '100%', minHeight: 22, height: 22, }}>
                                            <div
                                                className=''
                                                style={{

                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '5px',
                                                    padding: '4px 9px',
                                                    height: '22px',
                                                    color: '#999999',
                                                }}>
                                                <p className="small">Assign</p>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className="columnDropdown" style={{ minWidth: 280, maxHeight: 275, padding: '10px' }}>
                                            <div className='searchUserContainer'>
                                                <InputField
                                                    style={{ height: 30, fontSize: 12 }}
                                                    placeholder="Search team member to assign"
                                                    onChange={(e) => {
                                                        clearTimeout(this.searchTimeout);
                                                        this.setState({ searchTerm: e.target.value },
                                                            () => {
                                                                this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
                                                            });

                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="userListContainer"
                                            >
                                                {users && filteredUsers.map((user, index) => {
                                                    let emptyProfilePicture = !user || (user && (!user.profilePicture || user.profilePicture === ''))
                                                    return (
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.props.assign(id, user);
                                                            }
                                                            }
                                                        >
                                                            <div key={index} className='flex aic jcsb  pv-10'>
                                                                <div className='flex aic'>
                                                                    {emptyProfilePicture ? (
                                                                        <div className='abbreviation small'>
                                                                            <p className=" "><strong>{`${user.firstName[0]}${user.lastName[0]}`}</strong></p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='abbreviation small '>
                                                                            <img src={user.profilePicture} className=' abbreviation small ' />
                                                                        </div>
                                                                    )
                                                                    }
                                                                    <div className='ml-5 flex aic name '>
                                                                        <p>{`${user.firstName} ${user.lastName}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </DropdownItem>

                                                    )
                                                }
                                                )}

                                            </div>

                                        </DropdownMenu>

                                    </UncontrolledDropdown>

                                    :
                                    <UncontrolledDropdown direction="left" inNavbar style={{ marginRight: 22 }}>
                                        <DropdownToggle className="columnToggle" style={{ width: '100%', minHeight: 22, height: 22 }}>
                                            <div className='flex jcc aic assignedToContainer'
                                            >

                                                {emptyProfilePictureA ?
                                                    (
                                                        <div
                                                            className='assignedToAbv'
                                                        >
                                                            <b>{`${assignedTo.firstName.toUpperCase()[0]}${assignedTo.lastName.toUpperCase()[0]}`}</b>
                                                        </div>
                                                    )
                                                    : (
                                                        <div className='assignedToAbv'>
                                                            <img src={assignedTo.profilePicture} className=' abbreviation small ' />
                                                        </div>


                                                    )
                                                }
                                                <div
                                                    className='flex jcc aic'
                                                >

                                                    <div>
                                                        <p className='small gray'>{`${assignedTo.firstName} ${assignedTo.lastName}`}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className="columnDropdown" style={{ minWidth: 280, maxHeight: 275, padding: '10px' }}>
                                            <div className='searchUserContainer'>
                                                <InputField
                                                    style={{ height: 30, fontSize: 12 }}
                                                    placeholder="Search team member to assign"
                                                    onChange={(e) => {
                                                        clearTimeout(this.searchTimeout);
                                                        this.setState({ searchTerm: e.target.value },
                                                            () => {
                                                                this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
                                                            });

                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="userListContainer"
                                            >
                                                {users && filteredUsers.map((user, index) => {
                                                    let emptyProfilePicture = !user || (user && (!user.profilePicture || user.profilePicture === ''))
                                                    return (
                                                        <DropdownItem
                                                            onClick={() => {
                                                                this.props.assign(id, user);
                                                            }
                                                            }
                                                        >
                                                            <div key={index} className='flex aic jcsb  pv-10'>
                                                                <div className='flex aic'>
                                                                    {emptyProfilePicture ? (
                                                                        <div className='abbreviation small'>
                                                                            <p className=" "><strong>{`${user.firstName[0]}${user.lastName[0]}`}</strong></p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='abbreviation small '>
                                                                            <img src={user.profilePicture} className=' abbreviation small ' />
                                                                        </div>
                                                                    )
                                                                    }
                                                                    <div className='ml-5 flex aic name '>
                                                                        <p>{`${user.firstName} ${user.lastName}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </DropdownItem>

                                                    )
                                                }
                                                )}

                                            </div>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                : ''}
                            {!completed ?
                                < div
                                    className='ml-15 mr-15 flex jcc aic c-pointer'
                                    style={{
                                        padding: 2
                                    }}
                                    onClick={() => {
                                        this.props.updateStatus(id, true);
                                    }}
                                >
                                    <div className='status'>
                                    </div>
                                </div>
                                :
                                <div
                                    onClick={() => {

                                        this.props.updateStatus(id, false);
                                    }}
                                    className='ml-15 mr-15 flex jcc aic c-pointer activeStatus'
                                    style={{
                                    }}
                                >

                                    <i className="las la-check-circle"></i>
                                </div>

                            }


                            {link ? (
                                <div className="blackButton">
                                    <Link to={link}>
                                        <div
                                            className="flex jcc aic ml-10">
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </Link>
                                </div>
                            ) : (
                                <div className='settings'>
                                    <UncontrolledDropdown direction="left" inNavbar >
                                        <DropdownToggle className="columnToggle" style={{ width: 30, height: 30 }}>
                                            <div className='icon'>
                                                <i className="las la-ellipsis-v" />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className="columnDropdown" style={{ minWidth: 230 }}>
                                            <div className='ph-20'>
                                                {enableEditComment && <div>
                                                    <DropdownItem
                                                        className={`moreViewsPopoverItemMenuItem`}
                                                        style={{ textTransform: 'capitalize', padding: '7px 10px' }}
                                                        onClick={() => {
                                                            this.toggleEditComment();
                                                        }}
                                                    >

                                                        Edit Comment
                                                    </DropdownItem>
                                                </div>}
                                                {settings.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <DropdownItem
                                                                className={`moreViewsPopoverItemMenuItem`}
                                                                style={{ textTransform: 'capitalize', padding: '7px 10px' }}
                                                                onClick={() => {
                                                                    item.onClick && item.onClick(id);
                                                                }}
                                                            >

                                                                {item.label}
                                                            </DropdownItem>
                                                        </div>
                                                    )
                                                })}
                                            </div>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>)}

                        </div>
                    </div>
                    <div
                        className="timeDate ml-35 ">
                        {description}
                    </div>
                    <div
                        className="pt-20 commentCardBody ml-35">

                        <TextEditor
                            id={`comment-${id}`}
                            //codeViewEnabled={true}
                            options={[]}
                            placeholder={`Add a new comment...`}
                            staticToolbar={true}
                            value={editedMessage}
                            htmlOnly={!toggleEditComment && true}
                            key={`${key}-${editCount}`}
                            hideToolbar={!toggleEditComment}
                            onChange={this.updateEditComment}

                        />

                        {toggleEditComment && <div className='flex fcc aic ml-a pt-10'
                            style={{
                                flexFlow: 'row-reverse',
                            }}
                        >
                            <button className='prim min'
                                onClick={() => {
                                    this.saveEdit();
                                }}
                            >
                                Save
                            </button>
                            <button className='neu mr-25  min'
                                onClick={() => {
                                    this.cancelEditComment();
                                }}>
                                Cancel
                            </button>
                        </div>
                        }
                    </div>

                </div>
            </div >
        );
    }
}



export default TaskCard;
