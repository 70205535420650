import React, { Component, Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Row, Button, Input } from 'reactstrap';
import Select from 'react-select';
import API from '../../../utils/API';
import { Trash2, Plus, CheckCircle, X } from 'react-feather';
import moment from 'moment';

class EventSessionEmails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			emails: props.session.emailReminders,
			showAddEmailModal: false,
			showRemoveEmailModal: false,
			markedEmail: null
		};
	}

	toggleAddEmail = () => {
		this.setState({ showAddEmailModal: !this.state.showAddEmailModal });
	};

	addEmail = (email) => {
		const emails = this.state.emails;
		emails.push(email);
		this.setState({ emails: emails });
	};

	toggleRemoveEmail = (email) => {
		this.setState({
			showRemoveEmailModal: !this.state.showRemoveEmailModal,
			markedEmail: email ? email._id : null
		});
	};

	removeEmail = (emailId) => {
		const emails = this.state.emails.filter((e) => e._id !== emailId);
		this.setState({ emails: emails });
	};

	toggleEmail = async (emailReminderId) => {
		const { event, orgId, session } = this.props;
		await API()
			.patch(`Organizations/${orgId}/events/${event._id}/sessions/${session._id}`, {
				request: 'toggleEmail',
				emailReminderId: emailReminderId
			})
			.then((res) => {
				console.log(res);
				if (res.data) {
					const emails = this.state.emails.map((e) => {
						if (e._id === emailReminderId) e.active = !e.active;
						return e;
					});
					this.props.updateSession(session._id, res.data);
					this.setState({ emails: emails });
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	EmailItem = (email, index) => {
		return (
			<tr key={index}>
				<td className="text-truncate">{email.name}</td>
				<td className="text-truncate">{moment(email.scheduledTime).format('MM/DD/YYYY hh:mm a')}</td>
				<td className="text-truncate">
					<a onClick={() => this.toggleEmail(email._id)}>{email.active ? 'YES' : 'NO'}</a>
				</td>
				<td style={{ width: '50px' }}>
					<Trash2 size={14} color="red" onClick={() => this.toggleRemoveEmail(email, index)} />
				</td>
			</tr>
		);
	};

	render() {
		const { emails, showAddEmailModal, showRemoveEmailModal, markedEmail } = this.state;
		return (
			<div>
				<Table responsive className="innerSectionTable">
					<thead>
						<tr>
							<th>Template Name</th>
							<th>Scheduled Date & Time</th>
							<th>Active</th>
							<th style={{ width: '50px' }}>
								<Plus size={14} color="blue" onClick={this.toggleAddEmail} />
							</th>
						</tr>
					</thead>
					<tbody style={{}}>{emails.map((email, index) => this.EmailItem(email, index))}</tbody>
				</Table>

				<SessionAddEmailModal
					isOpen={showAddEmailModal}
					emails={this.props.eventEmails}
					addEmail={this.addEmail}
					toggle={this.toggleAddEmail}
					session={this.props.session}
					eventId={this.props.event._id}
					orgId={this.props.orgId}
					sessionEmails={this.props.session.emailReminders}
					updateSession={this.props.updateSession}
				/>
				<SessionRemoveEmailModal
					isOpen={showRemoveEmailModal}
					remove={this.removeEmail}
					toggle={this.toggleRemoveEmail}
					emailId={markedEmail}
					eventId={this.props.event._id}
					orgId={this.props.orgId}
					updateSession={this.props.updateSession}
					emails={this.props.eventEmails}
					session={this.props.session}
				/>
			</div>
		);
	}
}

export default EventSessionEmails;

class SessionAddEmailModal extends Component {
	constructor(props) {
		super(props);
		const emailNames = props.emails
			.filter((email) => !props.sessionEmails.find((e) => e._id === email._id))
			.map((email) => {
				return { value: email._id, label: email.name, color: '#00B8D9' };
			});
		const sDate = moment(this.props.session.date).format('YYYY-MM-DD');
		this.state = {
			name: '',
			defaultEmailNames: [
				...props.emails.map((email) => {
					console.log(email);
					return { value: email._id, label: email.name, color: '#00B8D9' };
				})
			],
			emailNames: emailNames,
			scheduledDate: sDate,
			scheduledTime: moment(`${sDate} ${this.props.session.startTime}`).format('HH:mm'),
			selectedValue: { label: '', color: '#00B8D9' },
			emailSubject: '',
			added: 0,
			submitting: false,
			invalid: false,
			success: false,
			attendeeFilter: { label: 'All Attendees', value: 'all' }
		};
	}

	reset = (e) => {
		this.setState({
			name: '',
			defaultEmailNames: [
				...this.props.emails.map((email) => {
					return { value: email._id, label: email.name, color: '#00B8D9' };
				})
			],
			emailNames: [
				...this.props.emails.map((email) => {
					return { value: email._id, label: email.name, color: '#00B8D9' };
				})
			],
			selectedValue: { label: '', color: '#00B8D9' },
			submitting: false,
			invalid: false,
			success: false
		});
		this.props.toggle();
	};

	handleEmailChosen = (e) => {
		this.setState({ selectedValue: e });
		/* if (e.value) {
			this.setState({
				groupNames: { 0: e.value }
			});
		} */
	};

	add = async () => {
		const { eventId, orgId, session } = this.props;
		const emailId = this.state.selectedValue.value;
		const attendeeFilter = this.state.attendeeFilter.value;
		const email = this.props.emails.find((e) => e._id == emailId);
		const scheduledTime = moment(
			`${moment(this.state.scheduledDate).format('MM/DD/YYYY')} ${this.state.scheduledTime}`
		).format('MM/DD/YYYY hh:mm a');
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				emailId: emailId,
				request: 'addEmail',
				emailSubject: this.state.emailSubject,
				reminderTime: this.state.scheduledDate,
				reminderTime: scheduledTime,
				emailAttendeeFilter: attendeeFilter
			})
			.then((res) => {
				console.log(res);
				if (res.data) {
					const addedEmail = res.data.emailReminders[res.data.emailReminders.length - 1];
					this.props.addEmail({
						_id: addedEmail._id,
						name: email.name,
						scheduledTime: scheduledTime,
						active: addedEmail.active
					});
					this.props.updateSession(session._id, res.data);
					this.setState({ submitting: false });
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen } = this.props;
		const {
			emailNames,
			submitting,
			invalid,
			selectedValue,
			emailSubject,
			scheduledTime,
			scheduledDate,
			attendeeFilter
		} = this.state;

		return (
			<Modal isOpen={isOpen} toggle={this.reset} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="calibreBold fs-30">Add Email Reminder</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<div className="formField">
						<label className="displayLabel">Email Template Name</label>
						<Select
							className="modalInput"
							classNamePrefix="select"
							value={selectedValue}
							name="emailName"
							options={emailNames}
							required
							onChange={this.handleEmailChosen}
						/>
					</div>
					<div className="formField">
						<label className="displayLabel">Email Subject</label>
						<div className="basic-single col-8" style={{ padding: 15 }}>
							<Input
								className="modalInput"
								style={{ width: '100%' }}
								type="text"
								value={emailSubject}
								onChange={(e) => {
									this.setState({ emailSubject: e.target.value });
								}}
								required
							/>
						</div>
					</div>
					<Row>
						<div className="col-4">
							<label className="displayLabel" style={{ marginLeft: 0, marginRight: 0 }}>
								Date
							</label>
						</div>
						<div className="basic-single col-8" style={{ padding: 15 }}>
							<Input
								className="basic-single"
								style={{ width: '100%' }}
								type="date"
								value={scheduledDate}
								onChange={(e) => {
									console.log(e.target.value);
									this.setState({ scheduledDate: e.target.value });
								}}
								required
							/>
						</div>
					</Row>
					<Row>
						<div className="col-4">
							<label className="displayLabel" style={{ marginLeft: 0, marginRight: 0 }}>
								Time
							</label>
						</div>
						<div className="basic-single col-8" style={{ padding: 15 }}>
							<Input
								className="basic-single"
								style={{ width: '100%' }}
								type="time"
								value={scheduledTime}
								onChange={(e) => this.setState({ scheduledTime: e.target.value })}
								required
							/>
						</div>
					</Row>
					<Row>
						<label className="displayLabel" style={{ marginLeft: 0, marginRight: 0 }} className="col-4">
							Attendee Filter
						</label>
						<Select
							className="basic-single col-8"
							classNamePrefix="select"
							value={attendeeFilter}
							name="attendeeFilter"
							options={[
								{ label: 'All Attendees', value: 'all' },
								{ label: 'Attended Only', value: 'attendedOnly' },
								{ label: 'No Shows Only', value: 'noShowOnly' }
							]}
							required
							onChange={(e) => this.setState({ attendeeFilter: e })}
						/>
					</Row>
					<Row>
						<p className="col-12" style={{ textAlign: 'center', margin: 15 }}>
							You are adding a scheduled email to this session
						</p>
					</Row>
					{invalid && <span style={{ color: 'red' }}>Email name is required</span>}
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionSave m-0"
						disabled={submitting}
						onClick={() => {
							this.setState({ submitting: true });
							if (selectedValue.name !== '') {
								this.add(selectedValue, this.reset);
							} else {
								this.setState({ invalid: true, submitting: false });
							}
						}}
					>
						<i className="las la-check-circle" />

						<div className="calibreBold fs-16">{!submitting ? 'Submit' : 'Scheduling Email...'}</div>
					</Button>
					<Button outline onClick={this.reset} className="modalButton actionButton actionOutline mb-0 ml-a">
						<i className="las la-times" />
						<div className="calibreBold fs-16">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
class SessionRemoveEmailModal extends React.Component {
	state = {
		submitting: false
	};

	removeEmail = async () => {
		const { eventId, orgId, emailId, session } = this.props;
		console.log(emailId);
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				emailReminderId: emailId,
				request: 'removeEmail'
			})
			.then((res) => {
				console.log(res);
				if (res.data) {
					this.props.remove(emailId);
					this.props.updateSession(session._id, res.data);
					this.setState({ submitting: false });
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen } = this.props;
		const { submitting } = this.state;
		return (
			<Modal isOpen={isOpen} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="calibreBold fs-30">Delete Email</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<p className="calibreRegular">
						You are about to delete a schedule session email<br />
						Would you like to continue?
					</p>
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionDelete m-0"
						disabled={submitting}
						onClick={this.removeEmail}
					>
						<i className="las la-trash" />
						<div className="calibreBold fs-16">Yes, Delete</div>
					</Button>
					<Button
						outline
						onClick={this.props.toggle}
						className="modalButton actionButton actionOutline mb-0 ml-a"
					>
						<i className="las la-times" />
						<div className="calibreBold fs-16">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
