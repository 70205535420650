import React, { Fragment } from 'react';

import API from '../../../../utils/API';


import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import SelectField from '../../../../components/inputs/selectField';
import { withRouter } from 'react-router-dom';

class AddTeamMemberModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            requiredFields: { name: false },
            users: [],
            selectedUser: '',
            mappedTeam: []
        };
    }

    componentDidMount() {
        let fixedUsers = [];
        const { orgId, users, session } = this.props;
        let team = session.team || [];
        users.map((user) => {
            let firstName = user.firstName ? user.firstName : '';
            let lastName = user.lastName ? user.lastName : '';
            let name = firstName + ' ' + lastName;
            if (!team.includes(user._id)) {
                fixedUsers.push({ label: name, value: user._id })
            }
        })

        let mappedTeam = team.map((member) => {
            let memberData = users.find((user) => user._id === member);
            return memberData;
        }).filter((member) => member);

        this.setState({ users: fixedUsers, mappedTeam: mappedTeam, team: team })
    }

    handleUserChange = (e) => {
        const { users, mappedTeam, team } = this.state;

        let selectedUser = e.value;
        let tempTeam = team;
        if (!tempTeam.includes(selectedUser)) {
            tempTeam.push(selectedUser);
        }

        this.updateTeam(tempTeam, () => {
            this.props.updateSessionTeam(tempTeam);
            let team = tempTeam;
            let mappedTeam = tempTeam.map((memberId) => {
                let memberData = this.props.users.find((user) => user._id === memberId);
                return memberData;
            })
            let filteredUsers = this.state.users.filter((user) => !team.includes(user.value));
            this.setState({ mappedTeam: mappedTeam, team: team, users: filteredUsers, })
        })
    };

    updateTeam = async (updateTeam, callback) => {
        const { orgId, eventId, session } = this.props;

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
                request: 'updateTeam',
                team: updateTeam
            })
            .then((res) => {
                if (res.data) {
                    callback && callback();
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    removeTeamMember = (memberId) => {
        const { team } = this.state;
        let tempTeam = team;
        let fixedUsers = [];

        tempTeam = tempTeam.filter((member) => member !== memberId);
        this.updateTeam(tempTeam, () => {
            this.props.updateSessionTeam(tempTeam);
            let team = tempTeam;
            let mappedTeam = tempTeam.map((memberId) => {
                let memberData = this.props.users.find((user) => user._id === memberId);
                return memberData;
            })
            let users = this.props.users.map((user) => {
                let firstName = user.firstName ? user.firstName : '';
                let lastName = user.lastName ? user.lastName : '';
                let name = firstName + ' ' + lastName;
                if (!team.includes(user._id)) {
                    fixedUsers.push({ label: name, value: user._id });
                }
            });

            this.setState({ mappedTeam: mappedTeam, team: team, users: fixedUsers })
        }
        )
    }

    render() {
        const { isOpen, toggle, sessionName } = this.props;
        const { submitting, name, requiredFields, users, selectedUser, mappedTeam } = this.state;

        return (
            <div>

                <FieldModal
                    size="medium"
                    isOpen={isOpen}
                    modalTitle={"Team Members"}
                    bodyHeaderText={`Manage which team members will see this session in their personal agenda.`}
                    bodyContent={() => {
                        return (
                            <div>
                                <SelectField
                                    label="Team Members"
                                    options={users}
                                    onChange={this.handleUserChange}
                                    value={''}
                                    placeholder="Select Team Members"
                                />
                                <div className="pt-5">

                                    {mappedTeam.map((member) => {
                                        //capitalize first letter of permission
                                        let permission = member.permission;
                                        permission = permission.charAt(0).toUpperCase() + permission.slice(1);
                                        let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
                                        return (
                                            <div className="flex aic mb-25"
                                                style={{ justifyContent: 'space-between' }}
                                            >

                                                <div className='flex aic'>
                                                    <div className={emptyProfilePicture ? "acronymCircle mr-5" : "profilePicture mr-5"}
                                                    >
                                                        <p>
                                                            {emptyProfilePicture ? `${member.firstName.toUpperCase()[0]}${member.lastName.toUpperCase()[0]}`
                                                                :
                                                                <img src={member.profilePicture} className="acronymCircle profilePicture" />
                                                            }
                                                        </p>
                                                    </div>
                                                    <span className=''>{member.firstName} {member.lastName}</span><span className="ml-5 grey">| {permission}</span>
                                                </div>
                                                <div
                                                    className="ml-1 grey c-pointer mr-15"
                                                    onClick={() => this.removeTeamMember(member._id)}
                                                ><i className="las la-times-circle"></i></div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </div>)
                    }}
                    toggle={toggle}
                    cancelButtonLabel={'Close'}
                    actionButtonDisabled={submitting}
                ></FieldModal>
            </div>

        );
    }
}

export default withRouter(AddTeamMemberModal);
