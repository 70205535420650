import React, { Fragment } from 'react';
import API from '../../../utils/API';
import InputField from '../../../components/inputs/inputField';
import FieldModal from '../../../components/modals/fieldModal';
class RenameAccountPageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.page.title ? this.props.page.title : '',
            submitting: false,
            requiredFields: { title: false }
        };

    }

    handleNameChange = (p) => {
        this.setState({
            title: p.target.value
        });
    };



    updateTitle = async () => {
        const { title } = this.state;
        const { orgId, page } = this.props;

        if (title) {
            this.setState({ requiredFields: { title: false } });

            await API()
                .patch(`Organizations/${orgId}/accountPages/${page._id}`, { title })
                .then((res) => {
                    if (res.data) {
                        this.props.updateTitle(title, () => {
                            this.props.toggle();
                        });

                    }
                })
                .catch((e) => {
                    this.setState({ loading: false, editing: false });
                });
        } else {

            this.setState({ requiredFields: { title: true } });


        }

    };

    render() {
        const { isOpen, toggle } = this.props;
        const { submitting, title, requiredFields } = this.state;
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Rename Page"}
                    bodyHeaderText={'Enter a new title for your Page.'}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <InputField
                                    label="title"
                                    value={title}
                                    required={true}
                                    errorTrigger={requiredFields.title}
                                    onChange={this.handleNameChange}
                                />
                            </div>)

                    }}

                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
                    actionButton={() => this.updateTitle()}
                    actionButtonDisabled={submitting}
                ></FieldModal>
            </div>

        );
    }
}

export default RenameAccountPageModal;
