import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';
import attendees from '../../attendee/attendees';

class ExcludeAttendeesModal extends React.Component {
	state = {
		submitting: false
	};

	excludeAttendees = async () => {
		const { eventId, orgId, markedAttendee, session, checkedAttendees } = this.props;
		this.setState({ submitting: true })
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/exempt`, {
				attendees: checkedAttendees,
				request: 'exclude'
			})
			.then((res) => {
				this.props.excludeUpdateAttendees(checkedAttendees, session._id);
				this.props.toggle();
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, attendees, toggle, checkedAttendees } = this.props;
		const { submitting } = this.state;

		return (
			<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={"Exclude Attendees"}
				bodyHeaderText={<span>	You are about to exclude <b>{checkedAttendees.length} attendees</b> from this session, would you
					like to continue?</span>}
				bodyContent={() => {
					return ('')
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Exclude' : 'Excluding...'}
				actionButton={() => this.excludeAttendees()}
				isDelete={true}
				actionButtonDisabled={submitting}

			></FieldModal>

		);
	}
}

export default ExcludeAttendeesModal;
