import React, { Fragment } from 'react';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import TextAreaField from '../../../../components/inputs/textAreaField';
import FieldModal from '../../../../components/modals/fieldModal';
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}
class AddFromModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fromName:
				this.props.email.senderName && this.props.email.senderName !== '' ? this.props.email.senderName : '',
			fromEmail:
				this.props.email.senderEmail && this.props.email.senderEmail !== '' ? this.props.email.senderEmail : '',

			submitting: false,
			invalid: false,
			error: '',
			requiredFields: { fromEmail: false },
			invalidBcc: false
		};
	}

	handleFromNameChange = (e) => {
		this.setState({
			fromName: e.target.value
		});
	};
	handleFromEmailChange = (e) => {
		this.setState({
			fromEmail: e.target.value
		});
	};

	updateFrom = async () => {
		const { fromName, fromEmail, ccEmails } = this.state;
		const { eventId, orgId, email, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		this.setState({ invalidBcc: false });

		requiredFields.fromEmail = false;
		this.setState({ requiredFields: requiredFields })
		if (ValidateEmail(fromEmail) || fromEmail == '') {
			this.setState({ submitting: true });

			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${email._id}`, {
					name: email.name,
					senderName: fromName,
					senderEmail: fromEmail
				})
				.then((res) => {
					const emailObj = res.data;

					if (res.data) {
						update(
							{
								name: email.name,
								senderName: fromName,
								senderEmail: fromEmail
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			requiredFields.fromEmail = true;
			this.setState({ requiredFields: requiredFields })
		}




	};

	render() {
		const { isOpen, toggle, email, event } = this.props;
		const { fromEmail, fromName, submitting, ccEmails, invalidBcc, requiredFields } = this.state;
		const adminEmail = email.defaultSenderEmail;
		const adminEmailName = email.defaultSenderName;
		let emailPlaceholder = event.eventEmail || adminEmail;
		let emailNamePlaceholder = event.eventEmailName || adminEmailName;




		return (
			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add From"}
					bodyHeaderText={'Who is sending this campaign?'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="From Name"
									required={false}
									placeholder={fromName == '' ? emailNamePlaceholder ? emailNamePlaceholder : 'Simple Events Support' : ''}
									value={fromName}
									onChange={this.handleFromNameChange}
								/>
								<InputField
									label="From Email"
									required={false}
									placeholder={fromEmail == '' ? emailPlaceholder ? emailPlaceholder : 'support@simple.events' : ''}
									errorTrigger={requiredFields.fromEmail}
									errorMessage={'Invalid email format'}
									value={fromEmail}
									onChange={this.handleFromEmailChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateFrom()}
					actionButtonDisabled={submitting}


				></FieldModal>



			</div>

		);
	}
}

export default AddFromModal;
