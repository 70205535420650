import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

class TabsContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			routeMapIndexSelected: 0,
		}
	}

	setRouteMapIndexSelected = (index) => {
		if (this.state.routeMapIndexSelected !== index) {
			this.setState({ routeMapIndexSelected: index });
		}
	}
	render() {
		const { tabs, updateSelected, selectedIndex, style, views, routeMap, center, classes } = this.props;
		const { routeMapIndexSelected } = this.state;
		return (
			<div className={`tabsContainer ${!views && 'unsetWidth'} ${classes ? classes : ''}`}>
				{views ? (
					<div style={style} className="viewTabs">
						{tabs.map((tab, i) => (
							<div className={`viewTab ${selectedIndex == i ? 'selected' : ''}`}>
								<div
									className="viewTabTitle"
									onClick={() => {
										updateSelected(i, tab.id);
									}}
								>
									{tab.icon}{tab.title}{' '}
									{/* <span className="count">
									({view.count || 0}) {view.unsavedFilter ? '*' : ''}
								</span> */}
								</div>
							</div>
						))}
					</div>
				) : (
					<div style={style} className={`tabContainer ${center ? 'center' : ''}`} >
						{tabs.map((tab, i) => (
							routeMap ?
								<NavLink
									isActive={(active) => {
										active && this.setRouteMapIndexSelected(i);
									}}
									className={`tabItem ${routeMapIndexSelected == i ? 'selected' : ''}`}
									activeClassName="selected" onClick={() => {
										updateSelected(i, tab.id);
									}}
									to={routeMap[i]}>
									{routeMapIndexSelected == i ? <h3>{tab.title}</h3> : <h4>{tab.title}</h4>}
								</NavLink>
								:
								<div
									className={selectedIndex == i ? 'tabItem selected ' : 'tabItem'}
									onClick={() => {
										updateSelected(i, tab.id);
									}}
								>
									{selectedIndex == i ? <h3>{tab.icon}{tab.title}</h3> : <h4>{tab.icon}{tab.title}</h4>}

								</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default TabsContainer;
