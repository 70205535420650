import React from 'react';
import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteCommentModal extends React.Component {
    state = {
        submitting: false
    };

    delete = () => {
        const { commentId } = this.props;

        this.props.deleteComment(commentId, () => {

            this.props.toggle();
        });
    };
    render() {
        const { isOpen, toggle, session } = this.props;
        const { submitting } = this.state;
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Comment"}
                    bodyHeaderText={<span>You are about to delete this selected comment from this session, this is irreversible,
                        would you like to continue?</span>}
                    bodyContent={() => {
                        return (
                            ''
                        )
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                    actionButton={() => this.delete()}
                    actionButtonDisabled={submitting}
                    isDelete={true}
                ></FieldModal>
            </div>

        );
    }
}

export default withRouter(DeleteCommentModal);
