import React from "react";
import FieldModal from "../../../../components/modals/fieldModal";
import InputField from "../../../../components/inputs/inputField";
import SelectField from "../../../../components/inputs/selectField";
import ColorField from "../../../../components/inputs/colorField";
import TextAreaField from "../../../../components/inputs/textAreaField";
import API from "../../../../utils/API";

const iconSizeOptions = [
    {
        label: '72x72',
        value: '72x72'
    },
    {
        label: '96x96',
        value: '96x96'
    },
    {
        label: '128x128',
        value: '128x128'
    },
    {
        label: '144x144',
        value: '144x144'
    },
    {
        label: '152x152',
        value: '152x152'
    },
    {
        label: '192x192',
        value: '192x192'
    },
    {
        label: '384x384',
        value: '384x384'
    },
    {
        label: '512x512',
        value: '512x512'
    }
];

const iconTypeOptions = [{ label: 'image/png', value: 'image/png' }, { label: 'image/jpg', value: 'image/jpg' }];
class MobileAppConfigModal extends React.Component {

    constructor(props) {
        super(props);
        const pwaDetails = props.event.pwaDetails;

        const privatePathOptions = props.event.pathOptions.map((po) => {
            return { label: po.title, value: po.pathSlug };
        });
        this.state = {
            editedPwaDetails: {
                ...pwaDetails
            },
            pwaDetails: {
                ...pwaDetails
            },
            editing: false,
            privatePathOptions: privatePathOptions,
            defaultPath: privatePathOptions.find((po) => po.value === this.props.event.pwaDetails.defaultPath),
            pwaEnabled: this.props.event.pwaEnabled,
            mobileIcon: this.props.event.pwaDetails.icons,
            isValid: false,
            iconsError: false,
            requiredFields: {
                name: false,
                shortName: false,
                iosModalInstallationInstructions: false,
                defaultPath: false,
                promptTimeout: false,
                backgroundColor: false
            },
        };
    }

    savePwaDetails = (pwaDetails, callback) => {
        const { orgId } = this.props;
        API().patch(`Organizations/${orgId}/events/${this.state.event._id}`, pwaDetails).then((res) => {
            if (res.data) {
                this.setState({ event: { ...this.state.event, ...res.data }, loading: false });
                callback && callback();
            }
        });
    };


    save = (e) => {
        const {
            backgroundColor,
            name,
            shortName,
            description,
            iosModalInstallationInstructions,
            icons,
            defaultPath,
            promptTimeout,
            pwaEnabled,
            mobileIcon
        } = this.state;

        const requiredFields = this.state.requiredFields;
        let valid = true;
        Object.keys(requiredFields).forEach((key) => {
            if (!this.state.editedPwaDetails[key] || this.state.editedPwaDetails[key] === '') {
                requiredFields[key] = true;
                valid = false;
            } else {
                requiredFields[key] = false;
            }
        });
        if (valid) {
            this.setState({ saving: true });
            //checks if mobileIcon contains any blanks
            var exists = mobileIcon.find((i) => i.src == '' || i.type == '' || i.size == '');
            requiredFields.mobileIcon = exists;
            if (!exists) {
                this.savePwaDetails(
                    {
                        pwaDetails: {
                            backgroundColor,
                            name,
                            shortName,
                            description,
                            iosModalInstallationInstructions,
                            icons,
                            defaultPath: defaultPath.value,
                            promptTimeout,
                            pwaEnabled,
                            icons: mobileIcon
                        },
                        pwaEnabled: pwaEnabled
                    },
                    () => {
                        this.setState({
                            editing: false,
                            saving: false,
                            requiredFields: requiredFields,
                            iconsError: false,
                            icons: mobileIcon
                        });
                    }
                );
            } else {
                this.setState({ requiredFields: requiredFields, saving: false, iconsError: false });
            }
        } else {
            this.setState({ requiredFields: requiredFields });
        }
    };

    cancelEdit = () => {
        const pwaDetails = this.props.event.pwaDetails;

        const requiredFields = this.state.requiredFields;
        Object.keys(requiredFields).forEach((key) => {
            requiredFields[key] = false;
        });
        this.setState({
            backgroundColor: pwaDetails.backgroundColor,
            name: pwaDetails.name,
            shortName: pwaDetails.shortName,
            description: pwaDetails.description,
            iosModalInstallationInstructions: pwaDetails.iosModalInstallationInstructions,
            icons: pwaDetails.icons,
            defaultPath: this.state.privatePathOptions.find(
                (po) => po.value === this.props.event.pwaDetails.defaultPath
            ),
            promptTimeout: pwaDetails.promptTimeout,
            editing: false,
            pwaEnabled: this.props.event.pwaEnabled,
            requiredFields: requiredFields,
            mobileIcon: this.props.event.pwaDetails.icons,
            isValid: false,
            iconsError: false
        });
    };

    saveEnablePWA = (val) => {
        const { requiredFields } = this.state;
        this.savePwaDetails(
            {
                pwaDetails: {
                    ...this.props.event.pwaDetails,
                    pwaEnabled: val
                },
                pwaEnabled: val
            },
            () => {
                this.setState({
                    requiredFields: requiredFields,
                    iconsError: false
                });
            }
        );
    };

    enablePWA = () => {
        this.setState({ pwaEnabled: !this.state.pwaEnabled });
    }

    /* enablePWA = () => {
        const { icons, pwaEnabled } = this.state;
        this.setState({ saving: true });
        //checks if mobileIcon contains any blanks

        const requiredFields = this.state.requiredFields;
        let valid = true;

        if (!pwaEnabled) {
            Object.keys(requiredFields).forEach((key) => {
                if (!this.state[key] || this.state[key] === '') {
                    requiredFields[key] = true;
                    valid = false;
                } else {
                    requiredFields[key] = false;
                }
            });
        }

        if ((valid && (!pwaEnabled && icons.length > 0)) || pwaEnabled) {
            this.setState({ pwaEnabled: !this.state.pwaEnabled }, () => {
                const { pwaEnabled, icons } = this.state;
                if (pwaEnabled && icons.length > 0) {
                    this.saveEnablePWA(true);
                } else if (!pwaEnabled) {
                    this.saveEnablePWA(false);
                }
            });
        } else {
            this.setState({ saving: false, requiredFields: requiredFields, iconsError: true });
        }
    }; */

    render() {
        const { isOpen, toggle, readOnly } = this.props;
        const {
            editedPwaDetails, submitting, requiredFields, name, shortName, description, defaultPath, backgroundColor, iosModalInstallationInstructions, promptTimeout, mobileIcon, isValid, iconsError, pwaEnabled
        } = this.state;

        return <FieldModal

            size="medium"
            isOpen={isOpen}
            modalTitle={"Mobile App Configuration"}
            bodyHeaderText={`Configure your mobile app.`}
            bodyContent={() => {
                return (<div className="sectionBlock">
                    {pwaEnabled ? <>
                        <h2 className='blockHeader'>Installation</h2>
                        <InputField
                            label="Application Name"
                            value={name}
                            errorTrigger={requiredFields.name}
                            disabled={readOnly}
                            required={true}
                            onChange={(e) => {
                                this.setState({
                                    editedPwaDetails: { ...editedPwaDetails, name: e.target.value },
                                    name: e.target.value,
                                    editing: true
                                });
                            }}
                        />
                        <InputField
                            label="Application Short Name"
                            value={shortName}
                            disabled={readOnly}
                            required={true}
                            errorTrigger={requiredFields.shortName}
                            onChange={(e) => {
                                this.setState({
                                    editedPwaDetails: { ...editedPwaDetails, shortName: e.target.value },
                                    shortName: e.target.value,
                                    editing: true
                                });
                            }}
                        />

                        <InputField
                            label="Application Description"
                            value={description}
                            disabled={readOnly}
                            className={`editingField`}
                            onChange={(e) => {
                                this.setState({
                                    editedPwaDetails: { ...editedPwaDetails, description: e.target.value },
                                    description: e.target.value,
                                    editing: true
                                });
                            }}
                        />
                        <SelectField
                            label="Default Home Page"
                            required={true}
                            value={
                                defaultPath ? (
                                    defaultPath
                                ) : (
                                    {
                                        label: '',
                                        value: ''
                                    }
                                )
                            }
                            errorTrigger={requiredFields.defaultPath}
                            name="registrationPageSlug"
                            disabled={readOnly}
                            options={this.state.privatePathOptions}
                            onChange={(path) => {
                                this.setState({
                                    editedPwaDetails: { ...editedPwaDetails, defaultPath: { label: path.label, value: path.value } },
                                    defaultPath: { label: path.label, value: path.value },
                                    editing: true
                                });
                            }}
                        />
                        <ColorField
                            label="Application Color"
                            value={backgroundColor}
                            type="color"
                            required={true}
                            errorTrigger={requiredFields.backgroundColor}
                            disabled={readOnly}
                            onChange={(e) => {
                                this.setState({
                                    editedPwaDetails: { ...editedPwaDetails, backgroundColor: e },
                                    backgroundColor: e,
                                    editing: true
                                });
                            }}
                        />
                        <TextAreaField
                            label="Installation Prompt Message"
                            value={iosModalInstallationInstructions}
                            disabled={readOnly}
                            required={true}
                            errorTrigger={requiredFields.iosModalInstallationInstructions}
                            className={`editingField`}
                            onChange={(e) => {
                                this.setState({
                                    iosModalInstallationInstructions: e.target.value,
                                    editedPwaDetails: { ...editedPwaDetails, iosModalInstallationInstructions: e.target.value },
                                    editing: true
                                });
                            }}
                        />
                        <InputField
                            label="If installation prompt is dismissed, show again after (hours)"
                            value={promptTimeout}
                            type="number"
                            disabled={readOnly}
                            required={true}
                            errorTrigger={requiredFields.promptTimeout}
                            className={`editingField`}
                            onChange={(e) => {
                                this.setState({
                                    promptTimeout: e.target.value,
                                    editedPwaDetails: { ...editedPwaDetails, promptTimeout: e.target.value },

                                    editing: true
                                });
                            }}
                        />

                        <label className="displayLabel">
                            <div className="flex-ac">
                                Mobile Application Homescreen Icons *
                                <div
                                    onClick={() => {
                                        var exists = mobileIcon.find(
                                            (i) => i.src == '' || i.type == '' || i.size == ''
                                        );
                                        if (mobileIcon.length == 0 || !exists) {
                                            this.setState({
                                                mobileIcon: [...mobileIcon, { src: '', size: '', type: '' }]
                                            });
                                        } else {
                                            this.setState({ isValid: false });
                                        }
                                    }}
                                >
                                    <i className="las la-plus-circle ml-10 c-pointer clickable" />
                                </div>
                                {iconsError && (
                                    <span style={{ marginLeft: 10, color: '#b90000' }}>Add at least one icon</span>
                                )}
                            </div>
                        </label>

                        {mobileIcon.map((icon, index) => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div
                                        onClick={() => {
                                            let updatedMobileIcon = mobileIcon.filter((i, newIndex) => {
                                                if (index !== newIndex) return i;
                                            });
                                            this.setState({ mobileIcon: updatedMobileIcon, editing: true });
                                        }}
                                    >
                                        <i className="las la-minus-circle mr-10 c-pointer" style={{ color: 'red' }} />
                                    </div>
                                    <InputField
                                        label="Icon Url"
                                        value={icon.src}
                                        onChange={(e) => {
                                            let mobileObj = JSON.parse(JSON.stringify(mobileIcon));
                                            mobileObj[index].src = e.target.value;
                                            this.setState({
                                                mobileIcon: mobileObj,
                                                editing: true
                                            });
                                        }}
                                    />
                                    <SelectField
                                        label="Icon Size"
                                        value={
                                            icon.size ? (
                                                {
                                                    label: icon.size,
                                                    value: icon.size
                                                }
                                            ) : (
                                                {
                                                    label: '',
                                                    value: ''
                                                }
                                            )
                                        }
                                        name="registrationPageSlug"
                                        options={iconSizeOptions}
                                        disabled={readOnly}
                                        onChange={(path) => {
                                            let mobileObj = JSON.parse(JSON.stringify(mobileIcon));
                                            mobileObj[index].size = path.label;
                                            this.setState({
                                                mobileIcon: mobileObj,
                                                editing: true
                                            });
                                        }}
                                    />
                                    <SelectField
                                        label="Icon Type"
                                        value={
                                            icon.type ? (
                                                {
                                                    label: icon.type,
                                                    value: icon.type
                                                }
                                            ) : (
                                                {
                                                    label: '',
                                                    value: ''
                                                }
                                            )
                                        }
                                        name="registrationPageSlug"
                                        options={iconTypeOptions}
                                        disabled={readOnly}
                                        onChange={(path) => {
                                            let mobileObj = JSON.parse(JSON.stringify(mobileIcon));
                                            mobileObj[index].type = path.label;
                                            this.setState({
                                                mobileIcon: mobileObj,
                                                editing: true
                                            });
                                        }}
                                    />
                                </div>
                            );
                        })}
                        {!isValid && <div style={{ color: 'red' }}>All Fields must be defined</div>}
                    </> : <div className="flex aic jcc  w-100" style={{ height: 300 }}>
                        <div className="flex column aic text-c" >
                            <h2 className="mb-10">Enable mobile application</h2>
                            <p className="mb-25">Configure the event to be installed as an app on attendee's phones. Leverage existing event pages to create an even more engaging experience.</p>
                            <button className="prim" onClick={() => {
                                this.enablePWA();
                            }}>Enable</button>

                        </div>
                    </div>}
                </div>)
            }}
            toggle={toggle}
            actionButtonLabel={pwaEnabled && (!submitting ? 'Submit' : 'Adding...')}
            actionButton={() => this.addPage()}
            actionButtonDisabled={!isValid}>

        </FieldModal>;
    }
}

export default MobileAppConfigModal;