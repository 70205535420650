import React, { createRef, Fragment } from 'react';
import API from '../../../utils/API';
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	Modal,
	ModalBody
} from 'reactstrap';
import ComponentLibrary from '../../../components/codeEditor/ComponentLibrary';
import LoadingWizard from '../../../components/spinner/wizard';
import 'react-toggle/style.css';
import EditorComponentList from '../../../components/codeEditor/EditorComponent';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import { withRouter } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { CircularProgressbar } from 'react-circular-progressbar';
import FilesUploaderModal from '../../../components/files/filesUploaderModal';
import FileChooserPreview from '../../../components/inputs/fileChooserPreview';
import ColorField from '../../../components/inputs/colorField';
import CodeEditor from '../../../components/codeEditor/codeEditor';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

const deviceWidthOptions = [
	{ value: 375, label: <span><i className='las la-mobile mr-10'></i> 375 px</span> },
	{ value: 768, label: <span><i className='las la-tablet mr-10'></i> 768 px</span> },
	{ value: 1200, label: <span><i className='las la-laptop mr-10'></i> 1200 px</span> },
	{ value: 1600, label: <span><i className='las la-desktop mr-10'></i> 1600 px</span> },
]

const deviceHeightOptions = [
	{ value: 667, label: <span><i className='las la-mobile mr-10'></i> 667 px</span> },
	{ value: 1024, label: <span><i className='las la-tablet mr-10'></i> 1024 px</span> },
	{ value: 800, label: <span><i className='las la-laptop mr-10'></i> 800 px</span> },
	{ value: 1080, label: <span><i className='las la-desktop mr-10'></i> 1080 px</span> },
]

const devicePresetOptions = [
	{ value: 0, label: <span><i className='las la-mobile mr-10'></i> Mobile</span> },
	{ value: 1, label: <span><i className='las la-tablet mr-10'></i> Tablet</span> },
	{ value: 2, label: <span><i className='las la-laptop mr-10'></i> Laptop</span> },
	{ value: 3, label: <span><i className='las la-desktop mr-10'></i> Desktop</span> }
]

const zoomOptions = [
	{ value: 0.25, label: <span><i className='las la-search-minus mr-10'></i> 25%</span> },
	{ value: 0.5, label: <span><i className='las la-search-minus mr-10'></i> 50%</span> },
	{ value: 0.75, label: <span><i className='las la-search-minus mr-10'></i> 75%</span> },
	{ value: 1, label: <span><i className='las la-search-minus mr-10'></i> 100%</span> },
	{ value: 1.25, label: <span><i className='las la-search-plus mr-10'></i> 125%</span> },
	{ value: 1.5, label: <span><i className='las la-search-plus mr-10'></i> 150%</span> },
	{ value: 1.75, label: <span><i className='las la-search-plus mr-10'></i> 175%</span> },
	{ value: 2, label: <span><i className='las la-search-plus mr-10'></i> 200%</span> },
]

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

let refreshTimeInterval = null;
class PageEditor extends React.Component {
	constructor(props) {
		super(props);
		const { match: { params }, orgId, programId, page } = props;
		console.log(orgId)
		const pageId = params.page;
		const REST_MAP = {
			event: {
				getPage: `Organizations/${orgId}/events/${params.event}/pages/${pageId}`,
				getAssets: `Organizations/${orgId}/events/${params.event}/assets`,
				addAssets: `Organizations/${orgId}/events/${params.event}/assets`,
				updatePage: `Organizations/${orgId}/events/${params.event}/pages/${pageId}`,
				addComponents: `Organizations/${orgId}/events/${params.event}/pages/${pageId}/editor/addComponents`,
				addGlobalComponent: `Organizations/${orgId}/events/${params.event}/pages/${pageId}/editor/addGlobalComponent`,
				addCustomComponent: `Organizations/${orgId}/events/${params.event}/pages/${pageId}/editor/addCustomComponent`,
				render: `Organizations/${orgId}/events/${params.event}/pages/render/${pageId}/`,
				formTypes: [
					{ label: 'Registration', value: 'registration' },
					{ label: 'Check-In', value: 'checkin' },
					{ label: 'General', value: 'general' }
				]
			},
			program: {
				getPage: `Organizations/${orgId}/programs/${programId}/pages/${page._id}`,
				getAssets: `Organizations/${orgId}/assets`,
				addAssets: `Organizations/${orgId}/assets`,
				updatePage: `Organizations/${orgId}/programs/${programId}/pages/${page._id}`,
				addComponents: `Organizations/${orgId}/programs/${programId}/pages/${page._id}/editor/addComponents`,
				render: `Organizations/${orgId}/programs/${programId}/render/${page._id}`,
				formTypes: [
					{ label: 'Registration', value: 'registration' },
					{ label: 'Check-In', value: 'checkin' },
					{ label: 'General', value: 'general' }
				]
			},
			account: {
				getPage: `Organizations/${orgId}/accountPages/${pageId}`,
				getAssets: `Organizations/${orgId}/assets`,
				getPrograms: `Organizations/${orgId}/programs`,
				addAssets: `Organizations/${orgId}/assets`,
				addComponents: `Organizations/${orgId}/accountPages/${pageId}/editor/addComponents`,
				addGlobalComponent: `Organizations/${orgId}/accountPages/${pageId}/editor/addGlobalComponent`,
				addCustomComponent: `Organizations/${orgId}/accountPages/${pageId}/editor/addCustomComponent`,
				updatePage: `Organizations/${orgId}/accountPages/${pageId}`,
				render: `Organizations/${orgId}/accountPages/render/${pageId}/`,
				formTypes: [
					{ label: 'Program Request', value: 'programRequest' },
					{ label: 'General', value: 'general' }
				]
			}
		};

		const customFields = [
			{
				text: 'Organization Name',
				value: '{{orgName}}',
				label: 'Organization Name'
			}
		];

		this.state = {
			links: REST_MAP[props.scope],
			searchInput: '',
			pageDetails: {},
			loading: true,
			previewOpen: true,
			detailsOpen: true,
			publishOpen: false,
			deletePageOpen: false,
			page: {},
			initialPage: {},
			editedPage: {},
			editingDetails: false,
			renderURL: REST_MAP[props.scope].render,
			userOptions: [],
			simulatedUser: { label: '', value: '' },
			simulatedSession: {},
			selectedModuleTab: 0,
			saving: false,
			componentLibraryIsOpen: false,
			componentDetailsOpen: true,
			componentDetailsExpanded: false,
			assetUploadIsOpen: false,
			frameHeight: 0,
			frameWidth: 0,
			editCount: 0,
			focusedComponent: '',
			deviceWidth: 1200,
			deviceHeight: 800,
			fontFamilies: [],
			files: [],
			horizontalViewEnabled: false,
			forms: [],
			selectedToolSection: 0,
			zoom: 1.0,
			requiredFields: {
				title: false,
				pathSlug: false
			},
			pagePaths: [],
			emailPaths: [],
			eventFeeds: [],
			pagePath: '',
			customCSSModalOpen: false,
			customFields: customFields,
			sessionOptions: [],
			componentSelected: false,
			selectedPartialIndex: null,
			parameterSelected: false,
			parameterPath: '',
			programs: [],
			isDragging: false,
			pageStylingOpen: false,
		};
		this.preview = createRef();
	}

	componentWillMount() {
		API().get(this.state.links.getPage).then((res) => {
			if (res.data) {
				const metadataOptions = [];
				res.data.customFieldLabels &&
					Object.keys(res.data.customFieldLabels).forEach((key) => {
						const layer1 = res.data.customFieldLabels[key];
						if (typeof layer1 === 'object') {
							Object.keys(layer1).forEach((key2) => {
								const layer2 = layer1[key2];
								const k2 = key2.substring(0, 1) + key2.substring(1);
								if (typeof layer2 === 'object') {
									Object.keys(layer2).forEach((key3) => {
										const layer3 = layer2[key3];
										const k3 = key3.substring(0, 1) + key3.substring(1);
										if (
											key !== '_defaultLabel' &&
											key2 !== '_defaultLabel' &&
											key3 !== '_defaultLabel'
										) {
											//metadataOptions.push(`${key}.${key2}.${key3}`);
											metadataOptions.push({
												label: `${layer1._defaultLabel} ${layer2._defaultLabel} ${layer3}`,
												value: `{{${key}${k2}${k3}}}`,
												text: `${key}${k2}${k3}`
											});
										}
									});
								} else {
									if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
										//metadataOptions.push(`${key}.${key2}`);
										metadataOptions.push({
											label: `${layer1._defaultLabel} ${layer2}`,
											value: `{{${key}${k2}}}`,
											text: `${key}${k2}`
										});
									}
								}
							});
						} else {
							if (key !== '_defaultLabel') {
								metadataOptions.push({ label: layer1, value: key, text: `{{${key}}}` });
							}
						}
					});
				this.setState({
					page: res.data,
					initialPage: res.data,
					editedPage: res.data,
					loading: false,
					userOptions: res.data.users,
					simulatedUser: res.data.users.length > 0 ? res.data.users[0] : {},
					simulatedSession:
						res.data.isSessionPage && res.data.sessions.length > 0 ? res.data.sessions[0] : {},
					sessionOptions: res.data.sessions,
					fontFamilies: res.data.fontFamilies,
					forms: res.data.forms.map((f) => {
						return { label: f.name, value: f._id };
					}),
					baseurl: res.data.baseurl,
					pagePaths: res.data.pagePaths,
					emailPaths: res.data.emailPaths,
					eventFeeds: res.data.eventFeeds,
					pagePath: res.data.url ? res.data.url.replace(res.data.baseurl, '') : '',
					customFields: metadataOptions,
					editCount: this.state.editCount + 1
				});
			}
		});

		this.state.links.getAssets &&
			API().get(this.state.links.getAssets).then((res) => {
				if (res.data) {
					this.setState({ files: res.data });
				}
			});

		this.state.links.getPrograms &&
			API().get(this.state.links.getPrograms).then((res) => {
				if (res.data) {
					const programOptions = res.data.programs.map((p) => {
						return { label: p.name, value: p._id };
					});
					this.setState({ programs: programOptions });
				}
			});

		window.addEventListener('message', (ev) => {
			if (ev.data.type == 'section-clicked' && this.state.editedPage.partials) {
				const sectionIndex = this.state.editedPage.partials.findIndex(
					(s) => `${s.file.replace('/', '_')}_${s._id}` == ev.data.sectionId
				);
				if (sectionIndex >= 0) {
					const section = this.state.editedPage.partials[sectionIndex];
					this.setState({
						focusedComponent: `#${section.file.replace('/', '_')}_${section._id}`,
						componentSelected: true,
						selectedPartialIndex: sectionIndex,
						componentDetailsOpen: true,
						componentLibraryIsOpen: false,
						pageStylingOpen: false,
						parameterPath: '',
						parameterSelected: false
					});
				}
			}
			if (typeof ev.data !== 'object') return;
			if (!ev.data.type) return;
			if (ev.data.type !== 'button-click') return;
			if (!ev.data.message) return;
			this.setState({
				message: ev.data.message
			});
		});
	}

	refreshPage = () => {
		this.setState({ refreshTimeRemaining: 1.5 });
		if (!refreshTimeInterval) {
			refreshTimeInterval = setInterval(() => {
				if (this.state.refreshTimeRemaining > 0) {
					this.setState({ refreshTimeRemaining: this.state.refreshTimeRemaining - 0.1 });
				} else {
					this.setState({ editCount: this.state.editCount + 1 });
					clearInterval(refreshTimeInterval);
					refreshTimeInterval = null;
				}
			}, 100);
		}
	};

	cancelEdit = () => {
		API().get(this.state.links.getPage).then((res) => {
			if (res.data) {
				const requiredFields = this.state.requiredFields;
				Object.keys(requiredFields).forEach((key) => {
					requiredFields[key] = false;
				});
				this.setState({
					page: res.data,
					editedPage: res.data,
					editingDetails: false,
					editCount: this.state.editCount + 1,
					requiredFields: requiredFields,
					componentSelected: false,
					focusedComponent: '',
					selectedPartialIndex: 0,
					parameterPath: ''
				});
			}
		});
	};

	updatePageComponentsAPI = (selectedPartials, isCustom, isGlobal) => {
		this.setState({ saving: true });
		API()
			.post(this.state.links.addComponents, {
				partials: selectedPartials,
				isCustom: isCustom,
				isGlobal,
				isGlobal
			})
			.then((res) => {
				if (res.data) {
					const updatedPage = this.state.page;
					updatedPage.partials = res.data;
					this.setState({
						page: updatedPage,
						saving: false,
						selectedToolSection: 0
					});
					this.refreshPage();
				}
			});
	};

	updatePageComponents = (selectedPartials, isCustom, isGlobal) => {
		const updatedPage = this.state.editedPage;
		selectedPartials.forEach((partial) => {
			const newPartial = JSON.parse(JSON.stringify(partial));
			if (isGlobal) newPartial.isGlobal = true;
			updatedPage.partials.push(newPartial);
		});
		this.setState({
			editingDetails: true,
			editedPage: updatedPage,
			editCount: this.state.editCount + 1
		});
	};

	addAsGlobalComponent = (partial) => {
		API()
			.post(this.state.links.addGlobalComponent, {
				partial: partial
			})
			.then((res) => {
				if (res.data) {
					const updatedPage = this.state.page;
					updatedPage.partials = this.state.page.partials.map((p) => {
						if (p._id == res.data._id) {
							return res.data;
						}
						return p;
					});
					this.setState({ page: updatedPage, editedPage: updatedPage });
				}
			});
	};

	addAsCustomComponent = (partial) => {
		API()
			.post(this.state.links.addCustomComponent, {
				partial: partial
			})
			.then((res) => {
				this.savePage();
			});
	};

	savePage = async () => {
		const { editedPage } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedPage[key] || editedPage[key] === '') {
				requiredFields[key] = true;
				valid = false;
			}
		});
		if (valid) {
			this.setState({ saving: true });
			await API()
				.patch(this.state.links.updatePage, this.state.editedPage)
				.then((res) => {
					if (res.data) {
						this.setState({
							page: { ...this.state.page, ...res.data },
							editedPage: { ...this.state.page, ...res.data },
							loading: false,
							editingDetails: false,

							saving: false,
							editCount: this.state.editCount + 1,
							requiredFields: requiredFields,
							componentSelected: false,
							focusedComponent: '',
							selectedPartialIndex: 0,
							parameterPath: '',
							parameterSelected: false
						}, () => this.props.updatePage(this.state.page));
					}
				})
				.catch((e) => {
					this.setState({ loading: false, editingDetails: false });
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	updatePartialProperty = (partialIndex, property, value) => {
		const updatedPage = this.state.editedPage;
		updatedPage.partials[partialIndex][property] = value;
		this.setState({
			editingDetails: true,
			editedPage: updatedPage
		});
		this.refreshPage();
	};

	updatePartial = (partialIndex, updatedPartial) => {
		const updatedPage = this.state.editedPage;
		updatedPage.partials[partialIndex] = updatedPartial;
		this.setState({
			editingDetails: true,
			editedPage: updatedPage
		});
		this.refreshPage();
	};

	updatePartialParameters = (partialIndex, paramIndex, value) => {
		const updatedPage = this.state.editedPage;
		updatedPage.partials[partialIndex].parameters[paramIndex].value = value;
		this.setState({
			editingDetails: true,
			editedPage: updatedPage
		});
		this.refreshPage();
	};

	updatePartialOrder = (partials) => {
		this.setState({
			editingDetails: true,
			editedPage: { ...this.state.editedPage, partials: partials },
			editCount: this.state.editCount + 1
		});
	};

	duplicatePartial = (partialIndex) => {
		const updatedPage = this.state.editedPage;
		const dupPartial = JSON.parse(JSON.stringify(updatedPage.partials[partialIndex]));
		dupPartial._id = `_${dupPartial._id}`;
		updatedPage.partials.splice(partialIndex, 0, dupPartial);
		this.setState({
			editingDetails: true,
			editedPage: updatedPage,
			editCount: this.state.editCount + 1
		});
	};

	duplicateComponentContainerCard = (pIndex, element) => {
		const { selectedPartialIndex } = this.state;
		const updatedPage = this.state.editedPage;
		let dupPartial = JSON.parse(JSON.stringify(updatedPage.partials[selectedPartialIndex]));
		dupPartial.parameters[pIndex].nestedComponents.push(element);
		updatedPage.partials[selectedPartialIndex] = dupPartial;

		this.setState({
			editingDetails: true,
			editedPage: updatedPage,
			editCount: this.state.editCount + 1
		});
	};

	removePartial = (partialIndex) => {
		const updatedPage = this.state.editedPage;
		updatedPage.partials.splice(partialIndex, 1);
		this.setState({
			editingDetails: true,
			editedPage: updatedPage,
			editCount: this.state.editCount + 1
		});
	};

	addPartial = (partial) => {
		const updatedPage = this.state.editedPage;
		updatedPage.partials.push(partial);
		this.setState({
			editingDetails: true,
			editedPage: updatedPage,
			editCount: this.state.editCount + 1
		});
	};

	setDeviceWidth = (dw) => this.setState({ deviceWidth: dw });

	setDeviceHeight = (dh) => this.setState({ deviceHeight: dh });

	onDragEnd = (result) => {
		this.setState({ isDragging: false });
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		switch (result.destination.droppableId) {
			case 'droppable-page-components':
				if (result.source.droppableId === 'droppable-components') {
					const editedPage = this.state.editedPage;
					const { _id, ...partial } = result.source.index;
					const draggedPartial = partial;
					draggedPartial._id = `_${this.state.editCount}`
					draggedPartial.id = _id;
					editedPage.partials.push(draggedPartial);
					this.setState({ editedPage: editedPage, editCount: this.state.editCount + 1 });
				} else if (result.source.droppableId === 'droppable-page-components') {
					const editedPage = this.state.editedPage;
					const partials = reorder(editedPage.partials, result.source.index, result.destination.index);
					editedPage.partials = partials;
					this.setState({ editedPage: editedPage, editCount: this.state.editCount + 1, editingDetails: true });
				}

				break;
			case 'droppable-page':
				if (result.source.droppableId === 'droppable-components') {
					const editedPage = this.state.editedPage;
					const { _id, ...partial } = result.source.index;
					const draggedPartial = partial;
					draggedPartial._id = `_${this.state.editCount}`
					draggedPartial.id = _id;
					editedPage.partials.push(draggedPartial);
					this.setState({ editedPage: editedPage, editCount: this.state.editCount + 1, editingDetails: true });
				}
				break;
			default:
				break;
		}
		/* const items = reorder(this.props.editedPage.partials, result.source.index, result.destination.index);
		this.props.updatePartialOrder(items); */
	}

	toggleSection = (section) => {
		const { componentDetailsOpen, componentLibraryIsOpen, pageStylingOpen } = this.state;
		switch (section) {
			case 'editor':
				this.setState({ componentDetailsOpen: !componentDetailsOpen, componentLibraryIsOpen: false, pageStylingOpen: false })

				break;
			case 'library':
				this.setState({ componentLibraryIsOpen: !componentLibraryIsOpen, componentDetailsOpen: false, pageStylingOpen: false })

				break;
			case 'style':
				this.setState({ pageStylingOpen: !pageStylingOpen, componentDetailsOpen: false, componentLibraryIsOpen: false })

				break;
		}
	}

	render() {
		const {
			loading,
			editedPage,
			renderURL,
			simulatedUser,
			simulatedSession,
			selectedModuleTab,
			saving,
			deviceWidth,
			deviceHeight,
			horizontalViewEnabled,
			zoom,
			componentLibraryIsOpen,
			componentDetailsOpen,
			componentDetailsExpanded,
			isDragging,
			editingDetails,
			pageStylingOpen,
		} = this.state;

		const { match: { params } } = this.props;

		const devicePreset = devicePresetOptions.find(d => {
			const w = deviceWidthOptions[d.value].value;
			const h = deviceHeightOptions[d.value].value;
			return w === deviceWidth && h === deviceHeight;
		}) || { value: 3, label: "Custom" }


		const { isOpen, toggle } = this.props;
		return <Modal isOpen={isOpen} toggle={toggle} className="fullscreen">
			<div className='modalHeader ph-20' style={{ borderBottom: '1px solid var(--background)' }}>
				<div className="headerContainer" style={{ maxWidth: 'unset', minHeight: 60, height: 60 }}>

					<div>
						<h2>Page Design Studio</h2>
						<h3>{editedPage.title} </h3>
					</div>
					<div className='flex aic'>
						{this.state.refreshTimeRemaining > 0 && (
							<div className='flex aic'
								style={{ width: 20, height: 20, fontSize: 20, marginRight: 20 }}
							>
								<CircularProgressbar
									value={this.state.refreshTimeRemaining}
									maxValue={1.5}
									text={this.state.refreshTimeRemaining.toFixed(0)}
									strokeWidth={12}
									counterClockwise={true}
								/>
							</div>
						)}
						<button className="neu mr-15 " onClick={() => {
							if (editingDetails) {
								if (window.confirm('Are you sure you want to discard your changes?')) {
									this.props.cancelEdit();
									this.props.toggle();
								}
							} else {
								this.props.toggle();
							}
						}
						}>{editingDetails ? 'Close & Discard Changes' : 'Close'}</button>
						<button className={`neu  ${editingDetails ? 'prim' : 'disabled'}`}
							onClick={this.savePage}>Save</button>
					</div>
				</div>
			</div>
			<ModalBody className="modalBody">
				{isOpen && <Fragment>{!loading && (<DragDropContext className="editorWorkspace" onDragEnd={this.onDragEnd} onDragStart={(e, f) => {
					this.setState({ isDragging: true });
				}}>
					<div className='flex jcsb w-100 h-100'>
						<div className="designerPreview">
							<div className='flex-sb flex aic jcc w-100 ph-20' style={{ height: 80, maxWidth: 1200 }} >

								<UncontrolledDropdown inNavbar className="mr-25">
									<DropdownToggle className="neu">
										<div className="deviceWidthButton flex-ac">
											<div className='mr-5'>
												{devicePreset.value === 0 ? (
													<i className="las la-mobile-alt" />
												) : devicePreset.value === 1 ? (
													<i className="las la-tablet" />
												) : devicePreset.value === 2 ? (
													<i className="las la-laptop" />
												) : (
													<i className="las la-crop-alt"></i>
												)}</div>

											<div className="seBlue mr-5">
												{deviceWidth}px <span className='las la-times'></span> {deviceHeight}px
											</div>
											<div className='mr-5'>at</div><div> {zoom * 100}%</div>
										</div>
									</DropdownToggle>
									<DropdownMenu left className="columnDropdown p-20" style={{ minWidth: 300 }}>
										<h5>Screen size</h5>
										<div className='p-10' >

											<SelectField label="Device Presets" options={devicePresetOptions} value={devicePreset} onChange={(e) => {
												const { value } = e;
												this.setState({
													deviceWidth: deviceWidthOptions[value].value,
													deviceHeight: deviceHeightOptions[value].value
												})
											}} />
											<div className='flex-ac'>
												<InputField
													label="Width (px)"
													value={deviceWidth}
													type="number"
													classes="mr-10"
													onChange={(e) => this.setDeviceWidth(e.target.value)}
												/>

												<InputField
													label="Height (px)"
													value={deviceHeight}
													type="number"
													onChange={(e) => this.setDeviceHeight(e.target.value)}
												/>
											</div>
										</div>
										<h5>Scale</h5>
										<div className='p-10' >

											<SelectField label="Zoom" options={zoomOptions} value={zoomOptions.find(z => z.value == zoom)} onChange={(e) => this.setState({ zoom: e.value })} />
										</div>

									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
							<Droppable droppableId="droppable-page">
								{(provided, snapshot) => (<div
									ref={provided.innerRef} className="editorFrame" style={{
										transform: `scale(${zoom})`,
										pointerEvents: isDragging ? 'none' : 'auto'
									}}>
									<div
										style={{
											minWidth: `${deviceWidth}px`,
											width: `${deviceWidth}px`
										}}
										className={`pagePreviewContainer2 ${horizontalViewEnabled &&
											selectedModuleTab == 3
											? 'otter'
											: ''}`}
									>
										<PagePreviewFrame
											setPreviewDimensions={({ width, height }) => {
												this.setState({
													frameWidth: width,
													frameHeight: height
												});
											}}
											editing={this.state.editCount}
											saving={saving}
											ENV={ENV}
											renderURL={renderURL}
											simulatedUser={simulatedUser}
											simulatedSession={simulatedSession}
											focusedComponent={this.state.focusedComponent}
											pageProperties={this.state.editedPage}
										/>
										{editedPage.partials.length === 0 && (
											<div className="previewFrameOverlay">
												<div className="mb-25">
													Click the button below to add your first component.
												</div>

												<div
													className="actionButton actionSave actionOutline flex-ac"
													onClick={() =>
														this.setState({ componentLibraryIsOpen: true })}
												>
													<i className="las la-plus" />{' '}
													<div className="">Add Component</div>
												</div>
											</div>
										)}
									</div>
								</div>)}
							</Droppable>
						</div>
						<div className={`designerPanel p-0 right ${componentDetailsOpen ? '' : 'closed'} ${componentDetailsExpanded ? 'expanded' : ''}`} style={{ overflowX: 'hidden', position: 'relative' }}>

							<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
								<button onClick={() => this.toggleSection('editor')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
								<h1 className='mb-10' >Editor</h1>
								<EditorComponentList
									togglePanel={() => this.setState({ componentDetailsOpen: !componentDetailsOpen, componentDetailsExpanded: componentDetailsOpen ? false : componentDetailsExpanded })}
									expandPanel={() => this.setState({ componentDetailsExpanded: !componentDetailsExpanded })}
									expanded={componentDetailsExpanded}
									scope={this.props.scope}
									maxCode={
										this.state.horizontalViewEnabled && selectedModuleTab == 3
									}
									editedPage={editedPage}
									updatePartial={this.updatePartial}
									updatePartialParameters={this.updatePartialParameters}
									focusedComponent={this.state.focusedComponent}
									updatePartialOrder={this.updatePartialOrder}
									focusComponent={(componentSelected, partialIndex) => {
										const partial = editedPage.partials[partialIndex];
										//get document element by name property

										const previewFrame = document.getElementsByName(
											'PreviewFrame'
										)[0];

										componentSelected
											? previewFrame.contentWindow.postMessage(
												{
													type: 'focusComponent',
													componentId: `#${partial.file.replace(
														'/',
														'_'
													)}_${partial._id.toString()}`
												},
												'*'
											)
											: previewFrame.contentWindow.postMessage(
												{
													type: 'unfocus'
												},
												'*'
											);

										this.setState({
											focusedComponent: `#${partial.file}_${partial._id}`,
											componentSelected: componentSelected,
											selectedPartialIndex: partialIndex,
											parameterPath: '',
											parameterSelected: false
										});
									}}
									addAsCustomComponent={this.addAsCustomComponent}
									addAsGlobalComponent={this.addAsGlobalComponent}
									files={this.state.files}
									programs={this.state.programs}
									openComponentLibrary={() =>
										this.setState({ componentLibraryIsOpen: true })}
									forms={this.state.forms}
									formTypes={this.state.links.formTypes}
									pagePaths={this.state.pagePaths}
									emailPaths={this.state.emailPaths}
									eventFeeds={this.state.eventFeeds}
									duplicatePartial={this.duplicatePartial}
									duplicateComponentContainerCard={
										this.duplicateComponentContainerCard
									}
									removePartial={this.removePartial}
									fontFamilies={this.state.fontFamilies}
									customFields={this.state.customFields}
									orgId={this.props.orgId}
									links={this.state.links}
									componentSelected={this.state.componentSelected}
									selectedPartialIndex={this.state.selectedPartialIndex}
									parameterSelected={this.state.parameterSelected}
									parameterPath={this.state.parameterPath}
									setParameterSelected={(parameterSelected, parameterPath) => {
										this.setState({ parameterSelected, parameterPath });
									}}
								/>
							</div>
						</div>

						<div className={`designerPanel p-0 right ${componentLibraryIsOpen ? '' : 'closed'}`} style={{ overflowX: 'hidden', position: 'relative' }}>
							<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
								<button onClick={() => this.toggleSection('library')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
								<h1>Library</h1>
								<ComponentLibrary
									orgId={this.props.orgId}
									eventId={params.event}
									ENV={ENV}
									simulatedUserId={simulatedUser.value}
									isOpen={this.state.componentLibraryIsOpen}
									updatePageComponents={this.updatePageComponents}
									addPartial={this.addPartial}
									backToComponents={() =>
										this.setState({ componentLibraryIsOpen: false })}
									togglePanel={() => this.setState({ componentLibraryIsOpen: !componentLibraryIsOpen })}
									event={this.props.event}
								/>
							</div>
						</div>
						{/* New Code  */}
						<div className={`designerPanel p-0 right ${pageStylingOpen ? '' : 'closed'}`} style={{ overflowX: 'hidden', position: 'relative' }} >

							<div style={{ overflowX: 'hidden', padding: 25, paddingBottom: 60, maxHeight: '100%' }} className={`formInputsSection selected`}>
								<button onClick={() => this.toggleSection('style')} style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16 }}><i className='las la-times'></i></button>
								<h1 className='mb-10'>Style</h1>

								<div className="editorFileInput">
									<FileChooserPreview
										label="Background Image"
										value={editedPage.backgroundImage}
										clearFile={() =>
											this.setState({
												editingDetails: true,
												editedPage: {
													...this.state.editedPage,
													backgroundImage: ''
												},
												editCount: this.state.editCount + 1
											})}
										onClick={(e) =>
											this.setState({
												editCount: this.state.editCount + 1,
												editingDetails: true,
												editedPage: {
													...this.state.editedPage,
													backgroundImage:
														'choosing__' + editedPage.backgroundImage
												}
											})}
									/>
									<FilesUploaderModal
										links={{
											getAssets: `Organizations/${this.props.orgId}/assets`,
											addAssets: `Organizations/${this.props.orgId}/assets`,
										}}
										isOpen={
											editedPage.backgroundImage &&
											editedPage.backgroundImage.includes('choosing__')
										}
										toggle={() => {
											this.setState({
												editingDetails: true,
												editCount: this.state.editCount + 1,
												editedPage: {
													...this.state.editedPage,
													backgroundImage: editedPage.backgroundImage.replace(
														'choosing__',
														''
													)
												}
											});
										}}
										orgId={this.props.orgId}
										addFiles={(fileURL) => {
											this.setState({
												editCount: this.state.editCount + 1,
												editingDetails: true,
												editedPage: {
													...this.state.editedPage,
													backgroundImage: fileURL
												}
											});
										}}
									/>
								</div>

								<ColorField
									label="Background Overlay"
									value={editedPage.backgroundOverlay}
									onChange={(e) => {
										this.setState({
											editingDetails: true,
											editedPage: {
												...this.state.editedPage,
												backgroundOverlay: e
											},
											editCount: this.state.editCount + 1
										});
									}}
								/>

								<div style={{ height: 'calc(100vh - 350px)' }}>
									<p className="gray small" style={{ marginBottom: 5 }}>Custom CSS</p>
									<CodeEditor
										className={`displayedField2`}
										placeholder="Add your custom CSS here"
										content={editedPage.customCSS}
										language="css"
										style={{
											marginLeft: 0,
											width: '100%',
											height: '100%',
											minHeight: '100%'
										}}
										setContent={(c) =>
											this.setState({
												editingDetails: true,
												editCount: this.state.editCount + 1,
												editedPage: {
													...this.state.editedPage,
													customCSS: c
												}
											})}
									/>
								</div>
							</div>
						</div>
						{/* New Code Ends  */}
						<div className='designerPanel side p-0' style={{ overflow: 'hidden', position: 'relative', minWidth: 100, width: 100 }} >
							<div className='flex column jcc'>
								<div className={`flex column jcc aic c-pointer ${componentDetailsOpen ? 'seBlue' : ''} `} style={{ height: 100 }} onClick={() => {
									this.toggleSection('editor')
								}}>
									<i className='las la-edit mb-10' style={{ fontSize: 30 }}></i>
									<h5>Editor</h5></div>
								<div className={`flex column jcc aic c-pointer ${componentLibraryIsOpen ? 'seBlue' : ''} `} style={{ height: 100 }} onClick={() => {
									this.toggleSection('library')
								}}>
									<i className='las la-book mb-10' style={{ fontSize: 30 }}></i>
									<h5>Library</h5></div>
								<div className={`flex column jcc aic c-pointer ${pageStylingOpen ? 'seBlue' : ''} `} style={{ height: 100 }} onClick={() => {
									this.toggleSection('style')
								}}>
									<i className='las la-brush mb-10' style={{ fontSize: 30 }}></i>
									<h5>Style</h5></div>
							</div>
						</div>
					</div>
				</DragDropContext>)}
					<LoadingWizard text="Setting up page" loading={loading} />
				</Fragment>}
			</ModalBody>
		</Modal>
	}
}

export default withRouter(PageEditor);
