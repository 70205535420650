import React from 'react';
import moment from 'moment';

import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import TimeField from '../../../../components/inputs/timeField';

import FieldModal from '../../../../components/modals/fieldModal';

class ScheduleCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
			invalid: false,
			attendees: [],
			sessions: [],
			groups: [],
			error: '',
			sentCount: 0,
			completed: false,
			totalEmails: 0,
			eventSessions: [],
			eventGroups: [],
			eventAttendees: [],
			date: '',
			time: '',
			isDateBefore: false,
			requiredFields: {
				date: false,
				time: false
			}
		};
	}

	componentDidMount() {
		const { email, orgId, eventId, event } = this.props;

		let recipients = email.recipients;
		let eventGroups = event.groups.filter((g) => recipients.groups.includes(g._id));

		this.setState({
			attendees: recipients.attendees,
			sessions: recipients.sessions,
			groups: recipients.groups,
			eventGroups: eventGroups
		});
	}

	handleDateChange = (val) => {
		this.setState({
			date: val
		});
	};
	handleTimeChange = (val) => {
		this.setState({
			time: val
		});
	};

	scheduleEmail = async () => {
		const { date, time } = this.state;

		const { event, orgId, eventId, email, update, toggle } = this.props;
		let requiredFields = this.state.requiredFields;

		let timezone = event.timezone;
		let valid = true;
		if (!date && date == '') { requiredFields.date = true; valid = false; } else { requiredFields.date = false; }
		if (!time && time == '') { requiredFields.time = true; valid = false; } else { requiredFields.time = false; }
		if (valid) {
			const currentTimeDate = new Date(moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a'));
			const selectedDate = new Date(`${date} ${time}`);

			if (selectedDate >= currentTimeDate) {
				this.setState({ isDateBefore: false })

				try {
					const res = await Scheduler()
						.post(`Organizations/${orgId}/email/${email._id}/scheduleEmail`, {
							date,
							time,
							timezone: timezone
						})
						.then((res) => {
							update(
								{
									sendDate: date,
									sendTime: time,
									timezone: timezone,
									scheduled: true,
									status: 'Scheduled'
								},
								() => {
									toggle();
								}
							);
						});
				} catch (err) {
					console.log(err);
				}

			} else {

				this.setState({ isDateBefore: true })
			}

		} else {
			this.setState({ requiredFields: requiredFields })

		}

	};
	render() {
		const { isOpen, toggle, email, event } = this.props;
		const {
			sending,
			attendees,
			sessions,
			groups,
			completed,
			sentCount,
			eventGroups,
			totalEmails,
			date,
			requiredFields,
			time, isDateBefore
		} = this.state;
		const fixedStartDate = moment(`${date}`).format('YYYY-MM-DD');
		const currentDateTimezone = moment().tz(event.timezone).format('YYYY-MM-DD');

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Schedule Email"}
					bodyHeaderText={<div>Schedule your email to be sent at a specific time in the future. <br /><span>Current time: {moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a')} {moment().tz(event.timezone).zoneAbbr()}</span></div>}

					bodyContent={() => {
						return (
							<div>
								<div>
									<span >Send to </span>
									{attendees.length !== 0 ? (
										`${attendees.length} Attendees`
									) : sessions.length !== 0 ? (
										`${sessions.length} Sessions`
									) : groups.length !== 0 ? (
										eventGroups.map((eg) => {
											return eg.name + ', ';
										})
									) : (
										'Nothing selected'
									)}
									{email.bcc.length > 0 && ' and the bcc list '}
									<span> on:</span>
								</div>
								<div style={{ display: 'flex', paddingTop: 10 }}>
									<div>
										<InputField
											label={'Date'}
											style={{ width: 150 }}
											value={fixedStartDate}
											required={true}
											errorTrigger={requiredFields.date}
											type={'date'}
											inputIcon={<i className="lar la-calendar" />}
											onChange={(e) => {
												this.handleDateChange(moment(e.target.value).format('MM/DD/YYYY'));
											}}
											min={currentDateTimezone}
										/>
									</div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '0 10px'
										}}
									>
										at
									</div>
									<TimeField
										label={'Send Time'}
										style={{ width: 150 }}
										value={time}
										required={true}
										errorTrigger={requiredFields.time}
										onChange={(e) => {
											this.handleTimeChange(e);
										}}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '0 10px'
										}}
									>
										{moment().tz(event.timezone).zoneAbbr()}
									</div>
								</div>
								{isDateBefore && <div style={{ paddingTop: 5, color: '#b90000', height: 'unset' }}>
									Scheduled time must be after the current time at the event's timezone.
								</div>}
								<div style={{ paddingTop: 5, paddingBottom: 25, height: 'unset' }}>
									{sending && `${!completed ? 'Sending' : 'Sent'} ${sentCount} of ${totalEmails} attendees`}
								</div>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Schedule Email' : completed ? 'Complete' : 'Sending...'}
					actionButton={() => {
						this.scheduleEmail();
					}}
					actionButtonDisabled={sending || completed}
					isFullWidthBody={false}
					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}


				></FieldModal>


				{/* <Modal isOpen={isOpen} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="fs-30 calibreBold">Schedule Email</div>
					<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
						Schedule your email to be sent at a specific time in the future.<br />
						<span style={{
							fontFamily: 'Calibre-Semibold'
						}}>Current time:</span> {`${moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a')} ${moment().tz(event.timezone).zoneAbbr()}`}
					</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<div
						className="fs-16"
						style={{
							fontFamily: 'Calibre-Semibold'
						}}
					>
						<span className="calibreRegular">Send to </span>
						{attendees.length !== 0 ? (
							`${attendees.length} Attendees`
						) : sessions.length !== 0 ? (
							`${sessions.length} Sessions`
						) : groups.length !== 0 ? (
							eventGroups.map((eg) => {
								return eg.name + ', ';
							})
						) : (
							'Nothing selected'
						)}
						{email.bcc.length > 0 && ' and the bcc list '}
						<span className="calibreRegular"> on:</span>
					</div>
					<div style={{ display: 'flex', paddingTop: 10 }}>
						<div>
							<InputField
								label={'Date'}
								value={fixedStartDate}
								required={true}
								errorTrigger={requiredFields.date}
								type={'date'}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.handleDateChange(moment(e.target.value).format('MM/DD/YYYY'));
								}}
								min={currentDateTimezone}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '0 10px'
							}}
						>
							at
						</div>
						<TimeField
							label={'Send Time'}
							style={{ width: 150 }}
							value={time}
							required={true}
							errorTrigger={requiredFields.time}

							onChange={(e) => {
								this.handleTimeChange(e);
							}}
						/>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '0 10px'
							}}
						>
							{moment().tz(event.timezone).zoneAbbr()}
						</div>
					</div>
					{isDateBefore && <div className="calibreRegular fs-16" style={{ paddingTop: 5, color: '#b90000', height: 'unset' }}>
						Scheduled time must be after the current time at the event's timezone.
					</div>}
					<div className="calibreRegular fs-16" style={{ paddingTop: 5, paddingBottom: 25, height: 'unset' }}>
						{sending && `${!completed ? 'Sending' : 'Sent'} ${sentCount} of ${totalEmails} attendees`}
					</div>
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						disabled={sending || completed}
						className="modalButton actionButton actionSave m-0"
						onClick={this.scheduleEmail}
					>
						<div className="modalButtonText">
							{!sending ? 'Schedule Email' : completed ? 'Complete' : 'Sending...'}
						</div>
					</Button>
					<Button onClick={toggle} outline className="modalButton actionButton modalCancel mb-0 ml-a">
						<div className="modalCancelText">{!completed ? 'Cancel' : 'Close'}</div>
					</Button>
				</ModalFooter>
			</Modal > */}
			</div >


		);
	}
}

export default ScheduleCampaignModal;
