import React, { Component } from 'react';
import API from '../../utils/API';
import InputField from '../../components/inputs/inputField';
import logo from '../../assets/img/logos/simple events logo.png';
import { withRouter } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import FileUploadInputBox from '../../components/inputs/fileUploadInputBox';
import ImageCropper from '../../components/modals/imageCropper';
const defaultPermissions = {
    readOnly: false,
    capabilities: ['overview', 'details', 'attendees', 'schedule', 'groups', 'inventory', 'emails', 'texting', 'forms', 'pages', 'engagement', 'media'],
    permissions: {
        overview: {
            write: true,
            read: true
        },
        details: {
            write: true,
            read: true
        },
        attendees: {
            write: true,
            read: true
        },
        schedule: {
            write: true,
            read: true
        },
        groups: {
            write: true,
            read: true
        },
        inventory: {
            write: true,
            read: true
        },
        emails: {
            write: true,
            read: true
        },
        texting: {
            write: true,
            read: true
        },
        forms: {
            write: true,
            read: true
        },
        pages: {
            write: true,
            read: true
        },
        engagement: {
            write: true,
            read: true
        },
        media: {
            write: true,
            read: true
        },
        insights: {
            write: true,
            read: true
        },
        reports: {
            write: true,
            read: true
        },
        notifications: {
            write: true,
            read: true
        }
    }
};
class Welcome extends Component {
    constructor(props) {
        super(props);
        this.fileInput = null;

        this.state = {
            email: '',
            password: '',
            error: '',
            disableSubmit: true,
            isChecked: true,
            passwordErrorTrigger: false,
            emailErrorTrigger: false,
            decoded: {},
            firstName: '',
            lastName: '',
            phoneNumber: '',
            confirmPassword: '',
            formatErrorTrigger: false,
            showPassword: {
                password: false,
                confirmPassword: false,
            },
            requiredFields: {
                email: false,
                password: false,
                confirmPassword: false,
                firstName: false,
                lastName: false,
            },
            accountCreatedSuccessFully: false,
            toggleImageCropper: false,
            selectedFile: null,
            tempFile: '',
            blobFile: null,
            errorMessage: '',
            eventId: null,


        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const token = urlParams.get('jwt');

        if (token) {
            var decoded = jwt_decode(token);
            let email = decoded.email;
            let firstName = decoded.firstName || '';
            let lastName = decoded.lastName || '';
            let eventId = decoded.eventId || null;
            this.setState({ decoded: decoded, token: token, email, firstName, lastName, eventId });
        } else {
            window.location.href = "/";
        }

        //listen to the "enter" key to fire of the submit function
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                this.submit(e);
            }
        });

    }


    handleChecked = (e) => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked
        }));
    };

    submit = () => {
        const { password, confirmPassword, tempFile, blobFile, eventId } = this.state;
        const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        let valid = true;
        if (!passwordRegEx.test(password)) {
            valid = false;
            this.setState({ formatErrorTrigger: true });
        } else {
            this.setState({ formatErrorTrigger: false });
        }

        if (password !== confirmPassword) {
            valid = false;
            this.setState({ passwordErrorTrigger: true });
            return;
        } else {
            this.setState({ passwordErrorTrigger: false });
        }

        if (!valid) {
            return;
        }

        const data = new FormData();

        data.append('emailAddress', this.state.email);
        data.append('password', this.state.password);
        data.append('firstName', this.state.firstName);
        data.append('lastName', this.state.lastName);
        data.append('phoneNumber', this.state.phoneNumber);
        if (eventId) {
            let eventPermissions = defaultPermissions;
            eventPermissions.eventId = eventId;
            data.append('eventPermissions', JSON.stringify([eventPermissions]));
        }
        data.append('token', this.state.token);

        if (blobFile) {
            data.append('includesImage', true);
            data.append('files', blobFile);
        }



        API()
            .post(`/auth/createAccountFromInvite`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                if (res.data && res.data.success) {
                    this.setState({ accountCreatedSuccessFully: true });
                } else {
                    this.setState({ errorMessage: res.data.message, disableSubmit: true });
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    enableSubmit = () => {

        const { email, password, confirmPassword, firstName, lastName } = this.state;

        if (email !== '' && password !== '' && confirmPassword !== '' && firstName !== '' && lastName !== '') {
            this.setState({ disableSubmit: false });
        } else {
            this.setState({ disableSubmit: true });
        }
    }

    openFileSelector = () => {

        if (this.fileInput) {
            this.fileInput.click();
        }

    }

    handleFileChange = (e) => {
        const reader = new FileReader();
        const selectedFile = e.target.files[0];
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {

            this.setState({ selectedFile: reader.result, toggleImageCropper: true });
        }
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };

    saveImage = (image, file) => {
        let tempurl = '';
        for (let pair of image.entries()) {
            if (pair[1] instanceof Blob) {
                const blob = pair[1];
                const url = URL.createObjectURL(blob);
                tempurl = url;
            }
        }

        this.setState({ tempFile: tempurl, blobFile: file }, () => {
            this.toggleImageCropper();
        });
    }

    toggleImageCropper = () => {
        this.setState({ toggleImageCropper: !this.state.toggleImageCropper });
    }

    render() {
        const {
            passwordErrorTrigger,
            disableSubmit,
            password,
            confirmPassword,
            firstName,
            lastName,
            phoneNumber,
            formatErrorTrigger,
            showPassword,
            accountCreatedSuccessFully,
            toggleImageCropper,
            tempFile,
            errorMessage
        } = this.state;

        return (
            <div className='w-100 h-100 flex column'>
                <div className='systemToolbar w-100' >
                    <div className="flex h-100 ml-30" style={{ alignItems: 'end' }}>

                        <div className="navLogo c-pointer" style={{ marginRight: 113 }} onClick={() => window.location.href = "/"}>
                            <img src={logo} />
                        </div>
                    </div>
                </div>
                <div className='w-fc p-0 neuCard  h-100' style={{ background: 'white', border: 'none', width: 'calc(100% - 15px)', marginLeft: 15 }}>

                    <div className="w-100 m-a mt-20 p-50" style={{ maxWidth: 560 }}>

                        <h1 className='mb-20 text-c'>Welcome to simple.events</h1>
                        {accountCreatedSuccessFully ? <div>
                            <p className='text-c'>
                                Account created successfully!

                                <button
                                    onClick={() => {
                                        this.props.history.push('/login');

                                    }}
                                    className={`prim w-100 mt-50`}
                                >
                                    Go to login
                                </button>
                            </p>
                        </div> : <>

                            <p className='text-c'>
                                Please complete the form below to activate your account.
                            </p>
                            <div className='pt-35'>
                                <InputField
                                    label={'First Name'}
                                    onChange={(e) => {
                                        this.setState({ firstName: e.currentTarget.value }, this.enableSubmit);
                                    }}
                                    required={true}
                                    value={firstName}
                                    errorMessage={'First name required'}
                                />
                                <InputField
                                    label={'Last Name'}
                                    onChange={(e) => {
                                        this.setState({ lastName: e.currentTarget.value }, this.enableSubmit);
                                    }}
                                    required={true}
                                    value={lastName}
                                    errorMessage={'Last name required'}
                                />
                                <InputField
                                    label={'Email'}
                                    type={'email'}
                                    value={this.state.email}
                                    disabled={true}
                                    errorMessage={'Email required'}
                                    inputIcon={<i className="las la-lock" />}
                                />

                                <InputField
                                    label={'Create your password'}
                                    type={showPassword.password ? 'text' : 'password'}
                                    onChange={(e) => {
                                        this.setState({ password: e.currentTarget.value }, this.enableSubmit);
                                    }}
                                    value={password}
                                    required={true}
                                    errorMessage={'Invalid format'}
                                    errorTrigger={formatErrorTrigger}
                                    inputActionLabel={!showPassword.password ? <i className="las la-eye grey"></i> : <i className="las la-eye-slash grey"></i>}
                                    inputAction={() => {
                                        this.setState({ showPassword: { ...showPassword, password: !showPassword.password } });
                                    }}

                                />
                                <InputField
                                    label={'Confirm your password'}
                                    type={showPassword.confirmPassword ? 'text' : 'password'}
                                    required={true}
                                    onChange={(e) => {
                                        this.setState({ confirmPassword: e.currentTarget.value }, this.enableSubmit);
                                    }}
                                    value={confirmPassword}
                                    errorMessage={'Passwords do not match'}
                                    inputActionLabel={!showPassword.confirmPassword ? <i className="las la-eye grey"></i> : <i className="las la-eye-slash grey"></i>}
                                    errorTrigger={passwordErrorTrigger}
                                    inputAction={() => {
                                        this.setState({ showPassword: { ...showPassword, confirmPassword: !showPassword.confirmPassword } });
                                    }}
                                />

                                <InputField
                                    label={'Phone'}
                                    type={'tel'}
                                    onChange={(e) => {
                                        this.setState({ phoneNumber: e.currentTarget.value }, this.enableSubmit);
                                    }}
                                    value={phoneNumber}
                                    errorMessage={'Invalid phone number'}
                                />


                                <FileUploadInputBox
                                    label="Profile Picture"
                                    addText="Image"
                                    value={tempFile}
                                    onClick={(e) => {
                                        this.openFileSelector();
                                    }}
                                    clearFile={() => {
                                        this.setState({ tempFile: '', blobFile: null });
                                    }}
                                />


                                {toggleImageCropper && <ImageCropper
                                    isOpen={toggleImageCropper}
                                    toggle={this.toggleImageCropper}
                                    selectedFile={this.state.selectedFile}
                                    returnBlob={true}
                                    saveImage={this.saveImage}
                                />}

                                <input
                                    type="file"
                                    ref={(input) => (this.fileInput = input)}
                                    style={{ display: 'none' }}
                                    onChange={this.handleFileChange}
                                />

                            </div>

                            <button
                                onClick={this.submit}
                                className={`prim ${disableSubmit ? 'inactive' : ''} w-100 mt-20`}
                                disabled={disableSubmit}
                            >
                                Submit
                            </button>
                            {errorMessage && <p className='text-c mt-20 danger'>{errorMessage}</p>}
                        </>
                        }


                    </div>
                </div>
            </div>
        );
    }


}

export default withRouter(Welcome);
