import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import API from '../../utils/API';
import ViewTab from '../tabs/ViewTab';
import MoreViewsTab from '../tabs/MoreViewsTab';
import ColumnFilterRow from '../dropdown/columnFilterRow';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ViewsTable from '../tables/ViewsTable';
import DeleteViewModal from './modals/deleteViewModal';
import RenameViewModal from './modals/renameViewModal';
import DuplicateViewModal from './modals/duplicateViewModal';
import moment from 'moment';
import CheckboxInput from '../inputs/checkboxInput';
import InputField from '../inputs/inputField';
import AddReportViewModal from './modals/addReportViewModal';
import ShareReportModal from '../../sections/event/reports/modals/shareReports';

const getComponentListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: '0 25px',
    margin: '0 -25px',
    marginBottom: '5px'
});


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging && 'lightgrey',
    /* borderBottom: '1px solid grey',
    padding: '15px 0', */
    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
class ReportsContainer extends Component {
    constructor(props) {
        super(props);
        let customViews = props.report.views.map((view) => {
            const columns = view.customFieldColumns;
            const columnTypes = view.defaultFieldTypes;
            const toggledColumns = {};
            const normalizedColumns = columns ? Object.keys(columns).map((cfc) => {
                toggledColumns[cfc] = view.toggledColumns.includes(cfc);
                return {
                    label: columns[cfc],
                    key: cfc,
                    value: cfc,
                    type: (columnTypes && columnTypes[cfc]) || typeof columns[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            }) : [];
            if (columns && Object.keys(columns).length > 0) {
                delete toggledColumns[Object.keys(columns)[0]];
            }
            return {
                ...view,
                toggledColumns: toggledColumns,
                normalizedColumns: normalizedColumns
            }
        });
        this.state = {
            newEntries: [],
            checkedEntries: {},
            allEntries: JSON.parse(JSON.stringify(props.entries)),
            organizedEntries: props.entries,
            allChecked: false,
            windowWidth: window.innerWidth,
            activeView: 0,
            focusedView: 0,
            searching: false,
            searchTerm: '',
            searchFocused: false,
            searchedEntries: [],
            columnSearchTerm: '',
            defaultViewFilters: [
                {
                    column: '',
                    condition: '',
                    value: ''
                }
            ],
            customViews: customViews,
            addViewModalOpen: false,
            duplicateViewModalOpen: false,
            deleteViewModalOpen: false,
            renameViewModalOpen: false,
            addCustomFieldModalOpen: false,
            renameCustomFieldModalOpen: false,
            deleteCustomFieldModalOpen: false,
            convertCustomFieldModalOpen: false,
            shareModalOpen: false,
            focusedCustomField: null,
            morePosition: null,
            deletedCustomField: {}
        };
    }

    //search all entries properties for search term
    searchEntries = () => {
        const { organizedEntries, searchTerm, activeView, customViews } = this.state;

        const selectedCustomView = customViews[activeView];
        let columns = selectedCustomView.customFieldColumns;
        if (selectedCustomView) {
            const customColumns = {};
            let columnsCustomView = Object.keys(selectedCustomView.toggledColumns);
            columnsCustomView.forEach((col) => {
                customColumns[col] = columns[col];
            });
            const mainColumnKey = Object.keys(columns)[0];
            const mainColumn = columns[mainColumnKey];
            columns = customColumns;
            columns[mainColumnKey] = mainColumn;
        }

        let searchedEntries = organizedEntries[selectedCustomView.type];

        if (searchTerm.length > 0) {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term != '') {
                    searchedEntries = searchedEntries.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }
        const sc = this.state.searchedEntries;
        sc[selectedCustomView.type] = searchedEntries;
        this.setState({ searching: searchTerm != '', searchedEntries: sc });
    };

    componentDidMount() {
        window.onresize = () => {
            this.setState({ windowWidth: window.innerWidth });
        };
        this.props.report.views.forEach((av, vi) => {
            this.countFilteredEntries(av);
            this.filterView(vi);
        });
        if (this.props.report.views.length > 0)
            this.selectView(0);
    }

    setOrderOfColumns = (viewIndex, orderArray) => {
        const { customViews } = this.state;
        const activeCustomView = customViews[viewIndex];
        const columns = activeCustomView.customFieldColumns;
        const columnTypes = activeCustomView.defaultFieldTypes;
        const toggledColumns = {};
        const normalizedColumns = columns ? Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: (columnTypes && columnTypes[cfc]) || typeof columns[cfc],
                sortAsc: false,
                sortDesc: false
            };
        }) : [];
        let processedColumns = [];
        let newCol = [];
        if (orderArray) {
            let sortedNormalizedColumns = [];
            let deletedColumns = [];
            let addedColumns = [];
            orderArray.forEach((col, i) => {
                let tempC = normalizedColumns.find(nc => nc.key == col);
                processedColumns.push(col);

                if (!tempC) {
                    deletedColumns.push(col);
                } else {
                    sortedNormalizedColumns.push(tempC);
                }

            });

            normalizedColumns.forEach((nc) => {
                if (!processedColumns.includes(nc.key)) {
                    sortedNormalizedColumns.push(nc);
                }
            })

            sortedNormalizedColumns.filter(c => !Object.keys(this.state.deletedCustomField).includes(c.key))
            this.setState({ normalizedColumns: sortedNormalizedColumns });
        } else {
            let sortedNormalizedColumns = Object.keys(activeCustomView.customFieldColumns).map((cfc) => {
                return {
                    label: activeCustomView.customFieldColumns[cfc],
                    key: cfc,
                    value: cfc,
                    type: (activeCustomView.defaultFieldTypes && activeCustomView.defaultFieldTypes[cfc]) || typeof activeCustomView.customFieldColumns[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            }).filter(c => !Object.keys(this.state.deletedCustomField).includes(c.key));
            this.setState({ normalizedColumns: sortedNormalizedColumns });
        }
    }

    renderToggleColumnItem = (key, viewId, defaultChecked) => {
        const { activeView } = this.state;

        const view = this.state.customViews[activeView];
        const columns = view.customFieldColumns;
        const categoryColumns = view.fieldCategories;
        const columnTypes = view.defaultFieldTypes;
        const toggledColumns = view.toggledColumns;
        let categoryTitle = 'Basic';
        if (key.includes('*')) {
            let splitKey = key.split("*");
            let category = splitKey[0];
            let value = splitKey[1];
            categoryTitle = categoryColumns[category].title;
        } else if (key.includes('_')) {
            let splitKey = key.split('_');
            let category = splitKey[0];
            let value = splitKey[1];
            categoryTitle = categoryColumns[category].title;

        }
        return (
            <div className={`flex-sb flex-ac `} >
                <i className="las la-braille mr-5"></i>
                <CheckboxInput
                    label={columns[key]}
                    eyebrow={categoryTitle}
                    checked={toggledColumns[key] || defaultChecked}
                    unsetHeight={true}
                    onClick={() => {
                        const customViews = this.state.customViews;
                        const vi = this.state.activeView;
                        const cView = this.state.customViews[vi];
                        if (cView.toggledColumns[key]) {
                            cView.toggledColumns[key] = false;
                        } else {
                            cView.toggledColumns[key] = true;
                        }
                        cView.unsavedColumns = true;
                        customViews[vi] = cView;
                        this.setState({ customViews }, () => this.filterView(vi));
                    }}
                />
            </div>
        );
    };

    checkEntry = (entryId) => {
        const checked = this.state.checkedEntries;
        checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
        this.setState({
            checkedEntries: checked
        });
    };

    checkEntries = (entryIds) => {
        let checked = this.state.checkedEntries;
        entryIds.length > 0
            ? entryIds.forEach((entryId) => {
                checked[entryId] = true;
            })
            : (checked = {});
        this.setState({
            checkedEntries: checked
        });
    };

    filterView = (viewIndex) => {
        const { allEntries, customViews, defaultViewFilters, organizedEntries } = this.state;
        let filterLayers = defaultViewFilters;
        const selectedCustomView = customViews[viewIndex];
        const aEntries = selectedCustomView ? allEntries[selectedCustomView.type] : [];
        const activeViewFilter = viewIndex;

        if (customViews[activeViewFilter] && customViews[activeViewFilter].filterLayers) {
            filterLayers = customViews[activeViewFilter].filterLayers;
            if (customViews[activeViewFilter].columnsOrder && customViews[activeViewFilter].columnsOrder.length > 0) {

            }
            /* (customViews[activeViewFilter].columnsOrder && customViews[activeViewFilter].columnsOrder.length > 0) ? this.setOrderOfColumns(viewIndex, customViews[activeViewFilter].columnsOrder, !customViews[activeViewFilter].unsavedColumns) : this.setOrderOfColumns(viewIndex); */
        }
        const filteredView =
            filterLayers.length > 0 && filterLayers[0].column != ''
                ? aEntries.filter((a) => {
                    let valid = true;
                    filterLayers.forEach((layer, index) => {
                        if (a[layer.column] || a[layer.column] == false) {
                            switch (layer.condition) {
                                case 'is':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                    break;
                                case 'isnot':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                    break;
                                case 'contains':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'doesnotcontain':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'isempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == '')
                                            : valid || (!a[layer.column] || a[layer.column] == '');
                                    break;
                                case 'isnotempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] && a[layer.column] != '')
                                            : valid || (a[layer.column] && a[layer.column] != '');
                                    break;
                                case 'isdatelaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                            : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                    break;
                                case 'isdateearlierthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                            : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                    break;
                                case 'isdateequalto':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                            : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                    break;
                                case 'isupcoming':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment())
                                            : valid || moment(a[layer.column]).isAfter(moment());
                                    break;
                                case 'ispast':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment())
                                            : valid || moment(a[layer.column]).isBefore(moment());
                                    break;

                                case 'istoday':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY')
                                            : valid ||
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY');
                                    break;
                                case 'isyes':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] || a[layer.column] == true)
                                            : valid || (a[layer.column] || a[layer.column] == true);
                                    break;
                                case 'isno':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == false)
                                            : valid || (!a[layer.column] || a[layer.column] == false);
                                    break;
                                case 'isnumbergreaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) > layer.value
                                            : valid || parseInt(a[layer.column]) > layer.value;
                                    break;
                                case 'isnumberlessthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) < layer.value
                                            : valid || parseInt(a[layer.column]) < layer.value;
                                    break;
                                default:
                            }
                        } else {
                            valid = false;
                        }
                    });
                    return valid;
                })
                : aEntries;
        if (customViews[activeViewFilter])
            customViews[activeViewFilter].count = filteredView.length;
        //customViews[activeViewFilter].unsavedFilter = true;
        const oEntries = organizedEntries;
        if (selectedCustomView) oEntries[selectedCustomView.type] = filteredView;
        this.setState({
            organizedEntries: oEntries,
            customViews: customViews
        });
    };

    countFilteredEntries = async (view) => {
        const { allEntries, customViews, activeView } = this.state;
        const selectedCustomView = customViews[activeView];
        const aEntries = allEntries[selectedCustomView.type];
        const filteredView =
            view.filterLayers.length > 0 && view.filterLayers[0].column != ''
                ? aEntries.filter((a) => {
                    let valid = true;
                    view.filterLayers.forEach((layer, index) => {
                        if (a[layer.column] || a[layer.column] == false) {
                            switch (layer.condition) {
                                case 'is':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                    break;
                                case 'isnot':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                            : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                    break;
                                case 'contains':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'doesnotcontain':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                            : valid ||
                                            !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                    break;
                                case 'isempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == '')
                                            : valid || (!a[layer.column] || a[layer.column] == '');
                                    break;
                                case 'isnotempty':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] && a[layer.column] != '')
                                            : valid || (a[layer.column] && a[layer.column] != '');
                                    break;
                                case 'isdatelaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                            : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                    break;
                                case 'isdateearlierthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                            : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                    break;
                                case 'isdateequalto':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                            : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                    break;
                                case 'isupcoming':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isAfter(moment())
                                            : valid || moment(a[layer.column]).isAfter(moment());
                                    break;
                                case 'ispast':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && moment(a[layer.column]).isBefore(moment())
                                            : valid || moment(a[layer.column]).isBefore(moment());
                                    break;
                                case 'istoday':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid &&
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY')
                                            : valid ||
                                            moment(a[layer.column]).format('MM/DD/YYYY') ==
                                            moment().format('MM/DD/YYYY');
                                    break;
                                case 'isyes':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (a[layer.column] || a[layer.column] == true)
                                            : valid || (a[layer.column] || a[layer.column] == true);
                                    break;
                                case 'isno':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && (!a[layer.column] || a[layer.column] == false)
                                            : valid || (!a[layer.column] || a[layer.column] == false);
                                    break;
                                case 'isnumbergreaterthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) > layer.value
                                            : valid || parseInt(a[layer.column]) > layer.value;
                                    break;
                                case 'isnumberlessthan':
                                    valid =
                                        layer.concatOperator == 'and'
                                            ? valid && parseInt(a[layer.column]) < layer.value
                                            : valid || parseInt(a[layer.column]) < layer.value;
                                    break;
                                default:
                            }
                        } else {
                            valid = false;
                        }
                    });
                    return valid;
                })
                : aEntries;
        const viewIndex = customViews.findIndex((a) => a._id == view._id);
        customViews[viewIndex] && (customViews[viewIndex].count = filteredView.length);
        this.setState({ customViews: customViews });

    };

    selectView = (viewIndex) => {
        this.setState({ activeView: viewIndex, searchTerm: '', searching: false, checkedEntries: {} }, () => {
            this.filterView(viewIndex);
            const { customViews } = this.state;
            const view = customViews[viewIndex];
            const normalizedColumns = view.normalizedColumns;
            let sortColumn = normalizedColumns[0];
            this.sortData(sortColumn, sortColumn.sortAsc, sortColumn.sortDesc);
        });
    };

    addView = (view) => {
        const { customViews } = this.state;
        //set normalized columns
        const columns = view.customFieldColumns;
        const columnTypes = view.defaultFieldTypes;
        const toggledColumns = {};
        const normalizedColumns = columns ? Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: (columnTypes && columnTypes[cfc]) || typeof columns[cfc],
                sortAsc: false,
                sortDesc: false
            };
        }) : [];

        if (columns && Object.keys(columns).length > 0) {
            delete toggledColumns[Object.keys(columns)[0]];
        }
        view.toggledColumns = toggledColumns;
        view.normalizedColumns = normalizedColumns;
        customViews.push(view);
        this.setState({ customViews }, () => {
            this.filterView(customViews.length - 1);
            this.countFilteredEntries(view);
        });
        //this.props.addView(view);
    };

    updateFocusedView = (updates) => {
        const { focusedView, customViews } = this.state;
        let fView = customViews[focusedView - 1];
        fView = { ...fView, ...updates };
        const customViewIndex = customViews.findIndex((a) => a._id == fView._id);
        customViews[customViewIndex] = fView;
        this.setState({ customViews }, () => this.filterView(customViewIndex));

        //this.props.updateView(fView);
    };

    removeFocusedView = (viewId) => {
        const { customViews } = this.state;
        const customViewIndex = customViews.findIndex((a) => a._id == viewId);
        customViews.splice(customViewIndex, 1);
        this.setState({ customViews, activeView: 0 }, () => this.filterView(0));

        //this.props.removeView(viewId);
    };

    saveCurrentView = async (filters, columns) => {
        const { activeView, customViews } = this.state;
        const view = customViews[activeView];
        const payload = {};
        let sortedColumns = [];
        view.normalizedColumns.map((nc) => {
            sortedColumns.push(nc.key);
        })
        if (
            filters &&
            view.filterLayers.length > 0 &&
            view.filterLayers[0].condition &&
            view.filterLayers[0].condition != ''
        ) {
            payload.filterLayers = view.filterLayers;
        } else if (filters && view.filterLayers.length > 0) {
            payload.filterLayers = [];
        }

        if (columns) {
            payload.toggledColumns = [];
            Object.keys(view.toggledColumns).forEach((col) => {
                if (view.toggledColumns[col]) {
                    payload.toggledColumns.push(col);
                }
            });
        }
        payload.columnsOrder = sortedColumns;
        payload.reportId = this.props.report._id;
        await API()
            .patch(`/Organizations/${this.props.orgId}/views/${view._id}`, payload)
            .then((res) => {
                //this.setState({ submitting: false });
                //this.props.updateView(res.data);
                //find view and remove unsaved property
                if (filters) customViews[activeView].unsavedFilter = false;
                if (columns) customViews[activeView].unsavedColumns = false;

                this.setState({ customViews });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    sortData = (column, sortAsc, sortDesc) => {
        const { organizedEntries } = this.state;
        const activeCustomView = this.state.customViews[this.state.activeView];
        const normalizedColumns = activeCustomView.normalizedColumns;
        const updatedColumns = normalizedColumns.map((col) => {
            if (col.key == column.key) {
                col.sortAsc = sortAsc;
                col.sortDesc = sortDesc;
            } else {
                col.sortAsc = false;
                col.sortDesc = false;
            }
            return col;
        });

        let reorganizedData = {};
        reorganizedData[activeCustomView.type] = organizedEntries[activeCustomView.type].sort((a, b) => {
            const columnType = column.type || typeof a[column.key];
            if (a[column.key] && b[column.key]) {
                switch (columnType) {
                    case 'string':
                        return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
                    case 'number':
                        return a[column.key] - b[column.key];
                    case 'currency':
                        return a[column.key] - b[column.key];
                    case 'date':
                    case 'time':
                    case 'timestamp':
                        return new Date(a[column.key]).getTime() - new Date(b[column.key]).getTime();
                    case 'boolean':
                        const a1 = a[column.key] ? 'yes' : 'no';
                        const b1 = b[column.key] ? 'yes' : 'no';
                        return a1.localeCompare(b1);
                    case 'bool':
                        const c1 = a[column.key] ? 'yes' : 'no';
                        const d1 = b[column.key] ? 'yes' : 'no';
                        return c1.localeCompare(d1);
                    default:
                        return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
                }
            } else if (a[column.key]) {
                return 1;
            }
            return -1;
        });
        reorganizedData[activeCustomView.type] = sortDesc ? reorganizedData[activeCustomView.type].reverse() : reorganizedData[activeCustomView.type];
        this.setState({ organizedEntries: reorganizedData, normalizedColumns: updatedColumns });
    };

    s2ab = (s) => {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
    };

    downloadReport = async () => {
        const { activeView, customViews, organizedEntries, allEntries } = this.state;
        const selectedCustomView = customViews[activeView - 1];
        let columns = selectedCustomView.customFieldColumns;
        let defaultFieldTypes = selectedCustomView.defaultFieldTypes;
        if (selectedCustomView) {
            const customColumns = {};
            if (selectedCustomView.columnsOrder && selectedCustomView.columnsOrder.length > 0) {
                let columnsOrder = selectedCustomView.columnsOrder;
                let toggledC = selectedCustomView.toggledColumns;

                columnsOrder.forEach(co => {
                    if (toggledC.includes(co)) {
                        if (columns[co]) {
                            customColumns[co] = columns[co];
                        }
                    }
                })
                columns = {};
                const mainColumn = Object.keys(columns)[0];
                columns[mainColumn] = columns[mainColumn];
                columns = { ...columns, ...customColumns };
            } else {
                //legacy
                selectedCustomView.toggledColumns.forEach((col) => {
                    if (columns[col]) {
                        customColumns[col] = columns[col];
                    }
                });
                columns = {};
                const mainColumn = Object.keys(columns)[0];
                columns[mainColumn] = columns[mainColumn];
                columns = { ...columns, ...customColumns };
            }

        } else {
            const customColumns = {};
            Object.keys(this.state.toggledColumns).forEach((col) => {
                customColumns[col] = columns[col];
            });
            columns = {};
            const mainColumn = Object.keys(columns)[0];
            columns[mainColumn] = columns[mainColumn];
            columns = { ...columns, ...customColumns };
        }
        columns = { ...columns };
        const entries = organizedEntries; //selectedCustomView ? organizedEntries : allEntries;
        const dataRows = [];
        entries.forEach((entry) => {
            const dataRow = {};
            Object.keys(columns).forEach((hc) => {
                if (entry[hc] != null) {
                    if (defaultFieldTypes[hc] === 'boolean' || defaultFieldTypes[hc] === 'bool') {
                        dataRow[hc] = entry[hc] ? 'Yes' : 'No';
                    } else {
                        dataRow[hc] = entry[hc];
                    }
                }
            });
            dataRows.push(dataRow);
        });
        const cleanData = [];
        const headers = Object.keys(columns).map((header, hIndex) => {
            dataRows.forEach((data, dIndex) => {
                if (!cleanData[dIndex]) cleanData[dIndex] = [];
                cleanData[dIndex][hIndex] = data[header] || '';
            });
            return columns[header];
        });
        const data = {
            headerRow: headers,
            dataRows: cleanData
        };

        const reportDetails = selectedCustomView
            ? { Title: selectedCustomView.name, Subject: selectedCustomView.name }
            : { Title: 'View Report', Subject: 'View Report' };
        this.downloadRegular(reportDetails, data);
    };

    downloadRegular = (report, data) => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: report.Title,
            Subject: report.Subject,
            Author: 'Simple Events',
            CreatedDate: new Date()
        };
        wb.SheetNames.push('Data');
        /* make worksheet */
        var ws_data2 = [data.headerRow, ...data.dataRows];
        var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

        /* Add the worksheet to the workbook */
        wb.Sheets['Data'] = ws2;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        saveAs(
            new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
            `${report.Title}_${moment().format('LLL')}.xlsx`
        );
    };

    //every view is a tab on the excel sheet
    downloadFullReport = async () => {
        const { report } = this.props;
        const { customViews, allEntries } = this.state;
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: report.name,
            Subject: report.description,
            Author: 'Simple Events',
            CreatedDate: new Date()
        };
        const data = {};
        customViews.forEach((view) => {
            const columns = view.customFieldColumns;
            const toggledColumns = view.toggledColumns;
            const defaultFieldTypes = view.defaultFieldTypes;
            const aEntries = allEntries[view.type];
            const dataRows = [];
            const filterLayers = view.filterLayers;
            const filteredView =
                filterLayers.length > 0 && filterLayers[0].column != ''
                    ? aEntries.filter((a) => {
                        let valid = true;
                        filterLayers.forEach((layer, index) => {
                            if (a[layer.column] || a[layer.column] == false) {
                                switch (layer.condition) {
                                    case 'is':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && a[layer.column].toLowerCase() == layer.value.toLowerCase()
                                                : valid || a[layer.column].toLowerCase() == layer.value.toLowerCase();
                                        break;
                                    case 'isnot':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && a[layer.column].toLowerCase() != layer.value.toLowerCase()
                                                : valid || a[layer.column].toLowerCase() != layer.value.toLowerCase();
                                        break;
                                    case 'contains':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid &&
                                                a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                                : valid ||
                                                a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                        break;
                                    case 'doesnotcontain':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid &&
                                                !a[layer.column].toLowerCase().includes(layer.value.toLowerCase())
                                                : valid ||
                                                !a[layer.column].toLowerCase().includes(layer.value.toLowerCase());
                                        break;
                                    case 'isempty':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (!a[layer.column] || a[layer.column] == '')
                                                : valid || (!a[layer.column] || a[layer.column] == '');
                                        break;
                                    case 'isnotempty':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (a[layer.column] && a[layer.column] != '')
                                                : valid || (a[layer.column] && a[layer.column] != '');
                                        break;
                                    case 'isdatelaterthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isAfter(moment(layer.value))
                                                : valid || moment(a[layer.column]).isAfter(moment(layer.value));
                                        break;
                                    case 'isdateearlierthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isBefore(moment(layer.value))
                                                : valid || moment(a[layer.column]).isBefore(moment(layer.value));
                                        break;
                                    case 'isdateequalto':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isSame(moment(layer.value))
                                                : valid || moment(a[layer.column]).isSame(moment(layer.value));
                                        break;
                                    case 'isupcoming':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isAfter(moment())
                                                : valid || moment(a[layer.column]).isAfter(moment());
                                        break;
                                    case 'ispast':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && moment(a[layer.column]).isBefore(moment())
                                                : valid || moment(a[layer.column]).isBefore(moment());
                                        break;

                                    case 'istoday':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid &&
                                                moment(a[layer.column]).format('MM/DD/YYYY') ==
                                                moment().format('MM/DD/YYYY')
                                                : valid ||
                                                moment(a[layer.column]).format('MM/DD/YYYY') ==
                                                moment().format('MM/DD/YYYY');
                                        break;
                                    case 'isyes':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (a[layer.column] || a[layer.column] == true)
                                                : valid || (a[layer.column] || a[layer.column] == true);
                                        break;
                                    case 'isno':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && (!a[layer.column] || a[layer.column] == false)
                                                : valid || (!a[layer.column] || a[layer.column] == false);
                                        break;
                                    case 'isnumbergreaterthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && parseInt(a[layer.column]) > layer.value
                                                : valid || parseInt(a[layer.column]) > layer.value;
                                        break;
                                    case 'isnumberlessthan':
                                        valid =
                                            layer.concatOperator == 'and'
                                                ? valid && parseInt(a[layer.column]) < layer.value
                                                : valid || parseInt(a[layer.column]) < layer.value;
                                        break;
                                    default:
                                }
                            } else {
                                valid = false;
                            }
                        });
                        return valid;
                    })
                    : aEntries;

            filteredView.forEach((entry) => {
                const dataRow = {};
                Object.keys(columns).forEach((hc, i) => {
                    if (!toggledColumns[hc] && i != 0) return;
                    if (entry[hc] != null) {
                        if (defaultFieldTypes[hc] === 'boolean' || defaultFieldTypes[hc] === 'bool') {
                            dataRow[hc] = entry[hc] ? 'Yes' : 'No';
                        } else if (defaultFieldTypes[hc] === 'timestamp') {
                            dataRow[hc] = moment(entry[hc]).format('MM/DD/YYYY hh:mm A');
                        } else {
                            dataRow[hc] = entry[hc];
                        }
                    }
                });

                dataRows.push(dataRow);
            });

            let headers = Object.keys(columns).map((header, hIndex) => {
                if (!toggledColumns[header] && hIndex != 0) return;
                return columns[header];
            }).filter(h => h);

            let cleanColumns = {}
            Object.keys(columns).forEach((header, hIndex) => {
                if (!toggledColumns[header] && hIndex != 0) return;
                cleanColumns[header] = columns[header];
            })

            let cleanData = [];
            Object.keys(cleanColumns).forEach((header, hIndex) => {
                dataRows.forEach((data, dIndex) => {
                    if (!cleanData[dIndex]) cleanData[dIndex] = [];
                    cleanData[dIndex][hIndex] = data[header] || '';
                });
            })

            data[view._id] = {
                view: view,
                headerRow: headers,
                dataRows: cleanData
            };
        });

        Object.keys(data).forEach((viewId) => {
            const view = data[viewId].view;
            const ws_data2 = [data[viewId].headerRow, ...data[viewId].dataRows];
            const ws2 = XLSX.utils.aoa_to_sheet(ws_data2);
            let tabName = `(${data[viewId].dataRows.length}) ${view.name}`
            const regex = /[\[\]\\\/\?\*\:]/g;
            tabName = tabName.replace(regex, '');
            wb.SheetNames.push(tabName);
            wb.Sheets[tabName] = ws2;
        });

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        saveAs(
            new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
            `${report.name}_${moment().format('LLL')}.xlsx`
        );


    }

    openShare = () => {
        this.setState({ shareModalOpen: true });
    }

    shareReport = (emails) => {

    }
    render() {
        const {
            organizedEntries,
            windowWidth,
            activeView,
            searching,
            searchedEntries,
            searchTerm,
            columnSearchTerm,
            defaultViewFilters,
            customViews,
            focusedView,
            addViewModalOpen,
            deleteViewModalOpen,
            renameViewModalOpen,
            duplicateViewModalOpen,
            shareModalOpen,
        } = this.state;
        const {
            title,
            enableDownloadViews,
            description,
            viewPanelActions,
            report,
            breadcrumbs,
            pinView,
            toggleEditDetails
        } = this.props;
        const selectedCustomView = customViews[activeView] || {};
        const normalizedColumns = selectedCustomView.normalizedColumns || [];
        const aEntries = organizedEntries[selectedCustomView.type] || [];
        const entries = organizedEntries[selectedCustomView.type] || [];
        const sEntries = searchedEntries[selectedCustomView.type] || [];
        const columns = selectedCustomView.customFieldColumns || {};
        const categoryColumns = selectedCustomView.fieldCategories;
        let toggledColumns = { ...selectedCustomView.toggledColumns };
        let cutoff = Math.floor((windowWidth - 270) / 270);
        let views = customViews;
        const overflowViews = views.slice(cutoff);
        const activeCustomViews = views.slice(0, cutoff);
        const activeViewCount = activeCustomViews.length;
        let overflowActive = false;
        if (cutoff > -1 && activeView > cutoff) {
            const activeCustomView = overflowViews[activeView - cutoff];
            overflowActive = true;
            activeCustomViews.push(activeCustomView);
        }
        let entryTable = searching ? sEntries : entries;
        const activeViewFilter = activeView;

        let selectedViewFilterLayers = customViews[activeViewFilter] ? customViews[activeViewFilter].filterLayers : []

        let columnCount = normalizedColumns.length;

        let selectedColumnCount = 0;
        categoryColumns ? Object.keys(categoryColumns).forEach((col, ic) => {
            categoryColumns[col].columns.forEach((k, ik) => {
                const firstField = ic == 0 && ik == 0;
                if (toggledColumns[k] || firstField) {
                    selectedColumnCount++;
                }
                if (columns[k]) {

                    //columnCount++;
                }
            });
        }) : Object.keys(columns).forEach((k) => {
            const firstField = columnCount == 0;
            if (toggledColumns[k] || firstField) {
                selectedColumnCount++;
            }
            if (columns[k]) {
                //columnCount++;
            }
        });
        const allColumnsSelected = selectedColumnCount === columnCount;
        const viewPins = views.filter(v => report.pins.includes(v._id));
        return (
            <div className={`sectionContainer`}>
                <div className="sectionHeader ns mb-10">
                    <div className="sectionTitleBar">
                        <div className="sectionTitle w-100">

                            {((breadcrumbs && breadcrumbs.length > 0)) && (
                                <div>
                                    {breadcrumbs &&
                                        breadcrumbs.length > 0 && (
                                            <Link to={`${breadcrumbs.length > 1 ? '../reports' : window.location.href.endsWith('/') ? '../../' : '../'}`} className={` breadCrumbContainer ${breadcrumbs.length > 1 ? 'internal' : ''}`} >
                                                {breadcrumbs.map((b, i) => (
                                                    <div key={i} className="breadCrumb flex-ac">
                                                        {i > 0 && (
                                                            <div className="breadCrumbSeparator">
                                                                <i className="las la-angle-right"></i>
                                                            </div>
                                                        )}
                                                        <p className="small">
                                                            {b.name}
                                                        </p>
                                                    </div>
                                                ))}
                                            </Link>
                                        )}
                                </div>
                            )}
                            <div className='flex aic'>
                                <h1 className="sectionName">{title}</h1>
                                {this.props.favoriteReport && <button className=' ml-15 p-7 fs-16 c-pointer' id="favoriteReport" onClick={() => this.props.favoriteReport(report._id, !report.favorited)}>

                                    {report.favorited ? <i className='las la-star' style={{ color: '#ffd400' }}></i> : <i className='lar la-star'></i>}
                                </button>}
                                <button className='ml-15 p-7 fs-16 c-pointer' id="downloadReport" onClick={this.downloadFullReport}>
                                    <i className='las la-download'></i>
                                </button>
                                <button className='ml-15 p-7 fs-16 c-pointer' id="shareReport" onClick={this.openShare}>
                                    <i className='las la-user-plus'></i>
                                </button>
                                {<UncontrolledTooltip placement="top" target="favoriteReport">
                                    {report.favorited ? 'Unfavorite' : 'Favorite'}
                                </UncontrolledTooltip>}
                                <UncontrolledTooltip placement="top" target="shareReport">
                                    Share
                                </UncontrolledTooltip>
                                <UncontrolledTooltip placement="top" target="downloadReport">
                                    Download
                                </UncontrolledTooltip>
                                <UncontrolledDropdown className="ml-a">
                                    <DropdownToggle className="p-7">
                                        <i className='las la-ellipsis-v'></i>
                                    </DropdownToggle>
                                    <DropdownMenu right className="columnDropdown p-20">
                                        <h5 className='mb-10'>Options</h5>
                                        <DropdownItem onClick={toggleEditDetails}>
                                            <i className={`mr-10 las la-edit`} />
                                            Edit Details
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            {report.description && <p className="sectionDescription">{report.description}</p>}
                            {viewPins && viewPins.length > 0 && <div className='flex mt-20'>{viewPins.map((summary, index) => {
                                switch (summary.type) {
                                    case "Attendee":
                                        summary.icon = "las la-user";
                                        break;
                                    case "Session":
                                        summary.icon = "las la-calendar";
                                        break;
                                    case "Group":
                                        summary.icon = "las la-users";
                                        break;
                                    case "Inventory":
                                        summary.icon = "las la-list-alt";
                                        break;
                                    case "Email":
                                        summary.icon = "las la-envelope";
                                        break;
                                    case "Text":
                                        summary.icon = "las la-sms";
                                        break;
                                    case "Form":
                                        summary.icon = "lab la-wpforms";
                                        break;
                                    case "Pages":
                                        summary.icon = "las la-laptop-code";
                                        break;
                                    default:
                                        summary.icon = null;
                                }
                                return <div className='summaryBox'>
                                    <div className='removebox'><i className='las la-times' onClick={() => {
                                        this.props.unpinView(summary._id)
                                    }}></i></div>
                                    <div className='number'>{summary.count || 0}</div>
                                    <div className="label">{summary.icon && <i className={`${summary.icon} mr-10`}></i>}{summary.name}</div>
                                </div>
                            })}</div>}
                        </div>
                    </div>
                </div>
                <div className='sectionsContainer'>

                    <div className={`sectionContainer`}>

                        <div className="sectionHeader">
                            <div className="viewTabs">
                                {activeCustomViews &&
                                    activeCustomViews.map((view, index) => {
                                        const viewIndex = index;
                                        view.isPinned = report.pins.includes(view._id);
                                        switch (view.type) {
                                            case "Attendee":
                                                view.icon = "las la-user";
                                                break;
                                            case "Session":
                                                view.icon = "las la-calendar";
                                                break;
                                            case "Group":
                                                view.icon = "las la-users";
                                                break;
                                            case "Inventory":
                                                view.icon = "las la-list-alt";
                                                break;
                                            case "Email":
                                                view.icon = "las la-envelope";
                                                break;
                                            case "Text":
                                                view.icon = "las la-sms";
                                                break;
                                            case "Form":
                                                view.icon = "lab la-wpforms";
                                                break;
                                            case "Pages":
                                                view.icon = "las la-laptop-code";
                                                break;
                                            default:
                                                view.icon = null;
                                        }
                                        return (
                                            <ViewTab

                                                viewIndex={viewIndex}
                                                onClick={() => this.selectView(viewIndex)}
                                                key={`view${view.name}${viewIndex}`}
                                                view={view}
                                                makeMainView={this.makeMainView}
                                                renameView={() =>
                                                    this.setState({
                                                        renameViewModalOpen: true,
                                                        focusedView: index
                                                    })}
                                                duplicateView={() =>
                                                    this.setState({
                                                        duplicateViewModalOpen: true,
                                                        focusedView: index
                                                    })}
                                                deleteView={() =>
                                                    this.setState({
                                                        deleteViewModalOpen: true,
                                                        focusedView: index
                                                    })}
                                                pinView={() => pinView(view._id)}
                                                selected={
                                                    activeView > cutoff && viewIndex == activeCustomViews.length ? (
                                                        true
                                                    ) : activeView == viewIndex ? (
                                                        true
                                                    ) : (
                                                        false
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                {overflowViews.length > 0 &&
                                    (!(activeView > activeViewCount && overflowViews.length - 1 == 0) ||
                                        (activeView < activeViewCount && overflowViews.length > 0)) && (
                                        <MoreViewsTab
                                            overflowActive={overflowActive}
                                            overflowViews={overflowViews}
                                            activeView={activeView}
                                            makeMainView={this.makeMainView}
                                            onClick={(index) => this.selectView(index)}
                                            renameView={(index) =>
                                                this.setState({
                                                    renameViewModalOpen: true,
                                                    focusedView: index
                                                })}
                                            duplicateView={(index) =>
                                                this.setState({ duplicateViewModalOpen: true, focusedView: index })}
                                            deleteView={(index) =>
                                                this.setState({
                                                    deleteViewModalOpen: true,
                                                    focusedView: index
                                                })}
                                            activeCustomViewsCount={activeCustomViews.length}
                                        />
                                    )}
                                <button className='ml-10 c-pointer flex aic p-7' onClick={() => this.setState({ addViewModalOpen: true })}>
                                    <i className="las la-plus black" style={{ fontSize: 16 }} />
                                </button>
                            </div>

                        </div>

                        <div className="sectionBody isTable p-0">
                            <div className="actionButtonContainer headerHeight flex-ac">

                                <InputField
                                    classes="m-0 viewSearch"
                                    placeholder="Search "
                                    clickIcon={true}
                                    required={true}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        clearTimeout(this.searchTimeout);
                                        this.setState({ searchTerm: e.target.value });
                                        this.searchTimeout = setTimeout(() => this.searchEntries(), 1000);
                                    }}
                                    prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                    inputIcon={searchTerm != '' &&
                                        <i
                                            onMouseDown={() => this.setState({ searchTerm: '', searching: false })}
                                            //onClick={() => this.setState({ searchTerm: '', searching: false })}
                                            className="las la-times-circle"
                                            style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                        />}
                                />
                                <UncontrolledDropdown inNavbar >
                                    <DropdownToggle
                                        className={`noExpand plus20 ${selectedViewFilterLayers.length > 0 &&
                                            selectedViewFilterLayers[0].condition != ''
                                            ? 'selected'
                                            : ''}`}
                                    >
                                        <i className="las la-filter mr-10" />
                                        {/* selectedViewFilterLayers.length > 0 &&
											selectedViewFilterLayers[0].condition != '' ? (
											<span>{selectedViewFilterLayers.length} Filter{selectedViewFilterLayers.length == 1 ? '' : 's'} Applied</span>
										) : (<span>No Filters Applied</span>) */}
                                        Filter
                                    </DropdownToggle>
                                    <DropdownMenu className="columnDropdown filterDropdown p-20">
                                        <h5 className='mb-10'>View Filters</h5>
                                        <div className="viewFilterActionBar">
                                            <p className='mb-0'>
                                                Showing {entries.length} out of {aEntries.length}
                                            </p>
                                            <div className="filterActionButtons flex-ac">
                                                <button
                                                    className="seBlue mr-10"
                                                    onClick={() => {
                                                        const avs = this.state.customViews;
                                                        avs[activeViewFilter].filterLayers = [
                                                            {
                                                                column: '',
                                                                condition: '',
                                                                value: '',
                                                                concatOperator: 'and'
                                                            }
                                                        ];
                                                        avs[activeViewFilter].unsavedFilter = true;
                                                        this.setState({ customViews: avs }, () => this.filterView(activeView));

                                                    }}
                                                >
                                                    Clear all
                                                </button>

                                                {(selectedCustomView ? (
                                                    <button
                                                        className={`min ${selectedCustomView.unsavedFilter
                                                            ? ''
                                                            : 'disabled'}`}
                                                        onClick={() => this.saveCurrentView(true)}
                                                    >
                                                        Save to this view
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={`min`}
                                                        onClick={() => this.setState({ addViewModalOpen: true })}
                                                    >
                                                        Save as new view
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="filterLayersContainer">
                                            {customViews[activeViewFilter] && (
                                                (customViews[activeViewFilter].filterLayers.length > 0
                                                    ? customViews[activeViewFilter].filterLayers
                                                    : [
                                                        {
                                                            column: '',
                                                            condition: '',
                                                            value: '',
                                                            concatOperator: 'and'
                                                        }
                                                    ]).map((filter, index) => (
                                                        <ColumnFilterRow
                                                            key={`${customViews[activeViewFilter]
                                                                .name}Filter${filter.column}${index}-${activeView}`}
                                                            firstFilter={index == 0}
                                                            columns={normalizedColumns}
                                                            selectedColumn={filter.column}
                                                            selectedCondition={filter.condition}
                                                            filterValue={filter.value}
                                                            selectedConcatOption={filter.concatOperator}
                                                            filterCount={customViews[activeViewFilter].filterLayers.length}
                                                            updateFilter={(updatedFilter) => {
                                                                const avs = this.state.customViews;
                                                                avs[activeViewFilter].filterLayers[index] = updatedFilter;
                                                                avs[activeViewFilter].unsavedFilter = true;
                                                                this.setState({ customViews: avs }, () => this.filterView(activeView));
                                                            }}
                                                            removeFilter={() => {
                                                                const avs = this.state.customViews;
                                                                avs[activeViewFilter].filterLayers.splice(index, 1);
                                                                if (avs[activeViewFilter].filterLayers.length == 0) {
                                                                    avs[activeViewFilter].filterLayers = [
                                                                        {
                                                                            column: '',
                                                                            condition: '',
                                                                            value: '',
                                                                            concatOperator: 'and'
                                                                        }
                                                                    ];
                                                                    avs[activeViewFilter].unsavedFilter = true;
                                                                    this.setState({ customViews: avs }, () => this.filterView(activeView));
                                                                } else {
                                                                    avs[activeViewFilter].unsavedFilter = true;
                                                                    this.setState({ customViews: avs }, () => this.filterView(activeView));
                                                                }
                                                            }}
                                                        />
                                                    ))
                                            )}
                                        </div>
                                        <button
                                            className="addFilterButton w-100"
                                            onClick={() => {
                                                const avs = this.state.customViews;
                                                if (avs[activeViewFilter]) {

                                                    avs[activeViewFilter].filterLayers.push({
                                                        column: '',
                                                        condition: '',
                                                        value: '',
                                                        concatOperator: 'and'
                                                    });
                                                } else {
                                                    avs[activeViewFilter].filterLayers = [{
                                                        column: '',
                                                        condition: '',
                                                        value: '',
                                                        concatOperator: 'and'
                                                    }];
                                                }
                                                avs[activeViewFilter].unsavedFilter = true;
                                                this.setState({ customViews: avs });
                                            }}
                                        >

                                            <i className="las la-plus-circle mr-10" /> Add another filter layer

                                        </button>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown inNavbar >
                                    <DropdownToggle className={` noExpand plus20 ${!allColumnsSelected ? 'selected' : ''}`}>
                                        {/* !allColumnsSelected ? (
											<Fragment>
												<i className="las la-low-vision mr-10" />
												{selectedColumnCount} of {
													columnCount
												} Field Columns Visible
											</Fragment>
										) : (
											<Fragment>
												<i className="las la-eye mr-10 " /> All Field Columns Visible
											</Fragment>
										) */}
                                        <i className="las la-stream mr-10 " style={{ transform: 'rotate(90deg)' }} />Columns
                                    </DropdownToggle>
                                    <DropdownMenu right className="columnDropdown p-20" style={{ width: 270 }} >
                                        <h5 className='mb-20'>Displayed Field Columns</h5>

                                        <InputField
                                            placeholder="Search for fields..."
                                            value={columnSearchTerm}
                                            onChange={(e) => this.setState({ columnSearchTerm: e.target.value })}
                                            clickIcon={true}
                                            inputIcon={
                                                columnSearchTerm !== '' ? <i
                                                    onClick={() => this.setState({ columnSearchTerm: '' })}
                                                    className="las la-times-circle"
                                                /> : <i className="las la-search mr-10" />}
                                        />

                                        <div className='flex aic'>
                                            {selectedCustomView ? (
                                                <button
                                                    className={` min ${!selectedCustomView.unsavedColumns
                                                        ? 'disabled'
                                                        : ''}`}
                                                    onClick={() => this.saveCurrentView(false, true)}
                                                >Save to this view
                                                </button>
                                            ) : (
                                                <button
                                                    className='min w-100'
                                                    onClick={() => this.setState({ addViewModalOpen: true })}
                                                >Save as new view
                                                </button>
                                            )}
                                        </div>
                                        <div className="columnToggleContainer">
                                            <div>
                                                <CheckboxInput
                                                    label="All Fields"
                                                    classes="mb-10 pr-25"
                                                    checked={allColumnsSelected}
                                                    onClick={() => {
                                                        if (selectedCustomView) {
                                                            const customViews = this.state.customViews;
                                                            const vi = customViews.findIndex(
                                                                (view) => view._id == selectedCustomView._id
                                                            );
                                                            if (vi >= 0) {
                                                                const cView = this.state.customViews[vi];
                                                                if (allColumnsSelected) {
                                                                    cView.toggledColumns = {};
                                                                } else {
                                                                    const newToggledColumns = {};
                                                                    Object.keys(columns).forEach((cfc) => {
                                                                        newToggledColumns[cfc] = true;
                                                                    });
                                                                    delete newToggledColumns[Object.keys(columns)[0]];
                                                                    cView.toggledColumns = newToggledColumns;
                                                                }
                                                                cView.unsavedColumns = true;
                                                                customViews[vi] = cView;
                                                            }
                                                            this.setState({ customViews }, () => this.filterView(vi));
                                                        } else {
                                                            let tc = this.state.toggledColumns;
                                                            if (allColumnsSelected) {
                                                                tc = {};
                                                            } else {
                                                                tc = {};
                                                                Object.keys(columns).forEach((col, i) => {
                                                                    if (i > 0) tc[col] = true;
                                                                });
                                                            }
                                                            this.setState({ toggledColumns: tc });
                                                        }
                                                    }}
                                                />

                                            </div>
                                            <DragDropContext key={`cols-${activeView}`} onDragEnd={(result) => {
                                                if (!result.destination || result.destination.index == 0) {
                                                    return;
                                                }
                                                const normalizedItems = reorder(
                                                    normalizedColumns,
                                                    result.source.index,
                                                    result.destination.index
                                                );
                                                const keys = normalizedItems.map(ni => { return ni.key; });
                                                let viewId = selectedCustomView
                                                    ? selectedCustomView._id
                                                    : false;

                                                if (viewId) {
                                                    const customViews = this.state.customViews;
                                                    const vi = customViews.findIndex((view) => view._id == viewId);
                                                    if (vi >= 0) {
                                                        const cView = this.state.customViews[vi];

                                                        cView.unsavedColumns = true;
                                                        cView.columnsOrder = keys;
                                                        customViews[vi] = cView;
                                                    }
                                                    this.setState({ customViews }, () => this.filterView(vi));
                                                }


                                                this.setState({ normalizedColumns: normalizedItems })

                                            }}>
                                                <Droppable droppableId="droppable"  >
                                                    {(provided, snapshot) => (

                                                        <div ref={provided.innerRef} style={getComponentListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {categoryColumns ? (

                                                                <div >
                                                                    {normalizedColumns
                                                                        .filter(
                                                                            (k, ik) =>
                                                                                ik == 0 || (columns[k.key] &&
                                                                                    columns[k.key]
                                                                                        .toLowerCase()
                                                                                        .includes(columnSearchTerm.toLowerCase()))
                                                                        )
                                                                        .map((key, j) => {
                                                                            const firstField = j == 0;
                                                                            return (
                                                                                <Draggable
                                                                                    isDragDisabled={firstField}
                                                                                    key={`#${key.key}-${activeView}-${j}`}
                                                                                    draggableId={`#${key.key}-${activeView}-${j}`}
                                                                                    index={j}
                                                                                >

                                                                                    {(provided, snapshot) => (

                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                            style={getItemStyle(
                                                                                                snapshot.isDragging,
                                                                                                provided.draggableProps.style
                                                                                            )}
                                                                                            className={`${firstField
                                                                                                ? 'columnToggleDisabled'
                                                                                                : ''}`}
                                                                                        >
                                                                                            {this.renderToggleColumnItem(
                                                                                                key.key,
                                                                                                selectedCustomView
                                                                                                    ? selectedCustomView._id
                                                                                                    : false,
                                                                                                firstField
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>

                                                                            );
                                                                        })}
                                                                </div>
                                                            ) : (
                                                                Object.keys(columns)
                                                                    .slice(1)
                                                                    .filter((k) =>
                                                                        columns[k].toLowerCase().includes(columnSearchTerm.toLowerCase())
                                                                    )
                                                                    .map((key) => {
                                                                        return this.renderToggleColumnItem(
                                                                            key,
                                                                            selectedCustomView ? selectedCustomView._id : false
                                                                        )
                                                                    })
                                                            )}
                                                        </div>

                                                    )}
                                                </Droppable >
                                            </DragDropContext>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <div className="sectionOptionsContainer">
                                    {viewPanelActions && viewPanelActions.length > 0 &&
                                        viewPanelActions.map((sa, index) => {
                                            return sa.iconClass && <><i id={`viewAction-${index}`} onClick={sa.onClick} className={` ${sa.iconClass}`} />
                                                <UncontrolledTooltip target={`viewAction-${index}`} placement="left">{sa.label}</UncontrolledTooltip></>
                                        })
                                    }
                                    {enableDownloadViews && (<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
                                        <i className="las la-download" />
                                        <UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
                                    </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex h-100 hasHeader'>
                                <ViewsTable
                                    key={`viewTable-${activeView}`}
                                    disableCheck={true}
                                    disableLink={true}
                                    columns={normalizedColumns}
                                    toggledColumns={toggledColumns}
                                    data={entryTable}
                                    title={"Data"}
                                    mainColumnActive={true}
                                    checked={[]}
                                    sortData={this.sortData}
                                    defaultSort={this.props.defaultSort}
                                    sortDirection={this.props.sortDirection}
                                />
                                {this.props.viewPanel}
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </div>

                {
                    addViewModalOpen && (
                        <AddReportViewModal
                            isOpen={addViewModalOpen}
                            orgId={this.props.orgId}
                            toggle={() => this.setState({ addViewModalOpen: false })}
                            filterLayers={
                                activeView > 0 ? (
                                    customViews[activeViewFilter].filterLayers
                                ) : (
                                    defaultViewFilters
                                )
                            }
                            toggledColumns={toggledColumns}
                            addView={this.addView}
                            type={this.props.type}
                            reportId={this.props.report._id}
                            referenceCollection={this.props.referenceCollection}
                            referenceId={this.props.referenceId}
                            normalizedColumns={normalizedColumns}
                            defaultColumns={this.props.columns}
                        />
                    )
                }

                {
                    renameViewModalOpen &&
                    focusedView >= 0 && (
                        <RenameViewModal
                            orgId={this.props.orgId}
                            view={customViews[focusedView - 0]}
                            updateView={this.updateFocusedView}
                            reportId={this.props.report._id}
                            isOpen={renameViewModalOpen}
                            toggle={() => this.setState({ renameViewModalOpen: false })}
                        />
                    )
                }
                {
                    duplicateViewModalOpen &&
                    focusedView >= 0 && (
                        <DuplicateViewModal
                            orgId={this.props.orgId}
                            view={customViews[focusedView - 0]}
                            addView={this.addView}
                            isOpen={duplicateViewModalOpen}
                            toggle={() => this.setState({ duplicateViewModalOpen: false })}
                            type={this.props.type}
                            referenceCollection={this.props.referenceCollection}
                            referenceId={this.props.referenceId}
                            reportId={this.props.report._id}
                            normalizedColumns={normalizedColumns}
                        />
                    )
                }

                {
                    deleteViewModalOpen &&
                    focusedView >= 0 && (
                        <DeleteViewModal
                            orgId={this.props.orgId}
                            view={customViews[focusedView - 0]}
                            reportId={this.props.report._id}
                            eventId={this.props.eventId}
                            removeView={this.removeFocusedView}
                            isOpen={deleteViewModalOpen}
                            toggle={() => this.setState({ deleteViewModalOpen: false })}
                        />
                    )
                }

                {shareModalOpen && (
                    <ShareReportModal
                        isOpen={shareModalOpen}
                        toggle={() => this.setState({ shareModalOpen: false })}
                        report={this.props.report}
                        shareReport={this.props.updateReportShareList}
                        orgId={this.props.orgId}
                        eventId={this.props.eventId}
                        reportId={this.props.report._id}
                    />
                )}

            </div >
        );
    }
}

export default ReportsContainer;
