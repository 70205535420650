import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';

class AddInventoryModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			category: '',
			vendor: '',
			quantity: 0,
			trackingNumber: '',
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	addInventory = () => {
		const item = {
			name: this.state.name,
			category: this.state.category,
			vendor: this.state.vendor,
			quantity: this.state.quantity
		};
		const requiredFields = this.state.requiredFields;

		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!item[key] || item[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			this.setState({ submitting: true });
			this.props.handleSubmit(item, this.props.toggle);
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, category, vendor, trackingNumber, submitting, quantity, invalid, requiredFields } = this.state;
		return (

			<div>
				<FieldModal
					size={'medium'}
					isOpen={isOpen}
					modalTitle={"Add Inventory Item"}
					bodyHeaderText={''}
					bodyDescription={``}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Item Name"
									onChange={(e) => this.setState({ name: e.target.value })}
									required={true}
									errorTrigger={requiredFields.name}
									value={name}
								/>

								<InputField
									label="Category"
									onChange={(e) => this.setState({ category: e.target.value })}
									value={category}
								/>

								<InputField
									label="Vendor"
									onChange={(e) => this.setState({ vendor: e.target.value })}
									value={vendor}
								/>

								<InputField
									label="Quantity"
									type={'number'}
									onChange={(e) => this.setState({ quantity: e.target.value })}
									value={quantity}
								/>
							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding Item...'}
					cancelButtonLabel={'Cancel'}

					actionButton={() => this.addInventory()}
					submitting={submitting}
					actionButtonDisabled={submitting}

				></FieldModal>
			</div>

		);
	}
}

export default AddInventoryModal;
