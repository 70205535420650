import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../../utils/API';
import EventTextCampaign from './campaign';
import LoadingWizard from '../../../components/spinner/wizard';
import AddCampaignModal from './modals/newCampaignModal';
import DeleteTextsModal from './modals/deleteTextsModal';
import BasicViewContainer from '../../../components/views/BasicViewContainer';
import RenameTextModal from './modals/renameEmailModal';
import DeleteSingleTextModal from './modals/deleteSingleTextModal';

import DuplicateCampaignModal from './modals/duplicateCampaignModal';
let batchCallback;
let mainActionCB;

class EventTextCampaigns extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			status: 'Status',
			createdAt: 'Created At',
			updatedAt: 'Updated At',
		};
		const defaultFieldTypes = {
			name: 'text',
			status: 'Status',
			createdAt: 'date',
			updatedAt: 'date',
		};

		this.state = {
			editing: false,
			templates: [],
			campaignsHidden: false,
			allTemplates: false,
			addCampaignToggle: false,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			textingViews: [],
			textMessages: [],
			loading: true,
			toggleCreateFromTemplate: false,
			selectedTemplate: {},
			checkedCampaigns: {},
			deletedCampaigns: [],
			hoverOn: '',
			selectedSection: 0,
			campaignDrafts: [],
			campaignScheduled: [],
			campaignSent: [],
			selectedCampaign: null,
			toggleRenameSingle: false,
			toggleSingleDeleteCampaign: false,
			toggleDuplicateSingleCampaign: false,
			editCount: 0,
		};
	}

	async componentDidMount() {
		const { eventId, orgId } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/textMessages`).then((res) => {
			if (res.data) {
				const textingViews = res.data.textMessageViews;
				let sortedTextMessages = res.data.textMessages.map((msg) => {
					return {
						name: msg.name,
						status: msg.sent ? 'Sent' : msg.scheduled ? 'Scheduled' : 'Draft',
						_id: msg._id,
						link: `/${this.props.orgId}/events/${eventId}/texting/${msg._id}`,
						createdAt: msg.createdAt,
						updatedAt: msg.updatedAt
					};
				});
				const metadataOptions = [];
				{
					Object.keys(res.data.keyedData).forEach((key) => {
						const layer1 = res.data.keyedData[key];
						if (typeof layer1 === 'object') {
							Object.keys(layer1).forEach((key2) => {
								const layer2 = layer1[key2];
								if (typeof layer2 === 'object') {
									Object.keys(layer2).forEach((key3) => {
										if (
											key !== '_defaultLabel' &&
											key2 !== '_defaultLabel' &&
											key3 !== '_defaultLabel'
										) {
											metadataOptions.push(`${key}.${key2}.${key3}`);
										}
									});
								} else {
									if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
										metadataOptions.push(`${key}.${key2}`);
									}
								}
							});
						} else {
							if (key !== '_defaultLabel') {
								metadataOptions.push(`${key}`);
							}
						}
					});
				}

				const drafts = sortedTextMessages.filter((e) => e.status == 'Draft');
				const scheduled = sortedTextMessages.filter((e) => e.status == 'Scheduled');
				const sent = sortedTextMessages.filter((e) => e.status == 'Sent');

				this.setState({
					sortedTextMessages: sortedTextMessages,
					textMessages: sortedTextMessages,
					campaignDrafts: drafts,
					campaignScheduled: scheduled,
					campaignSent: sent,
					loading: false,
					keyedMetadata: metadataOptions,
					keyedData: res.data.keyedData,
					textingViews: textingViews,
					loading: false
				});
			}
		});
	}

	hideCampaigns = () => {
		const { campaignsHidden } = this.state;
		this.setState({ campaignsHidden: !campaignsHidden });
	};

	toggleAddCampaign = (cb) => {
		const { addCampaignToggle } = this.state;
		mainActionCB = cb;
		this.setState({ addCampaignToggle: !addCampaignToggle });
	};

	createFromTemplateToggle = () => {
		const { toggleCreateFromTemplate } = this.state;
		this.setState({ toggleCreateFromTemplate: !toggleCreateFromTemplate });
	};

	addCampaign = (campaign, callback) => {
		const { campaignDrafts, textMessages } = this.state;

		this.setState({ campaignDrafts: [...campaignDrafts, campaign], textMessages: [...textMessages, campaign] }, () => {
			this.props.refreshSectionCounts();
			mainActionCB && mainActionCB('update');
			callback && callback()
		});
	};
	toggleSingleDeleteCampaign = (rowId) => {
		const { toggleSingleDeleteCampaign } = this.state;

		this.setState({
			toggleSingleDeleteCampaign: !toggleSingleDeleteCampaign,
			selectedRow: rowId
		});
	};

	toggleDuplicateSingleCampaign = (rowId) => {
		const { toggleDuplicateSingleCampaign } = this.state;

		this.setState({
			toggleDuplicateSingleCampaign: !toggleDuplicateSingleCampaign,
			selectedRow: rowId
		});
	};


	toggleDeleteCampaigns = (checkedCampaigns, bcb) => {
		const { toggleDeleteCampaigns } = this.state;
		batchCallback = bcb;

		this.setState({
			checkedCampaigns: checkedCampaigns || {},
			toggleDeleteCampaigns: !toggleDeleteCampaigns
		});
	};

	deleteCheckedCampaigns = (callback) => {
		const { checkedCampaigns, deletedCampaigns, textMessages, editCount } = this.state;

		let updatedTextMessages = textMessages.filter((e) => !Object.keys(checkedCampaigns).includes(e._id));
		const campaignDrafts = updatedTextMessages.filter((e) => e.status == 'Draft');
		const campaignScheduled = updatedTextMessages.filter((e) => e.status == 'Scheduled');
		const campaignSent = updatedTextMessages.filter((e) => e.status == 'Sent');
		this.setState(
			{
				editCount: editCount + 1,
				textMessages: updatedTextMessages,
				sortedTextMessages: updatedTextMessages,
				deletedCampaigns: [...deletedCampaigns, ...Object.keys(checkedCampaigns)],
				checkedCampaigns: {},
				campaignDrafts,
				campaignScheduled,
				campaignSent,

			},

			() => {
				this.props.refreshSectionCounts()
				callback();
			}
		);
	};

	updateDeleteCampaign = (campaignId, callback) => {
		const { textMessages, editCount } = this.state;
		let updatedTextMessages = textMessages.filter((e) => e._id !== campaignId);
		const campaignDrafts = updatedTextMessages.filter((e) => e.status == 'Draft');
		const campaignScheduled = updatedTextMessages.filter((e) => e.status == 'Scheduled');
		const campaignSent = updatedTextMessages.filter((e) => e.status == 'Sent');
		this.setState(
			{
				editCount: editCount + 1,
				textMessages: updatedTextMessages,
				sortedTextMessages: updatedTextMessages,
				deletedCampaigns: [...this.state.deletedCampaigns, campaignId],
				campaignDrafts,
				campaignScheduled,
				campaignSent,

			},
			() => {

				callback && callback();
				batchCallback && batchCallback('delete');
				this.props.refreshSectionCounts()

			}
		);
	};

	updateText = (tUpdate) => {
		let tempText = this.state.textMessages;
		const updatedTexts = tempText.map(object => {
			if (tUpdate._id == object._id) {

				Object.assign(object, tUpdate);
			}
			return object;
		})

		const campaignDrafts = updatedTexts.filter((e) => e.status == 'Draft');
		const campaignScheduled = updatedTexts.filter((e) => e.status == 'Scheduled');
		const campaignSent = updatedTexts.filter((e) => e.status == 'Sent');

		this.setState({ textMessages: updatedTexts, campaignDrafts, campaignScheduled, campaignSent })
	}







	updateSingleText = (tUpdate, callback) => {
		let tempText = this.state.textMessages
		const { selectedRow } = this.state;
		for (var i = 0; i < tempText.length; i++) {
			if (selectedRow == tempText[i]._id) {

				Object.assign(tempText[i], tUpdate);
			}
		}
		this.setState({ textMessage: tempText }, () => {
			callback && callback()
		})

	}




	toggleRenameSingle = (id) => {
		const { toggleRenameSingle } = this.state;
		this.setState({ toggleRenameSingle: !toggleRenameSingle, selectedRow: id });
	}

	render() {
		const {
			addCampaignToggle,
			columnTypes,
			columns,
			textingViews,
			textMessages,
			loading,
			toggleDeleteCampaigns,
			checkedCampaigns,
			deletedCampaigns,
			keyedData,
			keyedMetadata,
			selectedSection,
			campaignDrafts,
			campaignScheduled,
			campaignSent,
			selectedCampaign,
			renameCampaignModalOpen,
			toggleRenameSingle,
			selectedRow,
			toggleSingleDeleteCampaign,
			toggleDuplicateSingleCampaign
		} = this.state;
		const { eventId, event, orgId, editing } = this.props;
		const campaignList = selectedSection == 0 ? this.state.campaignDrafts : selectedSection == 1 ? this.state.campaignScheduled : this.state.campaignSent

		return (
			<Switch>
				<Route path={`/:organization/events/:eventId/texting/:textId`}>
					<EventTextCampaign
						groups={this.props.event.groups}
						sessions={this.props.event.sessions}
						event={this.props.event}
						eventId={eventId}
						orgId={orgId}
						textMessages={textMessages}
						keyedMetadata={keyedMetadata}
						keyedData={keyedData}
						updateDeleteCampaign={this.updateDeleteCampaign}
						updateText={this.updateText}

					/>
				</Route>
				<Route path={`/:organization/events/:eventId/texting`}>
					<Fragment>

						{!loading && (
							<div className="sectionContainer">

								{!loading && <div className="sectionContainer sectionBody">

									<BasicViewContainer
										editing={editing}
										key={`Texting${this.state.editCount}`}
										cancelEdit={() => {
											this.cancelEdit();
										}}
										rowSettings={[
											{
												label: <span><i className='las la-pen mr-10'></i>Rename Campaign</span>,
												onClick: this.toggleRenameSingle
											},
											{
												label: <span><i className='las la-copy mr-10'></i>Duplicate Campaign</span>,
												onClick: this.toggleDuplicateSingleCampaign
											},
											{
												label: <span><i className='las la-trash mr-10'></i>Delete Campaign</span>,
												onClick: this.toggleSingleDeleteCampaign
											}
										]}
										saveEdit={() => {
											this.saveInfo();
										}}
										title={'Texts'}
										description="Text campaigns are used to send SMS messages to attendees."
										enableLastLink={true}
										breadcrumbs={[
											{
												name: event.name
											}
										]}
										orgId={orgId}
										tabs={[{
											title: 'Drafts',
											count: campaignList.length
										}, {
											title: 'Scheduled',
											count: campaignList.length
										}, {
											title: 'Sent',
											count: campaignList.length

										}]}
										onTabsChange={(i) => {
											if (i === 0) {
												return campaignDrafts
											} else if (i === 1) {
												return campaignScheduled
											} else {
												return campaignSent
											}
										}}

										columns={columns}
										columnTypes={columnTypes}
										views={textingViews}
										entries={campaignList}
										deletedEntries={deletedCampaigns}
										readOnly={false}
										mainActions={[{ onClick: this.toggleAddCampaign, label: 'Create New Message' }]}
										enableDownloadViews={true}
										type={'TextMessage'}
										referenceCollection={'Events'}
										defaultSort={'name'}
										batchActions={[
											{
												label: 'Delete',
												iconClass: 'las la-trash-alt',
												class: 'danger',
												onClick: this.toggleDeleteCampaigns
											}
										]}
										referenceId={eventId}
									/>

								</div>}
								<LoadingWizard loading={loading} text="Loading Text Message Campaigns" />

								{addCampaignToggle && (
									<AddCampaignModal
										isOpen={addCampaignToggle}
										addCampaign={this.addCampaign}
										toggle={this.toggleAddCampaign}
										eventId={eventId}
										orgId={this.props.orgId}
									/>
								)}
								{toggleSingleDeleteCampaign && (
									<DeleteSingleTextModal
										isOpen={toggleSingleDeleteCampaign}
										editedTextMessage={{ _id: selectedRow }}
										toggle={this.toggleSingleDeleteCampaign}
										updateDeleteCampaign={this.updateDeleteCampaign}
										isSingleDelete={true}
										eventId={eventId}
										orgId={this.props.orgId}
									/>
								)}

								{toggleDuplicateSingleCampaign &&
									< DuplicateCampaignModal
										isOpen={toggleDuplicateSingleCampaign}
										email={{ _id: selectedRow }}
										orgId={orgId}
										eventId={eventId}
										event={event}
										toggle={this.toggleDuplicateSingleCampaign}
									/>}
								{toggleDeleteCampaigns && (
									<DeleteTextsModal
										isOpen={toggleDeleteCampaigns}
										checkedCampaigns={checkedCampaigns}
										toggle={this.toggleDeleteCampaigns}
										deleteCheckedCampaigns={this.deleteCheckedCampaigns}
										eventId={eventId}
										orgId={this.props.orgId}
										update={this.updateDeleteCampaign}
									/>
								)}
								{toggleRenameSingle && (
									<RenameTextModal
										isOpen={toggleRenameSingle}
										textMessage={textMessages.find((e) => e._id === selectedRow)}
										orgId={this.props.orgId}
										eventId={eventId}
										toggle={this.toggleRenameSingle}
										update={this.updateSingleText}
									/>
								)}
							</div>
						)}
						<LoadingWizard text="Loading SMS Text Campaigns" loading={loading} />
					</Fragment>

				</Route>
			</Switch >
		);
	}
}

export default withRouter(EventTextCampaigns);
