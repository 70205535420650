import React, { Component } from 'react';
import AddPersonToTeam from './addPersonToTeam';
import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';
import DeleteTeamModal from './modals/deleteTeamMembersModal';

let batchCallback;
class Teams extends Component {
	constructor(props) {
		super(props);

		const fieldColumns = {
			emailAddress: 'Email Address',
			firstName: 'First Name',
			lastName: 'Last Name',
			permission: 'Permission',
			status: 'Status',
			eventCount: 'Event Count',
			programCount: 'Program Count',
			updatedAt: 'Last Updated'
		};

		const fieldTypes = {
			emailAddress: 'string',
			firstName: 'string',
			lastName: 'string',
			permission: 'string',
			status: 'string',
			eventCount: 'number',
			programCount: 'number',
			updatedAt: 'date'
		};
		this.state = {
			members: [],
			events: [],
			checkedMembers: {},
			searchSelected: false,
			searchTerm: '',
			listType: 'list', //can be gallery or list
			sortCriteria: 'NameAsc',
			criteriaOpen: false,
			addModalOpen: false,
			loading: true,
			adding: false,
			selectedFilter: 0,
			teamViews: [],
			columns: fieldColumns,
			columnTypes: fieldTypes,
			selfSelected: false,
			checkedTeam: {},
		};
	}

	selectSection = (index) => {
		this.setState({ selectSection: index });
	};

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};

	componentDidMount() {
		API().get(`Organizations/${this.props.orgId}/members`).then((res) => {
			if (res.data) {
				let members = res.data.members.map((m) => {
					m.link = `/${this.props.orgId}/team/${m._id}`;
					m.permission = m.permission.charAt(0).toUpperCase() + m.permission.slice(1);
					m.isInvite = false;
					return m;
				}
				);
				let invites = res.data.invites.map((i) => {
					i.eventCount = 0;
					i.programCount = 0;
					i.updatedAt = i.invitedAt;
					i.permission = i.permission.charAt(0).toUpperCase() + i.permission.slice(1);
					i.isInvite = true;
					i.link = `/${this.props.orgId}/invites/${i._id}`;


					return i;
				}
				);

				let allTeam = members.concat(invites);



				this.setState({
					members: allTeam,
					teamViews: res.data.views,
					loading: false
				});
			}
		});
		this.eventName();
	}

	async eventName() {
		const response = await API().get(`Organizations/${this.props.orgId}/eventNames`);
		let eventData = response.data;
		eventData = eventData.events.map((event) => {
			return {
				label: event.name,
				value: event._id
			}
		})
		this.setState({ events: eventData })

	}

	toggleDeleteTeamMembers = (checkedTeam, bcb) => {
		batchCallback = bcb;
		const selfSelected = checkedTeam && this.state.members.find((m) => !!checkedTeam[m._id] && m.isUser);
		this.setState({
			selfSelected: !!selfSelected,
			checkedTeam: checkedTeam || {},
			deleteMembersModalOpen: !this.state.deleteMembersModalOpen
		});
	};

	deleteTeamMembers = async () => {
		const { orgId } = this.props;
		const { checkedTeam, members } = this.state;
		const del = Object.keys(checkedTeam);


		for (let i = 0; i < del.length; i++) {
			let member = members.find((m) => m._id === del[i]);
			if (member) {
				if (member.isInvite) {
					await API().delete(`Organizations/${orgId}/invites/${del[i]}`);

				} else {
					await API().delete(`Organizations/${orgId}/members/${del[i]}`);

				}
			}
		}

		this.props.removeTeamMembers(del);
		var updatedMembers = this.state.members;

		updatedMembers = updatedMembers.filter(
			(sa) => !Object.keys(this.state.checkedTeam).includes(sa._id.toString())
		);

		this.setState(
			{
				checkedTeam: {},
				members: updatedMembers
			},
			() => {
				batchCallback && batchCallback('delete');
				this.toggleDeleteTeamMembers();
			}
		);

	};

	render() {
		const { members, loading, columns, columnTypes, addModalOpen, teamViews, selfSelected, events, checkedTeam } = this.state;
		let permission = this.props.permission;
		return (
			<div className="pageContainer">
				{!loading && (
					<ViewsContainer
						views={teamViews}
						entries={members}
						columns={columns}
						columnTypes={columnTypes}
						title="Team"
						mainActions={[{ type: 'button', label: 'Add team member', onClick: this.toggleAdd }]}
						batchActions={!permission ? [] : [
							{
								label: 'Delete',
								iconClass: 'las la-trash-alt',
								class: 'danger',
								onClick: this.toggleDeleteTeamMembers
							}
						]}
						orgId={this.props.orgId}
						type="Team"
						referenceCollection="Organizations"
						referenceId={this.props.orgId}
						description="Manage everyone who has access to your account."
					/>
				)}
				<LoadingWizard text="LOADING TEAM" loading={loading} />
				{addModalOpen && (
					<AddPersonToTeam
						isOpen={addModalOpen}
						events={events}
						toggle={this.toggleAdd}
						orgId={this.props.orgId}
						allEmails={members.map(m => { return m.emailAddress })}
					/>
				)}{' '}
				{this.state.deleteMembersModalOpen && (
					<DeleteTeamModal
						isOpen={true}
						toggle={this.toggleDeleteTeamMembers}
						delete={!selfSelected ? this.deleteTeamMembers : this.toggleDeleteTeamMembers}
						selfSelected={selfSelected}
						inviteCount={Object.keys(checkedTeam).filter((m) => members.find((mem) => mem._id === m).isInvite).length}
						memberCount={Object.keys(checkedTeam).length - Object.keys(checkedTeam).filter((m) => members.find((mem) => mem._id === m).isInvite).length}
					//count={Object.keys(this.state.checkedTeam).length}
					/>
				)}
			</div>
		);
	}
}

export default Teams;
