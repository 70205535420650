import React, { Component, createRef } from 'react';
import './ShimmerWave.scss';

class ShimmerWave extends Component {
  constructor(props) {
    super(props);
    this.shimmerRef = createRef();
  }

  componentDidMount() {
    this.splitTextToSpans();
  }

  componentDidUpdate() {
    this.splitTextToSpans();
  }

  splitTextToSpans() {
    const targetElement = this.shimmerRef.current;
    if (targetElement) {
      const text = this.props.text;
      targetElement.innerHTML = '';
      text.split('').forEach((character, index) => {
        const span = document.createElement('span');
        span.style.animationDelay = `${index * 0.05}s`;
        if (character === ' ') {
          span.innerHTML = '&nbsp;';
        } else {
          span.textContent = character;
        }
        targetElement.appendChild(span);
      });
    }
  }

  render() {
    return (
      <div className="shimmerWave" ref={this.shimmerRef}>
        {this.props.text}
      </div>
    );
  }
}

export default ShimmerWave;
