import React from 'react';
import axios from 'axios';
import 'react-datetime/css/react-datetime.css';
import { Modal, ModalBody } from 'reactstrap';
import InputField from '../../components/inputs/inputField';
import SelectField from '../../components/inputs/selectField';
import ToggleField from '../../components/inputs/toggleField';
import ColorField from '../../components/inputs/colorField';
import API from '../../utils/API';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FilesUploaderModal from '../../components/files/filesUploaderModal.js';
import FileUploadInputBox from '../../components/inputs/fileUploadInputBox';


const defaultPermissions = {
    details: { label: "Details", slug: 'details', write: true, read: true },
    attendees: { label: "Attendees", slug: 'attendees', write: true, read: true },
    schedule: { label: "Schedule", slug: 'schedule', write: true, read: true },
    groups: { label: "Groups", slug: 'groups', write: true, read: true },
    inventory: { label: "Inventory", slug: 'inventory', write: true, read: true, addon: 'inventory' },
    emails: { label: "Emails", slug: 'emails', write: true, read: true },
    texting: { label: "Texting", slug: 'texting', write: true, read: true, addon: 'texting' },
    forms: { label: "Forms", slug: 'forms', write: true, read: true },
    pages: { label: "Pages", slug: 'pages', write: true, read: true },
    engagement: { label: "Engagement", slug: 'engagement', write: true, read: true, addon: 'engagement' },
    media: { label: "Media", slug: 'media', write: true, read: true },
    insights: { label: "Insights", slug: 'insights', write: true, read: true },
}

const roles = [
    { label: "Admin", value: "admin" },
    { label: "Organizer", value: "organizer" },
]


function ValidateEmail(mail) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(mailformat)) {
        return true;
    } else {
        return false;
    }
}

let dropzoneLogoRef;
class OnboardingModal extends React.Component {
    constructor(props) {
        super(props);

        const orgBranding = {
            accentColor: props.organization.branding.accentColor,
            fontColor: props.organization.branding.fontColor,
            backgroundColor: props.organization.branding.backgroundColor,
            textColor: props.organization.branding.textColor,
            logo: props.organization.branding.logo,
            favicon: props.organization.branding.favicon,
            fonts: props.organization.branding.fonts.map(f => {
                return {
                    label: f.name,
                    value: f.url
                }
            })
        };

        this.state = {
            sectionCount: 3,
            currentSection: 0,
            nextSectionEnabled: true,
            supportContactEnabled: false,
            supportContactName: props.account.firstName + ' ' + props.account.lastName,
            supportContactEmail: props.account.email,
            orgBranding: orgBranding,
            files: [],
            supportContactEnabled: true,
            teammates: [],
            selectedColor: 'background',
            submitting: false,
            invalid: false,
            fontOptions: [],
            collaborateEmail: '',
            collaborateEmailErrorMessage: '',
            expandedCategories: ['logos'],
        };
    }

    componentDidMount() {
        //make request to https://www.googleapis.com/webfonts/v1/webfonts?key=

        axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDvDVvkn5-FAGP-pb4vA2azGooK63V2RD8`).then((res) => {
            let fontOptions = [];
            res.data.items.forEach((font) => {
                font.variants.forEach((variant) => {
                    var apiUrl = [];
                    apiUrl.push('https://fonts.googleapis.com/css?family=');
                    apiUrl.push(font.family.replace(/ /g, '+'));
                    apiUrl.push(':');
                    apiUrl.push(variant);
                    const url = apiUrl.join('');
                    fontOptions.push({
                        label: font.family + ' ' + variant,
                        value: url,
                        font: font.family,
                        variant: variant
                    });
                });
            });
            this.setState({ fontOptions });
        }).catch((e) => {
            console.log(e);
        })
    }

    onDrop = (files) => {
        this.setState({
            eventBranding: {
                ...this.state.eventBranding,
                header: files[0]
            }
        });
    };


    onDropLogo = (files) => {
        this.setState({
            eventBranding: {
                ...this.state.eventBranding,
                logo: files[0]
            }
        });
    };

    chooseEventType = (type) => {
        this.setState({
            event: {
                ...this.state.event,
                experienceType: type
            },
            nextSectionEnabled: true
        });
    };

    nextSection = () => {
        const { currentSection } = this.state;
        /* if (currentSection + 1 === 1) {
            this.initializeGeocoder();
            this.initializeMap();
        } */
        this.setState({
            currentSection: currentSection + 1,
            nextSectionEnabled: true
        });
    }

    prevSection = () => {
        const { currentSection } = this.state;
        this.setState({
            currentSection: currentSection - 1,
            nextSectionEnabled: true
        });
    }

    updateEventField = (field, value) => {
        const { event, requiredFields } = this.state;
        event[field] = value;
        let requiredFieldsAcquired = true;
        Object.keys(requiredFields).forEach((key) => {
            if (!event[key] || event[key] === '') {
                requiredFieldsAcquired = false;
            }
        });
        this.setState({ event, nextSectionEnabled: requiredFieldsAcquired, requiredFields });
    }

    updateRole = (role, index) => {
        let eventTeam = this.state.eventTeam;
        eventTeam[index].role = role.value;
        this.setState({ eventTeam: eventTeam })
    }

    onDropLogo = (files) => {
        this.uploadFile('logo', files[0]);
    }

    onDropFavicon = (files) => {
        this.uploadFile('favicon', files[0]);
    }

    renderSection = (section) => {
        const { currentSection, supportContactEnabled, teammates, orgBranding, selectedColor, fontOptions, collaborateEmail, collaborateEmailErrorMessage, expandedCategories } = this.state;
        const { account, organization, orgId } = this.props;
        const { logo, favicon, textColor, fontColor, backgroundColor, accentColor, fonts } = orgBranding;

        switch (section) {
            case 0:
                // event type picker
                return <div className={`sectionBody w-100 posAbs anim left ${section == currentSection ? '' : 'closed'}`}>
                    <div className={`headerContainer mb-50 mt-25`}>
                        <div className='text-c'>
                            <h1 className='mb-10'>Welcome to simple.events</h1>
                            <p>Confirm your account.</p>
                        </div>
                    </div>
                    <div className='sectionBlock m-a' style={{ maxWidth: 600 }}>
                        <div
                            className='flex'
                            style={{ gap: 34 }}
                        >
                            <InputField
                                label='First Name'
                                value={account.firstName}
                                disabled />
                            <InputField
                                label='Last Name'
                                value={account.lastName}
                                disabled />
                        </div>
                        <InputField
                            label='Email'
                            value={account.email}
                            inputIcon={<i className="las la-lock grey" />}
                            inputClassName={'grey'}
                            disabled />
                        <InputField
                            label='Company'
                            value={organization.name}
                            disabled
                            inputIcon={<i className="las la-lock grey" />}
                            inputClassName={'grey'}
                            information={
                                <div>
                                    <p>
                                        Please confirm your company. Once confirmed, this cannot be changed in the future. To change your company now, please email <u>support@simple.science</u>.
                                    </p>
                                </div>
                            }
                        />
                        <ToggleField
                            label="Use my information as the main contact for all events"
                            onChange={(e) => {
                                this.setState({ supportContactEnabled: e.target.checked })
                            }}
                            checked={supportContactEnabled}
                            icons={false}
                            style={{ marginBottom: 0 }}

                        />
                        {!supportContactEnabled && <div className='mt-20'>
                            <InputField label="Support Contact Name" value={this.state.supportContactName} onChange={(e) => this.setState({ supportContactName: e.target.value })} />
                            <InputField label="Support Contact Email" value={this.state.supportContactEmail} onChange={(e) => this.setState({ supportContactEmail: e.target.value })} />
                        </div>}
                    </div>
                </div>
            case 1:
                return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
                    <div className={`headerContainer mb-50 mt-25`}>
                        <div className='text-c'>
                            <h1 className='mb-10'>Brand</h1>
                            <p>Look how you're supposed to.</p>
                        </div>
                    </div>
                    <div className='sectionBlock m-a' style={{ maxWidth: 600 }}>
                        <div className='mb-30'>
                            <h3>Site Styles</h3>
                            <p>Manage the styles settings that appear accross your events.</p>
                        </div>
                        {/* Expand Begins */}
                        <div style={{
                            borderBottom: '1px solid var(--border)',
                            padding: '12px 15px 11px 15px',
                        }}>
                            <div className='flex jcsb'
                                onClick={() => {
                                    expandedCategories.includes('logos') ?
                                        this.setState({ expandedCategories: expandedCategories.filter((cat) => cat !== 'logos') }) :
                                        this.setState({ expandedCategories: [...expandedCategories, 'logos'] })
                                }
                                }
                            >
                                <div className="flex aic">
                                    Logos
                                </div>

                                <div className='gray'><i className={!expandedCategories.includes('logos') ? `las la-angle-down` : `las la-angle-up`}></i></div>

                            </div>

                            {expandedCategories.includes('logos') &&
                                <div className='flex' style={{ gap: 25 }}>
                                    <div className='mt-20 w-100'>
                                        <FileUploadInputBox
                                            label="Logo"
                                            addText="Image"
                                            value={logo}
                                            onClick={() => {
                                                let { logo } = orgBranding;
                                                logo = 'choosing__' + logo;
                                                this.setState({ orgBranding: { ...orgBranding, logo } });
                                            }}
                                            clearFile={() => {
                                                let { logo } = orgBranding;
                                                logo = '';
                                                this.setState({
                                                    orgBranding: {
                                                        ...this.state.orgBranding,
                                                        logo: ''
                                                    }
                                                });
                                            }}
                                            information={
                                                'Your logo will automatically display in your emails and pages. We recommend a .PNG file with transparency to ensure it looks great on all backgrounds.'
                                            }

                                        />
                                        {logo.includes('choosing__') &&
                                            <FilesUploaderModal
                                                isOpen={logo.includes('choosing__')}
                                                toggle={() => {
                                                    logo = logo.replace('choosing__', '');
                                                }}

                                                links={{
                                                    getAssets: `Organizations/${orgId}/assets`,
                                                    addAssets: `Organizations/${orgId}/assets`,
                                                }}
                                                orgId={orgId}
                                                addFiles={(fileURL) => {
                                                    this.setState({
                                                        orgBranding: {
                                                            ...this.state.orgBranding,
                                                            logo: fileURL
                                                        }
                                                    });
                                                }}
                                            />}
                                    </div>
                                    <div className=' mt-20 w-100'>
                                        <FileUploadInputBox
                                            label="Favicon"
                                            addText="Image"
                                            value={favicon}
                                            onClick={() => {
                                                let { favicon } = orgBranding;
                                                favicon = 'choosing__' + favicon;
                                                this.setState({ orgBranding: { ...orgBranding, favicon } });
                                            }}
                                            clearFile={() => {
                                                let { favicon } = orgBranding;
                                                favicon = '';
                                                this.setState({
                                                    orgBranding: {
                                                        ...this.state.orgBranding,
                                                        favicon: ''
                                                    }
                                                });
                                            }}
                                            information={'A favicon is a browser icon that represents a brand or website. Most often seen next to a web page’s title in browser tabs.'}

                                        />
                                        {favicon.includes('choosing__') &&
                                            <FilesUploaderModal
                                                isOpen={favicon.includes('choosing__')}
                                                toggle={() => {
                                                    favicon = favicon.replace('choosing__', '');
                                                }}

                                                links={{
                                                    getAssets: `Organizations/${orgId}/assets`,
                                                    addAssets: `Organizations/${orgId}/assets`,
                                                }}
                                                orgId={orgId}
                                                addFiles={(fileURL) => {
                                                    this.setState({
                                                        orgBranding: {
                                                            ...this.state.orgBranding,
                                                            favicon: fileURL
                                                        }
                                                    });
                                                }}
                                            />}
                                    </div>
                                </div>

                            }


                        </div>
                        {/* Expand Ends */}


                        {/* Expand Begins */}
                        <div style={{
                            borderBottom: '1px solid var(--border)',
                            padding: '12px 15px 11px 15px',
                        }}>
                            <div className='flex jcsb'
                                onClick={() => {
                                    expandedCategories.includes('colors') ?
                                        this.setState({ expandedCategories: expandedCategories.filter((cat) => cat !== 'colors') }) :
                                        this.setState({ expandedCategories: [...expandedCategories, 'colors'] })
                                }
                                }
                            >
                                <div className="flex aic">

                                    Colors
                                </div>

                                <div className='gray'><i className={!expandedCategories.includes('colors') ? `las la-angle-down` : `las la-angle-up`}></i></div>

                            </div>

                            {expandedCategories.includes('colors') &&
                                <div className='mt-20'>
                                    <ColorField
                                        label="Body Text Color"
                                        value={fontColor}
                                        type="color"
                                        useHex={true}
                                        onChange={(color) => {
                                            let { orgBranding } = this.state;
                                            orgBranding.fontColor = color;
                                            this.setState({ orgBranding });
                                        }}
                                        placeholder="#000"
                                    />

                                    <ColorField
                                        label="Button and Accent Background Color"
                                        type="color"
                                        useHex={true}
                                        value={accentColor}
                                        useDefaultColorEvent={true}
                                        onChange={(e) => {

                                            let { orgBranding } = this.state;
                                            if (e.rgb) {
                                                let rgbValue = [e.rgb.r, e.rgb.g, e.rgb.b];
                                                let color = Math.round(((parseInt(rgbValue[0]) * 299) +
                                                    (parseInt(rgbValue[1]) * 587) +
                                                    (parseInt(rgbValue[2]) * 114)) / 1000);
                                                let textColor = (color > 125) ? '#000' : '#fff';
                                                orgBranding.accentColor = e.hex;
                                                orgBranding.textColor = textColor;
                                                this.setState({ orgBranding });
                                            } else {
                                                orgBranding.accentColor = e;
                                                orgBranding.textColor = '#000';
                                                this.setState({ orgBranding });
                                            }
                                        }}
                                        placeholder="#000"
                                    />
                                </div>

                            }


                        </div>
                        {/* Expand Ends */}


                        {/* Expand Begins */}
                        <div style={{
                            borderBottom: '1px solid var(--border)',
                            padding: '12px 15px 11px 15px',
                        }}>
                            <div className='flex jcsb'
                                onClick={() => {
                                    expandedCategories.includes('fonts') ?
                                        this.setState({ expandedCategories: expandedCategories.filter((cat) => cat !== 'fonts') }) :
                                        this.setState({ expandedCategories: [...expandedCategories, 'fonts'] })
                                }
                                }
                            >
                                <div className="flex aic">

                                    Fonts
                                </div>

                                <div className='gray'><i className={!expandedCategories.includes('fonts') ? `las la-angle-down` : `las la-angle-up`}></i></div>

                            </div>

                            {expandedCategories.includes('fonts') &&
                                <div className='mt-20 mb-50'>
                                    <h3>Preferred Font Family</h3>
                                    <p className='mb-20'>Will be applied to all the pages created on the platform unless explicitly stated otherwise.</p>

                                    <SelectField label="Search Fonts" placeholder="Search Fonts" classes="multi" isMulti={true} options={fontOptions} value={fonts} onChange={(selectedOptions) => {
                                        let { orgBranding } = this.state;
                                        orgBranding.fonts = selectedOptions;
                                        this.setState({ orgBranding });
                                    }} />

                                    {/* <div className='w-100 mt-20'>
                                        <p className='small gray mb-10'>Upload Custom Font</p>
                                        <Dropzone
                                            accept="image/*"
                                            className="clickable dropzone-details"
                                            inputProps={{ style: { margin: 'auto' } }}
                                            ref={(node) => {
                                                dropzoneLogoRef = node;
                                            }}
                                            onDrop={this.onDropFavicon.bind(this)}
                                        >
                                            <div className="dropzoneDescriptionContainer">
                                                {favicon != "" ? <img className="dropHeaderImage" src={favicon} /> : <div className='flex aic jcc column p-20'>
                                                    <i className="las la-plus mb-10" style={{ padding: 10, background: '#F5F6FA', borderRadius: 5 }}></i>
                                                    <p>
                                                        Install Font
                                                    </p>
                                                </div>}
                                            </div>

                                        </Dropzone>
                                    </div> */}
                                </div>
                            }


                        </div>
                        {/* Expand Ends */}
                    </div>
                </div>
            case 2:
                return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
                    <div className={`headerContainer mb-50 mt-25`}>
                        <div className='text-c'>
                            <h1 className='mb-10'>Collaborate</h1>
                            <p>Invite team members that help bring this together.</p>
                        </div>
                    </div>
                    <div className='sectionBlock m-a' style={{ maxWidth: 600 }}>

                        <InputField
                            label="Email"
                            placeholder="Add email to invite..."
                            value={collaborateEmail}
                            onChange={(e) => this.setState({ collaborateEmail: e.target.value })}
                            errorTrigger={collaborateEmailErrorMessage}
                            errorMessage={collaborateEmailErrorMessage}
                            inputActionLabel={collaborateEmail.length > 0 ? <i className="las la-plus-circle primary" /> : ''}
                            inputAction={() => {

                                let { teammates } = this.state;
                                if (ValidateEmail(collaborateEmail)) {

                                    if (!teammates.some(t => t.email === collaborateEmail)) {

                                        teammates.push({
                                            email: collaborateEmail,
                                            role: "admin",
                                        });
                                        this.setState({ teammates, collaborateEmail: '', collaborateEmailErrorMessage: '' });

                                    } else {

                                        this.setState({ collaborateEmailErrorMessage: 'User already added' });
                                    }

                                } else {

                                    this.setState({ collaborateEmailErrorMessage: 'Invalid email address' });
                                }


                            }}
                        />

                        {teammates.length > 0 && <h3 className='pt-20 mb-20'>Team</h3>}
                        {teammates.map((teammate, i) => {

                            return <div>
                                <div
                                    className={'flex jcsb aic mb-20'}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '12px 13px 11px 20px',
                                        borderRadius: 5,
                                        border: '1px solid var(--border)',
                                    }}
                                >
                                    <div>{teammate.email}</div>
                                    <div
                                        className='flex'
                                    >
                                        <div><UncontrolledDropdown direction="left" className='flex jcc aic' inNavbar style={{ marginRight: 45 }}>
                                            <DropdownToggle className="columnToggle " style={{ width: '100%', minHeight: 22, height: 22, }}>
                                                <div
                                                    className=''
                                                    style={{

                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: '5px',
                                                        padding: '4px 9px',
                                                        height: '22px',
                                                        color: '#999999',
                                                    }}>
                                                    <p className="small">{roles.find(r => r.value === teammate.role).label}</p>
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu className="columnDropdown" style={{ minWidth: 280, maxHeight: 275, padding: '10px' }}>

                                                <div
                                                    className="userListContainer"
                                                >

                                                    <DropdownItem
                                                        onClick={() => {
                                                            let { teammates } = this.state;
                                                            teammates[i]['role'] = 'admin';
                                                            this.setState({ teammates });
                                                        }
                                                        }
                                                    >
                                                        <div className='flex aic jcsb  pv-10'>
                                                            <div className='flex aic'>

                                                                <div className='ml-5 flex aic name '>
                                                                    <p>Admin</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => {
                                                            let { teammates } = this.state;
                                                            teammates[i]['role'] = 'organizer';
                                                            this.setState({ teammates });
                                                        }
                                                        }
                                                    >
                                                        <div className='flex aic jcsb  pv-10'>
                                                            <div className='flex aic'>

                                                                <div className='ml-5 flex aic name '>
                                                                    <p>Organizer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown></div>
                                        <div
                                            className='c-pointer'
                                            onClick={() => {
                                                let { teammates } = this.state;
                                                teammates.splice(i, 1);
                                                this.setState({ teammates });
                                            }}
                                        ><i className="las la-times-circle grey" /></div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div >
            case 3:

                return <div className={`sectionBody w-100 posAbs anim right ${section == currentSection ? '' : 'closed'}`}>
                    <div className={`headerContainer mb-40 mt-25`}>
                        <div className='text-c'>
                            <h1 className='mb-10'>Thank You</h1>
                            <p className='pt-75'>Use the power of simple.events to create your events.</p>

                        </div>
                    </div>
                    <div className='sectionBlock m-a flex aic' style={{ maxWidth: 600 }}>
                        <button className={`neu  prim`}
                            onClick={() => {
                                this.props.onboardingCompleteToggle()

                            }}>Create Your First Event</button>
                    </div>
                </div>
            default:
                return;

        }
    }

    handleTeammateChange(e, i, field) {
        let { teammates } = this.state;
        teammates[i][field] = e.target.value;
        this.setState({ teammates });
    }

    uploadFile = async (key, file) => {
        const formData = new FormData();
        formData.append('files', file);
        const res = await API().post(`/Organizations/${this.props.orgId}/assets`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (res.data) {
            this.setState({
                orgBranding: {
                    ...this.state.orgBranding,
                    [key]: res.data.assets[0].url
                }
            });
        }
    }

    submitOrgDetails = async () => {
        const { supportContactName, supportContactEmail, teammates, orgBranding, currentSection } = this.state;
        const { fontColor, accentColor, backgroundColor, textColor, fonts, logo, favicon } = orgBranding;


        API()
            .patch(`/Organizations/${this.props.orgId}/account`, {
                onboarded: true,
                branding: {
                    logo: logo,
                    favicon: favicon,
                    adminEmail: supportContactEmail,
                    adminEmailName: supportContactName,
                    supportEmail: supportContactEmail,
                    fontColor: fontColor,
                    accentColor: accentColor,
                    //backgroundColor: backgroundColor,
                    textColor: textColor,
                    fonts: fonts.map(f => {
                        return {
                            name: f.label,
                            url: f.value
                        }
                    })
                }
            })
            .then((res) => {
                if (res.data && teammates.length > 0) {

                    API().post(`Organizations/${this.props.orgId}/memberInvite`, {
                        teamMembers: teammates
                    })
                        .then((res) => {
                            this.setState({
                                currentSection: currentSection + 1,
                            });
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                } else {
                    this.setState({
                        currentSection: currentSection + 1,
                    });
                }
            })
            .catch((e) => {
                //this.setState({ editing: false, editedAccountData: this.state.accountData });
            });
    }

    dismissModal = () => {
        API()
            .patch(`/Organizations/${this.props.orgId}/account`, {
                onboarded: true,
                branding: {}
            }).then((res) => {
                this.props.toggle();
            })
    }


    render() {
        const { isOpen } = this.props;
        const { nextSectionEnabled, currentSection, sectionCount, submitting } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={this.dismissModal} className="stepSize">
                <div className="modalHeader">
                    <button onClick={this.dismissModal} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
                </div>
                <ModalBody className="modalBody">
                    <div>
                        {this.renderSection(0)}
                        {this.renderSection(1)}
                        {this.renderSection(2)}
                        {this.renderSection(3)}
                    </div>
                </ModalBody>
                {currentSection !== 3 && <div className='flex m-a' style={{ width: '100%' }}>

                    {currentSection == 0 ? <button className="neu ml-a mr-15" onClick={this.dismissModal}>Cancel</button> : <button className="neu ml-a mr-15" onClick={this.prevSection}>Back</button>}
                    {currentSection < sectionCount - 1 ? <button className={`neu  ${nextSectionEnabled ? 'prim' : 'disabled'}`}
                        onClick={this.nextSection}>Next</button> : <button className={`neu ${submitting ? 'disabled' : 'prim'}`} onClick={this.submitOrgDetails}>{submitting ? "Submitting..." : "Finish"}</button>}
                </div>}
            </Modal>
        );
    }
}

export default OnboardingModal;
