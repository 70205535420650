import React from 'react';

import 'react-datetime/css/react-datetime.css';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import cityTimezones from 'city-timezones';
import moment from 'moment-timezone';
import ToggleField from '../../components/inputs/toggleField';
import InputField from '../../components/inputs/inputField';
import SelectField from '../../components/inputs/selectField';
import TimeField from '../../components/inputs/timeField';
import LocationLookup from '../../components/inputs/locationLookup';
import ColorField from '../../components/inputs/colorField';
import ExpandableListItem from '../../components/list/expandableListItem';
import Dropzone from 'react-dropzone';
import ViewsTable from '../../components/tables/ViewsTable';
import { UncontrolledTooltip } from "reactstrap";

const defaultPermissions = {
    details: { label: "Details", slug: 'details', write: true, read: true },
    attendees: { label: "Attendees", slug: 'attendees', write: true, read: true },
    schedule: { label: "Schedule", slug: 'schedule', write: true, read: true },
    groups: { label: "Groups", slug: 'groups', write: true, read: true },
    inventory: { label: "Inventory", slug: 'inventory', write: true, read: true, addon: 'inventory' },
    emails: { label: "Emails", slug: 'emails', write: true, read: true },
    texting: { label: "Texting", slug: 'texting', write: true, read: true, addon: 'texting' },
    forms: { label: "Forms", slug: 'forms', write: true, read: true },
    pages: { label: "Pages", slug: 'pages', write: true, read: true },
    engagement: { label: "Engagement", slug: 'engagement', write: true, read: true, addon: 'engagement' },
    media: { label: "Media", slug: 'media', write: true, read: true },
    insights: { label: "Insights", slug: 'insights', write: true, read: true },
}
let dropzoneBannerRef;
let dropzoneLogoRef;
class DuplicateEventModal extends React.Component {
    constructor(props) {
        super(props);

        const eventBranding = {
            accentColor: props.organization.branding.accentColor,
            fontColor: props.organization.branding.fontColor,
            backgroundColor: props.organization.branding.backgroundColor,
            logo: { name: "Organization Logo", preview: props.organization.branding.logo },
        };
        const metadata = [];

        this.props.customFields.forEach(cf => {
            cf.fields.forEach(field => {
                metadata.push({
                    categoryId: cf._id,
                    fieldId: field._id,
                    fieldLabel: field.fieldName,
                    required: field.required,
                    type: field.fieldType,
                    fieldValue: ''
                })
            });
        });



        const salesforceIntegration = props.organization.integrations.find((integration) => integration.identifier == 'salesforce');
        let sfRegStatus = 'Registered';
        let sfCheckinStatus = 'Checked In';
        let sfNotAttendingStatus = 'Did Not Attend';
        if (salesforceIntegration) {

            salesforceIntegration.requiredFields.forEach((field) => {
                if (field.id === 'registeredStatus') sfRegStatus = field.value;
                if (field.id === 'checkedinStatus') sfCheckinStatus = field.value;
                if (field.id === 'didNotAttendStatus') sfNotAttendingStatus = field.value;
            });
        }
        this.state = {
            sectionCount: 4,
            currentSection: 0,
            nextSectionEnabled: false,
            chosenProgram: null,
            name: '',
            event: {
                experienceType: '',
                name: '',
                startDate: moment().format('MM/DD/YYYY'),
                endTime: '',
                startTime: '',
                endDate: moment().format('MM/DD/YYYY'),
                timezone: '',
                metadata: metadata,
                enableMeetingPassword: false,
                enableSSOLogin: false,
                enablePassthroughPassword: false,
                meetingPassword: '',
                SSOLoginUrl: '',
            },
            eventBranding: eventBranding,
            startDate: '',
            endTime: '',
            startTime: '',
            endDate: '',
            cityLookup: '',
            timezone: '',
            selectedVenue: {},
            submitting: false,
            invalid: false,
            locations: props.locations,
            currentStep: 0,
            requiredFields: {
                name: false,
                startDate: false,
                endDate: false,
                startTime: false,
                endTime: false,
                timezone: false
            },
            requiredCustomFields: [],
            eventTeam: [],
            salesforceEnabled: salesforceIntegration ? true : false,
            salesforceToggled: false,
            salesforceCampaign: '',
            searchTerm: '',
            salesforceRegistrationStatus: sfRegStatus,
            salesforceCheckedinStatus: sfCheckinStatus,
            salesforceNotAttendingStatus: sfNotAttendingStatus,
            checkedEvent: {},
        };
    }
    onDrop = (files) => {
        this.setState({
            eventBranding: {
                ...this.state.eventBranding,
                header: files[0]
            }
        });
    };


    onDropLogo = (files) => {
        //  console.log(files)
        this.setState({
            eventBranding: {
                ...this.state.eventBranding,
                logo: files[0]
            }
        });
    };

    createEvent = () => {
        const { handleSubmit } = this.props;
        const { chosenEvent, requiredCustomFields, event, selectedVenue, eventBranding, salesforceToggled, salesforceCampaign } = this.state;
        const { name, timezone, startTime, startDate, endTime, endDate, allowPublicRegistrations, enableMeetingPassword, meetingPassword, enableSSOLogin, SSOLoginUrl, enablePassthroughPassword, metadata } = event;
        this.setState({ submitting: true });

        const requiredFields = this.state.requiredFields;
        const editedEvent = {
            name,
            startDate,
            endDate,
            startTime,
            endTime,
            timezone,
            allowPublicRegistrations,
            enableMeetingPassword,
            meetingPassword,
            enableSSOLogin,
            SSOLoginUrl,
            enablePassthroughPassword,
            venue: selectedVenue.new ? selectedVenue.new : selectedVenue.value,
            customFields: requiredCustomFields,
            metadata,
            integrations: salesforceToggled ? {
                salesforce: {
                    campaignName: salesforceCampaign,
                    registrationStatus: this.state.salesforceRegistrationStatus,
                    checkinStatus: this.state.salesforceCheckedinStatus,
                    notAttendedStatus: this.state.salesforceNotAttendingStatus,
                }
            } : {}
        };
        const uploads = [];
        if (eventBranding.header) {
            uploads.push({ file: eventBranding.header, identifier: 'headerImage' })
        }
        if (eventBranding.logo && eventBranding.logo.preview && eventBranding.logo.preview.startsWith('blob:')) {
            uploads.push({ file: eventBranding.logo, identifier: 'logo' })
        }
        let valid = true;
        Object.keys(requiredFields).forEach((key) => {
            if (!editedEvent[key] || editedEvent[key] === '') {
                requiredFields[key] = true;
                valid = false;
            } else {
                requiredFields[key] = false;
            }
        });

        if (valid) {


            const res = this.state.isNewVenue
                ? handleSubmit({
                    programId: chosenEvent._id, name, startDate, endDate, startTime, endTime, timezone,
                    customFields: requiredCustomFields, metadata,
                    integrations: salesforceToggled ? {
                        salesforce: {
                            campaignName: salesforceCampaign,
                            registrationStatus: this.state.salesforceRegistrationStatus,
                            checkinStatus: this.state.salesforceCheckedinStatus,
                            notAttendedStatus: this.state.salesforceNotAttendingStatus,
                        }
                    } : {}
                }, selectedVenue, true)
                : handleSubmit({
                    eventId: chosenEvent._id,
                    name,
                    startDate,
                    endDate,
                    startTime,
                    endTime,
                    timezone,
                    allowPublicRegistrations,
                    enableMeetingPassword,
                    meetingPassword,
                    enableSSOLogin,
                    SSOLoginUrl,
                    enablePassthroughPassword,
                    members: this.state.eventTeam,
                    venue: selectedVenue.value,
                    customFields: requiredCustomFields,
                    /* branding: {
                        accentColor: eventBranding.accentColor,
                        fontColor: eventBranding.fontColor,
                        backgroundColor: eventBranding.backgroundColor,
                    }, */
                    metadata,
                    integrations: salesforceToggled ? {
                        salesforce: {
                            campaignName: salesforceCampaign,
                            registrationStatus: this.state.salesforceRegistrationStatus,
                            checkinStatus: this.state.salesforceCheckedinStatus,
                            notAttendedStatus: this.state.salesforceNotAttendingStatus,
                        }
                    } : {}
                }, false, true, uploads);
        } else {
            this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
        }
    };


    selectEvent = (event) => {
        this.setState({
            chosenEvent: event,
            nextSectionEnabled: true
        });
    };

    goToEventDetailsStep = () => {

        const customFields = JSON.parse(JSON.stringify(this.props.customFields));
        const requiredCustomFields = this.state.chosenProgram && this.state.chosenProgram.eventDefaults.details.requiredCustomFields ? customFields.map(category => {

            category.fields = category.fields.filter(field => {
                return this.state.chosenProgram.eventDefaults.details.requiredCustomFields.includes(field._id)
            });
            return category;
        }) : [];
        this.setState({
            currentStep: 1,
            requiredCustomFields
        });
    };

    handleCustomFieldChange = (field, value) => {
        const requiredCustomFields = this.state.requiredCustomFields;
        requiredCustomFields.forEach(category => {
            category.fields.forEach(f => {
                if (f._id === field._id) {
                    f.fieldValue = value;
                }
            });
        });
        this.setState({
            requiredCustomFields
        });
    };

    /* Search */
    searchEntries = (searchText) => {
        this.setState({
            searching: searchText != '' ? true : false,
            searchText: searchText ? searchText : '',
        });
    };

    renderSection = (section) => {
        const { events } = this.props;
        const {
            cityLookup,
            requiredFields,
            chosenEvent,
            salesforceEnabled,
            searchTerm,
            currentMember
        } = this.state;
        const cityTimezoneOptions =
            cityLookup != ''
                ? cityTimezones.findFromCityStateProvince(cityLookup).map((tz) => {
                    return { label: `${tz.city}, ${tz.province}`, value: tz.timezone };
                })
                : [];
        const { currentSection, event, eventBranding } = this.state;
        const { experienceType,
            name,
            timezone,
            startDate,
            endDate,
            startTime,
            endTime,
            allowPublicRegistrations,
            enableMeetingPassword,
            meetingPassword,
            SSOLoginUrl,
            enableSSOLogin,
            enablePassthroughPassword,


        } = event;
        const columns = {
            name: 'Name',
            experienceType: 'Experience Type',
            venue: 'Venue',
            timezone: 'Time Zone',
            startDate: 'Start Date'
        };
        const columnTypes = {
            name: 'string',
            experienceType: 'string',
            venue: 'string',
            timezone: 'string',
            startDate: 'string',
        };
        const columnWidths = {
            name: 250,
        };
        const toggledColumns = {};
        const normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });
        const eventData = events.map(event => {
            // Find the matching location based on the event's venue matching the location's value
            const matchingLocation = this.state.locations.find(location => location.value === event.venue);
            return matchingLocation ? { ...event, venue: matchingLocation.label } : { ...event, venue: "" }
        });

        switch (section) {
            case 0:
                let data = eventData;
                if (searchTerm.length > 0) {
                    const searchTerms = searchTerm.toLowerCase().split(' ');
                    searchTerms.forEach((term) => {
                        if (term != '') {
                            data = data.filter((entry) => {
                                let found = false;
                                Object.keys(columns).forEach((key) => {
                                    if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                        found = true;
                                    }
                                });
                                return found;
                            });
                        }
                    });
                }
                return <div>
                    <div className={`sectionBody w-100 posAbs anim sectionBody w-100 posAbs anim left ${section == currentSection ? '' : 'closed'}`}>


                        <div style={{ textAlign: 'center' }}>
                            <h1>Duplicate Event</h1>
                            <div className='mb-20  mt-10'>
                                <p className='mb-10'>
                                    Choose an event to duplicate. You can duplicate any event you have access to.
                                </p>

                            </div>
                        </div>
                        <div className='sectionBlock viewsTable'>
                            <div className='mb-10  mt-10 flex aic'>
                                <InputField
                                    classes="m-0 viewSearch"
                                    placeholder="Search "
                                    clickIcon={true}
                                    required={true}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        clearTimeout(this.searchTimeout);
                                        this.setState({ searchTerm: e.target.value },
                                            () => {
                                                this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
                                            });
                                    }}
                                    prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                    inputIcon={searchTerm != '' &&
                                        <i
                                            onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
                                            //onClick={() => this.setState({ searchTerm: '', searching: false })}
                                            className="las la-times-circle"
                                            style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                        />}
                                />
                            </div>
                            <div className="componentList p-0" style={{ height: '500px' }}>

                                <ViewsTable
                                    title="Event"
                                    classes="h-100 mt-10 viewsTableScrollable"
                                    columns={normalizedColumns}
                                    toggledColumns={toggledColumns}
                                    columnWidths={columnWidths}
                                    data={data}
                                    mainColumnActive={false}
                                    checked={this.state.checkedEvent}
                                    checkRow={(eventId) => {
                                        let checked = {}
                                        if (this.state.checkedEvent[eventId]) {
                                            checked = {};
                                        } else {
                                            checked[eventId] = true;
                                        }
                                        const eventToSelect = checked[eventId] ? this.props.events.find(event => event._id === eventId) : null;
                                        this.setState({
                                            checkedEvent: checked,
                                            chosenEvent: eventToSelect,
                                            nextSectionEnabled: !!Object.keys(checked).length,
                                        });
                                    }}
                                    singleCheck={true}
                                    mainActions={{}}
                                    checkAllRows={{}}
                                    sortData={() => { }}
                                    disableCheck={false}
                                />

                            </div>
                        </div>

                    </div>
                </div>
            case 1:
                return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Event Information</h1>
                        <div className='mb-20  mt-10'>
                            <p className='mb-10'>Please enter the information below to set up your {experienceType == 'in-person' ? 'In-Person' : experienceType == 'virtual' ? 'Virtual' : 'Hybrid'} experience</p>

                        </div>
                    </div>



                    <div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 96px)' }}>
                        <div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1, paddingTop: 30 }} >
                            <h3 className='blockHeader mb-20'>Basic Information</h3>
                            <InputField
                                label={'Event Name'}
                                required={true}
                                errorTrigger={requiredFields.name}
                                value={name}
                                onChange={(e) => {
                                    this.updateEventField('name', e.target.value);
                                }}
                            //subText={`URL: https://${this.props.organization.identifier}.simple.events/${name.replace(/[^A-Za-z0-9]/g, '')}`}
                            />
                            <div className="flex-sb w-100">
                                <div className="mr-10 w-100">
                                    <InputField
                                        label={'Start Date'}
                                        value={moment(startDate).format('yyyy-MM-DD')}
                                        min={moment().format('yyyy-MM-DD')}
                                        required={true}
                                        errorTrigger={requiredFields.startDate}
                                        type={'date'}
                                        inputIcon={<i className="lar la-calendar" />}
                                        onChange={(e) => {
                                            this.updateEventField('startDate', moment(e.target.value).format('MM/DD/YYYY'));
                                        }}
                                    />
                                </div>

                                <div className="ml-10 w-100">
                                    <InputField
                                        label={'End Date'}
                                        value={moment(endDate).format('yyyy-MM-DD')}
                                        min={moment().format('yyyy-MM-DD')}
                                        required={true}
                                        errorTrigger={requiredFields.endDate}
                                        type={'date'}
                                        inputIcon={<i className="lar la-calendar" />}
                                        onChange={(e) => {
                                            this.updateEventField('endDate', moment(e.target.value).format('MM/DD/YYYY'));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex-sb w-100">
                                <div className="mr-10 w-100">
                                    <TimeField
                                        label={'Start Time'}
                                        value={startTime}
                                        errorTrigger={requiredFields.startTime}
                                        onChange={(time) => {
                                            this.updateEventField('startTime', time);
                                        }}
                                    />
                                </div>

                                <div className="ml-10 w-100">
                                    <TimeField
                                        label={'End Time'}
                                        value={endTime}
                                        errorTrigger={requiredFields.endTime}
                                        onChange={(time) => {
                                            this.updateEventField('endTime', time);
                                        }}
                                    />
                                </div>
                            </div>

                            {(experienceType == 'in-person' || experienceType == 'hybrid') && (
                                <LocationLookup
                                    selectedPlace={this.state.selectedVenue}
                                    locations={this.props.locations}
                                    label="Location Finder"
                                    placeholder={<p className='grey'><i>Search for the venue where the event will take place</i></p>}
                                    noOptionsMessage="No results"
                                    onSelect={(location) => {
                                        if (location.timezone) {
                                            this.updateEventField('timezone', location.timezone);
                                        }
                                        this.setState({ selectedVenue: location })
                                    }}
                                    classes="w-100"
                                />
                            )}

                            <SelectField
                                label={'Timezone'}
                                value={{
                                    value: timezone, label: <p>
                                        {timezone}
                                        <span className='grey'><i>{timezone ? ` (${moment().tz(timezone).zoneAbbr()})` : ''}</i></span>
                                    </p>
                                }}
                                placeholder="Search for nearest city"
                                name="timezones"
                                classes="w-100"
                                options={cityTimezoneOptions}
                                required={true}
                                errorTrigger={requiredFields.timeZone}
                                onInputChange={(text) => {
                                    this.setState({ cityLookup: text });
                                }}
                                noOptionsMessage={({ inputValue }) =>
                                    !inputValue ? 'Type city name to get options' : 'No cities match your search'}
                                onChange={(timezone) => {
                                    this.updateEventField('timezone', timezone.value);
                                }}
                            />
                            {salesforceEnabled && <div>
                                <h3 className='blockHeader'>Salesforce</h3>
                                <p className='small gray  mb-10'>Connect to Salesforce to sync attendee data and create new leads.</p>
                                <ToggleField icons={false} label="Enable Salesforce Integration" checked={this.state.salesforceToggled} onChange={(checked) => {
                                    if (this.state.salesforceToggled) {
                                        let requiredFieldsAcquired = true;
                                        Object.keys(requiredFields).forEach((key) => {
                                            if (!event[key] || event[key] === '') {
                                                requiredFieldsAcquired = false;
                                            }
                                        });
                                        this.setState({ salesforceToggled: false, nextSectionEnabled: requiredFieldsAcquired, salesforceCampaign: '' });
                                    } else {
                                        this.setState({ salesforceToggled: true, nextSectionEnabled: false });
                                    }
                                }} />
                                {this.state.salesforceToggled && <div className="w-100">
                                    <InputField
                                        label={'Campaign Name'}
                                        value={this.state.salesforceCampaign}
                                        placeholder={'Name the Salesforce campaign for this event'}
                                        required={true}
                                        errorTrigger={requiredFields.salesforceCampaign}
                                        inputIcon={<i className="lab la-salesforce" />}
                                        onChange={(e) => {

                                            let requiredFieldsAcquired = true;
                                            Object.keys(requiredFields).forEach((key) => {
                                                if (!event[key] || event[key] === '') {
                                                    requiredFieldsAcquired = false;
                                                }
                                            });
                                            if (!e.target.value || e.target.value === '') {
                                                requiredFieldsAcquired = false;
                                            }
                                            this.setState({ salesforceCampaign: e.target.value, nextSectionEnabled: requiredFieldsAcquired });
                                        }}
                                    />

                                    <InputField
                                        label={'Registration Status'}
                                        value={this.state.salesforceRegistrationStatus}
                                        placeholder={'Registered'}
                                        onChange={(e) => {
                                            this.setState({ salesforceRegistrationStatus: e.target.value });
                                        }}
                                    />

                                    <InputField
                                        label={'Checked In Status'}
                                        value={this.state.salesforceCheckedinStatus}
                                        placeholder={'Checked In'}
                                        onChange={(e) => {
                                            this.setState({ salesforceCheckedinStatus: e.target.value });
                                        }}
                                    />

                                    <InputField
                                        label={'Did Not Attend Status'}
                                        value={this.state.salesforceNotAttendingStatus}
                                        placeholder={'Did Not Attend'}
                                        onChange={(e) => {
                                            this.setState({ salesforceNotAttendingStatus: e.target.value });
                                        }}
                                    />
                                </div>}

                            </div>}
                        </div>
                    </div>
                </div>
            case 2:
                //event access
                return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Event Access</h1>
                        <div className='mb-20  mt-10'>
                            <p className='mb-10'>Choose how attendees will register and access for your event.</p>
                        </div>
                    </div>
                    <div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 96px)', paddingTop: 30 }}>
                        <div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1 }}>
                            <h3 className='blockHeader mb-10'>Event Access</h3>
                            <SelectField
                                label={'Registration'}
                                classes="w-100"
                                name="eventType"
                                options={[{ label: 'Invite Only', value: false }, { label: 'Public', value: true }]}
                                required={true}
                                errorTrigger={requiredFields.allowPublicRegistrations}
                                value={allowPublicRegistrations ? { label: 'Public', value: true } : allowPublicRegistrations == false ? { label: 'Invite Only', value: false } : null}
                                onChange={(eventType) => {
                                    this.updateEventField('allowPublicRegistrations', eventType.value);
                                    this.errorCheckerEventAccessSection();
                                }}
                                information={
                                    <div>
                                        <h3 class="blockHeader">Invite Only</h3>
                                        <p className='mb-10'>
                                            Only attendees you have invited can register.
                                        </p>

                                        <h3 class="blockHeader">Public Events</h3>
                                        <p>
                                            Anyone with the link can register
                                        </p>

                                    </div>
                                }
                            />

                            <h3 className='blockHeader mb-20 pt-10'>Event Login</h3>
                            <ToggleField
                                label={'Enable Meeting Password'}
                                icons={false}
                                checked={enableMeetingPassword}
                                onChange={(e) => {
                                    this.setState({ event: { ...event, enableMeetingPassword: e.target.checked, meetingPassword: e.target.checked ? meetingPassword : '' } }, () => {
                                        this.errorCheckerEventAccessSection();
                                    })
                                }}
                            />
                            {enableMeetingPassword && (
                                <InputField
                                    label={'Meeting Password'}
                                    required={true}
                                    value={meetingPassword}
                                    onChange={(e) => {
                                        this.setState({
                                            event: { ...event, meetingPassword: e.target.value },
                                        }, () => {
                                            this.errorCheckerEventAccessSection();
                                        });
                                    }}
                                />
                            )}
                            <ToggleField
                                label={'Enable SSO'}
                                icons={false}
                                checked={enableSSOLogin}
                                onChange={(e) => {
                                    this.setState({ event: { ...event, enableSSOLogin: e.target.checked, SSOLoginUrl: e.target.checked ? SSOLoginUrl : '' } }, () => {
                                        this.errorCheckerEventAccessSection();
                                    })
                                }}
                            />
                            {enableSSOLogin && (
                                <InputField
                                    label={'SSO URL'}
                                    className={`editingField`}
                                    required={true}
                                    errorTrigger={requiredFields.SSOLoginUrl}
                                    value={SSOLoginUrl}
                                    onChange={(e) => {
                                        this.setState({
                                            event: { ...event, SSOLoginUrl: e.target.value },
                                            editing: true
                                        }, () => {
                                            this.errorCheckerEventAccessSection();

                                        });
                                    }}
                                />
                            )}
                            <ToggleField
                                label={'Enable Magic Link'}
                                checked={enablePassthroughPassword}
                                icons={false}
                                onChange={(e) => {
                                    this.setState({ event: { ...event, enablePassthroughPassword: e.target.checked } })
                                }}
                            />
                        </div>
                    </div>
                </div>

                break;
            case 3:
                const account = this.props.account;
                //Add Team Members
                let orgMembers = this.props.organization.members;
                orgMembers = orgMembers.filter((member) => member.permission !== 'admin' && !this.state.eventTeam.some(teamMember => teamMember._id == member._id));
                let adminMembers = this.props.organization.members.filter((member) => member.permission === 'admin');
                return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>

                    <div style={{ textAlign: 'center' }}>
                        <h1>Add Team Members</h1>
                        <div className='mb-20  mt-10'>
                            <p className='mb-10'>
                                All admins can see your event plus all organizers below. Add and mange organizers.
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 80px)' }}>
                        <div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1, paddingTop: 30 }}>
                            <SelectField
                                label={'Available Organizers'}
                                options={orgMembers.map((member) => {

                                    return { label: member.email, value: member._id }
                                })
                                }
                                value={currentMember}
                                onChange={(currentMember) => {
                                    let eventTeam = this.state.eventTeam;
                                    let member = this.props.organization.members.find((member) => member._id == currentMember.value);
                                    eventTeam.unshift({ ...member, permissions: { ...JSON.parse(JSON.stringify(defaultPermissions)) } });
                                    this.setState({ eventTeam: eventTeam })
                                }}
                            />
                            <h3 className='pt-20 mb-20'>Team</h3>


                            {this.state.eventTeam.length > 0 && <div className='flex flex-wrap'>
                                {this.state.eventTeam.map((member, i) => {
                                    const permissions = member.permissions;
                                    let permissionCount = 0;
                                    let readCount = 0;
                                    let writeCount = 0;
                                    Object.keys(defaultPermissions).forEach((key) => {
                                        if (permissions[key].read) {
                                            readCount++;
                                        }
                                        if (permissions[key].write) {
                                            writeCount++;
                                        }
                                        permissionCount++;
                                    });
                                    let accessString = '';
                                    let role = "";
                                    let countOf = `(${readCount}/${permissionCount})`;
                                    if (Object.keys(defaultPermissions).length === permissionCount && readCount == permissionCount && writeCount == permissionCount) {
                                        accessString = 'Full Access';
                                        role = 'Organizer';
                                    } else if (Object.keys(defaultPermissions).length === permissionCount && writeCount <= permissionCount && writeCount > 0 && readCount >= writeCount) {
                                        accessString = `Limited Access to ${writeCount} sections`;
                                        role = 'Staff';
                                    } else if (Object.keys(defaultPermissions).length === permissionCount && readCount <= permissionCount && readCount > 0) {
                                        accessString = `Read Only Access to ${readCount} sections`;
                                        role = 'Guest';
                                    } else {
                                        accessString = 'Will not be added';
                                        role = 'No Access';
                                    }
                                    return <ExpandableListItem className='w-100 mb-20'
                                        expandedContent={(state) => <div className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} key={'expandedListItem' + i} >
                                            <div className='flex aic jcsb' style={{ marginBottom: 10 }} >
                                                <h5>Sections</h5>
                                                <div className='flex' >
                                                    <ToggleField icons={false} style={{ margin: 0, marginRight: 10, width: 75 }} checked={readCount === permissionCount} label="All" onChange={(e) => {
                                                        const eventTeam = this.state.eventTeam;
                                                        const checked = e.target.checked
                                                        Object.keys(defaultPermissions).forEach((key) => {
                                                            eventTeam[i].permissions[key].read = checked;
                                                            if (!checked) eventTeam[i].permissions[key].write = false;

                                                        });
                                                        this.setState({ eventTeam });
                                                    }} />
                                                    <ToggleField icons={false} style={{ margin: 0, marginLeft: 50, marginRight: 10, width: 75 }} checked={writeCount === permissionCount} label="All" onChange={(e) => {
                                                        const eventTeam = this.state.eventTeam;
                                                        Object.keys(defaultPermissions).forEach((key) => {
                                                            eventTeam[i].permissions[key].write = e.target.checked;
                                                            if (e.target.checked) eventTeam[i].permissions[key].read = true;
                                                        });
                                                        this.setState({ eventTeam });
                                                    }} />
                                                </div>
                                            </div>
                                            {Object.keys(defaultPermissions).map((key) => {
                                                const permission = member.permissions[key];

                                                return <div className='flex aic jcsb' style={{ marginBottom: 5 }}>
                                                    <p>{permission.label}</p>
                                                    <div className='flex' >
                                                        <ToggleField icons={false} style={{ margin: 0, marginRight: 10, width: 75 }} checked={permission.read} label="Read" onChange={(e) => {
                                                            const eventTeam = JSON.parse(JSON.stringify(this.state.eventTeam));
                                                            eventTeam[i].permissions[key].read = e.target.checked;
                                                            if (!eventTeam[i].permissions[key].read) {
                                                                eventTeam[i].permissions[key].write = false;
                                                            }
                                                            this.setState({ eventTeam });
                                                        }} />
                                                        <ToggleField icons={false} style={{ margin: 0, marginLeft: 50, marginRight: 10, width: 75 }} checked={permission.write} label="Write" onChange={(e) => {
                                                            const eventTeam = JSON.parse(JSON.stringify(this.state.eventTeam));
                                                            eventTeam[i].permissions[key].write = e.target.checked;
                                                            if (eventTeam[i].permissions[key].write) eventTeam[i].permissions[key].read = true;
                                                            this.setState({ eventTeam });
                                                        }} />
                                                    </div>
                                                </div>
                                            })}

                                        </div>}
                                        render={(state, props) => {

                                            return <div className='cardDetailsContainer w-100 p-10' key={'listItem' + i}>
                                                <div className="profileIconContainer grey mr-10 d25">
                                                    {member.firstName.toUpperCase().charAt(0)}
                                                    {member.lastName.toUpperCase().charAt(0)}
                                                </div>
                                                <div className='w-100 mr-5'>
                                                    <p>{member.firstName} {member.lastName}</p>
                                                </div>
                                                <div className="flex aic" style={{ textAlign: 'right' }}>
                                                    <p className='small noBreak c-pointer'
                                                        style={{
                                                            color: !state.expanded ? 'var(--label)' : 'var(--primary)',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                        onClick={props.handleExpand}>
                                                        {`${role} ${countOf}`} {!state.expanded ? <i className='las la-angle-down ml-5'></i> : <i className='las la-angle-up ml-5'></i>}
                                                    </p>
                                                    <div
                                                        className='c-pointer grey'
                                                        style={{
                                                            marginLeft: 77,
                                                            fontSize: 20,
                                                        }}
                                                        onClick={() => {
                                                            const eventTeam = this.state.eventTeam;
                                                            eventTeam.splice(i, 1);
                                                            this.setState({ eventTeam });
                                                        }}><i class="las la-times-circle"></i></div>
                                                </div>
                                            </div>
                                        }} />
                                })}
                            </div>}


                            <div className='flex flex-wrap'>


                                <ExpandableListItem className='w-100 mb-20'
                                    expandedContent={(state) => <div style={{ padding: 0 }}
                                        className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} key={'expandedListItem'} >

                                        {adminMembers.map((member, i) => {

                                            return <div>
                                                <div style={{
                                                    border: 'unset'
                                                }}
                                                    className='cardDetailsContainer w-100 p-10' key={'listItem' + i}>
                                                    <div className="profileIconContainer grey mr-10 d25">
                                                        {member.firstName.toUpperCase().charAt(0)}
                                                        {member.lastName.toUpperCase().charAt(0)}
                                                    </div>
                                                    <div className='w-100 mr-5'>
                                                        <p>{member.firstName} {member.lastName}</p>
                                                    </div>
                                                    <div className="flex aic w-100 ml-10" >
                                                        <p className='small grey'>Full Access</p>
                                                    </div>
                                                </div>

                                            </div>


                                        })}
                                    </div>}
                                    render={(state, props) => {

                                        let renderedAdmins = [];

                                        for (let i = 0; i < adminMembers.length; i++) {
                                            if (i < 4) {
                                                renderedAdmins.push(adminMembers[i]);
                                            } else {
                                                break;
                                            }
                                        }


                                        return <div
                                            className='cardDetailsContainer w-100 p-10' key={'listItem'}>
                                            <div className='flex aic '>
                                                <div className="flex aic mr-30"

                                                >


                                                    {renderedAdmins.map((member, i) => {
                                                        let acronym = `${member.firstName.charAt(0)}${member.lastName.charAt(0)}`;
                                                        let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
                                                        return (
                                                            emptyProfilePicture ? (
                                                                <div
                                                                    className="add acronymCircle"
                                                                    style={{
                                                                        marginRight: -15,
                                                                        border: '1px solid #D5D5D5'
                                                                    }}
                                                                ><h4>{i == 3 ? `+${adminMembers.length - renderedAdmins.length}` : acronym}</h4></div>
                                                            )
                                                                :
                                                                (<img src={member.profilePicture}
                                                                    className="add acronymCircle profilePicture"
                                                                    style={{
                                                                        marginRight: -15,
                                                                        border: '1px solid #D5D5D5'
                                                                    }} />)
                                                        )

                                                    }
                                                    )}
                                                </div >
                                            </div>
                                            <div className='w-100 mr-5'>
                                                <p className='grey'>Admins</p>
                                            </div>
                                            <div className="flex aic" style={{ textAlign: 'right', marginRight: 103 }}>
                                                <p className='small  c-pointer'
                                                    style={{
                                                        color: !state.expanded ? 'var(--label)' : 'var(--primary)',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                    onClick={props.handleExpand}>

                                                    {`View Admin List`} {!state.expanded ? <i className='las la-angle-down ml-5'></i> : <i className='las la-angle-up ml-5'></i>}</p>                                                </div>
                                        </div>
                                    }} />

                            </div>



                        </div>

                    </div>
                </div>;
        }
    }

    errorCheckerEventAccessSection = () => {
        const { event } = this.state;
        const { enableMeetingPassword, meetingPassword, enableSSOLogin, SSOLoginUrl } = event;

        let valid = true;

        if (enableMeetingPassword) {
            if (meetingPassword === '') {
                valid = false;
            }
        }

        if (enableSSOLogin) {
            if (SSOLoginUrl === '') {
                valid = false;
            }
        }

        this.setState({ nextSectionEnabled: valid });


    }
    nextSection = () => {
        const { currentSection, chosenEvent, event } = this.state;
        const { enableMeetingPassword, enablePassthroughPassword, meetingPassword, enableSSOLogin, SSOLoginUrl } = event

        if (currentSection === 0 && chosenEvent) {
            const eventDetails = {
                experienceType: chosenEvent.experienceType,
                eventType: chosenEvent.eventType,
                eventBranding: {
                    accentColor: chosenEvent.branding.accentColor || '',
                    fontColor: chosenEvent.branding.fontColor || '',
                    backgroundColor: chosenEvent.branding.backgroundColor || '',
                    headerImage: chosenEvent.branding.headerImage || '',
                    logo: chosenEvent.branding.logo || '',
                    favicon: chosenEvent.branding.favicon || '',
                },
                startTime: chosenEvent.startTime,
                endTime: chosenEvent.endTime,
                timezone: chosenEvent.timezone,
                allowPublicRegistrations: chosenEvent.allowPublicRegistrations,
                enableMeetingPassword: chosenEvent.enableMeetingPassword || enableMeetingPassword,
                meetingPassword: chosenEvent.meetingPassword || meetingPassword,
                enableSSOLogin: chosenEvent.enableSSOLogin || enableSSOLogin,
                SSOLoginUrl: chosenEvent.SSOLoginUrl || SSOLoginUrl,
                enablePassthroughPassword: chosenEvent.enablePassthroughPassword || enablePassthroughPassword,
            };
            let eventMembers = chosenEvent.members || [];
            eventMembers = eventMembers.map((member) => {
                let formattedPermissions = {};
                Object.keys(member.permissions).forEach((key) => {
                    if (defaultPermissions[key]) {
                        formattedPermissions[key] = { label: defaultPermissions[key].label, slug: key, write: member.permissions[key].write, read: member.permissions[key].read };
                    }
                }
                );
                member.permissions = formattedPermissions;
                return member;
            });
            let eventTeam = chosenEvent.members || [];
            const eventLocation = this.props.locations.find(v => v.value == chosenEvent.venue);
            this.setState({
                event: { ...this.state.event, ...eventDetails },
                eventBranding: { ...this.state.eventBranding, ...eventDetails.eventBranding },
                currentSection: currentSection + 1,
                selectedVenue: eventLocation || {},
                nextSectionEnabled: false,
                eventTeam: eventTeam
            });
        } else {
            let nextSectionEnabled = false;
            let excludedSections = [2]

            if (excludedSections.includes(currentSection + 1)) {
                nextSectionEnabled = true;
            }
            this.setState({
                currentSection: currentSection + 1,
                nextSectionEnabled: nextSectionEnabled
            });
        }
    }


    prevSection = () => {
        const { currentSection } = this.state;
        this.setState({
            currentSection: currentSection - 1,
            nextSectionEnabled: true
        });
    }


    updateEventField = (field, value) => {
        const { event, requiredFields } = this.state;
        event[field] = value;
        let requiredFieldsAcquired = true;
        Object.keys(requiredFields).forEach((key) => {
            if (!event[key] || event[key] === '') {
                requiredFieldsAcquired = false;
            }
        });
        this.setState({ event, nextSectionEnabled: requiredFieldsAcquired, requiredFields });
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { nextSectionEnabled, currentSection, sectionCount, submitting } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={toggle} className="stepSize eventModal">
                <div className="modalHeader">
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
                    <div className='headerContainer' style={{ position: 'relative' }}>
                        <p className='grey small mt-10' style={{ position: 'absolute', right: 0 }}>Step {currentSection + 1} of {sectionCount}</p>
                    </div>
                </div>
                <ModalBody className="modalBody">
                    {this.renderSection(0)}
                    {this.renderSection(1)}
                    {this.renderSection(2)}
                    {this.renderSection(3)}
                </ModalBody>



                <div className='flex'>
                    {currentSection == 0 ? <button className="neu ml-a mr-15" onClick={toggle}>Cancel</button> : <button className="neu ml-a mr-15" onClick={this.prevSection}>Back</button>}
                    {currentSection < sectionCount - 1 ? <button className={`neu ${nextSectionEnabled ? 'prim' : 'disabled'}`}
                        onClick={this.nextSection}>Next</button> : <button className={`neu ${!submitting ? 'prim' : 'disabled'}`} onClick={this.createEvent}>{submitting ? "Creating..." : "Create Event"}</button>}

                </div>
            </Modal>
        );
    }
}

export default DuplicateEventModal;
