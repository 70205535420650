import React from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class RenameTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.template.name ? this.props.template.name : '',
			submitting: false,
			requiredFields: { name: false }
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	updateName = async () => {
		this.setState({ submitting: true });
		const { name } = this.state;
		const { eventId, orgId, template, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		if (!name) {
			requiredFields.name = true;
			valid = false;
		}

		if (valid) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/template/${template._id}`, {
					name: name
				})
				.then((res) => {
					if (res.data) {
						update(
							{
								name: name
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, requiredFields } = this.state;

		return (

			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Rename Template"}
					bodyHeaderText={'Enter a new name for your template.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Name"
									value={name}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateName()}
					actionButtonDisabled={submitting}


				></FieldModal>



			</div>

		);
	}
}

export default RenameTemplateModal;
