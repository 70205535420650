import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import InputField from '../../components/inputs/inputField';
class ProfileInformation extends Component {
	render() {
		const { editedMember, requiredFields } = this.props;
		return (
			<div style={{ padding: 5 }}>
				<div className="sectionBlock">
					<h2 className="mb-15 ">Personal Information</h2>
					<InputField
						label="First Name"
						value={editedMember.firstName}
						required={true}
						errorTrigger={requiredFields.firstName}
						type="text"
						onChange={(e) => this.props.editMemberInformation('firstName', e.target.value)}
					/>
					<InputField
						label="Last Name"
						value={editedMember.lastName}
						type="text"
						onChange={(e) => this.props.editMemberInformation('lastName', e.target.value)}
					/>
					<InputField
						label="Email Address"
						value={editedMember.emailAddress}
						type="text"
						disabled={true}
						inputIcon={<i className="las la-lock" />}
					/>
					<InputField
						label="Phone Number"
						value={editedMember.phoneNumber}
						type="text"
						onChange={(e) => this.props.editMemberInformation('phoneNumber', e.target.value)}
					/>
					<InputField
						label="Role"
						value={editedMember.permission}
						type="text"
						disabled={true}
						inputIcon={<i className="las la-lock" />}
					/>
					{/* <div className="formField">
					<label className="displayLabel">Profile Picture</label>
					<input
						className={`editingField`}
						value={editedMember.profilePicture}
						type="text"
						onChange={(e) => this.props.editMemberInformation('profilePicture', e.target.value)}
					/>
				</div> */}
				</div>
			</div>
		);
	}
}

export default ProfileInformation;
