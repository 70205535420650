// import external modules
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { setCookie, removeCookie } from '../../utils/cookie';
import API from '../../utils/API';
import { LogIn, Edit, Edit2, Send, Mail, Phone, AlertTriangle, CheckCircle, X } from 'react-feather';
import { Row, Col, Input, Form, FormGroup, Button, Label, Card, CardBody, CardFooter } from 'reactstrap';

import background from '../../assets/img/backgrounds/AuthBackground.jpg';

class Register extends Component {
	state = {
		firstName: '',
		lastName: '',
		company: '',
		email: '',
		password: '',
		error: '',
		isChecked: false
	};
	handleChecked = (e) => {
		this.setState((prevState) => ({
			isChecked: !prevState.isChecked
		}));
	};

	submit = (e) => {
		e.preventDefault();
		e.persist();

		const data = {
			emailAddress: this.state.email,
			password: this.state.password
		};
		console.log(data);
		API()
			.post(`/auth/login`, data)
			.then((res) => {
				console.log(res);
				setCookie('authToken', res.data.token);
				setCookie('refreshToken', res.data.refreshToken);
				localStorage.setItem('firstName', res.data.firstName);
				localStorage.setItem('lastName', res.data.lastName);

				API().get('/Organizations').then((res) => {
					if (res.data) {
						localStorage.setItem('Organizations', JSON.stringify(res.data));
						localStorage.setItem('selectedOrganization', 0);
						window.location.href = '/';
					}
				});
			})
			.catch((err) => {
				console.error(err);
				this.setState({ error: 'Email/Password invalid' });
			});
	};

	render() {
		return (
			<div
				style={{
					padding: 15,
					paddingTop: 0,
					backgroundImage: `url(${background})`,
					backgroundSize: 'cover',
					height: '100vh'
				}}
			>
				<Row
					id="landing-navbar"
					style={{
						padding: '0px 50px',
						paddingTop: 20,
						backgroundColor: 'white',
						boxShadow: 'rgb(0, 0, 0) 0px -10px 20px'
					}}
				>
					<Col xs={6}>
						<img
							src={require('../../assets/img/logos/logo.png')}
							style={{ height: 25, marginTop: 5 }}
							onClick={() => (window.location.href = './')}
						/>
					</Col>
					<Col xs={6} style={{ textAlign: 'right' }}>
						<Button
							title="login"
							title="login"
							outline
							style={{ minWidth: 200, color: '#2683f2', borderColor: '#2683f2' }}
							onClick={() => (window.location.href = 'login')}
						>
							<LogIn size={15} /> <strong>Log In</strong>
						</Button>
					</Col>
				</Row>
				<Row style={{ marginTop: 100 }}>
					<Col xs="12" className="d-flex align-items-center justify-content-center">
						<Card
							className="white text-center width-600"
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.3) 5px 5px 10px'
							}}
						>
							<CardBody style={{ padding: 20, paddingTop: 50, paddingBottom: 50 }}>
								<h2 className="black" style={{ fontWeight: 700 }}>
									Sign Up!
								</h2>
								<hr />
								<Form className="pt-2">
									<FormGroup>
										<Row>
											<Col xs="4">
												<p
													style={{
														color: 'gray',
														margin: 0,
														bottom: 1,
														position: 'absolute',
														fontSize: '16px'
													}}
												>
													First Name
												</p>
											</Col>
											<Col xs="8">
												<Input
													style={{
														border: 0,
														borderBottom: '1px solid black',
														borderRadius: 0,
														paddingBottom: 0
													}}
													type="text"
													className="form-control"
													name="firstName"
													id="firstName"
													placeholder="First Name"
													onChange={(e) => {
														this.setState({ firstName: e.currentTarget.value });
													}}
													required
												/>
											</Col>
										</Row>
									</FormGroup>

									<FormGroup>
										<Row>
											<Col xs="4">
												<p
													style={{
														color: 'gray',
														margin: 0,
														bottom: 1,
														position: 'absolute',
														fontSize: '16px'
													}}
												>
													Last Name
												</p>
											</Col>
											<Col xs="8">
												<Input
													style={{
														border: 0,
														borderBottom: '1px solid black',
														borderRadius: 0,
														paddingBottom: 0
													}}
													type="text"
													className="form-control"
													name="lastName"
													id="lastName"
													placeholder="Last Name"
													onChange={(e) => {
														this.setState({ lastName: e.currentTarget.value });
													}}
													required
												/>
											</Col>
										</Row>
									</FormGroup>

									<FormGroup>
										<Row>
											<Col xs="4">
												<p
													style={{
														color: 'gray',
														margin: 0,
														bottom: 1,
														position: 'absolute',
														fontSize: '16px'
													}}
												>
													Company
												</p>
											</Col>
											<Col xs="8">
												<Input
													style={{
														border: 0,
														borderBottom: '1px solid black',
														borderRadius: 0,
														paddingBottom: 0
													}}
													type="text"
													className="form-control"
													name="company"
													id="company"
													placeholder="Company"
													onChange={(e) => {
														this.setState({ email: e.currentTarget.value });
													}}
													required
												/>
											</Col>
										</Row>
									</FormGroup>

									<FormGroup>
										<Row>
											<Col xs="4">
												<p
													style={{
														color: 'gray',
														margin: 0,
														bottom: 1,
														position: 'absolute',
														fontSize: '16px'
													}}
												>
													Account Email
												</p>
											</Col>
											<Col xs="8">
												<Input
													style={{
														border: 0,
														borderBottom: '1px solid black',
														borderRadius: 0,
														paddingBottom: 0
													}}
													type="email"
													className="form-control"
													name="inputEmail"
													id="inputEmail"
													placeholder="Email"
													onChange={(e) => {
														this.setState({ email: e.currentTarget.value });
													}}
													required
												/>
											</Col>
										</Row>
									</FormGroup>

									<FormGroup>
										<Row>
											<Col xs="4">
												<p
													style={{
														color: 'gray',
														margin: 0,
														bottom: 1,
														position: 'absolute',
														fontSize: '16px'
													}}
												>
													Password
												</p>
											</Col>
											<Col xs="8">
												<Input
													style={{
														border: 0,
														borderBottom: '1px solid black',
														borderRadius: 0,
														paddingBottom: 0
													}}
													type="password"
													className="form-control"
													name="inputPass"
													id="inputPass"
													placeholder="Password"
													onChange={(e) => {
														this.setState({ password: e.currentTarget.value });
													}}
													required
												/>
											</Col>
										</Row>
									</FormGroup>

									<FormGroup>
										<Row>
											<Col xs="4" />
											<Col xs="8">
												<div className="custom-control custom-checkbox mb-2  mb-sm-0 ">
													<Input
														type="checkbox"
														className="custom-control-input"
														checked={this.state.isChecked}
														onChange={this.handleChecked}
														id="agreeTC"
													/>
													<Label
														className="custom-control-label float-left black"
														for="agreeTC"
													>
														I agree to the <a>terms & conditions</a>
													</Label>
												</div>
												{this.state.error && (
													<span style={{ color: 'red', float: 'left' }}>
														{this.state.error}
													</span>
												)}
											</Col>
										</Row>
									</FormGroup>
								</Form>
							</CardBody>
							<CardFooter style={{ backgroundColor: '#2683f2', padding: 30 }}>
								<div className="float-left">
									<Button
										style={{
											minWidth: 200,
											margin: 0,
											color: '#2683f2',
											backgroundColor: 'white',
											boxShadow: 'rgba(0, 0, 0, 0.3) 5px 5px 5px'
										}}
										type="submit"
										block
										className="btn-raised"
										onClick={this.submit}
									>
										<CheckCircle size={15} style={{ marginRight: 5 }} />
										<strong>Submit</strong>
									</Button>
								</div>
								<div className="float-right">
									<Button
										type="submit"
										block
										outline
										onClick={() => (window.location.href = '/')}
										style={{ minWidth: 200, margin: 0 }}
										color="white"
									>
										<X size={15} style={{ marginRight: 5 }} />
										<strong>Cancel</strong>
									</Button>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default Register;
