import React, { Fragment } from 'react';
import moment from 'moment';
import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import InputField from '../../../../components/inputs/inputField';
import TimeField from '../../../../components/inputs/timeField';
import SelectField from '../../../../components/inputs/selectField';
import ViewsTable from '../../../../components/tables/ViewsTable';
import momenttz from 'moment-timezone';

class ScheduleEmailModal extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email'
		};
		const columnTypes = {
			firstName: 'text',
			lastName: 'text',
			email: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			emailName: '',
			date: '',
			time: '',
			subject: '',
			groups: this.props.groups.map((group) => {
				return { label: group.name, value: group._id };
			}),
			sessions: this.props.sessions.map((session) => {
				return { label: session.name, value: session._id };
			}),
			group: { label: '', value: '' },
			session: { label: '', value: '' },
			type: { label: '', value: '' },
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			},
			checkedAttendees: [],
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns
		};
	}

	handleEmailNameChange = (val) => {
		this.setState({
			emailName: val,
			editing: true
		});
	};

	handleSubjectChange = (val) => {
		this.setState({
			subject: val,
			editing: true
		});
	};

	handleDateChange = (val) => {
		this.setState({
			date: val,
			editing: true
		});
	};
	handleTimeChange = (val) => {
		this.setState({
			time: val,
			editing: true
		});
	};

	handleTypeChange = (val) => {
		this.setState({ editing: true, type: val });
	};
	handleGroupChange = (val) => {
		this.setState({ editing: true, group: val });
	};
	handleSessionChange = (val) => {
		this.setState({ editing: true, session: val });
	};

	addEmail = async (email) => {
		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (this.state.emailName === '') {
			requiredFields.name = true;
			valid = false;
		} else {
			requiredFields.name = false;
		}

		if (valid) {
			this.props.addNewEmail(email, (id) => {
				window.open(`/${this.props.orgId}/events/${this.props.eventId}/emails/${id}`, '_self');
			});
			this.setState({ invalid: false });
		} else {
			this.setState({ invalid: true });
		}
	};

	scheduleEmail = async () => {
		const { orgId, event } = this.props;
		const { date, time, type, group, session, checkedAttendees } = this.state;
		const emailId = this.props.email._id;

		const currentTimeTemp = momenttz().tz(event.timezone);
		const timeScheduleTemp = momenttz(`${date} ${time}`);

		const isAfter =
			new Date(`${timeScheduleTemp.format('L')} ${timeScheduleTemp.format('LT')}`) >=
			new Date(`${currentTimeTemp.format('L')} ${currentTimeTemp.format('LT')}`);

		//TODO add is after check on all the emails schedules deloyment
		if (isAfter) {
			await Scheduler()
				.post(`Organizations/${orgId}/email/${emailId}/scheduleEmail`, {
					date,
					time,
					timezone: event.timezone,
					type: type.value,
					recipients:
						type.value == 'list'
							? Object.keys(checkedAttendees)
							: [type.value == 'session' ? session.value : group.value]
				})
				.then((res) => {
					console.log(res);
					this.props.addSchedule(res.data);
					this.props.toggle();
				});
		} else {
			console.log('It is in the past', event.timezone);
		}
	};
	updatedCheckedAttendees = (checked) => {
		this.setState({ checkedAttendees: checked });
	};

	render() {
		const { isOpen, attendees } = this.props;
		const {
			emailName,
			submitting,
			invalid,
			subject,
			requiredFields,
			date,
			time,
			type,
			group,
			session,
			checkedAttendees,
			normalizedColumns,
			columns,
			columnTypes,
			toggledColumns
		} = this.state;
		const fixedStartDate = moment(`${date}`).format('YYYY-MM-DD');

		return (
			<Modal isOpen={isOpen} toggle={this.resetAdd} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="calibreBold fs-30">Schedule Email</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<InputField
						label={'Date'}
						value={fixedStartDate}
						type={'date'}
						inputIcon={<i className="lar la-calendar" />}
						onChange={(e) => {
							this.handleDateChange(moment(e.target.value).format('MM/DD/YYYY'));
						}}
					/>
					<TimeField
						label={'Send Time'}
						value={time}
						onChange={(e) => {
							this.handleTimeChange(e);
						}}
					/>
					<SelectField
						label="Type"
						value={type}
						options={[
							{ label: 'Session', value: 'session' },
							{ label: 'Group', value: 'group' },
							{ label: 'list', value: 'list' }
						]}
						onChange={(e) => {
							this.handleTypeChange(e);
						}}
					/>

					{type.value == 'list' ? (
						<ViewsTable
							style={{ margin: 0 }}
							columns={normalizedColumns}
							toggledColumns={toggledColumns}
							data={attendees}
							mainColumnActive={false}
							checked={this.state.checkedAttendees}
							checkRow={(entryId) => {
								const checked = this.state.checkedAttendees;
								checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
								this.updatedCheckedAttendees(checked);
								this.setState({
									checkedAttendees: checked
								});
							}}
							mainActions={[]}
							checkAllRows={(entryIds) => {
								let checked = this.state.checkedAttendees;
								entryIds.length > 0
									? entryIds.forEach((entryId) => {
										checked[entryId] = true;
									})
									: (checked = {});
								this.updatedCheckedAttendees(checked);

								this.setState({
									checkedAttendees: checked
								});
							}}
							sortData={(column, sortAsc, sortDesc) => {
								/* const sortedData = this.sortData(
									column,
									sortAsc,
									sortDesc,
									editedGroup.sessions,
									normalizedColumns
								);
								this.setState({ editedGroup: { ...editedGroup, sessions: sortedData.organizedEntries } }); */
							}}
						/>
					) : (
						<SelectField
							label="val"
							value={type.value == 'session' ? session : group}
							options={type.value == 'session' ? this.state.sessions : this.state.groups}
							onChange={(e) => {
								if (type.value == 'session') {
									this.handleSessionChange(e);
								} else {
									this.handleGroupChange(e);
								}
							}}
						/>
					)}
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionSave m-0"
						disabled={submitting}
						onClick={() => {
							this.scheduleEmail();
						}}
					>
						<div className="modalButtonText">{!submitting ? 'Submit' : 'Adding Email...'}</div>
					</Button>
					<Button
						onClick={this.props.toggle}
						outline
						className="modalButton actionButton modalCancel mb-0 ml-a"
					>
						<div className="modalCancelText">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ScheduleEmailModal;
