import React from 'react';
import { Table } from 'reactstrap';
import { Trash2, Plus, X } from 'react-feather';
import AddAttendeesModal from './modals/addAttendeesModal';
import RemoveAttendeeModal from './modals/removeAttendeeModal';
import ExcludeAttendeesModal from './modals/excludeAttendeesModal';
import IncludeAttendeesModal from './modals/includeAttendeesModal';

import moment from 'moment';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';

import ViewsTable from '../../../components/tables/ViewsTable';

class EventSessionAttendees extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email',
			checkedIn: 'Check In',
			checkedInTime: 'Checked In Time',
			status: 'Status'
		};
		const columnTypes = {
			firstName: 'text',
			lastName: 'text',
			email: 'text',
			checkedIn: 'text',
			checkedInTime: 'timestamp',
			status: 'text'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			excludeAttendeeModalOpen: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			includeAttendeeModalOpen: false,
			markedAttendee: null,
			checkedAttendees: [],
			searchText: '',
			searching: false,
			addAttendeeToggle: false,
			excludeAttendeeToggle: false,
			deleteAttendeeToggle: false,
			includeAttendeeToggle: false,
			sortDesc: false,
			selectedColumnSort: null
		};
	}

	AttendeeItem(attendee, index) {
		const { readOnly, editedSession } = this.props;
		const excludedSessions = editedSession.excludedSessions;
		const excluded = excludedSessions ? excludedSessions.includes(this.props.editedSession._id) : false;
		let inGroup = false;
		const checkin = editedSession.checkins.find((checkin) => checkin.attendeeId.toString() == attendee._id);
		this.props.editedSession.groups.forEach((g) => {
			if (g.attendees.includes(attendee._id)) {
				inGroup = true;
				return;
			}
		});
		return (
			<tr key={index}>
				<td className="text-truncate">{attendee.firstName}</td>
				<td className="text-truncate">{attendee.lastName}</td>
				<td className="text-truncate">{attendee.email}</td>
				<td className="text-truncate">{checkin ? 'Checked In' : ''}</td>
				<td className="text-truncate">
					{checkin ? moment(checkin.checkinDate).format('MM-DD-YYYY hh:mm:ss a') : ''}
				</td>

				<td className="text-truncate" style={{ color: excluded ? 'red' : 'green', width: '150px' }}>
					{excluded ? 'Excluded' : 'Attending'}
				</td>
				<td style={{ width: '100px' }}>
					{inGroup ? (
						<span>
							{excluded ? (
								<a
									style={{ color: 'green' }}
									onClick={() => this.toggleIncludeAttendee(attendee)}
									className={`${readOnly ? 'disabled-link' : ''}`}
								>
									Include
								</a>
							) : (
								<a
									style={{ color: 'red' }}
									onClick={() => this.toggleExcludeAttendee(attendee)}
									className={`${readOnly ? 'disabled-link' : ''}`}
								>
									Exclude
								</a>
							)}
						</span>
					) : (
						<a
							style={{ color: 'red' }}
							onClick={() => this.toggleRemoveAttendee(attendee)}
							className={`${readOnly ? 'disabled-link' : ''}`}
						>
							Remove
						</a>
					)}
				</td>
			</tr>
		);
	}

	toggleAddAttendees = () => {
		this.setState({ addAttendeeToggle: !this.state.addAttendeeToggle });
	};
	toggleDeleteAttendees = () => {
		this.setState({ deleteAttendeeToggle: !this.state.deleteAttendeeToggle });
	};

	toggleExcludeAttendees = () => {
		this.setState({ excludeAttendeeToggle: !this.state.excludeAttendeeToggle });
	};

	toggleIncludeAttendees = () => {
		this.setState({ includeAttendeeToggle: !this.state.includeAttendeeToggle });
	};

	toggleRemoveAttendee = (attendee) => {
		this.setState({
			removeAttendeeModalOpen: !this.state.removeAttendeeModalOpen,
			markedAttendee: attendee || null
		});
	};

	toggleExcludeAttendee = (attendee) => {
		this.setState({
			excludeAttendeeModalOpen: !this.state.excludeAttendeeModalOpen,
			markedAttendee: attendee || null
		});
	};

	toggleIncludeAttendee = (attendee) => {
		this.setState({
			includeAttendeeModalOpen: !this.state.includeAttendeeModalOpen,
			markedAttendee: attendee || null
		});
	};

	updatedCheckedAttendees = (checked) => {
		this.setState({ checkedAttendees: checked });
	};

	searchEntries = (searchText) => {
		this.setState({ searching: searchText != '' ? true : false, searchText: searchText ? searchText : '' });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const {
			markedAttendee,
			normalizedColumns,
			toggledColumns,
			columns,
			searchText,
			searching,
			addAttendeeToggle,
			checkedAttendees,
			deleteAttendeeToggle,
			excludeAttendeeToggle,
			includeAttendeeToggle,
			selectedColumnSort,
			sortDesc
		} = this.state;
		const { readOnly, mainActions, editedSession, event } = this.props;
		const attendees = this.props.editedSession.isGeneral
			? this.props.event.attendees
			: this.props.editedSession.attendees;

		let searchedEntries = attendees;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		searchedEntries = searchedEntries.map((att) => {
			const checkin = editedSession.checkins.find((checkin) => checkin.attendeeId.toString() == att._id);

			const excludedSessions = att.excludedSessions;
			const excluded = excludedSessions ? excludedSessions.includes(editedSession._id) : false;

			return {
				_id: att._id,
				firstName: att.firstName,
				lastName: att.lastName,
				email: att.email,
				checkedIn: checkin ? 'Checked In' : 'Not Checked In',
				checkedInTime: checkin ? checkin.checkinDate : '',
				status: excluded ? 'Excluded' : 'Included'
			};
		});

		if (selectedColumnSort) {
			searchedEntries = searchedEntries.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
		}

		let mainAction = [];

		mainAction = [{ onClick: this.toggleAddAttendees, label: 'Add Attendee' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Include Attendees',
				onClick: this.toggleIncludeAttendees,
				iconClass: 'las la-trash-alt',

			},
			{
				type: 'button',
				label: 'Exclude Attendees',
				onClick: this.toggleExcludeAttendees,
				iconClass: 'las la-trash-alt',

			},
			{
				type: 'button',
				label: 'Delete Attendees',
				onClick: this.toggleDeleteAttendees,
				iconClass: 'las la-trash-alt',
				class: 'danger'
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedAttendees).length;
		entriesLength = searching ? searchedEntries.length : attendees.length;
		uncheckEntries = () => {
			this.setState({ checkedAttendees: [] });
		};
		searchEnabled = true;


		return (
			< div className='sectionBody posRel mb-0 p-0' >
				{/* <Table responsive className="innerSectionTable">
					<thead>
						<tr>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>Checked In</th>
							<th>Checked In Time</th>
							<th style={{ width: '150px' }}>Status</th>
							<th style={{ width: '100px' }}>
								<Plus
									size={14}
									color="blue"
									onClick={this.toggleAddAttendees}
									className={`clickable ${readOnly ? 'disabled-link' : ''}`}
								/>
							</th>
						</tr>
					</thead>
					<tbody style={{}}>{attendees.map((attendee, index) => this.AttendeeItem(attendee, index))}</tbody>


				</Table> */}

				<ActionButtonContainer
					mainActions={mainAction}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>
				<ViewsTable
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={searchedEntries}
					mainColumnActive={false}
					classes="nested"
					checked={this.state.checkedAttendees}
					checkRow={(entryId) => {
						const checked = this.state.checkedAttendees;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedAttendees(checked);
						this.setState({
							checkedAttendees: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedAttendees;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedAttendees(checked);

						this.setState({
							checkedAttendees: checked
						});
					}}
					sortData={this.sortData}
				/>

				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
				/>


				{addAttendeeToggle && (
					<AddAttendeesModal
						isOpen={addAttendeeToggle}
						toggle={this.toggleAddAttendees}
						orgId={this.props.orgId}
						updateAttendees={this.props.updateAttendees}
						loadMoreAttendees={this.props.loadMoreAttendees}
						eventId={this.props.event._id}
						existingAttendees={attendees}
						attendees={this.props.event.attendees}
						session={this.props.editedSession}
						checkedAttendees={Object.keys(checkedAttendees)}
					/>
				)}
				{deleteAttendeeToggle && (
					<RemoveAttendeeModal
						isOpen={deleteAttendeeToggle}
						toggle={this.toggleDeleteAttendees}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						markedAttendee={markedAttendee}
						attendees={attendees}
						updateAttendees={this.props.updateAttendees}
						session={this.props.editedSession}
						checkedAttendees={Object.keys(checkedAttendees)}
						uncheckEntries={uncheckEntries}
					/>
				)}

				{excludeAttendeeToggle && (
					<ExcludeAttendeesModal
						isOpen={excludeAttendeeToggle}
						toggle={this.toggleExcludeAttendees}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						checkedAttendees={Object.keys(checkedAttendees)}
						attendees={attendees}
						updateAttendees={this.props.updateAttendees}
						session={this.props.editedSession}
						excludeUpdateAttendees={(att, sid) => {
							let attTemp = attendees;
							attTemp.map(a => {
								if (att.includes(a._id)) {
									if (a.excludedSessions.includes(sid))
										a.excludedSessions = [...a.excludedSessions, sid]
								}

								return a;
							})
							uncheckEntries();

							this.props.excludeUpdateAttendees(att, sid);
							this.props.updateAttendees(attTemp)
						}}
					/>
				)}

				{includeAttendeeToggle && (
					<IncludeAttendeesModal
						isOpen={includeAttendeeToggle}
						toggle={this.toggleIncludeAttendees}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						checkedAttendees={Object.keys(checkedAttendees)}
						attendees={attendees}
						updateAttendees={(atts, callback) => {
							//array of attendee id
							let attTemp = attendees;
							attTemp.map((a) => {
								if (atts.includes(a._id)) {

									a.excludedSessions = a.excludedSessions.filter(el => el !== this.props.editedSession._id)
								}
								return a;

							})
							uncheckEntries();
							this.props.updateAttendees(attTemp)

							this.toggleIncludeAttendees();
						}}
						session={this.props.editedSession}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionAttendees;
