import React, { Component } from 'react';
import Toggle from 'react-toggle';

class ToggleField extends Component {
	render() {
		const {
			onChange,
			label,
			errorTrigger,
			errorMessage,
			disabled,
			style,
			inputStyle,
			checked,
			icons,
			isBold,
			className
		} = this.props;
		return (
			<div className={`formFieldContainer ${className ? className : ''}`} style={style}>
				<div className="toggleField">
					{label && <div className="labelContainer flex column">
						<div className={`toggleLabel mr-10`}>{label}</div>
						{errorTrigger && <div className="formFieldLabel mt-5 error">{errorMessage} </div>}
					</div>}
					<Toggle
						className='ml-a'
						style={inputStyle}
						checked={checked}
						icons={icons}
						disabled={disabled}
						onChange={onChange}
					/>
				</div>
			</div>
		);
	}
}

export default ToggleField;
