import React, { Component } from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

class RemoveFilesModal extends Component {
	state = {
		submitting: false
	};

	removeFiles = () => {
		const { orgId, eventId } = this.props;
		this.setState({ submitting: true })
		API()
			.post(`Organizations/${orgId}/events/${eventId}/removeAssets`, { assets: this.props.files })
			.then((res) => {
				if (res.data) {
					this.props.removeFiles();
					this.props.toggle();
				}
			});
	};

	render() {
		const { isOpen, files, toggle } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete ${files.length > 1 ? 'Files' : 'File'}`}
					bodyHeaderText={<span>You are about to delete {files.length} {files.length > 1 ? 'files' : 'file'}, this is
						irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => (this.removeFiles())}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default RemoveFilesModal;
