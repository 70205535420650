import React from "react";

const LinkCopy = ({ link }) => {

    const [copiedPassthrough, setCopiedPassthrough] = React.useState(false);

    return <div className="toggleField">
        <a rel='noreferrer' href={link} target="_blank" className="se-blue">
            {link}
        </a>

        <button
            className="prim"
            style={{ minWidth: 80 }}
            onClick={async () => {
                navigator.clipboard.writeText(link).then(
                    () => {
                        /* clipboard successfully set */
                        setCopiedPassthrough(true);
                        setTimeout(() => setCopiedPassthrough(false), 1000);
                    },
                    () => {
                        /* clipboard write failed */
                        document.execCommand('copy', true, link);
                        setCopiedPassthrough(true);
                        setTimeout(() => setCopiedPassthrough(false), 1000);
                    }
                );
            }}
        >
            <i className="las la-copy mr-10" />
            {copiedPassthrough ? 'Copied!' : 'Copy'}
        </button>
    </div>
}

export default LinkCopy;