import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class TopLevelNavLink2 extends React.Component {
	state = {
		popoverOpen: false
	};

	render() {
		const { menuItem, index, orgId, prefix, isLast, loading, className } = this.props;
		const path = this.context.router.route.location.pathname.toLowerCase();
		let isActive = path.includes(menuItem.section.toLowerCase());
		//with commas
		const countText = menuItem.count && menuItem.count > 0 ? `${menuItem.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			}` : null;
		return (
			<Link
				to={!prefix ? `/${orgId}/${menuItem.section}` : `${prefix}/${menuItem.section}`}
				className={`menuItem  ${isActive ? 'selected' : ''} ${className ? className : ''}`}
				key={`menuItem-${index}`}
				style={this.props.style}
			>
				{menuItem.icon && <div className={`sq icon w30 mr-15 ${isActive ? 'inset activated' : ''}`}>{menuItem.icon} </div>}
				<div className='menuItemLabel flex'>
					{isActive ? <h3>{menuItem.name}</h3> : <h4>{menuItem.name}</h4>}
				</div>
				{countText && <p className='small gray ml-a' style={{ fontSize: 10 }} >{countText}</p>}
			</Link>
		);
	}
}

TopLevelNavLink2.contextTypes = {
	router: PropTypes.object
};

export default TopLevelNavLink2;
