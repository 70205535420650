import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

class DeleteTemplatesModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
		};

	}


	deleteTemplates = async () => {
		const { orgId, eventId, deleteCheckedTemplates, toggle, checkedTemplates } = this.props;

		const templatesIds = Object.keys(checkedTemplates);

		try {
			this.setState({ submitting: true });
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/templates/`, { templates: templatesIds })
				.then((res) => {
					deleteCheckedTemplates(() => {
						toggle();
					});
				});
		} catch (e) {
			console.log(e);
		}
	};
	render() {
		const { isOpen, toggle, checkedTemplates } = this.props;
		const { submitting } = this.state;

		let templatesCount = Object.keys(checkedTemplates).length;

		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete Template${templatesCount > 1 ? 's' : ''}`}
					bodyHeaderText={<span>You are about to delete <b>{templatesCount}</b> template{templatesCount > 1 ? 's' : ''} from the
						event, this is irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting'}
					actionButton={() => (this.deleteTemplates())}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default withRouter(DeleteTemplatesModal);
