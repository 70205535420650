import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteEmailModal extends React.Component {
	state = {
		submitting: false
	};
	deleteCampaigns = async () => {
		const { checkedCampaigns, orgId, eventId, deleteCheckedCampaigns, toggle } = this.props;
		this.setState({ submitting: true })
		const campaignsIds = Object.keys(checkedCampaigns);


		try {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/emails/`, { emails: campaignsIds })
				.then((res) => {
					deleteCheckedCampaigns(() => {
						toggle();
					});
				});
		} catch (e) {
			console.log(e);

		}
	};
	render() {
		const { isOpen, toggle, checkedCampaigns } = this.props;
		const { submitting } = this.state;

		let campaignCount = Object.keys(checkedCampaigns).length;

		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete Email${campaignCount > 1 ? 's' : ''}`}
					bodyHeaderText={<span>You are about to delete <b>{campaignCount}</b> email{campaignCount > 1 ? 's' : ''} from the
						event, this is irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return (
							'')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.deleteCampaigns()}
					isDelete={true}
					actionButtonDisabled={submitting}

				></FieldModal>


			</div>

		);
	}
}

export default withRouter(DeleteEmailModal);
