import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import EventAttendeeInfo from './info';
import EventAttendeeGroups from './groups';
import EventAttendeeScheduledSessions from './scheduledSessions';
import EventAttendeeTravel from './travel';
import API from '../../../utils/API';
import DeleteAttendeeModal from './modals/deleteAttendeeModal';
import EventAttendeeRooming from './rooming';
import EventAttendeePoints from './points';

import TabsContainer from '../../../components/views/TabsContainer';


import LoadingWizard from '../../../components/spinner/wizard';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import EventAttendeeIntegrations from './integrations';

import ImageCropper from '../../../components/modals/imageCropper';

class EventAttendee extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const attendee = props.attendees.find((a) => a._id === params.attendeeId);
		this.fileInput = null;
		this.state = {
			attendee: attendee,
			editedAttendee: attendee,
			customFields: {},
			selectedSection: 'profile',
			DeleteAttendeeModalOpen: false,
			loading: true,
			editing: false,
			agendaDates: {},
			addSessionToggle: false,
			addGroupToggle: false,
			addPointsToggle: false,
			showRemoveFromGroupModal: false,
			checkedAttendeesFromGroups: [],
			requiredFields: {
				firstName: false,
				email: false,
				bcc: false
			},
			selectedFile: null,
			toggleImageCropper: false
		};
	}
	ValidateEmail(mail) {
		var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (mail.match(mailformat)) {
			return true;
		} else {
			return false;
		}
	}

	componentDidMount() {
		const { match: { params } } = this.props;
		const { attendee, editedAttendee } = this.state;
		this.setState({ loading: true });
		const { eventId, orgId } = this.props;
		this.props.setActiveRow(params.attendeeId)
		API().get(`Organizations/${orgId}/events/${eventId}/attendees/${params.attendeeId}`).then((res) => {
			if (res.data) {
				const resAttendee = { ...editedAttendee, ...res.data };
				//array to string
				if (resAttendee.bcc && resAttendee.bcc.length > 0) {
					resAttendee.bcc = resAttendee.bcc.join(', ');
				}


				const fields = {};
				this.props.customFields.forEach((category) => {
					fields[category._id] = { title: category.title, expanded: true };
					category.fields.forEach((field) => {
						fields[category._id][field._id] = { ...field };
					});
				});
				resAttendee.metadata.forEach((meta) => {
					try {
						fields[meta.categoryId][meta.fieldId].value = meta.fieldValue;
					} catch (e) {
						console.log(e);
					}
				});
				this.setState(
					{
						attendee: { ...attendee, ...res.data },
						editedAttendee: { ...editedAttendee, ...res.data },
						customFields: fields,
						loading: false
					},
					() => {
						this.fix();
					}
				);
			}
		});
	}

	componentWillUnmount() {
		this.props.setActiveRow(null);
	}

	fix = () => {
		const agendaDates = {};
		var sessionsList = [];
		sessionsList = this.state.editedAttendee.sessions;

		this.props.event.sessions.forEach((session) => {
			if (session.isGeneral) {
				if (!sessionsList.find((s) => session._id == s._id)) {
					sessionsList.push(session);
				}
			}
		});

		sessionsList.sort((a, b) => {
			if (a.date > b.date) {
				return 1;
			}
			if (a.date < b.date) {
				return -1;
			}
			return 0;
		});

		sessionsList.forEach((session) => {
			const date = moment(session.date);
			session.inGroup = false;

			if (this.state.editedAttendee.excludedSessions.find((ex) => ex._id == session._id)) {
				session.excluded = true;
			} else {
				session.excluded = false;
			}

			this.state.editedAttendee.groups.forEach((g) => {
				if (g.sessions.includes(session._id)) {
					session.inGroup = true;
				}
			});

			const formattedDate = date.format('MMMM D, YYYY');
			if (agendaDates[formattedDate]) {
				var exists = false;
				agendaDates[formattedDate].forEach((s) => {
					if (s._id == session._id) {
						exists = true;
					}
				});
				if (!exists) {
					agendaDates[formattedDate].push(session);
				}
			} else {
				agendaDates[formattedDate] = [session];
			}
		});
		this.setState({ agendaDates: agendaDates });
	};

	refreshAttendee = () => {
		const { attendee, editedAttendee } = this.state;
		const { eventId, orgId } = this.props;
		API().get(`Organizations/${orgId}/events/${eventId}/attendees/${editedAttendee._id}`).then((res) => {
			if (res.data) {
				this.setState({
					attendee: { ...attendee, ...res.data },
					editedAttendee: { ...editedAttendee, ...res.data }
				});
			}
		});
	};

	deleteAttendeePoints = async (pointIds, callback) => {
		const { attendee, editedAttendee } = this.state;
		const { eventId, orgId } = this.props;

		for (let i = 0; i < pointIds.length; i++) {
			const pointId = pointIds[i];

			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/attendees/${editedAttendee._id}`, {
					request: 'removePoints',
					pointId: pointId,
					email: attendee.email,
				})
				.then((res) => { });
		}
		editedAttendee.interactionPoints = editedAttendee.interactionPoints.filter((p) => !pointIds.includes(p._id));
		this.setState({
			editedAttendee: editedAttendee,
			attendee: { ...attendee, interactionPoints: editedAttendee.interactionPoints }
		});
		callback();
	};

	addAttendeePoints = (point, referenceId, callback) => {
		const { attendee, editedAttendee } = this.state;
		const { eventId, orgId } = this.props;
		API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${editedAttendee._id}`, {
				request: 'addPoints',
				pointAction: point.actionName,
				referenceId: referenceId,
				email: attendee.email,
			})
			.then((res) => {
				editedAttendee.interactionPoints.push(res.data);
				this.setState({
					editedAttendee: editedAttendee,
					attendee: { ...attendee, interactionPoints: editedAttendee.interactionPoints }
				});
				this.toggleAddPoints();
				callback(true);
			}).catch((e) => {
				console.log('err', e);
				callback(false);
			});
	};

	expandCustomField = (categoryId, categoryExpanded) => {
		const customFields = this.state.customFields;
		customFields[categoryId].expanded = !categoryExpanded;
		this.setState({ customFields: customFields });
	};

	saveImage = async (data, callback) => {
		const { eventId, orgId } = this.props;
		const { attendee, editedAttendee } = this.state;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${attendee._id}`, data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(res => {

				if (res.data) {
					let savedProfilePicture = res.data.profilePicture;
					this.setState({ attendee: { ...attendee, profilePicture: savedProfilePicture }, editedAttendee: { ...editedAttendee, profilePicture: savedProfilePicture } }, () => {
						callback && callback();
					});
				}
			}).catch(err => {
				console.log('err', err)
			})
	}

	saveInfo = () => {
		const { customFields, editedAttendee } = this.state;
		const metadata = [];

		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (key !== 'bcc') {
				if (!editedAttendee[key] || editedAttendee[key] === '') {
					requiredFields[key] = true;
					valid = false;
				} else {
					requiredFields[key] = false;
				}

			}

		});
		let finalBcc = [];
		//check validity of bcc
		if (editedAttendee.bcc && editedAttendee.bcc.length > 0) {
			//string to array split by ,
			let bccEmails = typeof editedAttendee.bcc == 'string' ? editedAttendee.bcc.replace(/ /g, '').split(',') : [];
			let validEmails = true;
			bccEmails.forEach(email => {
				if (!this.ValidateEmail(email)) {
					validEmails = false;
				}
				if (email !== '') return email;
			});

			if (!validEmails) {
				requiredFields.bcc = true;
				valid = false;
			} else {
				finalBcc = [...finalBcc, ...bccEmails];
			}

		} else if (editedAttendee.bcc === '') {
			finalBcc = [];
			requiredFields.bcc = false;
		}

		if (valid) {
			Object.keys(customFields).forEach((categoryId) => {
				Object.keys(customFields[categoryId]).forEach((fieldId) => {
					if (fieldId !== 'title' && fieldId !== 'expanded') {
						const data = customFields[categoryId][fieldId];
						if (data.value || data.value === "" || data.required) {
							metadata.push({
								categoryId: categoryId,
								fieldId: fieldId,
								fieldValue: data.value || ''
							});
						}
					}
				});
			});

			editedAttendee.metadata = metadata;

			const data = new FormData();
			typeof editedAttendee.firstName !== 'undefined' && data.append('firstName', editedAttendee.firstName);
			typeof editedAttendee.lastName !== 'undefined' && data.append('lastName', editedAttendee.lastName);
			typeof editedAttendee.phoneNumber !== 'undefined' && data.append('phoneNumber', editedAttendee.phoneNumber);
			typeof editedAttendee.email !== 'undefined' && data.append('email', editedAttendee.email);
			data.append('bcc', JSON.stringify(finalBcc));


			typeof editedAttendee.company !== 'undefined' && data.append('company', editedAttendee.company);
			typeof editedAttendee.textMessageOptIn !== 'undefined' && data.append('textMessageOptIn', editedAttendee.textMessageOptIn);
			typeof editedAttendee.dietaryPreference !== 'undefined' && data.append('dietaryPreference', editedAttendee.dietaryPreference);
			typeof editedAttendee.foodAllergies !== 'undefined' && data.append('foodAllergies', editedAttendee.foodAllergies);
			typeof editedAttendee.emergencyContactName !== 'undefined' && data.append('emergencyContactName', editedAttendee.emergencyContactName);
			typeof editedAttendee.emergencyContactPhoneNumber !== 'undefined' && data.append('emergencyContactPhoneNumber', editedAttendee.emergencyContactPhoneNumber);
			typeof editedAttendee.address !== 'undefined' && data.append('address', editedAttendee.address);
			typeof editedAttendee.city !== 'undefined' && data.append('city', editedAttendee.city);
			typeof editedAttendee.state !== 'undefined' && data.append('state', editedAttendee.state);
			typeof editedAttendee.zip !== 'undefined' && data.append('zip', editedAttendee.zip);
			typeof editedAttendee.country !== 'undefined' && data.append('country', editedAttendee.country);




			typeof editedAttendee.title !== 'undefined' && data.append('title', editedAttendee.title);
			typeof editedAttendee.email !== 'undefined' && data.append('status', editedAttendee.status);

			typeof editedAttendee.travelInformation !== 'undefined' &&
				data.append('travelInformation', JSON.stringify(editedAttendee.travelInformation));
			typeof editedAttendee.roomingInformation !== 'undefined' &&
				data.append('roomingInformation', JSON.stringify(editedAttendee.roomingInformation));

			data.append('metadata', JSON.stringify(editedAttendee.metadata));
			data.append('profilePicture', editedAttendee.profilePicture);
			if (editedAttendee.files && editedAttendee.files.length > 0) {
				data.append('includesImage', true);
				data.append('files', editedAttendee.files[0]);
			}
			const { eventId, orgId, event } = this.props;
			API()
				.patch(`Organizations/${orgId}/events/${eventId}/attendees/${editedAttendee._id}`, data, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((res) => {
					if (res.data) {
						this.setState(
							{
								editing: false,
								attendee: { ...editedAttendee, ...res.data },
								requiredFields: requiredFields
							},
							() => {
								const a = { ...editedAttendee, ...res.data };
								let invited = false,
									registered = false,
									attending = false,
									checkedIn = false,
									declined = false,
									removed = false;

								if (a.statusLog) {
									a.statusLog.forEach((s) => {
										if (s.status === 'invited') {
											invited = true;
											removed = false;
										} else if (s.status === 'registered') {
											registered = true;
											attending = true;
											declined = false;
										} else if (s.status === 'incomplete' || s.status === 'registered') {
											attending = true;
											declined = false;
										} else if (s.status === 'not attending' || s.status === 'cancelled') {
											declined = true;
											attending = false;
										} else if (s.status === 'removed') {
											invited = false;
											attending = false;
											registered = false;
											checkedIn = false;
											removed = true;
										} else if (s.status === 'checked in') {
											checkedIn = true;
										}
									});
								} else {
									if (a.status === 'invited') {
										invited = true;
										removed = false;
									} else if (a.status === 'registered') {
										registered = true;
										attending = true;
										declined = false;
									} else if (a.status === 'incomplete' || a.status === 'registered') {
										attending = true;
										declined = false;
									} else if (a.status === 'not attending' || a.status === 'cancelled') {
										declined = true;
										attending = false;
									} else if (a.status === 'removed') {
										invited = false;
										attending = false;
										registered = false;
										checkedIn = false;
										removed = true;
									} else if (a.status === 'checked in') {
										checkedIn = true;
									}
								}
								a.invited = invited;
								a.registered = registered;
								a.attendance = (declined || removed) ? 'Not Attending' : checkedIn ? 'Attended' : attending ? 'Attending' : 'Not Indicated';
								a.checkedIn = checkedIn;
								a.id = a._id;
								a.link = `/${orgId}/events/${eventId}/attendees/${a._id}`;
								a.metadata.forEach((meta) => (a[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));

								a.eventGroups = event.groups
									.map((eg) => eg.attendees.includes(a._id) && eg.name)
									.filter((g) => !!g)
									.join(', ');
								if (a.roomingInformation) {
									a.roomingInformation = Object.keys(a.roomingInformation).forEach((ri) => {
										a[`rooming_${ri}`] = a.roomingInformation[ri];
									});
								}
								if (a.travelInformation) {
									a.travelInformation = Object.keys(a.travelInformation).forEach((ti) => {
										a[`travel_${ti}`] = a.travelInformation[ti];
									});
								}
								this.props.updateAttendee(editedAttendee._id, a);
							}
						);
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	toggleDelete = () => {
		this.setState({ DeleteAttendeeModalOpen: !this.state.DeleteAttendeeModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	editAttendeeField = (property, value) => {
		const editedAttendee = this.state.editedAttendee;
		editedAttendee[property] = value;
		this.setState({ editedAttendee: editedAttendee, editing: true });
	};

	editTravelFields = (data) => {
		this.setState({ editedAttendee: data });
	};

	editRoomingFields = (data) => {
		this.setState({ editedAttendee: data });
	};

	updateCustomField = (e, meta, categoryId) => {
		const customFields = this.state.customFields;

		meta.fieldType === 'time' || meta.fieldType === 'boolean' || meta.fieldType === 's3file' ?
			(customFields[categoryId][meta._id].value = e) :
			meta.fieldType === 'checkbox' ?
				(customFields[categoryId][meta._id].value = e.target.checked) :
				(customFields[categoryId][meta._id].value = e.target.value);

		this.setState({ customFields: customFields, editing: true });
	};

	cancelEdit = () => {
		const attendee = this.state.attendee;
		const fields = {};
		this.props.customFields.forEach((category) => {
			fields[category._id] = { title: category.title, expanded: true };
			category.fields.forEach((field) => {
				fields[category._id][field._id] = { ...field, value: '' };
			});
		});

		attendee.metadata.forEach((meta) => {
			fields[meta.categoryId][meta.fieldId].value = meta.fieldValue;
		});


		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedAttendee: { ...attendee, files: [] },
			editing: false,
			customFields: fields,
			requiredFields: requiredFields
		});
	};

	addSession = (session) => {
		const { editedAttendee, attendee } = this.state;

		if (!editedAttendee.sessions.find((s) => session._id == s._id)) {
			editedAttendee.sessions.push(session);
			this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
				this.fix();
			});
		}

		if (!attendee.sessions.find((s) => s._id == session._id)) {
			attendee.sessions.push(session);
			this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
				this.fix();
			});
		}

		//this.props.updateAttendee(attendee._id, attendee);
	};

	removeSession = (session) => {
		const { editedAttendee, attendee } = this.state;
		editedAttendee.sessions = editedAttendee.sessions.filter((s) => s._id !== session._id);
		attendee.sessions = attendee.sessions.filter((s) => s._id !== session._id);
		//this.props.updateAttendee(attendee._id, attendee);
		this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
			this.fix();
		});
	};

	excludeSession = (session) => {
		const { editedAttendee, attendee } = this.state;
		//const attendee = this.props.attendees.find((a) => a._id == params.attendeeId);
		const updateSessions = attendee.sessions.map((s) => {
			if (s._id == session._id) {
				s.excluded = true;
			}
			return s;
		});
		attendee.sessions = updateSessions;
		editedAttendee.sessions = updateSessions;
		editedAttendee.excludedSessions.push(session);
		//this.props.updateAttendee(attendee._id, attendee);
		this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
			this.fix();
		});
	};
	includeSession = (session) => {
		const { editedAttendee, attendee } = this.state;
		const updatedSessions = attendee.sessions.map((s) => {
			if (s._id == session._id) {
				s.excluded = false;
			}
			return s;
		});
		//console.log('updateSessions include', updatedSessions);
		attendee.sessions = updatedSessions;
		editedAttendee.sessions = updatedSessions;
		editedAttendee.excludedSessions = editedAttendee.excludedSessions.filter((s) => session._id != s._id);
		//this.props.updateAttendee(attendee._id, attendee);
		this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
			this.fix();
		});
	};

	addGroup = (group) => {
		const { editedAttendee, attendee } = this.state;
		const attendeeGroups = editedAttendee.groups;
		attendeeGroups.push(group);
		editedAttendee.groups = attendeeGroups;
		attendee.groups = attendeeGroups;
		this.setState({ editedAttendee: editedAttendee, attendee: attendee });
	};

	addGroups = (groupIds) => {

		let groups = this.props.event.groups.filter((g) => groupIds.includes(g._id)).map((g) => {
			return { name: g.name, _id: g._id, sessions: g.sessions }
		});
		this.setState({ editedAttendee: { ...this.state.editedAttendee, groups: groups }, attendee: { ...this.state.attendee, groups: groups } });
		this.props.updateEventGroups(this.state.editedAttendee);
	}



	removeFromGroup = (group) => {
		const { editedAttendee, attendee } = this.state;
		const groups = attendee.groups.filter((g) => g._id !== group._id);
		attendee.groups = groups;
		editedAttendee.groups = groups;
		this.setState({ editedAttendee: editedAttendee, attendee: attendee });
	};

	updateSelected = (selectedIndex, tabId) => {
		this.setState({ selectedSection: tabId });
	};
	SectionItem = (title, sectionNo) => {
		return (
			<div
				className={`sectionTab ${sectionNo === this.state.selectedSection ? 'selected' : ''}`}
				onClick={() => {
					this.setState({ selectedSection: sectionNo });
				}}
			>
				<div className="calibreBold fs-30">{title}</div>
			</div>
		);
	};

	toggleAddSession = () => {
		this.setState({ addSessionToggle: !this.state.addSessionToggle });
	};

	toggleAddGroup = () => {
		this.setState({ addGroupToggle: !this.state.addGroupToggle });
	};

	toggleAddPoints = () => {
		this.setState({ addPointsToggle: !this.state.addPointsToggle });
	};

	renderSection() {
		const {
			selectedSection,
			editing,
			editedAttendee,
			customFields,
			addSessionToggle,
			addGroupToggle,
			addPointsToggle,
			requiredFields
		} = this.state;
		const { readOnly, event } = this.props;
		switch (selectedSection) {
			case 'profile':
				return (
					<EventAttendeeInfo
						editedAttendee={editedAttendee}
						editing={editing}
						readOnly={readOnly}
						requiredFields={requiredFields}
						index={this.props.index}
						customFields={customFields}
						expandCustomField={this.expandCustomField}
						editAttendeeField={this.editAttendeeField}
						updateAttendee={this.updateAttendee}
						updateCustomField={this.updateCustomField}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
					/>
				);

			case 'schedule':
				return (
					<EventAttendeeScheduledSessions
						sessions={editedAttendee.sessions}
						excludedSessions={editedAttendee.excludedSessions}
						addSession={this.addSession}
						removeSession={this.removeSession}
						excludeSession={this.excludeSession}
						includeSession={this.includeSession}
						editedAttendee={editedAttendee}
						editing={editing}
						readOnly={readOnly}
						locations={this.props.locations}
						event={this.props.event}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						agendaDates={this.state.agendaDates}
						toggle={this.toggleAddSession}
						addSessionToggle={addSessionToggle}
						attendee={this.state.attendee}
					/>
				);
			case 'groups':
				return (
					<EventAttendeeGroups
						editedAttendee={editedAttendee}
						editing={editing}
						readOnly={readOnly}
						event={this.props.event}
						groups={editedAttendee.groups}
						removeGroup={this.removeFromGroup}
						updateAttendee={this.props.updateAttendee}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						addNewGroup={this.props.addNewGroup}
						toggle={this.toggleAddGroup}
						addGroupToggle={addGroupToggle}
						addGroups={this.addGroups}
					/>
				);
			case 'travel':
				return (
					<EventAttendeeTravel
						editedAttendee={editedAttendee}
						editing={editing}
						readOnly={readOnly}
						index={this.props.index}
						updateAttendee={this.props.updateAttendee}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
						toggleEditing={this.toggleEditing}
						editTravelFields={this.editTravelFields}
						editRoomingFields={this.editRoomingFields}
					/>
				);

			case 'rooming':
				return (
					<EventAttendeeRooming
						editedAttendee={editedAttendee}
						editing={editing}
						readOnly={readOnly}
						index={this.props.index}
						updateAttendee={this.props.updateAttendee}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
						toggleEditing={this.toggleEditing}
						editRoomingFields={this.editRoomingFields}
						editTravelFields={this.editTravelFields}
					/>
				);

			case 'points':
				return (
					<EventAttendeePoints
						points={editedAttendee.interactionPoints}
						event={this.props.event}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						deletePoints={this.deleteAttendeePoints}
						addPoints={this.addAttendeePoints}
						toggle={this.toggleAddPoints}
						addPointsToggle={addPointsToggle}
						sessions={this.props.event.sessions}
					/>
				);

			case 'integrations':
				return (<EventAttendeeIntegrations
					org={this.props.org}
					editedAttendee={editedAttendee}
					editing={editing}
					readOnly={readOnly}
					index={this.props.index}
					updateAttendee={this.props.updateAttendee}
					eventId={this.props.eventId}
					orgId={this.props.orgId}
					toggleEditing={this.toggleEditing}
					editRoomingFields={this.editRoomingFields}
				/>)

			default:
				break;

		}
	}
	openFileSelector = () => {

		if (this.fileInput) {
			this.fileInput.click();
		}

	}
	handleFileChange = (e) => {
		const reader = new FileReader();
		const selectedFile = e.target.files[0];
		reader.readAsDataURL(selectedFile);
		reader.onload = () => {

			this.setState({ selectedFile: reader.result, toggleImageCropper: true });
		}
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	toggleImageCropper = () => {
		this.setState({ toggleImageCropper: false });
	};
	render() {
		const { DeleteAttendeeModalOpen, loading, editing, attendee, editedAttendee, selectedSection, toggleImageCropper } = this.state;
		const { event } = this.props;
		let tabs = [
			{
				title: <span>Profile </span>,
				id: 'profile'
			},
			{
				title: <span>Schedule</span>,
				id: 'schedule'
			},
			{
				title: <span>Groups</span>,
				id: 'groups'
			},
		]

		if (event.experienceType === 'in-person' || event.experienceType === 'hybrid') {
			tabs = [...tabs,
			{
				title: <span>Travel</span>,
				id: 'travel'
			},
			{
				title: <span>Rooming</span>,
				id: 'rooming'
			}]
		}

		if (event.experienceType === 'hybrid') {
			tabs = [...tabs,
			{
				title: <span>Points</span>,
				id: 'points'
			}
			]
		}

		if (attendee.integrations && Object.keys(attendee.integrations).length > 0) {
			tabs = [...tabs,
			{
				title: <span>Integrations</span>,
				id: 'integrations'
			}
			]
		}
		const subActions = false;
		return (
			<div className={`sectionContainer`}>
				<div className={`sectionHeader `}>
					<div style={{ width: 30, height: 30 }}>
						<Link to={`./`} >
							<button style={{ padding: 7, marginLeft: -7, marginTop: -7, fontSize: 16, }}><i className='las la-times'></i></button>

						</Link>
					</div>

					<div className="sectionTitleBar">
						<div className='profileIcon mr-15'
							style={{ position: 'relative' }}
							onClick={() => {
								if (!attendee.profilePicture) {
									this.openFileSelector();
								}

							}}
						>
							{editedAttendee.profilePicture ?
								(<img src={editedAttendee.profilePicture} alt='' />)
								:
								(<h1 className='acr white'>{attendee.firstName.charAt(0)}{attendee.lastName && attendee.lastName.length > 0 ? attendee.lastName.charAt(0) : ''}</h1>)}

							<div className="addImageIcon">

								{attendee.profilePicture ? <div className='flex'
								>
									<div className='replace mr-5 white'
										onClick={() => {
											this.openFileSelector();
										}}
									>
										<i className="las la-redo-alt"></i>
									</div>

									<div className='white'>|</div>


									<div className='remove ml-5 white'
										onClick={() => {

											this.editAttendeeField('profilePicture', "")
										}}
									>
										<i className="las la-trash"></i>
									</div>


								</div> : <i className="las la-camera white" />}

							</div>

							<input
								type="file"
								ref={(input) => (this.fileInput = input)}
								style={{ display: 'none' }}
								onChange={this.handleFileChange}
							/>
						</div>
						<div className="flex column jcc">
							<h3 >{attendee.firstName} {attendee.lastName}</h3>
							{attendee.title && <h4 className='attendeeTitle'>{attendee.title}</h4>}
							{attendee.company && <h4 className='mt-10'>{attendee.company}</h4>}
							<div></div>
						</div>
						<div style={{ margin: 'auto 20px', width: 1, height: 40, background: "var(--border)" }}></div>
						<div className='flex column jcc'>
							{attendee.phoneNumber && <h4 className='mb-10'><i className='las la-phone mr-10'></i>
								{/* format */}
								{
									attendee.phoneNumber.length === 10 ?
										`(${attendee.phoneNumber.substring(0, 3)}) ${attendee.phoneNumber.substring(3, 6)}-${attendee.phoneNumber.substring(6, 10)}`
										: attendee.phoneNumber
								}</h4>}
							<h4><i className='las la-envelope mr-10'></i><a rel="noreferrer" href={`mailto:${attendee.email}`} target='_blank'>{attendee.email}</a></h4>
						</div>

						<div className="ml-a">
							{subActions && (
								<UncontrolledDropdown className="ml-a" style={{ float: 'right', marginTop: 20 }}>
									<DropdownToggle className="neu mr-10">
										<i className='las la-ellipsis-h'></i>
									</DropdownToggle>
									<DropdownMenu right className="columnDropdown p-20">
										<h5 className='mb-10'>Options</h5>
										{subActions.map((sa, index) => {
											return sa.type === 'link' ? (
												<Link to={sa.target}>
													<DropdownItem>
														{sa.iconClass && <i className={`mr-10 ${sa.iconClass}`} />}
														{sa.label}
													</DropdownItem>

												</Link>) : (
												<DropdownItem onClick={sa.onClick}>
													{sa.iconClass && <i className={`mr-10 ${sa.iconClass}`} />}
													{sa.label}
												</DropdownItem>
											);
										})}
									</DropdownMenu>
								</UncontrolledDropdown>
							)}
						</div>
						<SectionHeader
							subActions={[{ onClick: this.toggleDelete, label: <span><i className='las la-trash mr-10'></i>Delete</span> }]} />
					</div>

					<div className="sectionHeaderChildren">
						<TabsContainer
							tabs={tabs}
							updateSelected={this.updateSelected}
							selectedIndex={tabs.findIndex((t) => t.id === selectedSection)}
						/></div>
				</div>
				<div className="sectionBody">{!loading && this.renderSection()}</div>
				<LoadingWizard text="Loading Attendee" loading={loading} />
				<SectionFooter updatedAt={attendee.updatedAt} cancelEdit={this.cancelEdit}
					saveEdit={this.saveInfo} editing={editing} />
				{DeleteAttendeeModalOpen && <DeleteAttendeeModal
					isOpen={DeleteAttendeeModalOpen}
					toggle={this.toggleDelete}
					attendee={attendee}
					submit={this.props.deleteAttendee}
				/>}
				{toggleImageCropper && <ImageCropper
					isOpen={toggleImageCropper}
					toggle={this.toggleImageCropper}
					selectedFile={this.state.selectedFile}
					saveImage={this.saveImage}
				/>}
			</div>
		);
	}
}

export default withRouter(EventAttendee);
