import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-toggle/style.css';

import InputField from '../../../components/inputs/inputField';
import ToggleField from '../../../components/inputs/toggleField';
import ViewsTable from '../../../components/tables/ViewsTable';
import ExcludeAttendeesModal from './modals/excludeAttendeesModal';
import GroupAndAttendeesModal from './modals/groupAndAttendeesModal';
import IncludeAttendeesModal from './modals/includeAttendeesModal';
import RemoveAttendeeModal from './modals/removeAttendeeModal';
import SessionRemoveFromGroupModal from './modals/sessionRemoveFromGroupModal';
import moment from 'moment';
import API from '../../../utils/API';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

class AttendeesAndGroups extends Component {
    constructor(props) {
        super(props);
        const columns = {
            sendDate: 'Date',
            sendTime: 'Time',
            timezone: 'Timezone',
            sent: 'Sent',
            recipientModel: 'Type',
            cancelled: 'Cancelled'
        };
        const columnTypes = {
            sendDate: 'date',
            sendTime: 'time',
            timezone: 'text',
            sent: 'bool',
            recipientModel: 'text',
            cancelled: 'bool'
        };
        const toggledColumns = {};
        const normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });
        this.state = {
            genExpanded: true,
            toggledColumns: toggledColumns,

            displayExpanded: true,
            featuresExpanded: true,
            settingsExpanded: true,
            sessionLocationId: this.props.editedSession.location ? this.props.editedSession.location : '',
            toggleAttendeesAndGroups: false,
            selectedColumnSortAttendees: null,
            sortDesc: false,
            toggleExcludeAttendees: false,
            selectedAttendee: null,
            toggleIncludeAttendees: false,
            toggleDeleteAttendee: false,
            toggleDeleteGroup: false,
            selectedGroup: null,
            searchTerm: '',

        };
    }

    toggleEditing = () => {
        this.setState({ editing: !this.state.editing });
    };

    listItem = (meta, categoryId) => {
        const { readOnly } = this.props;
        return (
            <InputField
                label={meta.fieldName}
                checked={meta.fieldType === 'checkbox' && meta.value}
                value={meta.value}
                disabled={readOnly}
                type={meta.fieldType}
                onChange={(e) => this.props.updateCustomField(e, meta, categoryId)}
            />
        );
    };

    displayMetadataFields = () => {
        const { customFields } = this.props;
        return Object.keys(customFields).map((categoryId) => {
            const categoryFields = customFields[categoryId];
            const categoryTitle = categoryFields.title;
            const categoryExpanded = categoryFields.expanded;

            let fieldCount = 0;
            const categoryRow = (
                <div style={{ marginBottom: 10, padding: '12.5px 0' }}>
                    <div style={{ display: 'flex' }}>
                        <div className="calibreBold fs-20">{categoryTitle}</div>
                        {/* 	<span
							style={{ margin: 'auto', marginRight: 10 }}
							onClick={() => this.props.expandCustomField(categoryId, categoryExpanded)}
						>
							{categoryExpanded ? '-' : '+'}
						</span> */}
                    </div>

                    <div style={{ display: categoryExpanded ? 'block' : 'none' }}>
                        {Object.keys(categoryFields).map((fieldKey) => {
                            if (fieldKey !== 'title' && fieldKey !== 'expanded') {
                                const fieldData = customFields[categoryId][fieldKey];
                                fieldCount++;
                                return this.listItem(fieldData, categoryId);
                            }
                        })}
                    </div>
                </div>
            );
            return fieldCount > 0 && categoryRow;
        });
    };
    toggleAttendeesAndGroups = () => {
        this.setState({ toggleAttendeesAndGroups: !this.state.toggleAttendeesAndGroups });
    }

    /* Download */
    s2ab = (s) => {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
    };

    downloadReport = async (data, columns, columnTypes) => {
        const { editedSession } = this.props;
        const entries = data;
        const dataRows = [];
        entries.forEach((entry) => {
            const dataRow = {};
            Object.keys(columns).forEach((hc) => {
                if (entry[hc] != null) {
                    if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
                        dataRow[hc] = entry[hc] ? 'Yes' : 'No';
                    } else {
                        dataRow[hc] = entry[hc];
                    }
                }
            });
            dataRows.push(dataRow);
        });
        const cleanData = [];
        const headers = Object.keys(columns).map((header, hIndex) => {
            dataRows.forEach((data, dIndex) => {
                if (!cleanData[dIndex]) cleanData[dIndex] = [];
                cleanData[dIndex][hIndex] = data[header] || '';
            });
            return columns[header];
        });
        const finalData = {
            headerRow: headers,
            dataRows: cleanData
        };

        const reportDetails =
            { Title: editedSession.name + " Report", Subject: 'Attendees Report' };
        this.downloadRegular(reportDetails, finalData);
    };

    downloadRegular = (report, data) => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: report.Title,
            Subject: report.Subject,
            Author: 'Simple Events',
            CreatedDate: new Date()
        };
        wb.SheetNames.push('Data');
        /* make worksheet */
        var ws_data2 = [data.headerRow, ...data.dataRows];
        var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

        /* Add the worksheet to the workbook */
        wb.Sheets['Data'] = ws2;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        saveAs(
            new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
            `${report.Title}_${moment().format('LLL')}.xlsx`
        );
    };
    /* Search */
    searchEntries = (searchText) => {
        this.setState({
            searching: searchText != '' ? true : false,
            searchText: searchText ? searchText : '',
        });
    };
    renderAttendeesTable = () => {
        const { editedSession, sortDesc, selectedColumnSort, event, } = this.props;
        const { searchTerm } = this.state
        let groups = event.groups.filter(att => editedSession.groups.find(eatt => eatt._id == att._id)) || [];
        let isGeneralSession = editedSession.isGeneral;
        let attendees = editedSession.isGeneral ? event.attendees : (event.attendees.filter(att => editedSession.attendees.some(eatt => eatt._id == att._id))) || [];

        //get unique attendees from both groups and attendees
        let uniqueAttendees = [];
        groups.map(g => {
            g.attendees.map(a => {
                if (!uniqueAttendees.find(ua => ua._id == a._id)) {
                    let attendee = event.attendees.find(att => att._id == a._id);
                    if (attendee) {
                        uniqueAttendees.push(attendee);
                    }
                }
            })
        })
        attendees.map(a => {
            if (!uniqueAttendees.some(ua => ua._id == a._id)) {
                uniqueAttendees.push(a);
            }
        })

        uniqueAttendees = uniqueAttendees.map(att => {
            const checkin = editedSession.checkins.find((checkin) => checkin.attendeeId.toString() == att._id);
            const excludedSessions = att.excludedSessions;

            const excluded = excludedSessions ? excludedSessions.includes(editedSession._id) : false;

            return {
                _id: att._id,
                phoneNumber: att.phoneNumber,
                firstName: att.firstName,
                lastName: att.lastName,
                email: att.email,
                checkedIn: checkin ? <i class="las la-check-square"></i> : '',
                checkedInTime: checkin ? moment(checkin.checkinDate).format('MM/DD/YYYY h:mm a') : 'Not checked in',
                status: excluded ? 'Excluded' : 'Included',
                isStrikeThrough: excluded
            };
        });


        let columns = {};
        let columnTypes = {};
        let normalizedColumns = null;
        let data = [];
        let sortData = () => { };
        let toggledColumns = {};
        let rowDetailColumns = {};
        let rowDetailTypes = {};
        let columnWidths = {
            firstName: 200,
            lastName: 200,
            email: 200,
            checkedIn: 100,
        };
        columns = {
            firstName: 'First Name',
            lastName: 'Last Name',
            checkedIn: 'Check In',
        };
        rowDetailColumns = {
            phoneNumber: 'Phone Number',
            email: 'Email',
            checkedInTime: 'Checked In',
            status: 'Status',
        };
        columnTypes = {
            firstName: 'text',
            lastName: 'text',
            checkedIn: 'text',
        };
        let rowSettings = isGeneralSession ? [{
            onClick: (e) => {
                this.toggleIncludeAttendees(e);
            },
            label: 'Include',
            displayCondition: (row) => {
                console.log('sss', row)
                return row.status == 'Excluded';
            }
        },
        {
            onClick: (e) => {
                this.toggleExcludeAttendees(e);
            },
            label: 'Exclude',
            displayCondition: (row) => {
                console.log('sss', row)

                return row.status == 'Included';
            }
        }
        ] : [{
            label: 'Remove',
            onClick: (e) => {
                this.removeAttendee(e);
            },
        }];


        data = attendees;
        normalizedColumns =
            !normalizedColumns &&
            Object.keys(columns).map((cfc) => {
                toggledColumns[cfc] = true;
                return {
                    label: columns[cfc],
                    key: cfc,
                    value: cfc,
                    type: columnTypes[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            });

        sortData = (column, sortAsc, sortDesc) => {
            normalizedColumns = normalizedColumns.map((col) => {
                if (col.key == column.key) {
                    col.sortAsc = sortAsc;
                    col.sortDesc = sortDesc;
                } else {
                    col.sortAsc = false;
                    col.sortDesc = false;
                }
                return col;
            });

            this.setState({
                selectedColumnSort: column,
                sortDesc: sortDesc == false ? true : false
            });
        };

        if (selectedColumnSort) {
            data = data.sort((a, b) => {
                const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
                if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
                    switch (columnType) {
                        case 'string':
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                        case 'number':
                            return a[selectedColumnSort.key] - b[selectedColumnSort.key];
                        case 'date':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'time':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'boolean':
                            const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
                            const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
                            return a1.localeCompare(b1);
                        default:
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                    }
                } else if (a[selectedColumnSort.key]) {
                    return 1;
                }
                return -1;
            });

            data = sortDesc ? data.reverse() : data;
        }
        let downloadColumns = {
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            phoneNumber: 'Phone Number',
            checkedInTime: 'Checked In',
            status: 'Status',
        };
        let downloadColumnTypes = {
            firstName: 'text',
            lastName: 'text',
            email: 'text',
            phoneNumber: 'text',
            checkedInTime: 'text',
            status: 'text',
        };
        let dataUnique = uniqueAttendees
        if (searchTerm.length > 0) {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term != '') {
                    dataUnique = dataUnique.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }

        return (
            <div>
                <h3 className='blockHeader'>Session Attendees</h3>
                <div className='flex jcsb'>
                    <div className='mb-10 flex aic'>
                        <InputField
                            classes="m-0 viewSearch"
                            placeholder="Search "
                            clickIcon={true}
                            required={true}
                            value={searchTerm}
                            onChange={(e) => {
                                clearTimeout(this.searchTimeout);
                                this.setState({ searchTerm: e.target.value },
                                    () => {
                                        this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
                                    });

                            }}
                            prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                            inputIcon={searchTerm != '' &&
                                <i
                                    onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
                                    className="las la-times-circle"
                                    style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                />}
                        />
                    </div>
                    <div class="c-pointer fs-20 mb-10">
                        <button onClick={() => {
                            this.downloadReport(uniqueAttendees, downloadColumns, downloadColumnTypes);

                        }} class="p-7"><i class="las la-download"></i></button>
                    </div>
                </div>
                <ViewsTable
                    title={'Attendee Details'}
                    style={{ margin: 0, paddingTop: 0, minHeight: 325, maxHeight: 325 }}
                    columns={normalizedColumns}
                    toggledColumns={toggledColumns}
                    data={dataUnique}
                    mainColumnActive={false}
                    checked={{}}
                    mainActions={[]}
                    sortData={sortData}
                    disableCheck={true}
                    columnWidths={columnWidths}
                    rowSettings={rowSettings}
                    detailsTitle={'Attendee Details'}
                    rowDetails={rowDetailColumns}
                />
            </div>
        );

    }

    renderGroupsTable = () => {
        const { editedSession, sortDesc, selectedColumnSort, event } = this.props;

        let groups = event.groups.filter(att => editedSession.groups.find(eatt => eatt._id == att._id)) || [];
        let columns = {};
        let columnTypes = {};
        let normalizedColumns = null;
        let data = [];
        let sortData = () => { };
        let toggledColumns = {};
        columns = {
            name: 'Name'
        };
        columnTypes = {
            name: 'text'
        };

        data = groups;
        normalizedColumns =
            !normalizedColumns &&
            Object.keys(columns).map((cfc) => {
                toggledColumns[cfc] = true;
                return {
                    label: columns[cfc],
                    key: cfc,
                    value: cfc,
                    type: columnTypes[cfc],
                    sortAsc: false,
                    sortDesc: false
                };
            });

        sortData = (column, sortAsc, sortDesc) => {
            normalizedColumns = normalizedColumns.map((col) => {
                if (col.key == column.key) {
                    col.sortAsc = sortAsc;
                    col.sortDesc = sortDesc;
                } else {
                    col.sortAsc = false;
                    col.sortDesc = false;
                }
                return col;
            });

            this.setState({
                selectedColumnSort: column,
                sortDesc: sortDesc == false ? true : false
            });
        };

        if (selectedColumnSort) {
            data = data.sort((a, b) => {
                const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
                if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
                    switch (columnType) {
                        case 'string':
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                        case 'number':
                            return a[selectedColumnSort.key] - b[selectedColumnSort.key];
                        case 'date':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'time':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'boolean':
                            const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
                            const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
                            return a1.localeCompare(b1);
                        default:
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                    }
                } else if (a[selectedColumnSort.key]) {
                    return 1;
                }
                return -1;
            });

            data = sortDesc ? data.reverse() : data;
        }
        return (
            <ViewsTable
                title={'Groups'}
                style={{ margin: 0, paddingTop: 0, minHeight: 325, maxHeight: 325 }}
                columns={normalizedColumns}
                toggledColumns={toggledColumns}
                data={data}
                mainColumnActive={false}
                checked={{}}
                mainActions={[]}
                sortData={sortData}
                disableCheck={true}
            />);
    }

    toggleExcludeAttendees = (attendeeSelected) => {
        let selectedAttendee = this.state.toggleExcludeAttendees == false ? attendeeSelected : null;
        this.setState({ toggleExcludeAttendees: !this.state.toggleExcludeAttendees, selectedAttendee: selectedAttendee });
    }

    toggleIncludeAttendees = (attendeeSelected) => {
        let selectedAttendee = this.state.toggleIncludeAttendees == false ? attendeeSelected : null;

        this.setState({ toggleIncludeAttendees: !this.state.toggleIncludeAttendees, selectedAttendee: selectedAttendee });
    }

    toggleDeleteAttendee = (attendeeSelected) => {
        let selectedAttendee = this.state.toggleDeleteAttendee == false ? attendeeSelected : null;

        this.setState({ toggleDeleteAttendee: !this.state.toggleDeleteAttendee, selectedAttendee: selectedAttendee });
    }

    toggleDeleteGroup = (groupSelected) => {
        let selectedGroup = this.state.toggleDeleteGroup == false ? groupSelected : null;

        this.setState({ toggleDeleteGroup: !this.state.toggleDeleteGroup, selectedGroup: selectedGroup });
    }

    removeAttendee = async (attendeeId) => {
        const { orgId, eventId, event } = this.props;
        const { editedSession } = this.props;
        let attendees = editedSession.attendees.filter(att => att._id != attendeeId);
        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${editedSession._id}`, {
                request: 'updateAttendeesAndGroups',
                attendees: attendees.map(att => att._id),
            })
            .then((res) => {
                if (res.data) {
                    let attendees = this.props.event.attendees.filter(att => res.data.attendees.includes(att._id));
                    this.props.updateAttendees(attendees);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        const { toggleIncludeAttendees, selectedGroup, toggleDeleteAttendee, toggleAttendeesAndGroups, toggleExcludeAttendees, toggleDeleteGroup } = this.state;
        const {
            editedSession,
            updateSessionField,
            readOnly,
        } = this.props;

        //get groups from sessions
        const groups = editedSession.groups || [];
        const attendees = editedSession.attendees || [];
        let addAttendeesLabel = attendees.length > 0 ? `${attendees.length} Attendees` : ''
        let addGroupsLabel = groups.length > 0 ? `${attendees.length > 0 ? ' ' : ''}${groups.length} Groups` : '';



        return (
            <div className="sectionBody "
                style={{
                    overflow: 'hidden',
                }}
            >
                <div className=" sectionBody h-100"

                >


                    <ToggleField
                        label="Show for all Attendees"
                        checked={editedSession.isGeneral}
                        icons={false}
                        disabled={readOnly}
                        onChange={(e) => updateSessionField('isGeneral', !editedSession.isGeneral)}
                    />

                    {!editedSession.isGeneral && <InputField
                        label="Attendees"
                        required={true}

                        value={`${addAttendeesLabel}${addGroupsLabel}`}
                        inputAction={this.toggleAttendeesAndGroups}
                        inputActionLabel={`${groups.length == 0 && attendees.length == 0 ? 'Add Attendees' : 'Edit Attendees'}`}
                        disabled={true}
                        onChange={(e) => {


                        }}
                    />}
                    {!editedSession.isGeneral && <div>
                        <h3 className='blockHeader'>Session Groups</h3>
                        <div className="">
                            {this.renderGroupsTable()}
                        </div>
                    </div>}
                    <div className="">
                        {this.renderAttendeesTable()}
                    </div>




                </div>
                {toggleAttendeesAndGroups &&
                    <GroupAndAttendeesModal
                        isOpen={toggleAttendeesAndGroups}
                        toggle={this.toggleAttendeesAndGroups}
                        event={this.props.event}
                        orgId={this.props.orgId}
                        eventId={this.props.eventId}
                        attendees={[]}
                        groups={[]}
                        updateAttendees={this.props.updateAttendees}
                        readOnly={readOnly}
                        session={editedSession}
                        updateSessionGroups={this.props.updateSessionGroups}
                    />
                }

                {toggleExcludeAttendees &&
                    <ExcludeAttendeesModal
                        isOpen={toggleExcludeAttendees}
                        toggle={this.toggleExcludeAttendees}
                        orgId={this.props.orgId}
                        eventId={this.props.event._id}
                        checkedAttendees={[this.state.selectedAttendee]}
                        session={editedSession}
                        excludeUpdateAttendees={(att, sid) => {
                            this.props.excludeUpdateAttendees(att, sid, callback => {
                                let attTemp = attendees;
                                attTemp.map(a => {
                                    if (att.includes(a._id)) {
                                        if (!a.excludedSessions.includes(sid)) {
                                            a.excludedSessions = [...a.excludedSessions, sid]
                                        }
                                    }
                                    return a;
                                })
                                this.props.updateAttendees(attTemp)
                            });
                        }}
                    />
                }

                {toggleIncludeAttendees && (
                    <IncludeAttendeesModal
                        isOpen={toggleIncludeAttendees}
                        toggle={this.toggleIncludeAttendees}
                        orgId={this.props.orgId}
                        eventId={this.props.event._id}
                        checkedAttendees={[this.state.selectedAttendee]}
                        session={editedSession}
                        updateAttendees={(atts, callback) => {
                            //array of attendee id
                            let attTemp = attendees;
                            attTemp.map((a) => {
                                if (atts[0].includes(a._id)) {

                                    a.excludedSessions = a.excludedSessions.filter(el => el !== this.props.editedSession._id)
                                }
                                return a;

                            })
                            this.props.updateAttendees(attTemp)

                            this.toggleIncludeAttendees();
                        }}
                    />
                )}
                {toggleDeleteAttendee && (
                    <RemoveAttendeeModal
                        isOpen={toggleDeleteAttendee}
                        toggle={this.toggleDeleteAttendee}
                        orgId={this.props.orgId}
                        eventId={this.props.event._id}
                        attendees={attendees}
                        updateAttendees={this.props.updateAttendees}
                        session={this.props.editedSession}
                        checkedAttendees={[this.state.selectedAttendee]}
                        uncheckEntries={() => { }}
                    />
                )}

                {toggleDeleteGroup && (
                    <SessionRemoveFromGroupModal
                        isOpen={toggleDeleteGroup}
                        toggle={this.toggleDeleteGroup}
                        orgId={this.props.orgId}
                        eventId={this.props.event._id}
                        attendees={attendees}
                        updateAttendees={this.props.updateAttendees}
                        session={this.props.editedSession}
                        checkedGroups={[selectedGroup]}
                        removeGroup={this.props.removeGroup}
                    />
                )}

            </div >
        );
    }
}

export default AttendeesAndGroups;
