import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';
import moment from 'moment';
import ViewsTable from '../../../../components/tables/ViewsTable';

class GroupAddSessionModal extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name',
			date: 'Date',
			startTime: 'Start Time'
		};
		const columnTypes = {
			name: 'text',
			date: 'date',
			startTime: 'time'
		};
		const columnWidth = {
			name: 300,
			date: 180
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			name: '',
			sessions: [
				...props.sessions
					.map((session) => {
						const date = moment(session.date).format('M/D/YYYY');
						const startTime = session.startTime;
						const endTime = session.endTime;
						const name = session.name;

						return { _id: session._id, date: date, startTime: startTime, endTime: endTime, name: name };
					})
					.filter((session) => !props.existingSessions.find((sesh) => session._id == sesh._id))
			],
			selectedValue: { label: '', color: '#00B8D9' },
			added: 0,
			submitting: false,
			invalid: false,
			success: false,
			checkedSessions: {},
			allChecked: false,
			searching: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			columnWidth: columnWidth,
			selectedColumnSort: null,
			sortDesc: false,
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	addSessions = async (sessionIds) => {
		const { group, eventId, orgId, sessions } = this.props;
		this.setState({ submitting: true });

		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/groups/${group._id}`, {
				sessions: [...Array.from(group.sessions, (s) => s._id), ...sessionIds]
			})
			.then((res) => {
				if (res.data) {
					this.props.addSessions(sessionIds.map((a) => this.props.sessions.find((s) => s._id == a)));

					this.props.toggle();
				}
			});
	};

	handleSessionChosen = (e) => {
		this.setState({ selectedValue: e });
	};

	checkAllSessions = async () => {
		if (!this.state.allChecked) {
			const eventId = this.props.eventId;
			const orgId = this.props.orgId;
			await API().get(`Organizations/${orgId}/events/${eventId}/checkSessions`).then((res) => {
				if (res.data) {
					let ids = [];
					res.data.map((id) => {
						if (!this.props.existingSessions.find((es) => id == es._id)) {
							ids[id] = true;
						}
					});
					this.setState({ checkedSessions: ids, allChecked: true });
				}
			});
		} else {
			this.setState({ checkedSessions: {}, allChecked: false });
		}
	};

	SessionItem(session, index) {
		return (
			<div key={index} className="tableColumnRow">
				<div
					className={`checkBox mr-10 ${this.state.checkedSessions[session._id] || false ? 'checked' : ''}`}
					onClick={() => {
						const checked = this.state.checkedSessions;
						checked[session._id] ? delete checked[session._id] : (checked[session._id] = true);
						this.setState({
							checkedAttendees: checked,
							allChecked: Object.keys(checked).length === this.props.attendeeCount
						});
					}}
				>
					<i className="las la-check" />
				</div>

				<div className="tableColumnRowCell main text-truncate calibreRegular">
					<div className="fs-16" style={{ marginTop: 'auto', marginBottom: 'auto' }}>{`${session.name}`}</div>
				</div>

				<div className="tableColumnRowCell  text-truncate calibreRegular fs-16">{session.date}</div>
				<div className="tableColumnRowCell  text-truncate calibreRegular fs-16">{session.startTime}</div>
			</div>
		);
	}
	updatedCheckedSessions = (checked) => {
		this.setState({ checkedSessions: checked });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			sessions,
			submitting,
			checkedSessions,
			toggledColumns,
			columnWidth,
			normalizedColumns,
			selectedColumnSort,
			sortDesc
		} = this.state;
		let availableSessions = sessions;

		if (selectedColumnSort) {
			availableSessions = availableSessions.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			availableSessions = sortDesc ? availableSessions.reverse() : availableSessions;
		}

		return (
			<div>
				<FieldModal
					size="large"
					isOpen={isOpen}
					modalTitle={"Add Sessions to Group"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div style={{
								overflowX: 'hidden',
								height: "calc(100vh - 300px)"
							}} >
								<ViewsTable
									title="Sessions"
									style={{ margin: 0, height: 'calc(100vh - 300px)' }}
									classes={"fit"}
									columns={normalizedColumns}
									toggledColumns={toggledColumns}
									data={availableSessions}
									mainColumnActive={false}
									columnWidth={columnWidth}
									checked={this.state.checkedSessions}
									checkRow={(entryId) => {
										const checked = this.state.checkedSessions;
										checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
										this.updatedCheckedSessions(checked);
										this.setState({
											checkedSessions: checked
										});
									}}
									checkAllRows={(entryIds) => {
										let checked = this.state.checkedSessions;
										entryIds.length > 0
											? entryIds.forEach((entryId) => {
												checked[entryId] = true;
											})
											: (checked = {});
										this.updatedCheckedSessions(checked);

										this.setState({
											checkedSessions: checked
										});
									}}
									sortData={this.sortData}
								/>{' '}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding to Group...'}
					actionButton={() => {
						if (Object.keys(checkedSessions).length > 0) {
							this.addSessions(Object.keys(checkedSessions));
						} else {
							this.setState({ invalid: true, submitting: false });
						}
					}}
					actionButtonDisabled={submitting}
					isFullWidthBody={true}
				></FieldModal>
			</div>

		);
	}
}

export default GroupAddSessionModal;
