import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import ViewsTable from '../../../../components/tables/ViewsTable';
import InputField from '../../../../components/inputs/inputField';
import API from '../../../../utils/API';

class AddGroupsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedColumnSort: null,
            sortDesc: false,
            searchTerm: '',
            toggledColumns: {},
            checkedGroups: {},
            groups: [],
            selectedGroupsCount: 0,

        };
    }
    componentDidMount() {
        const { groups, attendeeGroups } = this.props;

        let checkedGroups = {};
        for (let i = 0; i < attendeeGroups.length; i++) {
            checkedGroups[attendeeGroups[i]._id] = true;
        }

        this.setState({
            groups: groups,
            checkedGroups: checkedGroups,
            selectedGroupsCount: Object.keys(checkedGroups).length
        });

    }

    searchEntries = (searchText) => {
        this.setState({
            searching: searchText != '' ? true : false,
            searchText: searchText ? searchText : '',
        });
    };

    renderContent = () => {

        const { selectedColumnSort, searchTerm, toggledColumns, sortDesc } = this.state;

        let columns = {};
        let columnTypes = {};
        let normalizedColumns = null;
        let checkRow = () => { };
        let checkAllRows = () => { };
        let checkedRows = [];
        let data = [];
        let sortData = () => { };
        columns = {
            name: 'Name'
        };
        columnTypes = {
            name: 'text'
        };
        checkRow = (entryId) => {
            const checked = this.state.checkedGroups;
            checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
            this.setState({
                checkedGroups: checked,
                selectedGroupsCount: Object.keys(checked).length
            });
        };
        checkAllRows = (entryIds) => {
            let checked = this.state.checkedGroups;
            entryIds.length > 0
                ? entryIds.forEach((entryId) => {
                    checked[entryId] = true;
                })
                : (checked = {});

            this.setState({
                checkedGroups: checked,
                selectedGroupsCount: Object.keys(checked).length
            });
        };
        checkedRows = this.state.checkedGroups;
        data = this.state.groups;
        normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });


        if (selectedColumnSort) {
            data = data.sort((a, b) => {
                const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
                if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
                    switch (columnType) {
                        case 'string':
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                        case 'number':
                            return a[selectedColumnSort.key] - b[selectedColumnSort.key];
                        case 'date':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'time':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'boolean':
                            const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
                            const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
                            return a1.localeCompare(b1);
                        default:
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                    }
                } else if (a[selectedColumnSort.key]) {
                    return 1;
                }
                return -1;
            });

            data = sortDesc ? data.reverse() : data;
        }

        if (searchTerm.length > 0) {
            const searchTerms = searchTerm.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term != '') {
                    data = data.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }
        return (
            <div className='flex '
                style={{ height: "calc(100vh - 330px)" }}
            >
                <ViewsTable
                    style={{ margin: 0, paddingTop: 0, paddingBottom: 25, }}
                    columns={normalizedColumns}
                    toggledColumns={toggledColumns}
                    data={data}
                    mainColumnActive={false}
                    checked={checkedRows}
                    checkRow={checkRow}
                    mainActions={[]}
                    checkAllRows={checkAllRows}
                    sortData={sortData}
                    title={'Groups'}
                />
            </div>
        )

    }
    submit = async () => {
        const { attendee, eventId, orgId } = this.props;
        const { checkedGroups } = this.state;


        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/attendees/${attendee._id}`, {
                groups: Object.keys(checkedGroups),
                request: 'updateGroups',
            })
            .then((res) => {
                if (res.data) {
                    this.props.addGroups(Object.keys(checkedGroups));
                    this.props.toggle();
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    invalid: true,
                    submitting: false
                });
            });


    }

    render() {
        const { isOpen, toggle } = this.props;
        const { submitting, searchTerm, selectedGroupsCount } = this.state;

        return (
            <div>
                <FieldModal
                    size="large"
                    isOpen={isOpen}
                    modalTitle={"Groups"}
                    bodyHeaderText={'Choose groups to add to this attendee'}
                    bodyDescription={''}
                    overflowHidden={true}
                    bodyContent={() => {
                        return (
                            <div className=''
                            >
                                <div style={{}} className='mb-10 flex'>
                                    <div className='flex aic'>
                                        <InputField
                                            classes="m-0 viewSearch"
                                            placeholder="Search "
                                            clickIcon={true}
                                            required={true}
                                            value={searchTerm}
                                            onChange={(e) => {
                                                clearTimeout(this.searchTimeout);
                                                this.setState({ searchTerm: e.target.value },
                                                    () => {
                                                        this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
                                                    });

                                            }}
                                            prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                            inputIcon={searchTerm != '' &&
                                                <i
                                                    onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
                                                    className="las la-times-circle"
                                                    style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                                />}
                                        />
                                    </div>

                                </div>
                                {this.renderContent()}
                            </div>
                        )
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? (
                        `Add Groups ${selectedGroupsCount == 0 ? '' : `(${selectedGroupsCount})`}`
                    ) : (
                        'Adding...'
                    )}
                    actionButton={() => { this.submit() }}
                    actionButtonDisabled={submitting}
                    isFullWidthBody={true}


                ></FieldModal>
            </div >

        );
    }
}

export default AddGroupsModal;
