import React from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';
import axios from 'axios';

import InputField from '../../../../components/inputs/inputField';
function ValidatePhone(phone) {
	var re = /^\d{10}$/;
	return re.test(phone);
}

class SendTestTextModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
			invalid: false,
			error: '',
			requiredFields: {},
			sentCount: 0,
			completed: false,
			testNumber: '',
			invalidError: false
		};
	}

	handlePhonesChange = (e) => {
		this.setState({
			testNumber: e.target.value
		});
	};

	sendTestTexts = async () => {
		const { testNumber } = this.state;
		const { orgId, eventId, textMessage } = this.props;
		let numbers = testNumber.replace(/ /g, '').split(',');
		axios.defaults.headers.post['Content-Type'] = 'application/json';

		let valid = true;
		const request = {};

		numbers = numbers.map((e) => {
			if (!ValidatePhone(e) || '') {
				valid = false;
			}

			if (e !== '') return e;
		});

		if (valid || numbers.length == 0) {
			this.setState({ sending: true, invalidError: false, emailsTotal: numbers.length });

			for (let numberCount = 0; numberCount < numbers.length; numberCount++) {
				try {
					const res = await API()
						.post(`Organizations/${orgId}/events/${eventId}/textMessages/${textMessage._id}/send`, {
							isTest: true,
							testPhone: numbers[numberCount]
						})
					if (res.status == 200) this.setState({ sentCount: this.state.sentCount + 1 });
				} catch (err) {
					console.log(err);
				}
			}

			this.setState({ completed: true });
		} else {
			this.setState({ invalidError: true });
		}
	};
	render() {
		const { isOpen, toggle } = this.props;
		const { sending, completed, testNumber, invalidError, emailsTotal, sentCount } = this.state;

		return (


			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Send a Test Text"}
					bodyHeaderText={!sending ? (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							Who would you like to send a test text to?
						</div>
					) : (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							{`Sending ${sentCount} of ${emailsTotal}`}
						</div>
					)}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								{!sending && (
									<InputField
										label="Phone Numbers"
										required={true}
										value={testNumber}
										errorTrigger={invalidError}
										errorMessage={'Invalid Number Detected'}
										onChange={this.handlePhonesChange}
										subText={'Use commas to separate multiple phone numbers'}
									/>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Send Test' : completed ? 'Complete' : 'Sending...'}
					actionButton={() => this.sendTestTexts()}
					actionButtonDisabled={sending || completed}
					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}
				></FieldModal>

			</div>

		);
	}
}

export default SendTestTextModal;
