// import external modules
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../assets/img/logos/simple events logo.png';
import API from '../utils/API';
import TopLevelNavLink2 from '../components/nav/topLevelNavLink2';
import Event from '../views/events/event';
import SystemToolbar from '../components/nav/systemToolbar';
import Program from '../views/programs/program';
import LoadingWizard from '../components/spinner/wizard';

const defaultPlanNavigation = [
    {
        adminOnly: false,
        name: 'Details',
        icon: <i className="las la-pen-alt" />,
        section: 'details'
    }, {
        adminOnly: false,
        name: 'Attendees',
        icon: <i className="las la-user-friends" />,
        section: 'attendees',
    }, {
        adminOnly: false,
        name: 'Sessions',
        icon: <i className="las la-calendar-week" />,
        section: 'schedule'
    }, {
        adminOnly: false,
        name: 'Groups',
        icon: <i className="las la-users" />,
        section: 'groups',
    }, {
        adminOnly: false,
        name: 'Inventory',
        icon: <i className="las la-clipboard-list" />,
        section: 'inventory',
        addon: 'inventoryEnabled',
    }
];

const defaultProductionNavigation = [
    {
        name: 'Emails',
        icon: <i className="las la-envelope" />,
        section: 'emails',
    }, {
        adminOnly: false,
        name: 'Forms',
        icon: <i className="lab la-wpforms" />,
        section: 'forms',
    }, {
        name: 'Pages',
        icon: <i className="las la-laptop-code" />,
        section: 'pages',
    }
];


const requestNavigation = [
    {
        adminOnly: false,
        name: 'Requests',
        icon: <i className="lab la-wpforms" />,
        section: 'requests',
    }, {
        name: 'Request Form',
        icon: <i className="las la-envelope" />,
        section: 'requestForm',
    }, {
        name: 'Calendars',
        icon: <i className="las la-calendar-week" />,
        section: 'calendars',
    }
];


class ProgramLevelLayout extends PureComponent {
    constructor(props) {
        super(props);
        const premiumLicense = props.subscriptionType == 'Enterprise Premium';
        let budgetEnabled = premiumLicense;
        let inventoryEnabled = premiumLicense;
        let gamificationEnabled = premiumLicense;
        let scanningEnabled = premiumLicense;
        let capacityExtensionEnabled = premiumLicense;
        let customEmailsEnabled = premiumLicense;
        let ssoEnabled = premiumLicense;
        let textingEnabled = props.subscriptionAddons.find((addon) => addon.identifier == 'texting');

        if (!premiumLicense) {
            props.subscriptionAddons.forEach((addon) => {
                if (addon.identifier == 'budget') {
                    budgetEnabled = true;
                } else if (addon.identifier == 'inventory') {
                    inventoryEnabled = true;
                } else if (addon.identifier == 'gamification') {
                    gamificationEnabled = true;
                } else if (addon.identifier == 'event-pass') {
                    scanningEnabled = true;
                } else if (addon.identifier == 'space-plus') {
                    capacityExtensionEnabled = true;
                } else if (addon.identifier == 'html-emails') {
                    customEmailsEnabled = true;
                } else if (addon.identifier == 'sso') {
                    ssoEnabled = true;
                } else if (addon.identifier == 'texting') {
                    textingEnabled = true;
                }
            });
        }
        this.state = {
            orgId: this.props.match.params.organization,
            programId: this.props.match.params.program,
            program: {},
            permittedAddons: {
                budgetEnabled: budgetEnabled,
                inventoryEnabled: inventoryEnabled,
                gamificationEnabled: gamificationEnabled,
                scanningEnabled: scanningEnabled,
                capacityExtensionEnabled: capacityExtensionEnabled,
                customEmailsEnabled: customEmailsEnabled,
                ssoEnabled: ssoEnabled,
                textingEnabled: textingEnabled

            },
            defaultPlanNavigation: defaultPlanNavigation,
            defaultProductionNavigation: defaultProductionNavigation,
            requestNavigation: requestNavigation,
            loading: true,
        };
    }

    async componentDidMount() {
        const programId = this.props.match.params.program;
        const sectionCounts = await this.props.getSectionCounts(this.state.programId);
        const eventSectionCounts = sectionCounts.program;
        const { defaultPlanNavigation, defaultProductionNavigation, requestNavigation } = this.state;
        defaultPlanNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
        });
        defaultProductionNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
        });
        requestNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
        });
        let { sideMenuItems } = this.state;
        const { orgId, match: { params } } = this.props;
        const section = parseInt(params.section);

        if (section >= 0) {
            let sectionIndex = 0;
            let sectionName = '';
            sideMenuItems = sideMenuItems.map((emi, index) => {
                emi.target = `/${orgId}/programs/${programId}/${emi.section}/`;
                if (emi.isDropdown) {
                    emi.dropdownItems = emi.dropdownItems.map((di) => {
                        di.target = `/${orgId}/programs/${programId}/${di.section}/`;
                        if (di.section == section) {
                            sectionName = di.name;
                            sectionIndex = index;
                        }
                        return di;
                    });
                } else if (emi.section == section && !emi.isDropdown) {
                    sectionIndex = index;
                    sectionName = emi.name;
                }
                return emi;
            });
            this.setState({
                section: section,
                sectionIndex: sectionIndex,
                sectionName: sectionName,
                sideMenuItems: sideMenuItems
            });
        }
        API()
            .get(`Organizations/${orgId}/programs/${programId}`)
            .then((res) => {
                if (res.data) {
                    const mappedProgram = res.data;
                    if (mappedProgram.eventDefaults) {
                        mappedProgram.eventDefaults.attendees = mappedProgram.eventDefaults.attendees.map((a) => {
                            return {
                                ...a,
                                link: `/${orgId}/programs/${programId}/attendees/${a._id}`
                            };
                        });
                        mappedProgram.eventDefaults.sessions = mappedProgram.eventDefaults.sessions.map((a) => {
                            return {
                                ...a,
                                link: `/${orgId}/programs/${programId}/schedule/${a._id}`
                            };
                        });
                        mappedProgram.eventDefaults.groups = mappedProgram.eventDefaults.groups.map((a) => {
                            return {
                                ...a,
                                attendeeCount: a.attendees.length,
                                sessionCount: a.sessions.length,
                                capacity: a.maxCapacityEnabled ? a.maxCapacity.toString() : 'N/A',
                                link: `/${orgId}/programs/${programId}/groups/${a._id}`
                            };
                        });
                        mappedProgram.eventDefaults.pages = mappedProgram.eventDefaults.pages.map((a) => {
                            return {
                                ...a,
                                link: `/${orgId}/programs/${programId}/pages/${a._id}`
                            };
                        });
                        mappedProgram.eventDefaults.forms = mappedProgram.eventDefaults.forms.map((a) => {
                            return {
                                ...a,
                                link: `/${orgId}/programs/${programId}/forms/${a._id}`
                            };
                        });
                        mappedProgram.eventDefaults.emails = mappedProgram.eventDefaults.emails.map((a) => {
                            return {
                                ...a,
                                link: `/${orgId}/programs/${programId}/emails/${a._id}`
                            };
                        });

                        mappedProgram.eventDefaults.inventory = mappedProgram.eventDefaults.inventory.map((a) => {
                            return {
                                ...a,
                                link: `/${orgId}/programs/${programId}/inventory/${a._id}`
                            };
                        });
                    } else {
                        mappedProgram.eventDefaults = {
                            attendees: [],
                            sessions: [],
                            groups: [],
                            pages: [],
                            forms: [],
                            emails: [],
                            inventory: []
                        };
                    }
                    this.setState({ program: mappedProgram, loading: false });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({ loading: false });
            });
    }

    refreshSectionCounts = async () => {
        const sectionCounts = await this.props.getSectionCounts(this.state.programId);
        const eventSectionCounts = sectionCounts.program;
        let { defaultPlanNavigation, defaultProductionNavigation, requestNavigation } = this.state;
        defaultPlanNavigation = defaultPlanNavigation.map((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
            return navItem;
        });
        defaultProductionNavigation = defaultProductionNavigation.map((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
            return navItem;
        });
        requestNavigation = requestNavigation.map((navItem) => {
            if (navItem.section) {
                navItem.count = eventSectionCounts[navItem.section];
            }
            return navItem;
        });
        this.setState({ defaultPlanNavigation, defaultProductionNavigation, requestNavigation })
    }

    render() {
        const { organizations, permission, subscriptionAddons, subscriptionType, organization, socket, platformNotifications } = this.props;
        const { orgId, programId, program, loading } = this.state;
        return (
            <div className='application'>
                <div className="topLevelPages">
                    <div className='systemContentContainer'>
                        <SystemToolbar
                            socket={socket}
                            displayLogo={true}
                            account={this.props.account}
                            organization={organization}
                            organizations={organizations}
                            logout={this.props.logout}
                            programsAvailable={this.props.programsAvailable}
                            subscriptionAddons={this.props.subscriptionAddons}
                            permission={this.props.permission}
                            subscriptionType={this.props.subscriptionType}
                            taskCount={this.state.taskCount}
                            updateTaskCount={this.props.updateTaskCount}
                            pingServer={this.props.pingServer}
                            platformNotifications={platformNotifications}
                            markNotificationAsRead={this.props.markNotificationAsRead}

                        />
                        <div className='flex' style={{ height: 'calc(100% - 55px)' }}>

                            <div className={`sideNavigation ${loading ? 'closed' : ''}`}>
                                <div className='sideMenuContainer'>

                                    <div className='p-30 pt-15 pb-0 pr-20'>
                                        <h3 className='sideEventName'>{program.name}</h3>
                                        {/* <p className='small'>{dateTimeRangeString}</p> */}
                                    </div>
                                    <hr className='m-20 mr-0 ml-0' />
                                    <TopLevelNavLink2
                                        orgId={orgId}
                                        menuItem={{
                                            name: 'Program',
                                            icon: <i className="las la-stream" />,
                                            section: 'overview',
                                        }}
                                        prefix={`/${orgId}/programs/${programId}`}
                                        permission={permission}
                                        key={`program-overview-nav`}
                                    />
                                    <h5 className='mb-10 mt-20  sideNaveColor'>Event Plan</h5>
                                    {defaultPlanNavigation.map((menuItem, index) => {
                                        return (permission || !menuItem.adminOnly) &&
                                            ((subscriptionType != 'Enterprise Premium' &&
                                                menuItem.addon &&
                                                subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                                                !menuItem.addon ||
                                                subscriptionType == 'Enterprise Premium') ? (
                                            <TopLevelNavLink2
                                                isLast={index == defaultPlanNavigation.length - 1}
                                                orgId={orgId}
                                                menuItem={menuItem}
                                                prefix={`/${orgId}/programs/${programId}`}
                                                index={index}
                                                permission={permission}
                                                key={`program-event-plan-nav-${index}`}
                                            />
                                        ) : null;
                                    })}
                                    <h5 className='mb-10 mt-20 sideNaveColor'>Templates</h5>
                                    {defaultProductionNavigation.map((menuItem, index) => {
                                        return (permission || !menuItem.adminOnly) &&
                                            ((subscriptionType != 'Enterprise Premium' &&
                                                menuItem.addon &&
                                                subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                                                !menuItem.addon ||
                                                subscriptionType == 'Enterprise Premium') ? (
                                            <TopLevelNavLink2
                                                isLast={index == defaultProductionNavigation.length - 1}
                                                menuItem={menuItem}
                                                index={index}
                                                prefix={`/${orgId}/programs/${programId}`}
                                                permission={permission}
                                                key={`program-event-template-nav-${index}`}
                                            />
                                        ) : null;
                                    })}
                                    <h5 className='mb-10 mt-20 sideNaveColor'>Manage</h5>
                                    {requestNavigation.map((menuItem, index) => {
                                        return (permission || !menuItem.adminOnly) &&
                                            ((subscriptionType != 'Enterprise Premium' &&
                                                menuItem.addon &&
                                                subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                                                !menuItem.addon ||
                                                subscriptionType == 'Enterprise Premium') ? (
                                            <TopLevelNavLink2
                                                isLast={index == requestNavigation.length - 1}
                                                menuItem={menuItem}
                                                index={index}
                                                prefix={`/${orgId}/programs/${programId}`}
                                                permission={permission}
                                                key={`program-manage-nav-${index}`}
                                            />
                                        ) : null;
                                    })}
                                </div>
                            </div>
                            {!loading && <Program orgId={orgId} addNewField={(type, data, cb) => { cb && cb() }} account={this.props.account} organization={organization} refreshSectionCounts={this.refreshSectionCounts} socket={this.props.socket} />}
                            {loading && <div className='pageContainer'><LoadingWizard text="LOADING PROGRAM" loading={loading} /></div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProgramLevelLayout);
