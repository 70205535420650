import React from 'react';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { Trash, Image, MoreHorizontal } from 'react-feather';

class Venue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deleteOpen: false
		};
	}

	deleteVenue = () => {
		this.props.onDeleteClick(this.props._id);
		this.toggleDeletePopover();
	};

	toggleDeletePopover = () => {
		this.setState({ deleteOpen: !this.state.deleteOpen });
	};

	render() {
		const { _id, name, address, website, locations, image, count } = this.props;
		const { deleteOpen } = this.state;
		return (
			<li
				className={'list-group-item list-group-item-action mb-3 col-4 '}
				style={{ padding: 0, maxHeight: '120px', transform: 'scaleX(0.95)' }}
			>
				<Row style={{ margin: 0 }}>
					<Col
						xs={7}
						onClick={() => {
							window.location.href = `venues/${_id}`;
						}}
					>
						<p
							className="text-truncate"
							style={{ marginBottom: '-4px', fontSize: '16px', fontWeight: '600' }}
						>
							{name}
						</p>
						{address && (
							<p
								className="text-truncate"
								style={{ marginBottom: '-8px', fontSize: '12px', fontWeight: '400' }}
							>
								<span style={{ fontWeight: '100' }}>{`${address.address1 || ''} ${address.address2 ||
									''}`}</span>
								<span style={{ fontWeight: '100' }}>{`${address.city || ''} ${address.state ||
									''} ${address.postalCode || ''}`}</span>
							</p>
						)}
						<p
							className="text-truncate"
							style={{ marginBottom: '-8px', fontSize: '12px', fontWeight: '400' }}
						>
							{website}
						</p>
						<p
							className="text-truncate"
							style={{ marginBottom: '-8px', fontSize: '12px', fontWeight: '400' }}
						>
							Locations: {locations.length}
						</p>
					</Col>
					<Col xs={1}>
						<MoreHorizontal
							size={18}
							onClick={this.toggleDeletePopover}
							className="float-right mt-1 mb-2 width-25 d-block"
							id={`venue_${count}`}
							/* style={{ color: '#FF586B' }} */ style={{ color: '#000' }}
						/>
						<Popover
							placement="top"
							isOpen={deleteOpen}
							target={`venue_${count}`}
							toggle={this.toggleDeletePopover}
						>
							<PopoverBody>
								<span style={{ color: 'red', fontSize: '12px' }} onClick={this.deleteEvent}>
									<Trash size={14} /> Delete
								</span>
							</PopoverBody>
						</Popover>
					</Col>
					{image ? (
						<div
							className="col-4"
							style={{
								height: '120px',
								width: '120px',
								padding: 0,
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								backgroundImage: `url('${image}')`
							}}
						/>
					) : (
						<Col
							xs={4}
							className="gradient-indigo-dark-blue float-right"
							style={{ height: '120px', padding: '10px', textAlign: 'center' }}
						>
							<Image
								color="white"
								style={{
									height: '30px',
									marginTop: '30px'
								}}
							/>
							{/* <img
							src={require('../../assets/img/icons/image-solid.png')}
							alt="Event"
							style={{
								height: '30px',
								marginTop: '30px'
							}}
						/> */}
						</Col>
					)}
				</Row>
			</li>
		);
	}
}

Venue.propTypes = {
	_id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	address: PropTypes.object,
	website: PropTypes.string,
	locations: PropTypes.array
};

export default Venue;
