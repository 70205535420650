import React, { Component, Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Row, Button } from 'reactstrap';
import Select from 'react-select';
import API from '../../../utils/API';
import { Trash2, Plus, CheckCircle, X, Upload, PlusCircle } from 'react-feather';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import FieldModal from '../../../components/modals/fieldModal';
import InputField from '../../../components/inputs/inputField';

class EventSessionSurveys extends React.Component {
	constructor(props) {
		super(props);

		const columns = {
			question: 'Question',
			answers: 'Answers',
			correctAnswer: 'Correct Answer'
		};
		const columnTypes = {
			question: 'text',
			answers: 'text',
			correctAnswer: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			survey: props.editedSession.survey,
			showNewSurveyModal: false,
			showAddSurveyQuestionModal: false,
			showRemoveSurveyQuestionModal: false,
			showEditSurveyQuestionModal: false,
			markedSurveyQuestion: null,
			toggledColumns: toggledColumns,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			checkedQuestions: []
		};
	}

	toggleAddSurvey = () => {
		this.setState({ showNewSurveyModal: !this.state.showNewSurveyModal });
	};

	toggleAddSurveyQuestion = () => {
		this.setState({ showAddSurveyQuestionModal: !this.state.showAddSurveyQuestionModal });
	};
	toggleEditSurveyQuestion = (questionId) => {
		const { survey } = this.state;

		if (questionId) {
			this.setState({
				showEditSurveyQuestionModal: true,
				markedSurveyQuestion: survey.questions.find((q) => q._id === questionId)
			});
		} else {
			this.setState({
				showEditSurveyQuestionModal: false,
				markedSurveyQuestion: null
			});
		}
	};

	toggleRemoveSurveyQuestion = () => {
		this.setState({
			showRemoveSurveyQuestionModal: !this.state.showRemoveSurveyQuestionModal
		});
	};

	removeSurveyQuestions = () => {
		const { checkedQuestions } = this.state;
		const survey = this.state.survey;
		survey.questions = survey.questions.filter((q) => !Object.keys(checkedQuestions).includes(q._id));
		this.setState({ survey: survey, checkedQuestions: [] });
	};

	addSurveyQuestion = (surveyQuestion) => {
		const survey = this.state.survey;
		survey.questions.push(surveyQuestion);
		this.setState({ survey: survey });
	};

	QuestionItem(question, index) {
		return (
			<tr key={index}>
				<td className="text-truncate">{question.question}</td>
				<td className="text-truncate">
					{question.answers.map((r) => (
						<div className="flex-ac">
							{r}
							{r === question.correctAnswer && (
								<i className="las la-check-circle ml-10" style={{ color: 'green' }} />
							)}
						</div>
					))}
				</td>
				<td className="text-truncate">{question.correctAnswer}</td>
				<td>
					<div className="flex-ac" style={{ justifyContent: 'space-between' }}>
						<i
							className="las la-pen c-pointer"
							style={{ color: 'blue' }}
							onClick={() => this.toggleEditSurveyQuestion(question)}
						/>
						<i
							className="las la-trash c-pointer"
							style={{ color: 'red' }}
							onClick={() => this.toggleRemoveSurveyQuestion(question)}
						/>
					</div>
				</td>
			</tr>
		);
	}

	updatecheckedQuestions = (checked) => {
		this.setState({ checkedQuestions: checked });
	};

	render() {
		const {
			survey,
			showNewSurveyModal,
			showRemoveSurveyQuestionModal,
			showAddSurveyQuestionModal,
			showEditSurveyQuestionModal,
			markedSurveyQuestion,
			normalizedColumns,
			toggledColumns,
			columns
		} = this.state;

		let fixedSurvey = survey
			? survey.questions.map((q) => {
				return {
					question: q.question,
					answers: String(q.answers),
					correctAnswer: q.correctAnswer,
					_id: q._id
				};
			})
			: [];

		let mainActions = [];
		mainActions = [{ onClick: this.toggleAddSurveyQuestion, label: 'Add Question' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Delete Question',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleRemoveSurveyQuestion
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedQuestions).length;
		entriesLength = fixedSurvey.length;
		uncheckEntries = () => {
			this.setState({ checkedQuestions: [] });
		};
		searchEnabled = false;

		return (
			< div className='sectionBody posRel mb-0 p-0' >
				<ActionButtonContainer
					mainActions={mainActions}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>

				<ViewsTable
				title="Survey Questions"
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={fixedSurvey}
					classes="nested"
					mainColumnActive={false}
					checked={this.state.checkedQuestions}
					onClick={(id) => this.toggleEditSurveyQuestion(id)}
					checkRow={(entryId) => {
						const checked = this.state.checkedQuestions;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatecheckedQuestions(checked);
						this.setState({
							checkedQuestions: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedQuestions;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatecheckedQuestions(checked);

						this.setState({
							checkedQuestions: checked
						});
					}}
					sortData={(column, sortAsc, sortDesc) => {
						/* const sortedData = this.sortData(
							column,
							sortAsc,
							sortDesc,
							editedGroup.sessions,
							normalizedColumns
						);
						this.setState({ editedGroup: { ...editedGroup, sessions: sortedData.organizedEntries } }); */
					}}
				/>
				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
				/>
				{showNewSurveyModal && (
					<SessionAddSurveyModal
						isOpen={showNewSurveyModal}
						addSurvey={(s) => this.setState({ survey: s })}
						toggle={this.toggleAddSurvey}
						session={this.props.editedSession}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updateSession={this.props.updateSession}
					/>
				)}
				{showAddSurveyQuestionModal && (
					<SessionAddSurveyQuestionModal
						session={this.props.editedSession}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						isOpen={showAddSurveyQuestionModal}
						sessionSurvey={survey}
						toggle={this.toggleAddSurveyQuestion}
						updateSurvey={(survey) => {
							var updated = (this.props.editedSession.survey = survey);
							this.setState({ survey: updated });
						}}
					/>
				)}

				{showEditSurveyQuestionModal && (
					<SessionEditSurveyQuestionModal
						session={this.props.editedSession}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						isOpen={showEditSurveyQuestionModal}
						sessionSurvey={survey}
						toggle={() => {
							this.toggleEditSurveyQuestion();
						}}
						question={markedSurveyQuestion}
						updateSurvey={(survey) => this.setState({ survey: survey })}
					/>
				)}
				{showRemoveSurveyQuestionModal && (
					<SessionRemoveSurveyQuestionModal
						isOpen={showRemoveSurveyQuestionModal}
						remove={this.removeSurveyQuestions}
						toggle={this.toggleRemoveSurveyQuestion}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updateSession={this.props.updateSession}
						surveys={this.props.event.surveys}
						question={markedSurveyQuestion}
						session={this.props.editedSession}
						checkedQuestions={this.state.checkedQuestions}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionSurveys;

class SessionAddSurveyModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			submitting: false,
			invalid: false,
			success: false
		};
	}

	add = async () => {
		const { eventId, orgId, session, toggle } = this.props;
		const { name } = this.state;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				request: 'createSurvey',
				surveyName: name
			})
			.then((res) => {
				if (res.data) {
					this.props.addSurvey(res.data);
					toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, submitting, invalid } = this.state;

		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add Survey"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Survey Name"
								value={name}
								errorMessage={invalid}
								onChange={(e) => this.setState({ name: e.target.value })}
								placeholder="Survey Name"
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
				actionButton={() => {
					this.setState({ submitting: true });
					if (name != '') {
						this.add();
					} else {
						this.setState({ invalid: true, submitting: false });
					}
				}}

				actionButtonDisabled={submitting}

			></FieldModal>

		);
	}
}

class SessionAddSurveyQuestionModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			question: '',
			answers: [],
			newAnswer: '',
			correctAnswer: '',
			submitting: false,
			invalid: false,
			success: false
		};
	}

	handleQuestionChange = (e) => {
		this.setState({ question: e.target.value });
	};

	add = async () => {
		const { eventId, orgId, session } = this.props;
		const { question, answers, correctAnswer } = this.state;
		if (question !== '' && answers.length > 1) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
					request: 'addSurveyQuestion',
					surveyQuestion: {
						correctAnswer: correctAnswer,
						question: question,
						answers: answers
					}
				})
				.then((res) => {
					if (res.data) {
						const updatedSurvey = res.data;
						this.props.updateSurvey(updatedSurvey);
						this.props.toggle();
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({
				invalid: true,
				submitting: false
			});
		}
	};

	addResponse = () => {
		const { answers, newAnswer } = this.state;
		if (newAnswer !== '' && !answers.includes(newAnswer)) {
			answers.push(newAnswer);
			this.setState({ answers: answers, newAnswer: '' });
		}
	};

	markAsCorrect = (answer) => {
		if (this.state.correctAnswer === answer) {
			this.setState({ correctAnswer: '' });
		} else this.setState({ correctAnswer: answer });
	};

	removeAnswer = (answer) => {
		const updatedAnswers = this.state.answers.filter((a) => a !== answer);
		if (this.state.correctAnswer === answer) {
			this.setState({ correctAnswer: '', answers: updatedAnswers });
		} else {
			this.setState({ answers: updatedAnswers });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { question, answers, newAnswer, correctAnswer, submitting, invalid } = this.state;

		return (
			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Question"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Survey Question"
									value={question}
									onChange={this.handleQuestionChange}
									placeholder="Survey Question"
									errorMessage={'Question and at least 2 answers required'}
									errorTrigger={invalid}
									required={true}
								/>
								<label className="displayLabel">Answers (toggle check to mark as correct)</label>
								{answers.map((answer, i) => (
									<div className="flex-ac w-100">
										<i
											className="las la-minus-circle mr-10 c-pointer mb-25"
											onClick={() => this.removeAnswer(answer)}
											style={{ color: 'red', marginBottom: 25 }}
										/>

										<InputField
											value={answer}
											onChange={(e) => {
												let { answers, correctAnswer } = this.state;
												if (answers[i] == correctAnswer) {
													correctAnswer = e.target.value;
												}
												answers[i] = e.target.value;
												this.setState({ answers: answers });
											}}
										/>

										<button className={`p-7 ml-10 ${correctAnswer === answer ? 'prim' : ''} mb-25`}
												onClick={() => this.markAsCorrect(answer)}>
											<i
												className="las la-check-circle"
											/>
										</button>
									</div>
								))}
								<div className="formField mt-25">
									<div className="flex-ac w-100">
										<InputField
											value={newAnswer}
											onChange={(e) => this.setState({ newAnswer: e.target.value })}
											placeholder="New Answer"
										/>
										<i
											className="las la-plus-circle ml-10 c-pointer mb-25"
											style={{ marginBottom: 25 }}
											onClick={this.addResponse}
										/>
									</div>
								</div>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => {
						this.setState({ submitting: true });
						if (question.name !== '' && answers.length > 0) {
							this.add();
						} else {
							this.setState({ invalid: true, submitting: false });
						}
					}}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

class SessionEditSurveyQuestionModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			question: props.question.question,
			answers: props.question.answers,
			newAnswer: '',
			correctAnswer: props.question.correctAnswer,
			submitting: false,
			invalid: false,
			success: false,
			isDuplicate: false
		};
	}

	handleQuestionChange = (e) => {
		this.setState({ question: e.target.value });
	};

	edit = async () => {
		const { eventId, orgId, session } = this.props;
		const { question, answers, correctAnswer } = this.state;
		if (question !== '' && answers.length > 1) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
					request: 'editSurveyQuestion',
					surveyQuestion: {
						_id: this.props.question._id,
						correctAnswer: correctAnswer,
						question: question,
						answers: answers
					}
				})
				.then((res) => {
					const updatedSurvey = this.props.sessionSurvey;
					updatedSurvey.questions = updatedSurvey.questions.map(
						(q) =>
							q._id == this.props.question._id
								? {
									_id: this.props.question._id,
									correctAnswer: correctAnswer,
									question: question,
									answers: answers
								}
								: q
					);
					this.props.updateSurvey(updatedSurvey);
					this.props.toggle();
				})
				.catch((e) => {
					console.log(e);
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({
				invalid: true,
				submitting: false
			});
		}
	};

	addResponse = () => {
		const { answers, newAnswer } = this.state;
		if (newAnswer !== '' && !answers.includes(newAnswer)) {
			answers.push(newAnswer);
			this.setState({ answers: answers, newAnswer: '', isDuplicate: false });
		} else {
			if (answers.includes(newAnswer)) {
				this.setState({ isDuplicate: true });
			}
		}
	};

	markAsCorrect = (answer) => {
		if (this.state.correctAnswer === answer) {
			this.setState({ correctAnswer: '' });
		} else this.setState({ correctAnswer: answer });
	};

	removeAnswer = (answer) => {
		const updatedAnswers = this.state.answers.filter((a) => a !== answer);
		if (this.state.correctAnswer === answer) {
			this.setState({ correctAnswer: '', answers: updatedAnswers });
		} else {
			this.setState({ answers: updatedAnswers });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { question, answers, isDuplicate, newAnswer, correctAnswer, submitting, invalid } = this.state;

		return (
			<div>
				<FieldModal
					size='medium'
					isOpen={isOpen}
					modalTitle={"Edit Question"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Survey Question"
									value={question}
									onChange={this.handleQuestionChange}
									placeholder="Survey Question"
								/>

								<label className="displayLabel">Answers (toggle check to mark as correct)</label>
								{answers.map((answer, i) => (
									<div className="flex-ac w-100">
										<i
											className="las la-minus-circle mr-10 c-pointer"
											onClick={() => this.removeAnswer(answer)}
											style={{ color: 'red', marginBottom: 25 }}
										/>

										<InputField
											value={answer}
											onChange={(e) => {
												let { answers, correctAnswer } = this.state;
												if (answers[i] == correctAnswer) {
													correctAnswer = e.target.value;
												}
												answers[i] = e.target.value;
												this.setState({ answers: answers });
											}}
										/>
										<button className={`p-7 ml-10 ${correctAnswer === answer ? 'prim' : ''} mb-25`}
												onClick={() => this.markAsCorrect(answer)}>
											<i
												className="las la-check-circle"
											/>
										</button>
									</div>
								))}
								<div className="formField mt-25">
									<div className="flex-ac w-100">
										<InputField
											value={newAnswer}
											onChange={(e) => this.setState({ newAnswer: e.target.value })}
											placeholder="New Answer"
										/>
										<i
											className="las la-plus-circle ml-10 c-pointer mb-25"
											style={{ marginBottom: 25 }}
											onClick={this.addResponse}
										/>
									</div>
								</div>
								{invalid && (
									<div className="calibreRegular modalErrorMessage">Question and at least 2 answers required</div>
								)}
								{isDuplicate && <div className="calibreRegular modalErrorMessage">All answers must be unique</div>}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => {
						this.setState({ submitting: true });
						if (question.name !== '' && answers.length > 0) {
							this.edit();
						} else {
							this.setState({ invalid: true, submitting: false });
						}
					}}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

class SessionRemoveSurveyQuestionModal extends Component {
	state = {
		submitting: false
	};

	removeQuestion = async () => {
		const { eventId, orgId, question, session, checkedQuestions } = this.props;
		this.setState({ submitting: true })
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				request: 'removeSurveyQuestions',
				surveyQuestions: Object.keys(checkedQuestions)
			})
			.then((res) => {
				this.props.remove();
				this.props.toggle();
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Question From Survey"}
					bodyHeaderText={<span>You are about to delete this question from this survey, this will delete <b>ALL</b> submissions
						associated as well. Would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Remove' : 'Removing...'}
					actionButton={() => this.removeQuestion()}
					isDelete={true}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}
