import React, { Component } from 'react';
import Select from 'react-select';
import { Input, FormGroup, Label, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import CheckboxInput from '../inputs/checkboxInput';
import AddVenueModal from './modals/addVenueModal';
import AddLocationModal from './modals/addLocationModal';

import RenameVenueModal from './modals/renameVenueModal';
import RenameLocationModal from './modals/renameLocationModal';

import DeleteVenueModal from './modals/deleteVenueModal';
import DeleteLocationModal from './modals/deleteLocationModal';

import InputField from '../inputs/inputField';



class VenuesLocationField extends Component {
    constructor(props) {


        super(props);

        this.state = {
            searchTerm: '',
            toggleAddVenue: false,
            toggleAddLocation: false,
            toggleRenameVenue: false,
            toggleRenameLocation: false,
            toggleDeleteVenue: false,
            toggleDeleteLocation: false,
            currentVenue: {},
            currentLocation: {},
            loading: true,
            selectedVenue: null,
            focusedLocation: '',
            dropDownOpen: false,
            toggleLocationMenu: false

        };
        this.wrapperRef = React.createRef(); // Create a ref to attach to the dropdown
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setState({ loading: false, currentVenue: this.props.selectedVenue ? this.props.venueOptions.find(v => v._id == this.props.selectedVenue._id) : '' }, () => {

            if (this.props.isLocation) {
                this.setState({ currentLocation: this.state.currentVenue.locations && this.props.sessionLocationId ? this.state.currentVenue.locations.find(l => l._id == this.props.sessionLocationId) : {} })

            }
        });
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        if (
            (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target))) {
            this.setState({ toggleLocationMenu: false });
        }
    }
    toggleAddVenue = () => {
        const { toggleAddVenue } = this.state;
        this.setState({ toggleAddVenue: !toggleAddVenue })

    }
    toggleAddLocation = () => {

        const { toggleAddLocation } = this.state;
        this.setState({ toggleAddLocation: !toggleAddLocation })

    }
    toggleRenameVenue = () => {
        const { toggleRenameVenue } = this.state;
        this.setState({ toggleRenameVenue: !toggleRenameVenue })

    }
    toggleRenameLocation = () => {
        const { toggleRenameLocation } = this.state;
        this.setState({ toggleRenameLocation: !toggleRenameLocation })

    }
    toggleDeleteVenue = () => {
        const { toggleDeleteVenue } = this.state;
        this.setState({ toggleDeleteVenue: !toggleDeleteVenue })

    }
    toggleDeleteLocation = () => {
        const { toggleDeleteLocation } = this.state;
        this.setState({ toggleDeleteLocation: !toggleDeleteLocation })
    }

    toggleLocationMenu = () => {
        const { toggleLocationMenu } = this.state
        this.setState({ toggleLocationMenu: !toggleLocationMenu })
    }
    renderToggleLocationItem = (location) => {
        const { selectedVenue, locationId, sessionLocationId } = this.props;
        const { currentLocation } = this.state;
        return (
            <UncontrolledDropdown style={{ background: 'unset' }}>
                <div style={{ cursor: 'pointer', maxHeight: 50 }} className=" checkmarkItem flex-sb flex-ac">
                    <DropdownItem key={`colToggle-${location._id}`} className={` w-100`} onClick={() => {
                        this.setState({ currentLocation: location })
                        this.props.selectLocation(location)

                    }}>
                        {location.name}
                    </DropdownItem>
                    <div className="mr-10">
                        <UncontrolledDropdown className="accountDropdown">
                            <DropdownToggle className="columnToggle">
                                <i className="las la-ellipsis-h" />
                            </DropdownToggle>
                            <DropdownMenu right className="columnDropdown hideX ph-20">

                                <DropdownItem className="leftAlign"
                                    onClick={() => {
                                        this.setState({ focusedLocation: location })
                                        this.toggleRenameLocation();
                                    }}>
                                    <i className="las la-pen mr-10" /> Rename
                                </DropdownItem>

                                <DropdownItem className="leftAlign"
                                    onClick={() => {
                                        this.setState({ focusedLocation: location })

                                        this.toggleDeleteLocation();
                                    }}>
                                    <i className="las la-trash mr-10" /> Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div >
            </UncontrolledDropdown>
        )

    }

    renderToggleVenueItem = (venue, index) => {
        const { selectedVenue } = this.props;
        const { currentVenue } = this.state;
        return (
            <div style={{ cursor: 'pointer' }} className=" checkmarkItem flex-sb flex-ac">
                <DropdownItem key={`colToggle-${index}`} className={`w-100`} onClick={() => {
                    this.setState({ currentVenue: venue })
                    this.props.selectVenue(venue)

                }} style={{ textAlign: 'left' }} >
                    {venue.name}
                </DropdownItem>
                <div className="mr-10">
                    <UncontrolledDropdown direction="up" className="accountDropdown">
                        <DropdownToggle className="columnToggle p-7">
                            <i className="las la-ellipsis-h" />
                        </DropdownToggle>
                        <DropdownMenu right className="columnDropdown hideX ph-20">

                            <DropdownItem className="leftAlign"
                                onClick={() => {
                                    this.setState({ focusedVenue: venue })
                                    this.toggleRenameVenue();
                                }}>
                                <i className="las la-pen mr-10" /> Rename
                            </DropdownItem>

                            <DropdownItem className="leftAlign"
                                onClick={() => {
                                    this.setState({ focusedVenue: venue })

                                    this.toggleDeleteVenue();
                                }}>
                                <i className="las la-trash mr-10" /> Delete
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div >
        )
    }

    addNewLocation = (location) => {
        this.props.addNewLocation(location);
        this.setState({ currentLocation: location })
        console.log('location', location);
        this.props.selectLocation(location)

    }

    render() {
        const {
            style,
            classes,
            venueOptions,
            selectedVenue,
            isLocation,
            sessionLocationId,
            venuePicked,

        } = this.props;
        const { searchTerm, toggleAddVenue, currentVenue, toggleRenameVenue, dropDownOpen, toggleDeleteVenue, focusedVenue, loading, focusedLocation, toggleAddLocation, currentLocation, toggleRenameLocation,
            toggleDeleteLocation,
            focusedlocation, toggleLocationMenu } = this.state;
        let venue = !loading && currentVenue ? venueOptions.find(v => v._id == currentVenue._id) : '';
        let venueName = selectedVenue ? selectedVenue.name : '';

        let locations = selectedVenue ? selectedVenue.locations ? selectedVenue.locations : [] : [];
        const foundLocation = locations.find(l => l._id == sessionLocationId)
        let locationName = !loading && locations && sessionLocationId && foundLocation ? foundLocation.name : '';

        if (searchTerm.length > 0) {
            locations = locations.filter(location => location.name.toLowerCase().includes(searchTerm.toLowerCase()))
        }
        locations = locations.sort((a, b) => a.name.localeCompare(b.name));
        return (

            <div className={`formFieldContainerField selectField ${classes}`} ref={this.wrapperRef}>
                <div className='formField'>
                    <div className='labelContainer'>
                        <div className='formFieldLabel'>
                            {isLocation ? 'Location' : 'Venue'}
                        </div>
                    </div>
                </div>
                {!isLocation ? (
                    <UncontrolledDropdown isOpen={dropDownOpen} toggle={(s) => {
                        this.setState({ dropDownOpen: !dropDownOpen });

                    }} inNavbar>
                        <DropdownToggle className={`selectVenuesLocationField formFieldContainer selectField ${!true ? 'selected' : ''}`}>
                            {venueName}
                            <i className=" venuesIcon las la-angle-down ml-a"></i>
                        </DropdownToggle>
                        <DropdownMenu left="true" className="columnDropdown p-10 w-100">
                            { }
                            <div className="columnToggleContainer" style={{ paddingTop: 20, marginTop: 'unset' }}>
                                {
                                    venueOptions.map((venue, i) => {
                                        return (
                                            <div key={`${venue && venue._id || ''}-${i}`}>
                                                {this.renderToggleVenueItem(venue, i)}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div
                                className={`actionButton actionOutlineBlack w-100 mt-10`}
                                onClick={() => {
                                    this.toggleAddVenue()
                                }}
                            >
                                <div>Add new venue</div>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                ) : (<div >
                    <div style={{ width: '100%', position: 'relative', height: 40, background: 'var(--input)', padding: '0 15px', borderRadius: 'var(--input-border-radius)' }} className={`  selectVenuesLocationField formFieldContainer selectField  ${!true ? 'selected' : ''}`}>
                        <div className='w-100 h-100 flex aic' onClick={this.toggleLocationMenu}>
                            {locationName}
                            <i className="las la-angle-down ml-a"></i>
                        </div>
                        {toggleLocationMenu && <div style={{ boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3843137255)', width: '100%', transform: 'unset', position: 'absolute', top: 40, left: 0, zIndex: 1 }} left="true" className="columnDropdown p-10">
                            <InputField
                                classes="m-0 w-100 viewSearch"
                                placeholder="Search "
                                clickIcon={true}
                                required={true}
                                value={searchTerm}
                                onChange={(e) => this.setState({ searchTerm: e.target.value })}
                                prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
                                inputIcon={searchTerm != '' &&
                                    <i
                                        onMouseDown={() => this.setState({ searchTerm: '' })}
                                        className="las la-times-circle"
                                        style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
                                    />}
                            />
                            <div className="columnToggleContainer" style={{ paddingTop: 20, marginTop: 'unset' }}>

                                {
                                    locations.map((location, i) => {
                                        return (
                                            <div key={`${location}-${i}`}>
                                                {this.renderToggleLocationItem(location)}
                                            </div>
                                        );
                                    })}

                            </div>
                            <div
                                className={`actionButton actionOutlineBlack w-100 mt-10`}
                                onClick={() => {
                                    this.toggleAddLocation()
                                }}
                            >
                                <div >Add new location</div>
                            </div>


                        </div>}
                    </div>
                </div>)
                }
                {
                    toggleAddVenue &&
                    <AddVenueModal
                        orgId={this.props.orgId}
                        isOpen={toggleAddVenue}
                        toggle={this.toggleAddVenue}
                        addNewVenue={this.props.addNewVenue}
                    />
                }
                {
                    toggleAddLocation &&
                    <AddLocationModal
                        orgId={this.props.orgId}
                        venue={currentVenue}
                        isOpen={toggleAddLocation}
                        toggle={this.toggleAddLocation}
                        addNewLocation={this.addNewLocation}
                    />
                }
                {
                    toggleRenameVenue &&
                    <RenameVenueModal
                        orgId={this.props.orgId}
                        venue={focusedVenue}
                        isOpen={toggleRenameVenue}
                        toggle={this.toggleRenameVenue}
                        renameVenue={this.props.renameVenue}
                    />
                }
                {
                    toggleRenameLocation &&
                    <RenameLocationModal
                        orgId={this.props.orgId}
                        venue={currentVenue}
                        location={focusedLocation}
                        isOpen={toggleRenameLocation}
                        toggle={this.toggleRenameLocation}
                        renameLocation={(location) => {
                            this.setState({ currentLocation: location })
                            this.props.renameLocation(location);
                        }}
                    />
                }

                {
                    toggleDeleteVenue &&
                    <DeleteVenueModal
                        orgId={this.props.orgId}
                        venue={focusedVenue}
                        isOpen={toggleDeleteVenue}
                        toggle={this.toggleDeleteVenue}
                        deleteVenue={(venueId) => {
                            this.setState({ currentVenue: this.props.selectedVenue ? this.props.venueOptions.find(v => v._id == this.props.selectedVenue._id) : {} }, this.props.deleteVenue(venueId))

                        }}
                    />
                }
                {
                    toggleDeleteLocation &&
                    <DeleteLocationModal
                        orgId={this.props.orgId}
                        venue={currentVenue}
                        location={focusedLocation}
                        isOpen={toggleDeleteLocation}
                        toggle={this.toggleDeleteLocation}
                        deleteLocation={(venueId, locationId) => {
                            this.setState({ currentLocation: this.props.selectedVenue ? this.props.venueOptions.find(v => v._id == this.props.selectedVenue._id) : {} }, this.props.deleteLocation(venueId, locationId))

                        }}
                    />
                }
            </div >
        );
    }
}

export default VenuesLocationField;
