import React, { Component } from 'react';
import Select, { components } from 'react-select';
import moment from 'moment';

// an array of 24 hours in 30 minute increments
const defaultTimeOptions = [
	{ value: '12:00 AM', label: '12:00 AM' },
	{ value: '12:30 AM', label: '12:30 AM' },
	{ value: '1:00 AM', label: '1:00 AM' },
	{ value: '1:30 AM', label: '1:30 AM' },
	{ value: '2:00 AM', label: '2:00 AM' },
	{ value: '2:30 AM', label: '2:30 AM' },
	{ value: '3:00 AM', label: '3:00 AM' },
	{ value: '3:30 AM', label: '3:30 AM' },
	{ value: '4:00 AM', label: '4:00 AM' },
	{ value: '4:30 AM', label: '4:30 AM' },
	{ value: '5:00 AM', label: '5:00 AM' },
	{ value: '5:30 AM', label: '5:30 AM' },
	{ value: '6:00 AM', label: '6:00 AM' },
	{ value: '6:30 AM', label: '6:30 AM' },
	{ value: '7:00 AM', label: '7:00 AM' },
	{ value: '7:30 AM', label: '7:30 AM' },
	{ value: '8:00 AM', label: '8:00 AM' },
	{ value: '8:30 AM', label: '8:30 AM' },
	{ value: '9:00 AM', label: '9:00 AM' },
	{ value: '9:30 AM', label: '9:30 AM' },
	{ value: '10:00 AM', label: '10:00 AM' },
	{ value: '10:30 AM', label: '10:30 AM' },
	{ value: '11:00 AM', label: '11:00 AM' },
	{ value: '11:30 AM', label: '11:30 AM' },
	{ value: '12:00 PM', label: '12:00 PM' },
	{ value: '12:30 PM', label: '12:30 PM' },
	{ value: '1:00 PM', label: '1:00 PM' },
	{ value: '1:30 PM', label: '1:30 PM' },
	{ value: '2:00 PM', label: '2:00 PM' },
	{ value: '2:30 PM', label: '2:30 PM' },
	{ value: '3:00 PM', label: '3:00 PM' },
	{ value: '3:30 PM', label: '3:30 PM' },
	{ value: '4:00 PM', label: '4:00 PM' },
	{ value: '4:30 PM', label: '4:30 PM' },
	{ value: '5:00 PM', label: '5:00 PM' },
	{ value: '5:30 PM', label: '5:30 PM' },
	{ value: '6:00 PM', label: '6:00 PM' },
	{ value: '6:30 PM', label: '6:30 PM' },
	{ value: '7:00 PM', label: '7:00 PM' },
	{ value: '7:30 PM', label: '7:30 PM' },
	{ value: '8:00 PM', label: '8:00 PM' },
	{ value: '8:30 PM', label: '8:30 PM' },
	{ value: '9:00 PM', label: '9:00 PM' },
	{ value: '9:30 PM', label: '9:30 PM' },
	{ value: '10:00 PM', label: '10:00 PM' },
	{ value: '10:30 PM', label: '10:30 PM' },
	{ value: '11:00 PM', label: '11:00 PM' },
	{ value: '11:30 PM', label: '11:30 PM' }
];

const DropdownIndicator = (props) => {
	let iconClass = props.selectProps.iconClass ? props.selectProps.iconClass : 'las la-clock';
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<i className={iconClass} />
			</components.DropdownIndicator>
		)
	);
};

class TimeField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeInput: null
		};
	}

	handleTimeChange = (e) => {
		const newTime = {
			label: e,
			color: '#00B8D9',
			new: true
		};
		this.setState({
			timeInput: newTime
		});
	};

	chooseTime = (e) => {

		let selectedTime = e.label.toUpperCase();

		if (selectedTime.charAt(selectedTime.indexOf('AM') - 1) != ' ') {
			selectedTime = selectedTime.replace('AM', ' AM');
		} else if (selectedTime.charAt(selectedTime.indexOf('PM') - 1) != ' ') {
			selectedTime = selectedTime.replace('PM', ' PM');
		}
		const today = moment().format('YYYY-MM-DD');
		const time = moment(`${today} ${selectedTime}`, 'YYYY-MM-DD hh:mm A').format('h:mm A');

		if (time == 'Invalid date') {
			this.props.onChange(this.props.value);
		} else {
			this.props.onChange(time);
		}
		this.setState({
			timeInput: null
		});
	};

	render() {
		const {
			label,
			errorTrigger,
			errorMessage,
			value,
			disabled,
			placeholder,
			style,
			name,
			noOptionsMessage,
			required,
			classes,
			iconClass
		} = this.props;

		const today = moment().format('YYYY-MM-DD');
		const currentTime = moment(`${today} ${value}`).format('h:mm A');
		let timeOptions = [];
		if (this.props.timeStep) {
			if (this.props.startTime) {
				const endTime = this.props.endTime || '11:59 PM';
				const startTime = moment(`${today} ${this.props.startTime}`);
				const endTimeDate = moment(`${today} ${endTime}`);
				const timeStep = this.props.timeStep;
				while (startTime.isBefore(endTimeDate)) {
					timeOptions.push({
						value: startTime.format('h:mm A'),
						label: startTime.format('h:mm A')
					});
					startTime.add(timeStep, this.props.timeStepUnit);
				}
			} else {
				const timeStep = this.props.timeStep;
				const startTime = moment(`${today} 12:00 AM`);
				const endTimeDate = moment(`${today} 11:59 PM`);
				while (startTime.isBefore(endTimeDate)) {
					timeOptions.push({
						value: startTime.format('h:mm A'),
						label: startTime.format('h:mm A')
					});
					startTime.add(timeStep, this.props.timeStepUnit);
				}
			}
		} else {
			timeOptions = [...defaultTimeOptions];

			if (this.state.timeInput && !timeOptions.some(option => option.value === this.state.timeInput.value)) {
				timeOptions.push(this.state.timeInput);
			}

			if (value && value !== '' && !timeOptions.some(option => option.value === value)) {
				timeOptions.push({ value: value, label: currentTime });
			}
		}
		const time = timeOptions.find((e) => e.label == value) || { value: value, label: value };
		return (
			<div className={`formFieldContainer ${classes} disable-hover ${disabled ? 'disabledField' : ''}`} style={style}>
				<div className="formField">
					<div className="labelContainer">
						<div className="formFieldLabel">
							{label}
							{required ? '*' : ''}
						</div>
						{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
					</div>
					<Select
						styles={{
							valueContainer: (e) => ({
								...e,
								height: 30
							}),
							singleValue: (e) => ({
								...e,
								height: 30
							}),
							indicatorSeparator: (e) => ({
								...e,
								display: 'none'
							}),
							dropdownIndicator: (e) => ({
								...e,
								height: 30,
								padding: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: 30
							}),
							input: (e) => ({
								...e,
								height: 30,
								lineHeight: 1.2
							}),
							option: (e) => ({
								...e,
								height: 30,
								lineHeight: 1.2
							}),
							menuPortal: (e) => ({
								...e,
								zIndex: 9999
							})
						}}
						components={{ DropdownIndicator }}
						menuPortalTarget={document.body}
						menuPosition={'fixed'}
						placeholder={placeholder}
						className={`editingSelectField  ${disabled ? 'disabled-pointer-events' : ''} ${errorTrigger
							? 'error'
							: ''}`}
						classNamePrefix="select"
						value={time || this.props.value}
						name={name}
						options={timeOptions}
						onChange={this.chooseTime}
						onInputChange={this.handleTimeChange}
						noOptionsMessage={noOptionsMessage}
						iconClass={iconClass}
					/>
				</div>
			</div>
		);
	}
}

export default TimeField;
