import React from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

class DeleteAttendeesModal extends React.Component {

	constructor(props) {
		super(props);
		this.networkControllers = [];
		this.state = {
			isDeleting: false,
			deleteComplete: false,
			isCancelled: false,
			totalCount: props.count,
			deletedCount: 0,
		}
	}




	deleteAttendees = async () => {
		const { attendeeIds, eventId, orgId, } = this.props;

		const networkController = new AbortController();
		this.networkControllers.push(networkController);
		let count = 0;
		let deleteCount = 0;

		const chunkSize = 100;
		const attendeeChunks = [];
		const chunkCount = Math.ceil(attendeeIds.length / chunkSize);
		for (let i = 0; i < chunkCount; i++) {
			attendeeChunks.push(attendeeIds.slice(i * chunkSize, (i + 1) * chunkSize));
		}
		this.setState({ isDeleting: true });

		for (let i = 0; i < chunkCount; i++) {

			await new Promise((resolve, reject) => {
				count = 0;
				let currentAttendeeList = attendeeChunks[i];


				for (let index = 0; index < currentAttendeeList.length; index++) {
					let attendeeId = currentAttendeeList[index];

					API()
						.post(`Organizations/${orgId}/events/${eventId}/attendees`, {
							attendees: [attendeeId],
						}, { signal: networkController.signal })
						.then((res) => {
							if (res.data) {
								this.props.delete(attendeeId);
								count++;
								deleteCount++;
								this.setState({ deletedCount: deleteCount });
								if (count === currentAttendeeList.length) {
									resolve();
								}
							}
						})
						.catch((e) => {
							if (count === currentAttendeeList.length) {
								resolve();
							}
						});

				}
			})
			if (i === chunkCount - 1) {
				this.setState({ deleteComplete: true });
			}

		}
	}

	cancelButtonOnClick = () => {
		const { isDeleting, isCancelled, deleteComplete } = this.state
		if (isDeleting && !isCancelled && !deleteComplete) {
			this.networkControllers.forEach(async (controller) => {
				try {
					controller.abort();
				} catch (e) {
					console.log(e);
				}
			});
			this.setState({ isCancelled: true });
		} else {
			this.props.toggle();
		}
	}

	render() {
		const { isOpen, count, toggle } = this.props;
		const { isDeleting, deleteComplete, isCancelled, deletedCount, totalCount } = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Delete ${count > 1 ? 'Attendees' : 'Attendee'}`}
					bodyHeaderText={!isDeleting ? <span>	You are about to delete{' '}
						<b>
							{count} {count > 1 ? 'attendees' : 'attendee'}
						</b>, this is irreversible, would you like to continue?</span> : null}
					bodyContent={() => {
						return (

							<div>
								{isCancelled && <p> Cancelled </p>}
								{deleteComplete && <p> Complete </p>}

								{isDeleting ? <div>
									{deletedCount} of {totalCount} attendees deleted
								</div> : null}

							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!isDeleting ? 'Delete' : !isCancelled && !deleteComplete ? 'Deleting...' : null}
					actionButton={() => this.deleteAttendees()}
					isDelete={true}
					actionButtonDisabled={isDeleting && !isCancelled}
					cancelOnClick={this.cancelButtonOnClick}
					cancelButtonLabel={(isCancelled || (deleteComplete && isDeleting)) ? 'Close' : 'Cancel'}


				></FieldModal>
			</div >

		);
	}
}

export default DeleteAttendeesModal;
