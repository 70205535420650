import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import FieldModal from '../../../../components/modals/fieldModal';
import { Trash2, X } from 'react-feather';
import API from '../../../../utils/API';

class GroupRemoveSessionModal extends React.Component {
	state = {
		submitting: false
	};

	removeSession = async () => {
		const { eventId, orgId, markedSession, group } = this.props;
		let deletingSessions = Object.keys(this.props.checkedSessions);
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/groups/${group._id}`, {
				sessions: this.props.existingSessions.filter((s) => !deletingSessions.includes(s._id)).map((s) => s._id)
			})
			.then((res) => {
				if (res.data) {
					this.props.removeSessions(deletingSessions);
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, markedSession, toggle } = this.props;
		const { submitting } = this.state;
		const count = Object.keys(this.props.checkedSessions).length;

		return (
			<div>
				<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={"Delete Session"}
				bodyHeaderText={<span>You are about to delete{' '}
					<b>
						{count} attendee{count > 1 && 's'}
					</b>{' '}
					from this group, would you like to continue?</span>}
				bodyContent={() => {
					return ('')
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Delete' : 'Deleting'}
				actionButton={() => this.removeSession()}
				actionButtonDisabled={submitting}
				isDelete={true}
				>
				</FieldModal>
			</div>
		);
	}
}

export default GroupRemoveSessionModal;
