import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

class DeleteEmailModal extends React.Component {
	deleteCampaign = async () => {
		const { orgId, eventId, email, toggle, update, updateDeleteCampaign } = this.props;

		try {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/emails/`, { emails: [email._id] })
				.then((res) => {
					update({ sent: true }, () => {
						updateDeleteCampaign(email._id, () => {
							window.open('./', '_self');
						});
					});
				});
		} catch (e) {
			console.log(e);
		}
	};
	render() {
		const { isOpen, toggle, email } = this.props;

		console.log('eeeeeee', email);

		return (
			<Modal isOpen={isOpen} toggle={toggle} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="fs-30 calibreBold">Delete Email</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<p className="calibreRegular">
						You are about to delete this email from the event, this is irreversible, would you like to
						continue?
					</p>
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button className="modalButton actionButton actionDelete m-0" onClick={this.deleteCampaign}>
						<div className="modalButtonText">Delete</div>
					</Button>
					<Button
						outline
						onClick={this.props.toggle}
						className="modalButton actionButton modalCancel mb-0 ml-a"
					>
						<div className="modalCancelText">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default withRouter(DeleteEmailModal);
