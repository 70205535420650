import React, { Component, Fragment } from 'react';
import { Button, Input, Popover, PopoverBody, Row } from 'reactstrap';

import VenuesList from '../../components/venues/venuesList';
//import AddVenueModal from './addVenueModal';
import API from '../../utils/API';
import Branding from '../branding/branding';
import AccountDetails from '../account/accountDetails';
import SubscriptionAndBilling from './subscriptionAndBilling';
import BasicSectionHeader from '../../components/views/BasicSectionHeader';
import LoadingWizard from '../../components/spinner/wizard';
import TabsContainer from '../../components/views/TabsContainer';
import Typography from './typography';
import InputField from '../../components/inputs/inputField';
import ColorField from '../../components/inputs/colorField';
import SelectField from '../../components/inputs/selectField';
import PagePreviewFrame from '../pages/components/pagePreviewFrame';
import ToggleField from '../../components/inputs/toggleField';
import { Route, Router, Switch } from 'react-router-dom';
import Integrations from './accountIntegrations';
import SectionHeader from '../../components/views/SectionHeader';
import SectionFooter from '../../components/views/SectionFooter';

import FieldModal from '../../components/modals/fieldModal';
import CustomStyling from '../../sections/event/pages/customStyling';

const textTransformOptions = [
	{ label: 'Default', value: 'none' },
	{ label: 'Capitalize', value: 'capitalize' },
	{ label: 'lowercase', value: 'lowercase' },
	{ label: 'UPPERCASE', value: 'uppercase' }
];
const tabMap = {
	details: 0,
	account: 1,
	advanced: 2,
}

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

class Account extends Component {
	constructor(props) {
		super(props);
		const { orgId } = props
		const urlParams = new URLSearchParams(window.location.search);
		const selectedTab = urlParams.get('tab');
		this.state = {
			accountData: {},
			selectedSection: 0,
			searchSelected: false,
			loading: true,
			adding: false,
			selectedFilter: 0,
			editing: false,
			editingModal: false,
			editedAccountData: {},
			editedOrgStyles: {},
			searchIntegration: '',
			filteredIntegration: 'All',
			editCount: 0,
			selectedSubSection: 0,
			selectedSectionA: selectedTab ? tabMap[selectedTab] : 0,
			renderURL: `Organizations/${this.props.orgId}/accountPages/render/stylePreview/`,
			routeMap: {
				0: `/${orgId}/account/settings`,
				1: `/${orgId}/account/subscription`,
				2: `/${orgId}/account/branding`,
				3: `/${orgId}/account/integrations/all`
			}
		};
	}

	componentDidMount() {
		let searchParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')));

		API().get(`Organizations/${this.props.orgId}/account/`).then((res) => {
			if (res.data) {
				const publicPathOptions = res.data.orgPages
					.map((po) => {
						if (po.openToPublic) return { label: po.title, value: po.pathSlug };
					})
					.filter((p) => !!p);
				const privatePathOptions = res.data.orgPages.map((po) => {
					return { label: po.title, value: po.pathSlug };
				});
				this.setState({
					accountData: JSON.parse(JSON.stringify(res.data)),
					editedAccountData: res.data,
					loading: false,
					selectedSection: searchParams.get('section') ? parseInt(searchParams.get('section')) : 0,
					integrationToken: searchParams.get('code') ? searchParams.get('code') : false,
					editedOrgStyles: JSON.parse(JSON.stringify(res.data.style)),
					publicPathOptions: publicPathOptions,
					privatePathOptions: privatePathOptions
				});
			}
		});

	}

	saveChanges = (cb) => {
		API()
			.patch(`/Organizations/${this.props.orgId}/account`, {
				...this.state.editedAccountData,
				style: this.state.editedOrgStyles
			})
			.then((res) => {
				if (res.data) {
					this.setState({
						editing: false,
						editingModal: false
					});
					cb && cb();
				}
			})
			.catch((e) => {
				//this.setState({ editing: false, editedAccountData: this.state.accountData });
			});
	};

	editAccountData = (data) => {
		this.setState({ editedAccountData: data, editing: true });
	};


	updateDefaultCSS = (val) => {
		const { editedAccountData } = this.state;

		editedAccountData.branding.defaultCSS = val;
		this.setState({ editedAccountData: editedAccountData, editCount: this.state.editCount + 1, editingModal: true, editing:true })
	}
	updateEventStyles = (property, value) => {
		const editedOrgStyles = this.state.editedOrgStyles;
		editedOrgStyles[property] = value;
		this.setState({ editedOrgStyles: editedOrgStyles, editCount: this.state.editCount + 1, editing: this.state.brandingEditorOpen ? false : true, editingModal: this.state.brandingEditorOpen ? true : false });
	};

	cancelEdit = () => {
		this.setState({ editing: false, editedAccountData: JSON.parse(JSON.stringify(this.state.accountData)), editedOrgStyles: JSON.parse(JSON.stringify(this.state.accountData.style)) });
	};

	renderPage = () => {
	};

	updateSelectedSubSection = (index) => {
		this.setState({ selectedSubSection: index });
	};

	updateSelectedSection = (index) => {
		this.setState({ selectedSection: index });
	};
	updateSelected = (selected) => {
		this.setState({ selectedSectionA: selected });
	};

	render() {
		const {
			loading,
			selectedSection,
			selectedSectionA,
			editing,
			accountData,
			editedAccountData,
			editedOrgStyles,
			searchIntegration,
			filteredIntegration,
			integrationToken,
			routeMap,
			selectedSubSection,
			editingModal
		} = this.state;
		const { orgId } = this.props;
		const organization = accountData;

		//defaultCSS

		let fontOptions = [];
		if (organization.branding) {
			fontOptions = [...organization.branding.fonts].map((f) => {
				return { label: f.name, value: f.name };
			});
		}
		const integrationCategories = ["All", "Integrated", "Unintegrated"];
		editedAccountData.integrations && editedAccountData.integrations.forEach((integration) => {
			if (!integrationCategories.includes(integration.category)) {
				integrationCategories.push(integration.category);
			}
		});
		const tabs = [
			{ title: 'Details' },
			{ title: 'Account Pages' },
			{ title: 'Advanced' },
		]
		return <div className='h-100'>
			{!loading && <div className="flex h-100">
				<div className='p-50' style={{ background: 'var(--input)', width: 312, borderRadius: '15px' }}>
					<h1 className='nowrap mb-20'>Manage Account</h1>
					<h5 className='mb-20'>Settings</h5>
					<button className={`sideNav ${selectedSection == 0 ? 'selected' : ''}`} onClick={() => this.setState({ selectedSection: 0 })}>Overview</button>
					<button className={`sideNav ${selectedSection == 1 ? 'selected' : ''}`} onClick={() => this.setState({ selectedSection: 1 })}>Integrations</button>
					<button className={`sideNav ${selectedSection == 2 ? 'selected' : ''}`} onClick={() => this.setState({ selectedSection: 2 })}>Subscription</button>
					<h5 className='mt-30 mb-20'>Branding</h5>
					<button className={`sideNav ${selectedSection == 3 ? 'selected' : ''}`} onClick={() => this.setState({ selectedSection: 3 })}>Basic</button>
					<button className={`sideNav ${selectedSection == 4 ? 'selected' : ''}`} onClick={() => this.setState({ selectedSection: 4 })}>Advanced</button>
				</div>
				<div className='flex column p-50 w-100'  >
					<div className='h-100'>
						{selectedSection == 0 ? <div className="sectionBody pt-0">
							<h1 className='mb-20 '>Overview</h1>

							<TabsContainer
								tabs={tabs}
								updateSelected={this.updateSelected}
								selectedIndex={selectedSectionA}
							/>


							<div style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
								<AccountDetails orgId={orgId} accountData={editedAccountData} editAccountData={this.editAccountData} selectedIndexA={selectedSectionA} updateSelected={this.updateSelected} /></div>
						</div> : selectedSection == 2 ? <div className="sectionBody pt-0">
							<h1>Subscription</h1>
							<hr />
							<div style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
								<SubscriptionAndBilling
									accountData={editedAccountData}
									editAccountData={this.editAccountData}
								/>
							</div>
						</div>
							: selectedSection == 3 ? <div className="sectionBody pt-0 h-100">
								<h1>Basic Branding</h1>
								<hr />
								<div className='blocksContainer flex h-100' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
									<div className='sectionBlock mr-25'>
										<Branding orgId={orgId} accountData={editedAccountData} editAccountData={this.editAccountData} />
										<Typography
											accountData={editedAccountData}
											editAccountData={(editedBranding) => {
												const editedAccountData = this.state.editedAccountData;
												editedAccountData.branding = editedBranding;
												this.editAccountData(editedAccountData);
											}}
										/>
									</div>

									<div className='sectionBlock emailDesignPreview mt-20 mr-5'>
										<div
											className=" eFrame flex-ac preview" style={{ height: 'calc(100% - 2px)' }}
										>

											{!this.state.brandingEditorOpen && <PagePreviewFrame
												setPreviewDimensions={({ width, height }) => {
													this.setState({
														frameWidth: width,
														frameHeight: height
													});
												}}
												ref={(el) => (this.preview = el)}
												editing={this.state.editCount}
												saving={this.state.saving}
												ENV={ENV}
												renderURL={this.state.renderURL}
												pageProperties={{}}
												simulatedUser={{}}
												simulatedSession={{}}
											/>}
										</div>
									</div>

								</div>
							</div>
								: selectedSection == 4 ? <div className='sectionBody pt-0 h-100'>

									<h1 className='mb-10'>Advanced Branding</h1>
									<div className='sectionContainer h-100' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
										<TabsContainer
											tabs={[
												{ title: 'Colors' },
												{ title: 'Text' },
												{ title: 'Spacing' },
												{ title: 'Custom Styling' }
											]}
											updateSelected={this.updateSelectedSubSection}
											selectedIndex={selectedSubSection}
										/>

										<div className="sectionBody h-100">
											<div className="flex h-100">
												<div
													className="sectionBlock"
													style={{ maxHeight: '100%', overflowX: 'hidden', paddingRight: 5 }}
												>
													{selectedSubSection == 2 && <div><h2 className="blockHeader">Content Styles</h2>
														<InputField
															label="Max Content Width"
															value={
																editedOrgStyles.maxContentWidth
															}
															onChange={(e) => this.updateEventStyles('maxContentWidth', e.target.value)}
														/>

														<h4 >Default Content Padding</h4>
														<div className="flex-ac">
															<InputField
																label="Padding Top "
																classes="mr-5"
																value={
																	editedOrgStyles.contentPaddingTop
																}
																onChange={(e) => this.updateEventStyles('contentPaddingTop', e.target.value)}
															/>
															<InputField
																label="Padding Left "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.contentPaddingLeft
																}
																onChange={(e) => this.updateEventStyles('contentPaddingLeft', e.target.value)}
															/>
															<InputField
																label="Padding Bottom "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.contentPaddingBottom
																}
																onChange={(e) => this.updateEventStyles('contentPaddingBottom', e.target.value)}
															/>
															<InputField
																label="Padding Right "
																classes="ml-5"
																value={
																	editedOrgStyles.contentPaddingRight
																}
																onChange={(e) => this.updateEventStyles('contentPaddingRight', e.target.value)}
															/>
														</div>
														<h4 >Default Button Padding</h4>
														<div className="flex-ac">
															<InputField
																label="Padding Top "
																classes="mr-5"
																value={
																	editedOrgStyles.buttonPaddingTop
																}
																onChange={(e) => this.updateEventStyles('buttonPaddingTop', e.target.value)}
															/>

															<InputField
																label="Padding Left "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.buttonPaddingLeft
																}
																onChange={(e) => this.updateEventStyles('buttonPaddingLeft', e.target.value)}
															/>
															<InputField
																label="Padding Bottom "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.buttonPaddingBottom
																}
																onChange={(e) => this.updateEventStyles('buttonPaddingBottom', e.target.value)}
															/>
															<InputField
																label="Padding Right "
																classes="ml-5"
																value={
																	editedOrgStyles.buttonPaddingRight
																}
																onChange={(e) => this.updateEventStyles('buttonPaddingRight', e.target.value)}
															/>
														</div>
														<h4 >Default Border Radius</h4>
														<div className="flex-ac">
															<InputField
																label="Button Radius "
																classes="mr-5"
																value={
																	editedOrgStyles.buttonBorderRadius
																}
																onChange={(e) => this.updateEventStyles('buttonBorderRadius', e.target.value)}
															/>
															<InputField
																label="Input Radius"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.inputBorderRadius
																}
																onChange={(e) => this.updateEventStyles('inputBorderRadius', e.target.value)}
															/>
															<InputField
																label="Card Radius"
																classes="ml-5"
																value={
																	editedOrgStyles.cardBorderRadius
																}
																onChange={(e) => this.updateEventStyles('cardBorderRadius', e.target.value)}
															/>
														</div></div>}

													{selectedSubSection == 0 && <div><h2 className="blockHeader">Color Styles</h2>

														<h4 >Default Colors</h4>
														<div className="flex-ac">
															<ColorField
																label="Primary Accent Color"
																classes="mr-5"
																type="color"
																value={
																	editedOrgStyles.primaryColor
																}
																onChange={(e) =>
																	this.updateEventStyles('primaryColor', e.target ? e.target.value : e)}
															/>
															<ColorField
																label="Primary Text Color"
																classes="mr-5 ml-5"
																type="color"
																value={
																	editedOrgStyles.secondaryColor
																}
																onChange={(e) =>
																	this.updateEventStyles('secondaryColor', e.target ? e.target.value : e)}
															/>
														</div>

														<h4 >Navigation Text Color</h4>
														<div className="flex-ac">
															<ColorField
																label="Default"
																classes="mr-5"
																type="color"
																value={
																	editedOrgStyles.navigationTextColor}
																onChange={(e) =>
																	this.updateEventStyles(
																		'navigationTextColor',
																		e.target ? e.target.value : e
																	)}
															/>
															<ColorField
																label="Hover"
																classes="mr-5 ml-5"
																type="color"
																value={
																	editedOrgStyles.navigationHoverColor
																}
																onChange={(e) =>
																	this.updateEventStyles(
																		'navigationHoverColor',
																		e.target ? e.target.value : e
																	)}
															/>
															<ColorField
																label="Visited"
																classes="ml-5"
																type="color"
																value={
																	editedOrgStyles.navigationVisitedColor
																}
																onChange={(e) =>
																	this.updateEventStyles(
																		'navigationVisitedColor',
																		e.target ? e.target.value : e
																	)}
															/>
														</div>
														<h4 >Link Color</h4>
														<div className="flex-ac">
															<ColorField
																label="Default"
																classes="mr-5"
																type="color"
																value={
																	editedOrgStyles.linkTextColor
																}
																onChange={(e) =>
																	this.updateEventStyles('linkTextColor', e.target ? e.target.value : e)}
															/>
															<ColorField
																label="Hover"
																classes="mr-5 ml-5"
																type="color"
																value={
																	editedOrgStyles.linkHoverColor
																}
																onChange={(e) =>
																	this.updateEventStyles('linkHoverColor', e.target ? e.target.value : e)}
															/>
															<ColorField
																label="Visited"
																classes="ml-5"
																type="color"
																value={
																	editedOrgStyles.linkVisitedColor
																}
																onChange={(e) =>
																	this.updateEventStyles('linkVisitedColor', e.target ? e.target.value : e)}
															/>
														</div>
														<h4 >Button Color</h4>
														<div className="flex-ac">
															<ColorField
																label="Primary"
																classes="mr-5"
																type="color"
																value={
																	editedOrgStyles.buttonPrimaryColor
																}
																onChange={(e) =>
																	this.updateEventStyles('buttonPrimaryColor', e.target ? e.target.value : e)}
															/>

															<ColorField
																label="Secondary"
																classes="ml-5"
																type="color"
																value={
																	editedOrgStyles.buttonSecondaryColor
																}
																onChange={(e) =>
																	this.updateEventStyles(
																		'buttonSecondaryColor',
																		e.target ? e.target.value : e
																	)}
															/>
														</div>
														<h4 >Profile Icon Color</h4>
														<div className="flex-ac">
															<ColorField
																label="Background"
																classes="mr-5"
																type="color"
																value={
																	editedOrgStyles.profileIconPrimaryColor
																}
																onChange={(e) =>
																	this.updateEventStyles(
																		'profileIconPrimaryColor',
																		e.target ? e.target.value : e
																	)}
															/>
															<ColorField
																label="Text"
																classes="ml-5"
																type="color"
																value={
																	editedOrgStyles.profileIconSecondaryColor
																}
																onChange={(e) =>
																	this.updateEventStyles(
																		'profileIconSecondaryColor',
																		e.target ? e.target.value : e
																	)}
															/>
														</div>
														<h4 >Scrollbar Color</h4>
														<div className="flex-ac">
															<ColorField
																label="Bar Color"
																classes="mr-5"
																type="color"
																value={
																	editedOrgStyles.scrollbarColor
																}
																onChange={(e) =>
																	this.updateEventStyles('scrollbarColor', e.target ? e.target.value : e)}
															/>

															<ColorField
																label="Background Color"
																classes="ml-5"
																type="color"
																value={
																	editedOrgStyles.scrollbarBackgroundColor
																}
																onChange={(e) =>
																	this.updateEventStyles(
																		'scrollbarBackgroundColor',
																		e.target ? e.target.value : e
																	)}
															/>
														</div></div>}
													{selectedSubSection == 1 && <div>
														<h2 className="blockHeader">Text Styles</h2>
														<h4 >Default Fonts</h4>
														<div className="flex-ac">
															<SelectField
																label="Primary Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.primaryFont
																)}
																onChange={(e) => this.updateEventStyles('primaryFont', e.value)}
															/>
															<SelectField
																label="Secondary Font Family"
																style={{ marginLeft: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.secondaryFont
																)}
																onChange={(e) => this.updateEventStyles('secondaryFont', e.value)}
															/>
														</div>
														<h4 >Navigation Bar</h4>
														<div className="flex-ac">
															<InputField
																label="Font Size "
																classes="mr-5"
																value={
																	editedOrgStyles.navigationBarFontSize
																}
																onChange={(e) =>
																	this.updateEventStyles('navigationBarFontSize', e.target.value)}
															/>

															<SelectField
																label="Font Family"
																style={{ marginRight: 5, marginLeft: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.navigationBarFontFamily
																)}
																onChange={(e) => this.updateEventStyles('navigationBarFontFamily', e.value)}
															/>
															<SelectField
																label="Font Transform"
																style={{ marginLeft: 5 }}
																options={textTransformOptions}
																value={textTransformOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.navigationBarFontTransform
																)}
																onChange={(e) => this.updateEventStyles('navigationBarFontTransform', e.value)}
															/>
														</div>
														<h4 >Heading 1</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.heading1FontFamily
																)}
																onChange={(e) => this.updateEventStyles('heading1FontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading1FontSize}
																onChange={(e) => this.updateEventStyles('heading1FontSize', e.target.value)}
															/>
															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading1LineHeight
																}
																onChange={(e) => this.updateEventStyles('heading1LineHeight', e.target.value)}
															/>
															<InputField
																label="Bottom Margin"
																classes="mr-5"
																value={
																	editedOrgStyles.heading1MarginBottom
																}
																onChange={(e) => this.updateEventStyles('heading1MarginBottom', e.target.value)}
															/>
														</div>
														<h4 >Heading 2</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.heading2FontFamily
																)}
																onChange={(e) => this.updateEventStyles('heading2FontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading2FontSize
																}
																onChange={(e) => this.updateEventStyles('heading2FontSize', e.target.value)}
															/>
															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading2LineHeight
																}
																onChange={(e) => this.updateEventStyles('heading2LineHeight', e.target.value)}
															/>
															<InputField
																label="Bottom Margin"
																classes="mr-5"
																value={
																	editedOrgStyles.heading2MarginBottom
																}
																onChange={(e) => this.updateEventStyles('heading2MarginBottom', e.target.value)}
															/>
														</div>
														<h4 >Heading 3</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.heading3FontFamily
																)}
																onChange={(e) => this.updateEventStyles('heading3FontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading3FontSize
																}
																onChange={(e) => this.updateEventStyles('heading3FontSize', e.target.value)}
															/>
															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading3LineHeight
																}
																onChange={(e) => this.updateEventStyles('heading3LineHeight', e.target.value)}
															/>
															<InputField
																label="Bottom Margin"
																classes="mr-5"
																value={
																	editedOrgStyles.heading3MarginBottom
																}
																onChange={(e) => this.updateEventStyles('heading3MarginBottom', e.target.value)}
															/>
														</div>
														<h4 >Heading 4</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.heading4FontFamily
																)}
																onChange={(e) => this.updateEventStyles('heading4FontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading4FontSize
																}
																onChange={(e) => this.updateEventStyles('heading4FontSize', e.target.value)}
															/>
															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading4LineHeight
																}
																onChange={(e) => this.updateEventStyles('heading4LineHeight', e.target.value)}
															/>
															<InputField
																label="Bottom Margin"
																classes="mr-5"
																value={
																	editedOrgStyles.heading4MarginBottom
																}
																onChange={(e) => this.updateEventStyles('heading4MarginBottom', e.target.value)}
															/>
														</div>
														<h4 >Heading 5</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.heading5FontFamily
																)}
																onChange={(e) => this.updateEventStyles('heading5FontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading5FontSize
																}
																onChange={(e) => this.updateEventStyles('heading5FontSize', e.target.value)}
															/>
															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.heading5LineHeight
																}
																onChange={(e) => this.updateEventStyles('heading5LineHeight', e.target.value)}
															/>
															<InputField
																label="Bottom Margin"
																classes="mr-5"
																value={
																	editedOrgStyles.heading5MarginBottom
																}
																onChange={(e) => this.updateEventStyles('heading5MarginBottom', e.target.value)}
															/>
														</div>
														<h4 >Body</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.bodyFontFamily
																)}
																onChange={(e) => this.updateEventStyles('bodyFontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.bodyFontSize
																}
																onChange={(e) => this.updateEventStyles('bodyFontSize', e.target.value)}
															/>

															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.bodyLineHeight
																}
																onChange={(e) => this.updateEventStyles('bodyLineHeight', e.target.value)}
															/>
															<InputField
																label="Bottom Margin"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.paragraphMarginBottom
																}
																onChange={(e) =>
																	this.updateEventStyles('paragraphMarginBottom', e.target.value)}
															/>
															<SelectField
																label="Bold Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.bodyBoldFontFamily
																)}
																onChange={(e) => this.updateEventStyles('bodyBoldFontFamily', e.value)}
															/>
														</div>
														<h4 >Input Label</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.inputLabelFontFamily
																)}
																onChange={(e) => this.updateEventStyles('inputLabelFontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.inputLabelFontSize

																}
																onChange={(e) => this.updateEventStyles('inputLabelFontSize', e.target.value)}
															/>
															<SelectField
																label="Font Transform"
																style={{ marginLeft: 5 }}
																options={textTransformOptions}
																value={textTransformOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.inputLabelFontTransform
																)}
																onChange={(e) => this.updateEventStyles('inputLabelFontTransform', e.value)}
															/>
														</div>
														<h4 >Input Text</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.inputTextFontFamily
																)}
																onChange={(e) => this.updateEventStyles('inputTextFontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5"
																value={
																	editedOrgStyles.inputTextFontSize
																}
																onChange={(e) => this.updateEventStyles('inputTextFontSize', e.target.value)}
															/>
														</div>
														<h4 >Button Text</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.buttonTextFontFamily
																)}
																onChange={(e) => this.updateEventStyles('buttonTextFontFamily', e.value)}
															/>
															<InputField
																label="Font Size "
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.buttonTextFontSize
																}
																onChange={(e) => this.updateEventStyles('buttonTextFontSize', e.target.value)}
															/>
															<SelectField
																label="Font Transform"
																style={{ marginLeft: 5 }}
																options={textTransformOptions}
																value={textTransformOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.buttonTextFontTransform
																)}
																onChange={(e) => this.updateEventStyles('buttonTextFontTransform', e.value)}
															/>
														</div>
														<h4>Footer</h4>
														<div className="flex-ac">
															<SelectField
																label="Font Family"
																style={{ marginRight: 5 }}
																options={fontOptions}
																value={fontOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.footerFontFamily
																)}
																onChange={(e) => this.updateEventStyles('footerFontFamily', e.value)}
															/>
															<InputField
																label="Font Size"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.footerFontSize
																}
																onChange={(e) => this.updateEventStyles('footerFontSize', e.target.value)}
															/>
															<InputField
																label="Line Height"
																classes="mr-5 ml-5"
																value={
																	editedOrgStyles.footerLineHeight
																}
																onChange={(e) => this.updateEventStyles('footerLineHeight', e.target.value)}
															/>
															<SelectField
																label="Font Transform"
																style={{ marginLeft: 5 }}
																options={textTransformOptions}
																value={textTransformOptions.find(
																	(fo) =>
																		fo.value ==
																		editedOrgStyles.footerFontTransform
																)}
																onChange={(e) => this.updateEventStyles('footerFontTransform', e.value)}
															/>
														</div></div>}
													{selectedSubSection == 3 && <div>
														<h2 className="blockHeader">Custom CSS</h2><p className='mb-20'>The css here will be applied to all the pages within the event.</p>
														<CustomStyling
															eventCustomCSS={editedAccountData.branding.defaultCSS}
															updateStyling={(e) => {
																this.updateDefaultCSS(e)
																
															}}
															readOnly={false}
														/></div>}
												</div>
												<div className='sectionBlock emailDesignPreview mt-20 mr-5'>
													<div
														className=" eFrame flex-ac preview" style={{ height: 'calc(100% - 2px)' }}
													>
														<PagePreviewFrame
															setPreviewDimensions={({ width, height }) => {
																this.setState({
																	frameWidth: width,
																	frameHeight: height
																});
															}}
															ref={(el) => (this.preview = el)}
															editing={this.state.editCount}
															saving={this.state.saving}
															ENV={ENV}
															renderURL={this.state.renderURL}
															pageProperties={{}}
															simulatedUser={{}}
															simulatedSession={{}}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div> : selectedSection == 1 ? <div className="sectionBody pt-0 h-100 ">
									<h1>Integrations</h1>
									<hr />
									<div style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
										<Integrations
											orgId={this.props.orgId}
											integrations={editedAccountData.integrations}
											searchIntegration={searchIntegration}
											searchIntegrations={(e) => {
												this.setState({ searchIntegration: e.target.value });
											}}
											clearSearch={() => this.setState({ searchIntegration: '' })}
											filterIntegrations={(category) => this.setState({ filteredIntegration: category })}
											integrationCategories={integrationCategories}
											filteredIntegration={filteredIntegration}
											integrationToken={integrationToken}
											toggleIntegration={(integrationIndex, value, authenticated, requiredFieldsValues) => {
												console.log(this.state.accountData.integrations[integrationIndex].enabled)
												let integrations = editedAccountData.integrations;
												if (!this.state.accountData.integrations[integrationIndex].enabled && integrations[integrationIndex] && integrations[integrationIndex].links && integrations[integrationIndex].links.authorize && value && !authenticated) {
													if (requiredFieldsValues) {
														integrations[integrationIndex].requiredFields = integrations[integrationIndex].requiredFields.map(rf => {
															return {
																...rf,
																value: requiredFieldsValues[rf.id]
															}
														})
														this.saveChanges(() => {
															window.open(integrations[integrationIndex].links.authorize, '_self');
														});
													} else {
														window.open(integrations[integrationIndex].links.authorize, '_self');
													}
													return;
												} else if (authenticated) {
													window.location.href = 'all';
													return
												}
												integrations[integrationIndex].enabled = value;
												this.setState({ editedAccountData: { ...editedAccountData, integrations }, editing: true });
											}} /></div>
								</div>
									: null}
					</div>
					<SectionFooter
						editing={editing}
						cancelEdit={this.cancelEdit}
						saveEdit={this.saveChanges}
						updatedAt={accountData.updatedAt}
						style={{ marginBottom: 0, marginTop: 50 }}
					/>
				</div>
			</div >}

			<LoadingWizard text="LOADING ACCOUNT INFORMATION" loading={loading} />
		</div >
	}
}

export default Account;
