import React, { Component } from 'react';

import API from '../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import InputField from '../../inputs/inputField.js';
import FieldModal from '../../modals/fieldModal';
class DuplicateViewModal extends Component {
	constructor(props) {
		super(props);
		console.log(props.view);
		this.state = {
			submitting: false,
			name: props.view.name + ' (copy)',
			requiredFields: {
				name: false
			}
		};
	}

	saveNewView = () => {
		const { name, requiredFields } = this.state;
		const { orgId, referenceCollection, referenceId, normalizedColumns, reportId, view } = this.props;
		const Dview = {
			...view,
			name,
			referenceCollection: referenceCollection,
			referenceId: referenceId
		};

		let valid = true;
		let sortedColumns = [];
		normalizedColumns.map((nc) => {
			sortedColumns.push(nc.key);
		})

		Object.keys(requiredFields).forEach((key) => {
			if (!view[key] || view[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			this.setState({ submitting: true });
			if (reportId) {
				Dview.reportId = reportId;
			}
			API()
				.post(`/Organizations/${orgId}/views`, Dview)
				.then((res) => {
					this.setState({ submitting: false, requiredFields: requiredFields });
					this.props.addView(res.data);
					this.props.toggle();
				})
				.catch((err) => {
					this.setState({ submitting: false });
					console.log(err);
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, requiredFields } = this.state;
		return (

			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Duplicate view"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									value={name}
									label={'View Name'}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={(e) => this.setState({ name: e.target.value })}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={'Duplicate'}
					actionButton={this.saveNewView}
					actionButtonDisabled={submitting}

				></FieldModal>


			</div>




		);
	}
}

export default DuplicateViewModal;
