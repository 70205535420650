import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledTooltip,
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import API from "../../utils/API";
import ViewTab from "../tabs/ViewTab";
import MoreViewsTab from "../tabs/MoreViewsTab";
import ColumnFilterRow from "../dropdown/columnFilterRow";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import ViewsTable from "../tables/ViewsTable";
import DeleteViewModal from "./modals/deleteViewModal";
import RenameViewModal from "./modals/renameViewModal";
import DuplicateViewModal from "./modals/duplicateViewModal";
import AddViewModal from "./modals/addViewModal";
import moment from "moment";
import AddCustomFieldModal from "../modals/addCustomFieldModal";
import CheckboxInput from "../inputs/checkboxInput";
import EditCustomFieldModal from "../modals/editCustomFieldModal";
import ConvertCustomFieldModal from "../modals/convertCustomFieldModal";
import RemoveCustomFieldModal from "../modals/removeCustomFieldModal";
import InputField from "../inputs/inputField";

const getComponentListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "transparent" : "transparent",
	padding: "0 25px",
	margin: "0 -25px",
	marginBottom: "5px",
});

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",

	// change background colour if dragging
	background: isDragging && "lightgrey",
	/* borderBottom: '1px solid grey',
	  padding: '15px 0', */
	// styles we need to apply on draggables
	...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};
class ViewsContainer extends Component {
	constructor(props) {
		super(props);
		const toggleColumns = {};
		const normalizedColumns = Object.keys(props.columns).map((cfc) => {
			toggleColumns[cfc] = true;
			return {
				label: props.columns[cfc],
				key: cfc,
				value: cfc,
				type:
					(props.columnTypes && props.columnTypes[cfc]) ||
					typeof props.columns[cfc],
				sortAsc: false,
				sortDesc: false,
			};
		});

		let mainView = null;
		let customViews = [];
		props.views.forEach((cv) => {
			if (!cv.isMain) {
				if (!cv.filterLayers) {
					cv.filterLayers = [
						{
							column: "",
							condition: "",
							value: "",
						},
					];
				}
				customViews.push(cv);
			} else {
				mainView = cv;
			}
		});
		delete toggleColumns[Object.keys(props.columns)[0]];
		this.state = {
			newEntries: [],
			checkedEntries: {},
			searchedEntries: [],
			allEntries: props.entries,
			organizedEntries: props.entries,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggleColumns,
			allChecked: false,
			windowWidth: window.innerWidth,
			activeView: 0,
			focusedView: 0,
			searching: false,
			searchTerm: "",
			searchFocused: false,
			columnSearchTerm: "",
			defaultViewFilters: [
				{
					column: "",
					condition: "",
					value: "",
				},
			],
			customViews: customViews,
			mainView: mainView,
			addViewModalOpen: false,
			duplicateViewModalOpen: false,
			deleteViewModalOpen: false,
			renameViewModalOpen: false,
			addCustomFieldModalOpen: false,
			renameCustomFieldModalOpen: false,
			deleteCustomFieldModalOpen: false,
			convertCustomFieldModalOpen: false,
			focusedCustomField: null,
			morePosition: null,
			deletedCustomField: {},
		};
	}

	//search all entries properties for search term
	searchEntries = async () => {
		const { organizedEntries, searchTerm, mainView, activeView, customViews } =
			this.state;

		const selectedCustomView =
			mainView && activeView === 0
				? mainView
				: (!mainView && activeView === 0) || (mainView && activeView === 1)
					? null
					: customViews[activeView - (mainView ? 2 : 1)];
		let columns = this.props.columns;
		if (selectedCustomView) {
			const customColumns = {};
			selectedCustomView.toggledColumns.forEach((col) => {
				customColumns[col] = columns[col];
			});
			const mainColumnKey = Object.keys(columns)[0];
			const mainColumn = columns[mainColumnKey];
			columns = customColumns;
			columns[mainColumnKey] = mainColumn;
		}

		let searchedEntries = organizedEntries;

		if (searchTerm.length > 0) {
			const searchTerms = searchTerm.toLowerCase().split(" ");
			searchTerms.forEach((term) => {
				if (term !== "") {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (
								term !== "" &&
								entry[key] &&
								entry[key].toString().toLowerCase().includes(term)
							) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		this.setState({ searching: searchTerm !== "", searchedEntries });
	};

	componentDidMount() {
		window.onresize = () => {
			this.setState({ windowWidth: window.innerWidth });
		};

		this.props.views.forEach((av) => {
			this.countFilteredEntries(av);
		});
		this.filterView();
		this.selectView(0);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.entries !== this.props.entries) {
			this.setState(
				{
					allEntries: this.props.entries,
					organizedEntries: this.props.entries,
				},
				() => {
					this.filterView();
					this.searchEntries();
				}
			);
		}
		if (prevProps.views !== this.props.views) {
			this.props.views.forEach((av) => {
				this.countFilteredEntries(av);
			});
			this.filterView();
		}
	}

	setOrderOfColumns = (orderArray) => {
		const { normalizedColumns } = this.state;
		let processedColumns = [];
		if (orderArray) {
			let sortedNormalizedColumns = [];
			let deletedColumns = [];
			orderArray.forEach((col, i) => {
				let tempC = normalizedColumns.find((nc) => nc.key === col);
				processedColumns.push(col);

				if (!tempC) {
					deletedColumns.push(col);
				} else {
					sortedNormalizedColumns.push(tempC);
				}
			});

			normalizedColumns.forEach((nc) => {
				if (!processedColumns.includes(nc.key)) {
					sortedNormalizedColumns.push(nc);
				}
			});

			sortedNormalizedColumns.filter(
				(c) => !Object.keys(this.state.deletedCustomField).includes(c.key)
			);

			this.setState({ normalizedColumns: sortedNormalizedColumns });
		} else {
			let sortedNormalizedColumns = Object.keys(this.props.columns)
				.map((cfc) => {
					return {
						label: this.props.columns[cfc],
						key: cfc,
						value: cfc,
						type:
							(this.props.columnTypes && this.props.columnTypes[cfc]) ||
							typeof this.props.columns[cfc],
						sortAsc: false,
						sortDesc: false,
					};
				})
				.filter(
					(c) => !Object.keys(this.state.deletedCustomField).includes(c.key)
				);
			this.setState({ normalizedColumns: sortedNormalizedColumns });
		}
	};

	makeMainView = (av) => {
		const { customViews, mainView } = this.state;
		let updatedViews = customViews;
		updatedViews = customViews.filter((a) => a._id !== av._id);
		if (mainView) {
			mainView.isMain = false;
			updatedViews.push(mainView);
		}
		API()
			.patch(`/Organizations/${this.props.orgId}/views/${av._id}`, {
				isMainUpdate: true,
				isMain: true,
			})
			.then((res) => {
				//this.props.setViewAsMain(av._id);
				av.isMain = true;
				this.setState(
					{ activeView: 0, customViews: updatedViews, mainView: res.data },
					this.filterView
				);
			});
	};

	resetDefaultView = () => {
		const { customViews, mainView } = this.state;
		let updatedViews = customViews;
		if (mainView) {
			mainView.isMain = false;
			updatedViews.push(mainView);
		}
		API()
			.patch(`/Organizations/${this.props.orgId}/views/${mainView._id}`, {
				isMainUpdate: true,
				isMain: false,
			})
			.then((res) => {
				//this.props.resetMainView();
				this.setState(
					{ activeView: 0, customViews: updatedViews, mainView: null },
					this.filterView
				);
			});
	};

	renderToggleColumnItem = (key, toggledColumns, viewId, defaultChecked) => {
		const { columns, columnTypes, categoryColumns } = this.props;

		let categoryTitle = "Basic";
		if (key.includes("*")) {
			let splitKey = key.split("*");
			let category = splitKey[0];
			categoryTitle = categoryColumns[category].title;
		} else if (key.includes("_")) {
			let splitKey = key.split("_");
			let category = splitKey[0];
			categoryTitle = categoryColumns[category].title;
		}
		return (
			<div className={`flex-sb flex-ac ${!key.includes("*") ? "mr-25" : ""}`}>
				<i className="las la-braille mr-5"></i>
				<CheckboxInput
					label={columns[key]}
					eyebrow={categoryTitle}
					checked={toggledColumns[key] === true || defaultChecked}
					unsetHeight={true}
					onClick={() => {
						if (viewId) {
							const mainView = this.state.mainView;
							if (mainView && viewId === mainView._id) {
								if (mainView.toggledColumns.includes(key)) {
									mainView.toggledColumns = mainView.toggledColumns.filter(
										(col) => col !== key
									);
								} else {
									mainView.toggledColumns.push(key);
								}
								mainView.unsavedColumns = true;
								this.setState({ mainView }, this.filterView);
							} else {
								const customViews = this.state.customViews;
								const vi = customViews.findIndex((view) => view._id === viewId);
								if (vi >= 0) {
									const cView = this.state.customViews[vi];
									if (cView.toggledColumns.includes(key)) {
										cView.toggledColumns = cView.toggledColumns.filter(
											(col) => col !== key
										);
									} else {
										cView.toggledColumns.push(key);
									}
									cView.unsavedColumns = true;
									customViews[vi] = cView;
								}
								this.setState({ customViews }, this.filterView);
							}
						} else {
							const tc = this.state.toggledColumns;
							tc[key] = !tc[key];
							this.setState({ toggledColumns: tc });
						}
					}}
				/>
				{key.includes("*") && (
					<div className="mr-10">
						<UncontrolledDropdown
							direction="up"
							inNavbar
							className="flex-ac fs-16"
						>
							<DropdownToggle className="columnToggle">
								<i className="las la-ellipsis-h" />
							</DropdownToggle>
							<DropdownMenu right className="columnDropdown">
								<DropdownItem
									className="min"
									onClick={() => {
										this.setState({
											editCustomFieldModalOpen: true,
											focusedCustomField: key,
										});
									}}
								>
									<i className="las la-pen mr-10" /> Edit
								</DropdownItem>

								{!(
									columnTypes[key] === "text" || columnTypes[key] === "string"
								) && (
										<DropdownItem
											className="min"
											onClick={() =>
												this.setState({
													convertCustomFieldModalOpen: true,
													focusedCustomField: key,
												})
											}
										>
											<i className="las la-exchange-alt mr-10" /> Convert to text
										</DropdownItem>
									)}
								<DropdownItem
									className="min red"
									onClick={() => {
										this.setState({
											deleteCustomFieldModalOpen: true,
											focusedCustomField: key,
										});
									}}
								>
									<i className="las la-trash mr-10" /> Delete
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				)}
			</div>
		);
	};

	checkEntry = (entryId) => {
		const checked = this.state.checkedEntries;
		checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
		this.setState({
			checkedEntries: checked,
		});
	};
	checkEntries = (entryIds) => {
		let checked = this.state.checkedEntries;
		entryIds.length > 0
			? entryIds.forEach((entryId) => {
				checked[entryId] = true;
			})
			: (checked = {});
		this.setState({
			checkedEntries: checked,
		});
	};

	filterView = (filterEdit) => {
		const {
			allEntries,
			activeView,
			customViews,
			mainView,
			defaultViewFilters,
		} = this.state;
		let filterLayers = defaultViewFilters;

		const activeViewFilter = mainView ? activeView - 2 : activeView - 1;

		if (mainView && mainView.filterLayers && activeView === 0) {
			filterLayers = mainView.filterLayers;
			mainView.columnsOrder && mainView.columnsOrder.length > 0
				? this.setOrderOfColumns(
					mainView.columnsOrder,
					!mainView.unsavedColumns
				)
				: this.setOrderOfColumns();
		} else if (
			activeView > (mainView ? 1 : 0) &&
			customViews[activeViewFilter].filterLayers
		) {
			filterLayers = customViews[activeViewFilter].filterLayers;
			customViews[activeViewFilter].columnsOrder &&
				customViews[activeViewFilter].columnsOrder.length > 0
				? this.setOrderOfColumns(
					customViews[activeViewFilter].columnsOrder,
					!customViews[activeViewFilter].unsavedColumns
				)
				: this.setOrderOfColumns();
		} else {
			this.setOrderOfColumns();
		}
		const filteredView =
			filterLayers.length > 0 && filterLayers[0].column !== ""
				? allEntries.filter((a) => {
					let valid = true;
					filterLayers.forEach((layer, index) => {
						if (a[layer.column] || a[layer.column] === false) {
							const isString = typeof a[layer.column] == "string";
							switch (layer.condition) {
								case "is":
									if (isString) {
										valid =
											layer.concatOperator === "and"
												? valid &&
												a[layer.column].toLowerCase() ===
												layer.value.toLowerCase()
												: valid ||
												a[layer.column].toLowerCase() ===
												layer.value.toLowerCase();
									} else {
										valid =
											layer.concatOperator === "and"
												? valid && a[layer.column] === layer.value
												: valid || a[layer.column] === layer.value;
									}
									break;
								case "isnot":
									if (isString) {
										valid =
											layer.concatOperator === "and"
												? valid &&
												a[layer.column].toLowerCase() !==
												layer.value.toLowerCase()
												: valid ||
												a[layer.column].toLowerCase() !==
												layer.value.toLowerCase();
									} else {
										valid =
											layer.concatOperator === "and"
												? valid && a[layer.column] !== layer.value
												: valid || a[layer.column] !== layer.value;
									}

									break;
								case "contains":
									valid =
										layer.concatOperator === "and"
											? valid &&
											a[layer.column]
												.toLowerCase()
												.includes(layer.value.toLowerCase())
											: valid ||
											a[layer.column]
												.toLowerCase()
												.includes(layer.value.toLowerCase());
									break;
								case "doesnotcontain":
									valid =
										layer.concatOperator === "and"
											? valid &&
											!a[layer.column]
												.toLowerCase()
												.includes(layer.value.toLowerCase())
											: valid ||
											!a[layer.column]
												.toLowerCase()
												.includes(layer.value.toLowerCase());
									break;
								case "isempty":
									valid =
										layer.concatOperator === "and"
											? valid && (!a[layer.column] || a[layer.column] === "")
											: valid || !a[layer.column] || a[layer.column] === "";
									break;
								case "isnotempty":
									valid =
										layer.concatOperator === "and"
											? valid && a[layer.column] && a[layer.column] !== ""
											: valid || (a[layer.column] && a[layer.column] !== "");
									break;
								case "isdatelaterthan":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(a[layer.column]).isAfter(moment(layer.value))
											: valid ||
											moment(a[layer.column]).isAfter(moment(layer.value));
									break;
								case "isdateearlierthan":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(a[layer.column]).isBefore(moment(layer.value))
											: valid ||
											moment(a[layer.column]).isBefore(moment(layer.value));
									break;
								case "isdateequalto":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(a[layer.column]).isSame(moment(layer.value))
											: valid ||
											moment(a[layer.column]).isSame(moment(layer.value));
									break;
								case "isupcoming":
									valid =
										layer.concatOperator === "and"
											? valid && moment(a[layer.column]).isAfter(moment())
											: valid || moment(a[layer.column]).isAfter(moment());
									break;
								case "ispast":
									valid =
										layer.concatOperator === "and"
											? valid && moment(a[layer.column]).isBefore(moment())
											: valid || moment(a[layer.column]).isBefore(moment());
									break;

								case "istoday":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(a[layer.column]).format("MM/DD/YYYY") ===
											moment().format("MM/DD/YYYY")
											: valid ||
											moment(a[layer.column]).format("MM/DD/YYYY") ===
											moment().format("MM/DD/YYYY");
									break;
								case "isyes":
									valid =
										layer.concatOperator === "and"
											? valid && (a[layer.column] || a[layer.column] === true)
											: valid || a[layer.column] || a[layer.column] === true;
									break;
								case "isno":
									valid =
										layer.concatOperator === "and"
											? valid &&
											(!a[layer.column] || a[layer.column] === false)
											: valid ||
											!a[layer.column] ||
											a[layer.column] === false;
									break;
								case "isnumbergreaterthan":
									valid =
										layer.concatOperator === "and"
											? valid && parseInt(a[layer.column]) > layer.value
											: valid || parseInt(a[layer.column]) > layer.value;
									break;
								case "isnumberlessthan":
									valid =
										layer.concatOperator === "and"
											? valid && parseInt(a[layer.column]) < layer.value
											: valid || parseInt(a[layer.column]) < layer.value;
									break;
								default:
							}
						} else {
							valid = false;
						}
					});
					return valid;
				})
				: allEntries;
		if (mainView && activeView === 0) {
			mainView.count = filteredView.length;
			if (filterEdit) {
				mainView.unsavedFilter = true;
			}
			this.setState({
				organizedEntries: filteredView,
				mainView: mainView,
			});
		} else if (activeView > (mainView ? 1 : 0)) {
			customViews[activeViewFilter].count = filteredView.length;
			if (filterEdit) {
				customViews[activeViewFilter].unsavedFilter = true;
			}
			this.setState({
				organizedEntries: filteredView,
				customViews: customViews,
			});
		} else {
			this.setState({ organizedEntries: filteredView });
		}
	};

	countFilteredEntries = async (view) => {
		const { allEntries, mainView } = this.state;
		const filteredView =
			view.filterLayers.length > 0 && view.filterLayers[0].column !== ""
				? allEntries.filter((a) => {
					let valid = true;
					view.filterLayers.forEach((layer, index) => {

						if (a[layer.column] || a[layer.column] === false) {
							const columnLayer = typeof a[layer.column] === "string" ? a[layer.column].toLowerCase() : a[layer.column];
							switch (layer.condition) {
								case "is":
									valid =
										layer.concatOperator === "and"
											? valid &&
											columnLayer ===
											layer.value.toLowerCase()
											: valid ||
											columnLayer ===
											layer.value.toLowerCase();
									break;
								case "isnot":
									valid =
										layer.concatOperator === "and"
											? valid &&
											columnLayer !==
											layer.value?.toLowerCase()
											: valid ||
											columnLayer !==
											layer.value?.toLowerCase();
									break;
								case "contains":
									valid =
										layer.concatOperator === "and"
											? valid &&
											columnLayer
												.includes(layer.value.toLowerCase())
											: valid ||
											a[layer.column]?.toLowerCase()
												.includes(layer.value.toLowerCase());
									break;
								case "doesnotcontain":
									valid =
										layer.concatOperator === "and"
											? valid &&
											!columnLayer
												.includes(layer.value.toLowerCase())
											: valid ||
											!columnLayer
												.includes(layer.value.toLowerCase());
									break;
								case "isempty":
									valid =
										layer.concatOperator === "and"
											? valid && (!columnLayer || columnLayer === "")
											: valid || !columnLayer || columnLayer === "";
									break;
								case "isnotempty":
									valid =
										layer.concatOperator === "and"
											? valid && columnLayer && columnLayer !== ""
											: valid || (columnLayer && columnLayer !== "");
									break;
								case "isdatelaterthan":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(columnLayer).isAfter(moment(layer.value))
											: valid ||
											moment(columnLayer).isAfter(moment(layer.value));
									break;
								case "isdateearlierthan":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(columnLayer).isBefore(moment(layer.value))
											: valid ||
											moment(columnLayer).isBefore(moment(layer.value));
									break;
								case "isdateequalto":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(columnLayer).isSame(moment(layer.value))
											: valid ||
											moment(columnLayer).isSame(moment(layer.value));
									break;
								case "isupcoming":
									valid =
										layer.concatOperator === "and"
											? valid && moment(columnLayer).isAfter(moment())
											: valid || moment(columnLayer).isAfter(moment());
									break;
								case "ispast":
									valid =
										layer.concatOperator === "and"
											? valid && moment(columnLayer).isBefore(moment())
											: valid || moment(columnLayer).isBefore(moment());
									break;
								case "istoday":
									valid =
										layer.concatOperator === "and"
											? valid &&
											moment(columnLayer).format("MM/DD/YYYY") ===
											moment().format("MM/DD/YYYY")
											: valid ||
											moment(columnLayer).format("MM/DD/YYYY") ===
											moment().format("MM/DD/YYYY");
									break;
								case "isyes":
									valid =
										layer.concatOperator === "and"
											? valid && (columnLayer || columnLayer === true)
											: valid || columnLayer || columnLayer === true;
									break;
								case "isno":
									valid =
										layer.concatOperator === "and"
											? valid &&
											(!columnLayer || columnLayer === false)
											: valid ||
											!columnLayer ||
											columnLayer === false;
									break;
								case "isnumbergreaterthan":
									valid =
										layer.concatOperator === "and"
											? valid && parseInt(columnLayer) > layer.value
											: valid || parseInt(columnLayer) > layer.value;
									break;
								case "isnumberlessthan":
									valid =
										layer.concatOperator === "and"
											? valid && parseInt(columnLayer) < layer.value
											: valid || parseInt(columnLayer) < layer.value;
									break;
								default:
							}
						} else {
							valid = false;
						}
					});
					return valid;
				})
				: allEntries;
		if (mainView && mainView._id === view._id) {
			mainView.count = filteredView.length;
			this.setState({ mainView: mainView });
		} else {
			const customViews = this.state.customViews;
			const viewIndex = customViews.findIndex((a) => a._id === view._id);
			customViews[viewIndex] &&
				(customViews[viewIndex].count = filteredView.length);
			this.setState({ customViews: customViews });
		}
	};

	selectView = (viewIndex) => {
		this.setState(
			{
				activeView: viewIndex,
				searchTerm: "",
				searching: false,
				checkedEntries: {},
			},
			() => {
				let viewsList = [];
				viewsList.push(this.state.mainView);
				this.props.updateSelectedViewIndex &&
					this.props.updateSelectedViewIndex(
						viewIndex,
						[...viewsList, ...this.state.customViews],
						this.props.entries.length
					);
				this.filterView();
			}
		);
	};

	addView = (view) => {
		const { customViews } = this.state;
		customViews.push(view);
		this.setState({ customViews }, () => {
			this.filterView();
			this.countFilteredEntries(view);
		});
		//this.props.addView(view);
	};

	updateFocusedView = (updates) => {
		const { focusedView, customViews, mainView } = this.state;
		let fView =
			mainView && focusedView === 0 ? mainView : customViews[focusedView - 1];
		fView = { ...fView, ...updates };
		if (mainView && focusedView === 0) {
			this.setState({ mainView: fView }, this.filterView);
		} else {
			const customViewIndex = customViews.findIndex((a) => a._id === fView._id);
			customViews[customViewIndex] = fView;
			this.setState({ customViews }, this.filterView);
		}
		//this.props.updateView(fView);
	};

	removeFocusedView = (viewId) => {
		const { customViews, mainView } = this.state;
		if (mainView && mainView._id === viewId) {
			this.setState({ mainView: null, activeView: 0 }, this.filterView);
		} else {
			const customViewIndex = customViews.findIndex((a) => a._id === viewId);
			customViews.splice(customViewIndex, 1);
			this.setState({ customViews, activeView: 0 }, this.filterView);
		}
		//this.props.removeView(viewId);
	};

	saveCurrentView = async (filters, columns) => {
		const { activeView, customViews, mainView, normalizedColumns } = this.state;
		const view =
			mainView && activeView === 0
				? mainView
				: customViews[activeView - (mainView ? 2 : 1)];
		const payload = {};
		let sortedColumns = [];
		normalizedColumns.forEach((nc) => {
			sortedColumns.push(nc.key);
		});

		if (
			filters &&
			view.filterLayers.length > 0 &&
			view.filterLayers[0].condition &&
			view.filterLayers[0].condition !== ""
		) {
			payload.filterLayers = view.filterLayers;
		} else if (filters && view.filterLayers.length > 0) {
			payload.filterLayers = [];
		}
		if (columns) {
			payload.toggledColumns = view.toggledColumns;
		}
		payload.columnsOrder = sortedColumns;

		await API()
			.patch(`/Organizations/${this.props.orgId}/views/${view._id}`, payload)
			.then((res) => {
				//this.setState({ submitting: false });
				//this.props.updateView(res.data);
				//find view and remove unsaved property
				if (mainView && activeView === 0) {
					if (filters) mainView.unsavedFilter = false;
					if (columns) mainView.unsavedColumns = false;
					this.setState({ mainView });
				} else {
					if (filters)
						customViews[activeView - (mainView ? 2 : 1)].unsavedFilter = false;
					if (columns)
						customViews[activeView - (mainView ? 2 : 1)].unsavedColumns = false;

					this.setState({ customViews });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { organizedEntries, normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key === column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		let reorganizedData = organizedEntries.sort((a, b) => {
			const columnType = column.type || typeof a[column.key];
			if (a[column.key] && b[column.key]) {
				switch (columnType) {
					case "array":
						return a[column.key]
							.join(", ")
							.toLowerCase()
							.trim()
							.localeCompare(b[column.key].join("").toLowerCase().trim());
					case "string":
						return a[column.key]
							.toLowerCase()
							.trim()
							.localeCompare(b[column.key].toLowerCase().trim());
					case "number":
						return a[column.key] - b[column.key];
					case "currency":
						return a[column.key] - b[column.key];
					case "date":
					case "time":
					case "timestamp":
						return (
							new Date(a[column.key]).getTime() -
							new Date(b[column.key]).getTime()
						);
					case "boolean":
						const a1 = a[column.key] ? "yes" : "no";
						const b1 = b[column.key] ? "yes" : "no";
						return a1.localeCompare(b1);
					case "bool":
						const c1 = a[column.key] ? "yes" : "no";
						const d1 = b[column.key] ? "yes" : "no";
						return c1.localeCompare(d1);
					default:
						return a[column.key]
							.toLowerCase()
							.trim()
							.localeCompare(b[column.key].toLowerCase().trim());
				}
			} else if (a[column.key]) {
				return 1;
			}
			return -1;
		});
		reorganizedData = sortDesc ? reorganizedData.reverse() : reorganizedData;
		this.setState(
			{ organizedEntries: reorganizedData, normalizedColumns: updatedColumns },
			this.searchEntries
		);
	};

	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};

	downloadReport = async () => {
		const { activeView, customViews, mainView, organizedEntries } = this.state;
		const selectedCustomView =
			mainView && activeView === 0
				? mainView
				: !mainView && activeView === 0 //|| (mainView && activeView == 1)
					? mainView
					: customViews[activeView - (mainView ? 2 : 1)];
		let columns = this.props.columns;
		if (selectedCustomView) {
			const customColumns = {};
			if (
				selectedCustomView.columnsOrder &&
				selectedCustomView.columnsOrder.length > 0
			) {
				let columnsOrder = selectedCustomView.columnsOrder;
				let toggledC = selectedCustomView.toggledColumns;

				columnsOrder.forEach((co) => {
					if (toggledC.includes(co)) {
						if (columns[co]) {
							customColumns[co] = columns[co];
						}
					}
				});
				columns = {};
				const mainColumn = Object.keys(this.props.columns)[0];
				columns[mainColumn] = this.props.columns[mainColumn];
				columns = { ...columns, ...customColumns };
			} else {
				//legacy
				selectedCustomView.toggledColumns.forEach((col) => {
					if (columns[col]) {
						customColumns[col] = columns[col];
					}
				});
				columns = {};
				const mainColumn = Object.keys(this.props.columns)[0];
				columns[mainColumn] = this.props.columns[mainColumn];
				columns = { ...columns, ...customColumns };
			}
		} else {
			const customColumns = {};
			Object.keys(this.state.toggledColumns).forEach((col) => {
				customColumns[col] = columns[col];
			});
			columns = {};
			const mainColumn = Object.keys(this.props.columns)[0];
			columns[mainColumn] = this.props.columns[mainColumn];
			columns = { ...columns, ...customColumns };
		}
		columns = { ...columns };
		const entries = organizedEntries; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (
						this.props.columnTypes[hc] === "boolean" ||
						this.props.columnTypes[hc] === "bool"
					) {
						dataRow[hc] = entry[hc] ? "Yes" : "No";
					} else if (this.props.columnTypes[hc] ==='timestamp'){
						dataRow[hc] = moment(entry[hc]).format('MM/DD/YYYY hh:mm A');
					}
						else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || "";
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData,
		};

		const reportDetails = selectedCustomView
			? { Title: selectedCustomView.name, Subject: selectedCustomView.name }
			: { Title: "View Report", Subject: "View Report" };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: "Simple Events",
			CreatedDate: new Date(),
		};
		wb.SheetNames.push("Data");
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets["Data"] = ws2;

		var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
			`${report.Title}_${moment().format("LLL")}.xlsx`
		);
	};

	addField = (field, cb) => {
		this.props.addField(field, () => {
			const newField = field.fields[field.fields.length - 1];
			const toggleColumns = this.state.toggledColumns;
			const normalizedColumns = this.state.normalizedColumns;
			const cfc = field._id + "*" + newField._id;
			toggleColumns[cfc] = true;
			normalizedColumns.push({
				label: this.props.columns[cfc],
				key: cfc,
				value: cfc,
				type:
					(this.props.columnTypes && this.props.columnTypes[cfc]) ||
					typeof this.props.columns[cfc],
				sortAsc: false,
				sortDesc: false,
			});
			this.setState(
				{ toggledColumns: toggleColumns, normalizedColumns: normalizedColumns },
				cb
			);
		});
	};

	deleteField = (field, cb) => {
		this.props.deleteField(field, () => {
			const toggleColumns = this.state.toggledColumns;
			const normalizedColumns = this.state.normalizedColumns;
			const cfc = field._id + "*" + field.fields[0]._id;
			delete toggleColumns[cfc];
			normalizedColumns.splice(
				normalizedColumns.findIndex((col) => col.key === cfc),
				1
			);
			this.setState(
				{ toggledColumns: toggleColumns, normalizedColumns: normalizedColumns },
				cb
			);
		});
	};

	renameField = (field, cb) => {
		this.props.updateField(field, () => {
			let normalizedColumns = this.state.normalizedColumns;
			field.fields.forEach((f) => {
				const cfc = field._id + "*" + f._id;
				normalizedColumns = normalizedColumns.map((col) => {
					if (col.key === cfc) {
						col.label = this.props.columns[cfc];
					}
					return col;
				});
			});
			this.setState({ normalizedColumns: normalizedColumns }, cb);
		});
	};

	convertField = (field, cb) => {
		this.props.updateField(field, () => {
			let normalizedColumns = this.state.normalizedColumns;
			field.fields.forEach((f) => {
				const cfc = field._id + "*" + f._id;
				normalizedColumns = normalizedColumns.map((col) => {
					if (col.key === cfc) {
						col.type = "text";
					}
					return col;
				});
			});
			this.setState({ normalizedColumns: normalizedColumns }, cb);
		});
	};

	removeField = (field, cb) => {
		this.props.removeField(field, () => {
			let normalizedColumns = this.state.normalizedColumns;
			normalizedColumns = normalizedColumns.filter(
				(col) => col.key !== this.state.focusedCustomField
			);

			let tempDeletedCustomField = this.state.deletedCustomField;
			tempDeletedCustomField[this.state.focusedCustomField] = true;

			this.setState(
				{
					normalizedColumns: normalizedColumns,
					deletedCustomField: tempDeletedCustomField,
				},
				cb
			);
		});
	};

	downloadPDF = () => {
		const { activeView, customViews, organizedEntries } = this.state;
		const { enablePDFDownload } = this.props;
		const selectedView =
			activeView === 0 ? { name: "All Entries" } : customViews[activeView - 1];
		enablePDFDownload(selectedView, organizedEntries);
	};

	render() {
		const {
			checkedEntries,
			normalizedColumns,
			organizedEntries,
			windowWidth,
			activeView,
			searching,
			searchedEntries,
			searchTerm,
			columnSearchTerm,
			defaultViewFilters,
			customViews,
			mainView,
			allEntries,
			addViewModalOpen,
			focusedView,
			deleteViewModalOpen,
			renameViewModalOpen,
			duplicateViewModalOpen,
			addCustomFieldModalOpen,
			focusedCustomField,
			editCustomFieldModalOpen,
			convertCustomFieldModalOpen,
			deleteCustomFieldModalOpen,
		} = this.state;
		const {
			categoryColumns,
			columns,
			subtitle,
			title,
			entries,
			mainActions,
			batchActions,
			enableDownloadViews,
			breadcrumbs,
			disableTabs,
			fields,
			allViewTitle,
			viewPanelActions,
			summaryPanel,
			summaryPanelEnabled,
			enablePDFDownload,
			activeRow,
			columnWidths,
			enableSalesforce
		} = this.props;
		let checkedEntryCount = Object.keys(checkedEntries).length;
		const selectedCustomView =
			mainView && activeView === 0
				? mainView
				: (!mainView && activeView === 0) || (mainView && activeView === 1)
					? null
					: customViews[activeView - (mainView ? 2 : 1)];
		let toggledColumns = { ...this.state.toggledColumns }
		if (selectedCustomView && selectedCustomView.toggledColumns) {
			const tg = {};
			selectedCustomView.toggledColumns.forEach((c) => (tg[c] = true));
			toggledColumns = tg;
		}

		let cutoff = Math.floor((windowWidth - 270) / 270);
		let views = [...customViews];
		if (mainView) {
			views = [mainView, ...customViews];
		}
		const overflowViews = views.slice(cutoff);
		const activeCustomViews = views.slice(0, cutoff);
		const activeViewCount = activeCustomViews.length;
		let overflowActive = false;
		if (cutoff > -1 && activeView > cutoff) {
			const activeCustomView = overflowViews[activeView - 1 - cutoff];
			overflowActive = true;
			activeCustomViews.push(activeCustomView);
		}
		let entryTable = searching ? searchedEntries : organizedEntries;
		const activeViewFilter = mainView ? activeView - 2 : activeView - 1;

		let selectedViewFilterLayers =
			mainView && mainView.filterLayers && activeView === 0
				? mainView.filterLayers
				: activeView > (mainView ? 1 : 0)
					? customViews[activeViewFilter].filterLayers
					: defaultViewFilters;

		let columnCount = normalizedColumns.length;

		let selectedColumnCount = 0;
		categoryColumns
			? Object.keys(categoryColumns).forEach((col, ic) => {
				categoryColumns[col].columns.forEach((k, ik) => {
					const firstField = ic === 0 && ik === 0;
					if (toggledColumns[k] || firstField) {
						selectedColumnCount++;
					}
					if (columns[k]) {
						//columnCount++;
					}
				});
			})
			: Object.keys(columns).forEach((k) => {
				const firstField = columnCount === 0;
				if (toggledColumns[k] || firstField) {
					selectedColumnCount++;
				}
				if (columns[k]) {
					//columnCount++;
				}
			});
		const allColumnsSelected = selectedColumnCount === columnCount;

		let mainActionCallback = (cb) => {
			const updatedEntries = this.props.entries;

			switch (cb) {
				case "delete":
					this.setState(
						{
							organizedEntries: updatedEntries,
							allEntries: updatedEntries,
							checkedEntries: {},
						},
						() => {
							this.filterView();
							this.searchEntries();
							this.props.views.forEach((av) => {
								this.countFilteredEntries(av);
							});
						}
					);
					break;
				case "update":
					this.setState(
						{
							organizedEntries: updatedEntries,
							allEntries: updatedEntries,
							checkedEntries: {},
						},
						() => {
							this.filterView();
							this.searchEntries();
							this.props.views.forEach((av) => {
								this.countFilteredEntries(av);
							});
						}
					);
					break;
				default:
					this.checkEntries([]);
			}
		};

		return (
			<div className={`sectionContainer`}>
				<div className="sectionHeader ns">
					<div className="sectionTitleBar">
						<div className="sectionTitle">
							{((breadcrumbs && breadcrumbs.length > 0) || subtitle) && (
								<div>
									{breadcrumbs && breadcrumbs.length > 0 && (
										<Link
											to={`${breadcrumbs.length > 1
												? "./"
												: window.location.href.endsWith("/")
													? "../../"
													: "../"
												}`}
											className={` breadCrumbContainer ${breadcrumbs.length > 1 ? "internal" : ""
												}`}
										>
											{breadcrumbs.map((b, i) => (
												<div key={i} className="breadCrumb flex-ac mb-5">
													{i > 0 && (
														<div className="breadCrumbSeparator">/</div>
													)}
													<p className="small">{b.name}</p>
												</div>
											))}
										</Link>
									)}
									{subtitle && <p className="small">{subtitle}</p>}
								</div>
							)}
							{title && <h1 className="sectionName">{title}</h1>}
						</div>
						<div className="ml-a posRel">
							{summaryPanelEnabled && (
								<div
									style={{
										position: "absolute",
										right: 0,
										minWidth: "max-content",
									}}
								>
									{summaryPanel && summaryPanel}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="sectionsContainer">
					<div
						className={`sectionContainer ${checkedEntryCount > 0 ? " editing" : ""
							}`}
					>
						<div className="sectionHeader">
							{!disableTabs && (
								<div className="viewTabs">
									{mainView && (
										<ViewTab
											viewIndex={0}
											key={`defaultview${mainView.name}`}
											view={mainView}
											selected={activeView === 0}
											main={true}
											renameView={() =>
												this.setState({
													renameViewModalOpen: true,
													focusedView: 0,
												})
											}
											duplicateView={() =>
												this.setState({
													duplicateViewModalOpen: true,
													focusedView: 0,
												})
											}
											onClick={() => this.selectView(0)}
											deleteView={() =>
												this.setState({
													deleteViewModalOpen: true,
													focusedView: 0,
												})
											}
										/>
									)}
									{!mainView ? (
										<ViewTab
											viewIndex={0}
											key={"defaultView0"}
											view={{
												name: `All ${title ? title : allViewTitle ? allViewTitle : ""
													}`,
												count: entries.length,
												isBase: true,
												isMain: true,
											}}
											selected={activeView === 0}
											main={true}
											disableOptions={true}
											onClick={() => this.selectView(0)}
										/>
									) : (
										<ViewTab
											viewIndex={1}
											key={"defaultView1"}
											makeMainView={this.resetDefaultView}
											view={{
												name: `All ${title}`,
												count: entries.length,
												isBase: true,
											}}
											selected={activeView === 1}
											onClick={() => this.selectView(1)}
										/>
									)}
									{activeCustomViews &&
										activeCustomViews.map((view, index) => {
											if ((mainView && index > 0) || !mainView) {
												const viewIndex = index + 1;
												return (
													<ViewTab
														viewIndex={viewIndex}
														onClick={() => this.selectView(viewIndex)}
														key={`view${view.name}${viewIndex}`}
														view={view}
														makeMainView={this.makeMainView}
														renameView={() =>
															this.setState({
																renameViewModalOpen: true,
																focusedView: index,
															})
														}
														duplicateView={() =>
															this.setState({
																duplicateViewModalOpen: true,
																focusedView: index,
															})
														}
														deleteView={() =>
															this.setState({
																deleteViewModalOpen: true,
																focusedView: index,
															})
														}
														selected={
															activeView > cutoff &&
																viewIndex === activeCustomViews.length
																? true
																: activeView === viewIndex
																	? true
																	: false
														}
													/>
												);
											} else {
												return null;
											}
										})}
									{overflowViews.length > 0 &&
										(!(
											activeView > activeViewCount &&
											overflowViews.length - 1 === 0
										) ||
											(activeView < activeViewCount &&
												overflowViews.length > 0)) && (
											<MoreViewsTab
												overflowActive={overflowActive}
												overflowViews={overflowViews}
												activeView={activeView}
												makeMainView={this.makeMainView}
												onClick={(index) => this.selectView(index)}
												renameView={(index) =>
													this.setState({
														renameViewModalOpen: true,
														focusedView: index,
													})
												}
												duplicateView={(index) =>
													this.setState({
														duplicateViewModalOpen: true,
														focusedView: index,
													})
												}
												deleteView={(index) =>
													this.setState({
														deleteViewModalOpen: true,
														focusedView: index,
													})
												}
												activeCustomViewsCount={activeCustomViews.length + 1}
											/>
										)}
									<button
										className="ml-10 c-pointer flex aic p-7"
										onClick={() => this.setState({ addViewModalOpen: true })}
									>
										<i className="las la-plus black" style={{ fontSize: 16 }} />
									</button>
								</div>
							)}
						</div>

						<div className="sectionBody isTable p-0">
							<div className="actionButtonContainer headerHeight flex-ac">
								{mainActions.length > 0 && (
									<div className="flex-ac mr-10">
										<div className="mainActionContainer">
											{mainActions[0].type === "link" ? (
												<Link to={mainActions[0].target} className="mainAction">
													{mainActions[0].label}
												</Link>
											) : (
												<div
													className="mainAction"
													onClick={() =>
														mainActions[0].onClick(mainActionCallback)
													}
												>
													{mainActions[0].label}
												</div>
											)}
											{mainActions.length > 1 && (
												<UncontrolledDropdown inNavbar>
													<DropdownToggle className="columnToggle">
														<i className="las la-angle-down" />
													</DropdownToggle>
													<DropdownMenu className="columnDropdown">
														<div className="ph-20">
															<h5 className="mb-10">Options</h5>
															{mainActions.map((action, index) => {
																if (index > 0) {
																	return action.type === "link" ? (
																		<DropdownItem
																			key={`${action.type}-${index}`}
																		>
																			<Link
																				to={action.target}
																				className="black"
																			>
																				{action.label}
																			</Link>
																		</DropdownItem>
																	) : (
																		<DropdownItem
																			onClick={() =>
																				action.onClick(mainActionCallback)
																			}
																			key={`${action.type}-${index}`}
																		>
																			{action.label}
																		</DropdownItem>
																	);
																} else {
																	return null;
																}
															})}
														</div>
													</DropdownMenu>
												</UncontrolledDropdown>
											)}
										</div>
									</div>
								)}
								<InputField
									classes="m-0 viewSearch"
									placeholder="Search "
									clickIcon={true}
									required={true}
									value={searchTerm}
									onChange={(e) => {
										clearTimeout(this.searchTimeout);
										this.setState({ searchTerm: e.target.value });
										this.searchTimeout = setTimeout(
											() => this.searchEntries(),
											1000
										);
									}}
									prefix={
										<i
											className="las la-search"
											style={{ marginRight: 5, transform: "rotate(270deg)" }}
										/>
									}
									inputIcon={
										searchTerm !== "" && (
											<i
												onMouseDown={() =>
													this.setState({ searchTerm: "", searching: false })
												}
												className="las la-times-circle"
												style={{
													visibility: searchTerm === "" ? "hidden" : "visible",
												}}
											/>
										)
									}
								/>
								<UncontrolledDropdown inNavbar>
									<DropdownToggle
										className={`noExpand plus20 ${selectedViewFilterLayers.length > 0 &&
											selectedViewFilterLayers[0].condition !== ""
											? "selected"
											: ""
											}`}
									>
										<i className="las la-filter mr-10" />
										Filter
									</DropdownToggle>
									<DropdownMenu className="columnDropdown filterDropdown p-20">
										<h5 className="mb-10">View Filters</h5>
										<div className="viewFilterActionBar">
											<p className="mb-0">
												Showing {organizedEntries.length} out of{" "}
												{allEntries.length}
											</p>
											<div className="filterActionButtons flex-ac">
												<button
													className="seBlue mr-10"
													onClick={() => {
														if (
															mainView &&
															mainView.filterLayers &&
															activeView === 0
														) {
															const mav = this.state.mainView;
															mav.filterLayers = [
																{
																	column: "",
																	condition: "",
																	value: "",
																	concatOperator: "and",
																},
															];
															mav.unsavedFilter = true;
															this.setState({ mainView: mav }, this.filterView);
														} else if (activeView > (mainView ? 1 : 0)) {
															const avs = this.state.customViews;
															avs[activeViewFilter].filterLayers = [
																{
																	column: "",
																	condition: "",
																	value: "",
																	concatOperator: "and",
																},
															];
															avs[activeViewFilter].unsavedFilter = true;
															this.setState(
																{ customViews: avs },
																this.filterView
															);
														} else {
															let dvf = this.state.defaultViewFilters;
															dvf = [
																{
																	column: "",
																	condition: "",
																	value: "",
																	concatOperator: "and",
																},
															];

															this.setState(
																{ defaultViewFilters: dvf },
																this.filterView
															);
														}
													}}
												>
													Clear all
												</button>

												{!disableTabs &&
													(selectedCustomView ? (
														<button
															className={`min ${selectedCustomView.unsavedFilter
																? ""
																: "disabled"
																}`}
															onClick={() => this.saveCurrentView(true)}
														>
															Save to this view
														</button>
													) : (
														<button
															className={`min`}
															onClick={() =>
																this.setState({ addViewModalOpen: true })
															}
														>
															Save as new view
														</button>
													))}
											</div>
										</div>
										<div className="filterLayersContainer">
											{mainView && mainView.filterLayers && activeView === 0
												? (mainView.filterLayers.length > 0
													? mainView.filterLayers
													: [
														{
															column: "",
															condition: "",
															value: "",
															concatOperator: "and",
														},
													]
												).map((filter, index) => (
													<ColumnFilterRow
														key={`mainViewFilter${filter.column}${index}`}
														firstFilter={index === 0}
														columns={normalizedColumns}
														selectedColumn={filter.column}
														selectedCondition={filter.condition}
														filterValue={filter.value}
														selectedConcatOption={filter.concatOperator}
														filterCount={mainView.filterLayers.length}
														updateFilter={(updatedFilter) => {
															const mav = this.state.mainView;
															mav.filterLayers[index] = updatedFilter;
															this.setState({ mainView: mav }, () =>
																this.filterView(true)
															);
														}}
														removeFilter={() => {
															const mav = this.state.mainView;
															mav.filterLayers.splice(index, 1);
															if (mav.filterLayers.length === 0) {
																mav.filterLayers = [
																	{
																		column: "",
																		condition: "",
																		value: "",
																		concatOperator: "and",
																	},
																];
															}
															this.setState({ mainView: mav }, () =>
																this.filterView(true)
															);
														}}
													/>
												))
												: activeView > (mainView ? 1 : 0)
													? (customViews[activeViewFilter].filterLayers.length > 0
														? customViews[activeViewFilter].filterLayers
														: [
															{
																column: "",
																condition: "",
																value: "",
																concatOperator: "and",
															},
														]
													).map((filter, index) => (
														<ColumnFilterRow
															key={`${customViews[activeViewFilter].name}Filter${filter.column}${index}`}
															firstFilter={index === 0}
															columns={normalizedColumns}
															selectedColumn={filter.column}
															selectedCondition={filter.condition}
															filterValue={filter.value}
															selectedConcatOption={filter.concatOperator}
															filterCount={
																customViews[activeViewFilter].filterLayers
																	.length
															}
															updateFilter={(updatedFilter) => {
																const avs = this.state.customViews;
																avs[activeViewFilter].filterLayers[index] =
																	updatedFilter;
																this.setState({ customViews: avs }, () =>
																	this.filterView(true)
																);
															}}
															removeFilter={() => {
																const avs = this.state.customViews;
																avs[activeViewFilter].filterLayers.splice(
																	index,
																	1
																);
																if (
																	avs[activeViewFilter].filterLayers.length ===
																	0
																) {
																	avs[activeViewFilter].filterLayers = [
																		{
																			column: "",
																			condition: "",
																			value: "",
																			concatOperator: "and",
																		},
																	];
																}

																this.setState({ customViews: avs }, () =>
																	this.filterView(true)
																);
															}}
														/>
													))
													: defaultViewFilters.map((filter, index) => (
														<ColumnFilterRow
															key={`defaultFilter${filter.column}${index}`}
															firstFilter={index === 0}
															columns={normalizedColumns}
															selectedColumn={filter.column}
															selectedCondition={filter.condition}
															filterValue={filter.value}
															selectedConcatOption={filter.concatOperator}
															filterCount={defaultViewFilters.length}
															updateFilter={(updatedFilter) => {
																const dvf = this.state.defaultViewFilters;
																dvf[index] = updatedFilter;
																this.setState(
																	{ defaultViewFilters: dvf },
																	this.filterView
																);
															}}
															removeFilter={() => {
																const dvf = this.state.defaultViewFilters;
																dvf.splice(index, 1);
																this.setState(
																	{ defaultViewFilters: dvf },
																	this.filterView
																);
															}}
														/>
													))}
										</div>
										<button
											className="addFilterButton w-100"
											onClick={() => {
												if (
													mainView &&
													mainView.filterLayers &&
													activeView === 0
												) {
													const mav = this.state.mainView;
													mav.filterLayers.push({
														column: "",
														condition: "",
														value: "",
														concatOperator: "and",
													});
													this.setState({ mainView: mav });
												} else if (activeView > (mainView ? 1 : 0)) {
													const avs = this.state.customViews;
													avs[activeViewFilter].filterLayers.push({
														column: "",
														condition: "",
														value: "",
														concatOperator: "and",
													});
													this.setState({ customViews: avs });
												} else {
													const dvf = this.state.defaultViewFilters;
													dvf.push({
														column: "",
														condition: "",
														value: "",
														concatOperator: "and",
													});
													this.setState({ defaultViewFilters: dvf });
												}
											}}
										>
											<i className="las la-plus-circle mr-10" /> Add another
											filter layer
										</button>
									</DropdownMenu>
								</UncontrolledDropdown>
								<UncontrolledDropdown inNavbar>
									<DropdownToggle
										className={` noExpand plus20 ${!allColumnsSelected ? "selected" : ""
											}`}
									>
										<i
											className="las la-stream mr-10 "
											style={{ transform: "rotate(90deg)" }}
										/>
										Columns
									</DropdownToggle>
									<DropdownMenu right className="columnDropdown p-20">
										<h5 className="mb-20">Displayed Field Columns</h5>

										<InputField
											placeholder="Search for fields..."
											value={columnSearchTerm}
											onChange={(e) =>
												this.setState({ columnSearchTerm: e.target.value })
											}
											clickIcon={true}
											inputIcon={
												columnSearchTerm !== "" ? (
													<i
														onClick={() =>
															this.setState({ columnSearchTerm: "" })
														}
														className="las la-times-circle"
													/>
												) : (
													<i className="las la-search mr-10" />
												)
											}
										/>
										{!disableTabs && (
											<div className="flex aic">
												{selectedCustomView ? (
													<button
														className={` min ${!selectedCustomView.unsavedColumns
															? "disabled"
															: ""
															}`}
														onClick={() => this.saveCurrentView(false, true)}
													>
														Save to this view
													</button>
												) : (
													<button
														className="min w-100"
														onClick={() =>
															this.setState({ addViewModalOpen: true })
														}
													>
														Save as new view
													</button>
												)}
												{fields && (
													<button
														className="ml-10 min "
														onClick={() =>
															this.setState({ addCustomFieldModalOpen: true })
														}
													>
														Add new field
													</button>
												)}
											</div>
										)}
										<div className="columnToggleContainer">
											<div className={"mr-25"}>
												<CheckboxInput
													label="All Fields"
													classes="mb-10 pr-25"
													checked={allColumnsSelected}
													onClick={() => {
														if (selectedCustomView) {
															const mainView = this.state.mainView;
															if (
																mainView &&
																selectedCustomView._id === mainView._id
															) {
																if (allColumnsSelected) {
																	mainView.toggledColumns = [];
																} else {
																	const newToggledColumns =
																		Object.keys(columns);
																	delete newToggledColumns[0];
																	mainView.toggledColumns = newToggledColumns;
																}
																mainView.unsavedColumns = true;
																this.setState({ mainView }, this.filterView);
															} else {
																const customViews = this.state.customViews;
																const vi = customViews.findIndex(
																	(view) => view._id === selectedCustomView._id
																);
																if (vi >= 0) {
																	const cView = this.state.customViews[vi];
																	if (allColumnsSelected) {
																		cView.toggledColumns = [];
																	} else {
																		const newToggledColumns =
																			Object.keys(columns);
																		delete newToggledColumns[0];
																		cView.toggledColumns = newToggledColumns;
																	}
																	cView.unsavedColumns = true;
																	customViews[vi] = cView;
																}
																this.setState({ customViews }, this.filterView);
															}
														} else {
															let tc = this.state.toggledColumns;
															if (allColumnsSelected) {
																tc = {};
															} else {
																tc = {};
																Object.keys(columns).forEach((col, i) => {
																	if (i > 0) tc[col] = true;
																});
															}
															this.setState({ toggledColumns: tc });
														}
													}}
												/>
											</div>
											<DragDropContext
												onDragEnd={(result) => {
													if (
														!result.destination ||
														result.destination.index === 0
													) {
														return;
													}
													const normalizedItems = reorder(
														normalizedColumns,
														result.source.index,
														result.destination.index
													);
													const keys = normalizedItems.map((ni) => {
														return ni.key;
													});
													let viewId = selectedCustomView
														? selectedCustomView._id
														: false;

													if (viewId) {
														const mainView = this.state.mainView;
														if (mainView && viewId === mainView._id) {
															mainView.unsavedColumns = true;
															mainView.columnsOrder = keys;

															this.setState({ mainView }, this.filterView);
														} else {
															const customViews = this.state.customViews;
															const vi = customViews.findIndex(
																(view) => view._id === viewId
															);
															if (vi >= 0) {
																const cView = this.state.customViews[vi];

																cView.unsavedColumns = true;
																cView.columnsOrder = keys;
																customViews[vi] = cView;
															}
															this.setState({ customViews }, this.filterView);
														}
													}

													this.setState({ normalizedColumns: normalizedItems });
												}}
											>
												<Droppable droppableId="droppable">
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															style={getComponentListStyle(
																snapshot.isDraggingOver
															)}
														>
															{categoryColumns ? (
																<div>
																	{normalizedColumns
																		.filter(
																			(k, ik) =>
																				ik === 0 ||
																				(columns[k.key] &&
																					columns[k.key]
																						.toLowerCase()
																						.includes(
																							columnSearchTerm.toLowerCase()
																						))
																		)
																		.map((key, j) => {
																			const firstField = j === 0;
																			return (
																				<Draggable
																					isDragDisabled={firstField}
																					key={`#${key.key}-${j}`}
																					draggableId={`#${key.key}-${j}`}
																					index={j}
																				>
																					{(provided, snapshot) => (
																						<div
																							ref={provided.innerRef}
																							{...provided.draggableProps}
																							{...provided.dragHandleProps}
																							style={getItemStyle(
																								snapshot.isDragging,
																								provided.draggableProps.style
																							)}
																							className={`${firstField
																								? "columnToggleDisabled"
																								: ""
																								}`}
																						>
																							{this.renderToggleColumnItem(
																								key.key,
																								toggledColumns,
																								selectedCustomView
																									? selectedCustomView._id
																									: false,
																								firstField
																							)}
																						</div>
																					)}
																				</Draggable>
																			);
																		})}
																</div>
															) : (
																Object.keys(columns)
																	.slice(1)
																	.filter((k) =>
																		columns[k]
																			.toLowerCase()
																			.includes(columnSearchTerm.toLowerCase())
																	)
																	.map((key) => {
																		return this.renderToggleColumnItem(
																			key,
																			toggledColumns,
																			selectedCustomView
																				? selectedCustomView._id
																				: false
																		);
																	})
															)}
														</div>
													)}
												</Droppable>
											</DragDropContext>
										</div>
									</DropdownMenu>
								</UncontrolledDropdown>

								<div className="sectionOptionsContainer">
									{viewPanelActions &&
										viewPanelActions.length > 0 &&
										viewPanelActions.map((sa, index) => {
											return (
												sa.iconClass && (
													<>
														<button className="p-7">
															<i
																id={`viewAction-${index}`}
																onClick={sa.onClick}
																className={` ${sa.iconClass}`}
															/>
														</button>

														<UncontrolledTooltip
															target={`viewAction-${index}`}
															placement="left"
														>
															{sa.label}
														</UncontrolledTooltip>
													</>
												)
											);
										})}

									{enablePDFDownload && (
										<div
											id="downloadPDF"
											className="c-pointer fs-20"
											onClick={() => this.downloadPDF()}
										>
											<button className="p-7">
												<i className="las la-file-download" />
											</button>

											<UncontrolledTooltip
												target="downloadPDF"
												placement="left"
											>
												Download PDF
											</UncontrolledTooltip>
										</div>
									)}
									{enableDownloadViews && (
										<div
											id="downloadTable"
											className="c-pointer fs-20"
											onClick={() => this.downloadReport()}
										>
											<button className="p-7">
												<i className="las la-download" />
											</button>

											<UncontrolledTooltip
												target="downloadTable"
												placement="left"
											>
												Download Table Data
											</UncontrolledTooltip>
										</div>
									)}
								</div>
							</div>
							<div className="flex h-100 hasHeader">
								<ViewsTable
									importAvailable={this.props.importAvailable}
									title={title}
									columns={normalizedColumns}
									toggledColumns={toggledColumns}
									data={entryTable}
									mainColumnActive={true}
									checked={checkedEntries}
									checkRow={this.checkEntry}
									activeRow={activeRow}
									checkAllRows={this.checkEntries}
									deletedRows={this.props.deletedEntries}
									sortData={this.sortData}
									defaultSort={this.props.defaultSort}
									sortDirection={this.props.sortDirection}
									columnWidths={columnWidths}
								/>
								{this.props.viewPanel}
							</div>
						</div>

						<div
							className={`sectionFooter ph-20 ${batchActions && batchActions.length > 0 && checkedEntryCount > 0
								? "editing"
								: ""
								}`}
						>
							<div className="flex aic jcsb">
								<p style={{ width: 200 }} className="small gray mb-0 mr-25">
									{checkedEntryCount} selected out of {entryTable.length} record
									{entryTable.length === 1 ? "" : "s"}
								</p>
								<button
									style={{ padding: 7 }}
									onClick={() => {
										this.setState({ checkedEntries: {} });
									}}
									className={`mr-25 neu ${checkedEntryCount > 0 ? "" : "invisible"
										}`}
								>
									<i className="las la-times" style={{ margin: 0 }} />
								</button>
							</div>
							<div className="flex-ac footerActionContainer">
								<p className="mb-0 mr-25 lightGray">|</p>
								{batchActions.map((action) => (
									<button
										className={` neu ${action.class} mr-15`}
										onClick={(e) => {
											action.onClick(checkedEntries, (action) => {
												const updatedEntries = this.props.entries;
												switch (action) {
													case "delete":
														this.setState(
															{
																organizedEntries: updatedEntries,
																allEntries: updatedEntries,
																checkedEntries: {},
															},
															() => {
																this.filterView();
																this.searchEntries();
																this.props.views.forEach((av) => {
																	this.countFilteredEntries(av);
																});
															}
														);
														break;
													case "update":
														this.setState(
															{
																organizedEntries: updatedEntries,
																allEntries: updatedEntries,
																checkedEntries: {},
															},
															() => {
																this.filterView();
																this.searchEntries();
																this.props.views.forEach((av) => {
																	this.countFilteredEntries(av);
																});
															}
														);
														break;
													default:
														this.checkEntries([]);
												}
											});
											e.currentTarget.blur();
										}}
									>
										{action.iconClass && action.iconClass !== "" && (
											<i className={`${action.iconClass} mr-10`} />
										)}
										{action.pluralLabel
											? checkedEntryCount > 1
												? action.pluralLabel
												: action.label
											: action.label}
									</button>
								))}
							</div>
						</div>
					</div>
					{this.props.children}
				</div>

				{addViewModalOpen && (
					<AddViewModal
						isOpen={addViewModalOpen}
						orgId={this.props.orgId}
						toggle={() => this.setState({ addViewModalOpen: false })}
						filterLayers={
							mainView && mainView.filterLayers && activeView === 0
								? mainView.filterLayers
								: activeView > (mainView ? 1 : 0)
									? customViews[activeViewFilter].filterLayers
									: defaultViewFilters
						}
						toggledColumns={toggledColumns}
						addView={this.addView}
						type={this.props.type}
						referenceCollection={this.props.referenceCollection}
						referenceId={this.props.referenceId}
						normalizedColumns={normalizedColumns}
						defaultColumns={this.props.columns}
					/>
				)}

				{renameViewModalOpen && focusedView >= 0 && (
					<RenameViewModal
						orgId={this.props.orgId}
						view={
							mainView && focusedView === 0
								? mainView
								: customViews[focusedView - (mainView ? 1 : 0)]
						}
						updateView={this.updateFocusedView}
						isOpen={renameViewModalOpen}
						toggle={() => this.setState({ renameViewModalOpen: false })}
					/>
				)}
				{duplicateViewModalOpen && focusedView >= 0 && (
					<DuplicateViewModal
						orgId={this.props.orgId}
						view={
							mainView && focusedView === 0
								? mainView
								: customViews[focusedView - (mainView ? 1 : 0)]
						}
						addView={this.addView}
						isOpen={duplicateViewModalOpen}
						toggle={() => this.setState({ duplicateViewModalOpen: false })}
						type={this.props.type}
						referenceCollection={this.props.referenceCollection}
						referenceId={this.props.referenceId}
						normalizedColumns={normalizedColumns}
					/>
				)}

				{deleteViewModalOpen && focusedView >= 0 && (
					<DeleteViewModal
						orgId={this.props.orgId}
						view={
							mainView && focusedView === 0
								? mainView
								: customViews[focusedView - (mainView ? 1 : 0)]
						}
						removeView={this.removeFocusedView}
						isOpen={deleteViewModalOpen}
						toggle={() => this.setState({ deleteViewModalOpen: false })}
					/>
				)}

				{addCustomFieldModalOpen && (
					<AddCustomFieldModal
						enableSalesforce={enableSalesforce}
						isOpen={addCustomFieldModalOpen}
						toggle={() => this.setState({ addCustomFieldModalOpen: false })}
						orgId={this.props.orgId}
						fields={fields}
						addField={this.addField}
						eventId={this.props.eventId}
						programId={this.props.programId}
						section={this.props.section}
					/>
				)}
				{editCustomFieldModalOpen && focusedCustomField && (
					<EditCustomFieldModal
						enableSalesforce={enableSalesforce}
						orgId={this.props.orgId}
						isOpen={editCustomFieldModalOpen}
						toggle={() => this.setState({ editCustomFieldModalOpen: false })}
						field={focusedCustomField}
						fields={fields}
						fieldName={columns[focusedCustomField]}
						renameField={this.renameField}
						eventId={this.props.eventId}
						programId={this.props.programId}
						section={this.props.section}
					/>
				)}

				{convertCustomFieldModalOpen && focusedCustomField && (
					<ConvertCustomFieldModal
						orgId={this.props.orgId}
						isOpen={convertCustomFieldModalOpen}
						toggle={() => this.setState({ convertCustomFieldModalOpen: false })}
						field={focusedCustomField}
						convertField={this.convertField}
						eventId={this.props.eventId}
						programId={this.props.programId}
						section={this.props.section}
					/>
				)}
				{deleteCustomFieldModalOpen && focusedCustomField && (
					<RemoveCustomFieldModal
						orgId={this.props.orgId}
						isOpen={deleteCustomFieldModalOpen}
						toggle={() => this.setState({ deleteCustomFieldModalOpen: false })}
						field={focusedCustomField}
						removeField={this.removeField}
						eventId={this.props.eventId}
						programId={this.props.programId}
						section={this.props.section}
					/>
				)}
			</div>
		);
	}
}

export default ViewsContainer;
