import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import ViewableSchedule from './viewableSchedule';
import API from '../../../utils/API';
import moment from 'moment';

import ViewsTable from '../../../components/tables/ViewsTable';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';

import FieldModal from '../../../components/modals/fieldModal';

class EventAttendeeSessions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			addSessionToggle: false,
			showRemoveSessionModal: false,
			showIncludeSessionModal: false,
			markedSession: null,
			excluding: false,
			agendaDates: this.props.agendaDates,
			attendedSessions: this.props.editedAttendee.attendedSessions.map((a) => a.sessionId)
		};
	}

	componentDidMount() {
		this.setState({ showAddSessionModal: this.props.addSessionToggle });
	}

	toggleAddSession = () => {
		this.setState({ addSessionToggle: !this.state.addSessionToggle });
	};

	toggleRemoveSession = (sessionId, exclude) => {
		const removing = sessionId;
		!removing
			? this.setState({ showRemoveSessionModal: false, markedSession: null, excluding: false })
			: this.setState({
				showRemoveSessionModal: !this.state.showRemoveSessionModal,
				markedSession: sessionId,
				excluding: exclude
			});
	};

	toggleIncludeSession = (sessionId) => {
		const removing = sessionId;
		!removing
			? this.setState({ showIncludeSessionModal: false, markedSession: null })
			: this.setState({
				showIncludeSessionModal: !this.state.showIncludeSessionModal,
				markedSession: sessionId
			});
	};

	render() {
		const {
			showRemoveSessionModal,
			showIncludeSessionModal,
			markedSession,
			excluding,
			addSessionToggle
		} = this.state;
		const { readOnly, agendaDates } = this.props;

		const isEmpty = Object.keys(agendaDates).length == 0;
		return (
			<div>
				<ActionButtonContainer mainActions={[{ onClick: this.toggleAddSession, label: 'Add Session' }]} />

				<div>
					{isEmpty ? <ViewableSchedule
						isEmpty={true}
					/> :
						Object.keys(agendaDates).map((date, index) => (
							<ViewableSchedule
								date={date}
								readOnly={readOnly}
								sessions={agendaDates[date]}
								index={index}
								locations={this.props.locations}
								toggleRemove={this.toggleRemoveSession}
								toggleInclude={this.toggleIncludeSession}
								excluding={this.props.excluding}
								excludedSessions={this.props.editedAttendee.excludedSessions}
								attendedSessions={this.state.attendedSessions}
								attendee={this.props.attendee}
							/>
						))}

				</div>
				{addSessionToggle && (
					<AttendeeAddSessionModal
						isOpen={addSessionToggle}
						toggle={this.toggleAddSession}
						addSession={this.props.addSession}
						sessions={this.props.event.sessions}
						attendee={this.props.editedAttendee}
						excludedSessions={this.props.editedAttendee.excludedSessions}
						attendeeSessions={this.props.editedAttendee.sessions}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
					/>
				)}
				{showRemoveSessionModal && (
					<AttendeeRemoveSessionModal
						isOpen={showRemoveSessionModal}
						remove={this.props.removeSession}
						sessions={this.props.event.sessions}
						toggle={this.toggleRemoveSession}
						sessionId={markedSession}
						excluding={excluding}
						exclude={this.props.excludeSession}
						attendee={this.props.editedAttendee}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
					/>
				)}

				<AttendeeIncludeSessionModal
					isOpen={showIncludeSessionModal}
					include={this.props.includeSession}
					toggle={this.toggleIncludeSession}
					sessions={this.props.event.sessions}
					sessionId={markedSession}
					attendee={this.props.editedAttendee}
					eventId={this.props.eventId}
					orgId={this.props.orgId}
				/>
			</div>
		);
	}
}

export default EventAttendeeSessions;

class AttendeeAddSessionModal extends Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name',
			date: 'Date',
			startTime: 'Start time',
			isAllDay: 'All Day'
		};
		const columnTypes = {
			name: 'text',
			date: 'date',
			startTime: 'time',
			isAllDay: 'boolean'
		};
		const columnWidth = {
			//name: '100%'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			name: '',
			sessions: [
				...this.props.sessions
					.filter(
						(session) =>
							!this.props.attendeeSessions.find((sesh) => session._id == sesh._id) &&
							!this.props.excludedSessions.find((sesh) => session._id == sesh._id)
					)
					.map((session) => {
						//console.log(session);
						/* const sessionDesc = `${session.name} ${moment(session.date).format(
							'MM/DD/YYYY'
						)} (${!session.isAllDay ? `${session.startTime} - ${session.endTime}` : `All Day`})`;
						return { _id: session._id, name: sessionDesc, color: '#00B8D9' }; */
						return {
							_id: session._id,
							date: session.date,
							name: session.name,
							startTime: session.startTime,
							isAllDay: session.isAllDay
						};
					})
			],
			selectedValue: { label: '', color: '#00B8D9' },
			added: 0,
			submitting: false,
			invalid: false,
			success: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedSessions: {},
			columnWidth: columnWidth,
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	reset = (e) => {
		this.props.toggle();
	};

	handleSessionChosen = (e) => {
		this.setState({ selectedValue: e });
	};

	addSession = async (sessionId) => {
		const { checkedSessions } = this.state;
		const { eventId, orgId, attendee } = this.props;

		Object.keys(checkedSessions).forEach(async (id) => {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/attendees/${attendee._id}`, {
					request: 'addSession',
					session: id
				})
				.then((res) => {
					if (res.data) {
						const session = this.props.sessions.find((s) => s._id === id);
						this.props.addSession(session);
						this.props.sessions.push(session);
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		});
		this.reset();
	};

	updatedCheckedSessions = (checked) => {
		this.setState({ checkedSessions: checked });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			sessions,
			submitting,
			selectedValue,
			normalizedColumns,
			toggledColumns,
			columnWidth,
			selectedColumnSort,
			sortDesc
		} = this.state;
		let sessionsData = sessions;

		if (selectedColumnSort) {
			sessionsData = sessionsData.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			sessionsData = sortDesc ? sessionsData.reverse() : sessionsData;
		}
		return (
			<div>
				<FieldModal
					size="large"
					isOpen={isOpen}
					modalTitle={"Add Session"}
					bodyHeaderText={'You are adding this session'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<ViewsTable
									classes={'fit'}
									title={"Sessions"}
									columns={normalizedColumns}
									toggledColumns={toggledColumns}
									data={sessionsData}
									mainColumnActive={false}
									columnWidth={columnWidth}
									checked={this.state.checkedSessions}
									checkRow={(entryId) => {
										const checked = this.state.checkedSessions;
										checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
										this.updatedCheckedSessions(checked);
										this.setState({
											checkedSessions: checked
										});
									}}
									checkAllRows={(entryIds) => {
										let checked = this.state.checkedSessions;
										entryIds.length > 0
											? entryIds.forEach((entryId) => {
												checked[entryId] = true;
											})
											: (checked = {});
										this.updatedCheckedSessions(checked);

										this.setState({
											checkedSessions: checked
										});
									}}
									sortData={this.sortData}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => {
						this.setState({ submitting: true });
						if (selectedValue.name !== '') {
							this.addSession(selectedValue.value);
						} else {
							this.setState({ invalid: true, submitting: false });
						}
					}}
					actionButtonDisabled={submitting}
					isFullWidthBody={true}


				></FieldModal>







			</div>

		);
	}
}
class AttendeeRemoveSessionModal extends React.Component {
	state = {
		submitting: false
	};

	removeSession = async () => {
		const { eventId, orgId, sessionId, attendee } = this.props;

		const session = this.props.sessions.find((s) => s._id === sessionId);
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${attendee._id}`, {
				session: sessionId,
				request: 'removeSession'
			})
			.then((res) => {
				if (res.data) {
					this.props.remove(session);
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};
	excludeSession = async () => {
		const { eventId, orgId, sessionId, attendee } = this.props;
		const session = this.props.sessions.find((s) => s._id === sessionId);
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${attendee._id}`, {
				session: sessionId,
				request: 'excludeSession'
			})
			.then((res) => {
				if (res.data) {
					/*editedAttendee.sessions = editedAttendee.sessions.filter((s) => {
						if (s._id !== session) return true;
						else sesh = s;
					}); */
					this.props.exclude(session);
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};
	render() {
		const { isOpen, toggle } = this.props;
		const { submitting } = this.state;
		return (

			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`${this.props.excluding ? 'Exclude ' : 'Remove '}Session`}
					bodyHeaderText={`You are about to ${this.props.excluding ? 'exclude' : 'remove'} this session from attendee, would
						you like to continue?`}
					bodyContent={() => {
						return (
							'')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? this.props.excluding ? 'Exclude' : 'Remove' : this.props.excluding ? 'Excluding...' : 'Removing'}
					actionButton={this.props.excluding ? this.excludeSession : this.removeSession}
					actionButtonDisabled={submitting}
					isDelete={true}

				></FieldModal>

			</div>

		);
	}
}

class AttendeeIncludeSessionModal extends React.Component {
	state = {
		submitting: false
	};

	includeSession = async () => {
		this.setState({ submitting: true });
		const { eventId, orgId, sessionId, attendee } = this.props;

		const session = this.props.sessions.find((s) => s._id === sessionId);
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${attendee._id}`, {
				session: sessionId,
				request: 'includeSession'
			})
			.then((res) => {
				if (res.data) {
					this.props.include(session);
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};
	render() {
		const { isOpen, toggle } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Include Session"}
					bodyHeaderText={'You are about to include this session back into the attendee agenda, would you like to continue?'}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Include' : 'Including...'}
					actionButtonDisabled={submitting}
					actionButton={() => this.includeSession()}
				></FieldModal>



			</div>

		);
	}
}
