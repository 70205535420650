import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import ToggleField from '../../../../components/inputs/toggleField';
import SelectField from '../../../../components/inputs/selectField';

class AddPointsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			availablePointOptions: this.props.availablePoints.map((p) => {
				return { label: p.customLabel ? p.customLabel : p.actionName, value: p._id };
			}),
			selectedValue: { label: '', color: '#00B8D9' },
			isCustomPoint: false,
			customLabel: '',
			description: '',
			pointSuffix: '',
			pointValue: 0,
			maxPointsAllowed: 0,
			oncePerReference: false,
			active: false,
			callToActionLabel: '',
			callToActionUrl: '',
			submitting: false,
			invalid: false,
			success: false
		};
	}
	handlePointsChosen = (e) => {
		const pointsChosen = this.props.availablePoints.find((p) => p._id == e.value);
		if (pointsChosen) {
			this.setState({
				selectedValue: e,
				description: pointsChosen.description,
				pointSuffix: pointsChosen.pointSuffix,
				value: pointsChosen.value,
				maxPointsAllowed: pointsChosen.maxPointsAllowed,
				oncePerReference: pointsChosen.oncePerReference,
				callToActionLabel: pointsChosen.callToActionLabel,
				callToActionUrl: pointsChosen.callToActionUrl,
				active: false
			});
		}
	};

	handleSetCustomPoint = (e) => {
		const {isCustomPoint} = this.state;
		if(isCustomPoint){
			this.setState({isCustomPoint: false})
		} else {
			this.setState({
				isCustomPoint: true,
				selectedValue: { label: 'Custom Point', value: 'custom' },
				description: '',
				pointSuffix: '',
				pointValue: 0,
				maxPointsAllowed: 0,
				oncePerReference: false,
				callToActionLabel: '',
				callToActionUrl: '',
				active: false
			})
		}
	}

	submitInteractionPoints = () => {
		const { orgId, eventId } = this.props;
		if (this.state.selectedValue.value) {
			const data = {
				customLabel: this.state.customLabel,
				pointId: this.state.selectedValue.value,
				description: this.state.description,
				pointSuffix: this.state.pointSuffix,
				pointValue: this.state.pointValue,
				maxPointsAllowed: this.state.maxPointsAllowed,
				oncePerReference: this.state.oncePerReference,
				callToActionLabel: this.state.callToActionLabel,
				callToActionUrl: this.state.callToActionUrl,
				active: this.state.active
			};
			this.setState({ submitting: true });
			API().post(`Organizations/${orgId}/events/${eventId}/interactions/point`, data).then((res) => {
				if (res.data) {
					this.props.addInteractionPoint({ ...res.data.interactionPoint, active: this.state.active });
					this.props.toggle();
				}
			}).catch((err) => {
				console.log(err);
				this.setState({ submitting: false });
			})
		} else {
			this.setState({ invalid: true });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			availablePointOptions,
			submitting,
			invalid,
			customLabel,
			selectedValue,
			description,
			pointSuffix,
			pointValue,
			maxPointsAllowed,
			oncePerReference,
			callToActionLabel,
			callToActionUrl,
			active,
			isCustomPoint
		} = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add Interaction Point"}
					bodyHeaderText={'Points will be earned by attendees as they complete actions'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<ToggleField label="Set Custom Point" checked={isCustomPoint} icons={false} onChange={this.handleSetCustomPoint} />
								{!isCustomPoint && <SelectField
									label="Available Point Options"
									value={selectedValue}
									name="availablePointOptions"
									options={availablePointOptions}
									required={true}
									errorTrigger={invalid}
									onChange={this.handlePointsChosen}
								/>
							}
								{(selectedValue.value || isCustomPoint) && (
									<div>
										
										<InputField
											label="Label"
											value={customLabel}
											onChange={(e) => this.setState({ customLabel: e.target.value })}
										/>
										<InputField
											label="Description"
											value={description}
											onChange={(e) => this.setState({ description: e.target.value })}
										/>

										<InputField
											label="Points Suffix"
											value={pointSuffix}
											onChange={(e) => this.setState({ pointSuffix: e.target.value })}
										/>

										<InputField
											label="Point Value"
											value={pointValue}
											onChange={(e) => this.setState({ pointValue: e.target.value })}
										/>

										<InputField
											label="Max Points Allowed"
											value={maxPointsAllowed}
											onChange={(e) => this.setState({ maxPointsAllowed: e.target.value })}
										/>

										<ToggleField
											label="Once Per Reference"
											checked={oncePerReference}
											icons={false}
											onChange={() =>
												this.setState({
													oncePerReference: !oncePerReference
												})}
										/>

										<InputField label="Call To Action Label" value={callToActionLabel}
											onChange={(e) =>
												this.setState({
													callToActionLabel: e.target.value
												})}

										/>

										<InputField label="Call To Action Target"
											value={callToActionUrl}
											onChange={(e) =>
												this.setState({
													callToActionUrl: e.target.value
												})}

										/>

										<ToggleField
											label="Active"
											checked={active}
											icons={false}
											onChange={() =>
												this.setState({
													active: !active
												})}
										/>
									</div>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.submitInteractionPoints()}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}

export default AddPointsModal;
