import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import InventoryItem from './inventoryItem';
import AddInventoryModal from './modals/addInventoryModal';
import DeleteInventoryModal from './modals/deleteDefaultModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import group from './group';
let batchActionCB;

class EventInventory extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			category: 'Category',
			referenceNumber: 'Reference Number',
			vendor: 'Vendor',
			status: 'Status',
			quantity: 'Quantity',
			price: 'Price'
		};

		const defaultFieldTypes = {
			name: 'text',
			category: 'text',
			referenceNumber: 'text',
			vendor: 'text',
			status: 'text',
			quantity: 'number',
			price: 'currency'
		};

		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'category',
					'referenceNumber',
					'vendor',
					'status',
					'quantity',
					'price'
				]
			},
		};

		this.state = {
			categoryColumns: fieldCategories,
			loading: true,
			checkedInventory: {},
			addInventoryModalOpen: false,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			deletedInventory: [],
			inventoryViews: [],
			deleteInventoryModalOpen: false,
			sidePanelOpen: false,
			editCount: 0,
			activeRow: ''
		};
	}

	componentDidMount() {
		this.setState({ loading: false })
	}

	deleteCheckedInventory = () => {
		this.props.deleteInventory(Object.keys(this.state.checkedInventory), () => {
			batchActionCB && batchActionCB('delete');
			this.toggleDeleteInventory();
		});
	};

	deleteSingleItem = (item) => {
		this.props.deleteInventory(Object.keys(item), () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
			});
		});
	}

	toggleDeleteInventory = (checkedInventory, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedInventory: checkedInventory || this.state.checkedInventory,
			deleteInventoryModalOpen: !this.state.deleteInventoryModalOpen
		});
	};

	addInventory = async (item, cb) => {
		this.props.addInventory(item, () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				cb && cb();
			})
		});
	};

	toggleAddInventory = () => {
		this.setState({
			addInventoryModalOpen: !this.state.addInventoryModalOpen
		});
	};


	saveInventory = (inventory, cb) => {
		this.props.saveInventory(inventory, () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				cb && cb();
			})
		});
	}

	setActiveRow = (groupId) => {
		this.setState({ activeRow: groupId })
	}
	render() {
		const { inventory, program, orgId, programId, inventoryViews } = this.props;
		const {
			categoryColumns,
			deletedInventory,
			loading,
			columns,
			columnTypes,
			addInventoryModalOpen,
			checkedInventory,
			deleteInventoryModalOpen,
			sidePanelOpen,
			editCount,
			activeRow,
		} = this.state;
		const checkedCount = Object.keys(checkedInventory).length;

		return (
			<Switch>
				<Route path={`/${orgId}/programs/${programId}/inventory`}>
					<Fragment>
						<ViewsContainer
							//key={`inventory${editCount}`}
							activeRow={activeRow}
							categoryColumns={categoryColumns}
							orgId={orgId}
							columns={columns}
							columnTypes={columnTypes}
							views={inventoryViews}
							entries={inventory}
							deletedEntries={deletedInventory}
							title="Default Event Inventory"
							description="Inventory listed here will be added to all approved events. Add categories, vendor and defaults costs. "
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddInventory, label: 'Add Item' },
							]}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteInventory
								}
							]}
							enableDownloadViews={true}
							type={'Inventory'}
							referenceCollection={'Programs'}
							referenceId={programId}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>
						<DeleteInventoryModal
							isOpen={deleteInventoryModalOpen}
							toggle={() => this.toggleDeleteInventory()}
							title={`Delete Inventory ${checkedCount > 1 ? 'Items' : 'Item'}`}
							message={`You are about to delete ${checkedCount} inventory ${checkedCount > 1
								? 'items'
								: 'item'}, this is irreversible,
						would you like to continue?`}
							submit={() =>
								this.deleteCheckedInventory(Object.keys(checkedInventory), () => {
									this.setState({ checkedInventory: {}, deleteInventoryModalOpen: false });
								})}
						/>

						{addInventoryModalOpen && <AddInventoryModal
							isOpen={addInventoryModalOpen}
							program={program}
							programId={programId}
							orgId={this.props.orgId}
							handleSubmit={this.addInventory}
							toggle={this.toggleAddInventory}
						/>}
						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/programs/:programId/inventory/:inventoryId`}
									render={(props) => {
										const inventoryId = props.match.params.inventoryId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={inventoryId}>

											{!loading && <InventoryItem
												program={program}
												programId={programId}
												orgId={this.props.orgId}
												inventory={inventory}
												fields={this.props.fields}
												addNewField={this.props.addNewField}
												saveItem={this.saveInventory}
												setActiveRow={this.setActiveRow}
												deleteSingleItem={this.deleteSingleItem}
											/>}
											{/* 	<LoadingWizard loading={loading} /> */}
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/programs/:programId/inventory`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(EventInventory);
