import React, { Fragment } from 'react';

import API from '../../../../utils/API';


import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';

class RenameFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name ? this.props.name : '',
            submitting: false,
            requiredFields: { name: false }
        };
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    updateName = async () => {
        this.setState({ submitting: true });
        const { name } = this.state;
        const { eventId, orgId, formId, update, toggle, form } = this.props;
        const requiredFields = this.state.requiredFields;
        let valid = true;
        if (!name) {
            requiredFields.name = true;
            valid = false;
        }

        if (valid) {
            update(
                name
                ,
                () => {
                    toggle();
                }
            );

        } else {
            this.setState({ submitting: false, requiredFields: requiredFields });
        }
    };

    render() {
        const { isOpen, toggle } = this.props;
        const { submitting, name, requiredFields } = this.state;

        return (
            <div>

                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Rename Form"}
                    bodyHeaderText={'Enter a new name for your form.'}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <InputField
                                    label="Name"
                                    value={name}
                                    required={true}
                                    errorTrigger={requiredFields.name}
                                    onChange={this.handleNameChange}
                                />
                            </div>)
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
                    actionButton={() => this.updateName()}
                    actionButtonDisabled={submitting}


                ></FieldModal>
            </div>

        );
    }
}

export default RenameFormModal;
