import React from 'react';
import API from '../../utils/API';
import FieldModal from './fieldModal';

class RemoveCustomFieldModal extends React.Component {
	constructor(props) {
		super(props);
		const fieldIds = this.props.field.includes('*') ? this.props.field.split('*') : this.props.field;
		this.state = {
			submitting: false,
			fieldName: props.fieldName,
			categoryId: fieldIds[0],
			fieldId: fieldIds[1],
			requiredFields: {
				fieldName: false
			}
		};
	}

	deleteCustomField = async () => {
		this.setState({ submitting: true });
		const { orgId } = this.props;
		const { categoryId, fieldId } = this.state;
		this.setState({ submitting: true });

		const endpoint = this.props.eventId
			? `Organizations/${orgId}/events/${this.props.eventId}/customFields`
			: this.props.programId ? `Organizations/${orgId}/programs/${this.props.programId}/customFields`
				: `Organizations/${orgId}/customFields`;
		await API()
			.patch(endpoint, {
				categoryId: categoryId,
				fieldId: fieldId,
				deleteField: true,
				section: this.props.section
			})
			.then((res) => {
				this.props.removeField(res.data, this.props.toggle);
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting } = this.state;

		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Column"}
					bodyHeaderText={<span>You are removing this field, <b>this is irreversible</b>. Are you sure you want to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => (this.deleteCustomField())}
					actionButtonDisabled={submitting}
					isDelete={true}


				></FieldModal>

			</div>

		);
	}
}

export default RemoveCustomFieldModal;
