import React from 'react';
import AddAttendeesModal from './modals/addAttendeesModal';
import RemoveAttendeeModal from './modals/removeAttendeeModal';
import ImportGroupAttendeesModal from './modals/importAttendeesIntoGroupModal';
import ViewsTable from '../../../components/tables/ViewsTable';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import InputField from '../../../components/inputs/inputField';
import { UncontrolledTooltip } from 'reactstrap';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';

class EventGroupAttendees extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email'
		};
		const columnTypes = {
			firstName: 'string',
			lastName: 'string',
			email: 'string'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			addAttendeesModalOpen: false,
			removeAttendeeModalOpen: false,
			markedAttendee: null,
			importAttendeesModalOpen: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedAttendees: {},
			selectedColumnSort: null,
			sortDesc: false,
			searchTerm: '',
		};
	}

	toggleAddAttendees = () => {
		this.setState({ addAttendeesModalOpen: !this.state.addAttendeesModalOpen }, () => {
			this.props.toggleAddAttendees();
		});
	};

	toggleRemoveAttendee = (attendee) => {
		this.setState(
			{
				removeAttendeeModalOpen: !this.state.removeAttendeeModalOpen,
				markedAttendee: attendee || null
			},
			() => {
				this.props.toggleRemoveAttendees();
			}
		);
	};

	toggleImportAttendees = () => {
		this.setState({ importAttendeesModalOpen: !this.state.importAttendeesModalOpen }, () => {
			this.props.toggleImportAttendees();
		});
	};
	/* Search */
	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};
	/* Download */
	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};
	downloadReport = async () => {

		const { columns, columnTypes } = this.state;
		let attendees = this.props.editedGroup.attendees;
		const entries = attendees; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData
		};

		const reportDetails =
			{ Title: this.props.editedGroup.name + " Report", Subject: 'Group Session Report ' };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${moment().format('LLL')}.xlsx`
		);
	};
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const { markedAttendee, searchTerm, normalizedColumns, toggledColumns, selectedColumnSort, sortDesc, columns } = this.state;
		let attendees = this.props.editedGroup.attendees;
		let data = attendees;
		if (searchTerm.length > 0) {
			const searchTerms = searchTerm.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}

		let batchActions = [
			{
				type: 'button',
				label: 'Remove From Group',
				onClick: this.toggleRemoveAttendee,
				iconClass: 'las la-trash-alt',
				class: 'danger'
			}
		]
		return (
			<div className="sectionBody3 posRel mb-0 p-0">
				<div className="actionButtonContainer headerHeight flex-ac">
					<ActionButtonContainer
						mainActions={[{ onClick: this.toggleAddAttendees, label: 'Add Attendee' }]}
					/>
					<InputField
						classes="m-0 viewSearch"
						placeholder="Search "
						clickIcon={true}
						required={true}
						value={searchTerm}
						onChange={(e) => {
							clearTimeout(this.searchTimeout);
							this.setState({ searchTerm: e.target.value },
								() => {
									this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
								});

						}}
						prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
						inputIcon={searchTerm != '' &&
							<i
								onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
								className="las la-times-circle"
								style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
							/>}
					/>
					<div className="sectionOptionsContainer">
						<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
							<i className="las la-download" />
							<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
						</div>
					</div>
				</div>
				<ViewsTable
					title="Attendees"
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={data}
					mainColumnActive={false}
					checked={this.props.checkedAttendees}
					checkRow={(entryId) => {
						const checked = this.props.checkedAttendees;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.props.updateChecked(checked);
						this.setState({
							checkedAttendees: checked
						});
					}}
					checkAllRows={(entryIds) => {
						let checked = this.props.checkedAttendees;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.props.updateChecked(checked);

						this.setState({
							checkedAttendees: checked
						});
					}}
					sortData={this.sortData}
				/>


				<ActionButtonContainer
					mainActions={[]}
					batchActions={batchActions}
					checkedEntryCount={Object.keys(this.props.checkedAttendees).length}
					entriesLength={attendees.length}
					uncheckEntries={() => {
						this.props.updateChecked({});
						this.setState({
							checkedAttendees: {}
						});
					}}
				/>
				{this.props.addAttendeeToggle && (
					<AddAttendeesModal
						isOpen={this.props.addAttendeeToggle}
						toggle={this.toggleAddAttendees}
						existingAttendees={attendees}
						group={this.props.editedGroup}
						attendees={this.props.event.attendees}
						handleSubmit={this.props.updateGroup}
						addAttendees={this.props.addAttendees}
						loadMoreAttendees={this.props.loadMoreAttendees}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
					/>
				)}
				{this.props.deleteAttendeesModalOpen && (
					<RemoveAttendeeModal
						isOpen={this.props.deleteAttendeesModalOpen}
						toggle={this.toggleRemoveAttendee}
						existingAttendees={attendees}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						markedAttendee={markedAttendee}
						attendees={this.props.event.attendees}
						handleSubmit={this.props.updateGroup}
						removeAttendees={this.props.removeAttendees}
						group={this.props.editedGroup}
						checkedAttendees={this.props.checkedAttendees}
					/>
				)}
				{this.props.importAttendeesToggle && (
					<ImportGroupAttendeesModal
						isOpen={this.props.importAttendeesToggle}
						toggle={this.toggleImportAttendees}
						customFields={this.props.event.customFields.attendees}
						addAttendee={this.props.addAttendee}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						group={this.props.editedGroup}
					/>
				)}
			</div>
		);
	}
}

export default EventGroupAttendees;
