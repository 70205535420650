import React from 'react';

import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';
import FieldModal from '../../../../components/modals/fieldModal';
import axios from 'axios';

import InputField from '../../../../components/inputs/inputField';
function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}

class SendTestProgramTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
			invalid: false,
			attendees: [],
			sessions: [],
			groups: [],
			error: '',
			requiredFields: {},
			sentCount: 0,
			completed: false,
			totalEmails: 0,
			eventSessions: [],
			eventGroups: [],
			eventAttendees: [],
			testEmails: '',
			invalidError: false
		};
	}

	handleEmailsChange = (e) => {
		this.setState({
			testEmails: e.target.value
		});
	};

	sendTestEmails = async () => {
		const { testEmails } = this.state;
		const { orgId, eventId, program, template } = this.props;
		let emails = testEmails.replace(/ /g, '').split(',');
		axios.defaults.headers.post['Content-Type'] = 'application/json';

		let valid = true;
		const request = {};

		emails = emails.map((e) => {
			if (!ValidateEmail(e) || '') {
				valid = false;
			}

			if (e !== '') return e;
		});

		if (valid || emails.length == 0) {
			this.setState({ sending: true, invalidError: false, emailsTotal: emails.length });

			for (let emailCount = 0; emailCount < emails.length; emailCount++) {
				try {
					const res = await Scheduler()
						.post(`Organizations/${orgId}/programs/${program._id}/emailTemplate/${template._id}/test`, {
							isTest: true,
							testEmail: emails[emailCount]
						})
						.then((res) => {
							this.setState({ sentCount: this.state.sentCount + 1 });
						});
				} catch (err) {
					console.log(err);
				}
			}

			this.setState({ completed: true });
		} else {
			this.setState({ invalidError: true });
		}
	};
	render() {
		const { isOpen, toggle, email } = this.props;
		const { sending, completed, testEmails, invalidError, emailsTotal, sentCount } = this.state;

		return (

			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Send a Test Email from Template"}
					bodyHeaderText={!sending ? (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							Who would you like to send a test email to?
						</div>
					) : (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							{`Sending ${sentCount} of ${emailsTotal}`}
						</div>
					)}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								{!sending && (
									<InputField
										label="Emails"
										required={true}
										value={testEmails}
										errorTrigger={invalidError}
										errorMessage={'Invalid Email Detected'}
										onChange={this.handleEmailsChange}
										subText={'Use commas to separate multiple emails'}
									/>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Send Test' : completed ? '' : 'Sending...'}
					actionButton={() => this.sendTestEmails()}
					actionButtonDisabled={sending || completed}

					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}
				></FieldModal>
			</div>





		);
	}
}

export default SendTestProgramTemplateModal;
