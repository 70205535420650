import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

class ViewTab extends Component {
	state = {
		popupOpen: false
	};

	// Toggle the dropdown's open state
	togglePopup = () => {
		this.setState({
			popupOpen: !this.state.popupOpen
		});
	};

	//dissmiss pop up on screen click
	handleClickOutside = (event) => {
		const { viewIndex } = this.props;
		var x = event.target;
		while ((x = x.parentNode)) {
			if (x.id && x.id.includes(`view-${viewIndex}`)) {
				return;
			}
		}
		this.setState({
			popupOpen: false
		});
	};

	componentDidMount() {
		document.addEventListener('mouseup', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mouseup', this.handleClickOutside);
	}

	render() {
		const {
			selected,
			main,
			onClick,
			view,
			renameView,
			duplicateView,
			deleteView,
			viewIndex,
			disableOptions
		} = this.props;
		const { popupOpen } = this.state;
		const dropdownOpenClass = popupOpen ? 'dropdownOpen' : '';
		return (
			<div className={`viewTab ${selected ? 'selected' : ''}`} id={`view-${viewIndex}`}>
				<button
					className="viewTabTitle"
					onClick={() => {
						if (!selected) onClick();
					}}
				>
					{main && <i className="las la-home" />}
					{view.icon && <i className={`${view.icon} mr-10`} />}
					{view.name}{' '}
					<span className="count">
						({view.count || 0}) {view.unsavedFilter ? '*' : ''}
					</span>
				</button>
				{!disableOptions &&
					// allow it to shrink if necessary
					<div className={`flex-ac shrink ${dropdownOpenClass}`}>
						{/* Dropdown component that toggles based on the state of `popupOpen` */}
						<Dropdown isOpen={this.state.popupOpen} toggle={this.togglePopup} id={`view-${viewIndex}`} direction="down" >
							{/* Toggle button for the dropdown, visibility toggles based on `popupOpen` */}
							<DropdownToggle className={`columnToggle ${popupOpen ? 'visible' : ''}`}>
								<i className="las la-ellipsis-h" />
							</DropdownToggle>
							<DropdownMenu className="columnDropdown">
								<div className='ph-20'>

									<h5 className='mb-10'>View Settings</h5>
									{/* <DropdownItem
								className={`moreViewsPopoverItemMenuItem ${view.isBase ? 'disabled' : ''}`}
								style={{ textTransform: 'capitalize' }}
								onClick={() => updateVisibility(viewIndex)}
							>
								<i className="las la-eye mr-10" />{view.visibility ? view.visibility : 'Internal'}
							</DropdownItem> */}
								</div>
								{/* <hr /> */}
								<div className='ph-20'>
									{renameView && <DropdownItem
										className={` mb-10 moreViewsPopoverItemMenuItem ${view.isBase || view.isDefault
											? 'disabled'
											: ''}`}
										onClick={() => renameView(viewIndex)}
									>
										<i className="las la-pen mr-10" /> Rename
									</DropdownItem>}
									{duplicateView && <DropdownItem
										className={`moreViewsPopoverItemMenuItem ${view.isBase ? 'disabled' : ''} mb-10`}
										onClick={() => duplicateView(viewIndex)}
									>
										<i className="las la-copy mr-10" /> Duplicate
									</DropdownItem>}
									{this.props.makeMainView && <DropdownItem
										className={`moreViewsPopoverItemMenuItem ${view.isMain ? 'disabled' : ''}`}
										onClick={() => this.props.makeMainView(view)}
									>
										<i className="las la-home mr-10" /> Set as default view
									</DropdownItem>}
									{this.props.pinView && <DropdownItem
										className={`moreViewsPopoverItemMenuItem ${view.isPinned ? 'disabled' : ''}`}
										onClick={() => this.props.pinView(view)}
									>
										<i className="las la-thumbtack mr-10" /> Pin View
									</DropdownItem>}
								</div>
								<hr />
								<div className='ph-20'>
									{deleteView && <DropdownItem
										className={`moreViewsPopoverItemMenuItem ${view.isBase || view.isDefault
											? 'disabled'
											: ''}`}
										onClick={() => deleteView(viewIndex)}
									>
										<i className="las la-trash mr-10 danger" /> Delete
									</DropdownItem>}
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>}
			</div>
		);
	}
}

export default ViewTab;
