import React from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';

import Dropzone from 'react-dropzone';
import papa from 'papaparse';
import moment from 'moment';
import SelectField from '../../../../components/inputs/selectField';

let dropzoneRef;
const UNMAPPED_DATA = 'Unmapped Data';
const GROUP_DATA = 'Group Data';

class ImportSessionsModal extends React.Component {
	constructor(props) {
		super(props);
		const unmappedData = {};
		unmappedData[UNMAPPED_DATA] = {};
		const groupData = {};
		groupData[GROUP_DATA] = {};
		const groupOptions = props.groups.map((g) => {
			return { label: g.name, value: g._id };
		});
		const venue = this.props.event.venue;

		const customFields = [
			{ fieldName: 'Session Name', fieldType: 'text', _id: 'name' },
			{ fieldName: 'Subtitle', fieldType: 'text', _id: 'subtitle' },
			{ fieldName: 'Description', fieldType: 'text', _id: 'description' },
			{ fieldName: 'Location', fieldType: 'text', _id: 'location' },
			{ fieldName: 'Is General', fieldType: 'checkbox', _id: 'isGeneral' },
			{ fieldName: 'Date', fieldType: 'date', _id: 'date' },
			{ fieldName: 'Is All Day', fieldType: 'checkbox', _id: 'isAllDay' },
			{ fieldName: 'Start Time', fieldType: 'text', _id: 'startTime' },
			{ fieldName: 'End Time', fieldType: 'text', _id: 'endTime' },
			{ fieldName: 'Display Start Time', fieldType: 'text', _id: 'displayStartTime' },
			{ fieldName: 'Display End Time', fieldType: 'text', _id: 'displayEndTime' },
			{ fieldName: 'Display Timezone', fieldType: 'text', _id: 'displayTimezone' },
			{ fieldName: 'Meeting Type', fieldType: 'text', _id: 'virtualMeetingType' },
			{ fieldName: 'Start Offset', fieldType: 'text', _id: 'virtualStartOffset' },
			{ fieldName: 'Stop Offset', fieldType: 'text', _id: 'virtualStopOffset' },
			{ fieldName: 'Meeting Link', fieldType: 'text', _id: 'virtualMeetingLink' },
			{ fieldName: 'Recording Link', fieldType: 'text', _id: 'recordingLink' },
			{ fieldName: 'Chat Enabled', fieldType: 'checkbox', _id: 'chatEnabled' },
			{ fieldName: 'Reactions Enabled', fieldType: 'checkbox', _id: 'reactionsEnabled' },
			{ fieldName: 'Polls Enabled', fieldType: 'checkbox', _id: 'pollsEnabled' },
			{ fieldName: 'Review Enabled', fieldType: 'checkbox', _id: 'enableReview' }
		]
		if (!venue) {
			customFields.splice(3, 1,);
		}


		this.state = {
			files: [],
			accepted: [],
			rejected: [],
			customFields: [
				{
					title: 'General Information',
					_id: 0,
					fields: customFields,
				},
				...this.props.customFields
			],
			submitting: false,
			invalid: false,
			mapping: false,
			mappedData: {
				Category: {
					Header: ['data1']
				}
			},
			unmappedData: unmappedData,
			groupOptions: groupOptions,
			fieldOptions: [],
			importedSessions: [],
			finished: false,
			importCount: 0,
			importLength: 0,
			groupData: groupData,
			importedGroups: [],
			groupSessions: {},
			importedStarted: false,
			currentSection: 0,
			sectionCount: 4,
			importError: '',
			pageTemplate: '',
		};
	}

	reset = () => {
		const venue = this.props.event.venue;
		const customFields = [
			{ fieldName: 'Session Name', fieldType: 'text', _id: 'name' },
			{ fieldName: 'Subtitle', fieldType: 'text', _id: 'subtitle' },
			{ fieldName: 'Description', fieldType: 'text', _id: 'description' },
			{ fieldName: 'Location', fieldType: 'text', _id: 'location' },
			{ fieldName: 'Is General', fieldType: 'checkbox', _id: 'isGeneral' },
			{ fieldName: 'Date', fieldType: 'date', _id: 'date' },
			{ fieldName: 'Is All Day', fieldType: 'checkbox', _id: 'isAllDay' },
			{ fieldName: 'Start Time', fieldType: 'text', _id: 'startTime' },
			{ fieldName: 'End Time', fieldType: 'text', _id: 'endTime' },
			{ fieldName: 'Display Start Time', fieldType: 'text', _id: 'displayStartTime' },
			{ fieldName: 'Display End Time', fieldType: 'text', _id: 'displayEndTime' },
			{ fieldName: 'Display Timezone', fieldType: 'text', _id: 'displayTimezone' },
			{ fieldName: 'Meeting Type', fieldType: 'text', _id: 'virtualMeetingType' },
			{ fieldName: 'Start Offset', fieldType: 'text', _id: 'virtualStartOffset' },
			{ fieldName: 'Stop Offset', fieldType: 'text', _id: 'virtualStopOffset' },
			{ fieldName: 'Meeting Link', fieldType: 'text', _id: 'virtualMeetingLink' },
			{ fieldName: 'Recording Link', fieldType: 'text', _id: 'recordingLink' },
			{ fieldName: 'Chat Enabled', fieldType: 'checkbox', _id: 'chatEnabled' },
			{ fieldName: 'Reactions Enabled', fieldType: 'checkbox', _id: 'reactionsEnabled' },
			{ fieldName: 'Polls Enabled', fieldType: 'checkbox', _id: 'pollsEnabled' },
			{ fieldName: 'Review Enabled', fieldType: 'checkbox', _id: 'enableReview' }
		]
		if (!venue) {
			customFields.splice(3, 1,);
		}
		this.setState({
			files: [],
			accepted: [],
			rejected: [],
			customFields: [
				{
					title: 'General Information',
					_id: 0,
					fields: customFields,
				},
				...this.props.customFields
			],
			submitting: false,
			invalid: false,
			mapping: false,
			mappedData: {
				Category: {
					Header: ['data1']
				}
			},
			fieldOptions: [],
			importCount: 0,
			importLength: 0,
			importedSessions: [],
			importedGroups: [],
			groupSessions: {},
			cancelled: false,
		});
		this.props.toggle();
	};

	downloadCSVTemplate = () => {
		const mappedCategories = [];
		const mappedFieldNames = [];
		const mappedFieldTypes = [];
		this.state.customFields.forEach((category) => {
			category.fields.forEach((field) => {
				const fieldName = field.fieldName;
				mappedCategories.push(category.title);
				mappedFieldTypes.push(field.fieldType);
				mappedFieldNames.push(fieldName);
			});
		});
		const csv = papa.unparse([mappedFieldNames]);
		const filename = `SessionImportTemplate.csv`;
		var file = new Blob([csv], { type: 'text/csv' });
		if (
			window.navigator.msSaveOrOpenBlob // IE10+
		)
			window.navigator.msSaveOrOpenBlob(file, filename);
		else {
			// Others
			var a = document.createElement('a'),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	};

	downloadCSVErrorReport = () => {
		const mappedCategories = [];
		const mappedFieldNames = [];
		const mappedFieldTypes = [];
		const outPutError = [];
		this.state.customFields.forEach((category) => {
			category.fields.forEach((field) => {
				const fieldName = field.fieldName;
				mappedCategories.push(category.title);
				mappedFieldTypes.push(field.fieldType);
				mappedFieldNames.push(fieldName);
			});
		});
		var headers = mappedFieldNames;
		const unmappedProperties = Object.keys(this.state.unmappedData[UNMAPPED_DATA]);

		//adding all headers
		for (var unmappedHeaderIndex = 0; unmappedHeaderIndex < unmappedProperties.length; unmappedHeaderIndex++) {
			headers.push(unmappedProperties[unmappedHeaderIndex]);
		}
		headers.push('Error Message');
		outPutError.push(headers);

		this.state.rejected.forEach((session, index) => {
			var sessionRow = [];
			this.state.customFields[0].fields.forEach((property) => {
				sessionRow.push(session[property._id]);
			});

			//add customunmapped
			unmappedProperties.forEach((unmappedProperty) => {
				sessionRow.push(this.state.unmappedData[UNMAPPED_DATA][unmappedProperty][index + 1]);
			});
			sessionRow.push(session.errorMessage);

			outPutError.push(sessionRow);
		});

		const csv = papa.unparse(outPutError);
		const filename = `SessionErrorReport.csv`;
		var file = new Blob([csv], { type: 'text/csv' });
		if (
			window.navigator.msSaveOrOpenBlob // IE10+
		)
			window.navigator.msSaveOrOpenBlob(file, filename);
		else {
			var a = document.createElement('a'),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	};

	handleImport = () => {
		const { mappedData, groupData } = this.state;
		const sessions = [];
		const groups = [];
		if (!(mappedData['General Information'] && mappedData['General Information']['Session Name'])) {
			this.setState({ importError: 'Session Name is required' });
			return;
		} else {
			this.setState({ importError: '' });
		}
		this.setState({ submitting: true, mapping: false, importedStarted: true });
		Object.keys(mappedData).forEach((category) => {
			if (mappedData[category]._id !== 0 && mappedData[category]._id !== 1) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];
						data.forEach((sessionData, index) => {
							if (index > 0) {
								if (!sessions[index]) {
									sessions[index] = {
										metadata: []
									};
								} else if (!sessions[index].metadata) {
									sessions[index] = {
										...sessions[index],
										metadata: []
									};
								}
								sessions[index].metadata.push({
									categoryId: mappedData[category]._id,
									fieldId: data[0].value,
									fieldValue: sessionData
								});
							}
						});
					}
				});
			} else if (mappedData[category]._id === 0) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];
						data.forEach((sessionData, index) => {
							if (index > 0) {
								if (!sessions[index]) {
									sessions[index] = { metadata: [], rooming: [] };
								}

								sessions[index][data[0].value] = sessionData;
							}
						});
					}
				});
			} else if (mappedData[category]._id === 1) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];

						data.forEach((sessionData, index) => {
							if (index > 0) {
								if (!sessions[index]) {
									sessions[index] = {
										rooming: []
									};
								} else if (!sessions[index].rooming) {
									sessions[index] = {
										...sessions[index],
										rooming: []
									};
								}
								sessions[index].rooming.push({
									label: data[0].value,
									value: sessionData,
									type: 'text'
								});
							}
						});
					}
				});
			}
		});

		Object.keys(groupData[GROUP_DATA]).forEach((group) => {
			const groupColumn = groupData[GROUP_DATA][group];
			groupColumn.forEach((groupInclusion, index) => {
				if (index > 0) {
					if (groupInclusion != '') {
						if (sessions[index].groups) {
							sessions[index].groups.push(group);
						} else sessions[index].groups = [group];
					}
				}
			});
		});

		const filteredSessions = sessions.filter((session) => session !== null);

		this.setState({ submitting: true, mapping: false, importLength: filteredSessions.length }, () => {
			//;
			this.importSessions(filteredSessions);
		});
	};

	importSessionGroups = async () => {
		const { cancelled } = this.state;
		this.setState({ importingGroups: true });

		const sessionGroups = Object.keys(this.state.groupData[GROUP_DATA]);
		for (let g = 0; g < sessionGroups.length; g++) {
			if (cancelled) break;
			let group = sessionGroups[g];
			const groupedSessions = this.state.groupSessions[group] || [];
			let groupOption = this.state.groupOptions.find((gp) => gp.label.toLowerCase() == group.toLowerCase());
			if (group.includes('New Group:')) group = group.substring(group.indexOf('New Group: '));
			if (!groupOption) {
				groupOption = { label: group, value: '' };
			}

			await this.props.groupImportedSessions(groupOption, groupedSessions, (added) => {
				const importedGroups = this.state.importedGroups;
				if (added._id) {
					groupOption.value = added._id;
					this.state.groupOptions.push(groupOption);
					importedGroups.push({ name: group, added: added.sessions.length });
				} else {
					if (importedGroups.find((g) => g.name.toLowerCase() == group.toLowerCase())) {
						importedGroups.forEach((g) => {
							if (g.name.toLowerCase() == group.toLowerCase()) {
								g.added = g.added + added.sessionsAdded;
							}
						});
					} else {
						importedGroups.push({ name: group, added: added.sessionsAdded });
					}
				}

				this.setState({
					importedGroups: importedGroups
				});
			});
		}
		this.setState({ finished: true });
	};

	importSessions = async (sessions) => {
		const { importedSessions, mappedData } = this.state;
		this.setState({ submitting: true, mapping: false, importLength: sessions.length });
		try {
			for (let s = 0; s < sessions.length; s++) {
				if (this.state.cancelled) break;
				const session = sessions[s];
				const { eventId, orgId } = this.props;
				if (!session.name) {
					session.errorMessage = 'Session Name is required';
					this.state.rejected.push(session);
					this.setState({ invalid: true });
					continue;
				}
				if (mappedData['General Information']['Location']) {
					if (!session.location) {
						session.errorMessage = 'Session Location is required';
						this.state.rejected.push(session);
						this.setState({ invalid: true });
						continue;
					}
				}
				session.pageTemplate = this.state.pageTemplate
				await API()
					.post(`Organizations/${orgId}/events/${eventId}/session`, { ...session })
					.then((res) => {
						const groupSessions = this.state.groupSessions;

						if (res.data) {
							session.groups &&
								session.groups.forEach((g) => {
									if (groupSessions[g]) {
										groupSessions[g].push(res.data._id);
									} else {
										groupSessions[g] = [res.data._id];
									}
								});

							res.data.success = true;
							importedSessions.push(res.data);

							this.props.addSession(res.data);

							this.setState({
								importCount: this.state.importCount + 1,
								importedSessions: importedSessions,
								groupSessions: groupSessions
							});
						}
					})
					.catch((e) => {
						session.errorMessage = e.response.data.message;
						this.state.rejected.push(session);
						this.setState({ invalid: true });
					});
			}
			if (!this.state.cancelled) {
				this.importSessionGroups();
			}
			this.setState({ mapping: false, finished: true });
		} catch (ex) {
			console.log(ex);
			this.setState({
				invalid: true,
				submitting: false
			});
		}
	};

	onDrop = (files) => {
		papa.parse(files[0], {
			complete: (res) => {
				/* Remove Trailing Whitespace */
				const trimmedData = res.data.map(array =>
					array.map(element => element.trim())
				);
				const data = this.mapData(trimmedData);
				const mappedData = data.mappedData;
				const unmappedData = data.unmappedData;
				const fieldData = this.state.customFields.map((category) => {
					const title = category.title;
					const headers = category.fields.map((header) => {
						const name = header.fieldName;
						return {
							_id: header._id,
							category: title,
							categoryId: category._id,
							value: name,
							label: name
						};
					});
					return {
						label: title,
						value: category._id,
						options: headers
					};
				});
				this.setState({
					mapping: true,
					mappedData: mappedData,
					unmappedData: unmappedData,
					fieldOptions: fieldData
				});
			}
		});
		this.setState({
			files
		});
	};

	mapData = (csvData) => {
		const { customFields } = this.state;
		const csvHeaders = csvData[0];
		const mappedHeaders = [];
		const mappedData = {};
		customFields.forEach((category) => {
			const categoryTitle = category.title;
			mappedData[categoryTitle] = {};
			mappedData[categoryTitle]._id = category._id;
			category.fields.forEach((field) => {
				let fieldName = field.fieldName;

				const headerIndex = csvHeaders.findIndex((csvHeader) => {
					if (csvHeader.toLowerCase() == fieldName.toLowerCase()) {
						fieldName = csvHeader;
						return true;
					}
					return false;
				});
				mappedData[categoryTitle][fieldName] = [];
				mappedData[categoryTitle][fieldName][0] = { label: fieldName, value: field._id };
				if (headerIndex > -1) {
					csvData.forEach((row, index) => {
						if (index > 0) {
							if (!mappedData[categoryTitle][fieldName][index]) {
								mappedData[categoryTitle][fieldName][index] = [];
							}
							let value = null;
							switch (field.fieldType) {
								case 'number':
									value = Number.parseFloat(row[headerIndex]);
									break;
								case 'date':
									value = moment(row[headerIndex]).format('MM/DD/YYYY');
									break;
								case 'checkbox':
									value = row[headerIndex].toLowerCase() === 'yes' ? true : false;
									break;
								default:
									value = row[headerIndex];
							}
							mappedData[categoryTitle][fieldName][index] = value;
						}
					});
					mappedHeaders.push(fieldName);
				}
				if (mappedData[categoryTitle][fieldName].length < 2) {
					delete mappedData[categoryTitle][fieldName];
				}
			});
			if (Object.keys(mappedData[categoryTitle]).length < 2) {
				delete mappedData[categoryTitle];
			}
		});

		const unmappedData = {};
		unmappedData[UNMAPPED_DATA] = {};
		csvHeaders.forEach((header, headerIndex) => {
			if (!mappedHeaders.includes(header)) {
				unmappedData[UNMAPPED_DATA][header] = [];
				unmappedData[UNMAPPED_DATA][header][0] = { label: header, value: header };
				csvData.forEach((row, index) => {
					if (index > 0) {
						if (row[headerIndex] !== '') unmappedData[UNMAPPED_DATA][header][index] = row[headerIndex];
					}
				});
			}
		});
		return { mappedData, unmappedData };
	};

	formatGroupLabel = (data) => (
		<div style={groupStyles}>
			<span>{data.label}</span>
			<span style={groupBadgeStyles}>{data.options.length}</span>
		</div>
	);

	FieldContainer = (category, header, columnData, index, size, isUnmapped) => {
		const selectedOption = {
			value: header,
			label: header
		};
		const selectedGroup =
			category == GROUP_DATA &&
			this.state.groupOptions.find((g) => g.label.toLowerCase() == header.toLowerCase());
		const groupOptions = JSON.parse(JSON.stringify(this.state.groupOptions));
		if (category == GROUP_DATA && !selectedGroup) {
			groupOptions.push({ label: `New Group: ${header}`, value: header });
		}

		const dataPreview = JSON.parse(JSON.stringify(columnData));
		const data = dataPreview.length > 1 ? dataPreview.splice(1, 1) : "";
		return (
			<div className="w-100 mb-20" key={index}>
				<div className='flex aic jcsb'>
					<div className='flex w-100'>

						{category == UNMAPPED_DATA ? (
							<div className='mr-5'>
								<button
									id={`groupby-${index}`}
									style={{ padding: 0 }}
									onClick={(e) => {
										const unmappedData = this.state.unmappedData;
										const groupData = this.state.groupData;

										columnData &&
											columnData.length > 0 &&
											columnData.forEach((cd, index) => {
												if (index > 0) {
													if (!groupData[GROUP_DATA][cd]) {
														groupData[GROUP_DATA][cd] = [{ label: cd }];
														groupData[GROUP_DATA][cd][index] = cd;
													}
													groupData[GROUP_DATA][cd][index] = cd;
												}
											});
										this.setState({ groupData: groupData, unmappedData: unmappedData });
									}}
								>
									<i className="las la-users" />
									{/* Object.keys(this.state.groupData['Group Data']).includes(columnData[0].value) ? (
                                    'Add to Group'
                                ) : 'Create Group from column' */}
									<UncontrolledTooltip target={`groupby-${index}`} placement="top">Create group from values</UncontrolledTooltip>
								</button>
							</div>
						) : ''}

						<div
							className='w-100'
							style={{
								maxWidth: 283,
								border: isUnmapped ? `1px solid var(--danger)` : `1px solid var(--border)`,
								borderRadius: 5,
								padding: '3px 17px',
								height: 40
							}}>
							<p>{columnData[0].label}</p>
							<p className='small grey'>{data ? data : '{blank}'}</p>
						</div>

					</div>
					<i class="las la-arrow-right mr-5 ml-5" />
					<div className="w-100 mb-a">
						{category == GROUP_DATA ? (
							<SelectField
								label=""
								classes="w-100 mr-10 mb-0"
								options={groupOptions}
								value={
									selectedGroup ? selectedGroup : { label: `New Group: ${header}`, value: header }
								}
								onChange={(option) => {
									const groupData = this.state.groupData;
									delete groupData[GROUP_DATA][header];
									groupData[GROUP_DATA][option.label] = columnData;
									this.setState({ groupData: groupData });
								}}
								additionalSelectElement={
									category == GROUP_DATA ? (
										<div
											className='c-pointer'
											style={{
												position: 'absolute',
												zIndex: 999,
												right: 30,
												top: 10
											}}
											onClick={() => {
												const groupData = this.state.groupData;
												const unmappedData = this.state.unmappedData;

												delete groupData[GROUP_DATA][header];
												this.setState({ groupData: groupData, unmappedData: unmappedData });

											}}
										>
											<i class="lar la-times-circle grey"></i>
										</div>
									) : null
								}
							/>
						) : (
							<SelectField
								label=""
								style={{
									maxWidth: 283,
								}}
								classes=" neu mb-0 w-100"
								value={category != UNMAPPED_DATA && selectedOption}
								options={this.state.fieldOptions}
								formatGroupLabel={this.formatGroupLabel}
								onChange={(data) => {
									if (category != UNMAPPED_DATA) {
										const mappedData = this.state.mappedData;
										columnData[0] = { label: data.label, value: data._id };
										if (!mappedData[data.category]) {
											mappedData[data.category] = [];
											mappedData[data.category][data.label] = columnData;
											delete mappedData[category][header];
										} else if (!mappedData[data.category][data.label]) {
											mappedData[data.category][data.label] = columnData;
											delete mappedData[category][header];
										}
										this.setState({ mappedData: mappedData });
									} else {
										const mappedData = this.state.mappedData;
										const unmappedData = this.state.unmappedData;
										columnData[0] = { label: columnData[0].label, value: data._id };
										if (!mappedData[data.category]) {
											mappedData[data.category] = {};
											mappedData[data.category]._id = data.categoryId;
											mappedData[data.category][data.label] = columnData;
											delete unmappedData[category][header];
										} else if (!mappedData[data.category][data.label]) {
											mappedData[data.category][data.label] = columnData;
											delete unmappedData[category][header];
										}
										this.setState({ mappedData: mappedData, unmappedData: unmappedData })
									}
								}}
								additionalSelectElement={
									category != UNMAPPED_DATA || category == GROUP_DATA ? (
										<div
											className='c-pointer'
											style={{
												position: 'absolute',
												zIndex: 999,
												right: 30,
												top: 10
											}}
											onClick={() => {
												const mappedData = this.state.mappedData;
												const unmappedData = this.state.unmappedData;
												const propertyName = columnData[0].label.concat('-', columnData[0].headerIndex);
												if (!unmappedData[UNMAPPED_DATA]) {
													unmappedData[UNMAPPED_DATA] = [];
													delete mappedData[category][header];
													unmappedData[UNMAPPED_DATA][propertyName] = columnData;
												} else if (!unmappedData[UNMAPPED_DATA][propertyName]) {
													delete mappedData[category][header];
													unmappedData[UNMAPPED_DATA][propertyName] = columnData;
												} else if (unmappedData[UNMAPPED_DATA][propertyName]) {
													delete mappedData[category][header];
												}
												if (Object.keys(mappedData[category]).length < 2) {
													delete mappedData[category];
												}
												this.setState({ mappedData: mappedData, unmappedData: unmappedData });
											}}
										>
											<i class="lar la-times-circle grey"></i>
										</div>
									) : null
								}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	FieldContainerss = (category, header, columnData, index, size, isUnmapped) => {
		const selectedOption = {
			value: header,
			label: header
		};
		const selectedGroup =
			category == GROUP_DATA &&
			this.state.groupOptions.find((g) => g.label.toLowerCase() == header.toLowerCase());
		const groupOptions = JSON.parse(JSON.stringify(this.state.groupOptions));
		if (category == GROUP_DATA && !selectedGroup) {
			groupOptions.push({ label: `New Group: ${header}`, value: header });
		}
		const dataPreview = JSON.parse(JSON.stringify(columnData));
		return (
			<div className="neuCard mr-25 mb-20  w-250" key={index}>
				<div>
					<div>
						<div className="flex-ac" style={{ flexWrap: 'nowrap' }}>
							{category == GROUP_DATA ? (
								<SelectField
									label="Group"
									classes="w-100 mr-10"
									options={groupOptions}
									value={
										selectedGroup ? selectedGroup : { label: `New Group: ${header}`, value: header }
									}
									onChange={(option) => {
										const groupData = this.state.groupData;
										delete groupData[GROUP_DATA][header];
										groupData[GROUP_DATA][option.label] = columnData;
										this.setState({ groupData: groupData });
									}} />
							) : (
								<SelectField
									label="Field"
									classes="w-100 mr-10 neu"
									value={category != UNMAPPED_DATA && selectedOption}
									options={this.state.fieldOptions}
									formatGroupLabel={this.formatGroupLabel}
									onChange={(data) => {
										if (category != UNMAPPED_DATA) {
											const mappedData = this.state.mappedData;
											columnData[0] = { label: data.label, value: data._id };
											if (!mappedData[data.category]) {
												mappedData[data.category] = [];
												mappedData[data.category][data.label] = columnData;
												delete mappedData[category][header];
											} else if (!mappedData[data.category][data.label]) {
												mappedData[data.category][data.label] = columnData;
												delete mappedData[category][header];
											}
											this.setState({ mappedData: mappedData });
										} else {
											const mappedData = this.state.mappedData;
											const unmappedData = this.state.unmappedData;
											columnData[0] = { label: columnData[0].label, value: data._id };
											if (!mappedData[data.category]) {
												mappedData[data.category] = {};
												mappedData[data.category]._id = data.categoryId;
												mappedData[data.category][data.label] = columnData;
												delete unmappedData[category][header];
											} else if (!mappedData[data.category][data.label]) {
												mappedData[data.category][data.label] = columnData;
												delete unmappedData[category][header];
											}

											this.setState({ mappedData: mappedData, unmappedData: unmappedData })
										}
									}}
								/>
							)}
							{category == GROUP_DATA ? (
								<button
									onClick={(e) => {
										const groupData = this.state.groupData;
										const unmappedData = this.state.unmappedData;

										delete groupData[GROUP_DATA][header];
										this.setState({ groupData: groupData, unmappedData: unmappedData });
									}}
								>
									<i className="las la-times" />
								</button>
							) : category == UNMAPPED_DATA ? (
								<button
									className='prim'
									onClick={(e) => {
										const unmappedData = this.state.unmappedData;
										const groupData = this.state.groupData;

										columnData &&
											columnData.length > 0 &&
											columnData.forEach((cd, index) => {
												if (index > 0) {
													if (!groupData[GROUP_DATA][cd]) {
														groupData[GROUP_DATA][cd] = [{ label: cd }];
														groupData[GROUP_DATA][cd][index] = cd;
													}
													groupData[GROUP_DATA][cd][index] = cd;
												}
											});
										this.setState({ groupData: groupData, unmappedData: unmappedData });
									}}
								>
									<i className="las la-users" />
									{Object.keys(this.state.groupData['Group Data']).includes(columnData[0].value) && (
										<i className="las la-check" />
									)}
								</button>
							) : (
								<button
									onClick={(e) => {
										const mappedData = this.state.mappedData;
										const unmappedData = this.state.unmappedData;
										const propertyName = columnData[0].label.concat('-', columnData[0].headerIndex);
										if (!unmappedData[UNMAPPED_DATA]) {
											unmappedData[UNMAPPED_DATA] = [];
											delete mappedData[category][header];
											unmappedData[UNMAPPED_DATA][propertyName] = columnData;
										} else if (!unmappedData[UNMAPPED_DATA][propertyName]) {
											delete mappedData[category][header];
											unmappedData[UNMAPPED_DATA][propertyName] = columnData;
										} else if (unmappedData[UNMAPPED_DATA][propertyName]) {
											delete mappedData[category][header];
										}
										if (Object.keys(mappedData[category]).length < 2) {
											delete mappedData[category];
										}
										this.setState({ mappedData: mappedData, unmappedData: unmappedData });
									}}
								>
									<i className="las la-times" />
								</button>
							)}
						</div>
					</div>
					<div className='sectionTable fit'>
						<div className='tableSubColumns'>
							<div className='tableColumnHeader'>
								<div className='tableSubColumn'>{columnData[0].label}</div>
							</div>
							<div className='tableColumnRows'>
								{category !== GROUP_DATA &&
									dataPreview.splice(1, 4).map((data, index) => {
										return (
											<div className='tableColumnRow'>
												<div className='text-truncate rowText"'>{data ? data : '{blank}'}</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	cancelImport = () => {
		this.setState({ cancelled: true });
	};

	render() {
		const { isOpen, toggle, sessionsPages } = this.props;
		const {
			submitting,
			mapping,
			mappedData,
			groupData,
			unmappedData,
			finished,
			invalid,
			cancelled,
			importLength,
			rejected,
			importedGroups,
			importedSessions,
			importedStarted,
			importingGroups,
			importError,
			pageTemplate,
		} = this.state;
		const sessionPage = sessionsPages.find((path) => path.value == pageTemplate)
		const hasUnmappedData = Object.keys(unmappedData[UNMAPPED_DATA]).length > 0;
		const hasGroupData = Object.keys(groupData[GROUP_DATA]).length > 0;
		let index = 0;
		const defaultSessionPage = sessionsPages.find((path) => path.value == this.props.event.defaultSessionPage)
		return (
			<Modal isOpen={isOpen} toggle={toggle} className="import">
				<div className="modalHeader pb-0 ">

					<button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
					<div className="headerContainer">
						<h1>Import Session Data</h1>
						{/* <h4>Step {currentSection + 1} of {sectionCount}</h4> */}
					</div>
				</div>

				<ModalBody className="modalBody">
					{mapping ? (
						<div className={`sectionBody w-100`}
							style={{
								maxHeight: 400
							}}>

							<div className='mb-20'>
								<h4 className='mb-10'>Map the columns from the sheet to session fields</h4>
								{importError && <p className='red'>{importError}</p>}
							</div>
							{/* Page Template */}
							<SelectField
								label="Session Template"
								classes="w-100"
								value={sessionPage ? sessionPage : defaultSessionPage}
								options={sessionsPages}
								onChange={(path) => {
									this.setState({ pageTemplate: path.value });
								}}
							/>
							<div className='scrollContainer'>
								{hasGroupData && (
									<div>
										<h3>Group Data</h3>
										<p className="mb-20">
											{Object.keys(groupData[GROUP_DATA]).length} group{Object.keys(groupData[GROUP_DATA]).length == 1 ? '' : 's'} will be created.<br />
											Use the remove button <i className="las la-times-circle"></i> to exclude the group.
										</p>
										<div className="importedDataRow">
											{Object.keys(groupData[GROUP_DATA]).map((dataKey) =>
												this.FieldContainer(
													GROUP_DATA,
													dataKey,
													groupData[GROUP_DATA][dataKey],
													index++,
													true
												)
											)}
										</div>
									</div>
								)}
								{hasUnmappedData && (
									<div >
										<div>
											<div className='flex mb-10'>
												<h3 style={{ width: '50%' }}>Unmapped Data</h3>
												<h3 className='ml-25' style={{ width: '50%' }}>Session Column</h3>
											</div>
										</div>
										<div className="importedDataRow">
											{Object.keys(unmappedData[UNMAPPED_DATA]).map((dataKey) =>
												this.FieldContainer(
													UNMAPPED_DATA,
													dataKey,
													unmappedData[UNMAPPED_DATA][dataKey],
													index++,
													true,
													true
												)
											)}
										</div>
									</div>
								)}
								{Object.keys(mappedData).map((key) => {
									if (key !== '_id') {
										const categoryData = mappedData[key];
										return (
											<div key={key}>
												<div>
													<div className='flex mb-10'>
														<h3 style={{ width: '50%' }}>{key}</h3>
														<h3 className='ml-25' style={{ width: '50%' }}>Session Data</h3>
													</div>
												</div>
												<div className="importedDataRow">
													{Object.keys(categoryData).map(
														(dataKey) =>
															dataKey !== '_id' &&
															this.FieldContainer(
																key,
																dataKey,
																categoryData[dataKey],
																index++,
																hasUnmappedData
															)
													)}
												</div>
											</div>
										);
									}
								})}
							</div>

						</div>
					) : (

						<div className={`sectionBody w-100`}>
							{!submitting ? (
								<div className='flex flex-wrap column w-100'>
									<div className='mb-20'>
										<h4 className='mb-10'>Import session data from a spreadsheet to the simple.events workspace.</h4>
									</div>

									<Dropzone
										accept="text/csv"
										className="clickable dropzone-details banner mb-10"
										style={{ height: 375 }}
										ref={(node) => {
											dropzoneRef = node;
										}}
										onDrop={this.onDrop.bind(this)}
									>
										<div>
											<div className="addIcon">
												<i className="las la-plus"></i>
											</div>
											<p className='text-c'>
												Add sessions CSV File<br />
												or <span style={{ color: '#2B83F1' }}>Browse</span>
											</p>
										</div>
									</Dropzone>

									<div className='mb-40 mt-40 mt-20'>
										<h3>
											Need help getting started?
										</h3>
										<p>
											Make sure the data contains a column for session name
										</p>
										<p>
											<a className='click' onClick={this.downloadCSVTemplate}> Download </a> a sample CSV file
										</p>
									</div>
								</div>
							) : (
								<div className="flex column aic pt-20 h-100">
									<div className='sectionBlockAttendees'>
										<h3>
											{cancelled ? 'Import Stopped' : invalid ? (
												'Errors found in data'
											) : (
												finished ? (
													'Importing Complete'
												) : importingGroups ? (
													'Importing Groups...'
												) : (
													importedStarted && 'Importing Sessions...'
												)
											)}
										</h3>
										{importedStarted && (
											<p className="mb-20">
												{importedSessions.length} of {importLength} session{importLength == 1 ? '' : 's'}{' '}
												imported
											</p>
										)}
										{importedGroups.length > 0 && <div className="sectionBlock mb-20"><h4>Imported Groups</h4>
											{importedGroups.map((ig) => (
												<div className="importRow calibreRegular ">
													<div>{ig.name}</div>
													<div>
														{ig.added} session{ig.added == 1 ? '' : 's'} added
													</div>
												</div>
											))}</div>}

										<div className='sectionBlock'>
											<h3 className="pt-20">Imported Sessions</h3>
											{importedSessions.map((is) => (
												<div className="importRow calibreRegular ">
													<div>{is.name}</div>
													<div className="ml-10 mr-10">{is.success ? 'Imported' : is.error}</div>
													<div>
														{is.success ? (
															<i className="las la-check-circle green" />
														) : (
															<i className="las la-times-circle red" />
														)}
													</div>
												</div>
											))}
										</div>

										{invalid && (
											<div>
												<div>
													<a
														style={{ textDecoration: 'underline', color: '#2B83F1' }}
														onClick={this.downloadCSVErrorReport}
													>
														Export Error Report CSV
													</a>
												</div>
												<div className="sectionBody" style={{ padding: 0 }}>
													<div
														className="sectionTable mt-25"
														style={{ maxHeight: 400 }}
													>
														<div className="tableSubColumns" style={{ overflow: 'hidden' }}>
															<div className="tableColumnHeader" style={{ padding: "0px" }} >
																<div className="tableSubColumnNoSort ">
																	<div className="tableCellData">
																		<div className="tableColumnTitle2">Session Name</div>
																	</div>
																</div>
																<div className="tableSubColumnNoSort long">
																	<div className="tableCellData">
																		<div className="tableColumnTitle2">Error Message</div>
																	</div>
																</div>
															</div>
															<div className="tableColumnRows w-fc">
																{rejected.map((s) => {
																	return (
																		<div key={index} className="tableColumnRow" style={{ padding: "0px" }} >
																			<div className="tableColumnRowCell">
																				<div
																					className="text-truncate rowText"
																				>
																					{s.name}
																				</div>
																			</div>

																			<div className="tableColumnRowCell long">
																				<div
																					className="text-truncate rowText"
																				>
																					{s.errorMessage}
																				</div>
																			</div>
																		</div>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					)}

				</ModalBody>
				<div className='flex mt-20'>

					<button
						onClick={submitting && !finished ? this.cancelImport : this.props.toggle}
						outline
						className={`neu ml-a mr-15 `}
					>
						{finished ? 'Close' : 'Cancel'}
					</button>
					{!finished && <button

						className={`neu ${!mapping || ((importedSessions.length == importLength && importedStarted) || importedStarted) ? 'disabled' : 'prim'}`}

						onClick={() => {
							this.handleImport();
						}}
					>

						{
							!importedStarted ? (
								'Add'
							) : !finished ? (
								'Importing Sessions...'
							) : (
								'Done'
							)
						}
					</button>}
				</div>
			</Modal>
		);
	}
}

export default ImportSessionsModal;

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
};
const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center'
};

