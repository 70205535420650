import React, { Component, Fragment } from 'react';
import { Row, Button, Modal } from 'reactstrap';
import Toggle from 'react-toggle';
import 'react-datetime/css/react-datetime.css';
import CodeEditor from '../../../components/codeEditor/codeEditor';
import InputField from '../../../components/inputs/inputField';
import ToggleField from '../../../components/inputs/toggleField';

class EventEmailDetails extends Component {
	constructor(props) {
		super(props);
		const email = props.email;
		this.state = {
			editing: false,
			editedEmail: email,
			isHTML: email.isPlainHtml,
			parameterOptionsOpen: false
		};
	}

	renderMetadataOptions = () => {
		const { keyedMetadata, updateEditedEmail, editedEmail } = this.props;

		return (
			<div className="paramsList">
				{keyedMetadata.map((option) => {
					const concatenatedOption = option.split('.').join('');
					return (
						<div
							key={option}
							onClick={() => {
								const parameters = editedEmail.parameters;
								if (parameters.includes(option)) {
									parameters.splice(parameters.indexOf(option), 1);
								} else {
									parameters.push(option);
								}
								updateEditedEmail({ ...editedEmail, parameters: parameters });
							}}
							className={`emailParam ${editedEmail.parameters.includes(option) ? 'selected' : ''}`}
						>
							{option}
						</div>
					);
				})}
			</div>
		);
	};

	render() {
		const { editedEmail, toggleEditing, updateEditedEmail, requiredFields } = this.props;
		const { parameterOptionsOpen } = this.state;
		return (
			<div className="sectionBlock">
				<div className="sectionSubtitle">Email Details</div>
				<InputField
					label="Email Name"
					required={true}
					errorTrigger={requiredFields.name}
					placeholder="Email name"
					value={editedEmail.name}
					onChange={(e) => {
						updateEditedEmail({ ...editedEmail, name: e.target.value });
					}}
				/>
				<InputField
					label="Sender Name"
					placeholder={this.props.orgSenderName}
					value={editedEmail.senderName}
					onChange={(e) => {
						updateEditedEmail({ ...editedEmail, senderName: e.target.value });
					}}
				/>
				<InputField
					label="Sender Email"
					placeholder={this.props.orgSenderEmail}
					value={editedEmail.senderEmail}
					onChange={(e) => {
						updateEditedEmail({ ...editedEmail, senderEmail: e.target.value });
					}}
				/>
				<InputField
					label="Subject Line"
					required={true}
					errorTrigger={requiredFields.subject}
					value={editedEmail.subject}
					onChange={(e) => {
						updateEditedEmail({
							...editedEmail,
							subject: e.target.value
						});
					}}
				/>
				<InputField
					label="Preview Text"
					value={editedEmail.previewText}
					onChange={(e) => {
						updateEditedEmail({
							...editedEmail,
							previewText: e.target.value
						});
					}}
				/>
				{/* 	<ToggleField
					label="Use Template"
					checked={!editedEmail.isPlainHtml}
					icons={false}
					onChange={() => {
						updateEditedEmail({
							...editedEmail,
							isPlainHtml: !editedEmail.isPlainHtml
						});
					}}
				/> */}

				<Modal
					isOpen={parameterOptionsOpen}
					toggle={() => this.setState({ parameterOptionsOpen: !parameterOptionsOpen })}
				>
					{this.renderMetadataOptions()}
				</Modal>
			</div>
		);
	}
}
export default EventEmailDetails;
