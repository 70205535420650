import React from 'react';

import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';

import FieldModal from '../../../../components/modals/fieldModal';

class SendEmailNowModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
			invalid: false,
			attendees: [],
			sessions: [],
			groups: [],
			error: '',
			requiredFields: {},
			sentCount: 0,
			completed: false,
			totalEmails: 0,
			eventSessions: [],
			eventGroups: [],
			eventAttendees: [],
			uniqueAttendees: [],
		};
	}

	componentDidMount() {
		const { email, orgId, eventId, event } = this.props;

		let recipients = email.recipients;
		let eventGroups = event.groups.filter((g) => recipients.groups.includes(g._id));


		let recipientModel = '';
		let uniqueAttendees = [];

		if (recipients.attendees.length > 0) {
			recipientModel = 'attendees';
		} else if (recipients.sessions.length > 0) {
			recipientModel = 'sessions';
		} else if (recipients.groups.length > 0) {
			recipientModel = 'groups';
		}

		switch (recipientModel) {
			case 'attendees':
				uniqueAttendees = [...recipients.attendees];
				break;
			case 'sessions':
				let sessionsToSend = [...event.sessions.filter(s => recipients.sessions.includes(s._id))];
				for (let si = 0; si < sessionsToSend.length; si++) {
					let session = sessionsToSend[si];
					if (session.isGeneral) {
						uniqueAttendees = [];
						let attendeeIds = event.attendees.map(a => a._id);
						uniqueAttendees = [...uniqueAttendees, ...attendeeIds];
						break;
					}
					uniqueAttendees = [...uniqueAttendees, ...session.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			case 'groups':
				let groupsToSend = [...event.groups.filter(g => recipients.groups.includes(g._id))];
				for (let gi = 0; gi < groupsToSend.length; gi++) {
					let group = groupsToSend[gi];
					uniqueAttendees = [...uniqueAttendees, ...group.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			default:
				break;
		}
		this.setState({
			attendees: recipients.attendees,
			sessions: recipients.sessions,
			groups: recipients.groups,
			eventGroups: eventGroups,
			uniqueAttendees: uniqueAttendees,
		});
	}

	sendDedicatedEmail = async (attendeeId) => {
		const { email, orgId, eventId, event } = this.props;
		axios.defaults.headers.post['Content-Type'] = 'application/json';
		const request = {};
		const attendee = event.attendees.find((a) => a._id == attendeeId);
		email.parameters.forEach((param) => {
			if (param.includes('metadata')) {
				const breakdown = param.split('.');
				const categoryId = breakdown[1];
				const fieldId = breakdown[2];
				const varName = breakdown[3];
				const data = attendee.metadata.find((m) => m.categoryId == categoryId && m.fieldId == fieldId);
				if (data) {
					request[varName] = data.fieldValue;
				} else {
					return;
				}
			} else {
				request[param] = attendee[param];
			}
		});
		const sentDate = moment().format('MM/DD/YYYY hh:mm a');
		let sentEmail = null;
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/emails/${this.props.email._id}/sendDedicated`, {
				attendeeId: attendee._id,
				sentDate,
				status: 'sent'
			})
			.then(async (res1) => {
				if (res1.data) {
					sentEmail = res1.data;

					request['id'] = `${this.props.email._id}-${res1.data._id}`;
					request['email'] = `${attendee.email}`;
					await axios
						.post(email.serviceUrl, request)
						.then((res2) => {
							if (res2.data !== 1) {
								API().post(`tracking/emails/${this.props.email._id}/update/${res1.data._id}`, {
									status: 'bounced'
								});
							}
							this.setState({ sentCount: this.state.sentCount + 1 });
						})
						.catch((e) => {
							API().post(`tracking/emails/${this.props.email._id}/update/${res1.data._id}`, {
								status: 'bounced'
							});
						});
				}
			});
		return sentEmail;
	};

	sendOutEmails = async () => {
		const { email, orgId, eventId, event, update, toggle } = this.props;
		const { sentCount, attendees, sessions, groups, uniqueAttendees } = this.state;
		let count = sentCount;

		const isDedicatedEmailServerEnabled = email.dedicatedEmailServerEnabled;

		const date = moment().tz(event.timezone).format('MM/DD/YYYY');
		const time = moment().tz(event.timezone).format('hh:mm a');
		let timezone = event.timezone;
		if (attendees.length !== 0) {
			if (!isDedicatedEmailServerEnabled) {
				try {
					const res = await Scheduler()
						.post(`Organizations/${orgId}/email/${email._id}/scheduleEmail`, {
							date,
							time,
							timezone: timezone,
							isNow: true,
						})
						.then((res) => {
							update(
								{
									sent: true,
									status: 'Sent',
									sendDate: date,
									sendTime: time,
								},
								() => {
									this.props.runCheck();
									toggle();
								}
							);
						});
				} catch (err) {
					console.log(err);
				}
			} else {
				this.setState({ sending: true, totalEmails: attendees.length });
				let sentEmails = email.sentEmails;
				for (let ai = 0; ai < attendees.length; ai++) {
					let id = attendees[ai];

					const sentEmail = this.sendDedicatedEmail(id);
					sentEmail && sentEmails.push(sentEmail);
					update({
						sentEmails: sentEmails,
						sent: true,
						status: 'Sent',
						sendDate: date,
						sendTime: time,
					}, () => {
						this.setState({ completed: true });
					});
				}
			}
		} else if (sessions.length !== 0) {

			if (!isDedicatedEmailServerEnabled) {

				try {
					const res = await Scheduler()
						.post(`Organizations/${orgId}/email/${email._id}/scheduleEmail`, {
							date,
							time,
							timezone: timezone,
							isNow: true,
						})
						.then((res) => {
							update(
								{
									sent: true,
									status: 'Sent',
									sendDate: date,
									sendTime: time,
								},
								() => {
									this.props.runCheck();
									toggle();
								}
							);
						});
				} catch (err) {
					console.log(err);
				}
			} else {

				let finalAttendees = {};
				const eventSession = event.sessions.filter((s) => sessions.includes(s._id));
				finalAttendees = uniqueAttendees;
				this.setState({ sending: true, totalEmails: finalAttendees.length, eventSession: eventSession });
				let sentEmails = email.sentEmails;

				for (let si = 0; si < finalAttendees.length; si++) {
					let id = finalAttendees[si];
					const sentEmail = this.sendDedicatedEmail(id);
					sentEmail && sentEmails.push(sentEmail);
				}
				update({
					sentEmails: sentEmails,
					sent: true,
					status: 'Sent',
					sendDate: date,
					sendTime: time,
				}, () => {
					this.setState({ completed: true });
				});
			}
		} else if (groups.length !== 0) {




			if (!isDedicatedEmailServerEnabled) {

				try {
					const res = await Scheduler()
						.post(`Organizations/${orgId}/email/${email._id}/scheduleEmail`, {
							date,
							time,
							timezone: timezone,
							isNow: true,
						})
						.then((res) => {
							update(
								{
									sent: true,
									status: 'Sent',
									sendDate: date,
									sendTime: time,
								},
								() => {
									this.props.runCheck();
									toggle();
								}
							);
						});
				} catch (err) {
					console.log(err);
				}
			} else {

				let finalAttendees = {};
				const eventSession = event.groups.filter((s) => groups.includes(s._id));
				finalAttendees = uniqueAttendees;
				this.setState({ sending: true, totalEmails: finalAttendees.length, eventSession: eventSession });
				let sentEmails = email.sentEmails;

				for (let si = 0; si < finalAttendees.length; si++) {
					let id = finalAttendees[si];
					const sentEmail = this.sendDedicatedEmail(id);
					sentEmail && sentEmails.push(sentEmail);
				}
				update({
					sentEmails: sentEmails,
					sent: true,
					status: 'Sent',
					sendDate: date,
					sendTime: time,
				}, () => {
					this.setState({ completed: true });
				});
			}
		}
	};
	render() {
		const { isOpen, toggle, email, event } = this.props;
		const { sending, attendees, sessions, groups, completed, sentCount, eventGroups, totalEmails, uniqueAttendees } = this.state;

		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Send Email Now"}
					bodyHeaderText={`${attendees.length !== 0 ? (
						`${attendees.length} Attendee${attendees.length > 1 ? 's' : ''}`
					) : sessions.length !== 0 ? (
						`${uniqueAttendees.length} Attendee${uniqueAttendees.length > 1 ? 's' : ''} in ${sessions.length} Session${sessions.length > 1 ? 's' : ''}`
					) : groups.length !== 0 ? (
						`${uniqueAttendees.length} Attendee${uniqueAttendees.length > 1 ? 's' : ''} in ${groups.length} Group${groups.length > 1 ? 's' : ''}`
					) : (
						'Nothing selected'
					)} ${email.bcc.length > 0 ? ' and the bcc list' : ''}`}
					bodyContent={() => {
						return (
							<div>

								<p >
									{sending && `${!completed ? 'Sending' : 'Sent'} ${sentCount} of ${totalEmails} attendees`}
								</p>
							</div>
						)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Send Emails' : completed ? 'Complete' : 'Sending...'}
					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}
					actionButton={() => this.sendOutEmails()}
					actionButtonDisabled={sending || completed}


				></FieldModal>


			</div>

		);
	}
}

export default SendEmailNowModal;
