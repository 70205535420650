import React from 'react';
import { BarLoader } from 'react-spinners';

class SpinnerComponent extends React.Component {
	render() {
		const { text, loading, image } = this.props;
		return (
			<div className={`loadingContainer ${loading ? '' : 'hide'}`}>
				{image && <img className='mb-50' src={image} alt="loading" />}
				<BarLoader
					className="clip-loader"
					width={300}
					height={5}
					color={'#2B83F1'}
					loading={true}
				/>
				{text && <h5 className="mt-25 text-c">{text}</h5>}
			</div>
		);
	}
}

export default SpinnerComponent;
