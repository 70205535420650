import React, { Fragment } from 'react';

import SelectField from '../../../components/inputs/selectField.js';
import FieldModal from '../../../components/modals/fieldModal.js';
const permissionOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
    { value: 'guest', label: 'Guest' }
];
class UpdatePermissionsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            perm: '',
            submitting: false,
            invalid: false,
            error: 'All fields are required'
        };
    }



    resetAdd = (e, toggle) => {
        this.setState({
            firstName: '',
            lastName: '',
            email: '',
            submitting: false,
            invalid: false
        });
        if (!toggle === true) this.props.toggle();
    };

    updatePermissions = () => {
        this.setState({ submitting: true })
        this.props.updatePermissions(this.state.perm.value)
    }

    render() {
        const { isOpen, requiredFields } = this.props;
        const { firstName, lastName, email, submitting, invalid, error, perm } = this.state;

        return (

            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Update Permissions"}
                    bodyHeaderText={''}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <SelectField
                                    classes={'w-100'}
                                    label="Permission"
                                    value={perm}
                                    name="permission"
                                    options={permissionOptions}
                                    onChange={(permission) => {
                                        this.setState({
                                            perm: permission,
                                            editing: true
                                        });
                                    }}
                                />
                                {invalid && <p className="modalErrorMessage calibreRegular">{error}</p>}
                            </div>)
                    }}
                    toggle={this.resetAdd}
                    actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
                    actionButton={() => this.updatePermissions()}
                    actionButtonDisabled={submitting}
                ></FieldModal>
            </div>

        );
    }
}

export default UpdatePermissionsModal;
