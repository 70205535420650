import React, { Component } from 'react';
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
class FileUploadInputBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isHovering: false, // State to track hover status
            uniqueId: `id-${uuidv4()}`,
        };
    }

    // Event handler for mouse enter
    handleMouseEnter = () => {
        this.setState({ isHovering: true });
    }

    // Event handler for mouse leave
    handleMouseLeave = () => {
        this.setState({ isHovering: false });
    }

    render() {
        const {
            label,
            errorTrigger,
            errorMessage,
            value,
            style,
            required,
            classes,
            onClick,
            clearFile,
            information,
            addText
        } = this.props;

        const { uniqueId } = this.state;
        return (
            <div
                key={label}
                className={`formFieldContainer c-pointer color ${classes ? classes : ''}`}
                style={style}

            >
                <div className="formField">
                    <div className="labelContainer">
                        <div className='flex ' >
                            {label && (
                                <div className="formFieldLabel">
                                    {label}
                                    {required ? '*' : ''}
                                </div>
                            )}
                            {information &&
                                <>
                                    <i
                                        id={uniqueId}

                                        className="grey las la-info-circle informationIcon mb-5"
                                    />
                                    <UncontrolledTooltip
                                        className="tooltip formFieldTooltip"
                                        placement="right"
                                        target={uniqueId}
                                    >
                                        {information}
                                    </UncontrolledTooltip>
                                </>
                            }
                        </div>
                        {errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
                    </div>
                    <div className={`formFieldInputContainerLarge${errorTrigger ? 'error' : ''}`}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave} >
                        {value === '' ? (
                            <div className="filePickerButtonContainer" onClick={onClick}>
                                <div className="filePickerButtonLarge">
                                    <button className="imageUploadButton">
                                        <i className="las la-plus" />
                                    </button>
                                    <span className="addText">{addText ? `Add ${addText}` : "Add File"}</span>
                                </div>
                            </div>
                        ) : (
                            <div className="filePickerButtonContainer">

                                {
                                    this.state.isHovering ? <div className="filePickerButtonContainer" onClick={clearFile}>
                                        <div className="filePickerButtonLarge">
                                            <button className='imageUploadButton'>
                                                <i className="las la-times-circle" />
                                            </button>
                                            <span className="addText">{addText ? `Remove ${addText}` : "Remove File"}</span>
                                        </div>
                                    </div>
                                        : <img alt='upload' className="filePickerPreviewLarge" src={value} />

                                }

                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default FileUploadInputBox;
