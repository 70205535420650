import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import cityTimezones from 'city-timezones';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import ToggleField from '../../../components/inputs/toggleField';
import TimeField from '../../../components/inputs/timeField';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import TabsContainer from '../../../components/views/TabsContainer';
import VenuesLocationField from '../../../components/venues/venuesLocationField';
import FileChooserPreview from '../../../components/inputs/fileChooserPreview';
import FilesUploaderModal from '../../../components/files/filesUploaderModal';
import ColorField from '../../../components/inputs/colorField';
import Typography from '../../../views/account/typography';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import FieldModal from '../../../components/modals/fieldModal';
import BrandingEditor from '../../event/pages/branding';

const eventTypeOptions = [{ label: 'Public', value: true }, { label: 'Invite Only', value: false }];
const eventExperienceOptions = [{ label: 'Virtual', value: 'virtual' }, { label: 'In-Person', value: 'in-person' }, { label: 'Hybrid', value: 'hybrid' }];

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
class EventDefaultDetails extends Component {
	constructor(props) {
		super(props);
		const event = props.event;
		const program = props.program;
		const pages = props.program.eventDefaults.pages;
		const sessions = props.program.eventDefaults.sessions;

		const publicPathOptions = pages
			.map((po) => {
				if (po.openToPublic) return { label: po.title, value: po.pathSlug };
			})
			.filter((p) => !!p);
		const privatePathOptions = [
			...pages.map((po) => {
				return { label: po.title, value: po.pathSlug };
			}),
			...sessions.map((s) => {
				return { label: `Session: ${s.name}`, value: `session/${s.slug}` };
			})
		];

		const venueOptions = props.venues.map((venue) => {
			return {
				value: venue._id,
				label: venue.name,
				color: '#000'
			};
		})
		const programVenue = event.venue ? props.venues.find(v => v._id == event.venue) : '';
		const salesforceIntegration = props.organization.integrations.find((integration) => integration.identifier == 'salesforce');
		let sfRegStatus = 'Registered';
		let sfCheckinStatus = 'Checked In';
		let sfNotAttendingStatus = 'Did Not Attend';
		if (salesforceIntegration) {

			salesforceIntegration.requiredFields.forEach((field) => {
				if (field.id === 'registeredStatus') sfRegStatus = field.value;
				if (field.id === 'checkedinStatus') sfCheckinStatus = field.value;
				if (field.id === 'didNotAttendStatus') sfNotAttendingStatus = field.value;
			});
		}

		this.state = {
			editing: false,
			editedEvent: event,
			copiedPassthrough: false,
			formFilled: false,
			cityLookup: '',
			invalid: false,
			saving: false,
			publicPathOptions: publicPathOptions,
			requiredFields: {
				meetingPassword: false,
				SSOLoginUrl: false
			},
			venue: venueOptions.find((v) => v.value === event.venue) || { _id: '' },
			venues: venueOptions,
			selectedVenue: programVenue,
			selectedSection: 0,
			publicPathOptions,
			privatePathOptions,
			renderURL: `Organizations/${this.props.organization._id}/accountPages/render/stylePreview/`,
			salesforceEnabled: salesforceIntegration ? true : false,


		};
	}

	saveDetails = (e) => {
		const editedEvent = this.state.editedEvent;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedEvent[key] || editedEvent[key] === '') {
				if (key === 'meetingPassword' && !editedEvent.enableMeetingPassword) {
					return;
				} else if (key === 'SSOLoginUrl' && !editedEvent.enableSSOLogin) {
					return;
				}
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.setState({ saving: true });
			const today = moment().format('YYYY-MM-DD');
			const startTime = moment(`${today} ${editedEvent.startTime}`).format('hh:mm a');
			const endTime = moment(`${today} ${editedEvent.endTime}`).format('hh:mm a');
			this.props.saveDetails(
				{ ...this.props.event, ...editedEvent, startTime: startTime, endTime: endTime },
				() => {
					this.setState({ editing: false, saving: false, error: '', requiredFields: requiredFields });
				}
			);
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	saveEventStyle = () => {
		const { editedEvent } = this.state;

		this.props.saveStyling(
			{ customCSS: editedEvent.customCSS, style: editedEvent.style },
			() => {
				this.setState({
					editingStyle: false,
					editedEvent: { ...editedEvent, style: { ...editedEvent.style }, customCSS: editedEvent.customCSS },
				});
			}
		);
	};

	cancelEdit = () => {
		const event = this.props.event;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedEvent: event,
			editing: false,
			requiredFields: requiredFields
		});
	};

	handleStartTimeChange = (date) => {
		const startTime = date.target.value;
		this.setState((prevState) => ({
			editedEvent: {
				...this.state.editedEvent,
				startTime: startTime
			},
			editing: true
		}));
	};

	handleEndTimeChange = (date) => {
		const endTime = date.target.value;
		this.setState((prevState) => ({
			editedEvent: {
				...this.state.editedEvent,
				endTime: endTime
			},
			editing: true
		}));
	};

	updateSelected = (selected) => {
		this.setState({ selectedSection: selected });
	};

	updateEventStyles = (property, value) => {
		const editedEventStyles = this.state.editedEvent.style;
		editedEventStyles[property] = value;
		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				style: editedEventStyles
			},
			editingStyle: true
		});
	};

	updateEventStylesheet = (e) => {
		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				customCSS: e
			},
			editingStyle: true
		});
	};


	applyBrandingToStyling = () => {
		const { editedEvent } = this.state;
		const editedEventStyles = editedEvent.style;
		//editedEvent.branding.backgroundColor
		editedEventStyles.primaryColor = editedEvent.branding.accentColor;
		editedEventStyles.secondaryColor = editedEvent.branding.fontColor;
		editedEventStyles.navigationTextColor = editedEvent.branding.accentColor;
		editedEventStyles.navigationHoverColor = editedEvent.branding.accentColor;
		editedEventStyles.navigationVisitedColor = editedEvent.branding.fontColor;
		editedEventStyles.linkTextColor = editedEvent.branding.accentColor;
		editedEventStyles.linkHoverColor = editedEvent.branding.accentColor;
		editedEventStyles.linkVisitedColor = editedEvent.branding.fontColor;
		editedEventStyles.buttonPrimaryColor = editedEvent.branding.accentColor;
		editedEventStyles.buttonSecondaryColor = editedEvent.branding.fontColor;
		editedEventStyles.profileIconPrimaryColor = editedEvent.branding.accentColor;
		editedEventStyles.profileIconSecondaryColor = editedEvent.branding.fontColor;
		editedEventStyles.scrollbarColor = editedEvent.branding.accentColor;
		editedEventStyles.scrollbarBackgroundColor = editedEvent.branding.backgroundColor;

		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				style: editedEventStyles
			},
			editCount: this.state.editCount + 1,
			editing: true
		});

	}


	renderSection = () => {

		const {
			editedEvent,
			cityLookup,
			requiredFields,
			selectedSection
		} = this.state;
		const { program, event, readOnly, organization } = this.props;
		const eventId = event._id;

		const registrationPagePath = this.state.privatePathOptions.find(
			(path) => path.value == editedEvent.registrationPageSlug
		);
		const cityTimezoneOptions =
			cityLookup !== ''
				? cityTimezones.findFromCityStateProvince(cityLookup).map((tz) => {
					return { label: `${tz.city}, ${tz.province}`, value: tz.timezone };
				})
				: [];

		let sectionBlock;
		switch (selectedSection) {
			case 0:
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Basic Information</h2>

						<SelectField
							label={'Experience Type'}
							value={eventExperienceOptions.find((option) => option.value == editedEvent.experienceType)}
							name="Experience Type"
							classes="w-100"
							options={eventExperienceOptions}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.experience}
							onChange={(experience) => {
								this.setState({
									editedEvent: { ...editedEvent, experienceType: experience.value },
									editing: true
								});
							}}
						/>
						<div className="flex-sb w-100">
							<div className="mr-10 w-100">
								<TimeField
									label={'Start Time'}
									disabled={readOnly}
									value={editedEvent.startTime}
									required={true}
									errorTrigger={requiredFields.startTime}
									onChange={(time) => {
										this.setState({
											editedEvent: {
												...editedEvent,
												startTime: time
											},
											editing: true
										});
									}}
								/>
							</div>

							<div className="ml-10 w-100">
								<TimeField
									label={'End Time'}
									disabled={readOnly}
									value={editedEvent.endTime}
									required={true}
									errorTrigger={requiredFields.endTime}
									onChange={(time) => {
										this.setState({
											editedEvent: {
												...editedEvent,
												endTime: time
											},
											editing: true
										});
									}}
								/>
							</div>
						</div>
					</div>
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Location</h2>
						<VenuesLocationField
							selectedVenue={this.state.selectedVenue}
							orgId={this.props.orgId}
							classes="w-100"
							venueOptions={this.props.venues}
							addNewVenue={this.props.addNewVenue}
							renameVenue={(venue) => {
								let selectedVenue = this.state.selectedVenue;
								selectedVenue.name = venue.name
								this.setState({ selectedVenue: selectedVenue })
								this.props.renameVenue(venue);
							}}
							deleteVenue={(venueId) => {

								this.setState({ selectedVenue: '' })
								this.props.deleteVenue(venueId);
							}}
							selectVenue={(venue) => {
								console.log(venue);
								if (venue.timezone) {
									this.setState({
										selectedVenue: venue,
										editedEvent: { ...editedEvent, timezone: venue.timezone, venue: venue._id },
										editing: true
									});
								} else {
									this.setState({
										selectedVenue: venue,
										editedEvent: { ...editedEvent, venue: venue._id },
										editing: true
									})

								}
							}}
						></VenuesLocationField>
						<SelectField
							label={'Timezone'}
							value={{ value: editedEvent.timezone, label: editedEvent.timezone }}
							name="timezones"
							classes="w-100"
							options={cityTimezoneOptions}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.timeZone}
							onInputChange={(text) => {
								this.setState({ cityLookup: text });
							}}
							noOptionsMessage={({ inputValue }) =>
								!inputValue ? 'Type city name to get options' : 'No cities match your search'}
							onChange={(timezone) => {
								this.setState({
									editedEvent: { ...editedEvent, timezone: timezone.value },
									editing: true
								});
							}}
						/></div>
					<div className='sectionBlock'>
						{program.eventCustomFields &&
							program.eventCustomFields.map((category, i) => {
								const fields = category.fields.map(field => {
									return (<div className='flex-ac flex-sb' key={`customField-${i}`}>

										<ToggleField
											label={`Require ${field.fieldName}`}
											checked={editedEvent.requiredCustomFields && editedEvent.requiredCustomFields.includes(field._id)}
											icons={false}
											onChange={(e) => {
												let requiredFields = editedEvent.requiredCustomFields || [];
												if (requiredFields.includes(field._id) && !e.target.checked) {
													requiredFields = requiredFields.filter((f) => f !== field._id);
												} else {
													requiredFields.push(field._id);
												}
												this.setState({
													editedEvent: { ...editedEvent, requiredCustomFields: requiredFields },
													editing: true
												});
											}}
										/>
									</div>);
								});
								return (<div key={`customField-${i}`}>
									<h2 className='blockHeader'>{category.title}</h2>
									{fields}
								</div>);

							})}
					</div>


				</div>
				break;
			case 1:
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>

						<h2 className='blockHeader'>Gate Keeping</h2>


						<SelectField
							label="Event Type"
							value={editedEvent.allowPublicRegistrations ? eventTypeOptions[0] : eventTypeOptions[1]}
							name="eventType"
							options={eventTypeOptions}
							onChange={(eventType) => {
								this.setState({
									editedEvent: {
										...editedEvent,
										allowPublicRegistrations: eventType.value,
										requestAccessEnabled:
											eventType.value == true ? false : editedEvent.requestAccessEnabled,
										eventType: eventType.label
									},
									editing: true
								});
							}}
						/>

						{editedEvent.allowPublicRegistrations ? (
							<SelectField
								label="Registration Page"
								value={
									registrationPagePath ? (
										registrationPagePath
									) : (
										{
											label: '',
											value: ''
										}
									)
								}
								name="registrationPageSlug"
								options={this.state.publicPathOptions}
								onChange={(path) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											registrationPageSlug: path.value
										},
										editing: true
									});
								}}
							/>
						) : (
							<ToggleField
								label="Enable Request Access"
								checked={editedEvent.requestAccessEnabled}
								icons={false}
								disabled={readOnly}
								onChange={() =>
									this.setState({
										editedEvent: {
											...editedEvent,
											requestAccessEnabled: !this.state.editedEvent.requestAccessEnabled
										},
										editing: true
									})}
							/>
						)}

						{editedEvent.allowPublicRegistrations && (

							<InputField
								label={'Registration Capacity (0 = unlimited)'}
								disabled={readOnly}
								type="number"
								value={editedEvent.registrationCapacity}
								onChange={(e) => {
									this.setState({
										editedEvent: { ...editedEvent, registrationCapacity: e.target.value },
										editing: true
									});
								}}
							/>
						)}
						{editedEvent.allowPublicRegistrations && (
							<ToggleField
								label={'Enable Registration Auto Login'}
								onChange={() =>
									this.setState({
										editedEvent: {
											...editedEvent,
											autoLoginFromRegistration: !this.state.editedEvent.autoLoginFromRegistration
										},
										editing: true
									})}
								disabled={readOnly}
								icons={false}
								checked={editedEvent.autoLoginFromRegistration}
							/>
						)}


						<ToggleField
							label="Enable Meeting Password"
							checked={editedEvent.enableMeetingPassword}
							icons={false}
							disabled={readOnly}
							onChange={() =>
								this.setState({
									editedEvent: {
										...editedEvent,
										enableMeetingPassword: !this.state.editedEvent.enableMeetingPassword
									},
									editing: true
								})}
						/>
						{editedEvent.enableMeetingPassword && (
							<InputField
								label="Meeting Password"
								disabled={readOnly}
								required={true}
								errorTrigger={requiredFields.meetingPassword}
								value={editedEvent.meetingPassword}
								onChange={(e) => {
									this.setState({
										editedEvent: { ...editedEvent, meetingPassword: e.target.value },
										editing: true
									});
								}}
							/>
						)}
						<ToggleField
							label="Enable SSO"
							checked={editedEvent.enableSSOLogin}
							icons={false}
							disabled={readOnly}
							onChange={() =>
								this.setState({
									editedEvent: {
										...editedEvent,
										enableSSOLogin: !this.state.editedEvent.enableSSOLogin
									},
									editing: true
								})}
						/>
						{editedEvent.enableSSOLogin && (
							<InputField
								label="SSO URL"
								disabled={readOnly}
								required={true}
								errorTrigger={requiredFields.ssoUrl}
								value={editedEvent.SSOLoginUrl}
								onChange={(e) => {
									this.setState({
										editedEvent: { ...editedEvent, SSOLoginUrl: e.target.value },
										editing: true
									});
								}}
							/>
						)}
						<ToggleField
							label="Enable Magic Link"
							checked={editedEvent.enablePassthroughPassword}
							icons={false}
							disabled={readOnly}
							onChange={() =>
								this.setState({
									editedEvent: {
										...editedEvent,
										enablePassthroughPassword: !this.state.editedEvent.enablePassthroughPassword
									},
									editing: true
								})}
						/>
					</div>

				</div>
				break;
			case 2:
				sectionBlock = <div className="sectionBody">

					<div className='blocksContainer'>
						<div className='sectionBlock mr-25' style={{ maxHeight: '100%', overflowX: 'hidden', paddingRight: 5 }}>
							<h2 className='blockHeader'>Images</h2>
							<FileChooserPreview
								label="Logo"
								value={!editedEvent.branding.logo || editedEvent.branding.logo === '' ? '' : editedEvent.branding.logo}
								onClick={() => {
									editedEvent.branding.logo = 'choosing__' + editedEvent.branding.logo;
									this.setState({ editedEvent });
								}}
								clearFile={() => {
									editedEvent.branding.logo = '';
									this.setState({
										editedEvent,
										editing: true
									});
								}}
							/>
							{editedEvent.branding.logo.includes('choosing__') &&
								<FilesUploaderModal
									isOpen={editedEvent.branding.logo.includes('choosing__')}
									toggle={() => {
										editedEvent.branding.logo = editedEvent.branding.logo.replace('choosing__', '');
										this.setState({
											editedEvent,
											editing: true
										});
									}}
									eventId={eventId}
									links={{
										getAssets: `Organizations/${this.props.organization._id}/assets`,
										addAssets: `Organizations/${this.props.organization._id}/assets`,
									}}
									orgId={this.props.organization._id}
									addFiles={(fileURL) => {
										editedEvent.branding.logo = fileURL;
										this.setState({
											editedEvent,
											editing: true
										});
									}}
								/>}
							<FileChooserPreview
								label="Website Icon"
								value={!editedEvent.branding.favicon || editedEvent.branding.favicon === '' ? '' : editedEvent.branding.favicon}
								onClick={() => {
									editedEvent.branding.favicon = 'choosing__' + editedEvent.branding.favicon;
									this.setState({ editedEvent });
								}}
								clearFile={() => {
									editedEvent.branding.favicon = '';
									this.setState({
										editedEvent,
										editing: true
									});
								}}
							/>
							{editedEvent.branding.favicon.includes('choosing__') &&
								<FilesUploaderModal
									isOpen={editedEvent.branding.favicon.includes('choosing__')}
									toggle={() => {
										editedEvent.branding.favicon = editedEvent.branding.favicon.replace('choosing__', '');
										this.setState({
											editedEvent,
											editing: true
										});
									}}
									eventId={eventId}
									links={{
										getAssets: `Organizations/${this.props.organization._id}/assets`,
										addAssets: `Organizations/${this.props.organization._id}/assets`,
									}}
									orgId={this.props.organization._id}
									addFiles={(fileURL) => {
										editedEvent.branding.favicon = fileURL;
										this.setState({
											editedEvent,
											editing: true
										});
									}}
								/>}
							<div className='blockHeader flex aic jcsb'>

								<h2 className='m-0'>Colors</h2>
								<button className='min' onClick={this.applyBrandingToStyling}>Reapply to Branding</button>
							</div>
							<ColorField
								label="Primary Color"
								value={
									!editedEvent.branding.accentColor || editedEvent.branding.accentColor === '' ? (
										''
									) : (
										editedEvent.branding.accentColor
									)
								}
								disabled={readOnly}
								placeholder={organization.branding.accentColor}
								useHex={true} l
								onChange={(e) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedEvent.branding,
												accentColor: e
											}
										},
										editing: true
									});
								}}

							/>
							<ColorField
								label="Primary Text Color"
								value={
									!editedEvent.branding.fontColor || editedEvent.branding.fontColor === '' ? (
										''
									) : (
										editedEvent.branding.fontColor
									)
								}
								placeholder={organization.branding.fontColor}
								disabled={readOnly}
								useHex={true}
								onChange={(e) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedEvent.branding,
												fontColor: e
											}
										},
										editing: true
									});
								}}
							/>

							<ColorField
								label="Primary Background Color"
								value={
									!editedEvent.branding.backgroundColor || editedEvent.branding.backgroundColor === '' ? (
										''
									) : (
										editedEvent.branding.backgroundColor
									)
								}
								placeholder={organization.branding.backgroundColor}

								disabled={readOnly}
								useHex={true}
								onChange={(e) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedEvent.branding,
												backgroundColor: e
											}
										},
										editing: true
									});
								}}
							/>
							{/* <Dropzone
									accept="image/*"
									className="clickable dropzone-details"
									inputProps={{ style: { margin: 'auto' } }}
									ref={(node) => {
										dropzoneRef = node;
									}}
									onDrop={this.onDrop.bind(this)}
								>
									{this.state.files && this.state.files.length > 0 ? (
										<img className="dropHeaderImage" src={this.state.files[0].preview} />
									) : (
										event.headerImage && <img className="dropHeaderImage" src={event.headerImage} />
									)}
									<div className="dropzoneDescriptionContainer">
										<p>
											<span style={{ color: '#2B83F1' }}>Browse</span>, or drop event banner here.
										</p>
									</div>
								</Dropzone> */}
							<Typography
								fonts={editedEvent.branding}
								accountData={{
									branding: {
										...editedEvent.branding,
										fonts: editedEvent.branding.fonts ? [...editedEvent.branding.fonts] : [...organization.branding.fonts]
									}
								}}
								editAccountData={(editedBranding) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedBranding
											}
										},
										editing: true
									});
								}}
							/>
						</div>

						<div className='sectionBlock emailDesignPreview mt-20'>
							<div
								className="cardDetailsContainerSetup containerTitle"
								style={{ marginBottom: 5 }}
							>

								<div className='w-100'>
									<h2 className='blockHeader mt-0'> Design</h2>
									<h5 className='formFieldLabel mt-10'> Event Branding Preview </h5>
								</div>

								<button
									className="neu min ml-a"
									onClick={() => this.setState({ brandingEditorOpen: true })}
								>
									Edit Branding
								</button>
							</div>
							<div
								className="emailPreview eFrame flex-ac preview"
							>

								{!this.state.brandingEditorOpen && <PagePreviewFrame
									setPreviewDimensions={({ width, height }) => {
										this.setState({
											frameWidth: width,
											frameHeight: height
										});
									}}
									ref={(el) => (this.preview = el)}
									editing={this.state.editCount}
									saving={this.state.saving}
									eventStyling={editedEvent.style}
									customCSS={editedEvent.customCSS}
									ENV={ENV}
									renderURL={this.state.renderURL}
									pageProperties={{}}
									simulatedUser={{}}
									simulatedSession={{}}
									renderOnly={true}
								/>}
								{/* <IFrame.Form
									id="builderPreviewFrameTemplate"
									className="emailPreviewWindow Frame"
									key={`campaign-${editCount}`}
									style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
									name="PreviewFrameCamp"
									config={{ url, method, data }}
								/> */}
							</div>
						</div>
					</div>
					<FieldModal
						size='large'
						isOpen={this.state.brandingEditorOpen}
						cancelButtonLabel={this.state.editingStyle ? 'Discard & Close' : "Close"}
						toggle={() => {
							if (this.state.editingStyle) {
								window.confirm('You have unsaved changes. Are you sure you want to leave?') && this.setState({
									editedEvent: {
										...this.state.editedEvent,
										style: {
											...this.props.event.style
										},
										customCSS: this.props.event.customCSS
									},
									editingStyle: false, brandingEditorOpen: !this.state.brandingEditorOpen
								})
							} else {
								this.setState({
									brandingEditorOpen: !this.state.brandingEditorOpen
								})

							}
						}}
						modalTitle="Branding Editor"
						bodyContent={() => <div>
							<BrandingEditor
								renderURL={this.state.renderURL}
								orgId={this.props.organization._id}
								event={this.state.editedEvent}
								updateEventStyles={this.updateEventStyles}
								isOpen={this.state.brandingEditorOpen}
								updateEventStylesheet={this.updateEventStylesheet} />
						</div>}
						isFullWidthBody={true}
						actionButtonLabel="Save"
						actionButton={this.saveEventStyle}
						actionButtonDisabled={!this.state.editingStyle}>

					</FieldModal>
				</div>
				break;
			case 3:
				const { publicPathOptions, privatePathOptions } = this.state;
				const { defaultPublicPath, defaultUserPath, eventSupportEmailName, eventSupportEmail, eventEmail, eventEmailName } = editedEvent;
				const publicPath = publicPathOptions.find((path) => path.value == defaultPublicPath);
				const privatePath = privatePathOptions.find((path) => path.value == defaultUserPath);
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Default Navigation Settings</h2>

						<SelectField
							label="Public Home Page"
							value={publicPath ? publicPath : { label: '', value: '' }}
							name="defaultUserPath"
							classes="w-100"
							disabled={readOnly}
							options={publicPathOptions}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, defaultPublicPath: path.value },
									editing: true
								});
							}}
						/>
						<SelectField
							label="Private Home Page"
							disabled={readOnly}
							classes="w-100"
							value={privatePath ? privatePath : { label: '', value: '' }}
							name="defaultUserPath"
							options={privatePathOptions}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, defaultUserPath: path.value },
									editing: true
								});

							}}
						/>
					</div>
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Default Communication Settings</h2>
						<InputField
							label="Event Support Name"
							disabled={readOnly}
							value={eventSupportEmailName}
							placeholder={organization.accountSupportName}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventSupportEmailName: e.target.value },
									editing: true
								});
							}}

						/>
						<InputField
							label="Event Support Email"
							disabled={readOnly}
							placeholder={organization.accountSupportEmail}
							value={eventSupportEmail}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventSupportEmail: e.target.value },
									editing: true
								});
							}}
						/>

						<InputField
							label="Event Contact Email Name"
							disabled={readOnly}
							value={eventEmailName}
							placeholder={organization.adminEmailName}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventEmailName: e.target.value },
									editing: true
								});
							}}
						/>
						<InputField
							label="Event Contact Email"
							disabled={readOnly}
							value={eventEmail}
							placeholder={organization.adminEmail}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventEmail: e.target.value },
									editing: true
								});
							}}
						/>
					</div>
				</div>
				break;
			case 4:

				let registeredStatus = editedEvent.integrations && editedEvent.integrations.salesforce ? editedEvent.integrations.salesforce.registeredStatus : '';
				let checkedinStatus = editedEvent.integrations && editedEvent.integrations.salesforce ? editedEvent.integrations.salesforce.checkedinStatus : '';
				let notAttendedStatus = editedEvent.integrations && editedEvent.integrations.salesforce ? editedEvent.integrations.salesforce.notAttendedStatus : '';


				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Salesforce Integration</h2>
						<p className='small gray  mb-10'>Connect to Salesforce to sync attendee data and create new leads.</p>
						<ToggleField icons={false} label="Enable Salesforce Integration" checked={this.state.editedEvent.salesforceEnabled} onChange={(checked) => {
							if (this.state.editedEvent.salesforceEnabled) {

								this.setState({ editedEvent: { ...this.state.editedEvent, salesforceEnabled: false }, editing: true });

							} else {
								this.setState({ editedEvent: { ...this.state.editedEvent, salesforceEnabled: true }, editing: true });
							}
						}} />
						{editedEvent.salesforceEnabled &&
							<div className="w-100">
								<InputField
									label={'Registration Status'}
									value={registeredStatus}
									placeholder={'Registered'}
									onChange={(e) => {
										let editedEventIntegrations = this.state.editedEvent.integrations || { salesforce: {} };
										editedEventIntegrations.salesforce = { ...editedEventIntegrations.salesforce, registeredStatus: e.target.value };

										this.setState({
											editedEvent: { ...this.state.editedEvent, integrations: editedEventIntegrations },
											editing: true
										});
									}}
								/>

								<InputField
									label={'Checked In Status'}
									value={checkedinStatus}
									placeholder={'Checked In'}
									onChange={(e) => {
										let editedEventIntegrations = this.state.editedEvent.integrations || { salesforce: {} };
										editedEventIntegrations.salesforce = { ...editedEventIntegrations.salesforce, checkedinStatus: e.target.value };
										this.setState({
											editedEvent: { ...this.state.editedEvent, integrations: editedEventIntegrations },
											editing: true
										});
									}}
								/>

								<InputField
									label={'Did Not Attend Status'}
									value={notAttendedStatus}
									placeholder={'Did Not Attend'}
									onChange={(e) => {
										let editedEventIntegrations = this.state.editedEvent.integrations || { salesforce: {} };
										editedEventIntegrations.salesforce = { ...editedEventIntegrations.salesforce, notAttendedStatus: e.target.value };

										this.setState({
											editedEvent: { ...this.state.editedEvent, integrations: editedEventIntegrations },
											editing: true
										});
									}}
								/>
							</div>


						}
					</div>
				</div>;
			default:
				break;
		}

		return sectionBlock;
	}


	render() {
		const { editing, selectedSection } = this.state;
		const { program } = this.props;
		let tabs = [
			{ title: <span>Details</span> },
			{ title: <span>Access</span> },
			{ title: <span>Branding</span> },
			{ title: <span>Preferences</span> },

		];
		if (this.state.salesforceEnabled) {
			tabs.push({ title: <span>Integrations</span> });
		}

		return (
			<div className="sectionContainer">
				<SectionHeader
					title={'Default Event Details'}
					description={'Manage the basic details, settings, and access for all approved events.'}
					breadcrumbs={[
						{
							name: program.name
						}
					]}
				>
					<TabsContainer
						tabs={tabs}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/>
				</SectionHeader>

				{this.renderSection()}
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveDetails}
					updatedAt={program.updatedAt} />
			</div>
		);
	}
}
export default EventDefaultDetails;
