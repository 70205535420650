import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import InputField from "../../components/inputs/inputField";
import ToggleField from "../../components/inputs/toggleField";
import LoadingWizard from "../../components/spinner/wizard";
import API from "../../utils/API";
import FieldModal from "../../components/modals/fieldModal";

class Integrations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticating: props.integrationToken ? true : false,
            requiredDetailsModalOpen: false,
            selectedIntegrationIndex: null,
            requiredFieldValues: {}
        }
    }

    componentDidMount() {
        if (this.props.integrationToken) {

            let searchParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf('?')));
            const integrationId = searchParams.get('integration');
            if (integrationId) {
                const integrationIndex = this.props.integrations.findIndex(i => i.identifier == integrationId);
                const integration = this.props.integrations[integrationIndex];
                if (integration && integration.links && integration.links.authorize) {
                    API().get(`Organizations/${this.props.orgId}/integrations/${integrationId}/auth?token=${this.props.integrationToken}`).then(res => {
                        if (res.data && res.data.success) {
                            this.props.toggleIntegration(integrationIndex, true, true);
                            this.setState({ authenticating: false });
                        }
                    }).catch(err => {
                        console.log(err);
                        //navigate to integrations page
                        window.location.href = '/admin/account/integrations/all';

                    });
                }
            }
        }
    }

    render() {
        const { requiredDetailsModalOpen, selectedIntegrationIndex } = this.state;
        const selectedIntegration = this.props.integrations[selectedIntegrationIndex];
        const { integrations, searchIntegration, searchIntegrations, clearSearch, integrationCategories, filterIntegrations, filteredIntegration, toggleIntegration } = this.props;

        return (
            <div className='sectionBody pt-0 mt-25'>
                {!this.state.authenticating ? <div className='flex'>
                    <div className='sectionBlock' style={{ width: 265 }}>

                        <div className="searchbarContainer mb-25">
                            <i className="las la-search" />
                            <input
                                className="searchbar"
                                placeholder="Search..."
                                value={searchIntegration}
                                onChange={searchIntegrations}
                            />
                            <i
                                onClick={clearSearch}
                                className="las la-times-circle"
                                style={{ visibility: searchIntegration == '' ? 'hidden' : 'visible' }}
                            />
                        </div>
                        <div>

                            {integrationCategories.map((category, index) => {
                                return (
                                    <div>
                                        {filteredIntegration == category ? <h3
                                            key={index}
                                            className={`categoryButton ${filteredIntegration == category ? 'selected' : ''}`}
                                            onClick={() => filterIntegrations(category)}
                                        >
                                            {category}
                                        </h3> : <h4
                                            key={index}
                                            className={`categoryButton ${filteredIntegration == category ? 'selected' : ''}`}
                                            onClick={() => filterIntegrations(category)}
                                        >
                                            {category}
                                        </h4>}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='sectionBlock h-100 ml-25' style={{ maxWidth: 900 }}>
                        <div className='flex' style={{ flexWrap: 'wrap' }}>
                            {integrations && integrations.map((integration, index) => {
                                if ((filteredIntegration == 'All' || filteredIntegration == integration.category || (filteredIntegration == 'Integrated' && integration.enabled) || (filteredIntegration == 'Unintegrated' && !integration.enabled)) && ((searchIntegration != '' && integration.name.toLowerCase().includes(searchIntegration.toLowerCase())) || searchIntegration == '')) {
                                    return (
                                        <div key={index} className={`integrationCard ${integration.enabled ? '' : 'disabled'}`}>
                                            <div className='integrationLogo'>
                                                <img src={integration.logo} />
                                            </div>
                                            <div className='flex-ac flex-sb w-100'>
                                                <h3 >{integration.name}</h3>
                                                <ToggleField
                                                    checked={integration.enabled}
                                                    onChange={(e) => {
                                                        if (integration.requiredFields && integration.requiredFields.length > 0 && !integration.enabled) {
                                                            this.setState({ requiredDetailsModalOpen: true, selectedIntegrationIndex: index });
                                                        } else {
                                                            toggleIntegration(index, e.target.checked)
                                                        }
                                                    }}
                                                    style={{ marginBottom: 0, width: 'min-content' }}
                                                    icons={false}
                                                />
                                            </div>
                                            <div className='integrationDescription'>{integration.description}</div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div> :
                    <LoadingWizard text="AUTHENTICATING INTEGRATION" loading={true} />}
                {requiredDetailsModalOpen && <FieldModal isOpen={true} size="small" modalTitle="Required Fields" toggle={() => this.setState({ requiredDetailsModalOpen: false, requiredFieldValues: {}, selectedIntegrationIndex: null })} cancelOnClick={() => this.setState({ requiredDetailsModalOpen: false, requiredFieldValues: {}, selectedIntegrationIndex: null })} actionButtonLabel="Submit" actionButton={() => toggleIntegration(selectedIntegrationIndex, true, false, this.state.requiredFieldValues)} bodyContent={() => {
                    return selectedIntegration != null && selectedIntegration.requiredFields && selectedIntegration.requiredFields.map((field, index) => {
                        return (
                            <InputField
                                key={index}
                                label={field.label}
                                value={this.state.requiredFieldValues[field.id]}
                                onChange={(e) => {
                                    const requiredFieldValues = this.state.requiredFieldValues;
                                    requiredFieldValues[field.id] = e.target.value;
                                    this.setState({ requiredFieldValues });
                                }}
                            />
                        );
                    })
                }} />}
            </div>
        );
    }
}

export default withRouter(Integrations);