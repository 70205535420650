import React from 'react';

import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class RenameEmailTemplateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.emailTemplate.name ? this.props.emailTemplate.name : '',
			submitting: false,
			requiredFields: { name: false }
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	updateName = () => {
		const { update, emailTemplate } = this.props;
		const { name } = this.state;

		this.setState({ submitting: true })
		let editedEmailTemplate = emailTemplate;
		editedEmailTemplate.name = name;
		update(editedEmailTemplate);
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, requiredFields } = this.state;

		return (
			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Rename Email Template"}
					bodyHeaderText={'Enter new name for this template.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Name"
									value={name}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.updateName()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default RenameEmailTemplateModal;
