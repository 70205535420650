import React from 'react';

import ViewsTable from '../../../components/tables/ViewsTable';

import AddGroupsModal from './modals/addGroupsModal';

import InputField from '../../../components/inputs/inputField';

class EventAttendeeGroups extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name'
		};
		const columnTypes = {
			name: 'text'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			showAddToGroupModal: false,
			showRemoveFromGroupModal: false,
			markedGroup: null,
			showAddGroupModal: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedGroups: [],
			selectedColumnSort: null,
			sortDesc: false,
			toggleAddGroups: false
		};
	}

	componentDidMount() {
		this.setState({ showAddGroupModal: this.props.addGroupToggle });
	}

	toggleAddToGroup = () => {
		this.setState({ showAddToGroupModal: !this.state.showAddToGroupModal }, () => {
			//this.props.toggle();
		});
	};

	toggleRemoveFromGroup = (group) => {
		this.setState({
			showRemoveFromGroupModal: !this.state.showRemoveFromGroupModal
		});
	};

	updatedCheckedGroups = (checked) => {
		this.setState({ checkedGroups: checked });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	toggleAddGroups = () => {
		const { toggleAddGroups } = this.state;
		this.setState({ toggleAddGroups: !toggleAddGroups });
	}

	render() {
		const {
			showAddToGroupModal,

			markedGroup,
			normalizedColumns,
			toggledColumns,
			showRemoveFromGroupModal,
			sortDesc,
			selectedColumnSort,
			toggleAddGroups
		} = this.state;
		const { groups, editedAttendee } = this.props;
		let groupsData = groups;

		if (selectedColumnSort) {
			groupsData = groupsData.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			groupsData = sortDesc ? groupsData.reverse() : groupsData;
		}

		let addGroupsLabel = groupsData.length > 0 ? `${groupsData.length} Group${groupsData.length > 1 ? 's' : ''}` : '';


		return (

			<div className='sectionBody posRel mb-0 p-0' >
				<InputField
					label="Attendees"
					required={true}
					value={addGroupsLabel}
					inputAction={this.toggleAddGroups}
					inputActionLabel={`${groupsData.length == 0 && groupsData.length == 0 ? 'Add Groups' : 'Edit Groups'}`}
					disabled={true}
					onChange={(e) => {


					}}
				/>

				<ViewsTable
					title={"Groups"}
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={groupsData}
					mainColumnActive={false}
					checked={this.state.checkedGroups}
					checkRow={(entryId) => {
						const checked = this.state.checkedGroups;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedGroups(checked);
						this.setState({
							checkedGroups: checked
						});
					}}
					classes="nested"
					//mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedGroups;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedGroups(checked);

						this.setState({
							checkedGroups: checked
						});
					}}
					sortData={this.sortData}
					disableCheck={true}
				/>

				{toggleAddGroups &&
					<AddGroupsModal
						isOpen={toggleAddGroups}
						toggle={this.toggleAddGroups}
						groups={this.props.event.groups}
						attendeeGroups={this.props.editedAttendee.groups}
						attendee={this.props.editedAttendee}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						addGroups={this.props.addGroups}
					/>}


			</div >
		);
	}
}

export default EventAttendeeGroups;
