import React from 'react';

import FieldModal from '../../../components/modals/fieldModal';
import API from '../../../utils/API';

class DeleteTeamModal extends React.Component {
    state = {
        submitting: false,
        url: '',
        copyUrl: false
    };

    emailInvite = async () => {
        const { invite, orgId } = this.props;
        API().post(`Organizations/${orgId}/invites/${invite._id}/sendInvite`, {
        }).then((res) => {
            if (res.data) {
                let url = res.data.url;

                this.setState({ url, copyUrl: true, submitting: false });


            }

        }).catch((error) => {
            console.log(error);
        });

    }
    render() {
        const { isOpen, memberCount, selfSelected, toggle, permission, inviteCount } = this.props;
        const { submitting, copyUrl, url } = this.state;
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={'Resend Invite'}
                    bodyHeaderText={copyUrl ? 'Email successfuly sent to team member' : ''}
                    bodyContent={() => {
                        return (


                            <div>

                                {copyUrl ?
                                    < div >
                                        {/* {url} */}
                                    </div>
                                    :
                                    <div>
                                        <p>This will send an email with a link for the new team member to sign up and create an account.</p>
                                    </div>
                                }

                            </div>
                        )
                    }
                    }
                    toggle={toggle}
                    actionButtonLabel={copyUrl ? null : 'Send Invite Email'}
                    actionButton={() => {
                        this.setState({ submitting: true })
                        this.emailInvite();
                    }
                    }
                    cancelButtonLabel={copyUrl ? 'Close' : 'Cancel'}

                    actionButtonDisabled={submitting || copyUrl}
                ></FieldModal >
            </div >

        );
    }
}

export default DeleteTeamModal;
