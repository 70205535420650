import React from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class AddFromModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subject: this.props.template.subject ? this.props.template.subject : '',
			previewText: this.props.template.previewText ? this.props.template.previewText : '',

			submitting: false,
			invalid: false,
			error: '',
			requiredFields: { subject: false }
		};
	}

	handleSubjectChange = (e) => {
		const { requiredFields, subject } = this.state;
		let subCount = subject.length;
		let required = requiredFields;

		if (subCount > 140) {
			required.subject = true;
		} else {
			required.subject = false;
		}

		this.setState({
			subject: e.target.value,
			requiredFields: required
		});
	};
	handlePreviewTextChange = (e) => {
		this.setState({
			previewText: e.target.value
		});
	};

	updateSubject = async () => {
		this.setState({ submitting: true });
		const { subject, previewText } = this.state;
		const { eventId, orgId, template, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (valid) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/template/${template._id}`, {
					name: template.name,
					subject: subject,
					previewText: previewText
				})
				.then((res) => {
					if (res.data) {
						update(
							{
								name: template.name,
								subject: subject,
								previewText: previewText
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, subject, previewText, requiredFields } = this.state;

		let subCount = subject.length;

		return (
			<div>


				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Subject"}
					bodyHeaderText={'Add your Subject and Preview lines below.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Subject"
									required={false}
									errorTrigger={requiredFields.subject}
									subText={`${subCount} / 140`}
									errorMessage={'Reduce Characters'}
									value={subject}
									onChange={this.handleSubjectChange}
								/>
								<InputField
									label="Preview"
									required={false}
									value={previewText}
									subText={'Suggested Character Count: 40 - 140'}
									onChange={this.handlePreviewTextChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.addPage()}
					actionButtonDisabled={submitting}


				></FieldModal>
			</div>

		);
	}
}

export default AddFromModal;
