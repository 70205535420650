import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Input, FormGroup, Label } from 'reactstrap';
import API from '../../../utils/API';
import AddAttendeeModal from './modals/addAttendeeModal';
import DeleteAttendeeModal from './modals/deleteDefaultModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import EventAttendee from './attendee';
import LoadingWizard from '../../../components/spinner/wizard';

let batchActionCB;
class EventAttendees extends Component {
	constructor(props) {
		super(props);
		let customFieldColumns = {
			email: 'Email',
			firstName: 'First Name',
			lastName: 'Last Name',
			invited: 'Invited',
			attendance: 'Attendance',
			registered: 'Registered',
			checkedIn: 'Checked In',
			title: 'Title',
			phoneNumber: 'Phone Number',
			company: 'Company',
			textMessageOptIn: 'Text Message Opt In',
			dietaryPreference: 'Dietary Preference',
			foodAllergies: 'Food Allergies',
			emergencyContactName: 'Emergency Contact Name',
			emergencyContactPhoneNumber: 'Emergency Contact Phone Number',
			address: 'Address',
			city: 'City',
			state: 'State',
			zip: 'Zip',
			country: 'Country',
			eventGroups: 'Groups',
			updatedAt: 'Last Updated',
			rooming_arrivalDate: 'Rooming Arrival Date',
			rooming_departureDate: 'Rooming Departure Date',
			rooming_arrivalTime: 'Rooming Arrival Time',
			rooming_departureTime: 'Rooming Departure Time',
			rooming_checkInDate: 'Rooming Check-In Date',
			rooming_checkOutDate: 'Rooming Check-Out Date',
			rooming_hotelConfirmationNumber: 'Hotel Confirmation Number',
			rooming_numberOfDaysStay: 'Number of Days Stay',
			rooming_roomType: 'Room Type',
			rooming_paymentType: 'Rooming Payment Type',
			rooming_notes: 'Rooming Notes',
			travel_arrivalCarrier: 'Arrival Carrier',
			travel_arrivalCity: 'Arrival City',
			travel_arrivalClassOfService: 'Arrival Class of Service',
			travel_arrivalFlight: 'Arrival Flight',
			travel_arrivalLocator: 'Arrival Locator',
			travel_arrivalTicketed: 'Arrival Ticketed',
			travel_departureCarrier: 'Departure Carrier',
			travel_departureCity: 'Departure City',
			travel_departureClassOfService: 'Departure Class of Service',
			travel_departureFlight: 'Departure Flight',
			travel_departureLocator: 'Departure Locator',
			travel_departureTicketed: 'Departure Ticketed',
			travel_destinationCity: 'Destination City',
			travel_originCity: 'Origin City'
		};

		let fieldCategories = {
			basics: {
				title: 'General Information',
				columns: [
					'email',
					'firstName',
					'lastName',
					'status',
					'invited',
					'attendance',
					'registered',
					'checkedIn',
					'title',
					'phoneNumber',
					'company',
					'textMessageOptIn',
					'dietaryPreference',
					'foodAllergies',
					'emergencyContactName',
					'emergencyContactPhoneNumber',
					'address',
					'city',
					'state',
					'zip',
					'country',
					'eventGroups',
					'createdAt',
					'updatedAt'
				]
			},
			rooming: {
				title: 'Rooming',
				columns: [
					'rooming_arrivalDate',
					'rooming_departureDate',
					'rooming_arrivalTime',
					'rooming_departureTime',
					'rooming_checkInDate',
					'rooming_checkOutDate',
					'rooming_hotelConfirmationNumber',
					'rooming_numberOfDaysStay',
					'rooming_roomType',
					'rooming_paymentType',
					'rooming_notes'
				]
			},
			travel: {
				title: 'Travel',
				columns: [
					'travel_arrivalCarrier',
					'travel_arrivalCity',
					'travel_arrivalClassOfService',
					'travel_arrivalFlight',
					'travel_arrivalLocator',
					'travel_arrivalTicketed',
					'travel_departureCarrier',
					'travel_departureCity',
					'travel_departureClassOfService',
					'travel_departureFlight',
					'travel_departureLocator',
					'travel_departureTicketed',
					'travel_destinationCity',
					'travel_originCity'
				]
			}
		};



		let defaultFieldTypes = {
			email: 'text',
			firstName: 'text',
			lastName: 'text',
			//status: 'text',
			invited: 'bool',
			attendance: 'text',
			registered: 'bool',
			checkedIn: 'bool',
			phoneNumber: 'text',
			title: 'text',
			company: 'text',
			textMessageOptIn: 'bool',
			dietaryPreference: 'text',
			foodAllergies: 'text',
			emergencyContactName: 'text',
			emergencyContactPhoneNumber: 'text',
			address: 'text',
			city: 'text',
			state: 'text',
			zip: 'text',
			country: 'text',
			eventGroups: 'text',
			updatedAt: 'timestamp',
			rooming_arrivalDate: 'date',
			rooming_departureDate: 'date',
			rooming_arrivalTime: 'time',
			rooming_departureTime: 'time',
			rooming_checkInDate: 'date',
			rooming_checkOutDate: 'date',
			rooming_hotelConfirmationNumber: 'text',
			rooming_numberOfDaysStay: 'number',
			rooming_roomType: 'text',
			rooming_paymentType: 'text',
			rooming_notes: 'text',
			travel_arrivalCarrier: 'text',
			travel_arrivalCity: 'text',
			travel_arrivalClassOfService: 'text',
			travel_arrivalFlight: 'text',
			travel_arrivalLocator: 'text',
			travel_arrivalTicketed: 'text',
			travel_departureCarrier: 'text',
			travel_departureCity: 'text',
			travel_departureClassOfService: 'text',
			travel_departureFlight: 'text',
			travel_departureLocator: 'text',
			travel_departureTicketed: 'text',
			travel_destinationCity: 'text',
			travel_originCity: 'text'
		};

		if (props.organization.integrations.find(s => {
			return s.identifier == "medpro" && s.enabled
		})) {
			customFieldColumns = {
				...customFieldColumns,
				medProId: "MedPro ID",
				NPINumber: "NPI Number",
				stateLicenseNumber: "State License Number",
				licenseState: "License State",
			}

			fieldCategories = {
				...fieldCategories,
				medPro: {
					title: "MedPro",
					columns: [
						"medProId",
						"NPINumber",
						"stateLicenseNumber",
						"licenseState"
					]
				}
			}

			defaultFieldTypes = {
				...defaultFieldTypes,
				medProId: "text",
				NPINumber: "text",
				stateLicenseNumber: "text",
				licenseState: "text",
			}
		}
		props.customFields && props.customFields.forEach((category) => {
			const fields = category.fields;
			fields.forEach((f) => {
				if (fieldCategories[category._id]) {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				} else {
					fieldCategories[category._id] = {
						title: category.title,
						columns: [`${category._id}*${f._id}`]
					};
				}
				customFieldColumns[category._id + '*' + f._id] = f.fieldName;
				defaultFieldTypes[category._id + '*' + f._id] = f.fieldType;
			});
		});

		this.state = {
			programId: props.programId,
			newAttendees: [],
			groupedAttendees: [],
			checkedAttendees: {},
			categoryColumns: fieldCategories,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			allChecked: false,
			attendeeSelected: false,
			selectedAttendee: 0,
			addAttendeeModalOpen: false,
			deleteAttendeesModalOpen: false,
			groupAttendeesModalOpen: false,
			importAttendeesModalOpen: false,
			inviteAttendeesModalOpen: false,
			newAttendee: { firstName: '', lastName: '', email: '' },
			attendeeGroup: {},
			loading: true,
			updateAttendeesModalOpen: false,
			sectionTitleExpanded: true,
			deletedAttendees: [],
			toggleAddAttendee: false,
			sidePanelOpen: false,
			editCount: 0,
			activeRow: ''
		};
	}


	componentDidMount() {

		this.setState({ loading: false })
	}



	toggleAddAttendee = (cb) => {
		const { toggleAddAttendee } = this.state;
		batchActionCB = cb
		this.setState({
			newAttendee: { firstName: '', lastName: '', email: '' },
			toggleAddAttendee: !toggleAddAttendee
		})
	}

	addAttendee = (attendee, cb) => {
		this.props.addAttendee(attendee, () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				cb && cb();
			});
		});

	};

	toggleDeleteAttendees = (checkedAttendees, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedAttendees: checkedAttendees || this.state.checkedAttendees,
			deleteAttendeesModalOpen: !this.state.deleteAttendeesModalOpen
		});
	};

	deleteCheckedAttendees = (attendeeIds) => {
		this.props.deleteAttendees(attendeeIds, () => {
			//batchActionCB && batchActionCB('delete');
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
				this.toggleDeleteAttendees();
			});
		});
	};

	deleteSingleAttendee = (attendee) => {
		this.props.deleteAttendees(Object.keys(attendee), () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
			});
		});
	};

	toggleAttendeesUpdate = (checkedAttendees) => {
		this.setState({
			checkedAttendees: checkedAttendees || this.state.checkedAttendees,
			attendeeGroup: {},
			updateAttendeesModalOpen: !this.state.updateAttendeesModalOpen,
			editCount: this.state.editCount + 1

		});
	};

	toggleGroupAttendees = (checkedAttendees) => {
		this.setState({
			checkedAttendees: checkedAttendees || this.state.checkedAttendees,
			attendeeGroup: {},
			groupAttendeesModalOpen: !this.state.groupAttendeesModalOpen,
			editCount: this.state.editCount + 1

		});
	};

	groupCheckedAttendees = (group, callback) => {
		this.props.groupAttendees(group, this.state.checkedAttendees, (added) => {
			callback(added);
		});
		this.setState({
			groupedAttendees: [...this.state.groupedAttendees, group], loading: false,
			editCount: this.state.editCount + 1
		});
	};

	updateCheckedAttendeesStatus = (status, callback) => {
		this.props.batchUpdateAttendees(status, this.state.checkedAttendees, (added) => {
			const allAttendees = this.state.allAttendees.map((a) => {
				if (this.state.checkedAttendees[a._id]) {
					a.status = status;
				}
				return a;
			});
			const updatedAttendees = this.state.sortedAttendees.map((a) => {
				if (this.state.checkedAttendees[a._id]) {
					a.status = status;
				}
				return a;
			});
			this.setState({
				checkedAttendees: {},
				sortedAttendees: updatedAttendees,
				allAttendees: allAttendees,
				editCount: this.state.editCount + 1

			});
			callback(added);
		});
		//this.setState({ groupedAttendees: [ ...this.state.groupedAttendees, group ], loading: false });
	};

	toggleInviteAttendees = () => {
		this.setState({
			inviteAttendeesModalOpen: !this.state.inviteAttendeesModalOpen
		});
	};

	inviteCheckedAttendees = async (callback) => {
		const { checkedAttendees } = this.state;
		const programId = this.props.programId;
		const attendeeIds = Object.keys(checkedAttendees);
		if (attendeeIds.length > 0) {
			await API().get(`Organizations/${this.props.orgId}/programs/${programId}/emails/invitation`).then((res) => {
				if (res.data) {
				}
			});
		}
	};

	updateAttendee = (attendeeId, attendee) => {
		const updatedAttendees = this.state.sortedAttendees.map((a) => {
			if (attendeeId == a.id) {
				attendee.metadata.forEach((meta) => (attendee[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
				a = { ...a, ...attendee };
			}
			return a;
		});
		this.setState({
			sortedAttendees: updatedAttendees,
			editCount: this.state.editCount + 1
		}, () => this.props.updateAttendee(attendeeId, attendee));
	};


	addField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (
				fieldCategories[category._id] &&
				!fieldCategories[category._id].columns.includes(`${category._id}*${f._id}`)
			) {
				fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
			} else {
				fieldCategories[category._id] = {
					title: category.title,
					columns: [`${category._id}*${f._id}`]
				};
			}
			fieldColumns[category._id + '*' + f._id] = f.fieldName;
			fieldTypes[category._id + '*' + f._id] = f.fieldType;
		});
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	updateField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (fieldCategories[category._id]) {
				//fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				fieldColumns[category._id + '*' + f._id] = f.fieldName;
				fieldTypes[category._id + '*' + f._id] = f.fieldType;
			}
		});
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	removeField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		if (fields.length > 0) {
			if (fieldCategories[category._id]) {
				fieldCategories[category._id].columns = [];

				fields.forEach((f) => {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
					fieldColumns[category._id + '*' + f._id] = f.fieldName;
					fieldTypes[category._id + '*' + f._id] = f.fieldType;
				});
			}
		} else {
			delete fieldCategories[category._id];
		}
		this.setState({ columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories }, cb);
	};

	saveAttendee = (attendee, cb) => {
		this.props.saveAttendee(attendee, () => {
			this.setState({ editCount: this.state.editCount + 1 }, () => {
				cb && cb();
			})
		});
	}
	setActiveRow = (attendeeId) => {
		this.setState({ activeRow: attendeeId })
	}


	render() {
		const {
			programId,
			deleteAttendeesModalOpen,
			groupAttendeesModalOpen,
			categoryColumns,
			columns,
			updateAttendeesModalOpen,
			addAttendeeModalOpen,
			columnTypes,
			checkedAttendees,
			toggleAddAttendee,
			sidePanelOpen,
			editCount,
			loading,
			activeRow
		} = this.state;
		const { program, attendees, readOnly, orgId, attendeeViews } = this.props;
		const checkedCount = Object.keys(checkedAttendees).length;

		return (
			<Switch>
				<Route path={`/${orgId}/programs/${program._id}/attendees`}>
					<Fragment>
						<ViewsContainer
							//key={`attendees${editCount}`}
							activeRow={activeRow}
							programId={programId}
							orgId={orgId}
							views={attendeeViews}
							categoryColumns={categoryColumns}
							entries={attendees}
							columns={columns}
							columnTypes={columnTypes}
							title="Default Event Attendees"
							description="Users listed here will be added to all approved events. Create custom views to quickly check on your attendees."
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddAttendee, label: 'Add attendee' },
							]}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteAttendees
								}
							]}
							enableDownloadViews={true}
							type={'Attendee'}
							referenceCollection={'Programs'}
							referenceId={programId}
							breadcrumbs={[
								{
									name: program.name,
								}
							]}
							section={'attendees'}
							fields={this.props.customFields}
							addField={this.addField}
							updateField={this.updateField}
							defaultSort={'email'}
							removeField={this.removeField}
						/>
						{
							<DeleteAttendeeModal
								isOpen={deleteAttendeesModalOpen}
								toggle={() => this.toggleDeleteAttendees()}
								title={`Delete ${checkedCount > 1 ? 'Attendees' : 'Attendee'}`}
								message={`You are about to delete ${checkedCount} ${checkedCount > 1
									? 'attendees'
									: 'attendee'}, this is irreversible,
						would you like to continue?`}
								submit={() =>
									this.deleteCheckedAttendees(Object.keys(checkedAttendees), () => {
										this.setState({ checkedAttendees: {}, deleteAttendeesModalOpen: false });
									})}
							/>
						}
						{toggleAddAttendee && <AddAttendeeModal
							isOpen={toggleAddAttendee}
							handleSubmit={this.addAttendee}
							toggle={this.toggleAddAttendee}
							programId={programId}
							orgId={this.props.orgId}
						/>}


						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/programs/:programId/attendees/:attendeeId`}
									render={(props) => {
										const attendeeId = props.match.params.attendeeId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={attendeeId}>

											{!loading && <EventAttendee
												program={this.props.program}
												programId={programId}
												readOnly={readOnly}
												orgId={this.props.orgId}
												locations={this.props.locations}
												deleteAttendee={this.deleteSingleAttendee}
												attendees={attendees}
												customFields={this.props.customFields}
												addNewGroup={this.props.addNewGroup}
												saveAttendee={this.saveAttendee}
												setActiveRow={this.setActiveRow}
											/>}
											<LoadingWizard loading={loading} />
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/programs/:programId/attendees`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route>

			</Switch>
		);
	}
}

export default withRouter(EventAttendees);
