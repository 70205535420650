import React, { Component, Fragment } from 'react';
import { Modal } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import CodeEditor from '../../../components/codeEditor/codeEditor';
import CopyButton from '../../../components/inputs/copyButton';
import ToggleField from '../../../components/inputs/toggleField';
import InputField from '../../../components/inputs/inputField';

import AddBccProgramTemplateeModal from './modals/addBccProgramTemplateeModal';
import AddFromModal from './modals/addFromTemplateModal';
import AddSubjectModal from './modals/addSubjectTemplateModal';
import SendTestProgramTemplateModal from './modals/sendTestProgramTemplateModal';

import TemplateRenameModal from './modals/renameEmailTemplateModal';
import EmailEditor from '../../../components/email/emailEditor';

import * as IFrame from 'react-iframe-form';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import AlertBar from '../../../components/custom/alertBar';
import DeleteEmailModal from './modals/deleteDefaultModal';
import DuplicateEmailModal from './modals/duplicateEmailModal';


function ValidateEmail(mail) {
	var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (mail.match(mailformat)) {
		return true;
	} else {
		return false;
	}
}

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

class EventEmail extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const shortCodes = [
			{ label: 'Organization Name', key: 'org.name' },
			{ label: 'Event Name', key: 'event.name' },
			{ label: 'Event Start Date', key: 'event.startDate' },
			{ label: 'Event Start Time', key: 'event.startTime' },
			{ label: 'Event End Date', key: 'event.endDate' },
			{ label: 'Event End Time', key: 'event.endTime' },
			{ label: 'Event Name Slug', key: 'event.nameSlug' },
			{ label: 'Event Timezone', key: 'event.timezone' },
			{ label: 'Attendee First Name', key: 'attendee.firstName' },
			{ label: 'Attendee Last Name', key: 'attendee.lastName' },
			{ label: 'Attendee Email', key: 'attendee.email' },
			{ label: 'Attendee PhoneNumber', key: 'attendee.phoneNumber' },
			{ label: 'Attendee Title', key: 'attendee.title' },
			{ label: 'Attendee Company', key: 'attendee.company' },
			{ label: 'Attendee Text Message Opt In', key: 'attendee.textMessageOptIn' },
			{ label: 'Attendee Dietary Preference', key: 'attendee.dietaryPreference' },
			{ label: 'Attendee Food Allergies', key: 'attendee.foodAllergies' },
			{ label: 'Attendee Emergency Contact Name', key: 'attendee.emergencyContactName' },
			{ label: 'Attendee Emergency Contact PhoneNumber', key: 'attendee.emergencyContactPhoneNumber' },
			{ label: 'Attendee Address', key: 'attendee.address' },
			{ label: 'Attendee City', key: 'attendee.city' },
			{ label: 'Attendee State', key: 'attendee.state' },
			{ label: 'Attendee Zip', key: 'attendee.zip' },
			{ label: 'Attendee Country', key: 'attendee.country' }

		];

		const keyedMetadata = [
			'organization.name',
			'event.name',
			'event.startDate',
			'event.startTime',
			'event.endDate',
			'event.endTime',
			'event.nameSlug',
			'event.timezone',
			'attendee.firstName',
			'attendee.lastName',
			'attendee.email',
			'attendee.phoneNumber',
			'attendee.title',
			'attendee.company',
			'attendee.textMessageOptIn',
			'attendee.dietaryPreference',
			'attendee.foodAllergies',
			'attendee.emergencyContactName',
			'attendee.emergencyContactPhoneNumber',
			'attendee.address',
			'attendee.city',
			'attendee.state',
			'attendee.zip',
			'attendee.country'
		];
		const keyedData = {
			organization: {
				name: 'Name',
				_defaultLabel: 'Organization'
			},
			event: {
				name: 'Name',
				startDate: 'Start Date',
				startTime: 'Start Time',
				endDate: 'End Date',
				endTime: 'End Time',
				nameSlug: 'Name Slug',
				timezone: 'Timezone',
				_defaultLabel: 'Event'
			},
			attendee: {
				firstName: 'First Name',
				lastName: 'Last Name',
				email: 'Email',
				phoneNumber: 'Phone Number',
				title: 'Title',
				company: 'Company',
				textMessageOptIn: 'Text Message Opt In',
				dietaryPreference: 'Dietary Preference',
				foodAllergies: 'Food Allergies',
				emergencyContactName: 'Emergency Contact Name',
				emergencyContactPhoneNumber: 'Emergency Contact PhoneNumber',
				address: 'Address',
				city: 'City',
				state: 'State',
				zip: 'Zip',
				country: 'Country',
				_defaultLabel: 'Attendee'
			}
		};
		const email = props.emails.length > 0 ? props.emails.find((e) => e._id == params.emailId) : {};
		email.bcc = email.bcc ? email.bcc.toString().replace(/,/g, ', ') : '';
		if (this.props.attendeeFields) {
			this.props.attendeeFields.forEach((category) => {
				const categoryKey = category.title.replace(/ /g, '').replace('?', '');
				keyedData.attendee[categoryKey] = keyedData.attendee[categoryKey] || { _defaultLabel: category.title };
				category.fields.forEach((field) => {
					const fieldKey = field.fieldName.replace(/ /g, '').replace('?', '');
					keyedData.attendee[categoryKey][fieldKey] = field.fieldName;
					keyedMetadata.push(`attendee.${categoryKey}.${fieldKey}`);
					shortCodes.push({
						label: `Attendee ${category.title} ${field.fieldName}`,
						key: `attendee.${categoryKey}.${fieldKey}`
					});
				});
			});
		}

		if (this.props.eventFields) {
			this.props.eventFields.forEach((category) => {
				const categoryKey = category.title.replace(/ /g, '').replace('?', '');
				keyedData.event[categoryKey] = keyedData.event[categoryKey] || { _defaultLabel: category.title };
				category.fields.forEach((field) => {
					const fieldKey = field.fieldName.replace(/ /g, '').replace('?', '');
					keyedData.event[categoryKey][fieldKey] = field.fieldName;
					keyedMetadata.push(`event.${categoryKey}.${fieldKey}`);
					shortCodes.push({
						label: `Event ${category.title} ${field.fieldName}`,
						key: `event.${categoryKey}.${fieldKey}`
					});
				});
			});
		}


		this.state = {
			orgSenderEmail: '',
			orgSenderName: '',
			email: email,
			editedTemplate: email,
			selectedSection: 0,
			editedEmail: { ...email, parameters: email.parameters ? email.parameters : [] },
			sendEmailModalOpen: false,
			editing: false,
			errorMessage: false,
			deleteEmailModalOpen: false,
			toggleDuplicateEmailModal: false,
			loading: true,
			requiredFields: {
				/* name: false,
				subject: false */
			},
			parameters: [],
			codeExpanded: false,
			previewExpanded: false,
			parameterOptionsOpen: false,
			loading: true,
			toggleFrom: false,
			toggleSubject: false,
			toggleEmailTemplateEditorModal: false,
			editCount: 0,
			toggleRename: false,
			toggleBcc: false,
			shortCodes: shortCodes,
			keyedMetadata: keyedMetadata,
			keyedData: keyedData,
			validBcc: true,
			validEmail: true,
		};
	}

	componentDidMount() {
		const { emails, program, orgId } = this.props;

		this.setState({ loading: false });
	}

	saveChanges = (emailTemplate, callback) => {
		const { match: { params } } = this.props;

		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!emailTemplate[key] || emailTemplate[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			if (emailTemplate.isPlainHtml) {
				emailTemplate.template = 'email-simple-html';
			}

			this.props.saveEmail(emailTemplate, () => {
				const email = this.props.emails.find((e) => e._id == params.emailId);
				this.setState({
					email: emailTemplate,
					editedTemplate: { ...emailTemplate },
					editing: false,
					requiredFields: requiredFields
				});
				callback();
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};


	saveTemplate = async () => {
		const { match: { params } } = this.props;

		const { editedTemplate } = this.state;

		let senderEmail = editedTemplate.senderEmail
		let bccEmails = editedTemplate.bcc == '' ? [] : editedTemplate.bcc.replace(/ /g, '').split(',');
		let validbcc = true;
		bccEmails = bccEmails.map((e) => {
			if (!ValidateEmail(e) || '') {
				validbcc = false;
			}

			if (e !== '') return e;
		});
		let validEmail = editedTemplate.senderEmail && editedTemplate.senderEmail !== '' ? ValidateEmail(editedTemplate.senderEmail) : true;

		if (validbcc && validEmail) {
			if (editedTemplate.isPlainHtml) {
				editedTemplate.template = 'email-simple-html';
			}
			this.props.saveEmail(editedTemplate, () => {
				const email = this.props.emails.find((e) => e._id == params.emailId);
				this.setState({
					email: editedTemplate,
					editedTemplate: { ...editedTemplate },
					editing: false,
					validBcc: true,
					validEmail: true
				});
			});

		} else {
			this.setState({ validBcc: validbcc, validEmail: validEmail })

		}

	}


	updateEditedTemplate = (changesObj) => {
		const { editedEmail } = this.state;
		this.setState({ editedEmail: { ...editedEmail, ...changesObj }, editing: true });

	}

	updateEditedEmail = (data, callback) => {
		this.setState({ editing: true, editedEmail: data }, callback);
	};

	toggleCompose = () => {
		this.setState({ sendEmailModalOpen: !this.state.sendEmailModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	updateEmail = (email) => {
		this.setState({ email: email });
	};

	updateEmailTemplate = (emailTemplate) => {
		this.setState({ editedEmail: emailTemplate });
	};
	toggleEmailTemplateEditorModal = () => {
		const { toggleEmailTemplateEditorModal } = this.state;
		this.setState({ toggleEmailTemplateEditorModal: !toggleEmailTemplateEditorModal });
	};
	toggleRenameModal = () => {
		const { toggleRename } = this.state;
		this.setState({ toggleRename: !toggleRename });
	};

	toggleDeleteEmailsModal = () => {
		const { deleteEmailModalOpen } = this.state;
		this.setState({ deleteEmailModalOpen: !deleteEmailModalOpen });
	};

	toggleDuplicateEmailModal = () => {
		const { toggleDuplicateEmailModal } = this.state;
		this.setState({ toggleDuplicateEmailModal: !toggleDuplicateEmailModal })
	}
	editEmailField = (property, value) => {
		const editedEmail = this.state.editedEmail;
		editedEmail[property] = value;
		this.setState({ editedEmail: editedEmail, editing: true });
	};

	cancelEdit = () => {
		const editedEmail = this.state.editedEmail;

		this.setState({ editing: false, editedTemplate: this.state.email });
	};
	updateEditedTemplate = (changesObj) => {
		const { editedTemplate } = this.state;
		this.setState({ editedTemplate: { ...editedTemplate, ...changesObj }, editing: true });

	}

	renderMetadataOptions = () => {
		const { parameterOptionsOpen, editedEmail, shortCodes } = this.state;

		return (
			<Modal
				isOpen={parameterOptionsOpen}
				toggle={() => this.setState({ parameterOptionsOpen: !parameterOptionsOpen })}
			>
				<div>
					<div className="paramsList ">
						<p className="calibreSemiBold">Shortcodes</p>
					</div>
				</div>

				<div>
					<div className="paramsList">
						{shortCodes.map((option, i) => {
							const concatenatedOption = option.key.split('.').join(' ');
							const shortcode = option.key.split('.').join('');
							return (
								<div key={`shortcode-${i}`} style={{ display: 'flex', height: 40 }}>
									<ToggleField
										label={option.label}
										checked={
											editedEmail.parameters && editedEmail.parameters.includes(option.key) ? (
												true
											) : (
												false
											)
										}
										icons={false}
										style={{ width: 'unset', marginBottom: 'unset', margin: 'auto 0' }}
										onChange={() => {
											const parameters = editedEmail.parameters ? editedEmail.parameters : [];
											if (parameters.includes(option.key)) {
												parameters.splice(parameters.indexOf(option.key), 1);
											} else {
												parameters.push(option.key);
											}
											this.updateEditedEmail({ ...editedEmail, parameters: parameters });
											this.editEmailField('parameters', parameters);
										}}
									/>

									{editedEmail.parameters &&
										editedEmail.parameters.includes(option.key) && (
											<div style={{ display: 'flex', margin: 'auto 0', whiteSpace: 'nowrap' }}>
												<div
													style={{
														paddingLeft: 5,
														paddingRight: 10,
														fontFamily: 'Calibre-Regular',
														fontSize: 16,

														margin: 'auto 0'
													}}
												>
													- {`{{${shortcode}}}`}
												</div>
												<CopyButton copyValue={`{{${shortcode}}}`} />
											</div>
										)}
								</div>
							);
						})}
					</div>
				</div>
			</Modal>
		);
	};

	renderSection() {
		const { selectedSection, editedEmail, requiredFields, codeExpanded, previewExpanded } = this.state;
		switch (selectedSection) {
			case 0:
				return (
					<div className="sectionBlock">
						<div className="sectionSubtitle"> Email Details</div>
						<InputField
							label="Name"
							value={editedEmail.name}
							type="text"
							required={true}
							errorTrigger={requiredFields.name}
							onChange={(e) => this.editEmailField('name', e.target.value)}
						/>
						<InputField
							label="Sender Name"
							value={editedEmail.sender}
							type="text"
							placeholder={editedEmail.orgSenderName}
							onChange={(e) => this.editEmailField('sender', e.target.value)}
						/>
						<InputField
							label="Sender Email Address"
							value={editedEmail.senderEmail}
							placeholder={editedEmail.orgSenderEmail}
							type="email"
							onChange={(e) => this.editEmailField('senderEmail', e.target.value)}
						/>
						<InputField
							label="Subject Line"
							required={true}
							errorTrigger={requiredFields.subject}
							value={editedEmail.subject}
							type="text"
							onChange={(e) => this.editEmailField('subject', e.target.value)}
						/>

						<InputField
							label="Preview Text"
							value={editedEmail.previewText}
							type="text"
							onChange={(e) => this.editEmailField('previewText', e.target.value)}
						/>

						<ToggleField
							label="Use Platform Email Template"
							disabled={true}
							checked={!editedEmail.isPlainHtml}
							icons={false}
							onChange={() => {
								this.editEmailField('isPlainHtml', !editedEmail.isPlainHtml);
							}}
						/>

						{!editedEmail.isPlainHtml && (
							<InputField
								label="Email Template"
								value={editedEmail.template}
								onChange={(e) => {
									this.editEmailField('template', e.target.value);
								}}
							/>
						)}
					</div>
				);
			case 1:
				return (
					<div className="flex-sb">
						<div className={`${codeExpanded ? 'w-100' : previewExpanded ? 'hiddenBlock' : 'emailEditor'}`}>
							<div className="flex-sb">
								<div
									className="sectionSubtitle c-pointer"
									onClick={() => {
										this.setState({ codeExpanded: !codeExpanded, previewExpanded: false });
									}}
								>
									Code
								</div>
								<div
									className="calibreSemiBold se-blue c-pointer fs-20"
									onClick={() => {
										this.setState({ parameterOptionsOpen: true });
									}}
								>
									Insert shortcodes
								</div>
							</div>
							<CodeEditor
								className="detailsInput detailsTextArea"
								language="markup"
								content={editedEmail.html}
								style={{ height: '70vh' }}
								setContent={(content) => {
									this.editEmailField('html', content);
								}}
							/>
						</div>
						<div className={`${previewExpanded ? 'w-100' : codeExpanded ? 'hiddenBlock' : 'emailPreview'}`}>
							<div className="flex-sb">
								<div
									className="sectionSubtitle c-pointer"
									onClick={() => {
										this.setState({ previewExpanded: !previewExpanded, codeExpanded: false });
									}}
								>
									Preview
								</div>
							</div>
							<div
								className="emailPreviewWindow"
								style={{ border: '1px solid #bbb', borderRadius: '5px' }}
								dangerouslySetInnerHTML={{ __html: editedEmail.html }}
							/>
						</div>
					</div>
				);
			default:
		}
	}

	toggleFromModal = () => {
		const { toggleFrom } = this.state;
		this.setState({ toggleFrom: !toggleFrom });
	};
	toggleSubjectModal = () => {
		const { toggleSubject } = this.state;
		this.setState({ toggleSubject: !toggleSubject });
	};
	toggleSendTestEmailModal = () => {
		const { toggleSendTestEmail } = this.state;
		this.setState({ toggleSendTestEmail: !toggleSendTestEmail });
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	toggleBccModal = () => {
		const { toggleBcc } = this.state;
		this.setState({ toggleBcc: !toggleBcc });
	};

	render() {
		const { event, eventId, orgId, program } = this.props;
		const {
			email,
			loading,
			editing,
			editedEmail,
			toggleFrom,
			toggleSubject,
			toggleRecipients,
			toggleSendEmailNow,
			toggleSendTestEmail,
			selectedSection,
			editedTemplate,
			toggleRename,
			deleteEmailModalOpen,
			toggleEmailTemplateEditorModal,
			isDedicatedEmailServerEnabled,
			editCount,
			toggleBcc,
			keyedData,
			keyedMetadata,
			validBcc,
			validEmail,
			toggleDuplicateEmailModal,
			toggleCreateCampaign
		} = this.state;
		let recipients = editedEmail.recipients;
		//
		let testNow =
			!loading &&
			((isDedicatedEmailServerEnabled && editedTemplate.serviceUrl) ||
				(!isDedicatedEmailServerEnabled && editedTemplate.subject !== ''));

		let url = !loading ? `${ENV}Organizations/${orgId}/emailrenderer?preview=true` : '';
		let method = 'post';
		let data = !loading ? { html: editedTemplate.html } : { html: '' };

		let emailNamePlaceholder = '';
		let emailPlaceholder = '';
		let fromName = editedTemplate.senderName && editedTemplate.senderName !== '' ? editedTemplate.senderName : '';
		let fromEmail = editedTemplate.senderEmail && editedTemplate.senderEmail !== '' ? editedTemplate.senderEmail : '';
		return (
			<div className="sectionContainer" >
				<AlertBar message={"This email is a template. Changes made here will be applied to all future emails created from this template."} type="error" />

				<SectionHeader
					style={{ marginTop: 20 }}
					title={email.name}
					enableLastLink={true}
					breadcrumbs={[
						{
							name: program.name
						},
						{
							name: 'Event Email Templates'
						}
					]}
					subActions={[
						{
							label: <span><i className='las la-pen mr-10'></i>Rename Template</span>,
							onClick: this.toggleRenameModal,
						},
						{
							label: <span><i className='las la-copy mr-10'></i>Duplicate Template</span>,
							onClick: this.toggleDuplicateEmailModal
						},
						{
							label: <span><i className='las la-trash mr-10'></i>Delete Template</span>,
							onClick: () => this.toggleDeleteEmailsModal(),
						}
					]}
				>
				</SectionHeader>

				<Fragment>
					<div className='flex'>
						<div style={testNow ? { margin: 'auto' } : { margin: 'auto', color: '#D5D5D5', pointerEvents: 'none' }} className={`ml-a ml-10 ${!testNow ? "" : "c-pointer"}`} onClick={!testNow ? () => { } : this.toggleSendTestEmailModal}>
							<i className="las la-envelope-open mr-5"></i>
							Send a Test
						</div>
					</div>
				</Fragment >

				{!loading && <div className='sectionBody'>
					<div className="blocksContainer">
						<div className='sectionBlock mr-25 '>
							<h2 className='blockHeader' style={{ marginTop: 0 }}>To</h2>

							<InputField
								label="BCC"
								placeholder="Add emails separated by commas..."
								value={editedTemplate.bcc}
								errorTrigger={validBcc == false}
								errorMessage={'Invalid Email detected'}
								onChange={(e) => {

									this.updateEditedTemplate({
										bcc: e.target.value
									})
								}}
							/>
							<h2 className='blockHeader'>Subject</h2>
							<InputField
								label="Subject"
								placeholder="Write your subject line"
								value={editedTemplate.subject || ''}
								onChange={(e) => {
									this.updateEditedTemplate({
										subject: e.target.value
									})
								}}
							/>
							<InputField
								label="Preview"
								placeholder="Write a preview message"
								value={editedTemplate.previewText}
								onChange={(e) => {
									this.updateEditedTemplate({
										previewText: e.target.value
									})
								}}
							/>
							<h2 className='blockHeader'>From</h2>
							<InputField
								label="Name"
								placeholder={fromName == '' ? emailNamePlaceholder ? emailNamePlaceholder : 'Simple Events Support' : ''}
								value={fromName}
								onChange={(e) => {
									this.updateEditedTemplate({
										senderName: e.target.value
									})
								}}
							/>
							<InputField
								label="Email"
								placeholder={fromEmail == '' ? emailPlaceholder ? emailPlaceholder : 'support@simple.events' : ''}
								value={fromEmail}
								errorTrigger={validEmail == false}
								errorMessage={'Invalid Email'}
								onChange={(e) => {
									this.updateEditedTemplate({
										senderEmail: e.target.value
									})
								}}
							/>
						</div>
						<div style={{ maxWidth: 900 }} className='sectionBlock emailDesignPreview '>

							<div
								className="designPreviewDetailsContainer flex"
								style={
									!(editedTemplate.dedicatedEmailServerEnabled && editedTemplate.serviceUrl) ||
										!editedTemplate.html ? (
										{
											borderBottom: 'unset',
											borderRadius: 0
										}
									) : (
										{}
									)
								}
							>

								<div className="detailContainer">
									<h2 className='blockHeader mt-0'>Design</h2>
									{/* {isDedicatedEmailServerEnabled &&
										editedTemplate.serviceUrl && (
											<p>
												ServiceUrl: {editedTemplate.serviceUrl}
											</p>
										)}{' '}
									{testNow && (
										<div
											onClick={this.toggleSendTestEmailModal}
											style={{ color: '#2B83F1', cursor: 'pointer' }}
											className="detailDescription"
										>
											Send a test
										</div>
									)} */}
								</div>
								<button
									className='neu ml-a'
									onClick={this.toggleEmailTemplateEditorModal}
								>
									Edit Design
								</button>

							</div>
							<div>
								<p style={{ color: '#999999', marginBottom: 5 }} className='small'>Email Template Design</p>
							</div>
							{isDedicatedEmailServerEnabled && editedTemplate.serviceUrl ? (
								<div
									style={{
										maxWidth: 700,
										overflow: 'auto',
										borderRadius: 0,
										maxHeight: 900
									}}
									className="emailPreview"
								>
									<iframe
										className="emailPreviewWindow Frame"
										scrolling="no"
										style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
										src={`${editedTemplate.serviceUrl
											? editedTemplate.serviceUrl.concat('?preview=true')
											: ''}`}
									/>
								</div>
							) : (
								(
									<div
										className="emailPreview  eFrame flex-ac preview"
										style={{
											backgroundColor: editedTemplate.emailStyles ? editedTemplate.emailStyles.backgroundColor : '',
										}}
									>
										{
											<IFrame.Form
												id="builderPreviewFrameTemplate"
												className="emailPreviewWindow Frame"
												key={`template-${editCount}`}
												style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
												name="PreviewFrameTemp"
												config={{ url, method, data }}
											/>
										}
									</div>
								)
							)}
						</div>
					</div>
				</div>}
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveTemplate}
					updatedAt={email.updatedAt} />
				{
					toggleBcc && (
						<AddBccProgramTemplateeModal
							isOpen={toggleBcc}
							emailTemplate={editedTemplate}
							orgId={orgId}
							eventId={eventId}
							toggle={this.toggleBccModal}
							saveChanges={(content) => {
								this.saveChanges(content, () => {
									this.toggleBccModal();
								});
							}}
						/>
					)
				}
				{
					toggleFrom && (
						<AddFromModal
							isOpen={toggleFrom}
							emailTemplate={editedTemplate}
							orgId={orgId}
							eventId={eventId}
							toggle={this.toggleFromModal}
							saveChanges={(content) => {
								this.saveChanges(content, () => {
									this.toggleFromModal();
								});
							}}
						/>
					)
				}
				{
					toggleSubject && (
						<AddSubjectModal
							isOpen={toggleSubject}
							emailTemplate={editedTemplate}
							orgId={orgId}
							eventId={eventId}
							toggle={this.toggleSubjectModal}
							saveChanges={(content) => {
								this.saveChanges(content, () => {
									this.toggleSubjectModal();
								});
							}}
						/>
					)
				}
				{
					toggleSendTestEmail && (
						<SendTestProgramTemplateModal
							isOpen={toggleSendTestEmail}
							template={editedTemplate}
							orgId={orgId}
							eventId={eventId}
							program={program}
							event={event}
							toggle={this.toggleSendTestEmailModal}
						/>
					)
				}
				{
					toggleEmailTemplateEditorModal && (
						<EmailEditor
							isOpen={toggleEmailTemplateEditorModal}
							emailTemplate={editedTemplate}
							orgId={orgId}
							eventId={eventId}
							keyedMetadata={keyedMetadata}
							keyedData={keyedData}
							branding={program.eventDefaults.details.branding}
							event={event}
							url={`${ENV}Organizations/${orgId}/emailrenderer`}
							method={method}
							postData={data}
							links={{
								getAssets: `Organizations/${orgId}/assets`,
								addAssets: `Organizations/${orgId}/assets`,
							}}
							toggle={this.toggleEmailTemplateEditorModal}
							update={(content) => {
								this.saveChanges(content, () => {
									this.setState({ editCount: ++this.state.editCount });
									this.toggleEmailTemplateEditorModal();
								});
							}}
						/>
					)
				}
				{
					toggleRename && (
						<TemplateRenameModal
							isOpen={toggleRename}
							emailTemplate={editedTemplate}
							orgId={orgId}
							eventId={eventId}
							toggle={this.toggleRenameModal}
							update={(content) => {
								this.saveChanges(content, () => {
									this.toggleRenameModal();
								});
							}}
						/>
					)
				}
				{deleteEmailModalOpen &&
					<DeleteEmailModal
						isOpen={deleteEmailModalOpen}
						toggle={() => this.toggleDeleteEmailsModal()}
						title={`Delete Email`}
						message={`You are about to delete this email. This action is irreversible. Would you like to continue?`}
						submit={() => {
							this.props.deleteSingleEmails(editedTemplate._id);
							this.toggleDeleteEmailsModal()
							this.props.history.push(`/${orgId}/programs/${program._id}/emails`)
						}}
					/>
				}
				{
					toggleDuplicateEmailModal && <DuplicateEmailModal
						toggle={this.toggleDuplicateEmailModal}
						isOpen={toggleDuplicateEmailModal}
						name={editedTemplate.name}
						emailId={editedTemplate._id}
						orgId={orgId}
						programId={program._id}
						duplicateEmail={this.props.duplicateEmail}
					/>
				}

			</div >
		);
	}
}

export default withRouter(EventEmail);
