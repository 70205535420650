import React, { Component } from 'react';
import API from '../../utils/API';
import moment from 'moment';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';

import fileIcon from '../../assets/img/icons/file.png';

class FileGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverIndex: null,
            selectedMenu: null,
            lastCheckedIndex: null,
            copyText: "Copy Link",
        };
    }

    toggleFavorite = (fileId, set) => {
        const { orgId, updateFavorite } = this.props;


        API().post(`Organizations/${orgId}/platformActions/favorite`, { type: 'file', id: fileId, set: set }).then((res) => {


            updateFavorite(fileId, set);
        });

    }

    dropDownIsOpen = () => {
        const { dropDownIsOpen } = this.state
        this.setState({ dropDownIsOpen: !dropDownIsOpen })
    }


    GridFileItem(file, index) {
        const { checked, checkRow, singleSelection } = this.props;
        const { copyText, dropDownIsOpen } = this.state;

        let imageDimensions = '';
        let image = fileIcon;
        if (file.type.includes('image')) {

            imageDimensions = file.dimensions
            image = file.url ? file.url : file.imageUrl ? file.imageUrl : fileIcon;

        }


        return (
            <div
                className={`fileListLayoutItem ${checked[file._id] ? 'selected' : ''}`}>
                <div>
                    <div
                        className='imageContainer'
                    >
                        <div className='selectContainer'>


                            <div
                                className={`checkBoxContainer checkBox ${checked[file._id] ? 'checked' : ''}`}
                                disable={singleSelection && Object.keys(checked).length > 0 && !checked[file._id]}
                                onClick={() => {
                                    if (!(singleSelection && Object.keys(checked).length > 0 && !checked[file._id])) {

                                        const checkedLength = Object.keys(checked).length;
                                        if (singleSelection) {
                                            if (!checked[file._id] && checkedLength === 0) {
                                                checkRow(file._id);
                                            } else if (checked[file._id] && checkedLength === 1) {
                                                checkRow(file._id);
                                            }
                                        } else {
                                            checkRow(file._id);
                                        }
                                    }
                                }
                                }
                            >
                                <i className="las la-check" />
                            </div>

                            <div className='c-pointer favoriteContainer'
                                onClick={() => {
                                    this.toggleFavorite(file._id, !file.isFavorite);
                                }}
                            >
                                {file.isFavorite ? <i className="isFavorite las la-star"></i>
                                    :
                                    <i className="lar la-star"></i>
                                }

                            </div>
                        </div>
                        <img
                            alt='file'
                            className="image"
                            title={`${file.name}`}
                            src={image}
                        />

                    </div>

                </div>

                <div className='imageDetailsContainer'>
                    <div className='fileName'>
                        {file.name}
                    </div>
                    <div className='detailsContainer'>
                        <Dropdown direction="right" isOpen={dropDownIsOpen === file._id}
                            toggle={() => {
                                if ((dropDownIsOpen !== file._id)) {
                                    this.setState({ dropDownIsOpen: file._id })
                                }
                                else {
                                    this.setState({ dropDownIsOpen: "" });
                                }
                            }}>

                            <DropdownToggle className="columnToggle">
                                <div className='icon'>
                                    <i className="las la-ellipsis-v" />
                                </div>
                            </DropdownToggle>
                            <DropdownMenu className="columnDropdown" style={{ minWidth: 230 }}>
                                <div className='ph-20'>
                                    <DropdownItem
                                        className={`moreViewsPopoverItemMenuItem`}
                                        style={{ textTransform: 'capitalize', padding: '7px 10px' }}
                                        onClick={() => {
                                            this.toggleFavorite(file._id, !file.isFavorite);
                                        }}
                                    >
                                        {file.isFavorite ? <i className="mr-10 isFavorite las la-star"></i>
                                            :
                                            <i className="lar la-star mr-10"></i>
                                        } Favorite
                                    </DropdownItem>

                                    <DropdownItem
                                        className={`moreViewsPopoverItemMenuItem`}
                                        style={{ textTransform: 'capitalize', padding: '7px 10px' }}

                                        onClick={async () => {
                                            navigator.clipboard.writeText(file.url).then(

                                                () => {
                                                    this.setState({ copyText: "Link Copied" }, () => {
                                                        setTimeout(() => {
                                                            this.setState({ copyText: "Copy Link" })
                                                        }, 2000)
                                                    })

                                                },
                                                () => {
                                                    document.execCommand('copy', true, file.url);
                                                    this.setState({ copyText: "Link Copied" }, () => {
                                                        setTimeout(() => {
                                                            this.setState({ copyText: "Copy Link" })
                                                        }, 2000)
                                                    })


                                                }
                                            );
                                        }}
                                    >
                                        <div>
                                            <i className="las la-link mr-10"></i>

                                            {copyText}
                                        </div>


                                    </DropdownItem>


                                </div>
                                <hr />



                                <div className='ph-20'>
                                    <div className="mb-10" style={{ fontWeight: 600 }}>File Details</div>

                                    <div className="mb-10">
                                        <div style={{ fontSize: 12, color: '#999999' }}>Type:</div>
                                        <div>{file.type}</div>
                                    </div>

                                    <div className="mb-10">
                                        <div style={{ fontSize: 12, color: '#999999' }}>Size:</div>
                                        <div>{file.size}</div>
                                    </div>

                                    <div className="mb-10">
                                        <div style={{ fontSize: 12, color: '#999999' }}>Modified:</div>
                                        <div>{moment(file.updatedAt).format('MMMM Do YYYY, h:mm A')}</div>
                                    </div>

                                    <div className="mb-10">
                                        <div style={{ fontSize: 12, color: '#999999' }}>Created:</div>
                                        <div>{moment(file.createdAt).format('MMMM Do YYYY, h:mm A')}</div>
                                    </div>

                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>

                <div
                    className='dimensions'>
                    {imageDimensions}
                </div>
            </div >
        );
    }


    render() {
        const {
            data
        } = this.props;

        return (
            <div style={{ overflow: 'auto', height: '100%' }}>
                <div className="tableSubColumns" style={{ height: '100%', overflowX: 'hidden' }}>
                    <div
                        style={{ display: 'flex', flexFlow: 'wrap', flexGrow: 'unset', height: '100%', alignContent: 'baseline' }}
                        className="tableColumnRows"
                        ref={this.contentScroll}
                    >
                        {data.map((d, i) => this.GridFileItem(d, i))}

                        {data.length === 0 ?
                            <div className='BlankTable'>
                                <h2 className='mb-15'> No {this.props.title} Yet</h2>

                                <p className='tableLabel'> Add <span className='lower' > {this.props.title} </span>{this.props.importAvailable && <span> or import <span className='lower'> {this.props.title} </span>  from a spreadsheet. </span>} </p>
                            </div>
                            : false
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default FileGrid;
