import React from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import TabsContainer from '../../../../components/views/TabsContainer';
import ViewsTable from '../../../../components/tables/ViewsTable';
import FieldModal from '../../../../components/modals/fieldModal';

import SelectField from '../../../../components/inputs/selectField';

class AddRecipientsModal extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			sendDate: 'Date',
			sendTime: 'Time',
			timezone: 'Timezone',
			sent: 'Sent',
			recipientModel: 'Type',
			cancelled: 'Cancelled'
		};
		const columnTypes = {
			sendDate: 'date',
			sendTime: 'time',
			timezone: 'text',
			sent: 'bool',
			recipientModel: 'text',
			cancelled: 'bool'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			name: null,
			submitting: false,
			invalid: false,
			error: '',
			attendees: [],
			sessions: [],
			groups: [],
			selectedSection: 0,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			checkedAttendees: {},
			checkedGroups: {},
			checkedSessions: {},
			selectedColumnSort: null,
			sortDesc: false,
			selectedRecipientsCount: 0
		};
	}
	componentDidMount() {
		const { event, notification } = this.props;

		let checkedAttendees = [];
		let checkedSessions = [];
		let checkedGroups = [];
		let selectedSection = 0;
		let selectedRecipientsCount = 0;

		if (notification.recipients) {
			notification.recipients.attendees.map((a) => {
				checkedAttendees[a] = true;
				selectedSection = 0;
			});
			selectedRecipientsCount =
				Object.keys(checkedAttendees).length !== 0
					? Object.keys(checkedAttendees).length
					: selectedRecipientsCount;
			notification.recipients.groups.map((a) => {
				checkedGroups[a] = true;
				selectedSection = 1;
			});
			selectedRecipientsCount =
				Object.keys(checkedGroups).length !== 0 ? Object.keys(checkedGroups).length : selectedRecipientsCount;
			notification.recipients.sessions.map((a) => {
				checkedSessions[a] = true;
				selectedSection = 2;
			});
			selectedRecipientsCount =
				Object.keys(checkedSessions).length !== 0
					? Object.keys(checkedSessions).length
					: selectedRecipientsCount;
		}

		this.setState({
			attendees: event.attendees.map((a) => {
				return {
					...a,
					notificationsEnabled: a.notificationSubscriptions && a.notificationSubscriptions.length > 0
				};
			}),
			groups: event.groups,
			sessions: event.sessions,
			checkedAttendees: checkedAttendees,
			checkedSessions: checkedSessions,
			checkedGroups: checkedGroups,
			selectedSection: selectedSection,
			selectedRecipientsCount: selectedRecipientsCount
		});
	}
	updateSelected = (selectedIndex) => {
		const { checkedAttendees, checkedSessions, checkedGroups } = this.state;
		let selectedRecipientsCount = 0;

		selectedRecipientsCount =
			selectedIndex == 0
				? Object.keys(checkedAttendees).length
				: selectedIndex == 1
					? Object.keys(checkedGroups).length
					: selectedIndex == 2 ? Object.keys(checkedSessions).length : 0;
		this.setState({
			selectedSection: selectedIndex,
			selectedColumnSort: null,
			selectedRecipientsCount: selectedRecipientsCount
		});
	};

	updateRecipients = async () => {
		this.setState({ submitting: true });

		const { checkedGroups, checkedAttendees, checkedSessions, selectedSection } = this.state;
		const { eventId, orgId, notification, update, toggle } = this.props;
		let valid = true;

		if (valid) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/notifications/${notification._id}`, {
					name: notification.name,
					recipients: {
						attendees: selectedSection == 0 ? Object.keys(checkedAttendees) : [],
						groups: selectedSection == 1 ? Object.keys(checkedGroups) : [],
						sessions: selectedSection == 2 ? Object.keys(checkedSessions) : []
					}
				})
				.then((res) => {
					if (res.data) {
						update(
							{
								name: notification.name,
								recipients: {
									attendees: selectedSection == 0 ? Object.keys(checkedAttendees) : [],
									groups: selectedSection == 1 ? Object.keys(checkedGroups) : [],
									sessions: selectedSection == 2 ? Object.keys(checkedSessions) : []
								}
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false });
		}
	};

	renderSelectedTabContent = () => {
		const { selectedSection, toggledColumns, selectedColumnSort, sortDesc } = this.state;

		let columns = {};
		let columnTypes = {};
		let normalizedColumns = null;
		let checkRow = () => { };
		let checkAllRows = () => { };
		let checkedRows = [];
		let data = [];
		let sortData = () => { };

		switch (selectedSection) {
			case 0:
				columns = {
					firstName: 'First Name',
					lastName: 'Last Name',
					email: 'Email',
					notificationsEnabled: 'Notifications Enabled',
				};
				columnTypes = {
					firstName: 'text',
					lastName: 'text',
					email: 'text',
					notificationsEnabled: 'bool'
				};
				checkRow = (entryId) => {
					const checked = this.state.checkedAttendees;
					checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
					this.setState({
						checkedAttendees: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkAllRows = (entryIds) => {
					let checked = this.state.checkedAttendees;
					entryIds.length > 0
						? entryIds.forEach((entryId) => {
							checked[entryId] = true;
						})
						: (checked = {});

					this.setState({
						checkedAttendees: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkedRows = this.state.checkedAttendees;
				data = this.state.attendees;
				normalizedColumns =
					!normalizedColumns &&
					Object.keys(columns).map((cfc) => {
						toggledColumns[cfc] = true;
						return {
							label: columns[cfc],
							key: cfc,
							value: cfc,
							type: columnTypes[cfc],
							sortAsc: false,
							sortDesc: false
						};
					});

				sortData = (column, sortAsc, sortDesc) => {
					normalizedColumns = normalizedColumns.map((col) => {
						if (col.key == column.key) {
							col.sortAsc = sortAsc;
							col.sortDesc = sortDesc;
						} else {
							col.sortAsc = false;
							col.sortDesc = false;
						}
						return col;
					});

					this.setState({
						selectedColumnSort: column,
						sortDesc: sortDesc == false ? true : false
					});
				};

				break;
			case 1:
				columns = {
					name: 'Name'
				};
				columnTypes = {
					name: 'text'
				};
				checkRow = (entryId) => {
					const checked = this.state.checkedGroups;
					checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
					this.setState({
						checkedGroups: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkAllRows = (entryIds) => {
					let checked = this.state.checkedGroups;
					entryIds.length > 0
						? entryIds.forEach((entryId) => {
							checked[entryId] = true;
						})
						: (checked = {});

					this.setState({
						checkedGroups: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkedRows = this.state.checkedGroups;
				data = this.state.groups;
				normalizedColumns = Object.keys(columns).map((cfc) => {
					toggledColumns[cfc] = true;
					return {
						label: columns[cfc],
						key: cfc,
						value: cfc,
						type: columnTypes[cfc],
						sortAsc: false,
						sortDesc: false
					};
				});
				break;
			case 2:
				columns = {
					name: 'Name'
				};
				columnTypes = {
					name: 'text'
				};
				checkRow = (entryId) => {
					let checked = this.state.checkedSessions;

					checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);

					this.setState({
						checkedSessions: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkAllRows = (entryIds) => {
					let checked = this.state.checkedSessions;
					entryIds.length > 0
						? entryIds.forEach((entryId) => {
							checked[entryId] = true;
						})
						: (checked = {});

					this.setState({
						checkedSessions: checked,
						selectedRecipientsCount: 0
					});
				};
				checkedRows = this.state.checkedSessions;
				data = this.state.sessions;
				normalizedColumns = Object.keys(columns).map((cfc) => {
					toggledColumns[cfc] = true;
					return {
						label: columns[cfc],
						key: cfc,
						value: cfc,
						type: columnTypes[cfc],
						sortAsc: false,
						sortDesc: false
					};
				});
				break;
		}

		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}
		return (
			<ViewsTable
				style={{ margin: 0, paddingTop: 10, paddingBottom: 25, height: 325 }}
				columns={normalizedColumns}
				toggledColumns={toggledColumns}
				data={data}
				mainColumnActive={false}
				checked={checkedRows}
				checkRow={checkRow}
				mainActions={[]}
				checkAllRows={checkAllRows}
				sortData={sortData}
			/>
		);
	};

	render() {
		const { isOpen, toggle, notification } = this.props;
		const { submitting, selectedSection, selectedRecipientsCount } = this.state;
		let options = [{ label: 'Attendees', value: 0 }, { label: 'Groups', value: 1 }, { label: 'Sessions', value: 2 }];

		return (
			<div>

				<FieldModal
					size="large"
					isFullWidthBody={true}
					isOpen={isOpen}
					modalTitle={"Add Recipients"}
					bodyHeaderText={'Choose the recipients you would like to add to this notification.'}
					bodyContent={() => {
						return (
							<div className=''>
								<div style={{ width: 158, marginLeft: 1 }} className='mb-10'>
									<SelectField
										label={'Select From'}
										value={options[selectedSection]}
										options={options}
										onChange={(val) => {
											this.updateSelected(val.value)
										}}
									/>

								</div>
								{this.renderSelectedTabContent()}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? (
						`Add Recipients ${selectedRecipientsCount == 0 ? '' : `(${selectedRecipientsCount})`}`
					) : (
						'Adding Recipients...'
					)}
					actionButton={() => this.updateRecipients()}
					actionButtonDisabled={submitting}


				></FieldModal>




			</div >

		);
	}
}

export default AddRecipientsModal;
