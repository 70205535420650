import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';

class GroupRemoveAttendeeModal extends React.Component {
	state = {
		submitting: false
	};

	removeAttendees = async () => {
		const { eventId, orgId, markedAttendee, group } = this.props;

		let deletingAttendees = Object.keys(this.props.checkedAttendees);
		this.setState({ submitting: true })

		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/groups/${group._id}`, {
				attendees: this.props.existingAttendees
					.filter((a) => !deletingAttendees.includes(a._id))
					.map((a) => a._id)
			})
			.then((res) => {
				if (res.data) {
					this.props.removeAttendees(deletingAttendees);
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, markedAttendee, toggle } = this.props;
		const { submitting } = this.state;
		const count = Object.keys(this.props.checkedAttendees).length;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Remove Attendee"}
					bodyHeaderText={<span>You are about to Remove{' '}
						<b>
							{count} attendee{count > 1 && 's'}
						</b>{' '}
						from this group, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Remove' : 'Removing'}
					actionButton={() => this.removeAttendees()}
					actionButtonDisabled={submitting}
					isDelete={true}


				></FieldModal>
			</div>

		);
	}
}

export default GroupRemoveAttendeeModal;
