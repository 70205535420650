import React from 'react';
import API from '../../../utils/API';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SectionHeader from '../../../components/views/SectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import { Route, Switch } from 'react-router-dom';
import EventReport from './eventReport';
import LoadingWizard from '../../../components/spinner/wizard';
import ReportsCenter from './modals/reportsCenter';
import FieldModal from '../../../components/modals/fieldModal';
import ViewsTable from '../../../components/tables/ViewsTable';
import ReportsGrid from '../../../components/tables/ReportsGrid';

class EventReports extends React.Component {
	state = {
		loading: true,
		reports: [],
		selectedSection: 0,
		gridView: false,
		reportCenterOpen: false,
		searchTerm: '',
		reportToDelete: null,
		deleteReportModalOpen: false,
		platformReportsModalOpen: false,
		platformReports: [],
	};

	async componentDidMount() {
		const { orgId, eventId, event } = this.props;
		let registeredCount = 0;
		let invitedCount = 0;
		event.attendees.forEach((a) => {
			a.status === 'registered' && registeredCount++;
			a.status === 'invited' && invitedCount++;
		});
		const res = await API().get(`Organizations/${orgId}/events/${eventId}/viewReports`);
		if (res.data) {
			this.setState({ reports: res.data, registeredCount, invitedCount, loading: false });
		}

		await API().get(`Organizations/${orgId}/events/${eventId}/reports`).then((res) => {
			if (res.data) {
				this.setState({ platformReports: res.data });
			}
		});
	}



	downloadReportData = async (reportDataUrl) => {
		const { orgId, eventId } = this.props;
		const res = await API().get(`Organizations/${orgId}/events/${eventId}/reports/${reportDataUrl}`);
		return res.data;
	};

	downloadReport = async (report) => {
		this.setState({ fetching: true });
		const data = await this.downloadReportData(report.url);

		if (report.isMultiPage) {
			this.downloadMultiPage(report, data);
		} else {
			this.downloadRegular(report, data);
		}
	};

	downloadMultiPage = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: `${report.Title}${new Date().getTime()}`,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		data.contentTabs.forEach((content) => {
			wb.SheetNames.push(content.tabName);
			var ws_data = [[content.title], content.headerRow, ...content.dataRows];
			var ws = XLSX.utils.aoa_to_sheet(ws_data);
			wb.Sheets[content.tabName] = ws;
		});

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${new Date().getTime()}.xlsx`
		);
		this.setState({ fetching: false });
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: `${report.Title}${new Date().getTime()}`,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Summary');
		wb.SheetNames.push(report.Subject);

		/* make worksheet */
		var ws_data1 = [
			['SUMMARY', ''],
			['', ''],
			['Description', report.description],
			['Total Attendees', data.totalAttendees],
			['Total Registered', data.totalRegistered],
			['Not Registered', data.notRegistered]
		];
		var ws1 = XLSX.utils.aoa_to_sheet(ws_data1);

		/* Add the worksheet to the workbook */
		wb.Sheets['Summary'] = ws1;

		const attendeeData = data.attendeeData;
		/* make worksheet */
		var ws_data2 = [['SUMMARY', ''], ['', ''], data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets[report.Subject] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${new Date().getTime()}.xlsx`
		);
		this.setState({ fetching: false });
	};

	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};

	favoriteReport = async (reportId, set) => {

		const res = await API().post(`Organizations/${this.props.orgId}/platformActions/favorite`, {
			type: 'reports',
			id: reportId,
			set: set
		});
		if (res.data) {
			const reports = this.state.reports.map((r) => {
				if (r._id === reportId) {
					r.favorited = set;
				}
				return r;
			});
			this.setState({ reports: reports });
		}
	};

	ReportItem(report, index) {
		return (
			<div className="tableColumnRow" key={index}>
				<div className="tableColumnRowCell" style={{ minWidth: 250, maxWidth: 250, width: 250 }}>{report.Title}</div>
				<div className="tableColumnRowCell" style={{ minWidth: 250, maxWidth: 250, width: 250 }}>{report.description}</div>
				<div className="tableColumnRowCell ml-a">
					<button
						className="min"
						onClick={() => !this.state.fetching && this.downloadReport(report)}
					>
						<i className="las la-excel mr-10" /> Download
					</button>
				</div>

			</div>
		);
	}

	updateSelected = (index) => {
		this.setState({ selectedSection: index });
	};

	toggleReportCenter = () => {
		this.setState({ reportCenterOpen: !this.state.reportCenterOpen });
	}

	addReport = (report, cb) => {
		this.setState({ reports: [...this.state.reports, report] }, () => {
			cb && cb();
		});
	}

	deleteReport = async () => {
		const { orgId, eventId } = this.props;
		const { reportToDelete } = this.state;
		const res = await API().delete(`Organizations/${orgId}/events/${eventId}/viewReports/${reportToDelete}`);
		if (res.data) {
			this.setState({ reportToDelete: null, reports: this.state.reports.filter(r => r._id !== reportToDelete) }, () => {
				this.toggleDeleteReportModal();
			})
		}
	}

	toggleDeleteReportModal = () => {
		this.setState({ deleteReportModalOpen: !this.state.deleteReportModalOpen })
	}

	render() {
		const { reports, selectedSection, gridView, loading, reportCenterOpen, deleteReportModalOpen, platformReportsModalOpen, platformReports } = this.state;
		const { event, permittedEvent, locations } = this.props;
		let tabs = [
			{ title: <span>All Reports</span> },
			{ title: <span>Favorites</span> },
			{ title: <span>Created by me</span> },
		]

		let viewReports = reports;
		if (this.state.searchTerm.length > 0) {
			viewReports = reports.filter(r => r.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
		}

		if (selectedSection == 1) {
			viewReports = viewReports.filter(r => r.favorited)
		} else if (selectedSection == 2) {
			viewReports = viewReports.filter(r => r.createdByMe)
		}

		return (<Switch>
			<Route exact path={`/:organization/events/:event/reports/:report`}>
				<>
					{!loading && <EventReport
						reports={reports}
						fetchAttendees={this.fetchAttendees}
						event={event}
						readOnly={permittedEvent.readOnly}
						eventId={event._id}
						orgId={this.props.orgId}
						attendees={event.attendees}
						attendeeCount={event.attendeeCount}
						registeredCount={event.registeredCount}
						loadMoreAttendees={this.loadMoreAttendees}
						loadMoreSessions={this.loadMoreSessions}
						addAttendee={this.addAttendee}
						updateAttendee={this.updateAttendee}
						importAttendees={this.importAttendees}
						groupAttendees={this.groupAttendees}
						deleteAttendees={this.deleteAttendees}
						customFields={event.customFields['attendees']}
						addCustomField={this.addCustomField}
						locations={locations}
						batchUpdateAttendeeStatus={this.batchUpdateAttendeeStatus}
						batchAddAttendeePoint={this.batchAddAttendeePoint}
						addNewGroup={this.addGroup}
						groupImportedAttendees={this.groupImportedAttendees}
						addAttendeesView={this.addAttendeesView}
						updateView={this.updateAttendeesView}
						removeView={this.removeAttendeesView}
						attendeeViews={event.attendeesViews}
						setViewAsMain={this.setAttendeesViewAsMain}
						resetMainView={this.resetAttendeesViewAsMain}
						subscriptionAddons={this.props.subscriptionAddons}
						favoriteReport={this.favoriteReport}
						organization={this.props.organization} />}

					<LoadingWizard text="FETCHING REPORTS" loading={loading} />


				</>
			</Route>
			<Route path={`/:organization/events/:event/reports`}>

				<div className="sectionContainer">
					<SectionHeader
						title="Reports"
						description="Create reports to quickly view and share event metrics."
						breadcrumbs={[
							{
								name: event.name,
							}
						]}
					>
						<TabsContainer tabs={tabs}
							updateSelected={this.updateSelected}
							selectedIndex={selectedSection}></TabsContainer>
					</SectionHeader>
					<div className="sectionBody">

						<ActionButtonContainer
							mainActions={[{ onClick: this.toggleReportCenter, label: 'Add Report' }]}
							searchEnabled={true}
							searchEntries={(term) => {
								this.setState({ searchTerm: term })
							}}
							enabledViewSelection={true}
							toggleLayout={() => this.setState({ gridView: !gridView })}
							isGridLayout={gridView}
							viewPanelActions={[{
								iconClass: "las la-archive c-pointer",
								label: "Platform Reports",
								onClick: () => this.setState({ platformReportsModalOpen: true }),
							}]}
						/>
						{!gridView ? <ViewsTable toggledColumns={{ "description": true }}
							title="Reports"
							defaultSort={'report title'}
							data={viewReports}
							favorite={this.favoriteReport}
							disableCheck={true}
							mainColumnActive={true}
							columnWidths={{ "description": 700 }}
							checked={[]}
							sortData={() => { }}
							columns={[{
								label: 'Report Title',
								key: 'name',
								value: 'name',
								type: "string",
								sortAsc: true,
								sortDesc: false,
							}, {
								label: 'Description',
								key: 'description',
								value: 'description',
								type: "string",
								sortAsc: false,
								sortDesc: false,
								width: 500
							}]}
							classes={"nested"}
							rowSettings={[{
								label: <span><i className='las la-trash mr-10'></i>Delete Report</span>,
								onClick: (reportId) => {
									this.setState({ reportToDelete: reportId })
									this.toggleDeleteReportModal()
								}
							}]}
							gridView={gridView}
						/> : <ReportsGrid data={viewReports} />}
					</div>
					<ReportsCenter
						isOpen={reportCenterOpen}
						toggle={this.toggleReportCenter}
						templates={reports}
						addReport={this.addReport}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
					/>

					{deleteReportModalOpen && <FieldModal
						modalTitle="Delete Report" isOpen={deleteReportModalOpen}
						toggle={() => {
							this.setState({ deleteReportModalOpen: !deleteReportModalOpen })
						}} size="small"
						actionButton={this.deleteReport}
						actionButtonLabel="Delete"
						isDelete={true}
						bodyContent={() => {
							return <div className="modalBody">
								<p>
									You are about to delete this report, this is irreversible, would you like to continue?
								</p>
							</div>
						}}></FieldModal>}

					{platformReportsModalOpen && <FieldModal
						className="fit"
						modalTitle="Platform Reports" isOpen={platformReportsModalOpen}
						bodyHeaderText="Raw data reports from the platform available for download."
						isFullWidthBody={true}
						toggle={() => {
							this.setState({ platformReportsModalOpen: !platformReportsModalOpen })
						}} size="stepSize"
						bodyContent={() => {
							return <div className="modalBody">
								<div className="sectionTable fit">
									<div className="tableSubColumns">
										<div className="tableColumnHeader">
											<div className="tableSubColumn" style={{ minWidth: 250, maxWidth: 250, width: 250 }}>Name</div>
											<div className="tableSubColumn" style={{ minWidth: 250, maxWidth: 250, width: 250 }}>Description</div>
											<div className="tableSubColumn ml-a"></div>
										</div>
										<div className='tableColumnRows'>{platformReports.map((report, index) => this.ReportItem(report, index))}</div>
									</div>
								</div>
							</div>
						}}>
					</FieldModal>}
				</div>
			</Route>
		</Switch>
		);
	}
}

export default EventReports;
