import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import ToggleField from '../../../../components/inputs/toggleField';
import FieldModal from '../../../../components/modals/fieldModal';
class EditPointsModal extends React.Component {
	constructor(props) {
		super(props);
		const point = this.props.markedPoint;
		this.state = {
			customLabel: point.customLabel ? point.customLabel : point.actionName,
			description: point.description,
			pointSuffix: point.pointSuffix,
			pointValue: point.pointValue,
			maxPointsAllowed: point.maxPointsAllowed,
			oncePerReference: point.oncePerReference,
			active: point.active,
			callToActionLabel: point.callToActionLabel,
			callToActionUrl: point.callToActionUrl,
			submitting: false,
			invalid: false,
			success: false
		};
	}

	submitInteractionPoints = () => {
		const { orgId, eventId, markedPoint } = this.props;
		const data = {
			customLabel: this.state.customLabel,
			description: this.state.description,
			pointSuffix: this.state.pointSuffix,
			pointValue: this.state.pointValue,
			maxPointsAllowed: this.state.maxPointsAllowed,
			oncePerReference: this.state.oncePerReference,
			active: this.state.active,
			callToActionLabel: this.state.callToActionLabel,
			callToActionUrl: this.state.callToActionUrl
		};
		this.setState({ submitting: true });
		API()
			.patch(`Organizations/${orgId}/events/${eventId}/interactions/point/${markedPoint._id}`, data)
			.then((res) => {
				if (res.data) {
					this.props.editPoint({ ...res.data.interactionPoint, active: this.state.active });
					this.props.toggle();
				}
			});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			submitting,
			invalid,
			customLabel,
			description,
			pointSuffix,
			pointValue,
			maxPointsAllowed,
			oncePerReference,
			active,
			callToActionLabel,
			callToActionUrl
		} = this.state;

		return (

			<div>


				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Edit Interaction Point"}
					bodyHeaderText={'Points will be earned by attendees as they complete actions'}
					bodyDescription={''}
					overflowHidden={true}
					bodyContent={() => {
						return (
							<div>
								<div>
									<InputField label="Label" value={customLabel}
										onChange={(e) => this.setState({ customLabel: e.target.value })}
									/>
									<InputField
										label="Description"
										value={description}
										onChange={(e) => this.setState({ description: e.target.value })}
									/>

									<InputField
										label="Points Suffix"
										value={pointSuffix}
										onChange={(e) => this.setState({ pointSuffix: e.target.value })}
									/>

									<InputField
										label="Point Value"
										value={pointValue}
										onChange={(e) => this.setState({ pointValue: e.target.value })}
									/>

									<InputField
										label="Max Points Allowed"
										value={maxPointsAllowed}
										onChange={(e) => this.setState({ maxPointsAllowed: e.target.value })}
									/>

									<ToggleField
										label="Once Per Reference"
										checked={oncePerReference}
										icons={false}
										onChange={() =>
											this.setState({
												oncePerReference: !oncePerReference
											})}
									/>

									<InputField label="Call To Action Label" value={callToActionLabel} onChange={(e) => this.setState({ callToActionLabel: e.target.value })} />

									<InputField label="Call To Action Target" value={callToActionUrl} onChange={(e) => this.setState({ callToActionUrl: e.target.value })} />

									<ToggleField
										label="Active"
										checked={active}
										icons={false}
										onChange={() =>
											this.setState({
												active: !active
											})}
									/>
								</div>
								{invalid && <span style={{ color: 'red' }}>Group name is required</span>}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.submitInteractionPoints()}
					actionButtonDisabled={submitting}
				></FieldModal>





			</div>

		);
	}
}

export default EditPointsModal;
