import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ColumnFilter from './ColumnFilter';
import moment from 'moment';

class SectionTable extends Component {
	constructor(props) {
		super(props);
		this.sidebarScroll = React.createRef();
		this.contentScroll = React.createRef();
		this._preventEvent = false;
		this.contentLastScrollTop = 0;
		this.contentLastScrollLeft = 0;
		this.state = {
			//formatted as {label:'', key: '', type:''}
			columns: props.columns.map((c, i) => {
				return {
					...c,
					search: '',
					sortAsc: false,
					sortDesc: false
				};
			}),
			organizedData: props.data,
			searchData: props.data,
			checked: {}
		};
	}

	componentDidMount() {
		this.sortData(this.state.columns[0], true, false);
	}

	onSidebarScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}

		this._preventEvent = true;
		this.contentScroll.current.scrollTop = e.target.scrollTop;
	};

	onContentScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}

		if (e.target.scrollTop !== this.contentLastScrollTop) {
			this._preventEvent = true;
			this.sidebarScroll.current.scrollTop = e.target.scrollTop;
			this.contentLastScrollTop = e.target.scrollTop;
		}
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { organizedData, columns } = this.state;
		let sortedColumnIndex = -1;
		const updatedColumns = columns.map((col, i) => {
			if (col.key == column.key) {
				sortedColumnIndex = i;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});
		if (sortedColumnIndex >= 0) {
			updatedColumns[sortedColumnIndex].sortAsc = sortAsc;
			updatedColumns[sortedColumnIndex].sortDesc = sortDesc;
		}
		let reorganizedData = organizedData.sort((a, b) => {
			const columnType = column.type || typeof a[column.key];
			if (a[column.key] && b[column.key]) {
				switch (columnType) {
					case 'string':
						return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
					case 'number':
						return a[column.key] - b[column.key];
					case 'date':
						return new Date(a[column.key]).getTime() - new Date(b[column.key]).getTime();
					case 'boolean':
						const a1 = a[column.key] ? 'yes' : 'no';
						const b1 = b[column.key] ? 'yes' : 'no';
						return a1.localeCompare(b1);
				}
			} else if (a[column.key]) {
				return 1;
			}
			return -1;
		});
		reorganizedData = sortDesc ? reorganizedData.reverse() : reorganizedData;
		const searchedColumns = columns.filter((c) => c.search != '');
		this.setState(
			{
				organizedData: reorganizedData,
				columns: updatedColumns
			},
			() => {
				searchedColumns.forEach((sc) => this.searchData(sc, sc.search));
				this.props.updateColumnSorting(updatedColumns);
			}
		);
	};

	searchData = (column, search) => {
		const { organizedData, columns } = this.state;
		const searchColumns = [];
		const updatedColumns = columns.map((col, i) => {
			if (col.key == column.key) {
				col.search = search;
				searchColumns.push(col);
			} else if (col.search != '') {
				searchColumns.push(col);
			}
			return col;
		});
		const filteredData = organizedData.filter((od) => {
			let containsSearch = true;
			searchColumns.forEach((sc) => {
				if (od[sc.key] && typeof od[sc.key] == 'string') {
					containsSearch = containsSearch && od[sc.key].toLowerCase().includes(sc.search);
				} else if (od[sc.key] && typeof od[sc.key] == 'boolean') {
					const res = od[sc.key] ? 'yes' : 'no';
					containsSearch = containsSearch && res.includes(sc.search);
				}
			});

			return containsSearch;
		});
		this.setState({ searchData: filteredData, columns: updatedColumns }, () => {
			this.props.checkAllRows([]);
		});
	};

	RowItem(meta) {
		const { columns } = this.state;
		const { toggledColumns } = this.props;
		return (
			<div key={`row-${meta.id}`} className="tableColumnRow">
				{columns.map((c) => {
					const value =
						typeof meta[c.key] != 'undefined' &&
						(typeof meta[c.key] == 'boolean'
							? meta[c.key] ? 'Yes' : 'No'
							: c.type == 'date' ? moment(meta[c.key]).format('MM/DD/YYYY') : meta[c.key]);
					return (
						toggledColumns[c.key] && (
							<div className="tableColumnRowCell text-truncate calibreRegular" key={`cell-${c.key}`}>
								{value}
							</div>
						)
					);
				})}
			</div>
		);
	}

	render() {
		const { searchData, checkedCount, columns } = this.state;
		const {
			readOnly,
			mainColumnActive,
			toggledColumns,
			eventId,
			checked,
			checkRow,
			checkAllRows,
			deletedRows
		} = this.props;
		const mainColumn = columns[0];
		const allChecked = searchData.length === Object.keys(checked).length && Object.keys(checked).length > 0;
		return mainColumnActive ? (
			<div className="sectionTable">
				<div className="tableMainColumn">
					<div className="tableColumnHeader">
						<div className="tableMainColumnCell">
							{!readOnly && (
								<div
									className={`checkBox mr-10 ${allChecked ? 'checked' : ''}`}
									onClick={() => checkAllRows(allChecked ? [] : searchData.map((sd) => sd.id))}
								>
									<i className="las la-check" />
								</div>
							)}
							<div className="tableCellData">
								{checkedCount > 0 && <div className="tableSelectCount">({checkedCount})</div>}
								<div className="tableColumnTitle">{mainColumn.label}</div>
								<ColumnFilter
									column={mainColumn}
									sortData={this.sortData}
									searchData={this.searchData}
								/>
							</div>
						</div>
					</div>
					<div
						className="tableColumnRows hideScrollbar"
						ref={this.sidebarScroll}
						onScroll={this.onSidebarScroll}
					>
						{searchData.map((a) => {
							return (
								!deletedRows.includes(a.id) && (
									<div className="tableMainColumnRowCell" key={a.id}>
										{!readOnly && (
											<div
												className={`checkBox mr-10 ${checked[a.id] ? 'checked' : ''}`}
												onClick={() => checkRow(a.id)}
											>
												<i className="las la-check" />
											</div>
										)}
										<Link to={a.link ? a.link : ''} className="tableCellData">
											<div className="tableRowText">{a[mainColumn.key]}</div>
											<i className="las la-angle-right ml-a" />
										</Link>
									</div>
								)
							);
						})}
					</div>
				</div>
				<div className="mainColumnShadow" />
				<div className="tableSubColumns">
					<div className="tableColumnHeader">
						{columns.map(
							(c) =>
								toggledColumns[c.key] && (
									<div className="tableSubColumn" key={c.key}>
										<div className="tableColumnTitle">{c.label}</div>{' '}
										<ColumnFilter
											column={c}
											sortData={this.sortData}
											searchData={this.searchData}
										/>
									</div>
								)
						)}
					</div>
					<div className="tableColumnRows w-fc" ref={this.contentScroll} onScroll={this.onContentScroll}>
						{searchData.map((data) => !deletedRows.includes(data.id) && this.RowItem(data))}
					</div>
				</div>
			</div>
		) : (
			<div responsive className="sectionTable">
				<div className="tableSubColumns">
					<div className="tableColumnHeader">
						{columns.map(
							(c) =>
								toggledColumns[c.key] && (
									<div className="tableSubColumn">
										<div className="tableColumnTitle" title={c.label}>
											{c.label}
										</div>
									</div>
								)
						)}
					</div>
					<div className="tableColumnRows w-fc" ref={this.contentScroll} onScroll={this.onContentScroll}>
						{searchData.map((data) => !deletedRows.includes(data.id) && this.RowItem(data))}
					</div>
				</div>
			</div>
		);
	}
}

export default SectionTable;
