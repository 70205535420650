import React from 'react';
import { Table } from 'reactstrap';
import { Plus } from 'react-feather';
import AddModeratorModal from './modals/addModeratorModal';
import RemoveCheckedInModal from './modals/removeCheckedInModal';
import { last } from 'lodash';
import moment from 'moment';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
class EventSessionModerators extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email',
			checkinDate: 'Check In Date'
		};
		const columnTypes = {
			firstName: 'text',
			lastName: 'text',
			email: 'text',
			checkinDate: 'timestamp'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			addModeratorModalOpen: false,
			removeCheckedInModalOpen: false,
			markedCheckedIn: null,
			searching: false,
			searchText: '',
			toggledColumns: toggledColumns,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			checkedAttendee: [],
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	checkInItem(checkIn, index) {
		return (
			<tr key={index}>
				<td className="text-truncate">{checkIn.firstName}</td>
				<td className="text-truncate">{checkIn.lastName}</td>
				<td className="text-truncate">{checkIn.email}</td>
				<td className="text-truncate">{moment(checkIn.checkinDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
				<td className="text-truncate">
					<a style={{ color: 'red' }} onClick={() => this.toggleRemoveCheckedIn(checkIn)} className={''}>
						Remove
					</a>
				</td>
			</tr>
		);
	}

	toggleRemoveCheckedIn = () => {
		this.setState({
			removeCheckedInModalOpen: !this.state.removeCheckedInModalOpen
		});
	};

	toggleAddModerators = () => {
		this.setState({ addModeratorModalOpen: !this.state.addModeratorModalOpen });
	};

	toggleRemoveModerator = (moderator) => {
		this.setState({
			removeModeratorModalOpen: !this.state.removeModeratorModalOpen,
			markedCheckedIn: moderator || null
		});
	};

	removeModerator = (moderator) => {
		this.setState({ moderator: this.state.moderator.filter((m) => m._id != moderator._id) });
	};

	updatedCheckedAttendee = (checked) => {
		this.setState({ checkedAttendee: checked });
	};

	searchEntries = (searchText) => {
		this.setState({ searching: searchText != '' ? true : false, searchText: searchText ? searchText : '' });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};
	render() {
		const {
			addModeratorModalOpen,
			removeCheckedInModalOpen,
			markedCheckedIn,
			searching,
			searchText,
			columns,
			normalizedColumns,
			toggledColumns,
			selectedColumnSort,
			sortDesc
		} = this.state;
		const { checkins, moderators } = this.props.editedSession;
		const { attendees } = this.props.event;

		const checkInsList = checkins.filter((att) => attendees.find((at) => at._id == att.attendeeId)).map((a) => {
			const attendee = attendees.find((at) => at._id == a.attendeeId);
			if (attendee) {
				a.firstName = attendee.firstName;
				a.lastName = attendee.lastName;
				a.email = attendee.email;
				return a;
			}
		});

		let searchedEntries = checkInsList;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		if (selectedColumnSort) {
			searchedEntries = searchedEntries.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
		}

		let mainActions = [];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Delete Attendees',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleRemoveCheckedIn
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedAttendee).length;
		entriesLength = searching ? searchedEntries.length : checkInsList.length;
		uncheckEntries = () => {
			this.setState({ checkedAttendee: [] });
		};
		searchEnabled = true;

		return (
			< div className='sectionBody posRel mb-0 p-0' >
				{/* 	<Table responsive className="innerSectionTable">
					<thead>
						<tr>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th>Check In Date</th>
							<th />
						</tr>
					</thead>
					<tbody style={{}}>{checkInsList.map((checkIn, index) => this.checkInItem(checkIn, index))}</tbody>
				</Table> */}

				<ActionButtonContainer
					mainActions={mainActions}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>
				<ViewsTable
					title="Check Ins"
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={searchedEntries}
					classes="nested"
					mainColumnActive={false}
					checked={this.state.checkedAttendee}
					checkRow={(entryId) => {
						const checked = this.state.checkedAttendee;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedAttendee(checked);
						this.setState({
							checkedAttendee: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedAttendee;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedAttendee(checked);

						this.setState({
							checkedAttendee: checked
						});
					}}
					sortData={this.sortData}
				/>
				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
				/>
				{addModeratorModalOpen && (
					<AddModeratorModal
						isOpen={addModeratorModalOpen}
						toggle={this.toggleAddModerators}
						orgId={this.props.orgId}
						updateModerators={this.props.updateModerators}
						loadMoreAttendees={this.props.loadMoreAttendees}
						eventId={this.props.event._id}
						existingSpeakers={moderators}
						attendees={this.props.event.attendees}
						session={this.props.editedSession}
					/>
				)}
				{removeCheckedInModalOpen && (
					<RemoveCheckedInModal
						isOpen={removeCheckedInModalOpen}
						toggle={this.toggleRemoveCheckedIn}
						orgId={this.props.orgId}
						eventId={this.props.event._id}
						markedCheckedIn={markedCheckedIn}
						removeCheckedIn={this.props.removeCheckedIn}
						session={this.props.editedSession}
						checkedAttendee={this.state.checkedAttendee}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionModerators;
