import React, { Component } from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody } from 'reactstrap';

import Dropzone from 'react-dropzone';
import papa from 'papaparse';
import moment from 'moment';
import SelectField from '../../../../components/inputs/selectField';
const UNMAPPED_DATA = 'Unmapped Data';
const GROUP_DATA = 'Group Data';
let dropzoneRef;
class ImportScannablesModal extends Component {
    constructor(props) {
        super(props);
        const unmappedData = {};
        unmappedData[UNMAPPED_DATA] = {};
        this.state = {
            files: [],
            accepted: [],
            rejected: [],
            submitting: false,
            invalid: false,
            mapping: false,
            mappedData: {
                Category: {
                    Header: ['data1']
                }
            },
            unmappedData: unmappedData,
            fieldOptions: [],
            importedScannables: [],
            finished: false,
            importCount: 0,
            importLength: 0,
            nameExists: false,
            urlExists: false,
            checked: [],
            filteredScannables: [],
            handledDuplicates: false,
            importedStarted: false,
            dupSelectedCount: 0,
            importError: false,
            currentSection: 0,
            sectionCount: 4,
            isError: false,
            isInvalidPoints: false,
            invalidPoints: [],
            customFields: [
                {
                    title: 'General Information',
                    _id: 0,
                    fields: [
                        { fieldName: 'Name', fieldType: 'text', _id: 'name' },//required
                        { fieldName: 'Destination Url', fieldType: 'text', _id: 'url' },//required
                        { fieldName: 'Associated Points', fieldType: 'text', _id: 'pointCategory' },
                        { fieldName: 'Points Redeemed Success Message', fieldType: 'text', _id: 'successMessage' },
                        { fieldName: 'Points Already Redeemed Message', fieldType: 'text', _id: 'errorMessage' },
                        { fieldName: 'Active', fieldType: 'checkbox', _id: 'active' },
                    ]
                },
            ],
        };
    }

    cancelImport = () => {
        const unmappedData = {};
        unmappedData[UNMAPPED_DATA] = {};
        this.setState({
            submitting: false,
            invalid: false,
            mapping: false,
            mappedData: {
                Category: {
                    Header: ['data1']
                }
            },
            unmappedData: unmappedData,
            fieldOptions: [],
            importedScannables: [],
            finished: false,
            importCount: 0,
            importLength: 0,
            importedGroups: [],
            importingGroups: false,
            isDuplicate: false,
            emailExists: false,
            firstNameExists: false,
            checked: [],
            filteredScannables: [],
            handledDuplicates: false,
            importedStarted: false,
            dupSelectedCount: 0,
            importError: false,
            loading: true,
            isInvalidPoints: false,
            invalidPoints: [],
        });
    };

    componentDidMount() {
        const { eventId, orgId } = this.props;
        API().get(`Organizations/${orgId}/events/${eventId}/interactions`).then((res) => {
            if (res.data) {
                let indexPoints = {};
                res.data.points.forEach((p) => {
                    indexPoints[p.actionName] = p;
                });
                this.setState({
                    points: res.data.points,
                    indexPoints: indexPoints,
                    loading: false
                });
            }
        });
    }


    downloadCSVTemplate = () => {
        const mappedCategories = [];
        const mappedFieldNames = [];
        const mappedFieldTypes = [];
        this.state.customFields.forEach((category) => {
            category.fields.forEach((field) => {
                const fieldName = field.fieldName;
                mappedCategories.push(category.title);
                mappedFieldTypes.push(field.fieldType);
                mappedFieldNames.push(fieldName);
            });
        });
        const csv = papa.unparse([mappedFieldNames]);
        const filename = `QRCodesImportTemplate.csv`;
        var file = new Blob([csv], { type: 'text/csv' });
        if (
            window.navigator.msSaveOrOpenBlob // IE10+
        )
            window.navigator.msSaveOrOpenBlob(file, filename);
        else {
            // Others
            var a = document.createElement('a'),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    };

    handleImport = () => {
        const { mappedData } = this.state;
        const scannables = [];
        Object.keys(mappedData).forEach((category) => {

            if (mappedData[category]._id === 0) {
                Object.keys(mappedData[category]).forEach((field) => {
                    if (field !== '_id') {
                        const data = mappedData[category][field];

                        data.forEach((scanData, index) => {
                            if (index > 0) {
                                if (!scannables[index]) {
                                    scannables[index] = {};
                                }
                                scannables[index][data[0].value] = scanData;
                                scannables[index]['rowNumber'] = index + 1;
                            }
                        });
                    }
                });
            }
        });

        const filteredScannables = scannables.filter((scannable) => scannable);

        this.setState(
            {
                submitting: true,
                mapping: false,
                importLength: filteredScannables.length,
                filteredScannables: filteredScannables
            },
            () => {
                this.validateData();
            }
        );
    };


    uploadScannables = (scannables) => {
        const { eventId, orgId } = this.props;
        const { importedScannables } = this.state;
        this.setState({ importedStarted: true, submitting: true });
        let count = 0;
        let successfulScannables = [];
        scannables.forEach(async (scannable, index) => {
            if (scannable.textMessageOptIn) {
                scannable.textMessageOptIn = scannable.textMessageOptIn.toLowerCase() === 'yes' ? true : false;
            }
            if (scannable.valid) {
                scannable.valid = scannable.valid.toLowerCase() === 'yes' ? true : false;
            }
            if (scannable.pointCategory) {
                scannable.pointCategory = this.state.indexPoints[scannable.pointCategory]._id;
            }
            scannable.contentType = 'link';

            API().post(`Organizations/${orgId}/events/${eventId}/scannable`, scannable).then((res) => {
                if (res.data) {
                    successfulScannables.push(res.data);
                    count++;
                    importedScannables.push({ ...res.data, success: true });
                    this.setState({
                        importCount: this.state.importCount + 1,
                        importedScannables: importedScannables,
                    });

                    if (count === scannables.length) {
                        this.props.updateScannables(successfulScannables, () => {
                        });
                        this.setState({ finished: true });
                    }
                }
            }).catch((e) => {
                console.log(e);
            });


        })


    }

    validateData = () => {
        const { mappedData, unmappedData } = this.state;
        let fa = this.state.filteredScannables;

        let invalidPoints = [];
        let isInvalidPoints = false;


        if (mappedData['General Information'] && mappedData['General Information']['Associated Points']) {
            mappedData['General Information']['Associated Points'].forEach((o, index) => {

                if (index > 0) {
                    if (!this.state.indexPoints[o] && o !== '') {
                        isInvalidPoints = true;
                        invalidPoints.push({ name: mappedData['General Information']['Name'][index], rowNumber: index + 1, pointCategory: o });
                    }
                }
            }
            );
        }

        let isError = false;
        this.isRequiredMapped(() => {
            isError = !this.state.nameExists || !this.state.urlExists || isInvalidPoints;
            this.setState({
                importLength: fa.length,
                submitting: false,
                isError: isError,
                invalidPoints: invalidPoints,
                isInvalidPoints: isInvalidPoints,
            }, () => {

                if (!isError) {

                    this.uploadScannables(this.state.filteredScannables);
                }
            });
        })

    };

    isRequiredMapped = (callback) => {
        const { mappedData, unmappedData } = this.state;
        let nameExists = false;
        let urlExists = false;


        if (mappedData['General Information']) {
            if (mappedData['General Information']['Name']) {
                nameExists = true;
            }
            if (mappedData['General Information']['Destination Url']) {
                urlExists = true;
            }


        }

        this.setState({
            nameExists: nameExists,
            urlExists: urlExists,
        }, () => {
            callback && callback();
        });

    };

    onDrop = (files) => {
        papa.parse(files[0], {
            complete: (res) => {
                try {
                    /* Remove Trailing Whitespace */
                    const trimmedData = res.data.map(subArray =>
                        subArray.map(element => element.trim())
                    );
                    const data = this.mapData(trimmedData);
                    const mappedData = data.mappedData;
                    const unmappedData = data.unmappedData;
                    const fieldData = this.state.customFields.map((category) => {
                        const title = category.title;
                        const headers = category.fields.map((header) => {
                            const name = header.fieldName;
                            return {
                                _id: header._id,
                                category: title,
                                categoryId: category._id,
                                value: name,
                                label: name
                            };
                        });
                        return {
                            label: title,
                            value: category._id,
                            options: headers
                        };
                    });


                    if (!this.state.isDuplicate) {
                        this.setState({
                            mapping: true,
                            mappedData: mappedData,
                            unmappedData: unmappedData,
                            fieldOptions: fieldData
                        }, () => {
                            this.isRequiredMapped();
                        });
                    }
                } catch (e) {
                    console.log(e);
                    this.setState({ importError: true });
                }
            }
        });
        this.setState({
            files
        });
    };

    mapData = (csvData) => {
        const { customFields } = this.state;
        const csvHeaders = csvData[0];
        const mappedHeaders = [];
        const mappedData = {};
        let usedFields = [];
        customFields.forEach((category) => {
            const categoryTitle = category.title;
            mappedData[categoryTitle] = {};
            mappedData[categoryTitle]._id = category._id;
            category.fields.forEach((field) => {
                let fieldName = field.fieldName;
                if (!usedFields.includes(fieldName)) {
                    mappedData[categoryTitle][fieldName] = [{ label: fieldName, value: field._id }];

                    let count = -1;
                    const headerIndex = csvHeaders.findIndex((csvHeader) => {
                        count++;
                        if (csvHeader.toLowerCase() == fieldName.toLowerCase()) {
                            //fieldName = csvHeader;git
                            if (!usedFields.find((o) => o.fieldName == fieldName && o.index == count)) {
                                usedFields.push({ fieldName: fieldName, index: count });
                                return true;
                            }
                        }
                        return false;
                    });
                    if (headerIndex > -1) {
                        mappedData[categoryTitle][fieldName][0].headerIndex = headerIndex;
                        csvData.forEach((row, index) => {
                            if (index > 0) {
                                if (!mappedData[categoryTitle][fieldName][index]) {
                                    mappedData[categoryTitle][fieldName][index] = [];
                                }
                                let value = null;
                                switch (field.fieldType) {
                                    case 'number':
                                        value = Number.parseFloat(row[headerIndex]);
                                        break;
                                    case 'date':
                                        value = row[headerIndex] ? moment(row[headerIndex]).format('YYYY-MM-DD') : '';
                                        break;
                                    case 'checkbox':
                                        value = row[headerIndex].toLowerCase() === 'yes' ? true : false;
                                        break;
                                    default:
                                        value = row[headerIndex];
                                }
                                mappedData[categoryTitle][fieldName][index] =
                                    typeof value == 'string' ? value.trim() : value;
                            }
                        });
                    }
                    if (mappedData[categoryTitle][fieldName].length < 2) {
                        delete mappedData[categoryTitle][fieldName];
                    }
                }
            });
            if (Object.keys(mappedData[categoryTitle]).length < 2) {
                delete mappedData[categoryTitle];
            }
        });

        const unmappedData = {};
        unmappedData[UNMAPPED_DATA] = {};
        csvHeaders.forEach((header, headerIndex) => {
            if (!usedFields.find((f) => f.fieldName == header && f.index == headerIndex)) {
                unmappedData[UNMAPPED_DATA][header.concat('-', headerIndex.toString())] = [
                    {
                        label: header,
                        value: header,
                        headerIndex: headerIndex
                    }
                ];
                csvData.forEach((row, index) => {
                    if (index > 0) {
                        unmappedData[UNMAPPED_DATA][header.concat('-', headerIndex.toString())][index] =
                            row[headerIndex];
                    }
                });
            }
        });
        if (mappedData['General Information']) {
            this.setState({
                emailExists: Object.keys(mappedData['General Information']).includes('Email'),
                firstNameExists: Object.keys(mappedData['General Information']).includes('First Name')
            });
        } else {
            this.setState({ emailExists: false, firstNameExists: false });
        }

        return { mappedData, unmappedData };
    };

    formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    FieldContainer = (category, header, columnData, index, size, isUnmapped) => {
        const selectedOption = {
            value: header,
            label: header
        };


        const dataPreview = JSON.parse(JSON.stringify(columnData));
        let data = '';
        for (let dataCount = 1; dataCount < dataPreview.length; dataCount++) {
            let current = dataPreview[dataCount];
            if (current) {
                data = current;
                break;
            }
        }
        return (
            <div className="w-100 mb-20" key={index}>
                <div className='flex aic jcsb'>
                    <div className='flex '>



                        <div
                            className='w-100'
                            style={{
                                maxWidth: 283,
                                minWidth: 283,
                                width: 283,
                                border: isUnmapped ? `1px solid var(--danger)` : `1px solid var(--border)`,
                                borderRadius: 5,
                                padding: '3px 17px',
                                height: 40
                            }}>
                            <p>{columnData[0].label}</p>
                            <p style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                                className='small grey'>Ex: {data ? data : 'Blank column'}</p>
                        </div>

                    </div>
                    <i className="flex jcc w-100 las la-arrow-right mr-5 ml-5"
                        style={{
                            color: category !== UNMAPPED_DATA && category !== GROUP_DATA ? 'var(--primary)' : '',
                        }}
                    />
                    <div className="w-100 mb-a">

                        <SelectField
                            label=""
                            style={{
                                maxWidth: 283,
                                minWidth: 283,
                                width: 283
                            }}
                            classes=" neu mb-0 "
                            value={category != UNMAPPED_DATA && selectedOption}
                            options={this.state.fieldOptions}
                            formatGroupLabel={this.formatGroupLabel}
                            onChange={(data) => {
                                if (category != UNMAPPED_DATA) {
                                    const mappedData = this.state.mappedData;
                                    columnData[0] = { label: data.label, value: data._id };
                                    if (!mappedData[data.category]) {
                                        mappedData[data.category] = [];
                                        mappedData[data.category][data.label] = columnData;
                                        delete mappedData[category][header];
                                    } else if (!mappedData[data.category][data.label]) {
                                        mappedData[data.category][data.label] = columnData;
                                        delete mappedData[category][header];
                                    }
                                    this.setState({ mappedData: mappedData }, () => this.isRequiredMapped());
                                } else {
                                    const mappedData = this.state.mappedData;
                                    const unmappedData = this.state.unmappedData;
                                    columnData[0] = { label: columnData[0].label, value: data._id };
                                    if (!mappedData[data.category]) {
                                        mappedData[data.category] = {};
                                        mappedData[data.category]._id = data.categoryId;
                                        mappedData[data.category][data.label] = columnData;
                                        delete unmappedData[category][header];
                                    } else if (!mappedData[data.category][data.label]) {
                                        mappedData[data.category][data.label] = columnData;
                                        delete unmappedData[category][header];
                                    }

                                    this.setState({ mappedData: mappedData, unmappedData: unmappedData }, () =>
                                        this.isRequiredMapped()
                                    );
                                }
                            }}
                            additionalSelectElement={
                                category != UNMAPPED_DATA || category == GROUP_DATA ? (
                                    <div
                                        className='c-pointer'
                                        style={{
                                            position: 'absolute',
                                            zIndex: 999,
                                            right: 30,
                                            top: 10
                                        }}
                                        onClick={() => {
                                            const mappedData = this.state.mappedData;
                                            const unmappedData = this.state.unmappedData;
                                            const propertyName = columnData[0].label.concat('-', columnData[0].headerIndex);
                                            if (!unmappedData[UNMAPPED_DATA]) {
                                                unmappedData[UNMAPPED_DATA] = [];
                                                delete mappedData[category][header];
                                                unmappedData[UNMAPPED_DATA][propertyName] = columnData;
                                            } else if (!unmappedData[UNMAPPED_DATA][propertyName]) {
                                                delete mappedData[category][header];
                                                unmappedData[UNMAPPED_DATA][propertyName] = columnData;
                                            } else if (unmappedData[UNMAPPED_DATA][propertyName]) {
                                                delete mappedData[category][header];
                                            }
                                            if (Object.keys(mappedData[category]).length < 2) {
                                                delete mappedData[category];
                                            }
                                            this.setState({ mappedData: mappedData, unmappedData: unmappedData }, () => {
                                                this.isRequiredMapped();
                                            });

                                        }}
                                    >
                                        <i class="lar la-times-circle grey"></i>
                                    </div>
                                ) : null
                            }
                        />

                    </div>
                </div>
            </div>
        );
    };


    skipInvalidPoint = (pointCategoryObj) => {
        const { invalidPoints } = this.state;

        const removed = invalidPoints.filter((p) => p.rowNumber !== pointCategoryObj.rowNumber);

        let scannables = this.state.filteredScannables.filter((a) => a.rowNumber !== pointCategoryObj.rowNumber);


        this.setState({ invalidPoints: removed, filteredScannables: scannables, importLength: scannables.length })
    }

    render() {
        const { isOpen, toggle } = this.props;
        const {
            submitting,
            mapping,
            mappedData,
            unmappedData,
            finished,
            importLength,
            importedScannables,
            nameExists,
            urlExists,
            importedStarted,
            isError,
            isInvalidPoints,
            invalidPoints
        } = this.state;
        const hasUnmappedData = Object.keys(unmappedData[UNMAPPED_DATA]).length > 0;
        let index = 0;
        let isButtonDisabled = false;
        isButtonDisabled = (!nameExists || !urlExists || invalidPoints.length > 0)  // handles the case where the user has not mapped name, url, or pointCategory
            || importedStarted; //import has started

        return (
            <Modal isOpen={isOpen} toggle={toggle} className="import importModal" >
                <div className="modalHeader pb-0">

                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
                    <div className="headerContainer">
                        <h1>Import QR Codes Data</h1>
                    </div>
                </div>

                <ModalBody className="modalBody " >
                    {!importedStarted && mapping ? (
                        <div className={`sectionBody w-100 anim left`}
                            style={{
                                maxHeight: 400
                            }}
                        >
                            <div className='mb-20'>
                                <h4 className='mb-10'>Choose where your QR code data will go by mapping it to the relevant columns.</h4>
                            </div>
                            <div className='scrollContainer'>


                                {(!nameExists || !urlExists) && (
                                    <p style={{ color: 'red' }}>
                                        Name and Destination Url are required and has not been mapped. Please map.
                                    </p>
                                )}
                                {hasUnmappedData &&
                                    <div>
                                        <div className='flex mb-10'>
                                            <h3 style={{ width: '50%' }}>Unmapped Data</h3>
                                            <h3 className='ml-25' style={{ width: '50%' }}>QR Code Column</h3>
                                        </div>
                                    </div>}
                                {hasUnmappedData && (<div className="importedDataRow">
                                    {Object.keys(unmappedData[UNMAPPED_DATA]).map((dataKey) =>
                                        this.FieldContainer(
                                            UNMAPPED_DATA,
                                            dataKey,
                                            unmappedData[UNMAPPED_DATA][dataKey],
                                            index++,
                                            true,
                                            true
                                        )
                                    )}
                                </div>
                                )}


                                {Object.keys(mappedData).map((key) => {
                                    if (key !== '_id') {
                                        const categoryData = mappedData[key];
                                        return (
                                            <div key={key}>
                                                <div>
                                                    <div className='flex mb-10'>
                                                        <h3 style={{ width: '50%' }}>{key}</h3>

                                                        <h3 style={{ width: '50%', marginLeft: 50 }}>QR Code Data</h3>
                                                    </div>
                                                </div>
                                                <div className="importedDataRow" style={{ paddingRight: 2 }}>
                                                    {Object.keys(categoryData).map(
                                                        (dataKey) =>
                                                            dataKey !== '_id' &&
                                                            this.FieldContainer(
                                                                key,
                                                                dataKey,
                                                                categoryData[dataKey],
                                                                index++,
                                                                hasUnmappedData
                                                            )
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }
                                })}

                            </div>
                        </div>
                    ) : !importedStarted && isError && (isInvalidPoints) ? (
                        <div className={`sectionBody w-100  anim left ml-10`}>
                            {!isButtonDisabled ? <div className='text-c mb-40 mt-20'>
                                <h3 className='mb-10'>All Errors Resolved</h3>
                                <p className='mb-10'>
                                    You may begin to import your data.
                                </p>
                            </div> : <div className='text-c mb-40 mt-20'>
                                <h3 className='mb-10'>Errors found in data</h3>
                                <p className='mb-10'>
                                    Please review and make the necessary changes to continue importing.
                                </p>
                            </div>}
                            {isInvalidPoints && invalidPoints.length > 0 && (
                                <div className="sectionBlock m-a">
                                    <h3>Invalid QR Codes</h3>
                                    <p className='mb-10'>
                                        You may skip the invalid QR codes or correct them and re-import.
                                    </p>
                                    <div className="sectionTable mb-20" style={{ maxHeight: 300 }}>
                                        <div className="tableSubColumns" style={{ overflow: 'hidden' }}>
                                            <div className="tableColumnHeader">
                                                <div style={{ width: 50 }} >
                                                    <h3>Row #</h3>
                                                </div>
                                                <div className="tableSubColumnNoSort ">
                                                    <div className="tableCellData">
                                                        <h3>Name</h3>
                                                    </div>
                                                </div>
                                                <div className="tableSubColumnNoSort ">
                                                    <div className="tableCellData">
                                                        <h3>Point Category</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tableColumnRows w-fc">
                                                {invalidPoints.map((o) => {
                                                    return (
                                                        <div key={index} className="tableColumnRow" style={{ borderBottom: 'none' }}>
                                                            <div style={{ width: 50 }}>{o.rowNumber}
                                                            </div>
                                                            <div className="tableColumnRowCell text-truncate ">{o.name}
                                                            </div>
                                                            <div className="tableColumnRowCell text-truncate ">{o.pointCategory}
                                                            </div>

                                                            <button
                                                                onClick={() => {
                                                                    this.skipInvalidPoint(o);
                                                                }}
                                                                className="neu min"
                                                            >
                                                                Skip
                                                            </button>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    ) : (
                        <div className={`sectionBody w-100 anim left`} >
                            {!importedStarted && !submitting ? (
                                <div className='flex flex-wrap column w-100'>

                                    <div className='mb-20'>
                                        <h4 className='mb-10'>Import QR code data from a spreadsheet to the simple.events workspace.</h4>
                                    </div>

                                    <Dropzone
                                        accept="text/csv"
                                        className="clickable dropzone-details banner mb-10"
                                        style={{ height: 375 }}
                                        ref={(node) => {
                                            dropzoneRef = node;
                                        }}
                                        onDrop={this.onDrop.bind(this)}
                                    >
                                        <div>
                                            <div className="addIcon">
                                                <i className="las la-plus"></i>
                                            </div>
                                            <p className='text-c'>
                                                Add QR Codes CSV File<br />
                                                or <span style={{ color: '#2B83F1' }}>Browse</span>
                                            </p>
                                        </div>
                                    </Dropzone>

                                    <div className='mb-40 mt-40 mt-20'>
                                        <h3>
                                            Need help getting started?
                                        </h3>
                                        <p>
                                            Make sure the data contains columns for Name and Destination Url
                                        </p>
                                        <p>
                                            <a className='click' onClick={this.downloadCSVTemplate}> Download </a> a sample CSV file
                                        </p>
                                    </div>
                                </div>
                            ) : importedStarted && (
                                <div className="flex column aic pt-20 h-100">
                                    <h2>
                                        {finished ? (
                                            'Importing Complete'
                                        ) : !importedStarted ? (
                                            'You may Import'
                                        ) : (
                                            importedStarted && 'Importing QR Codes...'
                                        )}
                                    </h2>

                                    {importedStarted && (
                                        <p className="mb-20">
                                            {importedScannables.length} of {importLength} QR Code{importLength == 1 ? '' : 's'}{' '}
                                            imported
                                        </p>
                                    )}

                                    <div className='sectionBlockAttendees'>

                                        <div className='sectionBlock'>
                                            <h3 className="pt-20">Imported QR Codes</h3>
                                            {importedScannables.map((ia) => (
                                                <div className="importRow ">
                                                    <div className='w-100'
                                                        style={{
                                                            maxWidth: '50%',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>{ia.name}</div>
                                                    <div className='w-100'
                                                        style={{
                                                            maxWidth: '50%',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                        {ia.success ? (
                                                            <i className="las la-check-circle green mr-10" />
                                                        ) : (
                                                            <i className="las la-times-circle red mr-10" />
                                                        )}
                                                        {ia.success ? 'Imported' : ia.error}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </ModalBody>
                <div className={`flex mt-20 ${importedStarted ? 'mt-20' : ''}`}>
                    <button
                        onClick={submitting && !finished ? this.cancelImport : this.props.toggle}
                        outline
                        className={`neu ml-a mr-15 `}
                    >
                        {finished ? 'Close' : 'Cancel'}
                    </button>
                    <button
                        className={`neu  ${isButtonDisabled ? 'disabled' : 'prim'}`}

                        onClick={() => {
                            if (isError) {
                                if (isInvalidPoints && invalidPoints.length === 0) {
                                    this.uploadScannables(this.state.filteredScannables);
                                }
                            } else {
                                this.handleImport();
                            }
                        }
                        }
                    >

                        {(!submitting || invalidPoints) && importedScannables.length == 0 ? (
                            'Add'
                        ) : finished ? (
                            'Done'
                        ) : (
                            'Importing QR Codes...'
                        )}
                    </button>

                </div>
            </Modal >
        );
    }
}

export default ImportScannablesModal;

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center'
};
