import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import AddPageModal from './modals/addPageModal';
import CopyPageModal from './modals/copyPageModal';
import RenamePageModal from './modals/renamePageModal'

import ViewsContainer from '../../../components/views/ViewsContainer';
import LoadingWizard from '../../../components/spinner/wizard';
import EventPage from './page';
import Iframe from 'react-iframe';
import FieldModal from '../../../components/modals/fieldModal';
import MobileAppConfigModal from './modals/mobileAppConfigModal';
import SectionHeader from '../../../components/views/SectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import DeleteSinglePageModal from './modals/deleteSinglePageModal';
import DeletePageModal from './modals/deletePageModal';
import PagesTemplateCenter from './modals/pagesTemplateCenter';
import PageGrid from '../../../components/tables/PageGrid';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
let batchCallback;
class EventPages extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			title: 'Title',
			description: 'Description',
			isSessionPage: 'Is Session Page',
			openToPublic: 'Is Open to Public',
			slug: 'Address',
			status: 'Status',
			url: 'URL'
		};
		const defaultFieldTypes = {
			title: 'text',
			description: 'text',
			isSessionPage: 'boolean',
			openToPublic: 'boolean',
			slug: 'text',
			status: 'text',
			url: 'link'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(customFieldColumns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: customFieldColumns[cfc],
				key: cfc,
				value: cfc,
				type: (defaultFieldTypes && defaultFieldTypes[cfc]) || typeof defaultFieldTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});


		delete toggledColumns[Object.keys(defaultFieldTypes)[0]];


		const sessionTemplateCustomFieldColumns = {
			title: 'Title',
			description: 'Description',
			isSessionPage: 'Is Session Page',
			openToPublic: 'Is Open to Public',
			slug: 'Address',
			status: 'Status',
		};
		const sessionTemplateDefaultFieldTypes = {
			title: 'text',
			description: 'text',
			isSessionPage: 'boolean',
			openToPublic: 'boolean',
			slug: 'text',
			status: 'text',
		};

		const sessionTemplateToggledColumns = {};
		const sessionTemplateNormalizedColumns = Object.keys(sessionTemplateCustomFieldColumns).map((cfc) => {
			sessionTemplateToggledColumns[cfc] = true;
			return {
				label: sessionTemplateCustomFieldColumns[cfc],
				key: cfc,
				value: cfc,
				type: (sessionTemplateDefaultFieldTypes && sessionTemplateDefaultFieldTypes[cfc]) || typeof sessionTemplateDefaultFieldTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});


		delete sessionTemplateToggledColumns[Object.keys(sessionTemplateDefaultFieldTypes)[0]];


		this.state = {
			pages: [],
			loading: true,
			checkedPages: [],
			focusedPages: [],
			focusedPage: false,
			addPageModalOpen: false,
			isDeleting: false,
			isDeletingFocused: false,
			pageViews: [],
			normalizedColumns: normalizedColumns,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			toggledColumns: toggledColumns,
			sessionTemplateNormalizedColumns: sessionTemplateNormalizedColumns,
			sessionTemplateColumns: sessionTemplateCustomFieldColumns,
			sessionTemplateColumnTypes: sessionTemplateDefaultFieldTypes,
			sessionTemplateToggledColumns: sessionTemplateToggledColumns,
			DeletePagesModal: false,
			privateHomePageSlug: '',
			publicHomePageSlug: '',
			registrationPagePathSlug: '',
			copyPagesModalOpen: false,
			toggleRenamePage: false,
			deletedPages: [],
			templates: [],
			templatesHidden: true,
			hoverOn: null,
			selectedSection: 0,
			searchText: '',
			selectedRow: '',
			toggleDuplicatePage: false,
			mobileAppConfigOpen: false,
			toggleSingleDeletePageModal: false,
			toggleFocusedDeletePageModal: false,
			toggleDeletePageModal: false,
			isGridLayout: false

		};
	}

	async componentWillMount() {
		const { orgId, eventId } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/pages`).then((res) => {
			if (res.data) {


				this.setState({
					privateHomePageSlug: this.props.event.defaultUserPath,
					publicHomePageSlug: this.props.event.defaultPublicPath,
					registrationPagePathSlug: this.props.event.registrationPageSlug,
					pages: res.data.pages.map((p) => {
						p.link = `/${orgId}/events/${eventId}/pages/${p._id}`;
						return p;
					}),
					pageViews: res.data.pageViews,
					loading: false
				});
			}
		});
	}
	deletePages = async () => {
		const { eventId, orgId, event } = this.props;
		const { pages, checkedPages, toggleSingleDeletePageModaly, } = this.state;
		const isPublic = event.eventType == "Public" ? true : false;
		let filteredSelected = pages.filter(
			(p) =>
				Object.keys(checkedPages).includes(p._id) &&
				(this.state.privateHomePageSlug === p.slug ||
					this.state.publicHomePageSlug === p.slug ||
					(this.state.registrationPagePathSlug === p.slug && isPublic)
				)
		);

		if (filteredSelected.length == 0) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/pages/delete`, { pages: Object.keys(checkedPages) })
				.then((res) => {
					const pages = this.state.pages.filter((p) => !Object.keys(this.state.checkedPages).includes(p._id));
					this.setState({
						pages: pages,
						checkedPages: {},
						isDeleting: false,
						focusedPage: false,
					}, () => {
						this.props.refreshSectionCounts()
						batchCallback && batchCallback('delete');
						this.toggleDeletePages();
						this.props.updatePagesPathOp(pages)
					});


				})
				.catch((e) => { });
		}
		else {
			this.toggleSingleDeletePageModaly();
		}
	};

	/* New---Delete Focus pages  */
	deleteFocusedPages = async () => {
		const { eventId, orgId } = this.props;
		const { pages, focusedPages, toggleFocusedDeletePageModal } = this.state;

		let filteredSelected = pages.filter(
			(p) =>

				Object.keys(focusedPages).includes(p._id) &&
				(this.state.privateHomePageSlug === p.slug ||
					this.state.publicHomePageSlug === p.slug ||
					this.state.registrationPagePathSlug === p.slug)
		);



		if (filteredSelected.length == 0) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/pages/delete`, { pages: Object.keys(focusedPages) })
				.then((res) => {
					const pages = this.state.pages.filter((p) => !Object.keys(this.state.focusedPages).includes(p._id));

					this.setState({

						pages: pages,
						focusedPages: {},
						focusedPage: false,
						isDeletingFocused: false,
						checkedPages: {}
					}, () => {
						this.props.refreshSectionCounts()
						batchCallback && batchCallback('delete');
						this.props.updatePagesPathOp(pages)
						this.toggleFocusedDeletePageModal();
					});


				})
				.catch((e) => { });
		}
		else {
			this.toggleFocusedDeletePageModal();
		}
	};

	/* End---Delete Focused Pages  */

	toggleDeletePages = () => {
		const { toggleSingleDeletePageModal } = this.state;

		this.setState({ toggleSingleDeletePageModal: !toggleSingleDeletePageModal });
	}

	toggleFocusedDeletePages = () => {
		const { toggleFocusedDeletePageModal } = this.state;

		this.setState({ toggleFocusedDeletePageModal: !toggleFocusedDeletePageModal });
	}

	updateDeletePage = (pageId, callback) => {
		const { page } = this.state;

		let updatedPages = page.filter((p) => p.pageId !== pageId);

		this.setState(
			{
				pages: updatedPages,
				sortedPages: updatedPages,
				deletedPages: [...this.state.deletedPages, pageId]
			},
			() => {
				batchCallback && batchCallback('delete');
				this.props.refreshSectionCounts();
				callback && callback()

			}
		);
	};

	checkEntry = (entryId) => {
		const checked = this.state.checkedPages;
		checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
		this.setState({
			checkedPages: checked
		});
	};


	deleteSinglePage = (pageId, callback) => {
		const { pages } = this.state;

		let updatedPages = pages.filter((p) => p._id !== pageId);
		this.setState({ pages: updatedPages, toggleDeletePageModal: false, checkedPages: {} }, () => {
			callback && callback()
		});
	}


	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	checkAllEntries = (pageIds) => {
		let checked = this.state.checkedPages;
		pageIds.length > 0
			? pageIds.forEach((pageId) => {
				checked[pageId] = true;
			})
			: (checked = {});

		this.setState({
			checkedPages: checked,
		});
	};

	uncheckEntries = () => {
		this.setState({
			checkedPages: {}
		});

	}

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		let nc = normalizedColumns;
		nc = nc.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: nc,
			selectedColumnSort: column,
			sortDesc: sortDesc == false ? true : false
		});
	};

	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};

	updatePage = (pUpdate) => {
		let tempPage = this.state.pages;
		tempPage.map(object => {

			if (pUpdate._id == object._id) {
				Object.assign(object, pUpdate);
			}
			return object;
		})
	}

	updateSinglePage = (pUpdate, callback) => {

		let tempPages = this.state.pages;
		tempPages.map(object => {

			if (this.state.selectedRow == object._id) {
				Object.assign(object, pUpdate);
				this.props.updatePathOp(object)
			}
			return object;
		})
		this.setState({ pages: tempPages }, () => {

			callback && callback();
		})
	}



	updateAddCopy = (newPage, callback) => {
		const { eventId, orgId } = this.props;
		const { pages, page } = this.state;
		let existingArray = this.state.pages;
		//pages.link = `/${this.props.orgId}/events/${eventId}/pages/${page.id}/0`;
		newPage.link = `/${orgId}/events/${eventId}/pages/${newPage._id}`;
		existingArray.push(newPage);
		this.setState({ pages: existingArray }, () => {
			this.props.refreshSectionCounts()
			callback && callback();
		})
	}


	/* updatePage = async (updatedPage, callback) => {
		this.setState({ editedPage: updatedPage, page: { ...updatedPage } });
		callback && callback();
	}; */

	toggleCopyPages = () => {
		this.setState({ copyPagesModalOpen: !this.state.copyPagesModalOpen });
	};
	toggleSingleDeletePageModal = () => {
		this.setState({ toggleSingleDeletePageModal: !this.state.toggleSingleDeletePageModal, });
	};

	toggleFocusedDeletePageModal = () => {
		this.setState({ toggleFocusedDeletePageModal: !this.state.toggleFocusedDeletePageModal, });
	};
	toggleRenamePageModal = (pageId) => {
		const { toggleRenamePage } = this.state;
		this.setState({ toggleRenamePage: !toggleRenamePage, selectedRow: pageId });
	};

	toggleAddPages = () => {
		this.setState({ addPageModalOpen: !this.state.addPageModalOpen });
	};

	toggleLayout = (selected) => {
		this.setState({ isGridLayout: selected });
	};

	render() {
		const { event, orgId, eventId } = this.props;
		const {
			addPageModalOpen,
			pages,
			copyPagesModalOpen,
			copyPageId,
			deletedPages,
			deletePagesModalOpen,
			loading,
			pageViews,
			isDeleting,
			isDeletingFocused,
			columns,
			checkedPages,
			columnTypes,
			templatesHidden,
			templates,
			hoverOn,
			selectedSection,
			toggledColumns,
			toggleDeletePages,
			toggleDeletePageModal,
			normalizedColumns,
			selectedColumnSort,
			sortDesc,
			searchText,
			toggleRenamePage,
			toggleSingleDeletePageModal,
			toggleFocusedDeletePageModal,
			mobileAppConfigOpen,
			focusedPage,
			focusedPages,
			sessionTemplateNormalizedColumns,
			sessionTemplateToggledColumns,
			isGridLayout

		} = this.state;
		let mainAction = [{ onClick: this.toggleAddPages, label: 'Add Page' }]; /* good */
		let batchActions = [ /* good */

			{
				label: 'Duplicate Page',
				iconClass: 'las la-copy',
				pluralLabel: 'Duplicate Pages',
				onClick: this.toggleCopyPages
			},
			{
				label: 'Delete',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleSingleDeletePageModal
			}
		];
		let searchEnabled = true;
		let checkedEntryCount = Object.keys(this.state.checkedPages).length;
		let data = this.state.pages;
		let entriesLength = data.length;
		let checkedRows = this.state.checkedPages;

		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}


		switch (selectedSection) {
			case 0:
				data = data.filter((p) => !p.isSessionPage);
				break;
			case 1:
				data = data.filter((p) => p.status === 'published');
				break;
			case 2:
				data = data.filter((p) => p.status === 'draft');
				break;
			case 3:
				data = data.filter((p) => p.isSessionPage);
				break;
			default:
				break;
		}
		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}
		return (
			<Fragment>
				{!loading && (<Switch>
					<Route path={`/:organization/events/:event/pages/:page`}>
						<EventPage organization={this.props.organization} orgId={orgId} eventId={eventId} pages={pages} event={event} updatePage={this.updatePage} account={this.props.account} deleteSinglePage={this.deleteSinglePage} updateAddCopy={this.updateAddCopy} updatePathOp={this.props.updatePathOp}
						/>
					</Route>
					<Route path={`/:organization/events/:event/pages`}>
						<Fragment>
							<div className="sectionContainer">
								<SectionHeader
									title={'Event Pages '}
									description="Create and manage your event website. Build pages for registration, check-in, customized agendas and more."
									breadcrumbs={[
										{
											name: `${event.name}`,
										}
									]}
									style={{ paddingBottom: 0 }}
									enableLastLink={true}
								>
								</SectionHeader>
								<TabsContainer
									tabs={[{ title: 'All Event Pages' }, { title: 'Published' }, { title: 'Drafts' }, { title: 'Session Templates' }]}
									updateSelected={this.updateSelected}
									selectedIndex={selectedSection}


								/>
								<div className="sectionContainer sectionBody">
									<ActionButtonContainer
										mainActions={mainAction}
										searchEnabled={searchEnabled}
										searchEntries={this.searchEntries}
										enabledViewSelection={true}
										isGridLayout={isGridLayout}
										toggleLayout={this.toggleLayout}
									/>
									{
										!isGridLayout ? (
											<div className='flex h-100 hasHeader'>
												<ViewsTable
													title={"Page"}
													columns={selectedSection === 3 ? sessionTemplateNormalizedColumns : normalizedColumns}
													toggledColumns={selectedSection === 3 ? sessionTemplateToggledColumns : toggledColumns}
													data={data}
													mainColumnActive={true}
													checked={checkedRows}
													checkRow={this.checkEntry}
													checkAllRows={this.checkAllEntries}
													sortData={this.sortData}
													defaultSort={'title'}
													sortDirection={'desc'}
													rowSettings={[
														{
															label: 'Rename Page',
															onClick: this.toggleRenamePageModal
														},

														{
															label: 'Duplicate Page', onClick: (pageId) => {
																const selectedPages = {};
																selectedPages[pageId] = true;
																this.setState({ focusedPages: selectedPages, focusedPage: true, copyPagesModalOpen: true })
															}
														},
														{
															label: 'Delete Page', onClick: (pageId) => {
																const selectedPages = {};
																selectedPages[pageId] = true;
																this.setState({ focusedPages: selectedPages, focusedPage: true, toggleFocusedDeletePageModal: true })
															}
														}
													]}
												/>
											</div>
										) : (
											<PageGrid
												title={'Pages'}
												orgId={orgId}
												eventId={eventId}
												updateFavorite={this.updateFavorite}
												data={data}
												account={this.props.account}
												checkRow={this.checkEntry}
												checked={checkedRows}

											//checkAllRows={this.checkAllEntries}
											/>
										)
									}
								</div>

								<ActionButtonContainer
									batchActions={batchActions}
									checkedEntryCount={checkedEntryCount}
									entriesLength={entriesLength}
									uncheckEntries={this.uncheckEntries}
								/>

								{toggleRenamePage && (
									<RenamePageModal
										isOpen={toggleRenamePage}
										page={data.find((p) => p._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										event={event}
										toggle={this.toggleRenamePageModal}
										update={this.updateSinglePage}
										updatePathOp={this.props.updatePathOp}
									/>
								)}
								{copyPagesModalOpen && (
									<CopyPageModal
										orgId={orgId}
										pages={pages}
										eventId={eventId}
										checkedPages={focusedPage ? focusedPages : checkedPages}
										isOpen={copyPagesModalOpen}
										updateAddCopy={this.updateAddCopy}
										toggle={() => this.setState({ copyPagesModalOpen: !this.state.copyPagesModalOpen })}
										addPage={(page) => {
											const ps = this.state.pages;
											const { organization } = this.props
											const { editedPage } = this.state
											page.link = `/${this.props.orgId}/events/${eventId}/pages/${page.id}/`;
											// Vanity URL
											if (organization.hostname) {
												page.url = `https://${organization.hostname}/${event.nameSlug}/${page.slug}`;
											} else {
												page.url = `https://${organization.identifier}.simple.events/${event.nameSlug}/${page.slug}`;
											}
											ps.push(page);
											this.props.updatePathOptionsPage(page)
											this.setState({ focusedPage: false, checkedPages: {}, focusedPages: {}, pages: ps, copyPagesModalOpen: !copyPagesModalOpen }, () => {
												batchCallback && batchCallback('update');
											});
										}}
									/>
								)}

								{/* single */}
								{toggleSingleDeletePageModal && (
									<DeletePageModal
										delete={this.deletePages}
										event={event}
										pages={pages}
										checkedPages={checkedPages}
										eventId={eventId}
										isOpen={toggleSingleDeletePageModal}
										orgId={orgId}
										toggle={this.toggleDeletePages}
										count={Object.keys(this.state.checkedPages).length}
										isDeleting={isDeleting}
									/>
								)}
								{/* Focuse */}
								{toggleFocusedDeletePageModal && (
									<DeletePageModal
										delete={this.deleteFocusedPages}
										event={event}
										pages={pages}
										checkedPages={focusedPage ? focusedPages : checkedPages}
										eventId={eventId}
										isOpen={toggleFocusedDeletePageModal}
										orgId={orgId}
										toggle={this.toggleFocusedDeletePages}
										count={Object.keys(this.state.focusedPages).length}
										isDeletingFocused={isDeletingFocused}
									/>
								)}

								{addPageModalOpen && (
									<PagesTemplateCenter
										orgId={orgId}
										eventId={eventId}
										isOpen={true}
										toggle={this.toggleAddPages}
										addPage={(page) => {
											const ps = this.state.pages;
											page.link = `/${this.props.orgId}/events/${eventId}/pages/${page.id}/0`;
											ps.push(page);
											this.props.updatePathOptionsPage(page)
											this.setState({ pages: ps }, () => {
												this.props.refreshSectionCounts()
											});
										}}
										account={this.props.account}
									/>
								)}
								{mobileAppConfigOpen && (
									<MobileAppConfigModal
										orgId={orgId}
										eventId={eventId}
										event={event}
										isOpen={mobileAppConfigOpen}
										toggle={() => this.setState({ mobileAppConfigOpen: !mobileAppConfigOpen })}
									/>
								)}
							</div>
						</Fragment>
					</Route>
				</Switch>)}
				<LoadingWizard text="LOADING PAGES" loading={loading} />
			</Fragment>
		);
	}
}

export default withRouter(EventPages);
