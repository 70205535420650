import React, { Component } from 'react';
import ViewsTable from '../../../../components/tables/ViewsTable';

import FieldModal from '../../../../components/modals/fieldModal';

class AddToGroupModal extends Component {
	constructor(props) {
		super(props);
		const toggledColumns = {};
		const normalizedColumns = Object.keys(props.columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: props.columns[cfc],
				key: cfc,
				value: cfc,
				type: props.columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			checkedEntries: {},
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns
		};
	}

	addToGroup = () => {
		const { checkedEntries } = this.state;
		this.props.handleSubmit(Object.keys(checkedEntries), this.props.toggle);
	};

	render() {
		const { isOpen, toggle, inGroup, blankTitle } = this.props;
		const { normalizedColumns, toggledColumns, submitting } = this.state;
		const availableEntries = this.props.entries.filter((ent) => !inGroup.includes(ent._id));
		const columnWidth = {
			firstName: 150,
			lastName: 150,
			email: 200
		};

		return (


			<div>


				<FieldModal
					size="large"
					isOpen={isOpen}
					modalTitle={this.props.title}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<ViewsTable
								title={blankTitle}
								style={{ margin: 0, height: 'calc(100vh - 300px)', }}
								columnWidth={columnWidth}
								classes={'fit'}
								columns={normalizedColumns}
								toggledColumns={toggledColumns}
								data={availableEntries}
								mainColumnActive={false}
								checked={this.state.checkedEntries}
								checkRow={(entryId) => {
									const checked = this.state.checkedEntries;
									checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
									this.setState({
										checkedEntries: checked
									});
								}}
								checkAllRows={(entryIds) => {
									let checked = this.state.checkedEntries;
									entryIds.length > 0
										? entryIds.forEach((entryId) => {
											checked[entryId] = true;
										})
										: (checked = {});
									this.setState({
										checkedEntries: checked
									});
								}}
							/>
						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.addToGroup()}
					actionButtonDisabled={submitting}
					isFullWidthBody={true}
				></FieldModal>



			</div>

		);
	}
}

export default AddToGroupModal;
