import React, { Fragment } from 'react';
import InputField from '../../../components/inputs/inputField.js';

import FieldModal from '../../../components/modals/fieldModal.js';

class AddAttendeeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			submitting: false,
			invalid: false,
			error: 'All fields are required'
		};
	}

	handleFNChange = (e) => {
		this.setState({
			firstName: e.target.value
		});
	};

	handleLNChange = (e) => {
		this.setState({
			lastName: e.target.value
		});
	};

	handleEAChange = (e) => {
		this.setState({
			email: e.target.value
		});
	};

	resetAdd = (e, toggle) => {
		this.setState({
			firstName: '',
			lastName: '',
			email: '',
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	render() {
		const { isOpen, requiredFields } = this.props;
		const { firstName, lastName, email, submitting, invalid, error } = this.state;
		return (

			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Contact"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div
								style={{
									overflowX: 'hidden',
									maxHeight: "calc(100vh - 400px)",
								}}
							>
								<InputField
									label="Email Address"
									value={email}
									type="email"
									required={true}
									errorTrigger={requiredFields.email}
									onChange={this.handleEAChange}
								/>

								<InputField
									label="First Name"
									value={firstName}
									required={true}
									type="text"
									errorTrigger={requiredFields.firstName}
									onChange={this.handleFNChange}
								/>
								<InputField label="Last Name" value={lastName} type="text" onChange={this.handleLNChange} />
								{invalid && <div className="modalErrorMessage calibreRegular">{error}</div>}
							</div>)
					}}
					toggle={this.resetAdd}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.props.createContact({ email: email, firstName: firstName, lastName: lastName })}
					actionButtonDisabled={submitting}
				></FieldModal>

			</div>

		);
	}
}

export default AddAttendeeModal;
