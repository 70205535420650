import React, { Component } from 'react';
import { Table } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import API from '../../../utils/API';
import { Trash2 } from 'react-feather';
import AddPointsModal from './modals/addPointModal';
import RemovePointModal from './modals/removePointModal';
import EditPointsModal from './modals/editPointModal';
import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';

class EventInteractionPoints extends Component {
	constructor(props) {
		super(props);

		const columns = {
			iconClass: 'Icon',
			customLabel: 'Label',
			description: 'Description',
			pointSuffix: 'Points Suffix',
			pointValue: 'Value',
			maxPointsAllowed: 'Max Points Allowed',
			oncePerReference: 'Once Per Reference',
			active: 'Active',
			actionName: 'Identifier'
		};
		const columnTypes = {
			iconClass: 'icon',
			customLabel: 'string',
			description: 'text',
			pointSuffix: 'text',
			pointValue: 'number',
			maxPointsAllowed: 'number',
			oncePerReference: 'boolean',
			active: 'boolean',
			actionName: 'string'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			availablePoints: [],
			allPoints: [],
			points: [],
			removePointModalOpen: false,
			addPointsModalOpen: false,
			editPointModalOpen: false,
			markedPoint: null,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedPoints: []
		};
	}

	componentWillMount() {
		const { orgId, eventId } = this.props;
		this.props.setSelectedTab(1);

		this.setState({ loading: true });
		API().get(`Organizations/${orgId}/events/${eventId}/interactions`).then((res) => {
			if (res.data) {
				this.setState({
					points: res.data.points,
					allPoints: res.data.availablePoints,
					availablePoints: res.data.availablePoints.filter(
						(ap) => !res.data.points.find((p) => p.actionName == ap.actionName)
					),
					loading: false
				});
			}
		});
	}

	addInteractionPoint = (interactionPoint) => {
		const points = this.state.points;
		const availablePoints = this.state.availablePoints.filter((ap) => ap.actionName != interactionPoint.actionName);
		points.push(interactionPoint);
		this.setState({ points: points, availablePoints: availablePoints });
	};

	toggleRemovePoint = (point) => {
		this.setState({
			removePointModalOpen: !this.state.removePointModalOpen,
			markedPoint: point ? point : null
		});
	};

	toggleEditPoint = (point) => {
		this.setState({
			editPointModalOpen: !this.state.editPointModalOpen,
			markedPoint: point ? point : null
		});
	};

	editPoint = (point) => {
		const points = this.state.points.map((s) => (s._id == point._id ? { ...s, ...point } : s));
		this.setState({
			points: points
		});
	};

	removePoint = (pointId) => {
		const points = this.state.points.filter((s) => s._id !== pointId);
		this.setState({
			points: points,
			availablePoints: this.state.allPoints.filter((ap) => !points.find((p) => p.actionName == ap.actionName))
		});
	};

	updatedCheckedPoints = (checked) => {
		this.setState({ checkedPoints: checked });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	sortPoints = () => {
		const {
			sortDesc,
			selectedColumnSort, points
		} = this.state;
		let pointsData = points;
		if (selectedColumnSort) {
			pointsData = pointsData.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			pointsData = sortDesc ? pointsData.reverse() : pointsData;
		}

		return pointsData;
	}

	render() {
		const { points, addPointsModalOpen, removePointModalOpen, editPointModalOpen, markedPoint,
			normalizedColumns, toggledColumns, checkedPoints } = this.state;
		const { event, sectionName, readOnly } = this.props;
		const sortedpoints = this.sortPoints();
		return (
			<div className="sectionContainer">
				<div className="sectionBody posRel p-0">
					<ActionButtonContainer searchEnabled={false} mainActions={[
						{
							type: 'button',
							label: 'Add interaction point',
							onClick: () => this.setState({ addPointsModalOpen: true })
						}
					]} />
					<ViewsTable data={sortedpoints} columns={normalizedColumns}


						title={"Virtual Engagement"}
						checked={checkedPoints}
						classes="nested"
						checkRow={(entryId) => {
							const checked = this.state.checkedPoints;
							checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
							this.updatedCheckedPoints(checked);
							this.setState({
								checkedPoints: checked
							});
						}}
						//mainActions={mainActions}
						checkAllRows={(entryIds) => {
							let checked = this.state.checkedPoints;
							entryIds.length > 0
								? entryIds.forEach((entryId) => {
									checked[entryId] = true;
								})
								: (checked = {});
							this.updatedCheckedPoints(checked);

							this.setState({
								checkedPoints: checked
							});
						}}
						mainColumnActive={false}
						toggledColumns={toggledColumns}
						onClick={(entryId) => {
							const markedPoint = this.state.points.find((point) => point._id == entryId);
							this.toggleEditPoint(markedPoint);
						}
						}

					/>
					<ActionButtonContainer
						batchActions={
							[
								{
									type: 'button',
									label: 'Delete Points',
									onClick: this.toggleRemovePoint,
									iconClass: 'las la-trash-alt mr-10',
									class: 'neu danger'
								}
							]}
						checkedEntryCount={Object.keys(checkedPoints).length}
						entriesLength={points.length}
						disableNueFooter={true}
						uncheckEntries={() => {
							this.setState({ checkedPoints: {} });
						}}
					/>
				</div>
				{addPointsModalOpen && (
					<AddPointsModal
						isOpen={addPointsModalOpen}
						toggle={() => this.setState({ addPointsModalOpen: false })}
						availablePoints={this.state.availablePoints}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
						addInteractionPoint={this.addInteractionPoint}
					/>
				)}

				{removePointModalOpen && (
					<RemovePointModal
						isOpen={removePointModalOpen}
						toggle={this.toggleRemovePoint}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
						markedPoint={markedPoint}
						points={sortedpoints}
						checkedPoints={Object.keys(checkedPoints)}
						removePoint={this.removePoint}
					/>
				)}

				{editPointModalOpen && (
					<EditPointsModal
						isOpen={editPointModalOpen}
						toggle={this.toggleEditPoint}
						eventId={this.props.eventId}
						orgId={this.props.orgId}
						markedPoint={markedPoint}
						editPoint={this.editPoint}
					/>
				)}
			</div>
		);
	}
}

export default EventInteractionPoints;
