import React from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
const localizer = momentLocalizer(moment)

class CalendarView extends React.Component {

    mainActionCallback = (cb) => {
        const updatedEntries = this.props.entries;

        switch (cb) {
            case 'delete':
                this.setState(
                    {
                        organizedEntries: updatedEntries,
                        allEntries: updatedEntries,
                        checkedEntries: {}
                    },
                    () => {
                        this.filterView();
                        this.searchEntries();
                        this.props.views.forEach((av) => {
                            this.countFilteredEntries(av);
                        });
                    }
                );
                break;
            case 'update':
                this.setState(
                    {
                        organizedEntries: updatedEntries,
                        allEntries: updatedEntries,
                        checkedEntries: {}
                    },
                    () => {
                        this.filterView();
                        this.searchEntries();
                        this.props.views.forEach((av) => {
                            this.countFilteredEntries(av);
                        });
                    }
                );
                break;
            default:
                this.checkEntries([]);




        }
    }

    render() {
        const {
            categoryColumns,
            columns,
            columnTypes,
            subtitle,
            title,
            entries,
            readOnly,
            mainActions,
            batchActions,
            enableDownloadViews,
            breadcrumbs,
            description,
            disableTabs,
            fields,
            allViewTitle,
            subActions,
            viewPanelActions,
            summaryPanel,
            summaryPanelEnabled
        } = this.props;
        return (<div className={`sectionContainer`}>
            <div className="sectionHeader ns">
                <div className="sectionTitleBar">
                    <div className="sectionTitle">
                        {((breadcrumbs && breadcrumbs.length > 0) || subtitle) && (
                            <div>
                                {breadcrumbs &&
                                    breadcrumbs.length > 0 && (
                                        <Link to={`${breadcrumbs.length > 1 ? './' : window.location.href.endsWith('/') ? '../../' : '../'}`} className={` breadCrumbContainer ${breadcrumbs.length > 1 ? 'internal' : ''}`} >
                                            {breadcrumbs.map((b, i) => (
                                                <div key={i} className="breadCrumb flex-ac">
                                                    {i > 0 && (
                                                        <div className="breadCrumbSeparator">
                                                            /
                                                        </div>
                                                    )}
                                                    <p className="small">
                                                        {b.name}
                                                    </p>
                                                </div>
                                            ))}
                                        </Link>
                                    )}
                                {subtitle && <p className="small">{subtitle}</p>}
                            </div>
                        )}
                        {title && <h1 className="sectionName">{title}</h1>}
                    </div>
                </div>
            </div>
            <div className='sectionsContainer'>
                <div className={`sectionContainer`}>

                    <div className="sectionBody isTable p-0">
                        <div className="actionButtonContainer headerHeight flex-ac">

                            {mainActions.length > 0 && (<div className="flex-ac mr-10">
                                <div className="mainActionContainer">
                                    {mainActions[0].type == 'link' ? (
                                        <Link to={mainActions[0].target} className="mainAction">
                                            {mainActions[0].label}
                                        </Link>
                                    ) : (
                                        <div className="mainAction" onClick={() => mainActions[0].onClick(this.mainActionCallback)}>
                                            {mainActions[0].label}
                                        </div>
                                    )}
                                    {mainActions.length > 1 && (
                                        <UncontrolledDropdown inNavbar>
                                            <DropdownToggle className="columnToggle">
                                                <i className="las la-angle-down" />
                                            </DropdownToggle>
                                            <DropdownMenu className="columnDropdown">
                                                <div className='ph-20'>
                                                    <h5 className="mb-10">Options</h5>
                                                    {mainActions.map((action, index) => {
                                                        if (index > 0) {
                                                            return action.type == 'link' ? (
                                                                <DropdownItem key={`${action.type}-${index}`}>
                                                                    <Link
                                                                        to={action.target}
                                                                        className="black"
                                                                    >
                                                                        {action.label}
                                                                    </Link>
                                                                </DropdownItem>
                                                            ) : (
                                                                <DropdownItem
                                                                    onClick={() => action.onClick(this.mainActionCallback)}
                                                                    key={`${action.type}-${index}`}
                                                                >
                                                                    {action.label}
                                                                </DropdownItem>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                </div>
                            </div>
                            )}

                            <div className="sectionOptionsContainer">
                                {viewPanelActions && viewPanelActions.length > 0 &&
                                    viewPanelActions.map((sa, index) => {
                                        return sa.iconClass && <><i id={`viewAction-${index}`} onClick={sa.onClick} className={` ${sa.iconClass}`} />
                                            <UncontrolledTooltip target={`viewAction-${index}`} placement="left">{sa.label}</UncontrolledTooltip></>
                                    })
                                }
                                {enableDownloadViews && (<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
                                    <i className="las la-download" />
                                    <UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
                                </div>
                                )}
                            </div>
                        </div>
                        <Calendar events={[]} defaultView={Views.MONTH} localizer={localizer} />
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default CalendarView;