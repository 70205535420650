import React, { Component } from 'react';

import FieldModal from '../../../components/modals/fieldModal';

class DeleteProgramsModal extends Component {
	state = {
		submitting: false
	};
	render() {
		const { isOpen, count, toggle } = this.props;
		const { submitting } = this.state;
		return (
			<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={`Delete ${count > 1 ? 'Programs' : 'Program'}`}
				bodyHeaderText={<span>You are about to delete{' '}
					<b>
						{count} {count > 1 ? 'programs' : 'program'}
					</b>, this is irreversible, would you like to continue?</span>}
				bodyContent={() => {
					return ("")
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
				actionButton={() => this.props.delete()}
				isDelete={true}
			></FieldModal>


		);
	}
}

export default DeleteProgramsModal;
