import React from 'react';

import InputField from '../../../components/inputs/inputField';
import TextEditor from '../../../views/pages/components/textEditor';
import API from '../../../utils/API';
import LoadingWizard from '../../../components/spinner/wizard';
import TaskCard from '../../../components/cards/taskCard';
import moment from 'moment';
import DeleteCommentModal from './modals/deleteCommentModal';



class EventSessionComments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newComment: '',
            toggleNewComment: false,
            loading: true,
            editCount: 0,
            toggleDeleteComment: false,
            selectedId: '',
        };



    }
    async componentDidMount() {
        const { session, orgId, eventId } = this.props;
        await API()
            .get(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/comments`)
            .then((res) => {
                if (res.data) {
                    res.data.sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    this.setState({ comments: res.data, loading: false });

                }
            });

    }

    updateNewComment = (value) => {
        this.setState({ newComment: value, toggleNewComment: true });
    }

    cancelNewComment = () => {
        this.setState({ newComment: '', toggleNewComment: false, editCount: this.state.editCount + 1 });
    }
    addNewComment = async () => {
        const { newComment } = this.state;
        const { orgId, eventId, session } = this.props;

        await API()
            .post(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/comment`, {
                comment: newComment
            })
            .then((res) => {

                if (res.data) {
                    let comments = this.state.comments;
                    comments.unshift(res.data);
                    this.setState({ newComment: '', toggleNewComment: false, comments: comments, editCount: this.state.editCount + 1 });
                }

            });
    }

    assign = async (commentId, user) => {
        const { orgId, eventId, session } = this.props;

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/comment/${commentId}`, {
                assignedTo: user._id
            })
            .then((res) => {

                if (res.data) {
                    const comments = this.state.comments;
                    const index = comments.findIndex((c) => c._id == commentId);
                    comments[index] = res.data;
                    this.setState({ comments: comments });

                }
            });

    }

    updateStatus = async (commentId, status) => {
        const { orgId, eventId, session } = this.props;

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/comment/${commentId}`, {
                completed: status
            })
            .then((res) => {

                if (res.data) {
                    //replace by id
                    const comments = this.state.comments;
                    const index = comments.findIndex((c) => c._id == commentId);
                    comments[index] = res.data;
                    this.setState({ comments: comments });

                }
            });

    }

    updateMessage = async (commentId, message, callback) => {
        const { orgId, eventId, session } = this.props;

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/comment/${commentId}`, {
                message: message
            })
            .then((res) => {

                if (res.data) {
                    //replace by id
                    const comments = this.state.comments;
                    const index = comments.findIndex((c) => c._id == commentId);
                    comments[index] = res.data;
                    this.setState({ comments: comments }, () => {
                        callback && callback();
                    });

                }
            });

    }

    deleteComment = async (commentId, callback) => {
        const { orgId, eventId, session } = this.props;

        await API()
            .patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}/comment/${commentId}`, {
                deleted: true
            })
            .then((res) => {

                if (res.data) {
                    //remove by id
                    const comments = this.state.comments;
                    const index = comments.findIndex((c) => c._id == commentId);
                    comments.splice(index, 1);
                    this.setState({ comments: comments, editCount: this.state.editCount + 1 }, () => {

                        callback && callback();
                    });
                }
            });

    }

    handleFocus = (focus, callback) => {
        this.setState({ editorFocused: focus }, callback);
    }

    formatDate = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);
        const diffTime = Math.abs(now - createdDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


        let formattedDate = '';

        if (now.toDateString() === createdDate.toDateString()) {
            // If posted today
            formattedDate = `${moment(createdAt).format('LT')} Today`;

        } else if (diffDays >= 1 && diffDays <= 6) {
            // If posted 1-6 days ago
            formattedDate = moment(createdAt).format('ddd h:mm a');
        } else {
            // If posted 7+ days ago
            formattedDate = `${moment(createdAt).format('MMM')} ${moment(createdAt).format('M')}/${moment(createdAt).format('YY')}`;//Dex 12/23

        }

        return formattedDate;
    }

    toggleDeleteComment = (commentId) => {
        const { } = this.props;
        let selectedId = this.state.toggleDeleteComment == false ? commentId : '';

        this.setState({ toggleDeleteComment: !this.state.toggleDeleteComment, selectedId: selectedId })
    }


    render() {
        const { } = this.props;
        const { newComment, toggleNewComment, loading, comments, editCount, toggleDeleteComment, selectedId } = this.state;

        return (
            < div className='sectionBody mb-0 p-0'
                style={{
                    overflow: 'hidden',
                }}
            >
                {!loading ? <div className=" sectionBody h-100"
                    style={{ overflow: 'hidden' }}>


                    <TextEditor
                        id={`newComment`}
                        key={`editor-${editCount}`}
                        value={newComment}
                        //codeViewEnabled={true}
                        onChange={(value) => {
                            this.updateNewComment(value);
                        }}
                        options={[]}
                        placeholder={`Add a new comment...`}
                        staticToolbar={true}
                        handleFocus={this.handleFocus}
                        hideToolbar={!toggleNewComment}

                    />

                    {toggleNewComment &&
                        <div className='flex fcc aic ml-a pt-10'
                            style={{
                                flexFlow: 'row-reverse',
                            }}
                        >

                            <button className='prim min'
                                onClick={this.addNewComment}
                            >
                                Add Comment
                            </button>
                            <button className='neu mr-25  min'
                                onClick={this.cancelNewComment}>
                                Cancel
                            </button>
                        </div>
                    }



                    <div className='commentListContainer mt-20 mb-50 h-100 sectionBody'
                        style={{ paddingBottom: 100 }}
                    >
                        {comments.map((comment, index) => {
                            let createdAt = this.formatDate(comment.createdAt);
                            let description = <p className='small grey'>{createdAt}</p>
                            let emptyProfilePicCreatedBy = !comment.createdBy || (comment.createdBy && (!comment.createdBy.profilePicture || comment.createdBy.profilePicture === ''))
                            return (
                                <div key={`${index}-${editCount}`}>
                                    <TaskCard
                                        id={comment._id}
                                        message={comment.message}
                                        author={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
                                        authorAcronym={emptyProfilePicCreatedBy ? `${comment.createdBy.firstName[0]}${comment.createdBy.lastName[0]} ` : <img src={comment.createdBy.profilePicture} className=' abbreviation  ' />}
                                        description={description}
                                        users={this.props.organization.members}
                                        assign={this.assign}
                                        assignedTo={comment.assignedTo}
                                        completed={comment.completed}
                                        updateStatus={this.updateStatus}
                                        updateMessage={this.updateMessage}
                                        settings={[
                                            { label: 'Delete Comment', onClick: this.toggleDeleteComment }
                                        ]}
                                        htmlOnly={false}
                                        enableEditComment={comment.createdBy._id === this.props.account._id}

                                    />
                                </div>
                            );
                        })}
                    </div>




                    {toggleDeleteComment &&
                        <DeleteCommentModal
                            isOpen={toggleDeleteComment}
                            toggle={this.toggleDeleteComment}
                            deleteComment={this.deleteComment}
                            commentId={selectedId}
                        />
                    }


                </div> :
                    <LoadingWizard
                        text={'Loading Comments'}
                        loading={loading}
                    />
                }

            </div>


        );
    }
}

export default EventSessionComments;
