import React, { Fragment } from 'react';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import SelectField from '../../../../components/inputs/selectField';
import ToggleField from '../../../../components/inputs/toggleField';
const statusOptions = [
	{ label: 'Imported', value: 'imported' },
	{ label: 'Invited', value: 'invited' },
	{ label: 'Registered', value: 'registered' },
	{ label: 'Not Attending', value: 'not attending' },
	{ label: 'Checked In', value: 'checked in' },
	{ label: 'Incomplete', value: 'incomplete' },
	{ label: 'Entered through SSO', value: 'entered through SSO' },
	{ label: 'Removed', value: 'removed' },
	{ label: 'Did not attend', value: 'did not attend' }
];
class AddAttendeeModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedStatus: statusOptions[0],
			firstName: '',
			lastName: '',
			email: '',
			submitting: false,
			invalid: false,
			error: '',
			selectedValue: { label: 'Imported', value: 'imported' },
			enableWelcomeEmail: false,
			templates: [],
			welcomeEmailTemplate: null,
			selectedTemplateValue: null,
			selectedTemplateValueErr: false,
			isPlatformTemplate: false,
			requiredFields: {
				email: false,
				firstName: false,
			},
			contacts: [],
			defaultContactOptions: [],
			selectedContact: { label: '', value: '' }
		};
	}
	async componentDidMount() {
		const { eventId, orgId } = this.props;
		await API().get(`Organizations/${orgId}/events/${eventId}/templates/`).then((res) => {
			if (res.data) {
				let templates = res.data.templates;
				templates = templates.map((template) => {
					return {
						label: template.name,
						value: template._id,
						isPlatformTemplate: false, 
					};
				});

				this.setState({ templates: templates });
			}
		}
		);

		await API()
			.get(`Organizations/${orgId}/contacts`)
			.then((res) => {
				if (res.data) {
					let contactOptions = res.data.contacts.map((contact) => {
						return {
							label: contact.emailAddress,
							value: contact.emailAddress,
							_id: contact._id,
							firstName: contact.firstName,
							lastName: contact.lastName || '',
						};
					});

					this.setState({
						contacts: res.data.contacts,
						contactsOptions: contactOptions,
						defaultContactOptions: contactOptions
					});
				} else {

				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	}

	handleFNChange = (e) => {
		this.setState({
			firstName: e.target.value
		});
	};

	handleLNChange = (e) => {
		this.setState({
			lastName: e.target.value
		});
	};

	handleEAChange = (e) => {
		const email = e.target.value;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValidEmail = emailRegex.test(email);
	
		let error = '';
		let emailError = false;
	
		if (!email) {
			error = 'Required';
			emailError = true;
		} else if (!isValidEmail) {
			error = 'Invalid email format';
			emailError = true;
		}
	
		this.setState({
			email: email,
			requiredFields: {
				...this.state.requiredFields,
				email: emailError
			},
			error: error
		});
	};

	addAttendee = async (attendee) => {
		this.setState({ submitting: true });
		const { eventId, orgId } = this.props;
		const { firstName, lastName, email, selectedValue, enableWelcomeEmail, selectedTemplateValue, selectedContact } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValidEmail = emailRegex.test(email);

		let payload = {
			firstName,
			lastName,
			email,
			status: selectedValue.value,
			isContact: selectedContact.isNew ? false : true,
		};

		Object.keys(requiredFields).forEach((key) => {
			if (!payload[key] || payload[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		 // Additional email validation
		if (!email) {
			this.setState({
				requiredFields: { ...requiredFields, email: true },
				error: 'Required',
				submitting: false
			});
			valid = false;
		} else if (!isValidEmail) {
			this.setState({
				requiredFields: { ...requiredFields, email: true },
				error: 'Invalid email format',
				submitting: false
			});
			valid = false;
		}

		if (enableWelcomeEmail) {
			if (selectedTemplateValue === null) {
				this.setState({ selectedTemplateValueErr: true });
				valid = false;
			} else {

				payload = {
					...payload,
					sendWelcomeEmail: enableWelcomeEmail,
					welcomeEmailTemplate: selectedTemplateValue.value,
					isPlatformTemplate: selectedTemplateValue.isPlatformTemplate ? true : false
				};
				this.setState({ selectedTemplateValueErr: false });
			}

		}

		if (valid) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/attendee`, payload)
				.then((res) => {
					if (res.data) {
						this.props.handleSubmit(res.data);
						this.resetAdd(true);
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false,
						error: e.request.response._id ? 'Email already exists' : e.request.response,
						requiredFields: { ...requiredFields, email: true }
					});
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	resetAdd = (e, toggle) => {
		this.setState({
			firstName: '',
			lastName: '',
			email: '',
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	handleInputChange = (e) => {
		const { defaultContactOptions } = this.state;
		const newContact = {
			label: e,
			isNew: true,
			value: e
		};
		let updatedContactOptions = [...defaultContactOptions, newContact];
		this.setState({ contactsOptions: updatedContactOptions });

	};
	handleEmailOnChange = (e) => {

		let lastName = e.lastName || '';
		let firstName = e.firstName || '';

		this.setState({ selectedContact: e, firstName: firstName, lastName: lastName, email: e.value });
	}


	render() {
		const { isOpen, toggle, style } = this.props;
		const {
			firstName,
			lastName,
			error,
			requiredFields,
			selectedValue,
			selectedTemplateValueErr,
			selectedTemplateValue,
			enableWelcomeEmail,
			templates,
			contactsOptions,
			selectedContact
		} = this.state;

		return (
			<div>
				<FieldModal
					style={style}
					isOpen={isOpen}
					size={'medium'}
					modalTitle={"New Attendee"}
					bodyHeaderText={'Add an existing contact by email or create a new contact by filling out the form below.'}
					bodyContent={() => {
						return (
							<div >
								<h4 className='blockHeader mb-10'>Basic Information</h4>

								<SelectField
									classes={'w-100'}
									label="Email Address"
									value={selectedContact}
									errorMessage={error !== '' && error}
									name="email"
									errorTrigger={requiredFields.email}
									options={contactsOptions}
									required={true}
									onChange={this.handleEmailOnChange}
									onInputChange={this.handleInputChange}

								/>
								<InputField
									label="First Name"
									required={true}
									errorTrigger={requiredFields.firstName}
									value={firstName}
									onChange={this.handleFNChange} />
								<InputField
									label="Last Name"
									value={lastName}
									onChange={this.handleLNChange} />
								<SelectField
									classes={'w-100'}
									label="Attendee Status"
									value={selectedValue}
									name="attendeeStatus"
									errorTrigger={requiredFields.selectedValue}
									options={statusOptions}
									required
									onChange={(e) => {
										this.setState({ selectedValue: e });

									}}
								/>
								<ToggleField
									label="Send welcome email"
									checked={enableWelcomeEmail}
									icons={false}
									onChange={(e) => this.setState({ enableWelcomeEmail: e.target.checked })}
								/>
								{enableWelcomeEmail &&

									<SelectField
										classes={'w-100'}
										label="Email Template"
										value={selectedTemplateValue}
										name="attendeeStatus"
										errorTrigger={selectedTemplateValueErr}
										options={templates}
										required
										onChange={(e) => {
											this.setState({ selectedTemplateValue: e });
										}}
									/>}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={"Add"}
					actionButton={() => this.addAttendee()}
				></FieldModal>

			</div>
		);
	}
}

export default AddAttendeeModal;
