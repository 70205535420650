import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import Form from './form';
import CreateFormModal from './modals/addFormModal';
import DeleteFormModal from './modals/deleteDefaultModal';
import ViewsContainer from '../../../components/views/ViewsContainer';

let batchActionCB;
class EventForms extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			type: 'Type',
			enableSubmissionReview: 'Confirmation Required',
			confirmationEmailName: 'Confirmation Email',
			pageCount: 'Page Count',
			createdAt: 'Created At',
			updatedAt: 'Updated At'
		};

		const defaultFieldTypes = {
			name: 'text',
			type: 'text',
			description: 'text',
			status: 'text',
			enableSubmissionReview: 'boolean',
			confirmationEmailName: 'text',
			pageCount: 'number',
			createdAt: 'date',
			updatedAt: 'date'
		};

		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'type',
					'enableSubmissionReview',
					'confirmationEmailName',
					'pageCount',
					'createdAt',
					'updatedAt'
				]
			},
		};

		this.state = {
			categoryColumns: fieldCategories,
			loading: true,
			checkedForms: [],
			addFormModalOpen: false,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			deletedForms: [],
			formsViews: [],
			deleteFormsModalOpen: false
		};
	}

	componentDidMount() {
		const { program, programId, orgId } = this.props;

		let emailTemplates = program.eventDefaults.emails;
		let forms = this.props.forms;

		forms = forms.map((f) => {
			if (f.confirmationEmail && f.confirmationEmail !== '') {
				let email = emailTemplates.find(e => {
					let campaignId = e._id;
					let formConfirmationEmail = f.confirmationEmail;
					if (campaignId === formConfirmationEmail) {
						return e;
					}
				});

				if (email) {
					f.confirmationEmailName = email.name;

				} else {

					f.confirmationEmailName = 'N/A';
				}


			} else {
				f.confirmationEmailName = 'N/A';

			}

			return f;
		})
	}


	deleteCheckedForms = (formIds) => {
		this.props.deleteForms(formIds, () => {
			batchActionCB && batchActionCB('delete');
			this.toggleDeleteForms();
		});
	};

	toggleDeleteForms = (checkedForms, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedForms: checkedForms || this.state.checkedForms,
			deleteFormsModalOpen: !this.state.deleteFormsModalOpen
		});
	};

	addForm = (form, cb) => {
		this.props.addForm(form, () => {
			batchActionCB && batchActionCB('update');
			this.toggleAddForm();
		});
	};

	duplicateForm = (formId, name, callback) => {

		this.props.duplicateForm(formId, name, () => {
			batchActionCB && batchActionCB('update');
			callback();
		});
	}

	toggleAddForm = (cb) => {
		batchActionCB = cb;
		this.setState({ addFormModalOpen: !this.state.addFormModalOpen });
	};

	render() {
		const { forms, program, orgId, programId, readOnly } = this.props;
		const {
			categoryColumns,
			deletedForms,
			loading,
			columns,
			columnTypes,
			formsViews,
			deleteFormsModalOpen,
			checkedForms, addFormModalOpen
		} = this.state;
		const checkedCount = Object.keys(checkedForms).length;
		let emailTemplates = program.eventDefaults.emails;
		let formsList = this.props.forms;

		formsList = formsList.map((f) => {
			if (f.confirmationEmail && f.confirmationEmail !== '') {
				let email = emailTemplates.find(e => {
					let campaignId = e._id;
					let formConfirmationEmail = f.confirmationEmail;
					if (campaignId === formConfirmationEmail) {
						return e;
					}
				});

				if (email) {
					f.confirmationEmailName = email.name;
				} else {

					f.confirmationEmailName = 'N/A';
				}
			} else {
				f.confirmationEmailName = 'N/A';

			}
			return f;
		})
		return (
			<Switch>
				<Route exact path={`/${orgId}/programs/${programId}/forms`}>
					<Fragment>
						{!addFormModalOpen && <ViewsContainer
							orgId={orgId}
							categoryColumns={categoryColumns}
							columns={columns}
							columnTypes={columnTypes}
							views={formsViews}
							entries={formsList}
							deletedEntries={deletedForms}
							title="Default Forms"
							description="Forms listed here will be added to all approved events. Create and manage registration and check-in forms. Add forms to pages to create a custom registration website."
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddForm, label: 'Add Form' },

							]}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteForms
								}
							]}
							enableDownloadViews={true}
							type={'Form'}
							referenceCollection={'Programs'}
							referenceId={programId}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>}
						{
							<DeleteFormModal
								isOpen={deleteFormsModalOpen}
								toggle={() => this.toggleDeleteForms()}
								title={`Delete ${checkedCount > 1 ? 'Forms' : 'Form'}`}
								message={`You are about to delete ${checkedCount} ${checkedCount > 1
									? 'forms'
									: 'form'}, this is irreversible,
						would you like to continue?`}
								submit={() =>
									this.deleteCheckedForms(Object.keys(checkedForms), () => {
										this.setState({ checkedForms: {}, deleteFormsModalOpen: false });
									})}
							/>
						}
						{addFormModalOpen && <CreateFormModal
							isOpen={addFormModalOpen}
							program={program}
							programId={programId}
							readOnly={readOnly}
							handleSubmit={this.props.addForm}
							orgId={this.props.orgId}
							toggle={this.toggleAddForm}
						/>}
					</Fragment>
				</Route>
				<Route path={`/${orgId}/programs/${programId}/forms/:formId`}>
					<Form
						program={program}
						programId={programId}
						readOnly={readOnly}
						orgId={this.props.orgId}
						forms={forms}
						fields={this.props.fields}
						addNewField={this.props.addNewField}
						saveForm={this.props.saveForm}
						duplicateForm={this.duplicateForm}
						updatePages={this.props.updatePages}
						eventFields={this.props.eventFields}
						attendeeFields={this.props.attendeeFields}
					/>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(EventForms);
