import React from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import SelectField from '../../../../components/inputs/selectField';
import TabsContainer from '../../../../components/views/TabsContainer';
import ViewsTable from '../../../../components/tables/ViewsTable';

import FieldModal from '../../../../components/modals/fieldModal';
import InputField from '../../../../components/inputs/inputField';

class AddRecipientsModal extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			sendDate: 'Date',
			sendTime: 'Time',
			timezone: 'Timezone',
			sent: 'Sent',
			recipientModel: 'Type',
			cancelled: 'Cancelled'
		};
		const columnTypes = {
			sendDate: 'date',
			sendTime: 'time',
			timezone: 'text',
			sent: 'bool',
			recipientModel: 'text',
			cancelled: 'bool'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			name: null,
			submitting: false,
			invalid: false,
			error: '',
			attendees: [],
			sessions: [],
			groups: [],
			selectedSection: 0,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			toggledColumns: toggledColumns,
			checkedAttendees: {},
			checkedGroups: {},
			checkedSessions: {},
			selectedColumnSort: null,
			sortDesc: false,
			selectedRecipientsCount: 0,
			searching: false,
			searchTerm: ''
		};
	}
	componentDidMount() {
		const { event, email } = this.props;

		let checkedAttendees = [];
		let checkedSessions = [];
		let checkedGroups = [];
		let selectedSection = 0;
		let selectedRecipientsCount = 0;

		if (email.recipients) {
			email.recipients.attendees.map((a) => {
				checkedAttendees[a] = true;
				selectedSection = 0;
			});
			selectedRecipientsCount =
				Object.keys(checkedAttendees).length !== 0
					? Object.keys(checkedAttendees).length
					: selectedRecipientsCount;
			email.recipients.groups.map((a) => {
				checkedGroups[a] = true;
				selectedSection = 1;
			});
			selectedRecipientsCount =
				Object.keys(checkedGroups).length !== 0 ? Object.keys(checkedGroups).length : selectedRecipientsCount;
			email.recipients.sessions.map((a) => {
				checkedSessions[a] = true;
				selectedSection = 2;
			});
			selectedRecipientsCount =
				Object.keys(checkedSessions).length !== 0
					? Object.keys(checkedSessions).length
					: selectedRecipientsCount;
		}

		this.setState({
			attendees: event.attendees,
			groups: event.groups,
			sessions: event.sessions,
			checkedAttendees: checkedAttendees,
			checkedSessions: checkedSessions,
			checkedGroups: checkedGroups,
			selectedSection: selectedSection,
			selectedRecipientsCount: selectedRecipientsCount
		});
	}
	updateSelected = (selectedIndex) => {
		const { checkedAttendees, checkedSessions, checkedGroups } = this.state;
		let selectedRecipientsCount = 0;

		selectedRecipientsCount =
			selectedIndex == 0
				? Object.keys(checkedAttendees).length
				: selectedIndex == 1
					? Object.keys(checkedGroups).length
					: selectedIndex == 2 ? Object.keys(checkedSessions).length : 0;
		this.setState({
			selectedSection: selectedIndex,
			selectedColumnSort: null,
			selectedRecipientsCount: selectedRecipientsCount
		});
	};

	updateRecipients = async () => {
		this.setState({ submitting: true });

		const { checkedGroups, checkedAttendees, checkedSessions, selectedSection } = this.state;
		const { eventId, orgId, email, update, toggle } = this.props;
		let valid = true;

		if (valid) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${email._id}`, {
					name: email.name,
					recipients: {
						attendees: selectedSection == 0 ? Object.keys(checkedAttendees) : [],
						groups: selectedSection == 1 ? Object.keys(checkedGroups) : [],
						sessions: selectedSection == 2 ? Object.keys(checkedSessions) : []
					}
				})
				.then((res) => {
					if (res.data) {
						update(
							{
								name: email.name,
								recipients: {
									attendees: selectedSection == 0 ? Object.keys(checkedAttendees) : [],
									groups: selectedSection == 1 ? Object.keys(checkedGroups) : [],
									sessions: selectedSection == 2 ? Object.keys(checkedSessions) : []
								}
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false });
		}
	};

	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};

	renderSelectedTabContent = () => {
		const { selectedSection, toggledColumns, selectedColumnSort, sortDesc, searchTerm } = this.state;

		let columns = {};
		let columnTypes = {};
		let normalizedColumns = null;
		let checkRow = () => { };
		let checkAllRows = () => { };
		let checkedRows = [];
		let data = [];
		let sortData = () => { };

		let options = [{ label: 'Attendees', value: 0 }, { label: 'Groups', value: 1 }, { label: 'Sessions', value: 2 }];
		switch (selectedSection) {
			case 0:
				columns = {
					firstName: 'First Name',
					lastName: 'Last Name',
					email: 'Email'
				};
				columnTypes = {
					firstName: 'text',
					lastName: 'text',
					email: 'text'
				};
				checkRow = (entryId) => {
					const checked = this.state.checkedAttendees;
					checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
					this.setState({
						checkedAttendees: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkAllRows = (entryIds) => {
					let checked = this.state.checkedAttendees;
					entryIds.length > 0
						? entryIds.forEach((entryId) => {
							checked[entryId] = true;
						})
						: (checked = {});

					this.setState({
						checkedAttendees: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkedRows = this.state.checkedAttendees;
				data = this.state.attendees;
				normalizedColumns =
					!normalizedColumns &&
					Object.keys(columns).map((cfc) => {
						toggledColumns[cfc] = true;
						return {
							label: columns[cfc],
							key: cfc,
							value: cfc,
							type: columnTypes[cfc],
							sortAsc: false,
							sortDesc: false
						};
					});

				sortData = (column, sortAsc, sortDesc) => {
					normalizedColumns = normalizedColumns.map((col) => {
						if (col.key == column.key) {
							col.sortAsc = sortAsc;
							col.sortDesc = sortDesc;
						} else {
							col.sortAsc = false;
							col.sortDesc = false;
						}
						return col;
					});

					this.setState({
						selectedColumnSort: column,
						sortDesc: sortDesc == false ? true : false
					});
				};

				break;
			case 1:
				columns = {
					name: 'Name'
				};
				columnTypes = {
					name: 'text'
				};
				checkRow = (entryId) => {
					const checked = this.state.checkedGroups;
					checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
					this.setState({
						checkedGroups: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkAllRows = (entryIds) => {
					let checked = this.state.checkedGroups;
					entryIds.length > 0
						? entryIds.forEach((entryId) => {
							checked[entryId] = true;
						})
						: (checked = {});

					this.setState({
						checkedGroups: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkedRows = this.state.checkedGroups;
				data = this.state.groups;
				normalizedColumns = Object.keys(columns).map((cfc) => {
					toggledColumns[cfc] = true;
					return {
						label: columns[cfc],
						key: cfc,
						value: cfc,
						type: columnTypes[cfc],
						sortAsc: false,
						sortDesc: false
					};
				});
				break;
			case 2:
				columns = {
					name: 'Name'
				};
				columnTypes = {
					name: 'text'
				};
				checkRow = (entryId) => {
					let checked = this.state.checkedSessions;

					checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);

					this.setState({
						checkedSessions: checked,
						selectedRecipientsCount: Object.keys(checked).length
					});
				};
				checkAllRows = (entryIds) => {
					let checked = this.state.checkedSessions;
					entryIds.length > 0
						? entryIds.forEach((entryId) => {
							checked[entryId] = true;
						})
						: (checked = {});

					this.setState({
						checkedSessions: checked,
						selectedRecipientsCount: 0
					});
				};
				checkedRows = this.state.checkedSessions;
				data = this.state.sessions;
				normalizedColumns = Object.keys(columns).map((cfc) => {
					toggledColumns[cfc] = true;
					return {
						label: columns[cfc],
						key: cfc,
						value: cfc,
						type: columnTypes[cfc],
						sortAsc: false,
						sortDesc: false
					};
				});
				break;
			default:
				break;
		}

		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}

		if (searchTerm.length > 0) {
			const searchTerms = searchTerm.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		return (
			<ViewsTable
				key={`recipients-${selectedSection}`}
				title={options[selectedSection].label}
				style={{ margin: 0, paddingTop: 0, paddingBottom: 25, height: 325 }}
				columns={normalizedColumns}
				toggledColumns={toggledColumns}
				data={data}
				mainColumnActive={false}
				checked={checkedRows}
				checkRow={checkRow}
				mainActions={[]}
				checkAllRows={checkAllRows}
				sortData={sortData}
			/>
		);
	};

	render() {
		const { isOpen, toggle, email } = this.props;
		const { submitting, selectedSection, selectedRecipientsCount, searchTerm } = this.state;

		let options = [{ label: 'Attendees', value: 0 }, { label: 'Groups', value: 1 }, { label: 'Sessions', value: 2 }];
		return (
			<div>


				<FieldModal
					size="large"
					isOpen={isOpen}
					modalTitle={"Send To"}
					bodyHeaderText={'Choose recipients from attendees list, groups or sessions.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div className=''>
								{/* <TabsContainer
									tabs={[{ title: 'Attendees' }, { title: 'Groups' }, { title: 'Sessions' }]}
									updateSelected={this.updateSelected}
									selectedIndex={selectedSection}
								/> */}
								<div style={{}} className='mb-10 flex'>
									<div style={{ width: 158, marginLeft: 1 }}>
										<SelectField
											label={'Select From'}
											value={options[selectedSection]}
											options={options}
											onChange={(val) => {
												this.updateSelected(val.value)
											}}
										/>
									</div>
									<div className=' ml-25 flex aic'>
										<InputField
											classes="m-0 viewSearch"
											placeholder="Search "
											clickIcon={true}
											required={true}
											value={searchTerm}
											onChange={(e) => {
												clearTimeout(this.searchTimeout);
												this.setState({ searchTerm: e.target.value },
													() => {
														this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
													});

											}}
											prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
											inputIcon={searchTerm != '' &&
												<i
													onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
													//onClick={() => this.setState({ searchTerm: '', searching: false })}
													className="las la-times-circle"
													style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
												/>}
										/>
									</div>

								</div>
								{this.renderSelectedTabContent()}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? (
						`Add Recipients ${selectedRecipientsCount == 0 ? '' : `(${selectedRecipientsCount})`}`
					) : (
						'Adding...'
					)}
					actionButton={() => this.updateRecipients()}
					actionButtonDisabled={submitting}
					isFullWidthBody={true}


				></FieldModal>
			</div>

		);
	}
}

export default AddRecipientsModal;
