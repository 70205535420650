import React, { Component } from 'react';
import Loading from './spinner';

class LoadingWizard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			text: props.text || ''
		};
	}

	render() {
		const { text } = this.state;
		return <Loading text={text} loading={this.props.loading} />;
	}
}

export default LoadingWizard;
