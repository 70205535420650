import React, { Fragment } from 'react';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteDefaultModal extends React.Component {
	state = {
		submitting: false
	};
	render() {
		const { toggle, isOpen, title, message, submit } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={title}
					bodyHeaderText={message}
					bodyContent={() => {
						return ("")
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => submit()}
					actionButtonDisabled={submitting}
					isDelete={true}

				></FieldModal>


			</div>

		);
	}
}

export default DeleteDefaultModal;
