import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../assets/img/logos/simple events logo.png';
import TopLevelNavLink2 from '../components/nav/topLevelNavLink2';
import PromotionCarousel from '../components/custom/promotionCarousel';
import SystemToolbar from '../components/nav/systemToolbar';

const promotionTiles = [
    {
        title: "Version 4.0 is here!",
        description: "Discover the new features and upgrades included in the latest release.",
        action: {
            text: "What's new",
            click: () => { }
        }
    },
    {
        title: "Build unforgettable experiences",
        description: "Upgrade to Enterprise Premium to unlock all add on features.",
        action: {
            text: "Upgrade",
            click: () => { }
        }
    },
    {
        title: "Master the platform",
        description: "Learn how to use the platform to its full potential.",
        action: {
            text: "Learn",
            click: () => { }
        }
    },
]
class TopLevelLayout extends PureComponent {
    constructor(props) {
        super(props);
        const { permission, subscriptionAddons, subscriptionType } = props;
        let manageNavigation = [
            {
                adminOnly: false,
                name: 'Events',
                icon: <i className="las la-calendar" />,
                section: 'events'
            },
            {
                adminOnly: false,
                name: 'Programs',
                icon: <i className="las la-stream" />,
                section: 'programs',
                addon: 'requests'
            },
            {
                adminOnly: true,
                name: 'Contacts',
                icon: <i className="las la-id-badge" />,
                section: 'contacts'
            }
        ];
        let accountNavigation = [{
            adminOnly: true,
            name: 'Pages',
            icon: <i className="las la-laptop-code" />,
            section: 'pages',
            addon: 'account-pages',
        },/*  {
            adminOnly: true,
            name: 'Forms',
            icon: <i className="las la-file-alt" />,
            section: 'forms',
            addon: 'account-pages',
        }, */
        {
            adminOnly: true,
            name: 'Media',
            icon: <i className="las la-photo-video" />,
            section: 'media',
            addon: 'account-pages',
        }]


        accountNavigation = accountNavigation.filter((menuItem) => {
            return (permission || !menuItem.adminOnly) &&
                ((subscriptionType != 'Enterprise Premium' &&
                    menuItem.addon &&
                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                    !menuItem.addon ||
                    subscriptionType == 'Enterprise Premium')
        });

        manageNavigation = manageNavigation.filter((menuItem) => {
            if (menuItem.section == 'programs' && !this.props.programsAvailable) {
                return false;
            }
            return (permission || !menuItem.adminOnly) &&
                ((subscriptionType != 'Enterprise Premium' &&
                    menuItem.addon &&
                    subscriptionAddons.find((sa) => sa.identifier == menuItem.addon)) ||
                    !menuItem.addon ||
                    subscriptionType == 'Enterprise Premium')
        });
        this.state = {
            organization: this.props.match.params.organization,
            loading: true,
            manageNavigation,
            accountNavigation
        };
    }

    async componentDidMount() {
        const sectionCounts = await this.props.getSectionCounts();
        let { manageNavigation, accountNavigation } = this.state;
        manageNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = sectionCounts.top[navItem.section];
            }
        });
        accountNavigation.forEach((navItem) => {
            if (navItem.section) {
                navItem.count = sectionCounts.top[navItem.section];
            }
        });

        this.setState({ manageNavigation, accountNavigation, loading: false });
    }

    render() {
        const { organizations, permission, subscriptionAddons, subscriptionType,
            organization, socket, platformNotifications } = this.props;
        return (
            <div className='application'>
                <div className="topLevelPages">
                    <div className='systemContentContainer'>
                        <SystemToolbar
                            account={this.props.account}
                            organization={organization}
                            organizations={organizations}
                            logout={this.props.logout}
                            displayLogo={true}
                            programsAvailable={this.props.programsAvailable}
                            permission={permission}
                            subscriptionAddons={subscriptionAddons}
                            subscriptionType={subscriptionType}
                            socket={socket}
                            taskCount={this.props.taskCount}
                            updateTaskCount={this.props.updateTaskCount}
                            pingServer={this.props.pingServer}
                            platformNotifications={platformNotifications}
                            markNotificationAsRead={this.props.markNotificationAsRead}
                        />
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(TopLevelLayout);
