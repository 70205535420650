import React, { Component } from 'react';
import { Row } from 'reactstrap';
import axios from 'axios';

class EventDetails extends Component {
	state = {
		html: {
			__html: ''
		}
	};

	componentDidMount() {
		this.fetchPreview();
	}

	fetchPreview = async () => {
		const { email } = this.props;
		axios.defaults.headers.post['Content-Type'] = 'application/json';
		await axios
			.post(email.previewHtml, {
				preview: true
			})
			.then((res) => {
				if (res.data) {
					this.setState({ html: { __html: res.data } });
				}
			});
	};

	render() {
		const { html } = this.state;
		return (
			<div style={{ margin: '10px', marginTop: '20px' }}>
				<div style={{ height: '60vh', overflowX: 'hidden' }}>
					<Row>
						<div className="col-md-6">
							<p style={{ fontWeight: 600, margin: 0 }}>
								This is how the email will display for attendees
							</p>
							<hr style={{ margin: 0, marginBottom: '10px' }} />
							<div dangerouslySetInnerHTML={html} style={{ all: 'initial' }} />
						</div>
					</Row>
				</div>
			</div>
		);
	}
}
export default EventDetails;
