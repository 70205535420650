import React, { Fragment } from 'react';

import FieldModal from '../../modals/fieldModal';
import API from '../../../utils/API';

import InputField from '../../../components/inputs/inputField';

class AddVenueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            submitting: false,
            complete: false,
            requiredFields: {
                name: false,
            }
        };
    }

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value
        });
    };

    submit = async () => {
        const { name } = this.state;
        const { addNewLocation, venue } = this.props;
        let valid = true;
        let requiredFields = this.state.requiredFields;
        if (!name) {
            requiredFields.name = true;
            valid = false;
        } else {
            requiredFields = false;
        }

        if (valid) {

            this.setState({ submitting: true })



            await API().post(`/Organizations/${this.props.orgId}/venues/${venue._id}/location`, { name }).then((res) => {
                if (res.data) {
                    addNewLocation(res.data)
                    this.setState({ submitting: false, complete: true }, () => {
                        this.props.toggle();
                    })
                }
            });


        } else {

            this.setState({ requiredFields: requiredFields })
        }


    }

    render() {
        const { isOpen, toggle, venue } = this.props;
        const { name, submitting, requiredFields, complete } = this.state;
        return (

            <div>
                <FieldModal
                    size="medium"
                    isOpen={isOpen}
                    modalTitle={"Add Location"}
                    bodyHeaderText={venue.name}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <InputField
                                    label="Location Name"
                                    required={true}
                                    disabled={complete}
                                    errorTrigger={requiredFields.name}
                                    value={name}
                                    type="name"
                                    onChange={this.handleNameChange}
                                />
                            </div>)
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting && !complete ? 'Submit' : submitting && !complete ? 'Adding...' : complete ? 'Complete' : ''}
                    actionButton={() => this.submit()}
                    actionButtonDisabled={submitting || complete}
                    cancelButtonLabel={complete ? 'Close' : 'Cancel'}


                ></FieldModal>

            </div>

        );
    }
}

export default AddVenueModal;
