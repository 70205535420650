import React, { Component, Fragment } from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import EventGroup from './group';
import AddGroupModal from './modals/addGroupModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import DeleteGroupModal from './modals/deleteDefaultModal';

let batchActionCB;

class EventGroups extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			capacity: 'Capacity',
			attendeeCount: 'Attendees',
			sessionCount: 'Sessions'
		};
		const defaultFieldTypes = {
			name: 'text',
			capacity: 'number',
			attendeeCount: 'number',
			sessionCount: 'number'
		};
		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'capacity',
					'attendeeCount',
					'sessionCount'
				]
			},
		};
		const normalizedColumns = Object.keys(customFieldColumns).map((cfc) => {
			return {
				label: customFieldColumns[cfc],
				key: cfc,
				type: typeof customFieldColumns[cfc]
			};
		});

		this.state = {
			programId: props.programId,
			categoryColumns: fieldCategories,
			newGroups: [],
			checkedGroups: [],
			groupSelected: false,
			searchTerm: '',
			searchField: '',
			addGroupModalOpen: false,
			deleteGroupsModalOpen: false,
			newGroup: {},
			toggledColumns: { name: false, capacity: true, attendees: true, sessions: true },
			sortByColumn: { name: true },
			loading: false,
			normalizedColumns: normalizedColumns,
			deletedGroups: [],
			customFieldColumns: customFieldColumns,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			sidePanelOpen: false,
			editCount: 0,
			activeRow: '',
		};
	}

	componentDidMount() {
		this.setState({ loading: false })
	}

	addGroup = (group, cb) => {
		this.props.addGroup(group, () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				cb && cb();
			})
		});
	};


	toggleAddGroup = () => {
		this.setState({
			addGroupModalOpen: !this.state.addGroupModalOpen
		});
	};

	toggleDeleteGroups = (checkedGroups, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedGroups: checkedGroups || this.state.checkedGroups,
			deleteGroupsModalOpen: !this.state.deleteGroupsModalOpen
		});
	};
	deleteCheckedGroups = () => {
		this.props.deleteGroups(Object.keys(this.state.checkedGroups), () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
				this.toggleDeleteGroups();
			});
		});
	};

	deleteSingleGroup = (group) => {
		this.props.deleteGroups(Object.keys(group), () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
			});
		});
	};

	saveGroup = (group, cb) => {
		this.props.saveGroup(group, () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				cb && cb();
			})
		});

	}
	setActiveRow = (groupId) => {
		this.setState({ activeRow: groupId })
	}

	render() {
		const {
			programId,
			categoryColumns,
			deletedGroups,
			columns,
			columnTypes,
			newGroups,
			checkedGroups,
			deleteGroupsModalOpen,
			addGroupModalOpen,
			editCount,
			sidePanelOpen,
			loading,
			activeRow,
		} = this.state;
		const { program, groups, orgId, groupsViews } = this.props;
		const checkedCount = Object.keys(checkedGroups).length;
		return (
			<Switch>
				<Route path={`/${orgId}/programs/${programId}/groups`}>
					<Fragment>
						<ViewsContainer
							//key={`groups${editCount}`}
							activeRow={activeRow}
							categoryColumns={categoryColumns}
							eventId={programId}
							orgId={orgId}
							views={groupsViews}
							columns={columns}
							columnTypes={columnTypes}
							entries={groups}
							addedEntries={newGroups}
							deletedEntries={deletedGroups}
							title="Default Event Groups"
							description="Groups listed here will be added to all approved events. Add attendees and session to groups to create custom tracks. "
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddGroup, label: 'Add Group' },
							]}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteGroups
								}
							]}
							enableDownloadViews={true}
							type={'Group'}
							referenceCollection={'Programs'}
							referenceId={programId}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>
						<DeleteGroupModal
							isOpen={deleteGroupsModalOpen}
							toggle={() => this.toggleDeleteGroups()}
							title={`Delete ${checkedCount > 1 ? 'Groups' : 'Group'}`}
							message={`You are about to delete ${checkedCount} ${checkedCount > 1
								? 'groups'
								: 'group'}, this is irreversible,
						would you like to continue?`}
							submit={() =>
								this.deleteCheckedGroups(Object.keys(checkedGroups), () => {
									this.setState({ checkedGroups: {}, deleteGroupsModalOpen: false });
								})}
						/>

						{addGroupModalOpen && <AddGroupModal
							isOpen={addGroupModalOpen}
							handleSubmit={this.addGroup}
							toggle={this.toggleAddGroup}
							programId={programId}
							orgId={this.props.orgId}
						/>}


						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/programs/:programId/groups/:groupId`}
									render={(props) => {
										const groupId = props.match.params.groupId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={groupId}>

											{!loading && <EventGroup
												deleteGroup={this.deleteSingleGroup}
												program={this.props.program}
												programId={programId}
												orgId={this.props.orgId}
												groups={groups}
												saveGroup={this.saveGroup}
												setActiveRow={this.setActiveRow}
											/>}
											{/* <LoadingWizard loading={loading} /> */}
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/programs/:programId/groups`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>




					</Fragment>
				</Route>


			</Switch>
		);
	}
}

export default withRouter(EventGroups);
