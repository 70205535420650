import React from 'react';
import moment from 'moment';
import Scheduler from '../../../../utils/Scheduler';

import InputField from '../../../../components/inputs/inputField';
import TimeField from '../../../../components/inputs/timeField';

import FieldModal from '../../../../components/modals/fieldModal';
import SelectField from '../../../../components/inputs/selectField';

const timingOptions = [
	{ value: "beforeStart", label: "Before Session Starts" },
	{ value: "beforeEnd", label: "Before Session Ends" },
	{ value: "afterStart", label: "After Session Starts" },
	{ value: "afterEnd", label: "After Session Ends" },
]

class ScheduleNotificationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			useSessionTiming: false,
			sending: false,
			invalid: false,
			attendees: [],
			sessions: [],
			groups: [],
			error: '',
			sentCount: 0,
			completed: false,
			totalEmails: 0,
			eventSessions: [],
			eventGroups: [],
			eventAttendees: [],
			date: '',
			time: '',
			timing: '',
			timingValue: 0,
			isDateBefore: false,
			requiredFields: {
				date: false,
				time: false
			}
		};
	}

	componentDidMount() {
		const { notification, event } = this.props;
		let recipients = notification.recipients || {};
		let eventGroups = event.groups.filter((g) => recipients.groups.includes(g._id));

		let recipientModel = '';
		let uniqueAttendees = [];

		if (recipients.attendees.length > 0) {
			recipientModel = 'attendees';
		} else if (recipients.sessions.length > 0) {
			recipientModel = 'sessions';
		} else if (recipients.groups.length > 0) {
			recipientModel = 'groups';
		}

		switch (recipientModel) {
			case 'attendees':
				uniqueAttendees = [...recipients.attendees];
				break;
			case 'sessions':
				let sessionsToSend = [...event.sessions.filter(s => recipients.sessions.includes(s._id))];
				for (let si = 0; si < sessionsToSend.length; si++) {
					let session = sessionsToSend[si];
					if (session.isGeneral) {
						uniqueAttendees = [];
						let attendeeIds = event.attendees.map(a => a._id);
						uniqueAttendees = [...uniqueAttendees, ...attendeeIds];
						break;
					}
					uniqueAttendees = [...uniqueAttendees, ...session.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			case 'groups':
				let groupsToSend = [...event.groups.filter(g => recipients.groups.includes(g._id))];
				for (let gi = 0; gi < groupsToSend.length; gi++) {
					let group = groupsToSend[gi];
					uniqueAttendees = [...uniqueAttendees, ...group.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			default:
				break;
		}

		this.setState({
			attendees: recipients.attendees,
			sessions: recipients.sessions,
			groups: recipients.groups,
			eventGroups: eventGroups
		});
	}

	handleDateChange = (val) => {
		this.setState({
			date: val
		});
	};
	handleTimeChange = (val) => {
		this.setState({
			time: val
		});
	};

	scheduleNotification = async () => {
		const { date, time, timing, timingValue, useSessionTiming } = this.state;

		const { event, orgId, notification, update, toggle } = this.props;
		let requiredFields = this.state.requiredFields;

		let timezone = event.timezone;
		let valid = true;
		let data = {
			useSessionTiming,
			timezone: timezone
		}
		if (!useSessionTiming) {
			if (!date && date == '') { requiredFields.date = true; valid = false; } else { requiredFields.date = false; }
			if (!time && time == '') { requiredFields.time = true; valid = false; } else { requiredFields.time = false; }
			data.date = date;
			data.time = time;
		} else {
			if (!timing && timing == '') { requiredFields.timing = true; valid = false; } else { requiredFields.timing = false; }
			if (!timingValue && timingValue == '') { requiredFields.timingValue = true; valid = false; } else { requiredFields.timingValue = false; }
			data.timing = timing;
			data.timingValue = timingValue;
		}
		if (valid) {
			const currentTimeDate = new Date(moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a'));
			const selectedDate = new Date(`${date} ${time}`);

			if (selectedDate >= currentTimeDate || useSessionTiming) {
				this.setState({ isDateBefore: false })
				try {
					const res = await Scheduler()
						.post(`Organizations/${orgId}/notification/${notification._id}/scheduleNotification`, data)
						.then((res) => {
							update(
								{
									sendDate: date,
									sendTime: time,
									timezone: timezone,
									scheduled: true,
									status: 'Scheduled'
								},
								() => {
									toggle();
									this.props.runCheck();
								}
							);
						});
				} catch (err) {
					console.log(err);
				}

			} else {

				this.setState({ isDateBefore: true })
			}

		} else {
			this.setState({ requiredFields: requiredFields })

		}

	};

	toggleUseSessionTiming = () => {
		this.setState({
			useSessionTiming: !this.state.useSessionTiming
		});
	}
	render() {
		const { isOpen, toggle, event, recipientModel, recipientCount, recipientModelElementCount } = this.props;
		const {
			useSessionTiming,
			sending,
			attendees,
			sessions,
			groups,
			completed,
			sentCount,
			eventGroups,
			totalEmails,
			date,
			requiredFields,
			time, isDateBefore,
			timing,
			timingValue
		} = this.state;
		const fixedStartDate = moment(`${date}`).format('YYYY-MM-DD');
		const currentDateTimezone = moment().tz(event.timezone).format('YYYY-MM-DD');

		return (

			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Schedule Notification"}
					bodyHeaderText={<div>{!useSessionTiming ?
						<span>Schedule your notification to be sent at a specific time in the future{recipientModel === 'Session' && <span>, or <span onClick={this.toggleUseSessionTiming} className='seBlue c-pointer'>based off session timing </span></span>}</span> :
						<span>Schedule your notification based on session timing or <span className='seBlue c-pointer' onClick={this.toggleUseSessionTiming}>choose a specific time in the future</span></span>}
						<br /><span>Current time: {moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a')} {moment().tz(event.timezone).zoneAbbr()}</span>
					</div>
					}
					bodyContent={() => {
						return (
							<div>
								{recipientModel === "Session" ? <div>
									<span >Send to </span>
									{recipientCount} Attendees in {recipientModelElementCount} Session{recipientModelElementCount > 1 ? 's' : ''}
									<span> at:</span>
								</div> : <div>
									<span >Send to </span>
									{attendees && attendees.length !== 0 ? (
										`${attendees.length} Attendee${attendees.length > 1 ? 's' : ''}`
									) : sessions && sessions.length !== 0 ? (
										`${sessions.length} Session${sessions.length > 1 ? 's' : ''}`
									) : groups && groups.length !== 0 ? (
										eventGroups.map((eg) => {
											return eg.name + ', ';
										})
									) : (
										'Nothing selected'
									)}
									<span> on:</span>
								</div>}
								{useSessionTiming ? <div className='flex pt-10'>
									<InputField
										label={'Minutes'}
										classes={'mr-25'}
										value={timingValue}
										required={true}
										errorTrigger={requiredFields.timingValue}
										type={'number'}
										onChange={(e) => {
											this.setState({ timingValue: e.target.value });
										}}
									/>
									<SelectField
										label={'Timing'}
										value={timingOptions.find(t => t.value === timing)}
										required={true}
										errorTrigger={requiredFields.timing}
										options={timingOptions}
										onChange={(e) => {
											this.setState({ timing: e.value });
										}}
									/>
								</div> : <div style={{ display: 'flex', paddingTop: 10 }}>
									<InputField
										label={'Date'}
										value={fixedStartDate}
										required={true}
										errorTrigger={requiredFields.date}
										type={'date'}
										inputIcon={<i className="lar la-calendar" />}
										onChange={(e) => {
											this.handleDateChange(moment(e.target.value).format('MM/DD/YYYY'));
										}}
										min={currentDateTimezone}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '0 10px'
										}}
									>
										at
									</div>
									<TimeField
										label={'Send Time'}
										value={time}
										required={true}
										errorTrigger={requiredFields.time}
										onChange={(e) => {
											this.handleTimeChange(e);
										}}
									/>
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: '0 10px'
										}}
									>
										{moment().tz(event.timezone).zoneAbbr()}
									</div>
								</div>}
								{isDateBefore && <div style={{ paddingTop: 5, color: '#b90000', height: 'unset' }}>
									Scheduled time must be after the current time at the event's timezone.
								</div>}
								<div style={{ paddingTop: 5, paddingBottom: 25, height: 'unset' }}>
									{sending && `${!completed ? 'Sending' : 'Sent'} ${sentCount} of ${totalEmails} attendees`}
								</div>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Schedule Notification' : completed ? 'Complete' : 'Sending...'}
					actionButton={() => {
						this.scheduleNotification();
					}}
					actionButtonDisabled={sending || completed}
					isFullWidthBody={false}
					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}


				></FieldModal>
			</div>

		);
	}
}

export default ScheduleNotificationModal;
