import React from 'react';
import API from '../../utils/API';

import 'react-datetime/css/react-datetime.css';
import { Modal, ModalBody } from 'reactstrap';
import cityTimezones from 'city-timezones';
import moment from 'moment-timezone';
import conference from '../../assets/img/event/conference.jpg'
import virtual from '../../assets/img/event/virtual.jpeg'
import hybrid from '../../assets/img/event/hybrid.jpeg'

import InputField from '../../components/inputs/inputField';
import SelectField from '../../components/inputs/selectField';
import TimeField from '../../components/inputs/timeField';
import ExpandableListItem from '../../components/list/expandableListItem';
import ToggleField from '../../components/inputs/toggleField';
import Dropzone from 'react-dropzone';
import ColorField from '../../components/inputs/colorField';
import LocationLookup from '../../components/inputs/locationLookup';
import CheckboxInput from '../../components/inputs/checkboxInput';
import { UncontrolledTooltip } from "reactstrap";


const defaultPermissions = {
	details: { label: "Details", slug: 'details', write: true, read: true },
	attendees: { label: "Attendees", slug: 'attendees', write: true, read: true },
	schedule: { label: "Schedule", slug: 'schedule', write: true, read: true },
	groups: { label: "Groups", slug: 'groups', write: true, read: true },
	inventory: { label: "Inventory", slug: 'inventory', write: true, read: true, addon: 'inventory' },
	emails: { label: "Emails", slug: 'emails', write: true, read: true },
	texting: { label: "Texting", slug: 'texting', write: true, read: true, addon: 'texting' },
	forms: { label: "Forms", slug: 'forms', write: true, read: true },
	pages: { label: "Pages", slug: 'pages', write: true, read: true },
	engagement: { label: "Engagement", slug: 'engagement', write: true, read: true, addon: 'engagement' },
	media: { label: "Media", slug: 'media', write: true, read: true },
	insights: { label: "Insights", slug: 'insights', write: true, read: true },
}



let dropzoneBannerRef;
let dropzoneLogoRef;
class AddEventModal extends React.Component {
	constructor(props) {
		super(props);

		const eventBranding = {
			accentColor: props.organization.branding.accentColor,
			fontColor: props.organization.branding.fontColor,
			backgroundColor: props.organization.branding.backgroundColor,
			logo: { name: "Organization Logo", preview: props.organization.branding.logo },
		};

		const salesforceIntegration = props.organization.integrations.find((integration) => integration.identifier == 'salesforce');
		let sfRegStatus = 'Registered';
		let sfCheckinStatus = 'Checked In';
		let sfNotAttendingStatus = 'Did Not Attend';
		if (salesforceIntegration) {

			salesforceIntegration.requiredFields.forEach((field) => {
				if (field.id === 'registeredStatus') sfRegStatus = field.value;
				if (field.id === 'checkedinStatus') sfCheckinStatus = field.value;
				if (field.id === 'didNotAttendStatus') sfNotAttendingStatus = field.value;
			});
		}

		this.state = {
			sectionCount: 5,
			currentSection: 0,
			nextSectionEnabled: false,
			event: {
				experienceType: '',
				name: '',
				startDate: moment().format('MM/DD/YYYY'),
				endTime: '5:00 PM',
				startTime: '8:00 AM',
				endDate: moment().format('MM/DD/YYYY'),
				timezone: '',
				enableMeetingPassword: false,
				enableSSOLogin: false,
				enablePassthroughPassword: false,
				meetingPassword: '',
				SSOLoginUrl: '',
			},
			cityLookup: '',
			requiredFields: {
				name: false,
				startDate: false,
				endDate: false,
				timezone: false,
			},
			selectedVenue: null,
			eventBranding: eventBranding,
			submitting: false,
			invalid: false,
			locations: props.locations,
			eventTeam: [],
			searchTeam: "",
			venueMap: null,
			lng: -70.9,
			lat: 42.35,
			zoom: 9,
			files: [],
			salesforceEnabled: salesforceIntegration ? true : false,
			salesforceToggled: false,
			salesforceCampaign: '',
			salesforceRegistrationStatus: sfRegStatus,
			salesforceCheckedinStatus: sfCheckinStatus,
			salesforceNotAttendingStatus: sfNotAttendingStatus,
			allowPublicRegistrations: null,
			pageSelection: {
				defaultPages: {
					label: 'Default Pages',
					pages: [
						{ label: 'Login', addPage: true },
						{ label: 'Registration', addPage: true },
						{ label: 'Profile', addPage: true },
						{ label: 'Session', addPage: true },
					]
				},
				/* registrationPages: {
					label: 'Registration',
					pages: [
						{ label: 'Registration Landing Page', addPage: false },
						{ label: 'Hotel and Travel', addPage: false },
						{ label: 'Activities', addPage: false },
						{ label: 'FAQ', addPage: false }
					]
				},
				eventPages: {
					label: 'Event Website',
					pages: [
						{ label: 'Home', addPage: false },
						{ label: 'Agenda', addPage: false },
						{ label: 'Feed', addPage: false },
						{ label: 'Support', addPage: false },
						{ label: 'Venue and Maps', addPage: false },
			
					]
				},
				onSitePages: {
					label: 'On Site',
					pages: [
						{ label: 'Check in App', addPage: false },
					]
				}, */

			},
			expandedCategories: ['defaultPages'],
			currentMember: null
		};

		this.mapContainer = React.createRef();
	}

	async componentDidMount() {
		const { organization } = this.props;
		await API().get(`Organizations/${organization._id}/getTemplates`).then((res) => {
			let platformTemplates = [];

			if (res.data) {
				let formattedTemplates = this.state.pageSelection;
				let pt = res.data;

				pt.forEach((template) => {
					if (template.category) {
						if (!formattedTemplates[template.category]) {
							formattedTemplates[template.category] = {};
							formattedTemplates[template.category].label = template.category;
							formattedTemplates[template.category].pages = [];
							formattedTemplates[template.category].pages.push({ label: template.title, value: template._id, });
						} else {
							formattedTemplates[template.category].pages.push({ label: template.title, value: template._id });
						}
					} else {
						if (!formattedTemplates['General']) {
							formattedTemplates['General'] = {};
							formattedTemplates['General'].label = 'General';
							formattedTemplates['General'].pages = [];
							formattedTemplates['General'].pages.push({ label: template.title, value: template._id });
						} else {
							formattedTemplates['General'].pages.push({ label: template.title, value: template._id });
						}
					}
				});
				platformTemplates = formattedTemplates;
			}
			this.setState({ pageSelection: platformTemplates });
		});


	}



	onDrop = (files) => {
		this.setState({
			eventBranding: {
				...this.state.eventBranding,
				header: files[0]
			}
		});
	};


	onDropLogo = (files) => {
		this.setState({
			eventBranding: {
				...this.state.eventBranding,
				logo: files[0]
			}
		});
	};

	handleNewVenueChange = (e) => {
		const newVenue = {
			label: 'New Location: ' + e,
			color: '#00B8D9',
			new: true
		};
		if (e !== '') {
			this.setState({
				locations: [...this.props.locations, newVenue]
			});
		}
	};

	handleChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	handleStartDateChange = (e) => {
		this.setState({
			startDate: moment(e.target.value).format('MM/DD/YYYY')
		});
	};

	handleEndDateChange = (e) => {
		this.setState({
			endDate: moment(e.target.value).format('MM/DD/YYYY')
		});
	};

	handleStartTimeChange = (e) => {
		this.setState({
			startTime: moment(
				`${this.state.startDate !== '' ? this.state.startDate : moment().format('MM/DD/YYYY')} ${e.target
					.value}`
			).format('hh:mm a')
		});
	};

	handleEndTimeChange = (e) => {
		this.setState({
			endTime: moment(
				`${this.state.endDate !== '' ? this.state.endDate : moment().format('MM/DD/YYYY')} ${e.target.value}`
			).format('hh:mm a')
		});
	};

	handleTimezoneChange = (timezone) => {
		this.setState({
			timezone: timezone.value
		});
	};

	handleLocationChange = (location) => {
		if (location.new) {
			this.setState({
				venue: { ...location, label: location.label.split('New Location: ')[1] },
				isNewVenue: true
			});
		} else {
			this.setState({
				venue: location,
				isNewVenue: false
			});
		}
	};

	resetAdd = (e, toggle) => {
		this.setState({
			name: '',
			startDateTime: '',
			endDateTime: '',
			venue: '',
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	createEvent = () => {
		const { handleSubmit } = this.props;
		const { event, selectedVenue, eventBranding, eventTeam, salesforceToggled, salesforceCampaign } = this.state;
		const uploads = [];
		/* if (eventBranding.header) {
			uploads.push({ file: eventBranding.header, identifier: 'headerImage' })
		}
		if (eventBranding.logo && eventBranding.logo.preview && eventBranding.logo.preview.startsWith('blob:')) {
			uploads.push({ file: eventBranding.logo, identifier: 'logo' })
		}
		event.branding = {
			accentColor: eventBranding.accentColor,
			fontColor: eventBranding.fontColor,
			backgroundColor: eventBranding.backgroundColor,
		}; */

		event.members = eventTeam;

		if (salesforceToggled) {
			event.integrations = {
				salesforce: {
					campaignName: salesforceCampaign,
					registrationStatus: this.state.salesforceRegistrationStatus,
					checkinStatus: this.state.salesforceCheckedinStatus,
					notAttendedStatus: this.state.salesforceNotAttendingStatus,
				}
			}
		}

		//need to pass all page id to the event object
		let selectedPages = [];
		Object.keys(this.state.pageSelection).forEach((key) => {
			this.state.pageSelection[key].pages.forEach((page) => {
				if (page.addPage && page.value) {
					selectedPages.push(page.value);
				}
			});
		});
		event.pages = selectedPages;

		this.setState({ submitting: true });
		if (selectedVenue) {
			if (!selectedVenue.platform) {
				handleSubmit(event, selectedVenue, false, uploads);
			} else {
				handleSubmit({ ...event, venue: selectedVenue.value }, null, false, uploads);
			}
		} else {
			handleSubmit(event, null, false, uploads);
		}
	};

	chooseEventType = (type) => {
		this.setState({
			event: {
				...this.state.event,
				experienceType: type
			},
			nextSectionEnabled: true
		});
	};

	nextSection = () => {
		const { currentSection } = this.state;
		/* if (currentSection + 1 === 1) {
			this.initializeGeocoder();
			this.initializeMap();
		} */
		let nextSectionEnabled = false;
		let excludedSections = [3]

		if (excludedSections.includes(currentSection + 1)) {
			nextSectionEnabled = true;
		}

		this.setState({
			currentSection: currentSection + 1,
			nextSectionEnabled
		});
	}

	prevSection = () => {
		const { currentSection } = this.state;
		this.setState({
			currentSection: currentSection - 1,
			nextSectionEnabled: true
		});
	}

	updateEventField = (field, value) => {
		const { event, requiredFields, salesforceToggled } = this.state;
		event[field] = value;
		let requiredFieldsAcquired = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!event[key] || event[key] === '') {
				requiredFieldsAcquired = false;
			}
		});
		if (salesforceToggled) {
			if (!this.state.salesforceCampaign || this.state.salesforceCampaign === '') {
				requiredFieldsAcquired = false;
			}
		}





		this.setState({ event, nextSectionEnabled: requiredFieldsAcquired, requiredFields });
	}

	updateRole = (role, index) => {
		let eventTeam = this.state.eventTeam;
		eventTeam[index].role = role.value;
		this.setState({ eventTeam: eventTeam })
	}

	errorCheckerEventAccessSection = () => {
		const { event } = this.state;
		const { enableMeetingPassword, meetingPassword, enableSSOLogin, SSOLoginUrl, enablePassthroughPassword } = event;

		let valid = true;

		if (enableMeetingPassword) {
			if (meetingPassword === '') {
				valid = false;
			}
		}

		if (enableSSOLogin) {
			if (SSOLoginUrl === '') {
				valid = false;
			}
		}

		this.setState({ nextSectionEnabled: valid });


	}

	renderSection = (section) => {
		const { currentSection,
			event,
			cityLookup,
			requiredFields,
			pageSelection,
			expandedCategories,
			currentMember,
			salesforceEnabled
		} = this.state;
		const { name,
			startDate,
			endDate,
			startTime,
			endTime,
			timezone,
			experienceType,
			enableMeetingPassword,
			enableSSOLogin,
			enablePassthroughPassword,
			allowPublicRegistrations,
			meetingPassword,
			SSOLoginUrl
		} = event;

		switch (section) {
			case 0:
				// event type picker
				return <div className={`sectionBody w-100 posAbs anim left ${section == currentSection ? '' : 'closed'}`}>
					<div style={{ textAlign: 'center' }}>
						<h1>Create New Event</h1>
						<div className='mb-20  mt-10'>
							<p className='mb-10'>What type of event do you want to plan?</p>

						</div>
					</div>

					<div className='flex jcc w-100 flex-wrap' style={{ marginLeft: -10 }}>
						<div className={`contentCard ${experienceType == 'hybrid' ? 'selected' : ''}`}
							onClick={() => this.chooseEventType('hybrid')}
						>
							<div className={`borderContainer`}>
								<div className='w-100 mr-10 ml-10 mt-15 mb-15'>
									<h3>Hybrid Experience</h3>
									<p className='small grey pt-5'>In-person event with virtual interactions hosted at a physical location.</p>
								</div>
								<div className='imageBannerBackground flex-ac j'  >
									<img src={hybrid} style={{ maxWidth: '100%' }} />
								</div>
							</div>


							<div className={`pt-15`}>
								<div className='h-100'>
									<h2 className='mb-10 semibold'>Features:</h2>
									<div className='itemRow '><i className="las la-check-circle" /><p>Attendee rooming management</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>Attendee travel management</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>Attendee verification tools</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>In-App attendee engament</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>Session survey and reviews</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>Livestream management studio</p></div>
								</div>
							</div>
						</div>

						<div className={`contentCard ${experienceType == 'in-person' ? 'selected' : ''}`}
							onClick={() => this.chooseEventType('in-person')}
						>
							<div className="borderContainer">
								<div className='w-100 mr-10 ml-10 mt-15 mb-15'>
									<h3>In-Person Experience</h3>
									<p className='small grey pt-5'>Event hosted at a physical location and guests attend in person.</p>
								</div>
								<div className='imageBannerBackground flex-ac j'  >
									<img src={conference} style={{ maxWidth: '100%' }} />
								</div>
							</div>



							<div className={`pt-15`}>
								<div className='h-100'>
									<h2 className='mb-10 semibold'>Features:</h2>
									<div className='itemRow '><i className="las la-check-circle" /><p>Attendee rooming management</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>Attendee travel management</p></div>
									<div className='itemRow '><i className="las la-check-circle" /><p>Attendee verification tools</p></div>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>In-App attendee engament</p></div>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>Session survey and reviews</p></div>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>Livestream management studio</p></div>

								</div>
							</div>
						</div>

						<div className={`contentCard ${experienceType == 'virtual' ? 'selected' : ''}`}
							onClick={() => this.chooseEventType('virtual')}
						>
							<div className={`borderContainer`}>
								<div className='w-100 mr-10 ml-10 mt-15 mb-15'>
									<h3>Virtual Experience</h3>
									<p className='small grey pt-5'>Pre-recorded event or streamed event. Guests attend virtually.</p>
								</div>
								<div className='imageBannerBackground flex-ac j'  >
									<img src={virtual} style={{ maxWidth: '100%' }} />
								</div>
							</div>
							<div className={`pt-15`}>
								<div className='h-100'>
									<h2 className='mb-10 semibold'>Features:</h2>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>Attendee rooming management</p></div>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>Attendee travel management</p></div>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>Attendee verification tools</p></div>
									<div className='itemRow notIncluded'><i className="las la-times-circle" /><p>In-App attendee engament</p></div>
									<div className='itemRow'><i className="las la-check-circle" /><p>Session survey and reviews</p></div>
									<div className='itemRow'><i className="las la-check-circle" /><p>Livestream management studio</p></div>
								</div>
							</div>
						</div>
					</div>

					{/* <div className='text-c mb-50 mt-50 ph-20'>
						<h3 className='mb-10'>Don't know what to pick?</h3>
						<p>Use the chat widget on the bottom right to talk to our team about your needs.</p>
					</div> */}
				</div >
			case 1:
				const cityTimezoneOptions = cityLookup != ''
					? cityTimezones.findFromCityStateProvince(cityLookup).map((tz) => {
						return { label: `${tz.city}, ${tz.province}`, value: tz.timezone };
					})
					: [];



				// event details
				return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
					<div style={{ textAlign: 'center' }}>
						<h1>Event Information</h1>
						<div className='mb-20  mt-10'>
							<p className='mb-10'>Please enter the information below to set up your {experienceType == 'in-person' ? 'In-Person' : experienceType == 'virtual' ? 'Virtual' : 'Hybrid'} experience</p>

						</div>
					</div>

					<div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 96px)' }}>
						<div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1, paddingTop: 30 }}>
							<h3 className='blockHeader mb-20'>Basic Information</h3>
							<InputField
								label={'Event Name'}
								required={true}
								errorTrigger={requiredFields.name}
								value={name}
								onChange={(e) => {

									this.updateEventField('name', e.target.value);
								}}
							//subText={`URL: https://${this.props.organization.identifier}.simple.events/${name.replace(/[^A-Za-z0-9]/g, '')}`}
							/>


							<div className="flex-sb w-100">
								<div className="mr-10 w-100">
									<InputField
										label={'Start Date'}
										//value={moment(startDate).format('yyyy-MM-DD')}
										defaultValue={moment(startDate).format('yyyy-MM-DD')}
										min={moment().format('yyyy-MM-DD')}
										required={true}
										errorTrigger={requiredFields.startDate}
										type={'date'}
										inputIcon={<i className="lar la-calendar" />}
										onChange={(e) => {
											this.updateEventField('startDate', moment(e.target.value).format('MM/DD/YYYY'));
										}}
									/>
								</div>

								<div className="ml-10 w-100">
									<InputField
										label={'End Date'}
										//value={moment(endDate).format('yyyy-MM-DD')}
										defaultValue={moment(endDate).format('yyyy-MM-DD')}
										min={moment().format('yyyy-MM-DD')}
										required={true}
										errorTrigger={requiredFields.endDate}
										type={'date'}
										inputIcon={<i className="lar la-calendar" />}
										onChange={(e) => {
											this.updateEventField('endDate', moment(e.target.value).format('MM/DD/YYYY'));
										}}
									/>
								</div>
							</div>
							<div className="flex-sb w-100">
								<div className="mr-10 w-100">
									<TimeField
										label={'Start Time'}
										value={startTime}
										errorTrigger={requiredFields.startTime}
										onChange={(time) => {
											this.updateEventField('startTime', time);
										}}
									/>
								</div>

								<div className="ml-10 w-100">
									<TimeField
										label={'End Time'}
										value={endTime}
										errorTrigger={requiredFields.endTime}
										onChange={(time) => {
											this.updateEventField('endTime', time);
										}}
									/>
								</div>
							</div>
							{(experienceType == 'in-person' || experienceType == 'hybrid') && (
								<LocationLookup
									selectedPlace={this.state.selectedVenue}
									locations={this.props.locations}
									label="Location Finder"
									placeholder={<p className='grey'><i>Search for the venue where the event will take place</i></p>}
									noOptionsMessage="No results" onSelect={(location) => {
										if (location.timezone) {
											this.updateEventField('timezone', location.timezone);
										}
										this.setState({ selectedVenue: location })
									}}
									classes="w-100"
								/>
							)}

							<SelectField
								label={'Timezone'}
								value={{
									value: timezone, label: <p>
										{timezone}
										<span className='grey'><i>{timezone ? ` (${moment().tz(timezone).zoneAbbr()})` : ''}</i></span>
									</p>
								}}
								placeholder="Search for nearest city"
								name="timezones"
								classes="w-100"
								options={cityTimezoneOptions}
								required={true}
								errorTrigger={requiredFields.timeZone}
								onInputChange={(text) => {
									this.setState({ cityLookup: text });
								}}
								noOptionsMessage={({ inputValue }) =>
									!inputValue ? 'Type city name to get options' : 'No cities match your search'}
								onChange={(timezone) => {
									this.updateEventField('timezone', timezone.value);
								}}
							/>
							{salesforceEnabled && <div>
								<h3 className='blockHeader'>Salesforce</h3>
								<p className='small gray  mb-10'>Connect to Salesforce to sync attendee data and create new leads.</p>
								<ToggleField icons={false} label="Enable Salesforce Integration" checked={this.state.salesforceToggled} onChange={(checked) => {
									if (this.state.salesforceToggled) {
										let requiredFieldsAcquired = true;
										Object.keys(requiredFields).forEach((key) => {
											if (!event[key] || event[key] === '') {
												requiredFieldsAcquired = false;
											}
										});
										this.setState({ salesforceToggled: false, nextSectionEnabled: requiredFieldsAcquired, salesforceCampaign: '' });
									} else {
										this.setState({ salesforceToggled: true, nextSectionEnabled: false });
									}
								}} />
								{this.state.salesforceToggled && <div className="w-100">
									<InputField
										label={'Campaign Name'}
										value={this.state.salesforceCampaign}
										placeholder={'Name the Salesforce campaign for this event'}
										required={true}
										errorTrigger={requiredFields.salesforceCampaign}
										inputIcon={<i className="lab la-salesforce" />}
										onChange={(e) => {

											let requiredFieldsAcquired = true;
											Object.keys(requiredFields).forEach((key) => {
												if (!event[key] || event[key] === '') {
													requiredFieldsAcquired = false;
												}
											});
											if (!e.target.value || e.target.value === '') {
												requiredFieldsAcquired = false;
											}
											this.setState({ salesforceCampaign: e.target.value, nextSectionEnabled: requiredFieldsAcquired });
										}}
									/>

									<InputField
										label={'Registration Status'}
										value={this.state.salesforceRegistrationStatus}
										placeholder={'Registered'}
										onChange={(e) => {
											this.setState({ salesforceRegistrationStatus: e.target.value });
										}}
									/>

									<InputField
										label={'Checked In Status'}
										value={this.state.salesforceCheckedinStatus}
										placeholder={'Checked In'}
										onChange={(e) => {
											this.setState({ salesforceCheckedinStatus: e.target.value });
										}}
									/>

									<InputField
										label={'Did Not Attend Status'}
										value={this.state.salesforceNotAttendingStatus}
										placeholder={'Did Not Attend'}
										onChange={(e) => {
											this.setState({ salesforceNotAttendingStatus: e.target.value });
										}}
									/>
								</div>}

							</div>}
						</div>
					</div>
				</div>
			case 2:
				//event access
				return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
					<div style={{ textAlign: 'center' }}>
						<h1>Event Access</h1>
						<div className='mb-20 mt-10'>
							<p className='mb-10'>Choose how attendees will register and access for your event.</p>
						</div>
					</div>

					<div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 96px)' }}>
						<div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1, paddingTop: 30 }}>
							<h3 className='blockHeader mb-20'>Event Access</h3>
							<SelectField
								label={'Registration'}
								classes="w-100"
								name="eventType"
								options={[{ label: 'Invite Only', value: false }, { label: 'Public', value: true }]}
								required={true}
								errorTrigger={requiredFields.allowPublicRegistrations}
								value={allowPublicRegistrations ? { label: 'Public', value: true } : allowPublicRegistrations == false ? { label: 'Invite Only', value: false } : null}
								onChange={(eventType) => {
									this.updateEventField('allowPublicRegistrations', eventType.value);
									this.errorCheckerEventAccessSection();
								}}
								information={
									<div>
										<h3 class="blockHeader">Invite Only</h3>
										<p className='mb-10'>
											Only attendees you have invited can register.
										</p>

										<h3 class="blockHeader">Public Events</h3>
										<p>
											Anyone with the link can register
										</p>

									</div>
								}
							/>

							<h3 className='blockHeader pt-10 mb-20'>Event Login</h3>
							<ToggleField
								label={'Enable Meeting Password'}
								icons={false}
								checked={enableMeetingPassword}
								onChange={(e) => {
									this.setState({ event: { ...event, enableMeetingPassword: e.target.checked, meetingPassword: e.target.checked ? meetingPassword : '' } }, () => {
										this.errorCheckerEventAccessSection();
									})
								}}
							/>
							{enableMeetingPassword && (
								<InputField
									label={'Meeting Password'}
									required={true}
									value={meetingPassword}
									onChange={(e) => {
										this.setState({
											event: { ...event, meetingPassword: e.target.value },
										}, () => {
											this.errorCheckerEventAccessSection();
										});
									}}
								/>
							)}
							<ToggleField
								label={'Enable SSO'}
								icons={false}
								checked={enableSSOLogin}
								onChange={(e) => {
									this.setState({ event: { ...event, enableSSOLogin: e.target.checked, SSOLoginUrl: e.target.checked ? SSOLoginUrl : '' } }, () => {
										this.errorCheckerEventAccessSection();
									})
								}}
							/>
							{enableSSOLogin && (
								<InputField
									label={'SSO URL'}
									className={`editingField`}
									required={true}
									errorTrigger={requiredFields.SSOLoginUrl}
									value={SSOLoginUrl}
									onChange={(e) => {
										this.setState({
											event: { ...event, SSOLoginUrl: e.target.value },
											editing: true
										}, () => {
											this.errorCheckerEventAccessSection();

										});
									}}
								/>
							)}
							<ToggleField
								label={'Enable Magic Link'}
								checked={enablePassthroughPassword}
								icons={false}
								onChange={(e) => {
									this.setState({ event: { ...event, enablePassthroughPassword: e.target.checked } })
								}}
							/>
						</div>
					</div>
				</div>
			case 3:
				//Start your website
				return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>

					<div style={{ textAlign: 'center' }}>
						<h1>Start Your Website</h1>
						<div className='mb-20  mt-10'>
							<p className='mb-10' style={{ maxWidth: 600, margin: 'auto' }}>
								Get a headstart on your event website by selecting the pages you would like you include. Don’t worry, you can always add and remove pages later.
							</p>
						</div>
					</div>

					<div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 96px)' }}>
						<div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1, paddingTop: 30 }}>
							{Object.keys(pageSelection).map((key) => {
								let label = pageSelection[key].label;
								let pages = pageSelection[key].pages;

								return <div style={{
									borderBottom: '1px solid var(--border)',
									padding: '12px 15px 11px 15px',
								}}>
									<div className='flex jcsb'
										onClick={() => {
											expandedCategories.includes(key) ?
												this.setState({ expandedCategories: expandedCategories.filter((cat) => cat !== key) }) :
												this.setState({ expandedCategories: [...expandedCategories, key] })
										}
										}
									>
										<div className="flex aic">
											{label}
											{key == 'defaultPages' &&
												<i
													id={`default${key.replace(/\s/g, '')}`}

													class="grey las la-info-circle informationIcon "
												/>
											}
											{key == 'defaultPages' &&
												<UncontrolledTooltip
													className="tooltip formFieldTooltip"
													//isOpen={true}
													//className="formFieldTooltip"
													placement="right"
													target={`default${key.replace(/\s/g, '')}`}
												>
													These pages are required for this event and cannot be unselected.
												</UncontrolledTooltip>
											}
										</div>
										<div className='gray'><i className={!expandedCategories.includes(key) ? `las la-angle-down` : `las la-angle-up`}></i></div>
									</div>

									{expandedCategories.includes(key) &&
										<div className='pt-10'>
											{pages.map((page) => {
												return <div className='flex aic ' style={{ marginBottom: 5 }}>
													<CheckboxInput
														icons={false}
														left={true}
														style={{ width: 'unset' }}
														checked={page.addPage}
														label={page.label}
														disableHover={true}
														disabled={key === 'defaultPages'}
														onClick={() => {
															let pageSelection = JSON.parse(JSON.stringify(this.state.pageSelection));
															pageSelection[key].pages = pageSelection[key].pages.map((p) => {
																if (p.label == page.label) {
																	p.addPage = !p.addPage;
																}
																return p;
															});

															this.setState({ pageSelection })
														}
														}
													/>
												</div>
											}
											)}
										</div>
									}

								</div>
							})}
						</div>
					</div>
				</div>
			case 4:
				//Add Team Members
				let orgMembers = this.props.organization.members;
				orgMembers = orgMembers.filter((member) => member.permission !== 'admin' && !this.state.eventTeam.some(teamMember => teamMember._id == member._id));
				let adminMembers = this.props.organization.members.filter((member) => member.permission === 'admin');
				return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
					<div style={{ textAlign: 'center' }}>
						<h1>Add Team Members</h1>
						<div className='mb-20  mt-10'>
							<p className='mb-10'>
								All admins can see your event plus all organizers below. Add and mange organizers.
							</p>
						</div>
					</div>
					<div className='flex flex-wrap jcc' style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 80px)' }}>
						<div className={`sectionBlock mr-15 mb-15`} style={{ maxWidth: 600, paddingLeft: 1, paddingTop: 30 }}>
							<SelectField
								label={'Available Organizers'}
								options={orgMembers.map((member) => {

									return { label: member.email, value: member._id }
								})
								}
								value={currentMember}
								onChange={(currentMember) => {
									let eventTeam = this.state.eventTeam;
									let member = this.props.organization.members.find((member) => member._id == currentMember.value);
									eventTeam.unshift({ ...member, permissions: { ...JSON.parse(JSON.stringify(defaultPermissions)) } });
									this.setState({ eventTeam: eventTeam })
								}}

							/>
							<h3 className='pt-20 mb-20'>Team</h3>

							{this.state.eventTeam.length > 0 && <div className='flex flex-wrap'>
								{this.state.eventTeam.map((member, i) => {
									const permissions = member.permissions;
									let permissionCount = 0;
									let readCount = 0;
									let writeCount = 0;
									Object.keys(defaultPermissions).forEach((key) => {
										if (permissions[key].read) {
											readCount++;
										}
										if (permissions[key].write) {
											writeCount++;
										}
										permissionCount++;
									});
									let accessString = '';
									let role = "";
									let countOf = `(${readCount}/${permissionCount})`;
									if (Object.keys(defaultPermissions).length === permissionCount && readCount == permissionCount && writeCount == permissionCount) {
										accessString = 'Full Access';
										role = 'Organizer';
									} else if (Object.keys(defaultPermissions).length === permissionCount && writeCount <= permissionCount && writeCount > 0 && readCount >= writeCount) {
										accessString = `Limited Access to ${writeCount} sections`;
										role = 'Staff';
									} else if (Object.keys(defaultPermissions).length === permissionCount && readCount <= permissionCount && readCount > 0) {
										accessString = `Read Only Access to ${readCount} sections`;
										role = 'Guest';
									} else {
										accessString = 'Will not be added';
										role = 'No Access';
									}
									return <ExpandableListItem className='w-100 mb-20'
										expandedContent={(state) => <div className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} key={'expandedListItem' + i} >
											<div className='flex aic jcsb' style={{ marginBottom: 10 }} >
												<h5>Sections</h5>
												<div className='flex' >
													<ToggleField icons={false} style={{ margin: 0, marginRight: 10, width: 75 }} checked={readCount === permissionCount} label="All" onChange={(e) => {
														const eventTeam = this.state.eventTeam;
														const checked = e.target.checked
														Object.keys(defaultPermissions).forEach((key) => {
															eventTeam[i].permissions[key].read = checked;
															if (!checked) eventTeam[i].permissions[key].write = false;

														});
														this.setState({ eventTeam });
													}} />
													<ToggleField icons={false} style={{ margin: 0, marginLeft: 50, marginRight: 10, width: 75 }} checked={writeCount === permissionCount} label="All" onChange={(e) => {
														const eventTeam = this.state.eventTeam;
														Object.keys(defaultPermissions).forEach((key) => {
															eventTeam[i].permissions[key].write = e.target.checked;
															if (e.target.checked) eventTeam[i].permissions[key].read = true;
														});
														this.setState({ eventTeam });
													}} />
												</div>
											</div>
											{Object.keys(defaultPermissions).map((key) => {
												const permission = member.permissions[key];

												return <div className='flex aic jcsb' style={{ marginBottom: 5 }}>
													<p>{permission.label}</p>
													<div className='flex' >
														<ToggleField icons={false} style={{ margin: 0, marginRight: 10, width: 75 }} checked={permission.read} label="Read" onChange={(e) => {
															const eventTeam = JSON.parse(JSON.stringify(this.state.eventTeam));
															eventTeam[i].permissions[key].read = e.target.checked;
															if (!eventTeam[i].permissions[key].read) {
																eventTeam[i].permissions[key].write = false;
															}
															this.setState({ eventTeam });
														}} />
														<ToggleField icons={false} style={{ margin: 0, marginLeft: 50, marginRight: 10, width: 75 }} checked={permission.write} label="Write" onChange={(e) => {
															const eventTeam = JSON.parse(JSON.stringify(this.state.eventTeam));
															eventTeam[i].permissions[key].write = e.target.checked;
															if (eventTeam[i].permissions[key].write) eventTeam[i].permissions[key].read = true;
															this.setState({ eventTeam });
														}} />
													</div>
												</div>
											})}

										</div>}
										render={(state, props) => {

											return <div className='cardDetailsContainer w-100 p-10' key={'listItem' + i}>
												<div className="profileIconContainer grey mr-10 d25">
													{member.firstName.toUpperCase().charAt(0)}
													{member.lastName.toUpperCase().charAt(0)}
												</div>
												<div className='w-100 mr-5'>
													<p>{member.firstName} {member.lastName}</p>
												</div>
												<div className="flex aic" style={{ textAlign: 'right' }}>
													<p className='small noBreak c-pointer'
														style={{
															color: !state.expanded ? 'var(--label)' : 'var(--primary)',
															whiteSpace: 'nowrap'
														}}
														onClick={props.handleExpand}>
														{`${role} ${countOf}`} {!state.expanded ? <i className='las la-angle-down ml-5'></i> : <i className='las la-angle-up ml-5'></i>}
													</p>
													<div
														className='c-pointer grey'
														style={{
															marginLeft: 77,
															fontSize: 20,
														}}
														onClick={() => {

															const eventTeam = this.state.eventTeam;
															eventTeam.splice(i, 1);
															this.setState({ eventTeam });
														}}><i class="las la-times-circle"></i></div>
												</div>
											</div>
										}} />
								})}
							</div>}
							<div className='flex flex-wrap'>
								<ExpandableListItem className='w-100 mb-20'
									expandedContent={(state) => <div style={{ padding: 0 }}
										className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} key={'expandedListItem'} >
										{adminMembers.map((member, i) => {
											return <div>
												<div style={{
													border: 'unset'
												}}
													className='cardDetailsContainer w-100 p-10' key={'listItem' + i}>
													<div className="profileIconContainer grey mr-10 d25">
														{member.firstName.toUpperCase().charAt(0)}
														{member.lastName.toUpperCase().charAt(0)}
													</div>
													<div className='w-100 mr-5'>
														<p>{member.firstName} {member.lastName}</p>
													</div>
													<div className="flex aic w-100 ml-10" >
														<p className='small grey'>Full Access</p>
													</div>
												</div>
											</div>
										})}
									</div>}
									render={(state, props) => {
										let renderedAdmins = [];
										for (let i = 0; i < adminMembers.length; i++) {
											if (i < 4) {
												renderedAdmins.push(adminMembers[i]);
											} else {
												break;
											}
										}
										return <div
											className='cardDetailsContainer w-100 p-10' key={'listItem'}>
											<div className='flex aic '>
												<div className="flex aic mr-30"
												>
													{renderedAdmins.map((member, i) => {
														let acronym = `${member.firstName.charAt(0)}${member.lastName.charAt(0)}`;
														let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
														return (
															emptyProfilePicture ? (
																<div
																	className="add acronymCircle"
																	style={{
																		marginRight: -15,
																		border: '1px solid #D5D5D5'
																	}}
																><h4>{i == 3 ? `+${adminMembers.length - renderedAdmins.length}` : acronym}</h4></div>
															)
																:
																(<img src={member.profilePicture}
																	className="add acronymCircle profilePicture"
																	style={{
																		marginRight: -15,
																		border: '1px solid #D5D5D5'
																	}} />)
														)
													}
													)}
												</div >
											</div>
											<div className='w-100 mr-5'>
												<p className='grey'>Admins</p>
											</div>
											<div className="flex aic" style={{ textAlign: 'right', marginRight: 103 }}>
												<p className='small  c-pointer'
													style={{
														color: !state.expanded ? 'var(--label)' : 'var(--primary)',
														whiteSpace: 'nowrap'
													}}
													onClick={props.handleExpand}>

													{`View Admin List`} {!state.expanded ? <i className='las la-angle-down ml-5'></i> : <i className='las la-angle-up ml-5'></i>}</p>
											</div>
										</div>
									}} />
							</div>

						</div>
					</div>

				</div>
			case 6:
				const account = this.props.account;
				// event team
				return <div className={`sectionBody w-100 posAbs anim ${section >= currentSection ? 'right' : 'left'} ${section == currentSection ? '' : 'closed'}`}>
					<div className='mb-20 mt-10'>
						<h2 className='mb-10'>Add team members that will be helping produce this event.</h2>
						<p>
							Set roles and section permissions to tailor each team member's access.
						</p>
					</div>
					<div className='flex w-100'>
						<div className='sectionBlock flex column mr-20'>
							<h4 className='mb-10'>Available Members</h4>
							<InputField classes="mb-10" placeholder={'Search for team members'} value={this.state.searchTeam} onChange={(e) => {
								this.setState({ searchTeam: e.target.value });
							}} />
							<div className='simpleList'>
								{this.props.organization.members.map((member, i) => {
									//if member is already in event team, don't show them
									if (member._id == account._id || member.permission == 'admin') return;
									if (this.state.eventTeam.find(teamMember => teamMember._id == member._id)) return;
									if (this.state.searchTeam && !member.firstName.toLowerCase().includes(this.state.searchTeam.toLowerCase()) && !member.lastName.toLowerCase().includes(this.state.searchTeam.toLowerCase()) && !member.email.toLowerCase().includes(this.state.searchTeam.toLowerCase())) return;
									let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
									return <div className='cardDetailsContainer p-10 mb-20'>
										{emptyProfilePicture ?
											(
												<div className="profileIconContainer d30 mr-10">
													{member.firstName.toUpperCase().charAt(0)}
													{member.lastName.toUpperCase().charAt(0)}
												</div>
											) :
											(
												<img src={member.profilePicture}
													className="acronymCircle profilePicture d30 mr-10" />
											)
										}
										<div>
											<p>{member.firstName} {member.lastName}</p>
											<p className='small'>{member.email}</p>
										</div>
										<button className='prim ml-a' onClick={() => {
											let eventTeam = this.state.eventTeam;
											eventTeam.push({ ...member, permissions: { ...defaultPermissions } });
											this.setState({ eventTeam: eventTeam })
										}}>Add</button>
									</div>
								})}
							</div>
							{/* <button className='neu mt-25' onClick={() => {
								this.setState({ addTeamMember: true })
							}}>Add New Team Member</button> */}

							{/* !nextSectionEnabled && <div className='mt-25 w-100 text-c'><a className='mb-10 click' onClick={() => {
								this.setState({ nextSectionEnabled: true })
							}}>Skip for now</a>
								<p className='small'>You can always add later.</p></div> */}
						</div>
						<div className='sectionBlock flex column'>
							<h4 className='mb-10'>Event Team</h4>
							<div className='simpleList'>
								<ExpandableListItem className='w-100 mb-20'
									expandedContent={(state) => <div className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} >

										<h5 className='mb-10'>Sections</h5>
										{Object.keys(defaultPermissions).map((key) => {
											const permission = defaultPermissions[key];
											return <div className='flex aic jcsb' style={{ marginBottom: 5 }}>
												<h4>{permission.label}</h4>
												<div className='flex'>
													<ToggleField disabled icons={false} style={{ margin: 0, marginRight: 10 }} checked={permission.read} label="Read" />
													<ToggleField disabled icons={false} style={{ margin: 0, marginRight: 10 }} checked={permission.write} label="Write" />
												</div>
											</div>
										})}
									</div>}
									render={(state, props) => {

										return <div className='cardDetailsContainer w-100 p-10'>
											<div className="profileIconContainer mr-10 d30">
												{account.firstName.toUpperCase().charAt(0)}
												{account.lastName.toUpperCase().charAt(0)}
											</div>
											<div className='w-100 mr-5'>
												<p>{account.firstName} {account.lastName}</p>
												<p className='small'>{account.email}</p>
											</div>
											<div style={{ textAlign: 'right' }}>
												<h3>Administrator</h3>
												<p className='small'><a className='noBreak'>Full Access</a></p>
											</div>
										</div>
									}} />
								{
									this.props.organization.members.map((member, i) => {
										if (member.permission == 'admin') {
											return (<ExpandableListItem className='w-100 mb-20'
												expandedContent={(state) => <div className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} >
													<h5 className='mb-10'>Sections</h5>
													{Object.keys(defaultPermissions).map((key) => {
														const permission = defaultPermissions[key];
														return <div className='flex aic jcsb' style={{ marginBottom: 5 }}>
															<h4>{permission.label}</h4>
															<div className='flex'>
																<ToggleField disabled icons={false} style={{ margin: 0, marginRight: 10 }} checked={permission.read} label="Read" />
																<ToggleField disabled icons={false} style={{ margin: 0, marginRight: 10 }} checked={permission.write} label="Write" />
															</div>
														</div>
													})}
												</div>}
												render={(state, props) => {
													let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
													return <div className='cardDetailsContainer w-100 p-10'>
														{emptyProfilePicture ?
															(
																<div className="profileIconContainer d30 mr-10">
																	{member.firstName.toUpperCase().charAt(0)}
																	{member.lastName.toUpperCase().charAt(0)}
																</div>
															) :
															(
																<img src={member.profilePicture}
																	className="acronymCircle profilePicture d30 mr-10"
																/>
															)
														}
														<div className='w-100 mr-5'>
															<p>{member.firstName} {member.lastName}</p>
															<p className='small'>{member.email}</p>
														</div>
														<div style={{ textAlign: 'right' }}>
															<h3>Administrator</h3>
															<p className='small'><a className=' noBreak'>Full Access</a></p>
														</div>
													</div>
												}} />)
										}
									}
									)}
								{this.state.eventTeam.length > 0 && <div className='flex flex-wrap'>
									{this.state.eventTeam.map((member, i) => {
										const permissions = member.permissions;
										let permissionCount = 0;
										let readCount = 0;
										let writeCount = 0;
										Object.keys(defaultPermissions).forEach((key) => {
											if (permissions[key].read) {
												readCount++;
											}
											if (permissions[key].write) {
												writeCount++;
											}
											permissionCount++;
										});
										let accessString = '';
										let role = "";
										if (Object.keys(defaultPermissions).length === permissionCount && readCount == permissionCount && writeCount == permissionCount) {
											accessString = 'Full Access';
											role = 'Organizer';
										} else if (Object.keys(defaultPermissions).length === permissionCount && writeCount <= permissionCount && writeCount > 0 && readCount >= writeCount) {
											accessString = `Limited Access to ${writeCount} sections`;
											role = 'Staff';
										} else if (Object.keys(defaultPermissions).length === permissionCount && readCount <= permissionCount && readCount > 0) {
											accessString = `Read Only Access to ${readCount} sections`;
											role = 'Guest';
										} else {
											accessString = 'Will not be added';
											role = 'No Access';
										}


										return <ExpandableListItem className='w-100 mb-20'
											expandedContent={(state) => <div className={`expandedContentContainer ${!state.expanded ? 'closed' : ''}`} key={'expandedListItem' + i} >
												<div className='flex aic jcsb' style={{ marginBottom: 10 }} >
													<h5>Sections</h5>
													<div className='flex' style={{ width: 230 }}>
														<ToggleField icons={false} style={{ margin: 0, marginRight: 10 }} checked={readCount === permissionCount} label="All" onChange={(e) => {
															const eventTeam = this.state.eventTeam;
															const checked = e.target.checked
															Object.keys(defaultPermissions).forEach((key) => {
																eventTeam[i].permissions[key].read = checked;
																if (!checked) eventTeam[i].permissions[key].write = false;

															});
															this.setState({ eventTeam });
														}} />
														<ToggleField icons={false} style={{ margin: 0, marginRight: 10 }} checked={writeCount === permissionCount} label="All" onChange={(e) => {
															const eventTeam = this.state.eventTeam;
															Object.keys(defaultPermissions).forEach((key) => {
																eventTeam[i].permissions[key].write = e.target.checked;
																if (e.target.checked) eventTeam[i].permissions[key].read = true;
															});
															this.setState({ eventTeam });
														}} />
													</div>
												</div>
												{Object.keys(defaultPermissions).map((key) => {
													const permission = member.permissions[key];

													return <div className='flex aic jcsb' style={{ marginBottom: 5 }}>
														<h4>{permission.label}</h4>
														<div className='flex' style={{ width: 230 }}>
															<ToggleField icons={false} style={{ margin: 0, marginRight: 10 }} checked={permission.read} label="Read" onChange={(e) => {
																const eventTeam = JSON.parse(JSON.stringify(this.state.eventTeam));
																eventTeam[i].permissions[key].read = e.target.checked;
																if (!eventTeam[i].permissions[key].read) {
																	eventTeam[i].permissions[key].write = false;
																}
																this.setState({ eventTeam });
															}} />
															<ToggleField icons={false} style={{ margin: 0, marginRight: 10 }} checked={permission.write} label="Write" onChange={(e) => {
																const eventTeam = JSON.parse(JSON.stringify(this.state.eventTeam));
																eventTeam[i].permissions[key].write = e.target.checked;
																if (eventTeam[i].permissions[key].write) eventTeam[i].permissions[key].read = true;
																this.setState({ eventTeam });
															}} />
														</div>
													</div>
												})}
												<button className='mt-10 w-100' onClick={() => {
													const eventTeam = this.state.eventTeam;
													eventTeam.splice(i, 1);
													this.setState({ eventTeam });
												}}>Remove</button>
											</div>}
											render={(state, props) => {

												return <div className='cardDetailsContainer w-100 p-10' key={'listItem' + i}>
													<div className="profileIconContainer mr-10 d30">
														{member.firstName.toUpperCase().charAt(0)}
														{member.lastName.toUpperCase().charAt(0)}
													</div>
													<div className='w-100 mr-5'>
														<p>{member.firstName} {member.lastName}</p>
														<p className='small'>{member.email}</p>
													</div>
													<div style={{ textAlign: 'right' }}>
														<h3>{role}</h3>
														<p className='small'><a className={`click noBreak`} style={{ color: !state.expanded ? 'var(--label)' : 'var(--primary)' }} onClick={props.handleExpand}>{accessString} {!state.expanded ? <i className='las la-angle-down ml-5'></i> : <i className='las la-angle-up ml-5'></i>}</a></p>
													</div>
												</div>
											}} />
									})}
								</div>}
							</div>
							{/* <button className='neu mt-25' onClick={() => {
								this.setState({ addTeamMember: true })
							}}>Add New Team Member</button> */}

							{/* !nextSectionEnabled && <div className='mt-25 w-100 text-c'><a className='mb-10 click' onClick={() => {
								this.setState({ nextSectionEnabled: true })
							}}>Skip for now</a>
								<p className='small'>You can always add later.</p></div> */}
						</div>
					</div>
				</div >
			default:
				return;

		}
	}

	render() {
		const { isOpen, toggle } = this.props;
		const { nextSectionEnabled, currentSection, sectionCount, submitting, salesforceEnabled } = this.state;

		return (
			<Modal isOpen={isOpen} toggle={toggle} className="stepSize eventModal">
				<div className="modalHeader">
					<button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
					<div className='headerContainer' style={{ position: 'relative' }}>

						<p className='grey small mt-10' style={{ position: 'absolute', right: 0 }}>Step {currentSection + 1} of {sectionCount}</p>
						{/* <div className='sectionIndicatorBar'>
							{Array.from(Array(sectionCount).keys()).map((i) => {
								return <div className={`sectionIndicator ${i <= currentSection ? 'selected' : ''}`}></div>
							})}
						</div> */}
					</div>
				</div>
				<ModalBody className="modalBody">
					{this.renderSection(0)}
					{this.renderSection(1)}
					{this.renderSection(2)}
					{this.renderSection(3)}
					{this.renderSection(4)}


				</ModalBody>
				<div className='flex'>

					{currentSection == 0 ? <button className="neu ml-a mr-15" onClick={toggle}>Cancel</button> : <button className="neu ml-a mr-15" onClick={this.prevSection}>Back</button>}
					{currentSection < sectionCount - 1 ? <button
						className={`neu  ${nextSectionEnabled ? 'prim' : 'disabled'}`}
						onClick={this.nextSection}>
						Next
					</button> : <button className={`neu ${submitting ? 'disabled' : 'prim'}`} onClick={this.createEvent}>{submitting ? "Creating..." : "Create Event"}</button>}
				</div>
			</Modal>
		);
	}
}

export default AddEventModal;
