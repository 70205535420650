import React, { Component } from 'react';
import API from '../../../utils/API';
import ApplicationLogosAndColors from './logosColors';
import CustomStyling from './customStyling';
import SiteSettings from './siteSetUp';

import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import Typography from '../../../views/account/typography';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import ColorField from '../../../components/inputs/colorField';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';

const textTransformOptions = [
	{ label: 'Default', value: 'none' },
	{ label: 'Capitalize', value: 'capitalize' },
	{ label: 'lowercase', value: 'lowercase' },
	{ label: 'UPPERCASE', value: 'uppercase' }
];

const fontWeightOptions = [
	{ label: 'Light', value: 'light' },
	{ label: 'Normal', value: 'normal' },
	{ label: 'Bold', value: 'bold' }
];

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

class ApplicationSetup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedSection: 0,
			organization: {},
			editedBranding: { ...props.event.branding },
			editedEventStyles: { ...props.event.style },
			editedStyling: props.event.customCSS,
			defaultPublicPath: props.event.defaultPublicPath,
			defaultUserPath: props.event.defaultUserPath,
			eventEmailName: props.event.eventEmailName,
			eventEmail: props.event.eventEmail,
			eventSupportEmail: props.event.eventSupportEmail,
			eventSupportEmailName: props.event.eventSupportEmailName,
			loading: true,
			editing: false,
			requiredFields: {},
			renderURL: `Organizations/${this.props.orgId}/events/${this.props.eventId}/pages/render/stylePreview/`
		};
	}

	componentDidMount() {
		const { orgId } = this.props;
		API().get(`Organizations/${orgId}/account`).then((res) => {
			if (res.data) {
				this.setState({ organization: res.data, loading: false });
			}
		});
	}

	updateBranding = (property, value) => {
		const editedBranding = this.state.editedBranding;
		editedBranding[property] = value;
		this.setState({ editedBranding: editedBranding, editing: true });
	};

	updateStyling = (styling) => {
		this.setState({ editedStyling: styling, editing: true });
	};

	updateEventStyles = (property, value) => {
		const editedEventStyles = this.state.editedEventStyles;
		editedEventStyles[property] = value;
		this.setState({ editedEventStyles: editedEventStyles, editing: true });
	};

	updateDefaultUserPath = (path) => {
		this.setState({ defaultUserPath: path, editing: true });
	};

	updateDefaultPublicPath = (path) => {
		this.setState({ defaultPublicPath: path, editing: true });
	};


	updateEventSupportEmailName = (val) => {
		this.setState({ eventSupportEmailName: val, editing: true });
	};
	updateEventSupportEmail = (val) => {
		this.setState({ eventSupportEmail: val, editing: true });
	};
	updateEventEmail = (val) => {
		this.setState({ eventEmail: val, editing: true });
	};
	updateEventEmailName = (val) => {
		this.setState({ eventEmailName: val, editing: true });
	};

	saveInfo = () => {
		const { orgId, eventId } = this.props;
		const { editedBranding, eventEmailName,
			eventEmail,
			eventSupportEmail,
			eventSupportEmailName } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedBranding[key] || editedBranding[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			API()
				.patch(`Organizations/${orgId}/events/${eventId}`, {
					branding: { ...this.state.editedBranding },
					style: { ...this.state.editedEventStyles },
					customCSS: this.state.editedStyling,
					defaultPublicPath: this.state.defaultPublicPath,
					defaultUserPath: this.state.defaultUserPath,
					eventEmailName: eventEmailName,
					eventEmail: eventEmail,
					eventSupportEmail: eventSupportEmail,
					eventSupportEmailName: eventSupportEmailName
				})
				.then((res) => {
					if (res.data) {
						this.setState({
							editing: false,
							editedBranding: { ...res.data.branding },
							customCSS: res.data.customCSS,
							defaultPublicPath: res.data.defaultPublicPath,
							defaultUserPath: res.data.defaultUserPath,
							requiredFields: requiredFields
						});

						let tempBranding = this.props.event.branding;
						let tempDefaultPublicPath = this.props.event.defaultPublicPath;
						let tempdefaultUserPath = this.props.event.defaultUserPath;

						tempBranding = { ...tempBranding, ...res.data.branding };
						tempDefaultPublicPath = this.state.defaultPublicPath;
						tempdefaultUserPath = this.state.defaultUserPath;

						this.props.updateSetup({
							branding: tempBranding,
							defaultPublicPath: tempDefaultPublicPath,
							defaultUserPath: tempdefaultUserPath
						});
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	cancelEdit = () => {
		const { branding, customCSS, defaultPublicPath, defaultUserPath } = this.props.event;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedBranding: JSON.parse(JSON.stringify(branding)),
			editing: false,
			editedStyling: customCSS,
			defaultPublicPath: defaultPublicPath,
			defaultUserPath: defaultUserPath,
			requiredFields: requiredFields
		});
	};

	SectionItem = (title, sectionNo) => {
		return (
			<div
				className={`sectionTab ${sectionNo === this.state.selectedSection ? 'selected' : ''}`}
				onClick={() => {
					this.setState({ selectedSection: sectionNo });
				}}
			>
				<div className="calibreBold fs-30">{title}</div>
			</div>
		);
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	renderSection = () => {
		const {
			organization,
			editedBranding,
			selectedSection,
			editedStyling,
			defaultPublicPath,
			defaultUserPath,
			requiredFields,
			editedEventStyles,
			eventEmailName,
			eventEmail,
			eventSupportEmail,
			eventSupportEmailName
		} = this.state;
		const { readOnly } = this.props;
		switch (selectedSection) {
			case 0:
				return (
					<SiteSettings
						event={this.props.event}
						defaultPublicPath={defaultPublicPath}
						defaultUserPath={defaultUserPath}
						setPublicPath={this.updateDefaultPublicPath}
						setPrivatePath={this.updateDefaultUserPath}
						updateEventSupportEmailName={this.updateEventSupportEmailName}
						updateEventSupportEmail={this.updateEventSupportEmail}
						updateEventEmail={this.updateEventEmail}
						updateEventEmailName={this.updateEventEmailName}
						requiredFields={requiredFields}
						eventEmailName={eventEmailName}
						eventEmail={eventEmail}
						eventSupportEmail={eventSupportEmail}
						eventSupportEmailName={eventSupportEmailName}
					/>
				);

			case 1:
				return (
					<div>
						<ApplicationLogosAndColors
							orgBranding={organization.branding}
							editedEventBranding={editedBranding}
							updateBranding={this.updateBranding}
							readOnly={readOnly}
							requiredFields={requiredFields}
						/>
						<Typography
							fonts={editedBranding}
							accountData={{
								branding: {
									...editedBranding,
									fonts: [...editedBranding.fonts]
								}
							}}
							editAccountData={(editedBranding) => {
								this.updateBranding('fonts', editedBranding.fonts);
							}}
						/>
					</div>
				);
			case 2:
				const fontOptions = [...organization.branding.fonts].map((f) => {
					return { label: f.name, value: f.name };
				});
				return (
					<div className="flex h-100">
						<div
							className="sectionBlock"
							style={{ maxHeight: '100%', overflowX: 'hidden', paddingRight: 5 }}
						>
							<div className="sectionSubtitle">Content Styles</div>
							<InputField
								label="Max Content Width"
								value={
									editedEventStyles.maxContentWidth && editedEventStyles.maxContentWidth != '' ? (
										editedEventStyles.maxContentWidth
									) : (
										organization.style.maxContentWidth
									)
								}
								onChange={(e) => this.updateEventStyles('maxContentWidth', e.target.value)}
							/>

							<div className="calibreSemiBold">Default Content Padding</div>
							<div className="flex-ac">
								<InputField
									label="Padding Top "
									classes="mr-5"
									value={
										editedEventStyles.contentPaddingTop &&
											editedEventStyles.contentPaddingTop != '' ? (
											editedEventStyles.contentPaddingTop
										) : (
											organization.style.contentPaddingTop
										)
									}
									onChange={(e) => this.updateEventStyles('contentPaddingTop', e.target.value)}
								/>
								<InputField
									label="Padding Left "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.contentPaddingLeft &&
											editedEventStyles.contentPaddingLeft != '' ? (
											editedEventStyles.contentPaddingLeft
										) : (
											organization.style.contentPaddingLeft
										)
									}
									onChange={(e) => this.updateEventStyles('contentPaddingLeft', e.target.value)}
								/>
								<InputField
									label="Padding Bottom "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.contentPaddingBottom &&
											editedEventStyles.contentPaddingBottom != '' ? (
											editedEventStyles.contentPaddingBottom
										) : (
											organization.style.contentPaddingBottom
										)
									}
									onChange={(e) => this.updateEventStyles('contentPaddingBottom', e.target.value)}
								/>
								<InputField
									label="Padding Right "
									classes="ml-5"
									value={
										editedEventStyles.contentPaddingRight &&
											editedEventStyles.contentPaddingRight != '' ? (
											editedEventStyles.contentPaddingRight
										) : (
											organization.style.contentPaddingRight
										)
									}
									onChange={(e) => this.updateEventStyles('contentPaddingRight', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Default Button Padding</div>
							<div className="flex-ac">
								<InputField
									label="Padding Top "
									classes="mr-5"
									value={
										editedEventStyles.buttonPaddingTop &&
											editedEventStyles.buttonPaddingTop != '' ? (
											editedEventStyles.buttonPaddingTop
										) : (
											organization.style.buttonPaddingTop
										)
									}
									onChange={(e) => this.updateEventStyles('buttonPaddingTop', e.target.value)}
								/>

								<InputField
									label="Padding Left "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.buttonPaddingLeft &&
											editedEventStyles.buttonPaddingLeft != '' ? (
											editedEventStyles.buttonPaddingLeft
										) : (
											organization.style.buttonPaddingLeft
										)
									}
									onChange={(e) => this.updateEventStyles('buttonPaddingLeft', e.target.value)}
								/>
								<InputField
									label="Padding Bottom "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.buttonPaddingBottom &&
											editedEventStyles.buttonPaddingBottom != '' ? (
											editedEventStyles.buttonPaddingBottom
										) : (
											organization.style.buttonPaddingBottom
										)
									}
									onChange={(e) => this.updateEventStyles('buttonPaddingBottom', e.target.value)}
								/>
								<InputField
									label="Padding Right "
									classes="ml-5"
									value={
										editedEventStyles.buttonPaddingRight &&
											editedEventStyles.buttonPaddingRight != '' ? (
											editedEventStyles.buttonPaddingRight
										) : (
											organization.style.buttonPaddingRight
										)
									}
									onChange={(e) => this.updateEventStyles('buttonPaddingRight', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Default Border Radius</div>
							<div className="flex-ac">
								<InputField
									label="Button Radius "
									classes="mr-5"
									value={
										editedEventStyles.buttonBorderRadius &&
											editedEventStyles.buttonBorderRadius != '' ? (
											editedEventStyles.buttonBorderRadius
										) : (
											organization.style.buttonBorderRadius
										)
									}
									onChange={(e) => this.updateEventStyles('buttonBorderRadius', e.target.value)}
								/>
								<InputField
									label="Input Radius"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.inputBorderRadius &&
											editedEventStyles.inputBorderRadius != '' ? (
											editedEventStyles.inputBorderRadius
										) : (
											organization.style.inputBorderRadius
										)
									}
									onChange={(e) => this.updateEventStyles('inputBorderRadius', e.target.value)}
								/>
								<InputField
									label="Card Radius"
									classes="ml-5"
									value={
										editedEventStyles.cardBorderRadius &&
											editedEventStyles.cardBorderRadius != '' ? (
											editedEventStyles.cardBorderRadius
										) : (
											organization.style.cardBorderRadius
										)
									}
									onChange={(e) => this.updateEventStyles('cardBorderRadius', e.target.value)}
								/>
							</div>

							<div className="sectionSubtitle">Color Styles</div>

							<div className="calibreSemiBold">Default Colors</div>
							<div className="flex-ac">
								<ColorField
									label="Primary Accent Color"
									classes="mr-5"
									type="color"
									value={
										editedEventStyles.primaryColor && editedEventStyles.primaryColor != '' ? (
											editedEventStyles.primaryColor
										) : (
											organization.style.primaryColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('primaryColor', e.target ? e.target.value : e)}
								/>
								<ColorField
									label="Primary Text Color"
									classes="mr-5 ml-5"
									type="color"
									value={
										editedEventStyles.secondaryColor && editedEventStyles.secondaryColor != '' ? (
											editedEventStyles.secondaryColor
										) : (
											organization.style.secondaryColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('secondaryColor', e.target ? e.target.value : e)}
								/>
							</div>

							<div className="calibreSemiBold">Navigation Text Color</div>
							<div className="flex-ac">
								<ColorField
									label="Default"
									classes="mr-5"
									type="color"
									value={
										editedEventStyles.navigationTextColor &&
											editedEventStyles.navigationTextColor != '' ? (
											editedEventStyles.navigationTextColor
										) : (
											organization.style.navigationTextColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('navigationTextColor', e.target ? e.target.value : e)}
								/>
								<ColorField
									label="Hover"
									classes="mr-5 ml-5"
									type="color"
									value={
										editedEventStyles.navigationHoverColor &&
											editedEventStyles.navigationHoverColor != '' ? (
											editedEventStyles.navigationHoverColor
										) : (
											organization.style.navigationHoverColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('navigationHoverColor', e.target ? e.target.value : e)}
								/>
								<ColorField
									label="Visited"
									classes="ml-5"
									type="color"
									value={
										editedEventStyles.navigationVisitedColor &&
											editedEventStyles.navigationVisitedColor != '' ? (
											editedEventStyles.navigationVisitedColor
										) : (
											organization.style.navigationVisitedColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('navigationVisitedColor', e.target ? e.target.value : e)}
								/>
							</div>
							<div className="calibreSemiBold">Link Color</div>
							<div className="flex-ac">
								<ColorField
									label="Default"
									classes="mr-5"
									type="color"
									value={
										editedEventStyles.linkTextColor && editedEventStyles.linkTextColor != '' ? (
											editedEventStyles.linkTextColor
										) : (
											organization.style.linkTextColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('linkTextColor', e.target ? e.target.value : e)}
								/>
								<ColorField
									label="Hover"
									classes="mr-5 ml-5"
									type="color"
									value={
										editedEventStyles.linkHoverColor && editedEventStyles.linkHoverColor != '' ? (
											editedEventStyles.linkHoverColor
										) : (
											organization.style.linkHoverColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('linkHoverColor', e.target ? e.target.value : e)}
								/>
								<ColorField
									label="Visited"
									classes="ml-5"
									type="color"
									value={
										editedEventStyles.linkVisitedColor &&
											editedEventStyles.linkVisitedColor != '' ? (
											editedEventStyles.linkVisitedColor
										) : (
											organization.style.linkVisitedColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('linkVisitedColor', e.target ? e.target.value : e)}
								/>
							</div>
							<div className="calibreSemiBold">Button Color</div>
							<div className="flex-ac">
								<ColorField
									label="Primary Color"
									classes="mr-5"
									type="color"
									value={
										editedEventStyles.buttonPrimaryColor &&
											editedEventStyles.buttonPrimaryColor != '' ? (
											editedEventStyles.buttonPrimaryColor
										) : (
											organization.style.buttonPrimaryColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('buttonPrimaryColor', e.target ? e.target.value : e)}
								/>

								<ColorField
									label="Primary Text Color"
									classes="ml-5"
									type="color"
									value={
										editedEventStyles.buttonSecondaryColor &&
											editedEventStyles.buttonSecondaryColor != '' ? (
											editedEventStyles.buttonSecondaryColor
										) : (
											organization.style.buttonSecondaryColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('buttonSecondaryColor', e.target ? e.target.value : e)}
								/>
							</div>
							<div className="calibreSemiBold">Profile Icon Color</div>
							<div className="flex-ac">
								<ColorField
									label="Background"
									classes="mr-5"
									type="color"
									value={
										editedEventStyles.profileIconPrimaryColor &&
											editedEventStyles.profileIconPrimaryColor != '' ? (
											editedEventStyles.profileIconPrimaryColor
										) : (
											organization.style.profileIconPrimaryColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles(
											'profileIconPrimaryColor',
											e.target ? e.target.value : e
										)}
								/>
								<ColorField
									label="Text"
									classes="ml-5"
									type="color"
									value={
										editedEventStyles.profileIconSecondaryColor &&
											editedEventStyles.profileIconSecondaryColor != '' ? (
											editedEventStyles.profileIconSecondaryColor
										) : (
											organization.style.profileIconSecondaryColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles(
											'profileIconSecondaryColor',
											e.target ? e.target.value : e
										)}
								/>
							</div>
							<div className="calibreSemiBold">Scrollbar Color</div>
							<div className="flex-ac">
								<ColorField
									label="Bar Color"
									classes="mr-5"
									type="color"
									value={
										editedEventStyles.scrollbarColor && editedEventStyles.scrollbarColor != '' ? (
											editedEventStyles.scrollbarColor
										) : (
											organization.style.scrollbarColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles('scrollbarColor', e.target ? e.target.value : e)}
								/>

								<ColorField
									label="Background Color"
									classes="ml-5"
									type="color"
									value={
										editedEventStyles.scrollbarBackgroundColor &&
											editedEventStyles.scrollbarBackgroundColor != '' ? (
											editedEventStyles.scrollbarBackgroundColor
										) : (
											organization.style.scrollbarBackgroundColor
										)
									}
									onChange={(e) =>
										this.updateEventStyles(
											'scrollbarBackgroundColor',
											e.target ? e.target.value : e
										)}
								/>
							</div>

							<div className="sectionSubtitle">Text Styles</div>
							<div className="calibreSemiBold">Default Fonts</div>
							<div className="flex-ac">
								<SelectField
									label="Primary Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.primaryFont
												? editedEventStyles.primaryFont
												: organization.style.primaryFont)
									)}
									onChange={(e) => this.updateEventStyles('primaryFont', e.value)}
								/>
								<SelectField
									label="Secondary Font Family"
									style={{ marginLeft: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.secondaryFont
												? editedEventStyles.secondaryFont
												: organization.style.secondaryFont)
									)}
									onChange={(e) => this.updateEventStyles('secondaryFont', e.value)}
								/>
							</div>
							<div className="calibreSemiBold">Navigation Bar</div>
							<div className="flex-ac">
								<InputField
									label="Font Size "
									classes="mr-5"
									value={
										editedEventStyles.navigationBarFontSize &&
											editedEventStyles.navigationBarFontSize != '' ? (
											editedEventStyles.navigationBarFontSize
										) : (
											organization.style.navigationBarFontSize
										)
									}
									onChange={(e) => this.updateEventStyles('navigationBarFontSize', e.target.value)}
								/>

								<SelectField
									label="Font Family"
									style={{ marginRight: 5, marginLeft: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.navigationBarFontFamily
												? editedEventStyles.navigationBarFontFamily
												: organization.style.navigationBarFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('navigationBarFontFamily', e.value)}
								/>
								<SelectField
									label="Font Transform"
									style={{ marginLeft: 5 }}
									options={textTransformOptions}
									value={textTransformOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.navigationBarFontTransform
												? editedEventStyles.navigationBarFontTransform
												: organization.style.navigationBarFontTransform)
									)}
									onChange={(e) => this.updateEventStyles('navigationBarFontTransform', e.value)}
								/>
							</div>
							<div className="calibreSemiBold">Heading 1</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.heading1FontFamily
												? editedEventStyles.heading1FontFamily
												: organization.style.heading1FontFamily)
									)}
									onChange={(e) => this.updateEventStyles('heading1FontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading1FontSize &&
											editedEventStyles.heading1FontSize != '' ? (
											editedEventStyles.heading1FontSize
										) : (
											organization.style.heading1FontSize
										)
									}
									onChange={(e) => this.updateEventStyles('heading1FontSize', e.target.value)}
								/>
								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading1LineHeight &&
											editedEventStyles.heading1LineHeight != '' ? (
											editedEventStyles.heading1LineHeight
										) : (
											organization.style.heading1LineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('heading1LineHeight', e.target.value)}
								/>
								<InputField
									label="Bottom Margin"
									classes="mr-5"
									value={
										editedEventStyles.heading1MarginBottom &&
											editedEventStyles.heading1MarginBottom != '' ? (
											editedEventStyles.heading1MarginBottom
										) : (
											organization.style.heading1MarginBottom
										)
									}
									onChange={(e) => this.updateEventStyles('heading1MarginBottom', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Heading 2</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.heading2FontFamily
												? editedEventStyles.heading2FontFamily
												: organization.style.heading2FontFamily)
									)}
									onChange={(e) => this.updateEventStyles('heading2FontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading2FontSize &&
											editedEventStyles.heading2FontSize != '' ? (
											editedEventStyles.heading2FontSize
										) : (
											organization.style.heading2FontSize
										)
									}
									onChange={(e) => this.updateEventStyles('heading2FontSize', e.target.value)}
								/>
								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading2LineHeight &&
											editedEventStyles.heading2LineHeight != '' ? (
											editedEventStyles.heading2LineHeight
										) : (
											organization.style.heading2LineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('heading2LineHeight', e.target.value)}
								/>
								<InputField
									label="Bottom Margin"
									classes="mr-5"
									value={
										editedEventStyles.heading2MarginBottom &&
											editedEventStyles.heading2MarginBottom != '' ? (
											editedEventStyles.heading2MarginBottom
										) : (
											organization.style.heading2MarginBottom
										)
									}
									onChange={(e) => this.updateEventStyles('heading2MarginBottom', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Heading 3</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.heading3FontFamily
												? editedEventStyles.heading3FontFamily
												: organization.style.heading3FontFamily)
									)}
									onChange={(e) => this.updateEventStyles('heading3FontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading3FontSize &&
											editedEventStyles.heading3FontSize != '' ? (
											editedEventStyles.heading3FontSize
										) : (
											organization.style.heading3FontSize
										)
									}
									onChange={(e) => this.updateEventStyles('heading3FontSize', e.target.value)}
								/>
								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading3LineHeight &&
											editedEventStyles.heading3LineHeight != '' ? (
											editedEventStyles.heading3LineHeight
										) : (
											organization.style.heading3LineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('heading3LineHeight', e.target.value)}
								/>
								<InputField
									label="Bottom Margin"
									classes="mr-5"
									value={
										editedEventStyles.heading3MarginBottom &&
											editedEventStyles.heading3MarginBottom != '' ? (
											editedEventStyles.heading3MarginBottom
										) : (
											organization.style.heading3MarginBottom
										)
									}
									onChange={(e) => this.updateEventStyles('heading3MarginBottom', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Heading 4</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.heading4FontFamily
												? editedEventStyles.heading4FontFamily
												: organization.style.heading4FontFamily)
									)}
									onChange={(e) => this.updateEventStyles('heading4FontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading4FontSize &&
											editedEventStyles.heading4FontSize != '' ? (
											editedEventStyles.heading4FontSize
										) : (
											organization.style.heading4FontSize
										)
									}
									onChange={(e) => this.updateEventStyles('heading4FontSize', e.target.value)}
								/>
								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading4LineHeight &&
											editedEventStyles.heading4LineHeight != '' ? (
											editedEventStyles.heading4LineHeight
										) : (
											organization.style.heading4LineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('heading4LineHeight', e.target.value)}
								/>
								<InputField
									label="Bottom Margin"
									classes="mr-5"
									value={
										editedEventStyles.heading4MarginBottom &&
											editedEventStyles.heading4MarginBottom != '' ? (
											editedEventStyles.heading4MarginBottom
										) : (
											organization.style.heading4MarginBottom
										)
									}
									onChange={(e) => this.updateEventStyles('heading4MarginBottom', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Heading 5</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.heading5FontFamily
												? editedEventStyles.heading5FontFamily
												: organization.style.heading5FontFamily)
									)}
									onChange={(e) => this.updateEventStyles('heading5FontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading5FontSize &&
											editedEventStyles.heading5FontSize != '' ? (
											editedEventStyles.heading5FontSize
										) : (
											organization.style.heading5FontSize
										)
									}
									onChange={(e) => this.updateEventStyles('heading5FontSize', e.target.value)}
								/>
								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.heading5LineHeight &&
											editedEventStyles.heading5LineHeight != '' ? (
											editedEventStyles.heading5LineHeight
										) : (
											organization.style.heading5LineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('heading5LineHeight', e.target.value)}
								/>
								<InputField
									label="Bottom Margin"
									classes="mr-5"
									value={
										editedEventStyles.heading5MarginBottom &&
											editedEventStyles.heading5MarginBottom != '' ? (
											editedEventStyles.heading5MarginBottom
										) : (
											organization.style.heading5MarginBottom
										)
									}
									onChange={(e) => this.updateEventStyles('heading5MarginBottom', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Body</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.bodyFontFamily
												? editedEventStyles.bodyFontFamily
												: organization.style.bodyFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('bodyFontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.bodyFontSize && editedEventStyles.bodyFontSize != '' ? (
											editedEventStyles.bodyFontSize
										) : (
											organization.style.bodyFontSize
										)
									}
									onChange={(e) => this.updateEventStyles('bodyFontSize', e.target.value)}
								/>

								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.bodyLineHeight && editedEventStyles.bodyLineHeight != '' ? (
											editedEventStyles.bodyLineHeight
										) : (
											organization.style.bodyLineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('bodyLineHeight', e.target.value)}
								/>
								<InputField
									label="Bottom Margin"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.paragraphMarginBottom &&
											editedEventStyles.paragraphMarginBottom != '' ? (
											editedEventStyles.paragraphMarginBottom
										) : (
											organization.style.paragraphMarginBottom
										)
									}
									onChange={(e) => this.updateEventStyles('paragraphMarginBottom', e.target.value)}
								/>
								<SelectField
									label="Bold Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.bodyBoldFontFamily
												? editedEventStyles.bodyBoldFontFamily
												: organization.style.bodyBoldFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('bodyBoldFontFamily', e.value)}
								/>
							</div>
							<div className="calibreSemiBold">Input Label</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.inputLabelFontFamily
												? editedEventStyles.inputLabelFontFamily
												: organization.style.inputLabelFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('inputLabelFontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.inputLabelFontSize &&
											editedEventStyles.inputLabelFontSize != '' ? (
											editedEventStyles.inputLabelFontSize
										) : (
											organization.style.inputLabelFontSize
										)
									}
									onChange={(e) => this.updateEventStyles('inputLabelFontSize', e.target.value)}
								/>
								<SelectField
									label="Font Transform"
									style={{ marginLeft: 5 }}
									options={textTransformOptions}
									value={textTransformOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.inputLabelFontTransform
												? editedEventStyles.inputLabelFontTransform
												: organization.style.inputLabelFontTransform)
									)}
									onChange={(e) => this.updateEventStyles('inputLabelFontTransform', e.value)}
								/>
							</div>
							<div className="calibreSemiBold">Input Text</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.inputTextFontFamily
												? editedEventStyles.inputTextFontFamily
												: organization.style.inputTextFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('inputTextFontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5"
									value={
										editedEventStyles.inputTextFontSize &&
											editedEventStyles.inputTextFontSize != '' ? (
											editedEventStyles.inputTextFontSize
										) : (
											organization.style.inputTextFontSize
										)
									}
									onChange={(e) => this.updateEventStyles('inputTextFontSize', e.target.value)}
								/>
							</div>
							<div className="calibreSemiBold">Button Text</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.buttonTextFontFamily
												? editedEventStyles.buttonTextFontFamily
												: organization.style.buttonTextFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('buttonTextFontFamily', e.value)}
								/>
								<InputField
									label="Font Size "
									classes="mr-5 ml-5"
									value={
										editedEventStyles.buttonTextFontSize &&
											editedEventStyles.buttonTextFontSize != '' ? (
											editedEventStyles.buttonTextFontSize
										) : (
											organization.style.buttonTextFontSize
										)
									}
									onChange={(e) => this.updateEventStyles('buttonTextFontSize', e.target.value)}
								/>
								<SelectField
									label="Font Transform"
									style={{ marginLeft: 5 }}
									options={textTransformOptions}
									value={textTransformOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.buttonTextFontTransform
												? editedEventStyles.buttonTextFontTransform
												: organization.style.buttonTextFontTransform)
									)}
									onChange={(e) => this.updateEventStyles('buttonTextFontTransform', e.value)}
								/>
							</div>
							<div className="calibreSemiBold">Footer</div>
							<div className="flex-ac">
								<SelectField
									label="Font Family"
									style={{ marginRight: 5 }}
									options={fontOptions}
									value={fontOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.footerFontFamily
												? editedEventStyles.footerFontFamily
												: organization.style.footerFontFamily)
									)}
									onChange={(e) => this.updateEventStyles('footerFontFamily', e.value)}
								/>
								<InputField
									label="Font Size"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.footerFontSize && editedEventStyles.footerFontSize != '' ? (
											editedEventStyles.footerFontSize
										) : (
											organization.style.footerFontSize
										)
									}
									onChange={(e) => this.updateEventStyles('footerFontSize', e.target.value)}
								/>
								<InputField
									label="Line Height"
									classes="mr-5 ml-5"
									value={
										editedEventStyles.footerLineHeight &&
											editedEventStyles.footerLineHeight != '' ? (
											editedEventStyles.footerLineHeight
										) : (
											organization.style.footerLineHeight
										)
									}
									onChange={(e) => this.updateEventStyles('footerLineHeight', e.target.value)}
								/>
								<SelectField
									label="Font Transform"
									style={{ marginLeft: 5 }}
									options={textTransformOptions}
									value={textTransformOptions.find(
										(fo) =>
											fo.value ==
											(editedEventStyles.footerFontTransform
												? editedEventStyles.footerFontTransform
												: organization.style.footerFontTransform)
									)}
									onChange={(e) => this.updateEventStyles('footerFontTransform', e.value)}
								/>
							</div>
						</div>
						<div className="sectionBlock h-100 ml-25">
							<PagePreviewFrame
								setPreviewDimensions={({ width, height }) => {
									this.setState({
										frameWidth: width,
										frameHeight: height
									});
								}}
								ref={(el) => (this.preview = el)}
								editing={this.state.editCount}
								saving={this.state.saving}
								ENV={ENV}
								renderURL={this.state.renderURL}
								pageProperties={{}}
								simulatedUser={{}}
								simulatedSession={{}}
							/>
						</div>
					</div>
				);
			case 3:
				return (
					<CustomStyling
						eventCustomCSS={editedStyling}
						updateStyling={this.updateStyling}
						readOnly={readOnly}
					/>
				);
		}
	};

	render() {
		const { editing, loading, selectedSection } = this.state;
		const { event } = this.props;
		return (
			<div className="sectionContainer">
				<BasicSectionHeader
					editing={editing}
					cancelEdit={() => {
						this.cancelEdit();
					}}
					saveEdit={() => {
						this.saveInfo();
					}}
					updatedAt={event.updatedAt}
					title={'Event Settings'}
					description="Set your website homepage, customize your event theme and more. "
					breadcrumbs={[
						{
							name: 'Events',
							link: `/admin/events`
						},
						{
							name: event.name,
							link: `/admin/events/${event._id}/0`
						}
					]}
				/>
				<TabsContainer
					tabs={[
						{ title: 'Settings' },
						{ title: 'Branding' },
						{ title: 'Default Styles' },
						{ title: 'Advanced Styling' }
					]}
					updateSelected={this.updateSelected}
					selectedIndex={selectedSection}
				/>

				<div className="sectionBody">{!loading && this.renderSection()}</div>
			</div>
		);
	}
}

export default ApplicationSetup;
