import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button } from 'reactstrap';
import API from '../../../utils/API';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import FieldModal from '../../../components/modals/fieldModal';
import InputField from '../../../components/inputs/inputField';
class EventSessionPolls extends React.Component {
	constructor(props) {
		super(props);

		const columns = {
			question: 'Question',
			answers: 'Answers',
			submissions: 'Submissions'
		};
		const columnTypes = {
			question: 'text',
			answers: 'text',
			submissions: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			showAddPollModal: false,
			showRemovePollModal: false,
			markedPoll: null,
			showEditPollModal: false,
			showResetPollModal: false,
			toggledColumns: toggledColumns,
			normalizedColumns: normalizedColumns,
			columns: columns,
			columnTypes: columnTypes,
			checkedPolls: []
		};
	}

	toggleAddPoll = () => {
		this.setState({ showAddPollModal: !this.state.showAddPollModal });
	};

	addPoll = (poll) => {
		const polls = this.state.polls;
		polls.push(poll);
		this.setState({ polls: polls });
	};

	toggleRemovePoll = (poll) => {
		this.setState({
			showRemovePollModal: !this.state.showRemovePollModal,
			markedPoll: poll ? poll._id : null
		});
	};

	toggleResetPoll = () => {
		this.setState({
			showResetPollModal: !this.state.showResetPollModal
		});
	};

	removePoll = (poll) => {
		const polls = this.state.polls.filter((p) => p._id !== poll._id);
		this.setState({ polls: polls });
	};

	toggleEditPoll = (pollId) => {
		const { polls } = this.props.editedSession;
		if (pollId) {
			this.setState({
				showEditPollModal: true,
				markedPoll: polls.find((p) => p._id === pollId)
			});
		} else {
			this.setState({
				showEditPollModal: false,
				markedPoll: null
			});
		}
	};

	PollItem(poll, index) {
		return (
			<div key={index} className="tableColumnRow" style={{ padding: 15, height: 'unset' }}>
				<div className="tableColumnRowCell long calibreRegular">
					<div>{poll.question}</div>
				</div>
				<div>
					<div className="tableColumnRowCell long calibreRegular">
						<ul style={{ paddingLeft: 15, margin: 0 }}>
							{poll.responses.map((r) => {
								var percentage = Math.round(
									poll.answers.filter((a) => a.response == r).length / poll.answers.length * 100
								);
								return (
									<li style={{ paddingTop: '10px', lineHeight: '25px' }}>
										{r} {'( '}
										{percentage ? percentage : '0'}
										{'% )'}
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="tableColumnRowCell long calibreRegular">
					<div>{poll.answers.length}</div>
				</div>

				<div style={{ display: 'flex', marginLeft: 'auto' }}>
					<div
						className="actionButton actionSave mr-10"
						style={{ minWidth: 100, width: 100 }}
						onClick={() => this.toggleEditPoll(poll)}
					>
						<div className="calibreBold fs-16">
							<i className="las la-pen c-pointer" style={{ color: 'white' }} /> Edit
						</div>
					</div>

					<div
						className="actionButton actionOutline mr-10"
						style={{ minWidth: 100, width: 100 }}
						onClick={() => this.toggleResetPoll(poll)}
					>
						<div className="calibreBold fs-16">RESET</div>
					</div>
					<div className="actionButton actionDelete" style={{ minWidth: 45, width: 45, color: 'red' }}>
						<div className="calibreBold fs-16">
							<i
								className="las la-trash"
								style={{ marginRight: 0 }}
								onClick={() => this.toggleRemovePoll(poll, index)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	updatecheckedPolls = (checked) => {
		this.setState({ checkedPolls: checked });
	};

	render() {
		const {
			showAddPollModal,
			showRemovePollModal,
			markedPoll,
			showEditPollModal,
			showResetPollModal,
			normalizedColumns,
			toggledColumns,
			columns
		} = this.state;
		const { polls } = this.props.editedSession;

		let fixedPolls = polls.map((p) => {
			return {
				_id: p._id,
				question: p.question,
				submissions: p.answers.length,
				answers: String(p.responses)
			};
		});

		let mainActions = [];
		mainActions = [{ onClick: this.toggleAddPoll, label: 'Add Poll' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Delete Poll',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleRemovePoll
			},
			{
				type: 'button',
				label: 'Reset Poll',
				iconClass: 'las la-trash-alt',
				onClick: this.toggleResetPoll
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedPolls).length;
		entriesLength = fixedPolls.length;
		uncheckEntries = () => {
			this.setState({ checkedPolls: [] });
		};
		searchEnabled = false;
		return (
			<div className='sectionBody posRel mb-0 p-0'>
				<ActionButtonContainer
					mainActions={mainActions}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>

				<ViewsTable
					title="Polls"
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={fixedPolls}
					classes="nested"
					mainColumnActive={false}
					checked={this.state.checkedPolls}
					onClick={(id) => this.toggleEditPoll(id)}
					checkRow={(entryId) => {
						const checked = this.state.checkedPolls;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatecheckedPolls(checked);
						this.setState({
							checkedPolls: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedPolls;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatecheckedPolls(checked);

						this.setState({
							checkedPolls: checked
						});
					}}
					sortData={(column, sortAsc, sortDesc) => {
						/* const sortedData = this.sortData(
							column,
							sortAsc,
							sortDesc,
							editedGroup.sessions,
							normalizedColumns
						);
						this.setState({ editedGroup: { ...editedGroup, sessions: sortedData.organizedEntries } }); */
					}}
				/>
				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}
				/>

				{showAddPollModal && (
					<SessionAddPollModal
						isOpen={showAddPollModal}
						polls={this.props.event.polls}
						sessionPolls={polls}
						toggle={this.toggleAddPoll}
						session={this.props.editedSession}
						pollId={markedPoll}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updatePolls={this.props.updatePolls}
					/>
				)}

				{showRemovePollModal && (
					<SessionRemovePollModal
						isOpen={showRemovePollModal}
						remove={this.removePoll}
						toggle={this.toggleRemovePoll}
						pollId={markedPoll}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updatePolls={this.props.updatePolls}
						polls={this.props.event.polls}
						sessionPolls={this.props.editedSession.polls}
						session={this.props.editedSession}
						checkedPolls={this.state.checkedPolls}
						uncheckEntries={uncheckEntries}
					/>
				)}

				{showEditPollModal && (
					<SessionEditPollModal
						isOpen={showEditPollModal}
						polls={this.props.event.polls}
						sessionPolls={polls}
						toggle={this.toggleEditPoll}
						session={this.props.editedSession}
						pollId={markedPoll}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updatePolls={this.props.updatePolls}
						poll={markedPoll}
					/>
				)}
				{showResetPollModal && (
					<ResetPollModal
						isOpen={showResetPollModal}
						polls={this.props.event.polls}
						sessionPolls={polls}
						toggle={this.toggleResetPoll}
						session={this.props.editedSession}
						pollId={markedPoll}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updatePolls={this.props.updatePolls}
						poll={markedPoll}
						resetPoll={this.props.resetPoll}
						checkedPolls={this.state.checkedPolls}
						uncheckEntries={uncheckEntries}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionPolls;

class SessionAddPollModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			question: '',
			responses: [],
			newReponse: '',
			submitting: false,
			invalid: false,
			success: false,
			correctAnswer: '',
		};
	}

	reset = (e) => {
		this.setState({
			question: '',
			responses: [],
			newReponse: '',
			submitting: false,
			invalid: false,
			success: false,
			correctAnswer: '',
		});
		this.props.toggle();
	};

	handleQuestionChange = (e) => {
		this.setState({ question: e.target.value });
	};

	add = async () => {
		const { eventId, orgId, session } = this.props;
		const { question, responses, correctAnswer } = this.state;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				request: 'addPoll',
				poll: {
					question: question,
					responses: responses,
					correctAnswer: correctAnswer
				}
			})
			.then((res) => {
				if (res.data) {
					const updatedPolls = this.props.sessionPolls;
					updatedPolls.push(res.data);
					this.setState({ submitting: false });
					this.reset();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	addResponse = () => {
		const { responses, newReponse } = this.state;
		if (newReponse !== '' && !responses.includes(newReponse)) {
			responses.push(newReponse);
			this.setState({ responses: responses, newReponse: '', isDuplicate: false, isDuplicate: false });
		} else {
			if (responses.includes(newReponse)) {
				this.setState({ isDuplicate: true });
			}
		}
	};

	removeResponse = (response) => {
		const updatedResponses = this.state.responses.filter((r) => r !== response);
		if (this.state.correctAnswer == response) {
			this.setState({ correctAnswer: '', responses: updatedResponses })
		} else {
			this.setState({ responses: updatedResponses });
		}
	};

	render() {
		const { isOpen } = this.props;
		const { question, responses, newReponse, submitting, invalid, isDuplicate, correctAnswer } = this.state;

		return (

			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Poll"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Poll Question"
									value={question}
									onChange={this.handleQuestionChange}
									placeholder="Poll Question"
								/>
								{responses.map((response, i) => (
									<div className="flex-ac w-100 mb-25">
										<i
											className="las la-minus-circle mr-10 c-pointer clickable"
											style={{ color: 'red' }}
											onClick={() => {
												this.removeResponse(response);
											}}
										/>

										<InputField
											classes="m-0"
											value={response}
											disabled={true}
										/>

										<button className={`neu ml-10 p-7 ${correctAnswer == response ? 'prim' : ''}`} onClick={() => {
											if (correctAnswer == response) {
												this.setState({ correctAnswer: '' })
											} else {
												this.setState({ correctAnswer: response })
											}
										}}>
											<i className='las la-check-circle' />
										</button>
									</div>
								))}
								<div className="formField mt-25">
									<div className="flex-ac w-100">

										<InputField
											value={newReponse}
											onChange={(e) => this.setState({ newReponse: e.target.value })}
											placeholder="New Response"
										/>
										<i
											className="las la-plus-circle ml-10 c-pointer clickable"
											style={{ marginBottom: 25 }}
											onClick={this.addResponse}
										/>
									</div>
								</div>
								{invalid && (
									<div className="calibreRegular modalErrorMessage">Questions and responses required</div>
								)}
								{isDuplicate && <div className="calibreRegular modalErrorMessage">All answers must be unique</div>}
							</div>)
					}}
					toggle={this.reset}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => {
						this.setState({ submitting: true });
						if (question.name !== '' && responses.length > 0) {
							this.add();
						} else {
							this.setState({ invalid: true, submitting: false });
						}
					}}
					actionButtonDisabled={submitting}


				></FieldModal>




			</div>

		);
	}
}

class ResetPollModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			success: false
		};
	}

	reset = () => {
		this.setState({
			submitting: false,
			success: true
		});

		this.props.toggle();
	};

	resetPoll = async () => {
		const { eventId, orgId, session, sessionPolls, checkedPolls, uncheckEntries } = this.props;
		this.setState({ submitting: true });
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				request: 'resetPolls',
				polls: Object.keys(checkedPolls)
			})
			.then((res) => {
				this.props.resetPoll(Object.keys(checkedPolls));

				uncheckEntries();
				this.reset();
			})
			.catch((e) => {
				console.log(e);

				this.setState({
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, poll, sessionPolls, checkedPolls } = this.props;
		const { submitting } = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`Reset Poll${Object.keys(checkedPolls).length != 1 ? 's' : ''}`}
					bodyHeaderText={<span>Are you sure you want to reset{' '}
						<b>
							{Object.keys(checkedPolls).length} poll{Object.keys(checkedPolls).length != 1 ? 's' : ''}
						</b>. This is irreversable!</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={this.reset}
					actionButtonLabel={!submitting ? 'Reset' : 'Resetting...'}
					actionButton={() => this.resetPoll()}
					actionButtonDisabled={submitting}

				></FieldModal>
			</div>

		);
	}
}

class SessionEditPollModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			question: '',
			responses: [],
			newReponse: '',
			submitting: false,
			invalid: false,
			success: false,
			pollId: '',
			correctAnswer: '',
			isDuplicate: false
		};
	}

	componentDidMount() {
		var pollObj = JSON.parse(JSON.stringify(this.props.poll));
		this.setState({
			question: pollObj.question,
			responses: pollObj.responses,
			pollId: pollObj._id,
			correctAnswer: pollObj.correctAnswer
		});
	}

	reset = (e) => {
		this.setState({
			question: '',
			responses: [],
			newReponse: '',
			submitting: false,
			invalid: false,
			success: false,
			pollId: '',
			correctAnswer: '',
		});
		this.props.toggle();
	};

	handleQuestionChange = (e) => {
		this.setState({ question: e.target.value });
	};

	updatedPoll = async () => {
		const { eventId, orgId, session } = this.props;
		const { question, responses, pollId, correctAnswer } = this.state;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				request: 'editPoll',
				poll: {
					_id: pollId,
					question: question,
					responses: responses,
					correctAnswer: correctAnswer
				}
			})
			.then((res) => {
				if (res.data) {
					this.props.poll.question = res.data.question;
					this.props.poll.responses = res.data.responses;
					this.setState({ submitting: false });
					this.reset();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	addResponse = () => {
		const { responses, newReponse } = this.state;
		if (newReponse !== '' && !responses.includes(newReponse)) {
			responses.push(newReponse);
			this.setState({ responses: responses, newReponse: '', isDuplicate: false });
		} else {
			if (responses.includes(newReponse)) {
				this.setState({ isDuplicate: true });
			}
		}
	};

	removeResponse = (response) => {
		const updatedResponses = this.state.responses.filter((r) => r !== response);
		if (this.state.correctAnswer == response) {
			this.setState({ correctAnswer: '', responses: updatedResponses })
		} else {
			this.setState({ responses: updatedResponses });
		}
	};

	render() {
		const { isOpen, poll } = this.props;
		const { question, responses, newReponse, submitting, invalid, isDuplicate, correctAnswer } = this.state;
		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Edit Poll"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Poll Question"
								value={question}
								onChange={this.handleQuestionChange}
								placeholder="Poll Question"
							/>
							{responses.map((response, i) => (
								<div className="flex-ac w-100 mb-25">
									<i
										className="las la-minus-circle mr-10 c-pointer clickable"
										style={{ color: 'red' }}
										onClick={() => {
											this.removeResponse(response);
										}}
									/>

									<InputField
										value={response}
										classes="m-0"
										disabled={true}
									/>
									<button className={`neu ml-10 p-7 ${correctAnswer == response ? 'prim' : ''}`} onClick={() => {
										if (correctAnswer == response) {
											this.setState({ correctAnswer: '' })
										} else {
											this.setState({ correctAnswer: response })
										}
									}}>
										<i className='las la-check-circle' />
									</button>
								</div>
							))}
							<div className="formField mt-25">
								<div className="flex-ac w-100">
									<InputField
										value={newReponse}
										onChange={(e) => this.setState({ newReponse: e.target.value })}
										placeholder="New Response"
									/>
									<i
										className="las la-plus-circle ml-10 c-pointer clickable"
										style={{ marginBottom: 25 }}
										onClick={this.addResponse}
									/>
								</div>
							</div>
							{invalid && (
								<div className="calibreRegular modalErrorMessage">Questions and responses required</div>
							)}
							{isDuplicate && <div className="calibreRegular modalErrorMessage">All answers must be unique</div>}
						</div>)
				}}
				toggle={this.reset}
				actionButtonLabel={!submitting ? 'Submit' : 'Updating...'}
				actionButton={() => {
					this.setState({ submitting: true });
					if (question.name !== '' && responses.length > 0) {

						this.updatedPoll();
					} else {
						this.setState({ invalid: true, submitting: false });
					}
				}}
				actionButtonDisabled={submitting}


			></FieldModal>

		);
	}
}

class SessionRemovePollModal extends React.Component {
	state = {
		submitting: false
	};

	removePoll = async () => {
		const { eventId, orgId, pollId, session, checkedPolls, uncheckEntries } = this.props;
		this.setState({ submitting: true })
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				polls: Object.keys(checkedPolls),
				request: 'removePolls'
			})
			.then((res) => {
				if (res.data) {
					this.props.updatePolls(
						this.props.sessionPolls.filter((p) => !Object.keys(checkedPolls).includes(p._id))
					);
					this.setState({ submitting: false });
					uncheckEntries();
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, checkedPolls, toggle } = this.props;
		const { submitting } = this.state;
		return (

			<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={`Delete Poll${Object.keys(checkedPolls).length != 1 ? 's' : ''}`}
				bodyHeaderText={<span>You are about to delete{' '}
					<b>
						{Object.keys(checkedPolls).length} poll{Object.keys(checkedPolls).length != 1 ? 's' : ''}{' '}
					</b>
					from the session. Would you like to continue?</span>}
				bodyContent={() => {
					return ("")
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Remove' : 'Removing...'}
				actionButton={() => this.removePoll()}
				isDelete={true}
				actionButtonDisabled={submitting}

			></FieldModal>



		);
	}
}
