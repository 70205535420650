import React from 'react';
import { Row } from 'reactstrap';
import 'react-toggle/style.css';
import { ChevronDown, ChevronUp } from 'react-feather';

class ViewableSchedule extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: true
		};
	}

	toggleExpand = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	render() {
		const { expanded } = this.state;
		const { date, sessions, index, readOnly, attendedSessions, isEmpty, attendee } = this.props;
		return (
			<div index={index} className="rowSchedule horizontal" style={{ borderBottom: !isEmpty && '1px solid grey', padding: '10px 0px' }}>
				<Row style={{ padding: 10 }}>
					<div className="col-9" style={{ fontWeight: 'bold' }}>
						{'    '}
						{isEmpty ? 'Date' : date} <span style={{ fontWeight: '200' }} />
					</div>
					<div className="col-3" style={{ textAlign: 'right' }}>
						{!isEmpty && expanded ? (
							<ChevronUp
								onClick={this.toggleExpand}
								style={{ margin: 'auto', marginLeft: '5px', marginTop: '-10px' }}
							/>
						) : (
							<ChevronDown
								onClick={this.toggleExpand}
								style={{ margin: 'auto', marginLeft: '5px', marginTop: '-10px' }}
							/>
						)}
					</div>
				</Row>
				{expanded && (
					<div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
						<Row
							style={{
								padding: '5px',
								marginTop: '10px',
								width: '100%',
								fontWeight: '500',
								margin: 10,
								flexWrap: 'nowrap',
								//borderBottom: '1px solid grey',				
							}}
						>
							<div className="tableColTitle1">Session Name</div>
							<div className="tableColTitle1">Location</div>
							<div className="tableColTitle2">Attended</div>
							<div className="tableColTitle2">Checked In</div>
							<div className="tableColTitle2">Start Time</div>
							<div className="tableColTitle2">End Time</div>
							<div className="tableColTitle2">All Day</div>
							<div className="tableColTitle2">Action</div>
						</Row>

						{!isEmpty && sessions.sort((a, b) => {
							const sessionStartA = new Date(`${date} ${a.startTime}`);
							const sessionStartB = new Date(`${date} ${b.startTime}`);
							return sessionStartA - sessionStartB;

						}).map((s, i) => {
							const checkedIn = s.checkins?.find(ch => ch.attendeeId == attendee._id);
							return (
								<Row
									style={{
										padding: '5px',
										marginTop: '10px',
										flexWrap: 'nowrap',
										width: '100%',
										margin: 10,
										textDecoration: s.excluded && 'line-through'
									}}
								>
									<div className="tableCol1">{s.name}</div>
									<div className="tableCol1">{this.props.locations[s.location]}</div>
									<div className="tableCol2">{attendedSessions.includes(s._id) || checkedIn ? 'Yes' : 'No'}</div>
									<div className="tableCol2">{checkedIn ? 'Yes' : 'No'}</div>
									<div className="tableCol2">{!s.isAllDay ? s.startTime : '-'}</div>
									<div className="tableCol2">{!s.isAllDay ? s.endTime : '-'}</div>
									<div className="tableCol2">{s.isAllDay ? 'Yes' : 'No'}</div>
									{this.props.toggleRemove && (
										<div>
											{!s.inGroup && !s.isGeneral && !s.excluded ? (
												<a
													style={{ color: 'red' }}
													className={`${readOnly ? 'disabled-link' : ''}`}
													onClick={() => this.props.toggleRemove(s._id, false)}
												>
													Remove
												</a>
											) : (
												<div>
													{!s.excluded ? (
														<a
															style={{ color: 'red' }}
															className={`${readOnly ? 'disabled-link' : ''}`}
															onClick={() => this.props.toggleRemove(s._id, true)}
														>
															Exclude
														</a>
													) : (
														<a
															style={{ color: 'green', textDecoration: 'none' }}
															className={`${readOnly ? 'disabled-link' : ''}`}
															onClick={() => this.props.toggleInclude(s._id)}
														>
															Include
														</a>
													)}
												</div>
											)}
										</div>
									)}
								</Row>
							)
						})}
					</div>
				)}
			</div>
		);
	}
}

export default ViewableSchedule;
