import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';
import Select from 'react-select';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import API from '../../../utils/API';
import DeleteDefaultModal from './modals/deleteDefaultModal';
import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import ToggleField from '../../../components/inputs/toggleField';
import TimeField from '../../../components/inputs/timeField';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import VenuesLocationField from '../../../components/venues/venuesLocationField'

const virtualMeetingTypes = [
	{ label: 'Stream', value: 'stream' },
	{ label: 'Meeting', value: 'meeting' },
	{ label: 'In-Person', value: 'in-person' },
	{ label: 'Link', value: 'link' },
	{ label: 'Break', value: 'break' }
];
class EventSession extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const session = props.sessions.find((s) => s._id == params.sessionId);
		this.props.setActiveRow(params.sessionId)
		this.state = {
			session: session,
			editedSession: { ...session },
			customFields: {},
			selectedSection: 0,
			DeleteSessionModalOpen: false,
			loading: true,
			editing: false,
			requiredFields: {
				name: false,
				startTime: false,
				endTime: false
			},
			locationOptions: props.locations,
			
		};
	}
	componentWillUnmount() {
		this.props.setActiveRow(null);
	}

	saveInfo = () => {
		const { editedSession } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedSession[key] || editedSession[key] === '') {
				if (key === 'startTime' || (key === 'endTime' && !editedSession.isAllDay)) {
					return;
				}
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			const today = moment().format('YYYY-MM-DD');
			editedSession.startTime = moment(`${today} ${editedSession.startTime}`).format('hh:mm a');
			editedSession.endTime = moment(`${today} ${editedSession.endTime}`).format('hh:mm a');
			this.props.saveSession(editedSession, () => {
				this.setState({ session: { ...editedSession }, editing: false, requiredFields: requiredFields });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	toggleDelete = () => {
		this.setState({ DeleteSessionModalOpen: !this.state.DeleteSessionModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	editSessionField = (property, value) => {
		const editedSession = this.state.editedSession;
		editedSession[property] = value;
		this.setState({ editedSession: editedSession, editing: true });
	};

	editTravelFields = (data) => {
		this.setState({ editedSession: data });
	};

	editRoomingFields = (data) => {
		this.setState({ editedSession: data });
	};

	updateCustomField = (e, meta, categoryId) => {
		const customFields = this.state.customFields;
		meta.fieldType === 'checkbox'
			? (customFields[categoryId][meta._id].value = e.target.checked)
			: (customFields[categoryId][meta._id].value = e.target.value);

		this.setState({ customFields: customFields, editing: true });
	};

	cancelEdit = () => {
		const session = this.state.session;
		const fields = {};
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedSession: { ...session },
			requiredFields: requiredFields,
			editing: false,
			customFields: fields
		});
	};

	render() {
		const { DeleteSessionModalOpen, loading, editing, session, editedSession, requiredFields } = this.state;
		const { match: { params }, program } = this.props;
		const sessionLocationId = editedSession.location ? editedSession.location : '';
	//	selectedVenue={this.props.venues.find(v => v._id == this.props.event.venue) || ''}

		return (
			<div className="sectionContainer">
				<SectionHeader
					title={`${session.name}`}
					titleIcon={<div className="calibreBold fs-30">{session.name.toUpperCase().charAt(0)}</div>}
					closeButton={true}
					enableLastLink={true}
					subActions={[{ onClick: this.toggleDelete, label: <span><i className='las la-trash mr-10'></i>Delete Session</span> }]}
				/* breadcrumbs={[
					{
						name: program.name
					},
					{
						name: 'Event Sessions'
					}
				]} */
				/>
				<div className="sectionBody">
					<div className="sectionBlock">
						<h2 className="blockHeader">Information</h2>
						<InputField
							label="Name"
							required={true}
							errorTrigger={requiredFields.name}
							value={editedSession.name}
							type="text"
							onChange={(e) => this.editSessionField('name', e.target.value)}
						/>
						<InputField
							label="Subtitle"
							value={editedSession.subtitle}
							type="text"
							onChange={(e) => this.editSessionField('subtitle', e.target.value)}
						/>
						<InputField
							label="Description"
							value={editedSession.description}
							type="text"
							onChange={(e) => this.editSessionField('description', e.target.value)}
						/>

						<ToggleField
							label="Is All Day"
							checked={editedSession.isAllDay}
							icons={false}
							onChange={(e) => this.editSessionField('isAllDay', !editedSession.isAllDay)}
						/>
						{!editedSession.isAllDay && (
							<div className="flex-sb">
								<TimeField
									label={'Start Time'}
									onChange={(time) => this.editSessionField('startTime', time)}
									value={editedSession.startTime}
									classes="mr-10 w-100"
									required={true}
									errorTrigger={requiredFields.startTime}
								/>
								<TimeField
									label={'End Time'}
									onChange={(time) => this.editSessionField('endTime', time)}
									value={editedSession.endTime}
									classes="ml-10 w-100"
									required={true}
									errorTrigger={requiredFields.endTime}
								/>
							</div>
						)}
						<ToggleField
							label="Is General"
							checked={editedSession.isGeneral}
							icons={false}
							onChange={(e) => this.editSessionField('isGeneral', !editedSession.isGeneral)}
						/>
						<SelectField
							label="Session Type"
							value={virtualMeetingTypes.find((vmt) => vmt.value == editedSession.virtualMeetingType)}
							name="Session type"
							options={virtualMeetingTypes}
							onChange={(vmt) => this.editSessionField('virtualMeetingType', vmt.value)}
						/>
						{/* <InputField
							label="Location"
							value={editedSession.location}
							type="text"
							onChange={(e) => this.editSessionField('location', e.target.value)}
						/> */}
					
					<VenuesLocationField
					selectedVenue={this.props.venues.find(v => v._id == this.props.event.details.venue) || ''}
					orgId={this.props.orgId}
					venueOptions={this.props.venues}
					sessionLocationId={editedSession.location}
					isLocation={true}
					selectVenue={(venue) => {
						this.setState({
							venue: {
								value: venue._id,
								label: venue.name,
								color: '#000'
							},
							editing: true
						})
					}}
					venuePicked={this.state.venue}
					selectLocation={(location) => {
						this.editSessionField('location', location._id);
					}}
					addNewLocation={this.props.addNewLocation}
					renameLocation={this.props.renameLocation}
					deleteLocation={(venueId, locationId) => {
						let tempSession = this.state.session;
						if (tempSession.location == locationId) {
							tempSession.location = '';
						}
						let tempEditedSession = this.state.editedSession;
						if (tempSession.location == locationId) {
							tempEditedSession.location = '';

						}
						this.setState({ session: tempSession, editedSession: tempEditedSession });

						this.props.deleteLocation(venueId, locationId);
					}}
				></VenuesLocationField>
					</div>
				</div>
				<SectionFooter editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveInfo}
					updatedAt={session.updatedAt} />
				{DeleteSessionModalOpen && (
					<DeleteDefaultModal
						isOpen={DeleteSessionModalOpen}
						toggle={this.toggleDelete}
						title={`Delete Session`}
						message={`You are about to delete, this is irreversible,
					would you like to continue?`}
						submit={() => {
							const sessions = {};
							sessions[session._id] = true;
							this.props.deleteSession(
								sessions,
								this.props.history.push('./'),

							);
						}}
					/>
				)}
			</div>
		);
	}
}

export default withRouter(EventSession);
