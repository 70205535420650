import React from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';
import axios from 'axios';

import InputField from '../../../../components/inputs/inputField';
import SelectField from '../../../../components/inputs/selectField';
function ValidatePhone(phone) {
	var re = /^\d{10}$/;
	return re.test(phone);
}

class SendTestNotificationModal extends React.Component {
	constructor(props) {
		super(props);
		const validAttendees = this.props.event.attendees.filter((a) => a.notificationSubscriptions && a.notificationSubscriptions.length > 0);
		this.state = {
			sending: false,
			invalid: false,
			error: '',
			validAttendeeOptions: validAttendees.map((a) => {
				return {
					value: a._id,
					label: `${a.firstName} ${a.lastName}`,
					subscriptions: a.notificationSubscriptions.map((s) => {
						return {
							value: s,
							label: s.userAgent || 'Unknown Device'
						};
					})
				};
			}),
			subscriptionOptions: [],
			chosenAttendee: null,
			chosenSubscription: null,
			requiredFields: {},
			sentCount: 0,
			completed: false,
			testNumber: '',
			invalidError: false
		};
	}

	sendTestTexts = async () => {
		const { chosenSubscription } = this.state;
		const { orgId, eventId, notification } = this.props;
		if (chosenSubscription) {
			this.setState({ sending: true, invalidError: false });

			try {
				const res = await API()
					.post(`Organizations/${orgId}/events/${eventId}/notifications/${notification._id}/send`, {
						subscription: chosenSubscription,
						payload: notification.message
					})
				if (res.status == 200) this.setState({ sentCount: this.state.sentCount + 1 });
			} catch (err) {
				console.log(err);
			}
			this.setState({ completed: true });
		} else {
			this.setState({ invalidError: true });
		}
	};

	setChosenAttendee = (chosenAttendee) => {
		this.setState({ chosenAttendee, chosenSubscription: null });
	}
	render() {
		const { isOpen, toggle } = this.props;
		const { sending, completed, emailsTotal, sentCount, validAttendeeOptions } = this.state;

		return (


			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Send a Test Notification"}
					bodyHeaderText={!sending ? (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							Who would you like to send a test notification to?
						</div>
					) : (
						<div style={{ paddingTop: 15, height: 'unset' }} className=" ">
							{completed ? 'Sent' : 'Sending...'}
						</div>
					)}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<SelectField label="Attendee" options={validAttendeeOptions} onChange={this.setChosenAttendee} />
								{this.state.chosenAttendee && (
									<SelectField label="Registered Device" options={this.state.chosenAttendee.subscriptions} onChange={(e) => {
										this.setState({ chosenSubscription: e.value})
									}} />
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Send Test' : completed ? '' : 'Sending...'}
					actionButton={() => this.sendTestTexts()}
					actionButtonDisabled={sending || completed}
					cancelButtonLabel={!completed ? 'Cancel' : 'Close'}
				></FieldModal>

			</div>

		);
	}
}

export default SendTestNotificationModal;
