import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../utils/API';
import LoadingWizard from '../../components/spinner/wizard';
import ProgramRequests from '../../sections/program/requests';
import ProgramCalendars from '../../sections/program/calendars';
import RequestForm from '../../sections/program/requestForm/requestForm';
import EventDefaultDetails from '../../sections/program/eventDefaults/details';
import Attendees from '../../sections/program/eventDefaults/attendees';
import Sessions from '../../sections/program/eventDefaults/sessions';
import EventAttendeeGroups from '../../sections/program/eventDefaults/groups';
import Pages from '../../sections/program/eventDefaults/pages';
import Forms from '../../sections/program/eventDefaults/forms';
import Emails from '../../sections/program/eventDefaults/emails';
import Inventory from '../../sections/program/eventDefaults/inventory';
import Details from '../../sections/program/details';

class Program extends Component {
	constructor(props) {
		super(props);
		this.state = {
			program: {},
			venues: [],
			loading: true,
			section: 0,
			sectionIndex: 0,
			sectionName: 'Program Requests',
			sectionTakeoverRequested: false,
			sectionTakeoverDenied: false,
			sectionTakeoverGranted: false,
			venueOptions: [],
			sideMenuItems: [
				{
					name: 'Event Defaults',
					icon: <i className="las la-icons" />,
					isDropdown: true,
					section: 0,
					dropdownItems: [
						{ section: 0, name: 'Setup' },
						{ section: 3, name: 'Attendees' },
						{ section: 4, name: 'Sessions' },
						{ section: 5, name: 'Groups' },
						{ section: 6, name: 'Pages' },
						{ section: 7, name: 'Forms' },
						{ section: 8, name: 'Email Templates' },
						{ section: 9, name: 'Inventory' },
						{ section: 14, name: 'Settings' }
						/* { section: 13, name: 'Custom Fields' } */
					]
				},
				{
					name: 'Request Form',
					icon: <i className="las la-hand-paper" />,
					section: 1
				},
				{
					name: 'Event Requests',
					icon: <i className="las la-calendar-check" />,
					section: 2
				},
				{
					name: 'Calendars',
					icon: <i className="las la-calendar-day" />,
					section: 10
				},
				/* {
					name: 'Program Emails',
					icon: <i className="las la-envelope" />,
					section: 11
				} */
				{
					name: 'Settings',
					icon: <i className="las la-cog" />,
					section: 12
				}
			]
		};
	}

	componentDidMount() {
		const programId = this.props.match.params.program;
		let { sideMenuItems } = this.state;
		const { orgId, match: { params } } = this.props;
		const section = parseInt(params.section);
		this.getVenues(orgId);
		if (section >= 0) {
			let sectionIndex = 0;
			let sectionName = '';
			sideMenuItems = sideMenuItems.map((emi, index) => {
				emi.target = `/${orgId}/programs/${programId}/${emi.section}/`;
				if (emi.isDropdown) {
					emi.dropdownItems = emi.dropdownItems.map((di) => {
						di.target = `/${orgId}/programs/${programId}/${di.section}/`;
						if (di.section == section) {
							sectionName = di.name;
							sectionIndex = index;
						}
						return di;
					});
				} else if (emi.section == section && !emi.isDropdown) {
					sectionIndex = index;
					sectionName = emi.name;
				}
				return emi;
			});
			this.setState({
				section: section,
				sectionIndex: sectionIndex,
				sectionName: sectionName,
				sideMenuItems: sideMenuItems
			});
		}
		API()
			.get(`Organizations/${orgId}/programs/${programId}`)
			.then((res) => {
				if (res.data) {
					const mappedProgram = res.data;
					if (mappedProgram.eventDefaults) {
						mappedProgram.eventDefaults.attendees = mappedProgram.eventDefaults.attendees.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${programId}/attendees/${a._id}`
							};
						});
						mappedProgram.eventDefaults.sessions = mappedProgram.eventDefaults.sessions.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${programId}/schedule/${a._id}`
							};
						});
						mappedProgram.eventDefaults.groups = mappedProgram.eventDefaults.groups.map((a) => {
							return {
								...a,
								attendeeCount: a.attendees.length,
								sessionCount: a.sessions.length,
								capacity: a.maxCapacityEnabled ? a.maxCapacity.toString() : 'N/A',
								link: `/${orgId}/programs/${programId}/groups/${a._id}`
							};
						});
						mappedProgram.eventDefaults.pages = mappedProgram.eventDefaults.pages.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${programId}/pages/${a._id}`
							};
						});
						mappedProgram.eventDefaults.forms = mappedProgram.eventDefaults.forms.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${programId}/forms/${a._id}`
							};
						});
						mappedProgram.eventDefaults.emails = mappedProgram.eventDefaults.emails.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${programId}/emails/${a._id}`
							};
						});

						mappedProgram.eventDefaults.inventory = mappedProgram.eventDefaults.inventory.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${programId}/inventory/${a._id}`
							};
						});
					} else {
						mappedProgram.eventDefaults = {
							attendees: [],
							sessions: [],
							groups: [],
							pages: [],
							forms: [],
							emails: [],
							inventory: []
						};
					}

					if (!mappedProgram.requestForm) {
						mappedProgram.requestForm = {
							pages: [],
							submissions: []
						};
					}
					this.setState({ program: mappedProgram, loading: false });
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
		/* this.props.socket.on('section_takeover', (resData) => {
			if (resData) {
				this.setState({
					sectionControlRequested: true,
					sectionTakeoverUserId: resData.id,
					sectionTakeoverUserName: resData.name
				});
			}
		});

		this.props.socket.on('forced_section_takeover', (resData) => {
			window.location.assign(`../${section}`);
		}); */



		API().get(`Organizations/${this.props.orgId}/venues`).then((res) => {
			if (res.data) {
				const venues = res.data.map((venue) => {
					return {
						value: venue._id,
						label: venue.name,
						name: venue.name,
						timezone: venue.timezone || 'America/Los_Angeles',
						address: venue.address,
						platform: true
					};
				});
				this.setState({ venueOptions: venues });
			}
		});
	}


	componentDidUpdate(prevProps) {
		if (this.props.match.params.program !== prevProps.match.params.program) {
			this.componentDidMount();
		}
	}

	saveProgramSettings = async (editedSettings, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateProgramSettings',
			settings: {
				name: editedSettings.name,
				openToPublic: editedSettings.openToPublic,
				autoApprove: editedSettings.autoApprove,
				requestTemplateEnabled: editedSettings.requestTemplateEnabled,
				requestEmailSubject: editedSettings.requestEmailSubject,
				requestEmailRecipients: editedSettings.requestEmailRecipients,
				approvalTemplateEnabled: editedSettings.approvalTemplateEnabled,
				approvalEmailCode: editedSettings.approvalEmailCode,
				approvalEmailSubject: editedSettings.approvalEmailSubject,
				approvalEmailRecipients: editedSettings.approvalEmailRecipients,
				deniedTemplateEnabled: editedSettings.deniedTemplateEnabled,
				deniedEmailCode: editedSettings.deniedEmailCode,
				deniedEmailSubject: editedSettings.deniedEmailSubject,
				deniedEmailRecipients: editedSettings.deniedEmailRecipients,
				conditionalRequestConfirmationEnabled: editedSettings.conditionalRequestConfirmationEnabled,
				requestConfirmationEmailFieldConditionals: editedSettings.requestConfirmationEmailFieldConditionals,
				integrations: editedSettings.integrations,
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					...editedSettings
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	saveDetails = async (editedDefaultDetails, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventDetails',
			eventDefaults: {
				details: {
					...editedDefaultDetails
				},
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: { ...this.state.program.eventDefaults, details: res.data.details }
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};


	saveStyling = async (editedDefaultStyling, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventStyling',
			eventDefaults: {
				details: {

					style: {
						...editedDefaultStyling.style
					},
					customCSS: editedDefaultStyling.customCSS,
				}
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: { ...this.state.program.eventDefaults, details: res.data.details }
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	saveRequestEmail = async (editedRequestEmail, callback) => {

		const { orgId } = this.props;
		const payload = {
			request: 'updateRequestEmail',
			settings: {
				requestEmailCode: editedRequestEmail.html,
				requestEmailComponents: editedRequestEmail.emailComponents ? editedRequestEmail.emailComponents : [],
				requestEmailCodeOnly: editedRequestEmail.codeOnly ? editedRequestEmail.codeOnly : false,
				requestEmailStyles: editedRequestEmail.emailStyles
			}
		};

		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					...payload.settings
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	}


	saveApprovalEmail = async (editedApprovalEmail, callback) => {

		const { orgId } = this.props;
		const payload = {
			request: 'updateApprovalEmail',
			settings: {
				approvalEmailCode: editedApprovalEmail.html,
				approvalEmailComponents: editedApprovalEmail.emailComponents ? editedApprovalEmail.emailComponents : [],
				approvalEmailCodeOnly: editedApprovalEmail.codeOnly ? editedApprovalEmail.codeOnly : false,
				approvalEmailStyles: editedApprovalEmail.emailStyles
			}
		};

		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					...payload.settings
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	}

	saveDenialEmail = async (editedDenialEmail, callback) => {

		const { orgId } = this.props;
		const payload = {
			request: 'updateDenialEmail',
			settings: {
				deniedEmailCode: editedDenialEmail.html,
				deniedEmailComponents: editedDenialEmail.emailComponents ? editedDenialEmail.emailComponents : [],
				deniedEmailCodeOnly: editedDenialEmail.codeOnly ? editedDenialEmail.codeOnly : false,
				deniedEmailStyles: editedDenialEmail.emailStyles
			}
		};

		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {

				const updatedProgram = {
					...this.state.program,
					...payload.settings
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});

	}

	addAttendee = async (attendee, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventAttendees',
			eventDefaults: {
				attendees: [...this.state.program.eventDefaults.attendees, attendee]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						attendees: res.data.attendees.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${this.state.program._id}/attendees/${a._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(res.data.attendees[res.data.attendees.length - 1]);
			}
		});
	};

	saveAttendee = async (attendee, callback) => {
		const { orgId } = this.props;
		const attendees = this.state.program.eventDefaults.attendees.map((a) => {
			if (a._id === attendee._id) {
				return { ...a, ...attendee };
			}
			return a;
		});
		const payload = {
			request: 'updateDefaultEventAttendees',
			eventDefaults: {
				attendees: attendees
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						attendees: res.data.attendees.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${this.state.program._id}/attendees/${a._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteAttendees = async (attendeeIds, callback) => {
		const { orgId } = this.props;
		const filteredAttendees = this.state.program.eventDefaults.attendees.filter(
			(a) => !attendeeIds.includes(a._id)
		);
		const updatedGroups = this.state.program.eventDefaults.groups.map((g) => {
			return {
				...g,
				attendees: g.attendees.filter((a) => !attendeeIds.includes(a))
			};
		});
		const updatedSessions = this.state.program.eventDefaults.sessions.map((s) => {
			return {
				...s,
				attendees: s.attendees.filter((a) => !attendeeIds.includes(a))
			};
		});
		const payload = {
			request: 'updateDefaultEventAttendees',
			eventDefaults: {
				attendees: filteredAttendees
			}
		};

		if (updatedGroups.length > 0) payload.eventDefaults.groups = updatedGroups;
		if (updatedSessions.length > 0) payload.eventDefaults.sessions = updatedSessions;
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						attendees: res.data.attendees.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${this.state.program._id}/attendees/${a._id}`
							};
						}),

						sessions: updatedSessions,
						groups: updatedGroups
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	addSession = async (session, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'addDefaultEventSession',
			eventDefaults: {
				sessions: [session]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						sessions: res.data.sessions.map((a) => {
							return {
								...a,
								link: `/${orgId}/programs/${this.state.program._id}/schedule/${a._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(res.data.sessions[res.data.sessions.length - 1]);
			}
		});
	};

	saveSession = async (session, callback) => {
		const { orgId } = this.props;

		const sessions = this.state.program.eventDefaults.sessions.map((s) => {
			if (s._id === session._id) {
				return { ...s, ...session };
			}
			return s;
		});
		const payload = {
			request: 'updateDefaultEventSessions',
			eventDefaults: {
				sessions: sessions
			}
		};

		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						sessions: res.data.sessions.map((s) => {
							return {
								...s,
								link: `/${orgId}/programs/${this.state.program._id}/schedule/${s._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteSessions = async (sessionIds, callback) => {
		const { orgId } = this.props;
		const filteredSessions = this.state.program.eventDefaults.sessions.filter((s) => !sessionIds.includes(s._id));
		const payload = {
			request: 'updateDefaultEventSessions',
			eventDefaults: {
				sessions: filteredSessions
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						sessions: res.data.sessions.map((s) => {
							return {
								...s,
								link: `/${orgId}/programs/${this.state.program._id}/schedule/${s._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	addGroup = async (group, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventGroups',
			eventDefaults: {
				groups: [...this.state.program.eventDefaults.groups, group]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						groups: res.data.groups.map((g) => {
							return {
								...g,
								attendeeCount: g.attendees.length,
								sessionCount: g.sessions.length,
								capacity: g.maxCapacityEnabled ? g.maxCapacity.toString() : 'N/A',
								link: `/${orgId}/programs/${this.state.program._id}/groups/${g._id}`
							};
						}, this.props.refreshSectionCounts)
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(res.data.groups[res.data.groups.length - 1]);
			}
		});
	};

	saveGroup = async (group, callback) => {
		const { orgId } = this.props;

		const groups = this.state.program.eventDefaults.groups.map((g) => {
			if (g._id === group._id) {
				return { ...g, ...group };
			}
			return g;
		});
		const payload = {
			request: 'updateDefaultEventGroups',
			eventDefaults: {
				groups: groups
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						groups: res.data.groups.map((g) => {
							return {
								...g,
								attendeeCount: g.attendees.length,
								sessionCount: g.sessions.length,
								capacity: g.maxCapacityEnabled ? g.maxCapacity.toString() : 'N/A',
								link: `/${orgId}/programs/${this.state.program._id}/groups/${g._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteGroups = async (groupIds, callback) => {
		const { orgId } = this.props;
		const filteredGroups = this.state.program.eventDefaults.groups.filter((g) => !groupIds.includes(g._id));
		const payload = {
			request: 'updateDefaultEventGroups',
			eventDefaults: {
				groups: filteredGroups
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						groups: res.data.groups.map((g) => {
							return {
								...g,
								link: `/${orgId}/programs/${this.state.program._id}/groups/${g._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	addPage = async (page, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventPages',
			eventDefaults: {
				pages: [...this.state.program.eventDefaults.pages, page]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						pages: res.data.pages.map((p) => {
							return {
								...p,
								link: `/${orgId}/programs/${this.state.program._id}/pages/${p._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(res.data.pages[res.data.pages.length - 1]);
			}
		});
	};



	deletePages = async (pageIds, callback) => {
		const { orgId } = this.props;
		const filteredPages = this.state.program.eventDefaults.pages.filter((p) => !pageIds.includes(p._id));
		const payload = {
			request: 'updateDefaultEventPages',
			eventDefaults: {
				pages: filteredPages
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						pages: res.data.pages.map((p) => {
							return {
								...p,
								link: `/${orgId}/programs/${this.state.program._id}/pages/${p._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	updatePages = async (pages, callback) => {
		const { orgId } = this.props;
		this.setState({
			program: {
				...this.state.program, eventDefaults: {
					...this.state.program.eventDefaults,
					pages: pages.map((p) => {
						return {
							...p,
							link: `/${orgId}/programs/${this.state.program._id}/pages/${p._id}`
						};
					})
				}
			}
		}, () => {
			callback && callback();
		});
	}

	updatePage = (page, callback) => {
		const { orgId } = this.props;

		const pages = this.state.program.eventDefaults.pages.map((p) => {
			if (p._id === page._id) {
				return { ...p, ...page };
			}
			return p;
		});
		const payload = {
			request: 'updateDefaultEventPages',
			eventDefaults: {
				pages: pages
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						pages: res.data.pages.map((p) => {
							return {
								...p,
								link: `/${orgId}/programs/${this.state.program._id}/pages/${p._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	addForm = async (form, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventForms',
			eventDefaults: {
				forms: [...this.state.program.eventDefaults.forms, form]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						forms: res.data.forms.map((f) => {
							return {
								...f,
								link: `/${orgId}/programs/${this.state.program._id}/forms/${f._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(res.data.forms[res.data.forms.length - 1]);
			}
		});
	};

	duplicateForm = async (formId, name, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'duplicateDefaultEventForms',
			eventDefaults: {
				formId: formId,
				name: name
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						forms: res.data.forms.map((f) => {
							return {
								...f,
								link: `/${orgId}/programs/${this.state.program._id}/forms/${f._id}`
							};
						}
						)
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	duplicateEmail = async (emailId, name, callback) => {
		// Destructuring orgId from props
		const { orgId } = this.props;

		// Creating the payload for the API request
		const payload = {
			request: 'duplicateDefaultEventEmails',
			eventDefaults: {
				emailId: emailId,
				name: name
			}
		};

		// Sending a PATCH request to duplicate the email
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				// Updating the program state with the new email data
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						emails: res.data.emails.map((e) => {
							return {
								...e,
								link: `/${orgId}/programs/${this.state.program._id}/emails/${e._id}`
							};
						})
					}
				};
				// Setting the new program state and refreshing section counts
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);

				// Calling the callback function if provided
				callback && callback();
			}
		});
	};


	saveForm = async (form, callback) => {
		const { orgId } = this.props;

		const forms = this.state.program.eventDefaults.forms.map((f) => {
			if (f._id === form._id) {
				return { ...f, ...form };
			}
			return f;
		});
		const payload = {
			request: 'updateDefaultEventForms',
			eventDefaults: {
				forms: forms
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						forms: res.data.forms.map((f) => {
							return {
								...f,
								link: `/${orgId}/programs/${this.state.program._id}/forms/${f._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteForms = async (formIds, callback) => {
		const { orgId } = this.props;
		const filteredForms = this.state.program.eventDefaults.forms.filter((f) => !formIds.includes(f._id));
		const payload = {
			request: 'updateDefaultEventForms',
			eventDefaults: {
				forms: filteredForms
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						forms: res.data.forms.map((f) => {
							return {
								...f,
								link: `/${orgId}/programs/${this.state.program._id}/forms/${f._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	addEmail = async (email, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventEmails',
			eventDefaults: {
				emails: [...this.state.program.eventDefaults.emails, email]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						emails: res.data.emails.map((e) => {
							return {
								...e,
								link: `/${orgId}/programs/${this.state.program._id}/emails/${e._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts, () => {
					callback && callback(res.data.emails[res.data.emails.length - 1]);
				});

			}
		});
	};

	saveEmail = async (email, callback) => {
		const { orgId } = this.props;

		const emails = this.state.program.eventDefaults.emails.map((e) => {
			if (e._id === email._id) {
				return { ...e, ...email };
			}
			return e;
		});
		const payload = {
			request: 'updateDefaultEventEmails',
			eventDefaults: {
				emails: emails
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						emails: res.data.emails.map((e) => {
							return {
								...e,
								link: `/${orgId}/programs/${this.state.program._id}/emails/${e._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteEmails = async (emailIds, callback) => {
		const { orgId } = this.props;
		const filteredEmails = this.state.program.eventDefaults.emails.filter((e) => !emailIds.includes(e._id));
		const payload = {
			request: 'updateDefaultEventEmails',
			eventDefaults: {
				emails: filteredEmails
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						emails: res.data.emails.map((e) => {
							return {
								...e,
								link: `/${orgId}/programs/${this.state.program._id}/emails/${e._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();

			}
		});
	};

	addInventory = async (item, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'updateDefaultEventInventory',
			eventDefaults: {
				inventory: [...this.state.program.eventDefaults.inventory, item]
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						inventory: res.data.inventory.map((i) => {
							return {
								...i,
								link: `/${orgId}/programs/${this.state.program._id}/inventory/${i._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(res.data.inventory[res.data.inventory.length - 1]);
			}
		});
	};

	saveInventory = async (item, callback) => {
		const { orgId } = this.props;

		const inventory = this.state.program.eventDefaults.inventory.map((i) => {
			if (i._id === item._id) {
				return { ...i, ...item };
			}
			return i;
		});
		const payload = {
			request: 'updateDefaultEventInventory',
			eventDefaults: {
				inventory: inventory
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						inventory: res.data.inventory.map((i) => {
							return {
								...i,
								link: `/${orgId}/programs/${this.state.program._id}/inventory/${i._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteInventory = async (itemIds, callback) => {
		const { orgId } = this.props;
		const filteredInventory = this.state.program.eventDefaults.inventory.filter((i) => !itemIds.includes(i._id));
		const payload = {
			request: 'updateDefaultEventInventory',
			eventDefaults: {
				inventory: filteredInventory
			}
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					eventDefaults: {
						...this.state.program.eventDefaults,
						inventory: res.data.inventory.map((i) => {
							return {
								...i,
								link: `/${orgId}/programs/${this.state.program._id}/inventory/${i._id}`
							};
						})
					}
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	requestTakeOver = () => {
		const { singleUserSectionInUse } = this.props;
		this.props.socket.post(
			`/Organizations/${this.props.orgId}/sectionOverlap`,
			{
				request: 'takeover',
				userId: singleUserSectionInUse.id,
				location: `${window.location.pathname}${window.location.search}`
			},
			(response) => {
				if (response.success) {
					this.setState({
						sectionTakeoverRequested: true,
						sectionTakeoverDenied: false
					});
				} else if (response.sectionFree) {
					window.location.reload();
				}
			}
		);
		/* this.props.socket.on('takeover_response', (resData) => {
			if (resData.allowed) {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverGranted: true });
			} else {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverDenied: true });
			}
		}); */
	};

	addCalendar = async (calendar, callback) => {
		const { orgId } = this.props;
		const payload = {
			request: 'addProgramCalendar',
			calendar: calendar
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					calendars: res.data.calendars.map((c) => {
						return {
							...c,
							link: `/${orgId}/programs/${this.state.program._id}/calendars/${c._id}`
						};
					})
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback(updatedProgram.calendars[updatedProgram.calendars.length - 1]);
			}
		});
	};

	saveCalendar = async (calendar, callback) => {
		const { orgId } = this.props;
		const calendars = this.state.program.calendars.map((c) => {
			if (c._id === calendar._id) {
				return { ...c, ...calendar };
			}
			return c;
		});
		const payload = {
			request: 'updateProgramCalendars',
			calendars: calendars
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					calendars: res.data.calendars.map((c) => {
						return {
							...c,
							link: `/${orgId}/programs/${this.state.program._id}/calendars/${c._id}`
						};
					})
				};
				this.setState({ program: updatedProgram });
				callback && callback();
			}
		});
	};

	deleteCalendar = async (calendarIds, callback) => {
		const { orgId } = this.props;
		const filteredCalendars = this.state.program.calendars.filter((c) => !calendarIds.includes(c._id));
		const payload = {
			request: 'updateProgramCalendars',
			calendars: filteredCalendars
		};
		API().patch(`Organizations/${orgId}/programs/${this.state.program._id}`, payload).then((res) => {
			if (res.data) {
				const updatedProgram = {
					...this.state.program,
					calendars: res.data.calendars.map((c) => {
						return {
							...c,
							link: `/${orgId}/programs/${this.state.program._id}/calendars/${c._id}`
						};
					})
				};
				this.setState({ program: updatedProgram }, this.props.refreshSectionCounts);
				callback && callback();
			}
		});
	};

	forceTakeOver = () => {
		const { singleUserSectionInUse } = this.props;
		this.props.socket.post(
			`/Organizations/${this.props.orgId}/sectionOverlap`,
			{
				request: 'force_takeover',
				userId: singleUserSectionInUse.id,
				location: `${window.location.pathname}${window.location.search}`
			},
			(response) => {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverGranted: true });
			}
		);
		/* this.props.socket.on('takeover_response', (resData) => {
			if (resData.allowed) {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverGranted: true });
			} else {
				this.setState({ sectionTakeoverRequested: false, sectionTakeoverDenied: true });
			}
		}); */
	};

	//if response is true access was given else it was denied
	respondToTakeover = (response) => {
		const { match: { params } } = this.props;
		const section = parseInt(params.section);
		this.props.socket.post(
			`/Organizations/${this.props.orgId}/sectionOverlap`,
			{
				request: 'takeover_response',
				response: response,
				userId: this.state.sectionTakeoverUserId,
				location: `${window.location.pathname}${window.location.search}`
			},
			(res) => {
				if (res.success) {
					if (response) {
						window.location.assign(`../${section}`);
					} else {
						this.setState({ sectionControlRequested: false });
					}
				}
			}
		);
	};

	updateProgramRequestForm = (form, cb) => {
		const program = this.state.program;
		program.requestForm = form;
		this.setState({ program }, cb);
	};


	updateProgramRequestFormPage = (page, cb) => {
		const program = this.state.program;
		program.requestFormPage = page;
		program.requestFormPageId = page._id;
		this.setState({ program }, cb);
	};

	deleteVenue = (id) => {
		const { venues } = this.state;

		const updatedVenues = venues.filter(v => v._id !== id);
		this.setState({ venues: updatedVenues });

	}
	renameVenue = (updatedVenue) => {
		const { venues } = this.state;

		const updatedVenues = venues.map(v => {
			if (v._id == updatedVenue._id) {
				return updatedVenue;
			}
			return v;
		})
		this.setState({ venues: updatedVenues });

	}
	addNewVenue = (venue, cb) => {
		const { venues } = this.state;
		this.setState({ venues: [...venues, venue] });
		cb && cb(venue);
	}
	getVenues = (orgId) => {
		let oId = orgId ? orgId : this.props.orgId;
		API().get(`Organizations/${oId}/venues`).then((res) => {
			if (res.data) {
				const venues = res.data;
				this.setState({ venues: venues });
			}
		});
	};

	addNewLocation = (location) => {
		const { venues, program } = this.state;
		const event = program.eventDefaults ? program.eventDefaults.details : {};
		let updatedVenues = venues.map(v => {

			if (v._id == event.venue) {
				v.locations.push(location);
				return v;
			}

			return v;
		})

		this.setState({ venues: [...updatedVenues] });

	}

	renameLocation = (location) => {
		const { venues, program } = this.state;
		const event = program.eventDefaults ? program.eventDefaults.details : {};
		let updatedVenues = venues.map(v => {

			if (v._id == event.venue) {
				let updatedLocations = v.locations;

				v.locations = updatedLocations.map(l => {

					if (l._id == location._id) {
						return location;
					}
					return l;
				});
			}

			return v;
		})
		this.setState({ venues: updatedVenues })

	}
	deleteLocation = (venueId, locationId) => {

		const { venues } = this.state;
		const updatedVenues = venues.map(v => {

			if (v._id == venueId) {

				let updatedLocations = v.locations.filter(l => l._id !== locationId);
				v.locations = updatedLocations;
			}
			return v;
		})
	}


	render() {
		const {
			program,
			loading,
			sectionTakeoverRequested,
			sectionTakeoverDenied,
			sectionControlRequested,
			sectionTakeoverGranted,
			sideMenuItems,
			venueOptions,
			venues
		} = this.state;
		const { match: { params }, orgId } = this.props;
		const sideMenu = sideMenuItems;
		const section = parseInt(params.section);
		let sectionIndex = this.state.sectionIndex;
		let sectionName = this.state.sectionName;
		if (parseInt(section) >= 0) {
			sideMenu.forEach((emi, index) => {
				if (emi.isDropdown) {
					emi.dropdownItems.forEach((di) => {
						if (di.section == section) {
							sectionName = di.name;
							sectionIndex = index;
						}
					});
				} else if (emi.section == section && !emi.isDropdown) {
					sectionIndex = index;
					sectionName = emi.name;
				}
			});
		}
		const event = program.eventDefaults ? program.eventDefaults.details : {};
		const v = this.state.venues.find((v) => v._id === event.venue);
		const locations = {};
		const locationOptions = [];
		if (v) {
			v.locations.forEach((l) => {
				locationOptions.push({
					value: l._id,
					label: l.name,
					color: '#000'
				});
				locations[l._id] = l.name;
			});
		}
		return (
			<div className={`pageContainer`}>
				{!loading && (
					<div className="sectionContainer">
						<Switch>
							<Route path={`/${orgId}/programs/${program._id}/requests`}>
								<ProgramRequests
									loading={loading}
									program={program}
									sectionName={sectionName}
									orgId={this.props.orgId}
								/>
							</Route>
							<Route path={`/${orgId}/programs/${program._id}/requestForm`}>
								<RequestForm
									loading={loading}
									program={program}
									programId={program._id}
									updateProgramRequestForm={this.updateProgramRequestForm}
									updateProgramRequestFormPage={this.updateProgramRequestFormPage}
									sectionName={sectionName}
									orgId={this.props.orgId}
									fields={program.eventCustomFields}
									addNewField={this.props.addNewField}
									account={this.props.account}
								/>
							</Route>
							<Route path={`/${orgId}/programs/${program._id}/details`}>
								<EventDefaultDetails
									program={program}
									venueOptions={venueOptions}
									event={program.eventDefaults ? program.eventDefaults.details : {}}
									saveDetails={this.saveDetails}
									saveStyling={this.saveStyling}
									venues={venues}
									organization={this.props.organization}
									orgId={this.props.orgId}
									addNewVenue={this.addNewVenue}
									renameVenue={this.renameVenue}
									deleteVenue={this.deleteVenue}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/attendees`}>
								<Attendees
									program={program}
									programId={program._id}
									event={program.eventDefaults || {}}
									customFields={program.eventDefaults.customFields.attendees}
									attendees={program.eventDefaults.attendees}
									orgId={this.props.orgId}
									addAttendee={this.addAttendee}
									attendeeViews={program.defaultViews.attendees}
									saveAttendee={this.saveAttendee}
									deleteAttendees={this.deleteAttendees}
									organization={this.props.organization}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/schedule`}>
								<Sessions
									program={program}
									programId={program._id}
									event={program.eventDefaults || {}}
									customFields={program.eventDefaults.customFields.sessions}
									sessions={program.eventDefaults.sessions}
									orgId={this.props.orgId}
									addSession={this.addSession}
									saveSession={this.saveSession}
									sessionsViews={program.defaultViews.sessions}
									deleteSessions={this.deleteSessions}
									locations={locationOptions}
									venues={venues}
									addNewLocation={this.addNewLocation}
									renameLocation={this.renameLocation}
									deleteLocation={this.deleteLocation}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/groups`}>
								<EventAttendeeGroups
									program={program}
									programId={program._id}
									sectionName={sectionName}
									orgId={this.props.orgId}
									groups={program.eventDefaults.groups}
									addGroup={this.addGroup}
									groupsViews={program.defaultViews.groups}
									saveGroup={this.saveGroup}
									deleteGroups={this.deleteGroups}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/pages`}>
								<Pages
									program={program}
									sectionName={sectionName}
									programId={program._id}
									orgId={this.props.orgId}
									pages={program.eventDefaults.pages}
									addPage={this.addPage}
									pagesViews={program.defaultViews.pages}
									savePage={this.savePage}
									deletePages={this.deletePages}
									fields={program.eventCustomFields}
									organization={this.props.organization}
									account={this.props.account}
									updatePage={this.updatePage}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/forms`}>
								<Forms
									program={program}
									sectionName={sectionName}
									programId={program._id}
									orgId={this.props.orgId}
									forms={program.eventDefaults.forms}
									addForm={this.addForm}
									duplicateForm={this.duplicateForm}
									formsViews={program.defaultViews.forms}
									fields={program.eventDefaults.customFields.attendees}
									saveForm={this.saveForm}
									deleteForms={this.deleteForms}
									organization={this.props.organization}
									updatePages={this.updatePages}
									eventFields={program.eventCustomFields}
									attendeeFields={program.eventDefaults.customFields.attendees}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/emails`}>
								<Emails
									program={program}
									sectionName={sectionName}
									programId={program._id}
									orgId={this.props.orgId}
									duplicateEmail={this.duplicateEmail}
									emails={program.eventDefaults.emails}
									addEmail={this.addEmail}
									emailsViews={program.defaultViews.emails}
									attendeeFields={program.eventDefaults.customFields.attendees}
									saveEmail={this.saveEmail}
									deleteEmails={this.deleteEmails}
									eventFields={program.eventCustomFields}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/inventory`}>
								<Inventory
									program={program}
									sectionName={sectionName}
									programId={program._id}
									orgId={this.props.orgId}
									inventory={program.eventDefaults.inventory}
									addInventory={this.addInventory}
									saveInventory={this.saveInventory}
									inventoryViews={program.defaultViews.inventory}
									deleteInventory={this.deleteInventory}
								/>
							</Route>

							<Route path={`/${orgId}/programs/${program._id}/calendars`}>
								<ProgramCalendars
									loading={loading}
									program={program}
									sectionName={sectionName}
									orgId={this.props.orgId}
									calendars={program.calendars}
									addCalendar={this.addCalendar}
									saveCalendar={this.saveCalendar}
									deleteCalendar={this.deleteCalendar}
									calendarViews={program.programCalendarViews}
								/>
							</Route>
							{/* <Route path={`/${orgId}/programs/${program._id}/11`}>
								<ProgramEmails
									program={program}
									sectionName={sectionName}
									programId={program._id}
									orgId={this.props.orgId}
									emails={[]}
									emailsViews={program.programEmailViews}
								/>
							</Route> */}
							<Route path={`/${orgId}/programs/${program._id}/overview`}>
								<Details
									program={program}
									sectionName={sectionName}
									programId={program._id}
									orgId={this.props.orgId}
									saveDetails={this.saveProgramSettings}
									saveRequestEmail={this.saveRequestEmail}
									saveApprovalEmail={this.saveApprovalEmail}
									saveDeniedEmail={this.saveDenialEmail}
									saveStyling={this.saveProgramStyling}
								/>
							</Route>

							{/* <Route path={`/${orgId}/programs/${program._id}/13`}>
								<CustomFields
									program={program}
									programId={program._id}
									sectionName={sectionName}
									orgId={this.props.orgId}
								/>
							</Route> */}
						</Switch>

						{/* <Modal isOpen={singleUserSectionInUse && !sectionTakeoverGranted}>
							<ModalHeader className="modalHeader">
								<div className="fs-30 calibreBold">Section unavailable</div>
							</ModalHeader>

							<ModalBody className="modalBody ">
								{sectionTakeoverDenied ? (
									'Access denied'
								) : sectionTakeoverRequested ? (
									'Waiting for access response...'
								) : (
									`Section currently in use by ${singleUserSectionInUse.name}`
								)}
							</ModalBody>

							<ModalFooter className="modalFooter">
								{sectionTakeoverRequested ? (
									<Button
										onClick={() => this.forceTakeOver()}
										className="modalButton actionButton actionSave m-0"
									>
										<div className="calibreBold fs-16">Force Take Over</div>
									</Button>
								) : (
									<Button
										onClick={() => this.requestTakeOver()}
										className="modalButton actionButton actionSave m-0"
									>
										<div className="calibreBold fs-16">Request Take Over</div>
									</Button>
								)}
								<Button
									onClick={() => window.location.assign(`../${section}`)}
									className="modalButton actionButton actionOutline mb-0 ml-a"
								>
									<div className="calibreBold fs-16">Leave</div>
								</Button>
							</ModalFooter>
						</Modal>
						<Modal isOpen={sectionControlRequested}>
							<ModalHeader className="modalHeader">
								<div className="fs-30 calibreBold">Access Requested</div>
							</ModalHeader>
							<ModalBody className="modalBody">
								Section access and control is being requested by {this.state.sectionTakeoverUserName}
							</ModalBody>
							<ModalFooter className="modalFooter">
								<Button
									className="modalButton actionButton actionSave m-0"
									onClick={() => this.respondToTakeover(true)}
								>
									<div className="calibreBold fs-16">Allow</div>
								</Button>
								<Button
									onClick={() => this.respondToTakeover(false)}
									className="modalButton actionButton actionOutline mb-0 ml-a"
								>
									<div className="calibreBold fs-16">Deny</div>
								</Button>
							</ModalFooter>
						</Modal> */}
					</div>
				)}
				<LoadingWizard text="Loading Program" loading={loading} />
			</div>
		);
	}
}

export default withRouter(Program);
