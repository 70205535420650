import React, { Component } from 'react';
import Select from 'react-select';
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
class SelectField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uniqueId: `id-${uuidv4()}`,
		};
	}
	render() {
		const {
			onChange,
			label,
			errorTrigger,
			errorMessage,
			actionOn,
			actionMessage,
			value,
			disabled,
			placeholder,
			style,
			name,
			options,
			onInputChange,
			noOptionsMessage,
			required,
			formatGroupLabel,
			classes,
			isMulti,
			additionalSelectElement,
			disableFilter,
			relative,
			information
		} = this.props;
		const { uniqueId } = this.state;
		return (
			<div className={`formFieldContainer selectField ${classes ? classes : ''} ${disabled ? 'disabledField' : ''}`} style={style}>
				<div className="formField">
					<div className="labelContainer">
						<div className='flex ' >
							{label && (
								<div className="formFieldLabel">
									{label}
									{required ? '*' : ''}
								</div>
							)}

							{information &&
								<>
									<i
										id={uniqueId}

										className="grey las la-info-circle informationIcon mb-5"
									/>
									<UncontrolledTooltip
										className="tooltip formFieldTooltip"
										placement="right"
										target={uniqueId}
									>
										{information}
									</UncontrolledTooltip>
								</>
							}

						</div>
						{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'}</div>}
						{actionOn && <div className="formFieldLabel">{actionMessage}</div>}
					</div>
					<div style={additionalSelectElement ? { position: 'relative' } : {}}>
						{!disableFilter ? <Select
							//menuIsOpen={true}
							formatGroupLabel={formatGroupLabel}
							isMulti={isMulti}
							styles={{
								valueContainer: (e) => ({
									...e,
									height: 30
								}),
								singleValue: (e) => ({
									...e,
									height: 30
								}),
								indicatorSeparator: (e) => ({
									...e,
									display: 'none'
								}),
								dropdownIndicator: (e) => ({
									...e,
									height: 30,
									padding: 0,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: 30
								}),
								input: (e) => ({
									...e,
									height: 30,
									lineHeight: 1.2
								}),
								option: (e) => ({
									...e,
									height: 30,
									lineHeight: 1.2
								}),
								menuPortal: (e) => ({
									...e,
									zIndex: 9999
								})
							}}
							menuPortalTarget={!relative && document.body}
							menuPosition={'fixed'}
							placeholder={placeholder}
							className={`editingSelectField  ${disabled ? 'disabled-pointer-events' : ''} ${errorTrigger
								? 'error'
								: ''}`}
							classNamePrefix="select"
							value={value}
							name={name}
							options={options}
							onChange={onChange}
							onInputChange={onInputChange}
							noOptionsMessage={noOptionsMessage}

						/> : <Select
							//menuIsOpen={true}
							formatGroupLabel={formatGroupLabel}
							isMulti={isMulti}
							styles={{
								valueContainer: (e) => ({
									...e,
									height: 30
								}),
								singleValue: (e) => ({
									...e,
									height: 30
								}),
								indicatorSeparator: (e) => ({
									...e,
									display: 'none'
								}),
								dropdownIndicator: (e) => ({
									...e,
									height: 30,
									padding: 0,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: 30
								}),
								input: (e) => ({
									...e,
									height: 30,
									lineHeight: 1.2
								}),
								option: (e) => ({
									...e,
									height: 30,
									lineHeight: 1.2
								}),
								menuPortal: (e) => ({
									...e,
									zIndex: 9999
								})
							}}
							menuPortalTarget={document.body}
							menuPosition={'fixed'}
							placeholder={placeholder}
							className={`editingSelectField  ${disabled ? 'disabled-pointer-events' : ''} ${errorTrigger
								? 'error'
								: ''}`}
							classNamePrefix="select"
							value={value}
							name={name}
							options={options}
							onChange={onChange}
							onInputChange={onInputChange}
							noOptionsMessage={noOptionsMessage}
							filterOption={options => options}
						/>}

						{additionalSelectElement && additionalSelectElement}

					</div>
				</div>
			</div >
		);
	}
}

export default SelectField;
