import React from 'react';

import FieldModal from './fieldModal';

import Croppie from 'croppie/croppie';

const croppieOptions = {
    showZoomer: true,
    enableOrientation: true,
    maxZoom: .5,
    viewport: {
        width: 200,
        height: 200,
        type: 'circle'
    },
    boundary: {
        width: 300,
        height: 300
    }
};

class ImageCropper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateResults: null,
            submitting: false,
            invalid: false,
            success: false,
            selectedValue: {},
            requiredFields: {
                selectedValue: false
            },
            croppedImage: null,
            isFileUploaded: false,
            c: null
        };
    }

    componentDidMount() {

        const { selectedFile } = this.props;
        const croppie = document.getElementById("croppie");
        const c = new Croppie(croppie, croppieOptions);
        c.bind({ url: selectedFile });

        this.setState({ c: c });
    }
    onResult = () => {
        const { c } = this.state;
        c.result({
            type: "blob",
            size: { width: 500, height: 500 },
            format: "jpg",
            quality: 1,
            circle: false
        }).then(blob => {
            if (blob) {
                var formData = new FormData();
                formData.append('includesImage', true);
                formData.append('files', blob);
                if (this.props.returnBlob) {
                    this.props.saveImage(formData, blob);
                } else {
                    this.props.saveImage(formData, () => {
                        this.props.toggle();
                    });
                }
            }
        });
    };

    render() {
        const { isOpen, toggle, submit } = this.props;
        const { submitting, success } = this.state;

        return (
            <div>
                <FieldModal
                    size="medium"
                    isOpen={isOpen}
                    modalTitle={"Profile Picture"}
                    bodyHeaderText={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <div id="croppie">
                                </div>
                            </div>
                        )
                    }}
                    toggle={toggle}
                    actionButtonLabel={!success ? !submitting ? 'Submit' : 'Updating...' : 'Great!'}
                    cancelButtonLabel={success && 'Close'}
                    actionButton={() => {
                        this.onResult()
                    }}
                    submitting={submitting}
                    actionButtonDisabled={submitting || success}
                ></FieldModal>
            </div>
        )
    }
}

export default ImageCropper;
