import React from 'react';

import API from '../../../../utils/API';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

import InputField from '../../../../components/inputs/inputField.js';
import ToggleField from '../../../../components/inputs/toggleField';
import SelectField from '../../../../components/inputs/selectField';
import TimeField from '../../../../components/inputs/timeField';

import FieldModal from '../../../../components/modals/fieldModal';

const virtualMeetingTypes = [
	{ label: 'Stream', value: 'stream' },
	{ label: 'Meeting', value: 'meeting' },
	{ label: 'In-Person', value: 'in-person' },
	{ label: 'Link', value: 'link' },
	{ label: 'Break', value: 'break' }
];
class AddSessionModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			subtitle: '',
			description: '',
			date: moment(this.props.eventStartDate).format('MM/DD/YYYY'),
			isAllDay: false,
			startTime: '',
			endTime: '',
			isGeneral: false,
			submitting: false,
			invalid: false,
			virtualMeetingType: '',
			requiredFields: {
				name: false,
				date: false,

				startTime: false,
				endTime: false,
				virtualMeetingType: false
			},
			requiredFieldsIsAllDay: {
				name: false,
				date: false,
				virtualMeetingType: false
			}
		};
	}

	addSession = async (session) => {
		this.setState({ submitting: true });
		const { eventId, orgId } = this.props;
		const requiredFields = this.state.requiredFields;
		const requiredFieldsIsAllDay = this.state.requiredFieldsIsAllDay;
		let valid = true;
		if (this.state.isAllDay) {
			Object.keys(requiredFieldsIsAllDay).forEach((key) => {
				if (!session[key] || session[key] === '') {
					requiredFields[key] = true;
					valid = false;
				} else {
					requiredFields[key] = false;
				}
			});
		} else {
			Object.keys(requiredFields).forEach((key) => {
				if (!session[key] || session[key] === '') {
					requiredFields[key] = true;
					valid = false;
				} else {
					requiredFields[key] = false;
				}
			});
		}
		if (valid) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/session`, {
					...session,
				})
				.then((res) => {
					if (res.data) {
						this.props.handleSubmit(res.data);
						this.resetAdd(true);
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
		}
	};

	resetAdd = (e, toggle) => {
		this.setState({
			name: '',
			subtitle: '',
			description: '',
			date: moment(this.props.eventStartDate).format('MM/DD/YYYY'),
			isAllDay: false,
			startTime: moment(this.props.eventStartDate).format('h:mm a'),
			endTime: '',
			isGeneral: false,
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			name,
			subtitle,
			description,
			date,
			isAllDay,
			startTime,
			endTime,
			isGeneral,
			submitting,
			invalid,
			virtualMeetingType,
			requiredFields
		} = this.state;

		const fixedDate = date ? moment(date).format('YYYY-MM-DD') : '';

		return (
			<div>
				<FieldModal
					size='medium'
					isOpen={isOpen}
					modalTitle={"Add Session"}
					bodyHeaderText={``}
					bodyDescription={``}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Session Name"
									value={name}
									required={true}
									type="text"
									errorTrigger={requiredFields.name}
									onChange={(e) => {
										this.setState({
											name: e.target.value
										});
									}}
								/>

								<InputField
									label="Date"
									value={fixedDate}
									min={moment().format('yyyy-MM-DD')}
									type="date"
									required={true}
									errorTrigger={requiredFields.date}
									onChange={(e) => {
										this.setState({
											date: moment(e.target.value).format('MM/DD/YYYY')
										});
									}}
									inputIcon={<i className="lar la-calendar" />}
								/>

								<ToggleField
									label={'Is All Day'}
									checked={isAllDay}
									icons={false}
									onChange={(e) => {
										this.setState({ isAllDay: !this.state.isAllDay });
									}}
								/>

								{!isAllDay && (
									<div className="flex-sb w-100">
										<TimeField
											label={'Start Time'}
											onChange={(time) => {
												this.setState({
													startTime: time
												});
											}}
											required={true}
											errorTrigger={requiredFields.startTime}
											value={startTime}
											classes="mr-10 w-100"
										/>

										<TimeField
											label={'End Time'}
											onChange={(time) => {
												this.setState({
													endTime: time
												});
											}}
											required={true}
											errorTrigger={requiredFields.endTime}
											value={endTime}
											classes="ml-10 w-100"
										/>
									</div>
								)}

								<SelectField
									label="Session Type"
									value={virtualMeetingTypes.find((vmt) => vmt.value == virtualMeetingType)}
									options={virtualMeetingTypes}
									required={true}
									errorTrigger={requiredFields.virtualMeetingType}
									onChange={(vmt) => this.setState({ virtualMeetingType: vmt.value })}
								/>
								<ToggleField
									label="Show for all Attendees"
									defaultChecked={isGeneral}
									icons={false}
									checked={this.state.isGeneral}
									onChange={(e) => {
										this.setState({ isGeneral: !this.state.isGeneral });
									}}
								/>
							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding Session...'}
					cancelButtonLabel={'Cancel'}

					actionButton={() => {
						this.setState({ submitting: true });

						this.addSession({
							name,
							subtitle,
							description,
							date,
							startTime,
							endTime,
							isAllDay,
							isGeneral,
							virtualMeetingType
						});
					}}
					submitting={submitting}
					actionButtonDisabled={submitting}

				></FieldModal>
			</div>

		);
	}
}

export default AddSessionModal;
