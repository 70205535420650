import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import FormBuilder from "../../../../components/forms/formBuilder";

class FormBuilderModal extends Component {
    render() {
        const { isOpen, toggle, editedForm, saveForm, editing, cancelEdit } = this.props;
        return <Modal isOpen={isOpen} toggle={toggle} className="fullscreen">
            <div className='modalHeader ph-20 ' style={{ borderBottom: '1px solid var(--background)' }}>
                <div className="headerContainer" style={{ maxWidth: 'unset', minHeight: 60, height: 60 }}>

                    <div >
                        <h2>Form Builder</h2>
                        <h3>{editedForm.name}</h3>
                        {/*  <div className="flex aic">

                            <h4>Form</h4> <div className='mr-10 ml-10' style={{ fontSize: 12, lineHeight: 1 }}>|</div>
                        </div> */}
                    </div>
                    <div className="flex">

                        <button className="neu mr-15 " onClick={() => {
                            if (editing) {
                                if (window.confirm('Are you sure you want to discard your changes?')) {
                                    cancelEdit();
                                    toggle();
                                }
                            } else {
                                toggle();
                            }
                        }}>{editing ? "Discard & Close" : "Close"}</button>
                        <button className={`neu  ${!editing ? 'disabled' : 'prim'}`}
                            onClick={saveForm}>Save</button>
                    </div>
                </div>
            </div>
            <ModalBody className="modalBody">
                <FormBuilder {...this.props} />
            </ModalBody>
        </Modal>
    }
}

export default FormBuilderModal;