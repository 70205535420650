import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import EventSessionInfo from './info';
import API from '../../../utils/API';
import EventSessionAttendees from './attendees';
import EventSessionSpeakers from './speakers';
import EventSessionGroups from './groups';
import EventSessionEmails from './emails';
import DeleteSessionModal from './modals/deleteSessionModal';
import DuplicateSessionModal from './modals/duplicateSessionModal';
import EventSessionPolls from './polls';
import EventSessionSurveys from './surveys';
import EventSessionModerators from './moderators';
import EventSessionCheckIns from './checkIns';

import TabsContainer from '../../../components/views/TabsContainer';

import LoadingWizard from '../../../components/spinner/wizard';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';

import EventSessionComments from './comments';
import AddTeamMemberModal from './modals/addTeamMemberModal';

import AttendeesAndGroups from './attendeesAndGroups';

class EventSession extends Component {
	constructor(props) {
		super(props);
		const session = {
			name: '',
			slug: '',
			subtitle: '',
			description: '',
			date: '',
			startDate: '',
			endDate: '',
			startTime: '',
			endTime: '',
			location: '',
			template:'',
			setPeople: 0,
			guaranteedPeople: 0,
			roomSet: '',
			foodAndBeverage: '',
			posted: false,
			hotelItemsNeeded: '',
			avOrder: '',
			setTime: '',
			strikeTime: '',
			notes: '',
			attendees: [],
			speakers: [],
			isGeneral: false,
			isAllDay: false,
			displayStartTime: '',
			displayEndTime: '',
			displayTimezone: '',
			virtualMeetingType: '',
			virtualStartOffset: 0,
			virtualStopOffset: 0,
			virtualMeetingLink: '',
			recordingLink: '',
			enableQuestions: false,
			enableReview: false,
			reviewQuestions: [],
			enableEmailReminders: false,
			emailReminders: [],
			enableReviewReminder: false,
			enableReviewSpeakers: false,
			enableReviewRating: false,
			enableReviewAdditionalComments: false,
			metadata: [],
			attendeeQuestions: [],
			groupChatEnabled: false,
			chatEnabled: false,
			reactionsEnabled: false,
			QAEnabled: false,
			pollsEnabled: false,
			usersListEnabled: false,
			reactionsEnabled: false,
			polls: [],
			moderators: [],
			organizers: [],
			pageId: '',
			sessionPageEnabled: true,
			meetingStarted: false,
			meetingStopped: false,
			enableAddToCalendar: false,
			includeAttendeesInPage: false,
			playerControlsEnabled: false,
			mainChatRoom: '',
			reactionRoom: '',
			chatRooms: [],
			surveyId: '',
			checkins: []
		};
		this.state = {
			locationOptions: props.locations,
			session: session,
			editedSession: session,
			customFields: {},
			selectedSection: 0,
			DeleteSessionModalOpen: false,
			loading: true,
			editing: false,
			checkedAttendees: [],
			deleteAttendeeToggle: false,
			toggleDuplicate: false,
			searching: false,
			searchText: '',
			toggleAddTeamMember: false,
			requiredFields: {
				name: false,
				date: false,
				startTime: false,
				endTime: false
			}
		};
	}

	//Fetch complete session information and append to current
	componentDidMount() {
		const { match: { params } } = this.props;
		const { session, editedSession } = this.state;
		const { eventId, orgId } = this.props;
		this.props.setActiveRow(params.sessionId)
		API().get(`Organizations/${orgId}/events/${eventId}/sessions/${params.sessionId}`).then((res) => {
			if (res.data) {
				const resSession = { ...editedSession, ...res.data };
				const fields = {};
				this.props.customFields.forEach((category) => {
					fields[category._id] = { title: category.title, expanded: true };
					category.fields.forEach((field) => {
						fields[category._id][field._id] = { ...field };
					});
				});
				resSession.metadata.forEach((meta) => {
					try {
						fields[meta.categoryId][meta.fieldId].value = meta.fieldValue;
					} catch (e) {
						console.log(e);
					}
				});
				this.setState({
					session: { ...session, ...res.data },
					editedSession: { ...editedSession, ...res.data },
					customFields: fields,
					loading: false
				});
			}
		});
	}
	componentWillUnmount() {
		this.props.setActiveRow(null);
	}
	updateSession = (updatedSession) => { };
	refreshSession = () => {
		const { session } = this.state;
		const { eventId, orgId } = this.props;

		API().get(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`).then((res) => {
			if (res.data) {
				this.props.updateSession(res.data);
				this.setState({ session: { ...session, ...res.data } });
			}
		});
	};

	toggleDelete = () => {
		this.setState({ DeleteSessionModalOpen: !this.state.DeleteSessionModalOpen });
	};

	toggleDuplicate = () => {
		this.setState({ toggleDuplicate: !this.state.toggleDuplicate })
	}

	updateSessionField = (property, value) => {
		const editedSession = this.state.editedSession;
		editedSession[property] = value;
		this.setState({ editedSession: editedSession, editing: true });
	};

	handleDateChange = (property, date) => {
		const editedSession = this.state.editedSession;
		editedSession[property] = moment(date._d).format('M/D/YYYY');
		this.setState({ editedSession: editedSession, editing: true });
	};

	handleTimeChange = (property, date) => {
		const editedSession = this.state.editedSession;
		editedSession[property] = date instanceof moment ? date.format('h:mm a') : moment(date).format('h:mm a');
		this.setState({ editedSession: editedSession, editing: true });
	};

	updateCustomField = (e, meta, categoryId) => {
		const customFields = this.state.customFields;
		meta.fieldType === 'checkbox'
			? (customFields[categoryId][meta._id].value = e.target.checked)
			: (customFields[categoryId][meta._id].value = e.target.value);

		this.setState({ customFields: customFields, editing: true });
	};

	expandCustomField = (categoryId, categoryExpanded) => {
		const customFields = this.state.customFields;
		customFields[categoryId].expanded = !categoryExpanded;
		this.setState({ customFields: customFields });
	};

	saveInfo = () => {
		const { customFields, editedSession } = this.state;
		const metadata = [];
		const requiredFields = this.state.requiredFields;

		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedSession[key] || editedSession[key] === '') {
				if (key === 'startTime' && editedSession.isAllDay) {
					return;
				} else if (key === 'endTime' && editedSession.isAllDay) {
					return;
				}
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			Object.keys(customFields).forEach((categoryId) => {
				Object.keys(customFields[categoryId]).forEach((fieldId) => {
					if (fieldId !== 'title' && fieldId !== 'expanded') {
						const data = customFields[categoryId][fieldId];
						if (data.value || data.required) {
							metadata.push({
								categoryId: categoryId,
								fieldId: fieldId,
								fieldValue: data.value || ''
							});
						}
					}
				});
			});
			editedSession.metadata = metadata;

			if (!editedSession.virtualStartOffset) {
				editedSession.virtualStartOffset = 0;
			}
			if (!editedSession.virtualStopOffset) {
				editedSession.virtualStopOffset = 0;
			}
			if (editedSession.isAllDay) {
				editedSession.startTime = '';
				editedSession.endTime = '';
			}

			const { eventId, orgId } = this.props;
			API()
				.patch(`Organizations/${orgId}/events/${eventId}/sessions/${editedSession._id}`, {
					...editedSession
				})
				.then((res) => {
					if (res.data) {
						const selectedPage = editedSession.pageOptions.find(
							(po) => po._id.toString() == editedSession.pageId.toString()
						) || { title: '', _id: null };
						const updatedSession = {
							_id: res.data._id,
							name: res.data.name,
							slug: res.data.slug,
							subtitle: res.data.subtitle,
							description: res.data.description,
							date: res.data.date,
							startDate: res.data.startDate,
							endDate: res.data.endDate,
							startTime: res.data.startTime,
							endTime: res.data.endTime,
							location: res.data.location,
							isGeneral: res.data.isGeneral,
							isAllDay: res.data.isAllDay,
							displayStartTime: res.data.displayStartTime,
							displayEndTime: res.data.displayEndTime,
							displayTimezone: res.data.displayTimezone,
							virtualMeetingType: res.data.virtualMeetingType,
							virtualStartOffset: res.data.virtualStartOffset,
							virtualStopOffset: res.data.virtualStopOffset,
							virtualMeetingLink: res.data.virtualMeetingLink,
							recordingLink: res.data.recordingLink,
							enableQuestions: res.data.enableQuestions,
							enableReview: res.data.enableReview,
							enableEmailReminders: res.data.enableEmailReminders,
							enableReviewReminder: res.data.enableReviewReminder,
							enableReviewSpeakers: res.data.enableReviewSpeakers,
							enableReviewRating: res.data.enableReviewRating,
							enableReviewAdditionalComments: res.data.enableReviewAdditionalComments,
							metadata: res.data.metadata,
							groupChatEnabled: res.data.groupChatEnabled,
							chatEnabled: res.data.chatEnabled,
							reactionsEnabled: res.data.reactionsEnabled,
							QAEnabled: res.data.QAEnabled,
							pollsEnabled: res.data.pollsEnabled,
							usersListEnabled: res.data.usersListEnabled,
							reactionsEnabled: res.data.reactionsEnabled,
							pageId: res.data.pageId,
							sessionPageEnabled: res.data.sessionPageEnabled,
							enableAddToCalendar: res.data.enableAddToCalendar,
							includeAttendeesInPage: res.data.includeAttendeesInPage,
							playerControlsEnabled: res.data.playerControlsEnabled,
							surveyId: res.data.surveyId,
							foodAndBeverage: res.data.foodAndBeverage,
							hotelItemsNeeded: res.data.hotelItemsNeeded,
							avOrder: res.data.avOrder,
							notes: res.data.notes,
							roomSet: res.data.roomSet,
							setTime: res.data.setTime,
							strikeTime: res.data.strikeTime,
							template: selectedPage.title,
							link: `/${this.props.orgId}/events/${this.props.eventId}/schedule/${res.data._id}`
						};
						this.props.updateSession(editedSession._id, updatedSession);
						this.setState({
							editing: false,

							session: { ...this.state.session, ...updatedSession },
							editedSession: { ...this.state.editedSession, ...updatedSession }
						});
					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	removeGroup = (group, callback) => {
		this.setState({
			editedSession: {
				...this.state.editedSession,
				groups: this.state.editedSession.groups.filter((g) => g._id !== group._id)
			}
		}, () => {
			callback && callback();
		});
	};

	cancelEdit = () => {
		const session = this.state.session;
		const fields = {};
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.props.customFields.forEach((category) => {
			fields[category._id] = { title: category.title, expanded: true };
			category.fields.forEach((field) => {
				fields[category._id][field._id] = { ...field, value: '' };
			});
		});
		session.metadata.forEach((meta) => {
			fields[meta.categoryId][meta.fieldId].value = meta.fieldValue;
		});
		this.setState({
			editedSession: { ...session },
			editing: false,
			customFields: fields,
			requiredFields: requiredFields
		});
	};

	SectionItem = (title, sectionNo) => {
		return (
			<div
				className={`sectionTab ${sectionNo === this.state.selectedSection ? 'selected' : ''}`}
				onClick={() => {
					this.setState({ selectedSection: sectionNo });
				}}
			>
				<div className="calibreBold fs-30">{title}</div>
			</div>
		);
	};

	updateIncludeSessions = (sessions) => {
		const { session } = this.state;
		this.setState({ session: [...session, ...sessions.filter((item) => session.indexOf(item) < 0)] });
	};

	updateIncludeSessions = (sessions) => {
		const { session } = this.state;
		this.setState({
			session: {
				excludedSessions: sessions.excludedSessions.filter((item) => sessions.includes(item))
			}
		});
	};

	updateExcludeSessions = (sessions) => {
		const { session } = this.state;
		this.setState({
			session: {
				excludedSessions: [
					...session.excludedSessions,
					...sessions.excludedSessions.filter((item) => session.excludedSessions.indexOf(item) < 0)
				]
			}
		});
	};

	updateAttendees = (attendees, callback) => {
		this.setState({
			session: { ...this.state.session, attendees: attendees },
			editedSession: { ...this.state.editedSession, attendees: attendees }
		}, () => {
			callback && callback();
		});
	};

	updateSpeakers = (speakers) => {
		speakers.map((speaker) => {
			if (!this.state.session.attendees.find((a) => a._id == speaker._id)) {
				this.state.session.attendees.push(speaker);
			}
		});
		this.setState({
			session: { ...this.state.session, speakers: speakers },
			editedSession: { ...this.state.editedSession, speakers: speakers }
		});
	};
	updateModerators = (moderators) => {
		moderators.map((moderators) => {
			if (!this.state.session.attendees.find((a) => a._id == moderators._id)) {
				this.state.session.attendees.push(moderators);
			}
		});
		this.setState({
			session: { ...this.state.session, moderators: moderators },
			editedSession: { ...this.state.editedSession, moderators: moderators }
		});
	};

	removeCheckedIn = (checkIns) => {
		let filteredCheckins = this.state.editedSession.checkins.filter(
			(checkedIn) => !checkIns.includes(checkedIn._id)
		);
		this.setState({
			session: { ...this.state.session, checkins: filteredCheckins },
			editedSession: { ...this.state.editedSession, checkins: filteredCheckins }
		});
	};
	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	updatePolls = (polls) => {
		this.setState({
			session: { ...this.state.session, polls: polls },
			editedSession: { ...this.state.editedSession, polls: polls }
		});
	};

	/**
	 * This resets poll answers array to [].
	 * @param {*} pollId 
	 */
	resetPoll = (pollId) => {
		var session = this.state.session;
		session.polls.map((p) => {
			if (pollId.includes(p._id)) {
				p.answers = [];
			}
			return p;
		});

		this.setState({
			session: session,
			editedSession: session
		});
	};

	toggleAddAttendees = () => {
		this.setState({ addAttendeeToggle: !this.state.addAttendeeToggle });
	};
	toggleDeleteAttendees = () => {
		this.setState({ deleteAttendeeToggle: !this.state.deleteAttendeeToggle });
	};

	/**attendees is an array of ids */
	excludeUpdateAttendees = (attendees, sessionId, callback) => {
		const { editedSession } = this.state;
		let tempSession = editedSession;

		tempSession.attendees.map((att) => {
			if (attendees.includes(att._id)) {
				var index = att.excludedSessions.indexOf(sessionId);
				if (index !== -1) {
					att.excludedSessions.splice(index, 1);
				}
				return att;
			} else {
				return att;
			}
		});
		this.setState({ editedSession: tempSession }, () => {
			this.props.excludeUpdateAttendees(attendees, sessionId, callback);
		});
	};

	updateSessionTeam = (team) => {
		this.props.updateTeam(this.state.session._id, team);
		this.setState({ session: { ...this.state.session, team: team } });
	};

	renderSection() {
		const { readOnly } = this.props;
		const {
			selectedSection
		} = this.state;

		switch (selectedSection) {

			case 0:
				return (
					<EventSessionInfo
						session={this.state.session}
						editedSession={this.state.editedSession}
						locationOptions={this.state.locationOptions}
						event={this.props.event}
						index={this.props.index}
						readOnly={readOnly}
						eventId={this.props.eventId}
						customFields={this.state.customFields}
						handleDateChange={this.handleDateChange}
						handleTimeChange={this.handleTimeChange}
						updateSessionField={this.updateSessionField}
						updateCustomField={this.updateCustomField}
						expandCustomField={this.expandCustomField}
						orgId={this.props.orgId}
						venues={this.props.venues}
						requiredFields={this.state.requiredFields}
						addNewLocation={this.props.addNewLocation}
						renameLocation={this.props.renameLocation}
						deleteLocation={(venueId, locationId) => {
							let tempSession = this.state.session;
							if (tempSession.location == locationId) {
								tempSession.location = '';
							}
							let tempEditedSession = this.state.editedSession;
							if (tempSession.location == locationId) {
								tempEditedSession.location = '';

							}
							this.setState({ session: tempSession, editedSession: tempEditedSession });

							this.props.deleteLocation(venueId, locationId);
						}}
					/>
				);
			case 1:
				return (
					<EventSessionComments
						orgId={this.props.orgId}
						organization={this.props.organization}
						eventId={this.props.eventId}
						event={this.props.event}
						session={this.state.session}
						account={this.props.account}
					/>
				);
			case 2:
				return (
					<AttendeesAndGroups
						session={this.state.session}
						editedSession={this.state.editedSession}
						event={this.props.event}
						readOnly={readOnly}
						excludeUpdateAttendees={this.excludeUpdateAttendees}
						orgId={this.props.orgId}
						updateSessionField={this.updateSessionField}
						eventId={this.props.eventId}
						updateAttendees={this.updateAttendees}
						updateSessionGroups={this.updateSessionGroups}
						removeGroup={this.removeGroup}
						updateSession={this.props.updateSession}
					/>);

			/* case 22:
				return (
					<EventSessionAttendees
						editedSession={this.state.editedSession}
						event={this.props.event}
						readOnly={readOnly}
						orgId={this.props.orgId}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.props.updateSession}
						updateAttendees={this.updateAttendees}
						batchActions={batchActions}
						mainActions={mainAction}
						checkedAttendees={checkedAttendees}
						addAttendeeToggle={addAttendeeToggle}
						toggleAddAttendees={this.toggleAddAttendees}
						toggleDeleteAttendees={this.toggleDeleteAttendees}
						deleteAttendeesModalOpen={deleteAttendeeToggle}
						searching={searching}
						searchText={searchText}
						excludeUpdateAttendees={this.excludeUpdateAttendees}
					/>
				); */
			/* case 3:
				return (
					<EventSessionCheckIns
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.updateSession}
						removeCheckedIn={this.removeCheckedIn}
					/>
				); */
			/* case 4:
				return (
					<EventSessionGroups
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						updateSession={this.updateSession}
						addNewGroup={this.props.addNewGroup}
						removeGroup={this.removeGroup}
					/>
				); */

			case 3:
				return (
					<EventSessionSpeakers
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.updateSession}
						updateSpeakers={this.updateSpeakers}
					/>
				);
			case 4:
				return (
					<EventSessionPolls
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.updateSession}
						updatePolls={this.updatePolls}
						resetPoll={this.resetPoll}
					/>
				);
			case 5:
				return (
					<EventSessionSurveys
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.updateSession}
					/>
				);
			case 6:
				return (
					<EventSessionModerators
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.updateSession}
						updateModerators={this.updateModerators}
					/>
				);
			case 9:
				return (
					<EventSessionEmails
						editedSession={this.state.editedSession}
						event={this.props.event}
						orgId={this.props.orgId}
						readOnly={readOnly}
						loadMoreAttendees={this.props.loadMoreAttendees}
						updateSession={this.updateSession}
						eventEmails={this.props.eventEmails}
					/>
				);

			default:
		}
	}

	toggleAddTeamMember = () => {
		this.setState({ toggleAddTeamMember: !this.state.toggleAddTeamMember });
	};

	teamMemberElement = () => {
		const { session } = this.state;
		const { eventId, event, orgId, organization } = this.props;

		let members = organization.members;
		let team = session.team || [];
		let mappedTeam = team.map((memberId) => {
			let memberData = members.find((user) => user._id === memberId);
			return memberData;
		}
		).filter((member) => member);

		let renderedTeam = [];
		for (let mappedTeamCount = 0; mappedTeamCount < mappedTeam.length; mappedTeamCount++) {

			if (mappedTeamCount < 4) {
				renderedTeam.push(
					mappedTeam[mappedTeamCount]
				);
			}
		}
		return (
			<div className='flex aic '>
				<div className="flex c-pointer aic mr-30"
					onClick={
						() => {
							this.toggleAddTeamMember();
						}
					}
				>
					<div
						className="flex aic jcc "
						style={{
							width: 13,
							height: 13,
							borderRadius: '50%',
							backgroundColor: 'var(--primary)',
							color: 'var(--white)',
							marginRight: -7,
							zIndex: 1,
							fontSize: 7,


						}}
					><i className="las la-plus" /></div>

					{renderedTeam.map((member, i) => {
						let acronym = `${member.firstName.charAt(0)}${member.lastName.charAt(0)}`;
						let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
						return (
							emptyProfilePicture ? (
								<div
									className="add acronymCircle"
									style={{
										marginRight: -15,
										border: '1px solid #D5D5D5'
									}}
								><h4>{i == 3 ? `+${renderedTeam.length}` : acronym}</h4></div>
							)
								:
								(<img src={member.profilePicture}
									className="add acronymCircle profilePicture"
									style={{
										marginRight: -15,
										border: '1px solid #D5D5D5'
									}} />)
						)

					}
					)}
				</div >
				<p className='grey'

				>|</p>
			</div >

		);

	}

	updateSessionGroups = (groups) => {
		const { session, editedSession } = this.state;
		editedSession.groups = groups;
		session.groups = groups;
		session.eventGroups = session.groups
			.map((eg) => eg.name)
			.join(', ');
		this.setState({
			session: { ...session, groups: groups },
			editedSession: { ...editedSession, groups: groups }
		}, () => {
			this.props.updateSession(session._id, session);
		});
	}

	render() {
		const {
			session,
			DeleteSessionModalOpen,
			loading,
			editing,
			selectedSection,
			toggleDuplicate,
			toggleAddTeamMember
		} = this.state;
		const { eventId, event, orgId } = this.props;

		let mainAction = [];

		switch (selectedSection) {
			case 1:
				mainAction = [{ onClick: this.toggleAddAttendees, label: 'Add Attendee' }];

				break;
			case 2:
				mainAction = [];
				break;

			default:
				mainAction = [];
		}

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		switch (selectedSection) {
			case 1:
				batchActions = [
					{
						type: 'button',
						label: 'Include Attendees',
						onClick: this.toggleDeleteAttendees,
						iconClass: 'las la-trash-alt',
						class: 'actionGo'
					},
					{
						type: 'button',
						label: 'Exclude Attendees',
						onClick: this.toggleDeleteAttendees,
						iconClass: 'las la-trash-alt',
						class: 'actionOutlineBlack'
					},
					{
						type: 'button',
						label: 'Delete Attendees',
						onClick: this.toggleDeleteAttendees,
						iconClass: 'las la-trash-alt',
						class: 'danger'
					}
				];
				checkedEntryCount = Object.keys(this.state.checkedAttendees).length;
				entriesLength = this.state.editedSession.isGeneral
					? this.props.event.attendees.length
					: this.state.editedSession.attendees.length;
				uncheckEntries = () => {
					this.setState({ checkedAttendees: [] });
				};
				searchEnabled = true;
				break;

			default:
				batchActions = [];
		}

		return (
			<div className="sectionContainer">
				<SectionHeader
					editing={editing}
					title={session.name}
					description={"Insightful information about the session**"}
					enableLastLink={true}
					closeButton={true}
					subActions={[
						{
							onClick: () =>
								window.open(
									this.props.organization.hostname ? `https://${this.props.organization.hostname}/${this.props
										.event.nameSlug}/session/${session.slug}` : `https://${this.props.organization.identifier}.simple.events/${this.props
											.event.nameSlug}/session/${session.slug}`,
									'_blank'
								),
							label: 'View Session',
							iconClass: 'las la-external-link-alt'
						},
						{ onClick: this.toggleDuplicate, label: 'Duplicate Session', iconClass: 'las la-copy' },

						{ onClick: this.toggleDelete, label: 'Delete Session', iconClass: 'las la-trash' }
					]}
					additionalElements={this.teamMemberElement}
				>
					<TabsContainer
						tabs={[
							{ title: <span>Overview</span> },
							{ title: <span>Comments</span> },
							{ title: <span>Attendees & Groups</span> },
							{ title: <span>Speakers</span> },
							{ title: <span>Polls</span> },
							{ title: <span>Survey</span> },
							{ title: <span>Moderators</span> },
						]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/></SectionHeader>
				<div className="sectionBody">{!loading && this.renderSection()}</div>
				<LoadingWizard text="Loading Session" loading={loading} />
				<SectionFooter cancelEdit={this.cancelEdit} saveEdit={this.saveInfo} editing={editing} updatedAt={session.updatedAt} />
				{DeleteSessionModalOpen && <DeleteSessionModal
					isOpen={DeleteSessionModalOpen}
					toggle={this.toggleDelete}
					session={session}
					submit={this.props.deleteSession}
				/>}

				{toggleDuplicate &&
					<DuplicateSessionModal
						isOpen={toggleDuplicate}
						toggle={this.toggleDuplicate}
						session={session}
						orgId={orgId}
						eventId={eventId}
						submit={this.props.deleteSession}
						sessionName={session.name}
						addSession={this.props.addSession}
					/>}

				{toggleAddTeamMember &&
					<AddTeamMemberModal
						isOpen={toggleAddTeamMember}
						toggle={this.toggleAddTeamMember}
						users={this.props.organization.members}
						session={session}
						orgId={orgId}
						eventId={eventId}
						updateSessionTeam={this.updateSessionTeam}
						updateSessionsTeam={this.props.updateSessionsTeam}
					/>
				}
			</div>
		);
	}
}

export default withRouter(EventSession);
