import React, { Component } from "react";
import API from "../../utils/API";
import FilesUploadModal from "./modals/filesUploadModal";
import fileIcon from "../../assets/img/icons/file.png";
import RemoveFilesModal from "./modals/removeFilesModal";

import ActionButtonContainer from "../../components/views/ActionButtonContainer";
import TabsContainer from "../../components/views/TabsContainer";
import SectionHeader from "../../components/views/SectionHeader";
import FileGrid from "../../components/tables/FileGrid";
import ViewsTable from "../../components/tables/ViewsTable";
class AccountFiles extends Component {
  constructor(props) {
    super(props);
    const gridEnabled = localStorage.getItem("fileGridEnabled");

    const columns = {
      name: "Name",
      dimensions: "Image Dimensions",
      size: " File Size",
      uploadedByName: "Uploaded By",
      createdAt: "Created",
      url: "URL",
    };
    const columnTypes = {
      name: "text",
      dimensions: "text",
      size: "text",
      uploadedByName: "text",
      createdAt: "timestamp",
      url: "link",
    };
    const toggledColumns = {};
    const normalizedColumns = Object.keys(columns).map((cfc) => {
      toggledColumns[cfc] = true;
      return {
        label: columns[cfc],
        key: cfc,
        value: cfc,
        type: columnTypes[cfc],
        sortAsc: false,
        sortDesc: false,
      };
    });

    this.state = {
      addFilesModalOpen: false,
      checkedFiles: [],
      columns: columns,
      columnTypes: columnTypes,
      files: [],
      folderSelected: "",
      isGridLayout: gridEnabled ? gridEnabled == "true" : false,
      loading: false,
      normalizedColumns: normalizedColumns,
      removeFilesModalOpen: false,
      searchedEntries: [],
      searching: false,
      searchSelected: false,
      searchText: "",
      selectedColumnSort: null,
      selectedSection: 0,
      sortByColumn: { name: true },
      sortDesc: false,
      toggledColumns: toggledColumns,
    };
  }

  componentDidMount() {
    const { orgId } = this.props;
    API()
      .get(`Organizations/${orgId}/assets`)
      .then(async (res) => {
        if (res.data) {
          let tempFiles = res.data;

          for (let i = 0; i < tempFiles.length; i++) {
            let file = tempFiles[i];

            if (file.type.includes("image")) {
              await this.getImageDimensions(file.url).then((result) => {
                file.dimensions = `${result.width}x${result.height}`;
              });
            }
          }

          this.setState({ files: tempFiles });
        }
      });
  }

  getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = url;
      img.onload = function () {
        let width = img.width;
        let height = img.height;

        resolve({ width, height });
      };
    });
  };
  addNewFiles = (files) => {
    const existingFiles = this.state.files;
    files.forEach((f) => existingFiles.push(f));
    this.setState({ files: existingFiles });
  };

  TableFileItem(file, index) {
    const { checkedFiles, columns, toggledColumns } = this.state;
    return (
      <div key={index} className="tableColumnRow">
        <div
          className={`checkBox mr-10 ${checkedFiles[file._id] ? "checked" : ""
            }`}
          onClick={() => {
            const checked = checkedFiles;
            checked[file._id]
              ? delete checked[file._id]
              : (checked[file._id] = true);
            this.setState({
              checkedFiles: checked,
              allChecked:
                Object.keys(checked).length === this.state.files.length,
            });
          }}
        >
          <i className="las la-check" />
        </div>
        <div className="clickableTableColumnRow">
          <div
            className={`tableColumnRowCell main text-truncate calibreRegular fs-16`}
          >
            <img
              alt="file icon"
              className="tableImageThumb mr-10"
              src={file.type.includes("image") ? file.url : fileIcon}
            />
            {file.name}
          </div>
          <div
            className={`tableColumnRowCell text-truncate calibreRegular fs-16`}
          >
            {this.bytesToSize(file.size)}
          </div>
          <div
            className={`tableColumnRowCell text-truncate calibreRegular fs-16`}
          >
            {file.type}
          </div>
          <div className={`tableColumnRowCell`}>
            <div
              className="actionButton actionSave"
              style={{ minWidth: 80 }}
              onClick={async () => {
                navigator.clipboard.writeText(file.url).then(
                  () => {
                    /* clipboard successfully set */
                    const files = this.state.files;
                    const copiedFile = files.map((f) =>
                      f._id == file._id ? { ...file, copied: true } : f
                    );
                    this.setState({ files: copiedFile });
                    setTimeout(() => this.setState({ files: files }), 1000);
                  },
                  () => {
                    /* clipboard write failed */
                    document.execCommand("copy", true, file.url);
                    const files = this.state.files;
                    const copiedFile = files.map((f) =>
                      f._id == file._id ? { ...file, copied: true } : f
                    );
                    this.setState({ files: copiedFile });
                    setTimeout(() => this.setState({ files: files }), 1000);
                  }
                );
              }}
            >
              <div className="calibreBold fs-16">
                <i className="las la-clipboard" />
                {file.copied ? "Copied!" : "Copy"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  GridFileItem(file, index) {
    return (
      <div className="gridFileItem" key={index}>
        <div className="gridImageThumb">
          <div
            className="floatingCopyButton"
            onClick={async () => {
              navigator.clipboard.writeText(file.url).then(
                () => {
                  /* clipboard successfully set */
                  const files = this.state.files;
                  const copiedFile = files.map((f) =>
                    f._id == file._id ? { ...file, copied: true } : f
                  );
                  this.setState({ files: copiedFile });
                  setTimeout(() => this.setState({ files: files }), 1000);
                },
                () => {
                  /* clipboard write failed */
                  document.execCommand("copy", true, file.url);
                  const files = this.state.files;
                  const copiedFile = files.map((f) =>
                    f._id == file._id ? { ...file, copied: true } : f
                  );
                  this.setState({ files: copiedFile });
                  setTimeout(() => this.setState({ files: files }), 1000);
                }
              );
            }}
          >
            {file.copied ? (
              <i className="las la-check" />
            ) : (
              <i className="las la-clipboard" />
            )}
          </div>
          <img
            alt="file icon"
            className="gridItemImage"
            src={file.type.includes("image") ? file.url : fileIcon}
          />
        </div>
        <div className="gridItemDetails">
          {file.name}
          {/* <div>
						{file.type} - {this.bytesToSize(file.size)}
					</div> */}
        </div>
      </div>
    );
  }
  bytesToSize = (bytes) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    //check if bytes contains a value from sizes array
    const match = sizes.find((size) => {
      if (bytes.includes(size)) {
        return true;
      }
    });

    if (match) return bytes;
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  };

  toggleSearch = () => {
    this.setState({ searchSelected: !this.state.searchSelected });
  };

  toggleLayout = (selected) => {
    this.setState({ isGridLayout: selected });
  };

  toggleDeleteFiles = () => {
    this.setState({ removeFilesModalOpen: true });
  };

  searchEntries = (searchText) => {
    this.setState({
      searching: searchText !== "" ? true : false,
      searchText: searchText ? searchText : "",
    });
  };
  toggleAddFiles = () => {
    this.setState({ addFilesModalOpen: !this.state.addFilesModalOpen });
  };
  toggleRemoveFiles = () => {
    this.setState({ removeFilesModalOpen: !this.state.removeFilesModalOpen });
  };

  checkAllFiles = () => {
    if (!this.state.allChecked) {
      const checkedFiles = {};
      this.state.files.forEach((f) => (checkedFiles[f._id] = true));
      this.setState({ checkedFiles: checkedFiles, allChecked: true });
    } else {
      this.setState({ checkedFiles: {}, allChecked: false });
    }
  };

  updatedCheckedFiles = (checked) => {
    this.setState({ checkedFiles: checked });
  };

  updateSelected = (selectedIndex) => {
    this.setState({ selectedSection: selectedIndex });
  };

  removeFiles = () => {
    const filteredFiles = this.state.files.filter(
      (f) => !this.state.checkedFiles[f._id]
    );
    this.setState({
      files: filteredFiles,
      checkedFiles: {},
      allChecked: false,
    });
  };

  sortData = (column, sortAsc, sortDesc) => {
    const { normalizedColumns } = this.state;
    const updatedColumns = normalizedColumns.map((col) => {
      if (col.key == column.key) {
        col.sortAsc = sortAsc;
        col.sortDesc = sortDesc;
      } else {
        col.sortAsc = false;
        col.sortDesc = false;
      }
      return col;
    });

    this.setState({
      normalizedColumns: updatedColumns,
      selectedColumnSort: column,
      sortDesc: sortDesc ? true : false,
    });
  };

  updateFavorite = (fileId, set) => {
    const { files } = this.state;

    const updatedFiles = files.map((file) => {
      if (file._id == fileId) {
        file.isFavorite = set;
      }
      return file;
    });
    this.setState({ files: updatedFiles });
  };

  render() {
    const {
      files,
      folderSelected,
      addFilesModalOpen,
      removeFilesModalOpen,
      isGridLayout,
      columns,
      searching,
      selectedColumnSort,
      searchText,
      selectedSection,
      sortDesc,
      normalizedColumns,
      toggledColumns,
    } = this.state;

    let searchedEntries = files;

    //Searching by text from input
    if (searchText.length > 0) {
      const searchTerms = searchText.toLowerCase().split(" ");
      searchTerms.forEach((term) => {
        if (term !== "") {
          searchedEntries = searchedEntries.filter((entry) => {
            let found = false;
            Object.keys(columns).forEach((key) => {
              if (
                term !== "" &&
                entry[key] &&
                entry[key].toString().toLowerCase().includes(term)
              ) {
                found = true;
              }
            });
            return found;
          });
        }
      });
    }

    if (searchedEntries)
      searchedEntries = searchedEntries.map((file) => {
        file.size = this.bytesToSize(file.size);
        return file;
      });

    if (selectedSection === 1) {
      searchedEntries = searchedEntries.filter((file) => file.uploadedByYou);
    } else if (selectedSection === 2) {
      searchedEntries = searchedEntries.filter((file) => file.isFavorite);
    }

    if (selectedColumnSort) {
      searchedEntries = searchedEntries.sort((a, b) => {
        const columnType =
          selectedColumnSort.type || typeof a[selectedColumnSort.key];
        if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
          switch (columnType) {
            case "string":
              return a[selectedColumnSort.key]
                .toLowerCase()
                .trim()
                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
            case "number":
              return a[selectedColumnSort.key] - b[selectedColumnSort.key];
            case "date":
              return (
                new Date(a[selectedColumnSort.key]).getTime() -
                new Date(b[selectedColumnSort.key]).getTime()
              );
            case "time":
              return (
                new Date(a[selectedColumnSort.key]).getTime() -
                new Date(b[selectedColumnSort.key]).getTime()
              );
            case "boolean":
              const a1 = a[selectedColumnSort.key] ? "yes" : "no";
              const b1 = b[selectedColumnSort.key] ? "yes" : "no";
              return a1.localeCompare(b1);
            default:
              return a[selectedColumnSort.key]
                .toLowerCase()
                .trim()
                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
          }
        } else if (a[selectedColumnSort.key]) {
          return 1;
        }
        return -1;
      });

      searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
    }
    let mainAction = [];

    mainAction = [{ onClick: this.toggleAddFiles, label: "Add Files" }];

    let batchActions = [];
    let checkedEntryCount = 0;
    let entriesLength = 0;
    let uncheckEntries = null;
    let searchEnabled = false;

    batchActions = [
      {
        type: "button",
        label: "Delete Files",
        onClick: this.toggleRemoveFiles,
        iconClass: "las la-trash-alt",
        class: "danger",
      },
    ];
    checkedEntryCount = Object.keys(this.state.checkedFiles).length;
    entriesLength =
      searching || selectedSection === 1
        ? searchedEntries.length
        : files.length;
    uncheckEntries = () => {
      this.setState({ checkedFiles: [] });
    };
    searchEnabled = true;
    return (
      <div className="pageContainer">
        <div className="sectionContainer ">
          <SectionHeader
            title={"Account Files"}
            breadcrumbs={[]}
            enableLastLink={true}
            description="Upload and manage files for your account."
          ></SectionHeader>

          <TabsContainer
            tabs={[
              { title: "All Files" },
              { title: "Uploaded by Me" },
              { title: "Favorites" },
            ]}
            updateSelected={this.updateSelected}
            selectedIndex={selectedSection}
          />

          {
            <ActionButtonContainer
              mainActions={mainAction}
              searchEnabled={searchEnabled}
              searchEntries={this.searchEntries}
              enabledViewSelection={true}
              isGridLayout={isGridLayout}
              toggleLayout={this.toggleLayout}
            />
          }

          {!isGridLayout ? (
            <ViewsTable
              title={"Files"}
              columns={normalizedColumns}
              toggledColumns={toggledColumns}
              isGridLayout={isGridLayout}
              data={searchedEntries}
              updateFavorite={this.updateFavorite}
              orgId={this.props.orgId}
              mainColumnActive={false}
              checked={this.state.checkedFiles}
              checkRow={(entryId) => {
                const checked = this.state.checkedFiles;
                checked[entryId]
                  ? delete checked[entryId]
                  : (checked[entryId] = true);
                this.updatedCheckedFiles(checked);
                this.setState({
                  checkedFiles: checked,
                });
              }}
              mainActions={mainAction}
              checkAllRows={(entryIds) => {
                let checked = this.state.checkedFiles;
                entryIds.length > 0
                  ? entryIds.forEach((entryId) => {
                    checked[entryId] = true;
                  })
                  : (checked = {});
                this.updatedCheckedFiles(checked);

                this.setState({
                  checkedFiles: checked,
                });
              }}
              sortData={this.sortData}
              defaultSort={"name"}
              sortDirection={"desc"}
            />
          ) : (
            <FileGrid
              title={"Files"}
              orgId={this.props.orgId}
              updateFavorite={this.updateFavorite}
              data={searchedEntries}
              checked={this.state.checkedFiles}
              checkRow={(entryId) => {
                const checked = this.state.checkedFiles;
                checked[entryId]
                  ? delete checked[entryId]
                  : (checked[entryId] = true);
                this.updatedCheckedFiles(checked);
                this.setState({
                  checkedFiles: checked,
                });
              }}
            />
          )}
          <ActionButtonContainer
            mainActions={[]}
            batchActions={batchActions}
            checkedEntryCount={checkedEntryCount}
            entriesLength={entriesLength}
            uncheckEntries={uncheckEntries}
          />

          {addFilesModalOpen && (
            <FilesUploadModal
              isOpen={addFilesModalOpen}
              toggle={this.toggleAddFiles}
              folder={folderSelected}
              orgId={this.props.orgId}
              addFiles={this.addNewFiles}
            />
          )}
          {removeFilesModalOpen && (
            <RemoveFilesModal
              isOpen={removeFilesModalOpen}
              toggle={this.toggleRemoveFiles}
              orgId={this.props.orgId}
              removeFiles={this.removeFiles}
              files={Object.keys(this.state.checkedFiles)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AccountFiles;
