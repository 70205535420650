import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import API from '../../../utils/API';

import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import DeleteInventoryItemModal from './modals/deleteInventoryItemModal'
class InventoryItem extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const item = props.inventory.find((i) => i._id == params.inventoryId);
		this.props.setActiveRow(params.inventoryId)
		this.state = {
			item: item,
			editedItem: { ...item },
			DeleteItemModalOpen: false,
			loading: true,
			editing: false,
			requiredFields: {
				name: false
			}
		};
	}

	saveInfo = () => {
		const { eventId, orgId } = this.props;
		const { editedItem, item } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedItem[key] || editedItem[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			API()
				.patch(`Organizations/${orgId}/events/${eventId}/inventory/${item._id}`, editedItem)
				.then((res) => {
					const updatedItem = res.data;
					updatedItem.link = `/${orgId}/events/${eventId}/inventory/${item._id}`;
					updatedItem.total = updatedItem.quantity * updatedItem.price;
					this.props.updateInventory(updatedItem);
					this.setState({
						item: { ...updatedItem },
						editing: false,
						errorMessage: false,
						requiredFields: requiredFields
					});
				})
				.catch((e) => {
					console.log('Error', e);
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	componentWillUnmount() {
		this.props.setActiveRow(null);
	}
	toggleDelete = () => {
		this.setState({ DeleteItemModalOpen: !this.state.DeleteItemModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	editItemField = (property, value) => {
		const editedItem = this.state.editedItem;
		editedItem[property] = value;
		this.setState({ editedItem: editedItem, editing: true });
	};

	cancelEdit = () => {
		const item = this.state.item;
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({ editedItem: { ...item }, editing: false, requiredFields: requiredFields });
	};

	render() {
		const { DeleteItemModalOpen, loading, editing, item, editedItem, requiredFields } = this.state;
		const { eventId, orgId } = this.props;
		const arrivalDate = editedItem.arrivalDate ? moment(editedItem.arrivalDate).format('YYYY-MM-DD') : '';
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={`${item.name}`}
					enableLastLink={true}
					/* breadcrumbs={[
						{
							name: event.name,
						},
						{
							name: 'Inventory'
						}
					]} */
					subActions={[{ onClick: this.toggleDelete, label: <span><i className='las la-trash mr-10'></i>Delete Item</span> }]}
					closeButton={true}
				/>
				<div className="sectionBody">
					<div className="sectionBlock">
						<h2 className='blockHeader'>Basic Details</h2>
						<InputField
							label="Name"
							value={editedItem.name}
							required={true}
							errorTrigger={requiredFields.name}
							type="text"
							onChange={(e) => this.editItemField('name', e.target.value)}
						/>

						<InputField
							label="Category"
							value={editedItem.category}
							type="text"
							onChange={(e) => this.editItemField('category', e.target.value)}
						/>

						<InputField
							label="Reference Number"
							value={editedItem.referenceNumber}
							type="text"
							onChange={(e) => this.editItemField('referenceNumber', e.target.value)}
						/>

						<InputField
							label="Vendor"
							value={editedItem.vendor}
							type="text"
							onChange={(e) => this.editItemField('vendor', e.target.value)}
						/>
						<SelectField
							label="Status"
							value={this.props.statusOptions.find((s) => s.value == editedItem.status)}
							options={this.props.statusOptions}
							type="number"
							onChange={(e) => this.editItemField('status', e.value)}
						/>
						<InputField
							label="Tracking Number"
							value={editedItem.trackingNumber}
							type="text"
							onChange={(e) => this.editItemField('trackingNumber', e.target.value)}
						/>
						<InputField
							label="Arrival Date"
							value={editedItem.arrivalDate}
							type="date"
							onChange={(e) => this.editItemField('arrivalDate', e.target.value)}
							inputIcon={<i className="las la-calendar" />}
						/>
						<InputField
							label="Post Event Plan"
							value={editedItem.postEventPlan}
							type="text"
							onChange={(e) => this.editItemField('postEventPlan', e.target.value)}
						/>
						<InputField
							label="Quantity"
							value={editedItem.quantity}
							type="number"
							onChange={(e) => this.editItemField('quantity', e.target.value)}
						/>
						<InputField
							label="Price"
							value={editedItem.price}
							type="currency"
							onChange={(e) => this.editItemField('price', e.target.value)}
						/>

						<InputField
							label="Total"
							value={editedItem.quantity * editedItem.price}
							type="currency"
							disabled={true}
							inputIcon={<i className="las la-lock lightGray" />}
						/>
					</div>
				</div>
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveInfo}
					updatedAt={item.updatedAt} />
				{DeleteItemModalOpen &&
					<DeleteInventoryItemModal
						isOpen={DeleteItemModalOpen}
						toggle={this.toggleDelete}
						item={item}
						event={eventId}
						orgId={orgId}
						submit={this.props.deleteInventory}
						deleteSingleItem={this.props.deleteSingleItem}
					/>}
				{/* DeleteItemModalOpen && (
					<DeleteAttendeeModal
						isOpen={DeleteItemModalOpen}
						toggle={this.toggleDelete}
						item={item}
						submit={this.props.deleteAttendee}
					/>
				) */}
			</div>
		);
	}
}

export default withRouter(InventoryItem);
