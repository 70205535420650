import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import API from '../../../utils/API';
import { Edit, X, Save } from 'react-feather';
import PageEditor from './pageEditor';
import CopyPageModal from './modals/copyPageModal';
import SectionHeader from '../../../components/views/SectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import LoadingWizard from '../../../components/spinner/wizard';
import InputField from '../../../components/inputs/inputField';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import SelectField from '../../../components/inputs/selectField';
import PageBuilderModal from './pageEditor'
import SectionFooter from '../../../components/views/SectionFooter';
import FileChooserPreview from '../../../components/inputs/fileChooserPreview';
import FilesUploaderModal from '../../../components/files/filesUploaderModal';
import ColorField from '../../../components/inputs/colorField';
import CodeEditor from '../../../components/codeEditor/codeEditor';
import DeleteSinglePageModal from './modals/deleteSinglePageModal';
import RenamePageModal from './modals/renamePageModal';
import UnpublishPageModal from './modals/unpublishPageModal';
import PublishPageModal from './modals/publishPageModal';


const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

const pageTypeOptions = [
	{ value: false, label: 'General Page' },
	{ value: true, label: 'Session Template' },
]

const pageAvailabilityOptions = [
	{ value: true, label: 'Public (Available to anyone)' },
	{ value: false, label: 'Private (Only for logged in users)' }
]
class EventPage extends React.Component {
	constructor(props) {
		super(props);
		const page = props.pages.find((p) => p._id === props.match.params.pageId);
		this.state = {
			editorOpen: false,
			selectedPage: null,
			loading: false,
			addPageModalOpen: false,
			previewOpen: true,
			detailsOpen: true,
			page: { ...page },
			editedPage: { ...page },
			copyPagesModalOpen: false,
			selectedSection: 0,
			loading: true,
			editing: false,
			editCount: 0,
			toggleUnpublishPage: false,
			togglePublishPage: false,
			toggleRenamePage: false,
			deletePageModalTrigger: false
		};

	}

	componentWillMount() {
		const { orgId, eventId } = this.props;
		const { match: { params } } = this.props;
		this.setState({ loading: true });
		API().get(`Organizations/${orgId}/events/${eventId}/pages/${params.page}`).then((res) => {
			if (res.data) {
				this.setState({ page: { ...res.data }, editedPage: { ...res.data }, loading: false });
			}
		});
	}

	refreshIframe = () => {
		this.setState({ previewUrl: '' }, () => this.setState({ previewUrl: this.props.page.url }));
	};

	savePage = async () => {
		const { orgId, eventId, updatePage, updatePathOp } = this.props;
		const { page, editedPage } = this.state;

		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/pages/${page._id}`, this.state.editedPage)
			.then((res) => {
				if (res.data) {
					/* this.props.updatePage(res.data);   */
					updatePathOp(editedPage)
					updatePage(editedPage)
					this.setState({ editedPage: res.data, page: { ...res.data }, loading: false, editing: false });
				}
			})
			.catch((e) => {
				this.setState({ loading: false, editing: false });
			});
	};



	cancelEdit = () => {
		this.setState({ editing: false, editedPage: { ...this.state.page } });
	};

	updatePage = async (updatedPage, callback) => {
		this.setState({ editedPage: { ...this.state.page, ...updatedPage }, page: { ...this.state.page, ...updatedPage } }, () => {
			this.props.updatePage(this.state.page);
			this.props.updatePathOp(this.state.editedPage)
			callback && callback();

		});
	};



	publishPage = (published, callback) => {
		const { orgId, eventId } = this.props;
		const { page, editedPage, editing } = this.state;


		if (editedPage.pathSlug !== '' && page.status == 'draft') {

			API().post(`Organizations/${orgId}/pages/${page._id}/publish`, { published: published }).then((res) => {
				if (res.data) {
					this.setState({ editedPage: res.data, page: { ...res.data }, loading: false, editing: false });
					callback && callback();
				}
			});
		}
		else if (editedPage.pathSlug == '' && page.status == 'draft') {
			this.togglePublishPageModal();
		}

		else if (page.status == 'published') {
			API().post(`Organizations/${orgId}/pages/${page._id}/publish`, { published: published }).then((res) => {
				if (res.data) {
					this.setState({ editedPage: res.data, page: { ...res.data }, loading: false, editing: false });
					callback && callback();
				}
			});
		}




		/* API().post(`Organizations/${orgId}/pages/${page._id}/publish`, { published: published }).then((res) => {
			if (res.data) {
				this.setState({ editedPage: res.data, page: { ...res.data }, loading: false, editing: false });
				callback && callback();
			}
		}); */

	};

	togglePageBuilder = () => {
		this.setState({ editorOpen: !this.state.editorOpen });
	};

	toggleRenamePageModal = () => {
		const { toggleRenamePage } = this.state;
		this.setState({ toggleRenamePage: !toggleRenamePage });
	};

	toggleCopyPages = () => {
		const { copyPagesModalOpen } = this.state;
		this.setState({ copyPagesModalOpen: !copyPagesModalOpen });
	};

	toggleUnpublishPageModal = () => {
		const { toggleUnpublishPage } = this.state;
		this.setState({ toggleUnpublishPage: !toggleUnpublishPage });
	}

	togglePublishPageModal = () => {
		const { togglePublishPage } = this.state;
		this.setState({ togglePublishPage: !togglePublishPage });
	}

	updateSelected = (selected) => {
		this.setState({ selectedSection: selected });
	};

	renderSection() {
		const {
			selectedSection,
			editedPage,
			editorOpen,
		} = this.state;
		const { readOnly, organization, event, eventId, orgId } = this.props;
		switch (selectedSection) {
			case 0:
				return (
					<div className='sectionBody'>
						<div className='blocksContainer'>
							<div className='sectionBlock mr-25 contained-h'>
								<h2 className='blockHeader'>Basic Information</h2>
								<InputField
									label="Description"
									placeholder="Displays in page previews when link is shared"
									value={editedPage.description}
									onChange={(e) => {
										this.setState({
											editedPage: { ...editedPage, description: e.target.value },
											editing: true
										});
									}}
								/>

								<SelectField
									label="Page Type"
									required={true}
									classes="w-100"
									placeholder="Page type"
									value={editedPage.isSessionPage ? pageTypeOptions[1] : pageTypeOptions[0]}
									onChange={(e) => {
										this.setState({
											editedPage: { ...editedPage, isSessionPage: e.value },
											editing: true
										});
									}}
									options={pageTypeOptions}
								/>
								<InputField
									subText={`https://${organization.hostname ? organization.hostname : (organization.identifier + '.simple.events')}/${event.nameSlug}/${editedPage.pathSlug}`}
									label="Address"
									required={true}
									placeholder="Unique slug for this page"
									value={editedPage.pathSlug}
									onChange={(e) => {
										this.setState({
											editedPage: { ...editedPage, pathSlug: e.target.value },
											editing: true
										});
									}}
								/>
								<SelectField
									label="Availability"
									required={true}
									classes="w-100"
									placeholder="Page availability"
									value={editedPage.openToPublic ? pageAvailabilityOptions[0] : pageAvailabilityOptions[1]}
									onChange={(e) => {
										this.setState({
											editedPage: { ...editedPage, openToPublic: e.value },
											editing: true
										});
									}}
									options={pageAvailabilityOptions}
								/>


							</div>
							<div className='sectionBlock emailDesignPreview mt-20'>

								<div
									className="cardDetailsContainerPages containerTitle"
								>

									<div className='w-100'>
										<h2 className='blockHeader mt-0'>Design</h2>
										<h5 className='formFieldLabel mt-10'> Web Page Design </h5>
									</div>

									{editedPage.partials.length > 0 ? <button
										disabled={!editedPage.pathSlug}
										className={`neu min ml-a`}
										onClick={this.togglePageBuilder}
									>
										Edit Design
									</button> :

										<button
											disabled={!editedPage.pathSlug}
											className={`neu min ml-a prim`}
											onClick={this.togglePageBuilder}
										>
											Create Design
										</button>}

									{/* {editedPage.status === "published" && <button className='min neu ml-10' onClick={() => {
										window.open(`https://${organization.identifier}.simple.events/${event.nameSlug}/${editedPage.slug}`, '_blank');
									}}>
										Visit Page
									</button>} */}
								</div>

								<div
									className="emailPreview pagePreview eFrame pages flex-ac preview"
								>
									{!editorOpen && <PagePreviewFrame
										ENV={ENV}
										renderURL={`Organizations/${orgId}/events/${eventId}/pages/render/${editedPage._id}/`}
										preview={true}
										pageProperties={this.state.editedPage}
										focusedComponent={""}
										simulatedUser={{ value: this.props.account._id }}
										simulatedSession={{ value: false }}
										renderOnly={true}
										editing={this.state.editCount}
									/>}
								</div>
							</div>
						</div>
					</div>
				);
			case 1:
				return (
					<div className='sectionBody'>

					</div>
				);
		}
	}

	deletePageModalTrigger = () => {
		const { deletePageModalTrigger } = this.state;
		this.setState({ deletePageModalTrigger: !deletePageModalTrigger });
	}

	render() {
		const {
			loading,
			page,
			editedPage,
			editorOpen,
			editing,
			selectedSection,
			toggleRenamePage,
			copyPagesModalOpen,
			toggleUnpublishPage,
			togglePublishPage,
			deletePageModalTrigger
		} = this.state;
		const { event, eventId, orgId } = this.props;

		let currentPage = {};
		currentPage[editedPage._id] = true;
		//const publishActions = page.status == 'draft' ? [{label: 'Publish Page',onClick: () => this.publishPage(true),disabled: editing}] : [{label: 'Unpublish',onClick: () => this.publishPage(false),disabled: editing}]
		const isDraft = page.status == 'draft' ? true : false;
		return (
			<div className="sectionContainer">
				{/* 	{page.status != "published" && <div className="alertBar "> 
					
					This page is a draft. To enable page please click publish below.
				
				</div> } */}

				<SectionHeader
					style={{ marginTop: page.status != "published" ? 0 : 0 }}
					title={editedPage.title}
					status={page.status}
					breadcrumbs={[
						{
							name: event.name
						},
						{
							name: 'Pages',
						}
					]}

					/* mainActions={publishActions} */
					subActions={
						page.status != "published" ?
							(
								[
									{
										onClick: this.toggleRenamePageModal,
										label: <span><i className='las la-pen mr-10'></i>Rename Page</span>
									},
									{
										onClick: this.toggleCopyPages,
										label: <span><i className='las la-copy mr-10'></i>Duplicate Page</span>,
									},
									{
										onClick: this.deletePageModalTrigger,
										label: <span><i className='las la-trash mr-10'></i>Delete Page</span>,
									},
								]
							) :

							(
								[
									{
										onClick: this.toggleRenamePageModal,
										label: <span><i className='las la-pen mr-10'></i>Rename Page</span>,
									}, {
										onClick: this.toggleCopyPages,
										label: <span><i className='las la-copy mr-10'></i>Duplicate Page</span>,
									},
									{
										onClick: this.deletePageModalTrigger,
										label: <span><i className='las la-trash mr-10'></i>Delete Page</span>,
									},
									{
										onClick: this.toggleUnpublishPageModal,
										label: <span><i className='las la-unlink mr-10'></i>Unpublish Page</span>,
									},

								]
							)
					}
				>
					<div className='tabsContainer unsetWidth'></div>
				</SectionHeader>


				{!loading && <div className="actionButtonContainer mt-20" style={{ marginLeft: 'unset' }}>
					<div>
						<button className={`mainAction ${editing || !(editedPage.partials.length > 0) ? 'disabled pageDisabled  ' : 'prim'}`}
							disabled={editing}
							onClick={() => {
								const { organization, } = this.props;
								if (isDraft) {
									this.publishPage(isDraft);
								}
								else {
									if (organization.hostname) {
										window.open(`https://${organization.hostname}/${event.nameSlug}/${editedPage.pathSlug}`, '_blank');
									} else {
										window.open(`https://${organization.identifier}.simple.events/${event.nameSlug}/${editedPage.pathSlug}`, '_blank');
									}
								}
							}}>
							<div>{isDraft ? "Publish Page" : "View Page"}</div>
						</button>
					</div>
				</div>}


				{!loading && this.renderSection()}
				<SectionFooter updatedAt={page.updatedAt} saveEdit={this.savePage} cancelEdit={this.cancelEdit} editing={editing} />
				<LoadingWizard text="Loading Page" loading={loading} />
				{editorOpen && <PageBuilderModal scope="event" isOpen={editorOpen} toggle={() => this.setState({ editorOpen: !editorOpen })} page={this.state.page} editedPage={this.state.editedPage} updatePage={this.updatePage} cancelEdit={this.cancelEdit} event={this.props.event}
					orgId={orgId}
					eventId={eventId} />}

				{toggleRenamePage && (
					<RenamePageModal
						isOpen={toggleRenamePage}
						page={editedPage}
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.toggleRenamePageModal}
						update={this.updatePage}
						updatePathOp={this.props.updatePathOp}
					/>
				)}
				{toggleUnpublishPage && (
					<UnpublishPageModal
						isOpen={toggleUnpublishPage}
						page={editedPage}
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.toggleUnpublishPageModal}
						update={this.updatePage}
						publishPage={this.publishPage}
					/>
				)}
				{togglePublishPage && (
					<PublishPageModal
						isOpen={togglePublishPage}
						page={editedPage}
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.togglePublishPageModal}
						update={this.updatePage}
						publishPage={this.publishPage}
					/>
				)}
				{copyPagesModalOpen && (
					<CopyPageModal
						isOpen={copyPagesModalOpen}
						page={page} //editedPage
						orgId={orgId}
						eventId={eventId}
						event={event}
						toggle={this.toggleCopyPages}
						pages={[editedPage]}
						addPage={this.props.updateAddCopy}
						checkedPages={currentPage}
					/>
				)}

				{deletePageModalTrigger && (
					<DeleteSinglePageModal
						delete={this.deletePages}
						event={event}
						page={editedPage}
						eventId={eventId}
						isOpen={deletePageModalTrigger}
						orgId={orgId}
						toggle={this.deletePageModalTrigger}
						deleteSinglePage={this.props.deleteSinglePage}
					/>
				)}
			</div>
		);
	}
}

export default withRouter(EventPage);
