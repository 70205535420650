import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import API from '../../../utils/API';
import DeleteAttendeeModal from './modals/deleteDefaultModal';
import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import InputField from '../../../components/inputs/inputField';
import SectionFooter from '../../../components/views/SectionFooter';
import SectionHeader from '../../../components/views/SectionHeader';
import ToggleField from '../../../components/inputs/toggleField';

class EventAttendee extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const attendee = props.attendees.find((a) => a._id == params.attendeeId);
		this.props.setActiveRow(params.attendeeId)
		this.state = {
			attendee: attendee,
			editedAttendee: { ...attendee },
			customFields: {},

			selectedSection: 0,
			DeleteAttendeeModalOpen: false,
			loading: true,
			editing: false,
			agendaDates: {},
			requiredFields: {
				firstName: false,
				email: false
			}
		};
	}
	componentWillUnmount() {
		this.props.setActiveRow(null);
	}

	expandCustomField = (categoryId, categoryExpanded) => {
		const customFields = this.state.customFields;
		customFields[categoryId].expanded = !categoryExpanded;
		this.setState({ customFields: customFields });
	};

	saveInfo = () => {
		const { editedAttendee, requiredFields } = this.state;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedAttendee[key] || editedAttendee[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else requiredFields[key] = false;
		});
		if (valid) {
			this.props.saveAttendee(editedAttendee, () => {
				this.setState({ attendee: { ...editedAttendee }, editing: false, requiredFields: requiredFields });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	toggleDelete = () => {
		this.setState({ DeleteAttendeeModalOpen: !this.state.DeleteAttendeeModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	editAttendeeField = (property, value) => {
		const editedAttendee = this.state.editedAttendee;
		editedAttendee[property] = value;
		this.setState({ editedAttendee: editedAttendee, editing: true });
	};

	editTravelFields = (data) => {
		this.setState({ editedAttendee: data });
	};

	editRoomingFields = (data) => {
		this.setState({ editedAttendee: data });
	};

	updateCustomField = (e, meta, categoryId) => {
		const customFields = this.state.customFields;
		meta.fieldType === 'checkbox'
			? (customFields[categoryId][meta._id].value = e.target.checked)
			: (customFields[categoryId][meta._id].value = e.target.value);

		this.setState({ customFields: customFields, editing: true });
	};

	cancelEdit = () => {
		const attendee = this.state.attendee;
		const fields = {};
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editedAttendee: { ...attendee },
			requiredFields: requiredFields,
			editing: false,
			customFields: fields
		});
	};

	addSession = (session) => {
		const { editedAttendee, attendee } = this.state;

		if (!editedAttendee.sessions.find((s) => session._id == s._id)) {
			editedAttendee.sessions.push(session);
			this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
				this.fix();
			});
		}

		if (!attendee.sessions.find((s) => s._id == session._id)) {
			attendee.sessions.push(session);
			this.setState({ editedAttendee: editedAttendee, attendee: attendee }, () => {
				this.fix();
			});
		}
	};

	render() {
		const { DeleteAttendeeModalOpen, loading, editing, attendee, editedAttendee, requiredFields } = this.state;
		const { match: { params }, program } = this.props;
		//const attendee = this.props.attendees.find((a) => a._id == params.attendeeId);
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={`${attendee.firstName} ${attendee.lastName}`}
					titleIcon={
						attendee.profilePicture ? (
							<img src={attendee.profilePicture} style={{ maxWidth: '100%', overflow: 'clip' }} />
						) : (
							<div className="calibreBold fs-30">
								{attendee.firstName.toUpperCase().charAt(0)}
								{attendee.lastName.toUpperCase().charAt(0)}
							</div>
						)
					}
					enableLastLink={true}
					closeButton={true}
					subActions={[{ onClick: this.toggleDelete, label: <span><i className='las la-trash mr-10'></i>Delete Attendee</span> }]}
				/* 	breadcrumbs={[
						{
							name: program.name
						},
						{
							name: 'Event Attendees'
						}
					]} */
				/>
				<div className="sectionBody">
					<h2 className='blockHeader'>Information</h2>
					<div className="sectionBlock">
						<InputField
							label="First Name"
							required={true}
							errorTrigger={requiredFields.firstName}
							value={editedAttendee.firstName}
							type="text"
							onChange={(e) => this.editAttendeeField('firstName', e.target.value)}
						/>
						<InputField
							label="Last Name"
							value={editedAttendee.lastName}
							type="text"
							onChange={(e) => this.editAttendeeField('lastName', e.target.value)}
						/>
						<InputField
							label="Email"
							required={true}
							errorTrigger={requiredFields.email}
							value={editedAttendee.email}
							type="text"
							onChange={(e) => this.editAttendeeField('email', e.target.value)}
						/>
						<InputField
							label="Phone Number"
							value={editedAttendee.phoneNumber}
							type="text"
							onChange={(e) => this.editAttendeeField('phoneNumber', e.target.value)}
						/>
						<InputField
							label="Title"
							value={editedAttendee.title}
							type="text"
							onChange={(e) => this.editAttendeeField('title', e.target.value)}
						/>
						<InputField

							label="Company"
							value={editedAttendee.company}
							type="text"
							onChange={(e) => this.editAttendeeField('company', e.target.value)}
						/>
						<ToggleField
							label="Text Message Opt In"
							checked={editedAttendee.textMessageOptIn}
							icons={false}
							onChange={(e) => this.editAttendeeField('textMessageOptIn', e.target.checked)}
						/>
						<InputField
							label="Dietary Preference"
							value={editedAttendee.dietaryPreference}
							type="text"
							onChange={(e) => this.editAttendeeField('dietaryPreference', e.target.value)}
						/>
						<InputField
							label="Food Allergies"
							value={editedAttendee.foodAllergies}
							type="text"
							onChange={(e) => this.editAttendeeField('foodAllergies', e.target.value)}
						/>
						<InputField
							label="Emergency Contact Name"
							value={editedAttendee.emergencyContactName}
							type="text"
							onChange={(e) => this.editAttendeeField('emergencyContactName', e.target.value)}
						/>
						<InputField
							label="Emergency Contact Phone Number"
							value={editedAttendee.emergencyContactPhoneNumber}
							type="text"
							onChange={(e) => this.editAttendeeField('emergencyContactPhoneNumber', e.target.value)}
						/>
						<InputField
							label="Address"
							value={editedAttendee.address}
							type="text"
							onChange={(e) => this.editAttendeeField('address', e.target.value)}
						/>
						<InputField
							label="City"
							value={editedAttendee.city}
							type="text"
							onChange={(e) => this.editAttendeeField('city', e.target.value)}
						/>
						<InputField
							label="State"
							value={editedAttendee.state}
							type="text"
							onChange={(e) => this.editAttendeeField('state', e.target.value)}
						/>
						<InputField
							label="Zip"
							value={editedAttendee.zip}
							type="text"
							onChange={(e) => this.editAttendeeField('zip', e.target.value)}
						/>
						<InputField
							label="Country"
							value={editedAttendee.country}
							type="text"
							onChange={(e) => this.editAttendeeField('country', e.target.value)}
						/>
					</div>
				</div>
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveInfo}
					updatedAt={attendee.updatedAt} />
				{DeleteAttendeeModalOpen && (
					<DeleteAttendeeModal
						isOpen={DeleteAttendeeModalOpen}
						toggle={this.toggleDelete}
						attendee={attendee}
						title={`Delete Attendee`}
						message={`You are about to delete, this is irreversible,
					would you like to continue?`}
						submit={() => {
							const attendees = {};
							attendees[attendee._id] = true;
							this.props.deleteAttendee(
								attendees,
								this.props.history.push('./'),
							);
						}}
					/>
				)}
			</div>
		);
	}
}

export default withRouter(EventAttendee);
