import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-toggle/style.css';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import TabsContainer from '../../../components/views/TabsContainer';
import SectionHeader from '../../../components/views/SectionHeader';
import FormBuilderModal from '../../event/forms/modals/formBuilder';
import SectionFooter from '../../../components/views/SectionFooter';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import ToggleField from '../../../components/inputs/toggleField';
import DuplicateFormModal from './modals/duplicateFormModal';
import RichTextEditor from '../../../views/pages/components/richTextEditor';

import AddToPage from './modals/addToPage';
import RenameFormModal from './modals/renameFormModal';
const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
const baseFieldList = [
	/* {
		_id: 'event',
		title: 'Event',
		fields: [
			{
				_id: '_startDate',
				fieldName: 'Start Date',
				fieldType: 'date',
				propertyType: 'event',
				required: false
			},
			{
				_id: '_endDate',
				fieldName: 'End Date',
				fieldType: 'date',
				propertyType: 'event',
				required: false
			},
			{
				_id: '_name',
				fieldName: 'Name',
				fieldType: 'text',
				propertyType: 'event',
				required: true
			}
		]
	}, */
	{
		_id: 'attendee',
		title: 'Attendee',
		fields: [
			{
				_id: '_firstName',
				fieldName: 'First Name',
				fieldType: 'text',
				required: true,
				propertyType: 'attendee'
			},
			{
				_id: '_lastName',
				fieldName: 'Last Name',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_email',
				fieldName: 'Email',
				fieldType: 'email',
				required: true,
				propertyType: 'attendee'
			},
			{
				_id: '_phoneNumber',
				fieldName: 'Phone Number',
				fieldType: 'tel',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_title',
				fieldName: 'Title',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_company',
				fieldName: 'Company',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_textMessageOptIn',
				fieldName: 'Text Message Opt In',
				fieldType: 'toggle',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_dietaryPreference',
				fieldName: 'Dietary Preference',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_foodAllergies',
				fieldName: 'Food Allergies',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_emergencyContactName',
				fieldName: 'Emergency Contact Name',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_emergencyContactPhoneNumber',
				fieldName: 'Emergency Contact Phone Number',
				fieldType: 'tel',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_address',
				fieldName: 'Address',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_city',
				fieldName: 'City',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_state',
				fieldName: 'State',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_zip',
				fieldName: 'Zip',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_country',
				fieldName: 'Country',
				fieldType: 'text',
				required: false,
				propertyType: 'attendee'
			},
			{
				_id: '_profilePicture',
				fieldName: 'Profile Picture',
				fieldType: 'image',
				required: true,
				propertyType: 'attendee'
			},

		]
	},
	{
		_id: 'rooming',
		title: 'Attendee Rooming',
		fields: [
			{
				_id: '_roomingInformation-arrivalDate',
				fieldName: 'Arrival Date',
				fieldType: 'date',
				propertyType: 'attendee',
			}, {
				_id: '_roomingInformation-arrivalTime',
				fieldName: 'Arrival Time',
				fieldType: 'time',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-checkInDate',
				fieldName: 'Check In Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-departureDate',
				fieldName: 'Departure Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-departureTime',
				fieldName: 'Departure Time',
				fieldType: 'time',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-checkOutDate',
				fieldName: 'Check Out Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-numberOfDaysStay',
				fieldName: 'Number of Days Stay',
				fieldType: 'number',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-roomType',
				fieldName: 'Room Type',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_roomingInformation-hotelConfirmationNumber',
				fieldName: 'Hotel Confirmation Number',
				fieldType: 'text',
				propertyType: 'attendee'
			}
		]
	},
	{
		_id: 'travel',
		title: 'Attendee Travel',
		fields: [
			{
				_id: '_travelInformation-originCity',
				fieldName: 'Origin City',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-originState',
				fieldName: 'Origin State',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalCity',
				fieldName: 'Arrival City',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalState',
				fieldName: 'Arrival State',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalDate',
				fieldName: 'Arrival Date',
				fieldType: 'date',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalTime',
				fieldName: 'Arrival Time',
				fieldType: 'time',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalCarrier',
				fieldName: 'Arrival Carrier',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalFlight',
				fieldName: 'Arrival Flight',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalTicketed',
				fieldName: 'Arrival Ticketed',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalClassOfService',
				fieldName: 'Arrival Class of Service',
				fieldType: 'text',
				propertyType: 'attendee'
			}, {
				_id: '_travelInformation-arrivalLocator',
				fieldName: 'Arrival Locator',
				fieldType: 'text',
				propertyType: 'attendee'
			}]
	},
	{
		_id: 'special',
		title: 'Special',
		fields: [
			{
				_id: '_additionalAttendees',
				fieldName: 'Additional Attendees',
				fieldType: 'additionalAttendees',
				propertyType: 'base',
				moduleConfiguration: {
					label: 'Guest',
					maxAttendees: 2,
					associatedGroup: null,
					defaultStatus: 'registered',
					confirmationEmail: null,
					fields: [
						{
							_id: '_firstName',
							attendeeField: { propertyType: 'base', propertyName: 'firstName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeFirstName',
							label: 'First Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'text',
							static: true
						},
						{
							_id: '_lastName',
							attendeeField: { propertyType: 'base', propertyName: 'lastName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeLastName',
							label: 'Last Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: false,
							style: '',
							type: 'text',
							static: false
						},
						{
							_id: '_email',
							attendeeField: { propertyType: 'base', propertyName: 'email' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeEmail',
							label: 'Email',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'email',
							static: true
						}
					]
				}
			},
			{
				_id: '_notifierEmail',
				fieldName: 'Notifier Email',
				fieldType: 'notifierEmail',
				propertyType: 'base',
				moduleConfiguration: {
					notificationEmail: null,
				}
			},
			{
				_id: '_medProLookup',
				fieldName: 'MedPro Lookup',
				fieldType: 'medProLookup',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			},
			{
				_id: '_salesForceSearchContact',
				fieldName: 'Salesforce Search Contact',
				fieldType: 'salesForceSearchContact',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			},
			{
				_id: '_salesForceSearchAccount',
				fieldName: 'Salesforce Search Account',
				fieldType: 'salesForceSearchAccount',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			}
		]
	}
];

class Form extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;

		const shortCodes = [
			{ label: 'Organization Name', key: 'org.name' },
			{ label: 'Event Name', key: 'event.name' },
			{ label: 'Event Start Date', key: 'event.startDate' },
			{ label: 'Event Start Time', key: 'event.startTime' },
			{ label: 'Event End Date', key: 'event.endDate' },
			{ label: 'Event End Time', key: 'event.endTime' },
			{ label: 'Event Name Slug', key: 'event.nameSlug' },
			{ label: 'Event Timezone', key: 'event.timezone' },
			{ label: 'Attendee First Name', key: 'attendee.firstName' },
			{ label: 'Attendee Last Name', key: 'attendee.lastName' },
			{ label: 'Attendee Email', key: 'attendee.email' },
			{ label: 'Attendee PhoneNumber', key: 'attendee.phoneNumber' },
			{ label: 'Attendee Title', key: 'attendee.title' },
			{ label: 'Attendee Company', key: 'attendee.company' },
			{ label: 'Attendee Text Message Opt In', key: 'attendee.textMessageOptIn' },
			{ label: 'Attendee Dietary Preference', key: 'attendee.dietaryPreference' },
			{ label: 'Attendee Food Allergies', key: 'attendee.foodAllergies' },
			{ label: 'Attendee Emergency Contact Name', key: 'attendee.emergencyContactName' },
			{ label: 'Attendee Emergency Contact PhoneNumber', key: 'attendee.emergencyContactPhoneNumber' },
			{ label: 'Attendee Address', key: 'attendee.address' },
			{ label: 'Attendee City', key: 'attendee.city' },
			{ label: 'Attendee State', key: 'attendee.state' },
			{ label: 'Attendee Zip', key: 'attendee.zip' },
			{ label: 'Attendee Country', key: 'attendee.country' },
			{ label: 'Attendee Profile Picture', key: 'attendee.profilePicture' },
		];

		const keyedMetadata = [
			'organization.name',
			'event.name',
			'event.startDate',
			'event.startTime',
			'event.endDate',
			'event.endTime',
			'event.nameSlug',
			'event.timezone',
			'attendee.firstName',
			'attendee.lastName',
			'attendee.email',
			'attendee.phoneNumber',
			'attendee.title',
			'attendee.company',
			'attendee.textMessageOptIn',
			'attendee.dietaryPreference',
			'attendee.foodAllergies',
			'attendee.emergencyContactName',
			'attendee.emergencyContactPhoneNumber',
			'attendee.address',
			'attendee.city',
			'attendee.state',
			'attendee.zip',
			'attendee.country',

		];
		const keyedData = {
			organization: {
				name: 'Name',
				_defaultLabel: 'Organization'
			},
			event: {
				name: 'Name',
				startDate: 'Start Date',
				startTime: 'Start Time',
				endDate: 'End Date',
				endTime: 'End Time',
				nameSlug: 'Name Slug',
				timezone: 'Timezone',
				_defaultLabel: 'Event'
			},
			attendee: {
				firstName: 'First Name',
				lastName: 'Last Name',
				email: 'Email',
				phoneNumber: 'Phone Number',
				title: 'Title',
				company: 'Company',
				textMessageOptIn: 'Text Message Opt In',
				dietaryPreference: 'Dietary Preference',
				foodAllergies: 'Food Allergies',
				emergencyContactName: 'Emergency Contact Name',
				emergencyContactPhoneNumber: 'Emergency Contact Phone Number',
				address: 'Address',
				city: 'City',
				state: 'State',
				zip: 'Zip',
				country: 'Country',
				_defaultLabel: 'Attendee'
			}
		};

		if (this.props.attendeeFields) {
			this.props.attendeeFields.forEach((category) => {
				const categoryKey = category.title.replace(/ /g, '').replace('?', '');
				keyedData.attendee[categoryKey] = keyedData.attendee[categoryKey] || { _defaultLabel: category.title };
				category.fields.forEach((field) => {
					const fieldKey = field.fieldName.replace(/ /g, '').replace('?', '');
					keyedData.attendee[categoryKey][fieldKey] = field.fieldName;
					keyedMetadata.push(`attendee.${categoryKey}.${fieldKey}`);
					shortCodes.push({
						label: `Attendee ${category.title} ${field.fieldName}`,
						key: `attendee.${categoryKey}.${fieldKey}`
					});
				});
			});
		}

		if (this.props.eventFields) {
			this.props.eventFields.forEach((category) => {
				const categoryKey = category.title.replace(/ /g, '').replace('?', '');
				keyedData.event[categoryKey] = keyedData.event[categoryKey] || { _defaultLabel: category.title };
				category.fields.forEach((field) => {
					const fieldKey = field.fieldName.replace(/ /g, '').replace('?', '');
					keyedData.event[categoryKey][fieldKey] = field.fieldName;
					keyedMetadata.push(`event.${categoryKey}.${fieldKey}`);
					shortCodes.push({
						label: `Event ${category.title} ${field.fieldName}`,
						key: `event.${categoryKey}.${fieldKey}`
					});
				});
			});
		}

		const form = props.forms.find((f) => f._id == params.formId);
		/* if (form.sections.length === 0) {
			form.sections = [
				{
					_id: `^0`,
					title: '',
					page: 1,
					fields: [],
					buttons: []
				}
			];
		} */

		if ((form.pages && form.pages.length === 0) || !form.pages) {
			form.pages = [
				{
					title: '',
					description: '',
					sections: [
						{
							_id: `^0`,
							title: '',
							page: 1,
							fields: [],
							buttons: []
						}
					]
				}
			];
		}


		this.state = {
			form: form,
			editedForm: JSON.parse(JSON.stringify(form)),
			programId: props.programId,
			editedFields: props.fields,
			customFields: {} /* this.props.fields.map((category) => {
				const title = category.title;
				const headers = category.fields.map((header) => {
					const name = header.fieldName;
					return {
						_id: header._id,
						category: title,
						categoryId: category._id,
						value: name,
						label: name
					};
				});
				return {
					_id: category._id,
					label: title,
					value: category._id,
					options: headers
				};
			}), */,

			fieldModal: false,
			adding: false,
			loading: false,
			editing: false,
			removing: false,
			removedCategoryId: 0,
			removedFieldId: 0,
			customFieldsCollapsed: false,
			unattachedFieldsCollapsed: true,
			formButtonsCollapsed: true,
			fieldTypeTabSelected: 0,
			pageCount: form.pageCount || 1,
			currentPage: 1,
			formEditorTabSelected: 0,
			//availableEmails: availableEmails || [],
			selectedSection: 0,
			formBuilderModalOpen: false,
			formEditorModalOpen: false,
			toggleDuplicateFormModal: false,
			/* New */
			pages: [],
			isV1: false,
			selectedEmbedPagePreview: {},
			toggleAddToPage: false,
			groups: [],
			emailsAvailable: [],
			toggleAddToPage: false,
			formUsed: false,
			toggleRenameForm: false,
			editCount: 0,
			shortCodes: shortCodes,
			keyedData: keyedData,
			keyedMetadata: keyedMetadata,
			customFields: [],
		};
	}

	componentDidMount() {
		const { program } = this.props;
		const { editedForm } = this.state;
		let groups = program.eventDefaults.groups;
		let selectedEmbedPagePreview = {};
		groups = groups.map(g => {
			return {
				label: g.name,
				value: g._id,
			}
		})
		let emailsAvailable = program.eventDefaults.emails;
		emailsAvailable = emailsAvailable.map(e => {
			return {
				value: e._id,
				label: e.name
			}
		})
		let pages = program.eventDefaults.pages;
		let formUsed = false;

		pages = pages.filter((page) => {
			let partialFound = page.partials.find((partial) => partial.file == 'v2/form');
			if (partialFound) {

				partialFound.parameters.map(p => {
					if (p.key == 'linkedForm' && p.value == editedForm._id) {
						selectedEmbedPagePreview = page;
						formUsed = true;
					}

				})
				return !formUsed;
			}
		}).map((page) => {
			return {
				label: page.title,
				value: page._id,
			}
		});

		const formId = editedForm._id;


		let editForm = editedForm;
		let isMissingFields = true;
		if (editForm.pages && editForm.pages.length > 0) {
			for (let pageCount = 0; pageCount < editForm.pages.length; pageCount++) {
				let page = editForm.pages[pageCount];
				if (page.sections && page.sections.length > 0) {
					for (let sectionCount = 0; sectionCount < page.sections.length; sectionCount++) {
						let section = page.sections[sectionCount];
						if (section.fields && section.fields.length > 0) {
							isMissingFields = false;
							break;
						}
					}

				}
				if (isMissingFields == false) {
					break;

				}

			}

		}


		const metadataOptions = [];
		let keyedData = this.state.keyedData;

		keyedData &&
			Object.keys(keyedData).forEach((key) => {
				const layer1 = keyedData[key];
				if (typeof layer1 === 'object') {
					Object.keys(layer1).forEach((key2) => {
						const layer2 = layer1[key2];
						const k2 = key2.substring(0, 1) + key2.substring(1);
						if (typeof layer2 === 'object') {
							Object.keys(layer2).forEach((key3) => {
								const layer3 = layer2[key3];
								const k3 = key3.substring(0, 1) + key3.substring(1);
								if (
									key !== '_defaultLabel' &&
									key2 !== '_defaultLabel' &&
									key3 !== '_defaultLabel'
								) {
									//metadataOptions.push(`${key}.${key2}.${key3}`);
									metadataOptions.push({
										label: `${layer1._defaultLabel} ${layer2._defaultLabel} ${layer3}`,
										value: `{{${key}${k2}${k3}}}`,
										text: `${key}${k2}${k3}`
									});
								}
							});
						} else {
							if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
								//metadataOptions.push(`${key}.${key2}`);
								metadataOptions.push({
									label: `${layer1._defaultLabel} ${layer2}`,
									value: `{{${key}${k2}}}`,
									text: `${key}${k2}`
								});
							}
						}
					});
				} else {
					if (key !== '_defaultLabel') {
						metadataOptions.push({ label: layer1, value: key, text: `{{${key}}}` });
					}
				}
			});


		this.setState({ isMissingFields: isMissingFields, emailsAvailable: [{ value: '', label: 'No Email' }, ...emailsAvailable], groups: groups, pages: pages, formUsed: formUsed, selectedEmbedPagePreview: selectedEmbedPagePreview, customFields: metadataOptions });
	}

	saveInfo = () => {
		const { match: { params } } = this.props;
		const { editedForm } = this.state;
		let editedFormTemp = JSON.parse(JSON.stringify(editedForm));
		editedFormTemp.sections = editedFormTemp.sections.map((section) => {
			if (section._id.startsWith('^')) {
				delete section._id;
			}
			section.fields = section.fields.map((field) => {
				if (field._id.startsWith('*')) {
					delete field._id;
				}
				if (field.id && field.id.startsWith('*')) {
					delete field.id;
				}
				return field;
			});
			return section;
		});
		if (editedForm.confirmationEmail == 'N/A' || editedForm.confirmationEmail == '') {
			delete editedFormTemp.confirmationEmail;
		}
		this.props.saveForm(editedFormTemp, () => {
			const form = this.props.forms.find((f) => f._id == params.formId);
			let editForm = form;
			let isMissingFields = true;
			if (editForm.pages && editForm.pages.length > 0) {
				for (let pageCount = 0; pageCount < editForm.pages.length; pageCount++) {
					let page = editForm.pages[pageCount];
					if (page.sections && page.sections.length > 0) {
						for (let sectionCount = 0; sectionCount < page.sections.length; sectionCount++) {
							let section = page.sections[sectionCount];
							if (section.fields && section.fields.length > 0) {
								isMissingFields = false;
								break;
							}
						}

					}
					if (isMissingFields == false) {
						break;

					}

				}

			}
			this.setState({ isMissingFields: isMissingFields, form: form, editedForm: { ...form }, editing: false, editCount: this.state.editCount + 1 });
		});
	};

	cancelEdit = () => {
		const revertedForm = JSON.parse(JSON.stringify(this.state.form));
		this.setState({
			editedForm: revertedForm,
			editing: false,
			focusOnField: false,
			focusedFieldId: null,
			focusedSectionId: null
		});
	};

	updateForm = (updatedForm) => {
		this.setState({ editedForm: updatedForm, editing: true });
	};


	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	toggleFormBuilderModal = () => {
		this.setState({ formBuilderModalOpen: !this.state.formBuilderModalOpen });
	};


	toggleFormEditorModal = () => {
		this.setState({ formEditorModalOpen: !this.state.formEditorModalOpen });
	};
	toggleAddToPage = () => {
		this.setState({ toggleAddToPage: !this.state.toggleAddToPage });
	};

	/* Updates  */
	handleChange = (changeObj) => {
		const { editedForm } = this.state;
		this.setState({ editedForm: { ...editedForm, ...changeObj }, editing: true });
	};
	updateAddToPage = (page, callback) => {
		let pageEmbedOptionsV2 = [...this.state.pageEmbedOptionsV2, {
			label: page.title,
			value: page._id,
			partials: page.partials,
			url: '',
			_id: page._id
		}];

		let selectedEmbedPage = pageEmbedOptionsV2.length > 0 ? pageEmbedOptionsV2[0]._id : 0;

		const selectedEmbedPagePreview = pageEmbedOptionsV2.find((option) => option.value == selectedEmbedPage);
		this.setState({ selectedEmbedPagePreview: selectedEmbedPagePreview, pageEmbedOptionsV2: pageEmbedOptionsV2, selectedEmbedPage: selectedEmbedPage },
			() => {
				callback && callback();
			});
	}


	handleRenameForm = (name, callback) => {
		const { editedForm, form } = this.state;

		this.setState({ editedForm: { ...editedForm, name: name }, form: { ...form, name: name } }, () => {
			this.saveInfo();
			callback && callback();
		});
	}


	renderSection = () => {

		const { form, editing, editedForm, loading, selectedSection, pardotIntegrationEnabled, pageEmbedOptions, selectedEmbedPage, isV1, selectedEmbedPageV1, isMissingFields } = this.state;

		const { orgId, eventId, event, programId } = this.props;
		const selectedEmbedPagePreview = pageEmbedOptions && pageEmbedOptions.find((option) => option.value == selectedEmbedPage);
		const formTypes = [
			{ value: 'registration', label: 'Registration' },
			{ value: 'custom', label: 'Custom' },
			{ value: 'general', label: 'General' }
		]

		return <div className="sectionBody">

			<div className='blocksContainer'>
				{/* Start Settings */}
				{selectedSection == 0 ?
					<div className="sectionBlock mr-25 contained-h">
						<h2 className='blockHeader'>Basic Information</h2>
						{/* New Data */}
						<SelectField
							label={'Form Type'}
							type="select"
							required={true}
							value={formTypes.find((type) => type.value == editedForm.type)}
							onChange={(e) => {
								this.handleChange({ type: e.value });
							}}
							options={formTypes}
							information={
								<div>
									<h3 class="blockHeader">Registration</h3>
									<p className='mb-10'>
										Capture information to create attendee
									</p>
									<h3 class="blockHeader">General</h3>
									<p className='mb-10'>
										Capture any type of information without creating attendee
									</p>
									<h3 class="blockHeader">Custom</h3>
									<p className='mb-10'>
										Capture and send information to a third party source
									</p>

								</div>
							}
						/>
						<ToggleField
							label="Enable Attendance Confirmation"
							checked={editedForm.rsvpEnabled}
							icons={false}
							onChange={(e) => {
								this.handleChange({ rsvpEnabled: e.target.checked });
							}}
						/>

						{!editedForm.rsvpEnabled && editedForm.type == 'registration' && <ToggleField
							label="Allow Anonymous Submissions"
							checked={editedForm.allowAnonymous}
							icons={false}
							onChange={(e) => {
								this.handleChange({ allowAnonymous: e.target.checked });
							}}
						/>}
						{editedForm.rsvpEnabled &&
							<InputField
								label="Submit Button Label"
								required={false}
								placeholder="Register for Event"
								value={editedForm.rsvpLabel}
								onChange={(e) => {
									this.handleChange({ rsvpLabel: e.target.value });
								}}
							/>
						}
						<h2 className='blockHeader'>Submission</h2>
						<InputField
							label="Confirmation Page Title"
							required={false}
							placeholder="Form Submitted"
							value={editedForm.successTitle}
							onChange={(e) => {
								this.handleChange({ successTitle: e.target.value });
							}}
						/>
						<RichTextEditor
							id="Confirmation_Page_Description"
							label="Confirmation Page Description"
							placeholder="We have received your information"
							value={editedForm.successSubtitle}
							onChange={(e) => {
								this.handleChange({ successSubtitle: e });
							}}
						/>

						<ToggleField
							label="Send Confirmation Email"
							checked={editedForm.confirmationEmailEnabled}
							icons={false}
							onChange={(e) => {
								this.handleChange({ confirmationEmailEnabled: e.target.checked });
							}}
						/>
						{editedForm.confirmationEmailEnabled && (
							<SelectField
								label="Confirmation Email Template"
								options={this.state.emailsAvailable}
								value={this.state.emailsAvailable.find(
									(e) => e.value == editedForm.confirmationEmail
								)}
								onChange={(e) => {
									this.handleChange({ confirmationEmail: e.value });
								}}
							/>
						)}
					</div>

					: selectedSection == 1 ?

						<div className="sectionBlock mr-25 contained-h">
							<div className="sectionBlock mr-25 contained-h">
								<h2 className='blockHeader'>Form Information</h2>
								<ToggleField
									label="Pre-fill form with attendee data"
									checked={editedForm.preFillAttendeeData}
									icons={false}
									onChange={(e) => {
										this.handleChange({ preFillAttendeeData: e.target.checked });
									}}
								/>
								<ToggleField
									label="Show Progress Bar"
									checked={editedForm.progressBarEnabled}
									icons={false}
									onChange={(e) => {
										this.handleChange({ progressBarEnabled: e.target.checked });
									}}
								/>


								<ToggleField
									label="Enable a review page before form submission"
									checked={editedForm.enableSubmissionReview}
									icons={false}
									onChange={(e) => {
										this.handleChange({ enableSubmissionReview: e.target.checked });
									}}
								/>

								{editedForm.enableSubmissionReview &&
									<div>
										<InputField
											label="Review Page Title"
											required={false}
											placeholder="Please Review"
											value={editedForm.submissionReviewTitle}
											onChange={(e) => {
												this.handleChange({ submissionReviewTitle: e.target.value });
											}}
										/>

										<InputField
											label="Review Page Description"
											required={false}
											placeholder="Look over your questions and go back if you need to edit."
											value={editedForm.submissionReviewSubtitle}
											onChange={(e) => {
												this.handleChange({ submissionReviewSubtitle: e.target.value });
											}}
										/>
									</div>
								}

								<h2 className='blockHeader'>Decline Attendance</h2>

								<ToggleField
									label="Allow attendees to decline attendance"
									checked={editedForm.denialEnabled}
									icons={false}
									onChange={(e) => {
										this.handleChange({ denialEnabled: e.target.checked });
									}}
								/>

								{editedForm.denialEnabled &&
									<div>
										<InputField
											label="Decline Button Label"
											required={false}
											placeholder="I am unable to attend"
											value={editedForm.declineLabel}
											onChange={(e) => {
												this.handleChange({ declineLabel: e.target.value });
											}}
										/>

										<InputField
											label="Decline Page Title"
											required={false}
											placeholder="Sorry we missed you"
											value={editedForm.declineTitle}
											onChange={(e) => {
												this.handleChange({ declineTitle: e.target.value });
											}}
										/>

										<RichTextEditor
											id="Decline_Page_Description"
											label="Decline Page Description"
											placeholder="Not attending"
											value={editedForm.declineSubtitle}
											onChange={(e) => {
												this.handleChange({ declineSubtitle: e });
											}}
										/>


										<ToggleField
											label="Enable Decline Options"
											checked={editedForm.enableDeclineOptions}
											icons={false}
											onChange={(e) => {
												this.handleChange({ enableDeclineOptions: e.target.checked });
											}}
										/>

										{editedForm.enableDeclineOptions && (
											<div className="pb-20">
												<div className="formFieldLabel">Decline Options</div>
												{editedForm.declineOptions.map((option, index) => {
													return (
														<div className='flex-ac'>
															<InputField
																label="Item"
																required={false}
																placeholder="Not attending"
																value={option}
																onChange={(e) => {
																	editedForm.declineOptions[index] = e.target.value;
																	this.handleChange({ declineOptions: editedForm.declineOptions });
																}}
															/>
															<i className='las la-times ml-10' onClick={() => {
																editedForm.declineOptions.splice(index, 1);
																this.handleChange({ declineOptions: editedForm.declineOptions });
															}}></i>
														</div>
													)
												})}

												<div className="c-pointer flex jcc" onClick={() => {
													editedForm.declineOptions.push('');
													this.handleChange({ declineOptions: editedForm.declineOptions });
												}}>+ Add Decline Options</div>
											</div>
										)

										}

										<ToggleField
											label="Require Decline Options"
											checked={editedForm.requireDeclineOptions}
											icons={false}
											onChange={(e) => {
												this.handleChange({ requireDeclineOptions: e.target.checked });
											}}
										/>

										<ToggleField
											label="Include Decline Comment"
											checked={editedForm.enableDeclineComment}
											icons={false}
											onChange={(e) => {
												this.handleChange({ enableDeclineComment: e.target.checked });
											}}
										/>

										{editedForm.enableDeclineComment &&
											<ToggleField
												label="Require Decline Comment"
												checked={editedForm.requireComment}
												icons={false}
												onChange={(e) => {
													this.handleChange({ requireComment: e.target.checked });
												}}
											/>
										}
									</div>

								}

								<h2 className='blockHeader'>Attendee</h2>

								<ToggleField
									label="Add attendees to a group"
									checked={editedForm.enableGrouping}
									icons={false}
									onChange={(e) => {
										this.handleChange({ enableGrouping: e.target.checked });
									}}
								/>
								{editedForm.enableGrouping &&
									<div>

										<SelectField
											label="Attendee Group"
											options={this.state.groups}
											value={this.state.groups.find(
												(e) => e.value == editedForm.mappedGroup
											)}
											onChange={(e) => {
												this.handleChange({ mappedGroup: e.value });
											}}
										/>

										<InputField
											label="Max Capacity Title"
											required={false}
											placeholder="Registration is at capacity"
											value={editedForm.maxCapTitle}
											onChange={(e) => {
												this.handleChange({ maxCapTitle: e.target.value });
											}}
										/>
										<InputField
											label="Max Capacity Message"
											required={false}
											placeholder="This registration has reached capacity"
											value={editedForm.maxCapacityMessage}
											onChange={(e) => {
												this.handleChange({ maxCapacityMessage: e.target.value });
											}}
										/>
									</div>
								}
							</div>


						</div> : ''
				}
				<div style={{ padding: 5 }} className='sectionBlock contained-h emailDesignPreview mt-20'>
					<div style={{ width: 425 }}
						className="cardDetailsContainerPages containerTitle">
						<div className='w-100'>
							<h2 className='blockHeader mt-0'>Design</h2>
							<h5 className='formFieldLabel mt-10'>Form Preview </h5>

						</div>


						{!isMissingFields ? <button
							className="neu ml-a"
							onClick={this.toggleFormEditorModal}
						>
							Edit Form
						</button> : <button
							className="neu prim ml-a"
							onClick={this.toggleFormEditorModal}
						>Add Form Fields

						</button>}






						{/* <button
							className="neu prim ml-a"
							onClick={this.toggleFormEditorModal}
						>
							Edit Form
						</button>

						{selectedEmbedPagePreview && <button
							className="neu ml-25"
							onClick={() => {
								window.open(selectedEmbedPagePreview.url, '_blank');
							}}
						>
							Open Page
						</button>} */}
					</div>
					<div style={{ width: 425, height: 617 }}>

						<div
							className="emailPreview eFrame flex-ac preview"
						>
							<PagePreviewFrame
								ENV={ENV}
								preview={true}
								key={this.state.editCount}
								renderURL={`Organizations/${orgId}/programs/${programId}/render/formRender`}
								pageProperties={{}}
								focusedComponent={""}
								simulatedUser={{ value: '' }}
								simulatedSession={{ value: false }}
								renderOnly={true}
								formId={editedForm._id}

							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
	toggleDuplicateFormModal = () => {
		const { toggleDuplicateFormModal } = this.state;
		this.setState({ toggleDuplicateFormModal: !toggleDuplicateFormModal })
	}
	updateStatus = (status) => {
		const { program } = this.props;
		const { editedForm } = this.state;
		let pages = program.eventDefaults.pages;
		let formUsed = false;
		let selectedEmbedPagePreview = {};
		pages = pages.filter((page) => {
			let partialFound = page.partials.find((partial) => partial.file == 'v2/form');
			if (partialFound) {

				partialFound.parameters.map(p => {
					if (p.key == 'linkedForm' && p.value == editedForm._id) {
						selectedEmbedPagePreview = page;
						formUsed = true;
					}

				})
				return !formUsed;
			}
		}).map((page) => {
			return {
				label: page.title,
				value: page._id,
			}
		});


		this.setState({ formUsed: status, pages: pages, selectedEmbedPagePreview: selectedEmbedPagePreview })
	}

	toggleRenameForm = () => {
		const { toggleRenameForm } = this.state;
		this.setState({ toggleRenameForm: !toggleRenameForm })
	}

	render() {
		const { form, editing, editedForm, loading, selectedSection, toggleRenameForm, editingFormBuilder, toggleDuplicateFormModal, toggleAddToPage, formUsed, selectedEmbedPagePreview, isMissingFields, shortCodes, keyedMetadata, keyedData } = this.state;

		const { program, orgId } = this.props;
		return (
			<div className="sectionContainer" >
				<SectionHeader
					title={form.name}
					breadcrumbs={[
						{
							name: program.name,
						},
						{
							name: 'Event Forms',
						}
					]}
					subActions={[
						{
							label: 'Rename Form',
							onClick: this.toggleRenameForm
						},
						{
							label: 'Duplicate Form',
							onClick: this.toggleDuplicateFormModal
						}
					]}
				>
					<TabsContainer
						tabs={[{ title: 'Settings' }, { title: 'Advanced' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
						style={{}}
					/></SectionHeader>
				{!loading && <div className="actionButtonContainer mt-20" style={{ marginLeft: 'unset' }}>
					<div >
						<button className={`mainAction prim`}
							disabled={false}
							onClick={() => {
								if (!formUsed) {
									this.toggleAddToPage();
								} else {
									this.props.history.push(`/${orgId}/programs/${program._id}/pages/${selectedEmbedPagePreview._id}`);
								}
							}}>
							<div>{!formUsed ? "Add to Page" : "View Page"}</div>
						</button>
					</div>
				</div>}

				{!loading && this.renderSection()}
				<SectionFooter updatedAt={form.updatedAt} saveEdit={this.saveInfo} cancelEdit={this.cancelEdit} editing={editing} />
				<FormBuilderModal
					isOpen={this.state.formEditorModalOpen}
					editing={this.state.editing}
					toggle={this.toggleFormEditorModal}
					form={form}
					editedForm={editedForm}
					fields={this.props.fields}
					baseFieldList={baseFieldList}
					links={{ get: `` }}
					updateForm={this.updateForm}
					cancelEdit={this.cancelEdit}
					emails={this.state.emailsAvailable}
					orgId={this.props.orgId}
					eventId={this.props.eventId}
					addNewField={this.props.addNewField}
					keyedMetadata={keyedMetadata}
					keyedData={keyedData}
					customFields={this.state.customFields}
					saveForm={this.saveInfo} />
				{/* <Modal isOpen={formBuilderModalOpen} toggle={this.toggleFormBuilderModal}>
					<div className='modalHeader'>

						<div className="headerContainer">

							<button className="neu min" onClick={this.toggleFormBuilderModal}>Close</button>
							<div style={{ textAlign: 'center' }}>
								<h3>Form Builder</h3>
								<h4>{form.name}</h4>
							</div>
							<button className={`prim min`}
								onClick={this.saveInfo}>Save Form
							</button>
						</div>
					</div>
					<ModalBody>
						<FormBuilder
							editedForm={editedForm}
							fields={this.props.fields}
							baseFieldList={baseFieldList}
							links={{ get: `` }}
							updateForm={this.updateForm}
							cancelEdit={this.cancelEdit}
							emails={this.state.availableEmails}
						/>
					</ModalBody>
				</Modal> */}
				{/* addCustomFieldModalOpen && (
					<AddCustomFieldModal
						adding={true}
						toggle={() => this.setState({ addCustomFieldModalOpen: false, focusedField: null })}
						fields={this.props.event.customFields.attendees}
						section="attendees"
						addField={this.addField}
						programId={this.props.programId}
						fieldName={this.state.focusedField.label}
						orgId={this.props.orgId}
					/>
				) */}
				{
					toggleRenameForm && <RenameFormModal
						toggle={this.toggleRenameForm}
						isOpen={toggleRenameForm}
						name={form.name}
						formId={form._id}
						form={editedForm}
						orgId={orgId}
						update={this.handleRenameForm}
					/>
				}

				{
					toggleAddToPage &&
					<AddToPage
						toggle={this.toggleAddToPage}
						isOpen={toggleAddToPage}
						pages={this.state.pages}
						updateAddToPage={this.updateAddToPage}
						orgId={orgId}
						programId={program._id}
						formId={form._id}
						updateStatus={this.updateStatus}
						updatePages={this.props.updatePages}
					/>
				}
				{
					toggleDuplicateFormModal && <DuplicateFormModal
						toggle={this.toggleDuplicateFormModal}
						isOpen={toggleDuplicateFormModal}
						name={form.name}
						formId={form._id}
						orgId={orgId}
						programId={program._id}
						duplicateForm={this.props.duplicateForm}
					/>
				}
			</div >
		);
	}
}

export default withRouter(Form);
