import React from 'react';
import { withRouter } from 'react-router-dom';
import Scheduler from '../../../../utils/Scheduler';
import moment from 'moment';

import FieldModal from '../../../../components/modals/fieldModal';

class UnscheduleNotificationModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

			submitting: false
		}
	}
	pauseCampaign = async () => {
		const { orgId, eventId, toggle, notification, update } = this.props;
		this.setState({ submitting: true })

		try {
			await Scheduler()
				.patch(`Organizations/${orgId}/notificationSchedules`, { notifications: [notification._id], request: 'unschedule' })
				.then((res) => {
					update(
						{
							scheduled: false,
							sendDate: '',
							sendTime: '',
							status: 'Draft'
						},
						() => {
							toggle();
						}
					);
				});
		} catch (e) {
			console.log(e);
		}
	};

	render() {
		const { isOpen, toggle, event, notification } = this.props;
		const { submitting } = this.state;
		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Pause Campaign"}
					bodyHeaderText={'Are you sure you want to pause this campaign?'}
					bodyContent={() => {
						return (<p>Scheduled for {notification.sendDate} {notification.sendTime} {event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}</p>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Pause' : 'Pausing...'}
					actionButton={() => this.pauseCampaign()}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>
		);
	}
}

export default withRouter(UnscheduleNotificationModal);
