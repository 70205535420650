// import external modules
import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { setCookie, removeCookie } from '../../utils/cookie';
import API from '../../utils/API';
import { LogIn, Edit, Edit2, Send, Mail, Phone, AlertTriangle, CheckCircle, X } from 'react-feather';
import { Row, Col, Input, Form, FormGroup, Button, Label, Card, CardBody, CardFooter } from 'reactstrap';

import logo from '../../assets/img/logos/simple events logo.png';

import InputField from '../../components/inputs/inputField';
class ForgotPassword extends Component {
	state = {
		email: '',
		password: '',
		complete: false,
		error: '',
		disableSubmit: true,
		emailErrorTrigger: false
	};

	submit = (e) => {
		e.preventDefault();
		e.persist();
		const data = {
			emailAddress: this.state.email
		};
		if (data.emailAddress !== '') {
			API()
				.post(`/auth/cantLogin`, data)
				.then((res) => {
					this.setState({ complete: true, emailErrorTrigger: false });
				})
				.catch((err) => {
					this.setState({ error: err.response.data, emailErrorTrigger: true });
				});
		} else {
			this.setState({ error: 'Email is required' });
		}
	};

	render() {
		const { complete, disableSubmit, emailErrorTrigger } = this.state;

		return (
			<div className='w-100 h-100 flex column'>

				<div className='systemToolbar w-100' >
					<div className="flex h-100 ml-30" style={{ alignItems: 'end' }}>

						<div className="navLogo c-pointer" style={{ marginRight: 113 }} onClick={() => window.location.href = "/"}>
							<img src={logo} />
						</div>
					</div>
				</div>
				<div className='w-fc p-0 neuCard  h-100' style={{ background: 'white', border: 'none', width: 'calc(100% - 15px)', marginLeft: 15 }}>

					<div className="w-100 m-a mt-20 p-50" style={{ maxWidth: 560 }}>

						<div className='flex aic'>
							{!complete ? <div className='w-100 p-25'>
								<div className='text-c mb-40'>
									<h1 className='mb-10'>Password Reset Request</h1>
									<p>
										Enter your email address below and we will send you a password reset link.
									</p>
								</div>
								<InputField
									label={'Account Email'}
									type={'email'}
									onChange={(e) => {
										const val = e.currentTarget.value;
										val == ''
											? this.setState({ disableSubmit: true })
											: this.setState({ disableSubmit: false });
										this.setState({ email: val });
									}}
									value={this.state.email}
									errorTrigger={emailErrorTrigger}
									errorMessage={'Email required'}
								/>

								<button
									onClick={this.submit}
									className={`prim ${disableSubmit ? 'inactive' : ''} w-100 mt-50 mb-20`}
									disabled={disableSubmit}
								>
									Submit
								</button>
								<div className='w-100 flex jcc'>
									<Link to={'/'}>
										Back to login
									</Link>
								</div>
							</div> : <div className='w-100 p-25'>
								<div className='text-c mb-40'>
									<h1 className='mb-10'>Password Reset Link Sent</h1>
									<p>If you have a <b>simple.events</b> account, you will receive a password reset link to
										your email.</p>
								</div>
								<div className='w-100 flex jcc'>
									<Link to={'/'}>
										Back to login
									</Link>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ForgotPassword;
