import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../../utils/API';
import EventEmail from './email';
import NewEmailModal from './modals/addEmailModal';
import DeleteEmailModal from './modals/deleteDefaultModal';

import ViewsContainer from '../../../components/views/ViewsContainer';
import BasicViewContainer from '../../../components/views/BasicViewContainer';
import TemplateRenameModal from './modals/renameEmailTemplateModal';
import DuplicateEmailModal from './modals/duplicateEmailModal';

let batchActionCB;
class EventEmails extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			subject: 'Subject',
			previewText: 'Preview Text'
		};
		const defaultFieldTypes = {
			name: 'text',
			subject: 'text',
			previewText: 'text'
		};

		this.state = {
			orgId: this.props.orgId,
			sortedEmails: [],
			loading: false,
			selectedEmail: 0,
			emailSelected: false,
			addEmailModalOpen: false,
			newEmails: {},
			emailsViews: [],
			toggledColumns: { name: false, subject: true, sentEmailCount: true },
			checkedEmails: {},
			sortByColumn: { name: true },
			loading: true,
			deletedEmails: [],
			deleteEmailsModalOpen: false,
			loading: false,
			keyedMetadata: [],
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			loading: true,
			selectedSection: 0,
			toggleRename: false,
			selectedRow: '',
			toggleDuplicateEmailModal: false,
		};
	}

	deleteCheckedEmails = async (emails) => {
		const { eventId, orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/emails`, {
				emails: Object.keys(this.state.checkedEmails)
			})
			.then((res) => {
				if (res.data) {
					this.setState({
						addEmailModalOpen: false,
						deletedEmails: [...this.state.deletedEmails, ...Object.keys(this.state.checkedEmails)],
						sortedEmails: this.state.sortedEmails.filter(
							(e) => !Object.keys(this.state.checkedEmails).includes(e.id)
						),
						deleteEmailsModalOpen: false
					});
				}
			});

		this.setState({
			checkedEmails: {}
		});
	};

	deleteCheckedEmails = (emailIds) => {
		this.props.deleteEmails(emailIds, () => {
			batchActionCB && batchActionCB('delete');
			this.toggleDeleteEmails();
		});
	};

	updateEmails = (updatedEmail) => {
		const tempEmails = this.state.sortedEmails;
		tempEmails[this.state.selectedEmail] = updatedEmail;
		this.setState({ sortedEmails: tempEmails });
	};

	toggleDeleteEmails = (checkedEmails, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedEmails: checkedEmails || this.state.checkedEmails,
			deleteEmailsModalOpen: !this.state.deleteEmailsModalOpen
		});
	};

	toggleSingleDeleteEmailsModal = (emailId) => {
		const { toggleSingleDeleteEmails } = this.state;
		this.setState({ toggleSingleDeleteEmails: !toggleSingleDeleteEmails, selectedRow: emailId });
	};

	deleteSingleEmails = (emailId) => {
		this.props.deleteEmails([emailId], () => {
			this.setState({ toggleSingleDeleteEmails: false });
		});
	};

	toggleAddEmail = (cb) => {
		batchActionCB = cb;
		this.setState({ addEmailModalOpen: !this.state.addEmailModalOpen });
	};

	addEmail = (email, callback) => {

		this.props.addEmail(email, () => {
			batchActionCB && batchActionCB('update');
			callback && callback();
		});
		this.toggleAddEmail();
	}
	toggleRenameModal = (emailId) => {
		const { toggleRename } = this.state;
		this.setState({ toggleRename: !toggleRename, selectedRow: emailId });
	};
	toggleDuplicateEmailModal = (emailId) => {
		const { toggleDuplicateEmailModal } = this.state;
		this.setState({ toggleDuplicateEmailModal: !toggleDuplicateEmailModal, selectedRow: emailId })
	}
	duplicateEmail = (emailId, name, callback) => {

		this.props.duplicateEmail(emailId, name, () => {
			batchActionCB && batchActionCB('update');
			callback();
		});
	}

	render() {
		const {
			selectedEmail,
			loading,
			deleteEmailsModalOpen,
			emailsViews,
			columnTypes,
			columns,
			selectedSection,
			checkedEmails,
			addEmailModalOpen,
			toggleRename,
			toggleSingleDeleteEmails,
			toggleDuplicateEmailModal
		} = this.state;
		const { emails, program, orgId, programId } = this.props;

		const drafts = emails.filter((e) => e.status == 'Draft');
		const scheduled = emails.filter((e) => e.status == 'Scheduled');
		const sent = emails.filter((e) => e.status == 'Sent');

		const checkedCount = Object.keys(checkedEmails).length;
		//const campaignList = selectedSection == 0 ? drafts : selectedSection == 1 ? scheduled : sent;
		const campaignList = emails;
		return (
			<Switch>
				<Route exact path={`/${orgId}/programs/${programId}/emails`}>
					<Fragment>
						<BasicViewContainer
							style={{ marginBottom: '-10px' }}
							editing={false}
							cancelEdit={() => {
								this.cancelEdit();
							}}
							rowSettings={[
								{
									label: <span><i className='las la-pen mr-10'></i>Rename Template</span>,
									onClick: this.toggleRenameModal,
								},
								{
									label: <span><i className='las la-copy mr-10'></i>Duplicate Template</span>,
									onClick: this.toggleDuplicateEmailModal
								},
								{
									label: <span><i className='las la-trash mr-10'></i>Delete Template</span>,
									onClick: (rowId) => this.toggleSingleDeleteEmailsModal(rowId),
								}
							]}
							saveEdit={() => {
								this.saveInfo();
							}}
							title={'Email Campaign Templates'}
							description="Text campaigns are used to send SMS messages to attendees."
							enableLastLink={true}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
							orgId={orgId}
							tabs={[
								/* {
								title: 'Drafts',
								count: campaignList.length
							}, */ ]}
							onTabsChange={(i) => {
								if (i === 0) {
									return drafts
								} else if (i === 1) {
									return scheduled
								} else {
									return sent
								}
							}}

							columns={columns}
							columnTypes={columnTypes}
							views={[]}
							entries={campaignList}
							deletedEntries={{}}
							readOnly={false}
							mainActions={[{ onClick: this.toggleAddEmail, label: 'Add Email Template ' }]}
							enableDownloadViews={true}
							type={'TextMessage'}
							referenceCollection={'Events'}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteEmails
								}
							]}
							referenceId={programId}
						/>



						{deleteEmailsModalOpen &&
							<DeleteEmailModal
								isOpen={deleteEmailsModalOpen}
								toggle={() => this.toggleDeleteEmails()}
								title={`Delete ${checkedCount > 1 ? 'Emails' : 'Email'}`}
								message={`You are about to delete ${checkedCount} ${checkedCount > 1
									? 'emails'
									: 'email'}, this is irreversible,
						would you like to continue?`}
								submit={() =>
									this.deleteCheckedEmails(Object.keys(checkedEmails), () => {
										this.setState({ checkedEmails: {}, deleteEmailsModalOpen: false });
									})}
							/>
						}

						{toggleSingleDeleteEmails &&
							<DeleteEmailModal
								isOpen={toggleSingleDeleteEmails}
								toggle={() => this.toggleSingleDeleteEmailsModal()}
								title={`Delete Email`}
								message={`You are about to delete this email. This action is irreversible. Would you like to continue?`}
								submit={() =>
									this.deleteSingleEmails(this.state.selectedRow, () => {
										this.setState({ toggleSingleDeleteEmails: false });
									})}
							/>
						}

						{addEmailModalOpen && <NewEmailModal
							addNewEmail={this.addEmail}
							isOpen={addEmailModalOpen}
							toggle={this.toggleAddEmail}
						/>}
						{
							toggleRename && (
								<TemplateRenameModal
									isOpen={toggleRename}
									emailTemplate={campaignList.find((e) => e._id == this.state.selectedRow)}
									orgId={orgId}
									toggle={this.toggleRenameModal}
									update={(content) => {
										this.props.saveEmail(content, () => {
											this.toggleRenameModal();
										});
									}}
								/>
							)
						}
						{
							toggleDuplicateEmailModal && <DuplicateEmailModal
								toggle={this.toggleDuplicateEmailModal}
								isOpen={toggleDuplicateEmailModal}
								name={campaignList.find((e) => e._id == this.state.selectedRow).name}
								emailId={campaignList.find((e) => e._id == this.state.selectedRow)._id}
								orgId={orgId}
								programId={program._id}
								duplicateEmail={this.duplicateEmail}
							/>
						}
					</Fragment>
				</Route>

				<Route path={`/${orgId}/programs/${programId}/emails/:emailId`}>
					<EventEmail
						program={program}
						programId={programId}
						orgId={orgId}
						index={selectedEmail}
						emails={emails}
						saveEmail={this.props.saveEmail}
						updateEmails={this.updateEmails}
						keyedMetadata={this.state.keyedMetadata}
						attendeeFields={this.props.attendeeFields}
						eventFields={this.props.eventFields}
						deleteSingleEmails={this.deleteSingleEmails}
						duplicateEmail={this.duplicateEmail}
					/>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(EventEmails);
