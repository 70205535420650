import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

class OverflowPopoverItem extends Component {
	render() {
		const {
			view,
			onClick,
			renameView,
			deleteView,
			duplicateView,
			makeMainView,
			activeView,
			viewIndex
		} = this.props;
		return (
			<div
				className="moreViewsPopoverItem flex-ac flex-sb"
				key={`view${view.name}${viewIndex}`}
				id={`view-${viewIndex}`}
				style={{ display: activeView == viewIndex ? 'none' : '' }}
			>
				<DropdownItem className="moreViewsPopoverItemTitle mr-10" onClick={onClick}>
					{view.name} <span className="count">({view.count || 0})</span>
				</DropdownItem>
				<UncontrolledDropdown direction="down" inNavbar className="flex-ac">
					<DropdownToggle className="columnToggle">
						<i className="las la-ellipsis-h" />
					</DropdownToggle>
					<DropdownMenu className="columnDropdown">
						<div className='ph-20'>
							<h5 className='mb-10'>View Settings</h5>

							<DropdownItem
								className={`moreViewsPopoverItemMenuItem ${view.isBase ? 'disabled' : ''}`}
								style={{ textTransform: 'capitalize' }}
							/* onClick={() => updateVisibility(viewIndex)} */
							>
								<i className="las la-eye mr-25" />{view.visibility ? view.visibility : 'Internal'}
							</DropdownItem>
						</div>
						<hr />
						<div className='ph-20'>
							<DropdownItem
								className={`moreViewsPopoverItemMenuItem ${view.isBase || view.isDefault
									? 'disabled'
									: ''}`}
								onClick={() => renameView(viewIndex - 1)}
							>
								<i className="las la-pen mr-25" /> Rename
							</DropdownItem>
							<DropdownItem
								className={`moreViewsPopoverItemMenuItem ${view.isBase ? 'disabled' : ''}`}
								onClick={() => duplicateView(viewIndex - 1)}
							>
								<i className="las la-copy mr-25" /> Duplicate
							</DropdownItem>
							<DropdownItem
								className={`moreViewsPopoverItemMenuItem ${view.isMain ? 'disabled' : ''}`}
								onClick={() => makeMainView(view)}
							>
								<i className="las la-home mr-25" /> Set as default view
							</DropdownItem>
						</div>
						<hr />
						<div className='ph-20'>
							<DropdownItem
								className={`moreViewsPopoverItemMenuItem red ${view.isBase || view.isDefault
									? 'disabled'
									: ''}`}
								onClick={() => deleteView(viewIndex - 1)}
							>
								<i className="las la-trash mr-25" /> Delete
							</DropdownItem>
						</div>
					</DropdownMenu>
				</UncontrolledDropdown>
			</div>
		);
	}
}

export default OverflowPopoverItem;
