import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import LoadingWizard from '../../../components/spinner/wizard';
import ReportsContainer from '../../../components/views/ReportsContainer';
import SectionFooter from '../../../components/views/SectionFooter';
import FieldModal from '../../../components/modals/fieldModal';
import InputField from '../../../components/inputs/inputField';
import TextAreaField from '../../../components/inputs/textAreaField';

class EventReport extends Component {
    constructor(props) {
        super(props);
        const reportId = props.match.params.report;
        const report = props.reports.find(r => r._id == reportId);
        this.state = {
            report,
            updatedReport: { ...report },
            eventId: props.eventId,
            loading: false,
            editDetailsOpen: false,
        };
    }

    updateReport = async (cb) => {
        const { orgId, eventId } = this.props;
        const { updatedReport } = this.state;
        const res = await API().patch(`Organizations/${orgId}/events/${eventId}/viewReports/${updatedReport._id}`, updatedReport);
        if (res.data) {
            this.setState({ report: res.data, updatedReport: { ...res.data } }, cb);
        }
    }

    updateReportShareList = async (shareList, cb) => {
        const { orgId, eventId } = this.props;
        const { updatedReport } = this.state;
        const res = await API().patch(`Organizations/${orgId}/events/${eventId}/viewReports/${updatedReport._id}`, { sharedWith: shareList });
        if (res.data) {
            this.setState({ report: res.data, updatedReport: { ...res.data } }, cb);
        }
    }

    pinView = async (viewId) => {
        const updatedReport = this.state.report;
        updatedReport.pins.push(viewId);
        this.setState({ updatedReport }, this.updateReport);
    }

    unpinView = async (viewId) => {
        const updatedReport = this.state.report;
        updatedReport.pins = updatedReport.pins.filter(p => p !== viewId);
        this.setState({ updatedReport }, this.updateReport);
    }

    toggleEditDetails = () => {
        this.setState({ editDetailsOpen: !this.state.editDetailsOpen });
    }

    render() {
        const {
            report,
            updatedReport,
            eventId,
            loading,
            editing,
            editDetailsOpen
        } = this.state;

        const { event, orgId, favoriteReport } = this.props;

        return (
            <Fragment>
                {!loading && <div className='sectionContainer'>
                    <ReportsContainer
                        key={report._id}
                        report={report}
                        orgId={orgId}
                        toggleEditDetails={this.toggleEditDetails}
                        entries={{
                            Attendee: event.attendees,
                            Session: event.sessions,
                            Group: event.groups,
                            Email: event.emails,
                            Text: event.texts,
                            Form: event.forms,
                            Page: event.pages,
                        }}
                        title={report.name}

                        breadcrumbs={[
                            {
                                name: event.name
                            },
                            {
                                name: 'Reports'
                            }
                        ]}
                        //description={report.description}
                        readOnly={false}
                        mainActions={[]}
                        batchActions={[]}
                        enableDownloadViews={false}
                        referenceCollection='events'
                        referenceId={eventId}
                        eventId={eventId}
                        pinView={this.pinView}
                        unpinView={this.unpinView}
                        favoriteReport={favoriteReport}
                        updateReportShareList={this.updateReportShareList}
                    />
                    <SectionFooter updatedAt={report.updatedAt} cancelEdit={this.cancelEdit}
                        saveEdit={this.saveInfo} editing={editing} />
                    {editDetailsOpen && <FieldModal isOpen={editDetailsOpen} toggle={this.toggleEditDetails} size="medium" modalTitle="Edit Details" bodyContent={() => {
                        return <div>
                            <InputField
                                label="Report Name"
                                required={true}
                                value={updatedReport.name}
                                onChange={(e) => this.setState({ updatedReport: { ...updatedReport, name: e.target.value } })}
                            />
                            <TextAreaField
                                label="Report Description"
                                required={true}
                                value={updatedReport.description}
                                onChange={(e) => this.setState({ updatedReport: { ...updatedReport, description: e.target.value } })}
                            />
                        </div>
                    }} actionButton={() => this.updateReport(this.toggleEditDetails)}
                        actionButtonDisabled={updatedReport.name == "" || updatedReport.description == ""} actionButtonLabel="Save" cancelButtonLabel="Cancel"
                    />}
                </div>}

                <LoadingWizard loading={loading} text="Loading Report" />

            </Fragment>
        );
    }
}

export default withRouter(EventReport);
