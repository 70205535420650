import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
class ColorField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayColorPicker: false
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if (!event.target.closest('.chrome-picker ')) {
			this.setState({ displayColorPicker: false });
		}
	};

	render() {
		const {
			onChange,
			label,
			errorTrigger,
			errorMessage,
			type,
			value,
			disabled,
			placeholder,
			inputIcon,
			clickIcon,
			inputMode,
			pattern,
			autoComplete,
			maxLength,
			onKeyUp,
			style,
			inputStyle,
			required,
			useHex,
			classes,
			useDefaultColorEvent
		} = this.props;
		return (
			<div key={label} className={`formFieldContainer color ${classes ? classes : ''}`} style={style}>
				<div className="formField">
					<div className="labelContainer">
						<div className="formFieldLabel">
							{label}
							{required ? '*' : ''}
						</div>
						{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
					</div>
					<div
						className={`formFieldInputContainer ${errorTrigger ? 'error' : ''}  ${inputIcon
							? 'hasIcon'
							: ''} ${clickIcon ? 'clickIcon' : ''}`}
					>
						{/* <input
							style={inputStyle}
							value={value}
							className="formFieldColorInput"
							type="color"
							placeholder={placeholder}
							disabled={disabled}
							onChange={onChange}
						/> */}
						<div style={{ position: 'relative' }}>
							<div
								className="colorPickerPreview"
								style={{
									background: value ? value : '#fff',
								}}
								onClick={() => {
									this.setState({ displayColorPicker: true });
								}}
							>
								{!value && <div className="line"></div>}
							</div>
							{this.state.displayColorPicker && (
								<div
									style={{
										position: 'absolute',
										zIndex: '5'
									}}
								>
									<ChromePicker
										disableAlpha={useHex}
										onChange={(color, event) => {
											if (useDefaultColorEvent) {
												onChange(
													color
												);
											} else {
												onChange(
													useHex ? color.hex : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
												);
											}

										}}
										color={value}
									/>
								</div>
							)}
						</div>

						<input
							style={inputStyle}
							value={value}
							className="formFieldInput"
							type="text"
							placeholder={placeholder}
							disabled={disabled}
							onChange={(e) => onChange(e.target.value)}
						/>
						{inputIcon && <div className="inputIcon">{inputIcon}</div>}
					</div>
				</div>
			</div>
		);
	}
}

export default ColorField;
