import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';
import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
class AddInventoryModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			category: '',
			vendor: '',
			quantity: '',
			trackingNumber: '',
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	addInventory = () => {
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!this.state[key] || this.state[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.setState({ saving: true });
			const item = {
				name: this.state.name,
				category: this.state.category,
				vendor: this.state.vendor,
				trackingNumber: this.state.trackingNumber
			};
			this.props.handleSubmit(item, this.props.toggle);
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, category, vendor, trackingNumber, submitting, invalid } = this.state;
		return (
			<div>


				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Inventory Item"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Item Name"
									required={true}
									errorTrigger={this.state.requiredFields.name}
									onChange={(e) => this.setState({ name: e.target.value })}
									value={name}
								/>
								<InputField
									label="Category"
									onChange={(e) => this.setState({ category: e.target.value })}
									value={category}
								/>
								<InputField
									label="Vendor"
									onChange={(e) => this.setState({ vendor: e.target.value })}
									value={vendor}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding Item...'}
					actionButton={() => this.addInventory()}
					actionButtonDisabled={submitting}
				></FieldModal>



			</div>

		);
	}
}

export default AddInventoryModal;
