import React from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button } from 'reactstrap';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import InputField from '../../../../components/inputs/inputField';

import ToggleField from '../../../../components/inputs/toggleField';
import TimeField from '../../../../components/inputs/timeField';
import SelectField from '../../../../components/inputs/selectField';

import FieldModal from '../../../../components/modals/fieldModal';

const virtualMeetingTypes = [
	{ label: 'Stream', value: 'stream' },
	{ label: 'Meeting', value: 'meeting' },
	{ label: 'In-Person', value: 'in-person' },
	{ label: 'Link', value: 'link' },
	{ label: 'Break', value: 'break' }
];
class AddSessionModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			subtitle: '',
			description: '',
			isAllDay: false,
			startTime: moment(this.props.eventStartDate).format('h:mm a'),
			endTime: '',
			isGeneral: false,
			submitting: false,
			invalid: false,
			virtualMeetingType: '',

			requiredFields: {
				name: false,
				virtualMeetingType: false
			}
		};
	}

	addSession = async (session) => {
		let valid = true;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			if (!session[key] || session[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			this.setState({ submitting: true });

			this.props.handleSubmit(session, this.props.toggle);
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
		}
	};

	resetAdd = (e, toggle) => {
		this.setState({
			name: '',
			subtitle: '',
			description: '',
			isAllDay: false,
			startTime: moment(this.props.eventStartDate).format('h:mm a'),
			endTime: '',
			isGeneral: false,
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			name,
			subtitle,
			description,
			date,
			isAllDay,
			startTime,
			endTime,
			isGeneral,
			submitting,
			requiredFields,
			virtualMeetingType,
		} = this.state;

		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add Session"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Session name"
									value={name}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={(e) => {
										this.setState({
											name: e.target.value
										});
									}}
								/>
								<ToggleField
									label="Is All Day"
									defaultChecked={isAllDay}
									icons={false}
									checked={this.state.isAllDay}
									onChange={(e) => {
										this.setState({ isAllDay: !this.state.isAllDay });
									}}
								/>
								{!isAllDay && (
									<div className="flex-sb w-100">
										<TimeField
											label="Start Time"
											type="time"
											value={startTime}
											onChange={(time) => {
												this.setState({
													startTime: time
												});
											}}
											classes="mr-10 w-100"
										/>

										<TimeField
											label="End Time"
											type="time"
											value={endTime}
											onChange={(time) => {
												this.setState({
													endTime: time
												});
											}}
											classes="ml-10 w-100"
										/>
									</div>
								)}
								<SelectField
									classes="w-100"
									label="Session Type"
									value={virtualMeetingTypes.find((vmt) => vmt.value == virtualMeetingType)}
									options={virtualMeetingTypes}
									required={true}
									errorTrigger={requiredFields.virtualMeetingType}
									onChange={(vmt) => this.setState({ virtualMeetingType: vmt.value })}
								/>

								<ToggleField
									label="Is General"
									defaultChecked={isGeneral}
									icons={false}
									checked={this.state.isGeneral}
									onChange={(e) => {
										this.setState({ isGeneral: !this.state.isGeneral });
									}}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding Session...'}
					actionButton={() => this.addSession({
						name,
						subtitle,
						description,
						date,
						startTime,
						endTime,
						isAllDay,
						isGeneral,
						virtualMeetingType
					})}
					actionButtonDisabled={submitting}

				></FieldModal>






			</div>

		);
	}
}

export default AddSessionModal;
