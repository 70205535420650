import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../utils/API';
import LoadingWizard from '../../../components/spinner/wizard';
import logo from '../../../assets/img/logos/simple events logo.png';
import InputField from '../../../components/inputs/inputField';
import SharedReportsContainer from '../../../components/views/SharedReportsContainer';
class EventReport extends Component {
    constructor(props) {
        super(props);
        const reportId = props.match.params.report;
        const eventId = props.match.params.event;
        const orgId = props.match.params.organization;
        let authentication = localStorage.getItem('authentication');
        authentication = authentication ? JSON.parse(authentication) : {};
        const valid = authentication.expiry && new Date(authentication.expiry) > Date.now();
        this.state = {
            report: {},
            event: {
                attendees: [],
                sessions: [],
                groups: [],
                emails: [],
                texts: [],
                forms: [],
                pages: [],
            },
            expired: !valid,
            encryptedCode: '',
            email: '',
            error: '',
            emailCode: '',
            codeError: '',
            reportId,
            orgId,
            eventId,
            loading: true,
            codeRequested: false,
        };
    }

    componentDidMount() {
        const { reportId, eventId, orgId, expired } = this.state;
        if(!expired) {
            this.loadReport();
        }
    }

    verifyEmail = async () => {
        const { email, reportId, orgId } = this.state;
        try {
            const res = await API().post(`Organizations/${orgId}/platformActions/reportAccess/${reportId}`, { requestorEmail: email });
            if (res.data) {
                this.setState({ codeRequested: true, error: '', encryptedCode: res.data.ec });
            }
        } catch (e) {
            this.setState({ error: 'Access Denied' });
        }
    }

    submitCode = async () => {
        const { emailCode } = this.state;
        try {
            const validCode = this.validateCode(emailCode);
            console.log("validCode", validCode)
            if(validCode) {
                localStorage.setItem('authentication', JSON.stringify({ expiry: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7) }));
                this.setState({ expired: false, error: '', codeError:'' }, this.loadReport);
            } else {
                this.setState({ codeError: 'Invalid Code', error: '' });
            }
        } catch (e) {
            console.log(e)
            this.setState({ error: 'Access Denied' });
        }
    }

    loadReport = async () => {
        const { reportId, eventId, orgId } = this.state;
        try {
            const res = await API().get(`Organizations/${orgId}/events/${eventId}/viewReports/${reportId}`);
            if (res.data) {
                const report = res.data.report;
                const event = res.data.event;
                this.setState({ report: report, event: event, loading: false });
            }
        } catch (e) {
            console.log(e);
        }
    }

    validateCode = (code) => {
        const key = this.state.reportId.toString();
        const encryptedCode = this.state.encryptedCode;
        let decrypted = [...encryptedCode].map((char, index) => {
            const shift = key.charCodeAt(index % key.length);
            return String.fromCharCode(char.charCodeAt(0) - shift);
        }).join('');
        return decrypted === code;
    }

    closeReport = () => {
        localStorage.removeItem('authentication');
        window.location.reload();
    }

    render() {
        const {
            report,
            event,
            eventId,
            orgId,
            loading,
            expired,
            codeRequested
        } = this.state;

        return (
            <Fragment>
                <div className='systemToolbar' >
                    <div className="flex jcsb aic ml-30">
                        <div className="navLogo">
                            <img src={logo} />
                        </div>
                        {!expired && <button onClick={this.closeReport} className='neu mr-30 prim'>Logout</button>}
                    </div>
                </div>
                {!loading && <div className='pageContainer' style={{height: 'calc(100% - 51px)'}}>
                    <SharedReportsContainer
                        key={report._id}
                        report={report}
                        orgId={orgId}
                        entries={{
                            Attendee: event.attendees,
                            Session: event.sessions,
                            Group: event.groups,
                            Email: event.emails,
                            Text: event.texts,
                            Form: event.forms,
                            Page: event.pages,
                        }}
                        title={report.name}
                        readOnly={false}
                        mainActions={[]}
                        batchActions={[]}
                        enableDownloadViews={false}
                        referenceCollection='events'
                        referenceId={eventId}
                        eventId={eventId}
                    />
                </div>}

                <LoadingWizard loading={loading && !expired} text="Loading Report" />
                {expired && <div className="flex column aic jcc h-100">
                    <div className='flex column inputCard'>

                        <h1 className='mb-10'>Event Report</h1>
                        <p className='mb-20'>{!codeRequested ? 'Please enter your email address to view this report.' : 'A code was sent to the email entered, enter it below to view this report.'}</p>
                        <InputField
                            label={'Email'}
                            type={'email'}
                            value={this.state.email}
                            classes={`mb-20 ${codeRequested ? 'gray' : ''}`}
                            disabled={codeRequested}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            placeholder={'Enter email'}
                            inputIcon={codeRequested ? <i className='la las-lock' /> : null}
                            errorTrigger={this.state.error !== ''}
                            errorMessage={this.state.error}
                        />
                        {codeRequested && <InputField
                            label={'Code'}
                            type={'text'}
                            value={this.state.emailCode}
                            classes="mb-20"
                            onChange={(e) => this.setState({ emailCode: e.target.value })}
                            placeholder={'Enter code recieved in email'}
                            errorTrigger={this.state.codeError !== ''}
                            errorMessage={this.state.codeError}
                        />}
                        <button className="ml-a neu prim" onClick={() => !codeRequested ? this.verifyEmail() : this.submitCode()}>
                            Continue
                        </button>
                    </div>
                </div>}
            </Fragment>
        );
    }
}

export default withRouter(EventReport);
