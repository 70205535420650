import React from 'react';
import moment from 'moment';

import API from '../../../../utils/API';
import Scheduler from '../../../../utils/Scheduler';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import TimeField from '../../../../components/inputs/timeField';

import FieldModal from '../../../../components/modals/fieldModal';

class ScheduleCloseFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            error: '',
            completed: false,
            date: '',
            time: '',
            isDateBefore: false,
            requiredFields: {
                date: false,
                time: false
            }
        };
    }

    componentDidMount() {
        const { email, orgId, eventId, event } = this.props;


    }

    handleDateChange = (val) => {
        this.setState({
            date: val
        });
    };
    handleTimeChange = (val) => {
        this.setState({
            time: val
        });
    };

    scheduleClose = async () => {
        const { date, time } = this.state;
        const { eventId, event, formId, orgId } = this.props;

        let requiredFields = this.state.requiredFields;

        let timezone = event.timezone;
        let valid = true;
        if (!date && date == '') { requiredFields.date = true; valid = false; } else { requiredFields.date = false; }
        if (!time && time == '') { requiredFields.time = true; valid = false; } else { requiredFields.time = false; }
        if (valid) {
            const currentTimeDate = new Date(moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a'));
            const selectedDate = new Date(`${date} ${time}`);

            if (selectedDate >= currentTimeDate) {
                this.setState({ isDateBefore: false })

                try {
                    await API().patch(`Organizations/${orgId}/events/${eventId}/forms/${formId}/close`, { closeAt: selectedDate })
                        .then((res) => {
                            this.setState({ submitting: false });
                            this.props.updateCloseStatus({ closeAt: selectedDate, closed: false }, this.props.toggle());
                        }
                        ).catch((error) => {
                            this.setState({ submitting: false });
                        });
                } catch (err) {
                    console.log(err);
                }

            } else {

                this.setState({ isDateBefore: true })
            }

        } else {
            this.setState({ requiredFields: requiredFields })

        }

    };

    unscheduleClose = async () => {
        const { eventId, event, formId, orgId } = this.props;

        try {
            await API().patch(`Organizations/${orgId}/events/${eventId}/forms/${formId}/close`, { closeAt: null })
                .then((res) => {
                    this.setState({ submitting: false });
                    this.props.updateCloseStatus({ closeAt: null, closed: false }, this.props.toggle);
                }
                ).catch((error) => {
                    this.setState({ submitting: false });
                });
        } catch (err) {
            console.log(err);
        }
    }


    render() {
        const { isOpen, toggle, event, isScheduled } = this.props;
        const {
            sending,
            completed,
            date,
            requiredFields,
            time, isDateBefore
        } = this.state;
        const fixedStartDate = moment(`${date}`).format('YYYY-MM-DD');
        const currentDateTimezone = moment().tz(event.timezone).format('YYYY-MM-DD');

        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={isScheduled ? 'Unschedule Close Form' : "Schedule Close Form"}
                    bodyHeaderText={
                        isScheduled ?
                            'Are you sure you want to remove the schedule close form time?' :
                            <div>Schedule your form to be closed at a specific time in the future. <br /><span>Current time: {moment().tz(event.timezone).format('MM/DD/YYYY hh:mm a')} {moment().tz(event.timezone).zoneAbbr()}</span></div>}

                    bodyContent={() => {
                        return (
                            < div>{
                                !isScheduled ?
                                    <div>
                                        < div style={{ display: 'flex', paddingTop: 10 }}>
                                            <div>
                                                <InputField
                                                    label={'Date'}
                                                    style={{ width: 150 }}
                                                    value={fixedStartDate}
                                                    required={true}
                                                    errorTrigger={requiredFields.date}
                                                    type={'date'}
                                                    inputIcon={<i className="lar la-calendar" />}
                                                    onChange={(e) => {
                                                        this.handleDateChange(moment(e.target.value).format('MM/DD/YYYY'));
                                                    }}
                                                    min={currentDateTimezone}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '0 10px'
                                                }}
                                            >
                                                at
                                            </div>
                                            <TimeField
                                                label={'Send Time'}
                                                style={{ width: 150 }}
                                                value={time}
                                                required={true}
                                                errorTrigger={requiredFields.time}
                                                onChange={(e) => {
                                                    this.handleTimeChange(e);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: '0 10px'
                                                }}
                                            >
                                                {moment().tz(event.timezone).zoneAbbr()}
                                            </div>
                                        </div>
                                        {
                                            isDateBefore && <div style={{ paddingTop: 5, color: '#b90000', height: 'unset' }}>
                                                Scheduled time must be after the current time at the event's timezone.
                                            </div>
                                        }
                                    </div > : <div>
                                        <p>Scheduled for {moment(isScheduled).tz(event.timezone).format('MM/DD/YYYY hh:mm a')} {event.timezone ? moment().tz(event.timezone).zoneAbbr() : ''}</p>
                                    </div>
                            }
                            </div>

                        )
                    }
                    }
                    toggle={toggle}
                    actionButtonLabel={!sending ? 'Submit' : completed ? 'Complete' : 'Sending...'}
                    actionButton={() => {
                        if (!isScheduled) {
                            this.scheduleClose();

                        } else {
                            this.unscheduleClose();

                        }
                    }}
                    actionButtonDisabled={sending || completed}
                    isFullWidthBody={false}
                    cancelButtonLabel={!completed ? 'Cancel' : 'Close'}


                ></FieldModal >
            </div >


        );
    }
}

export default ScheduleCloseFormModal;
