import React, { Component } from 'react';
import icons from '../email/icons'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
//src / components / email / icons.js

const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;
class IconChooser extends Component {
    render() {
        const {
            onChange,
            label,
            errorTrigger,
            errorMessage,
            value,
            style,
            required,
            classes,
            onClick,
            clearIcon
        } = this.props;
        return (
            <div key={label} className={`formFieldContainer color ${classes ? classes : ''}`} style={style}>
                <div className="formField">
                    <div className="labelContainer">
                        <div className="formFieldLabel">
                            {label}
                            {required ? '*' : ''}
                        </div>
                        {errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
                    </div>
                    <div className={`formFieldInputContainer ${errorTrigger ? 'error' : ''}`}>

                        <UncontrolledDropdown inNavbar>
                            <DropdownToggle className="columnToggle flex-ac">
                                {value ? <img src={`${ENV}API/icons/svg/${value}/black`} style={{ paddingLeft: 10, width: 30, height: 20 }} key={`icon-${value}`} onClick={() => {

                                    onClick(value)
                                }} /> : <i style={{ paddingLeft: 10 }} className="las la-icons" />
                                }
                            </DropdownToggle>
                            <DropdownMenu
                                left
                                className="columnDropdown"
                            >
                                <div style={{ paddingLeft: 20, minWidth: 200, maxHeight: 300, overflowX: 'hidden' }}>
                                    {icons.map((i, index) => {
                                        return <img src={`${ENV}API/icons/svg/${i.value}/black`} style={{ width: 20, height: 20 }} key={`icon-${index}`} onClick={() => {

                                            onClick(i.value)
                                        }} />
                                    })}
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {value && <div className="filePickerButton red ml-a" onClick={clearIcon}>
                            <i className="las la-times mr-5" /> Clear Icon
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default IconChooser;
