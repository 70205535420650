import React, { Component } from 'react';

import FieldModal from '../../../../components/modals/fieldModal';

class DeletePageModal extends Component {
	state = {
		title: '',
		description: '',
		template: '',
		submitting: false,
		privateHomePageSlug: '',
		publicHomePageSlug: '',
		registrationPagePathSlug: '',
		isPrivateSlug: false,
		isPublicSlug: false,
		isRegistrationSlug: false,
		filteredSelected: [],
		processing: true,
		isMeetingLoginPath: false
	};
	componentDidMount() {
		this.setState({ submitting: true });
		const { pages, checkedPages } = this.props;
		const { isPrivateHomePageSlug, isPublicHomePageSlug, isregistrationPagePathSlug } = this.state;

		let filteredSelected = pages.filter((p) => {
			let isChecked = Object.keys(checkedPages).includes(p._id);

			if (isChecked) {
				let isPrivateHomePageSlug = this.props.event.defaultUserPath === p.slug;
				let isPublicHomePageSlug = this.props.event.defaultPublicPath === p.slug;
				let isregistrationPagePathSlug = this.props.event.registrationPageSlug === p.slug;
				let isMeetingLoginPath = this.props.event.meetingLoginPath === p.slug;

				if (isPrivateHomePageSlug) {
					this.setState({ isPrivateSlug: isPrivateHomePageSlug });
				}
				if (isPublicHomePageSlug) {
					this.setState({ isPublicSlug: isPublicHomePageSlug });
				}
				if (isregistrationPagePathSlug) {
					this.setState({ isRegistrationSlug: isregistrationPagePathSlug });
				}
				if (isMeetingLoginPath) {
					this.setState({ isMeetingLoginPath: isMeetingLoginPath });
				}

				if (!isPrivateHomePageSlug && !isPublicHomePageSlug && !isregistrationPagePathSlug && !isMeetingLoginPath) {
					return p;
				}
			}
		});

		this.setState({ submitting: false, filteredSelected: filteredSelected, processing: false });
	}


	render() {
		const { isOpen, toggle, isDeleting, count, event } = this.props;
		const { isRegistrationSlug, processing, isPublicSlug, submitting, isPrivateSlug, isMeetingLoginPath } = this.state;
		const isPublic = event.eventType == "Public" ? true : false;

		return (
			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Page"}
					bodyHeaderText={isRegistrationSlug && isPublic ? <span>One of the pages selected is being used in{' '}
						<b>Setup -> Event Access -> Registration Page Path</b>. Please update to a different
						page to delete this page!</span> : isPublicSlug ? <span>One of the pages selected is being used in{' '}
							<b>Applications -> Setup -> Public Home Page</b>. Please update to a different page to
							delete this page!</span> : isPrivateSlug ? <span>One of the pages selected is being used in{' '}
								<b>Applications -> Setup -> Private Home Page</b>. Please update to a different page to
								delete this page!</span> : isMeetingLoginPath ? <span>One of the pages selected is being used in{' '}
									<b>Applications -> Setup -> Login Page</b>. Please update to a different page to
									delete this page!</span> : <span>You are about to delete{' '}
						<b>
							{count} {count ? 'pages' : 'page'}
						</b>, this is irreversible, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Delete...'}

					actionButton={() => this.props.delete()}
					actionButtonDisabled={isDeleting || isPrivateSlug || isPublicSlug || submitting || (isRegistrationSlug && isPublic) || isMeetingLoginPath}
					isDelete={true}


				></FieldModal>

			</div>

		);
	}
}

export default DeletePageModal;
