import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import LoadingWizard from '../../../components/spinner/wizard';
import EditFeedItem from './modals/editFeedItem';
import AddFeedItemModal from './modals/addFeedItem';
import { withRouter } from 'react-router-dom';
import DeleteFeedItemsModal from './modals/deleteFeedItems';
import ViewsTable from '../../../components/tables/ViewsTable';
import FileGrid from '../../../components/tables/FileGrid';
class EventGallery extends Component {
    constructor(props) {
        super(props);

        const columns = {
            name: 'Name',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
        };
        const columnTypes = {
            name: 'text',
            createdAt: 'date',
            updatedAt: 'date',
        };

        const toggledColumns = {};
        const normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });

        this.state = {
            columns: columns,
            columnTypes: columnTypes,
            normalizedColumns: normalizedColumns,
            toggledColumns: toggledColumns,
            checkedItems: {},
            feedItems: [],
            isGridLayout: true,
            loading: true,
            toggleEditFile: false,
            markedItem: null,
            toggleAddFeedItem: false,
            searching: false,
            searchText: '',
            selectedColumnSort: null,
            sortDesc: false,
            toggleDeleteFeedItems: false

        };
    }

    componentDidMount() {
        const { galleries } = this.props;
        const { gallery } = this.props.match.params;
        let selectedGallery = galleries.find((g) => g._id === gallery);
        this.props.setSelectedGallery(selectedGallery.feedName);
        let feedItems = selectedGallery.feedItems || [];
        this.setState({ selectedGallery, feedItems });

    }


    sortData = (column, sortAsc, sortDesc) => {
        const { normalizedColumns } = this.state;
        const updatedColumns = normalizedColumns.map((col) => {
            if (col.key === column.key) {
                col.sortAsc = sortAsc;
                col.sortDesc = sortDesc;
            } else {
                col.sortAsc = false;
                col.sortDesc = false;
            }
            return col;
        });

        this.setState({
            normalizedColumns: updatedColumns,
            selectedColumnSort: column,
            sortDesc: sortDesc ? true : false
        });
    };


    toggleLayout = (selected) => {
        this.setState({ isGridLayout: selected });
    };
    updatedCheckedItems = (checked) => {
        this.setState({ checkedItem: checked });
    };

    toggleEditFile = (fileId) => {
        this.setState({
            toggleEditFile: !this.state.toggleEditFile,
            markedItem: fileId ? fileId : null
        });
    }

    toggleAddFeedItem = () => {
        this.setState({ toggleAddFeedItem: !this.state.toggleAddFeedItem });
    }

    addFeedItem = (feedItem, callback) => {
        const { selectedGallery } = this.state;
        const feedItems = this.state.feedItems;
        feedItems.unshift(feedItem);

        this.props.updateFeedItems(selectedGallery._id, feedItems, () => {
            this.setState({ feedItems: feedItems }, () => {
                callback && callback();
            });
        });


    }

    updateFeedItem = (galleryId, properties, callback) => {
        const { feedItems } = this.state;
        let updatedFeedItems = feedItems;

        updatedFeedItems = updatedFeedItems.map((fi) => {
            if (fi._id === properties._id) {
                fi = { ...fi, ...properties };

            }
            return fi;
        }
        );

        this.setState({ feedItems: updatedFeedItems }, () => {
            this.props.updateFeedItem(galleryId, properties, callback);
        }
        );

    }

    removeFeedItems = (galleryId, feedItemIds, callback) => {
        const { feedItems } = this.state;
        let updatedFeedItems = feedItems.filter((fi) => !feedItemIds.includes(fi._id));
        this.setState({ feedItems: updatedFeedItems, checkedItems: {} }, () => {
            this.props.removeFeedItems(galleryId, feedItemIds, callback);
        });
    }

    searchEntries = (searchText) => {
        this.setState({ searching: searchText !== '' ? true : false, searchText: searchText ? searchText : '' });
    };
    toggleDeleteFeedItems = () => {

        this.setState({ toggleDeleteFeedItems: !this.state.toggleDeleteFeedItems });

    }

    toggleSingleDeleteFeedItem = (feedItemId) => {
        this.setState({ toggleDeleteFeedItems: true, markedItem: feedItemId });
    }


    render() {
        const {
            normalizedColumns,
            toggledColumns,
            isGridLayout,
            feedItems,
            toggleEditFile,
            markedItem,
            toggleAddFeedItem,
            searching,
            searchText,
            columns,
            selectedColumnSort,
            sortDesc,
            toggleDeleteFeedItems,
            checkedItems,
        } = this.state;
        const { loading } = this.props;
        let searchedEntries = feedItems;

        //Searching by text from input
        if (searchText.length > 0) {
            const searchTerms = searchText.toLowerCase().split(' ');
            searchTerms.forEach((term) => {
                if (term !== '') {
                    searchedEntries = searchedEntries.filter((entry) => {
                        let found = false;
                        Object.keys(columns).forEach((key) => {
                            if (term !== '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
                                found = true;
                            }
                        });
                        return found;
                    });
                }
            });
        }
        if (selectedColumnSort) {
            searchedEntries = searchedEntries.sort((a, b) => {
                const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
                if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
                    switch (columnType) {
                        case 'string':
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                        case 'number':
                            return a[selectedColumnSort.key] - b[selectedColumnSort.key];
                        case 'date':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'time':
                            return (
                                new Date(a[selectedColumnSort.key]).getTime() -
                                new Date(b[selectedColumnSort.key]).getTime()
                            );
                        case 'boolean':
                            const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
                            const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
                            return a1.localeCompare(b1);
                        default:
                            return a[selectedColumnSort.key]
                                .toLowerCase()
                                .trim()
                                .localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
                    }
                } else if (a[selectedColumnSort.key]) {
                    return 1;
                }
                return -1;
            });

            searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
        }

        return (

            <div className="sectionContainer" style={{ overflow: 'hidden' }}>
                {!loading ?
                    <div className="sectionContainer sectionBody posRel p-0">
                        <ActionButtonContainer
                            mainActions={[
                                {
                                    type: 'button',
                                    label: 'Share a Picture',
                                    onClick: this.toggleAddFeedItem
                                }
                            ]}
                            searchEnabled={true}
                            searchEntries={this.searchEntries}
                            enabledViewSelection={true}
                            isGridLayout={isGridLayout}
                            toggleLayout={this.toggleLayout}

                        />
                        {!isGridLayout ? <ViewsTable
                            title={"Feed Items"}
                            columns={normalizedColumns}
                            toggledColumns={toggledColumns}
                            isGridLayout={isGridLayout}
                            data={searchedEntries}
                            orgId={this.props.orgId}
                            mainColumnActive={false}
                            checked={this.state.checkedItems}
                            checkRow={(entryId) => {
                                const checked = this.state.checkedItems;
                                checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
                                this.updatedCheckedItems(checked);

                            }}
                            //mainActions={mainAction}
                            checkAllRows={(entryIds) => {
                                let checked = this.state.checkedItems;
                                entryIds.length > 0
                                    ? entryIds.forEach((entryId) => {
                                        checked[entryId] = true;
                                    })
                                    : (checked = {});
                                this.updatedCheckedItems(checked);


                            }}
                            sortData={this.sortData}
                            toggleEditFile={this.toggleEditFile}
                            toggleDeleteFile={this.toggleSingleDeleteFeedItem}
                            defaultSort={'name'}
                            sortDirection={'desc'}
                        /> : <FileGrid
                            title={"Feed Items"}
                            data={searchedEntries}
                            checked={this.state.checkedItems}
                            checkRow={(entryId) => {
                                const checked = this.state.checkedItems;
                                checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
                                this.updatedCheckedItems(checked);

                            }} />}



                    </div> :
                    <LoadingWizard
                        text="Loading Feed Items"
                        loading={loading}

                    />}
                <ActionButtonContainer
                    batchActions={
                        [
                            {
                                type: 'button',
                                label: 'Delete Feed Items',
                                onClick: this.toggleDeleteFeedItems,
                                iconClass: 'las la-trash-alt mr-10',
                                class: 'neu danger'
                            }
                        ]}
                    checkedEntryCount={Object.keys(checkedItems).length}
                    entriesLength={searchedEntries.length}
                    disableNueFooter={true}
                    uncheckEntries={() => {
                        this.setState({ checkedItems: {} });
                    }}
                />
                {toggleEditFile &&
                    <EditFeedItem
                        isOpen={toggleEditFile}
                        toggle={this.toggleEditFile}
                        eventId={this.props.eventId}
                        orgId={this.props.orgId}
                        markedItem={searchedEntries.find((entry) => entry._id == markedItem)}
                        selectedGallery={this.state.selectedGallery}
                        updateFeedItem={this.updateFeedItem}
                    />
                }

                {toggleAddFeedItem &&
                    <AddFeedItemModal
                        isOpen={toggleAddFeedItem}
                        toggle={this.toggleAddFeedItem}
                        eventId={this.props.eventId}
                        orgId={this.props.orgId}
                        attendees={this.props.attendees}
                        selectedGallery={this.state.selectedGallery}
                        addFeedItem={this.addFeedItem}
                    />
                }
                {toggleDeleteFeedItems &&
                    <DeleteFeedItemsModal
                        isOpen={toggleDeleteFeedItems}
                        toggle={this.toggleDeleteFeedItems}
                        eventId={this.props.eventId}
                        orgId={this.props.orgId}
                        checkedItems={Object.keys(checkedItems)}
                        selectedGallery={this.state.selectedGallery}
                        removeFeedItems={this.removeFeedItems}
                        markedItem={this.state.markedItem}
                    />
                }

            </div>


        );
    }
}

export default withRouter(EventGallery);
