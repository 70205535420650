/* eslint-disable no-loop-func */
import React, { Component } from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';

import Dropzone from 'react-dropzone';
import papa from 'papaparse';
import moment from 'moment';
import SelectField from '../../../../components/inputs/selectField';
import CheckboxInput from '../../../../components/inputs/checkboxInput';
import LoadingWizard from '../../../../components/spinner/wizard';

const UNMAPPED_DATA = 'Unmapped Data';
const GROUP_DATA = 'Group Data';


class ImportAttendeesModal extends Component {
	constructor(props) {
		super(props);
		const unmappedData = {};
		unmappedData[UNMAPPED_DATA] = {};
		const groupData = {};
		groupData[GROUP_DATA] = {};
		const groupOptions = props.groups.map((g) => {
			return { label: g.name, value: g._id };
		});
		this.networkControllers = [];
		this.state = {
			files: [],
			accepted: [],
			rejected: [],
			overrideData: false,
			updateFNandLN: false,
			batchImport: false,
			customFields: [
				{
					title: 'General Information',
					_id: 0,
					fields: [
						{ fieldName: 'First Name', fieldType: 'text', _id: 'firstName' },
						{ fieldName: 'Last Name', fieldType: 'text', _id: 'lastName' },
						{ fieldName: 'Email', fieldType: 'text', _id: 'email' },
						{ fieldName: 'Phone Number', fieldType: 'text', _id: 'phoneNumber' },
						{ fieldName: 'Title', fieldType: 'text', _id: 'title' },
						{ fieldName: 'Company', fieldType: 'text', _id: 'company' },
						{ fieldName: 'Text Message Opt In', fieldType: 'checkbox', _id: 'textMessageOptIn' },
						{ fieldName: 'Dietary Preference', fieldType: 'text', _id: 'dietaryPreference' },
						{ fieldName: 'Food Allergies', fieldType: 'text', _id: 'foodAllergies' },
						{ fieldName: 'Emergency Contact Name', fieldType: 'text', _id: 'emergencyContactName' },
						{ fieldName: 'Emergency Contact Phone Number', fieldType: 'text', _id: 'emergencyContactPhoneNumber' },
						{ fieldName: 'Address', fieldType: 'text', _id: 'address' },
						{ fieldName: 'City', fieldType: 'text', _id: 'city' },
						{ fieldName: 'State', fieldType: 'text', _id: 'state' },
						{ fieldName: 'Zip', fieldType: 'text', _id: 'zip' },
						{ fieldName: 'Country', fieldType: 'text', _id: 'country' },
						{ fieldName: 'Profile Picture', fieldType: 'text', _id: 'profilePicture' },
						{ fieldName: 'BCC', fieldType: 'text', _id: 'bcc' },
						/* { fieldName: 'Status', fieldType: 'text', _id: 'status' },
						{ fieldName: 'Registered Date', fieldType: 'text', _id: 'registeredDate' } */
					]
				},
				{
					title: 'Rooming',
					_id: 1,
					fields: [
						{ fieldName: 'Rooming Arrival Date', fieldType: 'text', _id: 'arrivalDate' },
						{ fieldName: 'Rooming Departure Date', fieldType: 'text', _id: 'departureDate' },
						{ fieldName: 'Rooming Arrival Time', fieldType: 'text', _id: 'arrivalTime' },
						{ fieldName: 'Rooming Departure Time', fieldType: 'text', _id: 'departureTime' },
						{ fieldName: 'Rooming Check-In Date', fieldType: 'text', _id: 'checkInDate' },
						{ fieldName: 'Rooming Check-Out Date', fieldType: 'text', _id: 'checkOutDate' },
						{
							fieldName: 'Hotel Confirmation Number',
							fieldType: 'text',
							_id: 'hotelConfirmationNumber'
						},
						{ fieldName: 'Number of Days Stay', fieldType: 'text', _id: 'numberOfDaysStay' },
						{ fieldName: 'Room Type', fieldType: 'text', _id: 'roomType' },
						{ fieldName: 'Payment Type', fieldType: 'text', _id: 'paymentType' },
						{ fieldName: 'Notes', fieldType: 'text', _id: 'notes' },
					]
				},
				{
					title: 'Travel',
					_id: 2,
					fields: [
						{ fieldName: 'Arrival Carrier', fieldType: 'text', _id: 'arrivalCarrier' },
						{ fieldName: 'Arrival City', fieldType: 'text', _id: 'arrivalCity' },
						{ fieldName: 'Arrival State', fieldType: 'text', _id: 'arrivalState' },
						{ fieldName: 'Arrival Date', fieldType: 'text', _id: 'arrivalDate' },
						{ fieldName: 'Arrival Time', fieldType: 'text', _id: 'arrivalTime' },
						{
							fieldName: 'Arrival Class of Service',
							fieldType: 'text',
							_id: 'arrivalClassOfService'
						},
						{ fieldName: 'Arrival Flight', fieldType: 'text', _id: 'arrivalFlight' },
						{ fieldName: 'Arrival Locator', fieldType: 'text', _id: 'arrivalLocator' },
						{ fieldName: 'Arrival Ticketed', fieldType: 'text', _id: 'arrivalTicketed' },
						{ fieldName: 'Departure Carrier', fieldType: 'text', _id: 'departureCarrier' },
						{ fieldName: 'Departure City', fieldType: 'text', _id: 'departureCity' },
						{ fieldName: 'Departure State', fieldType: 'text', _id: 'departureState' },
						{
							fieldName: 'Departure Class of Service',
							fieldType: 'text',
							_id: 'departureClassOfService'
						},
						{ fieldName: 'Departure Flight', fieldType: 'text', _id: 'departureFlight' },
						{ fieldName: 'Departure Locator', fieldType: 'text', _id: 'departureLocator' },
						{ fieldName: 'Departure Ticketed', fieldType: 'text', _id: 'departureTicketed' },
						{ fieldName: 'Destination City', fieldType: 'text', _id: 'destinationCity' },
						{ fieldName: 'Destination State', fieldType: 'text', _id: 'destinationState' },
						{ fieldName: 'Departure Date', fieldType: 'text', _id: 'departureDate' },
						{ fieldName: 'Departure Time', fieldType: 'text', _id: 'departureTime' },

						{ fieldName: 'Origin City', fieldType: 'text', _id: 'originCity' },
						{ fieldName: 'Origin State', fieldType: 'text', _id: 'originState' }
					]
				},

				...this.props.customFields
			],
			submitting: false,
			invalid: false,
			mapping: false,
			mappedData: {
				Category: {
					Header: ['data1']
				}
			},
			attendeeGroups: {},
			groupOptions: groupOptions,
			groupData: groupData,
			unmappedData: unmappedData,
			fieldOptions: [],
			importedAttendees: [],
			finished: false,
			importCount: 0,
			importLength: 0,
			importedGroups: [],
			importingGroups: false,
			isDuplicate: false,
			duplicateEmail: [],
			isInvalidEmail: false,
			invalidEmails: [],
			invalidPhones: [],
			emailExists: false,
			firstNameExists: false,
			checked: [],
			filteredAttendees: [],
			handledDuplicates: false,
			importedStarted: false,
			dupSelectedCount: 0,
			importError: false,
			errorCount: 0,
			currentSection: 0,
			sectionCount: 4,
			isError: false,
			cancelled: false,
			salesforceIntegration: props.event.integrations && props.event.integrations.salesforce && props.event.integrations.salesforce.campaignId ? true : false,
			submitToSalesforce: false,
			isInvalidPhoneNumber: false,
			invalidPhoneMessage: '',
			phoneValidationCompleted: false,
			validating: false,
			phoneValidationStarted: false,
			phoneNumberCurrentCount: 0,
			phoneNumberTotalCount: null
		};
	}


	ValidateEmail(mail) {
		var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (mail.match(mailformat)) {
			return true;
		} else {
			return false;
		}
	}

	downloadCSVTemplate = () => {
		const mappedCategories = [];
		const mappedFieldNames = [];
		const mappedFieldTypes = [];
		this.state.customFields.forEach((category) => {
			category.fields.forEach((field) => {
				const fieldName = field.fieldName;
				mappedCategories.push(category.title);
				mappedFieldTypes.push(field.fieldType);
				mappedFieldNames.push(fieldName);
			});
		});
		const csv = papa.unparse([mappedFieldNames]);
		const filename = `AttendeeImportTemplate.csv`;
		var file = new Blob([csv], { type: 'text/csv' });
		if (
			window.navigator.msSaveOrOpenBlob // IE10+
		)
			window.navigator.msSaveOrOpenBlob(file, filename);
		else {
			// Others
			var a = document.createElement('a'),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	};

	handleImport = () => {
		const { mappedData, groupData } = this.state;
		const attendees = [];
		Object.keys(mappedData).forEach((category) => {
			if (mappedData[category]._id !== 0 && mappedData[category]._id !== 1 && mappedData[category]._id !== 2) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];
						data.forEach((attendeeData, index) => {
							if (index > 0) {
								if (!attendees[index]) {
									attendees[index] = {
										metadata: []
									};
								} else if (!attendees[index].metadata) {
									attendees[index] = {
										...attendees[index],
										metadata: []
									};
								}
								attendees[index].metadata.push({
									categoryId: mappedData[category]._id,
									fieldId: data[0].value,
									fieldValue: attendeeData
								});
							}
						});
					}
				});
			} else if (mappedData[category]._id === 0) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];
						data.forEach((attendeeData, index) => {
							if (index > 0) {
								if (!attendees[index]) {
									attendees[index] = { metadata: [], roomingInformation: {}, travelInformation: {} };
								}

								attendees[index][data[0].value] = attendeeData;
								attendees[index]['rowNumber'] = index + 1;
							}
						});
					}
				});
			} else if (mappedData[category]._id === 1) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];

						data.forEach((attendeeData, index) => {
							if (index > 0) {
								if (!attendees[index]) {
									attendees[index] = {
										roomingInformation: {}
									};
								} else if (!attendees[index].roomingInformation) {
									attendees[index] = {
										...attendees[index],
										roomingInformation: {}
									};
								}
								attendees[index].roomingInformation[data[0].value] = attendeeData;
							}
						});
					}
				});
			} else if (mappedData[category]._id === 2) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];

						data.forEach((attendeeData, index) => {
							if (index > 0) {
								if (!attendees[index]) {
									attendees[index] = {
										travelInformation: {}
									};
								} else if (!attendees[index].travelInformation) {
									attendees[index] = {
										...attendees[index],
										travelInformation: {}
									};
								}
								attendees[index].travelInformation[data[0].value] = attendeeData;
							}
						});
					}
				});
			}
		});

		Object.keys(groupData[GROUP_DATA]).forEach((group) => {
			const groupColumn = groupData[GROUP_DATA][group];
			groupColumn.forEach((attendeeInclusion, index) => {
				if (index > 0) {
					if (attendeeInclusion !== '') {
						if (attendees[index].groups) {
							attendees[index].groups.push(group);
						} else attendees[index].groups = [group];
					}
				}
			});
		});
		const filteredAttendees = attendees.filter((attendee) => attendee.email && attendee.email !== '');

		this.setState(
			{
				submitting: true,
				mapping: false,
				importLength: filteredAttendees.length,
				filteredAttendees: filteredAttendees
			},
			() => {
				this.validateData();
			}
		);
	};

	batchImport = (attendees) => {
		const { eventId, orgId } = this.props;
		this.setState({ importedStarted: true, submitting: true });
		API()
			.post(`Organizations/${orgId}/events/${eventId}/batchImportAttendees`, {
				attendees
			})
			.then((res) => {
				if (res.data) {
					this.setState({ finished: true, submitting: false });
				}
			})
			.catch((e) => { });
	};

	importAttendees = (attendees) => {
		try {
			this.importAttendee(attendees[0], attendees, 0, 0);
		} catch (ex) {
			console.log(ex);
			this.setState({
				invalid: true,
				submitting: false
			});
		}
	};

	importAttendeeGroups = async () => {
		const { cancelled } = this.state;
		const attendeeGroups = Object.keys(this.state.groupData[GROUP_DATA]);
		for (let g = 0; g < attendeeGroups.length; g++) {
			if (cancelled) break;
			let group = attendeeGroups[g];
			const groupedAttendees = this.state.attendeeGroups[group] || [];
			let groupOption = this.state.groupOptions.find((gp) => gp.label.toLowerCase() === group.toLowerCase());
			if (group.includes('New Group:')) group = group.substring(group.indexOf('New Group: '));
			if (!groupOption) {
				groupOption = { label: group, value: '' };
			}
			await this.props.groupAttendees(groupOption, groupedAttendees, (added) => {
				const importedGroups = this.state.importedGroups;

				if (added._id) {
					groupOption.value = added._id;
					this.state.groupOptions.push(groupOption);
					importedGroups.push({ name: group, added: added.attendees.length });
				} else {
					if (importedGroups.find((g) => g.name.toLowerCase() === group.toLowerCase())) {
						importedGroups.forEach((g) => {
							if (g.name.toLowerCase() === group.toLowerCase()) {
								g.added = added;
							}
						});
					} else {
						importedGroups.push({ name: group, added: added });
					}
				}

				this.setState({ importedGroups: importedGroups });
			});
		}
		this.setState({ finished: true });
	};

	importAttendee = async (attendee, attendees, index, count) => {
		const { importedAttendees } = this.state;
		if (attendee.metadata && attendee.metadata.length === 0) {
			delete attendee.metadata;
		}
		/* if (attendee.roomingInformation && Object.keys(attendee.roomingInformation).length === 0) {
			delete attendee.roomingInformation;
		}

		if (attendee.travelInformation && Object.keys(attendee.travelInformation.length) === 0) {
			delete attendee.travelInformation;
		} */
		if (attendee.groups && attendee.groups.length === 0) {
			delete attendee.groups;
		}

		if (attendee.bcc) {
			attendee.bcc = attendee.bcc.split(',').map((bcc) => bcc.trim());
		}

		if (this.state.submitting) {
			const { eventId, orgId } = this.props;

			this.setState({ importedStarted: true });
			API()
				.post(`Organizations/${orgId}/events/${eventId}/attendee`, {
					...attendee,
					overrideData: this.state.overrideData,
					updateFNandLN: this.state.updateFNandLN,
					submitToSalesforce: this.state.submitToSalesforce
				})
				.then((res) => {
					const attendeeGroups = this.state.attendeeGroups;
					if (res.data) {
						//this references the attending because the schema wont let me return a filed "overriden", this field is only changed in this response
						if (!res.data.attending) {
							//adding new Attendee object
							this.props.addAttendee(res.data);
						} else {
							console.log('OVERRIDDEN', res.data);
						}
						attendee.groups &&
							attendee.groups.forEach((g) => {
								if (attendeeGroups[g]) {
									attendeeGroups[g].push(res.data._id);
								} else attendeeGroups[g] = [res.data._id];
							});
						res.data.success = true;
						importedAttendees.push(res.data);
						this.setState({
							importCount: this.state.importCount + 1,
							importedAttendees: importedAttendees,
							attendeeGroups: attendeeGroups
						});
					}
					count++;
					if (count === attendees.length) {
						if (Object.keys(attendeeGroups).length > 0) {
							this.importAttendeeGroups();
						} else this.setState({ finished: true });
					} else {
						index++;
						this.importAttendee(attendees[index], attendees, index, count);
					}
				})
				.catch((e) => {
					console.log(e);
					count++;
					attendee.success = false;
					const attendeeGroups = this.state.attendeeGroups;
					attendee.error =
						e.response &&
						(e.response.data.message
							? e.response.data.message
							: e.response.data.email ? 'Attendee record exists' : e.response.data);
					importedAttendees.push(attendee);
					if (e.response && e.response.data._id) {
						attendee.groups &&
							attendee.groups.forEach((g) => {
								if (attendeeGroups[g]) {
									attendeeGroups[g].push(e.response.data._id);
								} else attendeeGroups[g] = [e.response.data._id];
							});
					}
					this.setState({ importedAttendees: importedAttendees, attendeeGroups: attendeeGroups });
					if (count === attendees.length) {
						if (Object.keys(attendeeGroups).length > 0) {
							this.importAttendeeGroups();
						} else this.setState({ finished: true });
					} else {
						index++;
						this.importAttendee(attendees[index], attendees, index, count);
					}
				});
		}
	};
	cleanPhoneNumber(phoneNumber) {
		return phoneNumber.replace(/(?!^\+)[^0-9]/g, '').replace(/^1(\d+)$/, '$1');
	}

	uploadAttendees = async (attendees) => {
		const { eventId, orgId } = this.props;
		this.setState({ importedStarted: true, submitting: true });
		let count = 0;
		const chunkSize = 100;
		const attendeeChunks = [];
		const chunkCount = Math.ceil(attendees.length / chunkSize);

		for (let i = 0; i < chunkCount; i++) {
			attendeeChunks.push(attendees.slice(i * chunkSize, (i + 1) * chunkSize));
		}

		for (let i = 0; i < chunkCount; i++) {
			await new Promise((resolve, reject) => {
				count = 0;
				let currentAttendeeList = attendeeChunks[i];

				for (let index = 0; index < currentAttendeeList.length; index++) {
					if (this.state.cancelled) break;
					const attendee = currentAttendeeList[index];
					if (attendee.textMessageOptIn) {
						attendee.textMessageOptIn = attendee.textMessageOptIn.toLowerCase() === 'yes' ? true : false;
					}
					if (attendee.metadata && attendee.metadata.length === 0) {
						delete attendee.metadata;
					}
					if (attendee.groups && attendee.groups.length === 0) {
						delete attendee.groups;
					}
					if (attendee.phoneNumber) {
						attendee.phoneNumber = this.cleanPhoneNumber(attendee.phoneNumber);
					}

					if (attendee.bcc) {
						attendee.bcc = attendee.bcc.split(',').map((bcc) => bcc.trim())
					}

					const networkController = new AbortController();
					this.networkControllers.push(networkController);
					API()
						.post(`Organizations/${orgId}/events/${eventId}/attendee`, {
							...attendee,
							overrideData: this.state.overrideData,
							updateFNandLN: this.state.updateFNandLN,
							submitToSalesforce: this.state.submitToSalesforce
						}, { signal: networkController.signal })
						.then((res) => {
							const { attendeeGroups, importedAttendees } = this.state;
							if (res.data) {
								attendee.groups &&
									attendee.groups.forEach((g) => {
										if (attendeeGroups[g]) {
											attendeeGroups[g].push(res.data._id);
										} else attendeeGroups[g] = [res.data._id];
									});
								res.data.success = true;
								importedAttendees.push(res.data);
								this.setState({
									importCount: this.state.importCount + 1,
									importedAttendees: importedAttendees,
									attendeeGroups: attendeeGroups
								});
							}
							count++;
							if (count === currentAttendeeList.length) {
								resolve();
								this.props.importAttendees(importedAttendees);
								if (Object.keys(attendeeGroups).length > 0) {
									this.importAttendeeGroups();
								}// else this.setState({ finished: true });
							}
						})
						.catch((e) => {
							console.log(e);
							const { attendeeGroups, importedAttendees } = this.state;
							count++;
							attendee.success = false;
							attendee.error = e.message === "Canceled" ? e.message :
								e.response &&
								(e.response.data.message
									? e.response.data.message
									: e.response.data.email ? 'Attendee record exists' : e.response.data);
							importedAttendees.push(attendee);
							if (e.response && e.response.data._id) {
								attendee.groups &&
									attendee.groups.forEach((g) => {
										if (attendeeGroups[g]) {
											attendeeGroups[g].push(e.response.data._id);
										} else attendeeGroups[g] = [e.response.data._id];
									});
							}
							this.setState({ importedAttendees: importedAttendees, attendeeGroups: attendeeGroups, errorCount: this.state.errorCount + 1 });
							if (count === currentAttendeeList.length) {
								resolve();
								this.props.importAttendees(importedAttendees);
								if (Object.keys(attendeeGroups).length > 0) {
									if (!this.state.cancelled) {
										this.importSessionGroups();
									} //else this.setState({ finished: true });
								} //else this.setState({ finished: true });
							}
						});
				}
			}
			)

			if (i === chunkCount - 1) {
				this.setState({ finished: true });
			}
		}
	}


	validateData = async () => {
		const { mappedData } = this.state;
		let duplicateEmail = [];
		let isDuplicate = false;
		let isInvalidEmail = false;
		let firstNameExists = false;
		let emailExists = false;
		let isInvalidPhoneNumber = false;
		let valuesSoFar = [];
		let invalidEmails = [];
		let invalidPhones = [];
		let fa = this.state.filteredAttendees;
		this.setState({ validating: true });
		if (mappedData['General Information']) {
			// Assuming mappedData and phoneNumber assignment are correctly configured
			if (mappedData['General Information'] && mappedData['General Information']['Phone Number']) {
				let phoneNumber = mappedData['General Information']['Phone Number'];
				this.setState({ phoneValidationStarted: true, phoneNumberTotalCount: phoneNumber.length });
				for (let p = 0; p < phoneNumber.length; p++) {
					const phone = phoneNumber[p];
					if (phone.length >= 8) {
						let newPhone = this.cleanPhoneNumber(phone)
						const data = JSON.stringify({ phone: newPhone });
						await fetch("https://intl-tel-input-7366.twil.io/lookup", {
							method: "POST",
							headers: {
								'Content-Type': 'application/json'
							},
							body: data,
						})
							.then(response => response.json())  // Corrected to properly handle JSON response
							.then((json) => {
								if (!json.success) {
									isInvalidPhoneNumber = true;
									invalidPhones.push({ rowNumber: p + 1, phoneNumber: phone, error: json.error });
								}
							})
							.catch((error) => {
								console.error("Error with fetch operation:", error);
							});
					}
					this.setState({ phoneNumberCurrentCount: p + 1 });
				}
				this.setState({ phoneValidationCompleted: true });
				if (isInvalidPhoneNumber) {
					fa = this.state.filteredAttendees.filter(
						(at) =>
							!invalidPhones.find((ip) => ip.phoneNumber === at.phoneNumber)
					);
				}

			}



			if (mappedData['General Information']['Email'] && mappedData['General Information']['First Name']) {
				firstNameExists = true;
				emailExists = true;
				mappedData['General Information']['Email'].forEach((e, index) => {
					let firstName = mappedData['General Information']['First Name'][index];
					if (index !== 0) {
						let email = e ? e.toLowerCase() : '';
						if (this.ValidateEmail(email) || email === '') {
							if (valuesSoFar[email]) {
								if (duplicateEmail[email]) {
									duplicateEmail[email].push({
										rowNumber: index + 1,
										email: email,
										firstName: firstName
									});
								} else {
									duplicateEmail[email] = valuesSoFar[email];
									duplicateEmail[email].push({
										rowNumber: index + 1,
										email: email,
										firstName: firstName
									});
								}

								isDuplicate = true;
							} else {
								valuesSoFar[email] = [
									{ rowNumber: index + 1, email: email, firstName: firstName }
								];
							}
						} else {
							isInvalidEmail = true;
							invalidEmails.push({ rowNumber: index + 1, email: email, firstName: firstName });
						}
					}
				});


				if (isInvalidEmail) {
					fa = this.state.filteredAttendees.filter(
						(at) =>
							!invalidEmails.find((ie) => ie.email.toLowerCase() === at.email.toLowerCase())
					);
				}
			} else {
				if (!mappedData['General Information']['Email']) {
					emailExists = false;
				}
				if (!mappedData['General Information']['First Name']) {
					firstNameExists = false;
				}
			}

		}
		let isError = false;
		isError = !emailExists || !firstNameExists || isInvalidEmail || isDuplicate || isInvalidPhoneNumber;
		this.setState({
			emailExists: true,
			firstNameExists: true,
			isInvalidEmail: isInvalidEmail,
			invalidPhones: invalidPhones,
			invalidEmails: invalidEmails,
			filteredAttendees: fa,
			importLength: fa.length,
			isDuplicate: isDuplicate,
			duplicateEmail: duplicateEmail,
			submitting: false,
			validating: false,
			isError: isError
		}, () => {
			if (!isError) {
				this.state.batchImport
					? this.batchImport(this.state.filteredAttendees)
					: this.uploadAttendees(this.state.filteredAttendees);
			}
		});
	};

	isRequiredMapped = () => {
		const { mappedData } = this.state;
		if (mappedData['General Information']) {
			if (mappedData['General Information']['Email'] && mappedData['General Information']['First Name']) {
				this.setState({ emailExists: true, firstNameExists: true });
			} else {
				if (!mappedData['General Information']['Email']) {
					this.setState({ emailExists: false });
				}
				if (!mappedData['General Information']['First Name']) {
					this.setState({ firstNameExists: false });
				}
			}
		}
	};

	onDrop = (files) => {
		papa.parse(files[0], {
			complete: (res) => {
				try {
					/* Remove Trailing Whitespace */
					const trimmedData = res.data.map(subArray =>
						subArray.map(element => element.trim())
					);
					const data = this.mapData(trimmedData);
					const mappedData = data.mappedData;
					const unmappedData = data.unmappedData;
					const fieldData = this.state.customFields.map((category) => {
						const title = category.title;
						const headers = category.fields.map((header) => {
							const name = header.fieldName;
							return {
								_id: header._id,
								category: title,
								categoryId: category._id,
								value: name,
								label: name
							};
						});
						return {
							label: title,
							value: category._id,
							options: headers
						};
					});
					this.isRequiredMapped();
					/* ?? */
					if (!this.state.isDuplicate && !this.state.isInvalidEmail) {
						this.setState({
							mapping: true,
							mappedData: mappedData,
							unmappedData: unmappedData,
							fieldOptions: fieldData
						});
					}
				} catch (e) {
					console.log(e);
					this.setState({ importError: true });
				}
			}
		});
		this.setState({
			files
		});
	};

	mapData = (csvData) => {
		const { customFields } = this.state;
		const csvHeaders = csvData[0];
		const mappedData = {};
		let usedFields = [];
		customFields.forEach((category) => {
			const categoryTitle = category.title;
			mappedData[categoryTitle] = {};
			mappedData[categoryTitle]._id = category._id;
			category.fields.forEach((field) => {
				let fieldName = field.fieldName;
				if (!usedFields.includes(fieldName)) {
					mappedData[categoryTitle][fieldName] = [{ label: fieldName, value: field._id }];

					let count = -1;
					const headerIndex = csvHeaders.findIndex((csvHeader) => {
						count++;
						if (csvHeader.toLowerCase() === fieldName.toLowerCase()) {
							//fieldName = csvHeader;
							if (!usedFields.find((o) => o.fieldName === fieldName && o.index === count)) {
								usedFields.push({ fieldName: fieldName, index: count });
								return true;
							}
						}
						return false;
					});
					if (headerIndex > -1) {
						mappedData[categoryTitle][fieldName][0].headerIndex = headerIndex;
						csvData.forEach((row, index) => {
							if (index > 0) {
								if (!mappedData[categoryTitle][fieldName][index]) {
									mappedData[categoryTitle][fieldName][index] = [];
								}
								let value = null;
								switch (field.fieldType) {
									case 'number':
										value = Number.parseFloat(row[headerIndex]);
										break;
									case 'date':
										value = row[headerIndex] ? moment(row[headerIndex]).format('YYYY-MM-DD') : '';
										break;
									case 'checkbox':
										value = row[headerIndex].toLowerCase() === 'yes' ? true : false;
										break;
									default:
										value = row[headerIndex];
								}
								mappedData[categoryTitle][fieldName][index] =
									typeof value == 'string' ? value.trim() : value;
							}
						});
					}
					if (mappedData[categoryTitle][fieldName].length < 2) {
						delete mappedData[categoryTitle][fieldName];
					}
				}
			});
			if (Object.keys(mappedData[categoryTitle]).length < 2) {
				delete mappedData[categoryTitle];
			}
		});

		const unmappedData = {};
		unmappedData[UNMAPPED_DATA] = {};
		csvHeaders.forEach((header, headerIndex) => {
			if (!usedFields.find((f) => f.fieldName === header && f.index === headerIndex)) {
				unmappedData[UNMAPPED_DATA][header.concat('-', headerIndex.toString())] = [
					{
						label: header,
						value: header,
						headerIndex: headerIndex
					}
				];
				csvData.forEach((row, index) => {
					if (index > 0) {
						unmappedData[UNMAPPED_DATA][header.concat('-', headerIndex.toString())][index] =
							row[headerIndex];
					}
				});
			}
		});
		if (mappedData['General Information']) {
			this.setState({
				emailExists: Object.keys(mappedData['General Information']).includes('Email'),
				firstNameExists: Object.keys(mappedData['General Information']).includes('First Name')
			});
		} else {
			this.setState({ emailExists: false, firstNameExists: false });
		}

		return { mappedData, unmappedData };
	};

	formatGroupLabel = (data) => (
		<div style={groupStyles}>
			<span>{data.label}</span>
			<span style={groupBadgeStyles}>{data.options.length}</span>
		</div>
	);

	FieldContainer = (category, header, columnData, index, size, isUnmapped) => {
		const selectedOption = {
			value: header,
			label: header
		};
		const selectedGroup =
			category === GROUP_DATA &&
			this.state.groupOptions.find((g) => g.label.toLowerCase() === header.toLowerCase());
		const groupOptions = JSON.parse(JSON.stringify(this.state.groupOptions));
		if (category === GROUP_DATA && !selectedGroup) {
			groupOptions.push({ label: `New Group: ${header}`, value: header });
		}
		const dataPreview = JSON.parse(JSON.stringify(columnData));
		let data = '';
		for (let dataCount = 1; dataCount < dataPreview.length; dataCount++) {
			let current = dataPreview[dataCount];
			if (current) {
				data = current;
				break;
			}
		}
		return (
			<div className="w-100 mb-20" key={index}>
				<div className='flex aic jcsb'>
					<div className='flex w-100'>

						{category === UNMAPPED_DATA ? (
							<div className='mr-5'>
								<button
									id={`groupby-${index}`}
									style={{ padding: 0 }}
									onClick={(e) => {
										const unmappedData = this.state.unmappedData;
										const groupData = this.state.groupData;

										columnData &&
											columnData.length > 0 &&
											columnData.forEach((cd, index) => {
												if (index > 0) {
													if (!groupData[GROUP_DATA][cd]) {
														groupData[GROUP_DATA][cd] = [{ label: cd }];
														groupData[GROUP_DATA][cd][index] = cd;
													}
													groupData[GROUP_DATA][cd][index] = cd;
												}
											});
										this.setState({ groupData: groupData, unmappedData: unmappedData });
									}}
								>
									<i className="las la-users" />
									{/* Object.keys(this.state.groupData['Group Data']).includes(columnData[0].value) ? (
									'Add to Group'
								) : 'Create Group from column' */}
									<UncontrolledTooltip target={`groupby-${index}`} placement="top">Create group from values</UncontrolledTooltip>
								</button>
							</div>
						) :
							<div className='' style={{ width: 21 }}>
							</div>
						}

						<div
							className='w-100'
							style={{
								maxWidth: 283,
								minWidth: 283,
								width: 283,
								border: isUnmapped ? `1px solid var(--danger)` : `1px solid var(--border)`,
								borderRadius: 5,
								padding: '3px 17px',
								height: 40
							}}>
							<p>{columnData[0].label}</p>
							<p style={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis'
							}}
								className='small grey'>Ex: {data ? data : 'Blank column'}</p>
						</div>

					</div>
					<i className="las la-arrow-right mr-5 ml-5"
						style={{
							color: category !== UNMAPPED_DATA && category !== GROUP_DATA ? 'var(--primary)' : '',
						}}
					/>
					<div className="w-100 mb-a">
						{category === GROUP_DATA ? (
							<SelectField
								label=""
								classes="w-100 mr-10 mb-0"
								options={groupOptions}
								value={
									selectedGroup ? selectedGroup : { label: `New Group: ${header}`, value: header }
								}
								onChange={(option) => {
									const groupData = this.state.groupData;
									delete groupData[GROUP_DATA][header];
									groupData[GROUP_DATA][option.label] = columnData;
									this.setState({ groupData: groupData });
								}}
								additionalSelectElement={
									category === GROUP_DATA ? (

										<div
											className='c-pointer'
											style={{
												position: 'absolute',
												zIndex: 999,
												right: 30,
												top: 10
											}}
											onClick={() => {
												const groupData = this.state.groupData;
												const unmappedData = this.state.unmappedData;

												delete groupData[GROUP_DATA][header];
												this.setState({ groupData: groupData, unmappedData: unmappedData });

											}}
										>
											<i class="lar la-times-circle grey"></i>
										</div>
									) : null
								}
							/>
						) : (
							<SelectField
								label=""
								style={{
									maxWidth: 283,
									minWidth: 283,
									width: 283
								}}
								classes=" neu mb-0 "
								value={category !== UNMAPPED_DATA && selectedOption}
								options={this.state.fieldOptions}
								formatGroupLabel={this.formatGroupLabel}
								onChange={(data) => {
									if (category !== UNMAPPED_DATA) {
										const mappedData = this.state.mappedData;
										columnData[0] = { label: data.label, value: data._id };
										if (!mappedData[data.category]) {
											mappedData[data.category] = [];
											mappedData[data.category][data.label] = columnData;
											delete mappedData[category][header];
										} else if (!mappedData[data.category][data.label]) {
											mappedData[data.category][data.label] = columnData;
											delete mappedData[category][header];
										}
										this.setState({ mappedData: mappedData }, () => this.isRequiredMapped());
									} else {
										const mappedData = this.state.mappedData;
										const unmappedData = this.state.unmappedData;
										columnData[0] = { label: columnData[0].label, value: data._id };
										if (!mappedData[data.category]) {
											mappedData[data.category] = {};
											mappedData[data.category]._id = data.categoryId;
											mappedData[data.category][data.label] = columnData;
											delete unmappedData[category][header];
										} else if (!mappedData[data.category][data.label]) {
											mappedData[data.category][data.label] = columnData;
											delete unmappedData[category][header];
										}

										this.setState({ mappedData: mappedData, unmappedData: unmappedData }, () =>
											this.isRequiredMapped()
										);
									}
								}}
								additionalSelectElement={
									category !== UNMAPPED_DATA || category === GROUP_DATA ? (
										<div
											className='c-pointer'
											style={{
												position: 'absolute',
												zIndex: 999,
												right: 30,
												top: 10
											}}
											onClick={() => {
												const mappedData = this.state.mappedData;
												const unmappedData = this.state.unmappedData;
												const propertyName = columnData[0].label.concat('-', columnData[0].headerIndex);
												if (!unmappedData[UNMAPPED_DATA]) {
													unmappedData[UNMAPPED_DATA] = [];
													delete mappedData[category][header];
													unmappedData[UNMAPPED_DATA][propertyName] = columnData;
												} else if (!unmappedData[UNMAPPED_DATA][propertyName]) {
													delete mappedData[category][header];
													unmappedData[UNMAPPED_DATA][propertyName] = columnData;
												} else if (unmappedData[UNMAPPED_DATA][propertyName]) {
													delete mappedData[category][header];
												}
												if (Object.keys(mappedData[category]).length < 2) {
													delete mappedData[category];
												}
												this.setState({ mappedData: mappedData, unmappedData: unmappedData }, () => {
													this.isRequiredMapped();
												});

											}}
										>
											<i class="lar la-times-circle grey"></i>
										</div>
									) : null
								}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	handleDuplicateSelections = () => {
		const { duplicateEmail, filteredAttendees, handledDuplicates } = this.state;

		if (handledDuplicates) {
			this.state.batchImport
				? this.batchImport(this.state.filteredAttendees)
				: this.uploadAttendees(this.state.filteredAttendees);
		} else {
			let updatedFilteredAttendees = filteredAttendees;
			let removeRows = [];
			Object.keys(duplicateEmail).forEach((email) => {
				if (duplicateEmail[email].find((r) => r.selected)) {
					duplicateEmail[email].forEach((r, index) => {
						if (!r.selected) {
							removeRows.push(r.rowNumber);
						}
					});
					this.setState({
						filteredAttendees: updatedFilteredAttendees
					});
					delete duplicateEmail[email];
				}
			});
			updatedFilteredAttendees = updatedFilteredAttendees.filter((a) => !removeRows.includes(a.rowNumber));
			this.setState(
				{
					duplicateEmail: duplicateEmail,
					importLength: this.state.filteredAttendees.length,
					filteredAttendees: updatedFilteredAttendees
				},
				() => {
					if (Object.keys(this.state.duplicateEmail).length === 0) {
						this.setState(
							{
								handledDuplicates: true,
								submitting: true,
								importLength: this.state.filteredAttendees.length,
								importingGroups: false,
								isDuplicate: false
							},
							() => {
								this.state.batchImport
									? this.batchImport(this.state.filteredAttendees)
									: this.uploadAttendees(this.state.filteredAttendees);
							}
						);
					}
				}
			);
		}
	};

	skipDuplicate = (email) => {
		const { duplicateEmail, filteredAttendees, dupSelectedCount } = this.state;

		if (duplicateEmail[email].find((row) => row.selected)) {
			this.setState({ dupSelectedCount: dupSelectedCount - 1 });
		}
		delete duplicateEmail[email];
		let fa = filteredAttendees.filter((row) => row.email.toLowerCase() !== email.toLowerCase())
		this.setState(
			{
				duplicateEmail: duplicateEmail,
				filteredAttendees: fa,
				importLength: fa.length
			},
			() => {
				Object.keys(this.state.duplicateEmail).length === 0 &&
					this.setState({ handledDuplicates: true, submitting: true, });
			}
		);
	};

	skipInvalidEmail = (email) => {
		const { invalidEmails } = this.state;
		const removed = invalidEmails.filter((e) => e.email !== email);
		this.setState({ invalidEmails: removed, });
	}

	skipInvalidPhone = (phoneNumber) => {
		const { invalidPhones } = this.state;
		const removed = invalidPhones.filter((p) => p.phoneNumber !== phoneNumber);
		this.setState({ invalidPhones: removed });
	}

	cancelImport = async () => {
		this.setState({ cancelled: true });
		this.networkControllers.forEach(async (controller) => {
			try {
				controller.abort();
			} catch (e) {
				console.log(e);
			}
		});
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			submitting,
			mapping,
			groupData,
			mappedData,
			unmappedData,
			finished,
			cancelled,
			importLength,
			overrideData,
			updateFNandLN,
			importedAttendees,
			importingGroups,
			importedGroups,
			isDuplicate,
			duplicateEmail,
			invalidEmails,
			isInvalidEmail,
			emailExists,
			firstNameExists,
			handledDuplicates,
			importedStarted,
			dupSelectedCount,
			isError,
			salesforceIntegration,
			submitToSalesforce,
			invalidPhones,
			isInvalidPhoneNumber,
			phoneValidationCompleted,
			phoneValidationStarted,
			phoneNumberCurrentCount,
			phoneNumberTotalCount
		} = this.state;
		const hasUnmappedData = Object.keys(unmappedData[UNMAPPED_DATA]).length > 0;
		const hasGroupData = Object.keys(groupData[GROUP_DATA]).length > 0;
		let index = 0;
		let isButtonDisabled = false;
		isButtonDisabled = (!emailExists || !firstNameExists) // handles the case where the user has not mapped email and first name
			|| (isError && (invalidEmails.length > 0 || duplicateEmail.length > 0))  // handles the case where the user has not selected a choice for all duplicate emails OR invalid emails
			|| (isError && (invalidEmails.length > 0 || dupSelectedCount !== Object.keys(duplicateEmail).length))// handles the case where the user has not selected a choice for all duplicate emails
			|| (isError && invalidPhones.length > 0) // Adds a check for invalid phone numbers
			|| importedStarted; //import has started
		return (
			<Modal isOpen={isOpen} toggle={toggle} className="import importModal">
				<div className="modalHeader pb-0">

					<button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7 }}><i className='las la-times'></i></button>
					<div className="headerContainer">
						<h1>Import Attendee Data</h1>
						{/* <h4>Step {currentSection + 1} of {sectionCount}</h4> */}
					</div>
				</div>

				<ModalBody className="modalBody " >
					{!importedStarted && mapping ? (
						<div className={`sectionBody w-100 anim left`}
							style={{
								maxHeight: 400
							}}
						>
							<div className='mb-20'>
								<h4 className='mb-10'>Choose where your attendees data will go by mapping it to the relevant columns.</h4>

								<div className='flex '>

									<div>

										{salesforceIntegration && <CheckboxInput
											left={true}
											style={{ justifyContent: 'unset' }}
											label="Upload to Salesforce"
											checked={submitToSalesforce}
											disableHover={true}
											onClick={
												(e) => this.setState({ submitToSalesforce: !this.state.submitToSalesforce })
											}
											icons={false} />}
										<CheckboxInput
											left={true}
											style={{ justifyContent: 'unset' }}
											label="Merge with existing data"
											checked={overrideData}
											disableHover={true}
											onClick={
												(e) => this.setState({ overrideData: !this.state.overrideData })
											}
											icons={false} />
										{overrideData && <CheckboxInput
											left={true}
											style={{ justifyContent: 'unset' }}
											label="Update First and Last name"
											checked={updateFNandLN}
											className="ml-25 nowrap"
											disableHover={true}
											onClick={
												(e) => this.setState({ updateFNandLN: !this.state.updateFNandLN })
											}
											icons={false} />}
									</div>
								</div>
							</div>
							<div className='scrollContainer'>


								{(!emailExists || !firstNameExists) && (
									<p style={{ color: 'red' }}>
										Email and First Name are required and has not been mapped. Please map.
									</p>
								)}
								{hasUnmappedData &&
									<div>
										<div className='flex mb-10'>
											<h3 style={{ width: '50%' }}>Unmapped Data</h3>
											<h3 className='ml-25' style={{ width: '50%' }}>Attendee Column</h3>
										</div>
									</div>}
								{hasUnmappedData && (<div className="importedDataRow">
									{Object.keys(unmappedData[UNMAPPED_DATA]).map((dataKey) =>
										this.FieldContainer(
											UNMAPPED_DATA,
											dataKey,
											unmappedData[UNMAPPED_DATA][dataKey],
											index++,
											true,
											true
										)
									)}
								</div>
								)}


								{Object.keys(mappedData).map((key) => {
									if (key !== '_id') {
										const categoryData = mappedData[key];
										return (
											<div key={key}>
												<div>
													<div className='flex mb-10'>
														<h3 style={{ marginLeft: '20px', width: '50%' }}>{key}</h3>

														<h3 className='ml-25' style={{ width: '50%' }}>Attendee Data</h3>
													</div>
												</div>
												<div className="importedDataRow">
													{Object.keys(categoryData).map(
														(dataKey) =>
															dataKey !== '_id' &&
															this.FieldContainer(
																key,
																dataKey,
																categoryData[dataKey],
																index++,
																hasUnmappedData
															)
													)}
												</div>
											</div>
										);
									}
									return null;
								})}
								{hasGroupData && (
									<div className='sectionBlock'>
										<h3>Group Data</h3>
										<>
											<p className="mb-20">
												{Object.keys(groupData[GROUP_DATA]).length} group{Object.keys(groupData[GROUP_DATA]).length === 1 ? '' : 's'} will be created.<br />
												Use the remove button <i className="las la-times-circle"></i> to exclude the group.
											</p>
											<div className="importedDataRow">
												{Object.keys(groupData[GROUP_DATA]).map((dataKey) => {
													return (
														this.FieldContainer(
															GROUP_DATA,
															dataKey,
															[groupData[GROUP_DATA][dataKey], `${Object.keys(groupData[GROUP_DATA][dataKey]).length - 1} in group`],
															index++,
															true
														)
													)
												}
												)}
											</div></>
									</div>
								)}
							</div>
						</div>
					) : !importedStarted && isError ? (
						<div className={`sectionBody w-100  anim left ml-10`}>
							{!isButtonDisabled ? <div className='text-c mb-40 mt-20'>
								{importLength > 0 ? <h3 className='mb-10'>All Errors Resolved</h3> : <h3 className='mb-10'> No Data to Import</h3>}
								{importLength > 0 ? <p className='mb-10'>You may begin to import your data.</p> : <p className='mb-10'>There is no data to be imported.</p>}
							</div> : <div className='text-c mb-40 mt-20'>
								<h3 className='mb-10'>Errors found in data</h3>
								<p className='mb-10'>
									Please review and make the necessary changes to continue importing.
								</p>
							</div>}
							{invalidEmails.length > 0 && (
								<div className="sectionBlock m-a">
									<h3>Invalid Emails</h3>
									<p className='mb-10'>
										You may skip the invalid emails or correct them and re-import.
									</p>
									<div className="sectionTable mb-20" style={{ maxHeight: 300 }}>
										<div className="tableSubColumns" style={{ overflow: 'hidden' }}>
											<div className="tableColumnHeader">
												<div style={{ width: 50 }} >
													<h3>Row #</h3>
												</div>
												<div className="tableSubColumnNoSort ">
													<div className="tableCellData">
														<h3>Email</h3>
													</div>
												</div>
												<div className="tableSubColumnNoSort ">
													<div className="tableCellData">
														<h3>First Name</h3>
													</div>
												</div>
											</div>
											<div className="tableColumnRows w-fc">
												{invalidEmails.map((o) => {
													return (
														<div key={index} className="tableColumnRow" style={{ borderBottom: 'none' }}>
															<div style={{ width: 50 }}>{o.rowNumber}
															</div>
															<div className="tableColumnRowCell text-truncate ">{o.email}
															</div>
															<div className="tableColumnRowCell  text-truncate ">{o.firstName}
															</div>

															<button
																onClick={() => {
																	this.skipInvalidEmail(o.email);
																}}
																className="neu min"
															>
																Skip
															</button>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							)}
							{invalidPhones.length > 0 && (
								<div className="sectionBlock m-a">
									<h3>Invalid Phone Number</h3>
									<p className='mb-10'>
										You may skip the invalid Phone Number or correct them and re-import.
									</p>
									<div className="sectionTable mb-20" style={{ maxHeight: 300 }}>
										<div className="tableSubColumns" style={{ overflow: 'hidden' }}>
											<div className="tableColumnHeader">
												<div style={{ width: 70 }} >
													<h3>Row #</h3>
												</div>
												<div style={{ width: 220 }}>
													<h3>phoneNumber</h3>
												</div>
												<div style={{ width: 200 }}>
													<h3>Error</h3>
												</div>
											</div>
											<div className="tableColumnRows w-fc">
												{invalidPhones.map((o) => {
													return (
														<div key={index} className="tableColumnRow" style={{ borderBottom: 'none' }}>
															<div style={{ width: 25 }}>{o.rowNumber}
															</div>
															<div style={{ width: 220 }}>{o.phoneNumber}
															</div>
															<div style={{ width: 200 }}>{o.error}
															</div>
															<button
																onClick={() => {
																	this.skipInvalidPhone(o.phoneNumber);
																}}
																className="neu min"
															>
																Skip
															</button>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							)}
							{isDuplicate &&
								Object.keys(duplicateEmail).length > 0 && (
									<div className="sectionBlock m-a mt-20">
										<h3>Duplicate Emails</h3>
										<p className='mb-10'>
											{Object.keys(duplicateEmail).length} duplicate email{
												Object.keys(duplicateEmail).length === 1 ? '' : 's'
											} found in the CSV data.<br /> Choose the correct row to keep or skip if not obvious. </p>
										<div className="sectionTable mb-20" style={{ maxHeight: 300 }}>
											<div className="tableSubColumns" style={{ overflow: 'hidden' }}>
												<div className="tableColumnHeader">
													<div
														style={{
															width: 50
														}}
													>
														<h3>Row #</h3>
													</div>
													<div className="tableSubColumnNoSort ">
														<div className="tableCellData"><h3>Email</h3>
														</div>
													</div>
													<div className="tableSubColumnNoSort ">
														<div className="tableCellData"><h3>First Name</h3>
														</div>
													</div>
													<div className="tableSubColumnNoSort ">
														<div className="tableCellData">
															<div className="tableColumnTitle" />
														</div>
													</div>
												</div>
												<div className="tableColumnRows w-fc">
													{Object.keys(duplicateEmail).map((key, index) => {
														return (
															<div
																key={index}
																className="tableColumnRow"
																style={{ display: 'flex', height: 'unset' }}
															>
																<div>
																	{duplicateEmail[key].map((dup) => {
																		return (
																			<div style={{ height: 30 }} className="flex aic">
																				<div className='flex aic' style={{ width: 50 }}><div
																					className={`checkBox mb-0 mr-10 ${dup.selected
																						? 'checked'
																						: ''}`}
																					onClick={() => {
																						let count = dupSelectedCount;
																						dup.selected
																							? (dup.selected = false)
																							: (dup.selected = true);
																						if (dup.selected) {
																							duplicateEmail[
																								key
																							].forEach((r) => {
																								if (
																									r.rowNumber !==
																									dup.rowNumber
																								) {
																									if (r.selected)
																										count =
																											count - 1;
																									r.selected = false;
																								} else {
																									count = count + 1;
																								}
																							});
																						} else {
																							count = count - 1;
																						}
																						this.setState({
																							duplicateEmail: duplicateEmail,
																							dupSelectedCount: count
																						});
																					}}
																				>
																					<i className="las la-check" />
																				</div>{dup.rowNumber}</div>
																				<div style={{ display: 'flex' }}>
																					<div className="tableColumnRowCell text-truncate ">{dup.email}
																					</div>
																				</div>
																				<div style={{ display: 'flex' }}>
																					<div className="tableColumnRowCell text-truncate ">{dup.firstName}
																					</div>
																				</div>
																			</div>
																		);
																	})}
																</div>
																<button
																	onClick={() => {
																		this.skipDuplicate(key);
																	}}
																	className="neu min"
																>
																	Skip
																</button>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>
								)}



						</div>
					) : (
						<div className={`sectionBody w-100 anim left`} >
							{!importedStarted && !submitting ? (
								<div>


									{!this.state.validating ? <div className='flex flex-wrap column w-100'>

										<div className='mb-20'>
											<h4 className='mb-10'>Import attendee data from a spreadsheet to the simple.events workspace.</h4>
										</div>

										<Dropzone
											accept="text/csv"
											className="clickable dropzone-details banner mb-10"
											style={{ height: 375 }}
											onDrop={this.onDrop.bind(this)}
										>
											<div>
												<div className="addIcon">
													<i className="las la-plus"></i>
												</div>
												<p className='text-c'>
													Add attendees CSV File<br />
													or <span style={{ color: '#2B83F1' }}>Browse</span>
												</p>
											</div>
										</Dropzone>

										<div className='mb-40 mt-40 mt-20'>
											<h3>
												Need help getting started?
											</h3>
											<p>
												Make sure the data contains columns for email address and first name
											</p>
											<p>
												<span className='click seBlue c-pointer' onClick={this.downloadCSVTemplate}> Download </span> a sample CSV file
											</p>
										</div>
									</div>


										: < LoadingWizard text="Validating" loading={true} />}
								</div>

							) : importedStarted ? (
								<div className="flex column aic pt-20 h-100">
									<h2>
										{
											cancelled ? 'Import Stopped' :
												finished ? (
													'Importing Complete'
												) : importingGroups ? (
													'Importing Groups...'
												) : !importedStarted && !isDuplicate && Object.keys(duplicateEmail) === 0 ? (
													'You may Import'
												) : (
													importedStarted && 'Importing Attendees...'
												)
										}
									</h2>
									{importedStarted && (
										<p className="mb-20">
											{(importedAttendees.length - this.state.errorCount)} of {importLength} attendee{importLength === 1 ? '' : 's'}{' '}
											imported
										</p>
									)}

									<div className='sectionBlockAttendees'>
										{importedGroups.length > 0 && <div className='sectionBlock mb-20'>
											<h3>Imported Groups</h3>
											{importedGroups.map((ig) => (
												<div className="importRow ">
													<div className='w-100'
														style={{
															maxWidth: '50%',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}>{ig.name}</div>
													<div className='w-100'
														style={{
															maxWidth: '50%',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}>
														{ig.added} attendee{ig.added === 1 ? '' : 's'} added
													</div>
												</div>
											))}
										</div>}
										<div className='sectionBlock'>
											<h3 className="pt-20">Imported Attendees</h3>
											{importedAttendees.map((ia) => (
												<div className="importRow ">
													<div className='w-100'
														style={{
															maxWidth: '50%',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}>{ia.email}</div>
													<div className='w-100'
														style={{
															maxWidth: '50%',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
														}}>
														{ia.success ? (
															<i className="las la-check-circle green mr-10" />
														) : (
															<i className="las la-times-circle red mr-10" />
														)}
														{ia.success ? 'Imported' : ia.error}
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							) : phoneValidationStarted && !phoneValidationCompleted && (
								<div className='loadingContainer flex column aic'>
									<LoadingWizard loading={true} />
									<h5 className="mt-50 text-c">{`Validating Phone Numbers ${phoneNumberCurrentCount} of ${phoneNumberTotalCount}`}</h5>
								</div>
							)}
						</div>
					)}
				</ModalBody>
				<div className={`flex mt-20 ${importedStarted ? 'mt-20' : ''}`}>
					<button
						onClick={submitting && !finished ? this.cancelImport : this.props.toggle}
						outline
						className={`neu ml-a mr-15 `}
					>
						{finished ? 'Close' : 'Cancel'}
					</button>

					{!finished && (!isError || importLength > 0) && <button
						className={`neu  ${isButtonDisabled ? 'disabled' : 'prim'}`}
						onClick={() => {
							if (isError) {
								if (isDuplicate) {
									this.handleDuplicateSelections();
								} else if (isInvalidEmail && invalidEmails.length === 0) {
									this.state.batchImport ?
										this.batchImport(this.state.filteredAttendees)
										: this.uploadAttendees(this.state.filteredAttendees);
								} else if (invalidPhones.length === 0) {
									this.state.batchImport ?
										this.batchImport(this.state.filteredAttendees)
										: this.uploadAttendees(this.state.filteredAttendees);
								}
							} else {
								this.handleImport();
							}
						}
						}
					>
						{(!submitting || invalidEmails || handledDuplicates) && importedAttendees.length === 0 ? (
							'Add'
						) : cancelled ? (
							'Cancelling...'
						) : (
							'Importing Attendees...'
						)}
					</button>}

				</div>
			</Modal >
		);
	}
}

export default ImportAttendeesModal;

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
};
const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center'
};
