import React, { Component, Fragment } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Row, Button } from 'reactstrap';
import Select from 'react-select';
import API from '../../../utils/API';
import { Trash2, Plus, CheckCircle, X } from 'react-feather';

import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../components/tables/ViewsTable';

import SelectField from '../../../components/inputs/selectField';
import FieldModal from '../../../components/modals/fieldModal';
class EventSessionGroups extends React.Component {
	constructor(props) {
		super(props);

		const columns = {
			name: 'Name'
		};
		const columnTypes = {
			name: 'text'
		};

		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			groups: props.editedSession.groups,
			showAddToGroupModal: false,
			showRemoveFromGroupModal: false,
			markedGroup: null,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			columns: columns,
			searching: false,
			searchText: '',
			checkedGroups: [],
			addGroupsModalOpen: false,
			deleteGroupsToggle: false,
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	toggleAddToGroup = () => {
		this.setState({ showAddToGroupModal: !this.state.showAddToGroupModal });
	};

	addToGroup = (group) => {
		const groups = this.state.groups;
		groups.push(group);
		this.setState({ groups: groups });
	};

	toggleRemoveFromGroup = (group) => {
		this.setState({
			showRemoveFromGroupModal: !this.state.showRemoveFromGroupModal,
			markedGroup: group ? group._id : null
		});
	};

	removeFromGroup = (group) => {
		const groups = this.state.groups.filter((g) => g._id !== group._id);
		this.props.removeGroup(group);
		this.setState({ groups: groups });
	};

	GroupItem(group, index) {
		return (
			<tr key={index}>
				<td className="text-truncate">{group.name}</td>
				<td style={{ width: '50px' }}>
					<Trash2
						size={14}
						color="red"
						onClick={() => this.toggleRemoveFromGroup(group, index)}
						className={`clickable ${this.props.readOnly ? 'disabled-link' : ''}`}
					/>
				</td>
			</tr>
		);
	}

	updatedCheckedGroups = (checked) => {
		this.setState({ checkedGroups: checked });
	};

	deleteGroupsToggle = () => {
		this.setState({ deleteGroupsToggle: !this.state.deleteGroupsToggle });
	};

	toggleAddGroups = () => {
		this.setState({ addGroupsModalOpen: !this.state.addGroupsModalOpen });
	};

	searchEntries = (searchText) => {
		this.setState({ searching: searchText != '' ? true : false, searchText: searchText ? searchText : '' });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const {
			groups,
			markedGroup,
			searchText,
			columns,
			toggledColumns,
			searching,
			normalizedColumns,
			addGroupsModalOpen,
			deleteGroupsToggle,
			selectedColumnSort,
			sortDesc
		} = this.state;

		let searchedEntries = groups;

		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		searchedEntries = searchedEntries.map((g) => {
			return {
				_id: g._id,
				name: g.name
			};
		});

		if (selectedColumnSort) {
			searchedEntries = searchedEntries.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
		}

		let mainActions = [];
		mainActions = [{ onClick: this.toggleAddGroups, label: 'Add Groups' }];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [
			{
				type: 'button',
				label: 'Remove from Group',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.deleteGroupsToggle
			}
		];
		checkedEntryCount = Object.keys(this.state.checkedGroups).length;
		entriesLength = searching ? searchedEntries.length : groups.length;
		uncheckEntries = () => {
			this.setState({ checkedGroups: [] });
		};
		searchEnabled = true;

		return (
			<div className='sectionBody posRel mb-0 p-0'>
				<ActionButtonContainer
					mainActions={mainActions}
					searchEnabled={searchEnabled}
					searchEntries={this.searchEntries}
				/>

				<ViewsTable
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					classes="nested"
					data={searchedEntries}
					mainColumnActive={false}
					checked={this.state.checkedGroups}
					checkRow={(entryId) => {
						const checked = this.state.checkedGroups;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.updatedCheckedGroups(checked);
						this.setState({
							checkedGroups: checked
						});
					}}
					mainActions={mainActions}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedGroups;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.updatedCheckedGroups(checked);

						this.setState({
							checkedGroups: checked
						});
					}}
					sortData={this.sortData}
				/>
				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					uncheckEntries={uncheckEntries}

				/>

				{addGroupsModalOpen && (
					<SessionAddToGroupModal
						isOpen={addGroupsModalOpen}
						groups={this.props.event.groups}
						addGroup={this.addToGroup}
						sessionGroups={this.props.editedSession.groups}
						toggle={this.toggleAddGroups}
						session={this.props.editedSession}
						groupId={markedGroup}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updateSession={this.props.updateSession}
						addNewGroup={this.props.addNewGroup}
					/>
				)}

				{deleteGroupsToggle && (
					<SessionRemoveFromGroupModal
						isOpen={deleteGroupsToggle}
						remove={this.removeFromGroup}
						toggle={this.deleteGroupsToggle}
						groupId={markedGroup}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						updateSession={this.props.updateSession}
						groups={this.props.event.groups}
						session={this.props.editedSession}
						checkedGroups={this.state.checkedGroups}
						uncheckEntries={uncheckEntries}
					/>
				)}
			</div>
		);
	}
}

export default EventSessionGroups;

class SessionAddToGroupModal extends Component {
	constructor(props) {
		super(props);
		const groupNames = props.groups
			.filter((group) => !props.sessionGroups.find((g) => g._id === group._id))
			.map((group) => {
				return { value: group._id, label: group.name, color: '#00B8D9' };
			});
		this.state = {
			name: '',
			defaultGroupNames: [
				...props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			groupNames: groupNames,
			selectedValue: { label: '', color: '#00B8D9' },
			added: 0,
			submitting: false,
			invalid: false,
			success: false,
			requiredFields: {
				name: false
			}
		};
	}

	reset = (e) => {
		this.setState({
			name: '',
			defaultGroupNames: [
				...this.props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			groupNames: [
				...this.props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			selectedValue: { label: '', color: '#00B8D9' },
			submitting: false,
			invalid: false,
			success: false
		});
		this.props.toggle();
	};

	handleNameChange = (e) => {
		const newGroup = {
			label: 'New group: ' + e,
			color: '#00B8D9',
			new: true
		};
		this.setState({
			groupNames: [...this.state.defaultGroupNames, newGroup]
		});
	};

	handleGroupChosen = (e) => {
		if (e.new) {
			this.setState({ selectedValue: { ...e, label: e.label.split('New group: ')[1] } });
		} else {
			this.setState({ selectedValue: e });
		}
		/* if (e.value) {
			this.setState({
				groupNames: { 0: e.value }
			});
		} */
	};

	add = async () => {
		const { eventId, orgId, session } = this.props;
		const { selectedValue } = this.state;

		let valid = true;
		const requiredFields = this.state.requiredFields;

		Object.keys(requiredFields).forEach((key) => {
			if (selectedValue.label === '') {
				requiredFields.name = true;
				valid = false;
			} else {
				requiredFields.name = false;
			}
		});
		if (valid) {
			this.setState({ submitting: true });

			if (this.state.selectedValue.new) {
				const groupName = this.state.selectedValue.label;
				await API()
					.post(`Organizations/${orgId}/events/${eventId}/group`, {
						name: groupName,
						sessions: [session._id]
					})
					.then((res) => {
						if (res.data) {
							this.props.addGroup(res.data);
							this.props.addNewGroup(res.data);
							this.setState({ submitting: false });
							this.props.toggle();
						}
					})
					.catch((e) => {
						console.log(e);
						this.setState({
							invalid: true,
							submitting: false
						});
					});
			} else {
				const groupId = this.state.selectedValue.value;
				const group = this.props.groups.find((g) => g._id == groupId);
				await API()
					.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
						group: groupId,
						request: 'addGroup'
					})
					.then((res) => {
						if (res.data) {
							this.props.addGroup(group);
							this.props.updateSession(session._id, res.data);
							this.setState({ submitting: false });
							this.props.toggle();
						}
					})
					.catch((e) => {
						console.log(e);
						this.setState({
							invalid: true,
							submitting: false
						});
					});
			}
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen } = this.props;
		const { groupNames, submitting, invalid, selectedValue, requiredFields } = this.state;

		return (


			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add To Group"}
					bodyHeaderText={'You are adding to this group.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<SelectField
									classes={'w-100'}
									label="Group Name"
									value={selectedValue}
									name="groupName"
									options={groupNames}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={this.handleGroupChosen}
									onInputChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={this.reset}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.add(selectedValue, this.reset)}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}
class SessionRemoveFromGroupModal extends React.Component {
	state = {
		submitting: false
	};

	removeGroup = () => {
		const { eventId, orgId, session, checkedGroups } = this.props;
		this.setState({ submitting: true })
		API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				groupIds: Object.keys(checkedGroups),
				request: 'removeGroups'
			})
			.then((res) => {
				if (res.data) {
					Object.keys(checkedGroups).forEach((groupId) => {
						const group = this.props.groups.find((g) => g._id == groupId);
						this.props.remove(group);
						this.props.updateSession(session._id, res.data);
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});

		this.props.uncheckEntries();
		this.props.toggle();
		this.setState({ submitting: false });
	};

	render() {
		const { isOpen, checkedGroups, toggle } = this.props;
		const { submitting } = this.state;

		let checkedGroupLength = Object.keys(checkedGroups).length;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete From Session"}
					bodyHeaderText={<span>You are about to delete{' '}
						<b>
							{checkedGroupLength} group{checkedGroupLength != 1 ? 's' : ''}
						</b>{' '}
						from this session, this will remove <b>ALL</b> group associated
						attendees from this session as well. Would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.removeGroup()}
					isDelete={true}
					actionButtonDisabled={submitting}
				></FieldModal>
			</div>

		);
	}
}
