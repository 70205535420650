import React, { Component } from 'react';
import API from '../../utils/API';
import FieldModal from '../modals/fieldModal';

let carouselInterval = null;

class PromotionCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            hovering: false,
            items: [],
            selectedItem: null,
            modalOpen: false
        };
    }

    componentDidMount() {
        const { organization } = this.props;
        API().get(`Organizations/${organization._id}/platformMessages`).then((response) => {
            this.setState({ items: response.data });
        });

        carouselInterval = setInterval(() => {
            if (!this.state.hovering) {
                this.next();
            }
        }, 15000);
    }

    // if hevering over carousel, stop the interval
    onMouseEnter = () => {
        this.setState({ hovering: true });
    };

    onMouseLeave = () => {
        this.setState({ hovering: false });
    };

    next = () => {
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = () => {
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex = (newIndex) => {
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex, modalOpen, selectedItem } = this.state;
        const slides = this.state.items.map((item, i) => {
            return <div className={`promotionBox ${activeIndex == i ? 'selected' : ''} `} onClick={() => {
                if (item.type == "modal") {
                    this.setState({ modalOpen: true, selectedItem: item });
                }
            }}>
                <h3 className='mb-10'>{item.title}</h3>
                <p className='small mb-20'>{item.message}</p>
                {item.actionButton && <button className='promotionBoxButton'>{item.actionButton}</button>}
            </div>
        });

        return (
            <div className='promotionCarousel' onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} >
                <div className='promotionCarouselInner'>
                    {slides}
                </div>
                <div className='carouselIndexIndicators'>
                    {this.state.items.map((item, index) => {
                        return <div className={`indicator ${index === activeIndex ? 'selected' : ''}`} onClick={() => this.goToIndex(index)}></div>
                    })}
                </div>
                <FieldModal isOpen={modalOpen && selectedItem} toggle={() => {
                    this.setState({ modalOpen: false, selectedItem: null });
                }} modalTitle={selectedItem && selectedItem.title} bodyContent={() => selectedItem && selectedItem.content && <div dangerouslySetInnerHTML={{__html: selectedItem.content}}></div>} cancelButtonLabel="Close" />
            </div>
        );
    }
}

export default PromotionCarousel;