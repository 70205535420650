import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../../utils/API';
import EventEmail from './email';
import NewEmailModal from './modals/newEmailModal';
import DeleteEmailModal from './modals/deleteEmailModal';

import ViewsContainer from '../../../components/views/ViewsContainer';
import { CloudLightning } from 'react-feather';

let batchCallback;
class EventEmails extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			subject: 'Subject',
			createdAt: 'Created At',
			updatedAt: 'Updated At',
			openedEmailCount: 'Opened Email Count',
			sentEmailCount: 'Sent Email Count'
		};
		const defaultFieldTypes = {
			name: 'text',
			subject: 'text',
			createdAt: 'date',
			updatedAt: 'date',
			openedEmailCount: 'number',
			sentEmailCount: 'number'
		};

		this.state = {
			orgId: this.props.orgId,
			eventId: props.eventId,
			sortedEmails: [],
			loading: false,
			selectedEmail: 0,
			emailSelected: false,
			addEmailModalOpen: false,
			newEmails: {},
			emailsViews: [],
			emails: [],
			toggledColumns: { name: false, subject: true, sentEmailCount: true },
			checkedEmails: {},
			sortByColumn: { name: true },
			loading: true,
			deletedEmails: [],
			deleteEmailsModalOpen: false,
			loading: true,
			keyedMetadata: [],
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			loading: true,
			keyedData: []
		};
	}

	async componentDidMount() {
		const { eventId, orgId } = this.state;
		await API().get(`Organizations/${orgId}/events/${eventId}/emails`).then((res) => {
			if (res.data) {
				const emailsViews = res.data.emailsViews;
				let sortedEmails = res.data.emails.map((email) => {
					return {
						name: email.name,
						subject: email.subject,
						sentEmailCount: email.sentEmailCount,
						openedEmailCount: email.openedEmailCount,
						_id: email._id,
						link: `/${orgId}/events/${eventId}/emails/${email._id}`,
						createdAt: email.createdAt,
						updatedAt: email.updatedAt
					};
				});
				const metadataOptions = [];
				Object.keys(res.data.keyedData).forEach((key) => {
					const layer1 = res.data.keyedData[key];
					if (typeof layer1 === 'object') {
						Object.keys(layer1).forEach((key2) => {
							const layer2 = layer1[key2];
							if (typeof layer2 === 'object') {
								Object.keys(layer2).forEach((key3) => {
									if (
										key !== '_defaultLabel' &&
										key2 !== '_defaultLabel' &&
										key3 !== '_defaultLabel'
									) {
										metadataOptions.push(`${key}.${key2}.${key3}`);
									}
								});
							} else {
								if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
									metadataOptions.push(`${key}.${key2}`);
								}
							}
						});
					} else {
						if (key !== '_defaultLabel') {
							metadataOptions.push(`${key}`);
						}
					}
				});
				this.setState({
					sortedEmails: sortedEmails,
					emails: sortedEmails,
					loading: false,
					keyedMetadata: metadataOptions,
					keyedData: res.data.keyedData,
					emailsViews: emailsViews,
					loading: false
				});
			}
		});
	}

	addNewEmail = async (email, callback) => {
		const { orgId } = this.props;
		const eventId = this.props.eventId;

		await API().post(`Organizations/${orgId}/events/${eventId}/email`, email).then((res) => {
			const emailObj = res.data;

			if (res.data) {
				const newEmail = {
					name: emailObj.name,
					subject: emailObj.subject,
					sentEmailCount: 0,
					openedEmailCount: 0,
					_id: emailObj._id,
					link: `/${orgId}/events/${eventId}/emails/${emailObj._id}`,
					createdAt: emailObj.createdAt,
					updatedAt: emailObj.updatedAt
				};
				this.setState(
					{
						sortedEmails: [...this.state.sortedEmails, newEmail],
						emails: [...this.state.emails, newEmail],
						addEmailModalOpen: false
					},
					() => {
						callback(emailObj._id);
					}
				);
			} else {
			}
		});
	};

	deleteCheckedEmails = async (emails) => {
		const { eventId, orgId } = this.props;
		await API()
			.post(`Organizations/${orgId}/events/${eventId}/emails`, {
				emails: Object.keys(this.state.checkedEmails)
			})
			.then((res) => {
				if (res.data) {
					let tempChecked = JSON.parse(JSON.stringify(this.state.checkedEmails));

					batchCallback && batchCallback('delete');
					this.setState({
						addEmailModalOpen: false,
						deletedEmails: [...this.state.deletedEmails, ...Object.keys(tempChecked)],
						sortedEmails: this.state.sortedEmails.filter(
							(e) => !Object.keys(this.state.checkedEmails).includes(e.id)
						),
						deleteEmailsModalOpen: false
					});
				}
			});

		this.setState({
			checkedEmails: {}
		});
	};

	removeEmail = async (emailId, callback) => {
		const { eventId, orgId } = this.props;
		await API().delete(`Organizations/${orgId}/events/${eventId}/email/${emailId}`).then((res) => {
			if (res.data) {
				this.state.deletedEmails.push(emailId);
				this.setState(
					{
						addEmailModalOpen: false,
						deletedEmails: this.state.deletedEmails,
						sortedEmails: this.state.sortedEmails.filter((e) => e.id !== emailId),
						deleteEmailsModalOpen: false
					},
					() => {
						callback();
					}
				);
			}
		});

		this.setState({
			checkedEmails: {}
		});
	};

	updateEmails = (updatedEmail) => {
		const emails = JSON.parse(JSON.stringify(this.state.sortedEmails));

		const tempEmails = emails.map((email) => {
			if (email._id === updatedEmail._id) {
				return { ...email, ...updatedEmail };
			} else {
				return email;
			}
		});
		this.setState({ emails: tempEmails, sortedEmails: tempEmails });
	};

	toggleDeleteEmails = (checkedEmails, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedEmails: checkedEmails || {},
			deleteEmailsModalOpen: !this.state.deleteEmailsModalOpen
		});
	};
	render() {
		const {
			eventId,
			selectedEmail,
			loading,
			deleteEmailsModalOpen,
			emails,
			emailsViews,
			columnTypes,
			columns,
			deletedEmails,
			keyedData
		} = this.state;
		const { event, orgId } = this.props;

		return (
			<Switch>
				<Route exact path={`/admin/events/${event._id}/8`}>
					<Fragment>
						{!loading && (
							<ViewsContainer
								orgId={orgId}
								columns={columns}
								columnTypes={columnTypes}
								views={emailsViews}
								entries={emails}
								deletedEntries={deletedEmails}
								title="Emails"
								description="Create emails from temples or upload your own HTML. Merge in Event and Attendee information using Short Codes. "
								readOnly={false}
								mainActions={[
									{ type: 'link', target: `/${orgId}/events/${eventId}/emails/add`, label: 'Add Email' }
								]}
								batchActions={[
									{
										label: 'Delete',
										iconClass: 'las la-trash-alt',
										class: 'danger',
										onClick: this.toggleDeleteEmails
									}
								]}
								breadcrumbs={[
									{ name: 'Events', link: `/admin/events` },
									{ name: event.name, link: `/admin/events/${eventId}/8` }
								]}
								enableDownloadViews={true}
								type={'Email'}
								referenceCollection={'Events'}
								referenceId={eventId}
							/>
						)}
						<DeleteEmailModal
							isOpen={deleteEmailsModalOpen}
							delete={this.deleteCheckedEmails}
							toggle={this.toggleDeleteEmails}
							count={Object.keys(this.state.checkedEmails).length}
							isMultiple={true}
						/>
					</Fragment>
				</Route>
				<Route exact path={`/${orgId}/events/${event._id}/emails/add`}>
					<NewEmailModal
						eventId={eventId}
						addNewEmail={this.addNewEmail}
						isOpen={true}
						toggle={() => this.props.history.push('./')}
					/>
				</Route>

				<Route path={`/${orgId}/events/${event._id}/emails/:emailId`}>
					<EventEmail
						event={this.props.event}
						attendeeCount={this.props.attendeeCount}
						loadMoreAttendees={this.props.loadMoreAttendees}
						eventId={eventId}
						orgId={orgId}
						index={selectedEmail}
						emails={emails}
						keyedData={keyedData}
						updateEmails={this.updateEmails}
						removeEmail={this.removeEmail}
						exit={() => {
							this.setState({ emailSelected: false });
						}}
						keyedMetadata={this.state.keyedMetadata}
					/>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(EventEmails);
