import React, { Component } from 'react';

import API from '../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';
import InputField from '../../inputs/inputField.js';
import FieldModal from '../../modals/fieldModal';
class RenameViewModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			name: props.view.name,
			requiredFields: {
				name: false
			}
		};
	}

	saveView = () => {
		const { name, requiredFields } = this.state;
		const { orgId, view, reportId } = this.props;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!name || name === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			this.setState({ submitting: true });
			const payload = {
				name: name
			};
			if(reportId) {
				payload.reportId = reportId;
			}
			API()
				.patch(`/Organizations/${orgId}/views/${view._id}`, payload)
				.then((res) => {
					this.setState({ submitting: false, requiredFields: requiredFields });
					this.props.updateView(res.data);
					this.props.toggle();
				})
				.catch((err) => {
					this.setState({ submitting: false });
					console.log(err);
				});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, requiredFields } = this.state;
		return (

			<div>


				<FieldModal
					size='medium'
					isOpen={isOpen}
					modalTitle={"Rename View"}
					bodyHeaderText={'Update the name of this view below.'}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="View Name"
									required={true}
									errorTrigger={requiredFields.name}
									value={name}
									onChange={(e) => this.setState({ name: e.target.value })}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
					actionButton={() => this.saveView()}
					actionButtonDisabled={submitting}


				></FieldModal>




			</div>
		)
	}
}

export default RenameViewModal;
