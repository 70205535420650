import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'react-toggle/style.css';
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap';
import fileIcon from '../../assets/img/icons/file.png';
import CodeEditor from './codeEditor';
import InputField from '../inputs/inputField';
import SelectField from '../inputs/selectField';
import ToggleField from '../inputs/toggleField';
import TextAreaField from '../inputs/textAreaField';
import ColorField from '../inputs/colorField';
import FileChooserPreview from '../inputs/fileChooserPreview';
import RichTextEditor from '../../views/pages/components/richTextEditor';
import FilesUploaderModal from '../files/filesUploaderModal';
import OpenSelectField from '../inputs/openSelectField';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};
const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#2b83f166' : 'transparent'
	//overflowX: 'hidden'
});

const getComponentListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#2b83f166' : 'transparent',
	padding: '0 25px',
	margin: '0 -25px',
	marginBottom: '5px'
});

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',

	// change background colour if dragging
	//background: isDragging ? 'lightgrey' : 'white',
	/* borderBottom: '1px solid grey',
	padding: '15px 0', */
	// styles we need to apply on draggables
	...draggableStyle
});

const typeTemplates = {
	menuItem: {
		title: 'Menu Item',
		file: '',
		type: 'menuItem',
		parameters: [
			{
				category: 'General',
				key: 'linkType',
				label: 'Link Type',
				value: '',
				type: 'select',
				options: [
					{ value: 'internalPage', label: 'Internal Page' },
					{ value: 'externalLink', label: 'External Link' },
					{ value: 'dropdown', label: 'Dropdown' }
				]
			}
		]
	}
};

const cssPropertyOptions = [
	{ value: 'max-width', label: 'Max Width' },
	{ value: 'padding', label: 'Padding' },
	{ value: 'padding-top', label: 'Padding Top' },
	{ value: 'padding-bottom', label: 'Padding Bottom' },
	{ value: 'padding-left', label: 'Padding Left' },
	{ value: 'padding-right', label: 'Padding Right' },
	{ value: 'margin', label: 'Margin' },
	{ value: 'margin-top', label: 'Margin Top' },
	{ value: 'margin-bottom', label: 'Margin Bottom' },
	{ value: 'margin-left', label: 'Margin Left' },
	{ value: 'margin-right', label: 'Margin Right' },
	{ value: 'flex-direction', label: 'Flex Direction' },
	{ value: 'flex-wrap', label: 'Flex Wrap' },
	{ value: 'justify-content', label: 'Justify Content' },
	{ value: 'align-items', label: 'Align Items' }
];

class EditorComponentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedComponentTab: 'General',
			componentSelected: false,
			selectedPartialIndex: null,
			parameterSelected: false,
			parameterPath: '',
			color: {},
			displayColorPickers: {},
			// optional, reset this if you dont want to preserve selectscted component tab for nested compmonents
			selectedNestedComponentTab: {},
			toggledIds: {},
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}
		const items = reorder(this.props.editedPage.partials, result.source.index, result.destination.index);
		this.props.updatePartialOrder(items);
	}

	focusComponent = (partialIndex) => {
		//this.props.focusComponent(this.props.editedPage.partials[partialIndex]);
		this.props.focusComponent(true, partialIndex);
		//const partial = this.props.editedPage.partials[partialIndex];
		/* document
			.getElementById('builderPreviewFrame')
			.contentWindow.postMessage(
				{ type: 'focusComponent', componentId: `#${partial.file.replace('/', '_')}_${partial._id.toString()}` },
				'*'
			); */
	};

	unfocusComponent = () => {
		this.props.focusComponent(false, 0);
		this.props.setParameterSelected(false, '');
	};

	renderParameters = (pIndex, param, onChange, propertyOptions) => {
		const { editedPage } = this.props;
		const { componentSelected, selectedPartialIndex } = this.props;
		const selectedPartial = componentSelected && editedPage.partials[selectedPartialIndex];
		let paramComponent = null;
		const paramType = param.type || typeof param.value;
		switch (paramType) {
			case 'boolean':
				paramComponent = (
					<ToggleField
						label={param.label}
						checked={param.value}
						icons={false}
						onChange={(e) => {
							onChange(e.target.checked);
						}}
					/>
				);
				break;
			case 'select':
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={param.options.find((opt) => opt.value === param.value)}
						onChange={onChange}
						options={param.options}
					/>
				);
				break;
			case 'openSelect':
				paramComponent = (
					<OpenSelectField
						label={param.label}
						type="select"
						value={param.value}
						onChange={onChange}
						options={param.options}
					/>
				);
				break;
			case 'pageSelect':
				let option = this.props.pagePaths;
				let value = this.props.pagePaths.find((pp) => pp.value == param.value);
				option = option.filter((pp) => pp.value != param.value);
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={value}
						onChange={onChange}
						options={option}
					/>
				);
				break;
			case 'feedSelect':
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={this.props.eventFeeds.find((ep) => ep.value == param.value)}
						onChange={onChange}
						options={this.props.eventFeeds}
					/>
				);
				break;
			case 'emailSelect':
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={this.props.emailPaths.find((ep) => ep.value == param.value)}
						onChange={onChange}
						options={this.props.emailPaths}
					/>
				);
				break;
			case 'groupSelect':
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={this.props.editedPage.groups.find((opt) => opt.value === param.value)}
						onChange={onChange}
						options={[...this.props.editedPage.groups, { value: '', label: 'Not Specified' }]}
					/>
				);
				break;
			case 'programSelect':
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={this.props.programs.find((opt) => opt.value === param.value)}
						onChange={onChange}
						options={this.props.programs}
					/>
				);
				break;
			case 'sessionSelect':
				const sessionOptions = this.props.event.sessions.map((s) => {
					return { value: s._id, label: s.name };
				});
				paramComponent = (
					<SelectField
						label={param.label}
						type="select"
						value={sessionOptions.find((opt) => opt.value === param.value)}
						onChange={onChange}
						options={sessionOptions}
					/>
				);
				break;
			case 'number':
				paramComponent = (
					<InputField label={param.label} type="number" value={param.value} onChange={onChange} />
				);
				break;
			case 'icon':
				paramComponent = (
					<InputField
						label={
							<span>
								{param.label}{' '}
								<a className="ml-a se-blue" href="https://icons8.com/line-awesome" target="_blank">
									<i className="las la-external-link-alt" />
								</a>
							</span>
						}
						value={param.value}
						onChange={onChange}
					/>
				);
				break;
			case 'code':
				paramComponent = (
					<CodeEditor
						className={`displayedField2`}
						disabled={this.props.readOnly}
						content={param.value}
						language="html"
						style={{ marginLeft: 0, width: '100%', height: '100%', minHeight: '40vh' }}
						setContent={(c) => onChange(c)}
					/>
				);
				break;
			case 'form':
				/* paramComponent = (
					<select type="select" defaultValue={param.value} onChange={onChange}>
						<option value="">Select form</option>
						{this.props.forms.map((f) => <option value={f.value}>{f.label}</option>)}
					</select>
				); */
				paramComponent = (
					<SelectField
						label={param.label}
						value={this.props.forms.find((opt) => opt.value === param.value)}
						onChange={onChange}
						options={this.props.forms}
					/>
				);
				break;
			case 'formType':
				paramComponent = (
					<SelectField
						label={param.label}
						value={this.props.formTypes.find((opt) => opt.value === param.value)}
						onChange={onChange}
						options={this.props.formTypes}
					/>
				);
				break;
			case 'color':
				paramComponent = <ColorField label={param.label} value={param.value} onChange={onChange} />;
				break;
			case 'string':
				if (propertyOptions && propertyOptions[param.key]) {
					paramComponent = (
						<select type="select" defaultValue={param.value} onChange={onChange}>
							{propertyOptions[param.key].map((po) => {
								return <option value={po}>{po}</option>;
							})}
						</select>
					);
				} else {
					paramComponent = (
						<TextAreaField
							label={param.label}
							rows={2}
							value={param.value}
							onChange={onChange}
							style={{ width: '100%', minHeight: 55 }}
						/>
					);
				}
				break;
			case 'richText':
				const id = `richText${pIndex}${param.key}`;
				paramComponent = (
					<div id={id} className="w-100 posRel">
						<div className="labelContainer">
							<div className="formFieldLabel">{param.label}</div>
						</div>
						<RichTextEditor
							id={id}
							value={param.value}
							onChange={onChange}
							customFields={this.props.customFields}
							fontFamilies={this.props.fontFamilies}
						/>
					</div>
				);
				break;
			case 'fileSelect':
			case 'file':
				const pv = param.value.replace('choosing__', '');
				let isOpen = this.state.toggledIds[param._id] || false;
				const isVideo = (pv) => /\.mp4$/i.test(pv);
				paramComponent = (
					<div className="editorFileInput">
						<FileChooserPreview
							label={param.label}
							value={
								paramType == 'fileSelect' && pv != '' ? (
									this.props.files.find((f) => f._id == pv).url
								) : (
									pv
								)
							}
							isVideo={isVideo(pv)}
							onClick={() => {
								this.setState({
									toggledIds: {
										...this.state.toggledIds,
										[param._id]: !isOpen
									}
								})
							}
							}
							clearFile={() => onChange('')}
						/>
						{this.state.toggledIds[param._id] &&
							<FilesUploaderModal
								isOpen={this.state.toggledIds[param._id]}
								toggle={() => {
									let toggledIds = this.state.toggledIds;
									toggledIds[param._id] = !toggledIds[param._id];
									this.setState({ toggledIds });
								}
								}
								eventId={this.props.eventId}
								links={this.props.links}
								orgId={this.props.orgId}
								addFiles={(fileURL) => {
									onChange(fileURL);
									let toggledIds = this.state.toggledIds;
									toggledIds[param._id] = !toggledIds[param._id];
									this.setState({ toggledIds });
								}}
							/>}
					</div>
				);

				break;
			case 'css':
				paramComponent = (
					<div className="w-100 mb-25">
						<div className="labelContainer">
							<div className="formFieldLabel">{param.label}</div>
						</div>
						{Array.isArray(param.value) &&
							param.value.map((css, index) => {
								return (
									<div className="flex-ac">
										<SelectField
											label="Property"
											options={cssPropertyOptions}
											value={cssPropertyOptions.find((opt) => opt.value == css.property)}
											onChange={(e) => {
												let newCss = [...param.value];
												newCss[index].property = e.value;
												onChange(newCss);
											}}
											style={{ marginRight: 10 }}
										/>
										<InputField
											label="Value"
											value={css.value}
											onChange={(e) => {
												let newCss = [...param.value];
												newCss[index].value = e.target.value;
												onChange(newCss);
											}}
											style={{ marginRight: 10 }}
										/>

										<div
											className="actionButton actionDelete"
											onClick={() => {
												let newCss = [...param.value];
												newCss.splice(index, 1);
												onChange(newCss);
											}}
										>
											<i className="las la-trash" />
										</div>
									</div>
								);
							})}
						<div
							className="actionButton actionOutline"
							onClick={() => {
								let newCss = [...param.value];
								newCss.push({ property: '', value: '' });
								onChange(newCss);
							}}
						>
							<i className="las la-plus" />
							<div className="">Add {param.label}</div>
						</div>
					</div>
				);
				break;
			case 'list':
				paramComponent = (
					<div className="w-100 mb-25">
						<div className="labelContainer">
							<div className="formFieldLabel">{param.label}</div>
						</div>
						{Array.isArray(param.value) &&
							param.value.map((item, index) => {
								return (
									<div className="flex-ac">
										<InputField
											label="Item"
											value={item}
											onChange={(e) => {
												let newList = [...param.value];
												newList[index] = e.target.value;
												onChange(newList);
											}}
											style={{ marginRight: 10 }}
										/>
										<div
											className="actionButton actionDelete"
											onClick={() => {
												let newList = [...param.value];
												newList.splice(index, 1);
												onChange(newList);
											}}
										>
											<i className="las la-trash" />
										</div>
									</div>
								);
							})}
						<div
							className="actionButton actionAdd"
							onClick={() => {
								let newList = [...param.value];
								newList.push('');
								onChange(newList);
							}}
						>
							<i className="las la-plus" />
							<div className="">Add {param.label}</div>
						</div>
					</div>
				);
				break;
			case 'tags':
				const tags = param.value.split('\n');
				let inputTag = tags[tags.length - 1];
				tags.pop();
				console.log(tags);
				paramComponent = (
					<InputField
						label={param.label}
						value={inputTag}
						onKeyUp={(e) => {
							if (e.key === 'Enter') {
								tags.push(inputTag + '\n');
								onChange(tags.join('\n'));
							}
						}}
						onChange={(e) => {
							tags.push(e.target.value);
							onChange(tags.join('\n'));
						}}
						prefix={
							<span className="flex-ac">
								{tags.map((t, i) => (
									<span
										className="tag"
										onClick={() => {
											tags.splice(i, 1);
											tags.push(inputTag);
											onChange(tags.join('\n'));
										}}
									>
										<span className="  fs-12">{t}</span>
										<i className="las la-times" />
									</span>
								))}
							</span>
						}
						style={{ marginRight: 10 }}
					/>
				);
				break;
			case 'nestedComponentList':
				paramComponent = (
					<div className="w-100 mb-25">
						<div className="labelContainer">
							<div className="formFieldLabel">{param.label}</div>
						</div>
						<div className="paramArrayElements">
							{param.nestedComponents.map((pv, pvi) => {
								return (
									<div className="paramListItem">
										<div className="flex-ac w-100">
											<div className="">{pv.title}</div>
											<div className="actionButton actionDelete ml-a">
												<i className="las la-trash" />
											</div>
										</div>
										<div className="itemParameters">
											{pv.parameters.map((p, pi) => {
												const selectedComponent = p;
												const paramType = param.type || typeof param.value;
												switch (paramType) {
													case 'componentContainer':
														return this.renderParameters(
															pi,
															p,
															(adding, component, reordering) => {
																const currentComponents =
																	selectedComponent.parameters[pi].nestedComponents;
																let updatedComponents = [];
																if (adding === true) {
																	updatedComponents = [
																		...currentComponents,
																		component
																	];
																} else if (adding >= 0 && !reordering) {
																	updatedComponents = [...currentComponents];
																	updatedComponents.splice(adding, 1);
																} else if (reordering) {
																	updatedComponents = component;
																}
																this.setToValue(
																	selectedPartial,
																	updatedComponents,
																	`${this.props
																		.parameterPath}.parameters.${pi}.nestedComponents`
																);
																this.props.updatePartial(
																	selectedPartialIndex,
																	selectedPartial
																);
																//updatePartialParameters(selectedPartialIndex, pIndex, value);
															}
														);
													case 'boolean':
														return this.renderParameters(pi, p, (e) => {
															this.setToValue(
																selectedPartial,
																e,
																`${this.props.parameterPath}.parameters.${pi}.value`
															);
															this.props.updatePartial(
																selectedPartialIndex,
																selectedPartial
															);
														});
													case 'string':
														return this.renderParameters(pi, p, (e) => {
															const value = e.target ? e.target.value : e;
															this.setToValue(
																selectedPartial,
																value,
																`${this.props.parameterPath}.parameters.${pi}.value`
															);
															this.props.updatePartial(
																selectedPartialIndex,
																selectedPartial
															);
														});
													case 'pageSelect':
													case 'select':
														return this.renderParameters(pi, p, (e) => {
															const value = e.value;
															this.setToValue(
																selectedPartial,
																value,
																`${this.props.parameterPath}.parameters.${pi}.value`
															);
															this.props.updatePartial(
																selectedPartialIndex,
																selectedPartial
															);
															//updatePartialParameters(selectedPartialIndex, pIndex, value);
														});

													default:
														return this.renderParameters(pi, p, (e) => {
															const value = e.target ? e.target.value : e;
															this.setToValue(
																selectedPartial,
																value,
																`${this.props.parameterPath}.parameters.${pi}.value`
															);
															this.props.updatePartial(
																selectedPartialIndex,
																selectedPartial
															);
															//updatePartialParameters(selectedPartialIndex, pIndex, value);
														});
												}
											})}
										</div>
									</div>
								);
							})}
						</div>

						{param.componentTemplate && (
							<div
								className="paramArrayAdd actionButton actionOutline"
								onClick={() => {
									const componentTemplate = JSON.parse(JSON.stringify(param.componentTemplate));
									onChange(true, componentTemplate);
								}}
							>
								<i className="las la-plus" />
								<div className="">Add {param.label}</div>
							</div>
						)}
					</div>
				);
				break;
			case 'componentContainer':
				if (param.nestedComponents && Array.isArray(param.nestedComponents)) {
					paramComponent = (
						<div className="w-100 mb-25">
							<div className="labelContainer">
								<div className="formFieldLabel">{param.label}</div>
							</div>
							<DragDropContext
								onDragEnd={(result) => {
									if (!result.destination) {
										return;
									}
									const items = reorder(
										param.nestedComponents,
										result.source.index,
										result.destination.index
									);
									onChange(false, items, true);
								}}
								className="paramArrayElements"
							>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
											{param.nestedComponents.map((pv, pvi) => {

												const removeHtmlTags = (html) => {
													const doc = new DOMParser().parseFromString(html, 'text/html');
													return doc.body.textContent || '';
												};
												const htmlContent = pv.parameters[0].value;
												const textContent = removeHtmlTags(htmlContent);
												return (
													<Draggable
														key={`#${param.key}_${pvi}`}
														draggableId={`#${param.key}_${pvi}`}
														index={pvi}
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
															>
																<div className="paramArrayItem">
																	<div className="flex-ac w-100" style={{ height: 50 }}>
																		<i className="las la-braille mr-5" />
																		<div
																			className="w-100 flex-ac c-pointer"
																			onClick={() => {
																				let updatedParameterPath = this.props
																					.parameterPath;
																				updatedParameterPath +=
																					updatedParameterPath == ''
																						? `parameters.${pIndex}.nestedComponents.${pvi}`
																						: `.parameters.${pIndex}.nestedComponents.${pvi}`;

																				this.props.setParameterSelected(
																					true,
																					updatedParameterPath
																				);
																			}}
																		>
																			<div>
																				<h4>
																					{pv.title}
																				</h4>
																				{pv.parameters &&
																					pv.parameters.length > 0 &&
																					typeof pv.parameters[0].value ==
																					'string' &&
																					pv.parameters[0].value !== '' && (
																						<p
																							style={{ maxWidth: 138, wordWrap: 'break-word' }}
																							className="small gray"
																						>
																							<div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
																								{textContent}
																							</div>
																						</p>
																					)}
																			</div>
																		</div>
																		{param.componentTemplate && (
																			<UncontrolledDropdown inNavbar>
																				<DropdownToggle className="columnToggle flex-ac">
																					<i className="las la-ellipsis-h" />
																				</DropdownToggle>
																				<DropdownMenu
																					right
																					className="columnDropdown  p-20"
																				>
																					<h5 className='mb-10'>Options</h5>
																					<DropdownItem
																						className="dropdownItem"
																						onClick={(e) => {
																							this.props.duplicateComponentContainerCard(
																								pIndex,
																								pv
																							);
																						}}
																					>
																						<i className="las la-copy mr-10" />Duplicate

																					</DropdownItem>
																					<DropdownItem
																						className="dropdownItem"
																						onClick={() => {
																							onChange(pvi, pv, false);
																						}}
																					>
																						<i className="las la-trash mr-10" />Remove
																					</DropdownItem>
																				</DropdownMenu>
																			</UncontrolledDropdown>
																		)}
																	</div>
																</div>
															</div>
														)}
													</Draggable>
												);
											})}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							{param.componentTemplate && (
								<div
									className="paramArrayAdd actionButton actionOutline"
									onClick={() => {
										const componentTemplate = JSON.parse(JSON.stringify(param.componentTemplate));
										onChange(true, componentTemplate);
									}}
								>
									<i className="las la-plus" />
									<div>Add {param.label}</div>
								</div>
							)}
						</div>
					);
				}
				break;
			case 'object':
				if (Array.isArray(param.value)) {
					paramComponent = (
						<div className="w-100">
							<DragDropContext
								onDragEnd={(result) => {
									if (!result.destination) {
										return;
									}
									const items = reorder(param.value, result.source.index, result.destination.index);
									onChange(items);
								}}
								className="paramArrayElements"
							>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
											{param.value.map((pv, pvi) => {
												return (
													<Draggable
														key={`#${param.key}_${pvi}`}
														draggableId={`#${param.key}_${pvi}`}
														index={pvi}
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={getItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
															>
																<div className="paramArrayItem">
																	<div className="flex-ac">
																		<i className="las la-braille mr-5" />
																		<div
																			className="w-100"
																			onClick={() => {
																				let updatedParameterPath = this.props
																					.parameterPath;
																				updatedParameterPath +=
																					updatedParameterPath == ''
																						? `parameters.${pIndex}.value.${pvi}`
																						: `.value.${pvi}`;
																				/* this.setState({
																					parameterSelected: true,
																					parameterPath: updatedParameterPath
																				}); */
																				this.props.setParameterSelected(
																					true,
																					updatedParameterPath
																				);
																			}}
																		>
																			<div className="">
																				{pv.label}
																			</div>
																		</div>
																		<UncontrolledDropdown inNavbar>
																			<DropdownToggle className="columnToggle flex-ac">
																				<i className="las la-ellipsis-h" />
																			</DropdownToggle>
																			<DropdownMenu
																				right
																				className="columnDropdown p-0"
																			>
																				<DropdownItem
																					className={`dropdownItem c-pointer`}
																				//onClick={}
																				>
																					<div className="">
																						<i className="las la-copy mr-5" />Duplicate
																					</div>
																				</DropdownItem>
																				<DropdownItem
																					className={`dropdownItem c-pointer`}
																					onClick={() => {
																						param.value.splice(pvi, 1);
																						onChange(param.value);
																					}}
																				>
																					<div className="red">
																						<i className="las la-trash mr-5" />Remove
																					</div>
																				</DropdownItem>
																			</DropdownMenu>
																		</UncontrolledDropdown>
																	</div>
																</div>
															</div>
														)}
														{/* this.renderParameters(
											{ key: pvi, value: pv },
											(value) => {
												param.value[pvi] = value;
												onChange(param.value);
											},
											true,
											param.propertyOptions ? param.propertyOptions : null
										) */}
													</Draggable>
												);
											})}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							{param.value.length > 0 && (
								<div
									className="paramArrayAdd actionButton actionSave"
									onClick={() => {
										const componentTemplate = JSON.parse(JSON.stringify(param.componentTemplate));
										param.nestedComponents.push(componentTemplate);
										onChange(param.value);
									}}
								>
									Add Item
								</div>
							)}
						</div>
					);
				} else {
					paramComponent = Object.keys(param.value).map((pv, pvi) => {
						return (
							<div className="paramObject">
								{this.renderParameters(
									pvi,
									{ key: pv, value: param.value[pv] },
									(value) => {
										switch (typeof param.value[pv]) {
											case 'boolean':
												param.value[pv] = value;
												break;
											case 'string':
											case 'number':
												const v = value.target ? value.target.value : value;
												param.value[pv] = v; //.target.value;
												break;
											default:
												param.value[pv] = value;
										}
										onChange(param.value);
									},
									false,
									propertyOptions ? propertyOptions : null
								)}
							</div>
						);
					});
				}
				break;
			default:
				paramComponent = <textarea rows="1" value={param.value} onChange={onChange} />;
		}
		return (
			<div className={`componentParameter ${paramType}`} key={`${param.key}-${pIndex}`}>
				{/* Array.isArray(param.value) ? (
					<label className="paramLabel array calibreRegular collapsed">{param.label || param.key}</label>
				) : !hideLabel ? (
					<label className="paramLabel calibreRegular" style={{ margin: 0 }}>
						{param.label || param.key}{' '}
					</label>
				) : (
					''
				) */}
				{paramComponent}
			</div>
		);
	};

	setToValue(obj, value, path) {
		var i;
		path = path.split('.');
		for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

		obj[path[i]] = value;
	}

	checkDependencyTrail = (param, selectedPartial, selectedComponent) => {
		let validParam = true;
		if (param.dependency && param.dependency.key !== '') {
			let dependency = false;
			if (selectedComponent) {
				dependency =
					selectedComponent.parameters.find((p) => p.key == param.dependency.key) ||
					selectedPartial.parameters.find((p) => p.key == param.dependency.key);
			} else {
				dependency = selectedPartial.parameters.find((p) => p.key == param.dependency.key);
			}

			if (dependency) {
				if (
					!(
						(dependency.value == param.dependency.value && !param.dependency.isNot) ||
						(dependency.value != param.dependency.value && param.dependency.isNot)
					)
				) {
					validParam = false;
				}

				validParam = validParam && this.checkDependencyTrail(dependency, selectedPartial, selectedComponent);
			} else {
				validParam = false;
			}
		}
		return validParam;
	};

	renderNestedComponent = () => {
		const { componentSelected, selectedPartialIndex, parameterPath, editedPage,
			togglePanel,
			expandPanel,
			expanded } = this.props;
		const { selectedNestedComponentTab } = this.state;
		const selectedPartial = componentSelected && editedPage.partials[selectedPartialIndex];
		const breadcrumbs = [];
		const parameterIndex = parameterPath.split('.')[1];
		const nestedIndex = parameterPath.split('.')[3];
		let breadCrumbPath = '';
		const selectedComponent = parameterPath.split('.').reduce(function (p, prop) {
			if (p && p.title) breadcrumbs.push({ label: p.title, value: breadCrumbPath.slice() });
			breadCrumbPath += breadCrumbPath == '' ? `${prop}` : `.${prop}`;
			return p[prop];
		}, selectedPartial);
		const categories = {};
		selectedComponent &&
			selectedComponent.parameters &&
			selectedComponent.parameters.forEach((param, pi) => {
				let validParam = this.checkDependencyTrail(param, selectedPartial, selectedComponent);
				if (validParam) {
					if (!categories[param.category]) {
						categories[param.category] = [pi];
					} else {
						categories[param.category].push(pi);
					}
				}
			});
		const selectedComponentTab = selectedNestedComponentTab[parameterPath]
			? selectedNestedComponentTab[parameterPath]
			: Object.keys(categories)[0];

		return (
			<div >
				{/* <div className='flex-sb flex-ac mb-20 mt-10'>
					<button onClick={expandPanel}>{expanded ? <i className='las la-arrow-alt-circle-right'></i> : <i className='las la-arrow-alt-circle-left'></i>}</button>
					<h2 className="blockHeader">Components</h2>
					<button onClick={togglePanel}><i className='las la-times'></i></button>
				</div> */}
				<div className="flex-ac">
					<div onClick={this.unfocusComponent} className="c-pointer">
						Page
						<i className="las la-angle-right" style={{ fontSize: 10, width: 20, textAlign: 'center' }} />
					</div>
					{breadcrumbs.map((breadcrumb, bi) => {
						return (
							<div
								onClick={() => {
									const deeplyNestedComponent = breadcrumb.value.split('.').length > 2;
									this.props.setParameterSelected(
										deeplyNestedComponent,
										deeplyNestedComponent ? breadcrumb.value : ''
									);
								}}
								className="c-pointer"
							>
								{breadcrumb.label}
								<i
									className="las la-angle-right"
									style={{ fontSize: 10, width: 20, textAlign: 'center' }}
								/>
							</div>
						);
					})}
				</div>
				<div className="flex-ac flex-sb mt-10">
					<InputField
						label="Subcomponent Name"
						value={selectedComponent.title}
						classes="mb-0"
						inputIcon={<i className="las la-pen" />}
						onChange={(e) => {
							this.setToValue(selectedPartial, e.target.value, `${this.props.parameterPath}.title`);
							this.props.updatePartial(selectedPartialIndex, selectedPartial);
						}}
					/>
					<UncontrolledDropdown inNavbar className="mt-10 ml-10">
						<DropdownToggle className="columnToggle flex-ac">
							<i className="las la-ellipsis-h" />
						</DropdownToggle>
						<DropdownMenu
							right
							className="columnDropdown  p-20"
						>
							<h5 className='mb-10'>Options</h5>
							<DropdownItem
								className="dropdownItem"
								onClick={(e) => {
									let currentCrumb = breadcrumbs[breadcrumbs.length - 1];
									let deeplyNestedComponent = currentCrumb.value.split('.').length > 2;

									this.props.setParameterSelected(
										deeplyNestedComponent,
										deeplyNestedComponent ? currentCrumb.value : ''
									);
									this.props.duplicateComponentContainerCard(
										parameterIndex,
										selectedComponent
									);
								}}
							>
								<i className="las la-copy mr-10" />Duplicate

							</DropdownItem>
							<DropdownItem
								className="dropdownItem"
								onClick={() => {
									let adding = nestedIndex;
									const currentComponents =
										selectedPartial.parameters[parameterIndex].nestedComponents;
									let updatedComponents = [];

									updatedComponents = [...currentComponents];
									updatedComponents.splice(adding, 1);

									this.setToValue(
										selectedPartial,
										updatedComponents,
										`parameters.${parameterIndex}.nestedComponents`
									);

									let currentCrumb = breadcrumbs[breadcrumbs.length - 1];
									let deeplyNestedComponent = currentCrumb.value.split('.').length > 2;

									this.props.setParameterSelected(
										deeplyNestedComponent,
										deeplyNestedComponent ? currentCrumb.value : ''
									);
									this.props.updatePartial(selectedPartialIndex, selectedPartial);

								}}
							>
								<i className="las la-trash mr-10" />Remove
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<div className="componentDetails">
					<div className="componentTabs mb-10">
						{Object.keys(categories).map((category, cIndex) => {
							return (
								<div
									className={`componentTab  ${selectedComponentTab === category
										? ' selected'
										: ''}`}
									onClick={() => {
										const updatedNestedComponentTab = { ...this.state.selectedNestedComponentTab };
										updatedNestedComponentTab[parameterPath] = category;
										this.setState({ selectedNestedComponentTab: updatedNestedComponentTab });
									}}
								>
									<div className="fs-16">{category || 'General'}</div>
								</div>
							);
						})}
					</div>
					<div className="partialParameters">
						{categories[selectedComponentTab].map((pIndex) => {
							const param = selectedComponent.parameters[pIndex];
							let validParam = this.checkDependencyTrail(param, selectedPartial, selectedComponent);
							if (validParam) {
								const paramType = param.type || typeof param.value;
								switch (paramType) {
									case 'nestedComponentList':
									case 'componentContainer':
										return this.renderParameters(pIndex, param, (adding, component, reordering) => {
											const currentComponents =
												selectedComponent.parameters[pIndex].nestedComponents;
											let updatedComponents = [];
											if (adding === true) {
												updatedComponents = [...currentComponents, component];
											} else if (adding >= 0 && !reordering) {
												updatedComponents = [...currentComponents];
												updatedComponents.splice(adding, 1);
											} else if (reordering) {
												updatedComponents = component;
											}
											this.setToValue(
												selectedPartial,
												updatedComponents,
												`${this.props.parameterPath}.parameters.${pIndex}.nestedComponents`
											);
											this.props.updatePartial(selectedPartialIndex, selectedPartial);
											//updatePartialParameters(selectedPartialIndex, pIndex, value);
										});
									case 'boolean':
										return this.renderParameters(pIndex, param, (e) => {
											this.setToValue(
												selectedPartial,
												e,
												`${this.props.parameterPath}.parameters.${pIndex}.value`
											);
											this.props.updatePartial(selectedPartialIndex, selectedPartial);
										});
									case 'string':
										return this.renderParameters(pIndex, param, (e) => {
											const value = e.target ? e.target.value : e;
											this.setToValue(
												selectedPartial,
												value,
												`${this.props.parameterPath}.parameters.${pIndex}.value`
											);
											this.props.updatePartial(selectedPartialIndex, selectedPartial);
										});
									case 'form':
									case 'formType':
									case 'pageSelect':
									case 'feedSelect':
									case 'emailSelect':
									case 'groupSelect':
									case 'programSelect':
									case 'sessionSelect':
									case 'select':
										return this.renderParameters(pIndex, param, (e) => {
											const value = e.value;
											this.setToValue(
												selectedPartial,
												value,
												`${this.props.parameterPath}.parameters.${pIndex}.value`
											);
											this.props.updatePartial(selectedPartialIndex, selectedPartial);
											//updatePartialParameters(selectedPartialIndex, pIndex, value);
										});

									default:
										return this.renderParameters(pIndex, param, (e) => {
											const value = e.target ? e.target.value : e;
											this.setToValue(
												selectedPartial,
												value,
												`${this.props.parameterPath}.parameters.${pIndex}.value`
											);
											this.props.updatePartial(selectedPartialIndex, selectedPartial);
											//updatePartialParameters(selectedPartialIndex, pIndex, value);
										});
								}
							}
						})}
					</div>
				</div>
			</div>
		);
	};

	renderPartialDetails = () => {
		const { selectedComponentTab } = this.state;
		const { componentSelected, selectedPartialIndex, editedPage, updatePartialParameters,
			togglePanel,
			expandPanel,
			expanded } = this.props;
		const selectedPartial = componentSelected && editedPage.partials[selectedPartialIndex];
		const categories = {};
		selectedPartial &&
			selectedPartial.parameters.forEach((param, pi) => {
				let validParam = this.checkDependencyTrail(param, selectedPartial);
				if (validParam) {
					if (!categories[param.category]) {
						categories[param.category] = [pi];
					} else {
						categories[param.category].push(pi);
					}
				}
			});
		const selectCTab = categories[selectedComponentTab] ? selectedComponentTab : Object.keys(categories)[0];
		return (
			<div key={`pd_${selectedPartial._id}`}>

				{/* <div className='flex-sb flex-ac mb-20 mt-10'>
					<button onClick={expandPanel}>{expanded ? <i className='las la-arrow-alt-circle-right'></i> : <i className='las la-arrow-alt-circle-left'></i>}</button>
					<h2 className="blockHeader">Components</h2>
					<button onClick={togglePanel}><i className='las la-times'></i></button>
				</div> */}
				<div className="flex-ac mb-10">
					<div onClick={this.unfocusComponent} className="c-pointer">
						Page
						<i className="las la-angle-right mr-5" />
					</div>
				</div>
				<div className="flex-ac flex-sb">
					<div className="w-100 mr-15 flex-ac">
						{selectedPartial.globalComponent && <i className="las la-globe mr-5" />}
						<InputField
							label="Component Name"
							classes="mb-0"
							value={selectedPartial.title}
							inputIcon={<i className="las la-pen" />}
							onChange={(e) => {
								this.setToValue(selectedPartial, e.target.value, 'title');
								this.props.updatePartial(selectedPartialIndex, selectedPartial);
							}}
							subText={`Type: ${selectedPartial.file}`}
						/>
					</div>
					<UncontrolledDropdown inNavbar className="flex-ac shrink">
						<DropdownToggle className="columnToggle">
							<i className="las la-ellipsis-h" />
						</DropdownToggle>
						<DropdownMenu right className="columnDropdown">
							<div className='ph-20'>
								<h5 className='mb-10'>Component Options</h5>

								{!selectedPartial.globalComponent ? (
									<DropdownItem

										onClick={() => this.props.addAsGlobalComponent(selectedPartial)}
									>
										<i className="las la-globe mr-25" />Add To Global Components
									</DropdownItem>
								) : (
									<DropdownItem >

										<i className="las la-plug mr-25" />Disconnect Global Component

									</DropdownItem>
								)}

								{/* selectedPartial.file === "generatedPage" && <DropdownItem
									onClick={() => {
										this.props.toggleSection('remix');
									}}
								>
									<i className='lab la-mix mr-25'></i> Remix Component
								</DropdownItem> */}
								<DropdownItem

									onClick={() => this.props.addAsCustomComponent(selectedPartial)}
								>
									<i className="las la-save mr-25" />Save As Custom Component
								</DropdownItem>
								<DropdownItem

									onClick={() => {
										this.props.duplicatePartial(selectedPartialIndex);
									}}
								>

									<i className="las la-copy mr-25" />Duplicate

								</DropdownItem>
							</div>
							<hr />
							<div className='ph-20'>
								<DropdownItem
									onClick={() => {
										this.props.removePartial(selectedPartialIndex);
										this.unfocusComponent();
									}}
								>
									<i className="las la-trash mr-25 danger" />Remove
								</DropdownItem>
							</div>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				{categories[selectCTab] &&
					<div className="componentDetails">
						<div className="componentTabs mb-10">
							{Object.keys(categories).map((category, cIndex) => {
								return (
									<div
										className={`componentTab  ${selectCTab === category
											? ' selected'
											: ''}`}
										onClick={() => {
											this.setState({ selectedComponentTab: category });
										}}
									>
										<div className="fs-16">{category || 'General'}</div>
									</div>
								);
							})}
						</div>
						<div className="partialParameters">
							{categories[selectCTab].map((pIndex) => {
								const param = selectedPartial.parameters[pIndex];
								let validParam = this.checkDependencyTrail(param, selectedPartial);
								if (validParam) {
									const paramType = param.type || typeof param.value;
									switch (paramType) {
										case 'componentContainer':
											return this.renderParameters(pIndex, param, (adding, component, reordering) => {
												const currentComponents =
													selectedPartial.parameters[pIndex].nestedComponents;
												let updatedComponents = [];
												if (adding === true) {
													updatedComponents = [...currentComponents, component];
												} else if (adding >= 0 && !reordering) {
													updatedComponents = [...currentComponents];
													updatedComponents.splice(adding, 1);
												} else if (reordering) {
													updatedComponents = component;
												}
												selectedPartial.parameters[pIndex].nestedComponents = updatedComponents;
												this.props.updatePartial(selectedPartialIndex, selectedPartial);
												//updatePartialParameters(selectedPartialIndex, pIndex, value);
											});
										case 'form':
										case 'formType':
										case 'pageSelect':
										case 'feedSelect':
										case 'emailSelect':
										case 'groupSelect':
										case 'programSelect':
										case 'select':
											return this.renderParameters(pIndex, param, (e) => {
												const value = e.value;
												updatePartialParameters(selectedPartialIndex, pIndex, value);
											});
										case 'boolean':
											return this.renderParameters(pIndex, param, (e) => {
												updatePartialParameters(selectedPartialIndex, pIndex, e);
											});
										case 'string':
											return this.renderParameters(pIndex, param, (e) => {
												const value = e.target ? e.target.value : e;
												updatePartialParameters(selectedPartialIndex, pIndex, value);
											});
										default:
											return this.renderParameters(pIndex, param, (e) => {
												const value = e.target ? e.target.value : e;
												updatePartialParameters(selectedPartialIndex, pIndex, value);
											});
									}
								}
							})}
						</div>
					</div>
				}
			</div>
		);
	};

	render() {
		const {
			editedPage,
			focusedComponent,
			openComponentLibrary,
			addAsGlobalComponent,
			addAsCustomComponent,
			componentSelected,
			selectedPartialIndex,
			parameterSelected,
			togglePanel,
			expandPanel,
			expanded
		} = this.props;
		const selectedPartial = componentSelected && editedPage.partials[selectedPartialIndex];
		return (
			<Fragment>
				{!componentSelected ? (
					<div className='h-100 pb-20'>
						{/* <div className='flex-sb flex-ac mb-20 mt-10'>
							<button onClick={expandPanel}>{expanded ? <i className='las la-arrow-alt-circle-right'></i> : <i className='las la-arrow-alt-circle-left'></i>}</button>
							<h2 className="blockHeader">Components</h2>
							<button onClick={togglePanel}><i className='las la-times'></i></button>
						</div> */}
						<Droppable droppableId="droppable-page-components">
							{(provided, snapshot) => (
								<div
									ref={provided.innerRef}
									style={getComponentListStyle(snapshot.isDraggingOver)}
									className="fieldListContainer h-100"
								>
									{editedPage.partials.map((partial, partialIndex) => {
										return (
											<Draggable
												key={`#${partial.file}_${partial._id}`}
												draggableId={`#${partial.file}_${partial._id}_${partialIndex}`}
												index={partialIndex}
											>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
														className={`fieldDraggable ${focusedComponent ===
															`#${partial.file}_${partial._id}`
															? 'focused'
															: ''}`}
													>
														<div className="flex-ac w-100 " style={{ height: 50 }}>

															<i className="las la-braille mr-5" />
															<div
																className="w-100 h-100 flex-ac c-pointer"
																onClick={() =>
																	this.focusComponent(partialIndex)}
															>

																{partial.globalComponent && (
																	<i className="las la-globe mr-5" />
																)}
																<h4 className="mb-0">
																	{partial.title}
																</h4>
															</div>

															<div className="flex-ac">
																<UncontrolledDropdown inNavbar>
																	<DropdownToggle className="columnToggle flex-ac">
																		<i className="las la-ellipsis-h" />
																	</DropdownToggle>
																	<DropdownMenu
																		right
																		className="columnDropdown p-0"
																	>
																		<DropdownItem
																			className={`dropdownItem c-pointer`}
																			onClick={() =>
																				this.props.duplicatePartial(
																					partialIndex
																				)}
																		>
																			<div className="">
																				<i className="las la-copy mr-5" />Duplicate
																			</div>
																		</DropdownItem>
																		{!partial.globalComponent ? (
																			<DropdownItem
																				className={`dropdownItem c-pointer`}
																				onClick={() =>
																					addAsGlobalComponent(
																						partial
																					)}
																			>
																				<div className="">
																					<i className="las la-globe mr-5" />Add
																					To Global Components
																				</div>
																			</DropdownItem>
																		) : (
																			<DropdownItem
																				className={`dropdownItem c-pointer`}
																			>
																				<div className="">
																					<i className="las la-plug mr-5" />Disconnect
																					Global Component
																				</div>
																			</DropdownItem>
																		)}

																		<DropdownItem
																			className={`dropdownItem c-pointer`}
																			onClick={() =>
																				addAsCustomComponent(
																					partial
																				)}
																		>
																			<div className="">
																				<i className="las la-save mr-5" />Save
																				As Custom Component
																			</div>
																		</DropdownItem>
																		<DropdownItem
																			className={`dropdownItem c-pointer`}
																			onClick={() =>
																				this.props.removePartial(
																					partialIndex
																				)}
																		>
																			<div className="red">
																				<i className="las la-trash mr-5" />Remove
																			</div>
																		</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</div>
														</div>

													</div>
												)
												}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</div >
				) : parameterSelected ? (
					this.renderNestedComponent()
				) : (
					this.renderPartialDetails()
				)
				}
			</Fragment>
		);
	}
}

export default EditorComponentList;
