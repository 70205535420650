import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';

class SiteSettings extends Component {
	constructor(props) {
		super(props);
		const event = props.event;

		const publicPathOptions = event.pathOptions
			.map((po) => {
				if (po.openToPublic) return { label: po.title, value: po.pathSlug };
			})
			.filter((p) => !!p);
		const privatePathOptions = event.pathOptions.map((po) => {
			return { label: po.title, value: po.pathSlug };
		});
		this.state = {
			publicPathOptions: publicPathOptions,
			privatePathOptions: privatePathOptions,
			eventEmailName: props.eventEmailName,
			eventEmail: props.eventEmail,
			eventSupportEmail: props.eventSupportEmail,
			eventSupportEmailName: props.eventSupportEmailName,

		};
	}

	render() {
		const { publicPathOptions, privatePathOptions, eventEmail, eventEmailName, eventSupportEmailName, eventSupportEmail } = this.state;
		const { defaultPublicPath, defaultUserPath, setPublicPath, setPrivatePath, readOnly, updateEventEmail, updateEventEmailName, updateEventSupportEmail, updateEventSupportEmailName } = this.props;


		const publicPath = publicPathOptions.find((path) => path.value == defaultPublicPath);
		const privatePath = privatePathOptions.find((path) => path.value == defaultUserPath);
		return (
			<div className="sectionBlock">
				<div className="sectionSubtitle">Navigation</div>
				<SelectField
					label="Public Home Page"
					value={publicPath ? publicPath : { label: '', value: '' }}
					name="defaultUserPath"
					disabled={readOnly}
					options={publicPathOptions}
					onChange={(path) => {
						this.setState({ publicPath: path });
						setPublicPath(path.value);
					}}
				/>
				<SelectField
					label="Private Home Page"
					disabled={readOnly}
					value={privatePath ? privatePath : { label: '', value: '' }}
					name="defaultUserPath"
					options={privatePathOptions}
					onChange={(path) => {

						this.setState({ privatePath: path });
						setPrivatePath(path.value);
					}}
				/>
				<div className="sectionSubtitle">Communication</div>
				<InputField
					label="Event Support Name"
					disabled={readOnly}
					value={eventSupportEmailName}
					onChange={(e) => {
						let val = e.target.value;
						this.setState({ eventSupportEmailName: e.target.value });
						updateEventSupportEmailName(val);
					}}

				/>
				<InputField
					label="Event Support Email"
					disabled={readOnly}
					value={eventSupportEmail}
					onChange={(e) => {
						let val = e.target.value;
						this.setState({ eventSupportEmail: e.target.value });
						updateEventSupportEmail(val)
					}}
				/>

				<InputField
					label="Event Contact Email"
					disabled={readOnly}
					value={eventEmail}
					onChange={(e) => {
						let val = e.target.value;
						this.setState({ eventEmail: e.target.value });
						updateEventEmail(val)
					}}
				/>
				<InputField
					label="Event Contact Email Name"
					disabled={readOnly}
					value={eventEmailName}
					onChange={(e) => {
						let val = e.target.value;
						this.setState({ eventEmailName: val });
						updateEventEmailName(val)
					}}
				/>
			</div>
		);
	}
}
export default SiteSettings;
