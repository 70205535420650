import React, { Fragment } from 'react';

import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';
class DeleteGroupModal extends React.Component {
	state = {
		submitting: false
	};

	delete = () => {
		const group = {};
		group[this.props.group._id] = true;
		this.props.submit(
			group,
			(error) => (error ? this.setState({ error: 'Error deleting group' }) : this.props.toggle),
			this.props.history.push('./')
		);
	};
	render() {
		const { isOpen, toggle, group } = this.props;
		const { submitting } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Group"}
					bodyHeaderText={<span>You are about to delete <b>{group.name}</b> group from the event, this is irreversible, would you like
						to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.delete()}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default withRouter(DeleteGroupModal);
