import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import API from '../../../utils/API';
import moment from 'moment';
import { Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import InputField from '../../../components/inputs/inputField.js';
import TimeField from '../../../components/inputs/timeField';


import TextAreaField from '../../../components/inputs/textAreaField';
import SelectField from '../../../components/inputs/selectField';
const paymentOptions = [
	{ label: "Room and Tax to Master", value: "Room and Tax to Master" },
	{ label: "All Charges to Master", value: "All Charges to Master" },
	{ label: "Attendee Pay on Own", value: "Attendee Pay on Own" }
];

class EventAttendeeRooming extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.toggle2 = this.toggle2.bind(this);
		this.state = {
			editing: false,
			editedAttendee: props.editedAttendee,
			genExpanded: true,
			popoverOpen: false,
			popoverIsOpen: false,
		};
	}

	toggleEditing = () => {
		this.setState({ editedAttendee: this.props.attendee, editing: true });
	};
	toggle = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}
	toggleOff = () => {
		this.setState({
			popoverOpen: this.state.popoverOpen
		});
	}

	toggle2 = () => {
		this.setState({
			popoverIsOpen: !this.state.popoverIsOpen
		});
	}
	toggle2Off = () => {
		this.setState({
			popoverIsOpen: this.state.popoverIsOpen
		});
	}

	saveInfo = async () => {
		const { eventId, orgId } = this.props;
		const { editedAttendee } = this.state;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/attendees/${editedAttendee._id}`, {
				...editedAttendee
			})
			.then((res) => {
				if (res.data) {
					this.props.updateAttendee(editedAttendee._id, res.data);
					this.setState({ editing: false, editedAttendee: res.data });
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	cancelEdit = () => {
		this.setState({ editedAttendee: this.props.attendee, editing: false });
	};

	render() {
		const { editing } = this.state;
		const { readOnly, editedAttendee } = this.props;

		const arrivalDate = moment(`${editedAttendee.roomingInformation.arrivalDate}`).format('YYYY-MM-DD');
		const arrivalTime = editedAttendee.roomingInformation.arrivalTime;
		const checkInDate = moment(`${editedAttendee.roomingInformation.checkInDate}`).format('YYYY-MM-DD');
		const departureDate = moment(`${editedAttendee.roomingInformation.departureDate}`).format('YYYY-MM-DD');
		const departureTime = editedAttendee.roomingInformation.departureTime;
		const checkOutDate = moment(`${editedAttendee.roomingInformation.checkOutDate}`).format('YYYY-MM-DD');

		//convert to MM/DD/YYYY and create variables
		const flightAririval = editedAttendee.travelInformation.arrivalDate
			? moment(new Date(editedAttendee.travelInformation.arrivalDate)).format('MM/DD/YYYY')
			: '';
		const hotelCheckin = editedAttendee.roomingInformation.checkInDate
			? moment(new Date(editedAttendee.roomingInformation.checkInDate)).format('MM/DD/YYYY')
			: '';
		const eventArrival = editedAttendee.roomingInformation.arrivalDate
			? moment(new Date(editedAttendee.roomingInformation.arrivalDate)).format('MM/DD/YYYY')
			: '';
		// Check if empty 
		const Flag1 = flightAririval == 'Invalid date' ? true : false
		const Flag2 = eventArrival == 'Invalid date' ? true : false
		const Flag3 = hotelCheckin == 'Invalid date' ? true : false
		const Flag4 = hotelCheckin == 'Invalid date' && eventArrival == 'Invalid date';
		//Check if Dates are equal
		const Flag5 = hotelCheckin == flightAririval && flightAririval == eventArrival && flightAririval == hotelCheckin;
		const Flag6 = eventArrival == hotelCheckin;
		//If empty show No Date else show date
		const FlightArrival = Flag1 ? "No Date Set - Flight Arrival" : flightAririval + " - Flight Arrival";
		const EventArrival = Flag2 ? "No Date Set - Event Arrival" : eventArrival + " - Event Arrival";
		const HotelCheckin = Flag3 ? "No Date Set - Hotel Check In" : hotelCheckin + " - Hotel Check In";
		// Set Warning Flag
		const ShowWarning = !(!Flag6 || (!Flag1 && !Flag5))

		//Icon logic 
		let icon = ShowWarning ? false : <i id="Popover1" onMouseEnter={this.toggle} onMouseLeave={this.toggleOff} onClick={this.toggleOff} class="las la-lg la-exclamation-triangle red" />;
		let icon2 = ShowWarning ? false : <i id="Popover2" onMouseEnter={this.toggle2} onMouseLeave={this.toggle2Off} onClick={this.toggle2Off} class="las la-lg la-exclamation-triangle red" />;

		return (
			<>
				<h2 className='blockHeader '>Rooming Information</h2>

				<div className='blocksContainer'>
					<div className='sectionBlock mr-25'>
						<div >
							{ShowWarning ? false :
								<Popover placement="left" className="popoverWarrning  " isOpen={this.state.popoverOpen} target="Popover1" toggle={this.toggle}    >
									<PopoverBody> Notice: Date mismatch </PopoverBody>
									<PopoverBody className="popoverWarrningBody">
										<div>{FlightArrival} </div>
										<div>{EventArrival} </div>
										<div>{HotelCheckin} </div>
									</PopoverBody>
								</Popover>
							}
							<InputField
								label={'Arrival Date'}
								warningIcon={icon}
								type={'date'}
								value={arrivalDate}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											arrivalDate: moment(e.target.value).format('MM/DD/YYYY')
										}
									});
								}}
							/>

							<TimeField
								label={'Arrival Time'}
								value={arrivalTime}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											arrivalTime: e
										}
									});
								}}
							/>
							{ShowWarning ? false :
								<Popover placement="left" className="popoverWarrning  " isOpen={this.state.popoverIsOpen} target="Popover2" toggle2={this.toggle2} >
									<PopoverBody> Notice: Date mismatch </PopoverBody>
									<PopoverBody className="popoverWarrningBody">
										<div>{FlightArrival} </div>
										<div>{EventArrival} </div>
										<div>{HotelCheckin} </div>
									</PopoverBody>
								</Popover>
							}
							<InputField
								label={'Check In Date'}
								type={'date'}
								warningIcon={icon2}
								value={checkInDate}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											checkInDate: moment(e.target.value).format('MM/DD/YYYY')
										}
									});
								}}
							/>

							<InputField
								label={'Departure Date'}
								type={'date'}
								value={departureDate}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											departureDate: moment(e.target.value).format('MM/DD/YYYY')
										}
									});
								}}
							/>

							<TimeField
								label={'Departure Time'}
								value={departureTime}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											departureTime: e
										}
									});
								}}
							/>

							<InputField
								label={'Check Out Date'}
								type={'date'}
								value={checkOutDate}
								inputIcon={<i className="lar la-calendar" />}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											checkOutDate: moment(e.target.value).format('MM/DD/YYYY')
										}
									});
								}}
							/>

							<InputField
								label={'Number of Days Stay'}
								value={editedAttendee.roomingInformation.numberOfDaysStay}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											numberOfDaysStay: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Room Type'}
								value={editedAttendee.roomingInformation.roomType}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											roomType: e.target.value
										}
									});
								}}
							/>

							<InputField
								label={'Hotel Confirmation Number'}
								value={editedAttendee.roomingInformation.hotelConfirmationNumber}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											hotelConfirmationNumber: e.target.value
										}
									});
								}}
							/>

							<SelectField
								label={'Payment Type'}
								value={paymentOptions.find((option) => option.value === editedAttendee.roomingInformation.paymentType)}
								options={paymentOptions}
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											paymentType: e.value
										}
									});
								}}
							/>
							<TextAreaField
								placeholder={''}
								value={editedAttendee.roomingInformation.notes}
								label="Notes"
								type="text"
								onChange={(e) => {
									this.props.toggleEditing(true);
									this.props.editRoomingFields({
										...this.props.editedAttendee,
										roomingInformation: {
											...this.props.editedAttendee.roomingInformation,
											notes: e.target.value
										}
									});
								}}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default EventAttendeeRooming;
