import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Dropzone from 'react-dropzone';

import InputField from '../../../components/inputs/inputField.js';
import SelectField from '../../../components/inputs/selectField.js';
import TimeField from '../../../components/inputs/timeField.js';
import FilesUploaderModal from '../../../components/files/filesUploaderModal.js';
import FileChooserPreview from '../../../components/inputs/fileChooserPreview.js';
import ToggleField from '../../../components/inputs/toggleField.js';

let dropzoneRef;

const statusOptions = [
	{ label: 'Imported', value: 'imported' },
	{ label: 'Invited', value: 'invited' },
	{ label: 'Registered', value: 'registered' },
	{ label: 'Not attending', value: 'not attending' },
	{ label: 'Checked in', value: 'checked in' },
	{ label: 'Incomplete', value: 'incomplete' },
	{ label: 'Entered through SSO', value: 'entered through SSO' },
	{ label: 'Removed', value: 'removed' },
	{ label: 'Did not attend', value: 'did not attend' },
	{ label: 'Cancelled', value: 'cancelled' },

];

const getMeta = (url, cb) => {
	const img = new Image();
	img.onload = () => cb(null, img);
	img.onerror = (err) => cb(err);
	img.src = url;
};

class EventAttendeeInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
			accepted: [],
			rejected: [],
			genExpanded: true,
			phoneNumber: '',
			errorPhoneNumber: false,
			errorPhoneMessage: '',
		};
	}

	listItem = (meta, categoryId) => {
		const { readOnly } = this.props;
		let element = null;

		switch (meta.fieldType) {
			case 'boolean':
				element = <SelectField
					label={meta.fieldName}
					value={meta.value == true ? { label: 'Yes', value: true } : { label: "No", value: false }}
					options={[{ label: 'Yes', value: true }, { label: "No", value: false }]}
					onChange={(e) => this.props.updateCustomField(e.value, meta, categoryId)}


				/>;
				break;
			case 'date':
				element = <InputField
					label={meta.fieldName}
					value={meta.value}
					inputIcon={<i className="lar la-calendar" />}
					disabled={readOnly}
					type={'date'}
					onChange={(e) => this.props.updateCustomField(e, meta, categoryId)}
				/>;
				break;
			case 'time':
				element = <TimeField
					label={meta.fieldName}
					disabled={readOnly}
					value={meta.value}
					required={true}
					onChange={(e) => this.props.updateCustomField(e, meta, categoryId)}
				/>;
				break;
			case 'checkbox':
				element = <InputField
					label={meta.fieldName}
					checked={meta.value}
					value={meta.value}
					disabled={readOnly}
					type={meta.fieldType}
					onChange={(e) => this.props.updateCustomField(e, meta, categoryId)}
				/>;
				break;
			case 's3file':
				meta.value = meta.value ? meta.value : '';
				element = <div className="editorFileInput" >
					<FileChooserPreview
						label={meta.fieldName}
						value={meta.value}
						onClick={() => {
							this.props.updateCustomField('choosing__' + meta.value, meta, categoryId)
						}}
						clearFile={() => {
							this.props.updateCustomField('', meta, categoryId)

						}}
					/>
					{
						meta.value && meta.value.includes('choosing__') &&
						<FilesUploaderModal
							isOpen={meta.value.includes('choosing__')}
							toggle={() => {
								this.props.updateCustomField(meta.value.replace('choosing__', ''), meta, categoryId)
							}}
							eventId={this.props.eventId}
							links={{
								addAssets: `Organizations/${this.props.orgId}/events/${this.props.eventId}/assets`,
								getAssets: `Organizations/${this.props.orgId}/events/${this.props.eventId}/assets`
							}}
							orgId={this.props.orgId}
							addFiles={(fileURL) => {

								getMeta(fileURL, (err, img) => {
									this.props.updateCustomField(fileURL, meta, categoryId)
								});
							}}
						/>
					}
				</div>;
				break;
			default:
				element = <InputField
					label={meta.fieldName}
					value={meta.value}
					disabled={readOnly}
					type={meta.fieldType}
					onChange={(e) => this.props.updateCustomField(e, meta, categoryId)}
				/>;
		}
		return (
			element
		);
	};

	displayMetadataFields = () => {
		const { customFields } = this.props;
		return Object.keys(customFields).map((categoryId) => {
			const categoryFields = customFields[categoryId];
			const categoryTitle = categoryFields.title;
			const categoryExpanded = categoryFields.expanded;

			let fieldCount = 0;
			const categoryRow = (
				<div className='sectionBlock'>
					<h2 className="blockHeader">{categoryTitle}</h2>
					<div style={{ display: categoryExpanded ? 'block' : 'none' }}>
						{Object.keys(categoryFields).map((fieldKey) => {
							if (fieldKey !== 'title' && fieldKey !== 'expanded') {
								const fieldData = customFields[categoryId][fieldKey];
								fieldCount++;
								return this.listItem(fieldData, categoryId);
							}
						})}
					</div>
				</div>

			);
			return fieldCount > 0 && categoryRow;
		});
	};

	onDrop = (files) => {
		this.props.editAttendeeField('files', files);
	};

	verifyPhone(value) {
		const phoneNumber = value
		if (phoneNumber.length >= 8) {
			const data = JSON.stringify({ phone: phoneNumber });
			fetch("https://intl-tel-input-7366.twil.io/lookup", {
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: data,
			})
				.then(response => {
					return response.json();
				})
				.then((json) => {
					if (json.success) {
						this.setState({ errorPhoneNumber: false });
					} else {
						this.setState({ errorPhoneMessage: json.error });
						this.setState({ errorPhoneNumber: true });
					}
				})
				.catch((error) => {
					console.error("Error with fetch operation:", error);
				});
		} else {
			this.setState({ errorPhoneNumber: false, errorPhoneMessage: null });
		}
	}
	render() {
		const { editedAttendee, editAttendeeField, editing, readOnly, requiredFields } = this.props;
		let statusValue = statusOptions.find(s => s.value == editedAttendee.status)

		return (
			<div className='sectionBlock'>
				<h2 className='blockHeader'>Basic Details</h2>
				<div >
					<InputField
						label="First Name"
						value={editedAttendee.firstName}
						required={true}
						errorTrigger={requiredFields.firstName}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('firstName', e.target.value)}
					/>

					<InputField
						label="Last Name"
						value={editedAttendee.lastName}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('lastName', e.target.value)}
					/>

					<InputField
						label="Email"
						value={editedAttendee.email}
						disabled={readOnly}
						required={true}
						errorTrigger={requiredFields.email}
						type="text"
						onChange={(e) => editAttendeeField('email', e.target.value)}
					/>

					<InputField
						label="BCC"
						required={false}
						placeholder="Add emails separated by commas..."
						value={editedAttendee.bcc}
						errorTrigger={requiredFields.bcc}
						errorMessage={'Invalid Email detected'}
						information={'This email address will receive a copy of every email sent to this attendee'}
						onChange={(e) => {

							editAttendeeField(
								'bcc', e.target.value
							)
						}}
					/>

					<InputField
						label="Phone Number"
						value={editedAttendee.phoneNumber}
						disabled={readOnly}
						type="tel"
						errorTrigger={this.state.errorPhoneNumber}
						errorMessage={this.state.errorPhoneMessage}
						onChange={(e) => {
							let cleanValue = e.target.value.replace(/[^0-9+]/g, '');
							cleanValue = !cleanValue.startsWith("+")
								? cleanValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
								: cleanValue.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
							this.verifyPhone(cleanValue);
							editAttendeeField('phoneNumber', cleanValue);
						}}
					/>

					<InputField
						label="Title"
						value={editedAttendee.title}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('title', e.target.value)}
					/>

					<InputField
						label="Company"
						value={editedAttendee.company}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('company', e.target.value)}
					/>

					<ToggleField
						label="Text Message Opt In"
						checked={editedAttendee.textMessageOptIn}
						disabled={readOnly}
						icons={false}
						onChange={(e) => editAttendeeField('textMessageOptIn', e.target.checked)}
					/>

					<InputField
						label="Dietary Preference"
						value={editedAttendee.dietaryPreference}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('dietaryPreference', e.target.value)}
					/>

					<InputField
						label="Food Allergies"
						value={editedAttendee.foodAllergies}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('foodAllergies', e.target.value)}
					/>

					<InputField
						label="Emergency Contact Name"
						value={editedAttendee.emergencyContactName}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('emergencyContactName', e.target.value)}
					/>

					<InputField
						label="Emergency Contact Phone Number"
						value={editedAttendee.emergencyContactPhoneNumber}
						disabled={readOnly}
						type="tel"
						errorTrigger={this.state.errorPhoneNumber}
						errorMessage={this.state.errorPhoneMessage}
						onChange={(e) => {
							let cleanValue = e.target.value.replace(/[^0-9+]/g, '');
							cleanValue = !cleanValue.startsWith("+")
								? cleanValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
								: cleanValue.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
							this.verifyPhone(cleanValue);
							editAttendeeField('emergencyContactPhoneNumber', cleanValue)
						}}
					/>

					<InputField
						label="Address"
						value={editedAttendee.address}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('address', e.target.value)}
					/>

					<InputField
						label="City"
						value={editedAttendee.city}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('city', e.target.value)}
					/>

					<InputField
						label="State"
						value={editedAttendee.state}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('state', e.target.value)}
					/>

					<InputField
						label="Zip"
						value={editedAttendee.zip}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('zip', e.target.value)}
					/>
					<InputField
						label="Country"
						value={editedAttendee.country}
						disabled={readOnly}
						type="text"
						onChange={(e) => editAttendeeField('country', e.target.value)}
					/>

					<SelectField
						label="Status"
						value={statusValue}
						name="Status"
						options={statusOptions}
						onChange={(value) => {
							editAttendeeField('status', value.value);
						}}
					/>

					{/* <h2 className='blockHeader'>Profile Picture</h2>

					<Dropzone
						accept="image/*"
						className="clickable dropzone-details"
						inputProps={{ style: { margin: 'auto' } }}
						ref={(node) => {
							dropzoneRef = node;
						}}
						onDrop={this.onDrop.bind(this)}
					>
						{editedAttendee.files && editedAttendee.files.length > 0 ? (
							<img className="dropHeaderImage" src={editedAttendee.files[0].preview} />
						) : (
							editedAttendee.profilePicture && (
								<img className="dropHeaderImage" src={editedAttendee.profilePicture} />
							)
						)}
						<div className="dropzoneDescriptionContainer">
							<p>
								<span style={{ color: '#2B83F1' }}>Browse</span>, or drop profile picture
								here.
							</p>
						</div>
					</Dropzone> */}
				</div>
				{this.displayMetadataFields(editing)}
			</div>
		);
	}
}

export default EventAttendeeInfo;
