import React from 'react';

import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import { withRouter } from 'react-router-dom';
import * as IFrame from 'react-iframe-form';
import TabsContainer from '../../../../components/views/TabsContainer';

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

class AddCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {
				name: false
			},
			selectedSection: 0,
			selectedTemplate: null,
			codeOnly: false,
			isPlatformTemplate: false
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	addCampaign = async (callback) => {
		this.setState({ submitting: true });
		const { name, selectedTemplate, isPlatformTemplate } = this.state;
		const { eventId, orgId, addCampaign } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!name || name === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			await API().post(`Organizations/${orgId}/events/${eventId}/email`, { name: name, codeOnly: true }).then((res) => {
				const emailObj = res.data;

				if (res.data) {
					const newEmail = {
						...emailObj,
						name: emailObj.name,
						subject: emailObj.subject,
						sentEmailCount: 0,
						openedEmailCount: 0,
						_id: emailObj._id,
						link: `/${orgId}/events/${eventId}/emails/${emailObj._id}`,
						createdBy: emailObj.createdBy,
						createdAt: emailObj.createdAt,
						updatedAt: emailObj.updatedAt
					};

					addCampaign(newEmail, () => {
						//window.open(`/${orgId}/events/${eventId}/emails/${emailObj._id}`, '_self');]
						this.props.toggle();
						this.props.history.push(`/${orgId}/events/${eventId}/emails/${emailObj._id}`)
					});
				} else {
				}
			});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	updateSelected = (selectedIndex) => {
		const { selectedSection } = this.state;

		this.setState({
			selectedSection: selectedIndex
		});
	};

	selectTemplate = (templateId, isPlatformTemplate) => {
		const { selectedTemplate } = this.state;

		this.setState({ selectedTemplate: templateId, codeOnly: false, isPlatformTemplate: isPlatformTemplate ? true : false });

	}

	render() {
		const { isOpen, toggle, templates, orgId, eventId, platformTemplates } = this.props;
		const { name, submitting, requiredFields, selectedSection, selectedTemplate, codeOnly, isPlatformTemplate } = this.state;
		const selectedTemplateObj = isPlatformTemplate ? platformTemplates.find((template) => template._id == selectedTemplate) : templates.find((template) => template._id == selectedTemplate);
		let url = `${ENV}Organizations/${orgId}/emailrenderer`;

		let method = 'post';
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Create New Campaign"}
					bodyHeaderText={''}
					//bodyDescription={`Please enter a unique name for your new email campaign.`}
					isFullWidthBody={true}
					bodyContent={() => {
						return (
							<div>
								<div className='sectionBlock m-a mb-20'>
									<h2 className='blockHeader mb-10 '>Enter a unique name for your new email campaign</h2>
									<InputField
										label="Campaign Name"
										required={true}
										errorTrigger={requiredFields.name}
										value={name}
										onChange={this.handleNameChange}
									/>
								</div>
							</div>

						)
					}
					}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Creating Campaign...'}
					cancelButtonLabel={'Cancel'}

					actionButton={() => this.addCampaign({ name })}
					submitting={submitting}
					actionButtonDisabled={submitting}

				></FieldModal >

			</div >
		);
	}
}

export default withRouter(AddCampaignModal);
