import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import cityTimezones from 'city-timezones';
import InputField from '../../../components/inputs/inputField.js';
import SelectField from '../../../components/inputs/selectField.js';
import TimeField from '../../../components/inputs/timeField';
import VenuesLocationField from '../../../components/venues/venuesLocationField';
import SectionHeader from '../../../components/views/SectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import ToggleField from '../../../components/inputs/toggleField';
import Typography from '../../../views/account/typography';
import ColorField from '../../../components/inputs/colorField';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import SectionFooter from '../../../components/views/SectionFooter';
import FileChooserPreview from '../../../components/inputs/fileChooserPreview';
import FilesUploaderModal from '../../../components/files/filesUploaderModal';
import FileUploadInputBox from '../../../components/inputs/fileUploadInputBox.js';
import FieldModal from '../../../components/modals/fieldModal';
import BrandingEditor from '../pages/branding';
import API from '../../../utils/API';
import TextAreaField from '../../../components/inputs/textAreaField';
import AddTeamMemberModal from './modals/addTeamMemberModal.js';

const eventTypeOptions = [{ label: 'Public', value: true }, { label: 'Invite Only', value: false }];
const eventExperienceOptions = [{ label: 'Virtual', value: 'virtual' }, { label: 'In-Person', value: 'in-person' }, { label: 'Hybrid', value: 'hybrid' }];
const iconTypeOptions = [{ label: 'image/png', value: 'image/png' }, { label: 'image/jpg', value: 'image/jpg' }];
const iconSizeOptions = [
	{
		label: '72x72',
		value: '72x72'
	},
	{
		label: '96x96',
		value: '96x96'
	},
	{
		label: '128x128',
		value: '128x128'
	},
	{
		label: '144x144',
		value: '144x144'
	},
	{
		label: '152x152',
		value: '152x152'
	},
	{
		label: '192x192',
		value: '192x192'
	},
	{
		label: '384x384',
		value: '384x384'
	},
	{
		label: '512x512',
		value: '512x512'
	}
];

const tabMap = {
	details: 0,
	access: 1,
	branding: 2,
	preferences: 3,
}

const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
class EventDetails extends Component {
	constructor(props) {
		super(props);
		//get url params
		const urlParams = new URLSearchParams(window.location.search);
		const selectedTab = urlParams.get('tab');
		const event = props.event;
		const fields = {};
		const venueOptions = props.venues.map((venue) => {
			return {
				value: venue._id,
				label: venue.name,
				color: '#000'
			};
		})
		this.props.customFields.forEach((category) => {
			fields[category._id] = { title: category.title, expanded: true };
			category.fields.forEach((field) => {
				fields[category._id][field._id] = { ...field };
			});
		});
		event.metadata.forEach((meta) => {
			try {
				fields[meta.categoryId][meta.fieldId].value = meta.fieldValue;
			} catch (e) {
				console.log(e);
			}
		});

		const loginOptions = event.loginOptions.map((lo) => {
			return { label: lo.title, value: lo.pathSlug };
		});
		const publicPathOptions = event.pathOptions
			.map((po) => {
				if (po.openToPublic) return { label: po.title, value: po.pathSlug };
				else return null;
			})
			.filter((p) => !!p);
		const privatePathOptions = event.pathOptions.map((po) => {
			return { label: po.title, value: po.pathSlug };
		});

		let editedEvent = JSON.parse(JSON.stringify(event));
		if (!editedEvent.branding.logo || editedEvent.branding.logo === '') {
			editedEvent.branding.logo = this.props.organization.branding.logo;
		}
		// Attempt to find a registration page path from a list of privatePathOptions.
		let registrationPagePath = privatePathOptions.find(
			(path) => path.value === editedEvent.registrationPageSlug
		);
		// Check if the registrationPagePath was not found in the privatePathOptions.
		if (!registrationPagePath) {
			editedEvent.registrationPageSlug = '';
		}
		const orgIntegrations = {};
		this.props.organization.integrations.forEach((integration) => {
			orgIntegrations[integration.identifier] = integration;
		})

		const eventVenue = props.venues.find((v) => v._id === event.venue) || { _id: '' };
		const sessionsPages = this.props.event.pathOptions.map(page => {
			return page.isSessionPage ? { label: page.title, value: page._id } : null;
		}).filter(path => path !== null);


		this.state = {
			files: [],
			accepted: [],
			rejected: [],
			editing: false,
			editingStyle: false,
			editedEvent: editedEvent,
			uploadingBanner: false,
			cityLookup: '',
			invalid: false,
			saving: false,
			venue: venueOptions.find((v) => v.value === event.venue) || { _id: '' },
			venues: venueOptions,
			selectedVenue: eventVenue,
			venueAddress: event.venueDetails ? event.venueDetails.address : eventVenue.address ? eventVenue.address : {},
			customFields: fields,
			requiredFields: {
				name: false,
				nameSlug: false,
				startDate: false,
				endDate: false,
				startTime: false,
				endTime: false,
				timezone: false,
				meetingPassword: false,
				SSOLoginUrl: false,
				registrationPageSlug: false
			},
			selectedSection: selectedTab ? tabMap[selectedTab] : 0,
			selectedSectionId: selectedTab ? selectedTab : 'details',
			copiedPassthrough: false,
			brandingEditorOpen: false,
			loginOptions: loginOptions,
			publicPathOptions: publicPathOptions,
			privatePathOptions: privatePathOptions,
			sessionsPages: sessionsPages,
			renderURL: `Organizations/${this.props.orgId}/events/${event._id}/pages/render/stylePreview/`,
			editCount: 0,
			orgIntegrations: orgIntegrations,
			salesforceModalOpen: false,
			salesforceEventCampaign: orgIntegrations.salesforce ? {
				campaignName: '',
				registrationStatus: orgIntegrations.salesforce.requiredFields.find(f => f.id === 'registeredStatus')?.value,
				checkinStatus: orgIntegrations.salesforce.requiredFields.find(f => f.id === 'checkedinStatus')?.value,
				notAttendedStatus: orgIntegrations.salesforce.requiredFields.find(f => f.id === 'didNotAttendStatus')?.value,
			} : {},
			connectingSalesforce: false,
			toggleAddTeamMember: false,
			sfError: '',

		};

	}
	handleNewVenueChange = (e) => {
		const newVenue = {
			label: 'New Location: ' + e,
			color: '#00B8D9',
			new: true
		};
		if (e !== '') {
			this.setState({
				venues: [...this.props.venueOptions, newVenue], editing: true,
			});
		}
	};

	saveDetails = (e) => {
		const { venue, venueAddress } = this.state;
		const editedEvent = this.state.editedEvent;
		editedEvent.venue = this.state.selectedVenue ? this.state.selectedVenue._id : '';
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (key === 'meetingPassword' || key === 'SSOLoginUrl' || key === 'registrationPageSlug') {
				valid = key === 'meetingPassword' && editedEvent.enableMeetingPassword && (!editedEvent[key] || editedEvent[key] === '') ? false : valid;
				valid = key === 'registrationPageSlug' && editedEvent.allowPublicRegistrations && (!editedEvent[key] || editedEvent[key] === '') ? false : valid;
				valid = key === 'SSOLoginUrl' && editedEvent.enableSSOLogin && (!editedEvent[key] || editedEvent[key] === '') ? false : valid;
				valid = key === 'registrationPageSlug' && editedEvent.enableRegistrationPage && (!editedEvent[key] || editedEvent[key] === '') ? false : valid;
				requiredFields[key] = !valid
			} else if (!editedEvent[key] || editedEvent[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			const { customFields } = this.state;
			const metadata = [];
			Object.keys(customFields).forEach((categoryId) => {
				Object.keys(customFields[categoryId]).forEach((fieldId) => {
					if (fieldId !== 'title' && fieldId !== 'expanded') {
						const data = customFields[categoryId][fieldId];
						if (data.value || data.required) {
							metadata.push({
								categoryId: categoryId,
								fieldId: fieldId,
								fieldValue: data.value || ''
							});
						}
					}
				});
			});
			this.setState({ saving: true });

			this.props.saveDetails(
				{ ...this.props.event, ...editedEvent, venueAddress, metadata: metadata, files: this.state.files }, this.state.isNewVenue ? venue : null,
				(updated) => {
					this.setState({
						editedEvent: { ...updated },
						editing: false,
						selectedVenue: updated.venue ? this.props.venues.find(v => v._id == updated.venue) : '',
						saving: false,
						error: '',
						requiredFields: requiredFields
					});
				}
			);
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	saveEventStyle = () => {
		const { editedEvent } = this.state;

		this.props.saveEventStyling(
			{ ...this.props.event, ...editedEvent },
			() => {
				this.setState({
					editingStyle: false,
					editedEvent: { ...editedEvent, style: { ...editedEvent.style }, customCSS: editedEvent.customCSS },
				});
			}
		);
	};

	cancelEdit = () => {
		const event = this.props.event;
		const fields = {};
		this.props.customFields.forEach((category) => {
			fields[category._id] = { title: category.title, expanded: true };
			category.fields.forEach((field) => {
				fields[category._id][field._id] = { ...field };
			});
		});
		event.metadata.forEach((meta) => {
			fields[meta.categoryId][meta.fieldId].value = meta.fieldValue;
		});

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		let venueOptions = this.props.venues.map((venue) => {
			return {
				value: venue._id,
				label: venue.name,
				color: '#000'
			};
		})
		const venueAddress = event.venueDetails ? event.venueDetails.address : event.venue ? this.props.venues.find(v => v._id == event.venue).address : {};
		this.setState({
			editedEvent: { ...event, style: { ...event.style }, customCSS: event.customCSS, branding: { ...event.branding } },
			files: [],
			editing: false,
			invalid: false,
			venue: venueOptions.find((v) => v.value === event.venue) || { _id: '' },
			venueAddress: venueAddress,
			selectedVenue: this.props.venues.find(v => v._id == event.venue),
			requiredFields: requiredFields,
			editCount: this.state.editCount + 1,
		});
	};

	handleStartDateTimeChange = (date) => {
		this.setState((prevState) => ({
			editedEvent: {
				...this.state.editedEvent,
				startDateTime: date._d
			},
			editing: true
		}));
	};

	handleEndDateTimeChange = (date) => {
		this.setState((prevState) => ({
			editedEvent: {
				...this.state.editedEvent,
				endDateTime: date._d
			},
			editing: true
		}));
	};

	handleUploadHeader = () => { };

	toggleBannerUpload = () => {
		this.setState({ uploadingBanner: !this.state.uploadingBanner });
	};

	onDrop = (files) => {
		this.setState({
			files,
			editing: true
		});
	};

	updateCustomField = (e, meta, categoryId) => {
		const customFields = this.state.customFields;
		meta.fieldType === 'checkbox'
			? (customFields[categoryId][meta._id].value = e.target.checked)
			: (customFields[categoryId][meta._id].value = e.target.value);

		this.setState({ customFields: customFields, editing: true });
	};

	listItem = (meta, categoryId) => {
		const { readOnly } = this.props;
		return (
			<InputField
				label={meta.fieldName}
				checked={meta.fieldType === 'checkbox' && meta.value}
				value={meta.value}
				disabled={readOnly}
				type={meta.fieldType}
				onChange={(e) => {
					this.updateCustomField(e, meta, categoryId);
				}}
			/>
		);
	};

	displayMetadataFields = () => {
		const { customFields } = this.state;
		return Object.keys(customFields).map((categoryId) => {
			const categoryFields = customFields[categoryId];
			const categoryTitle = categoryFields.title;

			let fieldCount = 0;
			const categoryRow = (
				<div className="sectionBlock">
					<h2 className='blockHeader'>{categoryTitle}</h2>

					{Object.keys(categoryFields).map((fieldKey) => {
						if (fieldKey !== 'title' && fieldKey !== 'expanded') {
							const fieldData = customFields[categoryId][fieldKey];
							fieldCount++;
							return this.listItem(fieldData, categoryId);
						}
					})}
				</div>
			);
			return fieldCount > 0 && categoryRow;
		});
	};

	updateSelected = (selected, tabId) => {
		this.setState({ selectedSection: selected, selectedSectionId: tabId });
	};

	updateEventStyles = (property, value) => {
		const editedEventStyles = this.state.editedEvent.style;
		editedEventStyles[property] = value;
		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				style: editedEventStyles
			},
			editingStyle: true
		});
	};

	updateEventStylesheet = (e) => {
		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				customCSS: e
			},
			editingStyle: true
		});
	};

	updateEventHeader = (e) => {
		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				headTagScripts: e
			},
			editingStyle: true
		});
	};

	updateEventBodyScripts = (e) => {
		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				bodyTagScripts: e
			},
			editingStyle: true
		});
	};

	applyBrandingToStyling = () => {
		const { editedEvent } = this.state;
		const editedEventStyles = editedEvent.style;
		//editedEvent.branding.backgroundColor
		editedEventStyles.primaryColor = editedEvent.branding.accentColor;
		editedEventStyles.secondaryColor = editedEvent.branding.fontColor;
		editedEventStyles.navigationTextColor = editedEvent.branding.accentColor;
		editedEventStyles.navigationHoverColor = editedEvent.branding.accentColor;
		editedEventStyles.navigationVisitedColor = editedEvent.branding.fontColor;
		editedEventStyles.linkTextColor = editedEvent.branding.accentColor;
		editedEventStyles.linkHoverColor = editedEvent.branding.accentColor;
		editedEventStyles.linkVisitedColor = editedEvent.branding.fontColor;
		editedEventStyles.buttonPrimaryColor = editedEvent.branding.accentColor;
		editedEventStyles.buttonSecondaryColor = editedEvent.branding.fontColor;
		editedEventStyles.profileIconPrimaryColor = editedEvent.branding.accentColor;
		editedEventStyles.profileIconSecondaryColor = editedEvent.branding.fontColor;
		editedEventStyles.scrollbarColor = editedEvent.branding.accentColor;
		editedEventStyles.scrollbarBackgroundColor = editedEvent.branding.backgroundColor;

		this.setState({
			editedEvent: {
				...this.state.editedEvent,
				style: editedEventStyles
			},
			editCount: this.state.editCount + 1,
			editing: true
		});

	}

	connectToSalesforceCampaign = () => {
		const { orgId, event } = this.props;
		const { salesforceEventCampaign } = this.state;
		if (salesforceEventCampaign.campaignName === '') {
			return
		}
		this.setState({ connectingSalesforce: true })
		API()
			.post(`Organizations/${orgId}/events/${event._id}/connectSalesforceCampaign`, salesforceEventCampaign)
			.then((res) => {
				if (res.data) {
					this.props.updateEventIntegration(res.data, () => {
						this.setState({
							editedEvent: { ...this.state.editedEvent, integrations: res.data },
							salesforceModalOpen: false,
							connectingSalesforce: false,
							sfError: '',
						});
					});
				}
			})
			.catch((e) => {
				const err = e.response.data ? e.response.data : 'Error connecting to Salesforce';
				this.setState({
					invalid: true,
					submitting: false,
					connectingSalesforce: false,
					sfError: err
				});
			});

	}
	updateTeam = (updatedTeam, callback) => {
		this.setState({
			editedEvent: { ...this.state.editedEvent, members: updatedTeam },
		}, () => {

			callback && callback();
		});
	}

	renderSection = () => {

		const {
			editedEvent,
			cityLookup,
			requiredFields,
			selectedSectionId,
			copiedPassthrough,
			salesforceModalOpen,
		} = this.state;
		const { event, readOnly, organization, orgId } = this.props;
		const eventId = event._id;

		const registrationPagePath = this.state.privatePathOptions.find(
			(path) => path.value === editedEvent.registrationPageSlug
		);
		const cityTimezoneOptions =
			cityLookup !== ''
				? cityTimezones.findFromCityStateProvince(cityLookup).map((tz) => {
					return { label: `${tz.city}, ${tz.province}`, value: tz.timezone };
				})
				: [];
		const startDate = moment(`${editedEvent.startDate}`).format('YYYY-MM-DD');
		const endDate = moment(new Date(editedEvent.endDate)).format('YYYY-MM-DD');
		let sectionBlock;
		switch (selectedSectionId) {
			case 'details':
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Basic Information</h2>
						<InputField
							label={'Event Name'}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.name}
							value={editedEvent.name}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, name: e.target.value },
									editing: true
								});
							}}
						/>

						<InputField
							label={'URL Slug'}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.nameSlug}
							value={editedEvent.nameSlug}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, nameSlug: e.target.value },
									editing: true
								});
							}}
							subText={`https://${organization.hostname ? organization.hostname : (organization.identifier + '.simple.events')}/${editedEvent.nameSlug}`}
						/>
						<div className="flex-sb w-100">
							<div className="mr-10 w-100">
								<InputField
									label={'Start Date'}
									disabled={readOnly}
									value={startDate}
									required={true}
									errorTrigger={requiredFields.startDate}
									type={'date'}
									inputIcon={<i className="lar la-calendar" />}
									onChange={(e) => {
										this.setState({
											editedEvent: {
												...editedEvent,
												startDate: moment(e.target.value).format('MM/DD/YYYY')
											},
											editing: true
										});
									}}
								/>
							</div>

							<div className="ml-10 w-100">
								<InputField
									label={'End Date'}
									disabled={readOnly}
									value={endDate}
									required={true}
									errorTrigger={requiredFields.endDate}
									type={'date'}
									inputIcon={<i className="lar la-calendar" />}
									onChange={(e) => {
										this.setState({
											editedEvent: {
												...editedEvent,
												endDate: moment(e.target.value).format('MM/DD/YYYY')
											},
											editing: true
										});
									}}
								/>
							</div>
						</div>
						<div className="flex-sb w-100">
							<div className="mr-10 w-100">
								<TimeField
									label={'Start Time'}
									disabled={readOnly}
									value={editedEvent.startTime}
									required={true}
									errorTrigger={requiredFields.startTime}
									onChange={(time) => {
										this.setState({
											editedEvent: {
												...editedEvent,
												startTime: time
											},
											editing: true
										});
									}}
								/>
							</div>

							<div className="ml-10 w-100">
								<TimeField
									label={'End Time'}
									disabled={readOnly}
									value={editedEvent.endTime}
									required={true}
									errorTrigger={requiredFields.endTime}
									onChange={(time) => {
										this.setState({
											editedEvent: {
												...editedEvent,
												endTime: time
											},
											editing: true
										});
									}}
								/>
							</div>
						</div>

						<SelectField
							label={'Experience Type'}
							value={eventExperienceOptions.find((option) => option.value === editedEvent.experienceType)}
							name="Experience Type"
							classes="w-100"
							options={eventExperienceOptions}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.experience}
							onChange={(experience) => {
								this.setState({
									editedEvent: { ...editedEvent, experienceType: experience.value },
									editing: true
								});
							}}
						/></div>
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Location</h2>
						<VenuesLocationField
							selectedVenue={this.state.selectedVenue}
							orgId={this.props.orgId}
							classes="w-100"
							venueOptions={this.props.venues}
							addNewVenue={(v) => this.props.addNewVenue(v, (venue) => {
								this.setState({
									selectedVenue: venue, venueAddress: {
										...venue.address
									}, editing: true
								})
							})}
							renameVenue={(venue) => {
								let selectedVenue = this.state.selectedVenue;
								selectedVenue.name = venue.name
								this.setState({ selectedVenue: selectedVenue })
								this.props.renameVenue(venue);
							}}
							deleteVenue={(venueId) => {

								this.setState({ selectedVenue: '' })
								this.props.deleteVenue(venueId);
							}}
							selectVenue={(venue) => {
								this.setState({
									selectedVenue: venue,
									venueAddress: venue.address,
									editing: true
								})
							}}
						></VenuesLocationField>
						<InputField
							label={"Address 1"}
							value={this.state.venueAddress.address1}
							onChange={(e) => {
								this.setState({
									venueAddress: { ...this.state.venueAddress, address1: e.target.value },
									editing: true
								})
							}}
						/>
						<InputField
							label={"Address 2"}
							value={this.state.venueAddress.address2}
							onChange={(e) => {
								this.setState({
									venueAddress: { ...this.state.venueAddress, address2: e.target.value },
									editing: true
								})
							}}
						/>
						<InputField
							label={"City"}
							value={this.state.venueAddress.city}
							onChange={(e) => {
								this.setState({
									venueAddress: { ...this.state.venueAddress, city: e.target.value },
									editing: true
								})
							}}
						/>
						<div className='flex'>

							<InputField
								label={"State"}
								value={this.state.venueAddress.state}
								onChange={(e) => {
									this.setState({
										venueAddress: { ...this.state.venueAddress, state: e.target.value },
										editing: true
									})
								}}
								classes='mr-10'
							/>
							<InputField
								label={"Postal Code"}
								value={this.state.venueAddress.postalCode}
								onChange={(e) => {
									this.setState({
										venueAddress: { ...this.state.venueAddress, postalCode: e.target.value },
										editing: true
									})
								}}
								classes='mr-10'
							/>
						</div>
						<InputField
							label={"Country"}
							value={this.state.venueAddress.country}
							onChange={(e) => {
								this.setState({
									venueAddress: { ...this.state.venueAddress, country: e.target.value },
									editing: true
								})
							}}
						/>

						<SelectField
							label={'Timezone'}
							value={{ value: editedEvent.timezone, label: editedEvent.timezone }}
							name="timezones"
							classes="w-100"
							options={cityTimezoneOptions}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.timeZone}
							onInputChange={(text) => {
								this.setState({ cityLookup: text });
							}}
							noOptionsMessage={({ inputValue }) =>
								!inputValue ? 'Type city name to get options' : 'No cities match your search'}
							onChange={(timezone) => {
								this.setState({
									editedEvent: { ...editedEvent, timezone: timezone.value },
									editing: true
								});
							}}
						/></div>
					<div className='sectionBlock'>
						{this.displayMetadataFields()}</div>

					{event.requestData && (() => {

						const request = event.requestData;
						return <div className="sectionBlock">
							<h2 className="blockHeader">Request Details</h2>
							<InputField
								label="Submitter Name"
								value={request.submitterName}
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
							<InputField
								label="Submitter Email"
								value={request.submitterEmail}
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
							<InputField
								label="Requested Event Name"
								value={request.name}
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
							<div className="flex-sb w-100">
								<InputField
									label="Start Date"
									type="date"
									value={request.startDate}
									classes="mr-10"
									disabled={true}
									inputIcon={<i className="las la-lock" />}
								/>
								<InputField
									label="End Date"
									type="date"
									classes="ml-10"
									value={request.endDate}
									disabled={true}
									inputIcon={<i className="las la-lock" />}
								/>
							</div>
							<div className="flex-sb w-100">
								<InputField
									label="Start Time"
									type="time"
									classes="mr-10"
									value={request.startTime}
									disabled={true}
									inputIcon={<i className="las la-lock" />}
								/>
								<InputField
									label="End Time"
									type="time"
									classes="ml-10"
									value={request.endTime}
									disabled={true}
									inputIcon={<i className="las la-lock" />}
								/>
							</div>
							{request.fieldLabels &&
								Object.keys(request.fieldLabels).map((field, i) => {
									if (request[field] && request[field] !== '')
										return (
											<InputField
												label={request.fieldLabels[field]}
												type="text"
												value={request[field]}
												disabled={true}
												inputIcon={<i className="las la-lock" />}
											/>
										);
									else return null;
								})}
						</div>
					})}
				</div>
				break;
			case 'access':
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Gate Keeping</h2>
						<SelectField
							label={'Event Type'}
							disabled={readOnly}
							classes="w-100"
							name="eventType"
							options={eventTypeOptions}
							value={editedEvent.allowPublicRegistrations ? eventTypeOptions[0] : eventTypeOptions[1]}
							onChange={(eventType) => {
								this.setState({
									editedEvent: {
										...editedEvent,
										allowPublicRegistrations: eventType.value,
										requestAccessEnabled:
											eventType.value === true ? false : editedEvent.requestAccessEnabled,
										eventType: eventType.label
									},
									editing: true
								});
							}}
						/>
						{editedEvent.allowPublicRegistrations ? (
							<SelectField
								label={'Registration Page'}
								disabled={readOnly}
								classes="w-100"
								name="registrationPageSlug"
								required={true}
								errorTrigger={requiredFields.registrationPageSlug}
								options={this.state.privatePathOptions}
								value={
									registrationPagePath ? (
										registrationPagePath
									) : (
										{
											label: '',
											value: ''
										}
									)
								}
								onChange={(path) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											registrationPageSlug: path.value
										},
										editing: true
									});
								}}
							/>
						) : (
							<ToggleField
								label={'Enable Request Access'}
								onChange={() =>
									this.setState({
										editedEvent: {
											...editedEvent,
											requestAccessEnabled: !this.state.editedEvent.requestAccessEnabled
										},
										editing: true
									})}
								disabled={readOnly}
								icons={false}
								checked={editedEvent.requestAccessEnabled}
							/>
						)}
						{editedEvent.allowPublicRegistrations && (

							<InputField
								label={'Registration Goal (0 = N/A)'}
								disabled={readOnly}
								type="number"
								value={editedEvent.registrationCapacity}
								onChange={(e) => {
									this.setState({
										editedEvent: { ...editedEvent, registrationCapacity: e.target.value },
										editing: true
									});
								}}
							/>
						)}
						{editedEvent.allowPublicRegistrations && (
							<ToggleField
								label={'Enable Registration Auto Login'}
								onChange={() =>
									this.setState({
										editedEvent: {
											...editedEvent,
											autoLoginFromRegistration: !this.state.editedEvent.autoLoginFromRegistration
										},
										editing: true
									})}
								disabled={readOnly}
								icons={false}
								checked={editedEvent.autoLoginFromRegistration}
							/>
						)}
						<ToggleField
							label={'Enable Meeting Password'}
							onChange={() =>
								this.setState({
									editedEvent: {
										...editedEvent,
										enableMeetingPassword: !this.state.editedEvent.enableMeetingPassword
									},
									editing: true
								})}
							disabled={readOnly}
							icons={false}
							checked={editedEvent.enableMeetingPassword}
						/>
						{editedEvent.enableMeetingPassword && (
							<InputField
								label={'Meeting Password'}
								disabled={readOnly}
								required={true}
								errorTrigger={requiredFields.meetingPassword}
								value={editedEvent.meetingPassword}
								onChange={(e) => {
									this.setState({
										editedEvent: { ...editedEvent, meetingPassword: e.target.value },
										editing: true
									});
								}}
							/>
						)}
						{this.props.ssoAvailable && (
							<div>
								<ToggleField
									label={'Enable SSO'}
									checked={editedEvent.enableSSOLogin}
									icons={false}
									disabled={readOnly}
									onChange={() =>
										this.setState({
											editedEvent: {
												...editedEvent,
												enableSSOLogin: !this.state.editedEvent.enableSSOLogin
											},
											editing: true
										})}
								/>

								{editedEvent.enableSSOLogin && (
									<InputField
										label={'SSO URL'}
										className={`editingField`}
										required={true}
										errorTrigger={requiredFields.SSOLoginUrl}
										disabled={readOnly}
										value={editedEvent.SSOLoginUrl}
										onChange={(e) => {
											this.setState({
												editedEvent: { ...editedEvent, SSOLoginUrl: e.target.value },
												editing: true
											});
										}}
									/>
								)}
							</div>
						)}

						<ToggleField
							label={'Enable Magic Link'}
							checked={editedEvent.enablePassthroughPassword}
							icons={false}
							disabled={readOnly}
							onChange={() =>
								this.setState({
									editedEvent: {
										...editedEvent,
										enablePassthroughPassword: !this.state.editedEvent.enablePassthroughPassword
									},
									editing: true
								})}
						/>
						{editedEvent.enablePassthroughPassword && (
							<div className="toggleField">
								<a rel='noreferrer' href={event.passthroughLink} target="_blank" className="se-blue">
									{event.passthroughLink}
								</a>

								<button
									className="prim"
									style={{ minWidth: 80 }}
									onClick={async () => {
										navigator.clipboard.writeText(event.passthroughLink).then(
											() => {
												/* clipboard successfully set */
												this.setState({ copiedPassthrough: true });
												setTimeout(() => this.setState({ copiedPassthrough: false }), 1000);
											},
											() => {
												/* clipboard write failed */
												document.execCommand('copy', true, event.passthroughLink);
												this.setState({ copiedPassthrough: true });
												setTimeout(() => this.setState({ copiedPassthrough: false }), 1000);
											}
										);
									}}
								>
									<i className="las la-copy mr-10" />
									{copiedPassthrough ? 'Copied!' : 'Copy'}
								</button>
							</div>
						)}</div>

				</div>
				break;
			case 'branding':
				sectionBlock = <div className="sectionBody">
					<div className='blocksContainer'>
						<div className='sectionBlock mr-25' style={{ maxHeight: '100%', overflowX: 'hidden', paddingRight: 5 }}>
							<h2 className='blockHeader'>Images</h2>
							<div className="flex-sb-gap w-100">
								<FileUploadInputBox
									label="Logo"
									addText="Image"
									value={!editedEvent.branding.logo || editedEvent.branding.logo === '' ? '' : editedEvent.branding.logo}
									onClick={() => {
										editedEvent.branding.logo = 'choosing__' + editedEvent.branding.logo;
										this.setState({ editedEvent });
									}}
									clearFile={() => {
										editedEvent.branding.logo = '';
										this.setState({
											editedEvent,
											editing: true,
											editCount: this.state.editCount + 1
										});
									}}

								/>
								{editedEvent.branding.logo.includes('choosing__') &&
									<FilesUploaderModal
										isOpen={editedEvent.branding.logo.includes('choosing__')}
										toggle={() => {
											editedEvent.branding.logo = editedEvent.branding.logo.replace('choosing__', '');
											this.setState({
												editedEvent,
												editing: true,
												editCount: this.state.editCount + 1
											});
										}}
										eventId={eventId}
										links={{
											getAssets: `Organizations/${orgId}/events/${eventId}/assets`,
											addAssets: `Organizations/${orgId}/events/${eventId}/assets`,
										}}
										orgId={orgId}
										addFiles={(fileURL) => {
											editedEvent.branding.logo = fileURL;
											this.setState({
												editedEvent,
												editing: true,
												editCount: this.state.editCount + 1
											});
										}}
									/>}
								<FileUploadInputBox
									label="Website Icon"
									addText="Image"
									value={!editedEvent.branding.favicon || editedEvent.branding.favicon === '' ? '' : editedEvent.branding.favicon}
									onClick={() => {
										editedEvent.branding.favicon = 'choosing__' + editedEvent.branding.favicon;
										this.setState({ editedEvent });
									}}
									clearFile={() => {
										editedEvent.branding.favicon = '';
										this.setState({
											editedEvent,
											editing: true,
											editCount: this.state.editCount + 1
										});
									}}
								/>
								{editedEvent.branding.favicon.includes('choosing__') &&
									<FilesUploaderModal
										isOpen={editedEvent.branding.favicon.includes('choosing__')}
										toggle={() => {
											editedEvent.branding.favicon = editedEvent.branding.favicon.replace('choosing__', '');
											this.setState({
												editedEvent,
												editing: true,
												editCount: this.state.editCount + 1
											});
										}}
										eventId={eventId}
										links={{
											getAssets: `Organizations/${orgId}/events/${eventId}/assets`,
											addAssets: `Organizations/${orgId}/events/${eventId}/assets`,
										}}
										orgId={orgId}
										addFiles={(fileURL) => {
											editedEvent.branding.favicon = fileURL;
											this.setState({
												editedEvent,
												editing: true,
												editCount: this.state.editCount + 1
											});
										}}
									/>}
							</div>

							<FileUploadInputBox
								label="Event Banner Image"
								addText="Image"
								value={!editedEvent.branding.headerImage || editedEvent.branding.headerImage === '' ? '' : editedEvent.branding.headerImage}
								onClick={() => {
									editedEvent.branding.headerImage = 'choosing__' + editedEvent.branding.headerImage;
									this.setState({ editedEvent });
								}}
								clearFile={() => {
									editedEvent.branding.headerImage = '';
									this.setState({
										editedEvent,
										editing: true,
										editCount: this.state.editCount + 1
									});
								}}
							/>
							{editedEvent.branding.headerImage.includes('choosing__') &&
								<FilesUploaderModal
									isOpen={editedEvent.branding.headerImage.includes('choosing__')}
									toggle={() => {
										editedEvent.branding.headerImage = editedEvent.branding.headerImage.replace('choosing__', '');
										this.setState({
											editedEvent,
											editing: true,
											editCount: this.state.editCount + 1
										});
									}}
									eventId={eventId}
									links={{
										getAssets: `Organizations/${orgId}/events/${eventId}/assets`,
										addAssets: `Organizations/${orgId}/events/${eventId}/assets`,
									}}
									orgId={orgId}
									addFiles={(fileURL) => {
										editedEvent.branding.headerImage = fileURL;
										this.setState({
											editedEvent,
											editing: true,
											editCount: this.state.editCount + 1
										});
									}}
								/>}
							<div className='blockHeader flex aic jcsb'>

								<h2 className='m-0'>Colors</h2>
								<button className='min' onClick={this.applyBrandingToStyling}>Reapply to Branding</button>
							</div>
							<ColorField
								label="Primary Color"
								value={
									!editedEvent.branding.accentColor || editedEvent.branding.accentColor === '' ? (
										''
									) : (
										editedEvent.branding.accentColor
									)
								}
								disabled={readOnly}
								placeholder={organization.branding.accentColor}
								useHex={true}
								onChange={(e) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedEvent.branding,
												accentColor: e
											}
										},
										editing: true,
										editCount: this.state.editCount + 1
									});
								}}
							/>
							<ColorField
								label="Primary Text Color"
								value={
									!editedEvent.branding.fontColor || editedEvent.branding.fontColor === '' ? (
										''
									) : (
										editedEvent.branding.fontColor
									)
								}
								placeholder={organization.branding.fontColor}
								disabled={readOnly}
								useHex={true}
								onChange={(e) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedEvent.branding,
												fontColor: e
											}
										},
										editing: true
									});
								}}
							/>

							<ColorField
								label="Primary Background Color"
								value={
									!editedEvent.branding.backgroundColor || editedEvent.branding.backgroundColor === '' ? (
										''
									) : (
										editedEvent.branding.backgroundColor
									)
								}
								placeholder={organization.branding.backgroundColor}
								disabled={readOnly}
								useHex={true}
								onChange={(e) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedEvent.branding,
												backgroundColor: e
											}
										},
										editing: true,
										editCount: this.state.editCount + 1
									});
								}}
							/>
							{/* <Dropzone
									accept="image/*"
									className="clickable dropzone-details"
									inputProps={{ style: { margin: 'auto' } }}
									ref={(node) => {
										dropzoneRef = node;
									}}
									onDrop={this.onDrop.bind(this)}
								>
									{this.state.files && this.state.files.length > 0 ? (
										<img className="dropHeaderImage" src={this.state.files[0].preview} />
									) : (
										event.headerImage && <img className="dropHeaderImage" src={event.headerImage} />
									)}
									<div className="dropzoneDescriptionContainer">
										<p>
											<span style={{ color: '#2B83F1' }}>Browse</span>, or drop event banner here.
										</p>
									</div>
								</Dropzone> */}
							<Typography
								fonts={editedEvent.branding}
								accountData={{
									branding: {
										...editedEvent.branding,
										fonts: [...editedEvent.branding.fonts]
									}
								}}
								editAccountData={(editedBranding) => {
									this.setState({
										editedEvent: {
											...editedEvent,
											branding: {
												...editedBranding
											}
										},
										editing: true
									});
								}}
							/>
						</div>

						<div className='sectionBlock emailDesignPreview mt-20'>
							<div
								className="cardDetailsContainerSetup containerTitle"
							>

								<div className='w-100'>
									<h2 className='blockHeader mt-0'> Design</h2>
									<h5 className='formFieldLabel mt-10'> Event Branding Preview </h5>
								</div>

								<button
									className={`neu min ml-a`}
									onClick={() => this.setState({ brandingEditorOpen: true })}
								>
									Edit Branding
								</button>
							</div>
							<div
								className="emailPreview eFrame flex-ac preview"
							>

								{!this.state.brandingEditorOpen && <PagePreviewFrame
									setPreviewDimensions={({ width, height }) => {
										this.setState({
											frameWidth: width,
											frameHeight: height
										});
									}}
									ref={(el) => (this.preview = el)}
									editing={this.state.editCount}
									saving={this.state.saving}
									branding={editedEvent.branding}
									eventStyling={editedEvent.style}
									customCSS={editedEvent.customCSS}
									ENV={ENV}
									renderURL={this.state.renderURL}
									preview={true}
									pageProperties={{}}
									simulatedUser={{}}
									simulatedSession={{}}
								/>}
								{/* <IFrame.Form
									id="builderPreviewFrameTemplate"
									className="emailPreviewWindow Frame"
									key={`campaign-${editCount}`}
									style={{ ponterEvent: 'none', transform: 'scale(0.99)' }}
									name="PreviewFrameCamp"
									config={{ url, method, data }}
								/> */}
							</div>
						</div>
					</div>
					{this.state.brandingEditorOpen && <FieldModal
						size="large"
						isOpen={this.state.brandingEditorOpen}
						cancelButtonLabel={this.state.editingStyle ? 'Discard & Close' : "Close"}
						toggle={() => {
							if (this.state.editingStyle) {
								window.confirm('You have unsaved changes. Are you sure you want to leave?') && this.setState({
									editedEvent: {
										...this.state.editedEvent,
										style: {
											...this.props.event.style
										},
										customCSS: this.props.event.customCSS
									},
									editingStyle: false, brandingEditorOpen: !this.state.brandingEditorOpen
								})
							} else {
								this.setState({
									brandingEditorOpen: !this.state.brandingEditorOpen
								})

							}
						}} modalTitle="Event Branding Editor" bodyContent={() => <div>
							<BrandingEditor
								orgId={this.props.orgId}
								event={this.state.editedEvent}
								updateEventStyles={this.updateEventStyles}
								isOpen={this.state.brandingEditorOpen}
								updateEventStylesheet={this.updateEventStylesheet}
								updateEventHeader={this.updateEventHeader}
								updateEventBodyScripts={this.updateEventBodyScripts}
							/>
						</div>} isFullWidthBody={true} actionButtonLabel="Save" actionButton={this.saveEventStyle} actionButtonDisabled={!this.state.editingStyle}></FieldModal>}
				</div>
				break;
			case 'preferences':
				const { publicPathOptions, privatePathOptions, loginOptions, sessionsPages } = this.state;
				const { defaultPublicPath, defaultUserPath, defaultSessionPage, eventSupportEmailName, eventSupportEmail, eventEmail, eventEmailName, meetingLoginPath } = editedEvent;
				const publicPath = publicPathOptions.find((path) => path.value === defaultPublicPath);
				const privatePath = privatePathOptions.find((path) => path.value === defaultUserPath);
				const loginPath = loginOptions.find((path) => path.value === meetingLoginPath);
				const sessionPage = sessionsPages.find((path) => path.value === defaultSessionPage)
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Default Navigation Settings</h2>

						<SelectField
							label="Login Page"
							value={loginPath ? loginPath : { label: '', value: '' }}
							name="defaultUserPath"
							classes="w-100"
							disabled={readOnly}
							options={loginOptions}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, meetingLoginPath: path.value },
									editing: true
								});
							}}
						/>

						<SelectField
							label="Public Home Page"
							value={publicPath ? publicPath : { label: '', value: '' }}
							name="defaultUserPath"
							classes="w-100"
							disabled={readOnly}
							options={publicPathOptions}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, defaultPublicPath: path.value },
									editing: true
								});
							}}
						/>
						<SelectField
							label="Private Home Page"
							disabled={readOnly}
							classes="w-100"
							value={privatePath ? privatePath : { label: '', value: '' }}
							name="defaultUserPath"
							options={privatePathOptions}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, defaultUserPath: path.value },
									editing: true
								});

							}}
						/>
						<SelectField
							label="Default Session Template"
							disabled={readOnly}
							classes="w-100"
							value={sessionPage ? sessionPage : { label: '', value: '' }}
							name="defaultUserPath"
							options={sessionsPages}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, defaultSessionPage: path.value },
									editing: true
								});
							}}
						/>
					</div>
					<div className='sectionBlock'>
						<h2 className='blockHeader'>Default Communication Settings</h2>
						<InputField
							label="Event Support Name"
							disabled={readOnly}
							value={eventSupportEmailName}
							placeholder={organization.accountSupportName}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventSupportEmailName: e.target.value },
									editing: true
								});
							}}

						/>
						<InputField
							label="Event Support Email"
							disabled={readOnly}
							placeholder={organization.accountSupportEmail}
							value={eventSupportEmail}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventSupportEmail: e.target.value },
									editing: true
								});
							}}
						/>

						<InputField
							label="Event Contact Email Name"
							disabled={readOnly}
							value={eventEmailName}
							placeholder={organization.adminEmailName}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventEmailName: e.target.value },
									editing: true
								});
							}}
						/>
						<InputField
							label="Event Contact Email"
							disabled={readOnly}
							value={eventEmail}
							placeholder={organization.adminEmail}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, eventEmail: e.target.value },
									editing: true
								});
							}}
						/>
					</div>
				</div>
				break;
			case 'integrations':
				const orgIntegrations = this.state.orgIntegrations;
				sectionBlock = <div className="sectionBody">
					<div className='sectionBlock'>
						{orgIntegrations.salesforce && <>
							<div className='flex jcsb'>
								<h2 className='blockHeader'>Salesforce</h2>
								{!event.integrations?.salesforce && <ToggleField icons={false} className='mt-a mb-0' checked={event.integrations?.salesforce}
									onChange={() => {
										this.setState({ salesforceModalOpen: true })
									}}
								/>}
								{salesforceModalOpen && <FieldModal modalTitle={"Connect Event To Campaign"} bodyHeaderText={this.state.sfError !== '' && <span className='red'>{this.state.sfError}</span>} size={'medium'} actionButtonLabel={this.state.connectingSalesforce ? "Submitting" : "Submit"} isOpen={salesforceModalOpen} toggle={() => this.setState({ salesforceModalOpen: false })} actionButtonDisabled={this.state.connectingSalesforce} cancelButtonDisabled={this.state.connectingSalesforce} actionButton={
									this.connectToSalesforceCampaign
								} bodyContent={() => {
									const { salesforceEventCampaign } = this.state;
									return <div className="w-100">
										<InputField
											label={'Campaign Name'}
											value={this.state.salesforceEventCampaign.campaignName}
											required={true}
											placeholder={'Name the Salesforce campaign for this event'}
											errorTrigger={requiredFields.campaignName}
											inputIcon={<i className="lab la-salesforce" />}
											onChange={(e) => {
												this.setState({ salesforceEventCampaign: { ...salesforceEventCampaign, campaignName: e.target.value } });
											}}
										/>

										<InputField
											label={'Registration Status'}
											value={this.state.salesforceEventCampaign.registrationStatus}
											placeholder={'Registered'}
											onChange={(e) => {
												this.setState({ salesforceEventCampaign: { ...salesforceEventCampaign, registrationStatus: e.target.value } });
											}}
										/>

										<InputField
											label={'Checked In Status'}
											value={this.state.salesforceEventCampaign.checkinStatus}
											placeholder={'Checked In'}
											onChange={(e) => {
												this.setState({ salesforceEventCampaign: { ...salesforceEventCampaign, checkinStatus: e.target.value } });
											}}
										/>

										<InputField
											label={'Did Not Attend Status'}
											value={this.state.salesforceEventCampaign.notAttendedStatus}
											placeholder={'Did Not Attend'}
											onChange={(e) => {
												this.setState({ salesforceEventCampaign: { ...salesforceEventCampaign, notAttendedStatus: e.target.value } });
											}}
										/>
									</div>

								}} />}
							</div>
							{event.integrations?.salesforce && <>
								<InputField label="Campaign"

									disabled={true}
									value={event.integrations.salesforce.campaignName
										? event.integrations.salesforce.campaignName
										: 'Not Set'}
									inputIcon={<i className={`lab la-salesforce`} onClick={() => {
										if (event.integrations.salesforce.campaignId) {
											window.open(`${orgIntegrations.salesforce.credentials.instance_url}/lightning/r/Campaign/${event.integrations.salesforce.campaignId}/view`, '_blank');
										} else if (event.integrations.salesforce.campaignName) {
											//call to create campaign from name
										}
									}} />}
									clickIcon={true}
								/>
								<InputField label="Registration Status" disabled={true} value={event.integrations.salesforce.registeredStatus ? event.integrations.salesforce.registeredStatus : 'Not Set'} />
								<InputField label="Checked In Status" disabled={true} value={event.integrations.salesforce.checkedinStatus ? event.integrations.salesforce.checkedinStatus : 'Not Set'} />
								<InputField label="Did Not Attend Status" disabled={true} value={event.integrations.salesforce.notAttendedStatus ? event.integrations.salesforce.notAttendedStatus : 'Not Set'} /></>}
						</>}

					</div>
				</div>
				break;
			case 'request':
				const request = event.requestData;
				sectionBlock = <div className="sectionBody">
					<div className="sectionBlock">
						<h2 className="blockHeader">Request Details</h2>
						<InputField
							label="Submitter Name"
							value={request.submitterName}
							disabled={true}
							inputIcon={<i className="las la-lock" />}
						/>
						<InputField
							label="Submitter Email"
							value={request.submitterEmail}
							disabled={true}
							inputIcon={<i className="las la-lock" />}
						/>
						<InputField
							label="Requested Event Name"
							value={request.name}
							disabled={true}
							inputIcon={<i className="las la-lock" />}
						/>
						<div className="flex-sb w-100">
							<InputField
								label="Start Date"
								type="date"
								value={request.startDate}
								classes="mr-10"
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
							<InputField
								label="End Date"
								type="date"
								classes="ml-10"
								value={request.endDate}
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
						</div>
						<div className="flex-sb w-100">
							<InputField
								label="Start Time"
								type="time"
								classes="mr-10"
								value={request.startTime}
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
							<InputField
								label="End Time"
								type="time"
								classes="ml-10"
								value={request.endTime}
								disabled={true}
								inputIcon={<i className="las la-lock" />}
							/>
						</div>
						{request.fieldLabels &&
							Object.keys(request.fieldLabels).map((field, i) => {
								if (request[field] && request[field] !== '')
									return (
										<InputField
											label={request.fieldLabels[field]}
											type="text"
											value={request[field]}
											disabled={true}
											inputIcon={<i className="las la-lock" />}
										/>
									);
								else return null;
							})}
					</div>
				</div>
				break;
			case "app":
				const mobileIcon = editedEvent.pwaDetails.icons;
				const incomplete = (editedEvent.pwaDetails.name === '' ||
					editedEvent.pwaDetails.shortName === '' ||
					editedEvent.pwaDetails.description === '' ||
					editedEvent.pwaDetails.icons.length === 0 ||
					editedEvent.pwaDetails.defaultPath === '' ||
					editedEvent.pwaDetails.backgroundColor === '');
				sectionBlock = <div className="sectionBody">
					<div className="sectionBlock">
						<h2 className='blockHeader'>Mobile App</h2>
						<ToggleField disabled={incomplete} errorTrigger={incomplete} errorMessage="Fields required" icons={false} className="mb-0" label={'Enable Mobile Application'} checked={editedEvent.pwaEnabled} onChange={() => {
							this.setState({
								editedEvent: {
									...editedEvent,
									pwaEnabled: !editedEvent.pwaEnabled
								},
								editing: true
							})
						}} />
						<h2 className='blockHeader'>Configuration</h2>
						<InputField
							label="Application Name"
							required={true}
							value={editedEvent.pwaDetails.name}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, name: e.target.value } },
									editing: true
								});
							}}
						/>
						<InputField
							label="Application Short Name"
							required={true}
							value={editedEvent.pwaDetails.shortName}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, shortName: e.target.value } },
									editing: true
								});
							}}
						/>

						<InputField
							label="Application Description"
							value={editedEvent.pwaDetails.description}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, description: e.target.value } },
									editing: true
								});
							}}
						/>
						<div className='flex jcsb aic mb-20'>
							<h2 className='blockHeader m-0'>Icons*</h2>
							<button className='neu'
								onClick={() => {
									let icon = { src: '', size: '', type: '' };
									const mobileIcon = editedEvent.pwaDetails.icons;
									mobileIcon.push(icon);
									this.setState({
										editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
										editing: true
									});
								}}
							>Add</button>
						</div>

						{mobileIcon.map((icon, index) => {
							return (
								<div className='mb-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
									<FileChooserPreview
										label="Icon"
										classes="mr-10"
										value={icon.src ? icon.src : ''}
										onClick={() => {
											const icon = mobileIcon[index];
											icon.src = 'choosing__' + icon.src;
											this.setState({
												editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
												editing: true
											});
										}}
										clearFile={() => {
											const icon = mobileIcon[index];
											icon.src = '';
											this.setState({
												editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
												editing: true,
												editCount: this.state.editCount + 1
											});
										}}
									/>
									{icon.src.includes('choosing__') &&
										<FilesUploaderModal
											isOpen={icon.src.includes('choosing__')}
											toggle={() => {
												const icon = mobileIcon[index];
												icon.src = icon.src.replace('choosing__', '');

												this.setState({
													editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
													editing: true,
													editCount: this.state.editCount + 1
												});
											}}
											eventId={eventId}
											links={{
												getAssets: `Organizations/${orgId}/events/${eventId}/assets`,
												addAssets: `Organizations/${orgId}/events/${eventId}/assets`,
											}}
											orgId={orgId}
											addFiles={(fileURL) => {
												const icon = mobileIcon[index];
												icon.src = fileURL;
												this.setState({
													editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
													editing: true,
													editCount: this.state.editCount + 1
												});
											}}
										/>}
									<SelectField
										label="Icon Size"
										options={iconSizeOptions}
										classes="mr-10"
										value={
											icon.size ? (
												{
													label: icon.size,
													value: icon.size
												}
											) : (
												{
													label: '',
													value: ''
												}
											)
										}
										onChange={(path) => {
											const icon = mobileIcon[index];
											icon.size = path.value;
											this.setState({
												editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
												editing: true
											});
										}}

									/>
									<SelectField
										label="Icon Type"
										options={iconTypeOptions}
										classes="mr-10"
										value={
											icon.type ? (
												{
													label: icon.type,
													value: icon.type
												}
											) : (
												{
													label: '',
													value: ''
												}
											)
										}
										onChange={(path) => {
											const icon = mobileIcon[index];
											icon.type = path.value;
											this.setState({
												editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: mobileIcon } },
												editing: true
											});
										}}
									/>

									<button
										className='p-7 mt-15'
										onClick={() => {
											let updatedMobileIcon = mobileIcon.filter((i, newIndex) => {
												return index !== newIndex
											});
											this.setState({
												editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, icons: updatedMobileIcon } },
												editing: true
											});
										}}
									>
										<i className="las la-minus-circle c-pointer" style={{ color: 'red' }} />
									</button>
								</div>
							);
						})}
						<SelectField
							label="Default Home Page"
							required={true}
							options={this.state.privatePathOptions}
							value={editedEvent.pwaDetails.defaultPath ? this.state.privatePathOptions.find(p => p.value === editedEvent.pwaDetails.defaultPath) : { label: '', value: '' }}
							disabled={readOnly}
							onChange={(path) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, defaultPath: path.value } },
									editing: true
								});
							}}
						/>
						<ColorField
							label="Application Color"
							required={true}
							value={editedEvent.pwaDetails.backgroundColor}
							disabled={readOnly}
							useHex={true}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, backgroundColor: e } },
									editing: true
								});
							}}
						/>
						<TextAreaField
							label="Installation Prompt Message"
							value={editedEvent.pwaDetails.iosModalInstallationInstructions}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, iosModalInstallationInstructions: e.target.value } },
									editing: true
								});
							}}
						/>
						<InputField
							label="If installation prompt is dismissed, show again after (hours)"
							value={editedEvent.pwaDetails.promptTimeout}
							disabled={readOnly}
							type="number"
							onChange={(e) => {
								this.setState({
									editedEvent: { ...editedEvent, pwaDetails: { ...editedEvent.pwaDetails, promptTimeout: e.target.value } },
									editing: true
								});
							}}
						/>

					</div>
				</div>
				break
			default:
		}

		return sectionBlock;
	}
	toggleAddTeamMember = () => {
		this.setState({ toggleAddTeamMember: !this.state.toggleAddTeamMember });
	};
	teamMemberElement = () => {
		const { editedEvent } = this.state;

		let eventMembers = editedEvent.members || [];
		let allMembers = this.props.organization.members || [];

		let renderedTeam = [];
		for (let mappedTeamCount = 0; mappedTeamCount < eventMembers.length; mappedTeamCount++) {
			if (mappedTeamCount < 4) {
				renderedTeam.push(
					eventMembers[mappedTeamCount]
				);
			}
		}

		if (renderedTeam.length < 4) {
			for (let allMembersCount = 0; allMembersCount < allMembers.length; allMembersCount++) {
				let currentMember = allMembers[allMembersCount];

				if (!renderedTeam.some(member => member._id === currentMember._id) && currentMember.permission === 'admin') {

					if (renderedTeam.length < 4) {
						renderedTeam.push(currentMember);
					} else {
						break;
					}
				}
			}
		}
		return (
			<div className='flex aic '>
				<button className="flex c-pointer aic mr-30"
					onClick={
						() => {
							this.toggleAddTeamMember();
						}
					}
				>
					<div className='mr-10'>Team</div>
					<div
						className="flex aic jcc "
						style={{
							width: 13,
							height: 13,
							borderRadius: '50%',
							backgroundColor: 'var(--primary)',
							color: 'var(--white)',
							marginRight: -7,
							zIndex: 1,
							fontSize: 7,
						}}
					><i className="las la-plus" /></div>

					{renderedTeam.map((member, i) => {
						let acronym = `${member.firstName.charAt(0)}${member.lastName.charAt(0)}`;
						let emptyProfilePicture = !member || (member && (!member.profilePicture || member.profilePicture === ''));
						return (
							emptyProfilePicture ? (
								<div
									className="add acronymCircle"
									style={{
										marginRight: -15,
										border: '1px solid #D5D5D5'
									}}
								><h4>{i === 3 ? `+${renderedTeam.length}` : acronym}</h4></div>
							)
								:
								(<img alt="profile" src={member.profilePicture}
									className="add acronymCircle profilePicture"
									style={{
										marginRight: -15,
										border: '1px solid #D5D5D5'
									}} />)
						)
					}
					)}
				</button >
			</div >
		);
	}

	render() {
		const {
			editing,
			selectedSection,
			orgIntegrations,
			toggleAddTeamMember,
			editedEvent
		} = this.state;
		const { event, orgId } = this.props;
		const tabs = [
			{ title: <span>{/* <i className="las la-calendar-check mr-5"></i> */}Details</span>, id: 'details' },
			{ title: <span>{/* <i className="las la-key mr-5"></i> */}Access</span>, id: 'access' },
			{ title: <span>{/* <i className="las la-users-cog mr-5"></i> */}Preferences</span>, id: 'preferences' },
			{ title: <span>{/* <i className="las la-mobile mr-5"></i> */}App</span>, id: 'app' },
			{ title: <span>{/* <i className="las la-pencil-ruler mr-5"></i> */}Branding</span>, id: 'branding' },
		]
		if (orgIntegrations && orgIntegrations.salesforce) {
			tabs.push({ title: <span>{/* <i className="las la-code mr-5"></i> */}Integrations</span>, id: 'integrations' })
		}
		if (event.requestData) {
			tabs.push({ title: <span><i className="las la-user-cog mr-5"></i>Request Data</span>, id: 'request' })
		}
		return (
			<div className={`sectionContainer`}>
				<SectionHeader
					breadcrumbs={[
						{
							name: event.name
						}
					]}
					title="Event Details"
					description="Edit the details of your event"
					additionalElements={this.teamMemberElement}

				>
					<TabsContainer
						tabs={tabs}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection} />
				</SectionHeader>
				{this.renderSection()}
				<SectionFooter updatedAt={event.updatedAt} cancelEdit={this.cancelEdit} saveEdit={this.saveDetails} editing={editing} />
				{toggleAddTeamMember &&
					<AddTeamMemberModal
						isOpen={toggleAddTeamMember}
						toggle={this.toggleAddTeamMember}
						users={this.props.organization.members}
						eventTeam={editedEvent.members}
						orgId={orgId}
						eventId={event._id}
						updateTeam={this.updateTeam}
					/>
				}
			</div >
		);
	}
}
export default EventDetails;
