import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Input, Button, Row } from 'reactstrap';
import { CheckCircle, X, XCircle } from 'react-feather';

import Dropzone from 'react-dropzone';
import Select from 'react-select';
import papa from 'papaparse';
import moment from 'moment';

let dropzoneRef;
const UNMAPPED_DATA = 'Unmapped Data';

class ImportGroupSessionsModal extends React.Component {
	constructor(props) {
		super(props);
		const unmappedData = {};
		unmappedData[UNMAPPED_DATA] = {};
		this.state = {
			files: [],
			accepted: [],
			rejected: [],
			customFields: [
				{
					title: 'General Information',
					_id: 0,
					fields: [
						{ fieldName: 'Session Name', fieldType: 'text', _id: 'name' },
						{ fieldName: 'Subtitle', fieldType: 'text', _id: 'subtitle' },
						{ fieldName: 'Description', fieldType: 'text', _id: 'description' },
						{ fieldName: 'Location', fieldType: 'text', _id: 'location' },
						{ fieldName: 'Is General', fieldType: 'checkbox', _id: 'isGeneral' },
						{ fieldName: 'Date', fieldType: 'date', _id: 'date' },
						{ fieldName: 'Is All Day', fieldType: 'checkbox', _id: 'isAllDay' },
						{ fieldName: 'Start Time', fieldType: 'text', _id: 'startTime' },
						{ fieldName: 'End Time', fieldType: 'text', _id: 'endTime' },
						{ fieldName: 'Display Start Time', fieldType: 'text', _id: 'displayStartTime' },
						{ fieldName: 'Display End Time', fieldType: 'text', _id: 'displayEndTime' },
						{ fieldName: 'Display Timezone', fieldType: 'text', _id: 'displayTimezone' },
						{ fieldName: 'Meeting Type', fieldType: 'text', _id: 'virtualMeetingType' },
						{ fieldName: 'Start Offset', fieldType: 'text', _id: 'virtualStartOffset' },
						{ fieldName: 'Stop Offset', fieldType: 'text', _id: 'virtualStopOffset' },
						{ fieldName: 'Meeting Link', fieldType: 'text', _id: 'virtualMeetingLink' },
						{ fieldName: 'Recording Link', fieldType: 'text', _id: 'recordingLink' }
					]
				},
				...this.props.customFields
			],
			submitting: false,
			invalid: false,
			mapping: false,
			mappedData: {
				Category: {
					Header: [ 'data1' ]
				}
			},
			unmappedData: unmappedData,
			fieldOptions: [],
			importedSessions: [],
			finished: false,
			importCount: 0,
			importLength: 0
		};
	}

	downloadCSVTemplate = () => {
		const mappedCategories = [];
		const mappedFieldNames = [];
		const mappedFieldTypes = [];
		this.state.customFields.forEach((category) => {
			category.fields.forEach((field) => {
				const fieldName = field.fieldName;
				mappedCategories.push(category.title);
				mappedFieldTypes.push(field.fieldType);
				mappedFieldNames.push(fieldName);
			});
		});
		const csv = papa.unparse([ mappedFieldNames ]);
		const filename = `SessionImportTemplate.csv`;
		var file = new Blob([ csv ], { type: 'text/csv' });
		if (
			window.navigator.msSaveOrOpenBlob // IE10+
		)
			window.navigator.msSaveOrOpenBlob(file, filename);
		else {
			// Others
			var a = document.createElement('a'),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function() {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	};

	handleImport = () => {
		const mappedData = this.state.mappedData;
		const sessions = [];
		this.setState({ submitting: true });
		Object.keys(mappedData).forEach((category) => {
			if (mappedData[category]._id !== 0) {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];
						data.forEach((sessionData, index) => {
							if (index > 0) {
								const key = index - 1;
								if (!sessions[key] || !sessions[key].metadata) {
									sessions[key] = {
										metadata: []
									};
								}
								sessions[key].metadata.push({
									categoryId: mappedData[category]._id,
									fieldId: data[0],
									fieldValue: sessionData
								});
							}
						});
					}
				});
			} else {
				Object.keys(mappedData[category]).forEach((field) => {
					if (field !== '_id') {
						const data = mappedData[category][field];
						data.forEach((sessionData, index) => {
							if (index > 0) {
								const key = index - 1;
								if (!sessions[key]) {
									sessions[key] = { metadata: [] };
								}

								sessions[key][data[0]] = sessionData;
							}
						});
					}
				});
			}
		});
		this.importSessions(sessions);
	};

	importSessions = async (sessions) => {
		const importedSessions = [];
		this.setState({ submitting: true, mapping: false, importLength: sessions.length });
		let count = 0;
		try {
			for (let s = 0; s < sessions.length; s++) {
				const session = sessions[s];
				const { eventId, orgId } = this.props;
				await API()
					.post(`Organizations/${orgId}/events/${eventId}/session`, { ...session })
					.then((res) => {
						if (res.data) {
							importedSessions.push(res.data);
						}
						if (++count === sessions.length) {
							this.addSessionsToGroup(importedSessions);
						}
					})
					.catch((e) => {
						console.log(e);
						if (++count === sessions.length) {
							this.addSessionsToGroup(importedSessions);
						}
					});
			}
		} catch (ex) {
			console.log(ex);
			this.setState({
				invalid: true,
				submitting: false
			});
		}
	};

	addSessionsToGroup = (importedSessions) => {
		const { eventId, orgId, group } = this.props;
		API()
			.post(`Organizations/${orgId}/events/${eventId}/group`, {
				id: group._id,
				sessions: importedSessions.map((s) => s._id)
			})
			.then((res) => {
				if (res.data) {
					importedSessions.forEach((is) => this.props.addSession(is, true));
					this.setState({ importCount: res.data.sessionsAdded, finished: true });
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	onDrop = (files) => {
		papa.parse(files[0], {
			complete: (res) => {
				const data = this.mapData(res.data);
				const mappedData = data.mappedData;
				const unmappedData = data.unmappedData;
				const fieldData = this.state.customFields.map((category) => {
					const title = category.title;
					const headers = category.fields.map((header) => {
						const name = header.fieldName;
						return {
							_id: header._id,
							category: title,
							value: name,
							label: name
						};
					});
					return {
						label: title,
						value: title,
						options: headers
					};
				});
				this.setState({
					mapping: true,
					mappedData: mappedData,
					unmappedData: unmappedData,
					fieldOptions: fieldData
				});
			}
		});
		this.setState({
			files
		});
	};

	mapData = (csvData) => {
		const { customFields } = this.state;
		const csvHeaders = csvData[0];
		const mappedHeaders = [];
		const mappedData = {};
		customFields.forEach((category) => {
			const categoryTitle = category.title;
			mappedData[categoryTitle] = {};
			mappedData[categoryTitle]._id = category._id;
			category.fields.forEach((field) => {
				const fieldName = field.fieldName;
				mappedData[categoryTitle][fieldName] = [];
				mappedData[categoryTitle][fieldName][0] = field._id;
				const headerIndex = csvHeaders.indexOf(fieldName);
				if (headerIndex > -1) {
					csvData.forEach((row, index) => {
						if (index > 0) {
							if (!mappedData[categoryTitle][fieldName][index]) {
								mappedData[categoryTitle][fieldName][index] = [];
							}
							let value = null;
							switch (field.fieldType) {
								case 'number':
									value = Number.parseFloat(row[headerIndex]);
									break;
								case 'date':
									value = moment(row[headerIndex]).format('MM/DD/YYYY');
									break;
								case 'checkbox':
									value = row[headerIndex].toLowerCase() === 'yes' ? true : false;
									break;
								default:
									value = row[headerIndex];
							}
							mappedData[categoryTitle][fieldName][index] = value;
						}
					});
					mappedHeaders.push(fieldName);
				}
				if (mappedData[categoryTitle][fieldName].length < 2) {
					delete mappedData[categoryTitle][fieldName];
				}
			});
			if (Object.keys(mappedData[categoryTitle]).length < 2) {
				delete mappedData[categoryTitle];
			}
		});

		const unmappedData = {};
		unmappedData[UNMAPPED_DATA] = {};
		csvHeaders.forEach((header, headerIndex) => {
			if (!mappedHeaders.includes(header)) {
				unmappedData[UNMAPPED_DATA][header] = [];
				csvData.forEach((row, index) => {
					if (index > 0) {
						unmappedData[UNMAPPED_DATA][header][index] = row[headerIndex];
					}
				});
			}
		});
		/* if (Object.keys(mappedData[additionalInformation]).length === 0) {
			delete mappedData[additionalInformation];
		} */
		return { mappedData, unmappedData };
	};

	formatGroupLabel = (data) => (
		<div style={groupStyles}>
			<span>{data.label}</span>
			<span style={groupBadgeStyles}>{data.options.length}</span>
		</div>
	);

	FieldContainer = (category, header, columnData, index, size) => {
		const selectedOption = {
			value: header,
			label: header
		};
		return (
			<div className={size ? 'col-6' : 'col-3'} key={index} style={{ marginBottom: 20 }}>
				<div style={{ border: '1px solid lightgray' }}>
					<div style={{ padding: '10px' }}>
						<div style={{ display: 'flex', marginBottom: 5 }}>
							<p style={{ fontWeight: '500', marginBottom: 0 }}>Field Name</p>
							{category != UNMAPPED_DATA && (
								<XCircle
									color="red"
									style={{ margin: 'auto', marginRight: 0 }}
									size={16}
									onClick={(e) => {
										const mappedData = this.state.mappedData;
										const unmappedData = this.state.unmappedData;

										if (!unmappedData[UNMAPPED_DATA]) {
											unmappedData[UNMAPPED_DATA] = [];
											unmappedData[UNMAPPED_DATA][header] = columnData;
											delete mappedData[category][header];
										} else if (!unmappedData[UNMAPPED_DATA][header]) {
											unmappedData[UNMAPPED_DATA][header] = columnData;
											delete mappedData[category][header];
										}
										if (Object.keys(mappedData[category]).length < 2) {
											delete mappedData[category];
										}
										this.setState({ mappedData: mappedData, unmappedData: unmappedData });
									}}
								/>
							)}
						</div>
						<Select
							value={category != UNMAPPED_DATA && selectedOption}
							options={this.state.fieldOptions}
							formatGroupLabel={this.formatGroupLabel}
							onChange={(data) => {
								if (category != UNMAPPED_DATA) {
									const mappedData = this.state.mappedData;
									if (!mappedData[data.category]) {
										mappedData[data.category] = [];
										mappedData[data.category][data.label] = columnData;
										delete mappedData[category][header];
									} else if (!mappedData[data.category][data.label]) {
										mappedData[data.category][data.label] = columnData;
										delete mappedData[category][header];
									}
									this.setState({ mappedData: mappedData });
								} else {
									const mappedData = this.state.mappedData;
									const unmappedData = this.state.unmappedData;
									columnData[0] = data._id;
									if (!mappedData[data.category]) {
										mappedData[data.category] = {};
										mappedData[data.category]._id = data.category._id;
										mappedData[data.category][data.label] = columnData;
										delete unmappedData[category][header];
									} else if (!mappedData[data.category][data.label]) {
										mappedData[data.category][data.label] = columnData;
										delete unmappedData[category][header];
									}

									this.setState({ mappedData: mappedData, unmappedData: unmappedData });
								}
							}}
						>
							X
						</Select>
					</div>
					<Table style={{ margin: 0 }}>
						<thead>
							<tr key={index} style={{ background: 'lightgray' }}>
								<td>{header}</td>
							</tr>
						</thead>
						<tbody>
							{columnData.map((data, index) => {
								if (
									((category !== UNMAPPED_DATA && index > 0) || category === UNMAPPED_DATA) &&
									index < 4
								) {
									return (
										<tr key={index}>
											<td>{data.toString()}</td>
										</tr>
									);
								}
							})}
						</tbody>
					</Table>
				</div>
			</div>
		);
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			submitting,
			mapping,
			mappedData,
			unmappedData,
			finished,
			invalid,
			importCount,
			importLength
		} = this.state;
		const hasUnmappedData = Object.keys(unmappedData[UNMAPPED_DATA]).length > 0;
		let index = 0;
		return (
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				style={{ marginTop: mapping ? '5vh' : '20vh', minWidth: mapping && '92%' }}
			>
				<div className="modalHeader modalHeaderBorder">
					{' '}
					<div className="calibreBold fs-30 modalTitle">
						{mapping ? 'Map Imported Fields' : 'Import Sessions'}
					</div>
					<div className=" modalDescription">
						Bulk upload sessions to your event! Download the{' '}
						<a style={{ textDecoration: 'underline', color: '#2B83F1' }} onClick={this.downloadCSVTemplate}>
							Sessions Upload Template CSV
						</a>, and upload your completed file below.
					</div>
				</div>
				{mapping ? (
					<ModalBody className="modalBody" style={{ paddingBottom: 25 }}>
						<h2 style={{ fontSize: '1.5rem' }}>Match Fields</h2>
						<h3 style={{ fontSize: '1.2rem' }}>
							Fields from the CSV document have been automatically mapped to defined custom fields
						</h3>
						<p style={{ fontSize: '1rem' }}>
							Please check through them and verify that the data has properly been mapped.<br />
							Fields that were not previously defined have been placed under "Unmapped Data" and use the
							provided header as its title.
						</p>
						<p style={{ fontSize: '1rem' }} />
						<hr />
						<Row>
							{hasUnmappedData && (
								<div
									style={{ padding: '10px 30px', height: '45vh', overflowX: 'hidden' }}
									className="col-6"
								>
									{Object.keys(unmappedData).map((key) => {
										const categoryData = unmappedData[key];
										return (
											<div key={key}>
												<h3 style={{ fontSize: '1.2rem', fontWeight: '700', marginBottom: 0 }}>
													{key}
												</h3>
												<hr style={{ marginTop: 5 }} />
												<Row>
													{Object.keys(categoryData).map((dataKey) =>
														this.FieldContainer(
															key,
															dataKey,
															categoryData[dataKey],
															index++,
															true
														)
													)}
												</Row>
											</div>
										);
									})}
								</div>
							)}
							<div
								style={{ padding: '10px 30px', height: '45vh', overflowX: 'hidden' }}
								className={hasUnmappedData ? 'col-6' : 'col-12'}
							>
								{Object.keys(mappedData).map((key) => {
									if (key !== '_id') {
										const categoryData = mappedData[key];
										return (
											<div key={key}>
												<h3 style={{ fontSize: '1.2rem', fontWeight: '700', marginBottom: 0 }}>
													{key}
												</h3>
												<hr style={{ marginTop: 5 }} />
												<Row>
													{Object.keys(categoryData).map(
														(dataKey) =>
															dataKey !== '_id' &&
															this.FieldContainer(
																key,
																dataKey,
																categoryData[dataKey],
																index++,
																hasUnmappedData
															)
													)}
												</Row>
											</div>
										);
									}
								})}
							</div>
						</Row>
					</ModalBody>
				) : (
					<ModalBody className="modalBody" style={{ paddingBottom: 25 }}>
						{!submitting ? (
							<div>
								<div>
									<Dropzone
										accept="text/csv"
										style={{
											position: 'relative',
											height: '200px',
											width: '100%',
											borderWidth: '2px',
											borderColor: '#BBBBBB',
											borderStyle: 'dashed',
											borderRadius: '5px',
											textAlign: 'center',
											paddingTop: '80px'
										}}
										className="clickable"
										inputProps={{ style: { margin: 'auto' } }}
										ref={(node) => {
											dropzoneRef = node;
										}}
										onDrop={this.onDrop.bind(this)}
									>
										<p>
											<span style={{ color: '#2B83F1' }}>Browse</span>, or drop Session CSV file
											here.
										</p>
									</Dropzone>
								</div>
							</div>
						) : (
							<div>
								<h2 style={{ fontSize: '1.5rem' }}>
									{finished ? 'Importing Complete' : 'Importing Sessions...'}
								</h2>

								<h3>
									{importCount} of {importLength} sessions imported
								</h3>
								{invalid && <h3>Error importing sessions</h3>}
							</div>
						)}
					</ModalBody>
				)}

				<ModalFooter className="modalFooter">
					{!finished && (
						<Button
							className="modalButton actionButton actionSave m-0"
							disabled={submitting || !mapping}
							onClick={this.handleImport}
						>
							<div className="modalButtonText">{!submitting ? 'Import' : 'Importing Sessions...'}</div>
						</Button>
					)}
					<Button onClick={toggle} outline className="modalButton actionButton modalCancel mb-0 ml-a">
						<div className="modalCancelText">{finished ? 'Done' : 'Cancel'}</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ImportGroupSessionsModal;

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
};
const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center'
};
