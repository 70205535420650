import React, { Component } from 'react';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import API from '../../utils/API';
import MemberInformation from '../../sections/team/information';
import EventPermissions from '../../sections/team/eventPermissions';

import LoadingWizard from '../../components/spinner/wizard';
import TabsContainer from '../../components/views/TabsContainer';
import { removeCookie } from '../../utils/cookie';
import { withRouter } from 'react-router-dom';
import SectionHeader from '../../components/views/SectionHeader';
import SectionFooter from '../../components/views/SectionFooter';
import DeleteInviteModal from './modals/deleteInvite';

import EmailInvite from './modals/emailInviteModal';

class Invite extends Component {
	constructor(props) {
		super(props);
		this.state = {
			member: {},
			editedMember: {},
			events: [],
			selectedSection: 0,
			DeleteInviteModalOpen: false,
			loading: true,
			editing: false,
			requiredFields: {
				firstName: false,
				emailAddress: false
			},
			selectedFile: null,
			toggleImageCropper: false,
			toggleEmailInvite: false,
		};
	}

	async componentDidMount() {
		const { match: { params }, orgId } = this.props;
		const eventData = await API().get(`Organizations/${orgId}/eventNames`);
		const programData = await API().get(`Organizations/${orgId}/programNames`);
		await API().get(`Organizations/${orgId}/invites/${params.invite}`).then((res) => {

			this.setState({
				invite: JSON.parse(JSON.stringify(res.data)),
				editedInvite: JSON.parse(JSON.stringify(res.data)),
				events: eventData.data.events,
				programs: programData.data.programs,
				loading: false
			});
		});
	}

	toggleDelete = () => {
		this.setState({ DeleteInviteModalOpen: !this.state.DeleteInviteModalOpen });
	};

	SectionItem = (title, sectionNo) => {
		return (
			<div
				className={`sectionTab ${sectionNo === this.state.selectedSection ? 'selected' : ''}`}
				onClick={() => {
					this.setState({ selectedSection: sectionNo });
				}}
			>
				<div className="calibreBold fs-30">{title}</div>
			</div>
		);
	};

	saveImage = async (data, callback) => {
		const { match: { params }, orgId } = this.props;

		API().patch(`Organizations/${orgId}/members/${params.teamMember}`, data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then((res) => {
			if (res.data) {
				this.setState({
					member: JSON.parse(JSON.stringify(res.data)),
					editedMember: JSON.parse(JSON.stringify(res.data)),
					editing: false,
				}, () => {

					callback && callback();
				});
			}
		});
	}
	handleFileChange = (e) => {
		const reader = new FileReader();
		const selectedFile = e.target.files[0];
		reader.readAsDataURL(selectedFile);
		reader.onload = () => {

			this.setState({ selectedFile: reader.result, toggleImageCropper: true });
		}
		reader.onerror = function (error) {
			console.log("Error: ", error);
		};
	};

	toggleImageCropper = () => {
		this.setState({ toggleImageCropper: false });
	};

	updateMember = () => {
		const { invite } = this.state;
		const { orgId } = this.props;
		let editedInvite = JSON.parse(JSON.stringify(this.state.editedInvite));
		let eventPermissions = editedInvite.eventPermissions;
		editedInvite.eventPermissions = Object.keys(eventPermissions).map((key) => {
			let event = eventPermissions[key];
			return {

				...event
			};
		});


		API().patch(`Organizations/${orgId}/invites/${editedInvite._id}`, editedInvite).then((res) => {
			if (res.data) {
				this.setState({
					invite: { ...invite, ...JSON.parse(JSON.stringify(res.data)) },
					editedMember: { ...invite, ...JSON.parse(JSON.stringify(res.data)) },
					editing: false,
				});
			}
		});

	};

	removeMember = () => {
		const { match: { params }, orgId } = this.props;
		API().delete(`Organizations/${orgId}/invites/${params.invite}`).then((res) => {
			if (this.state.invite.isUser) {
				localStorage.clear();
				removeCookie('authToken');
				window.location.replace('/login');
			} else {
				this.props.removeTeamMembers([params.invite]);
				this.props.history.push(`/${orgId}/team`)
			}
		});
	};

	editInviteEventPermission = (editedPermissions, inlineEdit) => {
		let editedInvite = JSON.parse(JSON.stringify(this.state.editedInvite));
		editedInvite.eventPermissions = editedPermissions;
		inlineEdit ? this.setState({ editedInvite: editedInvite, editing: true }) : this.setState({ editedInvite: editedInvite });
	};

	editProgramPermission = (editedPermissions, inlineEdit) => {
		const { editedMember } = this.state;
		editedMember.programPermissions = editedPermissions;
		inlineEdit ? this.setState({ editedMember: editedMember, editing: true }) : this.setState({ editedMember: editedMember });
	};

	updateProgramPermissions = () => {
		const { member, editedMember } = this.state;
		const { match: { params }, orgId } = this.props;
		API().patch(`Organizations/${orgId}/members/${params.teamMember}`, { ...member, programPermissions: editedMember.programPermissions }).then((res) => {
			if (res.data) {
				this.setState({
					member: { ...member, programPermissions: editedMember.programPermissions },
					editedMember: editedMember
				});
			}
		});
	};

	updateInviteEventPermissions = () => {
		const { invite, editedInvite } = this.state;
		const { orgId } = this.props;
		API().patch(`Organizations/${orgId}/invites/${editedInvite._id}`, { ...editedInvite, eventPermissions: editedInvite.eventPermissions }).then((res) => {
			if (res.data) {
				this.setState({
					invite: { ...invite, ...res.data },
					editedInvite: { ...invite, ...res.data },
					editing: false
				});
			}
		});
	};

	cancelProgramPermissionEdit = () => {
		const { member, editedMember } = this.state;
		editedMember.programPermissions = JSON.parse(JSON.stringify(member.programPermissions));
		this.setState({ editedMember: editedMember });
	};

	cancelInvitePermissionEdit = () => {
		const { invite, editedInvite } = this.state;
		editedInvite.eventPermissions = JSON.parse(JSON.stringify(invite.eventPermissions));
		this.setState({ editedInvite: editedInvite });
	};

	editInviteInformation = (property, value) => {
		const { editedInvite } = this.state;
		editedInvite[property] = value;
		this.setState({ editedInvite: editedInvite, editing: true });
	};
	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};
	renderSection() {
		const { selectedSection, editedInvite, events, requiredFields, programs } = this.state;
		let permission = this.props.permission;
		switch (selectedSection) {
			case 0:
				return (
					<MemberInformation
						requiredFields={requiredFields}
						editedMember={editedInvite}
						permission={permission}
						editMemberInformation={this.editInviteInformation}
						isInvite={true}
					/>
				);
			case 1:
				return (
					<EventPermissions
						events={events}
						editedMember={editedInvite}
						editEventPermission={this.editInviteEventPermission}
						cancelEventPermissionEdit={this.cancelInvitePermissionEdit}
						updateEventPermissions={this.updateInviteEventPermissions}
					/>
				);
			/* case 2: {
				return <ProgramPermissions
					programs={programs}
					editedMember={editedInvite}
					editProgramPermission={this.editProgramPermission}
					cancelProgramPermissionEdit={this.cancelProgramPermissionEdit}
					updateProgramPermissions={this.updateProgramPermissions}

				/>
			} */
			default:
		}
	}

	cancelEdit = () => {
		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			editing: false,
			editedMember: JSON.parse(JSON.stringify(this.state.member)),
			requiredFields: requiredFields
		});
	};

	toggleEmailInvite = () => {
		const { toggleEmailInvite } = this.state;
		this.setState({ toggleEmailInvite: !toggleEmailInvite });
	};

	render() {
		const { invite, DeleteInviteModalOpen, selectedSection, loading, editing, toggleImageCropper, editedMember, toggleEmailInvite } = this.state;
		let permission = this.props.permission;
		return <div className={`pageContainer `}>
			{!loading && <div className={`sectionContainer ${editing ? 'editing' : ''}`}>

				<SectionHeader
					updatedAt={invite.updatedAt}
					title={'Invite'}
					enableProfileIcon={false}
					profilePicture={editedMember.profilePicture || null}
					disabledProfileEvents={true}
					profileIconOnClick={null}
					handleProfileFileChange={null}
					handleProfileImageDelete={null}
					titleIcon={
						<div className='profileIconContainer d40'>
							{invite.firstName.toUpperCase().charAt(0)}
							{invite.lastName.toUpperCase().charAt(0)}
						</div>
					}
					enableLastLink={true}
					directLink={`../team`}
					breadcrumbs={[
						{
							name: 'Team',
						},
						{
							name: 'Member',
						}
					]}
					subActions={!permission
						? false : [{ onClick: this.toggleEmailInvite, label: 'Email Invite' },{ onClick: this.toggleDelete, label: 'Delete Invite' }]}
				>
					<TabsContainer
						tabs={[{ title: 'Information', icon: <i className='las la-info-circle mr-10'></i> }, { title: 'Events', icon: <i className='las la-calendar mr-10'></i> }, /* { title: 'Programs', icon: <i className='las la-stream mr-10'></i> } */]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/></SectionHeader>
				<div className="sectionBody">{this.renderSection()}</div>
				<SectionFooter cancelEdit={this.cancelEdit} saveEdit={this.updateMember} updatedAt={invite.updatedAt} editing={editing} />

				<DeleteInviteModal
					isOpen={DeleteInviteModalOpen}
					toggle={this.toggleDelete}
					memberName={invite.firstName.concat(' ').concat(invite.lastName)}
					delete={this.removeMember}
				/>

				{toggleEmailInvite &&
					<EmailInvite
						isOpen={toggleEmailInvite}
						toggle={this.toggleEmailInvite}
						invite={invite}
						orgId={this.props.orgId}
					/>

				}
			</div>}
			<LoadingWizard text="LOADING MEMBER" loading={loading} />
		</div>
	}
}

export default withRouter(Invite);
