import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import Dropzone from 'react-dropzone';
import API from '../../utils/API';
import TabsContainer from '../../components/views/TabsContainer';
import InputField from '../../components/inputs/inputField.js';
import ActionButtonContainer from '../../components/views/ActionButtonContainer';

import FieldModal from '../modals/fieldModal';
import ViewsTable from '../tables/ViewsTable.js';
import FileGrid from '../tables/FileGrid.js';
class FilesUploaderModal extends Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name',
			size: 'Size',
			uploadedByName: 'Uploaded By',
			createdAt: 'Created',
			url: 'URL'
		};
		const columnTypes = {
			name: 'text',
			size: 'text',
			uploadedByName: 'text',
			createdAt: 'timestamp',
			url: 'link'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			files: [],
			folder: props.folder || '',
			uploading: false,
			selectedSection: 0,
			imageURL: '',
			searchedEntries: [],
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedFiles: {},
			searching: false,
			searchText: '',
			selectedColumnSort: null,
			sortDesc: false,
			droppedFiles: [],
			dropOneError: false,
			uploadPercentage: 0,
			uploadComplete: false,
			uploadedFile: ''
		};
	}

	componentDidMount() {
		const { links } = this.props;
		API().get(links.getAssets).then((res) => {
			if (res.data) {
				this.setState({ files: res.data, searchedEntries: res.data });
			}
		});
	}
	onDrop = (files) => {
		if (files.length === 1) {
			const existingFiles = this.state.droppedFiles;
			files.forEach((f) => existingFiles.push(f));
			this.setState({
				droppedFiles: existingFiles,
				dropOneError: false
			});
		} else {
			this.setState({ dropOneError: true });
		}
	};

	uploadFiles = () => {
		const { links } = this.props;
		const { folder, droppedFiles } = this.state;
		if (droppedFiles.length > 0) {
			if (droppedFiles.length === 1) {
				this.setState({
					uploading: true
				});
				const data = new FormData();
				data.append('targetDirectory', folder);
				droppedFiles.forEach((f) => data.append(`files`, f));

				const options = {
					onUploadProgress: (progressEvent) => {
						const { loaded, total } = progressEvent;
						let percent = Math.floor(loaded * 100 / total);
						console.log(` ${loaded}kb of ${total}kb | ${percent}%`);

						if (percent < 100) {
							this.setState({ uploadPercentage: percent });
						}
					},
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				};

				API()
					.post(links.addAssets, data, options)
					.then((res) => {
						console.log(res);

						this.setState({ uploadPercentage: 100 });

						if (res.data) {
							this.setState({ uploading: false, uploadComplete: true, uploadedFile: res.data.assets[0].url });
							//this.props.toggle();
						}
					})
					.catch((e) => {
						console.log(e);
						this.setState({ error: true, uploading: false });
					});
			} else {
				this.setState({ dropOneError: true });
			}
		}
	};

	submit = () => {
		const { selectedSection, checkedFiles, files, imageURL, uploadComplete, uploadedFile } = this.state;
		console.log(selectedSection);
		switch (selectedSection) {
			case 0:
				const fileSelected = files.find((f) => f._id === Object.keys(checkedFiles)[0]);
				const fileSelectedURL = fileSelected.url;
				this.props.addFiles(fileSelectedURL);
				break;
			case 1:
				uploadComplete ? this.props.addFiles(uploadedFile) : this.uploadFiles();

				break;
			case 2:
				this.props.addFiles(imageURL);

				break;
			default:
		}
	};

	removeFile = (file) => {
		const filteredFiles = this.state.droppedFiles.filter((f) => f.name !== file.name);
		this.setState({ droppedFiles: filteredFiles });
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};
	updatedCheckedFiles = (checked) => {
		this.setState({ checkedFiles: checked });
	};
	toggleLayout = (selected) => {
		this.setState({ isGridLayout: selected });
	};
	bytesToSize = (bytes) => {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		//check if bytes contains a value from sizes array
		const match = sizes.find((size) => {
			return bytes.includes(size)
		});

		if (match) return bytes;
		if (bytes === 0) return 'n/a';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		if (i === 0) return `${bytes} ${sizes[i]}`;
		return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
	};
	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key === column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};
	searchEntries = (searchText) => {
		this.setState({
			searching: searchText !== '' ? true : false,
			searchText: searchText ? searchText : '',
			checkedFiles: []
		});
	};

	renderSection() {
		const {
			imageURL,
			normalizedColumns,
			toggledColumns,
			isGridLayout,
			files,
			selectedColumnSort,
			searchText,
			columns,
			searching,
			sortDesc,
			dropOneError,
			uploadPercentage,
			uploadComplete,
			droppedFiles
		} = this.state;
		let searchedEntries = files;

		//Searching by text from input
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term !== '') {
					searchedEntries = searchedEntries.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term !== '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}
		if (searchedEntries)
			searchedEntries = searchedEntries.map((file) => {
				file.size = this.bytesToSize(file.size);
				return file;
			});

		if (selectedColumnSort) {
			searchedEntries = searchedEntries.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			searchedEntries = sortDesc ? searchedEntries.reverse() : searchedEntries;
		}
		let mainAction = [];

		mainAction = [];

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;
		let searchEnabled = false;

		batchActions = [];
		checkedEntryCount = Object.keys(this.state.checkedFiles).length;
		entriesLength = searching ? searchedEntries.length : files.length;
		uncheckEntries = () => {
			this.setState({ checkedFiles: [] });
		};
		searchEnabled = true;
		switch (this.state.selectedSection) {
			case 0:
				return (

					<div className="sectionContainer sectionBody posRel p-0">
						<ActionButtonContainer
							mainActions={mainAction}
							batchActions={batchActions}
							checkedEntryCount={checkedEntryCount}
							entriesLength={entriesLength}
							uncheckEntries={uncheckEntries}
							searchEnabled={searchEnabled}
							searchEntries={this.searchEntries}
							enabledViewSelection={true}
							isGridLayout={isGridLayout}
							toggleLayout={this.toggleLayout}
						/>

						{!isGridLayout ? <ViewsTable
							singleSelection={true}
							style={{ margin: 0, height: 500, overflow: 'hidden' }}
							columns={normalizedColumns}
							toggledColumns={toggledColumns}
							isGridLayout={isGridLayout}
							data={searchedEntries}
							mainColumnActive={false}
							checked={this.state.checkedFiles}
							checkRow={(entryId) => {
								const checked = this.state.checkedFiles;
								checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
								this.updatedCheckedFiles(checked);
								this.setState({
									checkedFiles: checked
								});
							}}
							mainActions={mainAction}
							checkAllRows={() => { }}
							sortData={this.sortData}
						/> : <FileGrid
							title="Files"
							data={searchedEntries}
							singleSelection={true}
							checked={this.state.checkedFiles}
							checkRow={(entryId) => {
								const checked = this.state.checkedFiles;
								checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
								this.updatedCheckedFiles(checked);
								this.setState({
									checkedFiles: checked
								});
							}} />}
					</div>
				);
			case 1:
				return (
					<div className='mt-25'>
						<Dropzone
							className="clickable dropzone"
							inputProps={{ style: { margin: 'auto' } }}
							onDrop={this.onDrop.bind(this)}
						>
							<div className="dropzoneDescriptionContainer">
								<p>
									<span style={{ color: '#2B83F1' }}>Browse</span>, or drop a file here or click to upload.
								</p>
							</div>
						</Dropzone>

						{droppedFiles.length > 0 && (
							<div style={{ display: 'flex', paddingTop: 15 }}>
								<img
									alt='file'
									style={{ width: 40, height: 40, borderRadius: 6, border: '1px solid #999999' }}
									src={
										droppedFiles.length > 0 && droppedFiles[0].type.includes('image') ? (
											droppedFiles[0].preview
										) : (
											''
										)
									}
								/>

								<div style={{ display: 'block', width: '100%', paddingLeft: 10 }}>
									<div style={{ display: 'flex' }}>
										<p>
											{droppedFiles.length > 0 && droppedFiles[0].name}{' '}
											<span className='gray'>{`${droppedFiles.length > 0 &&
												this.bytesToSize(droppedFiles[0].size.toString())}`}</span>
										</p>
										{!uploadComplete && (
											<i
												style={{ color: '#999999', margin: 'auto 0 auto auto' }}
												className="las la-times"
												onClick={() => this.setState({ droppedFiles: [] })}
											/>
										)}
									</div>

									<Progress
										style={{
											margin: 0,
											height: 4,
											borderRadius: 6,
											marginTop: 8
										}}
										barClassName="progressBarColor"
										value={uploadPercentage}
									/>
								</div>
							</div>
						)}

						{dropOneError && <p className="small mt-10">You can only upload 1 file</p>}
					</div>
				);
			case 2:
				return (
					<div className='mt-25'>
						<InputField
							label={'Image URL'}
							style={{ marginBottom: 0 }}
							value={imageURL}
							onChange={(e) => {
								this.setState({ imageURL: e.target.value });
							}}
						/>
					</div>
				);

			default:
		}
	}

	render() {
		const { isOpen, toggle } = this.props;
		const { uploading, selectedSection, uploadComplete } = this.state;

		return (
			<div>
				<FieldModal
					size="large"
					isOpen={isOpen}
					modalTitle={"Add Media"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (<div>

							<TabsContainer
								tabs={[{ title: 'File Library' }, { title: 'Upload' }, { title: 'URL' }]}
								updateSelected={this.updateSelected}
								selectedIndex={selectedSection}
								style={{ marginLeft: 0, marginRight: 0 }}
							/>
							<div style={{
								overflowX: 'hidden',
								height: "calc(100vh - 307px)"
							}}>{this.renderSection()}</div>
						</div>)
					}}
					toggle={toggle}
					actionButtonLabel={(!uploading && !uploadComplete) ? 'Submit' : uploadComplete ? 'Done' : 'Submitting'}
					actionButton={() => (this.submit())}
					actionButtonDisabled={uploading}
					isFullWidthBody={true}


				></FieldModal>
			</div>

		);
	}
}

export default FilesUploaderModal;
