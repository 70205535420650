import React from 'react';
import { Table } from 'reactstrap';
import { Trash2, Plus } from 'react-feather';
import AddSessionModal from './modals/addSessionModal';
import RemoveSessionModal from './modals/removeSessionModal';
import moment from 'moment';
import ImportGroupSessionsModal from './modals/importSessionsIntoGroupModal';
import ViewsTable from '../../../components/tables/ViewsTable';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import InputField from '../../../components/inputs/inputField';
import { UncontrolledTooltip } from 'reactstrap';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

class EventGroupSessions extends React.Component {
	constructor(props) {
		super(props);
		const columns = {
			name: 'Name',
			date: 'Date',
			startTime: 'Start Time',
			endTime: 'End Time'
		};
		const columnTypes = {
			name: 'text',
			date: 'date',
			startTime: 'time',
			endTime: 'time'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});
		this.state = {
			addSessionModalOpen: false,
			importSessionsModalOpen: false,
			removeSessionModalOpen: false,
			markedSession: null,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			checkedSessions: {},
			selectedColumnSort: null,
			sortDesc: false,
			searchTerm: '',
		};
	}

	toggleAddSession = () => {
		this.setState({ addSessionModalOpen: !this.state.addSessionModalOpen }, () => {
			this.props.toggleAddSessions();
		});
	};

	toggleRemoveSession = (session) => {
		this.setState(
			{
				removeSessionModalOpen: !this.state.removeSessionModalOpen,
				markedSession: session || null
			},
			() => {
				this.props.toggleRemoveSessions();
			}
		);
	};

	toggleImportSessions = () => {
		this.setState({ importSessionsModalOpen: !this.state.importSessionsModalOpen }, () => {
			this.props.toggleImportSessions();
		});
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	/* Search */
	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};
	/* Download */
	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};
	downloadReport = async () => {

		const { columns, columnTypes } = this.state;
		let sessions = this.props.editedGroup.sessions.sort(
			(s1, s2) => new Date(s1.date).getTime() - new Date(s2.date).getTime()
		);
		const entries = sessions; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData
		};

		const reportDetails =
			{ Title: this.props.editedGroup.name + " Report", Subject: 'Group Session Report ' };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${moment().format('LLL')}.xlsx`
		);
	};
	render() {
		const { markedSession, toggledColumns, normalizedColumns, selectedColumnSort, sortDesc, searchTerm, columns } = this.state;
		let sessions = this.props.editedGroup.sessions.sort(
			(s1, s2) => new Date(s1.date).getTime() - new Date(s2.date).getTime()
		);
		/* Search */
		let data = sessions;
		if (searchTerm.length > 0) {
			const searchTerms = searchTerm.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}

		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}


		let batchActions = [
			{
				type: 'button',
				label: 'Remove from Group',
				onClick: this.toggleRemoveSession,
				iconClass: 'las la-trash-alt',
				class: 'danger'
			}
		]
		return (
			<div className="sectionBody3 posRel mb-0 p-0">
				<div className="actionButtonContainer headerHeight flex-ac">
					<ActionButtonContainer
						mainActions={[{ onClick: this.toggleAddSession, label: 'Add Session' }]}
					/>
					{/* Search and Download */}
					<InputField
						classes="m-0 viewSearch"
						placeholder="Search "
						clickIcon={true}
						required={true}
						value={searchTerm}
						onChange={(e) => {
							clearTimeout(this.searchTimeout);
							this.setState({ searchTerm: e.target.value },
								() => {
									this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
								});

						}}
						prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
						inputIcon={searchTerm != '' &&
							<i
								onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
								className="las la-times-circle"
								style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
							/>}
					/>
					<div className="sectionOptionsContainer">
						<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReport()}>
							<i className="las la-download" />
							<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
						</div>
					</div>
				</div>
				<ViewsTable
					title="Sessions"
					style={{ margin: 0 }}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					data={data}
					mainColumnActive={false}
					checked={this.props.checkedSessions}
					checkRow={(entryId) => {
						const checked = this.props.checkedSessions;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.props.updateChecked(checked);
						this.setState({
							checkedSessions: checked
						});
					}}
					checkAllRows={(entryIds) => {
						let checked = this.props.checkedSessions;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.props.updateChecked(checked);

						this.setState({
							checkedSessions: checked
						});
					}}
					sortData={this.sortData}
				/>

				<ActionButtonContainer
					mainActions={[]}
					batchActions={batchActions}
					checkedEntryCount={Object.keys(this.props.checkedSessions).length}
					entriesLength={sessions.length}
					uncheckEntries={() => {
						this.props.updateChecked({});
						this.setState({
							checkedSessions: {}
						});
					}}
				/>
				{this.props.addSessionToggle && (
					<AddSessionModal
						event={this.props.event}
						isOpen={this.props.addSessionToggle}
						toggle={this.toggleAddSession}
						existingSessions={sessions}
						group={this.props.editedGroup}
						index={this.props.index}
						sessions={this.props.event.sessions}
						addSessions={this.props.addSessions}
						loadMoreSessions={this.props.loadMoreSessions}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
					/>
				)}
				{this.props.deleteSessionsModalOpen && (
					<RemoveSessionModal
						isOpen={this.props.deleteSessionsModalOpen}
						toggle={this.toggleRemoveSession}
						existingSessions={sessions}
						orgId={this.props.orgId}
						index={this.props.index}
						eventId={this.props.event._id}
						markedSession={markedSession}
						sessions={this.props.event.sessions}
						removeSessions={this.props.removeSessions}
						group={this.props.editedGroup}
						checkedSessions={this.props.checkedSessions}
					/>
				)}
				{this.props.importSessionsToggle && (
					<ImportGroupSessionsModal
						isOpen={this.props.importSessionsToggle}
						toggle={this.toggleImportSessions}
						customFields={this.props.event.customFields.sessions}
						addSession={this.props.addSession}
						eventId={this.props.event._id}
						orgId={this.props.orgId}
						group={this.props.editedGroup}
					/>
				)}
			</div>
		);
	}
}

export default EventGroupSessions;
