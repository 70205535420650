import React, { Component } from 'react';
import InputField from '../../components/inputs/inputField';
import ActionButtonContainer from '../../components/views/ActionButtonContainer';

class Typography extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: {}
		};
	}
	render() {
		const { expanded } = this.state;
		const { accountData, editAccountData } = this.props;
		return (
			<div className="sectionBlock">
				<div className='blockHeader flex aic mb-10' >

					<h2 className='mb-15 mt-10'>Fonts</h2>
					<button className='prim ml-a min'
						onClick={() => {
							editAccountData({
								...accountData.branding,
								fonts: [
									{
										name: '',
										url: '',
										weight: '',
										style: '',
										applicableElements: []
									},
									...accountData.branding.fonts
								]
							});
						}}>Add Font</button>
				</div>
				{accountData.branding.fonts.map((font, index) => {
					return expanded[font._id] ? (
						<div key={`font-${index}`} className="fontCard expanded">
							<InputField
								label="Font Name"
								type="text"
								value={font.name}
								onChange={(e) => {
									editAccountData({
										...accountData.branding,
										fonts: [
											...accountData.branding.fonts.slice(0, index),
											{
												...font,
												name: e.target.value
											},
											...accountData.branding.fonts.slice(index + 1)
										]
									});
								}}
							/>
							<InputField
								label="Font URL"
								type="text"
								value={font.url}
								onChange={(e) => {
									editAccountData({
										...accountData.branding,
										fonts: [
											...accountData.branding.fonts.slice(0, index),
											{
												...font,
												url: e.target.value
											},
											...accountData.branding.fonts.slice(index + 1)
										]
									});
								}}
							/>

							<div className="flex-sb w-100">
								<InputField
									style={{ marginRight: 10 }}
									label="Font Weight"
									type="text"
									value={font.weight}
									onChange={(e) => {
										editAccountData({
											...accountData.branding,
											fonts: [
												...accountData.branding.fonts.slice(0, index),
												{
													...font,
													weight: e.target.value
												},
												...accountData.branding.fonts.slice(index + 1)
											]
										});
									}}
								/>

								<InputField
									style={{ marginLeft: 10 }}
									label="Font Style"
									type="text"
									value={font.style}
									onChange={(e) => {
										editAccountData({
											...accountData.branding,
											fonts: [
												...accountData.branding.fonts.slice(0, index),
												{
													...font,
													style: e.target.value
												},
												...accountData.branding.fonts.slice(index + 1)
											]
										});
									}}
								/>
							</div>

							<InputField
								label="Applicable Elements (press enter to add)"
								type="text"
								placeholder="e.g. h1, h2, p, a, li, span, .pageTitle, .button"
								value={font.newAP}
								onChange={(e) => {
									editAccountData({
										...accountData.branding,
										fonts: [
											...accountData.branding.fonts.slice(0, index),
											{
												...font,
												newAP: e.target.value
											},
											...accountData.branding.fonts.slice(index + 1)
										]
									});
								}}
								onKeyUp={(e) => {
									if (e.key === 'Enter') {
										editAccountData({
											...accountData.branding,
											fonts: [
												...accountData.branding.fonts.slice(0, index),
												{
													...font,
													applicableElements: [...font.applicableElements, font.newAP],
													newAP: ''
												},
												...accountData.branding.fonts.slice(index + 1)
											]
										});
									}
								}}
							/>
							<div className="applicableElementsContainer">
								{font.applicableElements.map((e, i) => {
									return (
										<div
											key={`ae-${index}-${i}`}
											className="applicableElement"
											onClick={() => {
												editAccountData({
													...accountData.branding,
													fonts: [
														...accountData.branding.fonts.slice(0, index),
														{
															...font,
															applicableElements: [
																...font.applicableElements.slice(0, i),
																...font.applicableElements.slice(i + 1)
															]
														},
														...accountData.branding.fonts.slice(index + 1)
													]
												});
											}}
										>
											{e}
											<i className="las la-times ml-5" />
										</div>
									);
								})}
							</div>
							<div className="flex-sb w-100">
								<button
									className="actionButton actionOutlineRed mb-20"
									onClick={() => {
										editAccountData({
											...accountData.branding,
											fonts: [
												...accountData.branding.fonts.slice(0, index),
												...accountData.branding.fonts.slice(index + 1)
											]
										});
									}}
								>
									<div >Delete Font</div>
								</button>

								<button
									className="actionButton actionOutline"
									onClick={() => {
										const ex = this.state.expanded;
										ex[font._id] = false;
										this.setState({ expanded: ex });
									}}
								>
									<div >Collapse</div>
								</button>
							</div>
						</div>
					) : (
						<div key={`font-${index}`} className="fontCard">
							<div>
								<div>{font.name}</div>

								<div className="applicableElementsContainer lightGray">
									{font.applicableElements.join(', ')}
								</div>
							</div>

							<button
								className="actionButton actionOutline"
								onClick={() => {
									const ex = this.state.expanded;
									ex[font._id] = true;
									this.setState({ expanded: ex });
								}}
							>
								<div >Expand</div>
							</button>
						</div>
					);
				})}
			</div>
		);
	}
}

export default Typography;
