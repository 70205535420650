import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Container, Row, Col, Button } from 'reactstrap';

import API from '../../../../utils/API';

import ToggleField from '../../../../components/inputs/toggleField';
import SelectField from '../../../../components/inputs/selectField';
import FieldModal from '../../../../components/modals/fieldModal';
class CopyPageModal extends Component {
	state = {
		title: '',
		description: '',
		copyToOtherEvent: false,
		otherEvent: null,
		eventOptions: [],
		submitting: false,
		filteredPages: [],
		processing: false,
		count: 1,
		completed: false,
		loading: true,
		goToEvent: null,
		goToCopy:null,
		requiredFields: {
			otherEvent: false
		}
	};

	componentDidMount = async () => {
		const { pages, checkedPages } = this.props;

		let filteredPages = pages.filter((p) => Object.keys(checkedPages).includes(p._id));

		await API().get(`Organizations/${this.props.orgId}/eventNames`).then((res) => {
			if (res.data) {
				this.setState({
					eventOptions: res.data.events.map((e) => {
						return { label: e.name, value: e._id };
					}),
					loading: false
				});
			}
		});

		this.setState({ filteredPages: filteredPages });
	};
	handleNameChange = (p) => {
		this.setState({
			title: p.target.value

		});
		console.log("title", p.target.value)
	};

	addPage = () => {
		const { eventId, orgId } = this.props;
		const { otherEvent, copyToOtherEvent, filteredPages } = this.state;
		let valid = true;
		const requiredFields = this.state.requiredFields;

		if (copyToOtherEvent == true && !otherEvent) {
			requiredFields.otherEvent = true;
			valid = false;
		} else {
			requiredFields.otherEvent = false;
		}

		if (valid) {
			this.setState({ submitting: true });

			filteredPages.forEach((page) => {
				this.setState({ count: this.state.count++, processing: true });
				if (copyToOtherEvent && otherEvent && otherEvent.value != '') {
					API()
						.post(`Organizations/${orgId}/events/${otherEvent.value}/page`, {
							title: page.title,
							description: page.description,
							pageId: page._id
						})
						.then((res) => {
							this.setState({ completed: true, goToEvent: `./../${otherEvent.value}/pages`.toString() });
						});
				} else {
					API()
						.post(`Organizations/${orgId}/events/${eventId}/page`, {
							title: page.title,
							description: page.description,
							pageId: page._id
						})
						.then((res) => {
							if (res.data) {

								this.props.addPage(res.data);
								this.setState({ goToCopy: `./../pages/${res.data.id}` });
							}
						});
				}
			});

			this.setState({ completed: true });
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const {
			submitting,
			copyToOtherEvent,
			otherEvent,
			eventOptions,
			processing,
			count,
			filteredPages,
			completed,
			loading,
			requiredFields,
			goToEvent,
			goToCopy
		} = this.state;
		return (


			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Duplicate Page"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								{(processing || completed) && (
									<div>
										<p>
											{count} of {filteredPages.length} processed
										</p>
									</div>
								)}

								{!processing && (
									<ToggleField
										label="Duplicate to another event"
										checked={copyToOtherEvent}
										icons={false}
										onChange={(e) => this.setState({ copyToOtherEvent: !this.state.copyToOtherEvent })}
									/>
								)}
								{!processing &&
									copyToOtherEvent && (
										<SelectField
											label="Event"
											className={`editingSelectField`}
											classNamePrefix="select"
											required={true}
											errorTrigger={requiredFields.otherEvent}
											value={otherEvent}
											name="eventType"
											options={eventOptions}
											onChange={(e) => {
												this.setState({
													otherEvent: e,
													editing: true
												});
											}}
										/>
									)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={completed && goToEvent ? (
						'Go to Event'
					) : completed && goToCopy ? (
						'Go to New Page'
					) : !submitting ? (
						'Submit'
					) : (
						'Adding Page...'
					)}
					actionButton = {
						completed ? (
						goToEvent ? (
							() => {
							window.location.href = goToEvent;
							}
						) : (
							() => {
							window.location.href = goToCopy;
							}
						)
						) : (
						this.addPage
						)
					}
					cancelButtonLabel={completed ? 'Done' : 'Cancel'}
				></FieldModal>
			</div >




		);
	}
}

export default CopyPageModal;
