import React from 'react';
import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';

class deleteInventoryItemModal extends React.Component {
    state = {
        submitting: false
    };
    delete = async () => {
        const { orgId, eventId, item, } = this.props;
            await API()
            .patch(`Organizations/${orgId}/events/${eventId}/deleteInventories`, { inventoryIds: [item._id] })
                .then((res) => {
                    this.props.deleteSingleItem(item._id, () => {
                        this.props.history.push('./')
                    });
                })
                .catch((e) => { console.log(e) });
    };
    render() {
        const { isOpen, toggle, item } = this.props;
        const { submitting } = this.state;
        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Item"}
                    bodyHeaderText={<span>You are about to delete <b>{item.name}</b> inventory item from the event, this is irreversible,
                        would you like to continue?</span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                    actionButton={() => this.delete()}
                    actionButtonDisabled={submitting}
                    isDelete={true}
                ></FieldModal>
            </div>

        );
    }
}

export default withRouter(deleteInventoryItemModal);
