// import external modules
import React, { Component } from 'react';
import { setCookie } from '../../utils/cookie';
import API from '../../utils/API';
import InputField from '../../components/inputs/inputField';
import loginBG from '../../assets/img/backgrounds/LoginBG.png';
import logo from '../../assets/img/logos/simple events logo.png';
import { Link, withRouter } from 'react-router-dom';
class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			error: '',
			disableSubmit: true,
			isChecked: true,
			passwordErrorTrigger: false,
			emailErrorTrigger: false
		};
	}


	handleChecked = (e) => {
		this.setState((prevState) => ({
			isChecked: !prevState.isChecked
		}));
	};

	submit = () => {
		const { state } = this.props.location;
		this.state.email === ''
			? this.setState({ emailErrorTrigger: true })
			: this.setState({ emailErrorTrigger: false });
		this.state.password === ''
			? this.setState({ passwordErrorTrigger: true })
			: this.setState({ passwordErrorTrigger: false });

		if (this.state.email === '' || this.state.password === '') {
			this.setState({ error: 'Credentials required' });
			return;
		}
		const data = {
			emailAddress: this.state.email,
			password: this.state.password
		};
		API()
			.post(`/auth/login`, data)
			.then((res) => {
				setCookie('authToken', res.data.token);
				setCookie('refreshToken', res.data.refreshToken);
				localStorage.setItem('defaultOrganization', res.data.organizations[0]._id);
				const targetLocation =
					state && state.from && state.from.pathname != '' ? state.from.pathname : `/${res.data.organizations[0]._id}/events`;
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.getRegistrations().then(function (rs) {
						if (rs.length > 0) {

							for (let reg of rs) {
								reg.unregister().then(() => {
									window.open(targetLocation, '_self');
								})
							}
						} else {
							window.open(targetLocation, '_self');
						}

					}).catch((err) => {
						window.open(targetLocation, '_self');
					});
				} else {
					window.open(targetLocation, '_self');
				}
			})
			.catch((err) => {
				if (err.response.data.message) {
					this.setState({ error: err.response.data.message, passwordErrorTrigger: true });
				} else if (err.response.data) {
					this.setState({ error: err.response.data.toString(), passwordErrorTrigger: true });
				}
			});
	};

	render() {
		const { emailErrorTrigger, passwordErrorTrigger, disableSubmit, password, email } = this.state;

		return (
			<div className='w-100 h-100 flex column'>
				<div className='systemToolbar w-100' >
					<div className="flex h-100 ml-30" style={{ alignItems: 'end' }}>

						<div className="navLogo c-pointer" style={{ marginRight: 113 }} onClick={() => window.location.href = "/"}>
							<img src={logo} />
						</div>
					</div>
				</div>
				<div className='w-fc p-0 neuCard  h-100' style={{ background: 'white', border: 'none', width: 'calc(100% - 15px)', marginLeft: 15 }}>

					<div className="w-100 m-a mt-20 p-50" style={{ maxWidth: 560 }}>

						<h1 className='mb-20 text-c'>Log in</h1>
						<InputField
							label={'Account Email'}
							type={'email'}
							onChange={(e) => {
								const val = e.currentTarget.value;
								val == '' || password == ''
									? this.setState({ disableSubmit: true })
									: this.setState({ disableSubmit: false });
								this.setState({ email: val });
							}}
							value={this.state.email}
							errorTrigger={emailErrorTrigger}
							errorMessage={'Email required'}
						/>

						<InputField
							label={'Password'}
							type={'password'}
							onChange={(e) => {
								const val = e.currentTarget.value;
								val == '' || email == ''
									? this.setState({ disableSubmit: true })
									: this.setState({ disableSubmit: false });
								this.setState({ password: val });
							}}
							value={this.state.password}
							errorTrigger={passwordErrorTrigger}
							errorMessage={'Invalid password'}
						/>
						<div className='flex aic jcsb'>
							<Link to="/forgotPassword">Forgot Password?</Link>
						</div>

						<button
							onClick={this.submit}
							className={`prim ${disableSubmit ? 'inactive' : ''} w-100 mt-20`}
							disabled={disableSubmit}
						>
							Log in
						</button>
						<div className='text-c mt-50 '>
							<h3 className='mb-10'>Don't have an account?</h3>
							<a href="mailto:sales@simple.events"><h4>Request Demo</h4></a>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		//listen to the "enter" key to fire of the submit function
		document.addEventListener('keydown', (e) => {
			if (e.key === 'Enter') {
				this.submit(e);
			}
		});

	}
}

export default withRouter(Login);
