import React, { Component } from 'react';

class CopyButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			copied: false
		};
	}
	render() {
		const { copyValue, style, isButton } = this.props;
		const { copied } = this.state;
		return (
			<div
				style={style}
				className={`actionButton ${isButton ? 'actionSave' : 'actionCopy'}`}
				onClick={async () => {
					navigator.clipboard.writeText(copyValue).then(
						() => {
							/* clipboard successfully set */
							this.setState({ copied: true });
							setTimeout(() => this.setState({ copied: false }), 1000);
						},
						() => {
							/* clipboard write failed */
							document.execCommand('copy', true, copied);
							this.setState({ copied: true });
							setTimeout(() => this.setState({ copied: false }), 1000);
						}
					);
				}}
			>
				{' '}
				<div style={{ height: 16 }}>
					<i className="las la-copy" />
					{copied ? 'Copied' : 'Copy'}
				</div>
			</div>
		);
	}
}

export default CopyButton;
