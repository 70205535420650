import React from 'react';

import API from '../../../../utils/API';
import moment from 'moment';

import FieldModal from '../../../../components/modals/fieldModal';
import Scheduler from '../../../../utils/Scheduler';

class SendNotificationNowModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
			invalid: false,
			attendees: [],
			sessions: [],
			groups: [],
			error: '',
			requiredFields: {},
			sentCount: 0,
			completed: false,
			totalEmails: 0,
			eventSessions: [],
			eventGroups: [],
			eventAttendees: []
		};
	}

	componentDidMount() {
		const { notification, event } = this.props;

		let recipients = notification.recipients;
		let eventGroups = event.groups.filter((g) => recipients.groups.includes(g._id));

		let recipientModel = '';
		let uniqueAttendees = [];

		if (recipients.attendees.length > 0) {
			recipientModel = 'attendees';
		} else if (recipients.sessions.length > 0) {
			recipientModel = 'sessions';
		} else if (recipients.groups.length > 0) {
			recipientModel = 'groups';
		}

		switch (recipientModel) {
			case 'attendees':
				uniqueAttendees = [...recipients.attendees];
				break;
			case 'sessions':
				let sessionsToSend = [...event.sessions.filter(s => recipients.sessions.includes(s._id))];
				for (let si = 0; si < sessionsToSend.length; si++) {
					let session = sessionsToSend[si];
					if (session.isGeneral) {
						uniqueAttendees = [];
						let attendeeIds = event.attendees.map(a => a._id);
						uniqueAttendees = [...uniqueAttendees, ...attendeeIds];
						break;
					}
					uniqueAttendees = [...uniqueAttendees, ...session.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			case 'groups':
				let groupsToSend = [...event.groups.filter(g => recipients.groups.includes(g._id))];
				for (let gi = 0; gi < groupsToSend.length; gi++) {
					let group = groupsToSend[gi];
					uniqueAttendees = [...uniqueAttendees, ...group.attendees];
				}
				uniqueAttendees = [...new Set(uniqueAttendees)];
				break;
			default:
				break;
		}


		this.setState({
			attendees: recipients.attendees,
			sessions: recipients.sessions,
			groups: recipients.groups,
			eventGroups: eventGroups,
			uniqueAttendees: uniqueAttendees,

		});
	}

	sendOutTextMesages = async () => {
		const { notification, orgId, eventId, event, update, toggle } = this.props;
		const { sentCount, attendees, sessions, groups } = this.state;
		let count = sentCount;
		//this.setState({ sending: true });
		const date = moment().tz(event.timezone).format('MM/DD/YYYY');
		const time = moment().tz(event.timezone).format('hh:mm a');
		let timezone = event.timezone;

		try {
			const res = await Scheduler()
				.post(`Organizations/${orgId}/notification/${notification._id}/scheduleNotification`, {
					date,
					time,
					timezone: timezone,
					isNow: true,
				})
				.then((res) => {
					update(
						{
							sent: true,
							status: 'Sent',
						},
						() => {
							update({
								sent: true,
								status: 'Sent',
								sendDate: date,
								sendTime: time,
							}, () => {
								this.props.runCheck();
								toggle();
							});
						}
					);
				});
		} catch (err) {
			console.log(err);
		}

	};
	render() {
		const { isOpen, toggle } = this.props;
		const { sending, attendees, sessions, groups, completed, sentCount, eventGroups, totalEmails, uniqueAttendees } = this.state;

		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Send Notification Now"}
					bodyHeaderText={`${attendees.length !== 0 ? (
						`${attendees.length} Attendee${attendees.length > 1 ? 's' : ''}`
					) : sessions.length !== 0 ? (
						`${uniqueAttendees.length} Attendee${uniqueAttendees.length > 1 ? 's' : ''} in ${sessions.length} Session${sessions.length > 1 ? 's' : ''}`
					) : groups.length !== 0 ? (
						`${uniqueAttendees.length} Attendee${uniqueAttendees.length > 1 ? 's' : ''} in ${eventGroups.length} Group${eventGroups.length > 1 ? 's' : ''}`
					) : (
						'Nothing selected'
					)}`}
					bodyContent={() => {
						return (

							<p>
								{sending && `${!completed ? 'Sending' : 'Sent'} ${sentCount} of ${totalEmails} attendees`}
							</p>)
					}}
					toggle={toggle}
					actionButtonLabel={!sending ? 'Submit' : 'Adding Item...'}
					actionButton={this.sendOutTextMesages}
					actionButtonDisabled={sending || completed}
				></FieldModal>
			</div>

		);
	}
}

export default SendNotificationNowModal;
