import React from 'react';

class ExpandableListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.initiallyExpanded || false,
        };
        this.handleExpand = this.handleExpand.bind(this);
    }

    handleExpand() {
        console.log('handleExpand')
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const { className, render, expandedContent, key } = this.props;
        return (
            <div className={`posRel ${className}`} key={key}>
                {render(this.state, this)}
                {expandedContent(this.state)}
            </div>
        );
    }
}

export default ExpandableListItem;