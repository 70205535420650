import React, { Component } from 'react';
import * as IFrame from 'react-iframe-form';

import { Link } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import fileIcon from '../../assets/img/icons/file.png';
const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;
class GridLayoutTable extends Component {
	constructor(props) {
		super(props);
		this.sidebarScroll = React.createRef();
		this.contentScroll = React.createRef();
		this._preventEvent = false;
		this.contentLastScrollTop = 0;
		this.contentLastScrollLeft = 0;

		this.state = {
			hoverIndex: null,
			selectedMenu: null,
			lastCheckedIndex: null
		};
	}

	onSidebarScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}

		this._preventEvent = true;
		this.contentScroll.current.scrollTop = e.target.scrollTop;
	};

	onContentScroll = (e) => {
		if (this._preventEvent) {
			this._preventEvent = false;
			return;
		}

		if (e.target.scrollTop !== this.contentLastScrollTop) {
			this._preventEvent = true;
			this.sidebarScroll.current.scrollTop = e.target.scrollTop;
			this.contentLastScrollTop = e.target.scrollTop;
		}
	};

	GridFileItem(file, index) {
		const { checked, checkRow, singleSelection, isiFrame, eventId, orgId, isRemoveEllipses } = this.props;
		const { selectedMenu } = this.state;
		let url = `${ENV}Organizations/${orgId}/events/${eventId}/templates/${file._id}/render`;
		let method = 'post';
		let data = { html: file.html };
		return (
			<div
				style={{
					width: 150,
					height: 250,
					margin: 0,
					marginRight: 15,
					padding: 10,
					opacity: singleSelection && Object.keys(checked).length > 0 && !checked[file._id] ? '0.3' : '1'
				}}
				className="gridFileItem"
				key={index}
			>
				<div style={{ position: 'relative', overflow: isiFrame && 'hidden' }} className="gridImageThumb">
					{!(singleSelection && Object.keys(checked).length > 0 && !checked[file._id]) && (
						<div
							style={{ position: 'absolute', top: 0, left: 0, margin: 10 }}
							className={`checkBox ${checked[file._id] ? 'checked' : ''}`}
							disable={singleSelection && Object.keys(checked).length > 0 && !checked[file._id]}
							onClick={() => {
								if (!(singleSelection && Object.keys(checked).length > 0 && !checked[file._id])) {

									const checkedLength = Object.keys(checked).length;
									if (singleSelection) {
										if (!checked[file._id] && checkedLength == 0) {
											checkRow(file._id);
										} else if (checked[file._id] && checkedLength == 1) {
											checkRow(file._id);
										}
									} else {
										checkRow(file._id);
									}
								}
							}
							}
						>
							<i className="las la-check" />
						</div>
					)}
					{!singleSelection &&
						!isRemoveEllipses && (
							<UncontrolledDropdown className="floatingEllepsisButton">
								<DropdownToggle className="columnToggle">
									<i className="las la-ellipsis-h" />
								</DropdownToggle>
								<DropdownMenu className="columnDropdown">

									<div className='ph-20'>
										<h5 className="mb-10">Options</h5>
										<DropdownItem
											onClick={async () => {
												navigator.clipboard.writeText(file.url).then(
													() => {
														/* clipboard successfully set */
													},
													() => {
														/* clipboard write failed */
														document.execCommand('copy', true, file.url);
													}
												);
											}}
										>
											<i className="lar la-clipboard mr-10" /> Copy Link
										</DropdownItem>
									</div>
								</DropdownMenu>
							</UncontrolledDropdown>
						)}

					{!isiFrame ? (
						<img
							style={{ padding: 10 }}
							className="gridItemImage"
							title={`${file.name}`}
							src={file.type.includes('image') ? file.url : fileIcon}
						/>
					) : (
						<Link to={file.link} style={{ padding: 25 }}>
							{!file.dedicatedEmailServerEnabled ? (
								<div className="emailPreviewWindow Frame" style={{ width: '100%' }}>
									<IFrame.Form
										id={`builderPreviewFrameTemplate-${file._id}`}
										className="emailPreviewWindow Frame"
										style={{
											ponterEvent: 'none',
											transform: 'scale(0.99)'
										}}
										name={`PreviewFrameTemp-${file._id}`}
										config={{ url, method, data }}
									/>
								</div>
							) : file.dedicatedEmailServerEnabled && file.serviceUrl ? (
								<iframe
									className="emailPreviewWindow Frame"
									scrolling="no"
									style={{ ponterEvent: 'none' }}
									src={
										!file.dedicatedEmailServerEnabled ? (
											`${ENV}Organizations/${orgId}/events/${eventId}/templates/${file._id}/render`
										) : file.dedicatedEmailServerEnabled && file.serviceUrl ? (
											`${file.serviceUrl.concat('?preview=true')}`
										) : (
											''
										)
									}
								/>
							) : (
								''
							)}
						</Link>
					)}
				</div>

				<div className="fileImageName gridItemDetails">
					{file.name}
				</div>
			</div>
		);
	}

	shiftCheck = (id, index) => {
		const { data, checked, checkRow } = this.props;
		const keys = Object.keys(checked);
		var checkedLength = keys.length;

		if (checkedLength == 0) {
			for (var i = 0; i <= index; i++) {

				checkRow(data[i]._id);
			}
			this.setState({ lastCheckedIndex: index });
		} else {
			let from = this.state.lastCheckedIndex > index ? index : this.state.lastCheckedIndex;
			let to = this.state.lastCheckedIndex < index ? index : this.state.lastCheckedIndex;
			if (keys.includes(id)) {
				for (var i = from; i <= to; i++) {
					checked[data[i]._id] && checkRow(data[i]._id);
				}
			} else {
				for (var i = from; i <= to; i++) {
					!checked[data[i]._id] && checkRow(data[i]._id);
				}
			}
			this.setState({ lastCheckedIndex: index });
		}
	};

	RowItem(meta, index) {
		const { columns, toggledColumns, checked, checkRow, onClick, singleSelection, isiFrame } = this.props;
		const { hoverIndex } = this.state;

		return (
			<div
				key={`row-${meta._id}`}
				className={`tableColumnRow ${hoverIndex == index ? 'hover' : ''} ${checked[meta._id]
					? 'checked'
					: ''} ${onClick ? 'c-pointer' : ''}`}
			>
				{columns.map((c, i) => {
					let value = '';
					if (typeof meta[c.key] != 'undefined' && meta[c.key] != null) {
						if (typeof meta[c.key] == 'boolean') {
							value = meta[c.key] ? 'Yes' : 'No';
						} else if (c.type == 'date') {
							value = moment(meta[c.key]).format('MM/DD/YYYY');
						} else if (c.type == 'timestamp') {
							value = moment(meta[c.key]).format('MM/DD/YYYY h:mm a');
						} else if (c.type == 'time') {
							const today = moment().format('YYYY-MM-DD');
							value = moment(`${today} ${meta[c.key]}`).format('h:mm a');
						} else if (c.type == 'link') {
							value = (
								<a className="link" href={meta[c.key]} target="_blank">
									{meta[c.key]}
								</a>
							);
						} else if (c.type == 'bool') {
							value = meta[c.key] ? 'Yes' : 'No';
						} else {
							value = meta[c.key];
						}
					}

					return (
						toggledColumns[c.key] && (
							<div
								style={{
									maxWidth: i == 0 ? '500px' : '180px',
									minWidth: i == 0 ? '500px' : '180px',
									opacity:
										singleSelection && Object.keys(checked).length > 0 && !checked[meta._id]
											? '0.3'
											: '1'
								}}
								className="tableColumnRowCell text-truncate calibreRegular"
								key={`cell-${c.key}`}
							>
								{i == 0 && (
									<div
										style={{ marginTop: 'auto', marginBottom: 'auto' }}
										className={`checkBox mr-10 ${checked[meta._id] ? 'checked' : ''}`}
										onClick={(e) => {
											const checkedLength = Object.keys(checked).length;
											if (singleSelection) {
												if (!checked[meta._id] && checkedLength == 0) {
													checkRow(meta._id);
												} else if (checked[meta._id] && checkedLength == 1) {
													checkRow(meta._id);
												}
											} else {
												if (e.shiftKey) {
													this.shiftCheck(meta._id, index);
												} else {
													this.setState({ lastCheckedIndex: i });
													checkRow(meta._id);
												}

											}
										}}
									>
										<i className="las la-check" />
									</div>
								)}
								{meta.link ? (
									<Link to={meta.link} style={{ color: '#000' }}>
										<div
											style={{ display: 'flex', alignItems: 'center' }}
											onClick={() => {
												if (onClick) {
													onClick(meta._id);
												}
											}}
										>
											{i == 0 && (
												<div>
													{!isiFrame && (
														<img
															style={{
																width: 34,
																height: 34,
																padding: '5px 10px 5px 0px'
															}}
															src={meta.type.includes('image') ? meta.url : fileIcon}
															alt="none"
														/>
													)}
												</div>
											)}
											<div style={{ height: 16 }}>{value}</div>
										</div>
									</Link>
								) : (
									<div
										style={{ display: 'flex', alignItems: 'center' }}
										onClick={() => {
											if (onClick) {
												onClick(meta._id);
											}
										}}
									>
										{i == 0 && (
											<div>
												{!isiFrame && (
													<img
														style={{
															width: 34,
															height: 34,
															padding: '5px 10px 5px 0px'
														}}
														src={meta.type.includes('image') ? meta.url : fileIcon}
														alt="none"
													/>
												)}
											</div>
										)}
										<div style={{ height: 16 }}>{value}</div>
									</div>
								)}
							</div>
						)
					);
				})}
			</div>
		);
	}

	mouseHoverStart = (index) => {
		this.setState({ hoverIndex: index });
	};

	mouseHoverEnd = () => {
		this.setState({ hoverIndex: null });
	};

	render() {
		const {
			readOnly,
			mainColumnActive,
			toggledColumns,
			checked,
			checkRow,
			checkAllRows,
			data,
			sortData,
			columns,
			isGridLayout,
			sortByColumn,
			singleSelection,
			classes
		} = this.props;
		const { hoverIndex } = this.state;
		const mainColumn = columns[0];
		const allChecked = data.length === Object.keys(checked).length && Object.keys(checked).length > 0;

		return !isGridLayout ? (
			<div className={`sectionTable ${classes ? classes : ''} `} style={this.props.style}>
				<div className="tableSubColumns">
					<div className="tableColumnHeader">
						{columns.map(
							(c, i) =>
								toggledColumns[c.key] && (
									<div
										className="tableSubColumn"
										style={{
											maxWidth: i == 0 ? '500px' : '180px',
											minWidth: i == 0 ? '500px' : '180px'
										}}
										onClick={() => {
											if (c.sortAsc) {
												sortData(c, false, true);
											} else {
												sortData(c, true, false);
											}
										}}
									>
										{i == 0 &&
											!singleSelection && (
												<div
													className={`checkBox mr-10 ${allChecked ? 'checked' : ''}`}
													onClick={() => checkAllRows(allChecked ? [] : data.map((sd) => sd._id))}
												>
													<i className="las la-check" />
												</div>
											)}
										<div className="tableColumnTitle2" title={c.label}>
											{c.label}
											{c.sortAsc ? (
												<i className="las la-angle-down" />
											) : c.sortDesc ? (
												<i className="las la-angle-up" />
											) : (
												''
											)}
										</div>
									</div>
								)
						)}
					</div>
					<div className="tableColumnRows w-fc">{data.map((d, i) => this.RowItem(d, i))}</div>
				</div>
			</div>
		) : (
			<div className={`sectionTable ${classes ? classes : ''} `} style={this.props.style}>
				<div className="tableSubColumns">
					<div className="tableColumnHeader">
						{columns.map(
							(c, i) =>
								toggledColumns[c.key] &&
								i == 0 && (
									<div
										className="tableSubColumn"
										onClick={() => {
											if (c.sortAsc) {
												sortData(c, false, true);
											} else {
												sortData(c, true, false);
											}
										}}
									>
										{i == 0 &&
											!singleSelection && (
												<div
													className={`checkBox mr-10 ${allChecked ? 'checked' : ''}`}
													onClick={() => checkAllRows(allChecked ? [] : data.map((sd) => sd._id))}
												>
													<i className="las la-check" />
												</div>
											)}
										<div className="tableColumnTitle2" title={c.label}>
											{c.label}
											{c.sortAsc ? (
												<i className="las la-angle-down" />
											) : c.sortDesc ? (
												<i className="las la-angle-up" />
											) : (
												''
											)}
										</div>
									</div>
								)
						)}
					</div>
					<div
						style={{ display: 'flex', paddingTop: 16, flexFlow: 'wrap' }}
						className="tableColumnRows w-fc"
						ref={this.contentScroll}
					>
						{data.map((d, i) => this.GridFileItem(d, i))}
					</div>
				</div>
			</div>
		);
	}
}

export default GridLayoutTable;
