import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import API from '../../../../utils/API';
import EventCampaign from '../campaigns/campaign';
import EventTemplate from '../templates/template';
import LoadingWizard from '../../../../components/spinner/wizard';

import AddCampaignModal from '../modals/newCampaignModal';
import AddCampaignFromTemplateModal from '../modals/createCampaignFromTemplate';

import DeleteCampaignsModal from '../modals/deleteEmailsModal';
import FieldModal from '../../../../components/modals/fieldModal';
import Templates from '../templates/templates';
import SectionHeader from '../../../../components/views/SectionHeader';
import TabsContainer from '../../../../components/views/TabsContainer';
import ActionButtonContainer from '../../../../components/views/ActionButtonContainer';
import ViewsTable from '../../../../components/tables/ViewsTable';
import RenameEmailModal from '../modals/renameEmailModal';
import DuplicateCampaignModal from '../modals/duplicateCampaignModal';
import DeleteEmailModal from '../modals/deleteEmailModal';

import CreateTemplateFromCampaignModal from '../modals/createTemplateFromCampaignModal';

import TemplateCenterModal from '../modals/templateCenterModal';


import TemplateRenameModal from '../modals/renameTemplateModal';
import DeleteTemplateModal from '../modals/deleteTemplateModal';
import AddTemplateModal from '../modals/addTemplateModal';


import DuplicateTemplateModal from '../modals/duplicateTemplateModal';

import CreateCampaignFromTemplateModal from '../modals/createCampaignFromTemplate';

let batchCallback;
class EventCampaigns extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			subject: 'Subject',
			status: 'Status',
			createdAt: 'Created At',
			updatedAt: 'Updated At',
			sentEmailCount: 'Sent Email Count',
			deliveredEmailCount: 'Delivered Email Count',
			openedEmailCount: 'Opened Email Count',
			clickedEmailCount: 'Clicked Email Count',
			bouncedEmailCount: 'Bounced Email Count',
		};
		const defaultFieldTypes = {
			name: 'text',
			subject: 'text',
			status: 'Status',
			createdAt: 'timestamp',
			updatedAt: 'timestamp',
			sentEmailCount: 'number',
			deliveredEmailCount: 'number',
			openedEmailCount: 'number',
			clickedEmailCount: 'number',
			bouncedEmailCount: 'number'
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(customFieldColumns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: customFieldColumns[cfc],
				key: cfc,
				value: cfc,
				type: (defaultFieldTypes && defaultFieldTypes[cfc]) || typeof defaultFieldTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});


		delete toggledColumns[Object.keys(defaultFieldTypes)[0]];


		//For Templates

		const templateCustomFieldColumns = {
			name: 'Name',
			subject: 'Subject',
			createdAt: 'Created At',
			updatedAt: 'Updated At',
		};
		const templateDefaultFieldTypes = {
			name: 'text',
			subject: 'text',
			createdAt: 'date',
			updatedAt: 'date',
		};
		const templateToggledColumns = {};
		const templateNormalizedColumns = Object.keys(templateCustomFieldColumns).map((cfc) => {
			templateToggledColumns[cfc] = true;
			return {
				label: templateCustomFieldColumns[cfc],
				key: cfc,
				value: cfc,
				type: (templateDefaultFieldTypes && templateDefaultFieldTypes[cfc]) || typeof templateDefaultFieldTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});


		delete templateToggledColumns[Object.keys(templateDefaultFieldTypes)[0]];


		this.state = {
			editing: false,
			templates: [],
			campaignsHidden: true,
			platformTemplates: [],
			allTemplates: false,
			normalizedColumns: normalizedColumns,
			templateNormalizedColumns: templateNormalizedColumns,
			addCampaignToggle: false,
			columns: customFieldColumns,
			templateColumns: templateCustomFieldColumns,
			columnTypes: defaultFieldTypes,
			templateColumnTypes: templateDefaultFieldTypes,
			toggledColumns: toggledColumns,
			templateToggledColumns: templateToggledColumns,
			emailsViews: [],
			emails: [],
			deletedEmails: [],
			loading: true,
			toggleCreateFromTemplate: false,
			selectedTemplate: {},
			checkedCampaigns: {},
			deletedCampaigns: [],
			templatesOpen: false,
			hoverOn: '',
			selectedSection: 0,
			searchText: '',
			toggleRenameEmail: false,
			selectedRow: '',
			toggleDuplicateCampaign: false,
			toggleTemplateCenter: false,
			toggleRenameTemplate: false,
			toggleDeleteTemplate: false,
			toggleCreateTemplateFromCampaign: false,
			toggleBatchDeleteTemplates: false,
			toggleDuplicateTemplateModal: false,
			toggleCreateCampaignFromTemplate: false,
			defaultSenderEmail: '',
			defaultSenderName: '',
		};
	}

	componentDidMount() {
		const { eventId, orgId } = this.props;
		API().get(`Organizations/${orgId}/events/${eventId}/emails`).then((res) => {
			if (res.data) {
				const emailsViews = res.data.emailsViews;
				let defaultSenderEmail = res.data.defaultSenderEmail;
				let defaultSenderName = res.data.defaultSenderName;
				const templates = res.data.templates.map(template => {

					return {
						...template,
						link: `/${this.props.orgId}/events/${eventId}/emails/templates/${template._id}`,
					}
				});
				const platformTemplates = res.data.platformTemplates;
				let sortedEmails = res.data.emails.map((email) => {
					return {
						...email,
						status: email.sent ? 'Sent' : email.scheduled ? 'Scheduled' : 'Draft',
						link: `/${this.props.orgId}/events/${eventId}/emails/${email._id}`,
					};
				});
				const metadataOptions = [];
				Object.keys(res.data.keyedData).forEach((key) => {
					const layer1 = res.data.keyedData[key];
					if (typeof layer1 === 'object') {
						Object.keys(layer1).forEach((key2) => {
							const layer2 = layer1[key2];
							if (typeof layer2 === 'object') {
								Object.keys(layer2).forEach((key3) => {
									if (
										key !== '_defaultLabel' &&
										key2 !== '_defaultLabel' &&
										key3 !== '_defaultLabel'
									) {
										metadataOptions.push(`${key}.${key2}.${key3}`);
									}
								});
							} else {
								if (key !== '_defaultLabel' && key2 !== '_defaultLabel') {
									metadataOptions.push(`${key}.${key2}`);
								}
							}
						});
					} else {
						if (key !== '_defaultLabel') {
							metadataOptions.push(`${key}`);
						}
					}
				});
				this.setState({
					sortedEmails: sortedEmails,
					emails: sortedEmails,
					loading: false,
					keyedMetadata: metadataOptions,
					keyedData: res.data.keyedData,
					emailsViews: emailsViews,
					templates: templates,
					platformTemplates: platformTemplates,
					defaultSenderEmail: defaultSenderEmail,
					defaultSenderName: defaultSenderName
				});
			}
		});
	}

	hideCampaigns = () => {
		const { campaignsHidden } = this.state;
		this.setState({ campaignsHidden: !campaignsHidden });
	};

	toggleAddCampaign = () => {
		const { addCampaignToggle } = this.state;
		this.setState({ addCampaignToggle: !addCampaignToggle });
	};

	createFromTemplateToggle = () => {
		const { toggleCreateFromTemplate } = this.state;
		this.setState({ toggleCreateFromTemplate: !toggleCreateFromTemplate });
	};

	selectTemplate = (template) => {
		const { selectedTemplate } = this.state;
		this.setState({ selectedTemplate: template }, () => {
			this.createFromTemplateToggle();
		});
	};
	addCampaign = (campaign, callback) => {
		const { emails } = this.state;
		this.setState({ emails: [...emails, campaign] }, () => {
			this.props.refreshSectionCounts();
			callback && callback();
		});
	};

	addNewTemplate = (template, callback) => {
		const { templates } = this.state;
		try {

			this.setState({ templates: [...templates, template] }, () => {
				//this.props.refreshSectionCounts();
				callback && callback();
			});
		} catch (err) {
			console.log(err)
		}
	};

	toggleDeleteCampaigns = () => {
		const { toggleDeleteCampaigns } = this.state;

		this.setState({
			toggleDeleteCampaigns: !toggleDeleteCampaigns
		});
	};

	updateEmail = (eUpdate) => {
		let tempEmail = this.state.emails
		for (var i = 0; i < tempEmail.length; i++) {
			if (eUpdate._id == tempEmail[i]._id) {

				Object.assign(tempEmail[i], eUpdate);
			}
		}
		this.setState({ emails: tempEmail })
	}

	toggleDeleteTemplates = (checkedCampaigns, bcb) => {
		const { toggleDeleteTemplates } = this.state;
		batchCallback = bcb;

		this.setState({
			checkedCampaigns: checkedCampaigns || {},
			toggleDeleteTemplates: !toggleDeleteTemplates
		});
	};

	deleteCheckedCampaigns = (callback) => {
		const { checkedCampaigns, deletedCampaigns, emails } = this.state;

		let updatedEmails = emails.filter((e) => !Object.keys(checkedCampaigns).includes(e._id));
		this.setState(
			{
				emails: updatedEmails,
				sortedEmails: updatedEmails,
				deletedCampaigns: [...this.state.deletedCampaigns, ...Object.keys(checkedCampaigns)],
				checkedCampaigns: {}
			},
			() => {
				batchCallback && batchCallback('delete');
				this.props.refreshSectionCounts();
				callback();
			}
		);
	};

	updateDeleteCampaign = (campaignId, callback) => {
		const { emails } = this.state;

		let updatedEmails = emails.filter((e) => e._id !== campaignId);

		this.setState(
			{
				emails: updatedEmails,
				sortedEmails: updatedEmails,
				deletedCampaigns: [...this.state.deletedCampaigns, campaignId]
			},
			() => {
				batchCallback && batchCallback('delete');
				this.props.refreshSectionCounts();
				callback();
			}
		);
	};

	updateDeleteTemplate = (templateId, callback) => {
		const { templates } = this.state;

		let updatedTemplates = templates.filter((e) => e._id !== templateId);

		this.setState(
			{
				templates: updatedTemplates,
				deletedTemplates: [...this.state.deletedTemplates, templateId]
			},
			() => {
				this.props.refreshSectionCounts();
				callback();
			}
		);
	}

	addTemplate = (newTemplate) => {
		const { eventId } = this.props;
		const { templates } = this.state;
		let editedNewTemplate = newTemplate;
		editedNewTemplate.link = `/${this.props.orgId}/events/${eventId}/emails/templates/${editedNewTemplate._id}`;

		this.setState({
			templates: [...templates, editedNewTemplate]
		})
	}

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex, checkedCampaigns: {} });
	};

	checkEntry = (entryId) => {
		const checked = this.state.checkedCampaigns;
		checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
		this.setState({
			checkedCampaigns: checked
		});
	};

	checkAllEntries = (entryIds) => {
		let checked = this.state.checkedCampaigns;
		entryIds.length > 0
			? entryIds.forEach((entryId) => {
				checked[entryId] = true;
			})
			: (checked = {});

		this.setState({
			checkedCampaigns: checked,
		});
	};

	uncheckEntries = () => {
		this.setState({
			checkedCampaigns: {}
		});

	}

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns, templateNormalizedColumns, selectedSection } = this.state;

		if (selectedSection !== 3) {
			let nc = normalizedColumns;
			nc = nc.map((col) => {
				if (col.key == column.key) {
					col.sortAsc = sortAsc;
					col.sortDesc = sortDesc;
				} else {
					col.sortAsc = false;
					col.sortDesc = false;
				}
				return col;
			});

			this.setState({
				normalizedColumns: nc,
				selectedColumnSort: column,
				sortDesc: sortDesc == false ? true : false
			});

		} else {


			let nc = templateNormalizedColumns;
			nc = nc.map((col) => {
				if (col.key == column.key) {
					col.sortAsc = sortAsc;
					col.sortDesc = sortDesc;
				} else {
					col.sortAsc = false;
					col.sortDesc = false;
				}
				return col;
			});

			this.setState({
				templateNormalizedColumns: nc,
				selectedColumnSort: column,
				sortDesc: sortDesc == false ? true : false
			});
		}

	};

	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};

	toggleRenameEmailModal = (id) => {
		const { toggleRenameEmail } = this.state;
		this.setState({ toggleRenameEmail: !toggleRenameEmail, selectedRow: id });
	};

	toggleRenameTemplate = (id) => {
		const { toggleRenameTemplate } = this.state;
		this.setState({ toggleRenameTemplate: !toggleRenameTemplate, selectedRow: id });
	};
	toggleDuplicateCampaignModal = (id) => {
		const { toggleDuplicateCampaign } = this.state;
		this.setState({ toggleDuplicateCampaign: !toggleDuplicateCampaign, selectedRow: id });
	};

	toggleDeleteEmailModal = (id) => {
		const { toggleDeleteEmailModal } = this.state;
		this.setState({ toggleDeleteEmailModal: !toggleDeleteEmailModal, selectedRow: id });
	};

	toggleDeleteTemplate = (id) => {
		const { toggleDeleteTemplate } = this.state;
		this.setState({ toggleDeleteTemplate: !toggleDeleteTemplate, selectedRow: id });
	};

	toggleCreateTemplateFromCampaign = (id) => {
		const { toggleCreateTemplateFromCampaign } = this.state;
		this.setState({ toggleCreateTemplateFromCampaign: !toggleCreateTemplateFromCampaign, selectedRow: id });
	};

	toggleTemplateCenter = () => {
		const { toggleTemplateCenter } = this.state;
		this.setState({ toggleTemplateCenter: !toggleTemplateCenter });
	};
	toggleBatchDeleteTemplates = () => {
		const { toggleBatchDeleteTemplates } = this.state;
		this.setState({ toggleBatchDeleteTemplates: !toggleBatchDeleteTemplates });
	};
	toggleDuplicateTemplateModal = (id) => {
		const { toggleDuplicateTemplateModal } = this.state;
		this.setState({ toggleDuplicateTemplateModal: !toggleDuplicateTemplateModal, selectedRow: id });
	};

	toggleCreateCampaignFromTemplate = (id) => {
		const { toggleCreateCampaignFromTemplate } = this.state;
		this.setState({ toggleCreateCampaignFromTemplate: !toggleCreateCampaignFromTemplate, selectedRow: id });
	};

	updateCampaign = (emailProperties, callback) => {
		const { emails, selectedRow } = this.state;
		let editedEmails = this.state.emails;
		for (let i = 0; i < editedEmails.length; i++) {
			if (editedEmails[i]._id === selectedRow) {
				editedEmails[i] = { ...editedEmails[i], ...emailProperties };
				break;
			}
		}

		this.setState(
			{
				emails: editedEmails,
			},
			() => {
				callback && callback();
			}
		);
	};


	updateTemplate = (templateProperties, callback) => {
		const { emails, selectedRow, templates } = this.state;
		let editedTemplates = this.state.templates;
		for (let i = 0; i < editedTemplates.length; i++) {
			if (editedTemplates[i]._id === selectedRow) {
				editedTemplates[i] = { ...editedTemplates[i], ...templateProperties };
				break;
			}
		}

		this.setState(
			{
				templates: editedTemplates,
			},
			() => {
				callback && callback();
			}
		);
	};

	updateDeleteTemplate = (templateId, callback) => {
		const { templates } = this.state;
		//delete template from state
		let updatedTemplates = templates.filter((e) => e._id !== templateId);
		this.setState(
			{
				templates: updatedTemplates,
			},
			() => {
				this.props.refreshSectionCounts();
				callback && callback();
			}
		);
	};



	updateMultipleDeleteTemplate = () => {

		const { checkedCampaigns, templates } = this.state;
		let updatedTemplates = templates.filter((e) => !Object.keys(checkedCampaigns).includes(e._id));
		this.setState(
			{
				templates: updatedTemplates,
				checkedCampaigns: {},
				toggleBatchDeleteTemplates: false
			},
			() => {
				this.props.refreshSectionCounts();
			}
		);


	}

	deleteSingleCampaign = (campaignId, callback) => {
		const { emails } = this.state;

		let updatedEmails = emails.filter((e) => e._id !== campaignId);
		this.setState({ emails: updatedEmails, toggleDeleteEmailModal: false, checkedCampaigns: {} });
	}
	render() {
		const { eventId, event, orgId, editing } = this.props;
		const {
			campaignsHidden,
			templates,
			addCampaignToggle,
			columnTypes,
			columns,
			emailsViews,
			emails,
			deletedEmails,
			toggleCreateFromTemplate,
			selectedTemplate,
			loading,
			toggleDeleteCampaigns,
			checkedCampaigns,
			deletedCampaigns,
			keyedData,
			keyedMetadata,
			templatesOpen,
			hoverOn,
			selectedSection,
			toggledColumns,
			normalizedColumns,
			selectedColumnSort,
			sortDesc,
			searchText,
			toggleRenameEmail,
			toggleDuplicateCampaign,
			toggleDeleteEmailModal,
			toggleTemplateCenter,
			toggleRenameTemplate,
			toggleDeleteTemplate,
			toggleCreateTemplateFromCampaign,
			toggleBatchDeleteTemplates,
			templateToggledColumns,
			templateNormalizedColumns,
			toggleDuplicateTemplateModal,
			toggleCreateCampaignFromTemplate
		} = this.state;

		const scheduledCampaigns = emails.filter((e) => e.status === 'Scheduled');
		let mainAction = selectedSection == 3 ? [{ onClick: this.toggleAddCampaign, label: 'Create New Template' }] : [{ onClick: this.toggleTemplateCenter, label: 'Create New Campaign' }];
		let batchActions = selectedSection !== 3 ? [
			{
				label: 'Delete',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleDeleteCampaigns
			}
		] : [

			{
				label: 'Delete',
				iconClass: 'las la-trash-alt',
				class: 'danger',
				onClick: this.toggleBatchDeleteTemplates
			}
		];
		let searchEnabled = true;
		let isGridLayout = false;
		let checkedEntryCount = Object.keys(this.state.checkedCampaigns).length;
		let data = selectedSection == 3 ? templates : this.state.emails;

		let checkedRows = this.state.checkedCampaigns;

		switch (selectedSection) {
			case 0:
				data = data.filter((e) => e.status === 'Draft');
				break;
			case 1:
				data = data.filter((e) => e.status === 'Scheduled');
				break;
			case 2:
				data = data.filter((e) => e.status === 'Sent');
				break;
			case 3:
				break;
			default:
				break;
		}
		let entriesLength = data.length;
		if (searchText.length > 0) {
			const searchTerms = searchText.toLowerCase().split(' ');
			searchTerms.forEach((term) => {
				if (term != '') {
					data = data.filter((entry) => {
						let found = false;
						Object.keys(columns).forEach((key) => {
							if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
								found = true;
							}
						});
						return found;
					});
				}
			});
		}



		if (selectedColumnSort) {
			data = data.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			data = sortDesc ? data.reverse() : data;
		}

		//change rowSettings for template
		let rowSettings = selectedSection !== 3 ? [
			{ label: <span><i className='las la-pen mr-10'></i>Rename Campaign</span>, onClick: this.toggleRenameEmailModal },
			{ label: <span><i className='las la-copy mr-10'></i>Duplicate Campaign</span>, onClick: this.toggleDuplicateCampaignModal },
			{ label: <span><i className='las la-save mr-10'></i>Save as Template</span>, onClick: this.toggleCreateTemplateFromCampaign },
			{ label: <span><i className='las la-trash mr-10'></i>Delete Campaign</span>, onClick: this.toggleDeleteEmailModal }
		] : [
			{ label: <span><i className='las la-save mr-10'></i>Create Campaign</span>, onClick: this.toggleCreateCampaignFromTemplate },
			{ label: <span><i className='las la-pen mr-10'></i>Rename Template</span>, onClick: this.toggleRenameTemplate },
			{ label: <span><i className='las la-copy mr-10'></i>Duplicate Template</span>, onClick: this.toggleDuplicateTemplateModal },
			{ label: <span><i className='las la-trash mr-10'></i>Delete Template</span>, onClick: this.toggleDeleteTemplate }
		];
		return (
			<Switch>
				<Route path={`/:organization/events/:eventId/emails/templates/:templateId`}>
					<Fragment>
						{!loading && <EventTemplate
							event={this.props.event}
							eventId={eventId}
							orgId={orgId}
							emails={emails}
							keyedMetadata={keyedMetadata}
							keyedData={keyedData}
							updateDeleteTemplate={this.updateDeleteTemplate}
							addTemplate={this.addTemplate}
						/>}
						<LoadingWizard text="Loading Email Campaign" loading={loading} />
					</Fragment>
				</Route>
				<Route path={`/:organization/events/:eventId/emails/:emailId`}>
					<Fragment>
						{!loading && <EventCampaign
							groups={this.props.event.groups}
							sessions={this.props.event.sessions}
							event={this.props.event}
							eventId={eventId}
							orgId={orgId}
							emails={emails}
							keyedMetadata={keyedMetadata}
							keyedData={keyedData}
							updateEmail={this.updateEmail}
							updateDeleteCampaign={this.updateDeleteCampaign}
							addTemplate={this.addTemplate}
							templates={templates}
							platformTemplates={this.state.platformTemplates}
						/>}
						<LoadingWizard text="Loading Email Campaign" loading={loading} />
					</Fragment>
				</Route>

				<Route path={`/:organization/events/:eventId/emails`}>
					<Fragment>
						{!loading && (
							<div className="sectionContainer">
								<SectionHeader
									title={'Emails'}
									description="Create and send emails to your event's attendees."
									breadcrumbs={[
										{
											name: `${event.name}`,
										}
									]}
									style={{ paddingBottom: 0 }}
									enableLastLink={true}
								>

								</SectionHeader>
								<TabsContainer
									tabs={[{ title: 'Drafts' }, { title: 'Scheduled' }, { title: 'Sent' }, { title: 'Templates' }]}
									updateSelected={this.updateSelected}
									selectedIndex={selectedSection}
								/>


								<div className="sectionContainer sectionBody">
									<ActionButtonContainer
										mainActions={mainAction}
										searchEnabled={searchEnabled}
										searchEntries={this.searchEntries}
										enabledViewSelection={false}
										isGridLayout={isGridLayout}
										toggleLayout={this.toggleLayout}
									/>

									<div className='flex h-100 hasHeader'>
										<ViewsTable
											title={'Email'}
											columns={selectedSection !== 3 ? normalizedColumns : templateNormalizedColumns}
											toggledColumns={selectedSection !== 3 ? toggledColumns : templateToggledColumns}
											data={data}
											mainColumnActive={true}
											checked={checkedRows}
											checkRow={this.checkEntry}
											checkAllRows={this.checkAllEntries}
											//deletedRows={this.props.deletedEntries}
											sortData={this.sortData}
											defaultSort={'name'}
											sortDirection={'desc'}
											rowSettings={rowSettings}
										/>
									</div>
								</div>
								<ActionButtonContainer
									batchActions={batchActions}
									checkedEntryCount={checkedEntryCount}
									entriesLength={entriesLength}
									uncheckEntries={this.uncheckEntries}
								/>



								{toggleTemplateCenter && (
									<TemplateCenterModal
										isOpen={toggleTemplateCenter}
										//email={editedEmail}
										orgId={orgId}
										templates={this.state.templates}
										platformTemplates={this.state.platformTemplates}
										eventId={eventId}
										event={this.props.event}
										keyedMetadata={keyedMetadata}
										toggle={this.toggleTemplateCenter}
										update={this.updateCampaign}
										addCampaign={this.addCampaign}
										defaultSenderEmail={this.state.defaultSenderEmail}
										defaultSenderName={this.state.defaultSenderName}
									/>
								)}
								{addCampaignToggle && (
									<AddTemplateModal
										isOpen={addCampaignToggle}
										addTemplate={this.addNewTemplate}
										toggle={this.toggleAddCampaign}
										eventId={eventId}
										orgId={this.props.orgId}
										templates={templates}
										platformTemplates={this.state.platformTemplates}
									/>
								)}
								{toggleRenameEmail && (
									<RenameEmailModal
										isOpen={toggleRenameEmail}
										email={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										event={event}
										toggle={this.toggleRenameEmailModal}
										update={this.updateCampaign}
									/>
								)}
								{toggleDuplicateCampaign && (
									<DuplicateCampaignModal
										isOpen={toggleDuplicateCampaign}
										email={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										keyedMetadata={keyedMetadata}
										keyedData={keyedData}
										event={event}
										toggle={this.toggleDuplicateCampaignModal}
										update={this.updateCampaign}
									/>
								)}
								{toggleDeleteEmailModal && (
									<DeleteEmailModal
										isOpen={toggleDeleteEmailModal}
										email={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										event={event}
										toggle={this.toggleDeleteEmailModal}
										update={this.updateCampaign}
										noRedirect={true}
										updateDeleteCampaign={this.deleteSingleCampaign}
									/>
								)}
								{toggleCreateFromTemplate && (
									<AddCampaignFromTemplateModal
										isOpen={toggleCreateFromTemplate}
										addCampaign={this.addCampaign}
										template={selectedTemplate}
										toggle={this.createFromTemplateToggle}
										eventId={eventId}
										orgId={this.props.orgId}
									/>
								)}
								{toggleDeleteCampaigns && (
									<DeleteCampaignsModal
										isOpen={toggleDeleteCampaigns}
										checkedCampaigns={checkedCampaigns}
										toggle={this.toggleDeleteCampaigns}
										deleteCheckedCampaigns={this.deleteCheckedCampaigns}
										eventId={eventId}
										orgId={this.props.orgId}
									/>
								)}

								{templatesOpen && (
									<FieldModal
										size='large'
										isOpen={templatesOpen}
										toggle={() => this.setState({ templatesOpen: !templatesOpen })}
										modalTitle={'Manage Templates'}
										isFullWidthBody={true}
										cancelButtonLabel={'Close'}
										bodyContent={() => {
											return <div className='sectionContainer' >
												<Templates
													eventId={eventId}
													orgId={this.props.orgId} />
											</div>
										}} />)}

								{toggleCreateTemplateFromCampaign && (
									<CreateTemplateFromCampaignModal
										isOpen={toggleCreateTemplateFromCampaign}
										email={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										keyedMetadata={keyedMetadata}
										keyedData={keyedData}
										event={event}
										toggle={this.toggleCreateTemplateFromCampaign}
										addTemplate={this.addTemplate}
										noRedirect={true}
									/>
								)}



								{/* TEMPLATE MODALS */}

								{toggleCreateCampaignFromTemplate &&
									<CreateCampaignFromTemplateModal
										isOpen={toggleCreateCampaignFromTemplate}
										addCampaign={(emailObj, cb) => { cb() }}
										template={data.find((e) => e._id === this.state.selectedRow)}
										keyedMetadata={keyedMetadata}
										toggle={this.toggleCreateCampaignFromTemplate}
										eventId={eventId}
										orgId={orgId}
									/>}

								{toggleRenameTemplate &&
									<TemplateRenameModal
										isOpen={toggleRenameTemplate}
										template={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										toggle={this.toggleRenameTemplate}
										update={this.updateTemplate}

									/>
								}

								{toggleDuplicateTemplateModal &&
									<DuplicateTemplateModal
										isOpen={toggleDuplicateTemplateModal}
										template={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										toggle={this.toggleDuplicateTemplateModal}
										update={this.updateTemplate}
										event={event}
									/>
								}
								{toggleBatchDeleteTemplates && <DeleteTemplateModal
									isOpen={toggleBatchDeleteTemplates}
									template={data.find((e) => e._id === this.state.selectedRow)}
									orgId={orgId}
									eventId={eventId}
									event={event}
									isBatchDelete={Object.keys(checkedCampaigns)}
									templates={templates}
									toggle={this.toggleBatchDeleteTemplates}
									//update={this.updateTemplate}
									updateMultipleDeleteTemplate={this.updateMultipleDeleteTemplate}
									//updateDeleteTemplate={this.updateDeleteTemplate}
									noRedirect={true}
								/>}

								{toggleDeleteTemplate && (
									<DeleteTemplateModal
										isOpen={toggleDeleteTemplate}
										template={data.find((e) => e._id === this.state.selectedRow)}
										orgId={orgId}
										eventId={eventId}
										event={event}
										toggle={this.toggleDeleteTemplate}
										update={this.updateTemplate}
										updateDeleteTemplate={this.updateDeleteTemplate}
										noRedirect={true}
									/>
								)}

							</div>
						)}

						<LoadingWizard text="Loading Email Campaigns" loading={loading} />
					</Fragment>
				</Route>
			</Switch>
		);
	}
}

export default withRouter(EventCampaigns);
