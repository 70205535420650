import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';

class SectionHeader extends Component {
    constructor(props) {
        super(props);
        this.fileInput = null;

    }
    openFileSelector = () => {

        if (this.fileInput) {
            this.fileInput.click();
        }

    }
    render() {
        const {
            subtitle,
            title,
            breadcrumbs,
            description,
            editing,
            cancelEdit,
            saveEdit,
            updatedAt,
            mainActions,
            batchActions,
            additionalActions,
            enableLastLink,
            titleIcon,
            checkedEntryCount,
            entriesLength,
            uncheckEntries,
            subActions,
            classes,
            style,
            status,
            directLink,
            closeButton,
            enableProfileIcon,
            acronym,
            profilePicture,
            handleProfileFileChange,
            handleProfileImageDelete,
            disabledProfileEvents
        } = this.props;
        return (
            <div style={style} className={`sectionHeader ${classes ? classes : ''}`}>
                <div className="sectionTitleBar">

                    <div className="sectionTitle">
                        {((breadcrumbs && breadcrumbs.length > 0) || subtitle) && (
                            <div>
                                {breadcrumbs &&
                                    breadcrumbs.length > 0 && (
                                        <Link to={`${directLink ? directLink : breadcrumbs.length > 1 ? './' : '../'}`} className={` breadCrumbContainer ${breadcrumbs.length > 1 ? 'internal' : ''}`} >
                                            {breadcrumbs.map((b, i) => (
                                                <div key={i} className="breadCrumb flex-ac mb-5">
                                                    {i > 0 && (
                                                        <div className="breadCrumbSeparator">
                                                            <i className="las la-angle-right"></i>
                                                        </div>
                                                    )}
                                                    <p className="small">
                                                        {b.name}
                                                    </p>
                                                </div>
                                            ))}
                                        </Link>
                                    )}
                                {subtitle && <p className="small">{subtitle}</p>}
                            </div>
                        )}
                        {closeButton && <Link to={`./`}  >
                            <button style={{ padding: 7, marginLeft: -7, fontSize: 16 }}><i className='las la-times'></i></button>

                        </Link>}

                        {enableProfileIcon ?
                            <div className='flex'>
                                <div className='profileIcon mr-15'
                                    style={{ position: 'relative' }}
                                    onClick={() => {

                                        if (!profilePicture) {
                                            !disabledProfileEvents && this.openFileSelector();
                                        }


                                    }}
                                >
                                    {profilePicture ?
                                        (<img src={profilePicture} />)
                                        :
                                        (<h1 className={`${disabledProfileEvents?'':'acr'} white`}>{acronym}</h1>)}

                                    {!disabledProfileEvents && <div className="addImageIcon">

                                        {profilePicture ? <div className='flex'
                                        >
                                            <div className='replace mr-5 white'
                                                onClick={() => {
                                                    this.openFileSelector();
                                                }}
                                            >
                                                <i className="las la-redo-alt"></i>
                                            </div>

                                            <div className='white'>|</div>


                                            <div className='remove ml-5 white'
                                                onClick={() => {
                                                    handleProfileImageDelete && handleProfileImageDelete();
                                                }}
                                            >
                                                <i className="las la-trash"></i>
                                            </div>


                                        </div> : <i className="las la-camera white" />}

                                    </div> }

                                    <input
                                        type="file"
                                        ref={(input) => (this.fileInput = input)}
                                        style={{ display: 'none' }}
                                        onChange={(e) => {

                                            handleProfileFileChange &&
                                                handleProfileFileChange(e);
                                        }}
                                    />
                                </div>
                                <div className="flex-ac">
                                    <h1 className="sectionName">{title}</h1>
                                </div>
                            </div> : <div className="flex-ac">
                                <h1 className="sectionName">{title}</h1>
                            </div>}

                        {/* {description && <p>{description}</p>} */}
                        {status && <p className='mt-3'>{status}</p>}
                    </div>
                    <div className="flex ml-a">
                        {this.props.additionalElements &&
                            this.props.additionalElements()
                        }
                        {subActions && (
                            <UncontrolledDropdown className="ml-a" style={{ float: 'right', marginTop: 20 }}>
                                <DropdownToggle className="p-7">
                                    <i className='las la-ellipsis-v'></i>
                                </DropdownToggle>
                                <DropdownMenu right className="columnDropdown p-20">
                                    <h5 className='mb-10'>Options</h5>
                                    {subActions.map((sa, index) => {
                                        return sa.type == 'link' ? (
                                            <Link to={sa.target}>
                                                <DropdownItem>
                                                    {sa.iconClass && <i className={`mr-10 ${sa.iconClass}`} />}
                                                    {sa.label}
                                                </DropdownItem>

                                            </Link>) : (
                                            <DropdownItem onClick={sa.onClick}>
                                                {sa.iconClass && <i className={`mr-10 ${sa.iconClass}`} />}
                                                {sa.label}
                                            </DropdownItem>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                    </div>
                </div>

                {(mainActions || batchActions || additionalActions) && (
                    <div className="actionButtonContainer mt-10">
                        {mainActions &&
                            mainActions.length > 0 && (
                                <div
                                    style={{ opacity: !mainActions[0].disabled ? '1' : '.5' }}
                                    className="mainActionContainer mr-10"
                                >
                                    {mainActions[0].type == 'link' ? (
                                        <Link to={mainActions[0].target} className="mainAction">
                                            <div >{mainActions[0].label}</div>
                                        </Link>
                                    ) : (
                                        <div
                                            className="mainAction"
                                            onClick={!mainActions[0].disabled && mainActions[0].onClick}
                                        >
                                            <div >{mainActions[0].label}</div>
                                        </div>
                                    )}
                                    {mainActions.length > 1 && (
                                        <UncontrolledDropdown inNavbar>
                                            <DropdownToggle className="columnToggle">
                                                <i className="las la-angle-down" />
                                            </DropdownToggle>
                                            <DropdownMenu right className="columnDropdown p-10">
                                                {mainActions.map((action, index) => {
                                                    if (index > 0) {
                                                        return action.type == 'link' ? (
                                                            <Link to={action.target} className="supAction">
                                                                <div className="calibreRegular fs-16">{action.label}</div>
                                                            </Link>
                                                        ) : (
                                                            <div className="supAction" onClick={action.onClick}>
                                                                <div className="calibreRegular fs-16">{action.label}</div>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    )}
                                </div>
                            )}

                        {batchActions &&
                            batchActions.length > 0 && (
                                <div className="selectedContainer m-0">
                                    <div className=" mr-10">
                                        {checkedEntryCount} selected out of {entriesLength} record{entriesLength > 1 ? 's' : ''}
                                    </div>
                                    {checkedEntryCount > 0 && (
                                        <div
                                            className="actionButton actionFade"
                                            style={{ width: 30, height: 30, padding: 0 }}
                                            onClick={uncheckEntries}
                                        >
                                            <i className="las la-times" style={{ margin: 0 }} />
                                        </div>
                                    )}
                                    {checkedEntryCount > 0 && (
                                        <div className="flex-ac">
                                            <div className="breakBar">|</div>
                                            {batchActions.map((action) => (
                                                <div
                                                    className={`actionButton ${action.class} mr-10`}
                                                    onClick={action.onClick}
                                                >
                                                    {action.iconClass &&
                                                        action.iconClass != '' && <i className={action.iconClass} />}
                                                    <div>{action.label}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}

                        {additionalActions &&
                            additionalActions.length > 0 && (
                                <div className="additionalActions">{additionalActions.map((action) => action)}</div>
                            )}
                    </div>
                )}
                <div className="sectionHeaderChildren">{this.props.children}</div>
            </div>
        );
    }
}

export default SectionHeader;
