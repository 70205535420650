import React, { Fragment, Component } from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import 'react-toggle/style.css';
import ViewsTable from '../../../../components/tables/ViewsTable';

class GroupAddAttendeesModal extends Component {
	constructor(props) {
		super(props);
		const columns = {
			firstName: 'First Name',
			lastName: 'Last Name',
			email: 'Email'
		};
		const columnTypes = {
			firstName: 'text',
			lastName: 'text',
			email: 'text'
		};
		const columnWidth = {
			firstName: 150,
			lastName: 150,
			email: 200
		};
		const toggledColumns = {};
		const normalizedColumns = Object.keys(columns).map((cfc) => {
			toggledColumns[cfc] = true;
			return {
				label: columns[cfc],
				key: cfc,
				value: cfc,
				type: columnTypes[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		this.state = {
			orgId: props.orgId,
			eventId: props.eventId,
			checkedAttendees: {},
			searchedAttendees: [],
			allChecked: false,
			searchSelected: false,
			searching: false,
			searchTerm: '',
			searchField: '',
			loading: false,
			submitting: false,
			columns: columns,
			columnTypes: columnTypes,
			normalizedColumns: normalizedColumns,
			toggledColumns: toggledColumns,
			columnWidth: columnWidth,
			selectedColumnSort: null,
			sortDesc: false
		};
	}

	fetchMoreAttendees = async (searching) => {
		const eventId = this.props.eventId;
		this.setState({ loading: true });
		let queryUrl = searching
			? `Organizations/${this.state.orgId}/events/${eventId}/attendees?a=${this.state.searchedAttendees
				.length}&b=${this.state.searchTerm}&c=${this.state.searchField}`
			: `Organizations/${this.state.orgId}/events/${eventId}/attendees?a=${this.props.attendees.length}`;
		await API().get(queryUrl).then((res) => {
			if (res.data) {
				this.props.loadMoreAttendees(res.data);
				this.setState({ loading: false });
			}
		});
	};

	filterAttendees = (attendees) => {
		return attendees.filter((a) => !this.props.existingAttendees.find((at) => at._id == a._id));
	};

	searchAttendees = async () => {
		const eventId = this.props.eventId;
		this.setState({ loading: true });
		let queryUrl = `Organizations/${this.state.orgId}/events/${eventId}/attendees?a=${this.state.searchedAttendees
			.length}&b=${this.state.searchTerm}&c=${this.state.searchField}`;
		await API().get(queryUrl).then((res) => {
			if (res.data) {
				this.setState({ searchedAttendees: res.data, searching: true, loading: false });
			}
		});
	};

	selectAttendee = (index) => {
		this.setState({ selectedAttendee: index, attendeeSelected: true });
	};

	AttendeeItem(attendee, index) {
		return (
			<div key={index} className="tableColumnRow">
				<div
					className={`checkBox mr-10 ${this.state.checkedAttendees[attendee._id] || false ? 'checked' : ''}`}
					onClick={() => {
						const checked = this.state.checkedAttendees;
						checked[attendee._id] ? delete checked[attendee._id] : (checked[attendee._id] = true);
						this.setState({
							checkedAttendees: checked,
							allChecked: Object.keys(checked).length === this.props.attendeeCount
						});
					}}
				>
					<i className="las la-check" />
				</div>

				<div className="tableColumnRowCell main text-truncate calibreRegular">
					{/* <div
						style={{
							width: '30px',
							height: '30px',
							background: 'gray',
							borderRadius: '50%',
							textAlign: 'center'
						}}
					>
						 {attendee.image ? (
							<img src={attendee.image} style={{}} />
						) : (
							<div
								style={{
									color: 'white',
									fontSize: '12px',
									texAlign: 'center',
									paddingTop: '9px'
								}}
								className=""
							>
								{attendee.firstName.toUpperCase().charAt(0)}
								{attendee.lastName.toUpperCase().charAt(0)}
							</div>
						)} 
					</div> */}
					<div
						className="fs-16"
						style={{ marginTop: 'auto', marginBottom: 'auto' }}
					>{`${attendee.firstName} ${attendee.lastName}`}</div>
				</div>

				<div className="tableColumnRowCell main text-truncate calibreRegular fs-16">{attendee.email}</div>
			</div>
		);
	}

	toggleSearch = () => {
		this.setState({ searchSelected: !this.state.searchSelected });
	};

	groupCheckedAttendees = (group, callback) => {
		this.props.groupAttendees(group, this.state.checkedAttendees, (added) => {
			callback(added);
		});
		this.setState({ groupedAttendees: [...this.state.groupedAttendees, group], loading: false });
	};

	checkAllAttendees = async () => {
		if (!this.state.allChecked) {
			const eventId = this.props.eventId;
			const orgId = this.props.orgId;
			await API().get(`Organizations/${orgId}/events/${eventId}/checkAttendees`).then((res) => {
				if (res.data) {
					const ids = [];
					res.data.map((id) => (ids[id] = true));
					this.setState({ checkedAttendees: ids, allChecked: true });
				}
			});
		} else {
			this.setState({ checkedAttendees: {}, allChecked: false });
		}
	};

	resetAdd = () => {
		this.setState({
			checkedAttendees: {},
			searchedAttendees: [],
			allChecked: false,
			searchSelected: false,
			searching: false,
			searchTerm: '',
			searchField: '',
			loading: false,
			submitting: false
		});
	};

	addAttendees = async (attendees) => {
		const { group } = this.props;
		this.setState({ submitting: true });
		const { eventId, orgId } = this.props;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/groups/${group._id}`, {
				attendees: [...group.attendees.map((ga) => ga._id), ...attendees]
			})
			.then((res) => {
				if (res.data) {
					this.props.addAttendees(attendees.map((a) => this.props.attendees.find((at) => at._id == a)));
					this.props.toggle();
				}
			});
	};

	groupCheckedAttendees = (group, callback) => {
		this.props.groupAttendees(group, this.state.checkedAttendees, (added) => {
			callback(added);
		});
		this.setState({ groupedAttendees: [...this.state.groupedAttendees, group], loading: false });
	};

	updatedCheckedAttendees = (checked) => {
		this.setState({ checkedAttendees: checked });
	};

	sortData = (column, sortAsc, sortDesc) => {
		const { normalizedColumns } = this.state;
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		this.setState({
			normalizedColumns: updatedColumns,
			selectedColumnSort: column,
			sortDesc: sortDesc ? true : false
		});
	};

	render() {
		const {
			loading,
			submitting,
			checkedAttendees,
			columnWidth,
			normalizedColumns,
			toggledColumns,
			selectedColumnSort,
			sortDesc
		} = this.state;
		const { attendees, isOpen, toggle } = this.props;
		let availableAttendees = this.filterAttendees(attendees);

		if (selectedColumnSort) {
			availableAttendees = availableAttendees.sort((a, b) => {
				const columnType = selectedColumnSort.type || typeof a[selectedColumnSort.key];
				if (a[selectedColumnSort.key] && b[selectedColumnSort.key]) {
					switch (columnType) {
						case 'string':
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
						case 'number':
							return a[selectedColumnSort.key] - b[selectedColumnSort.key];
						case 'date':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'time':
							return (
								new Date(a[selectedColumnSort.key]).getTime() -
								new Date(b[selectedColumnSort.key]).getTime()
							);
						case 'boolean':
							const a1 = a[selectedColumnSort.key] ? 'yes' : 'no';
							const b1 = b[selectedColumnSort.key] ? 'yes' : 'no';
							return a1.localeCompare(b1);
						default:
							return a[selectedColumnSort.key]
								.toLowerCase()
								.trim()
								.localeCompare(b[selectedColumnSort.key].toLowerCase().trim());
					}
				} else if (a[selectedColumnSort.key]) {
					return 1;
				}
				return -1;
			});

			availableAttendees = sortDesc ? availableAttendees.reverse() : availableAttendees;
		}
		return (


			<div>
				<FieldModal
					size="large"
					isOpen={isOpen}
					modalTitle={"Add Attendees to Group"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<ViewsTable
									title="Attendees"
									style={{ margin: 0, height: 'calc(100vh - 300px)' }}
									classes={"fit"}
									columns={normalizedColumns}
									toggledColumns={toggledColumns}
									data={availableAttendees}
									mainColumnActive={false}
									columnWidth={columnWidth}
									checked={this.state.checkedAttendees}
									checkRow={(entryId) => {
										const checked = this.state.checkedAttendees;
										checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
										this.updatedCheckedAttendees(checked);
										this.setState({
											checkedAttendees: checked
										});
									}}
									checkAllRows={(entryIds) => {
										let checked = this.state.checkedAttendees;
										entryIds.length > 0
											? entryIds.forEach((entryId) => {
												checked[entryId] = true;
											})
											: (checked = {});
										this.updatedCheckedAttendees(checked);

										this.setState({
											checkedAttendees: checked
										});
									}}
									sortData={this.sortData}
								/>{' '}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => {

						this.setState({ submitting: true });
						if (Object.keys(checkedAttendees).length > 0) {
							this.addAttendees(Object.keys(checkedAttendees));
						} else {
							this.setState({ invalid: true, submitting: false });
						}
					}}
					actionButtonDisabled={submitting}
					isFullWidthBody={true}
				></FieldModal>
			</div>

		);
	}
}

export default GroupAddAttendeesModal;
