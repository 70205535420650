import { getCookie } from './cookie';

export default (req) => {
	const jwtToken = getCookie('authToken', req);
	if (jwtToken) {
		window.io.sails.headers = {
			Authorization: 'Bearer ' + jwtToken
		};
	}
	const socket = window.io.sails.connect(
		process.env.NODE_ENV === 'production'
			? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
			: `http://${window.location.hostname}:5015/`
	);

	return socket;
};
