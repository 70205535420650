import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import EventSession from './session';
import AddSessionModal from './modals/addSessionModal';
import DeleteSessionModal from './modals/deleteDefaultModal';
import GroupSessionsModal from './modals/groupSessionsModal';
import ViewsContainer from '../../../components/views/ViewsContainer';

let batchActionCB;
class EventSessions extends Component {
	constructor(props) {
		super(props);
		const customFieldColumns = {
			name: 'Name',
			subtitle: 'Subtitle',
			description: 'Description',
			startTime: 'Start Time',
			endTime: 'End Time',
			isAllDay: 'All Day',
			isGeneral: 'Is General',
			location: 'Location',
			virtualMeetingType: 'Session Type'
		};
		const defaultFieldTypes = {
			name: 'text',
			subtitle: 'text',
			description: 'text',
			startTime: 'time',
			endTime: 'time',
			isAllDay: 'boolean',
			isGeneral: 'boolean',
			location: 'text',
			virtualMeetingType: 'text'
		};
		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'subtitle',
					'description',
					'startTime',
					'endTime',
					'isAllDay',
					'isGeneral',
					'location',
					'virtualMeetingType'
				]
			},
		};
		props.customFields.forEach((category) => {
			const fields = category.fields;
			fields.forEach((f) => {
				customFieldColumns[category._id + '*' + f._id] = f.fieldName;
			});
		});

		this.state = {
			categoryColumns: fieldCategories,
			eventId: props.eventId,
			newSessions: [],
			groupSessions: [],
			checkedSessions: {},
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			allChecked: false,
			sessionSelected: false,
			selectedSession: 0,
			searchSelected: false,
			searchTerm: '',
			searchField: '',
			sortByColumn: { name: true },
			addSessionModalOpen: false,
			deleteSessionsModalOpen: false,
			groupSessionsModalOpen: false,
			importSessionsModalOpen: false,
			newSession: {},
			sessionGroup: {},
			loading: false,
			upcomingCount: 0,
			completedCount: 0,
			deletedSessions: [],
			isDeleting: false,
			sidePanelOpen: false,
			editCount: 0,
			activeRow: '',
		};
	}

	addSession = (session, cb) => {
		this.props.addSession(session, () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				cb && cb();
			})
		});
	};

	toggleDeleteSessions = (checkedSessions, cb) => {
		batchActionCB = cb;
		this.setState({
			checkedSessions: checkedSessions || this.state.checkedSessions,
			deleteSessionsModalOpen: !this.state.deleteSessionsModalOpen
		});
	};

	deleteCheckedSessions = () => {
		this.props.deleteSessions(Object.keys(this.state.checkedSessions), () => {
			//batchActionCB && batchActionCB('delete');
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
				this.toggleDeleteSessions();
			});
		});
	};

	deleteSingleSession = (session) => {
		this.props.deleteSessions(Object.keys(session), () => {
			this.setState({
				editCount: this.state.editCount + 1
			}, () => {
				batchActionCB && batchActionCB('delete');
			});
		});
	};

	toggleGroupSessions = (checkedSessions) => {
		this.setState({
			sessionGroup: {},
			checkedSessions: checkedSessions || {},
			groupSessionsModalOpen: !this.state.groupSessionsModalOpen
		});
	};
	groupCheckedSessions = (group, callback) => {
		this.props.groupSessions(group, this.state.checkedSessions, (added) => {
			callback(added);
		});
		this.setState({
			groupSessions: [...this.state.groupSessions, group], loading: false,
			editCount: this.state.editCount + 1
		});
	};

	updateSession = (sessionId, session) => {
		const updatedSessions = this.state.sortedSessions.map((s) => {
			if (sessionId == s.id) {
				session.metadata.forEach((meta) => (session[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
				s = { ...s, ...session };
			}
			return s;
		});
		this.setState({
			sortedSessions: updatedSessions,
			editCount: this.state.editCount + 1
		}, () => {

			this.props.updateSession(sessionId, session)
			this.setState({ editCount: this.state.editCount + 1 })
		});
	};

	toggleAddSession = (cb) => {
		batchActionCB = cb
		this.setState({ addSessionModalOpen: !this.state.addSessionModalOpen });
	};

	saveSession = (session, callback) => {
		this.props.saveSession(session, () => {
			this.setState({ editCount: this.state.editCount + 1 }, () => {
				callback();
			})

		});
	}
	setActiveRow = (sessionId) => {
		this.setState({ activeRow: sessionId })
	}
	render() {
		const {
			eventId,
			selectedSession,
			deleteSessionsModalOpen,
			addSessionModalOpen,
			groupSessionsModalOpen,
			columns,
			isDeleting,
			columnTypes,
			checkedSessions,
			sidePanelOpen,
			editCount,
			categoryColumns,
			activeRow
		} = this.state;

		const { program, sessions, readOnly, sessionsViews, orgId, programId } = this.props;
		const checkedCount = Object.keys(checkedSessions).length;

		return (
			<Switch>
				<Route path={`/${orgId}/programs/${program._id}/schedule`}>
					<Fragment>
						<ViewsContainer
							//key={`sessions${editCount}`}
							activeRow={activeRow}
							categoryColumns={categoryColumns}
							programId={programId}
							orgId={orgId}
							views={sessionsViews}
							entries={sessions}
							columns={columns}
							columnTypes={columnTypes}
							title="Default Event Schedule"
							description="Sessions listed here will be added to all approved events."
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddSession, label: 'Add Session' },
							]}
							batchActions={[
								{
									label: 'Delete',
									iconClass: 'las la-trash-alt',
									class: 'danger',
									onClick: this.toggleDeleteSessions
								}
							]}
							enableDownloadViews={true}
							type={'Session'}
							referenceCollection={'Programs'}
							referenceId={program._id}
							breadcrumbs={[
								{
									name: program.name
								}
							]}
						/>
						<DeleteSessionModal
							isOpen={deleteSessionsModalOpen}
							toggle={() => this.toggleDeleteSessions()}
							title={`Delete ${checkedCount > 1 ? 'Sessions' : 'Session'}`}
							message={`You are about to delete ${checkedCount} ${checkedCount > 1
								? 'sessions'
								: 'session'}, this is irreversible,
						would you like to continue?`}
							submit={() =>
								this.deleteCheckedSessions(Object.keys(checkedSessions), () => {
									this.setState({ checkedSessions: {}, deleteSessionsModalOpen: false });
								})}
						/>
						{/*<GroupSessionsModal
							isOpen={groupSessionsModalOpen}
							addGroup={this.groupCheckedSessions}
							toggle={this.toggleGroupSessions}
							groups={this.props.event.groups}
							count={Object.keys(this.state.checkedSessions).length}
						/> */}
						{addSessionModalOpen && <AddSessionModal
							isOpen={addSessionModalOpen}
							handleSubmit={this.addSession}
							toggle={this.toggleAddSession}
							programId={programId}
							orgId={this.props.orgId}
						/>}

						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/programs/:programId/schedule/:sessionId`}
									render={(props) => {
										const attendeeId = props.match.params.attendeeId;
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={attendeeId}>
											<EventSession
												orgId={this.props.orgId}
												program={this.props.program}
												programId={this.state.programId}
												index={selectedSession}
												sessions={sessions}
												readOnly={readOnly}
												customFields={this.props.customFields}
												locations={this.props.locations}
												updateSession={this.updateSession}
												deleteSession={this.deleteSingleSession}
												eventEmails={this.props.eventEmails}
												addNewGroup={this.props.addNewGroup}
												organization={this.props.organization}
												saveSession={this.saveSession}
												setActiveRow={this.setActiveRow}
												venues={this.props.venues}
												event={this.props.event}
												addNewLocation={this.props.addNewLocation}
												renameLocation={this.props.renameLocation}
												deleteLocation={this.props.deleteLocation}
											/>
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/programs/:programId/schedule`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>
					</Fragment>
				</Route>

			</Switch>
		);
	}
}

export default withRouter(EventSessions);
