import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import API from '../../../../utils/API';

class SessionRemoveCheckedInModal extends React.Component {
	state = {
		submitting: false
	};

	removeCheckedInAttendee = async () => {
		const { eventId, orgId, markedCheckedIn, session, checkedAttendee } = this.props;
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				checkedIns: Object.keys(checkedAttendee),
				request: 'removeCheckedIns'
			})
			.then((res) => {
				if (res.data) {
					this.props.removeCheckedIn(Object.keys(checkedAttendee));
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, markedCheckedIn, checkedAttendee } = this.props;
		const { submitting } = this.state;

		const checkedInLength = Object.keys(checkedAttendee).length;

		return (
			<Modal isOpen={isOpen} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="calibreBold fs-30">Delete Check In{checkedInLength != 1 ? 's' : ''}</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<p className="calibreRegular">
						You are about to delete {checkedInLength} attendee{checkedInLength != 1 ? 's' : ''} from the
						checked in list. Would you like to continue? This is irreversable.
					</p>
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionDelete m-0"
						disabled={submitting}
						onClick={() => {
							this.removeCheckedInAttendee();
						}}
					>
						<i className="las la-trash" />
						<div className="calibreBold fs-16">Yes, Delete</div>
					</Button>
					<Button
						outline
						onClick={this.props.toggle}
						className="modalButton actionButton actionOutline mb-0 ml-a"
					>
						<i className="las la-times" />
						<div className="calibreBold fs-16">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default SessionRemoveCheckedInModal;
