import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DeleteAttendeeModal from './modals/deleteDefaultModal';
import DeleteSessionModal from './modals/deleteDefaultModal';
import BasicSectionHeader from '../../../components/views/BasicSectionHeader';
import TabsContainer from '../../../components/views/TabsContainer';
import ViewsTable from '../../../components/tables/ViewsTable';
import AddToGroupModal from './modals/addToGroupModal';
import ActionButtonContainer from '../../../components/views/ActionButtonContainer';
import InputField from '../../../components/inputs/inputField';
import ToggleField from '../../../components/inputs/toggleField';
import SectionHeader from '../../../components/views/SectionHeader';
import SectionFooter from '../../../components/views/SectionFooter';
import DeleteGroupModal from './modals/deleteDefaultModal';

import { UncontrolledTooltip } from 'reactstrap';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
class EventGroup extends Component {
	constructor(props) {
		super(props);
		const { match: { params } } = this.props;
		const group = props.groups.find((g) => g._id == params.groupId);
		this.props.setActiveRow(params.groupId)
		this.state = {
			group: group,
			editedGroup: { ...group },
			checkedAttendees: {},
			checkedSessions: {},
			customFields: {},
			selectedSection: 0,
			deleteSessionModalOpen: false,
			deleteGroupsModalOpen: false,
			loading: true,
			editing: false,
			addAttendeeModalOpen: false,
			addSessionModalOpen: false,
			sortDesc: false,
			deleteAttendeesModalOpen: false,
			searchTerm: '',
			requiredFields: {
				name: false
			}
		};
	}
	componentWillUnmount() {
		this.props.setActiveRow(null);
	}

	saveInfo = () => {
		const { editedGroup } = this.state;
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!editedGroup[key] || editedGroup[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.props.saveGroup(editedGroup, () => {
				this.setState({ group: { ...editedGroup }, editing: false, requiredFields: requiredFields });
			});
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	toggleDelete = () => {
		this.setState({ deleteGroupsModalOpen: !this.state.deleteGroupsModalOpen });
	};

	toggleEditing = (bool) => {
		this.setState({ editing: bool });
	};

	editGroupField = (property, value) => {
		const editedGroup = this.state.editedGroup;
		editedGroup[property] = value;
		this.setState({ editedGroup: editedGroup, editing: true });
	};

	cancelEdit = () => {
		const group = this.state.group;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({ editedGroup: { ...group }, requiredFields: requiredFields, editing: false });
	};
	/* Download */
	s2ab = (s) => {
		var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
		var view = new Uint8Array(buf); //create uint8array as viewer
		for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
		return buf;
	};
	downloadReportAttendees = async () => {
		const { editedGroup } = this.state;
		const columns = {
			email: 'Email',
			firstName: 'First Name',
			lastName: 'Last Name',
			phone: 'Phone',
			createdAt: 'Created At'
		};
		const columnTypes = {
			email: 'string',
			firstName: 'string',
			lastName: 'string',
			phone: 'string',
			createdAt: 'date'
		};
		let attendees = editedGroup.attendees.map((attendeeId) => {
			const attendee = this.props.program.eventDefaults.attendees.find(
				(att) => att._id === attendeeId
			);
			return attendee;
		});
		const entries = attendees; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData
		};
		const reportDetails =
			{ Title: editedGroup.name + " Report", Subject: 'Group Session Report ' };
		this.downloadRegular(reportDetails, data);
	};

	downloadReportSessions = async () => {
		const { editedGroup } = this.state;
		const columns = {
			name: 'Name',
			subtitle: 'Subtitle',
			description: 'Description',
			startTime: 'Start Time',
			endTime: 'End Time'
		};
		const columnTypes = {
			name: 'string',
			subtitle: 'string',
			description: 'string',
			startTime: 'time',
			endTime: 'time'
		};
		const sessions = editedGroup.sessions.map((sessionId) => {
			const session = this.props.program.eventDefaults.sessions.find((sesh) => sesh._id == sessionId);
			return session;
		});
		const entries = sessions; //selectedCustomView ? organizedEntries : allEntries;
		const dataRows = [];
		entries.forEach((entry) => {
			const dataRow = {};
			Object.keys(columns).forEach((hc) => {
				if (entry[hc] != null) {
					if (columnTypes[hc] === 'boolean' || columnTypes[hc] === 'bool') {
						dataRow[hc] = entry[hc] ? 'Yes' : 'No';
					} else {
						dataRow[hc] = entry[hc];
					}
				}
			});
			dataRows.push(dataRow);
		});
		const cleanData = [];
		const headers = Object.keys(columns).map((header, hIndex) => {
			dataRows.forEach((data, dIndex) => {
				if (!cleanData[dIndex]) cleanData[dIndex] = [];
				cleanData[dIndex][hIndex] = data[header] || '';
			});
			return columns[header];
		});
		const data = {
			headerRow: headers,
			dataRows: cleanData
		};

		const reportDetails =
			{ Title: editedGroup.name + " Report", Subject: 'Group Session Report ' };
		this.downloadRegular(reportDetails, data);
	};

	downloadRegular = (report, data) => {
		var wb = XLSX.utils.book_new();
		wb.Props = {
			Title: report.Title,
			Subject: report.Subject,
			Author: 'Simple Events',
			CreatedDate: new Date()
		};
		wb.SheetNames.push('Data');
		/* make worksheet */
		var ws_data2 = [data.headerRow, ...data.dataRows];
		var ws2 = XLSX.utils.aoa_to_sheet(ws_data2);

		/* Add the worksheet to the workbook */
		wb.Sheets['Data'] = ws2;

		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		saveAs(
			new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }),
			`${report.Title}_${moment().format('LLL')}.xlsx`
		);
	};
	/* Search */
	searchEntries = (searchText) => {
		this.setState({
			searching: searchText != '' ? true : false,
			searchText: searchText ? searchText : '',
		});
	};
	renderSection = () => {
		const { selectedSection, editedGroup, requiredFields, group, searchTerm } = this.state;

		let batchActions = [];
		let checkedEntryCount = 0;
		let entriesLength = 0;
		let uncheckEntries = null;

		let mainActions = [];
		switch (selectedSection) {
			case 1:
				mainActions = [{ type: 'button', label: 'Add Attendee', onClick: this.toggleAddAttendee }];
				break;
			case 2:
				mainActions = [{ type: 'button', label: 'Add Session', onClick: this.toggleAddSession }];
				break;
		}
		if (selectedSection == 0) {
			return (
				<div className='sectionBody'>

					<div className="sectionBlock">
						<h2 className="blockHeader">Group details</h2>
						<InputField
							label="Name"
							required={true}
							errorTrigger={requiredFields.name}
							value={editedGroup.name}
							type="text"
							onChange={(e) => this.editGroupField('name', e.target.value)}
						/>
						<ToggleField
							checked={editedGroup.maxCapacityEnabled}
							label={'Enable Max Capacity'}
							icons={false}
							onChange={() => this.editGroupField('maxCapacityEnabled', !editedGroup.maxCapacityEnabled)}
						/>

						{editedGroup.maxCapacityEnabled && (
							<InputField
								label={'Max Capacity'}
								value={editedGroup.maxCapacity || ''}
								type="text"
								onChange={(e) => this.editGroupField('maxCapacity', e.target.value)}
							/>
						)}
					</div>
				</div>
			);
		} else if (selectedSection == 1) {

			batchActions = [
				{
					type: 'button',
					label: 'Delete Attendees',
					onClick: this.toggleDeleteAttendees,
					iconClass: 'las la-trash-alt',
					class: 'danger'
				}
			];
			checkedEntryCount = Object.keys(this.state.checkedAttendees).length;
			entriesLength = group.attendees.length;
			uncheckEntries = () => this.setState({ checkedAttendees: {} });
			const columns = {
				email: 'Email',
				firstName: 'First Name',
				lastName: 'Last Name',
				phone: 'Phone',
				createdAt: 'Created At'
			};
			const columnTypes = {
				email: 'string',
				firstName: 'string',
				lastName: 'string',
				phone: 'string',
				createdAt: 'date'
			};
			const toggledColumns = {};
			const normalizedColumns = Object.keys(columns).map((cfc) => {
				toggledColumns[cfc] = true;
				return {
					label: columns[cfc],
					key: cfc,
					value: cfc,
					type: columnTypes[cfc],
					sortAsc: false,
					sortDesc: false
				};
			});

			const attendees = editedGroup.attendees.map((attendeeId) => {
				const attendee = this.props.program.eventDefaults.attendees.find(
					(att) => att._id === attendeeId
				);
				return attendee;
			});
			let data = attendees;
			if (searchTerm.length > 0) {
				const searchTerms = searchTerm.toLowerCase().split(' ');
				searchTerms.forEach((term) => {
					if (term != '') {
						data = data.filter((entry) => {
							let found = false;
							Object.keys(columns).forEach((key) => {
								if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
									found = true;
								}
							});
							return found;
						});
					}
				});
			}
			return (<div className='sectionBody posRel'>
				<div className="actionButtonContainer headerHeight flex-ac">
					<ActionButtonContainer mainActions={mainActions} />
					<InputField
						classes="m-0 viewSearch"
						placeholder="Search "
						clickIcon={true}
						required={true}
						value={searchTerm}
						onChange={(e) => {
							clearTimeout(this.searchTimeout);
							this.setState({ searchTerm: e.target.value },
								() => {
									this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
								});
						}}
						prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
						inputIcon={searchTerm != '' &&
							<i
								onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
								className="las la-times-circle"
								style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
							/>}
					/>
					<div className="sectionOptionsContainer">
						<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReportAttendees()}>
							<i className="las la-download" />
							<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
						</div>
					</div>
				</div>
				<ViewsTable
					title={"Attendees"}
					columns={normalizedColumns}
					classes="nested"
					toggledColumns={toggledColumns}
					data={data}
					mainColumnActive={false}
					checked={this.state.checkedAttendees}
					checkRow={(entryId) => {
						const checked = this.state.checkedAttendees;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.setState({
							checkedAttendees: checked
						});
					}}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedAttendees;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.setState({
							checkedAttendees: checked
						});
					}}
					deletedRows={this.props.deletedEntries}
					sortData={(column, sortAsc, sortDesc) => {
						const sortedData = this.sortData(
							column,
							sortAsc,
							sortDesc,
							editedGroup.attendees,
							normalizedColumns
						);
						this.setState({ editedGroup: { ...editedGroup, attendees: sortedData.organizedEntries } });
					}}
				/>

				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					disableNueFooter={true}
					uncheckEntries={uncheckEntries} />
				{/* Delete Attendee */}
				{this.state.deleteAttendeesModalOpen && (
					<DeleteAttendeeModal
						isOpen={true}
						toggle={() => this.deleteAttendees()}
						//group={group}
						title={`Remove ${checkedEntryCount > 1 ? 'Attendees' : 'Attendees'}`}
						message={`You are about to delete ${checkedEntryCount} ${checkedEntryCount > 1
							? 'Attendees'
							: 'Attendee'}, this is irreversible,
						would you like to continue?`}
						//submit={this.props.deleteAttendee}
						submit={() =>
							this.deleteAttendees(Object.keys(this.state.checkedAttendees), () => {
								this.setState({ checkedAttendees: {}, deleteAttendeesModalOpen: false }, () => {
									this.saveInfo();
								});
							})}

					/>
				)}
			</div>
			);
		} else if (selectedSection == 2) {

			batchActions = [
				{
					type: 'button',
					label: 'Delete Sessions',
					onClick: this.toggleDeleteSessions,
					iconClass: 'las la-trash-alt mr-10',
					class: 'neu danger'
				}
			];
			checkedEntryCount = Object.keys(this.state.checkedSessions).length;
			entriesLength = group.sessions.length;
			uncheckEntries = () => this.setState({ checkedSessions: {} });
			const columns = {
				name: 'Name',
				subtitle: 'Subtitle',
				description: 'Description',
				startTime: 'Start Time',
				endTime: 'End Time'
			};
			const columnTypes = {
				name: 'string',
				subtitle: 'string',
				description: 'string',
				startTime: 'time',
				endTime: 'time'
			};
			const toggledColumns = {};
			const normalizedColumns = Object.keys(columns).map((cfc) => {
				toggledColumns[cfc] = true;
				return {
					label: columns[cfc],
					key: cfc,
					value: cfc,
					type: columnTypes[cfc],
					sortAsc: false,
					sortDesc: false
				};
			});

			const sessions = editedGroup.sessions.map((sessionId) => {
				const session = this.props.program.eventDefaults.sessions.find((sesh) => sesh._id == sessionId);
				return session;
			});
			let data = sessions;
			if (searchTerm.length > 0) {
				const searchTerms = searchTerm.toLowerCase().split(' ');
				searchTerms.forEach((term) => {
					if (term != '') {
						data = data.filter((entry) => {
							let found = false;
							Object.keys(columns).forEach((key) => {
								if (term != '' && entry[key] && entry[key].toString().toLowerCase().includes(term)) {
									found = true;
								}
							});
							return found;
						});
					}
				});
			}
			return (<div className='sectionBody posRel'>
				<div className="actionButtonContainer headerHeight flex-ac">
					<ActionButtonContainer mainActions={mainActions} />
					<InputField
						classes="m-0 viewSearch"
						placeholder="Search "
						clickIcon={true}
						required={true}
						value={searchTerm}
						onChange={(e) => {
							clearTimeout(this.searchTimeout);
							this.setState({ searchTerm: e.target.value },
								() => {
									this.searchTimeout = setTimeout(() => this.searchEntries(this.state.searchTerm), 1000);
								});

						}}
						prefix={<i className="las la-search" style={{ marginRight: 5, transform: 'rotate(270deg)' }} />}
						inputIcon={searchTerm != '' &&
							<i
								onMouseDown={() => this.setState({ searchTerm: '', searching: false }, () => this.searchEntries(''))}
								className="las la-times-circle"
								style={{ visibility: searchTerm == '' ? 'hidden' : 'visible' }}
							/>}
					/>
					<div className="sectionOptionsContainer">
						<div id="downloadTable" className='c-pointer fs-20' onClick={() => this.downloadReportSessions()}>
							<i className="las la-download" />
							<UncontrolledTooltip target="downloadTable" placement="left">Download Table Data</UncontrolledTooltip>
						</div>
					</div>
				</div>

				<ViewsTable
					title={"Sessions"}
					columns={normalizedColumns}
					toggledColumns={toggledColumns}
					classes="nested"
					data={data}
					mainColumnActive={false}
					checked={this.state.checkedSessions}
					checkRow={(entryId) => {
						const checked = this.state.checkedSessions;
						checked[entryId] ? delete checked[entryId] : (checked[entryId] = true);
						this.setState({
							checkedSessions: checked
						});
					}}
					checkAllRows={(entryIds) => {
						let checked = this.state.checkedSessions;
						entryIds.length > 0
							? entryIds.forEach((entryId) => {
								checked[entryId] = true;
							})
							: (checked = {});
						this.setState({
							checkedSessions: checked
						});
					}}
					sortData={(column, sortAsc, sortDesc) => {
						const sortedData = this.sortData(
							column,
							sortAsc,
							sortDesc,
							editedGroup.sessions,
							normalizedColumns
						);
						this.setState({ editedGroup: { ...editedGroup, sessions: sortedData.organizedEntries } });
					}}
				/>

				<ActionButtonContainer
					batchActions={batchActions}
					checkedEntryCount={checkedEntryCount}
					entriesLength={entriesLength}
					disableNueFooter={true}
					uncheckEntries={uncheckEntries} />
				{/* Sessions */}
				{this.state.deleteSessionModalOpen && (
					<DeleteSessionModal
						isOpen={true}
						toggle={() => this.toggleDeleteSessions}
						title={`Remove ${checkedEntryCount > 1 ? 'Session' : 'Sessions'}`}
						message={`You are about to delete ${checkedEntryCount} ${checkedEntryCount > 1
							? 'Sessions'
							: 'Session'}, this is irreversible,
						would you like to continue?`}
						//group={group}
						submit={() =>
							this.deleteSessions(Object.keys(this.state.checkedSessions), () => {
								this.setState({ checkedSessions: {}, deleteSessionModalOpen: false }, () => {
									this.saveInfo();
								});
							})}
					/>
				)}

			</div>);
		}
	};

	sortData = (column, sortAsc, sortDesc, organizedEntries, normalizedColumns) => {
		const updatedColumns = normalizedColumns.map((col) => {
			if (col.key == column.key) {
				col.sortAsc = sortAsc;
				col.sortDesc = sortDesc;
			} else {
				col.sortAsc = false;
				col.sortDesc = false;
			}
			return col;
		});

		let reorganizedData = organizedEntries.sort((a, b) => {
			const columnType = column.type || typeof a[column.key];
			if (a[column.key] && b[column.key]) {
				switch (columnType) {
					case 'string':
						return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
					case 'number':
						return a[column.key] - b[column.key];
					case 'date':
						return new Date(a[column.key]).getTime() - new Date(b[column.key]).getTime();
					case 'time':
						return new Date(a[column.key]).getTime() - new Date(b[column.key]).getTime();
					case 'boolean':
						const a1 = a[column.key] ? 'yes' : 'no';
						const b1 = b[column.key] ? 'yes' : 'no';
						return a1.localeCompare(b1);
					default:
						return a[column.key].toLowerCase().trim().localeCompare(b[column.key].toLowerCase().trim());
				}
			} else if (a[column.key]) {
				return 1;
			}
			return -1;
		});
		reorganizedData = sortDesc ? reorganizedData.reverse() : reorganizedData;
		return { organizedEntries: reorganizedData, normalizedColumns: updatedColumns };
	};

	checkEntries = (entryIds) => {
		let checked = this.state.checkedEntries;
		entryIds.length > 0
			? entryIds.forEach((entryId) => {
				checked[entryId] = true;
			})
			: (checked = {});
		this.setState({
			checkedEntries: checked
		});
	};

	updateSelected = (selectedIndex) => {
		this.setState({ selectedSection: selectedIndex });
	};

	toggleAddAttendee = () => {
		this.setState({
			addAttendeeModalOpen: !this.state.addAttendeeModalOpen
		});
	};
	toggleAddSession = () => {
		this.setState({
			addSessionModalOpen: !this.state.addSessionModalOpen
		});
	};

	addAttendees = (attendeeIds, cb) => {
		const editedGroup = this.state.editedGroup;
		editedGroup.attendees = [...editedGroup.attendees, ...attendeeIds];
		this.setState({ editedGroup: editedGroup, editing: true }, cb);
	};

	addSessions = (sessionIds, cb) => {
		const editedGroup = this.state.editedGroup;
		editedGroup.sessions = [...editedGroup.sessions, ...sessionIds];
		this.setState({ editedGroup: editedGroup, editing: true }, cb);
	};
	toggleDeleteAttendees = () => {
		this.setState({
			deleteAttendeesModalOpen: !this.state.deleteAttendeesModalOpen
		});
	};
	toggleDeleteSessions = () => {
		this.setState({
			deleteSessionModalOpen: !this.state.deleteSessionModalOpen
		});
	};
	deleteAttendees = (attendeeIds, cb) => {
		const editedGroup = this.state.editedGroup;
		editedGroup.attendees = editedGroup.attendees.filter((attendeeId) => {
			return !attendeeIds.includes(attendeeId);
		});
		this.setState({ editedGroup: editedGroup }, cb);
	};
	deleteSessions = (sessionIds, cb) => {
		const editedGroup = this.state.editedGroup;
		editedGroup.sessions = editedGroup.sessions.filter((sessionId) => {
			return !sessionIds.includes(sessionId);
		});
		this.setState({ editedGroup: editedGroup }, cb);
	};

	render() {
		const { editing, group, selectedSection, editedGroup, deleteGroupsModalOpen } = this.state;
		const { program } = this.props;
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={`${group.name}`}
					enableLastLink={true}
					/* breadcrumbs={[
						{
							name: program.name
						},
						{
							name: 'Event Group'
						}
					]} */
					closeButton={true}
					subActions={[{ onClick: this.toggleDelete, label:  <span><i className='las la-trash mr-10'></i> Delete Group </span> }]}
				>
					<TabsContainer
						tabs={[{ title: 'Details' }, { title: 'Attendees' }, { title: 'Sessions' }]}
						updateSelected={this.updateSelected}
						selectedIndex={selectedSection}
					/>
				</SectionHeader>
				<div className="sectionContainer" >
					{this.renderSection()}
				</div>
				<SectionFooter
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveInfo}
					updatedAt={group.updatedAt} />
				{this.state.addAttendeeModalOpen && (
					<AddToGroupModal
						isOpen={true}
						entries={this.props.program.eventDefaults.attendees}
						handleSubmit={this.addAttendees}
						toggle={this.toggleAddAttendee}
						programId={this.props.programId}
						title={'Add Attendees'}
						blankTitle={'Attendees'}
						columns={{ email: 'Email', firstName: 'First Name', lastName: 'Last Name' }}
						columnTypes={{ email: 'string', firstName: 'string', lastName: 'string' }}
						orgId={this.props.orgId}
						inGroup={editedGroup.attendees}
					/>
				)}
				{this.state.addSessionModalOpen && (
					<AddToGroupModal
						isOpen={true}
						entries={this.props.program.eventDefaults.sessions}
						handleSubmit={this.addSessions}
						toggle={this.toggleAddSession}
						programId={this.props.programId}
						title={'Add Sessions'}
						blankTitle={'Sessions'}
						columns={{ name: 'Name', startTime: 'Start Time', endTime: 'End Time' }}
						columnTypes={{ name: 'string', startTime: 'string', endTime: 'string' }}
						orgId={this.props.orgId}
						inGroup={editedGroup.sessions}
					/>
				)}
				{this.state.deleteGroupsModalOpen && (
					<DeleteGroupModal
						isOpen={deleteGroupsModalOpen}
						toggle={() => this.toggleDelete()}
						title={`Delete Group`}
						message={`You are about to delete ${group.name} this is irreversible,
						would you like to continue?`}
						submit={() => {
							const groups = {};
							groups[group._id] = true;
							this.props.deleteGroup(
								groups,
								this.props.history.push('./'),

							);
						}}
					/>
				)}

			</div>
		);
	}
}

export default withRouter(EventGroup);
