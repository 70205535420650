import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class RemovePointModal extends React.Component {
	state = {
		submitting: false
	};

	removePoint = async () => {
		const { eventId, orgId, checkedPoints, points } = this.props;
		const checkedPointItems = points.filter((p) => checkedPoints.find((cp) => cp == p._id));
		this.setState({ submitting: true })
		for (let i = 0; i < checkedPointItems.length; i++) {
			const markedPoint = checkedPointItems[i];
			await API()
				.delete(`Organizations/${orgId}/events/${eventId}/interactions/point/${markedPoint._id}`)
				.then((res) => {
					if (res.data) {
						this.props.removePoint(markedPoint._id);
					}
				})
				.catch((e) => {
					console.log(e);
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		}
		this.props.toggle();
	};

	render() {
		const { isOpen, toggle, checkedPoints } = this.props;
		const { submitting } = this.state;
		return (

			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Interaction Point"}
					bodyHeaderText={<span>	You are about to delete{' '}
						<b>{checkedPoints.length}</b> point{checkedPoints.length > 1 ? 's' : ''} from
						this event, this will result in loss of points for attendees, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
					actionButton={() => this.removePoint()}
					actionButtonDisabled={submitting}
					isDelete={true}
				></FieldModal>
			</div>

		);
	}
}

export default RemovePointModal;
