import moment from "moment";
import React, { Fragment } from "react";
import API from "../../../../utils/API";
import LoadingWizard from "../../../../components/spinner/wizard";
import ViewsTable from "../../../../components/tables/ViewsTable";
import InputField from "../../../../components/inputs/inputField";
import SelectField from "../../../../components/inputs/selectField";

import FieldModal from "../../../../components/modals/fieldModal";

const changeTypeOptions = [
    { label: 'All', value: '' },
    { label: 'Created', value: 'Created' },
    { label: 'Modified', value: 'Modified' },
    { label: 'Deleted', value: 'Deleted' }
]
class AttendeeInsights extends React.Component {

    constructor(props) {
        super(props);
        const columns = {
            createdAt: 'On Date & Time',
            name: 'Attendee',
            editor: 'Made By',

            action: 'Type',
            changes: 'Description'
        };
        const columnTypes = {
            action: 'text',
            name: 'text',
            editor: 'text',
            createdAt: 'text',
            changes: 'rich'
        };

        const columnWidths = {
            createdAt: 175,
            editor: 180,
            name: 180,
            changes: 800,
            action: 100
        };

        const toggledColumns = {};
        const normalizedColumns = Object.keys(columns).map((cfc) => {
            toggledColumns[cfc] = true;
            return {
                label: columns[cfc],
                key: cfc,
                value: cfc,
                type: columnTypes[cfc],
                sortAsc: false,
                sortDesc: false
            };
        });
        this.state = {
            editHistory: [],
            filteredHistory: [],
            startRange: '',
            endRange: '',
            checked: [],
            loading: true,
            columns: columns,
            columnTypes: columnTypes,
            toggledColumns: toggledColumns,
            normalizedColumns: normalizedColumns,
            columnWidths: columnWidths,
            changeTypeFilter: ''
        };
    }

    componentDidMount() {
        this.fetchAttendeeHistory();
    }

    fetchAttendeeHistory = async () => {
        await API().get(`Organizations/${this.props.orgId}/events/${this.props.eventId}/reports/attendeeEditHistory`).then((res) => {
            if (res.data) {
                const { customFields } = this.props;
                const editHistory = res.data.map((eh) => {
                    let rowHeight = 1;
                    const changes = eh.changes ? <div>{Object.keys(eh.changes).length > 1 && <span>({Object.keys(eh.changes).length}) Changes. {/* <a className="ml-5" href="#">Revert All</a> */}<br /></span>}{Object.keys(eh.changes).map((ck) => {
                        rowHeight += 1;
                        const c = eh.changes[ck];
                        let id = ck;
                        const oldValue = c.oldValue ? c.oldValue.toString() : "";
                        if (oldValue == "") {
                            return <span><strong>{customFields[id] || 'Deleted Field'}</strong> was set to <i>"{c.newValue ? c.newValue.toString() : ""}"</i><br /></span>
                        }
                        return <span><strong>{customFields[id] || 'Deleted Field'}</strong> changed from <i>"{c.oldValue ? c.oldValue.toString() : ""}"</i> to <i>"{c.newValue ? c.newValue.toString() : ""}"</i> {/* <a className="ml-5" href="#" onClick={() => {

                            }}>Revert</a> */}<br /></span>
                    })}</div> : ''
                    return {
                        ...eh,
                        action: eh.action == 'patch' ? 'Modified' : eh.action == 'delete' ? 'Deleted' : 'Created',
                        createdAt: moment(eh.createdAt).format('MM/DD/YYYY hh:mma'),
                        changes: changes,
                        rawChanges: eh.changes,
                        rowHeight: rowHeight
                    };
                });
                this.setState({ editHistory: editHistory, filteredHistory: editHistory, loading: false });
            }
        });
    }

    filterOnDateRange = () => {
        const { editHistory, startRange, endRange, changeTypeFilter } = this.state;
        let filteredHistory = editHistory;
        if (startRange != '' && endRange != '') {
            const startDate = moment(startRange).format('YYYY-MM-DD HH:mm');
            const endDate = moment(endRange).format('YYYY-MM-DD HH:mm');
            filteredHistory = editHistory.filter((eh) => {
                const createdAt = moment(eh.createdAt, "MM/DD/YYYY HH:mma").format('YYYY-MM-DD HH:mm');
                return moment(createdAt).isBetween(startDate, endDate);
            });
        } else if (startRange != '' && endRange == '') {
            const startDate = moment(startRange).format('YYYY-MM-DD HH:mm');
            filteredHistory = editHistory.filter((eh) => {
                const createdAt = moment(eh.createdAt, "MM/DD/YYYY HH:mma").format('YYYY-MM-DD HH:mm');
                return moment(createdAt).isAfter(startDate);
            });
        } else if (startRange == '' && endRange != '') {
            const endRange = moment(endRange).format('YYYY-MM-DD HH:mm');
            filteredHistory = editHistory.filter((eh) => {
                const createdAt = moment(eh.createdAt, "MM/DD/YYYY HH:mma").format('YYYY-MM-DD HH:mm');
                return moment(createdAt).isBefore(endRange);
            });
        }
        if (changeTypeFilter != '') {
            filteredHistory = filteredHistory.filter((eh) => {
                return eh.action == changeTypeFilter;
            });
        }
        this.setState({ filteredHistory: filteredHistory });
    }

    clearFilter = () => {
        this.setState({ filteredHistory: this.state.editHistory, startRange: '', endRange: '', changeTypeFilter: '' });
    }

    downloadCSV = () => {
        const { customFields } = this.props;
        const { filteredHistory } = this.state;
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Change Type,Attendee,On Date & Time, Field, Old Value, New Value\r\n";
        filteredHistory.forEach((eh) => {
            // change type, made by, on date & time, joined changes
            eh.rawChanges ? Object.keys(eh.rawChanges).map(c => {
                const change = eh.rawChanges[c];
                csvContent += `${eh.action},${eh.name},${eh.createdAt},`;
                csvContent += `${customFields[c]},${change.oldValue},${change.newValue}\r\n`;
            }) : csvContent += `${eh.action},${eh.name},${eh.createdAt},,,\r\n`;
        });
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "attendeeEditHistory.csv");
        document.body.appendChild(link);
        link.click();
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { filteredHistory, normalizedColumns, loading, checked, toggledColumns, startRange, endRange, changeTypeFilter } = this.state;
        return (
            <FieldModal
                size="large"

                isFullWidthBody={true}
                className="attendeeInsightsModal"
                modalTitle="Attendee Change Log"
                bodyHeaderText="Please enter the information below to change attendee details"
                isOpen={isOpen}
                toggle={toggle}
                actionButtonLabel={"Download CSV"}
                cancelButtonDisabled={true}
                actionButton={this.downloadCSV}
                bodyContent={() => {
                    return <Fragment>
                        <div style={{ height: 'calc(100vh - 400px)' }}>
                            <div className="flex aic">
                                <div className="flex aic w-fc">
                                    <SelectField label="Change Type" style={{ minWidth: 150 }} classes={" mr-10"}
                                        options={changeTypeOptions}
                                        value={changeTypeOptions.find(cto => cto.value == changeTypeFilter)}
                                        onChange={(e) => {
                                            this.setState({ changeTypeFilter: e.value });
                                        }}
                                    ></SelectField>
                                    <InputField
                                        inputIcon={<i className="lar la-calendar" />}
                                        type="datetime-local" classes="mr-10" label="Date Range Start" value={startRange} onChange={(e) => {
                                            this.setState({ startRange: e.target.value });
                                        }} />
                                    <InputField
                                        inputIcon={<i className="lar la-calendar" />}

                                        type="datetime-local"
                                        label="Date Range End"
                                        value={endRange}
                                        onChange={(e) => {
                                            this.setState({ endRange: e.target.value });
                                        }}
                                    />
                                </div>
                                <button className="seButton neu outline ml-10" onClick={this.filterOnDateRange}>Apply</button>
                                {(startRange != '' || endRange != '') && <button className="seButton ml-20" onClick={this.clearFilter}>Clear</button>}


                            </div>
                            <ViewsTable
                                data={filteredHistory}
                                columns={normalizedColumns}
                                classes="h-100"
                                disableCheck={true}
                                checked={checked}
                                toggledColumns={toggledColumns}
                                sortData={() => { }}
                                columnWidths={this.state.columnWidths}
                                title={"Attendee Change Logs"}
                                mainColumnActive={false} />
                            <LoadingWizard loading={loading} text="LOADING CHANGES" />
                        </div>
                    </Fragment>
                }}



            />


        );
    }
}

export default AttendeeInsights;