import React from 'react';
import { withRouter } from 'react-router-dom';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteEmailModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

			isDeleting: false
		}
	}


	render() {
		const { isOpen, toggle, form, count, isMultiple } = this.props;
		const { isDeleting } = this.state;
		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Delete Form"}
					bodyHeaderText={isMultiple ? <span>You are about to delete <b>{count} forms</b> from the event, this is irreversible, would you
						like to continue?</span> : <span>You are about to delete <b>{form.name}</b> from the event, this is irreversible, would you
							like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!isDeleting ? 'Delete' : 'Deleting...'}
					actionButton={() => {
						this.setState({ isDeleting: true });
						if (isMultiple) {
							this.props.delete();
						} else {
							this.props.removeForm(form._id, () => {
								toggle();
								this.props.history.push('./');
							});
						}
					}}

					actionButtonDisabled={isDeleting}
					isDelete={true}
				></FieldModal>

			</div>
		);
	}
}

export default withRouter(DeleteEmailModal);
