import React from 'react';

import API from '../../../../utils/API';
import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class AddFromModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subject: this.props.email.subject ? this.props.email.subject : '',
			previewText: this.props.email.previewText ? this.props.email.previewText : '',

			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {}
		};
	}

	handleSubjectChange = (e) => {
		this.setState({
			subject: e.target.value
		});
	};
	handlePreviewTextChange = (e) => {
		this.setState({
			previewText: e.target.value
		});
	};

	updateSubject = async () => {
		this.setState({ submitting: true });
		const { subject, previewText } = this.state;
		const { eventId, orgId, email, update, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (valid) {
			await API()
				.patch(`Organizations/${orgId}/events/${eventId}/email/${email._id}`, {
					name: email.name,
					subject: subject,
					previewText: previewText
				})
				.then((res) => {
					if (res.data) {
						update(
							{
								name: email.name,
								subject: subject,
								previewText: previewText
							},
							() => {
								toggle();
							}
						);
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, subject, previewText } = this.state;
		return (
			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add From"}
				bodyHeaderText={'Who is sending this campaign?'}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField label="Subject" required={false} value={subject} onChange={this.handleSubjectChange} />
							<InputField
								label="Preview"
								required={false}
								value={previewText}
								onChange={this.handlePreviewTextChange}
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Save' : 'Saving...'}
				actionButton={() => this.updateSubject()}
				actionButtonDisabled={submitting}


			></FieldModal>

		);
	}
}

export default AddFromModal;
