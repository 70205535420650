import React from 'react';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class SessionRemoveSpeakerModal extends React.Component {
	state = {
		submitting: false
	};

	removeSpeaker = async () => {
		const { eventId, orgId, session, checkedSpeakers } = this.props;

		this.setState({ submitting: true })
		await API()
			.patch(`Organizations/${orgId}/events/${eventId}/sessions/${session._id}`, {
				speakers: Object.keys(checkedSpeakers),
				request: 'removeSpeaker'
			})
			.then((res) => {
				if (res.data) {
					const speakers = res.data.speakers.map((a) => this.props.attendees.find((at) => at._id == a));
					res.data.speakers = speakers;
					this.props.updateSpeakers(speakers);
					this.props.uncheckEntries();
					this.props.toggle();
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	render() {
		const { isOpen, checkedSpeakers, toggle } = this.props;
		const { submitting } = this.state;

		let selectedSpeakerCount = Object.keys(checkedSpeakers).length;
		return (
			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={`${`Delete Speaker${selectedSpeakerCount != 1 ? 's' : ''}`}`}
					bodyHeaderText={<span>You are about to delete{' '}
						<b>
							{selectedSpeakerCount} speaker{selectedSpeakerCount != 1 ? 's' : ''}{' '}
						</b>
						from this session, would you like to continue?</span>}
					bodyContent={() => {
						return ('')
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.removeSpeaker()}

					isDelete={true}
					actionButtonDisabled={submitting}
				></FieldModal>

			</div>

		);
	}
}

export default SessionRemoveSpeakerModal;
