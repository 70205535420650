import React from 'react';
import { withRouter } from 'react-router-dom';

import API from '../../../../utils/API';
import { Modal } from 'reactstrap';
import * as IFrame from 'react-iframe-form';

import InputField from '../../../../components/inputs/inputField';
const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;
class TemplateCenterModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: null,
            submitting: false,
            invalid: false,
            error: '',
            attendees: [],
            sessions: [],
            groups: [],
            selectedSection: 0,
            checkedAttendees: {},
            checkedGroups: {},
            checkedSessions: {},
            selectedColumnSort: null,
            sortDesc: false,
            selectedRecipientsCount: 0,
            templateSelected: null,
            isPlatformTemplate: false,
            selectedSection: 0,
            countingKey: 0,
            isConfirmation: false,
            campaignName: '',
            adminEmail: this.props.defaultSenderEmail,
            adminEmailName: this.props.defaultSenderName,
            platformTemplates: [],
            requiredFields: {
                name: false
            }
        };
    }
    componentDidMount() {
        const { platformTemplates } = this.props;
        let platformTemplatesMapped = platformTemplates.map(template => {

            return {
                ...template,
                isPlatformTemplate: true
            }
        }
        )
        this.setState({ platformTemplates: platformTemplatesMapped, countingKey: this.state.countingKey + 1 });
    }

    selectSection = (section) => {
        const { countingKey, campaignName } = this.state;


        this.setState({ selectedSection: section, isConfirmation: false, templateSelected: null, countingKey: countingKey + 1, campaignName: '' });
    };

    selectTemplate = (templateId, isPlatformTemplate) => {

        this.setState({ templateSelected: templateId, isPlatformTemplate })

    }

    setConfirmation = (isConfirmation) => {
        this.setState({ isConfirmation: isConfirmation, countingKey: this.state.countingKey + 1 })
    }


    onCancel = () => {
        const { isConfirmation } = this.state;


        if (isConfirmation) {
            this.setConfirmation(false);

        } else {
            this.props.toggle();
        }
    }


    setCampaignName = (campaignName) => {
        this.setState({ campaignName: campaignName })
    }

    addCampaign = async (callback) => {
        this.setState({ submitting: true });
        const { name, templateSelected, selectedSection, campaignName, isPlatformTemplate } = this.state;
        const { eventId, orgId, addCampaign, keyedMetadata } = this.props;
        const requiredFields = this.state.requiredFields;

        let valid = campaignName && campaignName !== '' ? true : false;
        let payload = {};

        switch (selectedSection) {
            case 0:

                payload.name = campaignName;
                payload.codeOnly = false;
                payload.templateId = templateSelected;
                payload.isPlatformTemplate = isPlatformTemplate
                break;
            case 1:
                payload.name = campaignName;
                payload.codeOnly = false;
                payload.templateId = templateSelected;
                payload.isPlatformTemplate = false;
                break;
            case 2:
                payload.name = campaignName;
                payload.codeOnly = false;
                break;
            case 3:
                payload.name = campaignName;
                payload.codeOnly = true;
                break;
        }


        if (valid) {
            await API().post(`Organizations/${orgId}/events/${eventId}/email`, payload).then((res) => {
                const emailObj = res.data;

                if (res.data) {
                    const newEmail = {
                        ...emailObj,
                        name: emailObj.name,
                        subject: emailObj.subject,
                        sentEmailCount: 0,
                        openedEmailCount: 0,
                        _id: emailObj._id,
                        status: 'Draft',
                        link: `/${orgId}/events/${eventId}/emails/${emailObj._id}`,
                        createdBy: emailObj.createdBy,
                        createdAt: emailObj.createdAt,
                        updatedAt: emailObj.updatedAt
                    };

                    addCampaign(newEmail, () => {
                        //window.open(`/${orgId}/events/${eventId}/emails/${emailObj._id}`, '_self');]
                        this.props.toggle();
                        this.props.history.push(`/${orgId}/events/${eventId}/emails/${emailObj._id}`)
                    });
                } else {
                }
            });
        } else {
            this.setState({ submitting: false });
        }
    };

    handleActionButtonLogic = () => {
        const { isConfirmation, templateSelected, selectedSection } = this.state;
        switch (selectedSection) {
            case 0:
                if (templateSelected && !isConfirmation) {
                    this.setConfirmation(true);
                } else if (templateSelected && isConfirmation) {
                    this.addCampaign();
                }
                break;
            case 1:
                if (templateSelected && !isConfirmation) {
                    this.setConfirmation(true);
                } else if (templateSelected && isConfirmation) {
                    this.addCampaign();
                }
                break;
            case 2:
                this.addCampaign();
                break;
            case 3:
                this.addCampaign();
                break;
        }
    }


    render() {
        const { isOpen, toggle, orgId } = this.props;
        const { selectedSection, templateSelected, countingKey, isConfirmation, requiredFields, isPlatformTemplate } = this.state;


        let localTemplates = this.props.templates ? this.props.templates : [];
        let localPlatformTemplates = this.state.platformTemplates ? this.state.platformTemplates : [];

        let data = [...localPlatformTemplates, ...localTemplates];
        if (selectedSection == 1) {
            data = data.filter(template => {

                if (localTemplates.find(localTemplate => localTemplate._id == template._id)) {
                    return true;
                }
            });

        }

        if (templateSelected && isConfirmation) {
            if (isPlatformTemplate) {
                data = [this.state.platformTemplates.find(template => template._id == templateSelected)];
            } else {
                data = [this.props.templates.find(template => template._id == templateSelected)];
            }
        }

        return (
            <div>
                <Modal isOpen={isOpen} style={{}} toggle={toggle} className={`large templateCenterModal`}>
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7, zIndex: 100 }}><i className='las la-times'></i></button>

                    <div className={`templateCenterContainer`} style={{}}>
                        <div className="sideNav">
                            <h1>Template Center</h1>
                            <div className="explorePanel">
                                <p>EXPLORE</p>
                                <button type="button" tabindex="0" className={`sideNav  ${selectedSection == 0 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(0)}>
                                    All Templates
                                </button>
                                <button type="button" tabindex="1" className={`sideNav  ${selectedSection == 1 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(1)}>
                                    My Saved Templates
                                </button>
                                <button type="button" tabindex="2" className={`sideNav  ${selectedSection == 2 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(2)}>
                                    Start From Scratch
                                </button>
                                <button type="button" tabindex="3" className={`sideNav  ${selectedSection == 3 ? 'selectedInverted' : ''}`} style={{ textTransform: 'capitalize', padding: '7px 10px' }} onClick={() => this.selectSection(3)}>
                                    Code Your Own
                                </button>
                            </div>
                        </div>
                        <div style={{ height: "calc(100vh - 80px)" }} className="templatesContainer">
                            {isConfirmation ? (


                                < div >
                                    {/*    <div className="headerContainer">
                                        <h1>{data[0] ? data[0].name : ''}</h1>
                                        <p>Templates are reusable emails that can be sent to attendees, groups or sessions.</p>
                                    </div>
                                    <hr /> */}

                                    <div style={{ alignContent: 'baseline', height: 'calc(100vh - 233px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>
                                        <div>
                                            {data.map((template, index) => {
                                                let url = `${ENV}Organizations/${orgId}/emailrenderer?preview=true`;
                                                let method = 'post';
                                                let data = { html: template.html };
                                                return (
                                                    <div>
                                                        {/* <div className="templateCard mt-10">
                                                            <div className="templateCardHeader">
                                                                <div className="templateCardHeaderLeft" style={{ fontWeight: '600', fontSize: 14 }}>
                                                                    <p style={{ fontWeight: '600', fontSize: 14 }}>{template.name}</p>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div style={{ opacity: '1', width: 455, height: 630, background: '#FFF', margin: '10px 50px 40px 0px' }}
                                                            onClick={() => {

                                                                //this.selectTemplate(this.state.templateSelected == template._id ? null : template._id)
                                                            }}>
                                                            <div className="noselect emailPreviewWindow Frame templateCenter isConfirmation" style={{ width: '100%' }}>
                                                                <IFrame.Form
                                                                    id={`builderPreviewFrameTemplate-${template._id}`}
                                                                    className="noselect emailPreviewWindow Frame"
                                                                    style={{
                                                                        ponterEvent: 'none',
                                                                        transform: 'scale(0.99)'
                                                                    }}
                                                                    key={countingKey}
                                                                    scrolling="no"
                                                                    name={`PreviewFrameTemp-${template._id}`}
                                                                    config={{ url, method, data }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })}
                                        </div>
                                        <div className='mt-10' style={{ width: '50%' }}>
                                            <InputField
                                                label={'Campaign Name'}
                                                value={this.state.campaignName}
                                                errorTrigger={this.state.campaignName == ''}
                                                inputStyle={{ fontSize: 28, lineHeight: '34px', fontWeight: 600, margin: 0, padding: 0 }}
                                                onChange={(e) => {
                                                    this.setCampaignName(e.target.value);
                                                }}
                                            />




                                            <div className='sectionBlock'>
                                                <h2 className='blockHeader mb-20'>Details</h2>


                                                <div className="mb-20">
                                                    <p className="small gray"> From Name</p>
                                                    <p >
                                                        {data[0].senderName ? data[0].senderName :
                                                            this.props.event.eventEmailName ? this.props.event.eventEmailName :
                                                                this.state.adminEmailName ? this.state.adminEmailName : 'Simple Events Support'}
                                                    </p>
                                                </div>
                                                <div className="mb-20">
                                                    <p className="small gray"> From Email</p>
                                                    <p>
                                                        {data[0].senderEmail ? data[0].senderEmail :
                                                            this.props.event.eventEmail ? this.props.event.eventEmail :
                                                                this.state.adminEmail ? this.state.adminEmail : 'support@simple.events'}
                                                    </p>
                                                </div>
                                                <div className="mb-20">
                                                    <p className="small gray">Subject Line</p>
                                                    <p>{data[0].subject ? data[0].subject : '-'}</p>
                                                </div>
                                                <div className="mb-20">
                                                    <p className="small gray">Preview Text</p>
                                                    <p>{data[0].previewText ? data[0].previewText : '-'}</p>
                                                </div>
                                                <div className="mb-20">
                                                    <p className="small gray">BCC</p>
                                                    <p>{data[0].bcc ? `${data[0].bcc.length} emails` : '-'}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : selectedSection == 0 || selectedSection == 1 ? (
                                <div>
                                    {/*    <div className="headerContainer">
                                        <h1>{selectedSection == 1 ? 'My Saved Templates' : 'All Templates'}</h1>
                                        <p>Templates are reusable emails that can be sent to attendees, groups or sessions.</p>
                                    </div>
                                    <hr /> */}

                                    <div style={{ alignContent: 'baseline', height: 'calc(100vh - 233px)', overflow: 'auto', display: 'flex', flexFlow: 'wrap' }}>
                                        {data.map((template, index) => {
                                            let url = `${ENV}Organizations/${orgId}/emailrenderer?preview=true`;
                                            let method = 'post';
                                            let data = { html: template.html };
                                            let isPlatformTemplate = template.isPlatformTemplate || false;
                                            return (
                                                <div>
                                                    <div className="templateCard mt-10">
                                                        <div className="templateCardHeader">
                                                            <div className="templateCardHeaderLeft" style={{ fontWeight: '600', fontSize: 14 }}>
                                                                <p style={{ fontWeight: '600', fontSize: 14 }}>{template.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`previewCardContainer ${template._id == this.state.templateSelected ? 'selected' : ''}`}
                                                        onClick={() => {

                                                            this.setCampaignName(this.state.templateSelected == template._id ? '' : template.name);
                                                            this.selectTemplate(this.state.templateSelected == template._id ? null : template._id, isPlatformTemplate)
                                                        }}>
                                                        <div style={{ opacity: template._id == this.state.templateSelected ? "0.4" : '1' }}>

                                                            <div className="noselect emailPreviewWindow Frame templateCenter" style={{ width: '100%' }}>
                                                                <IFrame.Form
                                                                    id={`builderPreviewFrameTemplate-${template._id}`}
                                                                    className="noselect emailPreviewWindow Frame"
                                                                    style={{
                                                                        ponterEvent: 'none',
                                                                        transform: 'scale(0.99)'
                                                                    }}
                                                                    key={countingKey}
                                                                    scrolling="no"
                                                                    name={`PreviewFrameTemp-${template._id}`}
                                                                    config={{ url, method, data }}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                </div>
                            ) : selectedSection == 2 ? (
                                <div className='formSection'>
                                    <div className='formSectionContent'>
                                        <div className=" formSectionCard m-a flex jcc aic">
                                            <div className="w-100 h-100 flex jcc aic" style={{ flexDirection: 'column' }}>
                                                <h1>Start From Scratch</h1>
                                                <p className="mb-20">Please type in your Campaign name below.</p>
                                                <InputField
                                                    label={'Campaign Name'}
                                                    value={this.state.campaignName}
                                                    errorTrigger={requiredFields.name}
                                                    required={true}
                                                    onChange={(e) => {
                                                        this.setCampaignName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            ) : selectedSection == 3 ? (
                                <div className='formSection'>
                                    <div className='formSectionContent'>
                                        <div className=" formSectionCard m-a flex jcc aic">
                                            <div className="w-100 h-100 flex jcc aic" style={{ flexDirection: 'column' }}>
                                                <h1>Code Your Own</h1>
                                                <p className="mb-20">Please type in your Campaign name below.</p>
                                                <InputField
                                                    label={'Campaign Name'}
                                                    value={this.state.campaignName}
                                                    required={true}
                                                    errorTrigger={requiredFields.name}
                                                    onChange={(e) => {
                                                        this.setCampaignName(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ) : ''}

                            <div className='modalFooter mt-20'>
                                <div className='flex'>
                                    <button className="neu ml-a" onClick={this.onCancel}>{isConfirmation ? 'Back' : 'Cancel'}</button>
                                    <button className={`ml-25 ${!templateSelected && !isConfirmation && selectedSection !== 2 && selectedSection !== 3 ? "disabled" : (this.state.campaignName == '') ? 'disabled' : 'prim'}`} onClick={() => {

                                        this.handleActionButtonLogic();



                                    }}>{(selectedSection == 0 || selectedSection == 1) && templateSelected && !isConfirmation ? "Next" : "Create Email"}</button>
                                </div>

                            </div>
                        </div>

                    </div>

                </Modal >

            </div >

        );
    }
}

export default withRouter(TemplateCenterModal);
