import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import DateTimePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import moment from 'moment';
import Select from 'react-select';

import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import ToggleField from '../../../components/inputs/toggleField';
import TextAreaField from '../../../components/inputs/textAreaField';
import TimeField from '../../../components/inputs/timeField';
import VenuesLocationField from '../../../components/venues/venuesLocationField';

const virtualMeetingTypes = [
	{ label: 'Stream', value: 'stream' },
	{ label: 'Meeting', value: 'meeting' },
	{ label: 'In-Person', value: 'in-person' },
	{ label: 'Link', value: 'link' },
	{ label: 'Break', value: 'break' }
];

class EventSessionInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			genExpanded: true,
			displayExpanded: true,
			featuresExpanded: true,
			settingsExpanded: true,
			sessionLocationId: this.props.editedSession.location ? this.props.editedSession.location : ''
		};
	}

	toggleEditing = () => {
		this.setState({ editing: !this.state.editing });
	};

	listItem = (meta, categoryId) => {
		const { readOnly } = this.props;
		return (
			<InputField
				label={meta.fieldName}
				checked={meta.fieldType === 'checkbox' && meta.value}
				value={meta.value}
				disabled={readOnly}
				type={meta.fieldType}
				onChange={(e) => this.props.updateCustomField(e, meta, categoryId)}
			/>
		);
	};

	displayMetadataFields = () => {
		const { customFields } = this.props;
		return Object.keys(customFields).map((categoryId) => {
			const categoryFields = customFields[categoryId];
			const categoryTitle = categoryFields.title;
			const categoryExpanded = categoryFields.expanded;

			let fieldCount = 0;
			const categoryRow = (
				<div style={{ marginBottom: 10, padding: '12.5px 0' }}>
					<div style={{ display: 'flex' }}>
						<div className="calibreBold fs-20">{categoryTitle}</div>
						{/* 	<span
							style={{ margin: 'auto', marginRight: 10 }}
							onClick={() => this.props.expandCustomField(categoryId, categoryExpanded)}
						>
							{categoryExpanded ? '-' : '+'}
						</span> */}
					</div>

					<div style={{ display: categoryExpanded ? 'block' : 'none' }}>
						{Object.keys(categoryFields).map((fieldKey) => {
							if (fieldKey !== 'title' && fieldKey !== 'expanded') {
								const fieldData = customFields[categoryId][fieldKey];
								fieldCount++;
								return this.listItem(fieldData, categoryId);
							}
						})}
					</div>
				</div>
			);
			return fieldCount > 0 && categoryRow;
		});
	};
	render() {
		const { genExpanded, displayExpanded, featuresExpanded, settingsExpanded, sessionLocationId } = this.state;
		const {
			editedSession,
			updateSessionField,
			locationOptions,
			readOnly,
			requiredFields,
			handleDateChange,
			handleTimeChange
		} = this.props;
		const selectedPage = editedSession.pageOptions.find(
			(po) => po._id.toString() == editedSession.pageId.toString()
		) || { title: '', _id: null };

		const date = moment(editedSession.date).format('YYYY-MM-DD');
		return (
			<div className="sectionBlock mt-20">
				<InputField
					label={'Session Name'}
					value={editedSession.name}
					disabled={readOnly}
					required={true}
					errorTrigger={requiredFields.name}
					type="text"
					onChange={(e) => updateSessionField('name', e.target.value)}
				/>

				<SelectField
					label="Session Type"
					value={virtualMeetingTypes.find((vmt) => vmt.value == editedSession.virtualMeetingType)}
					name="Meeting type"
					options={virtualMeetingTypes}
					onChange={(vmt) => updateSessionField('virtualMeetingType', vmt.value)}
					disabled={readOnly}
				/>

				<InputField
					label={'Date'}
					inputIcon={<i className="lar la-calendar" />}
					type={'date'}
					required={true}
					errorTrigger={requiredFields.date}
					value={date}
					disabled={readOnly}
					onChange={(e) => {
						updateSessionField('date', moment(e.target.value).format('MM/DD/YYYY'));
					}}
				/>

				<ToggleField
					label={'All-Day'}
					checked={editedSession.isAllDay}
					icons={false}
					disabled={readOnly}
					onChange={(e) => updateSessionField('isAllDay', !editedSession.isAllDay)}
				/>
				{
					!editedSession.isAllDay && (
						<div className="flex-sb w-100">
							<TimeField
								label={'Start Time'}
								classes="mr-10 w-100"
								value={editedSession.startTime}
								disabled={readOnly}
								required={true}
								errorTrigger={requiredFields.startTime}
								onChange={(time) => {
									updateSessionField('startTime', time);
								}}
							/>

							<TimeField
								label={'End Time'}
								onChange={(time) => {
									updateSessionField('endTime', time);
								}}
								required={true}
								disabled={readOnly}
								errorTrigger={requiredFields.endTime}
								value={editedSession.endTime}
								classes="ml-10 w-100"
							/>
						</div>
					)
				}
				<h3 className='blockHeader'>Onsite Details</h3>

				<VenuesLocationField
					selectedVenue={this.props.venues.find(v => v._id == this.props.event.venue) || ''}
					orgId={this.props.orgId}
					venueOptions={this.props.venues}
					sessionLocationId={editedSession.location}
					isLocation={true}
					selectVenue={(venue) => {
						this.setState({
							venue: {
								value: venue._id,
								label: venue.name,
								color: '#000'
							},
							editing: true
						})
					}}
					venuePicked={this.state.venue}
					selectLocation={(location) => {
						updateSessionField('location', location._id);
					}}
					addNewLocation={this.props.addNewLocation}
					renameLocation={this.props.renameLocation}
					deleteLocation={this.props.deleteLocation}
				></VenuesLocationField>
				<div className="flex-sb w-100">
					<InputField
						label={'Set (people)'}
						classes="mr-10 w-100"
						value={editedSession.setPeople}
						disabled={readOnly}
						type="number"
						onChange={(e) => {
							updateSessionField('setPeople', e.target.value);
						}}
					/>

					<InputField
						label={'Guarantee (people)'}
						classes="ml-10 w-100"
						value={editedSession.guaranteedPeople}
						disabled={readOnly}
						type="number"
						onChange={(e) => {
							updateSessionField('guaranteedPeople', e.target.value);
						}}
					/>
				</div>

				<div className="flex-sb w-100">
					<TimeField
						label="Set Time"
						value={editedSession.setTime}
						disabled={readOnly}
						classes="mr-10 w-100"
						onChange={(time) => {
							updateSessionField('setTime', time);
						}}
					/>
					<TimeField
						label="Strike Time"
						value={editedSession.strikeTime}
						disabled={readOnly}
						classes="ml-10 w-100"
						onChange={(time) => {
							updateSessionField('strikeTime', time);
						}}
					/>
				</div>

				<TextAreaField
					label={"Room Set"}
					value={editedSession.roomSet}
					disabled={readOnly}
					onChange={(e) => {
						updateSessionField('roomSet', e.target.value);
					}}
				/>

				<TextAreaField
					label={"Food and Beverage"}
					value={editedSession.foodAndBeverage}
					disabled={readOnly}
					onChange={(e) => {
						updateSessionField('foodAndBeverage', e.target.value);
					}}
				/>

				<TextAreaField
					label="Hotel Items Needed"
					value={editedSession.hotelItemsNeeded}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('hotelItemsNeeded', e.target.value)}
				/>
				<TextAreaField
					label="AV Order"
					value={editedSession.avOrder}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('avOrder', e.target.value)}
				/>


				<TextAreaField
					label="Notes"
					value={editedSession.notes}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('notes', e.target.value)}
				/>

				<ToggleField
					label={"Posted on Hotel Agenda"}
					checked={editedSession.posted}
					icons={false}
					disabled={readOnly}
					onChange={(e) => updateSessionField('posted', !editedSession.posted)}
				/>

				<h3 className='blockHeader'>Website</h3>

				<InputField
					label={'Subtitle'}
					value={editedSession.subtitle}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('subtitle', e.target.value)}
				/>

				<TextAreaField
					label={'Description'}
					value={editedSession.description}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('description', e.target.value)}
				/>

				<SelectField
					label="Page Template"
					value={{ label: selectedPage.title, value: selectedPage._id }}
					name="Page Template"
					options={editedSession.pageOptions.map((po) => {
						return { label: po.title, value: po._id };
					})}
					onChange={(po) => updateSessionField('pageId', po.value)}
					disabled={readOnly}
				/>


				<InputField
					label={'Recording Link'}
					value={editedSession.recordingLink}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('recordingLink', e.target.value)}
				/>

				<InputField
					label={'Session Link'}
					value={editedSession.virtualMeetingLink}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('virtualMeetingLink', e.target.value)}
				/>



				<label className="displayLabel">
					<div className="flex-ac">
						Back up Session Links
						<div
							onClick={() => {


								if (!editedSession.backupVirtualMeetingLinks || !editedSession.backupVirtualMeetingLinks.includes('') || editedSession.backupVirtualMeetingLinks.length == 0) {
									updateSessionField('backupVirtualMeetingLinks', editedSession.backupVirtualMeetingLinks ? [...editedSession.backupVirtualMeetingLinks, ''] : [''])
								} else {
									this.setState({ isValid: false });
								}
							}}
						>
							<i className="las la-plus-circle ml-10 c-pointer clickable" />
						</div>
					</div>
				</label>

				{editedSession.backupVirtualMeetingLinks && editedSession.backupVirtualMeetingLinks.map((link, index) => {
					return (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div
								onClick={() => {
									editedSession.backupVirtualMeetingLinks.splice(index, 1);
									updateSessionField('backupVirtualMeetingLinks', editedSession.backupVirtualMeetingLinks);
								}}
							>
								<i className="las la-minus-circle mr-10 c-pointer" style={{ color: 'red' }} />
							</div>
							<InputField
								label="Back up Session Link"
								value={editedSession.backupVirtualMeetingLinks[index]}
								onChange={(e) => {
									let backuplist = JSON.parse(JSON.stringify(editedSession.backupVirtualMeetingLinks));
									backuplist[index] = e.target.value;
									updateSessionField('backupVirtualMeetingLinks', backuplist);
								}}
							/>


						</div>
					);
				})
				}
				<ToggleField
					label="General session"
					checked={editedSession.isGeneral}
					icons={false}
					disabled={readOnly}
					onChange={(e) => updateSessionField('isGeneral', !editedSession.isGeneral)}
				/>
				<div className="flex-sb w-100">
					<InputField
						label={'Display Start Time'}
						value={editedSession.displayStartTime}
						disabled={readOnly}
						type="text"
						classes="mr-10 w-100"
						onChange={(e) => updateSessionField('displayStartTime', e.target.value)}
					/>

					<InputField
						label={'Display End Time'}
						value={editedSession.displayEndTime}
						disabled={readOnly}
						type="text"
						classes="ml-10 w-100"
						onChange={(e) => updateSessionField('displayEndTime', e.target.value)}
					/>
				</div>

				<div className="flex-sb w-100">
					<InputField
						label={'Start Offset'}
						value={editedSession.virtualStartOffset}
						disabled={readOnly}
						type="text"
						classes="mr-10 w-100"
						onChange={(e) => updateSessionField('virtualStartOffset', e.target.value)}
					/>

					<InputField
						label={'Stop Offset'}
						value={editedSession.virtualStopOffset}
						disabled={readOnly}
						type="text"
						classes="ml-10 w-100"
						onChange={(e) => updateSessionField('virtualStopOffset', e.target.value)}
					/>
				</div>

				<InputField
					label={'Display Timezone'}
					value={editedSession.displayTimezone}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateSessionField('displayTimezone', e.target.value)}
				/>


				<h4 className='blockHeader'>Features</h4>
				<ToggleField
					label="Enable Session Review"
					checked={editedSession.enableReview}
					icons={false}
					disabled={readOnly}
					onChange={(e) => updateSessionField('enableReview', !editedSession.enableReview)}
				/>
				<ToggleField
					label="Enable Survey"
					checked={editedSession.enableSurvey}
					icons={false}
					disabled={readOnly}
					onChange={(e) => updateSessionField('enableSurvey', !editedSession.enableSurvey)}
				/>
				{
					editedSession.virtualMeetingType == 'stream' && (
						<div>

							<ToggleField
								label="Enable Chat"
								checked={editedSession.chatEnabled}
								icons={false}
								disabled={readOnly}
								onChange={(e) => updateSessionField('chatEnabled', !editedSession.chatEnabled)}
							/>

							<ToggleField
								label={'Enable Stream Reactions'}
								checked={editedSession.reactionsEnabled}
								icons={false}
								disabled={readOnly}
								onChange={(e) =>
									updateSessionField('reactionsEnabled', !editedSession.reactionsEnabled)}
							/>

							<ToggleField
								label="Enable Polls"
								checked={editedSession.pollsEnabled}
								icons={false}
								disabled={readOnly}
								onChange={(e) => updateSessionField('pollsEnabled', !editedSession.pollsEnabled)}
							/>

							<ToggleField
								label="Enable Realtime 3D Effects"
								checked={editedSession.enable3dOverlays}
								icons={false}
								disabled={readOnly}
								onChange={(e) =>
									updateSessionField('enable3dOverlays', !editedSession.enable3dOverlays)}
							/>

							{editedSession.enable3dOverlays && (
								<ToggleField
									label="Fireworks"
									checked={editedSession.available3dOverlays.includes('Fireworks')}
									icons={false}
									disabled={readOnly}
									onChange={(e) => {
										const available3dOverlays = editedSession.available3dOverlays.includes(
											'Fireworks'
										)
											? editedSession.available3dOverlays.filter((o) => o != 'Fireworks')
											: [...editedSession.available3dOverlays, 'Fireworks'];
										updateSessionField('available3dOverlays', available3dOverlays);
									}}
								/>
							)}
							{editedSession.enable3dOverlays && (
								<ToggleField
									label={'Snowfall'}
									checked={editedSession.available3dOverlays.includes('Snow')}
									icons={false}
									disabled={readOnly}
									onChange={(e) => {
										const available3dOverlays = editedSession.available3dOverlays.includes(
											'Snow'
										)
											? editedSession.available3dOverlays.filter((o) => o != 'Snow')
											: [...editedSession.available3dOverlays, 'Snow'];
										updateSessionField('available3dOverlays', available3dOverlays);
									}}
								/>
							)}
							{editedSession.enable3dOverlays && (
								<ToggleField
									label={'Money Drop'}
									checked={editedSession.available3dOverlays.includes('Money')}
									icons={false}
									disabled={readOnly}
									onChange={(e) => {
										const available3dOverlays = editedSession.available3dOverlays.includes(
											'Money'
										)
											? editedSession.available3dOverlays.filter((o) => o != 'Money')
											: [...editedSession.available3dOverlays, 'Money'];
										updateSessionField('available3dOverlays', available3dOverlays);
									}}
								/>
							)}

						</div>
					)
				}
				{this.displayMetadataFields()}
			</div >
		);
	}
}

export default EventSessionInfo;
