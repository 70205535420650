import React from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';
import { withRouter } from 'react-router-dom';

class AddCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {
				name: false
			}
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	addCampaign = () => {
		this.setState({ submitting: true });
		const { name } = this.state;
		const { eventId, orgId, addCampaign, toggle } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!name || name === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			API().post(`Organizations/${orgId}/events/${eventId}/notification`, { name: name }).then((res) => {
				const notiObj = res.data;

				if (notiObj) {
					addCampaign({
						name: notiObj.name,
						status: notiObj.sent ? 'Sent' : notiObj.scheduled ? 'Scheduled' : 'Draft',
						_id: notiObj._id,
						link: `/${this.props.orgId}/events/${eventId}/notifications/${notiObj._id}`,
						createdAt: notiObj.createdAt,
						updatedAt: notiObj.updatedAt
					}, () => {
						toggle();
						this.props.history.push(`/${this.props.orgId}/events/${eventId}/notifications/${notiObj._id}`)
					});
				}
			});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, submitting, requiredFields } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Create New Notification"}
					bodyHeaderText={`Please enter a unique name for your new notification campaign.`}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Campaign Name"
									required={true}
									errorTrigger={requiredFields.name}
									value={name}
									onChange={this.handleNameChange}
								/>
							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Creating Campaign...'}
					cancelButtonLabel={'Cancel'}

					actionButton={() => this.addCampaign()}
					submitting={submitting}
					actionButtonDisabled={submitting}

				></FieldModal>
			</div>

		);
	}
}

export default withRouter(AddCampaignModal);
