import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import InputField from '../../../components/inputs/inputField';
import ColorField from '../../../components/inputs/colorField';

class ApplicationLogosAndColors extends Component {
	render() {
		const { orgBranding, editedEventBranding, updateBranding, readOnly } = this.props;
		return (
			<div className="sectionBlock">
				<div className="sectionSubtitle">Logos</div>
				<InputField
					label="Logo"
					value={!editedEventBranding.logo || editedEventBranding.logo === '' ? '' : editedEventBranding.logo}
					disabled={readOnly}
					placeholder={orgBranding.logo}
					type="text"
					onChange={(e) => updateBranding('logo', e.target.value)}
				/>
				<InputField
					label="Website Icon"
					value={
						!editedEventBranding.favicon || editedEventBranding.favicon === '' ? (
							''
						) : (
							editedEventBranding.favicon
						)
					}
					disabled={readOnly}
					placeholder={orgBranding.favicon}
					type="text"
					onChange={(e) => updateBranding('favicon', e.target.value)}
				/>
				<div className="sectionSubtitle">Colors</div>
				<ColorField
					label="Primary Color"
					value={
						!editedEventBranding.accentColor || editedEventBranding.accentColor === '' ? (
							''
						) : (
							editedEventBranding.accentColor
						)
					}
					disabled={readOnly}
					placeholder={orgBranding.accentColor}
					type="text"
					onChange={(e) => updateBranding('accentColor', e)}
				/>
				<ColorField
					label="Primary Text Color"
					value={
						!editedEventBranding.fontColor || editedEventBranding.fontColor === '' ? (
							''
						) : (
							editedEventBranding.fontColor
						)
					}
					placeholder={orgBranding.fontColor}
					disabled={readOnly}
					type="text"
					onChange={(e) => updateBranding('fontColor', e)}
				/>
			</div>
		);
	}
}

export default ApplicationLogosAndColors;
