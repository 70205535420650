import React from 'react';

import InputField from '../../../components/inputs/inputField';
import FieldModal from '../../../components/modals/fieldModal';

class NewCalendarModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			calendarName: '',
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	handleCalendarNameChange = (val) => {
		this.setState({
			calendarName: val.target.value
		});
	};

	addCalendar = async () => {
		const calendar = {
			name: this.state.calendarName
		};
		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!calendar[key] || calendar[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.setState({ invalid: false, submitting: true });
			await this.props.addCalendar(calendar, this.props.toggle);
		} else {
			this.setState({ invalid: true, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { calendarName, invalid, requiredFields, submitting } = this.state;
		return (

			<FieldModal
				size="medium"
				isOpen={isOpen}
				modalTitle={"Add New Calendar"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Calendar Name"
								value={calendarName}
								onChange={this.handleCalendarNameChange}
								required={true}
								errorTrigger={requiredFields.name}
								invalid={invalid}
							/>
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Add' : 'Adding...'}
				actionButton={() => this.addCalendar()}
				actionButtonDisabled={submitting}
			></FieldModal>

		);
	}
}

export default NewCalendarModal;
