import React, { Component } from 'react';

import API from '../../utils/API';
import ViewsContainer from '../../components/views/ViewsContainer';
import LoadingWizard from '../../components/spinner/wizard';

class Requests extends Component {
	constructor(props) {
		super(props);

		const fieldColumns = {
			name: 'Request Name',
			programName: 'Program Name',
			status: 'Status',
			submitterEmail: 'Submitter Email',
			submitterName: 'Submitter Name',
			submitDate: 'Submit Date',
			approvedDate: 'Approved Date',
			startDate: 'Start Date',
			endDate: 'End Date',
			startTime: 'Start Time',
			endTime: 'End Time'
		};

		const fieldCategories = {
			basics: {
				title: 'Basics',
				columns: [
					'name',
					'programName',
					'status',
					'submitterEmail',
					'submitterName',
					'submitDate',
					'approvedDate',
					'startDate',
					'endDate',
					'startTime',
					'endTime'
				]
			}
		};

		const fieldTypes = {
			name: 'text',
			programName: 'text',
			submitterEmail: 'text',
			submitterName: 'text',
			startDate: 'date',
			endDate: 'date',
			startTime: 'time',
			endTime: 'time',
			status: 'status',
			submitDate: 'timestamp',
			approvedDate: 'timestamp'
		};

		const toggleColumns = {};
		const normalizedColumns = Object.keys(fieldColumns).map((cfc) => {
			toggleColumns[cfc] = true;
			return {
				label: fieldColumns[cfc],
				key: cfc,
				value: cfc,
				type: fieldTypes[cfc] || typeof fieldColumns[cfc],
				sortAsc: false,
				sortDesc: false
			};
		});

		normalizedColumns[0].sortAsc = true;
		delete toggleColumns['name'];
		this.state = {
			events: [],
			organizedEvents: [],
			eventViews: [],
			loading: true,
			adding: false,
			categoryColumns: fieldCategories,
			columns: fieldColumns,
			columnTypes: fieldTypes
		};
	}

	async componentDidMount() {

		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		this.props.customFields.forEach((category) => {
			const fields = category.fields;
			fields.forEach((f) => {
				if (fieldCategories[category._id]) {
					fieldCategories[category._id].columns.push(`${f._id}`);
				} else {
					fieldCategories[category._id] = {
						title: category.title,
						columns: [`${f._id}`]
					};
				}
				fieldColumns[f._id] = f.fieldName;
				fieldTypes[f._id] = f.fieldType;
			});
		});
		this.setState({
			events: this.props.requests.map((request) => {
				Object.keys(request).forEach((key) => {
					if (key.includes('-')) {
						const idSplit = key.split('-');
						const id = idSplit[idSplit.length - 1];
						request[id] = request[key];
					}
				});
				return request;
			}),
			eventViews: this.props.views,
			loading: false,
			customFields: this.props.customFields,
			categoryColumns: fieldCategories,
			columns: fieldColumns,
			columnTypes: fieldTypes
		});
	}

	toggleAdd = () => {
		this.setState({ addModalOpen: !this.state.addModalOpen });
	};

	deleteEvent = async (eventId) => {
		await API().delete(`Organizations/${this.props.orgId}/events/${eventId}`).then((res) => {
			if (res.data) {
				const events = this.state.events.filter((e) => e._id !== eventId);
				this.setState({ events: events });
				return true;
			} else {
				return false;
			}
		});
	};

	render() {
		const { loading, eventViews, categoryColumns, columns, events, columnTypes } = this.state;

		return (
			<div className="sectionContainer">
				{!loading && (
					<ViewsContainer
						views={eventViews}
						entries={events}
						categoryColumns={categoryColumns}
						columns={columns}
						columnTypes={columnTypes}
						title="Program Requests"
						subtitle={null}
						readOnly={false}
						mainActions={[]}
						batchActions={[]}
						enableDownloadViews={true}
						orgId={this.props.orgId}
						type={'AllProgramRequests'}
						referenceCollection={'Organizations'}
						referenceId={this.props.orgId}
						description="View and manage all program requests. Approve requests to create a new event based on the program settings."
					/>
				)}
				<LoadingWizard text="Loading Program Requests" loading={loading} />
			</div>
		);
	}
}

export default Requests;
