import React from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import Select from 'react-select';

import SelectField from '../../../../components/inputs/selectField';
import FieldModal from '../../../../components/modals/fieldModal';

class GroupSessionsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			defaultGroupNames: [
				...props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			groupNames: [
				...props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			selectedValue: { label: '', color: '#00B8D9' },
			added: 0,
			submitting: false,
			invalid: false,
			success: false,
			requiredFields: {
				selectedValue: false
			}
		};
	}

	componentDidMount() {
		this.setState({});
	}

	reset = (e) => {
		this.setState({
			name: '',
			defaultGroupNames: [
				...this.props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			groupNames: [
				...this.props.groups.map((group) => {
					return { value: group._id, label: group.name, color: '#00B8D9' };
				})
			],
			selectedValue: { label: '', color: '#00B8D9' },
			submitting: false,
			invalid: false,
			success: false
		});
		this.props.toggle();
	};

	handleNameChange = (e) => {
		const newGroup = {
			label: 'New group: ' + e,
			color: '#00B8D9',
			new: true
		};
		this.setState({
			groupNames: [...this.state.defaultGroupNames, newGroup]
		});
	};

	handleGroupChosen = (e) => {
		if (e.new) {
			this.setState({ selectedValue: { ...e, label: e.label.split('New group: ')[1] } });
		} else {
			this.setState({ selectedValue: e });
		}
		/* if (e.value) {
			this.setState({
				groupNames: { 0: e.value }
			});
		} */
	};

	submit = () => {
		const { addGroup } = this.props;
		const { selectedValue } = this.state;

		let valid = true;

		const requiredFields = this.state.requiredFields;
		console.log('label ', selectedValue.label);

		if (selectedValue.label == '') {
			requiredFields.selectedValue = true;
			valid = false;
		} else {
			requiredFields.selectedValue = false;
		}

		console.log(selectedValue);
		console.log(requiredFields);

		if (valid) {
			this.setState({ submitting: true });

			addGroup(selectedValue, (added) => {
				this.setState({ success: true, added: added });
			});
		} else {
			this.setState({ invalid: true, submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, count, addGroup } = this.props;
		const { groupNames, submitting, invalid, success, selectedValue, added, requiredFields } = this.state;
		return (
			<div>
				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add Sessions to Group"}
					bodyHeaderText={!success ? `You are adding ${count} ${count > 1 ? 'sessions' : 'session'} to this group` : `${added > 1 || added === 0 ? (
						`${added} sessions were successfully added to group`
					) : (
						`${added} session was successfully added to group`
					)}`}
					bodyContent={() => {
						return (
							<div>
								<SelectField
									classes={'w-100'}
									label="Group Name"
									value={selectedValue}
									name="groupName"
									errorTrigger={requiredFields.selectedValue}
									options={groupNames}
									required={true}
									onChange={this.handleGroupChosen}
									onInputChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={this.reset}
					actionButtonLabel={!success ? !submitting ? 'Submit' : 'Adding...' : `Great!`}
					actionButton={() => this.submit()}
					actionButtonDisabled={success}
					cancelButtonLabel={!success ? `Cancel` : `Close`}


				></FieldModal>

			</div >
		);
	}
}

export default GroupSessionsModal;
