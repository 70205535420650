import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteGalleryModal extends React.Component {
    state = {
        submitting: false,
        isGeneralGallery: false
    };

    componentDidMount() {
        const { checkedItems, galleries, markedGallery } = this.props;

        //find the general gallery
        const generalGallery = galleries.find(g => g.feedIdentifier === '');//this is the general gallery
        const generalGalleryId = generalGallery._id;


        if ((markedGallery && markedGallery == generalGalleryId) || checkedItems.includes(generalGalleryId)) {
            this.setState({ isGeneralGallery: true });
        }


    }


    removePoint = async () => {
        const { eventId, orgId, checkedItems, toggle, markedGallery } = this.props;
        this.setState({ submitting: true })
        let feedIds = markedGallery ? [markedGallery] : checkedItems;
        await API().patch(`Organizations/${orgId}/events/${eventId}/eventFeed/`, {
            feedIds: feedIds,
        })
            .then((res) => {
                this.props.removeGallery(feedIds, toggle);
            }
            ).catch((err) => {
                console.log(err);
                this.setState({ submitting: false });
            });


    };


    render() {
        const { isOpen, toggle, checkedItems, markedGallery } = this.props;
        const { submitting, isGeneralGallery } = this.state;

        let itemCount = markedGallery ? 1 : checkedItems.length;

        return (

            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Delete Gallery"}
                    bodyHeaderText={isGeneralGallery ? <span>You cannot delete a General Gallery. Please remove from your selection to proceed to delete.</span> : <span>You are about to delete{' '}
                        <b>{itemCount}</b> {itemCount > 1 ? 'Galleries' : 'Gallery'} from
                        this event, would you like to continue?</span>}
                    bodyContent={() => {
                        return ('')
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                    actionButton={() => this.removePoint()}
                    actionButtonDisabled={submitting || isGeneralGallery}
                    isDelete={true}
                ></FieldModal>
            </div>

        );
    }
}

export default DeleteGalleryModal;
