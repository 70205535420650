import React, { Component } from 'react';
import { UncontrolledTooltip } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uniqueId: `id-${uuidv4()}`,
		};
	}
	render() {
		const {
			onChange,
			autoFocus,
			label,
			errorTrigger,
			errorMessage,
			type,
			value,
			disabled,
			placeholder,
			inputIcon,
			warningIcon,
			inputMode,
			pattern,
			autoComplete,
			maxLength,
			onKeyUp,
			onInputChange,
			style,
			inputStyle,
			required,
			classes,
			prefix,
			subText,
			min,
			clickIcon,
			max,
			inputAction,
			inputActionLabel,
			defaultValue,
			information,
			inputClassName,
		} = this.props;
		const { uniqueId } = this.state;
		return (
			<div className={`formFieldContainer ${classes ? classes : ''} ${disabled ? 'disabledField' : ''}`} style={style}>
				<div className="formField">
					{label &&
						label != '' && (
							<div className="labelContainer">
								<div className='flex'>
									<div className="formFieldLabel">
										{label}
										{warningIcon}
										{required ? '*' : ''}
									</div>
									{information &&
										<>
											<i
												id={uniqueId}

												className="grey las la-info-circle informationIcon mb-5"
											/>
											<UncontrolledTooltip
												className="tooltip formFieldTooltip"
												placement="right"
												target={uniqueId}
											>
												{information}
											</UncontrolledTooltip>
										</>
									}
								</div>

								{errorTrigger && <div className="formFieldLabel error">{errorMessage || 'Required'} </div>}
							</div>
						)}
					<div
						className={`formFieldInputContainer ${errorTrigger ? 'error' : ''}  ${inputIcon
							? 'hasIcon'
							: ''} ${clickIcon ? 'clickIcon' : ''}`}
					>
						{type == 'currency' && <span className="currencyPrefix">$</span>}
						{prefix && <span className="inputFieldPrefix">{prefix}</span>}
						<input
							key={label}
							style={inputStyle}
							value={value}
							className={`formFieldInput text-truncate ${inputClassName ? inputClassName : ''} `}
							type={type == 'currency' ? 'number' : type}
							placeholder={placeholder}
							disabled={disabled}
							onChange={onChange}
							onInput={onInputChange}
							inputMode={inputMode}
							pattern={pattern}
							autoComplete={autoComplete}
							maxLength={maxLength}
							onKeyUp={onKeyUp}
							required={required}
							defaultValue={defaultValue}
							min={min}
							max={max}
							autoFocus={autoFocus}
						/>
						{inputAction && <div className="filePickerButton inputAction" onClick={inputAction}>{inputActionLabel}</div>}
						{inputIcon && <div className="inputIcon">{inputIcon}</div>}
					</div>
					{subText && <div className="subText"> {subText}</div>}
				</div>
			</div>
		);
	}
}

export default InputField;
