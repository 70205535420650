import React, { Fragment } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import InputField from '../../../../components/inputs/inputField';

class NewEmailModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			emailName: '',
			subject: '',
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	handleEmailNameChange = (val) => {
		this.setState({
			emailName: val
		});
	};

	handleSubjectChange = (val) => {
		this.setState({
			subject: val
		});
	};

	addEmail = async (email) => {
		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (this.state.emailName === '') {
			requiredFields.name = true;
			valid = false;
		} else {
			requiredFields.name = false;
		}

		if (valid) {
			this.props.addNewEmail(email, (id) => {
				window.open(`/${this.props.orgId}/events/${this.props.eventId}/emails/${id}`, '_self');
			});
			this.setState({ invalid: false });
		} else {
			this.setState({ invalid: true });
		}
	};

	render() {
		const { isOpen } = this.props;
		const { emailName, submitting, invalid, subject, requiredFields } = this.state;
		return (
			<Modal isOpen={isOpen} toggle={this.resetAdd} >
				<ModalHeader className="modalHeader">
					{' '}
					<div className="calibreBold fs-30">Add New Email</div>
				</ModalHeader>
				<ModalBody className="modalBody">
					<InputField
						label="Email Name"
						value={emailName}
						required={true}
						errorTrigger={requiredFields.name}
						onChange={(e) => {
							this.handleEmailNameChange(e.target.value);
						}}
					/>
					{/* <div className="formField">
						<label className="displayLabel">Subject</label>
						<input
							className="detailsInput"
							value={subject}
							onChange={(e) => {
								this.handleSubjectChange(e.target.value);
							}}
						/>
					</div> */}
				</ModalBody>
				<ModalFooter className="modalFooter">
					<Button
						className="modalButton actionButton actionSave m-0"
						disabled={submitting}
						onClick={() => {
							this.addEmail({ name: emailName, subject: subject });
						}}
					>
						<div className="modalButtonText">{!submitting ? 'Submit' : 'Adding Email...'}</div>
					</Button>
					<Button
						onClick={this.props.toggle}
						outline
						className="modalButton actionButton modalCancel mb-0 ml-a"
					>
						<div className="modalCancelText">Cancel</div>
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default NewEmailModal;
