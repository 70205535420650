import React, { Fragment } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { Switch, Route, withRouter } from 'react-router-dom';
import API from '../../utils/API';
import LoadingWizard from '../spinner/wizard';

import SectionHeader from '../views/SectionHeader';
import TabsContainer from '../views/TabsContainer';

import InputField from '../inputs/inputField';

import TaskCard from '../cards/taskCard';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';


const ENV =
    process.env.NODE_ENV === 'production'
        ? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
        : `http://${window.location.hostname}:5015/`;
class InboxModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


            selectedSection: 0,
            loading: true,
            events: [],
            pastEvents: [],
            upcomingEvents: [],
            selectedSectionA: 0,
            selectedEvent: null,
            selectedEventData: null,
            hidePastEvents: false,
            searchTerm: '',
            hideResolvedTasks: false,
            editCount: 0,
        };
    }

    async componentDidMount() {
        const { orgId, eventId } = this.props;
        await API()
            .get(`Organizations/${orgId}/inbox`)
            .then((res) => {
                if (res.data) {
                    let pastEvents = [];
                    let upcomingEvents = [];
                    res.data.events.forEach((event) => {
                        if (new Date(event.endDate) < new Date()) {
                            pastEvents.push(event);
                        } else {
                            upcomingEvents.push(event);
                        }
                    });

                    pastEvents = pastEvents.reverse();


                    let taskCountByEventMapped = {};
                    for (let commentCount = 0; commentCount < res.data.comments.length; commentCount++) {
                        let comment = res.data.comments[commentCount];
                        if (taskCountByEventMapped[comment.eventId]) {
                            taskCountByEventMapped[comment.eventId] = taskCountByEventMapped[comment.eventId] + 1;
                        } else {
                            taskCountByEventMapped[comment.eventId] = 1;
                        }
                    }

                    let sessions = res.data.sessions;
                    let mappedSessions = {};
                    sessions.sort((a, b) => {
                        let aDate = new Date(a.date + ' ' + a.startTime);
                        let bDate = new Date(b.date + ' ' + b.startTime);
                        return aDate - bDate;
                    });



                    for (let sessionCount = 0; sessionCount < sessions.length; sessionCount++) {
                        let session = sessions[sessionCount];
                        let currentEvent = res.data.events.find((e) => e._id == session.eventId);

                        // Get current date and time in your timezone
                        let nowTZ = moment.tz(new Date(), moment.tz.guess());
                        let now2 = nowTZ.clone().tz(currentEvent.timezone);
                        let now = moment(now2.format('LLLL'));


                        //standardize date format
                        let sessionDate = moment(session.date).format('YYYY-MM-DD');
                        // Combine session date and start time, convert to session's timezone
                        let sessionStartTZ = moment(`${session.date} ${session.startTime}`);
                        // Combine session date and end time, convert to session's timezone
                        let sessionEndTZ = moment(`${session.date} ${session.endTime}`);

                        let sessionStart = moment(sessionStartTZ.format('LLLL'));
                        let sessionEnd = moment(sessionEndTZ.format('LLLL'));

                        // Check if session start is before, after or happening now
                        let isPast = sessionStart.isBefore(now);
                        let isCurrent = now.isBetween(sessionStart, sessionEnd, 'minute');
                        let isFuture = sessionStart.isAfter(now);

                        session.isPast = isPast;
                        session.isCurrent = isCurrent;
                        session.isFuture = isFuture;

                        if (mappedSessions[session.eventId]) {
                            if (mappedSessions[session.eventId][sessionDate]) {
                                mappedSessions[session.eventId][sessionDate].push(session);

                            } else {
                                mappedSessions[session.eventId][sessionDate] = [session];
                            }
                        } else {
                            mappedSessions[session.eventId] = {};
                            mappedSessions[session.eventId][sessionDate] = [session];

                        }
                    }




                    this.setState({ events: res.data.events, sessions: sessions, mappedSessions: mappedSessions, pastEvents: pastEvents, upcomingEvents: upcomingEvents, comments: res.data.comments, loading: false, taskCountByEvent: taskCountByEventMapped });
                }
            });
    }


    updateSelected = (index) => {
        this.setState({ selectedSectionA: index, hidePastEvents: false, hideResolvedTasks: false });
    }

    updateSelectedEvent = (eventId) => {
        const { events } = this.state;
        let selectedEventData = null;

        if (eventId) {
            selectedEventData = events.find((event) => event._id == eventId);
        }
        this.setState({ selectedEvent: eventId, selectedEventData: selectedEventData, selectedSectionA: 0 });
    }

    updateStatus = async (commentId, status) => {
        const { orgId, eventId } = this.props;

        let session = this.state.comments.find((c) => c._id == commentId);
        await API()
            .patch(`Organizations/${orgId}/events/${session.eventId}/sessions/${session._id}/comment/${commentId}`, {
                completed: status,
            })
            .then((res) => {

                if (res.data) {
                    const comments = this.state.comments;
                    const index = comments.findIndex((c) => c._id == commentId);
                    comments[index].completed = res.data.completed;
                    this.setState({ comments: comments }, () => {

                        this.props.updateTaskCount(!res.data.completed);
                    });

                }
            });

    }

    formatDate = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);
        const diffTime = Math.abs(now - createdDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        let formattedDate = '';
        if (now.toDateString() === createdDate.toDateString()) {
            // If posted today
            formattedDate = `${moment(createdAt).format('LT')} Today`;

        } else if (diffDays >= 1 && diffDays <= 6) {
            // If posted 1-6 days ago
            formattedDate = moment(createdAt).format('ddd h:mm a');
        } else {
            // If posted 7+ days ago
            formattedDate = `${moment(createdAt).format('MMM')} ${moment(createdAt).format('M')}/${moment(createdAt).format('YY')}`;//Dex 12/23
        }

        return formattedDate;
    }

    render() {
        const { isOpen, toggle, } = this.props;
        const { selectedSection, events, selectedSectionA, loading, upcomingEvents, pastEvents, selectedEvent, selectedEventData, hidePastEvents, searchTerm, comments, hideResolvedTasks, taskCountByEvent, mappedSessions } = this.state;
        let filteredComments = comments;
        if (selectedEvent) {
            filteredComments = comments.filter((c) => c.eventId == selectedEvent);
        }

        if (hideResolvedTasks) {
            filteredComments = filteredComments.filter((c) => c.completed !== true);
        }


        return (
            <div>
                <Modal isOpen={isOpen} style={{}} toggle={toggle} className={`large noPad`}>
                    <button onClick={toggle} style={{ position: 'absolute', right: 5, top: 5, padding: 7, zIndex: 100 }}><i className='las la-times'></i></button>

                    <div className='h-100'>
                        {!loading ? (
                            <div className="flex h-100">
                                <div className='p-50' style={{ background: 'var(--input)', width: 312, borderRadius: '15px', overflowX: 'hidden' }}>

                                    <h1 className='nowrap mb-20'>Inbox</h1>
                                    <button className={`mb-20 sideNav ${selectedEvent == null ? 'selected' : ''}`} onClick={() => this.updateSelectedEvent(null)}>All Tasks</button>

                                    <h5 className='mb-20'>Upcoming Events</h5>
                                    {upcomingEvents.length == 0 && <p className='gray' style={{ textAlign: 'center' }}>No upcoming events</p>}
                                    {upcomingEvents.map((event, index) => {
                                        return (
                                            <button style={{ padding: '5px 5px 5px 20px' }} className={`sideNav ${selectedEvent == event._id ? 'selected' : ''}`} onClick={() => this.updateSelectedEvent(event._id)} ><span className="mr-5" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{event.name}</span><span style={{ marginLeft: 'auto', fontSize: 10, }}>{taskCountByEvent[event._id]}</span></button>
                                        )
                                    }
                                    )}
                                    <h5 className='mt-30 mb-20'>Recent Events</h5>
                                    {pastEvents.length == 0 && <p className='gray ' style={{ textAlign: 'center' }}>No events in last 30 days</p>}
                                    {pastEvents.map((event, index) => {
                                        return (
                                            <button style={{ padding: '5px 5px 5px 20px' }} className={`sideNav ${selectedEvent == event._id ? 'selected' : ''}`} onClick={() => this.updateSelectedEvent(event._id)}><span className="mr-5" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{event.name}</span><span style={{ marginLeft: 'auto', fontSize: 10, }}>{taskCountByEvent[event._id]}</span></button>
                                        )
                                    }
                                    )}
                                </div>
                                {selectedEventData ? (
                                    <div className='flex column p-50 w-100'  >
                                        <div className='h-100'>
                                            <div className="sectionBody pt-0">
                                                <h1 className='mb-20 '>{selectedEventData.name}</h1>

                                                <TabsContainer
                                                    tabs={[
                                                        { title: 'My Agenda' },
                                                        { title: <span>My Tasks<span style={{ color: "var(--label)" }}>{taskCountByEvent[selectedEventData._id] ? ` (${taskCountByEvent[selectedEventData._id]})` : ``}</span></span> },
                                                    ]}
                                                    updateSelected={this.updateSelected}
                                                    selectedIndex={selectedSectionA}
                                                />

                                                <div style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
                                                    <div className="flex aic pt-20">
                                                        <div
                                                            className={`checkBox mr-10 ${(selectedSectionA == 0 && hidePastEvents) || (selectedSectionA == 1 && hideResolvedTasks) ? 'checked' : ''} `}
                                                            onClick={() => {
                                                                if (selectedSectionA == 0) {
                                                                    this.setState({ hidePastEvents: !hidePastEvents, editCount: this.state.editCount + 1 });
                                                                } else if (selectedSectionA == 1) {
                                                                    this.setState({ hideResolvedTasks: !hideResolvedTasks, editCount: this.state.editCount + 1 });
                                                                }
                                                            }}
                                                        >
                                                            <i className="las la-check" />
                                                        </div>
                                                        <h4>{selectedSectionA == 0 ? 'Hide Past Sessions' : selectedSectionA == 1 ? 'Hide Resolved Tasks' : ''}</h4>
                                                    </div>
                                                    {selectedSectionA == 0 ? (
                                                        <div className='commentListContainer pt-20 sectionBlock'>
                                                            {mappedSessions[selectedEvent] && Object.keys(mappedSessions[selectedEvent]).map((date, index) => {
                                                                let sessions = mappedSessions[selectedEvent][date];
                                                                //count how many sessions are hidden
                                                                let hiddenBlocks = sessions.filter((s) => s.isPast == true && s.isCurrent == false && hidePastEvents);

                                                                let hideSessionDateGroup = false;
                                                                if (hiddenBlocks.length === sessions.length) {
                                                                    hideSessionDateGroup = true;
                                                                } else if (hiddenBlocks.length < sessions.length) {
                                                                    hideSessionDateGroup = false;
                                                                } else if (hiddenBlocks.length === 0) {
                                                                    hideSessionDateGroup = true;
                                                                }

                                                                return (
                                                                    <div className={`sessionDateGroup ${hideSessionDateGroup == true ? 'hiddenBlock' : ''}`} style={{ paddingBottom: 45 }}>
                                                                        <div className='dateTitle'>{moment(date).format('dddd, L')}</div>
                                                                        {sessions.map((session, index) => {

                                                                            let statusBarClass = session.isCurrent ? 'current' : session.isPast ? 'past' : session.isFuture ? 'upcoming' : '';

                                                                            return (
                                                                                <div className={`flex agendaCardContainer ${hidePastEvents && session.isPast && session.isCurrent == false ? 'hiddenBlock' : ''}`}>
                                                                                    <div className={`statusBar ${statusBarClass}`}></div>
                                                                                    <div className='content'>
                                                                                        <div className='titleContent'>
                                                                                            <div>
                                                                                                <b>{session.name}</b>{session.location ? ` - ${session.location}` : ''}
                                                                                            </div>
                                                                                            <div className="blackButton">

                                                                                                <Link to={`/${this.props.orgId}/events/${session.eventId}/schedule/${session._id}`}>

                                                                                                    <div>
                                                                                                        <i className="las la-angle-right ml-5 mr-5"></i>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='detailsContent flex aic'>
                                                                                            <div className='date flex'
                                                                                                style={{ paddingRight: 40 }}>
                                                                                                <i className="las la-calendar mr-5"></i>
                                                                                                <p>{moment(session.date).format("dddd, L")}</p>
                                                                                            </div>
                                                                                            <div className='time flex aic'>
                                                                                                <i className="las la-clock mr-5"></i>
                                                                                                <p>{`${session.startTime} - ${session.endTime}`}</p>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}


                                                                    </div>

                                                                )

                                                            })}

                                                        </div>
                                                    ) : (

                                                        <div className='commentListContainer pt-20 sectionBlock'>
                                                            {filteredComments.map((comment, index) => {

                                                                let createdAt = this.formatDate(comment.createdAt);
                                                                let sessionName = comment.sessionId.name;
                                                                let eventName = comment.eventName || '';
                                                                let styleCSS = { maxWidth: 175, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
                                                                let description = <div style={{ color: 'var(--label)' }} className='flex'><p style={styleCSS}>{createdAt}</p><p className="ml-5 mr-5">&bull;</p><p style={styleCSS}>{eventName}</p><p><i className="las la-angle-right ml-5 mr-5"></i></p><p style={styleCSS}>{sessionName}</p></div>
                                                                let emptyProfilePicCreatedBy = !comment.createdBy || (comment.createdBy && (!comment.createdBy.profilePicture && comment.createdBy.profilePicture === ''));
                                                                return (
                                                                    <div key={index}>
                                                                        <TaskCard
                                                                            id={comment._id}
                                                                            message={comment.message}
                                                                            myTaskView={true}
                                                                            author={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
                                                                            authorAcronym={emptyProfilePicCreatedBy ? `${comment.createdBy.firstName[0]}${comment.createdBy.lastName[0]} ` : <img src={comment.createdBy.profilePicture} className=' abbreviation  ' />}
                                                                            description={description}
                                                                            assignedTo={comment.assignedTo}
                                                                            completed={comment.completed}
                                                                            updateStatus={this.updateStatus}
                                                                            link={`/${this.props.orgId}/events/${comment.eventId}/schedule/${comment.sessionId._id}`}
                                                                            htmlOnly={true}
                                                                            key={`${index}-${this.state.editCount}`}

                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>

                                                    )}
                                                </div>




                                            </div>
                                        </div>



                                    </div>
                                )
                                    :
                                    <div className='flex column p-50 w-100'  >
                                        <div className='h-100'>
                                            <div className="sectionBody pt-0">
                                                <h1 className='mb-20'>All Tasks</h1>
                                                <hr />

                                                <div style={{ overflowX: 'hidden', maxHeight: 'calc(100% - 56px)' }}>
                                                    <div className="flex aic pt-10 pb-20">
                                                        <div
                                                            className={`checkBox mr-5 ${hideResolvedTasks ? 'checked' : ''} `}
                                                            onClick={() => {
                                                                this.setState({ hideResolvedTasks: !hideResolvedTasks, editCount: this.state.editCount + 1 });
                                                            }}
                                                        >
                                                            <i className="las la-check" />
                                                        </div>
                                                        <h4>Hide Resolved Tasks</h4>
                                                    </div>

                                                    <div className='commentListContainer pt-20 sectionBlock'>
                                                        {filteredComments.map((comment, index) => {
                                                            let createdAt = this.formatDate(comment.createdAt);
                                                            let sessionName = comment.sessionId.name;
                                                            let eventName = comment.eventName || '';
                                                            let styleCSS = { maxWidth: 175, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
                                                            let description = <div style={{ color: 'var(--label)' }} className='flex'><p style={styleCSS}>{createdAt}</p><p className="ml-5 mr-5">&bull;</p><p style={styleCSS}>{eventName}</p><p><i className="las la-angle-right ml-5 mr-5"></i></p><p style={styleCSS}>{sessionName}</p></div>
                                                            let emptyProfilePicCreatedBy = !comment.createdBy || (comment.createdBy && (!comment.createdBy.profilePicture || comment.createdBy.profilePicture === ''));
                                                            return (
                                                                <div key={index}>
                                                                    <TaskCard
                                                                        id={comment._id}
                                                                        message={comment.message}
                                                                        myTaskView={true}
                                                                        author={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
                                                                        authorAcronym={emptyProfilePicCreatedBy ? `${comment.createdBy.firstName[0]}${comment.createdBy.lastName[0]} ` : <img src={comment.createdBy.profilePicture} className=' abbreviation  ' />}
                                                                        description={description}
                                                                        assignedTo={comment.assignedTo}
                                                                        completed={comment.completed}
                                                                        updateStatus={this.updateStatus}
                                                                        link={`/${this.props.orgId}/events/${comment.eventId}/schedule/${comment.sessionId._id}`}
                                                                        htmlOnly={true}
                                                                        key={`${index}-${this.state.editCount}`}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        ) : (
                            <LoadingWizard loading={loading} text={"Loading Inbox"} />
                        )}
                    </div>
                </Modal >
            </div >

        );
    }
}

export default withRouter(InboxModal);
