import React, { Component } from 'react';

import API from '../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';

import InputField from '../../inputs/inputField.js';
import ToggleField from '../../inputs/toggleField.js';
import FieldModal from '../../modals/fieldModal';
class AddViewModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitting: false,
			name: '',
			useCurrentViewSettings: true,
			requiredFields: {
				name: false
			}
		};
	}

	saveNewView = () => {
		const { name, useCurrentViewSettings, requiredFields } = this.state;
		const { orgId, type, referenceCollection, referenceId, normalizedColumns } = this.props;
		const view = {
			name,
			type: type,
			referenceCollection: referenceCollection,
			referenceId: referenceId
		};
		let valid = true;
		let sortedColumns = [];
		normalizedColumns.map((nc) => {
			sortedColumns.push(nc.key);
		})

		Object.keys(requiredFields).forEach((key) => {
			if (!view[key] || view[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		if (valid) {
			if (useCurrentViewSettings) {
				let sortedColumns = [];
				normalizedColumns.map((nc) => {
					sortedColumns.push(nc.key);
				})
				if (this.props.filterLayers.length > 0 && this.props.filterLayers[0].condition != '') {
					view.filterLayers = this.props.filterLayers;
				} else {
					view.filterLayers = [];
				}
				view.toggledColumns = Object.keys(this.props.toggledColumns).filter(
					(tck) => this.props.toggledColumns[tck] == true
				);
				view.columnsOrder = sortedColumns;
			} else {
				view.filterLayers = [];
				view.columnsOrder = [];
				view.toggledColumns = Object.keys(this.props.defaultColumns);

			}
			if (name != '') {
				this.setState({ submitting: true });
				API()
					.post(`/Organizations/${orgId}/views`, view)
					.then((res) => {
						this.setState({ submitting: false, requiredFields: requiredFields });
						this.props.addView(res.data);
						this.props.toggle();
					})
					.catch((err) => {
						this.setState({ submitting: false });
						console.log(err);
					});
			}
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { submitting, name, useCurrentViewSettings, requiredFields } = this.state;
		return (
			<div>

				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Views"}
					bodyHeaderText={'Please enter the information below to add new view.'}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<InputField
									value={name}
									label={'View Name'}
									required={true}
									errorTrigger={requiredFields.name}
									onChange={(e) => this.setState({ name: e.target.value })}
								/>
								<ToggleField
									label="Use current view settings"
									checked={useCurrentViewSettings}
									icons={false}
									onChange={(e) => this.setState({ useCurrentViewSettings: !useCurrentViewSettings })}
								/>{' '}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					actionButton={() => this.saveNewView()}
					actionButtonDisabled={submitting}

				></FieldModal>

			</div>

		);
	}
}

export default AddViewModal;
