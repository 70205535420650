import React, { Component } from 'react';
import InputField from '../../components/inputs/inputField';
import SelectField from '../../components/inputs/selectField';
import TabsContainer from '../../components/views/TabsContainer';
import FileChooserPreview from '../../components/inputs/fileChooserPreview';
import FilesUploaderModal from '../../components/files/filesUploaderModal';

class AccountDetails extends Component {
	constructor(props) {
		super(props);
		const urlParams = new URLSearchParams(window.location.search);
		const selectedTab = urlParams.get('tab');
		const { orgId } = props
	}
	render() {
		const {
			name,
			identifier,
			hostname,
			branding,
			adminEmail,
			adminEmailName,
			defaultRedirect,
			defaultUserPath,
			defaultPublicPath,
		} = this.props.accountData;
		const publicPathOptions = this.props.accountData.orgPages
			.map((po) => {
				if (po.openToPublic) return { label: po.title, value: po.pathSlug };
			})
			.filter((p) => !!p);
		const privatePathOptions = this.props.accountData.orgPages.map((po) => {
			return { label: po.title, value: po.pathSlug };
		});
		let publicPath = publicPathOptions.find((path) => path.value == defaultPublicPath);
		let privatePath = privatePathOptions.find((path) => path.value == defaultUserPath);

		let selectedSection = this.props.selectedIndexA
		const { orgId } = this.props
		return (
			<div className="sectionContainer mt-20 " >
				<div className="sectionBody h-100">
					<div className="flex h-100">
						<div className="sectionBlock" style={{ maxHeight: '100%', overflowX: 'hidden', paddingRight: 5 }} >
							{selectedSection == 0 && <div>
								<h2 className='mb-20  semibold'>Account</h2>
								<InputField
									label="Account Name"
									type="text"
									value={name}
									disabled={true}
									inputIcon={<i className="las la-lock lightGray" />}
								/>
								<InputField
									label="Platform Address"
									type="text"
									value={`${identifier}.simple.events`}
									disabled={true}
									inputIcon={<i className="las la-lock lightGray" />}
								/>
								<InputField
									label="Custom Web Address"
									type="text"
									value={hostname}
									disabled={true}
									inputIcon={<i className="las la-lock lightGray" />}
								/>
								<h2 className="mb-20 mt-20 semibold">Support</h2>
								<InputField
									label="Support Name"
									type="text"
									value={branding.accountName}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											branding: {
												...this.props.accountData.branding,
												accountName: e.target.value
											}
										});
									}}
								/>
								<InputField
									label="Support Email"
									type="text"
									value={branding.supportEmail}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											branding: {
												...this.props.accountData.branding,
												supportEmail: e.target.value
											}
										});
									}}
								/>

								<h2 className="mb-20 mt-20 semibold">Emails</h2>
								<InputField
									label="Default From  Name"
									type="text"
									value={adminEmailName}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											adminEmailName: e.target.value
										});
									}}
								/>
								<InputField
									label="Default From Email"
									type="text"
									value={adminEmail}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											adminEmail: e.target.value
										});
									}}
								/>

							</div>}
							{selectedSection == 1 && <div>
								<h2 className="mb-20 semibold">Setup</h2>
								<SelectField
									label="Public Home Page"
									value={publicPath ? publicPath : { label: '', value: '' }}
									name="defaultUserPath"
									options={publicPathOptions}
									onChange={(path) => {
										this.props.editAccountData({
											...this.props.accountData,
											defaultPublicPath: path.value
										});
									}}
								/>
								<SelectField
									label="Private Home Page"
									value={privatePath ? privatePath : { label: '', value: '' }}
									name="defaultUserPath"
									options={privatePathOptions}
									onChange={(path) => {
										this.props.editAccountData({
											...this.props.accountData,
											defaultUserPath: path.value
										});
									}}
								/>
								<h2 className="mb-20 mt-20 semibold">Login</h2>
								<InputField
									label="Login Page Title"
									type="text"
									value={branding.loginTitle}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											branding: {
												...branding,
												loginTitle: e.target.value
											}
										});
									}}
								/>
								{/* <InputField
								label="Page Background"
								type="text"
								value={branding.background}
								onChange={(e) => {
									this.props.editAccountData({
										...this.props.accountData,
										branding: {
											...branding,
											background: e.target.value
										}
									});
								}}
							/> */}

								<FileChooserPreview
									label="Page Background"
									value={branding.background}
									onClick={() => {
										this.props.editAccountData({
											...this.props.accountData,
											branding: {
												...this.props.accountData.branding,
												background: 'choosing__' + branding.background
											}
										});
									}}
									clearFile={() => {

										this.props.editAccountData({
											...this.props.accountData,
											branding: {
												...this.props.accountData.branding,
												background: ''
											}
										});
									}}
								/>
								{branding.background.includes('choosing__') &&
									<FilesUploaderModal
										isOpen={branding.background.includes('choosing__')}
										toggle={() => {
											this.props.editAccountData({
												...this.props.accountData,
												branding: {
													...this.props.accountData.branding,
													background: branding.background.replace('choosing__', '')
												}
											});
										}}
										//eventId={eventId}

										links={{
											getAssets: `Organizations/${orgId}/assets`,
											addAssets: `Organizations/${orgId}/assets`,
										}}
										orgId={orgId}
										addFiles={(fileURL) => {

											this.props.editAccountData({
												...this.props.accountData,
												branding: {
													...this.props.accountData.branding,
													background: fileURL
												}
											});
										}}
									/>}

							</div>}
							{selectedSection == 2 && <div>
								<h2 className="mb-20 semibold">Tracking</h2>
								<InputField
									label="Google Analytics Code"
									type="text"
									value={branding.googleId}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											branding: {
												...this.props.accountData.branding,
												googleId: e.target.value
											}
										});
									}}
								/>
								<InputField
									label="Default Redirect"
									type="text"
									prefix={hostname && hostname != '' ? `${hostname}/` : `${identifier}.simple.events`}
									value={defaultRedirect}
									onChange={(e) => {
										this.props.editAccountData({
											...this.props.accountData,
											defaultRedirect: e.target.value
										});
									}}
								/>
							</div>}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default AccountDetails;
