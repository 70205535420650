import React from 'react';

import API from '../../utils/API';
import 'react-datetime/css/react-datetime.css';

import FieldModal from '../../components/modals/fieldModal';

import SelectField from '../../components/inputs/selectField';
import InputField from '../../components/inputs/inputField';
const permissionOptions = [{ label: 'Admin', value: 'admin' }, { label: 'Organizer', value: 'organizer' }];
const defaultPermissions = {
	readOnly: false,
	capabilities: ['overview', 'details', 'attendees', 'schedule', 'groups', 'inventory', 'emails', 'texting', 'forms', 'pages', 'engagement', 'media'],
	permissions: {
		overview: {
			write: true,
			read: true
		},
		details: {
			write: true,
			read: true
		},
		attendees: {
			write: true,
			read: true
		},
		schedule: {
			write: true,
			read: true
		},
		groups: {
			write: true,
			read: true
		},
		inventory: {
			write: true,
			read: true
		},
		emails: {
			write: true,
			read: true
		},
		texting: {
			write: true,
			read: true
		},
		forms: {
			write: true,
			read: true
		},
		pages: {
			write: true,
			read: true
		},
		engagement: {
			write: true,
			read: true
		},
		media: {
			write: true,
			read: true
		},
		insights: {
			write: true,
			read: true
		},
		reports: {
			write: true,
			read: true
		},
		notifications: {
			write: true,
			read: true
		}
	}
};
class AddTeamMemberModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			emailAddress: '',
			permission: 'organizer',
			eventId: null,
			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {
				emailAddress: false,
				permission: false,
			},
			eventRequired: false,
			emailExistsMessage: '',
		};
	}

	inviteTeamMember = () => {
		const { firstName, lastName, emailAddress, permission, eventId, } = this.state;
		const { organization, allEmails } = this.props;
		let valid = true;
		const data = {
			firstName,
			lastName,
			emailAddress,
			permission,
		};
		const requiredFields = this.state.requiredFields;

		Object.keys(requiredFields).forEach((key) => {
			if (data[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});

		let eventRequired = false;

		if (permission == 'organizer' && eventId == null) {
			eventRequired = true;
			valid = false;
		}

		let eventPermissions = [];
		let dp = defaultPermissions;
		dp.eventId = eventId;

		eventPermissions.push(dp);
		data.eventPermissions = eventPermissions;
		let emailExistsMessage = '';
		if (allEmails.includes(emailAddress)) {
			valid = false;
			emailExistsMessage = 'This email is already in the team.';
		}


		if (valid) {
			this.setState({ submitting: true, requiredFields, eventRequired });

			let payload = {};
			if (permission === 'admin') {
				payload = {
					teamMembers: [{
						firstName: firstName,
						lastName: lastName,
						email: emailAddress,
						role: permission,

					}]
				};
			} else {
				payload = {
					teamMembers: [{
						firstName: firstName,
						lastName: lastName,
						email: emailAddress,
						role: permission,
						eventId: eventId,
						eventPermissions: eventPermissions
					}]
				};
			}

			API().post(`Organizations/${this.props.orgId}/memberInvite`, payload)
				.then((res) => {
					//this.setState({ submitting: false });
					window.location.reload();

				})
				.catch((e) => {
					let errorMessage = e.response.data || 'An error occurred';
					this.setState({ error: errorMessage });
				});
			//}

		} else {
			this.setState({ invalid: true, requiredFields, eventRequired, emailExistsMessage });
		}
	};

	resetAdd = (e, toggle) => {
		this.setState({
			firstName: '',
			lastName: '',
			emailAddress: '',
			permission: 'organizer',
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	render() {
		const { isOpen, handleSubmit, locations, toggle, allEmails } = this.props;
		const {
			firstName,
			lastName,
			emailAddress,
			permission,
			submitting,
			invalid,
			error,
			requiredFields,
			eventRequired,
			emailExistsMessage,

		} = this.state;

		let eventName = this.props.events;

		return (

			<div>

				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Add Team Member"}
					bodyHeaderText={''}
					bodyDescription={''}
					bodyContent={() => {
						return (
							<div>
								<div>
									<InputField
										label="First Name"
										value={firstName}
										type="text"
										onChange={(e) => this.setState({ firstName: e.target.value })}
									/>

									<InputField
										label="Last Name"
										value={lastName}
										type="text"
										onChange={(e) => this.setState({ lastName: e.target.value })}
									/>

									<InputField
										label="Email"
										value={emailAddress}
										type="text"
										required={true}
										errorTrigger={requiredFields.emailAddress}
										onChange={(e) => this.setState({ emailAddress: e.target.value })}
									/>

									<SelectField
										label="Role"
										name="permissions"
										value={permission === 'admin' ? permissionOptions[0] : permissionOptions[1]}
										options={permissionOptions}
										required={true}
										errorTrigger={requiredFields.permission}
										onChange={(e) => this.setState({ permission: e.value })}
									/>
									{permission !== 'admin' &&
										<SelectField
											label="Event"
											name="Event"
											options={eventName}
											required={true}
											errorTrigger={eventRequired}
											onChange={(e) => this.setState({ eventId: e.value })}
										/>
									}
								</div>
								{emailExistsMessage && <p className="danger fs20 mb-10" >
									{emailExistsMessage}
								</p>}
								{error && (
									<p className="danger" >
										{error}
									</p>
								)}
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Send Invite' : 'Sending...'}
					actionButton={() => this.inviteTeamMember()}
					actionButtonDisabled={submitting}


				></FieldModal>

			</div>

		);
	}
}

export default AddTeamMemberModal;
