import React, { Component, Fragment } from 'react';
import InputField from '../../../components/inputs/inputField';
import SelectField from '../../../components/inputs/selectField';
import ColorField from '../../../components/inputs/colorField';
import TextAreaField from '../../../components/inputs/textAreaField';
import TabsContainer from '../../../components/views/TabsContainer';
import SectionHeader from '../../../components/views/SectionHeader';
import EventInteractionPoints from '../interactions/points';
import EventScannables from '../interactions/scannables';
import EventGallery from '../interactions/gallery';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import EventGalleries from '../interactions/galleries';
import API from '../../../utils/API';

class MobileApp extends Component {
	constructor(props) {
		super(props);
		const pwaDetails = props.event.pwaDetails;
		const privatePathOptions = props.event.pathOptions.map((po) => {
			return { label: po.title, value: po.pathSlug };
		});
		const iconSize = [
			{
				label: '72x72',
				value: '72x72'
			},
			{
				label: '96x96',
				value: '96x96'
			},
			{
				label: '128x128',
				value: '128x128'
			},
			{
				label: '144x144',
				value: '144x144'
			},
			{
				label: '152x152',
				value: '152x152'
			},
			{
				label: '192x192',
				value: '192x192'
			},
			{
				label: '384x384',
				value: '384x384'
			},
			{
				label: '512x512',
				value: '512x512'
			}
		];

		const iconType = [{ label: 'image/png', value: 'image/png' }, { label: 'image/jpg', value: 'image/jpg' }];
		this.state = {
			editedPwaDetails: {
				...pwaDetails
			},
			pwaDetails: {
				...pwaDetails
			},
			editing: false,
			privatePathOptions: privatePathOptions,
			defaultPath: privatePathOptions.find((po) => po.value === this.props.event.pwaDetails.defaultPath),
			pwaEnabled: this.props.event.pwaEnabled,
			mobileIcon: this.props.event.pwaDetails.icons,
			isValid: true,
			iconSizeOptions: iconSize,
			iconTypeOptions: iconType,
			iconsError: false,
			requiredFields: {
				name: false,
				shortName: false,
				iosModalInstallationInstructions: false,
				defaultPath: false,
				promptTimeout: false,
				backgroundColor: false
			},
			selectedSection: 0,
			selectedTab: 0,
			galleries: [],
			loading: true
		};
	}
	componentDidMount() {
		this.fetchGalleries();
	}

	save = (e) => {
		const {
			backgroundColor,
			name,
			shortName,
			description,
			iosModalInstallationInstructions,
			icons,
			defaultPath,
			promptTimeout,
			pwaEnabled,
			mobileIcon
		} = this.state;

		const requiredFields = this.state.requiredFields;
		let valid = true;
		Object.keys(requiredFields).forEach((key) => {
			if (!this.state.editedPwaDetails[key] || this.state.editedPwaDetails[key] === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			this.setState({ saving: true });
			//checks if mobileIcon contains any blanks
			var exists = mobileIcon.find((i) => i.src == '' || i.type == '' || i.size == '');
			requiredFields.mobileIcon = exists;
			if (!exists) {
				this.props.save(
					{
						pwaDetails: {
							backgroundColor,
							name,
							shortName,
							description,
							iosModalInstallationInstructions,
							icons,
							defaultPath: defaultPath.value,
							promptTimeout,
							pwaEnabled,
							icons: mobileIcon
						},
						pwaEnabled: pwaEnabled
					},
					() => {
						this.setState({
							editing: false,
							saving: false,
							requiredFields: requiredFields,
							iconsError: false,
							icons: mobileIcon
						});
					}
				);
			} else {
				this.setState({ requiredFields: requiredFields, saving: false, iconsError: false });
			}
		} else {
			this.setState({ requiredFields: requiredFields });
		}
	};

	cancelEdit = () => {
		const pwaDetails = this.props.event.pwaDetails;

		const requiredFields = this.state.requiredFields;
		Object.keys(requiredFields).forEach((key) => {
			requiredFields[key] = false;
		});
		this.setState({
			backgroundColor: pwaDetails.backgroundColor,
			name: pwaDetails.name,
			shortName: pwaDetails.shortName,
			description: pwaDetails.description,
			iosModalInstallationInstructions: pwaDetails.iosModalInstallationInstructions,
			icons: pwaDetails.icons,
			defaultPath: this.state.privatePathOptions.find(
				(po) => po.value === this.props.event.pwaDetails.defaultPath
			),
			promptTimeout: pwaDetails.promptTimeout,
			editing: false,
			pwaEnabled: this.props.event.pwaEnabled,
			requiredFields: requiredFields,
			mobileIcon: this.props.event.pwaDetails.icons,
			isValid: true,
			iconsError: false
		});
	};

	saveEnablePWA = (val) => {
		const { requiredFields } = this.state;
		this.props.save(
			{
				pwaDetails: {
					...this.props.event.pwaDetails,
					pwaEnabled: val
				},
				pwaEnabled: val
			},
			() => {
				this.setState({
					requiredFields: requiredFields,
					iconsError: false
				});
			}
		);
	};

	enablePWA = () => {
		const { icons, pwaEnabled } = this.state;
		this.setState({ saving: true });
		//checks if mobileIcon contains any blanks

		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (!pwaEnabled) {
			Object.keys(requiredFields).forEach((key) => {
				if (!this.state[key] || this.state[key] === '') {
					requiredFields[key] = true;
					valid = false;
				} else {
					requiredFields[key] = false;
				}
			});
		}

		if ((valid && (!pwaEnabled && icons.length > 0)) || pwaEnabled) {
			this.setState({ pwaEnabled: !this.state.pwaEnabled }, () => {
				const { pwaEnabled, icons } = this.state;
				if (pwaEnabled && icons.length > 0) {
					this.saveEnablePWA(true);
				} else if (!pwaEnabled) {
					this.saveEnablePWA(false);
				}
			});
		} else {
			this.setState({ saving: false, requiredFields: requiredFields, iconsError: true });
		}
	};

	fetchGalleries = async () => {
		const { orgId, eventId } = this.props;

		await API().get(`Organizations/${orgId}/events/${eventId}/galleries`).then((res) => {
			if (res.data) {

				let galleries = res.data.galleries.map((gallery) => {
					return {
						...gallery,
						link: `/${orgId}/events/${eventId}/engagement/gallery/${gallery._id}`

					}
				});
				this.setState({
					galleries: galleries,
					loading: false
				});
			}
		});
	}

	updateFeedItems = (galleryId, feedItems, callback) => {
		const { galleries } = this.state;
		let updatedGalleries = galleries.map((gallery) => {
			if (gallery._id === galleryId) {
				return { ...gallery, feedItems: feedItems, feedItemsCount: feedItems.length };
			}
			return gallery;
		});

		this.setState({ galleries: updatedGalleries }, () => {
			callback && callback();
		});

	}

	updateSelected = (selectedIndex) => {
		this.setState({
			selectedSection: selectedIndex
		});
	};

	renderSection = () => {
		const {
			icons,
			defaultPath,
			editing,
			pwaEnabled,
			editedPwaDetails,
			mobileIcon,
			isValid,
			iconSizeOptions,
			iconTypeOptions,
			requiredFields,
			iconsError,
			selectedSection
		} = this.state;
		const {
			backgroundColor,
			name,
			shortName,
			description,
			iosModalInstallationInstructions,
			promptTimeout, } = editedPwaDetails;
		const { event, sectionName, readOnly } = this.props;
		let section;
		switch (selectedSection) {
			case 0:
				section = <EventScannables
					title={'Virtual Engagement'}
					event={event}
					readOnly={readOnly}
					eventId={event._id}
					orgId={this.props.orgId}
					organization={this.props.organization}
				/>
				break;
			case 1:
				section = <EventInteractionPoints
					event={event}
					readOnly={readOnly}
					eventId={event._id}
					orgId={this.props.orgId}
				/>
				break;
			case 2:
				section = <div className="sectionBody">
					<div className="sectionBlock">
						<h2 className='blockHeader'>Enable Mobile App</h2>
						<button className={`prim `} onClick={this.enablePWA}>
							{pwaEnabled ? 'Disable' : 'Enable'}
						</button>

						<h2 className='blockHeader'>Installation</h2>
						<InputField
							label="Application Name"
							value={name}
							errorTrigger={requiredFields.name}
							disabled={readOnly}
							required={true}
							onChange={(e) => {
								this.setState({
									editedPwaDetails: { ...editedPwaDetails, name: e.target.value },
									name: e.target.value,
									editing: true
								});
							}}
						/>
						<InputField
							label="Application Short Name"
							value={shortName}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.shortName}
							onChange={(e) => {
								this.setState({
									editedPwaDetails: { ...editedPwaDetails, shortName: e.target.value },
									shortName: e.target.value,
									editing: true
								});
							}}
						/>

						<InputField
							label="Application Description"
							value={description}
							disabled={readOnly}
							className={`editingField`}
							onChange={(e) => {
								this.setState({
									editedPwaDetails: { ...editedPwaDetails, description: e.target.value },
									description: e.target.value,
									editing: true
								});
							}}
						/>
						<SelectField
							label="Default Home Page"
							required={true}
							value={
								defaultPath ? (
									defaultPath
								) : (
									{
										label: '',
										value: ''
									}
								)
							}
							errorTrigger={requiredFields.defaultPath}
							name="registrationPageSlug"
							disabled={readOnly}
							options={this.state.privatePathOptions}
							onChange={(path) => {
								this.setState({
									editedPwaDetails: { ...editedPwaDetails, defaultPath: { label: path.label, value: path.value } },
									defaultPath: { label: path.label, value: path.value },
									editing: true
								});
							}}
						/>
						<ColorField
							label="Application Color"
							value={backgroundColor}
							type="color"
							required={true}
							errorTrigger={requiredFields.backgroundColor}
							disabled={readOnly}
							onChange={(e) => {
								this.setState({
									editedPwaDetails: { ...editedPwaDetails, backgroundColor: e },
									backgroundColor: e,
									editing: true
								});
							}}
						/>
						<TextAreaField
							label="Installation Prompt Message"
							value={iosModalInstallationInstructions}
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.iosModalInstallationInstructions}
							className={`editingField`}
							onChange={(e) => {
								this.setState({
									iosModalInstallationInstructions: e.target.value,
									editedPwaDetails: { ...editedPwaDetails, iosModalInstallationInstructions: e.target.value },
									editing: true
								});
							}}
						/>
						<InputField
							label="If installation prompt is dismissed, show again after (hours)"
							value={promptTimeout}
							type="number"
							disabled={readOnly}
							required={true}
							errorTrigger={requiredFields.promptTimeout}
							className={`editingField`}
							onChange={(e) => {
								this.setState({
									promptTimeout: e.target.value,
									editedPwaDetails: { ...editedPwaDetails, promptTimeout: e.target.value },

									editing: true
								});
							}}
						/>

						<label className="displayLabel">
							<div className="flex-ac">
								Mobile Application Homescreen Icons *
								<div
									onClick={() => {
										var exists = mobileIcon.find(
											(i) => i.src == '' || i.type == '' || i.size == ''
										);
										if (mobileIcon.length == 0 || !exists) {
											this.setState({
												mobileIcon: [...mobileIcon, { src: '', size: '', type: '' }]
											});
										} else {
											this.setState({ isValid: false });
										}
									}}
								>
									<i className="las la-plus-circle ml-10 c-pointer clickable" />
								</div>
								{iconsError && (
									<span style={{ marginLeft: 10, color: '#b90000' }}>Add at least one icon</span>
								)}
							</div>
						</label>

						{mobileIcon.map((icon, index) => {
							return (
								<div style={{ display: 'flex', justifyContent: 'space-between' }}>
									<div
										onClick={() => {
											let updatedMobileIcon = mobileIcon.filter((i, newIndex) => {
												if (index !== newIndex) return i;
											});
											this.setState({ mobileIcon: updatedMobileIcon, editing: true });
										}}
									>
										<i className="las la-minus-circle mr-10 c-pointer" style={{ color: 'red' }} />
									</div>
									<InputField
										label="Icon Url"
										value={icon.src}
										onChange={(e) => {
											let mobileObj = JSON.parse(JSON.stringify(mobileIcon));
											mobileObj[index].src = e.target.value;
											this.setState({
												mobileIcon: mobileObj,
												editing: true
											});
										}}
									/>
									<SelectField
										label="Icon Size"
										value={
											icon.size ? (
												{
													label: icon.size,
													value: icon.size
												}
											) : (
												{
													label: '',
													value: ''
												}
											)
										}
										name="registrationPageSlug"
										options={iconSizeOptions}
										disabled={readOnly}
										onChange={(path) => {
											let mobileObj = JSON.parse(JSON.stringify(mobileIcon));
											mobileObj[index].size = path.label;
											this.setState({
												mobileIcon: mobileObj,
												editing: true
											});
										}}
									/>
									<SelectField
										label="Icon Type"
										value={
											icon.type ? (
												{
													label: icon.type,
													value: icon.type
												}
											) : (
												{
													label: '',
													value: ''
												}
											)
										}
										name="registrationPageSlug"
										options={iconTypeOptions}
										disabled={readOnly}
										onChange={(path) => {
											let mobileObj = JSON.parse(JSON.stringify(mobileIcon));
											mobileObj[index].type = path.label;
											this.setState({
												mobileIcon: mobileObj,
												editing: true
											});
										}}
									/>
								</div>
							);
						})}
						{!isValid && <div style={{ color: 'red' }}>All Fields must be defined</div>}
					</div>
				</div>
		}

		return <Fragment>
			{section}
		</Fragment>
	};

	updateSelecteds = (selectedIndex) => {
		switch (selectedIndex) {
			case 0:
				this.props.history.push(`/${this.props.organization._id}/events/${this.props.event._id}/engagement/qrcodes`);
				break;
			case 1:
				this.props.history.push(`/${this.props.organization._id}/events/${this.props.event._id}/engagement/interaction-points`);
				break;
			case 2:
				this.props.history.push(`/${this.props.organization._id}/events/${this.props.event._id}/engagement/gallery`);
				break;
		}
	}

	setSelectedTab = (tabName) => {
		this.setState({ selectedTab: tabName });
	}

	setSelectedGallery = (gallery) => {
		this.setState({ selectedGallery: gallery });
	}

	addNewGallery = (gallery, callback) => {
		const galleries = this.state.galleries;
		gallery.link = `/${this.props.orgId}/events/${this.props.eventId}/engagement/gallery/${gallery._id}`;
		galleries.push(gallery);
		this.setState({ galleries: galleries }, () => {
			callback && callback();
		});
	}
	removeGallery = (feedIds, callback) => {
		const { galleries } = this.state;

		let updatedGalleries = galleries.filter((gallery) => !feedIds.includes(gallery._id));
		this.setState({ galleries: updatedGalleries }, () => {
			callback && callback();
		});
	}
	updateFeedItem = (galleryId, properties, callback) => {
		const { galleries } = this.state;
		let updatedGalleries = galleries.map((gallery) => {
			if (gallery._id === galleryId) {
				let feedItems = gallery.feedItems.map((feedItem) => {
					if (feedItem._id === properties._id) {
						return { ...feedItem, ...properties };
					}
					return feedItem;
				}
				);
			}
			return gallery;
		});
		this.setState({ galleries: updatedGalleries }, () => {
			callback && callback();
		});
	}
	removeFeedItems = (galleryId, feedItemIds, callback) => {
		const { galleries } = this.state;
		let updatedGalleries = galleries.map((gallery) => {
			if (gallery._id === galleryId) {
				let updatedFeedItems = gallery.feedItems.filter((feedItem) => !feedItemIds.includes(feedItem._id));
				return { ...gallery, feedItems: updatedFeedItems };
			}
			return gallery;
		});
		this.setState({ galleries: updatedGalleries }, () => {
			callback && callback();
		});
	}

	render() {

		const {
			selectedTab,
			galleries,
			loading,
			selectedGallery
		} = this.state;
		const { event, readOnly } = this.props;
		return (
			<Switch>
				<Route exact path={`/:organization/events/:event/engagement/gallery/:gallery`}>
					<Fragment>
						<div className="sectionContainer">
							<SectionHeader
								title={selectedGallery}
								breadcrumbs={[
									{
										name: event.name,
									},
									{
										name: 'Gallery',
									},

								]}
								description="Manage the virtual engagement experiences for attendees."
							>
								<div class="tabsContainer unsetWidth pt-10 "></div>
							</SectionHeader>
							{!loading &&
								<EventGallery
									event={event}
									readOnly={false}
									eventId={event._id}
									orgId={this.props.orgId}
									setSelectedTab={this.setSelectedTab}
									attendees={this.props.attendees}
									galleries={galleries}
									loading={this.state.loading}
									setSelectedGallery={this.setSelectedGallery}
									updateFeedItem={this.updateFeedItem}
									removeFeedItems={this.removeFeedItems}
									updateFeedItems={this.updateFeedItems}
								/>

							}
						</div>
					</Fragment>
				</Route>

				<Route exact path={`/:organization/events/:event/engagement`}>
					<Redirect
						to={{
							pathname: 'engagement/qrcodes'
						}}
					/>
				</Route>
				<Route path={`/:organization/events/:event/engagement`}>

					<div className="sectionContainer">
						<SectionHeader
							title={'Virtual Engagement'}
							breadcrumbs={[
								{
									name: event.name,
								}
							]}
							description="Manage the virtual engagement experiences for attendees."
							directLink={'../../'}
						>
							<TabsContainer
								tabs={[
									{ title: <span>{/* <i className="las la-qrcode mr-10"></i> */}QR Codes</span> },
									{ title: <span>{/* <i className="las la-award mr-10"></i> */}Points</span> },
									{ title: <span>{/* <i className="las la-award mr-10"></i> */}Gallery</span> },
									/* { title: <span><i className="las la-mobile mr-10"></i>Mobile App</span> }, */
								]}

								updateSelected={this.updateSelecteds}
								selectedIndex={selectedTab}
							/>
						</SectionHeader>

						<Switch>
							<Route
								path={`/:organization/events/:event/engagement/qrcodes`}
								render={(props) => (
									<EventScannables
										title={'Virtual Engagement'}
										event={event}
										readOnly={readOnly}
										eventId={event._id}
										orgId={this.props.orgId}
										organization={this.props.organization}
										setSelectedTab={this.setSelectedTab}
									/>
								)}
							/>
							<Route
								path={`/:organization/events/:event/engagement/interaction-points`}
								render={(props) => (
									<EventInteractionPoints
										event={event}
										readOnly={readOnly}
										eventId={event._id}
										orgId={this.props.orgId}
										setSelectedTab={this.setSelectedTab}
									/>
								)}
							/>
							<Route
								path={`/:organization/events/:event/engagement/gallery`}
								render={(props) => (
									<EventGalleries
										event={event}
										readOnly={false}
										eventId={event._id}
										orgId={this.props.orgId}
										galleries={galleries}
										setSelectedTab={this.setSelectedTab}
										attendees={this.props.attendees}
										addNewGallery={this.addNewGallery}
										removeGallery={this.removeGallery}
									/>
								)}
							/>
						</Switch>
					</div >

				</Route >


			</Switch >
		);
	}
}

export default withRouter(MobileApp);
