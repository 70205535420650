import React from 'react';
import FieldModal from '../../../../components/modals/fieldModal';
import API from '../../../../utils/API';

import InputField from '../../../../components/inputs/inputField';
import ToggleField from '../../../../components/inputs/toggleField';

class AddGalleryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            selectedValue: { label: '', color: '#00B8D9' },
            isCustomPoint: false,
            customLabel: '',
            description: '',
            pointSuffix: '',
            pointValue: 0,
            maxPointsAllowed: 0,
            oncePerReference: false,
            active: false,
            callToActionLabel: '',
            callToActionUrl: '',
            submitting: false,
            invalid: false,
            success: false,
            allowPoints: false,
            galleryName: ''
        };
    }

    addGallery = () => {
        const { orgId, eventId, toggle } = this.props;
        const { galleryName, allowPoints } = this.state;
        if (this.state.galleryName && this.state.galleryName !== '') {

            this.setState({ submitting: true, invalid: false });
            API().post(`Organizations/${orgId}/events/${eventId}/eventFeed`, { feedName: galleryName, allowPoints }).then((res) => {
                if (res.data) {
                    let newEventFeed = res.data.newEventFeed;
                    newEventFeed = { ...newEventFeed, feedItems: [], feedItemsCount: 0 };
                    this.props.addNewGallery(newEventFeed, toggle);
                }
            }).catch((err) => {
                console.log(err);
                this.setState({ submitting: false });
            })
        } else {
            this.setState({ invalid: true });
        }
    };

    render() {
        const { isOpen, toggle } = this.props;
        const {
            submitting,
            allowPoints,
            galleryName
        } = this.state;

        return (
            <div>
                <FieldModal
                    size="small"
                    isOpen={isOpen}
                    modalTitle={"Add Gallery"}
                    bodyHeaderText={'Galleries helps you organize your images posted on your event feeds.'}
                    bodyDescription={''}
                    bodyContent={() => {
                        return (
                            <div>
                                <InputField
                                    label="Gallery Name"
                                    value={galleryName}
                                    onChange={(e) => this.setState({ galleryName: e.target.value })}
                                    errorTrigger={this.state.invalid}
                                />

                                <ToggleField label="Allow Points"
                                    checked={allowPoints}
                                    icons={false}
                                    onChange={() => {
                                        this.setState({ allowPoints: !allowPoints })
                                    }} />
                            </div>)
                    }}
                    toggle={toggle}
                    actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
                    actionButton={() => this.addGallery()}
                    actionButtonDisabled={submitting}
                ></FieldModal>
            </div>

        );
    }
}

export default AddGalleryModal;
