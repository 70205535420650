import React, { Fragment } from 'react';


import InputField from '../../../../components/inputs/inputField';

import FieldModal from '../../../../components/modals/fieldModal';
import SelectField from '../../../../components/inputs/selectField';
import ToggleField from '../../../../components/inputs/toggleField';
class CreateFormModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			formType: '',
			submitting: false,
			invalid: false,
			error: 'All fields are required',
			requiredFields: {
				name: false,
				formType: false,

			}
		};
	}

	componentDidMount() {
		const { program } = this.props;
		let emailsAvailable = program.eventDefaults.emails;
		emailsAvailable = emailsAvailable.map(e => {
			return {
				value: e._id,
				label: e.name
			}
		});

		this.setState({
			availableEmails: emailsAvailable
		});

	}

	addForm = () => {
		const form = { name: this.state.name, type: this.state.formType.value, confirmationEmailEnabled: this.state.confirmationEmailEnabled, confirmationEmail: this.state.confirmationEmail };
		const { name,formType } = this.state;

		const requiredFields = this.state.requiredFields;
		let valid = true;

		if (name === '') {
			valid = false;
			requiredFields.name = true;
		}else{

			requiredFields.name = false;
		}
		if (formType === '') {
			valid = false;
			requiredFields.formType = true;
		}else{
			
			requiredFields.formType = false;
		}

		if (valid) {
			this.props.handleSubmit(form, this.props.toggle);
		} else {
			this.setState({ requiredFields });
		}
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, submitting, invalid, error, requiredFields, formType, confirmationEmail, confirmationEmailEnabled, availableEmails } = this.state;
		return (
			<FieldModal
				size="small"
				isOpen={isOpen}
				modalTitle={"Add Form"}
				bodyHeaderText={''}
				bodyDescription={''}
				bodyContent={() => {
					return (
						<div>
							<InputField
								label="Name"
								onChange={(e) => this.setState({ name: e.target.value })}
								value={name}
								required={true}
								errorTrigger={requiredFields.name}
							/>
							<SelectField
								label="Form Type"
								required={true}
								errorTrigger={requiredFields.formType}
								options={[
									{ value: 'registration', label: 'Registration' },
									{ value: 'general', label: 'General' },
									{ value: 'custom', label: 'Custom' }
								]}
								onChange={(e) => {
									this.setState({
										formType: e
									});
								}}
								value={formType}

							/>
							<ToggleField
								label="Enable Confirmation Email"
								checked={confirmationEmailEnabled}
								icons={false}
								onChange={(e) => {
									this.setState({
										confirmationEmailEnabled: e.target.checked
									});
								}}
							/>
							{confirmationEmailEnabled && (
								<SelectField
									label="Confirmation Email"
									options={availableEmails}
									value={availableEmails.find(
										(e) => e.value == confirmationEmail
									)}
									onChange={(e) => {
										this.setState({
											confirmationEmail: e.value
										});
									}}
								/>
							)}
							{invalid && <div className="modalErrorMessage calibreRegular">{error}</div>}
						</div>)
				}}
				toggle={toggle}
				actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
				actionButton={() => this.addForm()}


			></FieldModal>

		);
	}
}

export default CreateFormModal;
