import React from 'react';

import API from '../../../../utils/API';


import FieldModal from '../../../../components/modals/fieldModal';

import InputField from '../../../../components/inputs/inputField';

class AddCampaignModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: this.props.template.name.concat(' Copy'),
			submitting: false,
			invalid: false,
			error: '',
			requiredFields: {
				name: false
			}
		};
	}

	handleNameChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	createCampaignFromTemplate = async () => {
		this.setState({ submitting: true });
		const { name } = this.state;
		const { eventId, orgId, template, addCampaign } = this.props;
		const requiredFields = this.state.requiredFields;
		let valid = true;

		Object.keys(requiredFields).forEach((key) => {
			if (!name || name === '') {
				requiredFields[key] = true;
				valid = false;
			} else {
				requiredFields[key] = false;
			}
		});
		if (valid) {
			await API()
				.post(`Organizations/${orgId}/events/${eventId}/email`, { name: name, templateId: template._id })
				.then((res) => {
					const emailObj = res.data;

					if (res.data) {
						const newEmail = {
							name: emailObj.name,
							subject: emailObj.subject,
							sentEmailCount: 0,
							openedEmailCount: 0,
							_id: emailObj._id,
							link: `/${orgId}/events/${eventId}/emails/${emailObj._id}`,
							createdBy: emailObj.createdBy,
							createdAt: emailObj.createdAt,
							updatedAt: emailObj.updatedAt
						};

						addCampaign(emailObj, () => {
							window.open(`/${orgId}/events/${eventId}/emails/${emailObj._id}`, '_self');
						});
					} else {
					}
				});
		} else {
			this.setState({ submitting: false, requiredFields: requiredFields });
		}
	};

	render() {
		const { isOpen, toggle, template } = this.props;
		const { name, submitting, requiredFields } = this.state;
		return (

			<div>


				<FieldModal
					size="small"
					isOpen={isOpen}
					modalTitle={"Create New Campaign From Template"}
					bodyHeaderText={'Please enter a unique name for your new email campaign.'}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Campaign Name"
									required={true}
									errorTrigger={requiredFields.name}
									value={name}
									onChange={this.handleNameChange}
								/>
							</div>)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Create' : 'Creating...'}
					actionButton={() => this.createCampaignFromTemplate()}
					actionButtonDisabled={submitting}


				></FieldModal>


			</div>

		);
	}
}

export default AddCampaignModal;
