import React, { Component, Fragment } from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import API from '../../../utils/API';
import EventAttendee from './attendee';
import AddAttendeeModal from './modals/addAttendeeModal';
import DeleteAttendeeModal from './modals/deleteAttendeesModal';
import GroupAttendeesModal from './modals/groupAttendeesModal';
import ImportAttendeesModal from './modals/importAttendeesModal';
import UpdateStatusModal from './modals/updateStatusModal';
import ViewsContainer from '../../../components/views/ViewsContainer';
import AddPointsModal from './modals/AddPointsModal';
import LoadingWizard from '../../../components/spinner/wizard';
import AttendeeInsights from './modals/attendeeInsights';

import BatchUpdateModal from './modals/batchUpdateModal';

let batchCallback;

let bannedProperties = [
	'firstName',
	'lastName',
	'email',
	'emergencyContactName',
	'emergencyContactPhoneNumber',
	'status',
	'phoneNumber',
	'headshotUrl',
	'attendance',
	'eventGroups',
	'createdAt',
	'updatedAt',
	'invited',
	'attendance',
	'registered',
	'checkedIn'
]
class EventAttendees extends Component {
	constructor(props) {
		super(props);
		let customFieldColumns = {
			email: 'Email',
			firstName: 'First Name',
			lastName: 'Last Name',
			//status: 'Status',
			invited: 'Invited',
			attendance: 'Attendance',
			registered: 'Registered',
			checkedIn: 'Checked In',
			title: 'Title',
			phoneNumber: 'Phone Number',
			company: 'Company',
			textMessageOptIn: 'Text Message Opt-In',
			dietaryPreference: 'Dietary Preference',
			foodAllergies: 'Food Allergies',
			emergencyContactName: 'Emergency Contact Name',
			emergencyContactPhoneNumber: 'Emergency Contact Phone Number',
			address: 'Address',
			city: 'City',
			state: 'State',
			zip: 'Zip',
			country: 'Country',
			eventGroups: 'Groups',
			status: 'Status',
			bcc: 'BCC',
			createdAt: 'Date Created',
			updatedAt: 'Last Updated',
			rsvpAt: 'RSVP Date',
			registeredAt: 'Registered Date',
		};

		let fieldCategories = {
			basics: {
				title: 'General Information',
				columns: [
					'email',
					'firstName',
					'lastName',
					'status',
					'invited',
					'attendance',
					'registered',
					'checkedIn',
					'title',
					'phoneNumber',
					'company',
					'textMessageOptIn',
					'dietaryPreference',
					'foodAllergies',
					'emergencyContactName',
					'emergencyContactPhoneNumber',
					'address',
					'city',
					'state',
					'zip',
					'country',
					'eventGroups',
					'bcc',
					'createdAt',
					'updatedAt',
					'rsvpAt',
					'registeredAt'	
				]
			},
		};

		let defaultFieldTypes = {
			email: 'text',
			firstName: 'text',
			lastName: 'text',
			//status: 'text',
			invited: 'bool',
			attendance: 'text',
			registered: 'bool',
			checkedIn: 'bool',
			title: 'text',
			phoneNumber: 'text',
			company: 'text',
			textMessageOptIn: 'bool',
			dietaryPreference: 'text',
			foodAllergies: 'text',
			emergencyContactName: 'text',
			emergencyContactPhoneNumber: 'text',
			address: 'text',
			city: 'text',
			state: 'text',
			zip: 'text',
			country: 'text',
			eventGroups: 'text',
			bcc: 'array',
			createdAt: 'timestamp',
			updatedAt: 'timestamp',
			rsvpAt: 'timestamp',
			registeredAt: 'timestamp'
		};

		if (props.event.experienceType === "in-person" || props.event.experienceType === "hybrid") {
			customFieldColumns = {
				...customFieldColumns,

				rooming_arrivalDate: 'Rooming Arrival Date',
				rooming_arrivalTime: 'Rooming Arrival Time',
				rooming_checkInDate: 'Rooming Check-In Date',
				rooming_departureDate: 'Rooming Departure Date',
				rooming_departureTime: 'Rooming Departure Time',
				rooming_checkOutDate: 'Rooming Check-Out Date',
				rooming_numberOfDaysStay: 'Rooming Number of Days Stay',
				rooming_roomType: 'Rooming Room Type',
				rooming_hotelConfirmationNumber: 'Hotel Confirmation Number',
				rooming_paymentType: 'Rooming Payment Type',
				rooming_notes: 'Rooming Notes',
				travel_originCity: 'Origin City',
				travel_originState: 'Origin State',
				travel_arrivalCity: 'Arrival City',
				travel_arrivalState: 'Arrival State',
				travel_arrivalDate: 'Arrival Date',
				travel_arrivalTime: 'Arrival Time',
				travel_arrivalCarrier: 'Arrival Carrier',
				travel_arrivalFlight: 'Arrival Flight',
				travel_arrivalTicketed: 'Arrival Ticketed',
				travel_arrivalClassOfService: 'Arrival Class of Service',
				travel_arrivalLocator: 'Arrival Locator',
				travel_departureCity: 'Departure City',
				travel_departureState: 'Departure State',
				travel_destinationCity: 'Destination City',
				travel_destinationState: 'Destination State',
				travel_departureDate: 'Departure Date',
				travel_departureTime: 'Departure Time',
				travel_departureCarrier: 'Departure Carrier',
				travel_departureFlight: 'Departure Flight',
				travel_departureTicketed: 'Departure Ticketed',
				travel_departureClassOfService: 'Departure Class of Service',
				travel_departureLocator: 'Departure Locator'

			}

			fieldCategories = {
				...fieldCategories,

				rooming: {
					title: 'Rooming',
					columns: [
						'rooming_arrivalDate',
						'rooming_arrivalTime',
						'rooming_checkInDate',
						'rooming_departureDate',
						'rooming_departureTime',
						'rooming_checkOutDate',
						'rooming_numberOfDaysStay',
						'rooming_roomType',
						'rooming_hotelConfirmationNumber',
						'rooming_paymentType',
						'rooming_notes'
					]
				},
				travel: {
					title: 'Travel',
					columns: [
						'travel_originCity',
						'travel_originState',
						'travel_arrivalCity',
						'travel_arrivalState',
						'travel_arrivalDate',
						'travel_arrivalTime',
						'travel_arrivalCarrier',
						'travel_arrivalFlight',
						'travel_arrivalTicketed',
						'travel_arrivalClassOfService',
						'travel_arrivalLocator',
						'travel_departureCity',
						'travel_departureState',
						'travel_destinationCity',
						'travel_destinationState',
						'travel_departureDate',
						'travel_departureTime',
						'travel_departureCarrier',
						'travel_departureFlight',
						'travel_departureTicketed',
						'travel_departureClassOfService',
						'travel_departureLocator'
					]
				}
			}

			defaultFieldTypes = {
				...defaultFieldTypes,


				rooming_arrivalDate: 'date',
				rooming_arrivalTime: 'text',
				rooming_checkInDate: 'date',
				rooming_departureDate: 'date',
				rooming_departureTime: 'text',
				rooming_checkOutDate: 'date',
				rooming_numberOfDaysStay: 'number',
				rooming_roomType: 'text',
				rooming_hotelConfirmationNumber: 'text',
				rooming_paymentType: 'text',
				rooming_notes: 'text',

				travel_originCity: 'text',
				travel_originState: 'text',
				travel_arrivalCity: 'text',
				travel_arrivalState: 'text',
				travel_arrivalDate: 'date',
				travel_arrivalTime: 'text',
				travel_arrivalCarrier: 'text',
				travel_arrivalFlight: 'text',
				travel_arrivalTicketed: 'text',
				travel_arrivalClassOfService: 'text',
				travel_arrivalLocator: 'text',
				travel_departureCity: 'text',
				travel_departureState: 'text',
				travel_destinationCity: 'text',
				travel_destinationState: 'text',
				travel_departureDate: 'text',
				travel_departureTime: 'text',
				travel_departureCarrier: 'text',
				travel_departureFlight: 'text',
				travel_departureTicketed: 'text',
				travel_departureClassOfService: 'text',
				travel_departureLocator: 'text'
			}

		}
		if (props.organization.integrations.find(s => {
			return s.identifier === "medpro" && s.enabled
		})) {
			customFieldColumns = {
				...customFieldColumns,
				medProId: "MedPro ID",
				NPINumber: "NPI Number",
				stateLicenseNumber: "State License Number",
				licenseState: "License State",
			}

			fieldCategories = {
				...fieldCategories,
				medPro: {
					title: "MedPro",
					columns: [
						"medProId",
						"NPINumber",
						"stateLicenseNumber",
						"licenseState"
					]
				}
			}

			defaultFieldTypes = {
				...defaultFieldTypes,
				medProId: "text",
				NPINumber: "text",
				stateLicenseNumber: "text",
				licenseState: "text",
			}
		}

		if (props.organization.integrations.find(s => {
			return s.identifier === "salesforce" && s.enabled
		})) {
			customFieldColumns = {
				...customFieldColumns,
				isSFLead: "Is Lead",
				isSFContact: "Is Contact",
				accountName: "Account Name",
				sfMemberLink: "Member Link"
			}

			fieldCategories = {
				...fieldCategories,
				salesforce: {
					title: "Salesforce",
					columns: [
						"isSFLead",
						"isSFContact",
						"accountName",
						"sfMemberLink"
					]
				}
			}

			defaultFieldTypes = {
				...defaultFieldTypes,
				isSFLead: "bool",
				isSFContact: "bool",
				accountName: "text",
				sfMemberLink: "link"
			}
		}

		props.customFields.forEach((category) => {
			const fields = category.fields;
			fields.forEach((f) => {
				if (fieldCategories[category._id]) {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				} else {
					fieldCategories[category._id] = {
						title: category.title,
						columns: [`${category._id}*${f._id}`]
					};
				}
				customFieldColumns[category._id + '*' + f._id] = f.fieldName;
				defaultFieldTypes[category._id + '*' + f._id] = f.fieldType;
			});
		});

		const attendees = props.attendees;
		this.state = {
			eventId: props.eventId,
			newAttendees: [],
			groupedAttendees: [],
			checkedAttendees: {},
			allAttendees: attendees,
			sortedAttendees: attendees,
			categoryColumns: fieldCategories,
			columns: customFieldColumns,
			columnTypes: defaultFieldTypes,
			allChecked: false,
			attendeeSelected: false,
			selectedAttendee: 0,
			addAttendeeModalOpen: false,
			importAttendeesModalOpen: false,
			deleteAttendeesModalOpen: false,
			groupAttendeesModalOpen: false,
			inviteAttendeesModalOpen: false,
			addAttendeePointModalOpen: false,
			attendeeInsightsModalOpen: false,
			newAttendee: { firstName: '', lastName: '', email: '' },
			attendeeGroup: {},
			loading: true,
			updateAttendeesModalOpen: false,
			sectionTitleExpanded: true,
			deletedAttendees: [],
			isDeleting: false,
			invitedCount: 0,
			registeredCount: 0,
			checkedInCount: 0,
			toggleBatchUpdate: false,
			selectedViewIndex: 0,
			selectedView: null,
			entriesCount: 0,
			restrictBatchUpdate: false,
			sidePanelOpen: false,
			attendeeId: '',
			editCount: 0,
			activeRow: ''


		};
	}

	componentDidMount() {
		this.setState({ loading: true }, () => this.props.fetchAttendees(this.syncAttendees));
	}

	syncAttendees = () => {
		const { orgId, eventId, event } = this.props;
		let invitedCount = 0,
			registeredCount = 0,
			checkedInCount = 0;
		const attendees = this.props.attendees.map((a) => {
			let invited = false,
				registered = false,
				attending = false,
				checkedIn = false,
				declined = false,
				removed = false;

			if (a.statusLog) {
				a.statusLog.forEach((s) => {
					if (s.status === 'invited') {
						invited = true;
						removed = false;
					} else if (s.status === 'registered') {
						registered = true;
						attending = true;
						declined = false;
					} else if (s.status === 'incomplete' || s.status === 'registered') {
						attending = true;
						declined = false;
					} else if (s.status === 'not attending' || s.status === 'cancelled') {
						declined = true;
						attending = false;
					} else if (s.status === 'removed') {
						invited = false;
						attending = false;
						registered = false;
						checkedIn = false;
						removed = true;
					} else if (s.status === 'checked in') {
						checkedIn = true;
					}
				});
			} else {
				if (a.status === 'invited') {
					invited = true;
					removed = false;
				} else if (a.status === 'registered') {
					registered = true;
					attending = true;
					declined = false;
				} else if (a.status === 'incomplete' || a.status === 'registered') {
					attending = true;
					declined = false;
				} else if (a.status === 'not attending' || a.status === 'cancelled') {
					declined = true;
					attending = false;
				} else if (a.status === 'removed') {
					invited = false;
					attending = false;
					registered = false;
					checkedIn = false;
					removed = true;
				} else if (a.status === 'checked in') {
					checkedIn = true;
				}
			}
			a.invited = invited;
			a.registered = registered;
			a.attendance = (declined || removed) ? 'Not Attending' : checkedIn ? 'Attended' : attending ? 'Attending' : 'Not Indicated';
			a.checkedIn = checkedIn;
			a.id = a._id;
			a.link = `/${orgId}/events/${eventId}/attendees/${a._id}`;
			a.metadata.forEach((meta) => (a[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
			a.eventGroups = event.groups
				.map((eg) => eg.attendees.includes(a._id) && eg.name)
				.filter((g) => !!g)
				.join(', ');
			if (a.roomingInformation)
				a.roomingInformation = Object.keys(a.roomingInformation).forEach((ri) => {
					a[`rooming_${ri}`] = a.roomingInformation[ri];
				});
			if (a.travelInformation)
				a.travelInformation = Object.keys(a.travelInformation).forEach((ti) => {
					a[`travel_${ti}`] = a.travelInformation[ti];
				});
			if (a.status === 'invited') invitedCount++;
			if (a.status === 'registered') registeredCount++;
			if (a.status === 'checked in') checkedInCount++;
			return a;
		});

		this.setState({ loading: false, allAttendees: attendees, sortedAttendees: attendees, invitedCount, registeredCount, checkedInCount, editCount: this.state.editCount + 1 });
	}

	toggleAddAttendee = (cb) => {
		batchCallback = cb;
		this.setState({
			newAttendee: { firstName: '', lastName: '', email: '' },
			addAttendeeModalOpen: !this.state.addAttendeeModalOpen
		});
	};

	addAttendee = (attendee) => {
		const { orgId, eventId } = this.props;
		this.props.addAttendee(attendee);
		attendee.id = attendee._id;

		attendee.link = `/${orgId}/events/${eventId}/attendees/${attendee._id}`;
		attendee.metadata.forEach((meta) => (attendee[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
		this.setState({
			newAttendees: [...this.state.newAttendees, attendee],
			loading: false,
			sortedAttendees: [...this.state.sortedAttendees, attendee],
			editCount: this.state.editCount + 1
		}, () => {
			batchCallback && batchCallback('update')
			this.syncAttendees();
		});
	};

	toggleDeleteAttendees = (checkedAttendees, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedAttendees: checkedAttendees || {},
			deleteAttendeesModalOpen: !this.state.deleteAttendeesModalOpen
		});
	};

	deleteSingleAttendeeUpdate = (attendeeId) => {

		this.props.updateDeleteAttendee(attendeeId, () => {
			let updatedAttendees = this.state.sortedAttendees.filter((a) => a._id !== attendeeId);
			let updatedChecked = this.state.checkedAttendees;
			delete updatedChecked[attendeeId];
			this.setState({
				checkedAttendees: updatedChecked,
				sortedAttendees: updatedAttendees,
				editCount: this.state.editCount + 1,
				deletedAttendees: [...this.state.deletedAttendees, attendeeId],

			}, () => {
				//batchCallback && batchCallback('delete');
			});
		});




	}

	deleteCheckedAttendees = () => {
		this.setState({ isDeleting: true });
		this.props.deleteAttendees(this.state.checkedAttendees, () => {
			var updatedAttendees = this.state.sortedAttendees;
			let tempChecked = JSON.parse(JSON.stringify(this.state.checkedAttendees));
			updatedAttendees = updatedAttendees.filter(
				(sa) => !Object.keys(this.state.checkedAttendees).includes(sa._id.toString())
			);
			//console.log('UPDATED ATTENDEES', updatedAttendees);
			this.setState(
				{
					checkedAttendees: {},
					sortedAttendees: updatedAttendees,
					deletedAttendees: [...this.state.deletedAttendees, ...Object.keys(tempChecked)],
					isDeleting: false,
					editCount: this.state.editCount + 1
				},
				() => {
					batchCallback && batchCallback('delete');
					this.toggleDeleteAttendees();
				}
			);
		});
	};

	deleteSingleAttendee = (attendee, callback) => {
		this.props.deleteAttendees(attendee, () => {
			var updatedAttendees = this.state.sortedAttendees;
			updatedAttendees = updatedAttendees.filter((sa) => !Object.keys(attendee).includes(sa._id));
			batchCallback && batchCallback('delete');
			this.setState(
				{
					checkedAttendees: {},
					sortedAttendees: updatedAttendees,
					deletedAttendees: [...this.state.deletedAttendees, ...Object.keys(attendee)],
					editCount: this.state.editCount + 1
				},
				callback
			);
		});
	};

	toggleAttendeesUpdate = (checkedAttendees, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedAttendees: checkedAttendees || {},
			attendeeGroup: {},
			updateAttendeesModalOpen: !this.state.updateAttendeesModalOpen
		});
	};

	toggleBatchUpdate = (checkedAttendees, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedAttendees: checkedAttendees || {},
			attendeeGroup: {},
			toggleBatchUpdate: !this.state.toggleBatchUpdate
		});
	};

	toggleGroupAttendees = (checkedAttendees, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedAttendees: checkedAttendees || {},
			attendeeGroup: {},
			groupAttendeesModalOpen: !this.state.groupAttendeesModalOpen
		});
	};
	togglePointAdd = (checkedAttendees, bcb) => {
		batchCallback = bcb;
		this.setState({
			checkedAttendees: checkedAttendees || {},
			attendeeGroup: {},
			addAttendeePointModalOpen: !this.state.addAttendeePointModalOpen
		});
	};

	groupCheckedAttendees = (group, callback) => {
		this.props.groupAttendees(group, this.state.checkedAttendees, (added) => {
			const updatedAttendees = this.state.sortedAttendees.map((a) => {
				if (this.state.checkedAttendees[a._id]) {
					a.eventGroups += a.eventGroups !== '' ? ', ' + group.label : group.label;
				}
				return a;
			});
			this.setState(
				{
					checkedAttendees: {},
					sortedAttendees: updatedAttendees,
					allAttendees: updatedAttendees,
					editCount: this.state.editCount + 1
				},
				() => {
					batchCallback && batchCallback('update');
				}
			);
			callback(added);
		});
		this.setState({ groupedAttendees: [...this.state.groupedAttendees, group], loading: false });
	};

	updateCheckedAttendeesStatus = (status, callback) => {
		this.props.batchUpdateAttendeeStatus(status, this.state.checkedAttendees, (added) => {
			const updatedAttendees = this.state.sortedAttendees.map((a) => {
				if (this.state.checkedAttendees[a._id]) {
					a.status = status;
					if (status === 'invited') {
						a.invited = true;
					} else if (status === 'registered') {
						a.registered = true;
						a.attendance = 'Attending';
					} else if (status === 'incomplete' || status === 'registered') {
						a.attendance = 'Attending';
					} else if (status === 'not attending' || status === 'cancelled') {
						a.attendance = 'Not Attending';
					} else if (status === 'removed') {
						a.invited = false;
						a.attendance = 'Not Attending';
					} else if (status === 'checkedIn') {
						a.checkedIn = true;
					}
				}
				return a;
			});

			this.setState(
				{
					checkedAttendees: {},
					sortedAttendees: updatedAttendees,
					allAttendees: updatedAttendees,
					editCount: this.state.editCount + 1
				},
				() => {
					batchCallback && batchCallback('update');
				}
			);
			callback(added);
		});
		//this.setState({ groupedAttendees: [ ...this.state.groupedAttendees, group ], loading: false });
	};
	addPointToCheckedAttendees = (point, callback) => {
		this.props.batchAddAttendeePoint(point, this.state.checkedAttendees, (added) => {
			const updatedAttendees = this.state.sortedAttendees.map((a) => {
				a.interactionPoints.push(point);
				return a;
			});

			this.setState(
				{
					checkedAttendees: {},
					sortedAttendees: updatedAttendees,
					allAttendees: updatedAttendees,
					editCount: this.state.editCount + 1
				},
				() => {
					batchCallback && batchCallback('update');
				}
			);
			callback(added);
		});
	};

	toggleInviteAttendees = () => {
		this.setState({
			inviteAttendeesModalOpen: !this.state.inviteAttendeesModalOpen
		});
	};

	inviteCheckedAttendees = async (callback) => {
		const { checkedAttendees } = this.state;
		const eventId = this.props.eventId;
		const attendeeIds = Object.keys(checkedAttendees);
		if (attendeeIds.length > 0) {
			await API().get(`Organizations/${this.props.orgId}/events/${eventId}/emails/invitation`).then((res) => {
				if (res.data) {
				}
			});
		}
	};

	updateAttendee = (attendeeId, attendee) => {
		const updatedAttendees = this.state.sortedAttendees.map((a) => {
			if (attendeeId == a.id) {
				attendee.metadata.forEach((meta) => (attendee[meta.categoryId + '*' + meta.fieldId] = meta.fieldValue));
				a = { ...a, ...attendee };
			}
			return a;
		});
		this.setState({
			sortedAttendees: updatedAttendees,
			//editCount: this.state.editCount + 1
		}, () => this.props.updateAttendee(attendeeId, attendee));
	};


	addField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (
				fieldCategories[category._id] &&
				!fieldCategories[category._id].columns.includes(`${category._id}*${f._id}`)
			) {
				fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
			} else {
				fieldCategories[category._id] = {
					title: category.title,
					columns: [`${category._id}*${f._id}`]
				};
			}
			fieldColumns[category._id + '*' + f._id] = f.fieldName;
			fieldTypes[category._id + '*' + f._id] = f.fieldType;
		});
		this.setState({
			columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories,
			editCount: this.state.editCount + 1
		}, () => {
			this.props.addCustomField('attendees', category);
			cb && cb();
		});
	};

	updateField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		fields.forEach((f) => {
			if (fieldCategories[category._id]) {
				//fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
				fieldColumns[category._id + '*' + f._id] = f.fieldName;
				fieldTypes[category._id + '*' + f._id] = f.fieldType;
			}
		});
		this.setState({
			columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories,
		}, cb);
	};

	removeField = (category, cb) => {
		const fieldCategories = { ...this.state.categoryColumns };
		const fieldColumns = { ...this.state.columns };
		const fieldTypes = { ...this.state.columnTypes };
		const fields = category.fields;
		if (fields.length > 0) {
			if (fieldCategories[category._id]) {
				fieldCategories[category._id].columns = [];

				fields.forEach((f) => {
					fieldCategories[category._id].columns.push(`${category._id}*${f._id}`);
					fieldColumns[category._id + '*' + f._id] = f.fieldName;
					fieldTypes[category._id + '*' + f._id] = f.fieldType;
				});
			}
		} else {
			// If removing the last field, also clean up fieldColumns and fieldTypes
			if (fieldCategories[category._id].columns) {
				fieldCategories[category._id].columns.forEach(columnId => {
					delete fieldColumns[columnId];
					delete fieldTypes[columnId];
				});
			}
			// Finally, remove the category itself
			delete fieldCategories[category._id];
		}
		this.setState({
			columns: fieldColumns, columnTypes: fieldTypes, categoryColumns: fieldCategories,
		}, cb);
	};

	toggleImportAttendees = (cb) => {
		batchCallback = cb;
		this.setState({ importAttendeesModalOpen: true });
	}


	updateBatchAttendees = (editedValues, callback) => {
		const { checkedAttendees, sortedAttendees } = this.state;
		let attendeeIds = Object.keys(checkedAttendees);
		for (let i = 0; i < sortedAttendees.length; i++) {
			let currentAttendee = sortedAttendees[i];
			if (attendeeIds.includes(currentAttendee._id)) {
				sortedAttendees[i] = { ...currentAttendee, ...editedValues };
			}
		}

		this.setState({ sortedAttendees, editCount: this.state.editCount + 1 }, () => {
			batchCallback && batchCallback('update');
			callback && callback();
		});
	}
	updateEventGroups = (editedAttendee) => {
		const updatedAttendeesGroups = this.state.sortedAttendees.map(attendee => {

			if (attendee._id === editedAttendee._id) {
				attendee.eventGroups = editedAttendee.groups.map(ea => ea.name).join(', ');
			}

			return attendee;
		});
		this.setState({ sortedAttendees: updatedAttendeesGroups });
	}
	updateSelectedViewIndex = (selectedViewIndex, views, entriesCount) => {
		let currentView = null;

		if ((selectedViewIndex === 0 && views[0] == null) || (selectedViewIndex === 1 && views[0] !== null)) {
			currentView = null;
		} else {
			if (selectedViewIndex === 0) {
				currentView = views[0];
			} else {
				if (views[0] == null && selectedViewIndex > 0) {
					currentView = views[selectedViewIndex];
				} else {
					currentView = views[selectedViewIndex - 1];
				}
			}
		}

		this.setState({
			selectedViewIndex: selectedViewIndex, selectedView: currentView, entriesCount: entriesCount,
			//editCount: this.state.editCount + 1
		}, () => {

			if (this.state.selectedView) {
				let toggledColumns = JSON.parse(JSON.stringify(this.state.selectedView.toggledColumns))
				toggledColumns = toggledColumns.filter(c => !bannedProperties.includes(c));
				this.setState({ restrictBatchUpdate: toggledColumns.length === 0 ? true : false })
			} else {
				this.setState({ restrictBatchUpdate: false })

			}
		}
		)
	}


	setActiveRow = (attendeeId) => {
		this.setState({ activeRow: attendeeId })
	}

	render() {
		const {
			eventId,
			addAttendeeModalOpen,
			deleteAttendeesModalOpen,
			groupAttendeesModalOpen,
			categoryColumns,
			columns,
			isDeleting,
			updateAttendeesModalOpen,
			columnTypes,
			sortedAttendees,
			addAttendeePointModalOpen,
			importAttendeesModalOpen,
			attendeeInsightsModalOpen,
			loading, invitedCount, registeredCount, checkedInCount,
			toggleBatchUpdate,
			restrictBatchUpdate,
			sidePanelOpen,
			activeRow,
			editCount,
		} = this.state;
		const { event, readOnly, orgId, attendeeViews } = this.props;
		return (
			<Switch>

				<Route path={`/:organization/events/:eventId/attendees`}>
					<Fragment>
						{!loading && <ViewsContainer
							enableSalesforce={this.props.event.integrations && this.props.event.integrations.salesforce && this.props.event.integrations.salesforce.campaignId ? true : false}
							activeRow={activeRow}
							sidePanelOpen={sidePanelOpen}
							importAvailable={true}
							orgId={orgId}
							key={`attendees${editCount}`}
							summaryPanel={<button className='summaryPanel' onClick={() => this.setState({ attendeeInsightsModalOpen: true })}>
								<div className='flex aic jcsb mb-20 w-100'><h5><i className='las la-lightbulb mr-10 seBlue'></i>Attendee Insights</h5><i className='las la-angle-right'></i></div>
								<div className='flex aic wrap jcfe'>
									<div>
										<p>Total</p>
										<h3>{sortedAttendees.length}</h3>
									</div>
									<div className='ml-25'>
										<p>Invited</p>
										<h3>{invitedCount}</h3>
									</div>
									<div className='ml-25'>
										<p>Registered</p>
										<h3>{registeredCount}</h3>
									</div>
									<div className='ml-25'>
										<p>Checked In</p>
										<h3>{checkedInCount}</h3>
									</div>
								</div>
								{/* 								<p className='small'>Last Update: <span className='seBlue'>William added attendee on {moment(event.lastUpdated).format("MM/DD/YYYY H:mm:ss a")}</span>  </p>
 */}

							</button>}
							views={attendeeViews}
							entries={sortedAttendees}
							categoryColumns={categoryColumns}
							columns={columns}
							columnTypes={columnTypes}
							title="Attendees"
							description="View and manage all attendees. Create custom views to quickly check on your attendees."
							readOnly={false}
							mainActions={[
								{ type: 'button', onClick: this.toggleAddAttendee, label: 'Add attendee' },
								{
									type: 'button', onClick: this.toggleImportAttendees,
									label: <span><i className='las la-file-import mr-5' /> Import attendees</span>
								}
							]}
							batchActions={restrictBatchUpdate ? [{
								label: 'Update Status',
								iconClass: 'las la-redo-alt mr-5',
								class: '',
								onClick: this.toggleAttendeesUpdate
							},
							{
								label: 'Add to Group',
								iconClass: 'las la-user-plus mr-5',
								class: '',
								onClick: this.toggleGroupAttendees
							},
							{
								label: 'Add Interaction Point',
								iconClass: 'las la-plus-square mr-5',
								class: '',
								onClick: this.togglePointAdd
							},
							{
								label: 'Delete',
								iconClass: 'las la-trash-alt mr-5',
								class: 'danger',
								onClick: this.toggleDeleteAttendees
							}] : [{
								label: 'Batch Update',
								iconClass: 'las la-pen-square mr-5',
								class: '',
								onClick: this.toggleBatchUpdate
							},

							{
								label: 'Update Status',
								iconClass: 'las la-redo-alt mr-5',
								class: '',
								onClick: this.toggleAttendeesUpdate
							},
							{
								label: 'Add to Group',
								iconClass: 'las la-user-plus mr-5',
								class: '',
								onClick: this.toggleGroupAttendees
							},
							{
								label: 'Add Interaction Point',
								iconClass: 'las la-plus-square mr-5',
								class: '',
								onClick: this.togglePointAdd
							},
							{
								label: 'Delete',
								iconClass: 'las la-trash-alt mr-5',
								class: 'danger',
								onClick: this.toggleDeleteAttendees
							}
							]}
							breadcrumbs={[
								{ name: event.name }
							]}
							enableDownloadViews={true}
							type={'Attendee'}
							referenceCollection={'Events'}
							referenceId={eventId}
							eventId={eventId}
							section={'attendees'}
							fields={this.props.customFields}
							addField={this.addField}
							updateField={this.updateField}
							defaultSort={'email'}
							removeField={this.removeField}
							updateSelectedViewIndex={this.updateSelectedViewIndex}
							viewPanelActions={[{
								iconClass: "las la-history  fs-20 c-pointer",
								label: "Change Log",
								onClick: () => this.setState({ attendeeInsightsModalOpen: true }),
							}]}
						/>}
						<LoadingWizard loading={loading} text="Loading Attendees" />
						{deleteAttendeesModalOpen && <DeleteAttendeeModal
							isOpen={deleteAttendeesModalOpen}
							delete={this.deleteSingleAttendeeUpdate}
							isDeleting={isDeleting}
							toggle={this.toggleDeleteAttendees}
							count={Object.keys(this.state.checkedAttendees).length}
							attendeeIds={Object.keys(this.state.checkedAttendees)}
							eventId={eventId}
							orgId={orgId}
						/>}
						{groupAttendeesModalOpen && (
							<GroupAttendeesModal
								isOpen={groupAttendeesModalOpen}
								addGroup={this.groupCheckedAttendees}
								toggle={this.toggleGroupAttendees}
								groups={this.props.event.groups}
								count={Object.keys(this.state.checkedAttendees).length}
							/>
						)}
						{updateAttendeesModalOpen && (
							<UpdateStatusModal
								isOpen={updateAttendeesModalOpen}
								toggle={this.toggleAttendeesUpdate}
								count={Object.keys(this.state.checkedAttendees).length}
								updateCheckedAttendeesStatus={this.updateCheckedAttendeesStatus}
							/>
						)}
						{addAttendeePointModalOpen && (
							<AddPointsModal
								isOpen={addAttendeePointModalOpen}
								toggle={this.togglePointAdd}
								count={Object.keys(this.state.checkedAttendees).length}
								addAttendeePoint={this.addAttendeePoint}
								orgId={this.props.orgId}
								eventId={this.props.eventId}
								add={(point, referenceId) => {
									this.addPointToCheckedAttendees(
										{ id: point._id, reference: referenceId },
										this.togglePointAdd
									);
								}}
							/>
						)}
						{addAttendeeModalOpen &&
							<AddAttendeeModal
								style={{ maxHeight: "calc(100vh - 80px)" }}
								isOpen={true}
								handleSubmit={this.addAttendee}
								toggle={this.toggleAddAttendee}
								eventId={eventId}
								orgId={this.props.orgId}
							/>}

						{toggleBatchUpdate &&
							<BatchUpdateModal
								isOpen={toggleBatchUpdate}
								toggle={this.toggleBatchUpdate}
								count={Object.keys(this.state.checkedAttendees).length}
								attendeeIds={Object.keys(this.state.checkedAttendees)}
								updateCheckedAttendeesStatus={this.updateCheckedAttendeesStatus}
								updateBatchAttendees={this.updateBatchAttendees}
								categoryColumns={categoryColumns}
								columns={columns}
								columnTypes={columnTypes}
								orgId={this.props.orgId}
								eventId={this.props.eventId}
								bannedProperties={bannedProperties}
								checkedEntreesData={sortedAttendees.filter(a => Object.keys(this.state.checkedAttendees).includes(a._id))}
								selectedView={this.state.selectedView}
								entriesCount={this.state.entriesCount}
								checkedEntries={Object.keys(this.state.checkedAttendees).length}
							/>
						}
						{importAttendeesModalOpen && <ImportAttendeesModal

							isOpen={importAttendeesModalOpen}
							toggle={() => this.setState({ importAttendeesModalOpen: false })}
							customFields={this.props.event.customFields.attendees}
							addAttendee={this.addAttendee}
							eventId={eventId}
							orgId={this.props.orgId}
							groups={this.props.event.groups}
							importAttendees={(attendees) => this.props.importAttendees(attendees, this.syncAttendees)}
							groupAttendees={this.props.groupImportedAttendees}
							event={this.props.event}
						/>}
						{attendeeInsightsModalOpen &&
							<AttendeeInsights
								isOpen={attendeeInsightsModalOpen}
								toggle={() => this.setState({ attendeeInsightsModalOpen: false })}
								customFields={this.state.columns}
								attendees={sortedAttendees}
								orgId={orgId}
								eventId={eventId}
							/>
						}

						{/* Side Panel Open */}
						<div className={`sidePanelContainer ${sidePanelOpen ? 'panelOpen' : ''}`}>
							<Switch>
								<Route path={`/:organization/events/:eventId/attendees/:attendeeId`}
									render={(props) => {
										const attendeeId = props.match.params.attendeeId;
										const attendee = sortedAttendees.find((a) => a._id === attendeeId);
										if (!attendee) return <Redirect to={`/${orgId}/events/${eventId}/attendees`} />
										if (!sidePanelOpen) this.setState({ sidePanelOpen: true })
										return <div className='h-100' key={attendeeId}>

											{!loading && <EventAttendee
												event={this.props.event}
												eventId={eventId}
												readOnly={readOnly}
												org={this.props.organization}
												orgId={this.props.orgId}
												locations={this.props.locations}
												updateAttendee={this.updateAttendee}
												deleteAttendee={this.deleteSingleAttendee}
												attendees={sortedAttendees}
												customFields={this.props.customFields}
												addNewGroup={this.props.addNewGroup}
												setActiveRow={this.setActiveRow}
												updateEventGroups={this.updateEventGroups}
											/>}
											<LoadingWizard loading={loading} />
										</div>
									}}
								>
								</Route>
								<Route exact path={`/:organization/events/:eventId/attendees`}
									render={() => {
										if (sidePanelOpen) this.setState({ sidePanelOpen: false })
										return <></>
									}}
								>
								</Route>
							</Switch>
						</div>

					</Fragment>
				</Route >
			</Switch >
		);
	}
}

export default withRouter(EventAttendees);
