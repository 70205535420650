import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-toggle/style.css';
import API from '../../../utils/API';
import SectionHeader from '../../../components/views/SectionHeader';
import PagePreviewFrame from '../../../views/pages/components/pagePreviewFrame';
import InputField from '../../../components/inputs/inputField';
import FormBuilderModal from '../../event/forms/modals/formBuilder';

import ToggleField from '../../../components/inputs/toggleField';

import RichTextEditor from '../../../views/pages/components/richTextEditor';
import SectionFooter from '../../../components/views/SectionFooter';
const ENV =
	process.env.NODE_ENV === 'production'
		? window.location.href.includes('planner.simple.events') ? `https://admin-api.simple.events/` : `https://beta-api.simple.events/`
		: `http://${window.location.hostname}:5015/`;

const baseFieldList = [
	{
		_id: 'user',
		title: 'User Defaults',
		fields: [
			{
				_id: '_firstName',
				id: 'firstName',
				fieldName: 'First Name',
				fieldType: 'text',
				propertyType: 'base',
				required: true,
				unique: true
			},
			{
				_id: '_lastName',
				id: 'lastName',
				fieldName: 'Last Name',
				fieldType: 'text',
				propertyType: 'base',
			},
			{
				_id: '_email',
				id: 'email',
				fieldName: 'Email',
				fieldType: 'email',
				propertyType: 'base',
				required: true,
				unique: true
			}
		]
	},
	{
		_id: 'event',
		title: 'Event Defaults',
		fields: [
			{
				_id: '_name',
				id: 'name',
				fieldName: 'Name',
				fieldType: 'text',
				propertyType: 'base',
				required: true,
				unique: true
			},
			{
				_id: '_startDate',
				id: 'startDate',
				fieldName: 'Start Date',
				fieldType: 'date',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_endDate',
				id: 'endDate',
				fieldName: 'End Date',
				fieldType: 'date',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_startTime',
				id: 'startTime',
				fieldName: 'Start Time',
				fieldType: 'time',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_endTime',
				id: 'endTime',
				fieldName: 'End Time',
				fieldType: 'time',
				propertyType: 'base',
				required: false,
				unique: true
			},
			{
				_id: '_inventory',
				fieldName: 'Inventory',
				fieldType: 'inventory',
				propertyType: 'custom',
			}
		]
	},
	{
		_id: 'special',
		title: 'Special',
		fields: [
			{
				_id: '_additionalAttendees',
				fieldName: 'Additional Attendees',
				fieldType: 'additionalAttendees',
				propertyType: 'base',
				moduleConfiguration: {
					label: 'Guest',
					maxAttendees: 2,
					associatedGroup: null,
					defaultStatus: 'registered',
					confirmationEmail: null,
					fields: [
						{
							_id: '_firstName',
							attendeeField: { propertyType: 'base', propertyName: 'firstName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeFirstName',
							label: 'First Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'text',
							static: true
						},
						{
							_id: '_lastName',
							attendeeField: { propertyType: 'base', propertyName: 'lastName' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeLastName',
							label: 'Last Name',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: false,
							style: '',
							type: 'text',
							static: false
						},
						{
							_id: '_email',
							attendeeField: { propertyType: 'base', propertyName: 'email' },
							columnClass: 'col-12',
							description: '',
							disabled: false,
							id: '_attendeeEmail',
							label: 'Email',
							objectClass: 'xbaseInput',
							options: [],
							placeholder: '',
							required: true,
							style: '',
							type: 'email',
							static: true
						}
					]
				}
			},
			{
				_id: '_availabilityCalendar',
				fieldName: 'Availability Calendar',
				fieldType: 'availabilityCalendar',
				propertyType: 'base',
				moduleConfiguration: {}
			},
			{
				_id: '_salesForceSearchAccount',
				fieldName: 'Salesforce Search Account',
				fieldType: 'salesForceSearchAccount',
				propertyType: 'base',
				moduleConfiguration: {
					"medProID": false,
					"medProMasterID": false,
					"firstName": false,
					"lastName": false,
					"suffix": false,
					"title": false,
					"address": false,
					"licenseNumber": false,
					"licenseState": false,
				}
			},
			{
				_id: '_salesforceContactToAttendeeLookup',
				fieldName: 'Salesforce Contact to Attendee Lookup',
				fieldType: 'salesforceContactToAttendeeLookup',
				propertyType: 'base',
				moduleConfiguration: {}
			}
		]
	}
];

class RequestForm extends Component {
	constructor(props) {
		super(props);
		const form = props.program.requestForm;
		if ((form.pages && form.pages.length === 0) || !form.pages) {
			form.pages = [
				{
					title: 'Request Form',
					description: 'Please fill out the form below to request this event.',
					sections: [
						{
							_id: `^0`,
							title: '',
							page: 1,
							fields: [],
							buttons: []
						}
					]
				}
			];
		}
		this.state = {
			form: form,
			editedForm: JSON.parse(JSON.stringify(form)),
			editedFields: props.fields,
			fieldModal: false,
			adding: false,
			loading: false,
			editing: false,
			removing: false,
			removedCategoryId: 0,
			removedFieldId: 0,
			customFieldsCollapsed: false,
			unattachedFieldsCollapsed: true,
			formButtonsCollapsed: true,
			fieldTypeTabSelected: 0,
			currentPage: 1,
			formEditorTabSelected: 0,
			formBuilderModalOpen: false,
			editedCount: 0,

		};
	}

	async componentDidMount() {
		let editForm = this.state.editedForm;
		let isMissingFields = true;
		if (editForm.pages && editForm.pages.length > 0) {
			for (let pageCount = 0; pageCount < editForm.pages.length; pageCount++) {
				let page = editForm.pages[pageCount];
				if (page.sections && page.sections.length > 0) {
					for (let sectionCount = 0; sectionCount < page.sections.length; sectionCount++) {
						let section = page.sections[sectionCount];
						if (section.fields && section.fields.length > 0) {
							isMissingFields = false;
							break;
						}
					}

				}
				if (isMissingFields === false) {
					break;

				}

			}

		}
		this.setState({
			isMissingFields: isMissingFields
		});
	}

	saveForm = async () => {
		const { orgId } = this.props;
		const { editedForm } = this.state;
		let editedFormTemp = JSON.parse(JSON.stringify(editedForm));
		if (editedForm.confirmationEmail === 'N/A' || editedForm.confirmationEmail === '') {
			delete editedFormTemp.confirmationEmail;
		}
		await API()
			.patch(`Organizations/${orgId}/accountForms/${editedForm._id}`, editedFormTemp)
			.then((res) => {
				if (res.data) {

					let editForm = editedFormTemp;
					let isMissingFields = true;
					if (editForm.pages && editForm.pages.length > 0) {
						for (let pageCount = 0; pageCount < editForm.pages.length; pageCount++) {
							let page = editForm.pages[pageCount];
							if (page.sections && page.sections.length > 0) {
								for (let sectionCount = 0; sectionCount < page.sections.length; sectionCount++) {
									let section = page.sections[sectionCount];
									if (section.fields && section.fields.length > 0) {
										isMissingFields = false;
										break;
									}
								}

							}
							if (isMissingFields === false) {
								break;
							}
						}

					}
					this.setState({
						form: { ...editedForm, ...res.data },
						editing: false,
						isMissingFields: isMissingFields,
						editedCount: this.state.editedCount + 1
					});
					this.props.updateProgramRequestForm(res.data);
				}
			})
			.catch((e) => {
				this.setState({
					invalid: true,
					submitting: false
				});
			});
	};

	updateForm = (editedForm) => {
		this.setState({
			editedForm: editedForm,
			editing: true
		});
	};

	cancelEdit = () => {
		const revertedForm = JSON.parse(JSON.stringify(this.state.form));
		this.setState({
			editedForm: revertedForm,
			editing: false
		});
	};

	setupPage = () => {
		this.setState({ loading: true });
		API()
			.post(`Organizations/${this.props.orgId}/programs/${this.props.programId}/createRequestFormPage`)
			.then((res) => {
				if (res.data) {
					this.props.updateProgramRequestFormPage(res.data, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((e) => {
				console.log(e);
				this.setState({ loading: false });
			});
	};

	toggleFormEditorModal = () => {
		this.setState({ formEditorModalOpen: !this.state.formEditorModalOpen });
	};

	handleChange = (changeObj) => {
		const { editedForm } = this.state;
		this.setState({ editedForm: { ...editedForm, ...changeObj }, editing: true });
	};
	render() {
		const { form, editing, editedForm, loading, isMissingFields, editedCount } = this.state;
		const { program } = this.props;
		return (
			<div className="sectionContainer">
				<SectionHeader
					title={'Request Form'}
					description="Build a request form to allow users to request event based on this program."
					editing={editing}
					cancelEdit={this.cancelEdit}
					saveEdit={this.saveForm}
					updatedAt={form.updatedAt}
					subActions={""}
					breadcrumbs={[
						{
							name: program.name
						}
					]}
				>


				</SectionHeader>
				{!loading && <div className="actionButtonContainer" style={{ marginLeft: 'unset' }}>
					<div >
						<button className={`mainAction prim`}
							disabled={false}
							onClick={() => {
								if (!program.requestFormPage) {
									this.setupPage();
								} else {
									this.props.history.push(`/${this.props.orgId}/pages/${program.requestFormPageId}`);
								}
							}}>
							<div>{!program.requestFormPage ? "Add to Page" : "View Page"}</div>
						</button>
					</div>
				</div>}




				{!loading &&
					<div className="sectionBody">
						<div className='blocksContainer'>
							<div className="sectionBlock mr-25 contained-h">
								<h2 className='blockHeader'>Form Information</h2>

								<ToggleField
									label="Enable a review page before form submission"
									checked={editedForm.enableSubmissionReview}
									icons={false}
									onChange={(e) => {
										this.handleChange({ enableSubmissionReview: e.target.checked });
									}}
								/>

								{editedForm.enableSubmissionReview &&
									<div>
										<InputField
											label="Review Page Title"
											required={false}
											placeholder="Please Review"
											value={editedForm.submissionReviewTitle}
											onChange={(e) => {
												this.handleChange({ submissionReviewTitle: e.target.value });
											}}
										/>

										<InputField
											label="Review Page Description"
											required={false}
											placeholder="Look over your questions and go back if you need to edit."
											value={editedForm.submissionReviewSubtitle}
											onChange={(e) => {
												this.handleChange({ submissionReviewSubtitle: e.target.value });
											}}
										/>
									</div>
								}

								<h2 className='blockHeader'>Submission</h2>
								<InputField
									label="Confirmation Page Title"
									required={false}
									placeholder="Form Submitted"
									value={editedForm.successTitle}
									onChange={(e) => {
										this.handleChange({ successTitle: e.target.value });
									}}
								/>
								<RichTextEditor
									id="Confirmation_Page_Description"
									label="Confirmation Page Description"
									placeholder="We have received your information"
									value={editedForm.successSubtitle}
									onChange={(e) => {
										this.handleChange({ successSubtitle: e });
									}}
									customFields={[{
										label
											:
											"Organization Name",
										text
											:
											"organizationname",
										value
											:
											"{{organizationname}}"
									}]}
								/>






							</div>
							<div style={{ padding: 5 }} className=' contained-h sectionBlock emailDesignPreview mt-20'>
								<div style={{ width: 425 }}
									className="cardDetailsContainerPages containerTitle">
									<div className='w-100'>
										<h2 className='blockHeader mt-0'>Design</h2>
										<h5 className='formFieldLabel mt-10'>Form Preview </h5>

									</div>


									{!isMissingFields ? <button
										className="neu ml-a"
										onClick={this.toggleFormEditorModal}
									>
										Edit Form
									</button> : <button
										className="neu prim ml-a"
										onClick={this.toggleFormEditorModal}
									>Add Form Fields

									</button>}



								</div>
								<div style={{ width: 425, height: 617 }}>

									<div
										className="emailPreview eFrame flex-ac preview"
									>
										<PagePreviewFrame
											key={editedCount}
											ENV={ENV}
											renderURL={`Organizations/${this.props.orgId}/accountPages/render/formRender/`}
											preview={true}
											pageProperties={{}}
											focusedComponent={""}
											simulatedUser={{ value: this.props.account._id }}
											simulatedSession={{ value: false }}
											renderOnly={true}
											formId={program.requestFormId}
										/>

									</div>
								</div>
							</div>
						</div>

					</div>
				}

				<SectionFooter updatedAt={form.updatedAt} saveEdit={this.saveForm} cancelEdit={this.cancelEdit} editing={editing} />

				<FormBuilderModal
					isOpen={this.state.formEditorModalOpen}
					editing={editing}
					toggle={this.toggleFormEditorModal}
					form={form}
					editedForm={editedForm}
					fields={this.props.fields}
					baseFieldList={baseFieldList}
					links={{ get: `` }}
					updateForm={this.updateForm}
					cancelEdit={this.cancelEdit}
					emails={this.props.availableEmails}
					orgId={this.props.orgId}
					eventId={this.props.eventId}
					addNewField={this.props.addNewField}
					saveForm={this.saveForm}
					customFields={[{
						label
							:
							"Organization Name",
						text
							:
							"organizationname",
						value
							:
							"{{organizationname}}"
					}]}

				/>
			</div>
		);
	}
}

export default withRouter(RequestForm);
