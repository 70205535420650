import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../utils/API';

import FieldModal from '../../../../components/modals/fieldModal';

class DeleteTextsModal extends React.Component {
    state = {

        submitting: false
    }
    deleteCampaigns = async () => {
        const { checkedCampaigns, orgId, eventId, deleteCheckedCampaigns, toggle, isSingleDelete, editedTextMessage, updateDeleteCampaign } = this.props;
        this.setState({ submitting: true })

        const campaignsIds = !isSingleDelete ? Object.keys(checkedCampaigns) : [editedTextMessage._id];
        try {
            await API()
                .patch(`Organizations/${orgId}/events/${eventId}/textMessage`, { textIds: campaignsIds })
                .then((res) => {
                    if (isSingleDelete) {
                        updateDeleteCampaign(editedTextMessage._id, () => {
                            toggle();
                        })

                    } else {
                        deleteCheckedCampaigns(() => {
                            toggle();
                        });
                    }

                });
        } catch (e) {
            console.log(e);
        }
    };
    render() {
        const { isOpen, toggle, checkedCampaigns, isSingleDelete } = this.props;
        const { submitting } = this.state;

        let campaignCount = !isSingleDelete ? Object.keys(checkedCampaigns).length : 1;

        return (
            <FieldModal
                size="small"
                isOpen={isOpen}
                modalTitle={`Delete Text Message${campaignCount > 1 ? 's' : ''}`}
                bodyHeaderText={<span>You are about to delete <b>{campaignCount}</b> text message{campaignCount > 1 ? 's' : ''} from the
                    event, this is irreversible, would you like to continue?</span>}
                bodyContent={() => {
                    return ("")
                }}
                toggle={toggle}
                actionButtonLabel={!submitting ? 'Delete' : 'Deleting...'}
                actionButton={() => this.deleteCampaigns()}
                isDelete={true}
                actionButtonDisabled={submitting}


            ></FieldModal>

        );
    }
}

export default withRouter(DeleteTextsModal);
