import React, { Fragment } from 'react';

import API from '../../../../utils/API';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Button, Row } from 'reactstrap';

import InputField from '../../../../components/inputs/inputField';
import FieldModal from '../../../../components/modals/fieldModal';

class AddGroupModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			capacity: null,
			hasCapacity: false,
			submitting: false,
			invalid: false,
			requiredFields: {
				name: false
			}
		};
	}

	handleChange = (e) => {
		this.setState({
			name: e.target.value
		});
	};

	addGroup = async (group) => {
		this.setState({ submitting: true });
		const { eventId, orgId } = this.props;
		const { name } = this.state;
		let requiredFields = this.state.requiredFields;
		let valid = true;
		if (!name || name === '') {
			requiredFields.name = true;
			valid = false;
		} else {
			requiredFields.name = false;
		}

		if (valid) {
			this.setState({ submitting: true });

			await API()
				.post(`Organizations/${orgId}/events/${eventId}/group`, { ...group })
				.then((res) => {
					if (res.data) {
						this.props.handleSubmit(res.data);
						this.resetAdd(true);

						this.setState({ invalid: true, submitting: false });


					}
				})
				.catch((e) => {
					this.setState({
						invalid: true,
						submitting: false
					});
				});
		} else {
			this.setState({ requiredFields: requiredFields, submitting: false });
		}
	};

	resetAdd = (e, toggle) => {
		this.setState({
			name: '',
			submitting: false,
			invalid: false
		});
		if (!toggle === true) this.props.toggle();
	};

	render() {
		const { isOpen, toggle } = this.props;
		const { name, submitting, invalid, requiredFields } = this.state;
		return (

			<div>
				<FieldModal
					size="medium"
					isOpen={isOpen}
					modalTitle={"Add Group"}
					bodyHeaderText={''}
					bodyDescription={``}
					bodyContent={() => {
						return (
							<div>
								<InputField
									label="Group Name"
									required={true}
									errorTrigger={requiredFields.name}
									onChange={this.handleChange}
									value={name}
								/>
							</div>

						)
					}}
					toggle={toggle}
					actionButtonLabel={!submitting ? 'Submit' : 'Adding...'}
					cancelButtonLabel={'Cancel'}

					actionButton={() => this.addGroup({ name })}
					submitting={submitting}
					actionButtonDisabled={submitting}

				></FieldModal>


			</div>

		);
	}
}

export default AddGroupModal;
